import React, { useMemo, useState } from 'react';
import { AutoCompleteField, AutoCompleteFieldProps } from '@mezzanine-ui/react-hook-form';
import { debounce } from 'lodash';


/**
 * MaterialAutoCompleteField
 * 為了解決料號數量過多，造成AutoCompleteField選單效能的問題，所以將選單過濾的邏輯寫在這裡
 * @param param0 AutoCompleteFieldProps
 * @returns React.FC<AutoCompleteFieldProps>
 */
const DebounceAutoCompleteField = ({
  registerName,
  label,
  options,
  ...rest
}: AutoCompleteFieldProps) => {
  const [searchText, setSerachText] = useState('');

  const onSearch = (serachStr: string) => {
    setSerachText(serachStr);
  };

  const debouncedSearch = debounce(onSearch, 500);

  const filtedOptions = useMemo(() => {
    const searchTextReg = new RegExp(searchText.replace(/\\/g, '\\\\'), 'i');
    // find options and get only the first 30 options
    const result = options
      .filter((option) => !!option.name.match(searchTextReg))
      .slice(0, 30);
    return result;
  }, [searchText, options]);


  return (
    <AutoCompleteField
      {...rest}
      registerName={registerName}
      label={label}
      disabledOptionsFilter
      onSearch={debouncedSearch}
      options={filtedOptions}
    />
  );
}

export default DebounceAutoCompleteField;