import {
  Badge,
  BadgeContainer,
  Button,
  Message,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Option,
  Select,
  Tab,
  TabPane,
  Tabs,
  Typography,
} from '@mezzanine-ui/react';
import {
  FormStateErrors,
  ProductionForms,
  useGetFormValidation,
  useGetProductionForms,
  useGetProductionFormsAnswers,
  useTargetModal,
} from '@solar/templates';
import { Modals } from './Modals.enum';
import { Col, Row, Space } from 'antd';
import {
  IncomingPurchaseOrder,
  updateIQCOrderStatus,
  saveProductionFormAnswers,
  ProductionFormCondition,
  useGetIQCPurchaseOrders,
  useGetIQCDeliveryOrders,
  GetProductionFormAnswerParams,
} from '@solar/data';
import { useForm, useFormState, useWatch } from 'react-hook-form';
import {
  FormFieldsWrapper,
  TextAreaField,
} from '@mezzanine-ui/react-hook-form';
import { Key, useCallback, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { isEmpty } from 'lodash';
import { useGetIQCFormsQueryConditions } from './use-get-iqc-forms';

const defaultValues = {
  qcNote: '',
  qcForms: {},
};

interface FormValues {
  qcNote: string;
  qcForms: Record<string, Record<string, string>>;
}

export function IncomingQualityControlModal() {
  const [saving, setSaving] = useState(false);
  const [executing, setExecuting] = useState(false);
  const [activeKey, setActiveKey] = useState<Key | undefined>(undefined);

  const { mutateGetIQCPurchaseOrders } = useGetIQCPurchaseOrders({
    statusIds: [1, 2, 3, 4],
  });

  const { mutateGetIQCDeliveryOrders } = useGetIQCDeliveryOrders({
    statusIds: [1, 2, 3, 4],
  });

  const { open, data, closeModal } = useTargetModal<IncomingPurchaseOrder>(
    Modals.QUALITY_CONTROL
  );

  const materials = useMemo(() => {
    // delivery order
    if (data?.iqcOrderItems) {
      return data?.iqcOrderItems?.reduce<string[]>((result, item) => {
        const currentMaterials =
          item?.sapBatch?.subBatches
            ?.filter((batch) => !!batch?.id)
            ?.map((subBatch) =>
              [item?.materialId, item?.sapBatchId, subBatch?.id].join('_')
            ) ?? [];

        return result.concat(currentMaterials);
      }, []);
    }

    // purchase order
    if (
      !data?.materialId ||
      !data?.sapBatchId ||
      !((data?.sapBatch?.subBatches?.length ?? -1) > 0)
    )
      return [];

    return (
      data?.sapBatch?.subBatches
        ?.filter((batch) => !!batch?.id)
        ?.map((subBatch) =>
          [data?.materialId, data?.sapBatchId, subBatch?.id].join('_')
        ) ?? []
    );
  }, [data]);

  const {
    productionFormQueryConditions,
    iqcVersionOptionsMap,
    iqcVersionLoading,
    getIQCVersionSelectValue,
    onIQCVersionChange,
  } = useGetIQCFormsQueryConditions(materials);

  // const productionFormQueryConditions = useMemo(() => {
  //   const materialIdSet = new Set<string>(
  //     materials.map((material) => material?.split('_')?.[0])
  //   );

  //   return Array.from(materialIdSet).map<ProductionFormCondition>(
  //     (materialId) => ({
  //       materialId,
  //       templateTypes: ['IQC'],
  //     })
  //   );
  // }, [materials]);

  const {
    formsMap,
    formsLoading,
    resetFormsMap,
    getFormSchema,
    isFormRendered,
    onFormRender,
  } = useGetProductionForms(productionFormQueryConditions);

  const resolver = useMemo(() => {
    return yupResolver(
      yup.object().shape({
        qcNote: yup.string(),
        qcForms: yup.object().shape(
          materials.reduce((result, material) => {
            const targetForms = formsMap?.get(material?.split('_')?.[0]);
            return targetForms
              ? Object.assign({}, result, {
                  [material]: getFormSchema(targetForms),
                })
              : result;
          }, {})
        ),
      })
    );
  }, [formsMap, materials]);

  const methods = useForm<FormValues>({ defaultValues, resolver });

  const productionFormAnswersQueryConditions = useMemo<
    GetProductionFormAnswerParams[]
  >(() => {
    return materials.map((material) => {
      const [materialId, materialSapBatchId, materialSubBatchId] =
        material?.split('_') ?? [];
      return {
        templateTypes: ['IQC'],
        materialId,
        materialSapBatchId,
        materialSubBatchId,
      };
    });
  }, [materials]);

  const { formAnswersLoading, resetFormAnswersMap, compareFormAnswersFromMap } =
    useGetProductionFormsAnswers({
      methods,
      formsMap,
      registerName: 'qcForms',
      queryConditions: productionFormAnswersQueryConditions,
      isFormRendered,
    });

  useEffect(() => {
    if (open && materials?.length > 0) {
      setActiveKey(materials[0]);

      methods.setValue('qcNote', data?.qcNote ?? '');
    }
  }, [open, methods, materials]);

  const saveQCFormAnswers = useCallback(
    async (activeKey?: string, needToValidate?: boolean) => {
      try {
        setSaving(true);

        const isValid = await methods.trigger(
          activeKey ? `qcForms.${activeKey}` : 'qcForms'
        );

        if (needToValidate && !isValid) {
          Message.error('欄位填寫錯誤，請重新檢查');
          return;
        }

        const keys = activeKey ? [activeKey] : materials;

        for (const key of keys) {
          try {
            const values = compareFormAnswersFromMap(key);
            const newAnswers = Object.entries(values).map(
              ([productionFormId, answer]) => ({
                productionFormId,
                answer,
                picked: false,
              })
            );

            if (!newAnswers?.length) {
              Message.warning(`${key} 無答案變更`);
              continue;
            }

            const [materialId, materialSapBatchId, materialSubBatchId] =
              key.split('_');

            await saveProductionFormAnswers({
              materialId,
              materialSapBatchId,
              materialSubBatchId,
              upsertAnswers: [],
              newAnswers,
            });

            Message.success(`${key} 答案保存成功`);
          } catch (error) {
            console.error(error);
            throw error;
          }
        }

        resetFormAnswersMap(keys);
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        setSaving(false);
      }
    },
    [methods, materials, compareFormAnswersFromMap]
  );

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const closeIQCModal = useCallback(() => {
    setActiveKey(undefined);
    methods.reset(defaultValues);
    resetFormsMap();
    resetFormAnswersMap();
    closeModal();
    setConfirmModalOpen(false);
  }, []);

  const onClose = useCallback(() => {
    let hasChanged = false;
    for (const material of materials) {
      const values = compareFormAnswersFromMap(material);
      if (!isEmpty(values)) hasChanged = true;
    }

    if (hasChanged) {
      setConfirmModalOpen(true);
    } else {
      closeIQCModal();
    }
  }, [materials, compareFormAnswersFromMap, closeIQCModal]);

  const onAction = useCallback(
    async (updatedStatusId: number, action: string) => {
      try {
        setExecuting(true);

        const formState = methods.getValues();
        if (!data?.id) throw new Error('order id is required');

        await saveQCFormAnswers(undefined, true);

        await updateIQCOrderStatus(data?.id, {
          qcNote: formState?.qcNote,
          updatedStatusId,
        });

        await mutateGetIQCDeliveryOrders();
        await mutateGetIQCPurchaseOrders();

        Message.success(`${data?.id ?? ''}:${action}成功`);

        closeIQCModal();
      } catch (error) {
        console.error(error);
        Message.error(`${data?.id ?? ''}:${action}失敗`);
      } finally {
        setExecuting(false);
      }
    },
    [saveQCFormAnswers, methods, data, onClose]
  );

  const formState = useFormState({
    control: methods.control,
    name: 'qcForms',
  });

  const { hasInvalidError, hasOOSError } = useGetFormValidation({
    formState,
    registerName: 'qcForms',
    // errors: formState?.errors?.qcForms,
  });

  return (
    <Modal style={{ width: 1000 }} open={open} onClose={onClose}>
      <ModalHeader>檢驗</ModalHeader>
      <ModalBody>
        <FormFieldsWrapper methods={methods}>
          <Space direction="vertical" style={{ width: '100%' }} size="middle">
            <Tabs
              activeKey={activeKey}
              onChange={setActiveKey}
              style={{ height: '710px' }}
            >
              {materials.map((material) => (
                <TabPane
                  key={material}
                  tab={
                    <Tab>
                      <BadgeContainer>
                        <Badge>
                          {
                            Object.keys(
                              formState?.errors?.qcForms?.[material] ?? {}
                            ).length
                          }
                        </Badge>
                        {material}
                      </BadgeContainer>
                    </Tab>
                  }
                >
                  <div>
                    <Space>
                      <Typography variant="h4">iqc版次</Typography>
                      <Select
                        value={getIQCVersionSelectValue(
                          material?.split('_')?.[0]
                        )}
                        onChange={onIQCVersionChange(material?.split('_')?.[0])}
                      >
                        {(
                          iqcVersionOptionsMap?.get(
                            material?.split('_')?.[0]
                          ) ?? []
                        )?.map((option) => (
                          <Option value={option.id}>{option.name}</Option>
                        ))}
                      </Select>
                    </Space>
                    <ProductionForms
                      registerName={`qcForms.${material}`}
                      loading={formsLoading || formAnswersLoading}
                      forms={formsMap?.get(material?.split('_')?.[0])}
                      onFormRender={onFormRender}
                    />
                  </div>
                </TabPane>
              ))}
            </Tabs>

            <Row justify="end">
              <Button
                type="button"
                disabled={formAnswersLoading || formsLoading}
                loading={saving || executing}
                variant="outlined"
                onClick={() => saveQCFormAnswers(activeKey as string, false)}
              >
                暫存答案
              </Button>
            </Row>
            <TextAreaField fullWidth registerName="qcNote" label="備註" />
          </Space>
          <Modal open={confirmModalOpen} hideCloseIcon>
            <ModalHeader>
              <Typography variant="h5">
                關閉將遺失填寫答案，確認是否關閉？
              </Typography>
            </ModalHeader>
            <ModalFooter style={{ justifyContent: 'flex-end' }}>
              <Space>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => setConfirmModalOpen(false)}
                >
                  取消
                </Button>
                <Button
                  danger
                  type="button"
                  variant="contained"
                  onClick={closeIQCModal}
                >
                  確認
                </Button>
              </Space>
            </ModalFooter>
          </Modal>
        </FormFieldsWrapper>
      </ModalBody>
      <ModalFooter>
        <Row style={{ width: '100%' }} justify="end" gutter={[12, 12]}>
          <Col>
            <Button
              type="button"
              loading={executing}
              variant="outlined"
              disabled={formAnswersLoading || formsLoading || hasInvalidError}
              onClick={() => onAction(4, '轉交需求單位確認')}
            >
              轉交需求單位確認
            </Button>
          </Col>
          {/* <Col>
            <Button
              danger
              type="button"
              loading={executing}
              variant="contained"
              onClick={() => onAction(3)}
            >
              不通過
            </Button>
          </Col> */}
          <Col>
            <Button
              type="button"
              loading={executing}
              variant="contained"
              disabled={
                formAnswersLoading ||
                formsLoading ||
                hasInvalidError ||
                hasOOSError
              }
              onClick={() => onAction(2, '通過')}
            >
              通過
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
}
