import { TableColumn } from '@mezzanine-ui/core/table';
import Decimal from 'decimal.js';
import { useCallback, useMemo } from 'react';
import { typesMap } from './InventoryTypeField';
import { Button } from '@mezzanine-ui/react';
import { groupBy } from 'lodash';
import { Space } from 'antd';

export function useInventoryCountColumns({
  inventoryTakingData,
  editable,
  openEditModal,
  confirming,
  confirmInventoryTaking,
}: {
  inventoryTakingData: any;
  editable: boolean;
  openEditModal?: (item: any) => void;
  confirming?: boolean;
  confirmInventoryTaking?: () => void;
}) {
  const getGroupKey = useCallback(
    (item: any) =>
      [item?.materialId, item?.materialSapBatchId, item?.zoneId].join('_'),
    []
  );

  const groupByData = useMemo(
    () =>
      groupBy(
        inventoryTakingData?.map((item: any) =>
          Object.assign(
            {
              groupKey: getGroupKey(item),
            },
            item
          )
        ) ?? [],
        'groupKey'
      ),
    [getGroupKey, inventoryTakingData]
  );

  const getSumValueByKey = useCallback(
    (groupKey: string, key: string | ((item: any) => Decimal)) => {
      const items = groupByData[groupKey];
      return items
        ?.reduce((acc: Decimal, item: any) => {
          switch (typeof key) {
            case 'function':
              return acc.add(key?.(item) ?? 0);
            case 'string':
              return acc.add(item?.[key] ?? 0);
          }
        }, new Decimal(0))
        .toNumber();
    },
    [groupByData]
  );

  return useMemo<TableColumn<any>[]>(
    () => [
      ...(editable
        ? [
            {
              width: 250,
              title: '操作',
              render: (item: any) => (
                <Space>
                  <Button
                    type="button"
                    loading={confirming}
                    variant="contained"
                    onClick={confirmInventoryTaking}
                  >
                    確認盤點
                  </Button>
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() => openEditModal?.(item)}
                  >
                    更新盤點
                  </Button>
                </Space>
              ),
            },
          ]
        : []),
      { width: 100, title: '序號', dataIndex: 'serial' },
      { title: '品名', dataIndex: 'materialDescription' },
      { title: '料號', dataIndex: 'materialId' },
      { width: 150, title: '母批號', dataIndex: 'materialSapBatchId' },
      { width: 150, title: '倉位', dataIndex: 'zoneId' },
      { width: 120, title: '狀態', render: (item) => typesMap.get(item?.type) },
      { width: 200, title: '銷售訂單號', dataIndex: 'salesOrderId' },
      { width: 100, title: '項次', dataIndex: 'salesOrderLine' },
      { width: 200, title: '供應商代碼', dataIndex: 'outsourceSupplierId' },
      { width: 200, title: '客戶代碼', dataIndex: 'customerId' },
      {
        width: 200,
        title: '倉位總量',
        render: (item) => {
          if (!isNaN(item?.secondaryUnitRatio ?? NaN)) {
            return `${new Decimal(item?.sapQuantity ?? 0)
              .div(item?.secondaryUnitRatio)
              .toString()}（${item?.secondaryUnit}）`;
          }
          return `${item?.sapQuantity?.toString()}（${
            item?.materialStockUnit
          }）`;
        },
      },
      // {
      //   width: 80,
      //   title: '單位',
      //   render: (item) => {
      //     if (!isNaN(item?.secondaryUnitRatio ?? NaN)) {
      //       return item?.secondaryUnit;
      //     }
      //     return item?.materialStockUnit;
      //   },
      // },
      { width: 100, title: '子批號', dataIndex: 'materialSubBatchId' },
      { width: 180, title: '儲位', dataIndex: 'shelfId' },
      {
        width: 200,
        title: '儲位量',
        render: (item) => {
          if (!isNaN(item?.secondaryUnitRatio ?? NaN)) {
            return `${item?.secondaryUnitQuantity?.toString()}（${
              item?.secondaryUnit
            }）`;
          }
          return `${item?.quantity?.toString()}（${item?.materialStockUnit}）`;
        },
      },
      // {
      //   width: 80,
      //   title: '單位',
      //   render: (item) => {
      //     if (!isNaN(item?.secondaryUnitRatio ?? NaN)) {
      //       return item?.secondaryUnit;
      //     }
      //     return item?.materialStockUnit;
      //   },
      // },
      {
        width: 200,
        title: '實際重量',
        render: (item) => `${item?.filledActualWeight ?? '-'}（G）`,
      },
      // { width: 80, title: '單位', render: () => 'G' },
      {
        width: 200,
        title: '現場盤點量',
        render: (item) =>
          `${item?.filledQuantity ?? '-'}（${item?.filledUnit ?? '-'}）`,
      },
      // { width: 80, title: '單位', dataIndex: 'filledUnit' },
      {
        width: 200,
        title: '現場實秤',
        render: (item) => `${item?.filledActualWeight ?? '-'}（G）`,
      },
      // { width: 80, title: '單位', render: () => 'G' },
      {
        width: 200,
        title: '與SAP差異數量',
        render: (item) => {
          const groupKey = getGroupKey(item);
          const quantity = getSumValueByKey(
            groupKey,
            !isNaN(item?.secondaryUnitRatio ?? NaN)
              ? 'secondaryUnitQuantity'
              : 'quantity'
          );
          const sapQuantity = getSumValueByKey(groupKey, (item) => {
            return !isNaN(item?.secondaryUnitRatio ?? NaN)
              ? new Decimal(item?.sapQuantity ?? 0).div(
                  item?.secondaryUnitRatio
                )
              : new Decimal(item?.sapQuantity ?? 0);
          });
          const unit = !isNaN(item?.secondaryUnitRatio ?? NaN)
            ? item?.secondaryUnit
            : item?.materialStockUnit;

          return `${new Decimal(quantity ?? 0)
            .sub(sapQuantity ?? 0)
            .toString()}（${unit ?? '-'}）`;
        },
      },
      {
        width: 200,
        title: '與現場差異數量',
        render: (item) => {
          if (!item?.filledQuantity) {
            return '-';
          }
          const groupKey = getGroupKey(item);
          const quantity = getSumValueByKey(
            groupKey,
            !isNaN(item?.secondaryUnitRatio ?? NaN)
              ? 'secondaryUnitQuantity'
              : 'quantity'
          );
          const filledQuantity = getSumValueByKey(groupKey, 'filledQuantity');
          const unit = !isNaN(item?.secondaryUnitRatio ?? NaN)
            ? item?.secondaryUnit
            : item?.materialStockUnit;

          return `${new Decimal(quantity ?? 0)
            .sub(filledQuantity ?? 0)
            .toString()}（${item?.filledUnit ?? unit ?? '-'}）`;
        },
      },
      {
        width: 200,
        title: '差異重量',
        render: (item) => {
          if (!item?.filledActualWeight) {
            return '-';
          }
          const groupKey = getGroupKey(item);
          const actualWeight = getSumValueByKey(
            groupKey,
            'actualMaterialWeightUnitQuantity'
          );
          const filledActualWeight = getSumValueByKey(
            groupKey,
            'filledActualWeight'
          );
          return `${new Decimal(actualWeight ?? 0)
            .sub(filledActualWeight ?? 0)
            .toString()}（G）`;
        },
      },
    ],
    [editable, getGroupKey, getSumValueByKey, openEditModal]
  );
}
