import { Button, Message, SelectValue, Typography } from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  FormFieldsWrapper,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  API_NAMESPACE,
  request,
  useBatchIdsByMaterialId,
  useBatchInfo,
  useBatchInfoByLoaderId,
  useMaterialsInInventory,
} from '@solar/data';
import { RowSection } from '@solar/templates';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';

import { useAutoAnimate } from '@formkit/auto-animate/react';
import { TextAreaField } from '@mezzanine-ui/react-hook-form';
import debounce from 'lodash/debounce';
import { useSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { updateSubBatchRemark } from '../utils/update-sub-batch-remark';
import { LabelPreview } from './LabelPreview';
import { MaterialBatchLabelPdf } from './MaterialBatchLabelPdf';
import classes from './batch-sticker-panel.module.scss';
import { stickerInitValue, stockTypes } from './const';
import {
  BatchStickerFormProps,
  MaterialLabelInfoProps,
  SingleStickerProps,
} from './types';
import { BarcodeScanInput } from '../../Material/MaterialShift/BarcodeScanInput';
import { AnalyzeBatchLabelPdf } from './AnalyzeBatchLabelPdf';

export const AnalyzeStickerPanel = () => {
  const printRef = useRef<HTMLDivElement>(null);
  const [parent] = useAutoAnimate();

  const [searchParams] = useSearchParams();
  const directedFrom = searchParams.get('from');
  const isFromMaterialSupplyToWorkOrder =
    directedFrom === 'MaterialSupplyToWorkOrder';
  const isFromWorkOrderManagement = directedFrom === 'WorkOrderManagement';

  const stickersContainerRef = useRef<HTMLDivElement>(null);

  const scrollToTop = useCallback(() => {
    if (stickersContainerRef.current) {
      stickersContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  const { data: materials, setParams: mutateMaterialsParams } =
    useMaterialsInInventory();

  const methods = useForm<BatchStickerFormProps>({
    defaultValues: {
      stickers: [stickerInitValue],
      focusedIndex: 0,
    },
  });

  const stickersArrayMethods = useFieldArray({
    control: methods.control,
    name: 'stickers',
  });

  const focusedIndex = useWatch({
    control: methods.control,
    name: 'focusedIndex',
  });

  const watchedMaterial = useWatch({
    control: methods.control,
    name: `stickers.${focusedIndex}.material`,
  });

  const { data: batches, setParams: mutateBatchIdsByMaterialParams } =
    useBatchIdsByMaterialId({
      materialId: watchedMaterial?.id ?? null,
    });

  const {
    data: selectedBatchesInfoByLoaderId,
    setParams: setBatchInfoParamsByLoaderId,
  } = useBatchInfoByLoaderId();

  const watchedMaterialDescription = useWatch({
    control: methods.control,
    name: `stickers.${focusedIndex}.materialDescription`,
  });

  const watchedBatch = useWatch({
    control: methods.control,
    name: `stickers.${focusedIndex}.batch`,
  });

  const triggerRerenderBoolean = useWatch({
    control: methods.control,
    name: `stickers.${focusedIndex}.triggerRerenderBoolean`,
  });

  const {
    data: selectedBatchesInfo,
    setParams: mutateBatchInfoParams,
    mutate,
  } = useBatchInfo();

  // const watchedDate = useWatch({
  //   control: methods.control,
  //   name: `stickers.${focusedIndex}.date`,
  // });

  const watchedQuantity = useWatch({
    control: methods.control,
    name: `stickers.${focusedIndex}.quantity`,
  });

  const watchedStaff = useWatch({
    control: methods.control,
    name: `stickers.${focusedIndex}.staff`,
  });

  // const watchedRemark = useWatch({
  //   control: methods.control,
  //   name: 'remark',
  // });

  // const watchedBatchStoredLoaderRecordId = useWatch({
  //   control: methods.control,
  //   name: 'batchStoredLoaderRecordId',
  // });

  const autoCompleteHandler = useCallback(
    (event: SelectValue, fieldName: 'materialDescription') => {
      const targetId = event?.id;

      if (!targetId) return;
      if (!materials?.length) return;

      const relatedMaterials: {
        [id: string]: (typeof materials)[number];
      } = materials.reduce(
        (prev, material) => ({ ...prev, [material.materialId]: material }),
        {}
      );

      if (fieldName === 'materialDescription') {
        methods.setValue(`stickers.${0}.${fieldName}`, {
          id: relatedMaterials[targetId].materialId,
          name: relatedMaterials[targetId].materialDescription,
        });
      }
    },
    [materials, methods]
  );

  const [updatingRemark, setUpdatingRemark] = useState(false);
  // 處理備註欄位 debounce request
  const debounceInputHandler = useRef(
    debounce(async (e: ChangeEvent<HTMLTextAreaElement>) => {
      try {
        setUpdatingRemark(true);

        const materialId = methods.getValues(`stickers.${0}.material.id`);
        const batch = methods.getValues('stickers.0.batch');
        const sapBatchId = batch?.sapBatchId;
        const subBatchId = batch?.subBatchId;

        if (materialId && sapBatchId && subBatchId) {
          await updateSubBatchRemark(
            {
              materialId,
              sapBatchId: sapBatchId,
              subBatchId: subBatchId,
            },
            { remark: e.target.value ?? '' }
          );

          await mutate();
        }
      } catch (err) {
      } finally {
        setUpdatingRemark(false);
      }
    }, 600)
  ).current;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    //html, body { height: auto !important;} 避免 page break（多一個空白頁)
    pageStyle: `
      @media print {
        html, body {
          height: auto !important;    
        }

        .preview,
        .pdf-wrapper {
          border: 0px !important;
          box-shadow: none !important;
        }

        .trash-icon {
          display: none !important;
        }

        @page {
          size: 100mm 60mm !important;
          margin: 0 !important;
        }
      }
      `,
  });

  useEffect(() => {
    if (isFromMaterialSupplyToWorkOrder || isFromWorkOrderManagement) {
      const batchInfos = searchParams.getAll('loaderMaterialBatchId');
      if (batchInfos?.length) {
        const stickers: SingleStickerProps[] = [];
        request(`/warehouses/material-label-info`, {
          responseParser: (res) => res.json(),
          namespace: API_NAMESPACE.MATERIALS,
          params: {
            batchStoredLoaderRecordIds: batchInfos,
          },
        }).then((res: MaterialLabelInfoProps[]) => {
          res.forEach((row) => {
            stickers.push({
              material: { id: row.materialId, name: row.materialId },
              materialDescription: {
                id: row.materialId,
                name: row?.materialDescription ?? '',
              },
              batch: {
                id: `${row?.materialSubBatchId}-${row.materialSapBatchId}`,
                name: `${row?.materialSubBatchId}-${row.materialSapBatchId}`,
                sapBatchId: '',
                subBatchId: '',
              },

              quantity: row?.availableMaterialStockUnitQuantity ?? null,
              remark: row?.materialSubBatchRemark ?? null,
              batchStoredLoaderRecordId: row?.batchStoredLoaderRecordId!,
              loader: { id: row.loaderId, name: row.loaderId }, //row?.lo
              type: { id: row.type, name: row?.type },
              staff: '',
              searchBarcode: null,
              triggerRerenderBoolean: false,
            });
          });

          methods.setValue('stickers', stickers);
        });
      }
    }
  }, []);

  return (
    <div className={classes['host']}>
      <FormFieldsWrapper methods={methods} className={classes['form-wrapper']}>
        <div>
          <Button type="button" variant="outlined" onClick={handlePrint}>
            列印
          </Button>
          <Button
            type="button"
            onClick={() => {
              methods.setValue('focusedIndex', 0);
              stickersArrayMethods.insert(0, stickerInitValue);
            }}
          >
            新增
          </Button>
        </div>

        <div className={classes['barcode-scan']}>
          <BarcodeScanInput
            inputStyle={classes['barcode-scan-input']}
            otherOnKeyDownAction={(value) => {
              if (!value) return;

              if (
                methods.getValues(`stickers.${focusedIndex}.material`) === null
              ) {
                methods.setValue(
                  `stickers.${focusedIndex}.searchBarcode`,
                  value
                );
                return;
              }

              stickersArrayMethods.insert(0, {
                ...stickerInitValue,
                searchBarcode: value,
              });
              methods.setValue('focusedIndex', 0);

              scrollToTop();
            }}
          />
        </div>
        <RowSection label="料號：" childFullWidth required>
          <AutoCompleteField
            key={String(focusedIndex)}
            registerName={`stickers.${focusedIndex}.material`}
            options={(materials ?? [])?.map((material) => ({
              id: material.materialId,
              name: material.materialId,
            }))}
            onChange={(event) => {
              autoCompleteHandler(event, 'materialDescription');
              methods.setValue(`stickers.${focusedIndex}.searchBarcode`, null);
            }}
            onSearch={(searchTerm) => mutateMaterialsParams({ searchTerm })}
          />
        </RowSection>
        <RowSection label="品名：">
          <Typography>
            {methods.watch(`stickers.${focusedIndex}.materialDescription.name`)}
          </Typography>
        </RowSection>
        <RowSection label="批號：" childFullWidth required>
          <AutoCompleteField
            key={String(focusedIndex)}
            disabled={!methods.watch(`stickers.${focusedIndex}.material`)}
            registerName={`stickers.${focusedIndex}.batch`}
            onInput={({ target }) => {
              mutateBatchIdsByMaterialParams((prev) => ({
                ...prev,
                searchTerm: (target as HTMLInputElement).value,
              }));
            }}
            options={
              batches.map((row) => ({ id: row.batchId, name: row.batchId })) ??
              []
            }
            onChange={(select) => {
              if (watchedMaterial) {
                mutateBatchInfoParams({
                  materialBatchId: select.id,
                  materialId: watchedMaterial?.id,
                });
              }

              const selectedBatch = batches.find(
                (row) => row?.batchId === select.id
              );
              if (selectedBatch) {
                methods.setValue(
                  `stickers.${focusedIndex}.batch.sapBatchId`,
                  selectedBatch?.sapBatchId
                );
                methods.setValue(
                  `stickers.${focusedIndex}.batch.subBatchId`,
                  selectedBatch?.subBatchId
                );
              }
            }}
          />
        </RowSection>
        <RowSection label="載具：" required childFullWidth>
          <SelectField
            key={JSON.stringify(
              methods.watch(`stickers.${focusedIndex}.batch`)
            )}
            disabled={!methods.watch(`stickers.${focusedIndex}.batch`)}
            registerName={`stickers.${focusedIndex}.loader`}
            options={selectedBatchesInfo.map((row, index) => ({
              id: row.loaderId,
              name: row.loaderId,
            }))}
            onChange={(selectedLoader) => {
              setBatchInfoParamsByLoaderId({
                materialId: watchedMaterial?.id,
                materialBatchId: watchedBatch?.id,
                loaderId: selectedLoader.id,
              });
            }}
          />
        </RowSection>
        <RowSection label="庫存類型：">
          <SelectField
            key={JSON.stringify(
              methods.watch(`stickers.${focusedIndex}.loader`)
            )}
            disabled={!methods.watch(`stickers.${focusedIndex}.loader`)}
            registerName={`stickers.${focusedIndex}.type`}
            options={selectedBatchesInfoByLoaderId.map((row, index) => ({
              id: String(index),
              name: stockTypes[row.type as keyof typeof stockTypes],
            }))}
            onChange={(selectedType) => {
              const batchInfo =
                selectedBatchesInfoByLoaderId[Number(selectedType.id)];

              methods.setValue(
                `stickers.${focusedIndex}.batchStoredLoaderRecordId`,
                batchInfo.batchStoredLoaderRecordId
              );
              methods.setValue(
                `stickers.${focusedIndex}.remark`,
                batchInfo.subBatchRemark
              );
            }}
          />
        </RowSection>
        {/* SOL-1711 feature change */}
        {/* <RowSection label="數量：">
          <Typography key={String(focusedIndex)}>
            {watchedQuantity ?? ''}
          </Typography>
        </RowSection>*/}
        <RowSection label="入庫人：">
          <Typography key={String(focusedIndex)}>
            {watchedStaff ?? ''}
          </Typography>
        </RowSection>
        <RowSection label="備註：" labelAlign="top" childFullWidth>
          <TextAreaField
            key={String(focusedIndex)}
            registerName={`stickers.${focusedIndex}.remark`}
          />
          <Button
            style={{ marginTop: '12px' }}
            variant="contained"
            type="button"
            onClick={async () => {
              try {
                const materialId = methods.getValues(
                  `stickers.${focusedIndex}.material.id`
                );
                const batch = methods.getValues(
                  `stickers.${focusedIndex}.batch`
                );
                const sapBatchId = batch?.sapBatchId;
                const subBatchId = batch?.subBatchId;

                if (!sapBatchId || !subBatchId) throw '';

                const updateResult = await updateSubBatchRemark(
                  {
                    materialId,
                    sapBatchId: sapBatchId,
                    subBatchId: subBatchId,
                  },
                  {
                    remark:
                      methods.getValues(`stickers.${focusedIndex}.remark`) ??
                      '',
                  }
                );

                if (updateResult.id) {
                  Message.success('儲存成功');
                  mutate();

                  // setTriggerBoolean((prev) => !prev);
                  methods.setValue(
                    `stickers.${focusedIndex}.triggerRerenderBoolean`,
                    !triggerRerenderBoolean
                  );
                } else {
                  throw new Error();
                }
              } catch {
                Message.error('儲存失敗');
              }
            }}
          >
            儲存備註
          </Button>
        </RowSection>
      </FormFieldsWrapper>
      <div className={classes['stickers-wrapper']} ref={stickersContainerRef}>
        <FormFieldsWrapper methods={methods}>
          <div ref={printRef}>
            <div ref={parent}>
              {stickersArrayMethods.fields?.map((row, index) => (
                <LabelPreview key={row.id}>
                  <AnalyzeBatchLabelPdf
                    key={`${methods.watch(
                      `stickers.${index}.triggerRerenderBoolean`
                    )}-${row.id}`} // -${dateChanged}
                    stickerIndex={index}
                    datamatrixCanvasId={`${row.id}-MATERIAL_BATCH_BARCODE`}
                    barcodeCanvasId={`${row.id}-ANALYZE_BATCH_BARCODE`}
                    stickersArrayMethods={stickersArrayMethods}
                  />
                </LabelPreview>
              ))}
            </div>
          </div>
        </FormFieldsWrapper>
      </div>
    </div>
  );
};
