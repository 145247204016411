import useSWR from 'swr';
import { API_NAMESPACE, request } from '../request';
import { PageInfo } from '../pagination';
import { useState } from 'react';
import {
  DeliveryOrder,
  DeliveryOrderDetail,
  GetDeliveryOrdersParams,
} from './delivery-order.type';
import { TableDataSourceWithID } from '@mezzanine-ui/core/table';

const namespace = API_NAMESPACE.MATERIALS;

export function useGetDeliveryOrders(defaultParams?: GetDeliveryOrdersParams) {
  const [params, setParams] = useState<GetDeliveryOrdersParams | undefined>(
    defaultParams
  );
  const { data, error, mutate, isValidating } = useSWR<{
    records: DeliveryOrder[];
    pageInfo: PageInfo;
  }>(['/warehouses/production-delivery-orders', { params, namespace }]);

  return {
    deliveryOrders: data?.records ?? [],
    error,
    pageInfo: data?.pageInfo,
    isLoading: !data && !error && isValidating,
    refetchGetDeliveryOrders: setParams,
    mutateGetDeliveryOrders: mutate,
  };
}

export function useGetDeliveryOrderDetail(id?: string) {
  const { data, error, isValidating } = useSWR<DeliveryOrderDetail>(
    id ? [`/warehouses/production-delivery-orders/${id}`, { namespace }] : null
  );

  return {
    deliveryOrder: data,
    isLoading: !data && !error && isValidating,
  };
}

export interface VirtualDeliveryOrder extends DeliveryOrderDetail {
  bindings: VirtualDeliveryOrderBindingItem[];
}

interface VirtualDeliveryOrderBindingItemRelation
  extends TableDataSourceWithID {
  deliveryOrderId: string;
  deliveryOrderLine: string;
  relatedOrderId: string;
  relatedOrderDetailId: string;
  relatedOrderDetailMaterialId: string;
}

export interface VirtualDeliveryOrderBindingItem extends TableDataSourceWithID {
  deliveryOrderId: string;
  deliveryOrderLine: string;
  relatedDeliveryOrderId: string;
  relatedDeliveryOrderLine: string;
  relatedDeliveryOrderMaterialId: string;
  relatedInventoryPurchaseOrderId: any;
  relatedInventoryPurchaseOrderItemId: any;
  relatedInventoryPurchaseOrderMaterialId: any;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  relations: VirtualDeliveryOrderBindingItemRelation[];
}

export function useGetVirtualDeliveryOrderDetail(id?: string) {
  const { data, error } = useSWR<VirtualDeliveryOrder>(
    id
      ? [`/warehouses/production-virtual-delivery-orders/${id}`, { namespace }]
      : null
  );

  return {
    deliveryOrder: data,
  };
}

export function shipDeliveryOrder(payload: any) {
  return request('/warehouses/production-delivery-orders/ship', {
    method: 'post',
    namespace,
    body: JSON.stringify(payload),
  });
}

export function useGetProductionVirtualDeliveryOrders(
  defaultParams?: GetDeliveryOrdersParams
) {
  const [params, setParams] = useState<
    Partial<GetDeliveryOrdersParams> | undefined
  >(defaultParams);
  const { data, mutate, error, isValidating } = useSWR(
    params
      ? [
          '/warehouses/production-virtual-delivery-orders',
          { params, namespace },
        ]
      : null
  );

  return {
    productionVirtualDeliveryOrders: data?.records || [],
    pageInfo: data?.pageInfo,
    isLoading: !error && !data && isValidating,
    refetchGetProductionVirtualDeliveryOrders: setParams,
    mutateGetProductionVirtualDeliveryOrders: mutate,
  };
}
