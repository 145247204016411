import { TableDataSourceWithID } from '@mezzanine-ui/core/table';
import { SelectValue as MznSelectValue } from '@mezzanine-ui/react';
import { API_NAMESPACE } from '@solar/data';
import { useState } from 'react';
import useSWR from 'swr';

type SelectValue = Omit<MznSelectValue, 'id'> & TableDataSourceWithID;

interface GroupCode extends SelectValue {
  code: string;
}

export function useGetMaterialGroupCodes() {
  const { data, error, mutate, isValidating } = useSWR<GroupCode[]>([
    '/material-properties/groups',
    {
      namespace: API_NAMESPACE.MATERIALS,
    },
  ]);

  return {
    groupCodes: data,
    error,
    isLoading: !data && !error && isValidating,
    mutateGroupCodes: mutate,
  };
}

interface ClassCode extends TableDataSourceWithID {
  id: string;
  groupId: string;
  bigCode: string;
  bigCodeName: string;
  mediumCode: string;
  mediumCodeName: string;
  smallCode: string;
  smallCodeName: string;
}

interface GetMaterialClassCodesParams {
  groupCode: string;
  bigClassCode?: string;
  mediumClassCode?: string;
  smallClassCode?: string;
}

export function useGetMaterialClassCodes() {
  // const [params, setParams] = useState(defaultParams);
  const { data, error, mutate, isValidating } = useSWR<ClassCode[]>([
    '/material-properties/class-codes',
    {
      namespace: API_NAMESPACE.MATERIALS,
    },
  ]);

  return {
    classCodes: data,
    error,
    isLoading: !data && !error && isValidating,
    mutateClassCodes: mutate,
  };
}

export interface FeatureCode extends SelectValue {
  code: string;
  materialGroup: GroupCode;
}

interface GetMaterialFeatureCodesParams {
  groupCode: string;
}

export function useGetMaterialFeatureCodes() {
  const { data, error, mutate, isValidating } = useSWR<FeatureCode[]>([
    '/material-properties/feature-codes',
    {
      namespace: API_NAMESPACE.MATERIALS,
    },
  ]);

  return {
    featureCodes: data,
    error,
    isLoading: !data && !error && isValidating,
    mutateFeatureCodes: mutate,
  };
}

export function useGetMaterialSourceCodes() {
  const { data, error, mutate, isValidating } = useSWR<SelectValue[]>([
    '/material-properties/source-codes',
    {
      namespace: API_NAMESPACE.MATERIALS,
    },
  ]);

  return {
    sourceCodes: data,
    error,
    isLoading: !data && !error && isValidating,
    mutateSourceCodes: mutate,
  };
}

interface MachiningCode extends SelectValue {
  code: string;
}

export function useGetMaterialMachiningCodes() {
  const { data, error, mutate, isValidating } = useSWR<MachiningCode[]>([
    '/material-properties/machining-codes',
    {
      namespace: API_NAMESPACE.MATERIALS,
    },
  ]);

  return {
    machiningCodes: data,
    error,
    isLoading: !data && !error && isValidating,
    mutateMachiningCodes: mutate,
  };
}

export function useGetMaterialSamplingCodes() {
  const { data, error, mutate, isValidating } = useSWR<SelectValue[]>([
    '/material-properties/sampling-codes',
    {
      namespace: API_NAMESPACE.MATERIALS,
    },
  ]);

  return {
    samplingCodes: data,
    error,
    isLoading: !data && !error && isValidating,
    mutateSamplingCodes: mutate,
  };
}

export function useGetMaterialWasteCodes() {
  const { data, error, mutate, isValidating } = useSWR<SelectValue[]>([
    '/material-properties/waste-codes',
    {
      namespace: API_NAMESPACE.MATERIALS,
    },
  ]);

  return {
    wasteCodes: data,
    error,
    isLoading: !data && !error && isValidating,
    mutateWasteCodes: mutate,
  };
}
