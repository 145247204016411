import useSWR from 'swr';
import { APIResponse, FCCDrawing } from './types';
import { API_NAMESPACE, request } from '../request';
import { useState } from 'react';

const namespace = API_NAMESPACE.DRAWINGS;

export function useFCCDrawings({ params }: { params: object }) {
  const { data, error, mutate } = useSWR<APIResponse<FCCDrawing[]>>(
    params ? ['/drawings/fcc', { params, namespace }] : null
  );

  const drawings = data?.data || [];

  return {
    drawings,
    error,
    mutateFCCDrawing: mutate,
    isLoading: !data && !error,
    pageInfo: {
      page: data?.page ?? 1,
      size: data?.size ?? 10,
      count: data?.count ?? 0,
    },
  };
}

export function useFCCDrawingByNewDrawingCode({
  newDrawingCode,
}: {
  newDrawingCode?: string;
}) {
  const { data, error } = useSWR<APIResponse<FCCDrawing>>(
    newDrawingCode ? [`/drawings/fcc/${newDrawingCode}`, { namespace }] : null
  );

  const drawing = data?.data;

  return {
    drawing,
    error,
    isLoading: !data && !error,
    pageInfo: {
      page: data?.page ?? 1,
      size: data?.size ?? 10,
      count: data?.count ?? 0,
    },
  };
}

export async function createFCCDrawing(data: any) {
  const response = await request(`/drawings/fcc`, {
    method: 'post',
    body: JSON.stringify(data),
    responseParser: (res) => res,
    namespace,
  });
  const result = await response.json();
  return result;
}

export async function updateFCCDrawing({ id, data }: any) {
  const response = await request(`/drawings/fcc/${id}`, {
    method: 'put',
    body: JSON.stringify(data),
    responseParser: (res) => res,
    namespace,
  });
  const result = await response.json();
  return result;
}

export function useGetFCCParentOldDrawingCodes() {
  const [params, setParams] = useState<{
    searchTerm?: string;
    page?: number;
    size?: number;
  }>();
  const { data, error } = useSWR<APIResponse<string[]>>([
    '/drawings/fccParentOldDrawingCodes',
    { params, namespace },
  ]);
  return {
    fccParentOldDrawingCodes: data?.data ?? [],
    error,
    isLoading: !data && !error,
    refetchGetFCCParentOldDrawingCodes: setParams,
  };
}
