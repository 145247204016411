import styles from './work-report-bi.module.scss';

const powerBISrc =
  window.location.host === 'edges.solartech.cloud'
    ? 'https://app.powerbi.com/reportEmbed?reportId=8f50c94f-1c17-4248-a3c2-b76039cdd8ee&autoAuth=true&ctid=e3553a8e-c723-4b54-ac77-0bb3db8cf161'
    : 'https://app.powerbi.com/reportEmbed?reportId=37e5a420-2f1e-470a-9cb1-9a9fd7916817&autoAuth=true&ctid=e3553a8e-c723-4b54-ac77-0bb3db8cf161';

export function WorkReportBIPage() {
  return (
    <div className={styles.container}>
      <iframe
        title="WorkReportBI"
        width="1140"
        height="541.25"
        className={styles.iframe}
        src={powerBISrc}
        allowFullScreen
      ></iframe>
    </div>
  );
}
