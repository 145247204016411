import { Typography } from '@mezzanine-ui/react';
import styles from './HumanResourceChartPage.module.scss';
import { RCTree } from './RCTree/RCTree';

export function HumanResourceChartPage() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <Typography variant="h1">組織樹狀圖</Typography>
      </div>
      <RCTree />
    </div>
  );
}
