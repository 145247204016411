import { useState } from 'react';

type CloseModalOptions = {
  keepData?: boolean
}

export type UseModalControllerReturn<ModalDataType> = {
  open: boolean,
  data: ModalDataType | null,
  openModal: (data: ModalDataType) => void,
  closeModal: (options?: CloseModalOptions) => void,
}

export function useModalController<ModalDataType>() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<ModalDataType | null>(null);
  const openModal = (data?: ModalDataType) => {
    setData(data ?? null);
    setOpen(true);
  };
  const closeModal = (options?: CloseModalOptions) => {
    if (!options?.keepData) setData(null);
    setOpen(false);
  };
  return { open, data, openModal, closeModal };
}