import { TableColumn } from '@mezzanine-ui/core/table';
import { Button } from '@mezzanine-ui/react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { InventoryTableProps } from '../../types';

export function useTableColumn() {
  const navigate = useNavigate();
  const column: TableColumn<InventoryTableProps>[] = useMemo(
    () => [
      {
        title: '操作',
        render: () => {
          return (
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                navigate('test');
              }}
            >
              檢視
            </Button>
          );
        },
      },
      { title: '工廠', dataIndex: 'factory' },
      { title: '會計年度', dataIndex: 'fiscalYear' },
      { title: '盤點文件', dataIndex: 'takeInventoryOfTheFile' },
      { title: '建立日期', dataIndex: 'establishmentDate' },
      { title: '計畫盤點日期', dataIndex: 'plannedInventoryDates' },
    ],
    [navigate]
  );

  return column;
}
