import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { MiscPurchaseOrderOuterRecord } from './useMiscPurchaseOrders';

const namespace = API_NAMESPACE.MATERIALS;

// export type MiscPurchaseOrderResponse = {
//   id: string;
//   expectedCompletedAt: string;
//   supplierId: string;
//   supplierName: string;
//   staffName: string;
//   receivedState: `${InspectStatus}`;
//   returnedState: `${InspectStatus}`;
//   specId: string;
//   expectedMainMaterialUnitQuantity: number;
//   expectedSecondaryMaterialUnitQuantity: number;
//   receivedMainMaterialUnitQuantity: number;
//   receivedSecondaryMaterialUnitQuantity: number;
//   returnedMainMaterialUnitQuantity: number;
//   returnedSecondaryMaterialUnitQuantity: number;
//   materialId: string;
//   oldMaterialId: string;
//   materialDescription: string;
//   categoryName: string;
//   groupName: string;
// };
export function useMiscPurchaseOrder({
  orderId,
}: {
  orderId?: string | undefined;
}) {
  const { data, error } = useSWR<MiscPurchaseOrderOuterRecord>(
    orderId ? [`/misc-purchase-orders/${orderId}`, { namespace }] : null
  );

  return {
    orders: data,
    isLoading: !data && !error,
  };
}

export function useOutSourcePurchaseOrder({
  orderId,
}: {
  orderId?: string | undefined;
}) {
  const { data, error, mutate } = useSWR<MiscPurchaseOrderOuterRecord>(
    orderId
      ? [`/warehouses/outsource-purchase-orders/${orderId}`, { namespace }]
      : null
  );

  return {
    orders: data,
    isLoading: !data && !error,
    mutate,
  };
}

export function useFixedAssetPurchaseOrder({
  orderId,
}: {
  orderId?: string | undefined;
}) {
  const { data, error, mutate } = useSWR<MiscPurchaseOrderOuterRecord>(
    orderId ? [`/fixed-asset-purchase-orders/${orderId}`, { namespace }] : null
  );

  return {
    orders: data,
    isLoading: !data && !error,
    mutate,
  };
}

export function useOutSourcePurchaseOrderV2({
  orderId,
}: {
  orderId?: string | undefined;
}) {
  const { data, error, mutate } = useSWR<
    MiscPurchaseOrderOuterRecord & { stocks: any[] }
  >(
    orderId
      ? [`/warehouses/outsource-purchase-orders/v2/${orderId}`, { namespace }]
      : null
  );

  return {
    orders: data,
    isLoading: !data && !error,
    mutate,
  };
}
