import { BaseField, DateTimePickerField, FormFieldsWrapper, InputField, SelectField, TreeSelectField } from "@mezzanine-ui/react-hook-form";
import { Button, Icon, Input, MenuDivider, Modal, ModalBody, ModalFooter, ModalHeader, RadioGroup, SelectValue, Textarea, Typography } from "@mezzanine-ui/react";
import { useForm } from "react-hook-form";
import styles from './equipment-maintenance-modal.module.scss';
import { useEffect, useState } from "react";
import moment from "moment";
import { CreateRepairRequestLogPayload, RepairRequest, RepairRequestLog, RepairRequestPriorityOption, RepairRequestPriorityOptions, RepairRequestStatusOption, environment, getZoneDisplayName, repairRequestStatusOptions, useEquipment, useGetRepairRequestLogs, useHumanResourceMembers } from '@solar/data';
import { Upload } from "antd";
import { UploadIcon } from "@mezzanine-ui/icons";
import { Modals } from "./EquipmentMaintenanceModal.enum";
import { useTargetModal } from "@solar/templates";

type EditRepairRequestLogForm = {
  status: RepairRequestStatusOption;
  priority: RepairRequestPriorityOption;
  predict_fulfilled_at: RepairRequestLog['predictFulfilledAt'];
  fulfilled_at?: RepairRequestLog['fulfilledAt'];
  remark?: RepairRequestLog['remark'];
  internalInformation?: {
    men_count?: SelectValue;
    members?: SelectValue[];
    hours_count?: RepairRequestLog['hoursCount'];
    remark?: RepairRequestLog['internalRemark'];
  };
  externalInformation?: {
    supplier?: string;
    send_out_at?: RepairRequestLog['sendOutAt'];
    fixed_at?: RepairRequestLog['fixedAt'];
    files?: any[];
  };
};

type EditEquipmentMaintenanceModalProps = {
  onSubmit: (data: CreateRepairRequestLogPayload) => void,
}

export function EditEquipmentMaintenanceModal({ onSubmit }: EditEquipmentMaintenanceModalProps) {
  const [disabledMinusHoursCountButton, setDisabledMinusHoursCountButton] = useState(true);
  const [disabledPlusHoursCountButton, setDisabledPlusHoursCountButton] = useState(false);
  const [maintainMethod, setMaintainMethods] = useState<string>('internalInformation');
  const methods = useForm<EditRepairRequestLogForm>();
  const { open, data: repairRequest, closeModal } = useTargetModal<RepairRequest>(Modals.EDIT);
  const { equipment } = useEquipment(repairRequest?.equipment?.id ?? '');
  const { repairRequestLogs } = useGetRepairRequestLogs({ requestId: repairRequest?.id });
  const lastRepairRequestLog = repairRequestLogs?.at(-1);
  
  const { data: membersData } = useHumanResourceMembers();

  const onClose = () => {
    methods.reset({});
    closeModal();
  }

  useEffect(() => {
    if (open && lastRepairRequestLog) {
      const menCount = (lastRepairRequestLog?.menCount ?? 1)?.toString();
      methods.reset({
        status: repairRequestStatusOptions.find((option) => option?.id === lastRepairRequestLog?.status),
        priority: RepairRequestPriorityOptions.find((option) => option?.id === lastRepairRequestLog?.priority),
        predict_fulfilled_at: lastRepairRequestLog?.predictFulfilledAt,
        fulfilled_at: lastRepairRequestLog?.fulfilledAt,
        remark: lastRepairRequestLog?.remark,
        internalInformation: maintainMethod === 'internalInformation' ? {
          men_count: { id: menCount, name: menCount },
          members: lastRepairRequestLog?.members,
          hours_count: lastRepairRequestLog?.hoursCount,
          remark: lastRepairRequestLog?.internalRemark,
        } : undefined,
        externalInformation: maintainMethod === 'externalInformation' ? {
          supplier: lastRepairRequestLog?.supplier?.name,
          send_out_at: lastRepairRequestLog?.sendOutAt,
          fixed_at: lastRepairRequestLog?.fixedAt,
          files: lastRepairRequestLog?.files,
        } : undefined,
      });
    }
  }, [open, methods, lastRepairRequestLog, maintainMethod]);

  const handleMinusHoursCount = () => {
    const hoursCount = methods.getValues('internalInformation.hours_count');
    const result = hoursCount ? Number(hoursCount) - 0.5 : 0.5;
    setDisabledMinusHoursCountButton(result <= 0.5);
    setDisabledPlusHoursCountButton(result >= 12);
    if (result >= 0.5 && result <= 12) methods.setValue('internalInformation.hours_count', result?.toString());
  };

  const handlePlusHoursCount = () => {
    const hoursCount = methods.getValues('internalInformation.hours_count');
    const result = hoursCount ? Number(hoursCount) + 0.5 : 0.5;
    setDisabledMinusHoursCountButton(result <= 0.5);
    setDisabledPlusHoursCountButton(result >= 12);
    if (result >= 0.5 && result <= 12) methods.setValue('internalInformation.hours_count', result?.toString());
  };

  const handleSubmit = methods.handleSubmit((formState) => {
    onSubmit({
      id: repairRequest?.id ?? -1,
      status: formState?.status?.id,
      priority: formState?.priority?.id,
      predict_fulfilled_at: formState?.predict_fulfilled_at,
      fulfilled_at: formState?.fulfilled_at,
      remark: formState?.remark,
      internalInformation: maintainMethod === 'internalInformation' ? {
        men_count: Number(formState?.internalInformation?.men_count?.id),
        member_ids: formState?.internalInformation?.members?.map((member) => member?.id),
        hours_count: formState?.internalInformation?.hours_count,
        remark: formState?.internalInformation?.remark,
      } : undefined,
      externalInformation: maintainMethod === 'externalInformation' ? {
        supplier: formState?.externalInformation?.supplier,
        send_out_at: formState?.externalInformation?.send_out_at,
        fixed_at: formState?.externalInformation?.fixed_at,
        files: formState?.externalInformation?.files?.map((file: any) => ({ id: file?.response, title: file?.name })),
      } : undefined,
    });
    onClose();
  });

  return (
    <Modal
      className={styles.modal_wrapper}
      open={open}
      onClose={onClose}
    >
      <ModalHeader>{repairRequest?.id || ''}</ModalHeader>
      <ModalBody>
        <FormFieldsWrapper methods={methods}>
          <div className={styles.form_group}>
            <Typography variant="h4">基本資訊</Typography>
            <div className={styles.detail_wrapper}>
              <div style={{ width: '120px' }}>
                <Typography variant="h6" color="secondary">報修單號</Typography>
                <Typography>{repairRequest?.id || ''}</Typography>
              </div>
              <div style={{ width: '220px' }}>
                <Typography variant="h6" color="secondary">設備名稱</Typography>
                <Typography>{equipment?.name || ''}</Typography>
              </div>
              <div>
                <Typography variant="h6" color="secondary">位置</Typography>
                <Typography>{getZoneDisplayName(equipment?.zone)}</Typography>
              </div>
            </div>
            <div className={styles.detail_wrapper}>
              <div style={{ width: '120px' }}>
                <Typography variant="h6" color="secondary">申請人</Typography>
                <Typography>{repairRequest?.creator?.name || ''}</Typography>
              </div>
              <div style={{ width: '220px' }}>
                <Typography variant="h6" color="secondary">報修時間</Typography>
                <Typography>{moment(repairRequest?.createdAt).format("YYYY/MM/DD HH:mm") || ''}</Typography>
              </div>
              <div>
                <Typography variant="h6" color="secondary">最後更新</Typography>
                <Typography>{moment(repairRequest?.updatedAt).format("YYYY/MM/DD HH:mm") || ''}</Typography>
              </div>
            </div>
            <div className={styles.flex_fields_group}>
              <SelectField
                required
                label="狀態"
                registerName="status"
                options={repairRequestStatusOptions} />
              <SelectField
                required
                label="優先序"
                registerName="priority"
                options={RepairRequestPriorityOptions} />
              <DateTimePickerField
                required
                label="預計完成時間"
                registerName="predict_fulfilled_at" />
              <DateTimePickerField
                label="實際完成時間"
                registerName="fulfilled_at" />
            </div>
            <Textarea
              value={methods?.watch('remark') || ''}
              onChange={(e) => methods.setValue('remark', e.target.value)}
              fullWidth
              maxLength={75}
              placeholder="處理明細" />
          </div>
          <div className={styles.form_group}>
            <Typography variant="h4">處理方式</Typography>
            <RadioGroup
              value={maintainMethod}
              onChange={(e) => setMaintainMethods(e.target.value)}
              options={[
                { value: 'internalInformation', label: '內部' },
                { value: 'externalInformation', label: '外部' },
              ]} />
            {maintainMethod === 'internalInformation' && (
              <>
                <div className={styles.flex_fields_group}>
                  <SelectField
                    required
                    width={120}
                    label="維修人數 (人)"
                    registerName="internalInformation.men_count"
                    options={[
                      { id: '1', name: '1' },
                      { id: '2', name: '2' },
                      { id: '3', name: '3' },
                      { id: '4', name: '4' },
                      { id: '5', name: '5' },
                      { id: '6', name: '6' },
                      { id: '7', name: '7' },
                      { id: '8', name: '8' },
                      { id: '9', name: '9' },
                      { id: '10', name: '10' },
                    ]} />
                  <TreeSelectField
                    required
                    width={400}
                    fullWidth
                    label="維修人員"
                    registerName="internalInformation.members"
                    options={membersData?.data ?? []} />
                  <InputField
                    required
                    width={130}
                    type="number"
                    label="維修時間 (小時)"
                    registerName="internalInformation.hours_count"
                    clearable={false}
                    prefix={(
                      <Typography
                        style={{ cursor: 'pointer', fontSize: '20px', fontWeight: 'bold' }}
                        color={disabledMinusHoursCountButton ? 'secondary' : 'primary'}
                        onClick={handleMinusHoursCount}>
                        -
                      </Typography>
                    )}
                    suffix={(
                      <Typography
                        style={{ cursor: 'pointer', fontSize: '20px', fontWeight: 'bold' }}
                        color={disabledPlusHoursCountButton ? 'secondary' : 'primary'}
                        onClick={handlePlusHoursCount}>
                        +
                      </Typography>
                    )}
                    min={0.5}
                    max={12} />
                </div>
                <Textarea
                  value={methods?.watch('internalInformation.remark') || ''}
                  onChange={(e) => methods.setValue('internalInformation.remark', e.target.value)}
                  fullWidth
                  maxLength={75}
                  placeholder="處理明細" />
              </>
            )}
            {maintainMethod === 'externalInformation' && (
              <>
                <div className={styles.flex_fields_group}>
                  <div>
                    <label className="mzn-form-field__label mzn-rhf-base-field__label" htmlFor="externalInformation.supplier">
                      <span>
                        廠商
                        <span className="mzn-form-field__asterisk">*</span>
                      </span>
                    </label>
                    <Input
                      value={methods.watch('externalInformation.supplier')}
                      onChange={(e: any) => methods.setValue('externalInformation.supplier', e.target.value)} />
                  </div>
                  <DateTimePickerField
                    width={180}
                    label="送修時間"
                    registerName="externalInformation.send_out_at" />
                  <DateTimePickerField
                    width={180}
                    label="完修時間"
                    registerName="externalInformation.fixed_at" />
                </div>
                <MenuDivider />
                <BaseField name="externalInformation.files" label="附件">
                  <Upload
                    accept="image/jpg, image/jpeg, image/png, .pdf"
                    action={`${environment.API_HOST}/files`}
                    headers={{ apikey: environment.API_KEY }}
                    onChange={({ file, fileList }) => {
                      methods.setValue('externalInformation.files', fileList);
                    }}>
                    <Button variant="outlined" prefix={<Icon icon={UploadIcon} />}>
                      上傳附件
                    </Button>
                  </Upload>
                </BaseField>
              </>
            )}
          </div>
        </FormFieldsWrapper>
      </ModalBody>
      <ModalFooter>
        <Button variant="outlined" onClick={onClose}>
          取消
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          更新
        </Button>
      </ModalFooter>
    </Modal>
  );
}
