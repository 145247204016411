import useSWR from 'swr';
import { API_NAMESPACE } from '../request';

const namespace = API_NAMESPACE.MATERIALS;

export type OutsourcePurchaseMaterialRequestOrderResponse = {
  id: string;
  outsourcePurchaseOrderId: string;
  recipeId: string | null;
  expectedCompletedAt: string;
  actualCompletedAt: string | null;
  recipeName: string | null;
  supplierId: string;
  staffId: string;
  supplierName: string | null;
  shippedState: string;
  items: {
    id: string;
    materialId: string;
    materialSapBatchId: string;
    materialSubBatchId: string;
    loaderId: string;
    expectedMaterialStockUnitQuantity: string;
    actualMaterialStockUnitQuantity: string;
    actualMaterialWeightUnitQuantity: string;
    materialDescription: string;
    shelfId: string;
    stockUnit: string;
    materialStockUnit: string;
    materialInputUnit: string;
  }[];
};

export function useOutsourcePurchaseMaterialRequestOrder({
  orderId,
}: {
  orderId?: string;
}) {
  const { data, error } = useSWR<OutsourcePurchaseMaterialRequestOrderResponse>(
    orderId
      ? [
          `/warehouses/outsource-purchase-material-request-orders/${orderId}`,
          { namespace },
        ]
      : null
  );

  return {
    data: data,
    isLoading: !error && !data,
  };
}
