import { Typography } from '@mezzanine-ui/react';
import { PageLayout } from '../Templates/PageLayout/PageLayout';
import styles from './strike-price-report.module.scss';

const StrikePriceReport = () => {
  const pageTitle = '敲價報表';

  const powerBIHref =
    window.location.host === 'edges.solartech.cloud'
      ? 'https://app.powerbi.com/reportEmbed?reportId=5889fb17-8e42-4860-a5f9-6a863b182ba4&autoAuth=true&ctid=e3553a8e-c723-4b54-ac77-0bb3db8cf161'
      : 'https://app.powerbi.com/reportEmbed?reportId=3683ae31-2d7d-4ed8-8b79-5b59f4be450d&autoAuth=true&ctid=e3553a8e-c723-4b54-ac77-0bb3db8cf161';

  return (
    <div
      style={{
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'min-content auto',
      }}
    >
      <Typography variant="h1">{pageTitle}</Typography>
      <iframe
        title="WorkReportBI"
        width="1140"
        height="541.25"
        className={styles.iframe}
        src={powerBIHref}
        allowFullScreen
      />
    </div>
  );
};

export default StrikePriceReport;
