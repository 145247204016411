import { useMemo, useState } from 'react';
import { Button, Table, Typography } from '@mezzanine-ui/react';
import classes from '../IncomingInspectOperationPage/incoming-inspect-detail-page.module.scss';
import styles from '../../SalesAndDistribution/PackagingPage/PackagingPage.module.scss';
import { PageLayout } from '../PageLayout/PageLayout';
import { useLocation } from 'react-router';
import { TableColumn } from '@mezzanine-ui/core/table';
import { useNavigate } from 'react-router-dom';
import { useOrderTableData } from './hooks/useOrderTableData';
import { OrderMetaInfo } from '../components';
import { ModalGroup, useModalGroupController } from '@solar/templates';
import { ReceiveActionInputs } from '../IncomingInspectOperationPage/Modals/ReceiveActionModal';
import { ReturnOrderModal } from './ReturnOrderModal';
import { pageOptions } from './page-options';

export type ReceivableItemTable = {
  id: string;
  materialId: string;
  materialDescription: string;
  categoryName: string;
  groupName: string;
  expectedMainMaterialUnitQuantity: number;
  receivedMainMaterialUnitQuantity?: number;
  specId?: string;
};

export type ReceivedMaterialItemTable = ReceivableItemTable &
  ReceiveActionInputs;

export function MaterialReturnOrderDetailPage() {
  const modalGroupController = useModalGroupController([
    { name: 'RETURN_ORDER' },
  ]);

  const location = useLocation();
  const navigate = useNavigate();

  const { order, orderMetaDataLabelAndContext } = useOrderTableData();

  const column: TableColumn<ReceivedMaterialItemTable>[] = useMemo(
    () => [
      { title: '料號', dataIndex: 'materialId' },
      { title: '品名', dataIndex: 'materialDescription' },
      { title: '物料類型', dataIndex: 'categoryName' },
      { title: '物料群組', dataIndex: 'groupName' },
      { title: '應收量', dataIndex: 'expectedMainMaterialUnitQuantity' },
      {
        title: '操作',
        width: 120,
        align: 'center' as const,
        render(source) {
          return (
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                modalGroupController.openModal('RETURN_ORDER', null);
              }}
            >
              退貨
            </Button>
          );
        },
      },
    ],
    []
  );

  const receivedMaterialColumn: TableColumn<ReceivedMaterialItemTable>[] =
    useMemo(
      () => [
        { title: '料號', dataIndex: 'materialId' },
        { title: '品名', dataIndex: 'materialDescription' },
        { title: '物料類型', dataIndex: 'categoryName' },
        { title: '物料群組', dataIndex: 'groupName' },
        { title: '應收量', dataIndex: 'expectedMainMaterialUnitQuantity' },
        { title: '收料量', dataIndex: 'desiredReceiveQuantity' },

        { title: '儲位', dataIndex: 'warehouse' },
        { title: '載具', dataIndex: 'loader.name' },
        {
          title: '',
          render: (source) => {
            return (
              <Button
                type="button"
                onClick={() =>
                  setReceivedMaterialTableData((prev) =>
                    prev.filter((row) => row.id !== source.id)
                  )
                }
              >
                移除
              </Button>
            );
          },
        },
      ],
      []
    );
  const [receivedMaterialTableData, setReceivedMaterialTableData] = useState<
    ReceivedMaterialItemTable[]
  >([]);

  return (
    <>
      <PageLayout setGoBack>
        <Typography variant="h1">
          {
            pageOptions.find(
              (page) => page.id === location.pathname.split('/').at(-2)
            )?.name
          }
          退貨
        </Typography>
        <div className={classes['row-block']}>
          <OrderMetaInfo
            labelAndContexts={orderMetaDataLabelAndContext ?? []}
          />
        </div>
        <div className={classes['row-block']}>
          <Typography variant="h4" color="text-primary">
            應收項目
          </Typography>
          <Table
            columns={column}
            dataSource={order ?? []}
            bodyClassName={styles.table}
            headerClassName={styles.tableHeader}
          />
        </div>
        <div className={classes['row-block']}>
          <Typography variant="h4" color="text-primary">
            收料項目
          </Typography>
          <Table
            columns={receivedMaterialColumn}
            dataSource={receivedMaterialTableData ?? []}
            bodyClassName={styles.table}
            headerClassName={styles.tableHeader}
          />
        </div>
        <div className={classes['row-block']}>
          <div className={classes['action-container']}>
            <Button type="button" variant="outlined" onClick={async () => {}}>
              儲存
            </Button>
          </div>
        </div>
      </PageLayout>
      <ModalGroup {...modalGroupController}>
        <ReturnOrderModal />
      </ModalGroup>
    </>
  );
}
