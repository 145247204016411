import { useMemo } from 'react';
import { TableColumn, TableExpandable, TablePagination } from '@mezzanine-ui/core/table';
import { Sample, Submission, SubmissionResponse } from './typings';
import { UseFormReturn } from 'react-hook-form';
import { SubmissionFilter } from './use-submissions';
import moment from 'moment';
import { uniq } from 'lodash';

export function useTableColumns(
  methods: UseFormReturn<SubmissionFilter>,
  pageInfo?: Omit<SubmissionResponse, 'submissions'>,
): {
  columns: TableColumn<Submission>[];
  pagination: TablePagination;
  expandable: TableExpandable<Submission>;
} {
  const page = methods.watch('page');

  const pagination = useMemo<TablePagination>(() => ({
    disableAutoSlicing: true,
    current: page,
    total: pageInfo?.totalPages ?? 0,
    onChange: (newPage) => methods.setValue('page', newPage),
    options: {
      pageSize: pageInfo?.limit ?? 10,
      shoaPageSizeOptions: true,
      onChangePageSize: (pageSize) => methods.setValue('pageSize', pageSize),
    },
  }), [methods, page, pageInfo?.limit, pageInfo?.totalPages]);

  const columns = useMemo<TableColumn<Submission>[]>(() => [
    {
      title: '檢驗/樣品單號',
      width: 160,
      dataIndex: 'submissionNumber',
    },
    {
      title: '狀態',
      width: 150,
      render: (row) => uniq(row.samples.map(sample => sample.statusDescription)).join('/'),
    },
    {
      title: '檢驗單位/項目',
      width: 320,
      dataIndex: 'metadata.customerName',
    },
    {
      title: '送件時間',
      width: 160,
      render: (row) => moment(row.submittedAt).format('YY/MM/DD HH:mm'),
    },
    {
      title: '批號',
      width: 180,
      render: (row) => ' ',
    },
    {
      title: '分析類型',
      render: (row) => ' ',
    },
  ], []);

  const expandable = useMemo<TableExpandable<Submission>>(() => ({
    rowExpandable: (record) => !!record.samples.length,
    expandedRowRender: (record) => ({
      dataSource: record.samples,
      columns: [{
        dataIndex: 'sampleNumber',
      }, {
        dataIndex: 'statusDescription',
      }, {
        dataIndex: 'name',
      }, {
        dataIndex: 'createdAt',
        render: (row: Sample) => moment(row.createdAt).format('YY/MM/DD HH:mm'),
      }, {
        dataIndex: 'metadata.batchNumber',
      }, {
        dataIndex: 'analysisRequirement.requirement',
        render: (row: Sample) => `[${row.analysisRequirement.type}] ${row.analysisRequirement.requirement}`,
      }],
    }),
  }), []);

  return { columns, pagination, expandable };
}
