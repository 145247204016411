import type { ProductionPlanningOrdersData } from './useProductionPlanningOrdersGet';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { request, API_NAMESPACE } from '../../request';
import * as yup from 'yup';

const FormFieldName = {
  ORDER_DETAIL_ID: 'id',
  ORDER_ID: 'orderId',
  PLANNED_DELIVERY_DATE: 'plannedDeliveryDate',
} as const;

const plannedDeliveryDateInlineFormSchema = yup.object().shape({
  [FormFieldName.ORDER_DETAIL_ID]: yup.number().required(),
  [FormFieldName.ORDER_ID]: yup.string().required(),
  [FormFieldName.PLANNED_DELIVERY_DATE]: yup.string().required(),
});

export type PlannedDeliveryDateInlineFormValues = yup.InferType<
  typeof plannedDeliveryDateInlineFormSchema
>;

type HookProps = {
  dataSource: ProductionPlanningOrdersData;
};

export function usePlannedDeliveryDateInlineForm({ dataSource }: HookProps) {
  const formMethods = useForm<PlannedDeliveryDateInlineFormValues>({
    resolver: yupResolver(plannedDeliveryDateInlineFormSchema),
  });
  const formState = useFormState({ control: formMethods.control });

  useEffect(() => {
    if (!dataSource) return;

    formMethods.reset({
      [FormFieldName.ORDER_DETAIL_ID]: dataSource.id,
      [FormFieldName.ORDER_ID]: dataSource.orderId,
      [FormFieldName.PLANNED_DELIVERY_DATE]: dataSource.plannedDeliveryDate,
    });
  }, [dataSource, formMethods]);

  return {
    formMethods,
    formState,
    formSubmitCreator,
    FormFieldName,
  };
}

async function putPlannedDeliveryDate({
  id,
  plannedDeliveryDate,
}: PlannedDeliveryDateInlineFormValues) {
  try {
    return request(`/production-planning/orders/${id}`, {
      namespace: API_NAMESPACE.HR,
      method: 'PUT',
      body: JSON.stringify({
        [FormFieldName.PLANNED_DELIVERY_DATE]: plannedDeliveryDate,
      }),
    });
  } catch (error) {
    return null;
  }
}

type FormSubmitCreatorProps = {
  successCallback?: () => void;
  errorCallback?: (error: Error) => void;
};

function formSubmitCreator({
  successCallback,
  errorCallback,
}: FormSubmitCreatorProps) {
  const onSubmit = async (data: PlannedDeliveryDateInlineFormValues) => {
    putPlannedDeliveryDate(data)
      .then((res) => {
        successCallback?.();
      })
      .catch((error) => {
        errorCallback?.(error);
      });
  };
  return onSubmit;
}
