import useSWR from 'swr';
import { API_NAMESPACE, request } from '../request';

const namespace = API_NAMESPACE.MATERIALS;

export type InventoryPurchaseOrderResponse = {
  id: string;
  expectedCompletedAt: string;
  staffName: string;
  supplierId: string;
  supplierName: string;
  receivedState: string;
  items: Array<{
    id: string;
    itemId: string;
    expectedMainMaterialUnitQuantity: string;
    expectedSecondaryMaterialUnitQuantity: string;
    receivedMainMaterialUnitQuantity: string;
    receivedSecondaryMaterialUnitQuantity: string;
    returnedMainMaterialUnitQuantity: string;
    returnedSecondaryMaterialUnitQuantity: string;
    materialDescription: string;
    oldMaterialId: string;
    mainUnitCode: string;
    mainUnitName: string;
    materialStockUnit: string;
    secondaryUnitCode: string;
    secondaryUnitName: string;
    materialId: string;
    specId: string | number;
  }>;
  logs: Array<{
    id: number;
    shelfId: string;
    materialBatchId: string;
    orderId: string;
    materialId: string;
    material?: {
      id: string;
      description: string;
      mainMaterialUnitId: number;
      mainMaterialUnit: {
        id: number;
        code: string;
        name: string;
      };
      secondaryMaterialUnitId: any;
      secondaryMaterialUnit: any;
      categoryId: number;
      groupId: any;
      sourceId: any;
      oldMaterialId: string;
    };
    loaderId: number;
    mainMaterialUnitQuantity?: number;
    secondaryMaterialUnitQuantity?: number;
    staffId: string;
    actionType: string;
    orderType: string;
    createdAt: string;
  }>;
};

export async function updatePurchaseOrderReceipt(body: any) {
  const response: Promise<Response> = await request(
    '/warehouses/inventory-purchase-orders/receipt',
    {
      method: 'put',
      body: JSON.stringify(body),
      responseParser: (res) => res,
      namespace,
    }
  );

  return response;
}

export function useInventoryByPurchaseOrder({
  orderId,
}: {
  orderId?: string | undefined;
}) {
  const { data, error, mutate } = useSWR<InventoryPurchaseOrderResponse>(
    orderId
      ? [`/warehouses/inventory-purchase-orders/${orderId}`, { namespace }]
      : null
  );

  return {
    order: data?.items ?? [],
    data,
    refetch: mutate,
    mutate,
    isLoading: !data && !error,
  };
}
