import type { RefObject } from 'react';
import { useCallback, useEffect } from 'react';
import { useLocationControlContext } from '../LocationControlContext';
import { LocationLevel } from '../types';

export function useAddClickEventListenerInLocationSVG(
  svgWrapperRef: RefObject<HTMLDivElement>,
  svgContent?: string
) {
  const { activeId, onChangeBySVG } = useLocationControlContext();

  const handleClick = useCallback(
    (event: MouseEvent) => {
      const element = event.target as SVGElement;
      const id = element.getAttribute('id');
      const level = element.getAttribute('level') as LocationLevel | null;

      if (!id) {
        console.warn('此區塊無標記 id', element);
        return;
      }

      if (!level) {
        console.warn('此區塊無標記 level', element);
        return;
      }

      onChangeBySVG?.(id, level);
    },
    [onChangeBySVG]
  );

  useEffect(() => {
    // if (!svgWrapperRef.current || !svgContent) return;

    const svgWrapper = svgWrapperRef.current;
    const svgElement = svgWrapper?.querySelector('svg');
    const polygonList = svgElement?.querySelectorAll('polygon');
    const polygonElements = Array.from(polygonList ?? []);

    polygonElements?.forEach((element) => {
      element.addEventListener('click', handleClick);
    });

    return () => {
      polygonElements?.forEach((element) => {
        element.removeEventListener('click', handleClick);
      });
    };
  }, [
    svgContent,
    svgWrapperRef,
    svgWrapperRef.current?.innerHTML,
    handleClick,
  ]);

  useEffect(() => {
    // if (!svgWrapperRef.current || !svgContent) return;

    const svgWrapper = svgWrapperRef.current;
    const svgElement = svgWrapper?.querySelector('svg');
    const polygonList = svgElement?.querySelectorAll('polygon');
    const polygonElements = Array.from(polygonList ?? []);

    polygonElements.forEach((element) => {
      const elementId = element.getAttribute('id');
      const elementLevel = element.getAttribute(
        'level'
      ) as LocationLevel | null;

      if (elementId && elementLevel) {
        const active = activeId === elementId;
        element.setAttribute('active', active.toString());
      }
    });
  }, [activeId, svgContent, svgWrapperRef, svgWrapperRef.current?.innerHTML]);
}
