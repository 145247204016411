import CalendarMethodsMoment from '@mezzanine-ui/core/calendarMethodsMoment';
import { CalendarConfigProvider, Typography } from '@mezzanine-ui/react';
import {
  API_NAMESPACE,
  ProductionFormResponse,
  ProductionParameterResponse,
  ProductionFormCondition,
} from '@solar/data';
import { useMemo } from 'react';
import useSWR from 'swr';
import styles from './preview-modal.module.scss';
import { ProductionForms } from './ProductionForms';
import { ProductionParameters } from './ProductionParameters';

export function ProductionFormDisplayTable({
  materialId,
  recipeId,
  recipeVersion,
  processingId,
  workCenterId,
  equipmentId,
}: ProductionFormCondition) {
  const shouldShow = !!(
    materialId ||
    recipeId ||
    processingId ||
    workCenterId ||
    equipmentId
  );

  const queryString = useMemo(() => {
    const params = new URLSearchParams();

    if (materialId) {
      params.append('materialId', materialId);
    }

    if (recipeId) {
      params.append('recipeId', recipeId);
    }

    if (recipeVersion) {
      params.append('recipeVersion', recipeVersion);
    } else {
      params.append('recipeVersion', '1');
    }

    if (processingId) {
      params.append('processingId', processingId);
    }

    if (workCenterId) {
      params.append('workCenterId', workCenterId);
    }

    if (equipmentId) {
      params.append('equipmentId', equipmentId);
    }

    return params.toString();
  }, [materialId, recipeId, processingId, workCenterId, equipmentId]);

  const { data: forms } = useSWR<ProductionFormResponse[]>(
    shouldShow
      ? [`/forms?${queryString}`, { namespace: API_NAMESPACE.PP }]
      : undefined
  );

  const { data: parameters } = useSWR<ProductionParameterResponse[]>(
    shouldShow
      ? [`/parameters?${queryString}`, { namespace: API_NAMESPACE.PP }]
      : undefined
  );

  if (!shouldShow) return null;

  return (
    <CalendarConfigProvider methods={CalendarMethodsMoment}>
      <div className={styles.column}>
        <Typography variant="h5">生產機台題目</Typography>
        <ProductionForms forms={forms ?? []} />
      </div>
      <div className={styles.column}>
        <Typography variant="h5">生產參數</Typography>
        <ProductionParameters parameters={parameters ?? []} />
      </div>
    </CalendarConfigProvider>
  );
}
