import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import {
  FieldsType,
  headers,
  opFilterMutation,
  ResponseStatus,
} from '../../humanResources';
import { API_NAMESPACE, request } from '../../request';

const namespace = API_NAMESPACE.HR;

export function useMainFileData() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [queryParams, setQueryParams] = useState('');

  const tableQueries = useMemo(
    () => `?page=${page}&perPage=${perPage}`,
    [page, perPage]
  );

  const { data: swrData, mutate } = useSWR([
    `/packings/get/${tableQueries}${queryParams}`,
    { namespace },
  ]);

  const total = useMemo(() => swrData?.total ?? 0, [swrData]) as number;

  const filterMutation = async (payload: FieldsType[]) => {
    const mapping = [
      { registerName: 'productPartNo', query: 'materialId', isDate: false },
      { registerName: 'customerName', query: 'customerId', isDate: false },
      {
        registerName: 'packageMaterialName',
        query: 'packagingId',
        isDate: false,
      },
    ];

    const result = payload.map((item) =>
      item.registerName === 'packageMaterialName'
        ? { ...item, value: item.value ? [{ ...item.value }] : undefined }
        : item.registerName === 'customerName'
        ? { ...item, value: item.value ? [{ ...item.value }] : undefined }
        : item
    );

    const filterPayload = result.filter((item) =>
      mapping.some((mapItem) => mapItem.registerName === item.registerName)
    );

    const joinResult = opFilterMutation(filterPayload, mapping);

    joinResult === '' ? setQueryParams('') : setQueryParams(`&${joinResult}`);
  };

  const createMutation = async (payload: any) => {
    const options = {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    };
    const res = await request('/packings', {
      ...options,
      namespace,
      responseParser: () => ({ status: 200 }),
    });
    if (res.status === 200) {
      mutate();
      return { status: ResponseStatus.SUCCESS, message: '成功' };
    } else {
      const json = (await res.json()) as { message: string[] };
      return { status: ResponseStatus.FAIL, message: json.message };
    }
  };

  const updateMutation = async (id: string, payload: any) => {
    const options = {
      method: 'PUT',
      headers,
      body: JSON.stringify(payload),
    };
    const res = await request(`/packings/${id}`, {
      ...options,
      namespace,
      responseParser: () => ({ status: 200 }),
    });
    if (res.status === 200) {
      mutate();
      return { status: ResponseStatus.SUCCESS, message: '成功' };
    } else {
      const json = (await res.json()) as { message: string[] };
      return { status: ResponseStatus.FAIL, message: json.message };
    }
  };

  const deleteMutation = async (id: string) => {
    const options = {
      method: 'DELETE',
      headers,
    };
    const res = await request(`/packings/${id}`, {
      ...options,
      namespace,
      responseParser: () => ({ status: 200 }),
    });
    if (res.status === 200) {
      mutate();
      return ResponseStatus.SUCCESS;
    } else {
      return ResponseStatus.FAIL;
    }
  };

  useEffect(() => {
    if (!swrData || typeof swrData === 'string') {
      setLoading(true);
      return;
    }
    setData(swrData.data);
    setLoading(false);
  }, [swrData]);

  return {
    data,
    total,
    loading,
    filterMutation,
    createMutation,
    updateMutation,
    deleteMutation,
    page,
    setPage,
    perPage,
    setPerPage,
  };
}
