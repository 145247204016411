import { getRoleGroup } from './shared';

const FIRST_SPEC = {
  FF: {
    name: '未分類',
    nextLevel: {
      RS: {
        name: '待重熔',
      },
      RR: {
        name: '待回收',
      },
      DL: {
        name: '稀釋',
      },
    },
  },
};

const SOLID_MAIN_SMALL_GROUP = {
  B: {
    name: '氮化硼',
    nextLevel: getRoleGroup(FIRST_SPEC),
  },
  H: {
    name: '黑皮',
    nextLevel: getRoleGroup(FIRST_SPEC),
  },
};

const FILTER_SMALL_GROUP = {
  B: {
    name: '濾袋',
    nextLevel: getRoleGroup(FIRST_SPEC),
  },
  F: {
    name: '濾心',
    nextLevel: getRoleGroup(FIRST_SPEC),
  },
  R: {
    name: '樹脂',
    nextLevel: getRoleGroup(FIRST_SPEC),
  },
  E: {
    name: '濾餅',
    nextLevel: getRoleGroup(FIRST_SPEC),
  },
  1: {
    name: '未分類',
    nextLevel: getRoleGroup(FIRST_SPEC),
  },
};

const LU_SMALL_GROUP = {
  1: {
    name: '未分類',
    nextLevel: getRoleGroup(FIRST_SPEC),
  },
};

const SOLID_MEDIUM_GROUP = {
  A: {
    name: '料頭',
    nextLevel: SOLID_MAIN_SMALL_GROUP,
  },
  B: {
    name: '骨架',
    nextLevel: SOLID_MAIN_SMALL_GROUP,
  },
  C: {
    name: '外環料',
    nextLevel: SOLID_MAIN_SMALL_GROUP,
  },
  D: {
    name: '月牙料',
    nextLevel: SOLID_MAIN_SMALL_GROUP,
  },
  G: {
    name: '塊料',
    nextLevel: SOLID_MAIN_SMALL_GROUP,
  },
  H: {
    name: '屑料',
    nextLevel: SOLID_MAIN_SMALL_GROUP,
  },
  P: {
    name: '粉料',
    nextLevel: SOLID_MAIN_SMALL_GROUP,
  },
  M: {
    name: '泥漿',
    nextLevel: SOLID_MAIN_SMALL_GROUP,
  },
  F: {
    name: '濾材',
    nextLevel: FILTER_SMALL_GROUP,
  },
  L: {
    name: '爐渣',
    nextLevel: LU_SMALL_GROUP,
  },
};

const BASIC_LIQUID_SMALL_GROUP = {
  1: {
    name: '未分類',
    nextLevel: getRoleGroup({
      FF: {
        name: '未分類',
        nextLevel: {
          '01': {
            name: '未分類',
          },
        },
      },
    }),
  },
};

const LIQUID_MEDIUM_GROUP = {
  A: {
    name: '酸性',
    nextLevel: BASIC_LIQUID_SMALL_GROUP,
  },
  N: {
    name: '中性',
    nextLevel: BASIC_LIQUID_SMALL_GROUP,
  },
  B: {
    name: '鹼性',
    nextLevel: BASIC_LIQUID_SMALL_GROUP,
  },
};

export const WASTE_LARGE_GROUP = {
  S: {
    name: '固材',
    nextLevel: SOLID_MEDIUM_GROUP,
  },
  L: {
    name: '液體',
    nextLevel: LIQUID_MEDIUM_GROUP,
  },
};
