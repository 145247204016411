import { LocationLevel, useGetSVG } from "@solar/data";
import styles from './location-svg-viewer.module.scss';
import { Spin } from "antd";
import { LocationSVGPopover } from "./LocationSVGPopover";
import { ShelfSVG } from "./ShelfSVG";
import { useLocationSelectorContext } from "../LocationSelectorContext/useLocationSelectorContext";
import { LocationSVG } from "./LocationSVG";
import { CSSProperties } from "react";

type LocationSVGViewerProps = {
  showShelfSvg?: boolean;
  svgWrapperStyle?: CSSProperties;
};

export function LocationSVGViewer({ showShelfSvg = true, svgWrapperStyle }: LocationSVGViewerProps) {
  const controller = useLocationSelectorContext();
  const { svgId, currentLocation, handleNextLevel } = controller;
  const { isLoading } = useGetSVG(svgId);

  const defaultSvgWrapperStyle = {
    ...svgWrapperStyle,
    width: svgWrapperStyle?.width ?? '800px',
  };

  const svgPopoverStyle = {
    width: svgWrapperStyle?.width ?? '800px',
    height: svgWrapperStyle?.height ?? '600px',
  };
  
  const handleClick = (event: MouseEvent) => {
    const element = event.target as SVGPolygonElement;
    const id = element.getAttribute('id') ?? undefined;
    const level = element.getAttribute('level') as LocationLevel;
    handleNextLevel(id, level);
  };

  return (
    <Spin spinning={isLoading} delay={100}>
      <LocationSVGPopover style={svgPopoverStyle}>
        <LocationSVG className={styles.svg_wrapper} style={defaultSvgWrapperStyle} onClick={handleClick} />
        {showShelfSvg && currentLocation?.stack?.id && (
          <ShelfSVG className={styles.svg_wrapper} style={defaultSvgWrapperStyle} onClick={handleClick} />
        )}
      </LocationSVGPopover>
    </Spin>
  );
}
