import useSWR from 'swr';
import { UseFormReturn } from 'react-hook-form';
import styles from './table.module.scss';
import { API_NAMESPACE, NextPagination, PageInfo } from '@solar/data';
import { Loading, SelectValue } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { useTableColumn } from './use-table-column';
import { useEffect } from 'react';
import { MznPaginationTable } from '../../../Templates/PaginationTable/MznPaginationTable';

export interface MaterialViewerTableFilter {
  factory: SelectValue;
  idPrefix: string;
}

type Props = {
  dataSource: any[];
  isLoading: boolean;
  methods: UseFormReturn<MaterialViewerTableFilter>;
  fetchData: (nextPagination: NextPagination) => void;
  pageInfo: PageInfo;
};

export function MaterialViewerTable({
  dataSource,
  isLoading,
  methods,
  pageInfo,
  fetchData,
}: Props) {
  const { data: factories } = useSWR([
    '/factorys',
    { namespace: API_NAMESPACE.EQUIPMENTS },
  ]);

  useEffect(() => {
    if (factories?.length) {
      methods.setValue('factory', factories[0]);
    }
  }, [factories, methods]);

  const idPrefix = methods.watch('idPrefix')?.trim() ?? '';

  useEffect(() => {
    const upper = idPrefix?.toUpperCase() ?? '';

    if (upper !== idPrefix) {
      methods.setValue('idPrefix', upper);
    }
  }, [idPrefix, methods]);

  const tableColumns = useTableColumn();

  if (!factories) return <Loading loading />;

  return (
    <FormFieldsWrapper methods={methods} className={styles.wrapper}>
      <MznPaginationTable
        loading={isLoading}
        dataSource={dataSource}
        columns={tableColumns}
        pageInfo={pageInfo}
        fetchData={fetchData}
      />
    </FormFieldsWrapper>
  );
}
