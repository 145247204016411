import { useCallback, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import useSWR from 'swr';
import { GetMaterialsParams, MaterialResponseData } from './materials.type';

import { OffsetBased, PageInfo, PaginatedResponse } from '../pagination';
import { API_NAMESPACE, request } from '../request';
import { CommonTableFilterParam } from '../common-table-filter-param';

const namespace = API_NAMESPACE.MATERIALS;

export function useMaterials(methods?: UseFormReturn<any>) {
  const [params, setParams] = useState<GetMaterialsParams>({});
  const { data, error } = useSWR<{
    materials: MaterialResponseData[];
    pageInfo: PageInfo;
  }>(params ? ['/materials', { params, namespace }] : null);

  const pageInfo = useMemo(() => {
    const page =
      (data?.pageInfo?.offset ?? 0) / (data?.pageInfo?.limit ?? 20) + 1;
    const pageSize = data?.pageInfo?.limit ?? 20;
    return { ...(data?.pageInfo ?? {}), page, pageSize };
  }, [data]);

  const mutateGetMaterials = useCallback(
    (payload?: any) => {
      const filterState = methods?.getValues();
      const nextPageSize = payload?.nextPageSize ?? pageInfo?.pageSize;
      const nextPage = payload?.nextPage ?? pageInfo?.page;

      setParams({
        offset: (nextPage - 1) * nextPageSize,
        limit: nextPageSize,
        categoryIds:
          filterState?.categories?.map(({ id }: { id: string }) => id) ?? [],
        groupIds:
          filterState?.materialGroups?.map(({ id }: { id: string }) => id) ??
          [],
        searchTerm:
          filterState?.materialId ?? filterState?.materialDescription ?? null,
        searchType: filterState?.searchType ?? null,
      });
    },
    [methods, pageInfo]
  );

  return {
    materials: data?.materials,
    pageInfo,
    isLoading: !error && !data,
    mutateGetMaterials,
    setParams,
  };
}

interface UseGetMaterialsInInventoryParams extends OffsetBased {
  withDeleted?: boolean;
  searchTerm?: string;
}

export function useGetMaterialsInInventory(
  defaultParams?: UseGetMaterialsInInventoryParams
) {
  const [params, setParams] = useState(defaultParams);
  const { data, error, isValidating, mutate } = useSWR<{
    records: { materialId: string; materialDescription: string }[];
    pageInfo: PageInfo;
  }>(params ? ['/warehouses/inventory/material', { namespace, params }] : null);

  return {
    materials: data?.records ?? [],
    pageInfo: data?.pageInfo,
    isLoading: !error && !data && isValidating,
    refetchMaterialsInInventory: setParams,
    mutateMaterialsInInventory: mutate,
  };
}

export function useMaterialsInInventory() {
  const [params, setParams] = useState<
    Partial<CommonTableFilterParam & OffsetBased>
  >({});
  const { data, error } = useSWR<{
    records: { materialId: string; materialDescription: string }[];
    pageInfo: PageInfo;
  }>(
    params
      ? [
          '/warehouses/inventory/material',
          { params, namespace: API_NAMESPACE.MATERIALS },
        ]
      : null
  );

  const pageInfo = useMemo(() => {
    const page =
      (data?.pageInfo?.offset ?? 0) / (data?.pageInfo?.limit ?? 20) + 1;
    const pageSize = data?.pageInfo?.limit ?? 20;
    return { ...(data?.pageInfo ?? {}), page, pageSize };
  }, [data]);

  return {
    data: data?.records ?? [],
    pageInfo,
    isLoading: !error && !data,
    setParams,
  };
}

interface UseGetMaterialBatchesInInventoryParams extends OffsetBased {
  withDeleted?: boolean;
  searchTerm?: string;
}

export function useGetMaterialBatchesInInventory(
  materialId: string,
  defaultParams: UseGetMaterialBatchesInInventoryParams
) {
  const [params, setParams] = useState(defaultParams);

  const { data, error, isValidating, mutate } = useSWR<{
    records: {
      materialId: string;
      materialDescription: string;
      sapBatchId: string;
      subBatchId: string;
      batchId: string;
    }[];
    pageInfo: PageInfo;
  }>(
    materialId && params
      ? [`/warehouses/inventory/material/${materialId}`, { namespace, params }]
      : null
  );

  return {
    materialBatches: data?.records ?? [],
    pageInfo: data?.pageInfo,
    isLoading: !error && !data && isValidating,
    refetchMaterialBatchesInInventory: setParams,
    mutateMaterialBatchesInInventory: mutate,
  };
}

export function useBatchIdsByMaterialId({
  materialId,
}: {
  materialId: string | null;
}) {
  const [params, setParams] = useState<
    Partial<
      { materialId: string; withDeleted: boolean } & CommonTableFilterParam &
        OffsetBased
    >
  >({});
  const { data, error, mutate } = useSWR<{
    records: {
      materialId: string;
      sapBatchId: string;
      subBatchId: string;
      batchId: string;
    }[];
    pageInfo: PageInfo;
  }>(
    materialId
      ? [
          `/warehouses/inventory/material/${materialId}`,
          { params, namespace: API_NAMESPACE.MATERIALS },
        ]
      : null
  );

  const pageInfo = useMemo(() => {
    const page =
      (data?.pageInfo?.offset ?? 0) / (data?.pageInfo?.limit ?? 20) + 1;
    const pageSize = data?.pageInfo?.limit ?? 20;
    return { ...(data?.pageInfo ?? {}), page, pageSize };
  }, [data]);

  return {
    data: data?.records ?? [],
    pageInfo,
    isLoading: !error && !data,
    setParams,
    mutate,
  };
}

export function useBatchInfo() {
  const [params, setParams] = useState<
    Partial<
      {
        materialId: string | null;
        materialBatchId: string | null;
      } & CommonTableFilterParam &
        OffsetBased
    >
  >();
  const { data, error, mutate } = useSWR<{
    records: {
      // batchStoredLoaderRecordId: number;
      loaderId: string;
      materialId: string;
      materialDescription: string;
      sapBatchId: string;
      subBatchId: string;
      // availableMaterialStockUnitQuantity: string;
      // inspectionMaterialStockUnitQuantity: string;
      // actualMaterialWeightUnitQuantity: string;
      // createdAt: string;
      // subBatchRemark: string | null;
      // sapBatchRemark: string | null;
      batchId: string;
    }[];
    pageInfo: PageInfo;
  }>(
    params?.materialId && params.materialBatchId
      ? [
          `/warehouses/inventory/material/${params?.materialId}/${params.materialBatchId}`,
          { params, namespace: API_NAMESPACE.MATERIALS },
        ]
      : null
  );

  const pageInfo = useMemo(() => {
    const page =
      (data?.pageInfo?.offset ?? 0) / (data?.pageInfo?.limit ?? 20) + 1;
    const pageSize = data?.pageInfo?.limit ?? 20;
    return { ...(data?.pageInfo ?? {}), page, pageSize };
  }, [data]);

  return {
    data: data?.records ?? [],
    pageInfo,
    isLoading: !error && !data,
    setParams,
    mutate,
  };
}

export function useBatchInfoByLoaderId() {
  const [params, setParams] = useState<
    Partial<
      {
        materialId: string | null;
        materialBatchId: string | null;
        loaderId: string | null;
      } & CommonTableFilterParam &
        OffsetBased
    >
  >();
  const { data, error, mutate } = useSWR<
    PaginatedResponse<{
      batchStoredLoaderRecordId: number;
      loaderId: string;
      materialId: string;
      sapBatchId: string;
      subBatchId: string;
      quantity: string;
      type: string;
      actualMaterialWeightUnitQuantity: string;
      createdAt: string;
      materialDescription: string;
      subBatchRemark: string | null;
      sapBatchRemark: string | null;
      batchId: string;
      availableMaterialStockUnitQuantity: string;
      inspectionMaterialStockUnitQuantity: string;
    }>
  >(
    params?.materialId && params.materialBatchId && params.loaderId
      ? [
          `/warehouses/inventory/material/${params?.materialId}/${
            params.materialBatchId
          }/${encodeURIComponent(params.loaderId)}`,
          { namespace: API_NAMESPACE.MATERIALS },
        ]
      : null
  );

  const pageInfo = useMemo(() => {
    const page =
      (data?.pageInfo?.offset ?? 0) / (data?.pageInfo?.limit ?? 20) + 1;
    const pageSize = data?.pageInfo?.limit ?? 20;
    return { ...(data?.pageInfo ?? {}), page, pageSize };
  }, [data]);

  return {
    data: data?.records ?? [],
    pageInfo,
    isLoading: !error && !data,
    setParams,
    mutate,
  };
}

export function useMaterialsByLocation(methods?: UseFormReturn<any>) {
  const [params, setParams] = useState<GetMaterialsParams>({});
  const { data, error } = useSWR(
    params ? ['/materials/by-location', { params, namespace }] : null
  );

  const pageInfo = useMemo(() => {
    const page =
      (data?.pageInfo?.offset ?? 0) / (data?.pageInfo?.limit ?? 20) + 1;
    const pageSize = data?.pageInfo?.limit ?? 20;
    return { ...(data?.pageInfo ?? {}), page, pageSize };
  }, [data]);

  const mutateGetMaterials = useCallback(
    (payload?: any) => {
      const filterState = methods?.getValues();
      const nextPageSize = payload?.nextPageSize ?? pageInfo?.pageSize;
      const nextPage = payload?.nextPage ?? pageInfo?.page;

      setParams({
        offset: (nextPage - 1) * nextPageSize,
        limit: nextPageSize,
        [filterState?.factory && 'factoryId']: filterState?.factory?.id ?? null,
        [filterState?.building && 'buildingId']:
          filterState.building?.id ?? null,
        [filterState?.floor && 'floorId']: filterState?.floor?.id ?? null,
        [filterState?.area && 'areaId']: filterState?.area?.id ?? null,
        [filterState?.zone && 'zoneId']: filterState?.zone?.id ?? null,
        [filterState?.stack && 'stackId']: filterState?.stack?.id ?? null,
      });
    },
    [methods, pageInfo]
  );

  return {
    materials: data?.materials || [],
    pageInfo,
    isLoading: !error && !data,
    mutateGetMaterials,
    setParams,
  };
}

export function getMaterialInfo(materialBarcode: string) {
  return request(`/warehouses/material-info/${materialBarcode}`, {
    namespace,
  }) as Promise<{
    materialSubBatchId: string;
    materialSapBatchId: string;
    materialInputUnit: string;
    materialDescription: string;
    materialStockUnit: string;
    stockOverInputUnitRatio: string;
    materialBatchId: string;
    materialId: string;
  }>;
}

export function useGetMaterialById(materialId?: string) {
  const { data, error } = useSWR(
    materialId ? [`/materials/${materialId}`, { namespace }] : null
  );
  return {
    material: data,
    isLoading: !error && !data,
  };
}
