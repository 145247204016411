import { CSSProperties, ReactNode } from 'react';
import classes from './page-layout.module.scss';
import { useNavigate } from 'react-router-dom';
import { Button, Icon, Typography } from '@mezzanine-ui/react';
import { ChevronLeftIcon } from '@mezzanine-ui/icons';

interface PageLayout {
  children: ReactNode;
  setGoBack?: boolean;
  goBackPath?: string;
}

export function PageLayout({
  children,
  setGoBack = false,
  goBackPath,
}: PageLayout) {
  const navigate = useNavigate();

  return (
    <div className={classes.host}>
      {setGoBack && (
        <div>
          <Button
            type="button"
            onClick={() => {
              if (goBackPath) {
                navigate(goBackPath);
                return;
              }

              if (window.history.state && window.history.state.key) {
                navigate(-1);
              } else {
                navigate('/', { replace: true });
              }
            }}
            prefix={
              <Icon
                style={{ color: '#8F8F8F' } as CSSProperties}
                icon={ChevronLeftIcon}
              />
            }
            variant="text"
          >
            <Typography color="text-secondary" variant="button2">
              返回上一頁
            </Typography>
          </Button>
        </div>
      )}
      {children}
    </div>
  );
}
