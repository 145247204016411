import type { ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Message } from '@mezzanine-ui/react';
import {
  defaultLocationIds,
  LocationControlContext,
} from './LocationControlContext';
import {
  AreaWithResource,
  BuildingWithResource,
  Factory,
  FloorWithResource,
  LocationControlResetContextValueFunc,
  LocationControlSelectFunc,
  LocationId,
  LocationIds,
  LocationLevel,
  ShelfWithResource,
  StackWithResource,
  ZoneWithResource,
} from '../types';
import { request } from '@solar/data';
import { LocationLevels } from '../LocationLevel';
import { Space } from 'antd';

interface LocationControlProviderProps {
  error?: boolean;
  children: ReactNode;
  onChange?: (payload: {
    activeId: string | null;
    locationIds: LocationIds;
  }) => void;
}

export function LocationControlProvider({
  children,
  error = false,
  onChange,
}: LocationControlProviderProps) {
  const [loading, setLoading] = useState(false);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [locationIds, setLocationIds] =
    useState<LocationIds>(defaultLocationIds);

  const level = useMemo(() => {
    return (
      Object.entries(locationIds) as [LocationLevel, LocationId | null][]
    ).reduce<LocationLevel | null>(
      (result, [level, id]) => (id ? level : result),
      null
    );
  }, [locationIds]);

  const getLocationHierarchy = useCallback(
    async (id: string, level: LocationLevel) => {
      try {
        setLoading(true);

        const res = await request(`/locations/${id}/hierarchy`, {
          params: { returnLevel: level },
          responseParser: (res) => res,
        });

        const result = await res.json();

        if (!res.ok || !result?.id) {
          Message.error('位置編號查無資料');
          return null;
        }

        switch (level) {
          case LocationLevel.FACTORY: {
            result as Factory;
            return Object.assign({}, defaultLocationIds, {
              [LocationLevel.FACTORY]: result.id,
            }) as LocationIds;
          }
          case LocationLevel.BUILDING: {
            result as BuildingWithResource;

            const floorOptionsResponse = await request(`/floors`, {
              params: { buildingId: result.id },
              responseParser: (res) => res,
            });

            const floorOptions = await floorOptionsResponse.json();

            return Object.assign({}, defaultLocationIds, {
              [LocationLevel.FACTORY]: result.factory.id,
              [LocationLevel.BUILDING]: result.id,
              [LocationLevel.FLOOR]: floorOptions?.[0]?.id ?? null,
            }) as LocationIds;
          }
          case LocationLevel.FLOOR: {
            result as FloorWithResource;
            return Object.assign({}, defaultLocationIds, {
              [LocationLevel.FACTORY]: result.building.factory.id,
              [LocationLevel.BUILDING]: result.building.id,
              [LocationLevel.FLOOR]: result.id,
            }) as LocationIds;
          }
          case LocationLevel.AREA: {
            result as AreaWithResource;
            return Object.assign({}, defaultLocationIds, {
              [LocationLevel.FACTORY]: result.floor.building.factory.id,
              [LocationLevel.BUILDING]: result.floor.building.id,
              [LocationLevel.FLOOR]: result.floor.id,
              [LocationLevel.AREA]: result.id,
            }) as LocationIds;
          }
          case LocationLevel.ZONE: {
            result as ZoneWithResource;
            return Object.assign({}, defaultLocationIds, {
              [LocationLevel.FACTORY]: result.area.floor.building.factory.id,
              [LocationLevel.BUILDING]: result.area.floor.building.id,
              [LocationLevel.FLOOR]: result.area.floor.id,
              [LocationLevel.AREA]: result.area.id,
              [LocationLevel.ZONE]: result.id,
            }) as LocationIds;
          }
          case LocationLevel.STACK: {
            result as StackWithResource;
            return Object.assign({}, defaultLocationIds, {
              [LocationLevel.FACTORY]:
                result.zone.area.floor.building.factory.id,
              [LocationLevel.BUILDING]: result.zone.area.floor.building.id,
              [LocationLevel.FLOOR]: result.zone.area.floor.id,
              [LocationLevel.AREA]: result.zone.area.id,
              [LocationLevel.ZONE]: result.zone.id,
              [LocationLevel.STACK]: result.id,
            }) as LocationIds;
          }
          case LocationLevel.SHELF: {
            result as ShelfWithResource;
            return Object.assign({}, defaultLocationIds, {
              [LocationLevel.FACTORY]:
                result.stack.zone.area.floor.building.factory.id,
              [LocationLevel.BUILDING]:
                result.stack.zone.area.floor.building.id,
              [LocationLevel.FLOOR]: result.stack.zone.area.floor.id,
              [LocationLevel.AREA]: result.stack.zone.area.id,
              [LocationLevel.ZONE]: result.stack.zone.id,
              [LocationLevel.STACK]: result.stack.id,
              [LocationLevel.SHELF]: result.id,
            }) as LocationIds;
          }
          default: {
            Message.error('位置階層設定錯誤');
            return null;
          }
        }
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const resetContextValue = useCallback<LocationControlResetContextValueFunc>(
    async (payload) => {
      switch (true) {
        case 'level' in payload: {
          const { id, level } = payload as { id: string; level: LocationLevel };
          const locationIds = await getLocationHierarchy(id, level);
          if (!locationIds) return;
          setActiveId(id);
          setLocationIds(locationIds);
          onChange?.({ activeId: id, locationIds });
          break;
        }
        case 'locationIds' in payload: {
          const { id, locationIds } = payload as {
            id: string;
            locationIds: LocationIds;
          };
          setActiveId(id);
          setLocationIds(locationIds);
          onChange?.({ activeId: id, locationIds });
          break;
        }
        default: {
          break;
        }
      }
    },
    [getLocationHierarchy, onChange]
  );

  const _onChange = useCallback(
    async (id: string, level: LocationLevel) => {
      const locationIds = await getLocationHierarchy(id, level);

      if (!locationIds) return;

      setActiveId(id);
      setLocationIds(locationIds);
      onChange?.({ activeId: id, locationIds });
    },
    [getLocationHierarchy, onChange]
  );

  const _onClear = useCallback(
    (targetLevel: LocationLevel) => {
      const targetLevelIndex = LocationLevels.findIndex(
        ({ level }) => level === targetLevel
      );

      const { nextActiveId, nextLocationIds } = LocationLevels.reduce<{
        nextActiveId: string | null;
        nextLocationIds: LocationIds;
      }>(
        (result, { level }, currentLevelIndex) => {
          return {
            nextActiveId:
              currentLevelIndex < targetLevelIndex
                ? locationIds[level]
                : result.nextActiveId,
            nextLocationIds: Object.assign({}, result.nextLocationIds, {
              [level]:
                currentLevelIndex < targetLevelIndex
                  ? locationIds[level]
                  : null,
            }),
          };
        },
        {
          nextActiveId: null,
          nextLocationIds: defaultLocationIds,
        }
      );

      setActiveId(nextActiveId);
      setLocationIds(nextLocationIds);
      onChange?.({ activeId: nextActiveId, locationIds: nextLocationIds });
    },
    [locationIds, onChange]
  );

  const onChangeBySVG = useCallback(
    (id: string, level: LocationLevel) => {
      if (id && level) {
        _onChange(id, level);
      }
    },
    [_onChange]
  );

  const onChangeByLocationPicker = useCallback<LocationControlSelectFunc>(
    (value, level) => {
      if (value) {
        _onChange(value.id, level);
      } else {
        _onClear(level);
      }
    },
    [_onChange, _onClear]
  );

  const onChangeByAllLevelSearch = useCallback<LocationControlSelectFunc>(
    (value, level) => {
      if (value) {
        _onChange(value.id, level);
      } else {
        _onClear(level);
      }
    },
    [_onChange, _onClear]
  );

  return (
    <LocationControlContext.Provider
      value={{
        level,
        error,
        activeId,
        loading,
        locationIds,
        resetContextValue,
        onChangeBySVG,
        onChangeByLocationPicker,
        onChangeByAllLevelSearch,
      }}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        {children}
      </Space>
    </LocationControlContext.Provider>
  );
}
