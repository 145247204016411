import { Button, Tab, TabPane, Tabs, Typography } from '@mezzanine-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { OrderPanel } from './OrderPanel';
import { ModalGroup, useModalGroupController } from '@solar/templates';
import { BarcodeScanModal } from './BarcodeScanModal';
import { pageOptions } from './page-options';

export function MaterialReturnOrderPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const modalGroupController = useModalGroupController([
    {
      name: 'SCAN',
      keepData: true,
    },
  ]);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h1">{'物料退貨'}</Typography>
        {/* <Button
          type="button"
          onClick={() => modalGroupController.openModal('SCAN', null)}
        >
          掃描條碼
        </Button> */}
      </div>
      <Tabs
        activeKey={location.pathname.split('/').at(-1)}
        onChange={(tabId) =>
          navigate(`/warehouse/material-return-order/${tabId}`)
        }
      >
        {pageOptions.map((tab) => (
          <TabPane key={tab.id} tab={<Tab>{tab.name}</Tab>}>
            <OrderPanel />
          </TabPane>
        ))}
      </Tabs>
      <ModalGroup {...modalGroupController}>
        <BarcodeScanModal />
      </ModalGroup>
    </div>
  );
}
