import { Button, Message, SelectValue } from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  FormFieldsDebug,
  FormFieldsWrapper,
} from '@mezzanine-ui/react-hook-form';
import { PageLayout } from '@solar/templates';
import { useCallback, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { LocationAutoCompleteField } from '../../Templates/LocationAndLoaderPicker/LocationAutoCompleteField';
import { API_NAMESPACE, getJWTPayload, request } from '@solar/data';
import moment from 'moment';
import { InventoryCountDocField } from './InventoryCountDocField';
import useSWR from 'swr';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InventoryTypeField, typesMap } from './InventoryTypeField';
import Decimal from 'decimal.js';
import Papa from 'papaparse';
import saveAs from 'file-saver';
import { DownloadPDFButton, Previewer } from './InventoryTakingPDF';

interface FormValues {
  inventoryTakingId: SelectValue;
  locationId: string;
  inventoryType: SelectValue;
}

const schema = yup.object().shape({
  inventoryTakingId: yup
    .object()
    .shape({
      id: yup.string().required('必填'),
      name: yup.string().required('必填'),
    })
    .required('必填'),
  locationId: yup.string().required('必填'),
  inventoryType: yup
    .object()
    .shape({
      id: yup.string().required(),
      name: yup.string().required(),
    })
    .required('必填'),
});

export function CreateInventoryCountDoc() {
  const methods = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const { handleSubmit, formState } = methods;

  let payload;
  try {
    payload = getJWTPayload();
  } catch {
    payload = {};
  }

  const { data, mutate } = useSWR<{
    takings: { id: string; name: string; createdAt: string }[];
  }>([
    '/warehouses/takings',
    {
      namespace: API_NAMESPACE.MATERIALS,
    },
  ]);

  const takings = data?.takings ?? [];

  const [creating, setCreating] = useState(false);
  const createInventoryCountDoc = useCallback(async () => {
    setCreating(true);
    try {
      await request('/warehouses/takings', {
        namespace: API_NAMESPACE.MATERIALS,
        method: 'POST',
        body: JSON.stringify({
          name: moment().format('YYYYMMDD-HHmm'),
        }),
      });

      await mutate(undefined, true);
    } finally {
      setCreating(false);
    }
  }, []);

  const [downloading, setDownloading] = useState(false);
  const downloadInventoryCountDoc = useCallback(() => {
    setDownloading(true);
    try {
      handleSubmit(async (formValues) => {
        const inventoryTakingId = formValues.inventoryTakingId?.id;
        const locationId = formValues?.locationId;
        const inventoryType = formValues?.inventoryType?.id;

        const response = await request(
          `/warehouses/takings/${inventoryTakingId}/results`,
          {
            namespace: API_NAMESPACE.MATERIALS,
            method: 'GET',
            params: {
              locationId,
              inventoryType,
            },
          }
        );

        const fieldsMap = new Map([
          ['serial', '序號'],
          ['diffQuantityWithSAP', '差異數量（外圍減 SAP）'],
          ['materialDescription', '品名'],
          ['materialId', '料號'],
          ['materialSapBatchId', '母批號'],
          ['zoneId', '倉位'],
          ['type', '狀態'],
          ['salesOrderId', '銷售訂單號'],
          ['salesOrderLine', '項次'],
          ['sapTotalQuantity', '倉位總量（基礎單位）'],
          ['unit', '單位'],
          ['materialSubBatchId', '子批號'],
          ['shelfId', '儲位'],
          ['totalQuantity', '儲位量'],
          ['unit', '單位'],
          ['actualMaterialWeightUnitQuantity', '實際重量'],
          ['weightUnit', '單位'],
          ['filledQuantity', '現場盤點量'],
          ['filledUnit', '單位'],
          ['filledActualWeight', '現場實秤'],
          ['weightUnit', '單位'],
        ]);

        const results = response?.results?.map((item: any) => {
          const hasRatio = !isNaN(item?.secondaryUnitRatio ?? NaN);
          const weightUnit = 'G';
          const unit = hasRatio ? item?.secondaryUnit : item?.materialStockUnit;
          const sapTotalQuantity = hasRatio
            ? new Decimal(item?.sapQuantity ?? 0)
                .div(item?.secondaryUnitRatio)
                .toString()
            : new Decimal(item?.sapQuantity ?? 0).toString();
          const totalQuantity = hasRatio
            ? new Decimal(item?.secondaryUnitQuantity ?? 0).toString()
            : new Decimal(item?.quantity ?? 0).toString();
          const diffQuantityWithSAP = new Decimal(totalQuantity)
            .minus(sapTotalQuantity)
            .toString();

          const prevData = {
            ...item,
            quantity: item.quantity?.toFixed(2),
            type: typesMap.get(item.type),
            diffQuantityWithSAP,
            sapTotalQuantity,
            totalQuantity,
            weightUnit,
            unit,
          };

          return Array.from(fieldsMap).reduce<Record<string, unknown>>(
            (acc, [value, key]) => {
              return Object.assign(
                {
                  [key]: prevData[value],
                },
                acc
              );
            },
            {}
          );
        });

        const columns = Array.from(fieldsMap.values());

        const csv = Papa.unparse(results, {
          quotes: false,
          quoteChar: '"',
          escapeChar: '"',
          delimiter: ',',
          header: true,
          newline: '\r\n',
          skipEmptyLines: false,
          columns,
        });

        const csvWithBom = '\uFEFF' + csv;

        const blob = new Blob([csvWithBom], {
          type: 'text/csv;charset=utf-8;',
        });

        const filename =
          takings?.find((taking) => taking.id === inventoryTakingId)?.name ??
          moment().format('YYYYMMDD-HHmm');

        saveAs(blob, `${filename}.csv`);
      })();
    } finally {
      setDownloading(false);
    }
  }, []);

  const { inventoryTakingId, locationId } = useWatch({
    control: methods.control,
  });

  const inventoryTaking = takings.find(
    (taking) => taking.id === inventoryTakingId?.id
  );

  return (
    <PageLayout title="建立盤點與產生清冊">
      <Button
        type="button"
        variant="outlined"
        loading={creating}
        style={{ width: 150 }}
        onClick={createInventoryCountDoc}
      >
        建立盤點文件
      </Button>
      <FormFieldsWrapper
        methods={methods}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 'var(--mzn-spacing-4)',
        }}
      >
        <InventoryCountDocField />
        <LocationAutoCompleteField registerName="locationId" label="倉庫範圍" />
        <InventoryTypeField />
      </FormFieldsWrapper>
      <Button
        type="button"
        loading={downloading}
        variant="outlined"
        style={{ width: 150 }}
        onClick={downloadInventoryCountDoc}
      >
        下載盤點清冊
      </Button>
      {inventoryTaking && payload?.Account && locationId && (
        <>
          <DownloadPDFButton
            name={inventoryTaking?.name}
            createdAt={inventoryTaking?.createdAt}
            locationId={locationId}
            username={payload?.Account}
          />
          <Previewer
            name={inventoryTaking?.name}
            createdAt={inventoryTaking?.createdAt}
            locationId={locationId}
            username={payload?.Account}
          />
        </>
      )}
    </PageLayout>
  );
}
