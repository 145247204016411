import { MoreVerticalIcon, PlusIcon } from '@mezzanine-ui/icons';
import {
  Button,
  Icon,
  MenuItem,
  Popover,
  Table,
  Typography,
} from '@mezzanine-ui/react';
import { useHumanResourceBUManage } from '@solar/data';
import { useState } from 'react';
import { DeleteModal, EditModal } from '../BUManageModals/BUManageModals';
import styles from './HumanResourceBUManagePage.module.scss';

enum ModalType {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export function HumanResourceBUManagePage() {
  const { data: manageList } = useHumanResourceBUManage();
  const [popMenu, setPopMenu] = useState<{
    anchor: HTMLButtonElement;
    source: { name: string; id: string };
  } | null>(null);
  const [modal, setModal] = useState<{
    type: ModalType;
    id?: string;
    name?: string;
  } | null>(null);

  const columns = [
    { title: '#', dataIndex: 'id' },
    { title: 'BU 名稱', dataIndex: 'name', width: 550 },
    { title: '下屬部門數量', dataIndex: 'unitCount', width: 282 },
    {
      title: '動作',
      width: 60,
      render(source: any) {
        return (
          <Button
            type="button"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();

              const getButtonElement = (
                elem = e.target
              ): HTMLButtonElement | null => {
                if (elem instanceof HTMLButtonElement) return elem;

                const parent = (elem as HTMLElement).parentNode;

                if (!parent) return null;
                return getButtonElement(parent);
              };

              const anchor = getButtonElement();

              if (anchor) {
                setPopMenu({
                  anchor,
                  source,
                });
              } else {
                setPopMenu(null);
              }
            }}
            prefix={<Icon icon={MoreVerticalIcon} />}
          />
        );
      },
    },
  ];

  const onClose = () => {
    setModal(null);
  };

  return (
    <div>
      {!modal ? null : modal.type === ModalType.EDIT ? (
        <EditModal onClose={onClose} id={modal.id} name={modal.name} />
      ) : (
        <DeleteModal onClose={onClose} id={modal.id} name={modal.name} />
      )}
      <div className={styles.flexBetween}>
        <Typography variant="h1">BU 管理</Typography>
        <Button
          type="button"
          onClick={() => setModal({ type: ModalType.EDIT })}
          prefix={<Icon icon={PlusIcon} />}
        >
          新增 BU
        </Button>
      </div>
      <div className={styles.border}></div>
      <Table
        dataSource={manageList ?? []}
        columns={columns}
        bodyClassName={styles.table}
      />
      <Popover
        anchor={popMenu?.anchor}
        open={!!popMenu}
        onClose={() => setPopMenu(null)}
      >
        <MenuItem>
          <Button
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
            type="button"
            onClick={() => {
              if (!popMenu) return;
              setModal({
                type: ModalType.EDIT,
                id: popMenu.source.id,
                name: popMenu.source.name,
              });
              setPopMenu(null);
            }}
          >
            <Typography variant="button1" color="primary" align="left">
              編輯
            </Typography>
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
            type="button"
            onClick={() => {
              if (!popMenu) return;
              setModal({
                type: ModalType.DELETE,
                id: popMenu.source.id,
                name: popMenu.source.name,
              });
              setPopMenu(null);
            }}
          >
            <Typography variant="button1" color="primary" align="left">
              刪除
            </Typography>
          </Button>
        </MenuItem>
      </Popover>
    </div>
  );
}
