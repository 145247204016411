import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import { Message, ModalActions, Typography } from '@mezzanine-ui/react';
import { useForm } from 'react-hook-form';
import { ModalLayout } from '../ModalLayout/ModalLayout';
import FormBasicLayout from '../FormLayout/FormBasicLayout';
import { LocationIdsField, RowSection, useTargetModal } from '@solar/templates';
import {
  InventoryMiscByMaterialResponseData,
  shipWarehousesInventoryMiscMaterial,
} from '@solar/data';
import { MiscellaneousDispatchAndReturnModal } from './enum';

export function MiscellaneousDispatchModal({ methods, openLocationSelectorModal }: any) {
  const modalController = useTargetModal<InventoryMiscByMaterialResponseData>(
    MiscellaneousDispatchAndReturnModal.Dispatch
  );

  return (
    <ModalLayout
      modalHeader="發料"
      loading={methods?.formState?.isSubmitting}
      modalFooter={
        <ModalActions
          confirmText="確認"
          cancelText="取消"
          onCancel={() => modalController.closeModal()}
          confirmButtonProps={{
            form: MiscellaneousDispatchAndReturnModal.Dispatch,
          }}
          onConfirm={async () => {
            try {
              const values = methods.getValues();
              const { mainMaterialUnitQuantity, userId } = values;
              if (!modalController.data) return;
  
              const res: Array<{ id: number }> =
                await shipWarehousesInventoryMiscMaterial({
                  items: [
                    {
                      materialBarcode: modalController.data.materialBarcode,
                      userId: userId,
                      mainMaterialUnitQuantity: Number(mainMaterialUnitQuantity),
                      secondaryMaterialUnitQuantity: 0,
                    },
                  ],
                });
  
              if (res[0].id) {
                Message.success('發料成功');
                modalController?.closeModal();
              }
            } catch (err) {
              Message.error(JSON.stringify(err));
            }
          }}
        />
      }
      {...modalController}
    >
      <FormFieldsWrapper
        id={MiscellaneousDispatchAndReturnModal.Dispatch}
        methods={methods}
      >
        <FormBasicLayout>
          <RowSection label="料號：">
            {(modalController?.data ?? {})?.materialId}
          </RowSection>
          <RowSection label="品名：">
            {(modalController?.data ?? {})?.materialDescription}
          </RowSection>
          <RowSection label="批號：">
            {(modalController?.data ?? {})?.batchId}
          </RowSection>
          <RowSection label="儲位：">
            <LocationIdsField
              mode="single"
              rowProps={{ align: 'middle' }}
              methods={methods}
              registerName="locationIds"
              openModal={openLocationSelectorModal} />
          </RowSection>
          <RowSection label="數量：">
            <InputField
              type="number"
              width={480}
              registerName="mainMaterialUnitQuantity"
            />
          </RowSection>
          <RowSection label="領用人：">
            <InputField width={480} registerName="userId" />
          </RowSection>
        </FormBasicLayout>
      </FormFieldsWrapper>
    </ModalLayout>
  );
}
