import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { API_NAMESPACE, request } from '../request';

const namespace = API_NAMESPACE.MATERIALS;

export type UnitConversionRatio = {
  materialId: string;
  materialStockUnit: string;
  materialInputUnit: string;
  stockOverInputUnitRatio: string;
  materialStockUnitName: string;
  materialInputUnitName: string;
};

export function useUnitConversionRatiosByMaterialId({
  materialId,
}: {
  materialId?: string;
}) {
  const [params, setParams] = useState<
    Partial<Pick<UnitConversionRatio, 'materialId'>>
  >({ materialId });

  const { data, error } = useSWR<[UnitConversionRatio]>(
    params?.materialId
      ? [
          `/materials/${params?.materialId}/unit-conversion-ratios`,
          { namespace },
        ]
      : null
  );

  useEffect(() => {
    setParams({ materialId });
  }, [materialId]);

  return {
    data,
    isLoading: !data && !error,
    setParams,
  };
}

export function getUnitConversionRatiosByMaterialId({
  materialId,
}: {
  materialId: string;
}) {
  return request(`/materials/${materialId}/unit-conversion-ratios`, {
    namespace,
    method: 'get',
  }) as Promise<UnitConversionRatio[]>;
}
