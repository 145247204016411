export enum RepairRequestPriority {
  NORMAL = 'NORMAL',
  PRIORITY = 'PRIORITY',
  EMERGENCY = 'EMERGENCY',
}

export const RepairRequestPriorityOptions = [
  { id: RepairRequestPriority.NORMAL, name: '一般' },
  { id: RepairRequestPriority.PRIORITY, name: '急件' },
  { id: RepairRequestPriority.EMERGENCY, name: '特急' },
];

export enum RepairRequestStatus {
  INITED = 'INITED',
  WAITING_SUPPLIER = 'WAITING_SUPPLIER',
  WORKING_IN_PROGRESS = 'WORKING_IN_PROGRESS',
  REJECTED = 'REJECTED',
  RESOLVED = 'RESOLVED',
  CANCELLED = 'CANCELLED',
}

export const repairRequestStatusOptions = [
  { id: RepairRequestStatus.RESOLVED, name: '完成' },
  { id: RepairRequestStatus.WAITING_SUPPLIER, name: '等待廠商' },
  { id: RepairRequestStatus.WORKING_IN_PROGRESS, name: '處理中' },
  { id: RepairRequestStatus.INITED, name: '待處理' },
];

export enum RepairRequestTargetFinishedDays {
  TODAY = 'TODAY',
  TWO_2_FOUR = 'TWO_2_FOUR',
  FIVE_2_SEVEN = 'FIVE_2_SEVEN',
  OVER_A_WEEK = 'OVER_A_WEEK',
}

export const repairRequestTargetFinishedDayOptions = [
  { id: RepairRequestTargetFinishedDays.TODAY, name: '1天內' },
  { id: RepairRequestTargetFinishedDays.TWO_2_FOUR, name: '2-4天' },
  { id: RepairRequestTargetFinishedDays.FIVE_2_SEVEN, name: '5-7天' },
  { id: RepairRequestTargetFinishedDays.OVER_A_WEEK, name: '7天以上' },
];
