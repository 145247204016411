import { useMemo, useState } from 'react';
import { API_NAMESPACE, request } from '../request';
import { ProductionTrackingModel } from './productionTracking.type';
import useSWR from 'swr';
import { APIResponse } from '../drawings';

const namespace = API_NAMESPACE.DRAWINGS;

export function useGetProductionTrackingResult() {
  const [params, setParams] = useState<{
    sapBatchId?: string;
    subBatchId?: string;
    materialId?: string;
  }>();
  const { data, error, mutate } = useSWR<
    APIResponse<Array<ProductionTrackingModel>>
  >([
    `/pp/material-produce-history/${params?.materialId}/${params?.sapBatchId}/${params?.subBatchId}`,
    { params, namespace },
  ]);

  return {
    results: data?.data ?? [],
    isLoading: !error && !data,
    refetchGetProductionTrackingResult: setParams,
    mutateGetProductionTrackingResult: mutate,
  };
}
