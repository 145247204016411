import {
  CalculateSuggestedMaterialAllocationsResult,
  InventoryByMaterialSearchType,
  InventoryItem,
  MaterialAllocationFormValues,
  getInventoryByMaterial,
} from '@solar/data';
import { Space, notification } from 'antd';
import Decimal from 'decimal.js';
import { useCallback, useState } from 'react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';

export function useManualData({
  methods,
  suggestedData,
}: {
  methods?: UseFormReturn<MaterialAllocationFormValues>;
  suggestedData?: CalculateSuggestedMaterialAllocationsResult['data']['records'];
}) {
  const manualDataMethods = useFieldArray({
    control: methods?.control,
    name: 'manualData',
  });

  const [appending, setAppending] = useState(false);

  const getDiffSelectedIds = useCallback(
    (selectedIds: string[]) => {
      const manualData = methods?.getValues()?.manualData ?? [];
      const manualDataIdSet = new Set(
        manualData?.map((item) => item?.batchStoredLoaderRecordId?.toString())
      );
      return selectedIds.filter((id) => !manualDataIdSet.has(id));
    },
    [methods]
  );

  const getSuggestedMaterialUnitQuantity = useCallback(
    (batchStoredLoaderRecordId: string) => {
      return (
        suggestedData?.find(
          (item) =>
            item?.batchStoredLoaderRecordId?.toString() ===
            batchStoredLoaderRecordId
        )?.suggestedMaterialUnitQuantity ?? '0'
      );
    },
    [suggestedData]
  );

  const appendManualDataBySelectedIds = useCallback(
    async (
      selectedIds: string[],
      metadata?: MaterialAllocationFormValues['manualData']
    ) => {
      try {
        setAppending(true);
        const diffSelectedIds = getDiffSelectedIds(selectedIds);

        if (!(diffSelectedIds?.length > 0)) {
          return;
        }

        const response = await getInventoryByMaterial({
          type: InventoryByMaterialSearchType.MATERIAL_ID,
          batchStoredLoaderRecordIds: diffSelectedIds,
          withElementRatio: true,
        });

        const getTargetManualData = (record: InventoryItem) => {
          return metadata?.find(
            (item) =>
              item?.batchStoredLoaderRecordId?.toString() ===
              record?.batchStoredLoaderRecordId?.toString()
          );
        };

        const units = ['G', 'KG'];

        const manualData =
          response?.records?.map((record) => ({
            ...record,
            id: record?.batchStoredLoaderRecordId?.toString(),
            batchStoredLoaderRecordId:
              record?.batchStoredLoaderRecordId?.toString(),
            materialID: record?.materialId,
            usedMaterialUnitQuantity: getSuggestedMaterialUnitQuantity(
              record?.batchStoredLoaderRecordId?.toString()
            ),
            usedOldMaterial:
              getTargetManualData(record)?.usedOldMaterial ?? 'true',
            requestOrderStockUnitQuantity: getSuggestedMaterialUnitQuantity(
              record?.batchStoredLoaderRecordId?.toString()
            ),
          })) ?? [];

        const invalidData = manualData?.filter(
          (item) => !units.includes(item?.materialStockUnit?.toUpperCase())
        );

        const validData = manualData?.filter((item) =>
          units.includes(item?.materialStockUnit?.toUpperCase())
        );

        if ((invalidData?.length ?? 0) > 0) {
          notification.error({
            duration: 0,
            message: (
              <div>
                以下料號單位非 G, KG 無法進行配料
                <ul>
                  {invalidData?.map((item) => (
                    <li>{`#${item?.batchStoredLoaderRecordId}`}</li>
                  ))}
                </ul>
              </div>
            ),
          });
        }

        manualDataMethods?.append(validData);
      } catch (error) {
        console.log(error);
      } finally {
        setAppending(false);
      }
    },
    [getDiffSelectedIds, getSuggestedMaterialUnitQuantity, manualDataMethods]
  );

  const removeManualDataBySelectedIds = useCallback(
    (selectedIds: string[]) => {
      const indexes = selectedIds
        ?.map((id) =>
          manualDataMethods?.fields?.findIndex(
            (field) => field?.batchStoredLoaderRecordId?.toString() === id
          )
        )
        ?.filter((index) => index !== -1);
      manualDataMethods?.remove(indexes);
    },
    [manualDataMethods]
  );

  return {
    appending,
    appendManualDataBySelectedIds,
    removeManualDataBySelectedIds,
  };
}
