import { PageLayout } from '@solar/templates';
import { useIngredientSearch, IComponentItem } from '@solar/data';
import { useForm, useFieldArray } from 'react-hook-form';
import { SelectValue, Button, MenuDivider, Table, Typography, Tag } from '@mezzanine-ui/react';
import { AutoCompleteField, SelectField, InputField, FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import styles from './IngredientSearch.module.scss';
import { useMemo } from 'react';
import { TablePagination } from '@mezzanine-ui/core/table';


const unitOptions: SelectValue[] = [
  { id: 'wt', name: 'wt%' },
  { id: 'at', name: 'at%' }
];

interface IIngredient {
  unit: SelectValue | null;
  componentItemNumber: SelectValue | null;
  value: string;
}


interface ISearchForm {
  items: IIngredient[];
  componentCode: SelectValue | null;
}

const defaultValues = {
  items: [
    {
      unit: null,
      componentItemNumber: null,
      value: '',
    }
  ]
};


const IngredientSearch = () => {
  const searchForm = useForm<ISearchForm>({ defaultValues });
  const { fields, append, remove } = useFieldArray({
    control: searchForm.control,
    name: 'items',
  });
  const {
    componentNames,
    autoCompleteOptionsComponentCodes,
    searchIngredient,
    serachResult,
    searchErrorMessage,
    pageInfo,
    onPageChange,
    searchLoading,
  } = useIngredientSearch();

  const componentNameOptions: SelectValue[] = useMemo(() => componentNames ? (

    componentNames.map((name: string) => ({
      id: name,
      name,
    }))
  ) : [], [componentNames]);


  // 搜尋成分
  const onFormSubmit = (data: ISearchForm) => {
    const items = data.items.map((ingredient) => ({
      ...ingredient,
      componentItemNumber: ingredient.componentItemNumber?.id,
      unit: ingredient.unit?.id,
    }));
    const newPageInfo = {
      current: 1,
      pageSize: 10,
      total: 0,
    };
    const componentCode = data.componentCode?.id || '';
    searchIngredient(items, newPageInfo, componentCode);
  };

  // console.log('serachResult', serachResult);

  const tableColumns = [
    {
      title: '成分代碼',
      dataIndex: 'componentCode',
    }, {
      title: '成分名稱',
      dataIndex: 'componentItemNumber',
    },
    {
      title: '詳細成份',
      render: (record: any) => (record && Array.isArray(record.items)) ? (
        <div>
          {
            record.items.map(
              (item: IComponentItem) => (
                <div key={item.id} className={styles.itemSpace}>
                  <div className={styles.itemCompnentName}>{item.componentItemNumber}</div>
                  <div className={styles.itemUnit}>wt:{item.wtRate}%</div>
                  <div className={styles.itemUnit}>at:{item.atRate}%</div>
                </div>
              )
            )
          }
        </div>
      ) : '-'
    }
  ];

  const pagination: TablePagination = {
    current: pageInfo?.current || 1,
    total: pageInfo?.total || 0,
    onChange: onPageChange,
    disableAutoSlicing: true,
  };

  return (
    <PageLayout
      title="成分查詢"
    >
      <div>
        <FormFieldsWrapper methods={searchForm} onSubmit={onFormSubmit}>
          <AutoCompleteField
            label="成分代碼"
            placeholder="請選擇成分代碼"
            registerName="componentCode"
            options={autoCompleteOptionsComponentCodes}
          />
          <div style={{ marginBottom: 12 }} />
          {
            fields.map((field, index) => (
              <div key={field.id} className={styles.space}>
                <AutoCompleteField
                  label="成分"
                  placeholder="請選擇成分"
                  registerName={`items.${index}.componentItemNumber`}
                  options={componentNameOptions}
                />
                <SelectField
                  label="單位"
                  placeholder="請選擇單位"
                  registerName={`items.${index}.unit`}
                  options={unitOptions}
                />
                <InputField
                  label="數值"
                  placeholder="請輸入數值"
                  registerName={`items.${index}.value`}
                  width={100}
                />
                <Button
                  disabled={index === 0}
                  type="button"
                  onClick={() => remove(index)}
                >
                  刪除條件
                </Button>
              </div>
            ))
          }
          <MenuDivider style={{ marginBottom: 12 }} />
          <Button type="button" onClick={() => append({ unit: null, componentItemNumber: null, value: '' })}>新增條件</Button>
          <Button type="submit" variant="contained">查詢</Button>
          {
            searchErrorMessage && (
              <Typography color="error">
                {searchErrorMessage}
              </Typography>
            )
          }
        </FormFieldsWrapper>
      </div>
      <Table
        dataSource={serachResult}
        columns={tableColumns}
        pagination={pagination}
        loading={searchLoading}
      />
    </PageLayout>
  );
};

export default IngredientSearch;