import { TableColumn } from '@mezzanine-ui/core/table';
import { Button, Table } from '@mezzanine-ui/react';
import sharedStyles from '../shared-styles/index.module.scss';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTargetModal } from '../../Templates/Modal/ModalGroup';
import { useCallback } from 'react';
import { BarcodeScanInput } from '../MaterialShift/BarcodeScanInput';
import { RowSection } from '@solar/templates';
import { ModalLayout } from '../ModalLayout/ModalLayout';
import { CloseOutlined } from '@ant-design/icons';

type BarcodeScanModalForm = {
  orderId: string;
  quantity: number;
};

export function BarcodeScanModal() {
  const { open, closeModal } = useTargetModal('SCAN');
  const tableFormMethods = useForm<{
    table: BarcodeScanModalForm[];
  }>({
    defaultValues: {
      table: [{ orderId: 'test1', quantity: 10 }],
    },
  });
  const { fields, remove, append } = useFieldArray({
    control: tableFormMethods.control,
    name: 'table',
  });
  const column: TableColumn<{ id: string } & BarcodeScanModalForm>[] = [
    { title: '來源單號', dataIndex: 'orderId' },
    { title: '料號', dataIndex: 'materialId' },
    { title: '品名', dataIndex: 'materialDescription' },
    { title: '批號', dataIndex: 'batchId' },
    { title: '儲位', dataIndex: 'stackId' },
    { title: '載具', dataIndex: 'loader' },
    {
      title: '退貨數量',
      render: (source, index) => (
        <InputField
          valueAsNumber
          key={source.id}
          registerName={`table[${index}].quantity`}
        />
      ),
    },
    {
      title: '',
      render: (_, index) => (
        <Button
          prefix={<CloseOutlined />}
          onClick={() => remove(index)}
        ></Button>
      ),
    },
  ];

  const onCloseModal = useCallback(() => {
    closeModal();
    tableFormMethods?.reset();
  }, [closeModal, tableFormMethods]);

  return (
    <ModalLayout open={open} closeModal={onCloseModal}>
      <RowSection label="物料條碼">
        <BarcodeScanInput
          otherOnKeyDownAction={async (inputValue) => {
            // TODO: 接 api
            append({ orderId: inputValue ?? '', quantity: 0 });
          }}
        />
      </RowSection>
      <FormFieldsWrapper methods={tableFormMethods}>
        <Table
          bodyClassName={sharedStyles.table}
          columns={column}
          dataSource={fields}
        />
      </FormFieldsWrapper>
    </ModalLayout>
  );
}
