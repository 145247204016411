import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from '@mezzanine-ui/react';
import styles from './location-selector.module.scss';
import { useCallback } from 'react';
import {
  useTargetModal,
  LocationSelectorGroup,
  useLocationSelectorController,
  useLocationSelectorContext,
  UseLocationSelectorControllerReturn,
} from '@solar/templates';
import { SelectedIds } from '@solar/data';

export const LOCATION_SELECTOR = 'LOCATION_SELECTOR';

type LocationSelectorGroupModalProps = {
  open?: boolean;
  onSubmit: (selectedId?: string, selectedIds?: SelectedIds) => void;
  closeModal?: () => void;
  headerText?: string;
  showShelfSvg?: boolean;
  controller?: UseLocationSelectorControllerReturn;
};

export function LocationSelectorGroupModal({
  open,
  onSubmit,
  closeModal,
  headerText = '設備位置',
  showShelfSvg = false,
  controller,
}: LocationSelectorGroupModalProps) {
  const modalController = useTargetModal(LOCATION_SELECTOR);
  const locationController = controller ?? useLocationSelectorController({});
  const { activeId, resetLocationSelector, selectedIds } = locationController;

  const modalOpen = open ?? modalController?.open ?? false;

  const onClose = useCallback(() => {
    resetLocationSelector();
    (closeModal ?? modalController?.closeModal)?.();
  }, [closeModal, modalController]);

  const handleSubmit = () => {
    onSubmit(activeId, selectedIds);
    onClose();
  };

  return (
    <Modal className={styles.modal_wrapper} open={modalOpen} onClose={onClose}>
      <ModalHeader>{headerText}</ModalHeader>
      <ModalBody>
        <LocationSelectorGroup
          showShelfSvg={showShelfSvg}
          controller={locationController}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="outlined" onClick={onClose}>
          取消
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          確定
        </Button>
      </ModalFooter>
    </Modal>
  );
}
