import type { UseFormReturn } from 'react-hook-form';
import {
  type HubOrderUpdateFormValues,
  HubOrderUpdateFormFieldName,
} from './useHubOrderUpdate';
import { request, API_NAMESPACE } from '../../request';
import useSWR from 'swr';
import * as yup from 'yup';

export type OrderDetail = {
  deliveryOrderDetailId: number;
  id: number;
  line: string;
  materialId: string;
  netWeight: number | null; // 總淨重
  grossWeight: number | null; // 總毛重
  cbm: string | null; // 總材積
  // 新增新的屬性
  detailId: number;
  batchId: string;
  batchSn: string;
  customerBatchSn: string;
  quantityUnit: string;
};

export type HubOrderDetailData = {
  id: string;
  sourceDeliveryOrderIdOptions: string[];
  customerName: string;
  shipDate: string;
  debitDate: string;
  reverseDate: '';
  details: OrderDetail[];
  shelfId: string;
  warehouseOptions: WarehouseOption[];
};

export type WarehouseOption = {
  id: number;
  materialId: string;
  sapBatchId: string;
  subBatchId: string;
  barcode: string;
  quantity: string;
  deliveryOrderId: string;
  deliveryOrderLine: string;
};

type HookProps = {
  hubDeliveryOrderId: string | null;
  formMethods: UseFormReturn<HubOrderUpdateFormValues>;
  errorCallback?: (error: Error) => void;
  sourceHubDeliveryOrderId?: string | null;
  type?: string | null;
};

export function useHubOrderDetailGet({
  hubDeliveryOrderId,
  sourceHubDeliveryOrderId = null,
  type = null,
  formMethods,
  errorCallback,
}: HookProps) {
  // 初始化 BATCH_SN_ITEMS 欄位的數組
  const requestHandler: typeof request = async (endpoint, requestOptions) => {
    return request(endpoint, requestOptions).then((data) => {
      formMethods.setValue(
        HubOrderUpdateFormFieldName.BATCH_ITEMS,
        data?.details?.map((item: OrderDetail) => ({
          [HubOrderUpdateFormFieldName.HUB_DELIVERY_ORDER_LINE]: item?.line,
          [HubOrderUpdateFormFieldName.DETAIL_ID]: item?.id,
          [HubOrderUpdateFormFieldName.BATCH_ID]: item?.batchId ?? '',
          [HubOrderUpdateFormFieldName.BATCH_SN]: item?.batchSn ?? '',
          [HubOrderUpdateFormFieldName.CUSTOMER_BATCH_SN]:
            item?.customerBatchSn ?? '',
          [HubOrderUpdateFormFieldName.NET_WEIGHT]: item?.netWeight,
          [HubOrderUpdateFormFieldName.GROSS_WEIGHT]: item?.grossWeight,
        }))
      );
      return data;
    });
  };

  const { data, mutate, isValidating } = useSWR<HubOrderDetailData>(
    validateSWRKey(hubDeliveryOrderId, sourceHubDeliveryOrderId, type),
    requestHandler,
    {
      revalidateOnFocus: false,
      onError: (error) => errorCallback?.(error),
    }
  );

  return {
    hubOrderDetail: data,
    refetchHubOrderDetail: mutate,
    isLoading: isValidating,
  };
}

function validateSWRKey(
  hubDeliveryOrderId: string | null,
  sourceHubDeliveryOrderId: string | null,
  type: string | null
) {
  try {
    const safeHubDevlieryOrderId = yup
      .string()
      .validateSync(hubDeliveryOrderId);

    if (type) {
      const safeType = yup.string().validateSync(type);
      return [
        `/hubDeliveryOrders/get/${safeHubDevlieryOrderId}?type=${safeType}`,
        {
          namespace: API_NAMESPACE.HR,
        },
      ];
    }

    return [
      `/hubDeliveryOrders/get/${safeHubDevlieryOrderId}`,
      {
        namespace: API_NAMESPACE.HR,
      },
    ];
  } catch (error) {
    return false;
  }
}

export function fetchReceivingHubOrderOptionsGet({
  hubDeliveryOrderId,
  sourceHubDeliveryOrderId = null,
  type = null,
  errorCallback,
  successCallback,
}: Omit<HookProps, 'formMethods'> & { successCallback?: (data: any) => void }) {
  const safeType = yup.string().validateSync(type);
  const safeSourceHubDeliveryOrderId = yup
    .string()
    .validateSync(sourceHubDeliveryOrderId);
  return request(
    `/hubDeliveryOrders/get/${hubDeliveryOrderId}?type=${type}&sourceHubDeliveryOrderId=${safeSourceHubDeliveryOrderId}`,
    {
      namespace: API_NAMESPACE.HR,
    }
  )
    .then((data) => {
      successCallback?.(data);
    })
    .catch((e) => {
      errorCallback?.(e);
    });
}
