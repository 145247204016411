import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAddClickEventListenerInLocationSVG } from './useAddClickEventListenerInLocationSVG';
import { useInitElementsInLocationSVG } from './useInitElementsInLocationSVG';
import { request } from '@solar/data';
import { Empty, Loading } from '@mezzanine-ui/react';
import styles from './location-svg.module.scss';
import { useLocationControlContext } from '../LocationControlContext';
import { LocationLevels } from '../LocationLevel';
import { LocationSVGPopover } from './LocationSVGPopover';

export function LocationSVG() {
  const [svgLoading, setSVGLoading] = useState(false);
  const [svgContent, setSVGContent] = useState<string | undefined>();
  const svgWrapperRef = useRef<HTMLDivElement>(null);
  const { locationIds, level } = useLocationControlContext();

  const svgId = useMemo(() => {
    const targetLevelIndex = LocationLevels.findIndex(
      (config) => config.level === level
    );
    return LocationLevels.reduce<string | null>(
      (result, config, currentLevelIndex) => {
        if (currentLevelIndex <= targetLevelIndex && !!config?.svg?.show) {
          return locationIds[config.level] || result;
        }
        return result;
      },
      null
    );
  }, [level, locationIds]);

  const getSVGContext = useCallback(async (filename: string) => {
    try {
      setSVGLoading(true);

      const response = await request(`/svgs/${filename}.svg`, {
        method: 'get',
        responseParser: (res) => res,
      });

      const svgText = await response.text();

      setSVGContent(svgText);
    } catch (error) {
      setSVGContent(undefined);
    } finally {
      setSVGLoading(false);
    }
  }, []);

  useEffect(() => {
    if (svgId) {
      getSVGContext(svgId);
    } else {
      setSVGContent(undefined);
    }
  }, [getSVGContext, svgId]);

  const { initializing } = useInitElementsInLocationSVG(
    svgWrapperRef,
    svgContent
  );
  useAddClickEventListenerInLocationSVG(svgWrapperRef, svgContent);

  return (
    <Loading stretch loading={initializing || svgLoading}>
      <LocationSVGPopover svgWrapperRef={svgWrapperRef}>
        {svgContent && (
          <div
            id="location-svg-wrapper"
            ref={svgWrapperRef}
            className={styles.svg_wrapper}
            dangerouslySetInnerHTML={{ __html: svgContent }}
          />
        )}
        {!svgContent && (
          <Empty
            title="查無圖片"
            style={{ width: '800px', minHeight: '600px' }}
          />
        )}
      </LocationSVGPopover>
    </Loading>
  );
}
