import { useCallback, useMemo, useState } from 'react';
import { PageLayout } from '../PageLayout/PageLayout';
import { Button, MenuDivider, SelectValue, Typography } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { UseFormReturn, useForm } from 'react-hook-form';
import Filter from './Filter';
import styles from '../../Equipment/EquipmentTable/equipment-table.module.scss';
import {
  InventoryMiscByMaterialResponseData,
  NextPagination,
  getNextParams,
  useInventoryMiscByMaterial,
} from '@solar/data';
import { TableColumn } from '@mezzanine-ui/core/table';
import { MiscellaneousDispatchModal } from './MiscellaneousDispatchModal';
import { MiscellaneousReturnModal, ReturnModalForm, returnSchema } from './MiscellaneousReturnModal';
import { WarehouseModule, WarehouseModuleZh } from '../constant';
import {
  ModalGroup,
  MznPaginationTable,
  useModalGroupController,
  FilterScaffold,
  LocationSelectorGroupModal,
  LOCATION_SELECTOR,
} from '@solar/templates';
import { MiscellaneousDispatchAndReturnModal } from './enum';
import sharedStyles from '../shared-styles/index.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';

type FormProp = {
  searchTerm: string;
};

function MiscellaneousDispatchAndReturnPage() {
  const [currentModal, setCurrentModal] = useState<MiscellaneousDispatchAndReturnModal>();
  const filterMethods = useForm<Partial<FormProp>>();
  const returnMethods = useForm<ReturnModalForm>({
    resolver: yupResolver(returnSchema),
  });
  const dispatchMethods = useForm<{
    mainMaterialUnitQuantity: number;
    userId: string;
    locationIds: string[];
  }>();

  const modalGroupController = useModalGroupController([
    { name: MiscellaneousDispatchAndReturnModal.Dispatch, keepData: true },
    { name: MiscellaneousDispatchAndReturnModal.Return, keepData: true },
    { name: LOCATION_SELECTOR },
  ]);

  const returnModalOpen = useCallback(
    (item: Record<string, unknown> | null) => {
      setCurrentModal(MiscellaneousDispatchAndReturnModal.Return);
      const modalProps = modalGroupController.getModalProps(
        MiscellaneousDispatchAndReturnModal.Return
      );
      const data = modalProps?.data;
      modalGroupController.openModal(
        MiscellaneousDispatchAndReturnModal.Return,
        { ...data, ...item }
      );
    },
    [modalGroupController]
  );

  const dispatchModalOpen = useCallback(
    (data: InventoryMiscByMaterialResponseData) => {
      setCurrentModal(MiscellaneousDispatchAndReturnModal.Dispatch);
      modalGroupController.openModal(
        MiscellaneousDispatchAndReturnModal.Dispatch,
        data
      );
    },
    [modalGroupController]
  );

  const { records, pageInfo, mutateParams } = useInventoryMiscByMaterial();

  const refetchInventoryMiscByMaterial = useCallback(
    (nextPagination: NextPagination) => {
      // params type 後端尚未實做
      const filterState = filterMethods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      mutateParams({
        type: 'LOADER_BARCODE', //filterState.type,
        offset,
        limit,
        searchTerms: filterState?.searchTerm,
      });
    },
    [filterMethods, mutateParams, pageInfo]
  );

  const columns: TableColumn<InventoryMiscByMaterialResponseData>[] = useMemo(
    () => [
      { title: '類型', dataIndex: 'type' },
      { title: '料號', dataIndex: 'materialId' },
      // { title: '舊料號', dataIndex: 'oldMaterialId' },
      { title: '品名', dataIndex: 'materialDescription' },
      { title: '批號', dataIndex: 'batchId' },
      { title: '儲位', dataIndex: 'stackName' },
      {
        title: '載具',
        dataIndex: 'loaderName',
      },
      {
        title: '庫存數量',
        dataIndex: 'mainMaterialUnitQuantity',
      },
      {
        title: '操作',
        width: 120,
        align: 'center' as const,
        render(source) {
          return (
            <Button
              type="button"
              variant="outlined"
              onClick={() =>
                dispatchModalOpen({
                  ...source,
                })
              }
            >
              發料
            </Button>
          );
        },
      },
    ],
    [dispatchModalOpen]
  );

  return (
    <PageLayout>
      <Typography variant="h1">
        {WarehouseModuleZh[WarehouseModule.MiscellaneousDispatchAndReturn]}
      </Typography>
      <MenuDivider />
      <FormFieldsWrapper methods={filterMethods}>
        <FilterScaffold mutateParams={refetchInventoryMiscByMaterial}>
          <Filter />
        </FilterScaffold>
      </FormFieldsWrapper>
      <div>
        <Button
          variant="outlined"
          type="button"
          onClick={() => returnModalOpen(null)}
        >
          退料
        </Button>
      </div>
      <MznPaginationTable
        pageInfo={pageInfo}
        bodyClassName={styles.table}
        fetchData={refetchInventoryMiscByMaterial}
        columns={columns}
        dataSource={records ?? []}
        bodyRowClassName={sharedStyles.table}
      />
      <ModalGroup {...modalGroupController}>
        <MiscellaneousReturnModal
          methods={returnMethods}
          openLocationSelectorModal={() => {
            modalGroupController.openModal(LOCATION_SELECTOR, null);
          }}
        />
        <MiscellaneousDispatchModal
          methods={dispatchMethods}
          openLocationSelectorModal={() => {
            modalGroupController.openModal(LOCATION_SELECTOR, null);
          }}
        />
        <LocationSelectorGroupModal
          onSubmit={(selectedId) => {
            modalGroupController.openModal(
              currentModal as string, 
              modalGroupController.getModalProps(currentModal as string)?.data ?? null,
            );
            if (selectedId) {
              switch (currentModal) {
                case MiscellaneousDispatchAndReturnModal.Dispatch:
                  dispatchMethods.setValue('locationIds', [selectedId]);
                  break;
                case MiscellaneousDispatchAndReturnModal.Return:
                  returnMethods.setValue('locationIds', [selectedId]);
                  break;
                default:
                  break;
              }
            }
          }}
        />
      </ModalGroup>
    </PageLayout>
  );
}

export default MiscellaneousDispatchAndReturnPage;
