import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "@mezzanine-ui/react";
import { Modals } from "./Modal.enum";
import { useTargetModal } from "@solar/templates";
import { useForm } from "react-hook-form";
import { FormFieldsWrapper, SelectField } from "@mezzanine-ui/react-hook-form";
import { Col, Row } from "antd";
import styles from './add-materials-modal.module.scss';

type SuggestedMaterialAllocationsModalProps = {
  onSubmit?: (payload: any) => void;
};

export function SuggestedMaterialAllocationsModal({ onSubmit }: SuggestedMaterialAllocationsModalProps) {
  const methods = useForm();
  const { open, data, closeModal } = useTargetModal<{}>(Modals.SUGGESTED_MATERIAL_ALLOCATIONS);

  const onClose = () => {
    closeModal();
  };

  const onSave = () => {
    try {
      const formState = methods.getValues();
      onSubmit?.({
        stockQueryFactoryId: '1100',
        stockQueryAreaIds: formState?.areaIds?.map((option: any) => option?.id) ?? [],
      });
      closeModal();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal style={{ width: '1200px' }} open={open} onClose={onClose}>
      <ModalHeader>計算建議配料</ModalHeader>
      <ModalBody>
        <FormFieldsWrapper methods={methods}>
          <Row gutter={[12, 12]}>
            <Col>
              <SelectField
                className={styles.field}
                registerName="areaIds"
                mode="multiple"
                label="儲位"
                options={[
                  { id: '1100A1H', name: '科工廠 A 棟 1 樓 H 區' },
                  { id: '1100A2H', name: '科工廠 A 棟 2 樓 H 區' },
                  { id: '1100A3B', name: '科工廠 A 棟 3 樓 B 區' },
                  { id: '1100A4H', name: '科工廠 A 棟 4 樓 H 區' },
                ]} />
            </Col>
          </Row>
        </FormFieldsWrapper>
      </ModalBody>
      <ModalFooter>
        <Row style={{ width: '100%' }} justify="end" gutter={[12, 12]}>
          <Col>
            <Button onClick={onClose}>取消</Button>
          </Col>
          <Col>
            <Button variant="contained" onClick={onSave}>計算</Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  )
}