import { TableDataSourceWithID } from '@mezzanine-ui/core/table';
import {
  InventoryByMaterialSearchType,
  InventoryItem,
  NextPagination,
  PageInfo,
  getInventoryByMaterial,
  getNextParams,
} from '@solar/data';
import { useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormValues } from './typing';

export function useInventoryByMaterial({
  methods,
}: {
  methods: UseFormReturn<FormValues>;
}) {
  const [inventory, setInventory] = useState<
    (InventoryItem & TableDataSourceWithID)[]
  >([]);

  const [pageInfo, setPageInfo] = useState<PageInfo>({
    limit: 20,
    offset: 0,
  });

  const refetchGetInventoryByMaterial = useCallback(
    async (nextPagination: NextPagination) => {
      const formState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);

      if (!formState?.materialId || !formState?.batchId) {
        setInventory([]);
        setPageInfo({});
        return;
      }

      const response = await getInventoryByMaterial({
        type: InventoryByMaterialSearchType.MATERIAL_BATCH,
        materialIds: formState?.materialId
          ? [formState.materialId.id]
          : undefined,
        materialBatchIds: formState?.batchId
          ? [formState.batchId.id]
          : undefined,
        offset,
        limit,
      });

      setInventory(
        response?.records?.map((item) => ({
          ...item,
          id: item?.batchStoredLoaderRecordId?.toString(),
        })) ?? []
      );
      setPageInfo(response?.pageInfo);
    },
    [methods, pageInfo]
  );

  return {
    inventory,
    pageInfo,
    refetchGetInventoryByMaterial,
  };
}
