import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import useSWR from 'swr';

type GetEquipmentsParams = FieldValues & {
  offset?: number;
  limit?: number;
};

/**
 * @deprecated 請使用 useGetEquipments，詳見 libs/data/src/equipments/equipments.ts
 */
export function useEquipments(methods?: UseFormReturn<any>) {
  const [params, setParams] = useState<GetEquipmentsParams>({});
  const { data, error } = useSWR(params ? ['/equipments', { params }] : null);

  const pageInfo = useMemo(() => {
    const page =
      (data?.pageInfo?.offset ?? 0) / (data?.pageInfo?.limit ?? 20) + 1;
    const pageSize = data?.pageInfo?.limit ?? 20;
    return { ...(data?.pageInfo ?? {}), page, pageSize };
  }, [data]);

  const mutateGetEquipments = useCallback(
    (payload?: any) => {
      const filterState = methods?.getValues();
      const nextPageSize = payload?.nextPageSize ?? pageInfo?.pageSize;
      const nextPage = payload?.nextPage ?? pageInfo?.page;
      const installedFrom = filterState?.installedAt?.[0];
      const installedTo = filterState?.installedAt?.[1];
      setParams({
        offset: (nextPage - 1) * nextPageSize,
        limit: nextPageSize,
        categoryIds:
          filterState?.categories?.map(({ id }: { id: string }) => id) ?? [],
        workCenterIds:
          filterState?.workCenters?.map(({ id }: { id: string }) => id) ?? [],
        isForProduce: filterState?.isForProduce,
        installedFrom:
          installedFrom &&
          moment(installedFrom)
            .startOf('day')
            .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        installedTo:
          installedTo &&
          moment(installedTo)
            .endOf('day')
            .format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        zoneIds: filterState?.zones?.map(({ id }: { id: string }) => id) ?? [],
        equipmentIds:
          filterState?.equipments?.map(({ id }: { id: string }) => id) ?? [],
      });
    },
    [methods, pageInfo]
  );

  return {
    equipments: data?.equipments || [],
    pageInfo,
    isLoading: !error && !data,
    mutateGetEquipments,
    setParams,
  };
}
