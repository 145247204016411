import { Fragment } from 'react';
import classes from './order-meta-info.module.scss';
import { Divider } from 'antd';
import { Typography } from '@mezzanine-ui/react';
import {
  InspectStatus,
  InspectStatusTag,
} from '../../IncomingInspectOperationPage/InspectStatusTag';

type OrderMetaInfoProp = {
  labelAndContexts: Array<{ label: string; context: string }>;
};

export function OrderMetaInfo({ labelAndContexts }: OrderMetaInfoProp) {
  return labelAndContexts?.length ? (
    <div className={classes['host']}>
      {(labelAndContexts ?? [])?.map(
        (arr: { label: string; context: string }, index: number) => (
          <Fragment key={`orderInfo-block-${index}`}>
            {!!index && <Divider type="vertical" className={classes.divider} />}
            <div className={classes.content}>
              <Typography variant="h6" color="text-secondary">
                {arr.label}
              </Typography>
              <Typography>
                {Object.values(InspectStatus).includes(
                  arr.context as InspectStatus
                ) ? (
                  <InspectStatusTag status={arr.context as InspectStatus} />
                ) : (
                  arr.context
                )}
              </Typography>
            </div>
          </Fragment>
        )
      )}
    </div>
  ) : null;
}
