import useSWR from 'swr';
import {
  FormAnswer,
  GetProductionFormAnswerParams,
  SaveProductionFormAnswersPayload,
} from './production-process-form-answers.type';
import { useState } from 'react';
import { API_NAMESPACE, request } from '../request';
import { PageInfo } from '../pagination';

const namespace = API_NAMESPACE.PP;

export function useGetProductionFormAnswers(
  defaultParams?: GetProductionFormAnswerParams
) {
  const [params, setParams] = useState<
    GetProductionFormAnswerParams | undefined
  >(defaultParams);
  const { data, error, mutate, isValidating } = useSWR<{
    records: FormAnswer[];
    pageInfo: PageInfo;
  }>(
    params && !(params?.withLims && !params?.factory)
      ? [
          '/pp/production-form-answer/production-answers',
          {
            namespace,
            params,
          },
        ]
      : null
  );

  return {
    productionFormAnswers: data?.records ?? [],
    error,
    isLoading: !data && !error && !isValidating,
    mutateGetProductionFormAnswers: mutate,
    refetchGetProductionFormAnswers: setParams,
  };
}

export function getProductionFormAnswers(
  params: GetProductionFormAnswerParams
) {
  return request('/pp/production-form-answer/production-answers', {
    namespace,
    params,
  }) as Promise<{
    records: FormAnswer[];
    pageInfo: PageInfo;
  }>;
}

export function saveProductionFormAnswers(
  payload: SaveProductionFormAnswersPayload
) {
  return request(
    '/pp/production-form-answer/production-answers/modified-answer-and-pick',
    {
      namespace,
      method: 'PUT',
      body: JSON.stringify(payload),
    }
  );
}
