import type { HubOrdersFilterFormValues } from '@solar/data';
import {
  HubOrdersFilterFormFieldName as FormFieldName,
  useHubClients,
} from '@solar/data';
import { useFormContext } from 'react-hook-form';
import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';

export function CustomerIdAutoComplete() {
  const _NoUseFieldName = '_' + FormFieldName.CUSTOMER_ID;
  const formMethods = useFormContext<HubOrdersFilterFormValues>();

  const { customers } = useHubClients();

  return (
    <>
      <div>客戶姓名／編號：</div>
      <div>
        <AutoCompleteField
          control={formMethods.control}
          registerName={_NoUseFieldName}
          options={
            customers?.map((customer) => ({
              id: customer.id,
              name: `${customer.name} (${customer.id})`,
            })) || []
          }
          onChange={(selected) => {
            // update react-hook-form by oneself because the format from @mezzanine is not quite suitable.
            formMethods.setValue(FormFieldName.CUSTOMER_ID, selected?.id ?? '');
          }}
        />
      </div>
    </>
  );
}
