import {
  DateRangePickerField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { CustomInputSearch } from '@solar/templates';
import classes from './filter.module.scss';
import { useQueryString } from '../hooks/useQueryString';
import { WarehouseModule } from '../constant';
import { toUpperCamelCase } from '../utils/toUpperCamelCase';

export function Filter() {
  const { tab } = useQueryString();
  const camelCaseTab = toUpperCamelCase(tab ?? '');

  return (
    <div className={classes.host}>
      <CustomInputSearch />
      {[
        WarehouseModule.MaterialStorageIn,
        WarehouseModule.WarehouseDispatch,
      ].includes(camelCaseTab as WarehouseModule) && (
        <SelectField label="操作類型：" registerName="type" options={[]} />
      )}
      <DateRangePickerField
        fullWidth
        label="日期區間："
        registerName="dateRange"
      />
    </div>
  );
}
