import { Button, Message } from '@mezzanine-ui/react';
import { useCallback, useState, type ReactNode } from 'react';
import {
  LocationPickerModal,
  LocationPickerModalProps,
} from './LocationPickerModal';
import { useLocationControlContext } from '../LocationControlContext';
import { LocationControlContextValues } from '../types';

interface LocationPickerModalButtonProps {
  onSubmit?: (payload: any) => Promise<void>;
  children?: ReactNode;
  modalProps?: LocationPickerModalProps;
}

export function LocationPickerModalButton({
  children,
}: LocationPickerModalButtonProps) {
  const [open, setOpen] = useState(false);

  const { resetContextValue } = useLocationControlContext();

  const openModal = () => setOpen(true);

  const closeModal = () => setOpen(false);

  const _onSubmit = useCallback<
    (payload: LocationControlContextValues) => void
  >(
    ({ activeId, locationIds }) => {
      if (!activeId) {
        Message.error('請選擇一個位置');
        return;
      }

      resetContextValue?.({ id: activeId, locationIds });
      setOpen(false);
    },
    [resetContextValue]
  );

  return (
    <div>
      <Button type="button" variant="outlined" onClick={openModal}>
        {children ?? '地圖選取'}
      </Button>
      <LocationPickerModal
        open={open}
        onClose={closeModal}
        onSubmit={_onSubmit}
      />
    </div>
  );
}
