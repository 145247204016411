import { Message } from '@mezzanine-ui/react';
import { environment } from '../environments/environment';
import { headers } from '../humanResources';
import { API_NAMESPACE } from '../request';
import { redirect } from 'react-router';

const urlHost = `${environment.API_HOST.replace('/equipments', '')}${
  API_NAMESPACE.IAM
}`;

export const authGetFetcher = async (url: string) => {
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  };
  const res = await fetch(`${urlHost}${url}`, options);
  if (res.status === 401) {
    Message['error']('權限不足');
    redirect('/auth/login');
  }
  const json = await res.json();
  return json ?? null;
};

export const authPostFetcher = async (
  url: string,
  body: any,
  forLogin?: boolean
) => {
  const options = {
    headers: forLogin
      ? headers
      : {
          ...headers,
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
    method: 'POST',
    body: JSON.stringify(body),
  };
  const res = await fetch(`${urlHost}${url}`, options);
  if (res.status === 401) {
    Message['error']('權限不足');
    redirect('/auth/login');
  }
  const json = await res.json();
  return json ?? null;
};

export const authPutFetcher = async (url: string, body: any) => {
  const options = {
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    method: 'PUT',
    body: JSON.stringify(body),
  };
  const res = await fetch(`${urlHost}${url}`, options);
  if (res.status === 401) {
    Message['error']('權限不足');
    redirect('/auth/login');
  }
  const json = await res.json();
  return json ?? null;
};

export const authDeleteFetcher = async (url: string, body: any) => {
  const options = {
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    method: 'DELETE',
    body: JSON.stringify(body),
  };
  const res = await fetch(`${urlHost}${url}`, options);
  if (res.status === 401) {
    Message['error']('權限不足');
    redirect('/auth/login');
  }
  const json = await res.json();
  return json ?? null;
};
