import { useMemo } from 'react';

import { Table } from '@mezzanine-ui/react';

import { UseFormReturn, useWatch } from 'react-hook-form';

import { TableColumn } from '@mezzanine-ui/core/table';
import { SelectField } from '@mezzanine-ui/react-hook-form';
import { RowSection } from '@solar/templates';
import { ConfigurationForm, ConfigureTableProps } from './typings';

export function CreateConfigureTable({
  tableMethods,
  index,
}: {
  tableMethods: UseFormReturn<ConfigurationForm>;
  index: number;
}) {
  const fields = useWatch({ name: `tables.${index}.table` });
  const watchSelectedRowKeys = useWatch({
    name: `tables.${index}.selectedRowKeys`,
  });

  const columns: TableColumn<ConfigureTableProps>[] = useMemo(
    () => [
      {
        title: '料號',
        dataIndex: 'materialId',
      },
      {
        title: '品名',
        dataIndex: 'materialDescription',
      },
      {
        title: '批號',
        dataIndex: 'batchId',
      },
      {
        title: '批次備註',
        dataIndex: 'notes',
      },
      {
        title: '儲位',
        dataIndex: 'stackId',
      },
      {
        title: '載具',
        dataIndex: 'loaderId',
      },
      {
        title: '庫存狀態',
        dataIndex: 'storageStatus',
      },
      {
        title: '庫存數量',
        dataIndex: 'storage',
      },
      {
        title: '單位',
        dataIndex: 'storageUnit',
      },
      {
        title: '實際重量',
        dataIndex: 'weight',
      },
      {
        title: '實際重量',
        dataIndex: 'weightUnit',
      },
    ],
    []
  );

  if (!fields) return null;

  return (
    <>
      <div style={{ width: '100%', overflow: 'auto' }}>
        <Table
          columns={columns}
          dataSource={fields}
          rowSelection={{
            selectedRowKey: watchSelectedRowKeys,
            onChange: (keys) => {
              tableMethods.setValue(`tables.${index}.selectedRowKeys`, keys);
            },
          }}
        />
      </div>
      <br />
      <div
        style={{
          display: 'flex',
          gap: 'var(--mzn-spacing-4)',
          alignItems: 'center',
        }}
      >
        <RowSection label="新料號：" colGap="var(--mzn-spacing-4)">
          <SelectField
            registerName={`tables.${index}.newMaterial`}
            options={[{ id: '1', name: 'HLZZ1AC9001GZEL01' }]}
          />
        </RowSection>
        <RowSection label="品名：" colGap="var(--mzn-spacing-4)">
          <span>新品測試</span>
        </RowSection>
      </div>
    </>
  );
}
