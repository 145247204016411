import { Button } from '@mezzanine-ui/react';
import {
  Document,
  Page,
  Text,
  StyleSheet,
  usePDF,
  Font,
  PDFViewer,
  View,
} from '@react-pdf/renderer';
import moment from 'moment';
import { useMemo } from 'react';

Font.register({
  family: 'NotoSans',
  fonts: [
    {
      src: '/assets/font/NotoSansTC-Regular.otf',
      fontWeight: 'normal',
    },
    {
      src: '/assets/font/NotoSansTC-Medium.otf',
      fontWeight: 'medium',
    },
    {
      src: '/assets/font/NotoSansTC-Bold.otf',
      fontWeight: 'bold',
    },
  ],
});

Font.registerHyphenationCallback((word: string) => {
  if (word.length === 1) {
    return [word];
  }

  return Array.from(word)
    .map((char) => [char, ''])
    .reduce((arr, current) => {
      arr.push(...current);
      return arr;
    }, []);
}); // fix chinese break line issue.

const styles = StyleSheet.create({
  page: {
    fontFamily: 'NotoSans',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 24,
    fontSize: 12,
  },
  headers: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 36,
    fontWeight: 'bold',
  },
  body: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  content: {
    flex: '1 1 50%',
    boxSizing: 'border-box',
    padding: '10px',
  },
  signatures: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 12,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 12,
  },
});

interface MyPDFDocumentProps {
  name?: string;
  locationId?: string;
  createdAt?: string;
  username?: string;
}

const MyPDFDocument = ({
  name,
  locationId,
  createdAt,
  username,
}: MyPDFDocumentProps) => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={styles.headers}>
        <Text>光洋應用材料科技股份有限公司</Text>
        <Text>庫存盤點清單報表</Text>
      </View>
      <View style={styles.body}>
        <Text style={styles.content}>
          事業單位: 光洋應用材料科技股份有限公司
        </Text>
        <Text style={styles.content}>倉庫: {locationId ?? ''}</Text>
        <Text style={styles.content}>
          生產地: {locationId?.slice(0, 4) ?? ''}
        </Text>
        <Text style={styles.content}>盤點文件編號: {name ?? ''}</Text>
        <Text style={styles.content}>
          資料產生日期:{' '}
          {createdAt ? moment(createdAt).format('YYYY-MM-DD') : ''}
        </Text>
        <Text style={styles.content}>產生盤點清單人員: {username ?? ''}</Text>
      </View>
      <View style={styles.signatures}>
        <Text>理級主管: _____________________</Text>
        <Text>課級主管: _____________________</Text>
        <Text>覆盤人員: _____________________</Text>
        <Text>初盤人員: _____________________</Text>
      </View>
      <View style={styles.footer}>
        <Text>列印日期：{moment().format('YYYY-MM-DD')}</Text>
        <Text>列印時間：{moment().format('HH:mm:ss')}</Text>
      </View>
    </Page>
  </Document>
);

export function DownloadPDFButton({
  name,
  createdAt,
  username,
  locationId,
}: MyPDFDocumentProps) {
  const document = useMemo(
    () => MyPDFDocument({ name, createdAt, username, locationId }),
    [createdAt, name, username, locationId]
  );
  const [instance] = usePDF({ document });

  return (
    <a
      style={{
        textDecoration: 'none',
      }}
      href={instance.url as string}
      download="清單報表.pdf"
    >
      <Button variant="outlined" loading={instance.loading}>
        下載清單報表
      </Button>
    </a>
  );
}

export function Previewer(props: MyPDFDocumentProps) {
  return (
    <PDFViewer width="100%" height="600">
      <MyPDFDocument {...props} />
    </PDFViewer>
  );
}
