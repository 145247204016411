import useSWR from 'swr';
import { API_NAMESPACE } from '../request';

const namespace = API_NAMESPACE.MATERIALS;
export function useWorkOrder({ orderId }: { orderId?: string | undefined }) {
  const { data, error, mutate } = useSWR(
    orderId ? [`/warehouses/work-orders/${orderId}`, { namespace }] : null
  );

  return {
    order: data,
    isLoading: !data && !error,
    mutate,
  };
}
