import { TableDataSourceWithID } from '@mezzanine-ui/core/table';
import { OffsetBased } from '../pagination';
import { FormAnswer } from '../production-process-form';

export enum OOCTicketStatus {
  NOT_CLEAR = 'NOT_CLEAR',
  CLEAR = 'CLEAR',
}

export interface GetOOCTicketsParams extends OffsetBased {
  materialId?: string;
  sapBatchId?: string;
  subBatchId?: string;
  status?: OOCTicketStatus;
}

export interface OOCTicket extends TableDataSourceWithID {
  id: string;
  materialId: string;
  sapBatchId: string;
  subBatchId: string;
  status: string;
  oocBlockShelfIds: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  items: OOCTicketItem[];
}

export interface OOCTicketItem extends TableDataSourceWithID {
  id: string;
  ticketId: string;
  productionFormId: string;
  productionAnswerId: string;
  oocReason: string;
  comment: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  productionAnswer: FormAnswer;
}

export interface UpdateOOCTicketPayload {
  items: {
    itemId: string;
    comment: string;
  }[];
}
