export interface ProductionAnswerDto {
  id: string;
  answer: string;
  modifiedAnswer: string | null;
  form: {
    id: string;
    title: string | null;
    suffix: string | null;
  };
  picked: boolean | null;
}
