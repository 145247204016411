import { SelectValue } from '@mezzanine-ui/react';

import {
  DeliveryListingFormFields,
  LogisticsReqData,
  RequirementFormValues,
} from '../RequirementsManagementPage/RequirementManagement.interface';
import {
  CostUnitOptions,
  DeliveryAreaOptions,
  LogisticTypeOptions,
  CorrespondentTypeOptions,
  YesNoOptions,
  ScrapFeatureOptions,
  PackagingTypeOptions,
  RequestStatusOptions,
  UnitOptions,
  BooleanStrings,
} from '../RequirementsManagementPage/RequirementsManagement.config';

export function formValuesToNewReq(
  values: RequirementFormValues
): LogisticsReqData {
  const requestStatus = values.requestStatus?.id === BooleanStrings.True;
  const applyUnit = values.applyUnit?.id;
  const costUnit = values.costUnit?.id;
  const deliveryArea = values.deliveryArea?.id;
  const logisticType = values.logisticType?.id;
  const correspondentType = values.correspondentType?.id;
  const isRecycle = values.isRecycle
    ? values.isRecycle?.id === BooleanStrings.True
    : values.isRecycle;
  const isSealed = values.isSealed
    ? values.isSealed?.id === BooleanStrings.True
    : values.isSealed;
  const scrapId = values.scrapName?.id;
  const scrapTypeId = values.scrapType?.id;
  const scrapFeature = Number(
    values.scrapFeature.reduce((result, item) => result + Number(item), 0)
  );
  const packagingType = Number(
    values.packagingType.reduce((result, item) => result + Number(item), 0)
  );
  const recycleInfo = {
    isRecycle,
    isSealed: isRecycle ? isSealed : undefined,
    scrapId: isRecycle ? scrapId : undefined,
    scrapTypeId: isRecycle ? scrapTypeId : undefined,
    scrapFeature: isRecycle ? scrapFeature : undefined,
    packagingType: isRecycle ? packagingType : undefined,
    environmentalFormId: isRecycle ? values.environmentalFormId : undefined,
  };

  const items = values.items
    .map((item) => ({
      ...item,
      totalGrossWeightUnit: item.totalGrossWeightUnit?.id,
      isStack: item.isStack?.id === BooleanStrings.True,
      isUsingPallet: item.isUsingPallet?.id === BooleanStrings.True,
      materialId: item.productNumber,
    }))
    .map((item) => {
      const value = structuredClone(item) as any;
      delete value.id;
      delete value.convert;
      delete value.logisticRequestId;
      delete value.createdAt;
      delete value.updatedAt;
      delete value.deletedAt;
      delete value.deliveryOrderDetailId;
      return value;
    });

  const result = {
    ...values,
    requestStatus,
    applyUnit,
    costUnit,
    deliveryArea,
    logisticType,
    correspondentType,
    ...recycleInfo,
    items,
  };

  const ignoreKeys = [
    'deliveryStartFactory',
    'deliveryEndFactory',
    'uofSource',
    'createdAt',
    'updatedAt',
    'deletedAt',
    'sapImportDeliveryOrderId',
    'sapImported',
    'requestFormId',
    'id',
  ];
  Object.entries(result)
    .filter(([key, value]) => {
      return value === undefined || value === '' || ignoreKeys.includes(key);
    })
    .forEach(([key, _]) => {
      delete result[key as keyof LogisticsReqData];
    });

  return result;
}
export function logisticsDetailToFormValues(
  data: LogisticsReqData,
  isClone: boolean,
  scrapNameOptions: SelectValue[],
  scrapTypeOptions: SelectValue[],
  applyUnitOptions?: SelectValue[]
) {
  const requestStatus = isClone
    ? undefined
    : RequestStatusOptions.find(
        (item) => (item.id === BooleanStrings.True) === data.requestStatus
      );
  const applyDate = data.applyDate || '';
  const applyUnit = applyUnitOptions?.find(
    (item) => item.id === data.applyUnit
  );
  const costUnit = CostUnitOptions.find((item) => item.id === data.costUnit);
  const deliveryArea = DeliveryAreaOptions.find(
    (item) => item.id === data.deliveryArea
  );
  const logisticType = LogisticTypeOptions.find(
    (item) => item.id === data.logisticType
  );
  const correspondentType = CorrespondentTypeOptions.find(
    (item) => item.id === data.correspondentType
  );
  const isRecycle = YesNoOptions.find(
    (item) => (item.id === BooleanStrings.True) === data.isRecycle
  );
  const isSealed = YesNoOptions.find(
    (item) => (item.id === BooleanStrings.True) === data.isSealed
  );
  const scrapName = scrapNameOptions.find((item) => item.id === data.scrapId);
  const scrapType = scrapTypeOptions.find(
    (item) => item.id === data.scrapTypeId
  );
  const scrapFeature = data.scrapFeature
    ?.toString(2)
    .split('')
    .reverse()
    .reduce((result, item, index) => {
      if (item === '1') {
        result.push(ScrapFeatureOptions[index].value);
      }
      return result;
    }, [] as string[]);
  const packagingType = data.packagingType
    ?.toString(2)
    .split('')
    .reverse()
    .reduce((result, item, index) => {
      if (item === '1') {
        result.push(PackagingTypeOptions[index].value);
      }
      return result;
    }, [] as string[]);

  const formatValues = {
    ...data,
    requestStatus,
    applyDate,
    applyUnit,
    costUnit,
    deliveryArea,
    logisticType,
    correspondentType,
    isRecycle,
    isSealed,
    scrapName,
    scrapType,
    scrapFeature,
    packagingType,
  };

  const deliveryItems: DeliveryListingFormFields[] =
    data.items?.map((item, index) => ({
      ...item,
      id: index.toString(),
      totalGrossWeightUnit: UnitOptions.find(
        (option) => option.id === item.totalGrossWeightUnit
      ),
      isStack: YesNoOptions.find(
        (option) => (option.id === BooleanStrings.True) === item.isStack
      ),
      isUsingPallet: YesNoOptions.find(
        (option) => (option.id === BooleanStrings.True) === item.isUsingPallet
      ),
    })) || [];

  return { formatValues, deliveryItems };
}
