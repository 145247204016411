import { cx, Table as MznTable } from '@mezzanine-ui/react';
import { NextPagination, PageInfo } from '@solar/data';

import classes from './mzn-pagination-table.module.scss';
import { TableColumn } from '@mezzanine-ui/core/table';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useDraggableColumns } from './useDraggableColumns';
import { TableWithPagination } from '@mezzanine-ui/react/Table/Table';

interface MznPaginationTableProps<DataType>
  extends Omit<TableWithPagination<DataType>, 'columns' | 'bodyClassName'> {
  bodyClassName?: string;
  columns: (TableColumn<DataType> & { draggable?: boolean })[];
  pageInfo?: PageInfo;
  fetchData: (nextPagination: NextPagination) => void;
  hideDefaultExpandColumn?: boolean;
}

export const MznPaginationTable = forwardRef<
  HTMLTableElement,
  MznPaginationTableProps<Record<string, unknown>>
>(function Table(
  {
    bodyClassName,
    columns,
    dataSource,
    fetchData,
    pageInfo,
    hideDefaultExpandColumn = false,
    ...restProps
  },
  ref
) {
  const tableRef = useRef<HTMLTableElement>(null);

  useImperativeHandle(ref, () => tableRef.current as HTMLTableElement);

  const pageSize = pageInfo?.limit ?? 20;
  const page = (pageInfo?.offset ?? 0) / pageSize + 1;

  const _columns = useDraggableColumns(tableRef, columns);

  return (
    <MznTable
      ref={ref ?? tableRef}
      bodyClassName={cx(bodyClassName, {
        [classes['hide-default-expand-column']]: hideDefaultExpandColumn,
      })}
      dataSource={dataSource}
      columns={_columns}
      pagination={{
        disableAutoSlicing: true,
        total: pageInfo?.totalCount ?? 0,
        current: page,
        onChange: (nextPage) => fetchData({ nextPage }),
        options: {
          pageSize,
          pageSizeLabel: '每頁顯示：',
          pageSizeUnit: '筆',
          onChangePageSize: (nextPageSize) =>
            fetchData({ nextPage: 1, nextPageSize }),
          renderPageSizeOptionName: (p) => `${p}`,
          renderPaginationSummary: (start, end) =>
            `目前顯示 ${start} - ${end} 筆，共 ${end - start + 1} 筆資料`,
          showPageSizeOptions: true, // 開啟每頁顯示 N 筆
          showJumper: true, // 開啟跳頁功能
          jumperButtonText: '前往',
          jumperHintText: '跳至',
        },
      }}
      {...restProps}
    />
  );
});
