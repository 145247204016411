import {
  Modal,
  ModalActions,
  ModalBody,
  Typography,
} from '@mezzanine-ui/react';
import { useTargetModal } from '@solar/templates';
import { useCallback } from 'react';
import { MiscellaneousStorageInModals } from '../enum';

export function AcceptanceModal() {
  const modalController = useTargetModal<{ materialId: string }>(
    MiscellaneousStorageInModals.Acceptance
  );
  const { open, closeModal, data } = modalController;

  const handleModalClose = useCallback(() => {
    closeModal();
  }, []);

  return (
    <Modal open={open} onClose={handleModalClose}>
      <br />
      <br />
      <ModalBody>
        <Typography variant="h2">是否確認驗收通過？ </Typography>
        <Typography>
          {`採購單號 ${
            data?.materialId ?? ''
          } 是否已清點數量並驗收通過，點擊確認後此採 購單之物料將正式收貨入庫。`}
        </Typography>
      </ModalBody>
      <ModalActions
        cancelText="取消"
        confirmText="確認"
        onCancel={() => {
          handleModalClose();
        }}
        onConfirm={() => {
          handleModalClose();
        }}
      />
    </Modal>
  );
}
