import {
  CopyTextWrapper,
  LOCATION_SELECTOR,
  LocationSelectorGroupModal,
  ModalGroup,
  MznPaginationTable,
  PageLayout,
  useModalGroupController,
} from '@solar/templates';

import { TableColumn } from '@mezzanine-ui/core/table';
import { Button, SelectValue } from '@mezzanine-ui/react';
import {
  AutoCompleteMultiField,
  BaseField,
  FormFieldsWrapper,
  InputField,
} from '@mezzanine-ui/react-hook-form';
import {
  API_NAMESPACE,
  InventoryTransferOrder,
  NextPagination,
  checkJWTRoles,
  getNextParams,
  request,
  useGetLoaders,
  useGetMembers,
  useInventoryTransferOrders,
  useMaterials,
} from '@solar/data';
import { Col, Row, notification } from 'antd';
import moment from 'moment';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { LocationIdsAutoField } from '../../ProductionPlanning/WaitStorageInPage/LocationIdsAutoField';
import sharedStyles from '../shared-styles/index.module.scss';

const namespace = API_NAMESPACE.MATERIALS;
export function MaterialReceiptCheckPage() {
  const methods = useForm<{
    creators: SelectValue[];
    materials: SelectValue[];
    loaderIds?: SelectValue[];
    // toLoaderIds?: SelectValue[];
    // fromLoaderIds?: SelectValue[];
    toLocationIds?: SelectValue[]; //string[];
    fromLocationIds?: SelectValue[]; //string[];
    materialBarcode?: string;
  }>();

  const { members, mutateGetMembers } = useGetMembers();
  const { materials, setParams: refetchGetMaterials } = useMaterials();
  const { loaders: toLoaderOptions, refetchGetLoaders: refetchToLoaders } =
    useGetLoaders();
  const { loaders: fromLoaderOptions, refetchGetLoaders: refetchFromLoaders } =
    useGetLoaders();
  const {
    records,
    pageInfo,
    isLoading,
    mutateGetTransferOrders,
    refetchGetTransferOrders,
  } = useInventoryTransferOrders();

  const [selectedKeys, setSelectedKeys] = useState<string[]>();
  const disabled = (selectedKeys?.length ?? -1) < 1;
  const modalGroupController = useModalGroupController([
    { name: LOCATION_SELECTOR },
  ]);

  const tableDataSource = useMemo(() => {
    return (
      records?.map((record) => ({
        ...record,
        ...record?.items?.[0],
        items: record?.items?.filter((item, index) => index !== 0),
      })) ?? []
    );
  }, [records]);

  const [transfering, setTransfering] = useState(false);

  const handleTransferActions = useCallback(
    async ({ receiptStatus }: { receiptStatus: boolean }) => {
      try {
        setTransfering(true);

        for (const key of selectedKeys!) {
          try {
            await request(
              `/warehouses/inventory-transfer-orders/${key}/receipt-status`,
              {
                method: 'put',
                body: JSON.stringify({ receiptStatus }),
                namespace,
                responseParser: (res) => res,
              }
            );

            notification.success({
              duration: 0,
              message: <li>#{key} 成功</li>,
            });
          } catch (err: any) {
            console.error(err);
            notification.error({
              duration: 0,
              message: <li>#{key} 失敗</li>,
              description: (
                <li>
                  {JSON.parse(err?.reason?.message || err?.message)?.message ??
                    ''}
                </li>
              ),
            });
          }
        }
      } finally {
        setTransfering(false);
      }

      setSelectedKeys([]);
      await mutateGetTransferOrders(undefined, true);
    },
    [selectedKeys]
  );
  const columns: TableColumn<InventoryTransferOrder>[] = [
    {
      width: 80,
      title: '#',
      dataIndex: 'id',
    },
    {
      title: '發料時間',
      render: (source) => moment(source?.createdAt).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: '來源',
      render: (source) => {
        switch (source?.sourceType) {
          case 'CHANGE_LOCATION':
            return '載具移轉';
          case 'INVENTORY_TRANSFER_ORDER':
            return '移轉';
          case 'REQUEST_ORDER':
            return '發料單';
          default:
            return '';
        }
      },
    },
    {
      title: '發料人',
      render: (source: any) => (
        <CopyTextWrapper text={source?.creatorName ?? ''} />
      ),
    },
    {
      width: 500,
      title: '品名',
      render: (source: any) => (
        <CopyTextWrapper text={source?.materialDescription ?? ''} />
      ),
    },
    {
      width: 200,
      title: '料號',
      render: (source: any) => (
        <CopyTextWrapper text={source?.materialId ?? ''} />
      ),
    },
    {
      width: 200,
      title: '批號流水',
      render: (source: any) => (
        <CopyTextWrapper
          text={`${source?.materialSapBatchId}-${source?.materialSubBatchId}`}
        />
      ),
    },
    { title: '發料位置', dataIndex: 'fromShelfId' },
    { title: '發料載具', dataIndex: 'fromLoaderId' },
    { title: '收料位置', dataIndex: 'toShelfId' },
    { title: '收料載具', dataIndex: 'toLoaderId' },
    {
      title: '移轉庫存管理單位數量',
      dataIndex: 'transferMaterialInputUnitQuantity',
    },
    { title: '管理單位', dataIndex: 'materialInputUnit' },
  ];

  const specifiedLocationFieldToAppend = useRef<
    'toLocationIds' | 'fromLocationIds' | null
  >(null);
  const appendLocationIds = (selectedLocationId?: string) => {
    const currentField = specifiedLocationFieldToAppend.current;
    if (!currentField) return;

    // const locationIds = methods.getValues(currentField) ?? [];
    // const idSet = new Set(locationIds);
    // if (selectedLocationId) idSet.add(selectedLocationId);
    // methods.setValue(currentField, [...idSet]);
    const locationIds = methods.getValues(currentField) ?? [];
    const idSet = new Set(locationIds.map((location) => location.id));
    if (selectedLocationId) idSet.add(selectedLocationId);

    methods.setValue(
      currentField,
      [...idSet].map((location) => ({ id: location, name: location }))
    );
  };

  const openLocationSelectorModal = () =>
    modalGroupController?.openModal(LOCATION_SELECTOR, {});

  const refetchGetTransferOrdersWithPagination = useCallback(
    (nextPagination: NextPagination) => {
      const formValues = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      refetchGetTransferOrders({
        offset,
        limit,
        creators: formValues?.creators?.map((c) => c?.id) ?? [],
        // toLoaderIds: formValues?.loaderIds?.map((l) => l?.id) ?? [],
        // fromLoaderIds: formValues?.loaderIds?.map((l) => l?.id) ?? [],
        loaderIds: formValues?.loaderIds?.map((l) => l?.id) ?? [],
        toShelfIds: (formValues?.toLocationIds ?? []).map((row) => row.id),
        fromShelfIds: (formValues?.fromLocationIds ?? []).map((row) => row.id),
        materialIds: formValues?.materials?.map((m) => m?.id) ?? [],
        materialBarcodes: formValues?.materialBarcode
          ? [formValues?.materialBarcode]
          : [],
      });
    },
    [methods, refetchGetTransferOrders, getNextParams]
  );

  const onSearchTransferOrders = useCallback(() => {
    refetchGetTransferOrdersWithPagination({ nextPage: 1 });
    setSelectedKeys([]);
  }, []);

  return (
    <PageLayout title="收料對點">
      <FormFieldsWrapper
        methods={methods}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 'var(--mzn-spacing-4)',
        }}
      >
        <Row gutter={[12, 12]} align="bottom">
          <Col>
            <BaseField label="接收的位置" name="toLocationIds" width={380}>
              <LocationIdsAutoField
                methods={methods}
                registerName="toLocationIds"
                openModal={() => {
                  specifiedLocationFieldToAppend.current = 'toLocationIds';
                  openLocationSelectorModal();
                }}
              />
            </BaseField>
          </Col>
          <Col>
            <BaseField label="發出的位置" name="fromLocationIds" width={380}>
              <LocationIdsAutoField
                methods={methods}
                registerName="fromLocationIds"
                openModal={() => {
                  specifiedLocationFieldToAppend.current = 'fromLocationIds';
                  openLocationSelectorModal();
                }}
              />
            </BaseField>
          </Col>
          <Col>
            <AutoCompleteMultiField
              label="載具"
              registerName="loaderIds"
              options={toLoaderOptions.map((row) => ({ ...row, name: row.id }))}
              onSearch={(searchTerm) => {
                refetchToLoaders({ searchTerm });
              }}
            />
          </Col>
          <Col>
            <AutoCompleteMultiField
              label="料號"
              registerName="materials"
              options={
                materials?.map((m) => ({ id: m?.id, name: m?.id })) ?? []
              }
              onSearch={(searchTerm) =>
                refetchGetMaterials({ searchType: 'ID', searchTerm })
              }
            />
          </Col>
          <Col>
            <InputField label="物料條碼" registerName="materialBarcode" />
          </Col>
          <Col>
            <AutoCompleteMultiField
              label="發料人"
              registerName="creators"
              options={members ?? []}
              onSearch={(searchTerm) => mutateGetMembers({ searchTerm })}
            />
          </Col>
          <Col>
            <Button
              loading={isLoading}
              variant="contained"
              onClick={onSearchTransferOrders}
            >
              查詢
            </Button>
          </Col>
        </Row>
        {checkJWTRoles([86]) && (
          <Row gutter={[12, 12]} justify="end">
            <Col>
              <Button
                type="button"
                variant="contained"
                loading={transfering}
                disabled={disabled || isLoading}
                onClick={() => handleTransferActions({ receiptStatus: true })}
              >
                批次接受
              </Button>
            </Col>
            <Col>
              <Button
                danger
                type="button"
                variant="outlined"
                loading={transfering}
                disabled={disabled || isLoading}
                onClick={() => handleTransferActions({ receiptStatus: false })}
              >
                批次拒絕
              </Button>
            </Col>
          </Row>
        )}
      </FormFieldsWrapper>
      <MznPaginationTable
        fetchData={refetchGetTransferOrdersWithPagination}
        pageInfo={pageInfo}
        scroll={{ x: 2300 }}
        bodyClassName={sharedStyles.table}
        columns={columns}
        loading={isLoading}
        dataSource={tableDataSource ?? []}
        bodyRowClassName={sharedStyles.table}
        rowSelection={{
          selectedRowKey: selectedKeys,
          onChange: setSelectedKeys,
        }}
        expandable={{
          rowExpandable: (record: InventoryTransferOrder) =>
            (record?.items?.length ?? -1) > 0,
          expandedRowRender: (record: InventoryTransferOrder) => ({
            dataSource: record?.items ?? [],
            className: 'expanded-table-row',
            columns,
          }),
        }}
      />
      <ModalGroup {...modalGroupController}>
        <LocationSelectorGroupModal onSubmit={appendLocationIds} />
      </ModalGroup>
    </PageLayout>
  );
}
