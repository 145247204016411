import { Table } from '@mezzanine-ui/react';
import {
  CheckInFormFields,
  useIsStationListColumns,
} from '../hooks/use-in-station-list-columns';
import { useFormContext } from 'react-hook-form';
import { useWorkOrderInfo } from '../hooks/use-work-order-info';
import { useRef } from 'react';
import { useDraggableColumns } from '@solar/templates';

export function MaterialRequestOrderTable({
  queryWorkOrderId,
}: {
  queryWorkOrderId?: string | null;
}) {
  const methods = useFormContext<CheckInFormFields>();
  const tableRef = useRef<HTMLTableElement>(null);

  const materialCheckInList = methods.watch('materialCheckInList');

  const requestMaterialBatchIds = methods.watch(
    materialCheckInList?.map(
      (item, index) =>
        `materialCheckInList.${index}.inputMaterialBarcode` as const
    ) ?? []
  );

  const defaultColumns = useIsStationListColumns(requestMaterialBatchIds);

  const columns = useDraggableColumns(tableRef, defaultColumns);

  const { workOrder, isLoading } = useWorkOrderInfo(queryWorkOrderId);

  const workOrderSpecs = workOrder?.materialRequestOrderSpecs;

  return Array.isArray(workOrderSpecs) ? (
    <Table
      ref={tableRef}
      draggable={{ enabled: false }}
      loading={isLoading}
      columns={columns}
      dataSource={workOrderSpecs}
    />
  ) : null;
}
