import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

export function PDFPreviewer({
  width = 900,
  fileLink,
}: {
  width?: number;
  fileLink?: string;
}) {
  const [numPages, setNumPages] = useState<number>();

  return (
    <div>
      {fileLink && (
        <Document
          file={{ url: fileLink }}
          onLoadSuccess={(pdf) => setNumPages(pdf?.numPages)}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={width}
            />
          ))}
        </Document>
      )}
    </div>
  );
}
