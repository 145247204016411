import {
  Button,
  Icon,
  Loading,
  Message,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  SelectValue,
  Typography,
} from '@mezzanine-ui/react';
import { OrderDetailLine } from './dto/order-detail-line.dto';
import styles from './ContractedAmountModal.module.scss';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { TimesIcon } from '@mezzanine-ui/icons';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { API_NAMESPACE, request } from '@solar/data';
import useSWR from 'swr';

interface ContractedAmountRecord {
  orderId: string;
  salesOrderLine: string;
  element: string;
  amount: number | null;
  createdAt: string;
  updatedAt: string;
}

interface FormFields {
  elements: {
    id: string;
    element: SelectValue;
    amount: number | null;
  }[];
}

const Elements = [
  {
    id: 'Ag',
    name: 'Ag',
  },
  {
    id: 'Au',
    name: 'Au',
  },
  {
    id: 'Ga',
    name: 'Ga',
  },
  {
    id: 'In',
    name: 'In',
  },
  {
    id: 'Ir',
    name: 'Ir',
  },
  {
    id: 'Pd',
    name: 'Pd',
  },
  {
    id: 'Pt',
    name: 'Pt',
  },
  {
    id: 'Re',
    name: 'Re',
  },
  {
    id: 'Rh',
    name: 'Rh',
  },
  {
    id: 'Ru',
    name: 'Ru',
  },
];

const validateSchema = Yup.object({
  elements: Yup.array().of(
    Yup.object().shape({
      element: Yup.object()
        .shape({
          id: Yup.string(),
          name: Yup.string(),
        })
        .test('element', '重複的元素', (thisElement, { from, path }) => {
          path.match(/elements\[(\d+)\]/);

          const index = Number(RegExp.$1);

          const elementSet = new Set<string>(
            [
              ...from![2].value.elements.slice(0, index),
              ...from![2].value.elements.slice(index + 1),
            ].map((element) => element.element.id)
          );

          return !thisElement || !elementSet.has(thisElement.id as string);
        })
        .required('約定元素必填'),
      amount: Yup.number()
        .transform((value, originValue) => {
          if (originValue === '') return null;

          if (Number.isNaN(value)) return '';

          return value;
        })
        .nullable()
        .notRequired()
        .typeError('請輸入數字'),
    })
  ),
});

export function ContractedAmountModal({
  onClose,
  orderId,
  salesOrderLine,
  customerId,
  customerName,
  materialId,
  materialName,
}: OrderDetailLine & { onClose: () => void }) {
  const { data } = useSWR<ContractedAmountRecord[]>([
    `/sd/${orderId}/${salesOrderLine}/contracted-amount`,
    {
      namespace: API_NAMESPACE.MATERIALS,
    },
  ]);

  const methods = useForm<FormFields>({
    defaultValues: {
      elements: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldUseNativeValidation: false,
    resolver: yupResolver(validateSchema),
  });

  const setValue = methods.setValue;

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'elements',
    keyName: 'id',
  });

  useEffect(() => {
    if (data) {
      setValue(
        'elements',
        data.map((record) => ({
          id: crypto.randomUUID(),
          element: Elements.find(
            (element) => element.id === record.element
          ) as SelectValue,
          amount: record.amount,
        }))
      );
    }
  }, [data, setValue]);

  const pickedSet = useMemo(
    () => fields.map((field) => field.element.id),
    [fields]
  );

  const onSubmit = useCallback(
    async (formData: FormFields) => {
      await request(`/sd/${orderId}/${salesOrderLine}/contracted-amount`, {
        method: 'PUT',
        namespace: API_NAMESPACE.MATERIALS,
        json: true,
        body: JSON.stringify({
          elements: formData.elements.map((row) => ({
            element: row.element.id,
            amount: Number.isNaN(Number(row.amount))
              ? null
              : Number(row.amount),
          })),
        }),
      });

      Message.success('更新成功');

      onClose();
    },
    [orderId, salesOrderLine, onClose]
  );

  return (
    <Modal open onClose={onClose}>
      <ModalHeader>約定純量設定</ModalHeader>
      <ModalBody>
        <FormFieldsWrapper methods={methods} className={styles.container}>
          <Typography variant="h5">訂單編號/項次</Typography>
          <Typography className={styles.spanValue} variant="body1">
            {orderId}/{salesOrderLine}
          </Typography>
          <Typography variant="h5">客戶名稱</Typography>
          <Typography className={styles.spanValue} variant="body1">
            {customerName} [{customerId}]
          </Typography>
          <Typography variant="h5">約定料號</Typography>
          <Typography className={styles.spanValue} variant="body1">
            {materialId}
          </Typography>
          <Typography variant="h5">品名</Typography>
          <Typography className={styles.spanValue} variant="body1">
            {materialName}
          </Typography>
          {data ? (
            <Fragment>
              {fields.map((field, index) => (
                <Fragment key={index}>
                  <SelectField
                    options={Elements}
                    placeholder="約定元素"
                    registerName={`elements.${index}.element`}
                  />
                  <InputField
                    valueAsNumber
                    placeholder="純量 (g)"
                    registerName={`elements.${index}.amount`}
                  />
                  <Button
                    onClick={() => remove(index)}
                    type="button"
                    danger
                    className={styles.removeButton}
                    prefix={<Icon icon={TimesIcon} />}
                  />
                </Fragment>
              ))}
              {fields.length >= Elements.length ? null : (
                <div className={styles.formActionLine}>
                  <Button
                    onClick={() =>
                      append({
                        id: crypto.randomUUID(),
                        element: Elements.filter(
                          (element) => !pickedSet.includes(element.id)
                        )[0],
                        amount: null,
                      })
                    }
                    size="small"
                    variant="outlined"
                    type="button"
                  >
                    新增元素
                  </Button>
                </div>
              )}
            </Fragment>
          ) : (
            <Loading loading />
          )}
        </FormFieldsWrapper>
      </ModalBody>
      <ModalActions
        confirmButtonProps={{
          disabled:
            !data ||
            !methods.formState.isValid ||
            methods.formState.isSubmitting,
        }}
        cancelButtonProps={{
          color: 'secondary',
        }}
        onConfirm={methods.handleSubmit(onSubmit)}
        confirmText="更新純量設定"
        onCancel={onClose}
        cancelText="取消"
      />
    </Modal>
  );
}
