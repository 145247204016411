import useSWR from 'swr';
import {
  APIResponse,
  BackplateSourceCode,
  CompletedStatusCode,
  CompositeCode,
  Drawing,
  DrawingRefs,
  DrillLayeringCode,
  ShapeCode,
  SizeCodeOne,
  SlottingCode,
  TypeCode,
} from './types';
import { API_NAMESPACE, request } from '../request';
import { useEffect, useState } from 'react';

const namespace = API_NAMESPACE.DRAWINGS;

export function useDrawingRefs(params: any) {
  const { data, error } = useSWR<APIResponse<DrawingRefs>>([
    '/drawings/componentDrawingRefs',
    {
      params,
      namespace,
    },
  ]);

  const drawingRefs = data?.data;

  return {
    drawingRefs,
    error,
    isLoading: !data && !error,
  };
}

export function useDrawings({ params }: { params: object }) {
  const { data, error } = useSWR<APIResponse<Drawing[]>>(
    params
      ? [
          '/drawings',
          {
            params,
            namespace,
          },
        ]
      : null
  );

  const drawings = data?.data || [];

  return {
    drawings,
    error,
    isLoading: !data && !error,
    pageInfo: {
      page: data?.page ?? 1,
      size: data?.size ?? 10,
      count: data?.count ?? 0,
    },
  };
}

export function useTypeCodesDDL() {
  const { data, error } = useSWR<APIResponse<TypeCode[]>>([
    '/drawings/typeCodes',
    {
      namespace,
    },
  ]);

  const result = data?.data || [];

  return {
    data: result,
    options: result.map(({ name, code }) => ({
      text: name,
      label: name,
      value: code,
    })),
    isLoading: !data && !error,
    error,
  };
}

export function useCompositeCodesDDL() {
  const { data, error } = useSWR<APIResponse<CompositeCode[]>>([
    '/drawings/compositeCodes',
    {
      namespace,
    },
  ]);

  const result = data?.data || [];

  return {
    data: result,
    options: result.map(({ name, code }) => ({
      text: `${name}（${code}）`,
      label: `${name}（${code}）`,
      value: code,
    })),
    isLoading: !data && !error,
    error,
  };
}

export function useShapeCodesDDL() {
  const { data, error } = useSWR<APIResponse<ShapeCode[]>>([
    '/drawings/shapeCodes',
    {
      namespace,
    },
  ]);

  const result = data?.data || [];

  return {
    data: result,
    options: result.map(({ name, code }) => ({
      text: `${name}（${code}）`,
      label: `${name}（${code}）`,
      value: code,
    })),
    isLoading: !data && !error,
    error,
  };
}

export function useSizeCodesDDL() {
  const { data, error } = useSWR<APIResponse<SizeCodeOne[]>>([
    '/drawings/sizeCodes',
    {
      namespace,
    },
  ]);

  const result = data?.data || [];

  const sizeCodeOneOptions = Array.from({ length: 26 }, (_v, index) =>
    String.fromCharCode(index + 65)
  )
    .filter((char) => char !== 'I' && char !== 'O')
    .map((char, index) => ({ id: index, code: char, value: index + 10 }));

  const getNumberString = (num: number) => {
    switch (true) {
      case num < 10:
        return '0' + num.toString();
      default:
        return num.toString();
    }
  };

  const getSizeCodeOneBySizeLength = (mm: number) => {
    const value = Math.floor(mm / 100);
    const numStr = getNumberString(Math.round(mm % 100));

    switch (true) {
      case value < 10:
        return value.toString() + numStr;
      case value > 33:
        return 'Z' + numStr;
      default:
        return (
          sizeCodeOneOptions?.find((item) => item.value === value)?.code +
          numStr
        );
    }
  };

  return {
    data: result,
    options: result.map(({ code, upperBound, lowerBound }) => ({
      text: `${lowerBound} mm ~ ${
        upperBound ? `${upperBound} mm` : ''
      }（${code}）`,
      label: `${lowerBound} mm ~ ${
        upperBound ? `${upperBound} mm` : ''
      }（${code}）`,
      value: code,
    })),
    optionsForSizeLength: result.map(({ code, upperBound, lowerBound }) => ({
      text: `${lowerBound} mm ~ ${upperBound ? `${upperBound} mm` : ''}`,
      label: `${lowerBound} mm ~ ${upperBound ? `${upperBound} mm` : ''}`,
      value: code,
    })),
    sizeCodeOneOptions,
    getSizeCodeOneBySizeLength,
    isLoading: !data && !error,
    error,
  };
}

export function useSandblastingCodesDDL() {
  const { data, error } = useSWR<APIResponse<{ code: string; name: string }[]>>(
    [
      '/drawings/sandblastingCodes',
      {
        namespace,
      },
    ]
  );

  const result = data?.data || [];

  return {
    data: result,
    options: result.map(({ code, name }) => ({
      text: `${name}（${code}）`,
      label: `${name}（${code}）`,
      value: code,
    })),
    isLoading: !data && !error,
    error,
  };
}

export function useDrillLayeringCodesDDL() {
  const { data, error } = useSWR<APIResponse<DrillLayeringCode[]>>([
    '/drawings/drillLayeringCodes',
    {
      namespace,
    },
  ]);

  const result = data?.data || [];

  return {
    data: result,
    options: result.map(({ code, isDrilling, isLayering }) => ({
      text: `${isDrilling ? '有' : '無'}鑽孔、${
        isLayering ? '有' : '無'
      }分階（${code}）`,
      label: `${isDrilling ? '有' : '無'}鑽孔、${
        isLayering ? '有' : '無'
      }分階（${code}）`,
      value: code,
    })),
    isLoading: !data && !error,
    error,
  };
}

export function useSlottingCodesDDL() {
  const { data, error } = useSWR<APIResponse<SlottingCode[]>>([
    '/drawings/slottingCodes',
    {
      namespace,
    },
  ]);

  const result = data?.data || [];

  return {
    data: result,
    options: result.map(({ code, isSurfaceSlotting, isSideSlotting }) => ({
      text: `${isSurfaceSlotting ? '有' : '無'}表面開槽、${
        isSideSlotting ? '有' : '無'
      }側面開槽（${code}）`,
      label: `${isSurfaceSlotting ? '有' : '無'}表面開槽、${
        isSideSlotting ? '有' : '無'
      }側面開槽（${code}）`,
      value: code,
    })),
    isLoading: !data && !error,
    error,
  };
}

export function useBackplateSourceCodesDDL() {
  const { data, error } = useSWR<APIResponse<BackplateSourceCode[]>>([
    '/drawings/backplateSources',
    {
      namespace,
    },
  ]);

  const result = data?.data || [];

  return {
    data: result,
    options: result.map(({ code, name }) => ({
      text: name,
      label: name,
      value: code,
    })),
    isLoading: !data && !error,
    error,
  };
}

export function useCompletedStatusCodesDDL() {
  const { data, error } = useSWR<APIResponse<CompletedStatusCode[]>>([
    '/drawings/completedStatuses',
    {
      namespace,
    },
  ]);

  const result = data?.data || [];

  return {
    data: result,
    options: result.map(({ code, name }) => ({
      text: name,
      label: name,
      value: code,
    })),
    isLoading: !data && !error,
    error,
  };
}

export function useDrawingsQueryDDL() {
  const typeCodes = useTypeCodesDDL();
  const compositeCodes = useCompositeCodesDDL();
  const shapeCodes = useShapeCodesDDL();
  const sizeCodes = useSizeCodesDDL();
  const drillLayeringCodes = useDrillLayeringCodesDDL();
  const slottingCodes = useSlottingCodesDDL();
  const backplateSourceCodes = useBackplateSourceCodesDDL();
  const completedStatusCodes = useCompletedStatusCodesDDL();
  const sandblastingCodes = useSandblastingCodesDDL();
  return {
    typeCodes,
    compositeCodes,
    shapeCodes,
    sizeCodes,
    drillLayeringCodes,
    slottingCodes,
    backplateSourceCodes,
    completedStatusCodes,
    sandblastingCodes,
  };
}

export async function createDrawing(data: any) {
  const response = await request(`/drawings`, {
    method: 'post',
    body: JSON.stringify(data),
    responseParser: (res) => res,
    namespace,
  });
  const result = await response.json();
  return result;
}

export async function updateDrawing({ id, data }: any) {
  const response = await request(`/drawings/${id}`, {
    method: 'put',
    body: JSON.stringify(data),
    responseParser: (res) => res,
    namespace,
  });
  const result = await response.json();
  return result;
}

export async function deleteDrawing({ id, newDrawingCode, bu }: any) {
  const response = await request(`/drawings`, {
    method: 'delete',
    params: {
      ids: [id].filter(Boolean),
      newDrawingCodes: [newDrawingCode].filter(Boolean),
      bu,
    },
    responseParser: (res) => res,
    namespace,
  });
  const result = await response.json();
  return result;
}
