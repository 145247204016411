import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { SelectValue } from '@mezzanine-ui/react';
import {
  GetMaterialClassCodesParams,
  GetMaterialClassCodesResponse,
} from './material-class-codes.type';
import { useState } from 'react';

const namespace = API_NAMESPACE.MATERIALS;

export function useMaterialClassCodes(
  defaultParams?: GetMaterialClassCodesParams
) {
  const [params, setParams] = useState<GetMaterialClassCodesParams | undefined>(
    defaultParams
  );

  const { data, error, mutate } = useSWR<GetMaterialClassCodesResponse>([
    '/material-properties/class-codes',
    { namespace, params },
  ]);

  return {
    materialClassCodes: data ?? [],
    mutateGetMaterialClassCodes: mutate,
    refetchMaterialClassCodes: setParams,
    isLoading: !error && !data,
  };
}
