import * as Yup from 'yup';

export enum QuestionType {
  TEXT = 'TEXT',
  NUMERIC = 'NUMERIC',
  TIMESTAMP = 'TIMESTAMP',
  ENUM = 'ENUM',
}

export const QUESTION_TYPE_OPTIONS = [
  {
    label: '文字',
    value: QuestionType.TEXT,
  },
  {
    label: '數值',
    value: QuestionType.NUMERIC,
  },
  {
    label: '時間',
    value: QuestionType.TIMESTAMP,
  },
  {
    label: '選項',
    value: QuestionType.ENUM,
  },
];

export const formTemplateCreatorSchema = Yup.object({
  fieldName: Yup.string().required('請輸入參數名稱'),
  name: Yup.string().required('請輸入顯示名稱'),
  suffix: Yup.string().optional(),
  type: Yup.string()
    .oneOf(QUESTION_TYPE_OPTIONS.map((option) => option.value))
    .required('請選擇題型'),
  placeholder: Yup.string().optional(),
  max: Yup.string().optional(),
  min: Yup.string().optional(),
  scale: Yup.string().optional(),
  precision: Yup.string().optional(),
  options: Yup.array()
    .when('type', {
      is: QuestionType.ENUM,
      then: (schema) =>
        schema
          .of(Yup.string())
          .min(1, '至少輸入一個選項')
          .required('請輸入選項'),
    })
    .optional(),
});
