import { PageLayout } from "../../Templates/PageLayout/PageLayout";
import moment from "moment";
import { Button, Table, Message, Icon, Typography, Popconfirm, SelectValue } from "@mezzanine-ui/react";
import { Link } from "react-router-dom";
import { useOrderPredict, useOrderPredictSearch } from "@solar/data";
import { useState, useCallback, useEffect, MouseEvent, useRef } from "react";
import { useForm } from "react-hook-form";
import styles from './OrderPredictSearchPage.module.scss';
import { ChevronDownIcon } from "@mezzanine-ui/icons";
import { FormFieldsWrapper, DateRangePickerField, AutoCompleteField } from "@mezzanine-ui/react-hook-form";
import DebounceAutoCompleteField from "../components/DebounceAutoCompleteField";
import { TablePagination } from "@mezzanine-ui/core/table";

interface ISearchFormVal {
  dateRange?: [string, string];
  customer?: SelectValue<string>;
  material?: SelectValue<string>;
  sales?: SelectValue<string>;
};

const OrderPredictSearchPage = () => {
  const {
    autoCompleteOptionsCustomer,
    autoCompleteOptionsMaterial,
    autoCompleteOptionsSales,
  } = useOrderPredict();

  const {
    searchPredictOrder,
    onPageChange,
    deletePredictOrder,
    searchLoading,
    serachResult,
    pageInfo,
    searchErrorMessage,
  } = useOrderPredictSearch();

  const formRef = useRef<boolean>(true);

  const [onDeleteId, setOnDeleteId] = useState<string | null>(null);
  const [onDelAnchor, setOnDelAnchor] = useState<HTMLButtonElement | null>(null);

  const searchForm = useForm();

  const [expandFilter, setExpandFilter] = useState(false);

  const toggleExpandIcon = useCallback(() => setExpandFilter((expand) => !expand), []);

  const onInsert = (text: string) => {
    return {
      id: text,
      name: text,
    };
  }

  const onSubmit = (data: ISearchFormVal) => {
    const { dateRange, customer, material, sales } = data;
    const params = {
      startDate: dateRange?.[0],
      endDate: dateRange?.[1],
      customerName: customer?.name,
      materialId: material?.id,
      salesName: sales?.name,
    };

    const pagination = {
      current: 1,
      pageSize: 10,
      total: 0,
    };
    searchPredictOrder(params, pagination);
  };

  useEffect(() => {
    // 因為 React 18 的 strict mode 會導致 componentDidMount 被呼叫兩次，我做了一個 ref 來控制只能呼叫一次
    // console.log('form ref', formRef.current);
    if (formRef.current) {
      formRef.current = false;
      onSubmit({});
    }
  }, [formRef]);


  const onDelete = async () => {
    try {
      if (onDeleteId) {
        await deletePredictOrder(onDeleteId);
        Message.success('刪除成功');
        searchForm.handleSubmit(onSubmit)();
        setOnDeleteId(null);
      }
    } catch (error) {
      Message.error('刪除失敗');
    }
  }

  const onClickConfirmDel = () => {
    onDelete();
  }

  const onClickTableDel = (id: any) => (e: MouseEvent<HTMLButtonElement>) => {
    setOnDelAnchor(e.currentTarget);
    setOnDeleteId(id);
  };

  const columns = [
    {
      title: '操作',
      render: (record: any) => (
        <div>
          <Link to={`/order/order-predict-edit/${record.id}`}>
            <Button variant="text" >編輯</Button>
          </Link>
          <Button variant="text" danger onClick={onClickTableDel(record.id)}>刪除</Button>
        </div>
      ),
    },
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '訂單日期',
      render: (record: any) => moment(record.orderDate).format('YYYY-MM-DD'),
    },
    {
      title: '客戶名稱',
      render: (record: any) =>
        record.customer
          ? `${record.customer}-${record.customerName}`
          : `${record.customerName}`,
    },
    {
      title: '業務人員',
      render: (record: any) =>
        record.sales
          ? `${record.sales}-${record.salesName}`
          : `${record.salesName}`,
    }
  ];

  const pagination: TablePagination = {
    current: pageInfo?.current || 1,
    total: pageInfo?.total || 0,
    onChange: onPageChange,
    disableAutoSlicing: true,
  };

  return (
    <PageLayout title="預測訂單管理">
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Typography variant="h4">查詢</Typography>
          <Icon
            icon={ChevronDownIcon}
            onClick={toggleExpandIcon}
            className={expandFilter ? styles.noRotateIcon : styles.rotateIcon}
          />
        </div>
        <div className={styles.border}></div>
        <FormFieldsWrapper
          methods={searchForm}
          className={expandFilter ? styles.displayNone : ''}
          onSubmit={onSubmit}
        >
          <div className={styles.space}>

            <DateRangePickerField
              registerName="dateRange"
              label="訂單日期"
            />

            <AutoCompleteField
              registerName="customer"
              label="客戶名稱"
              options={autoCompleteOptionsCustomer}
              addable
              onInsert={onInsert}
            />

            <DebounceAutoCompleteField
              registerName="material"
              label="料號"
              options={autoCompleteOptionsMaterial}
            />

            <AutoCompleteField
              registerName="sales"
              label="業務"
              options={autoCompleteOptionsSales}
            />
            <Button type="submit" variant="contained">查詢</Button>
          </div>
          {
            searchErrorMessage && (
              <Typography color="error">
                {searchErrorMessage}
              </Typography>
            )
          }
        </FormFieldsWrapper>
      </div>
      <div>
        <Link to="/order/order-predict-add">
          <Button variant="outlined" >新增預測訂單</Button>
        </Link>
      </div>
      <Table
        columns={columns}
        dataSource={serachResult}
        pagination={pagination}
        loading={searchLoading}
      />

      <Popconfirm
        open={!!onDeleteId}
        confirmButtonProps={{ danger: true }}
        title={`確定要刪除該筆資料? ID:${onDeleteId}`}
        cancelText="取消"
        confirmText="刪除"
        onConfirm={onClickConfirmDel}
        onCancel={() => setOnDeleteId(null)}
        anchor={onDelAnchor} />
    </PageLayout>
  );
};

export default OrderPredictSearchPage;