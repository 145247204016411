import { EquipmentMaintenanceTable } from './EquipmentMaintenanceTable';
import { EquipmentMaintenanceFilter, EquipmentMaintenanceFilterForm } from './EquipmentMaintenanceFilter';
import { useCallback, useEffect } from 'react';
import { ViewEquipmentMaintenanceModal } from './ViewEquipmentMaintenanceModal';
import { EditEquipmentMaintenanceModal } from './EditEquipmentMaintenanceModal';
import { useForm } from 'react-hook-form';
import { createRepairRequestLog, Equipment, useGetRepairRequests, NextPagination, getNextParams, CreateRepairRequestLogPayload } from '@solar/data';
import { LOCATION_SELECTOR, LocationSelectorModal } from '../LocationSelector/LocationSelectorModal';
import { PageLayout, ModalGroup, useModalGroupController } from '@solar/templates';
import { Modals } from './EquipmentMaintenanceModal.enum';
import moment from 'moment';
import { message } from 'antd';

export function EquipmentMaintenancePage() {
  const filterMethods = useForm<EquipmentMaintenanceFilterForm>();
  const modalGroupController = useModalGroupController([
    { name: Modals.VIEW },
    { name: Modals.CREATE },
    { name: Modals.EDIT },
    { name: LOCATION_SELECTOR },
  ]);
  const { repairRequests, pageInfo, fetchRepairRequests, mutateGetRepairRequests } = useGetRepairRequests();

  const openViewModal = (selectedRepairRequest: any) => modalGroupController.openModal(Modals.VIEW, selectedRepairRequest);
  const openEditModal = (selectedRepairRequest: any) => modalGroupController.openModal(Modals.EDIT, selectedRepairRequest);
  const openLocationSelectorModal = () => modalGroupController.openModal(LOCATION_SELECTOR, null);

  const appendSelectedEquipments = (selectedItems: Equipment[]) => {
    const appendEquipments: Equipment[] = [];
    const equipments = filterMethods.getValues('equipments') ?? [];
    const idSet = new Set(equipments?.map((e: Equipment) => e?.id));
    selectedItems?.forEach((item) => {
      if (!idSet.has(item?.id)) appendEquipments.push(item);
    });
    filterMethods.setValue('equipments', [...equipments, ...appendEquipments]);
  };

  const refetchRepairRequests = useCallback((nextPagination: NextPagination) => {
    const filterState = filterMethods.getValues();
    const { offset, limit } = getNextParams(nextPagination, pageInfo);
    const requestFrom = filterState?.rangeOfRequestDate?.[0];
    const requestTo = filterState?.rangeOfRequestDate?.[1];
    fetchRepairRequests({
      requestFrom: requestFrom && moment(requestFrom).startOf('day').format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
      requestTo: requestTo && moment(requestTo).endOf('day').format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
      priority: (filterState?.priority?.id !== 'ALL') ? filterState?.priority?.id : undefined,
      status: (filterState?.status?.id !== 'ALL') ? filterState?.status?.id : undefined,
      equipmentIds: filterState?.equipments?.map((e) => e?.id),
      offset,
      limit,
    })
  }, []);

  useEffect(() => {
    const subscription = filterMethods.watch(() => refetchRepairRequests({ nextPage: 1 }));
    return () => subscription.unsubscribe();
  }, [filterMethods, filterMethods.watch, refetchRepairRequests]);

  return (
    <PageLayout title="工務報修">
      <EquipmentMaintenanceFilter
        methods={filterMethods}
        openLocationSelectorModal={openLocationSelectorModal} />
      <EquipmentMaintenanceTable
        dataSource={repairRequests}
        pageInfo={pageInfo}
        openViewModal={openViewModal}
        openEditModal={openEditModal}
        refetchRepairRequests={refetchRepairRequests} />
      <ModalGroup {...modalGroupController}>
        <ViewEquipmentMaintenanceModal />
        <EditEquipmentMaintenanceModal
          onSubmit={async (payload: CreateRepairRequestLogPayload) => {
            try {
              await createRepairRequestLog(payload);
              await mutateGetRepairRequests();
            } catch (error) {
              message.error('更新失敗');
            }
          }} />
        <LocationSelectorModal
          onSubmit={(selectedItem: { equipments: Equipment[] }) => {
            if (selectedItem?.equipments?.length > 0) {
              appendSelectedEquipments(selectedItem?.equipments);
            }
          }} />
      </ModalGroup>
    </PageLayout>
  );
}
