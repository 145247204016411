import { TableColumnProps } from 'antd';
import classes from '../../inventory-detail.module.scss';
import { InventoryDetailTableProps } from '../../types';

export function useTableColumnForPreview() {
  const column: TableColumnProps<InventoryDetailTableProps>[] = [
    { title: '儲位', dataIndex: 'storageLocation' },
    { title: '載具', dataIndex: 'vehicles' },
    {
      title: '料號',
      dataIndex: 'materialId',
      width: 150,
      render: (_, record) => {
        return (
          <span className={classes['line-break']}>{`${
            record['materialId'] ?? ''
          }`}</span>
        );
      },
    },
    {
      title: '品名',
      dataIndex: 'materialDescription',
      width: 150,
      render: (_, record) => {
        return (
          <span className={classes['line-break']}>{`${
            record['materialDescription'] ?? ''
          }`}</span>
        );
      },
    },
    { title: '批號', dataIndex: 'sapBatchId' },
    {
      title: '基礎計量單位數量',
      dataIndex: 'availableMaterialStockUnitQuantity',
    },
    { title: '單位', dataIndex: 'materialStockUnit' },
    {
      title: '管理單位數量',
      dataIndex: 'availableMaterialInputUnitQuantity', // availableMaterialInputUnitQuantity
      render: (_, record) => record['availableMaterialInputUnitQuantity'],
      width: 150,
    },
    { title: '單位', dataIndex: 'availableMaterialInputUnit' },
    { title: '實際重量', dataIndex: 'actualWeight' },
    { title: '單位', dataIndex: 'weightUnit' },
  ];

  return column;
}
