import {
  Message,
  ModalActions,
  SelectValue,
  Typography,
} from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  FormFieldsWrapper,
} from '@mezzanine-ui/react-hook-form';
import {
  useGetSalesOrders,
  useGetTargetRuleCustomers,
  useRefactoredOrders,
} from '@solar/data';
import { RowSection, useTargetModal } from '@solar/templates';
import { groupBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { ModalLayout } from '../../ModalLayout/ModalLayout';
import { EditModalDataProps, EditModalProps, Modals } from '../types';
import { updateSalesMaterials } from '../utils';
import classes from './edit.module.scss';

type Form = {
  customerId: SelectValue | null;
  salesOrderLine: SelectValue | null;
  salesOrder: SelectValue | null;
};

const THIS_FORM = Modals.EDIT;

export const Edit = ({ paginationCallBack }: EditModalProps) => {
  const methods = useForm<Form>();
  const modalController = useTargetModal<EditModalDataProps>(THIS_FORM);

  const { targetRuleCustomers } = useGetTargetRuleCustomers('');
  // const { mutateParams, orders } = useRefactoredOrders({
  //   initiateFetching: false,
  // });

  const { salesOrders, refetchSalesOrders } = useGetSalesOrders();

  const salesOrdersOptions = useMemo(() => {
    return salesOrders.map((order) => ({
      id: order?.id,
      name: order?.id,
    }));
  }, [salesOrders]);

  const watchedSalesOrder = useWatch({
    control: methods.control,
    name: 'salesOrder',
  });

  const salesOrderLinesOptions = useMemo(() => {
    return (
      salesOrders
        ?.find((order) => order.id === watchedSalesOrder?.id)
        ?.orderDetails.map((detail) => ({
          id: detail.salesOrderLine,
          name: detail.salesOrderLine,
        })) ?? []
    );
  }, [watchedSalesOrder]);

  const onCustomerChange = useCallback(
    (customer: SelectValue | null) => {
      refetchSalesOrders({ customerIds: customer?.id ? [customer?.id] : [] });
    },
    [refetchSalesOrders]
  );

  const onSalesOrderChange = useCallback(
    (order: SelectValue | null) => {
      if (order) {
        const salesOrderLine = methods.getValues('salesOrderLine');
        const targetSalesOrder = salesOrders?.find(
          (order) => order.id === order.id
        );
        if (!salesOrderLine && !!targetSalesOrder?.orderDetails?.[0]) {
          methods.setValue('salesOrderLine', {
            id: targetSalesOrder.orderDetails[0]?.salesOrderLine,
            name: targetSalesOrder.orderDetails[0]?.salesOrderLine,
          });
        }
      } else {
        methods.setValue('salesOrderLine', null);
      }
    },
    [salesOrders, methods]
  );

  const onSalesOrderSearch = useCallback(
    (searchTerm: string) => {
      const customer = methods.getValues('customerId');
      refetchSalesOrders({
        orderIds: searchTerm,
        customerIds: customer?.id ? [customer?.id] : [],
      });
    },
    [refetchSalesOrders]
  );

  const watchedSalesOrderLine = useWatch({
    control: methods.control,
    name: 'salesOrderLine',
  });

  const selectedSalesOrderLine = useMemo(() => {
    return salesOrders
      ?.find((order) => order.id === watchedSalesOrder?.id)
      ?.orderDetails.find(
        (detail) => detail.salesOrderLine === watchedSalesOrderLine?.id
      );
  }, [watchedSalesOrder, watchedSalesOrderLine]);

  const [submitting, setSubmitting] = useState(false);

  const closeModal = useCallback(() => {
    modalController.closeModal();
    methods.reset();
  }, [methods, modalController, paginationCallBack]);

  return (
    <ModalLayout
      modalHeader={<br />}
      loading={methods.formState.isSubmitting}
      {...modalController}
      closeModal={closeModal}
      modalFooter={
        <ModalActions
          confirmText="確認"
          cancelText="取消"
          confirmButtonProps={{ form: THIS_FORM, loading: submitting }}
          onCancel={closeModal}
        />
      }
    >
      <FormFieldsWrapper
        id={THIS_FORM}
        methods={methods}
        onSubmit={async () => {
          try {
            setSubmitting(true);

            if (modalController.data && selectedSalesOrderLine) {
              const { loaderId, sapBatchId, subBatchId } =
                modalController?.data;
              const res = await updateSalesMaterials({
                payload: {
                  loaderId,
                  sapBatchId,
                  subBatchId,
                  materialId: selectedSalesOrderLine?.materialId,
                  orderId: selectedSalesOrderLine?.orderId,
                  orderSalesLine: selectedSalesOrderLine?.salesOrderLine,
                },
              });

              if (res?.ok) {
                Message.success('更新成功');
                paginationCallBack({});
                closeModal();
              }
            }
          } catch (err) {
            if (err instanceof Error) {
              Message.error(JSON.parse(err.message).message);
            } else {
              Message.error('更新失敗');
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        <RowSection label="客戶代碼：">
          <AutoCompleteField
            registerName="customerId"
            options={targetRuleCustomers ?? []}
            onChange={onCustomerChange}
          />
        </RowSection>
        <div className={classes['sales-order']}>
          <RowSection label="銷售訂單：">
            <AutoCompleteField
              registerName="salesOrder"
              options={salesOrdersOptions ?? []}
              onChange={onSalesOrderChange}
              onSearch={onSalesOrderSearch}
            />
          </RowSection>
          <RowSection label="項次：">
            <AutoCompleteField
              registerName="salesOrderLine"
              options={salesOrderLinesOptions}
            />
          </RowSection>
        </div>
        <RowSection label="料號：">
          <Typography>{selectedSalesOrderLine?.materialId}</Typography>
        </RowSection>
        <RowSection label="品名：">
          <Typography>
            {selectedSalesOrderLine?.material?.description}
          </Typography>
        </RowSection>
      </FormFieldsWrapper>
    </ModalLayout>
  );
};
