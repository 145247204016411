import { Equipment } from '@solar/data';

export enum ProductionFormFieldType {
  NUMERIC = 'NUMERIC',
  ENUM = 'ENUM',
  TEXT = 'TEXT',
  // TEXT_AREA = 'TEXT_AREA',
  TIMESTAMP = 'TIMESTAMP',
}

interface ProductionFormFieldBase {
  type: ProductionFormFieldType;
  required?: boolean; // Default is false
  placeholder?: string;
}

export interface ProductionFormFieldNumeric extends ProductionFormFieldBase {
  type: ProductionFormFieldType.NUMERIC;
  avg?: number;
  min?: number;
  max?: number;
  std?: number;
  // scale?: number;
  // precision?: number;
  specMax?: number;
  specMin?: number;
  controlMax?: number;
  controlMin?: number;
}

export interface ProductionFormFieldTimestamp extends ProductionFormFieldBase {
  type: ProductionFormFieldType.TIMESTAMP;
}

export interface ProductionFormFieldEnum extends ProductionFormFieldBase {
  type: ProductionFormFieldType.ENUM;
  enum: string[];
}

export interface ProductionFormFieldText extends ProductionFormFieldBase {
  type: ProductionFormFieldType.TEXT;
  maxLength?: number;
}

// export interface ProductionFormFieldTextArea extends ProductionFormFieldBase {
//   type: ProductionFormFieldType.TEXT_AREA;
//   maxLength?: number;
// }

export type ProductionFormField =
  | ProductionFormFieldNumeric
  | ProductionFormFieldEnum
  | ProductionFormFieldText
  // | ProductionFormFieldTextArea
  | ProductionFormFieldTimestamp;

export interface ProductionInfoBaseResponse {
  id: string;
  material: {
    id: string;
    name: string;
  } | null;
  workCenter: {
    id: string;
    name: string;
  } | null;
  equipment: {
    id: string;
    name: string;
  } | null;
  processing: {
    id: string;
    name: string;
  } | null;
  recipe: {
    id: string;
  } | null;
}

export interface ProductionParameterResponse
  extends ProductionInfoBaseResponse {
  key: string;
  value: string;
}

export interface ProductionFormResponse extends ProductionInfoBaseResponse {
  id: string;
  fields: ProductionFormField;
  category: string;
  sortIndex?: number;
  fieldName?: string;
  isIPQC?: boolean;
  isIQC?: boolean;
  limsItem: any;
  blockByOOC?: boolean;
  blockByOOCShelfId: any;
  spcRules: any;
  deletedAt: any;
  processingId: any;
  materialId: any;
  workCenterId: any;
  equipmentId?: string;
  recipeId: any;
  recipeVersion?: number;
  material: any;
  workCenter: any;
  equipment: Equipment | null;
  processing: any;
  recipe: any;

  title: string | null;
  suffix: string | null;
  createdAt: string;
  updatedAt: string;
  // templateVersion: {
  //   production_form_template_id: string;
  //   version: number;
  //   fields: ProductionFormField;
  //   template: {
  //     id: string;
  //     type: 'PRODUCT';
  //     name: string;
  //     suffix: string | null;
  //     fieldName: string;
  //   };
  // };
}

export type FormTemplateType =
  | 'PRODUCT'
  | 'EQUIPMENT'
  | 'COMPONENT_CODE'
  // | 'LIMS'
  | 'IQC';

export interface ProductionFormCondition {
  materialId?: string;
  recipeId?: string;
  recipeVersion?: string;
  processingId?: string;
  workCenterId?: string;
  equipmentId?: string;
  iqcVersion?: string;
  formTemplate?: {
    id: string;
    name: string;
    versions: { version: number }[];
  };
  templateTypes?: FormTemplateType[];
}
