import useSWR from 'swr';
import { API_NAMESPACE, request } from '../request';
import { useState } from 'react';
import {
  CreateSubmissionPayload,
  GetSubmissionOrganizationsParams,
  GetSubmissionResultsByInventoryParams,
  GetSubmissionResultsByInventoryResponse,
  GetSubmissionResultsParams,
  GetSubmissionResultsResponse,
  SubmissionOrgResponse,
  SubmissionStatus,
} from './submission.type';

const namespace = API_NAMESPACE.PP;

export function useGetSubmissionStatuses() {
  const { data, error } = useSWR<SubmissionStatus[]>([
    '/pp/lims/submission/statuses',
    {
      namespace,
    },
  ]);
  return {
    submissionStatuses: data ?? [],
    isLoading: !error && !data,
  };
}

export function useGetSubmissionOrganizations(
  defaultParams: GetSubmissionOrganizationsParams | undefined
) {
  const [params, setParams] = useState<
    GetSubmissionOrganizationsParams | undefined
  >(defaultParams);
  const { data, error, mutate, isValidating } = useSWR<SubmissionOrgResponse>(
    params?.factory
      ? [
          '/pp/lims/submission/organizations',
          {
            namespace,
            params,
          },
        ]
      : null
  );

  return {
    submissionOrganizations: data?.records ?? [],
    pageInfo: data?.pageInfo,
    isLoading: !error && !data && !isValidating,
    refetchGetSubmissionOrganizations: setParams,
    mutateGetSubmissionOrganizations: mutate,
  };
}

export function createSubmission(payload: CreateSubmissionPayload) {
  return request('/pp/lims/submission', {
    method: 'POST',
    namespace,
    body: JSON.stringify(payload),
  });
}

export function useGetSubmissionResultsByInventory() {
  const [params, setParams] = useState<GetSubmissionResultsByInventoryParams>();
  const { data, error, isValidating, mutate } =
    useSWR<GetSubmissionResultsByInventoryResponse>(
      params?.factory && params?.materialId && params?.batchId
        ? [
            '/pp/lims/sample/submission-results',
            {
              namespace,
              params,
            },
          ]
        : null
    );

  return {
    submissionResults: data?.records ?? [],
    pageInfo: data?.pageInfo,
    isLoading: !error && !data && !isValidating,
    refetchGetSubmissionResults: setParams,
    mutateGetSubmissionResults: mutate,
  };
}

export function useGetSubmissionResults(
  defaultParams?: GetSubmissionResultsParams
) {
  const [params, setParams] = useState<GetSubmissionResultsParams | undefined>(
    defaultParams
  );
  const { data, error, isValidating, mutate } =
    useSWR<GetSubmissionResultsResponse>(
      params?.factory
        ? [
            '/pp/lims/submission-results',
            {
              namespace,
              params,
            },
          ]
        : null
    );

  return {
    submissionResults: data?.records ?? [],
    pageInfo: data?.pageInfo,
    isLoading: !error && !data && !isValidating,
    refetchGetSubmissionResults: setParams,
    mutateGetSubmissionResults: mutate,
  };
}

export function syncLimsSubmissionResults({ factory }: { factory: string }) {
  return request('/pp/lims/sync-submission-results', {
    method: 'get',
    namespace,
    params: { factory },
  });
}
