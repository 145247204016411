import { redirect } from 'react-router';
import { environment } from './environments/environment';
import { Message } from '@mezzanine-ui/react';

export const API_NAMESPACE = {
  IAM: process.env['NX_API_PREFIX_IAM'] ?? '/iam/v1',
  EQUIPMENTS:
    process.env['NX_API_PREFIX_EQUIPMENTS'] ?? '/equipments/equipments',
  DRAWINGS: process.env['NX_API_PREFIX_DRAWINGS'] ?? '/drawing/api/v1',
  MATERIALS: process.env['NX_API_PREFIX_MATERIALS'] ?? '/materials/materials',
  PP: process.env['NX_API_PREFIX_PP'] ?? '/pp/pp',
  SD: process.env['NX_API_PREFIX_SD'] ?? '/sd/sd',
  LIMS: process.env['NX_API_PREFIX_LIMS'] ?? '/lims/lims',
  HR: process.env['NX_API_PREFIX_HR'] ?? '/hr-legacy',
  MISC: process.env['NX_API_PREFIX_MISC'] ?? '/misc',
  QC: process.env['NX_API_PREFIX_QC'] ?? '/qc-nodejs/qc',
};

function checkParamType(item: any) {
  switch (typeof item) {
    case 'string':
    case 'boolean':
      return true;
    case 'number':
      return isFinite(item);
    default:
      return false;
  }
}

function stringifyParams(params: object) {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        if (checkParamType(item)) searchParams.append(key, item);
      });
    } else {
      if (checkParamType(value)) searchParams.append(key, value);
    }
  });
  return searchParams.toString();
}

type RequestOptions = {
  auth?: boolean;
  json?: boolean;
  params?: object;
  namespace?: string;
  requestType?: 'json';
  responseType?: 'json' | 'text';
  responseParser?: (res: Response) => any;
};

export function request(
  endpoint: string,
  requestOptions?: RequestInit & RequestOptions
) {
  const {
    auth = true,
    json = true,
    params = {},
    namespace = API_NAMESPACE.EQUIPMENTS,
    responseParser,
    ...requestInit
  } = requestOptions ?? {};

  const paramsString = stringifyParams(params);
  const search = paramsString === '' ? paramsString : `?${paramsString}`;
  const url = `${environment.API_HOST}${namespace}${endpoint}${search}`;

  const headers = new Headers(requestInit?.headers);
  headers.set('apikey', environment.API_KEY);

  if (auth) {
    const accessToken = localStorage.getItem('accessToken');
    headers.set('Authorization', `Bearer ${accessToken}`);
  }

  if (json) {
    headers.append('Accept', `application/json`);
    headers.append('Content-Type', `application/json`);
  }

  return fetch(url, Object.assign(requestInit, { headers }))
    .then((response) => {
      if (!response?.ok) {
        return response.json().then((data) => {
          throw new Error(JSON.stringify(data));
        });
      }

      return responseParser?.(response) ?? response.json();
    })
    .catch((err) => {
      const error = JSON.parse(err?.message);
      switch (error?.statusCode) {
        case 401: {
          Message['error']('權限不足');
          redirect('/auth/login');
          // window.location.href = `${
          //   environment.production
          //     ? 'https://dev-edges.solartech.cloud'
          //     : 'http://localhost:4200'
          // }/auth/login`;
          break;
        }
        default:
          throw err;
      }
    });
}

export interface FetchRequestOptions extends RequestInit {
  auth?: boolean;
  json?: boolean;
  params?: object;
  namespace: string;
  endpoint: string;
}

export function fetchRequest(requestOptions: FetchRequestOptions) {
  const {
    json = true,
    auth = true,
    params = {},
    namespace,
    endpoint,
    ...requestInit
  } = requestOptions ?? {};

  const paramsString = stringifyParams(params);
  const search = paramsString === '' ? paramsString : `?${paramsString}`;
  const url = `${environment.API_HOST}${namespace}${endpoint}${search}`;

  const headers = new Headers(requestInit?.headers);
  headers.set('apikey', environment.API_KEY);

  if (auth) {
    const accessToken = localStorage.getItem('accessToken');
    headers.set('Authorization', `Bearer ${accessToken}`);
  }

  if (json) {
    headers.append('Accept', `application/json`);
    headers.append('Content-Type', `application/json`);
  }

  try {
    return fetch(
      url,
      Object.assign(requestInit, {
        headers,
      })
    );
  } catch (error: any) {
    switch (error?.statusCode) {
      case 401: {
        Message['error']('權限不足');
        redirect('/auth/login');
        break;
      }
      default:
        break;
    }

    throw error;
  }
}
