/* eslint-disable react/jsx-no-useless-fragment */
import { Select, Button, SelectProps, SelectValue } from '@mezzanine-ui/react';
import {
  SelectMultipleProps,
  SelectSingleProps,
} from '@mezzanine-ui/react/Select/Select';
import { Col, Row, RowProps } from 'antd';
import { Fragment } from 'react';
import { UseFormReturn } from 'react-hook-form';

type LocationIdsFieldProps = SelectProps & {
  methods: UseFormReturn<any>;
  registerName: string;
  buttonText?: string;
  rowProps?: RowProps;
  openModal: () => void;
};

export function LocationIdsField({
  methods,
  registerName,
  buttonText,
  rowProps,
  openModal,
  onChange,
  mode = 'multiple',
}: LocationIdsFieldProps) {
  const locationIds = methods.watch(registerName) as string[];

  const onSingleChange = (item: SelectValue | null) => {
    methods.setValue(registerName, item?.id ? [item.id] : []);
    if (item && onChange) {
      (onChange as SelectSingleProps['onChange'])?.(item);
    }
  };

  const onMultipleChange = (items: SelectValue[]) => {
    methods.setValue(
      registerName,
      items?.map((item) => item?.id)
    );
    if (Array.isArray(items) && onChange) {
      (onChange as SelectMultipleProps['onChange'])?.(items);
    }
  };

  const selectProps = (() => {
    switch (mode) {
      case 'single':
        return {
          mode: 'single' as const,
          value: {
            id: locationIds?.[0],
            name: locationIds?.[0],
          },
          onChange: onSingleChange,
        };
      default:
        return {
          mode: 'multiple' as const,
          value: locationIds?.map((id) => ({ id, name: id })),
          onChange: onMultipleChange,
        };
    }
  })();

  return (
    <Row gutter={[8, 8]} {...rowProps}>
      <Col span={18}>
        <Select
          ellipsis={true}
          clearable
          fullWidth
          disablePortal
          suffixActionIcon={<Fragment />}
          onClear={() => methods.setValue(registerName, [])}
          {...selectProps}
        />
      </Col>
      <Col span={6}>
        <Button type="button" variant="outlined" onClick={openModal}>
          {buttonText ?? '地圖'}
        </Button>
      </Col>
    </Row>
  );
}
