import type { ShipmentBoxMergeFormValues } from './form-definition';
import { shipmentBoxMergeFormResolver } from './form-validation';
import { ShipmentBoxMergeFieldName as FormFieldName } from './form-definition';
import { useForm, useFieldArray, useFormState } from 'react-hook-form';
import { request, API_NAMESPACE } from '../../request';
import * as yup from 'yup';

export function useShipmentBoxMergeForm() {
  const methods = useForm<ShipmentBoxMergeFormValues>({
    resolver: shipmentBoxMergeFormResolver,
    mode: 'onChange',
  });
  const formState = useFormState({ control: methods.control });

  const { fields } = useFieldArray({
    name: FormFieldName.BOX_MERGE_ITEMS,
    control: methods.control,
  });

  return {
    formMethods: methods,
    formFieldArray: fields,
    formFieldBoxNumberName: (index: number) =>
      `${FormFieldName.BOX_MERGE_ITEMS}.${index}.${FormFieldName.BOX_NUMBER}`,
    // formFieldPackageListIdName: (index: number) =>
    //   `${FormFieldName.BOX_MERGE_ITEMS}.${index}.${FormFieldName.PACKAGE_LIST_ID}`,
    formSubmitCreator,
    formState,
    isError:
      typeof formState.errors[FormFieldName.BOX_MERGE_ITEMS] !== 'undefined',
  };
}

async function postBoxMerge({
  deliveryOrderId,
  boxMergeItems,
}: ShipmentBoxMergeFormValues) {
  try {
    const safeDeliveryOrderId = yup
      .number()
      .integer()
      .moreThan(0)
      .validateSync(deliveryOrderId);

    return request(`/deliveryOrders/${safeDeliveryOrderId}/boxMerge`, {
      namespace: API_NAMESPACE.HR,
      method: 'POST',
      body: JSON.stringify([...boxMergeItems]),
    });
  } catch (error) {
    return null;
  }
}

type FormSubmitCreatorProps = {
  successCallback?: () => void;
  errorCallback?: (error: Error) => void;
};

function formSubmitCreator({
  successCallback,
  errorCallback,
}: FormSubmitCreatorProps) {
  const onSubmit = async (data: ShipmentBoxMergeFormValues) => {
    postBoxMerge(data)
      .then((res) => {
        successCallback?.();
      })
      .catch((error) => {
        errorCallback?.(error);
      });
  };
  return onSubmit;
}
