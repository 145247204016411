import { SelectValue } from '@mezzanine-ui/react';

export enum VirtualInType {
  DELIVERY_ORDER = 'DELIVERY_ORDER',
  INVENTORY_PURCHASE_ORDER = 'INVENTORY_PURCHASE_ORDER',
}

export interface BindingFormValues {
  [virtualOutDeliveryOrderItemId: string]: {
    receivedItems: {
      relatedOrderId: string;
      relatedOrderItemId: string;
      relatedOrderMaterialId: string;
    }[];
    relatedCategory: SelectValue<VirtualInType>;
    relatedOrderId: SelectValue | null;
    relatedOrderItemId: SelectValue | null;
    relatedOrderMaterialId: string | null;
  };
}
