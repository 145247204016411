import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { UseDataSourceHook } from '../common-table-filter-param';
import { OffsetBased, PageInfo } from '../pagination';
import { API_NAMESPACE } from '../request';

const STATES = {
  WAITING: '待發料',
  PARTIAL: '部分已發',
  COMPLETE: '已發料',
} as const;

const STATES_SELECTIONS = Object.entries(STATES).map(([key, value]) => ({
  id: key,
  name: value,
}));

const SORT_TYPE = {
  CREATED_AT_DESC: '工單建立日期近 -> 遠',
  CREATED_AT_ASC: '工單建立日期遠 -> 近',
  EXPECTED_COMPLETED_AT_DESC: '訂單交期近 -> 遠',
  EXPECTED_COMPLETED_AT_ASC: '訂單交期遠 -> 近',
};

export {
  STATES as OUTSOURCE_PURCHASE_MATERIAL_REQUEST_ORDERS_STATES,
  STATES_SELECTIONS as OUTSOURCE_PURCHASE_MATERIAL_REQUEST_ORDERS_STATES_SELECTIONS,
};

export type OutsourcePurchaseMaterialOrderRequestParams = {
  materialRequestOrderIds: string[];
  states: Array<keyof typeof STATES>;
  searchTerm: string;
  // sourceTypes: `${OutsourcePurchaseMaterialRequestOrderSourceType}`[];
  expectedCompletedAtDateFrom: string;
  expectedCompletedAtDateTo: string;
  actualCompletedAtDateFrom: string;
  actualCompletedAtDateTo: string;
  dateTo: string;
  dateFrom: string;
  receivedStates: [keyof typeof STATES];
  sortType: keyof typeof SORT_TYPE;
  supplierIds: string[];
  workOrderIds: string[];
} & OffsetBased;

export type OutsourcePurchaseMaterialRequestOrder = {
  id: string;
  outsourcePurchaseOrderId: string;
  recipeId: string | null;
  expectedCompletedAt: string;
  actualCompletedAt: string | null;
  // sourceType: `${OutsourcePurchaseMaterialRequestOrderSourceType}`;
  shippedState: `${OutsourcePurchaseShippedState}`;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  recipeName: string | null;
};

// export enum OutsourcePurchaseMaterialRequestOrderSourceType {
//   REQUEST_ORDER = 'REQUEST_ORDER',
//   INGREDIENTS_ORDER = 'INGREDIENTS_ORDER',
// }

// export const OUTSOURCE_PURCHASE_SOURCE_TYPE_TRANSLATION = {
//   [OutsourcePurchaseMaterialRequestOrderSourceType.REQUEST_ORDER]: '發料',
//   [OutsourcePurchaseMaterialRequestOrderSourceType.INGREDIENTS_ORDER]: '配料',
// };

export enum OutsourcePurchaseShippedState {
  WAITING = 'WAITING',
  PARTIAL = 'PARTIAL',
  COMPLETE = 'COMPLETE',
}

export const OUTSOURCE_PURCHASE_STATES_TRANSLATION = {
  [OutsourcePurchaseShippedState.WAITING]: '待發料',
  [OutsourcePurchaseShippedState.PARTIAL]: '部分已發',
  [OutsourcePurchaseShippedState.COMPLETE]: '已發料',
};

const namespace = API_NAMESPACE.MATERIALS;

export function useOutsourcePurchaseMaterialRequestOrders({
  initiateFetching = false,
}: UseDataSourceHook) {
  const [params, setParams] =
    useState<Partial<OutsourcePurchaseMaterialOrderRequestParams>>();
  const { data, error, mutate } = useSWR<{
    records: OutsourcePurchaseMaterialRequestOrder[];
    pageInfo: PageInfo;
  }>(
    initiateFetching || params
      ? [
          '/warehouses/outsource-purchase-material-request-orders',
          { params, namespace },
        ]
      : null
  );

  const pageInfo = useMemo(() => {
    const page =
      (data?.pageInfo?.offset ?? 0) / (data?.pageInfo?.limit ?? 20) + 1;
    const pageSize = data?.pageInfo?.limit ?? 20;
    return { ...(data?.pageInfo ?? {}), page, pageSize };
  }, [data]);

  return {
    orders: data?.records || [],
    pageInfo,
    isLoading: !error && !data,
    mutateParams: setParams,
    mutate,
  };
}
