import {
  DateRangePickerField,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';

import {
  MaterialRequestOrderSourceType,
  SOURCE_TYPE_TRANSLATION,
} from '@solar/data';

import classes from './filter.module.scss';

const SORT_TYPE = {
  EXPECTED_COMPLETED_AT_DESC: '預期發料日期新 -> 舊',
  EXPECTED_COMPLETED_AT_ASC: '預期發料日期舊 -> 新',
};

const SORT_TYPE_SELECTIONS = Object.entries(SORT_TYPE).map(([key, value]) => ({
  id: key,
  name: value,
}));

const CREATE_SOURCE_TYPE_SELECTIONS = Object.values(
  MaterialRequestOrderSourceType
).map((key) => ({
  id: key,
  name: SOURCE_TYPE_TRANSLATION[key],
}));

export function DispatchOrderPanelFilter() {
  return (
    <div className={classes.host}>
      <InputField
        registerName="searchTerm"
        placeholder="請輸入關鍵字進行搜尋（銷售訂單、工單、建立者、工作中心名稱、工作中心代碼、料號、品名"
      />
      <SelectField
        label="狀態："
        mode="multiple"
        clearable
        registerName="states"
        options={[]}
      />
      <SelectField
        label="建立來源："
        mode="multiple"
        clearable
        registerName="sourceType"
        options={CREATE_SOURCE_TYPE_SELECTIONS}
      />
      <DateRangePickerField
        fullWidth
        label="日期區間："
        registerName="createdDateRange"
      />
      <SelectField
        label="預期發料日期："
        clearable
        registerName="sortType"
        options={SORT_TYPE_SELECTIONS}
      />
    </div>
  );
}
