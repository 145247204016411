import { Button, Message, Typography } from '@mezzanine-ui/react';
import { API_NAMESPACE, request } from '@solar/data';
import { useEffect, useState } from 'react';
import styles from './LoginPage.module.scss';
import {
  FormFieldsWrapper,
  InputField,
  PasswordField,
} from '@mezzanine-ui/react-hook-form';
import { useForm } from 'react-hook-form';
import { Space } from 'antd';
import { useNavigate } from 'react-router';

export const defaultResponse = '錯誤，請再嘗試一次';

interface LoginFormValues {
  account: string;
  password: string;
}

export function LoginPage() {
  const navigate = useNavigate();
  const methods = useForm<LoginFormValues>();
  const [isSignIn, setIsSignIn] = useState(false);

  const login = methods.handleSubmit(async ({ account, password }) => {
    try {
      setIsSignIn(true);
      const response = await request('/login', {
        method: 'POST',
        namespace: API_NAMESPACE.IAM,
        body: JSON.stringify({
          account,
          password,
        }),
      });

      if (response.status === 'success') {
        const token = response.token;

        localStorage.setItem('accessToken', token);

        if (response.need_reset_password) {
          navigate(`/auth/reset-password`);
          return;
        }

        const redirect = localStorage.getItem('redirect');

        navigate(redirect ?? '/');

        Message.success('登入成功');

        localStorage.removeItem('redirect');
      }
    } catch (error) {
      if (error instanceof Error) {
        const errMessage: any = JSON.parse(error?.message ?? '') ?? '';

        switch (errMessage?.status) {
          case 400: {
            switch (errMessage?.title) {
              case 'invalid password':
                Message.error('帳號或密碼不正確');
                break;
              case 'over login attempt limit':
                Message.error('超過登入嘗試限制');
                break;
              default:
                Message.error(errMessage.title);
                break;
            }
            break;
          }
          case 401:
            Message.error('權限不足');
            break;
          default:
            Message.error(defaultResponse);
            break;
        }
      } else {
        Message.error(defaultResponse);
      }
    } finally {
      setIsSignIn(false);
    }
  });

  useEffect(() => {
    const loginHandler = (e: KeyboardEvent) => {
      if (e.key === 'Enter') login();
    };

    document.addEventListener('keydown', loginHandler);

    return () => document.removeEventListener('keydown', loginHandler);
  }, [login]);

  return (
    <div className={styles.wrapper}>
      <Typography variant="h2">登入</Typography>
      <FormFieldsWrapper methods={methods}>
        <Space direction="vertical">
          <div className={styles.label}>
            <Typography variant="h5">帳號</Typography>
          </div>
          <InputField registerName="account" required />
          <div className={styles.label}>
            <Typography variant="h5">密碼</Typography>
          </div>
          <PasswordField registerName="password" required />
        </Space>
      </FormFieldsWrapper>
      <Button
        loading={isSignIn}
        type="button"
        onClick={login}
        variant="contained"
        className={styles.loginButton}
      >
        登入
      </Button>
    </div>
  );
}
