import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { SelectValue } from '@mezzanine-ui/react';

const namespace = API_NAMESPACE.MATERIALS;

export function useMaterialMachiningCodes() {
  const { data, error } = useSWR<(SelectValue & { code: string })[]>([
    '/material-properties/machining-codes',
    { namespace },
  ]);

  return {
    materialMachiningCodes: data ?? [],
    isLoading: !error && !data,
  };
}
