import { Button, SelectValue, Table } from '@mezzanine-ui/react';
import { useGetMaterialClassCodes, useGetMaterialGroupCodes } from './hooks';
import { Space } from 'antd';
import { useForm, useWatch } from 'react-hook-form';
import {
  AutoCompleteField,
  FormFieldsWrapper,
} from '@mezzanine-ui/react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { groupBy, uniqBy } from 'lodash';

interface FilterValues {
  groupCode: SelectValue;
  bigClassCode: SelectValue;
  mediumClassCode: SelectValue;
  smallClassCode: SelectValue;
}

export function MaterialClassCodePanel() {
  const methods = useForm<FilterValues>();

  const { classCodes, isLoading: isClassCodesLoading } =
    useGetMaterialClassCodes();

  const selectedGroupCode = useWatch({
    control: methods.control,
    name: 'groupCode',
  });

  const selectedBigClassCode = useWatch({
    control: methods.control,
    name: 'bigClassCode',
  });

  const selectedMediumClassCode = useWatch({
    control: methods.control,
    name: 'mediumClassCode',
  });

  const selectedSmallClassCode = useWatch({
    control: methods.control,
    name: 'smallClassCode',
  });

  const groupCodeOptions = uniqBy(
    classCodes?.map((classCode) => ({
      id: classCode.groupId,
      name: classCode.groupId,
    })) ?? [],
    'id'
  );

  const bigClassCodeOptions = useMemo(
    () =>
      uniqBy(
        classCodes
          ?.filter((classCode) => classCode.groupId === selectedGroupCode?.id)
          .map((classCode) => ({
            id: classCode.bigCode,
            name: `${classCode.bigCode}(${classCode.bigCodeName})`,
          })) ?? [],
        'id'
      ),
    [classCodes, selectedGroupCode]
  );

  const mediumClassCodeOptions = useMemo(
    () =>
      uniqBy(
        classCodes
          ?.filter(
            (classCode) =>
              classCode.groupId === selectedGroupCode?.id &&
              classCode.bigCode === selectedBigClassCode?.id
          )
          .map((classCode) => ({
            id: classCode.mediumCode,
            name: `${classCode.mediumCode}(${classCode.mediumCodeName})`,
          })) ?? [],
        'id'
      ),
    [classCodes, selectedBigClassCode, selectedGroupCode]
  );

  const smallClassCodeOptions = useMemo(
    () =>
      uniqBy(
        classCodes
          ?.filter(
            (classCode) =>
              classCode.groupId === selectedGroupCode?.id &&
              classCode.bigCode === selectedBigClassCode?.id &&
              classCode.mediumCode === selectedMediumClassCode?.id
          )
          .map((classCode) => ({
            id: classCode.smallCode,
            name: `${classCode.smallCode}(${classCode.smallCodeName})`,
          })) ?? [],
        'id'
      ),
    [
      classCodes,
      selectedBigClassCode,
      selectedGroupCode,
      selectedMediumClassCode,
    ]
  );

  const dataSource = useMemo(
    () =>
      classCodes?.filter(
        (classCode) =>
          (selectedGroupCode
            ? classCode.groupId === selectedGroupCode.id
            : true) &&
          (selectedBigClassCode
            ? classCode.bigCode === selectedBigClassCode.id
            : true) &&
          (selectedMediumClassCode
            ? classCode.mediumCode === selectedMediumClassCode.id
            : true) &&
          (selectedSmallClassCode
            ? classCode.smallCode === selectedSmallClassCode.id
            : true)
      ) ?? [],
    [
      classCodes,
      selectedBigClassCode,
      selectedGroupCode,
      selectedMediumClassCode,
      selectedSmallClassCode,
    ]
  );

  // const onSearch = useCallback(() => {
  //   const values = methods.getValues();
  //   refetchClassCodes({
  //     groupCode: values?.groupCode?.id,
  //     bigClassCode: values?.bigClassCode?.id,
  //     mediumClassCode: values?.mediumClassCode?.id,
  //     smallClassCode: values?.smallClassCode?.id,
  //   });
  // }, [methods, refetchClassCodes]);

  return (
    <FormFieldsWrapper methods={methods}>
      <Space direction="vertical">
        <Space>
          <AutoCompleteField
            label="物料群組"
            registerName="groupCode"
            options={groupCodeOptions ?? []}
          />
          <AutoCompleteField
            label="產品階層（大）"
            disabled={!selectedGroupCode}
            registerName="bigClassCode"
            options={bigClassCodeOptions ?? []}
          />
          <AutoCompleteField
            label="產品階層（中）"
            disabled={!selectedBigClassCode}
            registerName="mediumClassCode"
            options={mediumClassCodeOptions ?? []}
          />
          <AutoCompleteField
            label="產品階層（小）"
            disabled={!selectedMediumClassCode}
            registerName="smallClassCode"
            options={smallClassCodeOptions ?? []}
          />
          {/* <Button type="button" variant="contained" onClick={onSearch}>
            查詢
          </Button> */}
        </Space>
        <Table
          loading={isClassCodesLoading}
          dataSource={dataSource}
          scroll={{ y: 550 }}
          columns={[
            { title: '代碼', dataIndex: 'id' },
            { title: '物料群組', dataIndex: 'groupId' },
            { title: '產品階層（大）代碼', dataIndex: 'bigCode' },
            { title: '產品階層（大）對應值', dataIndex: 'bigCodeName' },
            { title: '產品階層（中）代碼', dataIndex: 'mediumCode' },
            { title: '產品階層（中）對應值', dataIndex: 'mediumCodeName' },
            { title: '產品階層（小）代碼', dataIndex: 'smallCode' },
            { title: '產品階層（小）對應值', dataIndex: 'smallCodeName' },
          ]}
        />
      </Space>
    </FormFieldsWrapper>
  );
}
