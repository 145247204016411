import { useLocation, useParams } from 'react-router-dom';
import { useMemo, useCallback } from 'react';
import { pageOptions } from '../page-options';
import { CustomerOrderTableProps, PurchaseOrderTableProps } from '../typings';
import { InspectStatusTag } from '../InspectStatusTag';
import moment from 'moment';
import { InspectMaterialType } from '../inspect-modal-enum';

export const useOrderTableData = () => {
  const location = useLocation();
  const { orderId } = useParams();

  const currentRouteOption =
    pageOptions.find(
      (page) => page.id === location?.pathname.split('/').at(-2)
    ) ?? pageOptions[0];

  const { order } = currentRouteOption.fetchOrder({ orderId });

  const isPurchaseOrder = useCallback(
    (order: unknown): order is Array<PurchaseOrderTableProps> => {
      return currentRouteOption.id === InspectMaterialType.PurchaseOrder;
    },
    [currentRouteOption.id]
  );
  const isSupplierOrder = useCallback(
    (order: unknown): order is Array<CustomerOrderTableProps> => {
      return currentRouteOption.id === InspectMaterialType.CustomerOrder;
    },
    [currentRouteOption.id]
  );

  const orderMetaData = useMemo(() => {
    if (isPurchaseOrder(order)) {
      return {
        id: order[0]?.id,
        supplierName: order[0]?.supplierName,
        supplierId: order[0]?.supplierId,
        staffName: order[0]?.staffName,
        expectedCompletedAt: moment(order[0]?.expectedCompletedAt).format(
          'YYYY-MM-DD'
        ),
        receivedState: order[0]?.receivedState,
      };
    } else if (isSupplierOrder(order))
      return {
        id: order[0]?.id,
        customerName: order[0]?.customerName,
        customerId: order[0]?.customerId,
        processName: order[0]?.processName,
        expectedCompletedAt: moment(order[0]?.expectedCompletedAt).format(
          'YYYY-MM-DD'
        ),
        receivedState: order[0]?.receivedState,
      };
    return undefined;
  }, [isPurchaseOrder, isSupplierOrder, order]);

  const orderMetaColumnTranslation = useMemo(() => {
    const baseTransLation = {
      expectedCompletedAt: '物料收貨日',
      receivedState: '狀態',
    };
    if (isPurchaseOrder(order)) {
      return {
        id: '採購單號',
        supplierName: '供應商名稱',
        supplierId: '供應商代碼',
        staffName: '採購人員',
        ...baseTransLation,
      } as PurchaseOrderTableProps;
    } else if (isSupplierOrder(order)) {
      return {
        id: '出貨單號',
        customerName: '客戶名稱',
        customerId: '客戶代碼',
        processName: '製程',
        ...baseTransLation,
      } as CustomerOrderTableProps;
    }
    return undefined;
  }, [isPurchaseOrder, isSupplierOrder, order]);

  const orderMetaDataLabelAndContext:
    | Array<{ label: string; context: string }>
    | undefined = orderMetaColumnTranslation
    ? (Object.keys(orderMetaColumnTranslation).map((key) => ({
        label:
          !!orderMetaColumnTranslation &&
          orderMetaColumnTranslation[
            key as keyof typeof orderMetaColumnTranslation
          ],
        context:
          !!orderMetaData &&
          orderMetaData[key as keyof typeof orderMetaColumnTranslation],
      })) as Array<{ label: string; context: string }>)
    : undefined;

  return {
    order,
    orderMetaDataLabelAndContext,
  };
};
