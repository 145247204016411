import { useEffect, useMemo, useState } from 'react';
import styles from './../../TargetNumber.module.scss';
import { Table } from '@mezzanine-ui/react';
import { useDispatcherTableColumns } from './useDispatcherTableColumn';
import { TargetManualDispatcherModal } from './Modal/ManualDispatcherModal';
import { TargetAutoDispatcherModal } from './Modal/AutoDispatcherModal';
import { DispatcherTargetNumberRule, useGetDispatcherNumberRules, useGetTargetRuleCustomersForSearch } from '@solar/data';

interface TargetNumberRuleDispatcherFilterProps {
  filterParams: DispatcherTargetNumberRule;
}

interface Customer {
  id: string;
  name: string;
}

interface Rule {
  customer_id: string;
}

interface RuleDetails {
  customer_id: string;
  customer_name: string;
  material_id: string;
  order_id: string;
  order_detail_id: number;
  sales_order_line: string;
  quantity: number;
  usage_amount: number;
}

export function DispatcherTable({ filterParams }: TargetNumberRuleDispatcherFilterProps) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [autoDispatchModalShow, setAutoDispatchModalShow] = useState(false);
  const [manualDispatchModalShow, setManualDispatchModalShow] = useState(false);
  const [selectedRuleDetails, setSelectedRuleDetails] = useState<RuleDetails | undefined>(undefined);
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  const currentFilterParams = useMemo(() => ({ ...filterParams, page_at: page, page_size: pageSize }), [filterParams, page, pageSize]);

  const { dispatcherNumberRules = [], isLoading: isLoadingRules, total } = useGetDispatcherNumberRules(currentFilterParams, refreshTrigger);
  const { targetRuleCustomers, isLoading: isLoadingCustomers } = useGetTargetRuleCustomersForSearch('');

  const openAutoDispatchModal = (ruleDetails: RuleDetails) => {
    setSelectedRuleDetails(ruleDetails);
    setAutoDispatchModalShow(true);
  };
  const closeAutoDispatchModal = () => setAutoDispatchModalShow(false);
  const openManualDispatchModal = (ruleDetails: RuleDetails) => {
    setSelectedRuleDetails(ruleDetails);
    setManualDispatchModalShow(true);
  };
  const closeManualDispatchModal = () => setManualDispatchModalShow(false);

  const mergedData = dispatcherNumberRules.map((rule: Rule) => {
    let customerInfo;
    if (targetRuleCustomers) {
      customerInfo = targetRuleCustomers.find((customer: Customer) => customer.id === rule.customer_id);
    }
    return {
      ...rule,
      customer_name: customerInfo ? `${customerInfo.name} - ${customerInfo.id}` : 'N/A',
    };
  });

  const triggerRefresh = () => {
    setRefreshTrigger(prev => !prev);
  };

  const columns = useDispatcherTableColumns({
    openAutoDispatchModal,
    openManualDispatchModal,
  });

useEffect(() => {
  setPage(1);
}, [filterParams]);

  return (
    <div>
      <Table
        className={styles.table}
        columns={columns}
        dataSource={mergedData}
        bodyClassName={styles.tableBody}
        loading={isLoadingRules || isLoadingCustomers}
        scroll={{ x: 2000 }}
        pagination={{
          disableAutoSlicing: true,
          current: page,
          total: total,
          onChange: setPage,
          options: {
            pageSize: pageSize,
            onChangePageSize: setPageSize,
            showJumper: true,
            jumperButtonText: '前往',
            jumperHintText: '跳至',
            jumperInputPlaceholder: '頁碼',
            className: styles.pagination,
          },
        }}
      />
      <TargetManualDispatcherModal
        open={manualDispatchModalShow}
        onClose={closeManualDispatchModal}
        ruleDetails={selectedRuleDetails}
        onSuccessfulDispatch={triggerRefresh}
      />
      <TargetAutoDispatcherModal
        open={autoDispatchModalShow}
        onClose={closeAutoDispatchModal}
        ruleDetails={selectedRuleDetails}
        onSuccessfulDispatch={triggerRefresh}
      />
    </div>
  );
}
