import { Button, Input } from '@mezzanine-ui/react';
import { useFormContext } from 'react-hook-form';
import classes from './custom-input-search.module.scss';
import { useState } from 'react';

type CustomInputSearchProp = {
  registerName?: string;
};
export function CustomInputSearch({
  registerName = 'searchTerm',
}: CustomInputSearchProp) {
  const { setValue } = useFormContext();
  const [keyword, setKeyword] = useState('');

  return (
    <div className={classes['input-search']}>
      <Input
        value={keyword}
        fullWidth
        onChange={(event) => setKeyword(event.target.value)}
        placeholder="請輸入關鍵字搜尋"
        inputProps={{
          onKeyDown: (e) => {
            if (e.code === 'Enter') {
              setValue(registerName, keyword);
            }
          },
        }}
      />
      <Button
        type="button"
        variant="outlined"
        onClick={() => {
          setValue(registerName, keyword);
        }}
      >
        確認
      </Button>
    </div>
  );
}
