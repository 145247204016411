import { useEffect, useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { API_NAMESPACE } from '../request';
import { ResponseStatus } from './useHumanResourceBuManage';

const namespace = API_NAMESPACE.HR;

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

const REPORTS_PAYMENT_EXPORT = '/reports/hr/payments/export';

enum Status {
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  PROCESSING = 'PROCESSING',
  WAITING = 'WAITING',
}

export const statusOptions: any = Object.values(Status).map((item) => ({
  id: item,
  name: item,
}));

export type ExportListFilterPayload = {
  status?: Status;
  dateStart?: string;
  dateEnd?: string;
  name?: string;
  page?: number;
  per_page?: number;
};

type ExportPayload = {
  baseYear: string;
  bonusConditions: string[];
  name: string;
};

type ExportSource = {
  bonusConditions: string;
  createdAt: string;
  filePath: string | null;
  id: number | string;
  name: string;
  status: string;
};

export function useHumanResourceBUReportExportList() {
  const [data, setData] = useState<any>(undefined);
  const [loading, setLoading] = useState(true);
  const [queryParams, setQueryParams] = useState('&page=1&perPage=10');

  const { data: swrData } = useSWR([
    REPORTS_PAYMENT_EXPORT,
    { namespace, params: queryParams },
  ]);
  const { mutate: mutateConfig } = useSWRConfig();

  const filterMutation = async (payload: ExportListFilterPayload) => {
    const status = payload.status ? `status=${payload.status}&` : '';
    const dateStart = payload.dateStart
      ? `dateStart=${payload.dateStart.split('T')[0]}&`
      : '';
    const dateEnd = payload.dateEnd
      ? `dateEnd=${payload.dateEnd.split('T')[0]}&`
      : '';
    const name = payload.name ? `name=${payload.name}&` : '';
    const page = payload.page ? `page=${payload.page}&` : 'page=1&';
    const per_page = payload.per_page
      ? `perPage=${payload.per_page}`
      : 'perPage=10';

    setQueryParams(`&${status}${dateStart}${dateEnd}${name}${page}${per_page}`);
  };

  const exportMutation = async (payload: ExportPayload) => {
    if (!payload.baseYear || !payload.name) return;

    const bonusConditions = payload.bonusConditions?.map((condition) =>
      condition.replace('-', '/')
    );

    const options = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        baseYear: payload.baseYear,
        name: payload.name,
        bonusConditions: bonusConditions ?? [],
      }),
    };

    // const postFetch = async () => {
    //   const res = await fetch(url, options);
    //   return res;
    // };
    //
    // const res = await mutateConfig(url, postFetch, false);

    const res = { status: 200 };
    if (res.status === 200) return ResponseStatus.SUCCESS;
    return ResponseStatus.FAIL;
  };

  const isExportSource = (object: unknown): object is ExportSource => {
    if (object && typeof object === 'object') return 'id' in object;
    return false;
  };

  useEffect(() => {
    if (!swrData || typeof swrData === 'string') {
      setLoading(true);
      return;
    }
    setData(swrData);
    setLoading(false);
  }, [swrData]);

  return {
    loading,
    data,
    filterMutation,
    exportMutation,
    isExportSource,
  };
}
