import { API_NAMESPACE, request } from '@solar/data';
import {
  CreatePurchaseFixedAssetMaterialPayload,
  CreatePurchaseMiscMaterialPayload,
} from '../types';

const namespace = API_NAMESPACE.MATERIALS;

export const createPurchaseMiscMaterial = ({
  payload,
}: {
  payload: CreatePurchaseMiscMaterialPayload;
}): Promise<Response> => {
  return request('/misc-material/purchase', {
    namespace,
    method: 'post',
    body: JSON.stringify(payload),
    responseParser: (res) => res,
  });
};

export const createPurchaseFixedAssetMaterial = ({
  payload,
}: {
  payload: CreatePurchaseFixedAssetMaterialPayload;
}): Promise<Response> => {
  return request('/fixed-asset-material/purchase', {
    namespace,
    method: 'post',
    body: JSON.stringify(payload),
    responseParser: (res) => res,
  });
};
