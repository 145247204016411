import type { CustomerTradeConditionCreateFormValues } from '@solar/data';
import styles from './index.module.scss';
import React from 'react';
import {
  CustomerTradeConditionCreateFormFieldName as FormFieldName,
  useCustomerTradeConditionFilterGet,
} from '@solar/data';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  AutoCompleteField,
  useAutoCompleteInput,
} from '@mezzanine-ui/react-hook-form';
import { Icon } from '@mezzanine-ui/react';
import { SearchIcon, SpinnerIcon } from '@mezzanine-ui/icons';

const _PrivateFieldName = '_' + FormFieldName.MATERIAL_ID;

export function MaterialAutoComplete() {
  const formMethods = useFormContext<CustomerTradeConditionCreateFormValues>();

  const materialOptional = useWatch({
    control: formMethods.control,
    name: FormFieldName._MATERIAL_OPTIONAL,
  });

  const { input, onInput } = useAutoCompleteInput({ debounceMs: 50 });

  const { materials, isLoading } = useCustomerTradeConditionFilterGet({
    materialKeyword: input,
  });

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [helperText, setHelperText] = React.useState('');

  React.useEffect(() => {
    // workaround: becuase AutoCompleteField can't attach onBlur
    if (inputRef.current) {
      inputRef.current.addEventListener('blur', () => {
        setHelperText('');
      });
    }
  }, []);

  React.useEffect(() => {
    if (!isLoading && input !== '' && materials.length === 0) {
      setHelperText('查無資料');
    } else {
      setHelperText('');
    }
  }, [isLoading, input, materials]);

  const clearMaterialComposition = () => {
    formMethods.setValue(FormFieldName.COMPONENT_ITEM_NUMBER_1, undefined);
    formMethods.setValue(FormFieldName.COMPONENT_ITEM_NUMBER_2, undefined);
    formMethods.setValue(FormFieldName.COMPONENT_ITEM_NUMBER_3, undefined);
    formMethods.setValue(FormFieldName.COMPONENT_ITEM_NUMBER_4, undefined);
    formMethods.setValue(FormFieldName.WEIGHT_METHOD_1, undefined);
    formMethods.setValue(FormFieldName.WEIGHT_METHOD_2, undefined);
    formMethods.setValue(FormFieldName.WEIGHT_METHOD_3, undefined);
    formMethods.setValue(FormFieldName.WEIGHT_METHOD_4, undefined);
    formMethods.setValue(FormFieldName.ANALYSIS_VALUE_METHOD_1, undefined);
    formMethods.setValue(FormFieldName.ANALYSIS_VALUE_METHOD_2, undefined);
    formMethods.setValue(FormFieldName.ANALYSIS_VALUE_METHOD_3, undefined);
    formMethods.setValue(FormFieldName.ANALYSIS_VALUE_METHOD_4, undefined);
    // workaround
    formMethods.setValue(`_${FormFieldName.WEIGHT_METHOD_1}` as any, undefined);
    formMethods.setValue(`_${FormFieldName.WEIGHT_METHOD_2}` as any, undefined);
    formMethods.setValue(`_${FormFieldName.WEIGHT_METHOD_3}` as any, undefined);
    formMethods.setValue(`_${FormFieldName.WEIGHT_METHOD_4}` as any, undefined);
    formMethods.setValue(
      `_${FormFieldName.ANALYSIS_VALUE_METHOD_1}` as any,
      undefined
    );
    formMethods.setValue(
      `_${FormFieldName.ANALYSIS_VALUE_METHOD_2}` as any,
      undefined
    );
    formMethods.setValue(
      `_${FormFieldName.ANALYSIS_VALUE_METHOD_3}` as any,
      undefined
    );
    formMethods.setValue(
      `_${FormFieldName.ANALYSIS_VALUE_METHOD_4}` as any,
      undefined
    );
  };

  return (
    <>
      <div className={styles['col-span-1']}>產品料號：</div>
      <div className={styles['col-span-3']} style={{ position: 'relative' }}>
        <AutoCompleteField
          inputRef={inputRef}
          placeholder="輸入關鍵字等待搜尋"
          control={formMethods.control}
          registerName={_PrivateFieldName}
          options={materials}
          onInput={onInput}
          onChange={(selected) => {
            clearMaterialComposition();
            formMethods.setValue(FormFieldName.MATERIAL_ID, selected?.id);
          }}
          disabled={materialOptional}
        />
        {!materialOptional && (
          <Icon
            icon={isLoading ? SpinnerIcon : SearchIcon}
            size={32}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              padding: '4px',
              margin: '1px 8px',
              background: 'white',
              borderRadius: '50%',
              color: 'rgb(119, 123, 128)',
            }}
            className={isLoading ? styles.spinner : ''}
          />
        )}
        <div
          style={{
            position: 'absolute',
            left: '0',
            bottom: '-38px',
            width: '100%',
          }}
        >
          <small style={{ letterSpacing: '0.01em' }}>{helperText}</small>
        </div>
      </div>
    </>
  );
}
