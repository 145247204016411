import { useState } from 'react';
import { Key, EventDataNode, DataNode } from 'rc-tree/lib/interface';
import {
  FilterPayload,
  useHumanResourceMembers,
} from '../useHumanResourceMembers';

type SelectedCallback =
  | ((
      selectedKeys: Key[],
      info: {
        event: 'select';
        selected: boolean;
        node: EventDataNode<DataNode>;
        selectedNodes: DataNode[];
        nativeEvent: MouseEvent;
      }
    ) => void)
  | undefined;

type SelectedKeys = {
  type: 'Unit' | 'Member' | null;
  id: number | string | null;
  name: string | null;
  where: string | null;
  hasMembers: boolean;
};

export function useSelectedKeys() {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [selectedKeys, setSelectedKeys] = useState<SelectedKeys>({
    type: null,
    id: null,
    name: null,
    where: null,
    hasMembers: false,
  });

  const { filterMutation: filterMembersDataMutation, data } =
    useHumanResourceMembers(selectedKeys.hasMembers);

  const recombinePayload = (
    reusePayload: FilterPayload,
    selectedKeys: SelectedKeys
  ): FilterPayload =>
    selectedKeys.type === 'Unit'
      ? {
          ...reusePayload,
          unitPath: selectedKeys.id ? [selectedKeys.id] : undefined,
        }
      : selectedKeys.type === 'Member'
      ? {
          ...reusePayload,
          code: `${selectedKeys.id}` ?? undefined,
        }
      : reusePayload;

  const handleSelectedKeys: SelectedCallback = (keys, info) => {
    const { selected } = info;
    const { type, key, name, where, hasMembers } = info.node as any;
    setSelectedKeys({
      type: selected ? type : null,
      id: selected ? key : null,
      name: selected ? name : null,
      where: selected ? where : null,
      hasMembers: selected ? hasMembers : false,
    });
    setCurrentPage(1);
    const reusePayload = { page: 1, per_page: perPage } as FilterPayload;
    const payload = recombinePayload(reusePayload, {
      type,
      id: key,
      name,
      where,
      hasMembers,
    });
    filterMembersDataMutation(payload);
  };

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
    const reusePayload = { page, per_page: perPage } as FilterPayload;
    const payload = recombinePayload(reusePayload, selectedKeys);
    filterMembersDataMutation(payload);
  };

  const handleChangePageSize = (pageSize: number) => {
    setPerPage(pageSize);
    setCurrentPage(1);
    const reusePayload = { page: 1, per_page: pageSize } as FilterPayload;
    const payload = recombinePayload(reusePayload, selectedKeys);
    filterMembersDataMutation(payload);
  };

  return {
    tableData: selectedKeys.hasMembers ? data : [],
    selectedKeys,
    handleSelectedKeys,
    currentPage,
    setCurrentPage,
    perPage,
    setPerPage,
    recombinePayload,
    handleChangePage,
    handleChangePageSize,
  };
}
