import { Button, Typography } from '@mezzanine-ui/react';
import { Link } from 'react-router-dom';
import styles from './HumanResourceReportsComponents.module.scss';

type HeaderProps = {
  title: string;
  button: string | null;
  customChildren?: JSX.Element;
};

export function Header({ title, button, customChildren }: HeaderProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <Typography variant="h1">{title}</Typography>
        {button && (
          <Link to="/human-resources/reports-detail" className={styles.link}>
            <Button type="button" size="medium" variant="outlined">
              {button}
            </Button>
          </Link>
        )}
      </div>
      {customChildren}
      <div className={styles.border}></div>
    </div>
  );
}
