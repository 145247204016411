import {
  NextPagination,
  OOCTicket,
  OOCTicketStatus,
  getNextParams,
  useBatchIdsByMaterialId,
  useGetOOCTickets,
  useMaterials,
} from '@solar/data';
import {
  ModalGroup,
  MznPaginationTable,
  PageLayout,
  PageWrapper,
  RowSection,
  useModalGroupController,
} from '@solar/templates';
import { useCallback, useState } from 'react';
import { useOOCTocketsTableColumns } from './hooks/use-ooc-tickets-table-columns';
import { Modals } from './Modals.enum';
import { InLineSPCAbnormalReportModal } from './InLineSPCAbnormalReportModal';
import { useForm } from 'react-hook-form';
import { Space } from 'antd';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  AutoComplete,
  Button,
  Option,
  Select,
  SelectValue,
} from '@mezzanine-ui/react';

export function InLineSPCAbnormalReportPage() {
  const [targetMaterial, setTargetMaterial] = useState<SelectValue | null>(
    null
  );
  const [targetBatches, setTargetBatches] = useState<SelectValue | null>(null);
  const [status, setStatus] = useState<SelectValue | null>(null);

  const modalGroupController = useModalGroupController([
    { name: Modals.IN_LINE_SPC_ABNORMAL_REPORT },
  ]);

  const columns = useOOCTocketsTableColumns({
    openModal: modalGroupController.openModal,
  });

  const { oocTickets, pageInfo, refetchGetOOCTickets } = useGetOOCTickets();

  const refetchGetOOCTicketsWithPagination = useCallback(
    (nextPagination: NextPagination) => {
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      const [sapBatchId, subBatchId] = targetBatches?.id?.split('-') ?? [];
      refetchGetOOCTickets({
        offset,
        limit,
        materialId: targetMaterial?.id,
        sapBatchId,
        subBatchId,
        status: status?.id as OOCTicketStatus,
      });
    },
    [refetchGetOOCTickets, status, targetBatches, targetMaterial]
  );

  const { materials: targetMaterials, setParams: refetchGetTargetMaterials } =
    useMaterials();

  const targetMaterialOptions =
    targetMaterials?.map((material) => ({
      id: material?.id,
      name: material?.id,
    })) ?? [];

  const { data: targetBatchesData, setParams: mutateGetTargetBatches } =
    useBatchIdsByMaterialId({
      materialId: targetMaterial?.id ?? null,
    });

  const targetBatchOptions =
    targetBatchesData?.map((batch) => ({
      id: batch?.batchId,
      name: batch?.batchId,
    })) ?? [];

  const onSearchMaterialField = useCallback(
    (refetch: Function) => (searchTerm: string) =>
      refetch({ searchTerm, searchType: 'ID' }),
    [refetchGetTargetMaterials]
  );

  const onInputBatchField = useCallback(
    (refetch: Function) => (searchTerm: string) => {
      refetch((prev: any) => ({
        ...prev,
        searchTerm,
      }));
    },
    [mutateGetTargetBatches]
  );

  return (
    <PageWrapper title="IN LINE SPC異常回覆報表">
      <Space direction="vertical">
        <RowSection label="料號">
          <AutoComplete
            fullWidth
            value={targetMaterial}
            onSearch={onSearchMaterialField(refetchGetTargetMaterials)}
            options={targetMaterialOptions}
            onChange={(option) => {
              setTargetMaterial(option);
              setTargetBatches(null);
            }}
          />
        </RowSection>
        <RowSection label="批號">
          <AutoComplete
            fullWidth
            value={targetBatches}
            disabled={!targetMaterial}
            onSearch={onInputBatchField(mutateGetTargetBatches)}
            onChange={setTargetBatches}
            options={targetBatchOptions}
          />
        </RowSection>
        <RowSection label="狀態">
          <Select
            fullWidth
            value={status}
            onChange={setStatus}
            clearable
            onClear={() => setStatus(null)}
          >
            <Option value="CLEAR">已完成</Option>
            <Option value="NOT_CLEAR">未完成</Option>
          </Select>
        </RowSection>
        <Button
          type="button"
          variant="contained"
          onClick={() => refetchGetOOCTicketsWithPagination({ nextPage: 1 })}
        >
          搜尋
        </Button>
      </Space>
      <MznPaginationTable
        dataSource={oocTickets}
        fetchData={refetchGetOOCTicketsWithPagination}
        pageInfo={pageInfo}
        columns={columns}
      />
      <ModalGroup {...modalGroupController}>
        <InLineSPCAbnormalReportModal />
      </ModalGroup>
    </PageWrapper>
  );
}
