import { ExclamationCircleFilledIcon } from '@mezzanine-ui/icons';
import {
  Button,
  Icon,
  Message,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Typography,
} from '@mezzanine-ui/react';
import {
  FormFieldsWrapper,
  SearchInputField,
} from '@mezzanine-ui/react-hook-form';
import { ResponseStatus, useHumanResourceBUManage } from '@solar/data';
import { FieldValues, useForm } from 'react-hook-form';
import styles from './BUManageModals.module.scss';

type EditModalProps = {
  id?: string;
  name?: string;
  onClose: () => void;
};

export function EditModal({ id, name, onClose }: EditModalProps) {
  const defaultValues: FieldValues = {
    'search-input': name,
  };
  const methods = useForm({
    defaultValues,
  });
  const {
    createMutation: createHumanResourceBUManage,
    updateMutation: updateHumanResourceBUManage,
  } = useHumanResourceBUManage();

  const submit = async () => {
    const newBUManageData = methods.watch()['search-input'];
    if (!newBUManageData) return; // show error
    if (id) {
      const res = await updateHumanResourceBUManage(id, newBUManageData);
      if (res === ResponseStatus.SUCCESS) {
        Message.success('編輯ＢＵ成功');
      } else {
        Message.error('編輯ＢＵ失敗');
      }
    } else {
      const res = await createHumanResourceBUManage(newBUManageData);
      if (res === ResponseStatus.SUCCESS) {
        Message.success('新增ＢＵ成功');
      } else {
        Message.error('新增ＢＵ失敗');
      }
    }
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      open={true}
      style={{ width: 576 }}
      className={styles.modal}
    >
      <FormFieldsWrapper methods={methods} onSubmit={submit}>
        <ModalHeader>新增/編輯</ModalHeader>
        <ModalBody>
          <Typography variant="input1" color="secondary">
            BU名稱
          </Typography>
          <SearchInputField
            registerName="search-input"
            placeholder="預設文字"
            defaultValue={name}
            className={styles.searchInput}
          />
        </ModalBody>
        <ModalFooter className={styles.footer}>
          <Button
            type="button"
            size="large"
            variant="outlined"
            className={styles.button}
            onClick={onClose}
          >
            取消
          </Button>
          <Button size="large" variant="contained">
            確認
          </Button>
        </ModalFooter>
      </FormFieldsWrapper>
    </Modal>
  );
}

type DeleteModalProps = EditModalProps;

export function DeleteModal({ id, name, onClose }: DeleteModalProps) {
  const { deleteMutation: deleteHumanResourceBUManage } =
    useHumanResourceBUManage();

  const handleDelete = async () => {
    if (!id) return;
    const res = await deleteHumanResourceBUManage(id);
    if (res === ResponseStatus.SUCCESS) {
      Message.success('刪除ＢＵ成功');
    } else {
      Message.error('刪除ＢＵ失敗');
    }
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      open={true}
      className={styles.modal}
      style={{ width: 404 }}
    >
      <ModalHeader className={styles.header}>
        <Icon color="warning" size={32} icon={ExclamationCircleFilledIcon} />
        刪除此BU項目
      </ModalHeader>
      <ModalBody>
        <Typography variant="body1">
          請確認是否將用戶名稱「{name}」刪除，若點選確認將刪除此BU項目且無法復原
        </Typography>
      </ModalBody>
      <ModalFooter className={styles.footer}>
        <Button
          type="button"
          size="large"
          variant="outlined"
          className={styles.button}
          onClick={onClose}
        >
          取消
        </Button>
        <Button
          type="button"
          size="large"
          variant="contained"
          danger
          onClick={handleDelete}
        >
          確認刪除
        </Button>
      </ModalFooter>
    </Modal>
  );
}
