import { SelectValue } from '@mezzanine-ui/react';
import { LocationLevel } from './useFactories';
import { Dispatch } from 'react';

export type Factory = SelectValue & {
  buildings: (SelectValue & {
    floors: (SelectValue & {
      areas: (SelectValue & {
        zones: (SelectValue & {
          stacks: SelectValue[];
        })[];
      })[];
    })[];
  })[];
};

// export type Building = SelectValue & {
//   floors: Floor[];
// };

// export type Floor = SelectValue & {
//   areas: Area[];
// };

// export type BuildingFloor = SelectValue & {
//   areas: Area[];
// };

// export type Area = SelectValue & {
//   zones: Zone[];
// };

// export type Zone = SelectValue & {
//   stacks: Stack[];
// };

// export type Stack = SelectValue;

export type FactoryEntity = SelectValue;

export type BuildingEntity = SelectValue & {
  factory: FactoryEntity;
};

export type FloorEntity = SelectValue & {
  building: BuildingEntity;
};

export type AreaEntity = SelectValue & {
  floor: FloorEntity;
};

export type ZoneEntity = SelectValue & {
  area: AreaEntity;
};

/**
 * @deprecated
 * @todo: 將 LocationType 替換成 CurrentLocation
 * */
export type LocationType = {
  factory?: SelectValue;
  building?: SelectValue;
  floor?: SelectValue;
  area?: SelectValue;
  zone?: SelectValue;
};

export type CurrentLocation = {
  factory?: SelectValue;
  buildingFloor?: SelectValue;
  building?: SelectValue;
  floor?: SelectValue;
  area?: SelectValue;
  zone?: SelectValue;
  stack?: SelectValue;
  shelf?: SelectValue;
};

export type UseLocationSelector = {
  currentLevel: LocationLevel;
  currentLocation: CurrentLocation;
  setCurrentLocation: Dispatch<CurrentLocation>;
  selectedItem: any;
  svg: any;
  // loadingSVG: boolean;
  handlePrevLevel: () => void;
  handleNextLevel: (item: any, nextLevel?: LocationLevel) => void;
  handleNextCurrentLocation: (nextCurrentLocation: CurrentLocation) => void;
  resetLocationSelector: (defaultCurrentLocation?: CurrentLocation) => void;
};
