import { TableColumn } from '@mezzanine-ui/core/table';

import {
  DispatchMaterialRecordProps,
  InputMaterialTableProps,
  Modals,
} from '../types';

import { Button, Table } from '@mezzanine-ui/react';
import { ModalLayout } from '../../../Material/ModalLayout/ModalLayout';

import { useTargetModal } from '../../../Templates/Modal/ModalGroup';
import { CollapsibleContainer } from '@solar/templates';
import { useMaterialRequestOrder } from '../../../../../../../libs/data/src/materials/useMaterialRequestOrder';
import {
  checkJWTRoles,
  useMaterialRequestOrderLogs,
  useOutsourcePurchaseMaterialRequestOrder,
} from '@solar/data';

export type DispatchMaterialViewModalProps = {
  orderId: string;
};

export function DispatchMaterialViewModal() {
  const modalController = useTargetModal<DispatchMaterialViewModalProps>(
    Modals.DISPATCH_MATERIAL_VIEW
  );

  const { data: modalProps } = modalController;

  const { data: inputNeeds, isLoading: materialRequestOrderSpecsLoading } =
    useOutsourcePurchaseMaterialRequestOrder({
      orderId: modalProps?.orderId,
    });

  const { logs } = useMaterialRequestOrderLogs(modalProps?.orderId ?? '');
  const inputMaterialColumns: TableColumn<InputMaterialTableProps>[] = [
    {
      title: '品名',
      dataIndex: 'materialDescription',
      ellipsis: false,
      width: 500,
    },
    {
      title: '料號',
      dataIndex: 'materialId',
      width: 500,
    },
    {
      title: '批號',
      render: (source) =>
        `${source['materialSapBatchId'] ?? ''}-${
          source['materialSubBatchId'] ?? ''
        }`,
    },
    { title: '儲存位置', dataIndex: 'shelfId' },
    { title: '載具', dataIndex: 'loaderId' },
    {
      title: '應發數量',
      dataIndex: 'expectedMaterialStockUnitQuantity',
    },
    {
      title: '已發數量',
      dataIndex: 'actualMaterialStockUnitQuantity',
    },
    { title: '單位', dataIndex: 'stockUnit', width: 100 },
  ];

  const dispatchMaterialRecordColumns: TableColumn<DispatchMaterialRecordProps>[] =
    [
      { title: '發料時間', dataIndex: 'issueTime' },
      { title: '領料人', dataIndex: 'picker' },
      { title: '品名', dataIndex: 'materialDescription' },
      { title: '料號', dataIndex: 'materialId' },
      { title: '批號流水', dataIndex: 'lotNumberFlow' },
      { title: '發料位置', dataIndex: 'stackId' },
      { title: '發料載具', dataIndex: 'loaderId' },
      { title: '發料數量', dataIndex: 'theNumberOfMaterialsIssued' },
      { title: '單位', dataIndex: 'unit', width: 100 },
    ];

  return (
    <ModalLayout {...modalController} title="">
      <br />
      <br />
      <div style={{ display: 'flex', gap: 'var(--mzn-spacing-4)' }}>
        {/* <Button
          variant="outlined"
          type="button"
          onClick={() => {
            window.open(
              `/print-management/work-order?tab=work-order&workOrderId=${inputNeeds?.workOrderId}`,
              '_blank'
            );
          }}
        >
          列印工單 {inputNeeds?.workOrderId}
        </Button> */}
        {checkJWTRoles([133]) && (
          <Button
            variant="outlined"
            type="button"
            // onClick={() => {
            //   window.open(
            //     `/print-management/work-order?tab=dispatch-material-order&workOrderId=${inputNeeds?.workOrderId}&materialRequestOrderId=${inputNeeds?.materialRequestOrderSpecs?.[0]?.materialRequestOrderId}`,
            //     '_blank'
            //   );
            // }}
          >
            列印發料單 {inputNeeds?.items?.[0]?.id}
          </Button>
        )}
      </div>
      {/* <CollapsibleContainer header="" enableRowBlockStyle open>
        <Table
          columns={[
            {
              title: '目標料號',
              dataIndex: 'materialId',
            },
            {
              title: '目標批號',
              dataIndex: 'materialSapBatchId-materialBatchId',
              render: (source) =>
                `${source?.materialSapBatchId ?? ''}-${
                  source?.materialBatchId ?? ''
                }`,
            },
          ]}
          dataSource={inputNeeds?.materialRequestOrderBatches ?? []}
        />
      </CollapsibleContainer> */}
      <br />
      {/* <Descriptions>
        {inputNeeds?.materialRequestOrderBatches.map((row) => {
          return (
            <>
              <Descriptions.Item label="目標料號">
                {row?.materialId}
              </Descriptions.Item>
              <Descriptions.Item label="目標批號">
                {row?.materialSapBatchId ?? ''}-{row?.materialBatchId ?? ''}
              </Descriptions.Item>
            </>
          );
        })}
      </Descriptions> */}
      <CollapsibleContainer header="投料需求" enableRowBlockStyle open>
        <Table
          scroll={{ x: 2300 }}
          columns={inputMaterialColumns}
          loading={materialRequestOrderSpecsLoading}
          dataSource={inputNeeds?.items ?? []}
        />
      </CollapsibleContainer>
      <br />
      <CollapsibleContainer header="發料紀錄" enableRowBlockStyle open>
        <Table
          scroll={{ x: 1800 }}
          columns={dispatchMaterialRecordColumns}
          dataSource={logs}
        />
      </CollapsibleContainer>
    </ModalLayout>
  );
}
