import { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router';
import { LocalTabs, PAGE_OPTIONS } from '../const';

export function useAutoNormalManufacturingPageRouting() {
  const { tab } = useParams<{ tab: LocalTabs }>();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!tab) {
      navigate(location.pathname + `/${PAGE_OPTIONS.CHECK_IN.id}`);
    }

    if (
      tab &&
      ![
        PAGE_OPTIONS.CHECK_IN.id,
        PAGE_OPTIONS.CHECK_OUT.id,
        PAGE_OPTIONS.GEN_PARAMS.id,
      ].includes(tab)
    ) {
      navigate(location.pathname + `/../${PAGE_OPTIONS.CHECK_IN.id}`);
    }
  }, [location.pathname, navigate, tab]);

  return tab;
}
