import { Message } from '@mezzanine-ui/react';
import { useEffect, useRef } from 'react';

export function CopyTextWrapper({
  text,
  className,
}: {
  text: string;
  className?: string;
}) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = wrapperRef.current;

    const touchEndHandler = (e: TouchEvent) => {
      if (element?.contains(e.target as Node)) {
        navigator.clipboard.writeText(text);
        Message.success(`${text}複製成功`);
      }
    };

    element?.addEventListener('touchend', touchEndHandler);

    return () => {
      element?.removeEventListener('touchend', touchEndHandler);
    };
  }, [text]);

  return (
    <div
      ref={wrapperRef}
      className={className}
      style={{ cursor: 'pointer', color: 'var(--mzn-color-primary)' }}
    >
      {text}
    </div>
  );
}
