import { ModalLayout } from '../../ModalLayout/ModalLayout';
import { Loading, ModalActions, Typography, cx } from '@mezzanine-ui/react';
import { useModalController } from '../../ModalLayout/hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  FormFieldsWrapper,
  InputField,
  TextAreaField,
} from '@mezzanine-ui/react-hook-form';
import { useRef } from 'react';
import classes from './material-scrapping-modal.module.scss';
import shareStyles from '../../shared-styles/index.module.scss';
import { enhanceCustomMznComponent } from '../../IncomingInspectOperationPage/Modals/InspectMaterialSecondLayerModal';
import { SuccessScrapModal } from './SuccessScrapModal';

const schema = Yup.object({
  scrapAmount: Yup.string().required('必填'),
  scrapReason: Yup.string().required('必填'),
});

export function MaterialScrappingModal(
  props: ReturnType<typeof useModalController>
) {
  const { open, closeModal } = props;
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const scrapSuccessModalController = useModalController();

  const {
    formState: { isSubmitting },
    reset,
  } = methods;

  const submitRef = useRef<HTMLInputElement | null>(null);

  const handleOnConfirm = () => {
    submitRef.current?.click();
  };

  const handleClose = () => {
    closeModal();
    reset();
  };

  return (
    <>
      <ModalLayout
        modalHeader="物料報廢"
        className={cx(classes.modal)}
        open={open}
        closeModal={handleClose}
        modalFooter={
          <ModalActions
            confirmText="確認"
            cancelText="取消"
            onConfirm={() => handleOnConfirm()}
            onCancel={() => handleClose()}
            confirmButtonProps={{ type: 'submit', loading: isSubmitting }}
            cancelButtonProps={{ loading: isSubmitting }}
          />
        }
      >
        <FormFieldsWrapper
          methods={methods}
          onSubmit={async (v) => {
            // TODO: api and try catch
            closeModal();
            scrapSuccessModalController.openModal({});
            reset();
          }}
        >
          <Loading loading={isSubmitting}>
            <div className={shareStyles['modal-body-wrapper']}>
              <div className={shareStyles['row-block']}>
                <div className={shareStyles['row-flex']}>
                  <div className={classes['half-space']}>
                    <Typography ellipsis={true}>
                      料號：AABBBCD02921料號：AABBBCD02921料號：AABBBCD02921料號：AABBBCD02921
                    </Typography>
                  </div>
                  <div className={classes['half-space']}>
                    <Typography ellipsis={true}>
                      批號：20230405025-001
                    </Typography>
                  </div>
                </div>
                <div className={shareStyles['row-flex']}>
                  <div className={classes['half-space']}>
                    <Typography ellipsis={true}>儲位：1100A1C4J1B</Typography>
                  </div>
                  <div className={classes['half-space']}>
                    <Typography ellipsis={true}>
                      入庫日期：2020/02/04
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={shareStyles['row-block']}>
                <div className={shareStyles['row-flex']}>
                  <div className={classes['half-space']}>
                    <Typography ellipsis={true}>
                      料號：AABBBCD02921料號：AABBBCD02921料號：AABBBCD02921料號：AABBBCD02921
                    </Typography>
                  </div>
                  <div className={classes['half-space']}>
                    <Typography ellipsis={true}>
                      批號：20230405025-001
                    </Typography>
                  </div>
                </div>
                <div className={shareStyles['row-flex']}>
                  <div className={classes['half-space']}>
                    <Typography ellipsis={true}>儲位：1100A1C4J1B</Typography>
                  </div>
                  <div className={classes['half-space']}>
                    <Typography ellipsis={true}>
                      入庫日期：2020/02/04
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={shareStyles['row-block']}>
                <Typography variant="h4">規格</Typography>
                <div className={shareStyles['row-flex']}>
                  <Typography>重量：</Typography>
                  <Typography>容量：</Typography>
                  <Typography>長度：</Typography>
                  <Typography>數量：1 個</Typography>
                </div>
              </div>
              <div
                className={cx(
                  shareStyles['row-block'],
                  shareStyles['modal-body-wrapper']
                )}
              >
                {enhanceCustomMznComponent(InputField)({
                  props: {
                    registerName: 'scrapAmount',
                  },
                  label: '報廢數量',
                })}
                <div className={shareStyles['text-area-full-width']}>
                  {enhanceCustomMznComponent(TextAreaField)({
                    props: {
                      registerName: 'scrapReason',
                      className: shareStyles['text-area-full-width'],
                    },
                    label: '報廢原因',
                  })}
                </div>
              </div>
            </div>
            <input type="submit" ref={submitRef} style={{ display: 'none' }} />
          </Loading>
        </FormFieldsWrapper>
      </ModalLayout>
      <SuccessScrapModal {...scrapSuccessModalController} />
    </>
  );
}
