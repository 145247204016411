import { Table } from '@mezzanine-ui/react';
import {
  FetchingInventoryParams,
  FetchingInventoryStockTypes,
  useInventoryByMaterial,
} from '@solar/data';
import {
  LocationSelectorProvider,
  LocationSVGPopper,
  useDraggableColumns,
  useLocationSelectorController,
  useLocationSVGPopper,
} from '@solar/templates';
import { Dispatch, SetStateAction, useCallback, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useMaterialShiftTableColumn } from './hooks/useMaterialShiftTableColumn';
import { MaterialShiftInputFields } from './shift-action.enum';
import classes from './shift-page.module.scss';
import { ShiftTableColumnProps } from './typings';
import Decimal from 'decimal.js';
import { Space } from 'antd';

type MaterialShiftTableProps<T extends ShiftTableColumnProps> = {
  keySourcePair: {
    fieldTranslation: string;
    dataSource: Array<T>;
    dataSourceMaintainer: Dispatch<SetStateAction<Array<T>>>;
  };
  fetchingInventoryParams: FetchingInventoryParams;
  mutateFetchInventoryParams: ReturnType<
    typeof useInventoryByMaterial
  >['mutateFetchInventoryParams'];
  fetchInventoryByMaterial: ReturnType<
    typeof useInventoryByMaterial
  >['fetchInventoryByMaterial'];
};

export const PAGE_LIMIT = 20;

const stockTypes = [
  FetchingInventoryStockTypes.AVAILABLE,
  FetchingInventoryStockTypes.PENDING,
  FetchingInventoryStockTypes.SALES,
];

export function MaterialShiftTable<T extends ShiftTableColumnProps>({
  keySourcePair,
  fetchingInventoryParams,
  mutateFetchInventoryParams,
  fetchInventoryByMaterial,
}: MaterialShiftTableProps<T>) {
  const tableRef = useRef<HTMLTableElement>(null);
  const { dataSource, dataSourceMaintainer } = keySourcePair ?? {};

  const { setValue } = useFormContext();
  const watchSelectedRowKeys = useWatch({ name: 'selectedRowKeys' }) ?? [];

  // for location SVG popper when hovering stackId
  const locationController = useLocationSelectorController({
    noDefaultValue: true,
  });
  const locationSVGPopperHandler = useLocationSVGPopper({
    handleNextLevel: locationController?.handleNextLevel,
  });

  const defaultColumn = useMaterialShiftTableColumn<T>({
    selectedRowKeys: watchSelectedRowKeys,
    dataSourceMaintainer,
    type: fetchingInventoryParams.type,
    locationSVGPopperHandler,
  });

  const column = useDraggableColumns(tableRef, defaultColumn);

  const expandedDataSource = useCallback(
    (key: string): any => {
      const target = dataSource.find((d) => d.id === key);

      return (target as any)?.subItem ?? [];
    },

    [dataSource]
  );
  const mockExpandableData = {
    expandedRowRender: (record: any) => {
      return {
        dataSource: expandedDataSource(record.id),
        columns: [
          { title: '料號', dataIndex: 'materialId' },
          { title: '批號', dataIndex: 'batchId' },
          { title: '載具', dataIndex: 'loaderId' },
          { title: '儲位', dataIndex: 'shelfId' },
          {
            title: '基礎計量單位數量',
            dataIndex: 'quantity',
          },
          {
            title: '可移轉庫存管理單位數量',
            dataIndex: 'availableMaterialInputUnitQuantity',
            render: (source: T) =>
              new Decimal(source?.quantity ?? '0')
                .div(source?.stockOverInputUnitRatio ?? '1')
                .toString(),
          },
          {
            title: '管理單位',
            dataIndex: 'materialInputUnit1',
            render: (source: T) => source?.materialInputUnit ?? '',
          },
          {
            title: '移轉管理單位數量',
            dataIndex: 'availableMaterialInputUnitQuantity',
          },
          {
            title: '管理單位',
            dataIndex: 'materialInputUnit2',
            render: (source: T) => source?.materialInputUnit ?? '',
          },
          {
            title: '環保連單號',
            dataIndex: 'recycleNumber',
            render: (source: T) => (
              <Space direction="vertical">
                <div>{source?.inventoryOrderRecycleNumber}</div>
                <div>{source?.deliveryOrderRecycleNumber}</div>
              </Space>
            ),
          },
          {
            title: '特採單號',
            dataIndex: 'waiverNumber',
            render: (source: T) => (
              <Space direction="vertical">
                <div>{source?.inventoryOrderWaiverNumber}</div>
                <div>{source?.deliveryOrderWaiverNumber}</div>
              </Space>
            ),
          },
          { title: '銷售訂單單號', dataIndex: 'salesOrderId' },
          { title: '銷售訂單項次', dataIndex: 'salesOrderLine' },
          {
            title: '實際重量',
            dataIndex: 'actualMaterialWeightUnitQuantity',
          },
          { title: '移除', dataIndex: 'remove' },
        ],
      };
    },
    rowExpandable: () =>
      MaterialShiftInputFields.LOADER_BARCODE === fetchingInventoryParams?.type,
  };

  return (
    <LocationSelectorProvider controller={locationController}>
      <Table
        ref={tableRef}
        scroll={{
          y: 300,
          x: 2500,
        }}
        fetchMore={{
          callback: async () => {
            // 載具條碼一次拿一個 table 所有的資料
            if (
              fetchingInventoryParams?.type ===
              MaterialShiftInputFields.LOADER_BARCODE
            )
              return;

            const res = await fetchInventoryByMaterial({
              stockTypes,
              offset: (fetchingInventoryParams?.offset ?? 0) + PAGE_LIMIT,
            });
            const { records, pageInfo } = res;
            dataSourceMaintainer((prevSource) => [
              ...prevSource,
              ...(records ?? []).map((row: ShiftTableColumnProps) => ({
                ...row,
                id: `${Math.random()}`,
                stackName: row?.stackName,
                batchId: row?.batchId,
                loaderId: row?.loaderId,
                loaderName: row?.loaderName,
                availableMaterialInputUnitQuantity: new Decimal(
                  row?.quantity ?? '0'
                )
                  .div(row?.stockOverInputUnitRatio ?? '1')
                  .toString(),
                transferAmount: new Decimal(row?.quantity ?? '0')
                  .div(row?.stockOverInputUnitRatio ?? '1')
                  .toString(),
              })),
            ]);
            mutateFetchInventoryParams((prev) => {
              return {
                ...prev,
                offset: pageInfo?.offset,
                hasNext: pageInfo?.hasNext,
              };
            });
          },
          isReachEnd: !fetchingInventoryParams?.hasNext,
        }}
        columns={column}
        dataSource={dataSource}
        bodyClassName={classes['shift-table']}
        {...((
          [
            MaterialShiftInputFields.MATERIAL_ID_OR_MATERIAL_NAME,
            MaterialShiftInputFields.MATERIAL_BARCODE,
          ] as string[]
        )?.includes(fetchingInventoryParams?.type ?? '') && {
          rowSelection: {
            selectedRowKey: watchSelectedRowKeys,
            onChange: (keys) => {
              setValue('selectedRowKeys', keys);
            },
          },
        })}
        {...(MaterialShiftInputFields.LOADER_BARCODE ===
          fetchingInventoryParams?.type && {
          expandable: { ...mockExpandableData },
        })}
      />
      <LocationSVGPopper anchorRef={locationSVGPopperHandler?.anchorRef} />
    </LocationSelectorProvider>
  );
}
