import React from 'react';
import {
  Message,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions,
} from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import styles from './index.module.scss';

import type { CustomerTradeConditionListData } from '@solar/data';
import { useCustomerTradeConditionDelete } from '@solar/data';

type Props = {
  dataSource: CustomerTradeConditionListData;
  refetchDataTable?: () => void;
};

export function ActionDeleteConfirmButton({
  dataSource,
  refetchDataTable,
}: Props) {
  const [activeModal, setActiveModal] = React.useState(false);
  const { formMethods, createFormSubmit } = useCustomerTradeConditionDelete({
    customerId: dataSource?.customerId ?? '',
    materialId: dataSource?.materialId ?? '',
  });

  const openModal = () => setActiveModal(true);
  const closeModal = () => setActiveModal(false);

  return (
    <>
      <Button type="button" variant="contained" danger onClick={openModal}>
        刪除
      </Button>
      <Modal severity="info" open={activeModal} onClose={closeModal}>
        <FormFieldsWrapper
          methods={formMethods}
          onSubmit={createFormSubmit({
            successCallback: () => {
              Message.success('交易條件刪除成功');
              // FIXME: fetch prev page data when delete the last row of current page
              refetchDataTable?.();
              closeModal();
            },
            errorCallback: (error) => {
              Message.error(`交易條件刪除失敗：${error?.message}`);
            },
          })}
        >
          <ModalHeader showSeverityIcon>確認刪除？</ModalHeader>
          <ModalBody style={{ paddingBottom: '1rem' }}>
            <div className={styles['wrapper']}>
              <div className={styles['col-span-1']}>客戶：</div>
              <div className={styles['col-span-1']}>
                {`${dataSource?.customerName ?? ''}(${
                  dataSource?.customerId ?? ''
                })`}
              </div>
              <div className={styles['col-span-1']}>品名：</div>
              <div className={styles['col-span-1']}>
                {`${dataSource?.materialName ?? ''}(${
                  dataSource?.materialId ?? ''
                })`}
              </div>
            </div>
          </ModalBody>
          <ModalActions
            cancelText="取消"
            confirmText="刪除"
            cancelButtonProps={{ type: 'button', variant: 'contained' }}
            confirmButtonProps={{ type: 'submit', danger: true }}
            onCancel={closeModal}
          ></ModalActions>
        </FormFieldsWrapper>
      </Modal>
    </>
  );
}
