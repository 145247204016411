import { Button, Table, Typography } from '@mezzanine-ui/react';

import { useForm } from 'react-hook-form';

import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';

export function DeletedDrawingCodePage() {
  return (
    <div>
      <Typography variant="h2">已刪除圖號</Typography>
      <iframe
        title="LIMS_new"
        width="1140"
        height="541.25"
        src="https://app.powerbi.com/reportEmbed?reportId=cb0f665d-4e01-40ec-8437-47c8d299daa4&autoAuth=true&ctid=e3553a8e-c723-4b54-ac77-0bb3db8cf161"
        allowFullScreen
      />
    </div>
  );
}
