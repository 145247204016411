import {
  Message,
  ModalActions,
  SelectValue,
  Typography,
} from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  FormFieldsDebug,
  FormFieldsWrapper,
} from '@mezzanine-ui/react-hook-form';
import { useMaterials, useMaterialsInInventory } from '@solar/data';
import { RowSection, useTargetModal } from '@solar/templates';
import { useCallback, useEffect } from 'react';
import { useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { ModalLayout } from '../../ModalLayout/ModalLayout';
import { MaterialIdTransferObj, MaterialIdUpdateForm, Modals } from '../types';
import { materialIdTransferTo } from '../utils';

const THIS_FORM = 'EDIT_MATERIAL_ID';

export const Edit = ({
  tableMethods,
}: {
  tableMethods: UseFormReturn<MaterialIdUpdateForm, any>;
}) => {
  const methods = useForm<{
    material: SelectValue | null;
    materialDescription: SelectValue | null;
  }>();

  const modalController = useTargetModal<MaterialIdTransferObj>(Modals.EDIT);

  const closeModal = useCallback(() => {
    modalController.closeModal();
    methods.reset();
  }, [methods, modalController]);

  const { materials, setParams: mutateGetMaterials } = useMaterials();

  const autoCompleteMaterialDescription = useCallback(
    (event: SelectValue, fieldName: 'materialDescription') => {
      const targetId = event?.id;

      if (!targetId) return;
      if (!materials?.length) return;

      const relatedMaterials: {
        [id: string]: typeof materials[number];
      } = materials.reduce(
        (prev, material) => ({ ...prev, [material.id]: material }),
        {}
      );

      if (fieldName === 'materialDescription') {
        methods.setValue(`${fieldName}`, {
          id: relatedMaterials[targetId].id,
          name: relatedMaterials[targetId].description,
        });
      }
    },
    [materials, methods]
  );

  const watchedMaterialDescription = useWatch({
    control: methods.control,
    name: 'materialDescription',
  });

  useEffect(() => {
    if (modalController?.open && modalController.data) {
      methods.setValue('material', {
        id: modalController.data.newMaterialId,
        name: modalController.data.newMaterialId,
      });
      methods.setValue('materialDescription', {
        id: modalController.data.newMaterialId,
        name: modalController?.data?.materialDescription ?? '',
      });
    }
  }, [modalController.open, modalController.data]);

  return (
    <ModalLayout
      modalHeader={<br />}
      {...modalController}
      closeModal={closeModal}
      loading={methods.formState.isSubmitting}
      modalFooter={
        <ModalActions
          confirmText="確認"
          cancelText="取消"
          confirmButtonProps={{ form: THIS_FORM }}
          onCancel={closeModal}
        />
      }
    >
      <FormFieldsWrapper
        id={THIS_FORM}
        methods={methods}
        onSubmit={async (values) => {
          const { material } = values;
          try {
            if (!material?.id) {
              throw '料號必填';
            }

            if (!modalController.data) {
              throw '系統錯誤';
            }

            const res = await materialIdTransferTo({
              payload: {
                ...modalController?.data,
                newMaterialId: material?.id,
              },
            });

            if (res?.ok) {
              Message.success('料號更新成功');
              tableMethods.reset();
              modalController.closeModal();
            }
          } catch (err) {
            if (err instanceof Error) {
              Message.error(JSON.stringify(JSON.parse(err.message).message));
            } else {
              Message.error(JSON.stringify(err));
            }
          }
        }}
      >
        <RowSection label="料號：">
          <AutoCompleteField
            registerName="material"
            options={(materials ?? [])?.map((material) => ({
              id: material.id,
              name: material.id,
            }))}
            onChange={(event) => {
              autoCompleteMaterialDescription(event, 'materialDescription');
            }}
            onSearch={(searchTerm) =>
              mutateGetMaterials({ searchTerm, searchType: 'ID' })
            }
          />
        </RowSection>
        <RowSection label="品名：">
          <Typography>{watchedMaterialDescription?.name}</Typography>
        </RowSection>
      </FormFieldsWrapper>
    </ModalLayout>
  );
};
