import { TableColumn } from '@mezzanine-ui/core/table';
import {
  Button,
  Message,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from '@mezzanine-ui/react';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import {
  API_NAMESPACE,
  IncomingPurchaseOrder,
  request,
  useGetIQCPurchaseOrders,
} from '@solar/data';
import {
  QuantityWithUnitsColumn,
  QuantityWithUnitsField,
  useDraggableColumns,
  usePageModal,
  useTargetModal,
} from '@solar/templates';
import { Space } from 'antd';
import { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

export const RETURN_STORED_LOADER_RECORD_MODAL =
  'RETURN_STORED_LOADER_RECORD_MODAL';

export function ReturnStoredLoaderRecordModal() {
  const tableRef = useRef<HTMLTableElement>(null);
  const [submitting, setSubmitting] = useState(false);
  const { open, data, closeModal } = useTargetModal<
    IncomingPurchaseOrder['sapBatch']['subBatches'][number]['storedLoaderRecords'][number] & {
      materialInputUnit: string;
      materialStockUnit: string;
      stockOverInputUnitRatio: string;
      remark: string;
    }
  >(RETURN_STORED_LOADER_RECORD_MODAL);

  const { mutateGetIQCPurchaseOrders } = useGetIQCPurchaseOrders({
    statusIds: [10],
    purchaseOrderType: 'INVENTORY',
  });

  const methods = useForm();

  const resetForm = useCallback(() => {
    methods.reset({
      materialActualWeightQuantity: '',
      materialStockUnitQuantity: '',
    });
  }, [methods]);

  const onClose = useCallback(() => {
    closeModal?.();
    resetForm();
  }, [closeModal, resetForm]);

  const onSubmit = useCallback(async () => {
    const formState = methods.getValues();

    try {
      setSubmitting(true);

      await request('/warehouses/inventory-purchase-orders/returning', {
        method: 'put',
        namespace: API_NAMESPACE.MATERIALS,
        body: JSON.stringify({
          iqcOrderId: data?.iqcOrderId,
          loaderMaterialBatches: [
            {
              loaderMaterialBatchId: data?.id,
              materialStockUnitQuantity: formState.materialStockUnitQuantity,
              materialActualWeightQuantity:
                formState.materialActualWeightQuantity,
            },
          ],
        }),
      });

      Message.success('退貨成功');

      await mutateGetIQCPurchaseOrders(undefined, true);

      onClose();
    } catch (error: any) {
      console.error(error);
      Message.error(`退貨失敗：${JSON.parse(error?.message)?.message}`);
    } finally {
      setSubmitting(false);
    }
  }, [data, methods, onClose]);

  const columns = useDraggableColumns(tableRef, [
    {
      dataIndex: 'materialId',
      title: '料號',
    },
    {
      width: 200,
      title: '批號',
      render: (source) =>
        `${source?.materialSapBatchId}-${source?.materialBatchId}`,
    },
    {
      width: 120,
      dataIndex: 'loaderId',
      title: '載具',
    },
    {
      width: 150,
      dataIndex: 'shelfId',
      title: '儲位',
    },
    {
      width: 250,
      title: '數量',
      render: (source) => (
        <QuantityWithUnitsColumn
          quantity={source?.quantity}
          materialInputUnit={source?.materialInputUnit}
          materialStockUnit={source?.materialStockUnit}
          stockOverInputUnitRatio={source?.stockOverInputUnitRatio}
        />
      ),
    },
    {
      width: 200,
      dataIndex: 'actualMaterialWeightUnitQuantity',
      title: '實際重量',
    },
    {
      dataIndex: 'remark',
      title: '備註',
    },
  ] as TableColumn<
    IncomingPurchaseOrder['sapBatch']['subBatches'][number]['storedLoaderRecords'][number] & {
      iqcOrderId: string;
      materialInputUnit: string;
      materialStockUnit: string;
      stockOverInputUnitRatio: string;
      remark: string;
    }
  >[]);

  return (
    <Modal open={open} onClose={onClose} style={{ width: 1200 }}>
      <ModalHeader>退貨</ModalHeader>
      <ModalBody>
        <FormFieldsWrapper methods={methods}>
          <Space direction="vertical">
            <Table
              ref={tableRef}
              dataSource={[Object.assign({}, data, { key: '1' })]}
              columns={columns}
            />
            <QuantityWithUnitsField
              stockUnitQuantityRegisterName="materialStockUnitQuantity"
              materialStockUnit={data?.materialStockUnit ?? ''}
              materialInputUnit={data?.materialInputUnit ?? ''}
              stockOverInputUnitRatio={data?.stockOverInputUnitRatio ?? ''}
            />
            <InputField
              registerName="materialActualWeightQuantity"
              label="實際重量："
            />
          </Space>
        </FormFieldsWrapper>
      </ModalBody>
      <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Space>
          <Button variant="outlined" onClick={onClose}>
            取消
          </Button>
          <Button
            danger
            variant="contained"
            loading={submitting}
            onClick={onSubmit}
          >
            確認退貨
          </Button>
        </Space>
      </ModalFooter>
    </Modal>
  );
}
