import { Message } from '@mezzanine-ui/react';
import { getJWTPayload, isTokenExpired } from '@solar/data';
import { useEffect, useState, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type PageGuard = {
  children: ReactNode;
};

export function PageGuard({ children }: PageGuard) {
  const location = useLocation();
  const navigate = useNavigate();
  const [valid, toggleValid] = useState<boolean>(false);

  useEffect(() => {
    const token = localStorage.getItem('accessToken') ?? null;
    const expired = isTokenExpired(token);

    const isExcludePath = ['/auth/login'].includes(location.pathname);

    try {
      if (!isExcludePath && (!token || expired)) {
        throw new Error('invalid token');
      }

      // const payload = getJWTPayload();

      if (isExcludePath && token && !expired) {
        const redirect = localStorage.getItem('redirect') ?? '/';
        navigate(redirect, { replace: true });
      }
    } catch (error) {
      if (!isExcludePath) {
        console.log(error);
        localStorage.removeItem('accessToken');
        Message.error('權限失效，請重新登入');

        localStorage.setItem(
          'redirect',
          `${location.pathname}${location.search}`
        );

        navigate('/auth/login', { replace: true });
      }
    } finally {
      toggleValid(true);
    }
  }, [location, navigate]);

  return (valid ? children : null) as JSX.Element;
}
