import { CSSProperties, ChangeEvent } from 'react';
import { Typography, Button, Icon, Textarea } from '@mezzanine-ui/react';
import { useNavigate } from 'react-router-dom';
import styles from '../../../SalesAndDistribution/PackagingPage/PackagingPage.module.scss';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  AutoCompleteField,
  FormFieldsWrapper,
} from '@mezzanine-ui/react-hook-form';
import { ChevronLeftIcon } from '@mezzanine-ui/icons';
import classes from './create-material-register-order-page.module.scss';
import { enhanceCustomMznComponent } from '../../IncomingInspectOperationPage/Modals/InspectMaterialSecondLayerModal';
import { CreateMaterialRegisterOrderTable } from './CreateMaterialRegisterOrderTable';

export function CreateMaterialRegisterOrderPage() {
  const methods = useForm({
    defaultValues: {
      tableValues: [
        {
          amount: 5,
          materialId: '1234',
          zone: {
            id: '1100A1B1A',
            name: 'A群落',
            area: {
              id: '1100A1B',
              name: 'B區',
              floor: {
                id: '1100A1',
                name: '1F',
                building: {
                  id: '1100A',
                  name: 'A棟',
                  factory: {
                    id: '1100',
                    name: '科工廠',
                  },
                },
              },
            },
          },
        },
      ],
      department: null,
      purpose: '',
    },
  });
  const { fields } = useFieldArray({
    control: methods.control,
    name: 'tableValues',
  });

  const navigate = useNavigate();

  return (
    <FormFieldsWrapper methods={methods}>
      <div className={classes.wrapper}>
        <div>
          <Button
            type="button"
            onClick={() => navigate('/warehouse/material-collect')}
            prefix={
              <Icon
                style={{ color: '#8F8F8F' } as CSSProperties}
                icon={ChevronLeftIcon}
              />
            }
            variant="text"
          >
            <Typography color="text-secondary" variant="button2">
              返回上一頁
            </Typography>
          </Button>
        </div>
        <Typography variant="h1">{'新建領料單'}</Typography>

        {enhanceCustomMznComponent(AutoCompleteField)({
          props: {
            registerName: 'department',
            options: [],
          },
          label: '領用部門',
        })}
        {enhanceCustomMznComponent(Textarea)({
          props: {
            registerName: 'purpose',
            fullWidth: true,
            onChange: (e: ChangeEvent<HTMLInputElement>) =>
              methods.setValue('purpose', e.target.value),
            value: methods?.watch('purpose') || '',
          },
          label: '事由',
        })}

        <Typography variant="h3">領用物料清單</Typography>

        <CreateMaterialRegisterOrderTable
          key={`${fields}`}
          bodyClassName={styles.table}
        />
      </div>
    </FormFieldsWrapper>
  );
}
