import { useNavigate, useParams } from "react-router";
import { PageTemplate } from "../../components/PageTemplate/PageTemplate";
import { Button, MenuDivider, Table, Typography } from "@mezzanine-ui/react";
import { useReturnMaterial } from "@solar/data";
import { Descriptions } from "antd";

enum returnMaterialResourceType {
  workOrder = '1',
  department = '2',
  ipqc = '3',
};

export function ReturnMaterialDetailPage() {
  const params = useParams();
  const navigate = useNavigate();
  const { returnMaterial } = useReturnMaterial(params as { id: string });

  const getTitleWording = (type: any) => {
    switch (type) {
      case returnMaterialResourceType.workOrder: return '工單退料';
      case returnMaterialResourceType.department: return '部門費用退料';
      case returnMaterialResourceType.ipqc: return 'IPQC 品檢退料';
      default: return '';
    }
  };

  const columns = [
    {
      title: '料號',
      dataIndex: 'materialId',
    },
    {
      title: '規格',
      dataIndex: 'spec',
    },
    {
      title: '批號',
      dataIndex: 'batch',
    },
    {
      title: '儲位',
      dataIndex: 'stack',
    },
    {
      title: '載具',
      dataIndex: 'loader',
    },
    {
      title: '已領數量',
      dataIndex: 'gottenAmount',
    },
    {
      title: '已退數量',
      dataIndex: 'returnedAmount',
    },
    {
      title: '操作',
      render: () => (
        <Button>退料</Button>
      )
    },
  ];

  return (
    <PageTemplate
      title={getTitleWording(returnMaterial?.resourceType)}
      extraContent={(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button onClick={() => navigate('/warehouse/return-material')}>
            返回上一頁
          </Button>
        </div>
      )}
    >
      {returnMaterial?.resourceType === returnMaterialResourceType.workOrder && (
        <Descriptions>
          <Descriptions.Item label="工單單號">WW0001</Descriptions.Item>
          <Descriptions.Item label="BU">TEM</Descriptions.Item>
          <Descriptions.Item label="製程">稀金屬回收</Descriptions.Item>
          <Descriptions.Item label="預期領料日">2023/02/15</Descriptions.Item>
        </Descriptions>
      )}
      {returnMaterial?.resourceType === returnMaterialResourceType.department && (
        <Descriptions>
          <Descriptions.Item label="領料單號">WW0001</Descriptions.Item>
          <Descriptions.Item label="預期領料日">2023/02/15</Descriptions.Item>
          <Descriptions.Item label="領用部門">實驗課</Descriptions.Item>
          <Descriptions.Item label="領料事由">某某部門需要領用作為測試與時間!@#$%%&!^#%$&</Descriptions.Item>
        </Descriptions>
      )}
      {returnMaterial?.resourceType === returnMaterialResourceType.ipqc && (
        <Descriptions>
          <Descriptions.Item label="IPQC 單號">WW0001</Descriptions.Item>
          <Descriptions.Item label="預期領料日">2023/02/15</Descriptions.Item>
        </Descriptions>
      )}
      <MenuDivider />
      <div>
        <Typography variant="h3">已領取物料</Typography>
        <Table
          style={{ height: 350 }}
          columns={columns}
          dataSource={[
            {
              id: 1,
              materialId: 'AABBBCD02921',
              spec: '',
              batch: '20230405025-001',
              stack: '1100A1C4J1B',
              loader: '00001',
              gottenAmount: 5,
              returnedAmount: 0
            },
          ]} />
      </div>
      {returnMaterial?.resourceType !== returnMaterialResourceType.department && (
        <div>
          <Typography variant="h3">已領額外物料</Typography>
          <Table
            style={{ height: 350 }}
            columns={columns}
            dataSource={[
              {
                id: 1,
                materialId: 'AABBBCD02921',
                spec: '',
                batch: '20230405025-001',
                stack: '1100A1C4J1B',
                loader: '00001',
                gottenAmount: 5,
                returnedAmount: 0
              },
            ]} />
        </div>
      )}
    </PageTemplate>
  )
}