import { InputField, InputFieldProps } from '@mezzanine-ui/react-hook-form';
import { ProductionFormFieldText } from '@solar/data';
import Decimal from 'decimal.js';
import { useCallback, useMemo } from 'react';
import {
  FieldValues,
  UseFormRegister,
  Validate,
  useFormContext,
} from 'react-hook-form';

interface TextInputFieldProps extends InputFieldProps {
  fields: ProductionFormFieldText;
}

export function TextInputField({
  fields,
  registerName,
  ...props
}: TextInputFieldProps) {
  const methods = useFormContext<FieldValues>();

  // const validate = useMemo(() => {
  //   const results: Record<string, Validate<any>> = {};

  //   if (fields?.required) {
  //     results.required = (v) => !!v || '必填';
  //   }

  //   return results;
  // }, [fields?.required]);

  // const _register = useCallback<UseFormRegister<FieldValues>>(
  //   (name, options) => {
  //     return methods.register(name, Object.assign(options ?? {}, { validate }));
  //   },
  //   [methods, validate]
  // );

  const onBlur = useCallback(() => {
    methods.trigger(registerName);
  }, [methods, registerName]);

  return (
    <InputField
      // register={_register}
      registerName={registerName}
      placeholder={fields?.placeholder ?? ''}
      onBlur={onBlur}
      {...props}
    />
  );
}
