import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { MaterialGroup } from './material-group.type';

const namespace = API_NAMESPACE.MATERIALS;

export function useMaterialGroups() {
  const { data, error } = useSWR<MaterialGroup[]>([
    '/material-properties/groups',
    { namespace },
  ]);

  return {
    materialGroups: data ?? [],
    isLoading: !error && !data,
  };
}
