import { Button, Message } from '@mezzanine-ui/react';
import {
  FormFieldsWrapper,
  TextAreaField,
} from '@mezzanine-ui/react-hook-form';
import { checkJWTRoles, useWorkOrder } from '@solar/data';
import { ModalGroup, useModalGroupController } from '@solar/templates';
import { Descriptions, Table } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMaterialLabelColumn } from './hooks';
import { DispatchSettingModal } from './modal/DispatchSettingModal';
import {
  DispatchMaterialViewModalProps,
  DispatchPreviewTableProps,
  Modals,
} from './types';
import { updateRemark } from './utils';
import Decimal from 'decimal.js';

export const fieldNamingMapping = {
  alreadyUsedQuantity: '需求管理數量', //'已耗用數量',
  createdAt: '建立日期',
  expectedCompletedAt: '訂單交期',
  expectedMaterialUnitQuantity: '預期產出數量',
  actualMainMaterialUnitQuantity: '已入庫數量',
  factory: '工廠',
  orderId: '工單單號',
  orderType: '工單類形',
  requirementQuantity: '需求基礎劑量單位數量', //'需求數量',
  saleOrderId: '銷售訂單單號',
  saleOrderItem: '銷售訂單項目',
  status: '狀態',
  targetMaterialId: '目標料號',
  targetMaterialDescription: '目標品名',
  byProductMaterialId: '副產品料號',
  byProductMaterialDescription: '副產品品名',
  workCenterId: '工作中心代碼',
  workCenterName: '工作中心名稱',
  inputMaterialId: '投入料料號',
  inputMaterialDescription: '投入料品名',
  targetBatchId: '目標批號',
  unit: '單位',
  mrpController: 'MRP 控制員',
};

type Form = {
  remark: string;
};

const REMARK = 'remark';
export const ANTD_TH_BACKGROUND = 'rgba(0, 0, 0, 0.02)';

export const BasicInfoPanel = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const { order, mutate } = useWorkOrder({ orderId });
  const materialLabelColumn = useMaterialLabelColumn();

  const methods = useForm<Form>();

  const bomRef = useRef<HTMLDivElement>(null);
  const routingRef = useRef<HTMLDivElement>(null);

  /** SOL-1256 自動儲存改成按儲存才送出 */
  // 處理備註欄位 debounce request
  // const debounceInputHandler = useRef(
  //   debounce((e: ChangeEvent<HTMLTextAreaElement>) => {
  //     if (orderId) {
  //       updateRemark({ remark: e.target.value, orderId });
  //     }
  //   }, 600)
  // ).current;

  const handleUpdateRemark = useCallback(
    ({ remark }: { remark: string }) => {
      if (orderId) {
        updateRemark({ remark, orderId })
          .then((res) => {
            if (res.ok) {
              Message.success('備註更新成功');
            }
          })
          .catch(() => {
            Message.success('備註更新失敗');
          })
          .finally(() => {
            mutate();
          });
      }
    },
    [orderId]
  );

  const navigate = useNavigate();

  const modalGroupController = useModalGroupController([
    { name: Modals.DISPATCH_SETTING },
  ]);

  useEffect(() => {
    if (order) {
      if (bomRef?.current) {
        bomRef.current?.parentElement?.parentElement?.setAttribute(
          'colspan',
          '8'
        );
      }
      if (routingRef?.current) {
        routingRef.current?.parentElement?.parentElement?.setAttribute(
          'colspan',
          '8'
        );
      }
    }
  }, [order]);

  return (
    <>
      <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
        <Descriptions bordered column={4}>
          <Descriptions.Item label={fieldNamingMapping['orderId']}>
            {orderId}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNamingMapping['status']}>
            {order?.workOrderStatusName}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNamingMapping['factory']}>
            {order?.factoryId}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNamingMapping['mrpController']}>
            {order?.mrpControllerName}
          </Descriptions.Item>
          {/*  */}
          <Descriptions.Item label={fieldNamingMapping['orderType']} span={2}>
            {order?.orderTypeName}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNamingMapping['createdAt']} span={2}>
            {order?.createdAt
              ? moment(order?.createdAt).format('YYYY/MM/DD')
              : ''}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNamingMapping['saleOrderId']}>
            {order?.salesOrderId}
          </Descriptions.Item>

          <Descriptions.Item label={fieldNamingMapping['saleOrderItem']}>
            {order?.salesOrderLineId}
          </Descriptions.Item>
          <Descriptions.Item
            label={fieldNamingMapping['expectedCompletedAt']}
            span={2}
          >
            {order?.expectedDeliveryDate
              ? moment(order?.expectedDeliveryDate).format('YYYY/MM/DD')
              : ''}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ padding: '0px' }}
            contentStyle={{ padding: '0px' }}
            label={
              <div>
                <div style={{ padding: '16px 24px' }}>
                  {fieldNamingMapping['targetMaterialId']}
                </div>
                <hr style={{ border: '1px solid rgba(5, 5, 5, 0.06)' }} />
                <div style={{ padding: '16px 24px' }}>
                  {fieldNamingMapping['targetMaterialDescription']}
                </div>
              </div>
            }
            span={2}
          >
            <div style={{ padding: '16px 24px' }}>
              {((order?.expectedMainProduct ?? [])[0] ?? {})?.materialId}
            </div>
            <hr style={{ border: '1px solid rgba(5, 5, 5, 0.06)' }} />
            <div style={{ padding: '16px 24px' }}>
              {
                ((order?.expectedMainProduct ?? [])[0] ?? {})
                  ?.materialDescription
              }
            </div>
          </Descriptions.Item>
          <Descriptions.Item
            label={fieldNamingMapping['expectedMaterialUnitQuantity']}
            contentStyle={{ padding: '0px' }}
          >
            <div style={{ padding: '16px 24px' }}>
              {`${new Decimal(
                ((order?.expectedMainProduct ?? [])[0] ?? {})
                  ?.expectedMaterialStockUnitQuantity ?? '0'
              )
                .div(order?.stockOverInputUnitRatio ?? '1')
                .toString()} ${order?.materialInputUnit}`}
            </div>
            <hr style={{ border: '1px solid rgba(5, 5, 5, 0.06)' }} />
            <div style={{ padding: '16px 24px' }}>
              {`${
                ((order?.expectedMainProduct ?? [])[0] ?? {})
                  ?.expectedMaterialStockUnitQuantity
              } ${order?.materialStockUnit}`}
            </div>
          </Descriptions.Item>
          <Descriptions.Item
            label={fieldNamingMapping['actualMainMaterialUnitQuantity']}
            contentStyle={{ padding: '0px' }}
          >
            <div style={{ padding: '16px 24px' }}>
              {`${new Decimal(
                ((order?.expectedMainProduct ?? [])[0] ?? {})
                  ?.actualMaterialStockUnitQuantity ?? '0'
              )
                .div(order?.stockOverInputUnitRatio ?? '1')
                .toString()} ${order?.materialInputUnit}`}
            </div>
            <hr style={{ border: '1px solid rgba(5, 5, 5, 0.06)' }} />
            <div style={{ padding: '16px 24px' }}>
              {`${
                ((order?.expectedMainProduct ?? [])[0] ?? {})
                  ?.actualMaterialStockUnitQuantity
              } ${order?.materialStockUnit}`}
            </div>
          </Descriptions.Item>
          {/* <Descriptions.Item label={fieldNamingMapping['unit']}>
            &nbsp;
          </Descriptions.Item> */}
          {/* <Descriptions.Item
            label={fieldNamingMapping['targetMaterialDescription']}
            span={2}
          >
            {((order?.expectedMainProduct ?? [])[0] ?? {})?.materialDescription}
          </Descriptions.Item>
          <Descriptions.Item
            label={fieldNamingMapping['expectedMaterialUnitQuantity']}
          >
            {
              ((order?.expectedMainProduct ?? [])[0] ?? {})
                ?.expectedMainMaterialUnitQuantity
            }
          </Descriptions.Item>
          <Descriptions.Item label={fieldNamingMapping['unit']}>
            {((order?.expectedMainProduct ?? [])[0] ?? {})?.mainMaterialUnit}
          </Descriptions.Item> */}

          {(order?.expectedSecondaryProduct ?? [])?.map((row: any) => {
            return (
              <>
                <Descriptions.Item
                  label={fieldNamingMapping['byProductMaterialId']}
                  span={2}
                >
                  {row?.materialId}
                </Descriptions.Item>
                <Descriptions.Item
                  label={fieldNamingMapping['actualMainMaterialUnitQuantity']}
                >
                  {row?.actualMaterialStockUnitQuantity}
                </Descriptions.Item>
                <Descriptions.Item label={fieldNamingMapping['unit']}>
                  {row?.mainMaterialUnit}
                </Descriptions.Item>
                <Descriptions.Item
                  label={fieldNamingMapping['byProductMaterialDescription']}
                  span={2}
                >
                  {row?.materialDescription}
                </Descriptions.Item>
                <Descriptions.Item
                  label={fieldNamingMapping['expectedMaterialUnitQuantity']}
                >
                  {row?.expectedMainMaterialUnitQuantity}
                </Descriptions.Item>
                <Descriptions.Item label={fieldNamingMapping['unit']}>
                  {row?.mainMaterialUnit}
                </Descriptions.Item>
              </>
            );
          })}

          {/*  */}
          {order?.expectedJointProduct.map((row: any) => {
            return (
              <>
                <Descriptions.Item label="聯產品料號" span={2}>
                  {row?.materialId}
                </Descriptions.Item>
                <Descriptions.Item label="已入庫數量">
                  {row?.actualMaterialStockUnitQuantity}
                </Descriptions.Item>
                <Descriptions.Item label="單位">
                  {row?.materialStockUnit}
                </Descriptions.Item>

                <Descriptions.Item label="聯產品品名" span={2}>
                  {row?.materialDescription}
                </Descriptions.Item>
                <Descriptions.Item label="預期產出數量">
                  {row?.expectedMaterialStockUnitQuantity}
                </Descriptions.Item>
                <Descriptions.Item label="單位">
                  {row?.materialStockUnit}
                </Descriptions.Item>
              </>
            );
          })}

          <Descriptions.Item
            labelStyle={{ display: 'none' }}
            span={4}
            contentStyle={{
              backgroundColor: ANTD_TH_BACKGROUND,
              textAlign: 'center',
            }}
            children={
              <>
                <div ref={bomRef}>工單 BOM</div>
              </>
            }
          />

          {(order?.expectedMaterials ?? []).map((row: any) => {
            return (
              <>
                {/* <Descriptions.Item
                  label={fieldNamingMapping['workCenterId']}
                  span={2}
                >
                  {row.workCenterId}
                </Descriptions.Item>
                <Descriptions.Item
                  label={fieldNamingMapping['workCenterName']}
                  span={2}
                >
                  {row.workCenterName}
                </Descriptions.Item> */}
                <Descriptions.Item
                  label={fieldNamingMapping['inputMaterialId']}
                  span={2}
                >
                  {row.materialId}
                </Descriptions.Item>
                <Descriptions.Item
                  label={fieldNamingMapping['requirementQuantity']}
                >
                  {row.expectedMaterialStockUnitQuantity}
                </Descriptions.Item>
                <Descriptions.Item label={fieldNamingMapping['unit']}>
                  {row.materialStockUnit}
                </Descriptions.Item>
                <Descriptions.Item
                  label={fieldNamingMapping['inputMaterialDescription']}
                  span={2}
                >
                  {row.materialDescription}
                </Descriptions.Item>
                <Descriptions.Item
                  label={fieldNamingMapping['alreadyUsedQuantity']}
                >
                  {row.expectedMaterialInputUnitQuantity}
                </Descriptions.Item>
                <Descriptions.Item label={fieldNamingMapping['unit']}>
                  {row.materialInputUnit}
                </Descriptions.Item>
              </>
            );
          })}

          <Descriptions.Item
            labelStyle={{ display: 'none' }}
            span={4}
            contentStyle={{
              backgroundColor: ANTD_TH_BACKGROUND,
              textAlign: 'center',
            }}
            children={
              <>
                <div ref={routingRef}>Routing</div>
              </>
            }
          />
          {(order?.operationCodes ?? []).map((row: any) => {
            return (
              <>
                <Descriptions.Item label={fieldNamingMapping['workCenterId']}>
                  {row.workCenterId}
                </Descriptions.Item>
                <Descriptions.Item label={fieldNamingMapping['workCenterName']}>
                  {row.workCenterName}
                </Descriptions.Item>
                <Descriptions.Item label="內文碼">
                  {row.processingName}
                </Descriptions.Item>
                <Descriptions.Item label="工序">
                  {row.operationCode}
                </Descriptions.Item>
              </>
            );
          })}
        </Descriptions>
      </div>
      <hr />
      <br />
      <Table columns={materialLabelColumn} dataSource={order?.batches ?? []} />
      <br />
      <br />
      <FormFieldsWrapper
        id={REMARK}
        methods={methods}
        onSubmit={async ({ remark }) => {
          handleUpdateRemark({ remark });
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <label htmlFor="salesOrderNote">銷售訂單備註</label>
          <textarea
            style={{ width: 400 }}
            readOnly
            id="salesOrderNote"
            placeholder=""
            value={order?.salesOrderNote ?? ''}
          />
        </div>
        <br />
        <div style={{ display: 'flex', alignItems: 'end', gap: '8px' }}>
          <TextAreaField
            label="備註"
            registerName="remark"
            defaultValue={order?.remark}
            width={400}
          />
          {checkJWTRoles([115]) && (
            <Button variant="contained" form={REMARK}>
              儲存備註
            </Button>
          )}
        </div>
        <br />
      </FormFieldsWrapper>
      <br />
      <div
        style={{ display: 'flex', justifyContent: 'flex-start', gap: '12px' }}
      >
        {checkJWTRoles([115]) && (
          <Button
            type="button"
            variant="outlined"
            onClick={() =>
              modalGroupController.openModal<DispatchMaterialViewModalProps>(
                Modals.DISPATCH_SETTING,
                {
                  dispatchPreviewTable:
                    order?.expectedMaterials as DispatchPreviewTableProps[],
                  materialId: order?.materialId ?? '',
                  orderId: null,
                  recipeId: order?.recipeId ?? null,
                  processingIdIncludesEF: order?.operationCodes.some(
                    (row: { processingId: string }) => row.processingId === 'EF'
                  ), //order?.operationCodes?.[0]?.processingId ?? null,
                }
              )
            }
          >
            建立發料單
          </Button>
        )}
        <Button
          type="button"
          variant="outlined"
          onClick={() =>
            navigate(
              `/production-planning/material-allocations?workOrderId=${orderId}`
            )
          }
        >
          前往配料
        </Button>
        {checkJWTRoles([115]) && (
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              window.open(
                `/print-management/work-order?tab=work-order&workOrderId=${orderId}`,
                '_blank'
              );
            }}
          >
            列印工單
          </Button>
        )}
      </div>
      <ModalGroup {...modalGroupController}>
        <DispatchSettingModal />
      </ModalGroup>
    </>
  );
};
