import {
  ProductionFormResponse,
  ProductionFormCondition,
  request,
  API_NAMESPACE,
} from '@solar/data';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { sortBy, uniqBy } from 'lodash';
import { Message } from '@mezzanine-ui/react';
import { useDynamicFormSchema } from './use-dynamic-form-schema';

export function useGetProductionForms(conditions?: ProductionFormCondition[]) {
  const [formsMap, setFormsMap] =
    useState<Map<string, ProductionFormResponse[]>>();
  const [formsLoading, setFormsLoading] = useState(false);
  const [isFormRendered, setIsFormRendered] = useState(false);

  const { getFormSchema } = useDynamicFormSchema();

  const onFormRender = useCallback(() => {
    setIsFormRendered(true);
  }, []);

  const getProductionForms = useCallback(
    async (conditions: ProductionFormCondition[]) => {
      const materialIdFormMap = new Map<string, ProductionFormResponse[]>();

      for (const condition of conditions) {
        if (!condition.materialId) {
          continue;
        }

        const response = (await request('/forms', {
          method: 'GET',
          namespace: API_NAMESPACE.PP,
          params: {
            materialId: condition.materialId,
            recipeId: condition.recipeId,
            iqcVersion: condition.iqcVersion,
            recipeVersion: condition.recipeVersion ?? '1',
            processingId: condition.processingId,
            workCenterId: condition.workCenterId,
            equipmentId: condition.equipmentId,
            templateTypes: condition.templateTypes ?? ['PRODUCT', 'EQUIPMENT'],
          },
        })) as ProductionFormResponse[];

        if (!Array.isArray(response)) {
          continue;
        }

        materialIdFormMap.set(
          condition.materialId,
          sortBy(response, 'sortIndex')
        );
      }

      return materialIdFormMap;
    },
    []
  );

  useEffect(() => {
    let cancelled = true;

    (async () => {
      try {
        if (!conditions) return;

        setFormsLoading(true);

        const result = await getProductionForms(conditions);

        if (cancelled) {
          setFormsMap(result);
        }
      } catch (error) {
        Message.error('');
      } finally {
        setFormsLoading(false);
      }
    })();

    return () => {
      cancelled = false;
    };
  }, [conditions, getProductionForms]);

  const forms = useMemo(() => {
    if (!formsMap) return undefined;

    const formsCollection = sortBy(
      uniqBy(
        Array.from(formsMap).flatMap(([_material, forms]) => forms),
        (row) => row.id
      ),
      'sortIndex'
    );

    return formsCollection.length === 0 ? undefined : formsCollection;
  }, [formsMap]);

  const resetFormsMap = useCallback(() => {
    setFormsMap(undefined);
  }, []);

  return {
    forms,
    formsMap,
    resetFormsMap,
    formsLoading,
    getFormSchema,
    isFormRendered,
    onFormRender,
  };
}
