export type ApiDataType = {
  id: string;
  name: string;
  tenant_id?: number;
  selectable?: boolean;
  has_roles?: boolean;
};

export type TreeDataType = {
  value: string;
  title: string;
  selectable: boolean;
  tenant_id?: number;
  isLeaf?: boolean;
  children?: TreeDataType[];
};

export const areApiData = (obj: unknown): obj is ApiDataType[] => {
  if (obj && Array.isArray(obj)) return obj.every((item) => item.id);
  return false;
};

export const convertToTreeData = (
  data: ApiDataType[],
  index: number,
  arr: TreeDataType[],
  isLeaf?: boolean
): TreeDataType[] => {
  const current = data?.[index];
  if (!current) return arr;
  const withTenant = {
    tenant_id: current.tenant_id,
  };

  const newArr: TreeDataType[] = [
    ...arr,
    {
      ...withTenant,
      value: `${current.id}`,
      title: current.name,
      selectable: current.selectable ?? true,
      isLeaf: !current.has_roles ?? isLeaf ?? false,
    },
  ];

  return convertToTreeData(data, index + 1, newArr);
};

export const combineTreeData = (
  parent: TreeDataType[],
  children: TreeDataType[]
): TreeDataType[] => {
  children.forEach((child) => {
    const targetParent = parent.filter(
      (item) => parseInt(item.value) === child.tenant_id
    )[0];
    const newChild = {
      ...child,
      value: `role-${child.tenant_id}-${child.value}`,
    };
    if (targetParent.children) {
      targetParent.children.push(newChild);
    } else {
      targetParent.children = [newChild];
    }
  });
  return parent;
};

export type TenantRolesApiType = {
  role_ids: number[];
  tenant_id: number;
};

export const convertTenantAndRole = (
  tenants_roles: { value: string }[]
): TenantRolesApiType[] => {
  const tenants: TenantRolesApiType[] =
    tenants_roles
      ?.filter((tenant: { value: string }) => !tenant.value.includes('role-'))
      .map((tenant) => ({
        role_ids: [],
        tenant_id: parseInt(tenant.value),
      })) ?? [];
  const tenants_id_set = new Set(
    tenants.map((tenant: { tenant_id: number }) => tenant.tenant_id)
  );
  tenants_roles?.forEach((role) => {
    const current = role.value;
    if (!current.includes('role-')) return;
    const split = current.split('-');
    const curr_tenant_id = split[1];
    const curr_role_id = split[2];
    if (tenants_id_set.has(parseInt(curr_tenant_id))) {
      for (const tenant of tenants) {
        if (tenant.tenant_id === parseInt(curr_tenant_id)) {
          tenant.role_ids.push(parseInt(curr_role_id));
        }
      }
    } else {
      tenants_id_set.add(parseInt(curr_tenant_id));
      tenants.push({
        tenant_id: parseInt(curr_tenant_id),
        role_ids: [parseInt(curr_role_id)],
      });
    }
  });
  return tenants;
};

export type ResourceApiDataType = {
  id: number;
  name: string;
  actions?: Array<ResourceApiDataType>;
};

export const areResourceActionsApiData = (
  obj: unknown
): obj is ResourceApiDataType[] => {
  if (obj && Array.isArray(obj)) return obj.every((item) => item.id);
  return false;
};

export const convertResourceActionsToTreeData = (
  data: ResourceApiDataType[],
  index: number,
  arr: TreeDataType[],
  parentId?: number
): TreeDataType[] => {
  const current: ResourceApiDataType = data?.[index];
  if (!current) return arr;
  const children = current?.actions
    ? {
        children: convertResourceActionsToTreeData(
          current.actions,
          0,
          [],
          current.id
        ),
      }
    : {};

  const newArr: TreeDataType[] = [
    ...arr,
    {
      ...children,
      value: !current?.actions
        ? `actions-${parentId}-${current.id}`
        : `${current.id}`,
      title: current.name,
      selectable: true,
      isLeaf: !current?.actions,
    },
  ];

  return convertResourceActionsToTreeData(data, index + 1, newArr, parentId);
};

export type ResourceActionsApiType = {
  resource_id: number;
  action_ids: number[];
};

export const convertResourceAndActions = (
  resource_actions: { value: string }[]
): ResourceActionsApiType[] => {
  const resources: ResourceActionsApiType[] = resource_actions
    .filter((resource) => !resource.value.includes('actions-'))
    .map((resource) => ({
      action_ids: [],
      resource_id: parseInt(resource.value),
    }));
  const resource_id_set = new Set(
    resources.map((resource) => resource.resource_id)
  );
  resource_actions.forEach((action) => {
    const current = action.value;
    if (!current.includes('actions-')) return;
    const split = current.split('-');
    const curr_resource_id = split[1];
    const curr_actions_id = split[2];
    if (resource_id_set.has(parseInt(curr_resource_id))) {
      for (const resource of resources) {
        if (resource.resource_id === parseInt(curr_resource_id)) {
          resource.action_ids.push(parseInt(curr_actions_id));
        }
      }
    } else {
      resource_id_set.add(parseInt(curr_resource_id));
      resources.push({
        resource_id: parseInt(curr_resource_id),
        action_ids: [parseInt(curr_actions_id)],
      });
    }
  });
  return resources;
};
