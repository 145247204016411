import { Button, Table, Typography } from '@mezzanine-ui/react';
import {
  IQCOrderType,
  IncomingPurchaseOrder,
  NextPagination,
  getNextParams,
  useGetIQCDeliveryOrders,
  useGetIQCOrderStatuses,
} from '@solar/data';
import { useCallback, useRef } from 'react';
import {
  CopyTextWrapper,
  MznPaginationTable,
  useDraggableColumns,
} from '@solar/templates';
import { Space } from 'antd';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import { useForm } from 'react-hook-form';
import { IQCTableBaseProps } from './incoming-quality-control-table.type';
import { title } from 'process';

interface IQCDeliveryOrderTableProps
  extends IQCTableBaseProps<IncomingPurchaseOrder> {
  iqcOrderType: IQCOrderType.DELIVERY_ORDER;
  deliveryOrderId?: string;
}

export function IQCDeliveryOrderTable({
  deliveryOrderId,
  statusIds,
  getColumns,
  expandable,
}: IQCDeliveryOrderTableProps) {
  const methods = useForm();
  const {
    iqcDeliveryOrders,
    isLoading,
    pageInfo,
    refetchGetIQCDeliveryOrders,
  } = useGetIQCDeliveryOrders({ statusIds, deliveryOrderId });

  const { statuses } = useGetIQCOrderStatuses();

  const refetchGetIQCDeliveryOrdersWithPagiantion = useCallback(
    (nextPagination: NextPagination) => {
      const formState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      refetchGetIQCDeliveryOrders({
        offset,
        limit,
        statusIds,
        deliveryOrderId: deliveryOrderId ?? formState?.deliveryOrderId,
        materialId: formState?.materialId,
        sapBatchId: formState?.sapBatchId,
      });
    },
    [deliveryOrderId, methods, pageInfo, refetchGetIQCDeliveryOrders, statusIds]
  );

  const onSearch = () =>
    refetchGetIQCDeliveryOrdersWithPagiantion({ nextPage: 1 });

  const defaultColumns = [
    {
      width: 100,
      title: '#',
      dataIndex: 'id',
    },
    // {
    //   width: 150,
    //   dataIndex: 'deliveryOrderId',
    //   title: '出貨單號',
    // },
    // {
    //   width: 100,
    //   dataIndex: 'deliveryOrderItemId',
    //   title: '項次',
    // },
    // {
    //   width: 300,
    //   title: '料號',
    //   render: (source: any) => (
    //     <CopyTextWrapper text={source?.materialId ?? ''} />
    //   ),
    // },
    // {
    //   width: 150,
    //   dataIndex: 'sapBatchId',
    //   title: '批號',
    // },
    // {
    //   width: 500,
    //   title: '品名',
    //   render: (source: any) => (
    //     <CopyTextWrapper text={source?.materialDescription ?? ''} />
    //   ),
    // },
    {
      title: '狀態',
      render: ({ statusId }: { statusId: number }) =>
        statuses?.find((status) => status.id === statusId)?.name ?? '',
    },
    {
      width: 200,
      dataIndex: 'receivedQuantity',
      title: '數量',
    },
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <FormFieldsWrapper methods={methods}>
        <Space align="end">
          {!deliveryOrderId && (
            <InputField label="出貨單號" registerName="deliveryOrderId" />
          )}
          <InputField label="料號" registerName="materialId" />
          <InputField label="批號" registerName="sapBatchId" />
          <Button
            type="button"
            variant="outlined"
            loading={isLoading}
            onClick={onSearch}
          >
            搜尋
          </Button>
        </Space>
      </FormFieldsWrapper>
      <MznPaginationTable
        dataSource={iqcDeliveryOrders}
        pageInfo={pageInfo}
        loading={isLoading}
        fetchData={refetchGetIQCDeliveryOrdersWithPagiantion}
        columns={getColumns?.(defaultColumns) ?? defaultColumns}
        scroll={{ x: 1200 }}
        expandable={
          expandable ?? {
            rowExpandable: (source: IncomingPurchaseOrder) =>
              (source?.iqcOrderItems?.length ?? 0) > 0,
            expandedRowRender: (source: IncomingPurchaseOrder) => (
              <Space direction="vertical">
                <IQCOrderItemTable dataSource={source?.iqcOrderItems ?? []} />
                <Space style={{ padding: '0 12px' }}>
                  <Typography variant="caption">檢驗備註：</Typography>
                  <Typography variant="body1">
                    {source?.qcNote ?? '無'}
                  </Typography>
                </Space>
                <Space style={{ padding: '0 12px' }}>
                  <Typography variant="caption">倉管備註：</Typography>
                  <Typography variant="body1">
                    {source?.warehouseNote ?? '無'}
                  </Typography>
                </Space>
                <Space style={{ padding: '0 12px' }}>
                  <Typography variant="caption">需求單位備註：</Typography>
                  <Typography variant="body1">
                    {source?.demandedUnitNote ?? '無'}
                  </Typography>
                </Space>
              </Space>
            ),
          }
        }
      />
    </Space>
  );
}

function IQCOrderItemTable({ dataSource }: { dataSource: any[] }) {
  const tableRef = useRef<HTMLTableElement>(null);

  const columns = useDraggableColumns(tableRef, [
    {
      width: 200,
      title: '出貨單號',
      dataIndex: 'deliveryOrderId',
    },
    {
      width: 100,
      title: '項次',
      dataIndex: 'deliveryOrderItemId',
    },
    {
      width: 200,
      title: '料號',
      dataIndex: 'materialId',
    },
    {
      width: 150,
      title: '批號',
      dataIndex: 'sapBatchId',
    },
    {
      width: 500,
      title: '品名',
      dataIndex: 'material.description',
    },
    {
      width: 100,
      title: '數量',
      dataIndex: 'receivedQuantity',
    },
    {
      width: 100,
      render: (source: any) => {
        return source?.deliveryOrderDetail?.quantityConvertedUnitName ?? '';
      },
      title: '單位',
    },
  ]);

  return (
    <Table
      ref={tableRef}
      columns={columns}
      scroll={{ x: 1200 }}
      dataSource={dataSource}
    />
  );
}
