import { Message } from '@mezzanine-ui/react';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import useSWR from 'swr';
import { API_NAMESPACE, request } from '../../request';
import {
  HumanResourceDepartmentInformationApiResponse,
  HumanResourceDepartmentInformationData,
  humanResourceDepartmentInformationFilterFormDefaultValues,
  HumanResourceDepartmentInformationFilterFormField,
  HumanResourceDepartmentInformationLevelList,
  HumanResourceDepartmentInformationModalField,
  humanResourceDepartmentInformationSchemas,
  HumanResourceDepartmentInformationTableField,
} from './schemas';

const namespace = API_NAMESPACE.HR;

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
};

export function useHumanResourceDepartmentInformation() {
  const endpoint = '/dept/get';

  const [data, setData] = useState<
    HumanResourceDepartmentInformationApiResponse | undefined
  >(undefined);
  const [queryParams, setQueryParams] = useState<
    Partial<HumanResourceDepartmentInformationFilterFormField> &
      HumanResourceDepartmentInformationTableField
  >({ page: 1, perPage: 10 });

  const [modal, setModal] =
    useState<HumanResourceDepartmentInformationData | null>(null);

  const [levelListOptions, setLevelListOptions] = useState<
    HumanResourceDepartmentInformationLevelList[] | undefined
  >(undefined);

  const filterMethods =
    useForm<HumanResourceDepartmentInformationFilterFormField>({
      defaultValues: humanResourceDepartmentInformationFilterFormDefaultValues,
      mode: 'onChange',
    });

  const methods = useForm<HumanResourceDepartmentInformationModalField>({
    defaultValues: {
      [humanResourceDepartmentInformationSchemas.BU]: '',
      [humanResourceDepartmentInformationSchemas.LEVEL_ID]: undefined,
      [humanResourceDepartmentInformationSchemas.COST_CENTER_ID]: '',
    },
    mode: 'onChange',
  });
  const { reset: resetModalField, setValue } = methods;

  const { data: swrData, mutate } = useSWR([
    endpoint,
    { namespace, params: queryParams },
  ]);

  const { data: levelListSwrData } = useSWR(['/dept/get/levelList', { namespace }]);

  const handleSubmitFilter: SubmitHandler<
    HumanResourceDepartmentInformationFilterFormField
  > = (data) => {
    const levelId = data.levelId
      ? {
          [humanResourceDepartmentInformationSchemas.LEVEL_ID]: data.levelId.id,
        }
      : {};
    const queries = { ...queryParams, ...data, ...levelId };
    const filteredQueries: Partial<HumanResourceDepartmentInformationFilterFormField> =
      Object.fromEntries(
        Object.entries(queries).filter((query) => {
          /* state -> 有效：0、無效：1、全部：不送 */
          if (query[0] === humanResourceDepartmentInformationSchemas.STATE)
            return query[1] !== 'all';
          return query[1];
        })
      );
    setQueryParams((params) => ({
      ...filteredQueries,
      perPage: params['perPage'],
      page: 1,
    }));
  };

  const handleChangePage = (currentPage: number): void => {
    setQueryParams((params) => ({ ...params, page: currentPage }));
  };

  const handleChangePageSize = (pageSize: number): void => {
    setQueryParams((params) => ({ ...params, perPage: pageSize, page: 1 }));
  };

  const handleOpenModal = (source: HumanResourceDepartmentInformationData) => {
    const { bu, levelId, costCenterId } = source;
    const defaultLevel = levelId
      ? levelListOptions?.find((option) => `${option.id}` === `${levelId}`)
      : undefined;
    // NOTE: 把值放進 form 裡面
    setValue(humanResourceDepartmentInformationSchemas.BU, bu);
    setValue(humanResourceDepartmentInformationSchemas.LEVEL_ID, defaultLevel);
    setValue(humanResourceDepartmentInformationSchemas.COST_CENTER_ID, costCenterId);
    setModal(source);
  };

  const handleCloseModal = () => {
    setModal(null);
    resetModalField();
  };

  const handleSubmitModal: SubmitHandler<
    HumanResourceDepartmentInformationModalField
  > = async (data) => {
    try {
      const levelId = data.levelId?.id || '';
      const options = {
        method: 'PUT',
        headers,
        body: JSON.stringify({
          ...data,
          [humanResourceDepartmentInformationSchemas.LEVEL_ID]: levelId,
        }),
      };
      await request(`/dept/${modal?.id}`, { ...options, namespace });
      mutate();
      Message['success']('更新成功');
    } catch (error) {
      if (error instanceof Error) {
        Message['error'](JSON.parse(error.message).message);
      } else {
        Message['error']('失敗，請再試一次');
      }
    }
    handleCloseModal();
  };

  useEffect(() => {
    if (isHumanResourceDepartmentInformationApiResponse(swrData)) {
      setData(swrData);
    }
  }, [swrData]);

  useEffect(() => {
    if (isHumanResourceDepartmentInformationModalData(levelListSwrData)) {
      setLevelListOptions(levelListSwrData);
    }
  }, [levelListSwrData]);

  return {
    methods,
    filterMethods,
    data: data?.data ?? [],
    total: data?.total ?? 0,
    levelListOptions,
    modal,
    queryParams,
    setQueryParams,
    handleSubmitFilter,
    handleChangePage,
    handleChangePageSize,
    handleSubmitModal,
    handleOpenModal,
    handleCloseModal,
  };
}

function isHumanResourceDepartmentInformationApiResponse(
  obj: unknown
): obj is HumanResourceDepartmentInformationApiResponse {
  if (obj && typeof obj === 'object') return 'data' in obj;
  return false;
}

function isHumanResourceDepartmentInformationModalData(
  obj: unknown
): obj is HumanResourceDepartmentInformationLevelList[] {
  if (obj && Array.isArray(obj)) return obj.every((item) => 'id' in item);
  return false;
}
