import { Option, Select, SelectValue, Typography } from '@mezzanine-ui/react';
import { LocationLevel } from '@solar/data';
import { Col, ColProps, Row, RowProps } from 'antd';
import { useCallback } from 'react';
import { useLocationSelectorContext } from '../LocationSelectorContext/useLocationSelectorContext';

type LocationSelectorProps = {
  rowProps?: RowProps;
  colProps?: ColProps;
};

export function LocationSelector({
  rowProps,
  colProps,
}: LocationSelectorProps) {
  const span = colProps?.span ?? 6;

  const { currentLocation, locationOptions, handleNextLevel } =
    useLocationSelectorContext();

  const onSelect = useCallback(
    (level: LocationLevel) => (option: SelectValue) => {
      // console.log('action: select');
      handleNextLevel(option?.id, level);
    },
    [handleNextLevel]
  );

  const renderOptions = (options: SelectValue[]) =>
    options?.map((option) => (
      <Option key={option?.id} value={option?.id}>
        {option?.name}
      </Option>
    ));

  return (
    <Row gutter={rowProps?.gutter ?? [8, 8]}>
      <Col span={span}>
        <Typography variant="h6" color="secondary">
          廠區
        </Typography>
        <Select
          disablePortal
          fullWidth
          placeholder="尚未選取"
          value={currentLocation?.factory ?? null}
          onChange={onSelect(LocationLevel.FACTORY)}
        >
          {renderOptions(locationOptions?.factory ?? [])}
        </Select>
      </Col>
      {currentLocation?.factory && (
        <Col span={span}>
          <Typography variant="h6" color="secondary">
            棟
          </Typography>
          <Select
            disablePortal
            fullWidth
            placeholder="尚未選取"
            value={currentLocation?.building ?? null}
            onChange={onSelect(LocationLevel.BUILDING)}
          >
            {renderOptions(locationOptions?.building ?? [])}
          </Select>
        </Col>
      )}
      {currentLocation?.building && (
        <Col span={span}>
          <Typography variant="h6" color="secondary">
            樓
          </Typography>
          <Select
            disablePortal
            fullWidth
            placeholder="尚未選取"
            value={currentLocation?.floor ?? null}
            onChange={onSelect(LocationLevel.FLOOR)}
          >
            {renderOptions(locationOptions?.floor ?? [])}
          </Select>
        </Col>
      )}
      {currentLocation?.floor && (
        <Col span={span}>
          <Typography variant="h6" color="secondary">
            大區
          </Typography>
          <Select
            disablePortal
            fullWidth
            placeholder="尚未選取"
            value={currentLocation?.area ?? null}
            onChange={onSelect(LocationLevel.AREA)}
          >
            {renderOptions(locationOptions?.area ?? [])}
          </Select>
        </Col>
      )}
      {currentLocation?.area && (
        <Col span={span}>
          <Typography variant="h6" color="secondary">
            小區
          </Typography>
          <Select
            disablePortal
            fullWidth
            placeholder="尚未選取"
            value={currentLocation?.zone ?? null}
            onChange={onSelect(LocationLevel.ZONE)}
          >
            {renderOptions(locationOptions?.zone ?? [])}
          </Select>
        </Col>
      )}
      {currentLocation?.zone && (
        <Col span={span}>
          <Typography variant="h6" color="secondary">
            儲位
          </Typography>
          <Select
            disablePortal
            fullWidth
            placeholder="尚未選取"
            value={currentLocation?.stack ?? null}
            onChange={onSelect(LocationLevel.STACK)}
          >
            {renderOptions(locationOptions?.stack ?? [])}
          </Select>
        </Col>
      )}
      {currentLocation?.stack && (
        <Col span={span}>
          <Typography variant="h6" color="secondary">
            層架
          </Typography>
          <Select
            disablePortal
            fullWidth
            placeholder="尚未選取"
            value={currentLocation?.shelf ?? null}
            onChange={onSelect(LocationLevel.SHELF)}
          >
            {renderOptions(locationOptions?.shelf ?? [])}
          </Select>
        </Col>
      )}
    </Row>
  );
}
