import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import {
  authDeleteFetcher,
  authGetFetcher,
  authPostFetcher,
  authPutFetcher,
} from './fetch';
import {
  areApiData,
  combineTreeData,
  convertTenantAndRole,
  convertToTreeData,
} from './functions';

export type ModalType = 'WarningModal' | 'CreateEditModal' | null;

type Member = {
  member_name: string;
  member_position_name: string;
  member_unit_name: string;
  worker_number: string;
};

export type CreateEditResponse = {
  status: 'success' | number;
  detail: string;
  title: string;
};

export function useTenantList() {
  const methods = useForm();
  const filterMethods = useForm();

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [openModal, setOpenModal] = useState<ModalType>(null);
  const [modalSource, setModalSource] = useState<any | null>(null);
  const [modalTitle, setModalTitle] = useState('');
  const [filterDataPayload, setFilterDataPayload] = useState({});

  const { data: tenantsData } = useSWR(`/tenants`, authGetFetcher);
  const { data: roleData } = useSWR('/query_roles', () =>
    authPostFetcher('/query_roles', {})
  );
  const { data: membersData } = useSWR('/members', authGetFetcher);
  const { data: myaccountData } = useSWR('/users/me', authGetFetcher);
  const myAccountId = useMemo(
    () => myaccountData?.me?.id ?? null,
    [myaccountData]
  );

  const members: Member[] = useMemo(
    () => membersData?.members ?? [],
    [membersData]
  );

  const formatMembers = useMemo(
    () =>
      members.map((member) => ({
        id: member.worker_number,
        name: `${member.worker_number} ${member.member_name}${
          member.member_unit_name && member.member_unit_name !== '' && '（'
        }${member.member_unit_name}${
          member.member_unit_name && member.member_unit_name !== '' && '）'
        }`,
      })) ?? [],
    [members]
  );

  const tenantsObj: Record<string, string> = useMemo(() => {
    const tenants: { id: number; name: string; has_roles: boolean }[] =
      tenantsData?.tenants ?? [];
    return tenants.reduce((acc, cur) => ({ ...acc, [cur.id]: cur.name }), {});
  }, [tenantsData]);

  const roleWithResourceActions = useMemo(() => {
    const result: any[] = [];
    roleData?.tenant_with_role_resource_actions?.forEach(
      (resourceActions: any) => {
        resourceActions.role_with_resource_actions.forEach((resource: any) => {
          result.push(resource);
        });
      }
    );
    return result;
  }, [roleData]);

  const onChangeCurrentPage = useCallback(
    (page: number) => setCurrentPage(page),
    [setCurrentPage]
  );

  const onChangePageSize = useCallback(
    (perPage: number) => setPageSize(perPage),
    [setPageSize]
  );

  const onSearchTenantUsers = useCallback(async () => {
    const formValues = filterMethods.control._formValues;
    const { policies, ...rest } = formValues;
    const tenants = convertTenantAndRole(policies);

    const payload = {
      ...rest,
      tenant_id_with_roles: tenants,
    };
    setFilterDataPayload(payload);
    const result = await authPostFetcher('/query_users', payload);
    if (result?.users) {
      const data = result.users.map((user: any) => {
        const roles: { id: number; tenant_id: number; name: string }[] =
          user.roles ?? [];
        const tenant_set = new Set(roles.map((role) => role.tenant_id));
        const tenants: string[] = [];
        if (tenant_set.size !== 0) {
          tenant_set.forEach((tenant) => {
            tenants.push(tenantsObj[tenant]);
          });
        }
        return { ...user, tenants: tenants.join(', ') };
      });

      setDataSource(data);
    }
  }, [
    filterMethods,
    setFilterDataPayload,
    authPostFetcher,
    setDataSource,
    tenantsObj,
  ]);

  const onCloseModal = useCallback(() => {
    methods.reset();
    setOpenModal(() => null);
    setModalSource(() => null);
  }, [setOpenModal, setModalSource, methods]);

  const onOpenWarningModal = useCallback(
    (source: any, isDelete?: boolean) => {
      setOpenModal('WarningModal');
      setModalSource({ ...source, isDelete: isDelete ?? false });
    },
    [setOpenModal, setModalSource]
  );

  const onOpenCreateEditModal = useCallback(
    (source: any, title: string) => {
      setOpenModal('CreateEditModal');
      setModalSource(source);
      setModalTitle(title);
      const roles: { id: number; tenant_id: number; name: string }[] =
        source?.roles ?? [];
      const convertRoles = roles.map((role) => ({
        value: `role-${role.tenant_id}-${role.id}`,
        label: role.name,
      }));
      methods.setValue('module', convertRoles);
    },
    [setOpenModal, setModalSource, setModalTitle, convertTenantAndRole]
  );

  const confirmDeleteModal = useCallback(async () => {
    const userId = modalSource?.id;
    const deleteResult = await authDeleteFetcher(
      `/users?user_ids=${userId}`,
      {}
    );
    const result = await authPostFetcher('/query_users', filterDataPayload);
    if (result?.users) {
      setDataSource(result.users);
    }
    onCloseModal();
    return deleteResult;
  }, [
    modalSource,
    authDeleteFetcher,
    authPostFetcher,
    filterDataPayload,
    onCloseModal,
  ]);

  const confirmStatusModal = useCallback(async () => {
    const userId = modalSource?.id;
    const status = modalSource?.status === 1 ? 2 : 1;
    const statusResult = await authPutFetcher(`/users/${userId}/status`, {
      status,
    });
    const result = await authPostFetcher('/query_users', filterDataPayload);
    if (result?.users) {
      setDataSource(result.users);
    }
    onCloseModal();
    return statusResult;
  }, [modalSource, authPutFetcher, filterDataPayload, onCloseModal]);

  const createEditModal = useCallback(async () => {
    const userId = modalSource?.id;
    const form = methods.control._formValues;
    const { module: moduleForm, worker_number, ...rest } = form;
    const tenants_role = convertTenantAndRole(moduleForm);
    const payload = {
      ...rest,
      tenant_id_with_roles: tenants_role,
      worker_number: worker_number?.id ?? null,
    };
    let response: CreateEditResponse | null = null;
    if (userId) {
      response = await authPutFetcher(`/users/${userId}`, payload);
    } else {
      response = await authPostFetcher('/users', payload);
    }
    methods.reset();
    const result = await authPostFetcher('/query_users', filterDataPayload);
    if (result?.users) {
      setDataSource(result.users);
    }
    onCloseModal();
    return response;
  }, [
    methods,
    modalSource,
    convertTenantAndRole,
    authPostFetcher,
    filterDataPayload,
    setDataSource,
  ]);

  const tenantsTreeDataWithoutRole = useMemo(() => {
    if (!tenantsData?.tenants || !areApiData(tenantsData.tenants)) return [];
    return convertToTreeData(tenantsData.tenants, 0, []);
  }, [tenantsData, areApiData, convertToTreeData]);

  const tenantsTreeData = useMemo(() => {
    if (!tenantsData?.tenants || !areApiData(tenantsData.tenants)) return [];
    const result = convertToTreeData(tenantsData.tenants, 0, []);
    if (!areApiData(roleWithResourceActions)) return result;

    const convertRoleData = convertToTreeData(
      roleWithResourceActions,
      0,
      [],
      true
    );
    return combineTreeData(result, convertRoleData);
  }, [tenantsData, roleWithResourceActions, areApiData, convertToTreeData]);

  return {
    myAccountId,
    dataSource,
    tenantsTreeDataWithoutRole,
    tenantsObj,
    formatMembers,
    data: tenantsTreeData ?? [],
    methods,
    filterMethods,
    currentPage,
    onChangeCurrentPage,
    pageSize,
    onChangePageSize,
    onSearchTenantUsers,
    openModal,
    modalSource,
    modalTitle,
    onOpenWarningModal,
    onCloseModal,
    onOpenCreateEditModal,
    confirmStatusModal,
    confirmDeleteModal,
    createEditModal,
  };
}
