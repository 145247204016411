import { SelectValue } from '@mezzanine-ui/react';
import useSWR from 'swr';

export function useApplyUnit() {
  const { data: applyUnitOptions } = useSWR<SelectValue[]>(
    [
      '/logistics/apply-units',
      {
        namespace: '/sd/sd',
      },
    ],
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    }
  );

  return { applyUnitOptions };
}
