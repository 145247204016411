import { Button, Typography } from '@mezzanine-ui/react';
import { InputField } from '@mezzanine-ui/react-hook-form';
import {
  MaterialAllocationFormValues,
  useGetWorkOrderDetailById,
} from '@solar/data';
import { Col, Descriptions, Row, Space, Table } from 'antd';
import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

export function DefaultAllocationsTable({
  calculating,
  calculatedResult,
  calculateAllocationResult,
}: {
  calculating: boolean;
  calculatedResult: any;
  calculateAllocationResult: () => void;
}) {
  const [searchParams] = useSearchParams();
  const searchParamOfWorkOrderId = searchParams.get('workOrderId');

  const { workOrder } = useGetWorkOrderDetailById(
    searchParamOfWorkOrderId ?? undefined
  );
  const mainProduct = workOrder?.expectedMainProduct?.[0];
  const mainProductUnit = mainProduct?.materialStockUnit?.toUpperCase() ?? '';

  const methods = useFormContext<MaterialAllocationFormValues>();
  const manualData = methods.watch('manualData');

  const materialQuantities = methods.watch(
    manualData?.map(
      (_item, index) => `manualData.${index}.usedMaterialUnitQuantity` as const
    ) ?? []
  );

  const totalIssuedQuality = useMemo(() => {
    const manualData = methods.getValues('manualData');
    const gQuantity =
      manualData?.reduce((result, item, index) => {
        if (Number.isNaN(Number(materialQuantities?.[index] || undefined))) {
          return 0;
        }
        const currentQuantityDeciaml = new Decimal(
          materialQuantities?.[index] ?? 0
        );
        const currentQuantity =
          item.materialStockUnit === 'G'
            ? currentQuantityDeciaml
            : currentQuantityDeciaml.mul(1000);
        return currentQuantity.add(result).toNumber();
      }, 0) ?? 0;
    return mainProductUnit === 'G'
      ? gQuantity
      : new Decimal(gQuantity).div(1000).toNumber();
  }, [methods, mainProductUnit, materialQuantities]);

  return (
    <>
      <Row style={{ margin: '12px 0' }}>
        <Col span={24}>
          <Typography variant="h4">手動配料結果</Typography>
        </Col>
        <Col span={24}>
          <Descriptions
            bordered
            column={4}
            layout="horizontal"
            labelStyle={{
              width: '120px',
              color: 'var(--mzn-color-action-inactive)',
            }}
            contentStyle={{
              minWidth: '200px',
            }}
          >
            <Descriptions.Item label="目標重量" span={4}>
              <Space>
                <InputField
                  required
                  type="number"
                  registerName="targetWeight"
                  suffix={`(${mainProductUnit})`}
                />
                <Button
                  variant="contained"
                  loading={calculating}
                  onClick={() => calculateAllocationResult()}
                >
                  計算
                </Button>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label="投料總量" span={4}>
              {totalIssuedQuality}({mainProductUnit ?? ''})
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Table
        loading={calculating}
        rowKey="element_name"
        dataSource={calculatedResult ?? []}
        scroll={{ x: 1000 }}
        pagination={false}
        columns={[
          {
            title: 'Component',
            dataIndex: 'elementName',
          },
          {
            title: '原子量',
            dataIndex: 'atomicWeight',
          },
          {
            title: 'wt%',
            dataIndex: 'standardWtPercent',
          },
          {
            title: 'at%',
            dataIndex: 'standardAtPercent',
          },
          {
            title: '調整順序',
            dataIndex: '',
          },
          {
            title: '調整後wt%',
            dataIndex: 'adjustedWtPercent',
          },
          {
            title: '調整後at%',
            dataIndex: 'adjustedAtPercent',
          },
          {
            title: '實際配料%',
            dataIndex: '',
          },
          {
            title: 'Batch Required',
            dataIndex: 'batchRequired',
          },
          {
            title: 'Actual Required',
            dataIndex: 'actualRequired',
          },
          {
            title: '熔煉調整',
            dataIndex: 'meltAdjust',
          },
          {
            width: 110,
            title: '實際值補償差異調整',
            dataIndex: 'gapAdjust',
          },
          {
            title: 'Difference',
            dataIndex: 'difference',
          },
          {
            title: 'Batch Allocated',
            dataIndex: 'batchAllocated',
          },
          {
            title: 'Issue Qty',
            dataIndex: 'issuedQuality',
          },
        ]}
      />
    </>
  );
}
