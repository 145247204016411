import useSWR from 'swr';
import { request } from '../request';
import { Equipment } from './equipment.type';

export function getEquipmentByChartId({ chartId }: { chartId: string }) {
  return request(`/equipments/by-chart-id/${chartId}`, {
    method: 'get',
    responseParser: (res) => res,
  }).then((res) => res.json());
}

export function bindEquipmentStackId({
  equipmentId,
  stackId,
}: {
  equipmentId: Equipment['id'];
  stackId: string;
}) {
  return request(`/equipments/${equipmentId}/stack`, {
    method: 'put',
    body: JSON.stringify({ stackId }),
    responseParser: (res) => res,
  });
}

export function useEquipment(id?: string) {
  const { data, error } = useSWR<Equipment>(id ? [`/equipments/${id}`] : null);

  return {
    equipment: data,
    isLoading: !error && !data,
  };
}
