import type { ProductionPlanningOrdersData } from '@solar/data';
import {
  FormFieldsWrapper,
  DatePickerField,
} from '@mezzanine-ui/react-hook-form';
import { checkJWTRoles, usePlannedDeliveryDateInlineForm } from '@solar/data';
import { Message, Button } from '@mezzanine-ui/react';

type Props = {
  source: ProductionPlanningOrdersData;
  refetchProductionPlanningOrders: () => void;
};

export function PlannedDeliveryDateInlineForm({
  source,
  refetchProductionPlanningOrders,
}: Props) {
  const { formMethods, formSubmitCreator, FormFieldName } =
    usePlannedDeliveryDateInlineForm({
      dataSource: source,
    });

  return (
    <FormFieldsWrapper
      methods={formMethods}
      onSubmit={formSubmitCreator({
        successCallback: () => {
          Message.success('更新成功');
          refetchProductionPlanningOrders();
        },
        errorCallback: (error) => {
          try {
            if (!error?.message) return;
            const { message } = JSON.parse(error.message) ?? {};
            Message.error(`更新失敗${message ? '：' + message : ''}`);
          } catch (error) {
            Message.error('更新失敗');
          }
        },
      })}
      style={{ display: 'flex', gap: '1rem' }}
    >
      <DatePickerField
        control={formMethods.control}
        registerName={FormFieldName.PLANNED_DELIVERY_DATE}
        errorMsgRender={(error) => null}
      />
      {checkJWTRoles([114]) && (
        <Button type="submit" variant="contained">
          更新
        </Button>
      )}
    </FormFieldsWrapper>
  );
}
