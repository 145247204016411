import useSWR from 'swr';
import { API_NAMESPACE, request } from '../request';
import { useEffect, useMemo, useState } from 'react';
import {
  AutoDispatcherPayload,
  CreateTargetCodeRulePayload,
  CustomerFromAPI,
  DisableTargetCodeRulePayload,
  DispatchedTargetNumberRuleFilter,
  DispatcherTargetNumberRule,
  ManualDispatcherPayload,
  MaterialInternalTargetRuleCheckerPayload,
  TargetNumberRulesParams,
  UpdateNumberHistoryPayload,
  UpdateTargetCodeRulePayload,
  UpdateTargetRuleCustomerPayload,
  UpdateTargetRuleMaterialPayload,
  UseGetCustomerMaterialIdForInternalRuleParams,
  UseGetCustomerMaterialIdParams,
} from './target-code.interface';

const namespace = API_NAMESPACE.HR;

export function useGetTargetCodeRules({
  type,
  include_deleted,
}: {
  type: 'internal' | 'customer';
  include_deleted?: number;
}) {
  const params = useMemo(() => ({ type, ...(include_deleted ? { include_deleted } : {}) }), [type, include_deleted]);

  const { data, error} = useSWR(
    params ? ['/target-code/code-rules', { namespace, params }] : null
  );

  return {
    ruleTypeOptions: data?.data,
    isLoading: !data,
    error,
  };
}

export function useGetTargetNumberRules(filterParams: TargetNumberRulesParams) {
  const [params, setParams] = useState<TargetNumberRulesParams | undefined>();

  useEffect(() => {
    setParams(filterParams);
  }, [filterParams]);

  const { data, error, mutate } = useSWR(
    params ? ['/target-code/number-rules/get', { namespace, params }] : null
  );

  return {
    numberRulesListing: data?.data,
    page_at: data?.page_at,
    pageSize: data?.page_size,
    total: data?.total,
    lastPage: data?.last_page,
    isLoading: !data,
    error,
    refetchGetTargetNumberRules: (newParams?: TargetNumberRulesParams) => {
      if (newParams) {
        setParams(newParams);
      }
      mutate();
    },
  };
}

export function useGetTargetRuleCustomers(query: string) {
  const { data, error } = useSWR(
    query
      ? [`/target-code/options/customers?q=${query}`, { namespace }]
      : [`/target-code/options/customers`, { namespace }]
  );

  const mappedData = data?.data.map((customer: CustomerFromAPI) => ({
    id: customer.customer_id,
    name: `${customer.customer_name} - ${customer.customer_short_name} (${customer.customer_id})`,
  }));

  return {
    targetRuleCustomers: mappedData,
    isLoading: !data,
    error,
  };
}

export function useGetTargetRuleCustomersForSearch(query: string) {
  const { data, error } = useSWR(
    query
      ? [`/target-code/options/customers?q=${query}`, { namespace }]
      : [`/target-code/options/customers`, { namespace }]
  );

  const mappedNameAndIdData = data?.data.map((customer: CustomerFromAPI) => ({
    id: customer.customer_id,
    name: `${customer.customer_name} - ${customer.customer_short_name}`,
  }));

  return {
    targetRuleCustomers: mappedNameAndIdData,
    isLoading: !data,
    error,
  };
}

export function useGetCustomerMaterialId({
  customerIds,
  query,
  pageAt,
  pageSize,
}: UseGetCustomerMaterialIdParams) {
  let url = `/target-code/options/materials?customer_ids=${customerIds}&page_at=${pageAt}&page_size=${pageSize}`;

  if (query) {
    url += `&q=${encodeURIComponent(query)}`;
  }

  const { data, error} = useSWR(
    customerIds ? [url, { namespace }] : null
  );

  return {
    customerMaterialId: data?.data,
    isLoading: !!customerIds && !data && !error,
    page_at: data?.page_at,
    pageSize: data?.page_size,
    total: data?.total,
    lastPage: data?.last_page,
    error,
  };
}

export function useGetMaterialIdInternalRule({
  query,
  pageAt,
  pageSize,
}: UseGetCustomerMaterialIdForInternalRuleParams) {
  let url = `/target-code/options/materials?type=internal&page_at=${pageAt}&page_size=${pageSize}`;

  if (query) {
    url += `&q=${encodeURIComponent(query)}`;
  }

  const { data, error} = useSWR(
    [url, { namespace }]
  );

  return {
    materialIdForInternalRule: data?.data,
    isLoadingForInternalRule: !data && !error,
    page_atForInternalRule: data?.page_at,
    pageSizeForInternalRule: data?.page_size,
    totalForInternalRule: data?.total,
    lastPageForInternalRule: data?.last_page,
    error,
  };
}

export function useGetInternalTargetNumberRuleByCustomerId(
  customerIds: string
) {
  const { data, error } = useSWR(
    customerIds
      ? [
          `/target-code/options/number-rules?customer_ids=${customerIds}`,
          { namespace },
        ]
      : null
  );

  return {
    internalTargetNumberRule: data?.data,
    isLoading: !data,
    error,
  };
}

export function useGetTargetNumberRuleById(id: string) {
  const { data, error} = useSWR(
    id ? [`/target-code/number-rules/get/${id}`, { namespace }] : null
  );

  return {
    targetNumberRule: data?.data,
    isLoading: !data,
    error,
  };
}

export function createTargetCodeRule({ body }: CreateTargetCodeRulePayload) {
  return request('/target-code/number-rules', {
    method: 'POST',
    body: JSON.stringify(body),
    namespace,
  });
}

export function updateTargetCodeRule({
  id,
  body,
}: UpdateTargetCodeRulePayload) {

  console.log('id:', id);
  console.log('body:', body);
  return request(`/target-code/number-rules/${id}`, {
    method: 'PUT',
    body: JSON.stringify(body),
    namespace,
  });
}

export function updateTargetRuleCustomer({
  id,
  body,
}: UpdateTargetRuleCustomerPayload) {
  return request(`/target-code/number-rules/${id}/customers`, {
    method: 'POST',
    body: JSON.stringify(body),
    namespace,
  });
}

export function updateTargetRuleMaterial({
  id,
  body,
}: UpdateTargetRuleMaterialPayload) {
  return request(`/target-code/number-rules/${id}/materials`, {
    method: 'POST',
    body: JSON.stringify(body),
    namespace,
  });
}

export function disableTargetCodeRule({
  id,
  body,
}: {
  id: string | undefined;
  body: DisableTargetCodeRulePayload;
}) {
  return request(`/target-code/number-rules/${id}`, {
    method: 'PUT',
    body: JSON.stringify(body),
    namespace,
  });
}

export function useGetDispatcherNumberRules(filterParams: DispatcherTargetNumberRule, refreshTrigger: boolean) {
  const [params, setParams] = useState<DispatcherTargetNumberRule | undefined>();

  useEffect(() => {
    if (JSON.stringify(params) !== JSON.stringify(filterParams)) {
      setParams(filterParams);
    }
  }, [filterParams, params]);

  const { data, error, mutate } = useSWR(
    params ? ['/target-code/dispatch-number/get', { namespace, params, refreshTrigger }] : null
  );

  return {
    dispatcherNumberRules: data?.data,
    page_at: data?.page_at,
    pageSize: data?.page_size,
    total: data?.total,
    lastPage: data?.last_page,
    isLoading: !data,
    error,
    refetchDispatcherNumberRules: (newParams?: DispatcherTargetNumberRule) => {
      if (newParams) {
        setParams(newParams);
      }
      mutate();
    },
  };
}

export function createManualTargetNumberDispatch(body: ManualDispatcherPayload) {
  return request('/target-code/dispatch-number/manual', {
    method: 'POST',
    body: JSON.stringify(body),
    namespace,
  });
}

export function createAutoTargetNumberDispatch(body: AutoDispatcherPayload) {
  return request('/target-code/dispatch-number/auto', {
    method: 'POST',
    body: JSON.stringify(body),
    namespace,
  });
}

export function useGetCustomerTargetNumberRuleForAutoDispatcher(
  customerIds: string | undefined,
  materialIds: string | undefined
) {
  const { data, error } = useSWR(
    customerIds
      ? [
          `/target-code/options/number-rules?type=customer&customer_ids=${customerIds}&material_ids=${materialIds}`,
          { namespace },
        ]
      : null
  );

  return {
    customerTargetNumberRule: data?.data,
    isLoading: !data,
    error,
  };
}

export function useGetCustomerNumberRuleForDispatchedFilter() {
  const { data, error } = useSWR(
    [`/target-code/options/number-rules?type=customer`, { namespace }]
  );

  return {
    customerNumberRule: data?.data,
    isLoading: !data,
    error,
  };
}

export function useGetDispatchedTargetNumberRule(filterParams: DispatchedTargetNumberRuleFilter, refreshTrigger: boolean) {
  const [params, setParams] = useState<DispatcherTargetNumberRule | undefined>();

  useEffect(() => {
    if (JSON.stringify(params) !== JSON.stringify(filterParams)) {
      setParams(filterParams);
    }
  }, [filterParams, params]);

  const { data, error, mutate } = useSWR(
    params ? ['/target-code/number-history/get', { namespace, params, refreshTrigger }] : null
  );

  return {
    dispatchedNumberRules: data?.data,
    page_at: data?.page_at,
    pageSize: data?.page_size,
    total: data?.total,
    lastPage: data?.last_page,
    isLoading: !data,
    error,
    refetchDispatchedNumberRules: (newParams?: DispatchedTargetNumberRuleFilter) => {
      if (newParams) {
        setParams(newParams);
      }
      mutate();
    },
  };
}

export function updateNumberHistoryById(id: number, body: UpdateNumberHistoryPayload) {
  return request(`/target-code/number-history/${id}`, {
    method: 'PUT',
    body: JSON.stringify(body),
    namespace,
  });
}

export function useInternalTargetRuleChecker(body: MaterialInternalTargetRuleCheckerPayload) {
  return request('/target-code/dispatch-number/check-enabled', {
    method: 'POST',
    body: JSON.stringify(body),
    namespace,
  });
}
