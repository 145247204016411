import {
  AutoCompleteField,
  FormFieldsDebug,
  FormFieldsWrapper,
  InputField,
} from '@mezzanine-ui/react-hook-form';
import { PageLayout } from '@solar/templates';
import { useForm } from 'react-hook-form';
import { LocationAutoCompleteField } from '../../Templates/LocationAndLoaderPicker/LocationAutoCompleteField';
import {
  Button,
  Message,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SelectValue,
  Table,
  Typography,
} from '@mezzanine-ui/react';
import { useCallback, useMemo, useState } from 'react';
import { InventoryCountDocField } from './InventoryCountDocField';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useSWR from 'swr';
import {
  API_NAMESPACE,
  getNextParams,
  NextPagination,
  PageInfo,
  request,
} from '@solar/data';
import { InventoryTypeField } from './InventoryTypeField';
import { useInventoryCountColumns } from './use-inventory-count-columns';
import { TableColumn } from '@mezzanine-ui/core/table';
import { MznPaginationTable } from '@solar/templates';

interface FormValues {
  inventoryTakingId: SelectValue;
  locationId: string;
  inventoryType: SelectValue;
  materialId: string;
  materialSapBatchId: string;
  barcode: string;
  customerId: string;
  supplierId: string;
  salesOrderId: string;
}

interface ModalFormValues {
  id: string;
  type: string;
  materialId: string;
  materialSapBatchId: string;
  materialSubBatchId: string;
  shelfId: string;
  unit: string;
  quantity: string;
  actualWeight: string;
}

const modalSchema = yup.object().shape({
  id: yup.string().required('必填'),
  type: yup.string(),
  materialId: yup.string().required('必填'),
  materialSapBatchId: yup.string().required('必填'),
  materialSubBatchId: yup.string().required('必填'),
  shelfId: yup.string().required('必填'),
  unit: yup.string(),
  quantity: yup.string().required('必填'),
  actualWeight: yup.string().required('必填'),
});

const schema = yup.object().shape({
  inventoryTakingId: yup
    .object()
    .shape({
      id: yup.string().required('必填'),
      name: yup.string().required('必填'),
    })
    .required('必填'),
  locationId: yup.string(),
  inventoryType: yup.object().shape({
    id: yup.string(),
    name: yup.string(),
  }),
  materialId: yup.string(),
  materialSapBatchId: yup.string(),
  barcode: yup.string(),
  customerId: yup.string(),
  supplierId: yup.string(),
  salesOrderId: yup.string(),
});

export interface GetResultsParams {
  inventoryTakingId: string;
  locationId?: string;
  type?: string;
  materialId?: string;
  materialSapBatchId?: string;
  barcode?: string;
  customerId?: string;
  supplierId?: string;
  salesOrderId?: string;
  offset?: number;
  limit?: number;
}

export function InventoryCountReports() {
  const methods = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const modalMethods = useForm<ModalFormValues>({
    resolver: yupResolver(modalSchema),
  });

  const [params, setParams] = useState<GetResultsParams | undefined>();
  const { data, error, mutate, isValidating } = useSWR<
    PageInfo & {
      results: { id: string; name: string }[];
    }
  >(
    params && params?.inventoryTakingId
      ? [
          `/warehouses/takings/${params.inventoryTakingId}/results`,
          {
            namespace: API_NAMESPACE.MATERIALS,
            params,
          },
        ]
      : null
  );
  const searching = !data && !error && isValidating;
  const pageInfo: PageInfo = useMemo(() => {
    return {
      limit: data?.limit ?? 10,
      offset: data?.offset ?? 0,
      totalCount: data?.totalCount ?? 0,
    };
  }, [data]);

  const { handleSubmit } = methods;

  const onSearch = useCallback(
    async (nextPagination?: NextPagination) => {
      const { offset, limit } = getNextParams(
        nextPagination ?? { nextPage: 1 },
        pageInfo
      );
      handleSubmit((data) => {
        setParams({
          inventoryTakingId: data.inventoryTakingId.id,
          locationId: data.locationId,
          type: data.inventoryType?.id,
          materialId: data.materialId,
          materialSapBatchId: data.materialSapBatchId,
          barcode: data.barcode,
          customerId: data.customerId,
          supplierId: data.supplierId,
          salesOrderId: data.salesOrderId,
          offset,
          limit,
        });
      })();
    },
    [pageInfo]
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const openEditModal = useCallback((data: any) => {
    setModalData(data);
    setModalOpen(true);
    const inventoryTakingId = methods.getValues()?.inventoryTakingId?.id;
    modalMethods.reset({
      id: inventoryTakingId,
      type: data?.type,
      materialId: data?.materialId,
      materialSapBatchId: data?.materialSapBatchId,
      materialSubBatchId: data?.materialSubBatchId,
      shelfId: data?.shelfId,
      unit: !isNaN(data?.secondaryUnitRatio ?? NaN)
        ? data?.secondaryUnit
        : data?.materialStockUnit,
      quantity: data?.quantity,
      actualWeight: data?.actualWeight,
    });
  }, []);
  const closeEditModal = useCallback(() => {
    setModalOpen(false);
    setModalData(undefined);
    modalMethods.reset({});
  }, []);

  const [updating, setUpdating] = useState(false);
  const updateInventoryTaking = useCallback(() => {
    const formValues = methods.getValues();
    if (!formValues?.inventoryTakingId?.id) {
      Message.error('缺少盤點編號');
      return;
    }
    modalMethods.handleSubmit(async (data) => {
      try {
        setUpdating(true);

        await request(
          `/warehouses/takings/${formValues?.inventoryTakingId?.id}/results`,
          {
            namespace: API_NAMESPACE.MATERIALS,
            method: 'PUT',
            body: JSON.stringify(data),
            responseParser: (res) => res,
          }
        );

        Message.success('更新成功');
        await mutate(undefined, true);
        closeEditModal();
      } catch (error) {
        Message.error('更新失敗');
      } finally {
        setUpdating(false);
      }
    })();
  }, []);

  const [confirming, setConfirming] = useState(false);
  const confirmInventoryTaking = useCallback(() => {
    const formValues = methods.getValues();
    if (!formValues?.inventoryTakingId?.id) {
      Message.error('缺少盤點編號');
      return;
    }

    try {
      setConfirming(true);
      request(
        `/warehouses/takings/${formValues?.inventoryTakingId?.id}/refresh-snapshots`,
        {
          namespace: API_NAMESPACE.MATERIALS,
          method: 'PUT',
          body: JSON.stringify({
            id: formValues?.inventoryTakingId?.id,
          }),
          responseParser: (res) => res,
        }
      );

      Message.success('確認成功');
      mutate(undefined, true);
    } catch (error) {
      Message.error('確認失敗');
    } finally {
      setConfirming(false);
    }
  }, []);

  const columns = useInventoryCountColumns({
    inventoryTakingData: data?.results ?? [],
    editable: true,
    openEditModal,
    confirming,
    confirmInventoryTaking,
  });

  return (
    <PageLayout title="盤點結果回報">
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto 200px',
          gap: 'var(--mzn-spacing-4)',
        }}
      >
        <FormFieldsWrapper
          methods={methods}
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 'var(--mzn-spacing-4)',
          }}
        >
          <InventoryCountDocField />
          <LocationAutoCompleteField
            registerName="locationId"
            label="倉庫範圍"
          />
          <InventoryTypeField />
          <InputField label="料號" registerName="materialId" clearable />
          <InputField
            label="SAP批號"
            registerName="materialSapBatchId"
            clearable
          />
          <InputField label="庫存標籤" registerName="barcode" clearable />
          <InputField label="客戶代碼" registerName="customerId" clearable />
          <InputField label="供應商代碼" registerName="supplierId" clearable />
          <InputField label="銷售訂單" registerName="salesOrderId" clearable />
        </FormFieldsWrapper>
        <div
          style={{
            width: 200,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            type="button"
            variant="outlined"
            loading={searching}
            onClick={() => onSearch()}
          >
            搜尋
          </Button>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: 'var(--mzn-spacing-4)',
        }}
      ></div>
      <MznPaginationTable
        scroll={{ x: 4000 }}
        loading={searching}
        dataSource={data?.results ?? []}
        columns={columns}
        fetchData={onSearch}
        pageInfo={pageInfo}
      />
      <Modal style={{ width: 1000 }} open={modalOpen} onClose={closeEditModal}>
        <ModalHeader>盤點序號：{modalData?.serial}</ModalHeader>
        <ModalBody>
          <FormFieldsWrapper
            methods={modalMethods}
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: 'var(--mzn-spacing-4)',
            }}
          >
            <FormFieldsDebug />
            {columns
              .filter((column, index) => index > 1)
              .map<TableColumn<any>>((column) => {
                switch (column.title) {
                  case '現場盤點量':
                    return {
                      title: column.title,
                      render: (item: any) => {
                        const unit = !isNaN(item?.secondaryUnitRatio ?? NaN)
                          ? item?.secondaryUnit
                          : item?.materialStockUnit;
                        return (
                          <InputField
                            type="number"
                            registerName="quantity"
                            suffix={`（${unit ?? '-'}）`}
                          />
                        );
                      },
                    };
                  case '現場實秤':
                    return {
                      title: column.title,
                      render: () => {
                        return (
                          <InputField
                            type="number"
                            registerName="actualWeight"
                            suffix="（G）"
                          />
                        );
                      },
                    };
                  default:
                    return column;
                }
              })
              .map((column, index) => (
                <div
                  key={column.title}
                  style={{
                    gridColumn: `span ${[0, 1].includes(index) ? '3' : '1'}`,
                  }}
                >
                  <Typography variant="h5">{column.title}</Typography>
                  <Typography variant="body1">
                    {column?.render?.(modalData, index, column) ??
                      modalData?.[column?.dataIndex ?? ''] ??
                      '-'}
                  </Typography>
                </div>
              ))}
          </FormFieldsWrapper>
        </ModalBody>
        <ModalFooter
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 'var(--mzn-spacing-4)',
          }}
        >
          <Button variant="outlined" onClick={closeEditModal}>
            取消
          </Button>
          <Button
            variant="contained"
            loading={updating}
            onClick={updateInventoryTaking}
          >
            更新盤點
          </Button>
        </ModalFooter>
      </Modal>
    </PageLayout>
  );
}
