import { useState } from 'react';
import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { Pagination } from '../pagination';

const namespace = API_NAMESPACE.PP;

export function useGetComponentCodes() {
  const [params, setParams] = useState<{ searchTerm: string }>();
  const { data, error, mutate } = useSWR<{
    records: string[];
    pageInfo: Pagination;
  }>([
    `/pp/component-code`,
    {
      params,
      namespace,
    },
  ]);

  return {
    componentCodes: data?.records ?? [],
    loading: !data && !error,
    refetchGetComponentCodes: setParams,
    mutateGetComponentCodes: mutate,
  };
}
