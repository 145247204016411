import styles from './RequirementPage.module.scss';
import {
  CalendarConfigProvider,
  Button,
  Message,
  SelectValue,
  ButtonGroup,
  Loading,
  Typography,
  Icon,
} from '@mezzanine-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import CalendarMethodsMoment from '@mezzanine-ui/core/calendarMethodsMoment';
import { BasicInfoForm } from '../../components/TransportationRequirement-Forms/Requirement-Forms/Basic-Info/Basic-Info';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { DeliveryListingForm } from '../../components/TransportationRequirement-Forms/Requirement-Forms/Delivery-Listing/Delivery-Listing';
import { OtherInfoForm } from '../../components/TransportationRequirement-Forms/Requirement-Forms/Other-Info/Other-Info';
import { Col, Row } from 'antd';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { request } from '@solar/data';
import {
  LogisticsReqData,
  RequirementFormValues,
} from '../RequirementsManagementPage/RequirementManagement.interface';
import { useNavigate, useParams } from 'react-router';
import { formValuesToNewReq, logisticsDetailToFormValues } from './DataMapping';
import moment from 'moment';
import { DeliveryInfoStartEnd } from '../../components/TransportationRequirement-Forms/Requirement-Forms/Delivery-Info/Delivery-Info-Start-End';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { useSearchParams } from 'react-router-dom';
import { ChevronLeftIcon } from '@mezzanine-ui/icons';
import { useApplyUnit } from './useApplyUnit';
import {
  BooleanStrings,
  CorrespondentTypes,
} from '../RequirementsManagementPage/RequirementsManagement.config';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const defaultValues: Partial<RequirementFormValues> = {
  requestStatus: { id: BooleanStrings.False, name: '未確認' },
  isRecycle: { id: BooleanStrings.False, name: '否' },
  scrapFeature: [],
  packagingType: [],
  items: [],
};

const formSchema = Yup.object({
  applicant: Yup.string().required('必填'),
  applyDate: Yup.string().required('必填'),
  // confirmor: Yup.string().required('必填'),
  // confirmDate: Yup.string().required('必填'),
  logisticType: Yup.object().required('必填'),
  dispatchDate: Yup.string().required('必填'),
  applyUnit: Yup.object().required('必填'),
  costUnit: Yup.object().required('必填'),
  contact: Yup.string().required('必填'),
  contactPhone: Yup.string().required('必填'),
  correspondentType: Yup.object().required('必填'),
  deliveryArea: Yup.object().required('必填'),
  deliveryStartCompany: Yup.string().required('必填'),
  deliveryStartContact: Yup.string().required('必填'),
  deliveryStartContactPhone: Yup.string().required('必填'),
  deliveryStartAddress: Yup.string().required('必填'),
  deliveryEndCompany: Yup.string().required('必填'),
  deliveryEndContact: Yup.string().required('必填'),
  deliveryEndContactPhone: Yup.string().required('必填'),
  deliveryEndAddress: Yup.string().required('必填'),
  isRecycle: Yup.object(),
  environmentalFormId: Yup.string().when(
    ['correspondentType', 'isRecycle'],
    ([correspondentType, isRecycle], schema) => {
      if (
        isRecycle.id === BooleanStrings.True &&
        correspondentType.id !== CorrespondentTypes.Customer
      ) {
        return schema.required('必填');
      }
      return schema.notRequired();
    }
  ),
  isSealed: Yup.object().when('isRecycle', {
    is: (value: SelectValue) => value.id === BooleanStrings.True,
    then: (schema) => schema.required('必填'),
  }),
  scrapType: Yup.object().when('isRecycle', {
    is: (value: SelectValue) => value.id === BooleanStrings.True,
    then: (schema) => schema.required('必填'),
  }),
  scrapName: Yup.object().when('isRecycle', {
    is: (value: SelectValue) => value.id === BooleanStrings.True,
    then: (schema) => schema.required('必填'),
  }),
  scrapFeature: Yup.array().when('isRecycle', {
    is: (value: SelectValue) => value.id === BooleanStrings.True,
    then: (schema) => schema.min(1, '必填'),
  }),
  packagingType: Yup.array().when('isRecycle', {
    is: (value: SelectValue) => value.id === BooleanStrings.True,
    then: (schema) => schema.min(1, '必填'),
  }),
  items: Yup.array().min(1, '必填'),
});

export const ScrapNameOptionsContext = createContext<SelectValue[]>([]);
export const ScrapTypeOptionsContext = createContext<SelectValue[]>([]);

const DeliveryInfoPropsMap = {
  Start: {
    title: '收/送貨 - 起點',
    key: 'deliveryStartCompany',
    companyRegisterName: 'deliveryStartCompany',
    contactRegisterName: 'deliveryStartContact',
    phoneRegisterName: 'deliveryStartContactPhone',
    addressRegisterName: 'deliveryStartAddress',
    factoryRegisterName: 'deliveryStartFactory',
  },
  End: {
    title: '收/送貨 - 迄點',
    key: 'deliveryEndCompany',
    companyRegisterName: 'deliveryEndCompany',
    contactRegisterName: 'deliveryEndContact',
    phoneRegisterName: 'deliveryEndContactPhone',
    addressRegisterName: 'deliveryEndAddress',
    factoryRegisterName: 'deliveryEndFactory',
  },
};

export function RequirementPage() {
  const navigate = useNavigate();
  const { id: editId } = useParams();
  const [getSearchParams] = useSearchParams();
  const cloneId = useMemo(
    () => getSearchParams.get('source'),
    [getSearchParams]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [scrapNameOptions, setScrapNameOptions] = useState<SelectValue[]>([]);
  const [scrapTypeOptions, setScrapTypeOptions] = useState<SelectValue[]>([]);
  const { applyUnitOptions } = useApplyUnit();
  defaultValues.applyDate = moment().toISOString();
  const methods = useForm<RequirementFormValues>({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(formSchema),
  });

  const backToList = useCallback(
    () => navigate('../../requirements-management'),
    [navigate]
  );

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const nameOptions = await request('/logistics/scraps', {
          namespace: '/sd/sd',
        });
        setScrapNameOptions(nameOptions);

        const typeResData = await request('/logistics/scrap-types', {
          namespace: '/sd/sd',
        });
        const typeOptions = typeResData.map(
          (item: { id: string; scrapType: string }) => ({
            id: item.id,
            name: item.scrapType,
          })
        );
        setScrapTypeOptions(typeOptions);

        const sourceId = editId || cloneId;
        if (editId || cloneId) {
          const res = (await request(`/logistics/${sourceId}`, {
            namespace: '/sd/sd',
          })) as LogisticsReqData;
          const { formatValues, deliveryItems } = logisticsDetailToFormValues(
            res,
            !!cloneId,
            nameOptions,
            typeOptions,
            applyUnitOptions
          );
          const formValues = {
            ...defaultValues,
            ...formatValues,
          };
          Object.entries(formValues).forEach(([key, value]) => {
            methods.setValue(
              key as keyof RequirementFormValues,
              value as RequirementFormValues[keyof RequirementFormValues]
            );
          });

          methods.setValue('items', deliveryItems);
        }
        setIsLoading(false);
      } catch (err) {
        if (err instanceof Error) {
          Message.error(JSON.parse(err.message).message);
        } else {
          Message.error('資料錯誤');
        }
        backToList();
      }
    }

    fetchData();
  }, [editId, cloneId, methods, backToList, applyUnitOptions]);

  const onSubmit = methods.handleSubmit(async () => {
    const actionLabel = editId ? '編輯' : '新增';
    try {
      const values = methods.getValues();
      const formatValue: LogisticsReqData = formValuesToNewReq(values);
      const res = await request(`/logistics/${editId || ''}`, {
        method: editId ? 'put' : 'post',
        namespace: '/sd/sd',
        body: JSON.stringify(formatValue),
      });
      Message.success(`${actionLabel}成功`);
      backToList();
    } catch (err) {
      if (err instanceof Error) {
        Message.error(JSON.parse(err.message).message);
      } else {
        Message.error(`${actionLabel}失敗`);
      }
    }
  });

  return (
    <Loading stretch={isLoading} loading={isLoading}>
      <FormProvider {...methods}>
        <ScrapNameOptionsContext.Provider value={scrapNameOptions}>
          <ScrapTypeOptionsContext.Provider value={scrapTypeOptions}>
            <CalendarConfigProvider methods={CalendarMethodsMoment}>
              <FormFieldsWrapper methods={methods} className={styles.container}>
                <div className={styles.backBtn}>
                  <Button
                    type="button"
                    prefix={
                      <Icon
                        style={{ color: '#8F8F8F' }}
                        icon={ChevronLeftIcon}
                      />
                    }
                    variant="text"
                    onClick={() => navigate('../../requirements-management')}
                  >
                    <Typography color="text-secondary" variant="button2">
                      返回上一頁
                    </Typography>
                  </Button>
                </div>
                <PageTemplate title={`派車 - ${editId ? '編輯' : '新建需求'}`}>
                  <div className={styles.formContent}>
                    <BasicInfoForm />
                    <DeliveryInfoStartEnd {...DeliveryInfoPropsMap['Start']} />
                    <DeliveryInfoStartEnd {...DeliveryInfoPropsMap['End']} />
                    <DeliveryListingForm />
                    <OtherInfoForm />
                  </div>
                </PageTemplate>
              </FormFieldsWrapper>
              <Row
                justify="end"
                gutter={[12, 12]}
                style={{ marginTop: '12px' }}
              >
                <Col>
                  <ButtonGroup
                    className={styles.buttonGroup}
                    variant="contained"
                    size="large"
                  >
                    <Button onClick={onSubmit}>確認</Button>
                    {editId ? (
                      <Button danger onClick={backToList}>
                        取消
                      </Button>
                    ) : null}
                  </ButtonGroup>
                </Col>
              </Row>
            </CalendarConfigProvider>
          </ScrapTypeOptionsContext.Provider>
        </ScrapNameOptionsContext.Provider>
      </FormProvider>
    </Loading>
  );
}

export default RequirementPage;
