import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { request, API_NAMESPACE } from '../../request';
import * as yup from 'yup';

export const CustomerTradeConditionDeleteFormFieldName = {
  MATERIAL_ID: 'materialId',
  CUSTOMER_ID: 'customerId',
} as const;

const _FormFieldName = CustomerTradeConditionDeleteFormFieldName;

export const customerTradeConditionDeleteFormSchema = yup.object().shape({
  [_FormFieldName.MATERIAL_ID]: yup.string().optional(),
  [_FormFieldName.CUSTOMER_ID]: yup.string().optional(),
});

export type CustomerTradeConditionDeleteFormValues = yup.InferType<
  typeof customerTradeConditionDeleteFormSchema
>;

type HookProps = CustomerTradeConditionDeleteFormValues;

export function useCustomerTradeConditionDelete({
  customerId,
  materialId,
}: HookProps) {
  const formMethods = useForm<CustomerTradeConditionDeleteFormValues>({
    resolver: yupResolver(customerTradeConditionDeleteFormSchema),
  });

  useEffect(() => {
    if (!customerId) return;

    formMethods.reset({
      [_FormFieldName.MATERIAL_ID]: materialId,
      [_FormFieldName.CUSTOMER_ID]: customerId,
    });
  }, [customerId, materialId, formMethods]);

  return {
    formMethods,
    createFormSubmit,
    FormFieldName: _FormFieldName,
  };
}

async function deleteCustomerTradeCondition(
  query: CustomerTradeConditionDeleteFormValues
) {
  try {
    const safeQuery =
      customerTradeConditionDeleteFormSchema.validateSync(query);

    return request('/customers/customer-trade-condition', {
      namespace: API_NAMESPACE.HR,
      method: 'DELETE',
      params: safeQuery,
    });
  } catch (error) {
    return null;
  }
}

type FormSubmitCreatorProps = {
  successCallback?: (payload: any) => void;
  errorCallback?: (error: Error) => void;
};

function createFormSubmit({
  successCallback,
  errorCallback,
}: FormSubmitCreatorProps) {
  const onSubmit = async (data: CustomerTradeConditionDeleteFormValues) => {
    deleteCustomerTradeCondition(data)
      .then((payload) => {
        successCallback?.(payload);
      })
      .catch((error) => {
        errorCallback?.(error);
      });
  };

  return onSubmit;
}
