import { PlusIcon } from '@mezzanine-ui/icons';
import { Button, Icon, Message, Table, Typography } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  FieldsType,
  ResponseStatus,
  useOrderMarks,
  useOrderMarksFilters,
  useOrderMarksModal,
} from '@solar/data';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  AutoComplete,
  MultipleSelect,
  SearchInput,
} from '../../OrderManagement/FilterSearch/enhanceFilter/enhanceFilter';
import { SubmitButtonType } from '../Layout/Filter/Filter';
import { Layout } from '../Layout/Layout';
import { getComponent } from '../Layout/ModalLayout/enhanceModalActions/enhanceModalActions';
import { ModalLayout } from '../Layout/ModalLayout/ModalLayout';
import { OrderMarksDeleteModal } from './OrderMarksDeleteModal/OrderMarksDeleteModal';
import styles from './OrderMarksPage.module.scss';

export function OrderMarksPage() {
  const layoutMethods = useForm();
  const methods = useForm();

  const {
    data: orderMarksData,
    filterMutation: filterOrderMarks,
    page: currentPage,
    total,
    setPage: setCurrentPage,
    perPage,
    setPerPage,
  } = useOrderMarks();

  const {
    deleteModal,
    setDeleteModal,
    modal,
    setModal,
    closeModal,
    disableConfirm: disableConfirmModal,
    confirmModal,
    createModal,
    updateModal,
    deleteModalMutation,
  } = useOrderMarksModal(methods);

  const { data: orderMarksFiltersData, customers } = useOrderMarksFilters();

  const modalBodyComponents = useMemo(() => {
    const modalData = modal?.title === '編輯' ? updateModal : updateModal;
    return modalData.map((item) => ({
      ...item,
      fn: getComponent(item.fn),
    }));
  }, [modal, createModal, updateModal]);

  const basicBodyComponents = useMemo(
    () => modalBodyComponents.slice(0, 1),
    [modalBodyComponents]
  );
  const packageBodyComponents = useMemo(
    () => modalBodyComponents.slice(1),
    [modalBodyComponents]
  );

  const filtersData = useMemo(
    () => [
      {
        label: '客戶名稱',
        fn: AutoComplete,
        registerName: 'customerName',
        props: {
          width: 160,
          options:
            customers.map((customer) => ({
              id: customer.id,
              name: customer.name ?? '',
            })) ?? [],
          placeholder: '請選擇',
          getValues: layoutMethods.getValues,
        },
      },
      {
        label: '嘜頭名稱',
        fn: SearchInput,
        registerName: 'orderMarksName',
        props: {
          width: 160,
          clearable: true,
          placeholder: '請輸入',
          className: styles.input,
          name: 'orderMarksName',
          register: layoutMethods.register,
          setValue: layoutMethods.setValue,
        },
      },
      {
        label: '嘜頭料號',
        fn: MultipleSelect,
        registerName: 'orderMarksNumber',
        props: {
          mode: 'multiple',
          width: 768,
          clearable: true,
          // @TODO: 這邊需要補一個 autocomplete 的 input 下拉選單元件
          placeholder: '請選擇或輸入',
          className: styles.input,
          options: orderMarksFiltersData?.customerMaterials ?? [],
          name: 'orderMarksNumber',
          register: layoutMethods.register,
          setValue: layoutMethods.setValue,
        },
      },
    ],
    [orderMarksFiltersData, customers]
  );

  const registers = useMemo(() => {
    layoutMethods.watch();
    return filtersData.map((data) => ({
      registerName: data.registerName,
      value: layoutMethods.getValues(data.registerName),
    }));
  }, [layoutMethods.watch()]);

  const registerDependencies = useMemo(
    () => registers.map((register) => register.value),
    [registers]
  );

  const filterButton = useMemo(
    () => ({
      name: '清空條件',
      onClick() {
        setCurrentPage(1);
        filtersData.forEach((filter) => {
          layoutMethods.setValue(filter.registerName, undefined);
        });
      },
    }),
    []
  ) as SubmitButtonType;

  const columns = useMemo(
    () => [
      { title: '嘜頭料號', dataIndex: 'customerMaterialNumbers' },
      { title: '客戶名稱', dataIndex: 'customerName' },
      { title: '嘜頭名稱', dataIndex: 'name' },
      { title: '嘜頭尺寸 (長x寬 cm)', dataIndex: 'size' },
      {
        title: '動作',
        width: 120,
        align: 'center' as const,
        render(source: any) {
          return (
            <Button
              type="button"
              variant="outlined"
              onClick={() =>
                setModal({ title: '編輯', confirmButton: '更新', source })
              }
            >
              編輯
            </Button>
          );
        },
      },
    ],
    []
  );

  const filterLayout = useCallback(
    async (payload: FieldsType[]) => {
      setCurrentPage(1);
      filterOrderMarks(payload);
    },
    [setCurrentPage, filterOrderMarks]
  );

  const confirmCreateModal = useCallback(async () => {
    const res = await confirmModal(methods);
    if (res.status === ResponseStatus.SUCCESS) {
      Message.success(res.message);
    } else {
      Message.error(res.message);
    }
  }, [methods, confirmModal]);

  return (
    <div className={styles.wrapper}>
      <ModalLayout
        title={modal?.title}
        open={modal}
        width={864}
        onClose={closeModal}
        onConfirm={confirmCreateModal}
        onConfirmDelete={() => deleteModalMutation(modal?.source.id)}
        confirmButtonText={modal?.confirmButton}
        confirmButtonDisable={disableConfirmModal}
        deleteButton={modal?.title === '編輯'}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        deleteModalChildren={<OrderMarksDeleteModal source={modal?.source} />}
      >
        <div className={styles.modalWrapper}>
          <FormFieldsWrapper methods={methods} className={styles.formWrapper}>
            <div className={styles.modalBodyWithBorder}>
              <Typography variant="h4" color="text-primary">
                基本資訊
              </Typography>
              <div className={styles.body}>
                {basicBodyComponents.map((component) =>
                  component.fn(component)
                )}
              </div>
            </div>
            <div className={styles.modalBodyWithBorder}>
              <Typography variant="h4" color="text-primary">
                嘜頭資訊
              </Typography>
              <div className={styles.body}>
                {packageBodyComponents.map((component) =>
                  component.fn(component)
                )}
              </div>
            </div>
          </FormFieldsWrapper>
        </div>
      </ModalLayout>
      <Layout
        title="嘜頭管理"
        button={
          <Button
            variant="contained"
            size="large"
            prefix={<Icon icon={PlusIcon} />}
            onClick={() =>
              setModal({
                title: '新增',
                confirmButton: '新增',
                source: null,
              })
            }
          >
            新增
          </Button>
        }
        filterHeader="查詢"
        filtersData={filtersData}
        filterMethods={filterLayout}
        filterDependencies={registerDependencies}
        registers={registers}
        submitButton={filterButton}
        methods={layoutMethods}
      >
        <Table
          columns={columns}
          dataSource={orderMarksData ?? []}
          bodyClassName={styles.table}
          headerClassName={styles.tableHeader}
          pagination={{
            disableAutoSlicing: true,
            total: total,
            current: currentPage,
            onChange(page) {
              setCurrentPage(page);
            },
            options: {
              pageSize: perPage,
              onChangePageSize: (pageSize: number) => {
                setPerPage(pageSize);
                setCurrentPage(1);
              },
              showPageSizeOptions: true,
              pageSizeLabel: '每頁顯示：',
              pageSizeUnit: '筆',
            },
          }}
        />
      </Layout>
    </div>
  );
}
