import { Button, Message, Typography } from '@mezzanine-ui/react';
import { API_NAMESPACE, request } from '@solar/data';
import { Col, Row } from 'antd';
import { useState } from 'react';
import styles from './material-viewer-page.module.scss';
import { MaterialViewer } from './viewers/MaterialViewer';

const syncFromSap = () => {
  return request('/sync/materials', {
    method: 'get',
    namespace: API_NAMESPACE.MATERIALS,
    responseParser: (res) => res,
  });
};
export function MaterialViewerPage() {
  const [syncLoading, setSyncLoading] = useState(false);

  return (
    <div className={styles.wrapper}>
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Typography variant="h2">料號檢視器</Typography>
        </Col>
        <Col span={12} push={10}>
          <Button
            type="button"
            variant="outlined"
            loading={syncLoading}
            onClick={async () => {
              try {
                setSyncLoading(true);

                const res = await syncFromSap();

                if (res?.ok) {
                  Message.success('同步成功');
                }
              } catch (err) {
                Message.error(JSON.stringify(err));
              } finally {
                setSyncLoading(false);
              }
            }}
          >
            同步 SAP
          </Button>
        </Col>
      </Row>
      <MaterialViewer />
    </div>
  );
}
