import {
  Modal,
  ModalActions,
  ModalBody,
  Typography,
} from '@mezzanine-ui/react';
import { useTargetModal } from '@solar/templates';
import { useCallback, useState } from 'react';
import { Modals } from '../typings';

export function CancelReceiveModal() {
  const modalController = useTargetModal<{ cancelFunc: () => void }>(
    Modals.Cancel
  );
  const { open, closeModal, data } = modalController;

  const [cancelling, setCancelling] = useState(false);

  const handleCancel = useCallback(() => {
    closeModal();
  }, []);

  const handleConfirm = useCallback(async () => {
    try {
      setCancelling(true);
      await data?.cancelFunc();

      closeModal();
    } finally {
      setCancelling(false);
    }
  }, [data]);

  return (
    <Modal style={{ width: '50vw' }} open={open} onClose={handleCancel}>
      <br />
      <br />
      <ModalBody>
        <Typography variant="h2">是否確認取消收貨？</Typography>
        <br />
      </ModalBody>
      <ModalActions
        confirmText="確認"
        cancelText="取消"
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        confirmButtonProps={{
          type: 'button',
          danger: true,
          loading: cancelling,
        }}
      />
    </Modal>
  );
}
