import { TableColumn } from '@mezzanine-ui/core/table';
import { Button } from '@mezzanine-ui/react';
import { WaitReceiptObj } from '@solar/data';
import { useTargetModal } from '@solar/templates';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { ColumnHooks, Modals, TabsEnum } from '../types';
import { WaitReceiptModalProps } from '../modal/DeleteWaitReceiptModal';
import { useSearchParams } from 'react-router-dom';

export const useWaitingStorageInColumn = ({
  rollback,
}: {
  rollback?: (id: string) => Promise<void>;
}) => {
  const openInspectModal = useTargetModal<WaitReceiptObj>(
    Modals.Inspect
  ).openModal;

  const [searchParams] = useSearchParams();

  const activeTab = searchParams.get('tab') as `${TabsEnum}`;
  const openDeleteWaitReceiptModal = useTargetModal<{ id: string }>(
    Modals.DeleteWaitReceipt
  ).openModal;

  const [loading, setLoading] = useState(false);

  const column: TableColumn<WaitReceiptObj>[] = useMemo(
    () => [
      {
        title: '操作',
        width: activeTab !== 'record' ? 220 : 120,
        align: 'center' as const,
        ellipsis: false,
        render(source) {
          return (
            <div
              style={{
                display: 'flex',
                visibility: 'unset',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              {activeTab !== 'record' && (
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => {
                    openInspectModal(source);
                  }}
                >
                  入庫
                </Button>
              )}
              {activeTab !== 'record' && (
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => {
                    openDeleteWaitReceiptModal({ id: source.id });
                  }}
                >
                  刪除
                </Button>
              )}
              {activeTab === 'record' && (
                <Button
                  type="button"
                  variant="outlined"
                  loading={loading}
                  onClick={async () => {
                    try {
                      setLoading(true);
                      await rollback?.(source.id);
                    } finally {
                      setLoading(false);
                    }
                  }}
                >
                  迴轉
                </Button>
              )}
            </div>
          );
        },
      },
      { width: 150, title: '儲存地點', dataIndex: 'shelfId' },
      {
        width: 200,
        title: '出站時間',
        render: (source) =>
          source
            ? moment(source.createdAt).local().format('YYYY-MM-DD HH:mm')
            : '',
      },
      { width: 160, title: '工單單號', dataIndex: 'workOrderId' },
      { title: '料號', dataIndex: 'materialId', width: 300 },
      { title: '品名', dataIndex: 'materialDescription' },
      {
        title: '批號',
        width: 300,
        render: (source) =>
          `${source.materialSapBatchId ?? ''}-${
            source.materialSubBatchId ?? ''
          }`,
      },
      { title: '載具', dataIndex: 'loaderId' },
      {
        title: '管理單位數量',
        dataIndex: 'materialInputUnitQuantity',
      },
      { title: '單位', dataIndex: 'materialInputUnit' },
      { title: '實際重量', dataIndex: 'materialWeightUnitQuantity' },
      { title: '單位', render: () => 'G' },
    ],
    [openInspectModal, openDeleteWaitReceiptModal]
  );

  return column;
};
