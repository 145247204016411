import CalendarMethodsMoment from '@mezzanine-ui/core/calendarMethodsMoment';
import { CalendarConfigProvider, Notification } from '@mezzanine-ui/react';
import { Outlet } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { request } from '@solar/data';
import { message, notification } from 'antd';
import { Message } from '@mezzanine-ui/react';
import { PageGuard } from '../Auth/PageGuard/PageGuard';
import { AppSideBarWrapper } from '../AppSideBarWrapper';
import { useCheckTokenInterval } from './useCheckTokenInterval';

export function GlobalProvider() {
  message.config({
    duration: 5,
    maxCount: 3,
  });

  notification.config({
    duration: 5,
  });

  Message.config({
    duration: 5000,
  });

  Notification.config({
    duration: 5000,
  });

  useCheckTokenInterval();

  return (
    <SWRConfig
      value={{ revalidateOnFocus: false, refreshInterval: 0, fetcher: request }}
    >
      <CalendarConfigProvider methods={CalendarMethodsMoment}>
        <PageGuard>
          <AppSideBarWrapper>
            <Outlet />
          </AppSideBarWrapper>
        </PageGuard>
      </CalendarConfigProvider>
    </SWRConfig>
  );
}
