export type WorkCenterBigClass = {
  bigClassCode: string;
  bigClassName: string;
};

export type WorkCenterSmallClass = {
  smallClassCode: string;
  smallClassName: string;
};

export type WorkCenter = {
  id: string;
  name: string;
  bigClassCode: WorkCenterBigClass['bigClassCode'];
  smallClassCode: WorkCenterSmallClass['smallClassCode'];
};
