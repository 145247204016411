import type { HubOrdersFilterFormValues } from '@solar/data';
import { HubOrdersFilterFormFieldName as FormFieldName } from '@solar/data';
import { useFormContext } from 'react-hook-form';
import { RadioGroupField } from '@mezzanine-ui/react-hook-form';

export function DebitConfirmRadioGroup() {
  const formMethods = useFormContext<HubOrdersFilterFormValues>();
  console.log("🚀 ~ DebitConfirmRadioGroup ~ formMethods:", formMethods)
  const resetDebitDatePicker = () => {
    formMethods.resetField(FormFieldName.DEBIT_DATE);
  };

  return (
    <>
      <div>是否已扣帳：</div>
      <div>
        <RadioGroupField
          control={formMethods.control}
          registerName={FormFieldName.IS_DEBIT}
          options={[
            {
              value: '',
              label: '不限',
            },
            {
              value: 'Y',
              label: '是',
            },
            {
              value: 'N',
              label: '否',
            },
          ]}
          onChange={(e) => {
            if (e.target.value !== 'Y') {
              resetDebitDatePicker();
            }
          }}
        />
      </div>
    </>
  );
}
