import { Button } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  NextPagination,
  getNextParams,
  useGetMiscMaterialInventory,
} from '@solar/data';
import {
  ModalGroup,
  MznPaginationTable,
  PageLayout,
  useModalGroupController,
} from '@solar/templates';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Filter } from './Filter';
import { CreateMaterial } from './modals/CreateMaterial';
import { Modals, RegisterNames } from './types';

export function MiscMaterialInventoryPage() {
  const methods = useForm<RegisterNames & { searchTerm: string }>();
  const { miscMaterialInventory, pageInfo, refetchGetMiscMaterialInventory } =
    useGetMiscMaterialInventory();

  const refetchGetMiscMaterialInventoryWithPagination = useCallback(
    (nextPagination: NextPagination) => {
      const formState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      refetchGetMiscMaterialInventory({
        groupId: formState?.groups?.id,
        categoryId: formState?.categories?.id,
        subCategoryId: formState?.subCategories?.id,
        searchTerm: formState?.searchTerm,
        offset,
        limit,
      });
    },
    []
  );

  const modalGroupController = useModalGroupController([
    { name: Modals.CreateMaterial },
  ]);

  return (
    <>
      <PageLayout title="費用性料號清單">
        <FormFieldsWrapper methods={methods}>
          <Filter />
          <br />
          <div style={{ display: 'flex', gap: '4px' }}>
            <Button
              variant="contained"
              onClick={() =>
                refetchGetMiscMaterialInventoryWithPagination({ nextPage: 1 })
              }
            >
              查詢
            </Button>
            {/* <Button
              type="button"
              variant="outlined"
              onClick={() =>
                modalGroupController.openModal(Modals.CreateMaterial, null)
              }
            >
              ＋建立料號
            </Button> */}
          </div>
        </FormFieldsWrapper>
        <MznPaginationTable
          fetchData={refetchGetMiscMaterialInventoryWithPagination}
          pageInfo={pageInfo}
          dataSource={miscMaterialInventory ?? []}
          columns={[
            {
              title: '舊品號',
              dataIndex: 'oldMaterialId',
            },
            {
              title: '新物料編碼',
              dataIndex: 'id',
            },
            {
              title: '費用性物料群組',
              dataIndex: 'subCategory.category.group.name',
            },
            // {
            //   title: '費用性物料群組編碼',
            //   dataIndex: 'groupCode',
            // },
            {
              title: '物料群組大類',
              dataIndex: 'subCategory.category.name',
            },
            // {
            //   title: '物料群組大類編碼',
            //   dataIndex: 'categoryCode',
            // },
            {
              title: '物料中類',
              dataIndex: 'subCategory.name',
            },
            // {
            //   title: '物料中類編碼',
            //   dataIndex: 'categorySubCode',
            // },
            {
              title: '物料描述',
              dataIndex: 'description',
            },
          ]}
        />
      </PageLayout>
      <ModalGroup {...modalGroupController}>
        <CreateMaterial />
      </ModalGroup>
    </>
  );
}
