import { Table } from '@mezzanine-ui/react';
import { useGetMaterialWasteCodes } from './hooks';

export function MaterialWasteCodePanel() {
  const { wasteCodes, isLoading } = useGetMaterialWasteCodes();
  return (
    <Table
      loading={isLoading}
      dataSource={wasteCodes ?? []}
      scroll={{ y: 550 }}
      columns={[
        { width: 150, title: '代碼', dataIndex: 'id' },
        { title: '對應值', dataIndex: 'name' },
      ]}
    />
  );
}
