import useSWR from 'swr';
import { API_NAMESPACE } from '../request';

export type PalletCategory = {
  id: number;
  name: string;
  widthCm: number;
  lengthCm: number;
  weightKg: number;
  type: string;
};

const namespace = API_NAMESPACE.MATERIALS;
export function usePalletCategories() {
  const { data, error } = useSWR<PalletCategory[]>([
    '/pallets/categories',
    {
      namespace,
    },
  ]);

  return {
    palletCategories: data,
    isLoading: !error && !data,
  };
}
