import {
  Modal, ModalActions, ModalBody,
  ModalHeader, SelectValue, Button, Typography,
} from "@mezzanine-ui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  FormFieldsWrapper,
  InputField,
  SelectField
} from '@mezzanine-ui/react-hook-form';
import {
  useOrderPredict,
  getOrderMaterialDetail,
  useIngredientSearch,
  ICompMaterialItem,
  IOrderItem,
} from '@solar/data';
import DebounceAutoCompleteField from '../components/DebounceAutoCompleteField';
import styles from './OrderPredictUpsertPage.module.scss';

interface IOrderItemModalProps {
  open: boolean;
  onFinish: (orderItemData: any) => void;
  isCreate: boolean;
  nowComponentData?: IOrderItem | null;
}

const OrderItemModal = ({
  open = false,
  onFinish,
  isCreate = false,
  nowComponentData,
}: IOrderItemModalProps) => {
  const title = isCreate ? "新增預測訂單項目" : "編輯預測訂單項目";
  const itemForm = useForm();
  const {
    autoCompleteOptionsMaterial,
    autocompleteOptionsCertainty,
    autocompleteOptionsForecastType,
    autocompleteOptionsUnit,
    expensiveCompNames,
  } = useOrderPredict();

  const { autoCompleteOptionsComponentNames } = useIngredientSearch();

  useEffect(() => {
    if (nowComponentData) {
      // console.log('nowComponentData', nowComponentData);
      itemForm.reset(nowComponentData);
    }
  }, [itemForm, nowComponentData]);

  const resetForm = () => {
    itemForm.reset({
      material: '',
      unit: { id: '', name: '' },
      certainty: { id: '', name: '' },
      orderType: { id: '', name: '' },
      standardWt: '',
      stockArea: '',
      component1: '',
      component2: '',
      component3: '',
      component4: '',
    });
  };

  const cancelForm = () => {
    // 如果 reset 沒有帶參數會導致有些元件沒有被清空
    resetForm();
    onFinish(null);
  };

  const onFormSubmit = (values: any) => {
    // console.log('onFormSubmit', values);
    if (!values.key) {
      values.key = Math.random().toString(36).substring(7);
    }
    values.totalPrice = (values.price && values.qty) ? values.price * values.qty : 0;
    onFinish(values);
    resetForm();
  };


  const clearComponentFields = () => {
    itemForm.setValue('component1', '');
    itemForm.setValue('component2', '');
    itemForm.setValue('component3', '');
    itemForm.setValue('component4', '');
    itemForm.setValue('wtRate1', '');
    itemForm.setValue('wtRate2', '');
    itemForm.setValue('wtRate3', '');
    itemForm.setValue('wtRate4', '');
    itemForm.setValue('wt1', '');
    itemForm.setValue('wt2', '');
    itemForm.setValue('wt3', '');
    itemForm.setValue('wt4', '');
  }

  const onMaterialChange = async (materialOption: SelectValue<string>) => {
    // 這邊有個需求是，當選擇料號時，要去抓取料號的資料，並且填入表單中
    try {
      if (materialOption && materialOption.id) {
        const { id: materialId } = materialOption;
        const materialData = await getOrderMaterialDetail(materialId);
        const { unitCode, standardWt, component } = materialData
        itemForm.setValue('unit', { id: unitCode, name: unitCode });
        itemForm.setValue('standardWt', standardWt);
        const qty = itemForm.getValues('qty');
        // 如果料號資訊裡面有成分資料，就要把成分資料填入表單中
        if (component && Array.isArray(component.items)) {
          const { items } = component;
          clearComponentFields();
          items // 這邊要過濾掉不需要的成分
            .filter((itemData: ICompMaterialItem) => ((expensiveCompNames || []).includes(itemData.componentItemNumber)))
            .forEach((itemData: ICompMaterialItem, index: number) => {
              const { componentItemNumber, wtRate } = itemData;
              itemForm.setValue(`component${index + 1}`, { id: componentItemNumber, name: componentItemNumber });
              itemForm.setValue(`wtRate${index + 1}`, wtRate);
              const newWt = wtRate * standardWt * (qty || 1) / 100;
              itemForm.setValue(`wt${index + 1}`, newWt.toFixed(3));
            });
        }
      } else {
        // console.log('no materialOption');
        itemForm.setValue('unit', { id: '', name: '' });
        itemForm.setValue('standardWt', '');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const reCalcComponentWieght = () => {
    const { wtRate1, wtRate2, wtRate3, wtRate4, standardWt, qty } = itemForm.getValues();
    const newWt1 = wtRate1 * standardWt * (qty || 1) / 100;
    const newWt2 = wtRate2 * standardWt * (qty || 1) / 100;
    const newWt3 = wtRate3 * standardWt * (qty || 1) / 100;
    const newWt4 = wtRate4 * standardWt * (qty || 1) / 100;
    itemForm.setValue('wt1', newWt1 ? newWt1.toFixed(3) : '');
    itemForm.setValue('wt2', newWt2 ? newWt2.toFixed(3) : '');
    itemForm.setValue('wt3', newWt3 ? newWt3.toFixed(3) : '');
    itemForm.setValue('wt4', newWt4 ? newWt4.toFixed(3) : '');
  };

  return (
    <Modal
      open={open}
      style={{ width: 1000 }}
      onClose={cancelForm}
      disableCloseOnBackdropClick
      disableCloseOnEscapeKeyDown
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <FormFieldsWrapper
          methods={itemForm}
          onSubmit={onFormSubmit}
        >
          <div className={styles.row}>
            <div className={styles.space} style={{ width: '50%' }}>
              <DebounceAutoCompleteField
                registerName="material"
                label="料號"
                options={autoCompleteOptionsMaterial}
                onChange={onMaterialChange}
              />
              <SelectField
                registerName="unit"
                options={autocompleteOptionsUnit}
                label="單位"
              />
              <InputField
                registerName="price"
                label="單價"
                type="number"
              />

              <InputField
                registerName="qty"
                label="數量"
                type="number"
              />
              <SelectField
                registerName="certainty"
                label="把握度"
                options={autocompleteOptionsCertainty}
              />

              <SelectField
                registerName="orderType"
                label="入/耗料"
                options={autocompleteOptionsForecastType}
              />

              <InputField
                registerName="standardWt"
                label="物料標準重"
                type="number"
              />
              <InputField
                registerName="stockArea"
                label="工廠/收出貨倉庫"
              />
              <div>
                <Button
                  type="button"
                  variant="contained"
                  onClick={reCalcComponentWieght}
                >
                  重新計算物料重量
                </Button>
                <Typography variant="body2">
                  重新計算右側每項物料重量 = 物料標準重 * 數量 * 比例
                </Typography>
                <Typography variant="body2">
                  單位同料號單位
                </Typography>
              </div>
            </div>
            <div className={styles.space2}>
              <DebounceAutoCompleteField
                registerName="component1"
                label="物料1類型"
                options={autoCompleteOptionsComponentNames}
                size="small"
              />
              <InputField
                registerName="wtRate1"
                label="物料1比例(%)"
                type="number"
                size="small"
              />
              <InputField
                registerName="wt1"
                label="物料1量重"
                type="number"
                size="small"
              />
              <DebounceAutoCompleteField
                registerName="component2"
                label="物料2類型"
                options={autoCompleteOptionsComponentNames}
                size="small"
              />
              <InputField
                registerName="wtRate2"
                label="物料2比例(%)"
                type="number"
                size="small"
              />
              <InputField
                registerName="wt2"
                label="物料2量重"
                type="number"
                size="small"
              />
              <DebounceAutoCompleteField
                registerName="component3"
                label="物料3類型"
                options={autoCompleteOptionsComponentNames}
                size="small"
              />
              <InputField
                registerName="wtRate3"
                label="物料3比例(%)"
                type="number"
                size="small"
              />
              <InputField
                registerName="wt3"
                label="物料3量重"
                type="number"
                size="small"
              />
              <DebounceAutoCompleteField
                registerName="component4"
                label="物料4類型"
                options={autoCompleteOptionsComponentNames}
                size="small"
              />
              <InputField
                registerName="wtRate4"
                label="物料4比例(%)"
                type="number"
                size="small"
              />
              <InputField
                registerName="wt4"
                label="物料4量重"
                type="number"
                size="small"
              />
            </div>
          </div>
        </FormFieldsWrapper>
      </ModalBody>
      <ModalActions
        onConfirm={itemForm.handleSubmit(onFormSubmit)}
        onCancel={cancelForm}
        confirmText="確定"
        cancelText="取消"
      />
    </Modal>
  );
};

export default OrderItemModal;
