import { Typography } from '@mezzanine-ui/react';
import styles from './bill-weight-ref-page.module.scss';

export const BillWeightRefPage = () => {
  const pageTitle = '出貨單客戶結帳重量參考';

  const powerBIHref =
    window.location.host === 'edges.solartech.cloud'
      ? 'https://app.powerbi.com/reportEmbed?reportId=6683dcd9-c8a9-44be-8640-96585495c788&autoAuth=true&ctid=e3553a8e-c723-4b54-ac77-0bb3db8cf161'
      : 'https://app.powerbi.com/reportEmbed?reportId=65889f18-86af-478d-b22a-185c04dd155a&autoAuth=true&ctid=e3553a8e-c723-4b54-ac77-0bb3db8cf161';

  return (
    <div
      style={{
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'min-content auto',
      }}
    >
      <Typography variant="h1">{pageTitle}</Typography>
      <iframe
        title="WorkReportBI"
        width="1140"
        height="541.25"
        className={styles.iframe}
        src={powerBIHref}
        allowFullScreen
      />
    </div>
  );
};
