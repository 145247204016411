import { Table } from "@mezzanine-ui/react";
import styles from "./material-mapping-table.module.scss";
import { useState } from "react";

type MaterialMappingTableProps = {
  materialMapping: any[],
  isLoading: boolean,
  pageInfo: any,
  refetchGetMaterialMapping: (next: any) => void,
};

export function MaterialMappingTable({ materialMapping, isLoading, pageInfo, refetchGetMaterialMapping }: MaterialMappingTableProps) {
  const [expandedId, setExpandedId] = useState();
  const columns = [{
    width: 180,
    title: '物料類型',
    dataIndex: 'category.name',
  }, {
    width: 180,
    title: '物料群組',
    dataIndex: 'group.name',
  }, {
    title: '新料號',
    dataIndex: 'id',
  }, {
    title: '舊料號',
    dataIndex: 'oldMaterialId',
    // render: (record: any) => (
    //   <div style={{ display: 'flex', alignItems: 'center', gap: 'var(--mzn-spacing-2)' }}>
    //     <span>{record?.oldMaterials[0]?.id}</span>
    //     {record?.id !== expandedId && record?.oldMaterials?.length > 1 && (
    //       <>
    //         <span>...</span>
    //         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 42, height: 20, borderRadius: 10, backgroundColor: '#8F8F8F', fontSize: '13px', color: '#fff' }}>
    //           +{record?.oldMaterials?.length - 1 ?? 0}
    //         </div>
    //       </>
    //     )}
    //   </div>
    // ),
  }];

  return (
    <Table
      loading={isLoading}
      pagination={{
        disableAutoSlicing: true,
        total: pageInfo?.totalCount,
        current: pageInfo?.page,
        onChange: (nextPage) => refetchGetMaterialMapping({ nextPage }),
        options: {
          pageSize: pageInfo?.pageSize ?? 20,
          pageSizeLabel: '每頁顯示：',
          pageSizeUnit: '筆',
          onChangePageSize: (nextPageSize) => refetchGetMaterialMapping({ nextPage: 1, nextPageSize }),
          renderPageSizeOptionName: (p) => `${p}`,
          renderPaginationSummary: (start, end) => `目前顯示 ${start} - ${end} 筆，共 ${end - start + 1} 筆資料`,
          showPageSizeOptions: true, // 開啟每頁顯示 N 筆
          showJumper: true, // 開啟跳頁功能
          jumperButtonText: '前往',
          jumperHintText: '跳至',
        }
      }}
      bodyClassName={styles.table}
      dataSource={materialMapping}
      columns={columns}
      // expandable={{
      //   className: 'mzn-table__body__row--highlight',
      //   expandedRowRender: (record) => {
      //     const [, ...oldMaterialSource] = record?.oldMaterials as [] ?? [];
      //     return {
      //       dataSource: oldMaterialSource,
      //       className: 'mzn-table__body__row--highlight',
      //       columns: [{
      //         dataIndex: '',
      //       }, {
      //         dataIndex: '',
      //       }, {
      //         dataIndex: '',
      //       }, {
      //         dataIndex: 'id',
      //       }],
      //     }
      //   },
      //   rowExpandable: (record: any) => record?.oldMaterials?.length > 1,
      //   onExpand: (record: any, status) => setExpandedId(status ? record?.id : undefined),
      // }}
    />
  )
}