import { FieldValues } from 'react-hook-form';
import { PageInfo } from '../pagination';

// 基礎計量單位數量(MaterialStockUnitQuantity) = 管理單位數量(MaterialInputUnitQuantity) * 管理轉基礎計量單位比例(stockToInputUnitRatio)
export type MaterialUnit = {
  /** @description 基礎計量單位 */
  materialStockUnit: string;
  /** @deprecated */
  mainMaterialUnit?: string;
  /** @deprecated */
  mainUnitCode?: string;
  /** @deprecated */
  mainUnitName?: string;

  /** @description 管理單位 */
  materialInputUnit: string;
  /** @deprecated */
  secondaryMaterialUnit?: string;
  /** @deprecated */
  secondaryUnitCode?: string;
  /** @deprecated */
  secondaryUnitName?: string;

  /** @description 管理轉基礎計量單位比例 */
  stockOverInputUnitRatio: string;
  /** @deprecated */
  mainToSecondaryUnitRatio?: string;
};

export type MaterialResponseData = {
  id: string;
  description: string;
  categoryId: string;
  groupId: string;
  sourceId: string;
  category: string;
  group: string;
  batches: [
    {
      id: string;
      name: string;
      createdAt: string;
      materialId: string;
    }
  ];
};

export type GetMaterialsParams = FieldValues & PageInfo;
