export interface ProductionTrackingModel {
  workOrderId?: string;
  workCenterId?: string;
  equipmentName?: string;
  materialId?: string;
  id: string;
  createdAt?: string;
  equipmentId?: string;
  materialRequestOrderId?: string;
  sapBatchId?: string;
  subBatchId?: string;
  checkInUnit?: string;
  checkInQuantity?: number;
  manufacturingTimeInSeconds?: number;
  parentRecords: ProductionTrackingModel[];
  batchNote?: string;
}
