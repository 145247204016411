import { ReactNode } from 'react';

export function LabelPreview({
  children,
  landscape = false,
}: {
  children: ReactNode;
  landscape?: boolean;
}) {
  const landscapeStyle = {
    width: '29.7cm',
    minHeight: '21cm',
    // width: '80mm',
    // minHeight: '50mm',
  };

  const portraitStyle = {
    width: '420pt',
    // height: 'fit-content',
    height: '250pt',
    maxHeight: '255pt',
    // width: '80mm',
    // height: '50mm',
  };

  return (
    <div
      style={{
        ...(landscape ? landscapeStyle : portraitStyle),
        padding: '1pt',
        border: '1pt #D3D3D3 solid',
      }}
      className="preview"
    >
      {children}
    </div>
  );
}
