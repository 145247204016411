import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { OffsetBased, PageInfo } from '../pagination';
import { API_NAMESPACE } from '../request';

export type LoadersByCodeQueryParams = {
  categoryCodes: string;
};

export type LoadersByCodeProps = {
  id: string;
  shelfId: string;
  categoryCodes: string;
  category: {
    code: string;
    name: string;
  };
  widthCm: string;
  lengthCm: string;
  heightCm: string;
  capacityL: string;
  barcode: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

const namespace = API_NAMESPACE.MATERIALS;
export const useLoadersByCode = () => {
  const [params, setParams] = useState<
    (LoadersByCodeQueryParams & OffsetBased) | undefined
  >();

  const { data, mutate } = useSWR<{
    pageInfo: PageInfo;
    records: LoadersByCodeProps[];
  }>(
    params
      ? [
          '/loaders',
          {
            namespace,
            params,
          },
        ]
      : null
  );

  const pageInfo = useMemo(() => {
    const page =
      (data?.pageInfo?.offset ?? 0) / (data?.pageInfo?.limit ?? 20) + 1;
    const pageSize = data?.pageInfo?.limit ?? 20;
    return { ...(data?.pageInfo ?? {}), page, pageSize };
  }, [data]);

  return {
    loaders: data?.records ?? [],
    pageInfo,
    mutateParams: setParams,
    mutate,
  };
};
