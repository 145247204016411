export const schemas = {
  STATUS: 'status',
  DATE: 'date',
  DATE_START: 'dateStart',
  DATE_END: 'dateEnd',
  NAME: 'name',
} as const;

export const defaultValues: SalariesFormField = {
  [schemas.STATUS]: null,
  [schemas.DATE]: null,
  [schemas.NAME]: '',
};

export enum EStatus {
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  WAITING = 'WAITING',
}

export type SelectType<S> = {
  id: S;
  name: S;
};

export type SalariesFormField = {
  [schemas.STATUS]: SelectType<EStatus> | null;
  [schemas.DATE]: string[] | null;
  [schemas.NAME]: string;
};
