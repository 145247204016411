import { useCallback, useMemo, useEffect } from 'react';

import { Button } from '@mezzanine-ui/react';

import {
  getNextParams,
  NextPagination,
  useGetDeliveryOrders,
} from '@solar/data';

import { useForm } from 'react-hook-form';

import { TableColumn } from '@mezzanine-ui/core/table';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import type { GetDeliveryOrdersParams } from '@solar/data';
import {
  CopyTextWrapper,
  FilterScaffold,
  MznPaginationTable,
} from '@solar/templates';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from '../PageLayout/PageLayout';
import sharedStyles from '../shared-styles/index.module.scss';
import { Filter, filteredOrderTypes } from './Filters/HubReturn/Filter';
import { InspectStatus, InspectStatusTag } from './InspectStatusTag';
import { CustomerOrderTableProps, CustomerSupplyForm } from './typings';

export function HubReturnPanel() {
  const filterMethods = useForm<CustomerSupplyForm>();
  const navigate = useNavigate();

  const { deliveryOrders, pageInfo, refetchGetDeliveryOrders, isLoading } =
    useGetDeliveryOrders({
      types: filteredOrderTypes as GetDeliveryOrdersParams['types'],
    });

  const refetchGetDeliveryOrdersWithPagination = useCallback(
    (nextPagination: NextPagination) => {
      const formState = filterMethods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      refetchGetDeliveryOrders({
        expectedCompletedDateFrom: formState?.createdDateRange?.[0],
        expectedCompletedDateTo: formState?.createdDateRange?.[1],
        orderIds: formState?.orderId ? [formState?.orderId] : undefined,
        customerNames: formState?.customerName
          ? [formState?.customerName]
          : undefined,
        employeeNames: formState?.employeeName
          ? [formState?.employeeName]
          : undefined,
        packedStates:
          (formState?.packedStates?.map(
            (row) => row.id
          ) as GetDeliveryOrdersParams['packedStates']) ?? undefined,
        sortType: formState?.sortType
          ?.id as GetDeliveryOrdersParams['sortType'],
        types:
          ((formState?.types ?? [])
            ?.map((row) => row.id)
            .concat(filteredOrderTypes) as GetDeliveryOrdersParams['types']) ??
          undefined,
        offset,
        limit,
      });
    },
    [filterMethods, refetchGetDeliveryOrders, pageInfo]
  );

  const columns: TableColumn<CustomerOrderTableProps>[] = useMemo(
    () => [
      {
        title: '操作',
        width: 120,
        align: 'center' as const,
        render(source) {
          return (
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                navigate(`${source.id}`);
              }}
            >
              檢視
            </Button>
          );
        },
      },
      {
        title: '出貨單號',
        render: (source) => <CopyTextWrapper text={source?.id ?? ''} />,
      },
      {
        title: '出貨日期',
        render: (record) =>
          record?.expectedCompletedAt
            ? moment(record.expectedCompletedAt).format('YYYY-MM-DD')
            : '',
      },
      { title: '出貨單類形', dataIndex: 'orderTypeName' },
      {
        title: '預計收貨日期',
        render: (record) =>
          record?.expectedCompletedAt
            ? moment(record.expectedCompletedAt).format('YYYY-MM-DD')
            : '',
      },
      { title: '客戶名稱', dataIndex: 'customerName' },
      { title: '客戶代碼', dataIndex: 'customerId' },
      { title: '業務人員', dataIndex: 'employeeName' },
      {
        title: '狀態',
        render: (source) => {
          return (
            <InspectStatusTag status={source.packedState as InspectStatus} />
          );
        },
      },
    ],
    [navigate]
  );

  return (
    <PageLayout>
      <FormFieldsWrapper methods={filterMethods}>
        <FilterScaffold
          mutateParams={refetchGetDeliveryOrdersWithPagination}
          fetchAsParamsMutated={false}
        >
          <Filter />
        </FilterScaffold>
      </FormFieldsWrapper>
      <div style={{ display: 'flex', gap: '12px' }}>
        <Button
          variant="outlined"
          type="button"
          loading={isLoading}
          onClick={() =>
            refetchGetDeliveryOrdersWithPagination({ nextPage: 1 })
          }
        >
          搜尋
        </Button>
        {/* <Button variant="contained" type="button" onClick={() => ''}>
          重新整理
        </Button> */}
      </div>
      <MznPaginationTable
        columns={columns}
        dataSource={deliveryOrders ?? []}
        bodyClassName={sharedStyles.table}
        fetchData={refetchGetDeliveryOrdersWithPagination}
        loading={isLoading}
        pageInfo={pageInfo}
      />
    </PageLayout>
  );
}
