export const STATUS = {
  FINISH: '已完成',
  ONGOING: '未完成',
} as const;

export const CREATE_ORDER = {
  createdAtDesc: '建立日期 - 新到舊',
  createdAtAsce: '建立日期 - 舊到新',
} as const;

export const TEMP_CREATE_CONFIGURE_TABLE = [
  {
    id: '1',
    materialId: 'HLZZ1A',
    materialDescription:
      '化學處理-電鍍廢棄之氰化物電鍍液*金電解-Au電鍍液Au>600ppm,ph<9',
    batchId: '38GZA001',
    notes: '',
    stackId: '1300E1EA10',
    loaderId: 'H00003',
    storageStatus: '未限制',
    storage: 123,
    storageUnit: 'G',
    weight: 123,
    weightUnit: 'G',
  },
  {
    id: '2',
    materialId: 'HLZZ1A',
    materialDescription:
      '化學處理-電鍍廢棄之氰化物電鍍液*金電解-Au電鍍液Au>600ppm,ph<9',
    batchId: '38GZA001',
    notes: '',
    stackId: '1300E1EA10',
    loaderId: 'H00003',
    storageStatus: '未限制',
    storage: 123,
    storageUnit: 'G',
    weight: 123,
    weightUnit: 'G',
  },
];
