import { Button, Message, Table, Typography } from '@mezzanine-ui/react';
import {
  checkJWTRoles,
  FieldsType,
  ResponseStatus,
  usePackagingEstimate,
  usePackagingEstimateFilter,
  usePackagingEstimateModal,
} from '@solar/data';
import { Tooltip } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  AutoComplete,
  CalendarRanger,
  SearchInput,
} from '../../OrderManagement/FilterSearch/enhanceFilter/enhanceFilter';
import { SubmitButtonType } from '../Layout/Filter/Filter';
import { Layout } from '../Layout/Layout';
import { getComponent } from '../Layout/ModalLayout/enhanceModalActions/enhanceModalActions';
import { ModalLayout } from '../Layout/ModalLayout/ModalLayout';
import styles from './PackagingEstimate.module.scss';

type ModalType = 'unCaculate' | 'caculated';

export function PackagingEstimate() {
  const layoutMethods = useForm();
  const methods = useForm();

  const [selectedRowKey, setSelectedRowKey] = useState<string[]>([]);
  const [modalType, setModalType] = useState<ModalType>('unCaculate');

  const {
    data: packagingEstimateData,
    total,
    filterMutation: filterPackagingEstimate,
    fetchResult: fetchPackingEstimateResult,
    exportCaculate: exportCaculatePackingEstimate,
    page: currentPage,
    setPage: setCurrentPage,
    perPage,
    setPerPage,
    resultData,
  } = usePackagingEstimate();

  const { modal, setModal, closeModal, createModal, modalTableColumns } =
    usePackagingEstimateModal(methods);

  const modalCaculateTableColumns = useMemo(
    () => [
      {
        title: '箱號',
        width: 212,
        render: (source: any) => (
          <Tooltip
            title={source.index?.join(',') ?? ''}
            arrow={false}
            color="#777b80"
            overlayInnerStyle={{
              borderRadius: 0,
              fontSize: 12,
              lineHeight: '16px',
            }}
          >
            <div>
              {source.index?.map((item: string) => (
                <Typography
                  key={Math.random()}
                  className={styles.modalCaculateTableColumnsIndex}
                >
                  {item}
                </Typography>
              ))}
            </div>
          </Tooltip>
        ),
      },
      { title: '包材名稱', dataIndex: 'packagingName', width: 120 },
      { title: '包材料號', dataIndex: 'packagingMaterialId' },
      { title: '包裝類型', dataIndex: 'packagingTypeName', width: 100 },
    ],
    []
  );

  const { data: packingsFiltersData, customers } = usePackagingEstimateFilter();

  const selectedActions = useMemo(
    () =>
      modal?.source?.detail?.map((item: any) => ({
        ...item,
        key: item.salesOrderLine,
      })),
    [modal]
  );

  const modalBodyComponents = useMemo(() => {
    return createModal.map((item) => ({
      ...item,
      fn: getComponent(item.fn),
    }));
  }, [modal, createModal]);

  const basicBodyComponents = useMemo(
    () => modalBodyComponents.slice(0, 7),
    [modalBodyComponents]
  );

  const filtersData = useMemo(
    () => [
      {
        label: '訂單日期',
        fn: CalendarRanger,
        registerName: 'orderDate',
        isDate: true,
        props: {
          width: 300,
          clearable: true,
          placeholder: 'yyyy/mm/dd',
        },
      },
      {
        label: '訂單編號',
        fn: SearchInput,
        registerName: 'orderNumber',
        props: {
          width: 160,
          clearable: true,
          placeholder: '請輸入',
          className: styles.input,
          name: 'orderNumber',
          register: layoutMethods.register,
          setValue: layoutMethods.setValue,
        },
      },
      {
        label: '客戶名稱',
        fn: AutoComplete,
        registerName: 'customerName',
        props: {
          width: 160,
          options:
            customers.map((customer) => ({
              id: customer.id,
              name: customer.name ?? '',
            })) ?? [],
          placeholder: '請選擇',
          getValues: layoutMethods.getValues,
        },
      },
    ],
    [packingsFiltersData, customers]
  );

  const registers = useMemo(() => {
    layoutMethods.watch();
    return filtersData.map((data) => ({
      registerName: data.registerName,
      value: layoutMethods.getValues(data.registerName),
    }));
  }, [layoutMethods.watch()]);

  const registerDependencies = useMemo(
    () => registers.map((register) => register.value),
    [registers]
  );

  const filterButton = useMemo(
    () => ({
      name: '清空條件',
      onClick() {
        filtersData.forEach((filter) => {
          layoutMethods.setValue(filter.registerName, undefined);
        });
      },
    }),
    []
  ) as SubmitButtonType;

  const columns = useMemo(
    () => [
      { title: '訂單日期', dataIndex: 'customerOrderDate' },
      { title: '訂單編號', dataIndex: 'orderId' },
      { title: '客戶', dataIndex: 'customerName' },
      {
        title: '動作',
        width: 120,
        align: 'center' as const,
        render(source: any) {
          return (
            checkJWTRoles([73]) && (
              <Button
                type="button"
                variant="outlined"
                onClick={() =>
                  setModal({
                    title: '選擇試算項次',
                    confirmButton: '更新',
                    source,
                  })
                }
              >
                試算
              </Button>
            )
          );
        },
      },
    ],
    [modalType]
  );

  const expandable = useMemo(
    () => ({
      expandedRowRender: (record: any) => (
        <div className={styles.expandTableWrapper}>
          <div className={styles.expandTable}>
            <div className={styles.td}>
              <Typography color="secondary-light">項次：</Typography>
              <Typography>{record.salesOrderLine}</Typography>
            </div>
            <div className={styles.td}>
              <Typography color="secondary-light">數量：</Typography>
              <Typography>{record.quantity}</Typography>
            </div>
            <div className={styles.td}>
              <Typography color="secondary-light">數量單位：</Typography>
              <Typography>{record.unit}</Typography>
            </div>
          </div>
          <div className={styles.expandTable}>
            <div className={styles.td}>
              <Typography color="secondary-light">料號：</Typography>
              <Typography>{record.materialId}</Typography>
            </div>
            <div className={styles.td}>
              <Typography color="secondary-light">品名：</Typography>
              <Typography>{record.materialName}</Typography>
            </div>
          </div>
        </div>
      ),
      rowExpandable: (record: any) => !!record.salesOrderLine,
    }),
    []
  );

  const confirmCaculate = useCallback(() => {
    const modalId = modal?.source?.orderId;
    if (!modalId) return;

    if (modalType === 'unCaculate') {
      fetchPackingEstimateResult(modalId, { detailIds: selectedRowKey });
      setModalType('caculated');
    } else {
      closeModal();
      setModalType('unCaculate');
      setSelectedRowKey([]);
    }
  }, [
    modal,
    modalType,
    fetchPackingEstimateResult,
    closeModal,
    selectedRowKey,
  ]);

  const exportCaculate = useCallback(async () => {
    const modalId = modal?.source?.orderId;
    if (!modalId) return;
    const result = await exportCaculatePackingEstimate(modalId, {
      detailIds: selectedRowKey,
    });
    if (result === ResponseStatus.SUCCESS) {
      Message.success('成功');
    } else {
      Message.error('請再試一次');
    }
  }, [modal, exportCaculatePackingEstimate, selectedRowKey]);

  const filterLayout = useCallback(
    async (payload: FieldsType[]) => {
      setCurrentPage(1);
      filterPackagingEstimate(payload);
    },
    [setCurrentPage]
  );

  return (
    <div className={styles.wrapper}>
      <ModalLayout
        title={modalType === 'unCaculate' ? '選擇試算項次' : '試算結果'}
        open={modal}
        width={864}
        onClose={() => {
          closeModal();
          setModalType('unCaculate');
          setSelectedRowKey([]);
        }}
        onConfirm={confirmCaculate}
        onConfirmDelete={() => {}}
        confirmButtonText={modalType === 'unCaculate' ? '試算' : '完成'}
        deleteButton={false}
        confirmButtonDisable={
          modalType === 'unCaculate' && selectedRowKey.length === 0
        }
        deleteModal={null}
        setDeleteModal={() => {}}
        deleteModalChildren={<></>}
        othersButton={
          modalType === 'unCaculate' ? undefined : (
            <Button
              variant="text"
              type="button"
              size="medium"
              onClick={exportCaculate}
            >
              下載
            </Button>
          )
        }
      >
        <div className={styles.modalWrapper}>
          <div className={styles.formWrapper}>
            <div className={styles.modalBodyWithBorder}>
              <Typography variant="h4" color="text-primary">
                基本資訊
              </Typography>
              <div className={styles.body}>
                {basicBodyComponents.map(
                  (component: {
                    fn: any;
                    label?: string | undefined;
                    required: boolean;
                    componentsProps: any;
                  }) => component.fn(component)
                )}
              </div>
            </div>
            <div className={styles.modalBodyWithBorder}>
              <Typography variant="h4" color="text-primary">
                訂單明細
              </Typography>
              <div className={styles.body}>
                {modalType === 'unCaculate' ? (
                  <Table
                    columns={modalTableColumns}
                    dataSource={
                      modal?.source?.details?.map((item: any) => ({
                        ...item,
                        key: item.index,
                      })) ?? []
                    }
                    bodyClassName={styles.table}
                    rowSelection={{
                      selectedRowKey,
                      onChange: (keys) => {
                        setSelectedRowKey(keys);
                      },
                      actions: selectedActions,
                    }}
                  />
                ) : (
                  <Table
                    columns={modalCaculateTableColumns}
                    dataSource={resultData ?? []}
                    className={styles.table}
                    expandable={expandable}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </ModalLayout>
      <Layout
        title="包裝預估"
        filterHeader="查詢"
        button={<div></div>}
        filtersData={filtersData}
        filterMethods={filterLayout}
        filterDependencies={registerDependencies}
        registers={registers}
        submitButton={filterButton}
        methods={layoutMethods}
      >
        <Table
          columns={columns}
          dataSource={packagingEstimateData ?? []}
          bodyClassName={styles.table}
          headerClassName={styles.tableHeader}
          pagination={{
            disableAutoSlicing: true,
            total,
            current: currentPage,
            onChange(page) {
              setCurrentPage(page);
            },
            options: {
              pageSize: perPage,
              onChangePageSize: (pageSize: number) => {
                setPerPage(pageSize);
                setCurrentPage(1);
              },
              showPageSizeOptions: true,
              pageSizeLabel: '每頁顯示：',
              pageSizeUnit: '筆',
            },
          }}
        />
      </Layout>
    </div>
  );
}
