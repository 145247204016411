import { SelectValue } from '@mezzanine-ui/react';
export type PackagingStation = {
  id: string;
  packagingId: string;
  orderId: string;
  customerName: string;
  staffName: string;
  createdAt: string;
  packedState: string;
};

export enum Modals {
  PALLET_SELECTORS = 'PALLET_SELECTORS',
  SETUP_PACKAGING = 'SETUP_PACKAGING',
  VIEW_PACKAGED = 'VIEW_PACKAGED',
}

export type PackageDetailBarcodeTable = {
  id: string;
  index: number; // 紀錄所屬箱號
  materialName: string;
  batchId: string;
  mainMaterialUnitQuantity: number;
  materialBarcode: string;
  grossWeight: number;
};

export type PackagedTableColumns = {
  id: string;
  index: number;
  markId: string;
  packageSize: string;
  contentQuantity: number;
  netWeight: number;
  grossWeight: number;
};

export type ViewPackagedModalProps = {
  grossWeight: number;
  table: [
    {
      batchId: string;
      createdAt: string;
      materialDescription: string;
      oldMaterialId: string;
      recordId: number;
      mainMaterialUnitQuantity: number;
      secondaryMaterialUnitQuantity: 0;
      materialBarcode: string;
      loaderId: number;
      loaderBarcode: string;
      shelfId: string;
      shelfName: string;
      stackId: string;
      stackName: string;
      materialId: string;
      loaderName: string;
      staffName: string;
    }
  ];
  marks: SelectValue;
  packageOption: SelectValue;
};

export type PackingsResponse = {
  id: string;
  createdAt: string;
  boxTotal: number;
  details: Array<{
    id: string;
    index: number;
    deliveryOrderLine: string;
    snum: number;
    materialId: string;
    materialName: string;
    boxProductNum: number;
    productUnit: string;
    packagingMaterialId: string;
    packagingName: string;
    productNum: number;
    packingId: number;
    boxQuantity: number;
    boxNum: number;
  }>;
};

export type PackagingExpandRowRecords = {
  tableRows: Array<
    PackingsResponse['details'][number] & {
      subItems: Array<PackageDetailBarcodeTable>;
    }
  >;
};

export type SetupPackagingMethods = {
  [palletKey: string]: PackagingExpandRowRecords;
};

export type PalletsList = Array<{
  id: string | null;
  name: string | null;
  realId: number | null;
}>;

export type PackingPayload = {
  item: Array<{
    packingListId: number;
    palletId: number;
    batches: Array<{
      barcode: string;
      mainMaterialUnitQuantity: number;
    }>;
  }>;
};
