import { useState } from 'react';
import useSWR from 'swr';
import { OffsetBased, PageInfo } from '../pagination';
import { API_NAMESPACE } from '../request';

const namespace = API_NAMESPACE.MATERIALS;

export type WorkOrderStatus = 'WAITING' | 'PARTIAL' | 'COMPLETE';

type ProductionWorkOrderResponseData = {
  businessUnitName: string;
  expectedCompletedAt: string;
  id: string;
  processName: string;
  state: string;
  staffName: string;
};

type GetProductionWorkOrdersParams = OffsetBased & {
  dateFrom?: string;
  dateTo?: string;
  shippedStates?: Array<'WAITING' | 'PARTIAL' | 'COMPLETE'>;
  searchTerm?: string;
};

export function useGetProductionWorkOrders() {
  const [params, setParams] = useState<GetProductionWorkOrdersParams>();
  const { data, error } = useSWR<{
    records: ProductionWorkOrderResponseData[];
    pageInfo: PageInfo;
  }>([
    '/warehouses/production-work-orders',
    {
      params,
      namespace,
    },
  ]);

  const mutateGetProductionWorkOrders = (
    payload: GetProductionWorkOrdersParams
  ) => {
    setParams(payload);
  };

  return {
    productionWorkOrders: data?.records || [],
    pageInfo: data?.pageInfo,
    isLoading: !data && !error,
    mutateGetProductionWorkOrders,
  };
}
