import useSWR from 'swr';
import { API_NAMESPACE } from '../request';

export type LoaderWithCount = {
  code: string;
  name: string;
  equipmentQuantity: number;
};

const namespace = API_NAMESPACE.MATERIALS;
export const useLoadersWithCount = () => {
  return useSWR<Array<LoaderWithCount>>([
    '/loaders/categories/count',
    { namespace },
  ]);
};
