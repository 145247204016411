import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { UseDataSourceHook } from '../common-table-filter-param';
import { OffsetBased, PageInfo } from '../pagination';
import { API_NAMESPACE, request } from '../request';

const STATES = {
  WAITING: '待處理',
  PARTIAL: '部分處理',
  COMPLETE: '處理完',
} as const;

const STATES_SELECTIONS = Object.entries(STATES).map(([key, value]) => ({
  id: key,
  name: value,
}));

const SORT_TYPE = {
  CREATED_AT_DESC: '工單建立日期近 -> 遠',
  CREATED_AT_ASC: '工單建立日期遠 -> 近',
  EXPECTED_COMPLETED_AT_DESC: '訂單交期近 -> 遠',
  EXPECTED_COMPLETED_AT_ASC: '訂單交期遠 -> 近',
};

const SORT_TYPE_SELECTIONS = Object.entries(SORT_TYPE).map(([key, value]) => ({
  id: key,
  name: value,
}));

export {
  STATES as WORK_ORDERS_STATES,
  STATES_SELECTIONS as WORK_ORDER_STATES_SELECTIONS,
  SORT_TYPE as WORK_ORDERS_SORT_TYPE,
  SORT_TYPE_SELECTIONS as WORK_ORDER_SORT_TYPE_SELECTIONS,
};

export type WorkOrdersRequestParams = {
  states: Array<keyof typeof STATES>;
  factoryId: string;
  factoryIds: string[];
  orderTypeCode: string;
  orderTypeCodes: string[];
  searchTerm: string;
  createdAtDateFrom: string;
  createdAtDateTo: string;
  expectedCompletedAtDateFrom: string;
  expectedCompletedAtDateTo: string;
  sortType: keyof typeof SORT_TYPE;
  filteredOrderTypeCodes: string[];
} & OffsetBased;

export type WorkOrders = {
  id: string;
  expectedCompletedAt: string;
  factoryId: string;
  createdAt: string;
  staffName: string;
  state: keyof typeof STATES;
  orderTypeName: string;
  expectedDeliveryDate?: string;
  workOrderStatusName?: string;
};

const namespace = API_NAMESPACE.MATERIALS;

export function useWorkOrders({
  initiateFetching = false,
  defaultParams,
}: UseDataSourceHook & {
  defaultParams?: Partial<WorkOrdersRequestParams>;
}) {
  const [params, setParams] = useState<
    Partial<WorkOrdersRequestParams> | undefined
  >(defaultParams);
  const { data, error, mutate } = useSWR<{
    records: WorkOrders[];
    pageInfo: PageInfo;
  }>(
    initiateFetching || params
      ? ['/warehouses/work-orders', { params, namespace }]
      : null
  );

  const pageInfo = useMemo(() => {
    const page =
      (data?.pageInfo?.offset ?? 0) / (data?.pageInfo?.limit ?? 20) + 1;
    const pageSize = data?.pageInfo?.limit ?? 20;
    return { ...(data?.pageInfo ?? {}), page, pageSize };
  }, [data]);

  return {
    orders: data?.records || [],
    pageInfo,
    isLoading: !error && !data,
    mutateParams: setParams,
    refetchOrders: mutate,

    refetchWorkOrders: setParams,
    mutateWorkOrders: mutate,
  };
}

export type SyncWorkOrdersOrdersFromSAPParams = {
  dateFrom?: string;
  dateTo?: string;
};

export function syncWorkOrdersOrdersFromSAP(
  params: SyncWorkOrdersOrdersFromSAPParams
) {
  return request('/sync/work-orders', {
    method: 'get',
    namespace,
    responseParser: (res) => res,
    params,
  });
}
