import * as yup from 'yup';
import {
  ProductionFormFieldEnum,
  ProductionFormFieldNumeric,
  ProductionFormFieldText,
  ProductionFormFieldTimestamp,
  ProductionFormResponse,
} from '@solar/data';
import { useCallback } from 'react';
import Decimal from 'decimal.js';

export function useDynamicFormSchema() {
  const getDateTimeSchema = useCallback(
    (field: ProductionFormFieldTimestamp) => {
      return yup
        .string()
        .when([], (value, schema) =>
          field?.required ? schema.required('必填') : schema
        );
    },
    []
  );

  const getNumericSchema = useCallback((field: ProductionFormFieldNumeric) => {
    const isNumber = (value?: string | null) =>
      !isNaN(Number(value || undefined));
    return yup.string().when([], (_value, schema) => {
      if (field?.required) {
        schema = schema
          .test('is-number', '必須為數字', isNumber)
          .required('必填');
      }

      if (field?.min) {
        schema = schema.test('min', `必須大於等於最小值${field.min}`, (value) =>
          isNumber(value)
            ? new Decimal(value ?? '').greaterThanOrEqualTo(
                (field.min as number).toString()
              )
            : true
        );
      }

      if (field?.max) {
        schema = schema.test('max', `必須小於等於最大值${field.max}`, (value) =>
          isNumber(value)
            ? new Decimal(value ?? '').lessThanOrEqualTo(
                (field.max as number).toString()
              )
            : true
        );
      }

      if (field?.specMin) {
        schema = schema.test(
          'specMin',
          `必須大於等於spec最小值${field.specMin}`,
          (value) =>
            isNumber(value)
              ? new Decimal(value ?? '').greaterThanOrEqualTo(
                  (field.specMin as number).toString()
                )
              : true
        );
      }

      if (field?.specMax) {
        schema = schema.test(
          'specMax',
          `必須小於等於spec最大值${field.specMax}`,
          (value) =>
            isNumber(value)
              ? new Decimal(value ?? '').lessThanOrEqualTo(
                  (field.specMax as number).toString()
                )
              : true
        );
      }

      if (field?.controlMin) {
        schema = schema.test(
          'controlMin',
          `必須大於等於control最小值${field.controlMin}`,
          (value) =>
            isNumber(value)
              ? new Decimal(value ?? '').greaterThanOrEqualTo(
                  (field.controlMin as number).toString()
                )
              : true
        );
      }

      if (field?.controlMax) {
        schema = schema.test(
          'controlMax',
          `必須小於等於control最大值${field.controlMax}`,
          (value) =>
            isNumber(value)
              ? new Decimal(value ?? '').lessThanOrEqualTo(
                  (field.controlMax as number).toString()
                )
              : true
        );
      }

      return schema;
    });
  }, []);

  const getEnumSchema = useCallback((field: ProductionFormFieldEnum) => {
    return yup
      .object()
      .shape({
        id: yup.string(),
        name: yup.string(),
      })
      .when([], (value, schema) =>
        field?.required ? schema.required('必填') : schema
      );
  }, []);

  const getTextSchema = useCallback((field: ProductionFormFieldText) => {
    return yup
      .string()
      .when([], (value, schema) =>
        field?.required ? schema.required('必填') : schema
      );
  }, []);

  const getFieldSchema = useCallback(
    (field: ProductionFormResponse['fields']) => {
      switch (field.type) {
        case 'ENUM':
          return getEnumSchema(field);
        case 'NUMERIC':
          return getNumericSchema(field);
        case 'TEXT':
          return getTextSchema(field);
        case 'TIMESTAMP':
          return getDateTimeSchema(field);
        default:
          return null;
      }
    },
    [getDateTimeSchema, getEnumSchema, getNumericSchema, getTextSchema]
  );

  const getFormSchema = useCallback(
    (forms: ProductionFormResponse[]) => {
      return yup.object().shape(
        forms.reduce((result, form) => {
          return Object.assign({}, result, {
            [form.id]: getFieldSchema(form.fields),
          });
        }, {})
      );
    },
    [getFieldSchema]
  );

  return { getFormSchema };
}
