export enum WarehouseModule {
  OperationHistory = 'OperationHistory',
  MaterialStorageIn = 'MaterialStorageIn',
  WarehouseDispatch = 'WarehouseDistribute',
  GeneratedStorageIn = 'GeneratedStorageIn',
  MaterialShift = 'MaterialShift',
  MiscellaneousStorageIn = 'MiscellaneousStorageIn',
  MiscellaneousDispatchAndReturn = 'MiscellaneousDispatchAndReturn',
  LoaderManagement = 'LoaderManagement',
}

export const WarehouseModuleZh = {
  [WarehouseModule.OperationHistory]: '操作歷史紀錄',
  [WarehouseModule.MaterialStorageIn]: '物料入庫',
  [WarehouseModule.WarehouseDispatch]: '倉庫發料',
  [WarehouseModule.GeneratedStorageIn]: '生產入庫',
  [WarehouseModule.MaterialShift]: '物料移轉',
  [WarehouseModule.MiscellaneousStorageIn]: '雜項入庫',
  [WarehouseModule.MiscellaneousDispatchAndReturn]: '雜項發退料',
  [WarehouseModule.LoaderManagement]: '載具管理',
};
