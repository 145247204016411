import { Tab, TabPane, Tabs, Typography } from '@mezzanine-ui/react';
import { useQueryStringWithAutoRouting } from '@solar/templates';

import { createSearchParams } from 'react-router-dom';
import { PageLayout } from '../../Material/PageLayout/PageLayout';

import { tabIds, tabsOptions } from './const';

export const LabelPage = () => {
  const { activeTab, setSearchParams } = useQueryStringWithAutoRouting({
    enableAutoRouting: true,
    tabIds: tabIds,
  });

  return (
    <PageLayout>
      <Typography variant="h1">標籤列印</Typography>
      <Tabs
        activeKey={activeTab || ''}
        onChange={(tabId) => {
          setSearchParams(createSearchParams({ tab: String(tabId) }), {
            replace: true,
          });
        }}
      >
        {tabsOptions.map((option) => (
          <TabPane key={option.id} tab={<Tab>{option.name}</Tab>}>
            <option.panel />
          </TabPane>
        ))}
      </Tabs>
    </PageLayout>
  );
};
