import { useMemo } from "react";
import useSWR from "swr";
import { API_NAMESPACE, request } from "../request";
import { SelectValue } from "@mezzanine-ui/react";
import { TableDataSourceWithKey } from "@mezzanine-ui/core/table";


export function useOrderPredictUpsert(orderId: string | undefined) {
  const { data } = useSWR(
    orderId
      ? [
        '/forecastOrder/get',
        {
          params: { id: orderId },
          method: 'GET',
          namespace: API_NAMESPACE.MISC,
        }
      ]
      : null
  );

  return useMemo(() => data, [data]);
}


interface IAPIItemComponent {
  component: string;
  wt: number;
  wtRate: number;
}

interface IAPIOrderItem {
  certainty: number | null;
  id: string;
  materialId: string;
  materialName: string;
  orderType: number | null;
  price: number;
  qty: number;
  standardWt: number;
  // status: number;
  stockArea: string;
  unit: string;
  components: IAPIItemComponent[];
}

interface IAPIOrderDetail {
  note: string;
  orderDate: string;
  sales: string;
  salesName: string;
  customer: string;
  customerName: string;
  bu: string;
  items: IAPIOrderItem[];
  deleteItemIds?: string[];
  id?: string;
};

export interface IOrderItem extends TableDataSourceWithKey {
  material: SelectValue<string>;
  materialId: string;
  materialName: string;
  unit: SelectValue<string>;
  price: number;
  qty: number;
  certainty: SelectValue<string>;
  orderType: SelectValue<string>;
  standardWt: number;
  stockArea: string;
  component1: SelectValue<string>;
  component2: SelectValue<string>;
  component3: SelectValue<string>;
  component4: SelectValue<string>;
  wt1: number;
  wt2: number;
  wt3: number;
  wt4: number;
  wtRate1: number;
  wtRate2: number;
  wtRate3: number;
  wtRate4: number;
  key: string;
  id: string;
}

export interface IOrderDetail {
  note: string;
  orderDate: string;
  sales: SelectValue<string>;
  customerName: SelectValue<string>;
  bu: string;
  items: IOrderItem[];
  id?: string;
};

export const parseOrderFromAPI = (
  orderDetailByAPI: IAPIOrderDetail,
  autocompleteOptionsCertainty: SelectValue<string>[],
  autocompleteOptionsForecastType: SelectValue<string>[],
)
  : IOrderDetail => {

  // transform API data to form data
  const { items, customer, customerName, sales, salesName, ...rest } = orderDetailByAPI;

  const orderDetail: IOrderDetail = {
    ...rest,
    customerName: { id: customer || customerName, name: customerName },
    sales: { id: sales || salesName, name: salesName },
    items: items.map((item: IAPIOrderItem) => {
      const {
        components,
        certainty,
        orderType,
        unit,
        ...restItem
      } = item;
      const CertaintyOption = autocompleteOptionsCertainty.find((option) => option.id === certainty?.toString());
      const ForecastTypeOption = autocompleteOptionsForecastType.find((option) => option.id === orderType?.toString());
      const component1 = components[0] || {};
      const component2 = components[1] || {};
      const component3 = components[2] || {};
      const component4 = components[3] || {};
      // console.log(component1, component2, component3, component4);
      return {
        ...restItem,
        material: { id: item.materialId, name: item.materialId + '-' + item.materialName },
        component1: { id: component1.component, name: component1.component },
        component2: { id: component2.component, name: component2.component },
        component3: { id: component3.component, name: component3.component },
        component4: { id: component4.component, name: component4.component },
        wt1: component1.wt,
        wt2: component2.wt,
        wt3: component3.wt,
        wt4: component4.wt,
        wtRate1: component1.wtRate,
        wtRate2: component2.wtRate,
        wtRate3: component3.wtRate,
        wtRate4: component4.wtRate,
        key: Math.random().toString(36).substr(2, 9),
        // 如果有 price 跟 qty 都是數字才計算，不然都顯示 0
        totalPrice: (item.price && item.qty) ? item.price * item.qty : 0,
        certainty: CertaintyOption || { id: '', name: '' },
        orderType: ForecastTypeOption || { id: '', name: '' },
        unit: { id: unit, name: unit },
      }
    }),
  };
  return orderDetail;
}

export const parseOrderToAPI = (orderDetail: IOrderDetail): IAPIOrderDetail => {
  // transform form data to API data
  const { items, customerName, sales, ...rest } = orderDetail;
  const orderDetailByAPI: IAPIOrderDetail = {
    ...rest,
    customer: customerName?.id,
    customerName: customerName?.name,
    sales: sales?.id,
    salesName: sales?.name,
    items: items.map((item: IOrderItem) => {
      const {
        material,
        component1,
        component2,
        component3,
        component4,
        wt1,
        wt2,
        wt3,
        wt4,
        wtRate1,
        wtRate2,
        wtRate3,
        wtRate4,
        certainty,
        orderType,
        unit,
        ...restItem
      } = item;
      const components: IAPIItemComponent[] = [];
      if (component1?.id) {
        components.push({
          component: component1.id,
          wt: wt1,
          wtRate: wtRate1,
        });
      }
      if (component2?.id) {
        components.push({
          component: component2.id,
          wt: wt2,
          wtRate: wtRate2,
        });
      }
      if (component3?.id) {
        components.push({
          component: component3.id,
          wt: wt3,
          wtRate: wtRate3,
        });
      }
      if (component4?.id) {
        components.push({
          component: component4.id,
          wt: wt4,
          wtRate: wtRate4,
        });
      }
      return {
        ...restItem,
        materialId: material?.id,
        components,
        certainty: certainty ? Number(certainty.id) : null,
        orderType: orderType ? Number(orderType.id) : null,
        unit: unit?.id,
      }
    }),
  };
  return orderDetailByAPI;
}

export const insertOrderPredict = async (orderData: IAPIOrderDetail) => {
  return request('/forecastOrder/insert', {
    body: JSON.stringify(orderData),
    method: 'POST',
    namespace: API_NAMESPACE.MISC,
  });
};

export const updateOrderPredict = async (orderData: IAPIOrderDetail) => {
  return request('/forecastOrder/update', {
    body: JSON.stringify(orderData),
    method: 'POST',
    namespace: API_NAMESPACE.MISC,
  });
};