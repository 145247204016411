import { useState, useEffect } from 'react';
import { SelectValue } from '@mezzanine-ui/react';
import { SelectField } from '@mezzanine-ui/react-hook-form';
import { useFormContext, useWatch } from 'react-hook-form';

type CustomDependentSelectorsProps = {
  data: any;
  labelAndRegisterKeyArray: Array<{ label: string; registerName: string }>;
  thisSelectorLevel: number;
};

export function CustomDependentSelectors({
  data,
  labelAndRegisterKeyArray,
  thisSelectorLevel,
}: CustomDependentSelectorsProps) {
  const [nextLevelData, setNextLevelData] = useState<any>([]);
  const thisLevelLabel = labelAndRegisterKeyArray[thisSelectorLevel].label;
  const thisLevelRegisterName =
    labelAndRegisterKeyArray[thisSelectorLevel].registerName;
  const thisLevelValue = useWatch({
    name: thisLevelRegisterName,
  }) as SelectValue | undefined;
  const { getValues, setValue } = useFormContext();
  const hasNextLevel = thisSelectorLevel + 1 < labelAndRegisterKeyArray.length;
  const isFieldCleared = thisLevelValue === undefined;

  // 監聽 SelectField onClear(按 Ｘ) 後, 清空右側 Selector value
  useEffect(() => {
    if (thisLevelValue === undefined) {
      for (
        let i = thisSelectorLevel;
        i <= labelAndRegisterKeyArray.length - 1;
        i++
      ) {
        if (getValues(labelAndRegisterKeyArray[i].registerName)) {
          console.log(labelAndRegisterKeyArray[i].registerName);
          setValue(labelAndRegisterKeyArray[i].registerName, undefined);
        }
      }
    }
  }, [
    getValues,
    labelAndRegisterKeyArray,
    setValue,
    thisLevelValue,
    thisSelectorLevel,
  ]);
  return (
    <>
      <SelectField
        value={thisLevelValue || undefined}
        label={thisLevelLabel}
        registerName={thisLevelRegisterName}
        clearable
        key={thisLevelValue?.id}
        options={data || []}
        onChange={(v) => {
          if (labelAndRegisterKeyArray.length === thisSelectorLevel + 1) {
            return;
          }

          // 往右清空
          for (
            let i = thisSelectorLevel;
            i < labelAndRegisterKeyArray.length - 1;
            i++
          ) {
            if (getValues(labelAndRegisterKeyArray[i + 1]?.registerName)) {
              setValue(labelAndRegisterKeyArray[i + 1].registerName, null);
            }
          }
          // 提供下一個選單 options
          const tempNextLevelData = data.find(
            (location: SelectValue) => location.id === v.id
          );
          const targetKey =
            labelAndRegisterKeyArray[thisSelectorLevel + 1].registerName;

          // Object.keys(tempNextLevelData).find(
          //   (k) =>
          // ) as string;
          setNextLevelData(tempNextLevelData[targetKey]);
        }}
      />

      {hasNextLevel && (
        <CustomDependentSelectors
          data={nextLevelData}
          labelAndRegisterKeyArray={labelAndRegisterKeyArray}
          thisSelectorLevel={thisSelectorLevel + 1}
        />
      )}
    </>
  );
}
