import type { ComponentProps } from 'react';
import type { CustomerTradeConditionExportFormValues } from '@solar/data';
import type { UseFormReturn } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import { Typography, MenuDivider, Button, Message } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { FormProvider } from 'react-hook-form';
import { useCustomerTradeConditionExport } from '@solar/data';
import styles from './index.module.scss';

type Props = ComponentProps<'div'> & {
  exposeFormMethodsForInitializeFilterPanel: (
    methods: UseFormReturn<CustomerTradeConditionExportFormValues>
  ) => void;
  applyFilterCondition: () => void;
};

export function FilterPanelForm({
  children,
  exposeFormMethodsForInitializeFilterPanel,
  applyFilterCondition,
}: Props) {
  const { formMethods, createFormSubmit, downloadFromFilePath } =
    useCustomerTradeConditionExport();

  const searchButtonRef = useRef<HTMLButtonElement>(null);

  const submitSuccessCallback = (payload: { filePath: string }) => {
    downloadFromFilePath(payload?.filePath);
    Message.success('匯出 Excel 成功');
  };
  const submitErrorCallback = (error: Error) => {
    if (error?.message) {
      const { message } = JSON.parse(error.message) ?? {};
      Message.error(`匯出 Excel 失敗${message ? '：' + message : ''}`);
    }
  };

  useEffect(() => {
    exposeFormMethodsForInitializeFilterPanel(formMethods);
  }, [formMethods, exposeFormMethodsForInitializeFilterPanel]);

  return (
    <div className={styles.wrapper}>
      <FormProvider {...formMethods}>
        <FormFieldsWrapper
          methods={formMethods}
          onSubmit={createFormSubmit({
            successCallback: submitSuccessCallback,
            errorCallback: submitErrorCallback,
          })}
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault();
            if (e.key === 'Enter' && searchButtonRef.current) {
              searchButtonRef.current.click();
            }
          }}
        >
          <Typography variant="h4">查詢</Typography>
          <MenuDivider className={styles.divider} />
          <div className={styles.filterGrid}>
            {children}
            <div
              style={{ gridColumn: 'span 4 / span 4', justifySelf: 'right' }}
            >
              <Button
                ref={searchButtonRef}
                type="button"
                variant="contained"
                style={{ marginRight: '1rem' }}
                onClick={applyFilterCondition}
              >
                查詢
              </Button>
              <Button type="submit" variant="contained">
                匯出 Excel
              </Button>
            </div>
          </div>
        </FormFieldsWrapper>
      </FormProvider>
    </div>
  );
}
