import { uniqBy } from 'lodash';
import {
  ProductionFormCondition,
  ProductionFormResponse,
} from './production-process-form.type';
import { API_NAMESPACE, request } from '../request';

const namespace = API_NAMESPACE.PP;

export async function getProductionForms(
  conditions: ProductionFormCondition[]
) {
  let forms: ProductionFormResponse[] = [];
  const materialIdFormsMap: Map<string, ProductionFormResponse[]> = new Map([]);

  try {
    for (const {
      materialId,
      recipeId,
      processingId,
      workCenterId,
      equipmentId,
      templateTypes = ['PRODUCT', 'EQUIPMENT'],
    } of conditions) {
      const response = (await request('/forms', {
        namespace,
        params: {
          materialId,
          recipeId,
          processingId,
          workCenterId,
          equipmentId,
          templateTypes,
        },
      })) as ProductionFormResponse[];

      if (!Array.isArray(response)) continue;

      forms = uniqBy(forms.concat(response), (row) => row.id);

      if (!materialId) continue;

      const targetForms = materialIdFormsMap.get(materialId) ?? [];

      materialIdFormsMap.set(
        materialId,
        uniqBy(targetForms.concat(response), (row) => row.id)
      );
    }
  } catch (error) {
    console.error(error);
  }

  return { forms, materialIdFormsMap };
}
