import { TableColumn } from '@mezzanine-ui/core/table';
import { useCallback, useMemo } from 'react';
import { TempMaterial } from './typings';
import { useMaterialHierarchy } from '../use-material-hierarchy';
import { ResultDetail } from '../typings';
import { Tag } from '@mezzanine-ui/react';
import styles from './table.module.scss';

export function useTableColumn(): TableColumn<TempMaterial>[] {
  const hierarchy = useMaterialHierarchy();

  const matchDescription = useCallback(
    (fullMaterialId: string) => {
      const getMatch = (materialId: string): ResultDetail | null => {
        if (!materialId) return null;

        return (
          hierarchy.get(materialId) ??
          getMatch(materialId.substring(0, materialId.length - 1))
        );
      };

      return getMatch(fullMaterialId);
    },
    [hierarchy]
  );

  return useMemo<TableColumn<TempMaterial>[]>(
    () => [
      {
        dataIndex: 'materialId',
        title: '料號',
        width: 320,
      },
      {
        dataIndex: 'oldMaterialId',
        title: '舊料號',
        width: 160,
      },
      {
        dataIndex: 'description',
        title: '描述',
      },
      {
        title: '陳述',
        render: (row) => {
          const matchedDescription = matchDescription(row.id);

          if (!matchedDescription) return '-';

          return (
            <div className={styles.tagWrapper}>
              {matchedDescription.hierarchy.map((item) => (
                <Tag size="small">{item.name}</Tag>
              ))}
            </div>
          );
        },
      },
    ],
    [matchDescription]
  );
}
