import {
  MenuDivider,
  Message,
  ModalActions,
  SelectValue,
} from '@mezzanine-ui/react';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import {
  DeliveryOrderDetail,
  updateProductionDeliveryOrderReceipt,
  useGetDeliveryOrderDetail,
  useInventoryByPurchaseOrder,
} from '@solar/data';
import {
  LocationSelectorProvider,
  useLocationSelectorController,
  useTargetModal,
} from '@solar/templates';
import { Table } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { LOCATION_SELECTOR } from '../../../Equipment/LocationSelector/LocationSelectorModal';
import { ModalLayout } from '../../ModalLayout/ModalLayout';
import { useProductionDeliveryOrderReceiptModalColumns } from '../hooks';
import { InspectMaterialType } from '../inspect-modal-enum';
import { WarehouseAndVehicleFilter } from './WarehouseAndVehicleFilter';
import { ProductionDeliveryOrderReceiptModalColumns } from '../hooks/table-columns/detail-page/types';

const sysErrorMsg = '系統錯誤，請嘗試重新整理';

// const validateSchema = Yup.object({
//   table: Yup.array().of(
//     Yup.object().shape({
//       weight: Yup.string().required('重量必填'),
//     })
//   ),
// });

type TempReceiveActionModal = {
  locationSelectorController: ReturnType<typeof useLocationSelectorController>;
};

const updater = {
  [InspectMaterialType.HubReturn]: updateProductionDeliveryOrderReceipt,
  [InspectMaterialType.CustomerOrder]: updateProductionDeliveryOrderReceipt,
};

export function ProductionDeliveryOrderReceiptModal({
  locationSelectorController: controller,
}: TempReceiveActionModal) {
  const { orderType } = useParams<{
    orderType: InspectMaterialType.HubReturn;
  }>();
  const methods = useForm<ProductionDeliveryOrderReceiptModalColumns>({
    defaultValues: {
      table: [],
    },
    // resolver: yupResolver(validateSchema),
  });

  const { orderId } = useParams();
  const { deliveryOrder: data } = useGetDeliveryOrderDetail(orderId);
  const columns = useProductionDeliveryOrderReceiptModalColumns({
    formMethods: methods,
  });

  const locationSelectorModalController =
    useTargetModal<null>(LOCATION_SELECTOR);
  const receiveActionModalController =
    useTargetModal<DeliveryOrderDetail['specs'][number]['materialItems']>(
      'ReceiveAction'
    );
  const handleReceiveActionModalClose = useCallback(() => {
    methods.reset();
    receiveActionModalController.closeModal();
  }, []);

  const warehouseAndVehicleFilterRef =
    useRef<React.ElementRef<typeof WarehouseAndVehicleFilter>>(null);

  const location = useLocation();
  const { mutate } = useInventoryByPurchaseOrder({
    orderId:
      location.pathname.split('/')[2] === InspectMaterialType.PurchaseOrder
        ? orderId
        : undefined,
  });

  const watchedTable = useFieldArray({
    control: methods.control,
    name: 'table',
  });

  useEffect(() => {
    if (data && data.specs && !watchedTable?.fields.length) {
      methods.setValue(
        'table',
        data.specs
          .flatMap((parentBatch) => parentBatch.materialItems)
          .map((item, index) =>
            Object.assign({ parentBatchIndex: index }, item)
          )
      );
    }
  }, [receiveActionModalController.open, watchedTable.fields]);

  const [receiving, setReceiving] = useState(false);

  return (
    <ModalLayout
      style={{ width: '1500px' }}
      loading={methods.formState.isSubmitting}
      modalHeader="收料"
      modalFooter={
        <ModalActions
          confirmText="確認"
          cancelText="取消"
          confirmButtonProps={{ form: 'receiveActionForm', loading: receiving }}
          onCancel={() => handleReceiveActionModalClose()}
        />
      }
      {...receiveActionModalController}
      closeModal={handleReceiveActionModalClose}
    >
      <LocationSelectorProvider controller={controller}>
        <FormFieldsWrapper
          id="receiveActionForm"
          methods={methods}
          onSubmit={async ({ table }) => {
            if (!orderType) {
              Message.error(sysErrorMsg);

              throw '';
            }

            try {
              setReceiving(true);

              const { selectedIds } = controller;
              const shelfId =
                selectedIds?.shelfId ??
                selectedIds?.stackId ??
                selectedIds?.zoneId;

              if (!shelfId) {
                warehouseAndVehicleFilterRef.current?.setLocationRequired(true);
                return;
              } else {
                warehouseAndVehicleFilterRef.current?.setLocationRequired(
                  false
                );
              }

              const payload = {
                items: table.map((row) => {
                  return {
                    specId: row.productionDeliveryOrderSpecId,
                    toShelfId: shelfId,
                    toLoaderId: null,
                    materialInputUnit: row?.quantityUnit ?? '',
                    batches:
                      row?.subBatches?.map((subBatch) => ({
                        materialInputUnitQuantity:
                          subBatch?.materialInputUnitQuantity?.trim()
                            ? String(subBatch.materialInputUnitQuantity).trim()
                            : undefined,
                        materialWeightUnitQuantity: subBatch?.weight?.trim()
                          ? String(subBatch.weight).trim()
                          : undefined,
                        materialGrossWeightUnitQuantity:
                          subBatch?.weight?.trim()
                            ? String(subBatch.weight).trim()
                            : undefined,
                        remark: subBatch.remark ?? null,

                        deliveredSapBatchId:
                          subBatch?.batchOptions?.[
                            Number(subBatch?.batch?.id) ?? 0
                          ]?.materialSapBatchId ?? null,
                        deliveredSubBatchId:
                          subBatch?.batchOptions?.[
                            Number(subBatch?.batch?.id) ?? 0
                          ]?.materialSubBatchId ?? null,
                        deliveredOrderId:
                          subBatch?.batchOptions?.[
                            Number(subBatch?.batch?.id) ?? 0
                          ].deliveredOrderId ?? null,
                        deliveredOrderLine:
                          subBatch?.batchOptions?.[
                            Number(subBatch?.batch?.id) ?? 0
                          ].deliveredOrderLine ?? null,
                      })) ?? [],
                    remark: null,
                  };
                }),
                recycleNumber: methods.getValues('recycleNumber'),
              }; //satisfies ProductionDeliveryOrderReceiptBody;

              const res = await updater[orderType](payload as any);

              if (res.ok) {
                Message.success('收料成功');
                handleReceiveActionModalClose();
                await mutate(undefined, true);
              }
            } catch (err) {
              console.error(err);
              if (err instanceof Error) {
                Message.error(JSON.parse(err.message).message);
              } else {
                Message.error(sysErrorMsg);
              }
            } finally {
              setReceiving(false);
            }
          }}
        >
          <WarehouseAndVehicleFilter
            openLocationSelectorModal={
              locationSelectorModalController.openModal
            }
            ref={warehouseAndVehicleFilterRef}
            locationLabel="存放儲位"
          />
          <MenuDivider />
          <InputField
            width={300}
            registerName="recycleNumber"
            label="環保聯單號"
            onKeyPress={(e) => {
              if (e.key == 'Enter') {
                e.preventDefault();
              }

              const event = e as unknown as React.ChangeEvent<HTMLInputElement>;

              if (event.target.value.length >= 16) {
                event.preventDefault();
                event.stopPropagation();
              }
            }}
          />
          {methods.watch('table').map((parentBatch, index) => (
            <Table
              scroll={{ x: 1500 }}
              pagination={false}
              columns={columns as any}
              dataSource={[parentBatch, ...(parentBatch?.subBatches ?? [])]}
            />
          ))}
        </FormFieldsWrapper>
      </LocationSelectorProvider>
    </ModalLayout>
  );
}
