import { API_NAMESPACE, request } from '@solar/data';

const namespace = API_NAMESPACE.MATERIALS;

export const syncMiscMaterialFromSap = (): Promise<Response> => {
  return request('/misc-material-sap/sync', {
    namespace,
    method: 'post',
    responseParser: (res) => res,
  });
};

export async function syncFixedAssetPurchaseOrder() {
  return request('/fixed-asset-material-sap/sync', {
    namespace,
    method: 'post',
    responseParser: (res) => res,
  });
}
