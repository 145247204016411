import { useCallback, useMemo, useRef, useState } from 'react';

import { Button, Icon, Message, SelectValue } from '@mezzanine-ui/react';

import { useForm } from 'react-hook-form';

import { InspectStatus, InspectStatusTag } from './InspectStatusTag';

import {
  NextPagination,
  getNextParams,
  syncPurchaseOrdersFromSAP,
  useInventoryByPurchaseOrders,
} from '@solar/data';

import { useNavigate } from 'react-router-dom';
import { Filter } from './Filters/PurchaseOrder/Filter';
import { TableColumn, TableExpandable } from '@mezzanine-ui/core/table';
import { PurchaseOrderTableProps } from './typings';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  MznPaginationTable,
  FilterScaffold,
  mznTableRowExpandHelper,
  CopyTextWrapper,
} from '@solar/templates';
import sharedStyles from '../shared-styles/index.module.scss';
import { PageLayout } from '../PageLayout/PageLayout';
import { ChevronDownIcon } from '@mezzanine-ui/icons';
import moment from 'moment';

export function PurchaseOrderPanel() {
  const filterMethods = useForm();
  const navigate = useNavigate();
  const tableRef = useRef<HTMLTableElement>(null);

  const [syncing, setSyncing] = useState(false);

  // table data
  const {
    orders,
    pageInfo,
    isLoading,
    refetchInventoryByPurchaseOrders,
    mutateInventoryByPurchaseOrders,
  } = useInventoryByPurchaseOrders();

  const refetchInventoryByPurchaseOrdersByPagination = useCallback(
    (nextPagination: NextPagination) => {
      const filterState = filterMethods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);

      refetchInventoryByPurchaseOrders({
        offset,
        limit,
        searchTerm: filterState?.searchTerm,
        dateFrom: (filterState?.dateRange ?? [])[0],
        dateTo: (filterState?.dateRange ?? [])[1],
        supplierIds: filterState?.suppliers?.map(
          (supplier: SelectValue) => supplier.id
        ),
        receivedStates: filterState?.status?.map(
          (status: SelectValue) => status?.id
        ),
      });
    },
    [filterMethods, refetchInventoryByPurchaseOrders, pageInfo]
  );

  const columns: TableColumn<PurchaseOrderTableProps>[] = useMemo(
    () => [
      {
        title: '操作',
        width: 100,
        align: 'start',
        render(source) {
          return (
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                navigate(`${source.id}`);
              }}
            >
              檢視
            </Button>
          );
        },
      },
      {
        draggable: true,
        width: 200,
        title: '採購單號',
        render: (source, index) => {
          return (
            <>
              <Button
                type="button"
                onClick={() => {
                  mznTableRowExpandHelper({ ref: tableRef, index });
                }}
                prefix={<Icon icon={ChevronDownIcon} />}
              />
              <span>{source.id ?? ''}</span>
            </>
          );
        },
      },
      {
        draggable: true,
        width: 500,
        title: '應收日',
        render: (source) =>
          source['expectedCompletedAt']
            ? moment(source['expectedCompletedAt']).format('YYYY-MM-DD')
            : '',
      },
      { title: '供應商名稱', dataIndex: 'supplierName' },
      { title: '供應商代碼', dataIndex: 'supplierId' },
      { title: '請購人員', dataIndex: 'staffName' },
      {
        title: '狀態',
        render: (source) => {
          return (
            <InspectStatusTag status={source.receivedState as InspectStatus} />
          );
        },
      },
    ],
    [navigate]
  );

  const expandable = useMemo<TableExpandable<PurchaseOrderTableProps>>(
    () => ({
      rowExpandable: () => true,
      expandedRowRender: (record) => {
        return {
          dataSource: record.items ?? [],

          columns: [
            { dataIndex: 'null-1' },
            {
              width: 200,
              dataIndex: 'materialId',
              render: (source) => (
                <CopyTextWrapper text={(source?.materialId as string) ?? ''} />
              ),
            },
            {
              dataIndex: 'materialDescription',
              width: 500,
              render: (source) => (
                <CopyTextWrapper
                  text={(source?.materialDescription as string) ?? ''}
                />
              ),
            },

            { dataIndex: 'unit' },
            { dataIndex: 'null-2' },
            { dataIndex: 'null-3' },
            { dataIndex: 'null-4' },
          ],
        };
      },
    }),
    []
  );

  const refetchInventoryByPurchaseOrdersFromSAP = useCallback(async () => {
    try {
      setSyncing(true);

      await syncPurchaseOrdersFromSAP();

      Message.success('同步成功');

      await mutateInventoryByPurchaseOrders(undefined, true);
    } catch (error) {
      console.log(error);
    } finally {
      setSyncing(false);
    }
  }, []);

  return (
    <PageLayout>
      <FormFieldsWrapper methods={filterMethods}>
        <FilterScaffold
          mutateParams={refetchInventoryByPurchaseOrders}
          fetchAsParamsMutated={false}
        >
          <Filter />
        </FilterScaffold>
      </FormFieldsWrapper>
      <div style={{ display: 'flex', gap: '12px' }}>
        <Button
          variant="outlined"
          type="button"
          loading={isLoading || syncing}
          onClick={() =>
            refetchInventoryByPurchaseOrdersByPagination({ nextPage: 1 })
          }
        >
          搜尋
        </Button>
        <Button
          variant="contained"
          type="button"
          loading={syncing}
          onClick={() => refetchInventoryByPurchaseOrdersFromSAP()}
        >
          重新整理
        </Button>
      </div>
      <div ref={tableRef}>
        <MznPaginationTable
          columns={columns}
          loading={isLoading || syncing}
          dataSource={orders ?? []}
          expandable={expandable}
          fetchData={refetchInventoryByPurchaseOrdersByPagination}
          hideDefaultExpandColumn
          pageInfo={pageInfo}
          scroll={{ x: 1200 }}
          bodyClassName={sharedStyles.table}
        />
      </div>
    </PageLayout>
  );
}
