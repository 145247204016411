import {
  DateRangePickerField,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  DeliveryOrder,
  GetDeliveryOrdersParams,
  NextPagination,
  getNextParams,
  useGetDeliveryOrders,
} from '@solar/data';
import {
  CopyTextWrapper,
  MznPaginationTable,
  PageLayout,
} from '@solar/templates';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  INSPECT_STATUS_OPTIONS,
  InspectStatus,
  InspectStatusTag,
} from './InspectStatusTag';
import { Space } from 'antd';
import { Button, SelectValue } from '@mezzanine-ui/react';
import moment from 'moment';
import { CustomerOrderTableProps } from './typings';
import { TableColumn } from '@mezzanine-ui/core/table';
import { useNavigate } from 'react-router';

export function RentTargetPanel() {
  const navigate = useNavigate();
  const methods = useForm();

  const { deliveryOrders, pageInfo, isLoading, refetchGetDeliveryOrders } =
    useGetDeliveryOrders({
      types: ['ZLR', 'ZLR4'],
    });

  const refetchGetDeliveryOrdersWithPagination = useCallback(
    (nextPagination: NextPagination) => {
      const formState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      refetchGetDeliveryOrders({
        expectedCompletedDateFrom: formState?.createdDateRange?.[0],
        expectedCompletedDateTo: formState?.createdDateRange?.[1],
        orderIds: formState?.orderId ? [formState?.orderId] : undefined,
        customerNames: formState?.customerName
          ? [formState?.customerName]
          : undefined,
        employeeNames: formState?.employeeName
          ? [formState?.employeeName]
          : undefined,
        packedStates:
          (formState?.packedStates?.map(
            (row: SelectValue) => row.id
          ) as GetDeliveryOrdersParams['packedStates']) ?? undefined,
        sortType: formState?.sortType
          ?.id as GetDeliveryOrdersParams['sortType'],
        types: ['ZLR', 'ZLR4'],
        offset,
        limit,
      });
    },
    [methods, pageInfo, refetchGetDeliveryOrders]
  );

  const columns: TableColumn<CustomerOrderTableProps>[] = useMemo(
    () => [
      {
        title: '操作',
        width: 120,
        align: 'center' as const,
        render(source) {
          return (
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                navigate(`${source.id}`);
              }}
            >
              檢視
            </Button>
          );
        },
      },
      {
        title: '出貨單號',
        render: (source) => <CopyTextWrapper text={source?.id ?? ''} />,
      },
      // { title: '出貨單類形', dataIndex: 'orderTypeName' },
      {
        title: '預計收貨日期',
        render: (record) =>
          record?.expectedCompletedAt
            ? moment(record.expectedCompletedAt).format('YYYY-MM-DD')
            : '',
      },
      { title: '客戶名稱', dataIndex: 'customerName' },
      { title: '客戶代碼', dataIndex: 'customerId' },
      { title: '業務人員', dataIndex: 'employeeName' },
      {
        title: '狀態',
        render: (source) => {
          return (
            <InspectStatusTag status={source.packedState as InspectStatus} />
          );
        },
      },
    ],
    [navigate]
  );

  return (
    <Space direction="vertical" size="large">
      <FormFieldsWrapper methods={methods}>
        <Space direction="vertical">
          <Space style={{ width: '100%' }}>
            <InputField registerName="orderId" placeholder="請輸入出貨單號" />
            <InputField
              registerName="customerName"
              placeholder="請輸入客戶名稱"
            />
            <InputField
              registerName="employeeName"
              placeholder="請輸入業務人員"
            />
          </Space>
          <Space style={{ width: '100%' }}>
            <DateRangePickerField
              fullWidth
              label="日期區間："
              registerName="createdDateRange"
            />
            <SelectField
              label="狀態："
              mode="multiple"
              clearable
              registerName="packedStates"
              options={[...INSPECT_STATUS_OPTIONS]}
            />
            <SelectField
              label="日期排序："
              clearable
              registerName="sortType"
              options={[
                {
                  id: 'EXPECTED_COMPLETED_AT_DESC',
                  name: '預期收貨日期近 -> 遠',
                },
                {
                  id: 'EXPECTED_COMPLETED_AT_ASC',
                  name: '預期收貨日期遠 -> 近',
                },
              ]}
            />
          </Space>
          <Space>
            <Button
              variant="outlined"
              type="button"
              onClick={() =>
                refetchGetDeliveryOrdersWithPagination({ nextPage: 1 })
              }
            >
              搜尋
            </Button>
            {/* <Button variant="contained" type="button" onClick={() => ''}>
              重新整理
            </Button> */}
          </Space>
        </Space>
      </FormFieldsWrapper>
      <MznPaginationTable
        loading={isLoading}
        fetchData={refetchGetDeliveryOrdersWithPagination}
        pageInfo={pageInfo}
        columns={columns}
        dataSource={deliveryOrders ?? []}
      />
    </Space>
  );
}
