/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useState } from 'react';
import useSWR from 'swr';

type UseSVGsOptions = {
  showSVG?: boolean;
};

export function useSVGs(options?: UseSVGsOptions) {
  const showSVG = options?.showSVG ?? true;
  const [filename, setFilename] = useState<string>();

  const responseParser = useCallback((res: Response) => {
    if (!res.ok) return res.json();
    return res.text();
  }, []);

  const { data, error, mutate } = useSWR(
    filename ? [`/svgs/${filename}.svg`, { responseParser }] : null
  );

  const resetSVG = () => {
    mutate(null);
    setFilename(undefined);
  };

  return showSVG
    ? {
        svg: data,
        error,
        isLoading: !data && !error,
        mutateGetSVG: setFilename,
        resetSVG,
      }
    : {
        svg: undefined,
        error: undefined,
        isLoading: false,
        mutateGetSVG: () => {},
        resetSVG: () => {},
      };
}
