// import { Message } from "@mezzanine-ui/react";
import { Stack } from '../locations';
import { request } from '../request';

export async function getStackByChartId({ chartId }: { chartId: string }) {
  const response = await request(`/stacks-by-chart-id/${chartId}`, {
    method: 'get',
    responseParser: (res) => res,
  });
  const result: Stack = await response.json();
  if (!response.ok) return undefined;
  return result;
}
