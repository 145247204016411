import { Table } from '@mezzanine-ui/react';
import styles from './../../TargetNumber.module.scss';
import { useTargetRuleTableColumns } from './useTargetRuleTableColumns';
import { useEffect, useMemo, useState } from 'react';
import { TargetRuleMgmtDeleteModal } from './Modal/TargetRuleMgmtDisableModal';
import {
  TargetNumberRulesParams,
  useGetTargetNumberRules,
  useGetTargetRuleCustomersForSearch,
} from '@solar/data';
import { useNavigate } from 'react-router-dom';

interface TargetRuleTableProps {
  filterParams: TargetNumberRulesParams;
}

interface Customer {
  id: string;
  name: string;
}

interface Rule {
  customers: Array<{ customer_id: string }>;
}

export function TargetRuleTable({ filterParams }: TargetRuleTableProps) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState<string | null>(null);

  const openDeleteModal = (id: string) => {
    setDeleteModalId(id);
    setDeleteModalShow(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalShow(false);
  };

  const variables = useMemo(
    () => ({
      ...filterParams,
      page_at: page,
      page_size: pageSize,
    }),
    [filterParams, page, pageSize]
  );

  const {
    numberRulesListing = [],
    total,
    refetchGetTargetNumberRules,
    isLoading,
  } = useGetTargetNumberRules(variables);
  const { targetRuleCustomers } = useGetTargetRuleCustomersForSearch('') || [];

  const mergedData = numberRulesListing.map((rule: Rule) => {
    const customerInfo = targetRuleCustomers?.find((customer: Customer) => {
      const isMatch = customer.id === rule.customers[0]?.customer_id;
      // if (isMatch) {
      //   console.log(`Match found for customer_id: ${customer.id}`);
      // }
      return isMatch;
    });
    return {
      ...rule,
      customer_name: customerInfo?.name || 'N/A',
    };
  });

  useEffect(() => {
    setPage(1);
  }, [filterParams]);

  const navigate = useNavigate();
  const columns = useTargetRuleTableColumns(
    (id: string) => openDeleteModal(id),
    navigate
  );

  return (
    <>
      <Table
        className={styles.table}
        columns={columns}
        scroll={{ x: 1600 }}
        dataSource={mergedData}
        expandable={{
          expandedRowRender(record: any) {
            const { materials } = record;
            return (
              <div
                style={{
                  padding: '0px 20px 20px 20px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  boxSizing: 'border-box',
                }}
              >
                <Table
                  className={styles.table}
                  columns={[
                    {
                      title: '物料編號',
                      dataIndex: 'material_id',
                      width: 400,
                    },
                    {
                      title: '敘述',
                      dataIndex: 'description',
                      width: 400,
                    },
                  ]}
                  dataSource={materials}
                  scroll={{ x: 700 }}
                  bodyClassName={styles.tableBody}
                />
              </div>
            );
          },
          rowExpandable: () => true,
        }}
        loading={isLoading}
        bodyClassName={styles.tableBody}
        pagination={{
          disableAutoSlicing: true,
          current: page,
          total: total,
          onChange: setPage,
          options: {
            pageSize: pageSize,
            onChangePageSize: setPageSize,
            showJumper: true,
            jumperButtonText: '前往',
            jumperHintText: '跳至',
            jumperInputPlaceholder: '頁碼',
            className: styles.pagination,
          },
        }}
      />
      <TargetRuleMgmtDeleteModal
        open={deleteModalShow}
        onClose={closeDeleteModal}
        id={deleteModalId}
        refetch={refetchGetTargetNumberRules}
      />
    </>
  );
}
