import React, { useEffect, useState } from 'react';
import { ProductionTrackingModel } from '@solar/data';
import {
  FlowAnalysisGraph,
  FlowAnalysisGraphConfig,
  IG6GraphEvent,
} from '@ant-design/charts';
import { DagreLayout } from '@antv/layout';
import moment from 'moment';
import { Button, Message } from '@mezzanine-ui/react';
import { Divider, Space } from 'antd';
import { useTrackingTreeEvents } from './use-tracking-tree-events';
import styles from './tracking-page.module.scss';
import {
  TrackingTreeNodeModal,
  useTrackingTreeNodeModal,
} from './TrackingTreeNodeModal';

type Props = {
  data?: ProductionTrackingModel;
};

type TreeGraphDisplayItem = {
  text: string;
  value?: string;
};
type TreeGraphValue = {
  title?: string;
  items?: TreeGraphDisplayItem[];
};
type TreeGraphData = {
  id: string;
  value: TreeGraphValue;
};
type TreeGraphEdge = {
  source: string;
  target: string;
};

export function TrackingTree(props: Props) {
  const {
    contextMenuProps,
    closeContextMenu,
    onNodeLeftClick,
    onNodeRightClick,
    onCanvasLeftClick,
    onCanvasLeftDbClick,
    onCanvasRightClick,
  } = useTrackingTreeEvents();

  const { modalData, modalOpen, onOpen, onClose } = useTrackingTreeNodeModal();

  if (!props.data) return null;

  const nodes: TreeGraphData[] = [];
  const edges: TreeGraphEdge[] = [];

  function transformToTreeGraph(
    data?: ProductionTrackingModel[],
    parentId?: string
  ) {
    data?.forEach((data) => {
      transformToTreeGraph(data.parentRecords, data.id);
      const displayValue: TreeGraphValue = {
        title: data.materialId,
        items: [
          {
            text: data.sapBatchId + '-' + data.subBatchId,
          },
          {
            text: data.parentRecords?.length
              ? data.parentRecords[0].equipmentName || ''
              : '',
          },
          {
            text: data.parentRecords?.length
              ? moment(data.parentRecords[0].createdAt).format(
                  'yyyy/MM/DD HH:mm:ss'
                ) || ''
              : '',
          },
        ],
      };
      if (data.batchNote) {
        if (!displayValue.items) {
          displayValue.items = [];
        }
        displayValue.items.push({
          text: data.batchNote,
        });
      }
      nodes.push({ id: data.id, value: displayValue });
      edges.push({ source: parentId || 'root', target: data.id });
    });
  }
  transformToTreeGraph([props.data]);
  // console.log(nodes);
  const rootNode: TreeGraphData = {
    id: 'root',
    value: {
      title: props.data.materialId,
      items: [
        {
          text: props.data.sapBatchId + '-' + props.data.subBatchId,
        },
        {
          text: props.data.parentRecords?.length
            ? props.data.parentRecords[0].equipmentName || ''
            : '',
        },
        {
          text: props.data.parentRecords?.length
            ? moment(props.data.parentRecords[0].createdAt).format(
                'yyyy/MM/DD HH:mm:ss'
              ) || ''
            : '',
        },
      ],
    },
  };
  nodes.push(rootNode);
  // "LR" | "RL" | "TB" | "BT" | "H" | "V" | undefined
  enum Direction {
    LR = 'LR',
    RL = 'RL',
    TB = 'TB',
    BT = 'BT',
  }
  enum Position {
    LEFT = 'left',
    RIGHT = 'right',
    TOP = 'top',
    BOTTOM = 'bottom',
  }
  const config: FlowAnalysisGraphConfig = {
    data: {
      nodes: nodes,
      edges: edges,
    },
    layout: {
      rankdir: 'TB',
      ranksepFunc: () => 50,
      nodesep: 100,
    } as DagreLayout,
    tooltipCfg: { show: false },
    menuCfg: { show: false },
    nodeCfg: {
      size: [140, 50],
      anchorPoints: [
        [0.5, 0],
        [0.5, 1],
      ],
      style: {
        fill: '#1890FF',
        stroke: '#1890FF',
        minWidth: 200,
      },
      autoWidth: true,
      autoFit: false,
    },
    edgeCfg: {
      type: 'polyline',
    },
    markerCfg: (cfg) => {
      return {
        position: Position.BOTTOM,
        show: edges.filter((item) => item.source === cfg.id)?.length > 0,
      };
    },
    behaviors: ['drag-canvas', 'zoom-canvas', 'drag-node'],
  };

  return (
    <div className={styles.tracking_tree_wrapper}>
      {contextMenuProps.open && (
        <Space
          direction="vertical"
          size={0}
          className={styles.context_menu_wrapper}
          split={<Divider style={{ margin: 0 }} />}
          style={{
            left: contextMenuProps.x ?? 0,
            top: contextMenuProps.y ?? 0,
          }}
        >
          <Button
            onClick={() => {
              onOpen(contextMenuProps.data);
              closeContextMenu();
            }}
          >
            檢視題目
          </Button>
          <Button
            onClick={() => {
              if (contextMenuProps.data) {
                const text = [
                  contextMenuProps.data.materialId,
                  contextMenuProps.data.materialSapBatchId,
                  contextMenuProps.data.materialSubBatchId,
                ].join('_');
                navigator.clipboard.writeText(text);
                Message.success(`${text}複製成功`);
              } else {
                Message.error(`批號資訊不齊全`);
              }
            }}
          >
            複製批號
          </Button>
        </Space>
      )}
      <FlowAnalysisGraph
        className={styles.flow_analysis_graph_wrapper}
        onReady={(graph) => {
          onNodeLeftClick(graph);
          onNodeRightClick(graph);
          onCanvasLeftClick(graph);
          onCanvasLeftDbClick(graph);
          onCanvasRightClick(graph);
        }}
        {...config}
      />
      <TrackingTreeNodeModal
        open={modalOpen}
        data={modalData}
        onClose={onClose}
      />
    </div>
  );
}
