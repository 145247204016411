import { useMemo, useState } from 'react';
import { API_NAMESPACE, request } from '../request';
import {
  CalculateMaterialAllocationsPayload,
  CalculateSuggestedMaterialAllocationsPayload,
  CalculateSuggestedMaterialAllocationsResult,
  MaterialAllocationResult,
  SaveAndCreateMaterialRequestPayload,
} from './material-allocations.type';
import useSWR from 'swr';
import { APIResponse } from '../drawings';
import { PageInfo } from '../pagination';

const namespace = API_NAMESPACE.DRAWINGS;

export function calculateMaterialAllocations(
  materialId: string,
  data: CalculateMaterialAllocationsPayload
) {
  return request(
    `/pp-material-allocations/materials/${materialId}/material-allocations`,
    {
      namespace,
      method: 'POST',
      body: JSON.stringify(data),
    }
  );
}

export function calculateSuggestedMaterialAllocations(
  materialId: string,
  data: CalculateSuggestedMaterialAllocationsPayload
) {
  return request(
    `/pp-material-allocations/materials/${materialId}/suggested-material-allocations`,
    {
      namespace,
      method: 'POST',
      body: JSON.stringify(data),
    }
  ) as Promise<CalculateSuggestedMaterialAllocationsResult>;
}

export function saveAndCreateMaterialRequests({
  workOrderId,
  data,
}: {
  workOrderId: string;
  data: SaveAndCreateMaterialRequestPayload;
}) {
  return request(
    `/pp-material-allocations/work-orders/${workOrderId}/save-and-create-material-requests`,
    {
      namespace,
      method: 'POST',
      body: JSON.stringify(data),
    }
  ) as Promise<{
    data: {
      orderCreated: boolean;
      materialRequestOrderId: string;
      createMaterialRequestOrderResp: {
        id: string;
        workOrderId: string;
        creatorId: string;
        recipeId: string;
        sourceType: string;
        materialSapBatchId: string;
        hasMultiMaterialSapBatchId: boolean;
        expectedCompletedAt: string;
        actualCompletedAt: any;
        materialRequestOrderBatches: {
          materialRequestOrderId: string;
          materialId: string;
          materialSapBatchId: string;
          materialBatchId: string;
          loaderId: string;
          used: boolean;
        }[];
        savedRequestedIds: number[];
        createdAt: string;
        updatedAt: string;
        deletedAt: any;
        productionExpectedFinishAt: string;
        recipeVersion: number;
        remark: string;
      };
    };
    code: number;
    title: string;
    message: string;
    count: number;
  }>;
}

export function useGetMaterialAllocationsResult() {
  const [params, setParams] = useState<{
    workOrderId: string;
    statuses: ('EDITING' | 'FINISHED')[];
    page: number;
    size: number;
  }>();

  const { data, error, mutate } = useSWR<
    APIResponse<Array<MaterialAllocationResult>>
  >(['/pp-material-allocations/results', { params, namespace }]);

  const pageInfo = useMemo<PageInfo>(
    () => ({
      limit: data?.size ?? 10,
      offset: (data?.page ?? 0) * (data?.size ?? 10),
      totalCount: data?.count ?? 0,
    }),
    [data]
  );

  return {
    results: data?.data ?? [],
    pageInfo,
    isLoading: !error && !data,
    refetchGetMaterialAllocationsResult: setParams,
    mutateGetMaterialAllocationsResult: mutate,
  };
}

export function useGetMaterialAllocationsResultById({
  id,
}: {
  id?: string | null;
}) {
  const { data, error } = useSWR<APIResponse<MaterialAllocationResult>>(
    id ? [`/pp-material-allocations/results/${id}`, { namespace }] : null
  );

  return {
    result: data?.data,
    isLoading: !error && !data,
  };
}
