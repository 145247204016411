import { SelectValue } from '@mezzanine-ui/react';
import { LocationLevel } from '../types';
import {
  useGetAreas,
  useGetBuildings,
  useGetFactories,
  useGetFloors,
  useGetShelves,
  useGetStacks,
  useGetZones,
} from '../hooks';
import { useLocationControlContext } from '../LocationControlContext';
import { LocationLevels } from '../LocationLevel';
import { useMemo } from 'react';

export function useLocationPickerOptionHooks() {
  const { level } = useLocationControlContext();
  const targetLevelIndex = LocationLevels.findIndex(
    (config) => config.level === level
  );
  const activeLocationHooksSet = useMemo(
    () =>
      LocationLevels.reduce<Set<LocationLevel>>(
        (result, config, currentLevelIndex) => {
          if (currentLevelIndex <= targetLevelIndex) {
            result.add(config.level);
            config.svg?.includeLevels?.forEach((level) => result.add(level));
          }
          return result;
        },
        new Set([])
      ),
    [targetLevelIndex]
  );
  const factoriesHook = useGetFactories();
  const buildingsHook = useGetBuildings(
    activeLocationHooksSet.has(LocationLevel.BUILDING)
  );
  const floorsHook = useGetFloors(
    activeLocationHooksSet.has(LocationLevel.FLOOR)
  );
  const areasHook = useGetAreas(activeLocationHooksSet.has(LocationLevel.AREA));
  const zonesHook = useGetZones(activeLocationHooksSet.has(LocationLevel.ZONE));
  const stacksHook = useGetStacks(
    activeLocationHooksSet.has(LocationLevel.STACK)
  );
  const shelvesHook = useGetShelves(
    activeLocationHooksSet.has(LocationLevel.SHELF)
  );

  return {
    [LocationLevel.FACTORY]: factoriesHook,
    [LocationLevel.BUILDING]: buildingsHook,
    [LocationLevel.FLOOR]: floorsHook,
    [LocationLevel.AREA]: areasHook,
    [LocationLevel.ZONE]: zonesHook,
    [LocationLevel.STACK]: stacksHook,
    [LocationLevel.SHELF]: shelvesHook,
  } as Record<
    LocationLevel,
    {
      options: SelectValue[];
      error: unknown;
      loading: boolean;
      onSearch?: (searchTerm: string) => void;
    }
  >;
}
