import { Message } from '@mezzanine-ui/react';
import { EquipmentTable } from '../EquipmentTable/EquipmentTable';
import { EquipmentListFilter, EquipmentListFilterForm } from './EquipmentListFilter';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { BindEquipmentLocationModal } from './BindEquipmentLocationModal';
import {
  createRepairRequest,
  bindEquipmentStackId,
  NextPagination,
  useGetEquipments,
  getNextParams,
  Equipment,
  CreateRepairRequestPayload,
} from '@solar/data';
import { CreateEquipmentMaintenanceModal } from './CreateEquipmentMaintenanceModal';
import { useNavigate } from 'react-router-dom';
import { LOCATION_SELECTOR, LocationSelectorGroupModal, ModalGroup, PageLayout, useModalGroupController } from '@solar/templates';
import moment from 'moment';
import { Modals } from './EquipmentListModals.enum';

export function EquipmentListPage() {
  const filterMethods = useForm<EquipmentListFilterForm>();
  const navigate = useNavigate();
  const modalGroupController = useModalGroupController([
    { name: Modals.CREATE },
    { name: Modals.BIND_LOCATION },
    { name: LOCATION_SELECTOR },
  ]);
  const { equipments, pageInfo, isLoading, fetchEquipments, mutateGetEquipments } = useGetEquipments();

  const openLocationSelectorModal = () => modalGroupController.openModal(LOCATION_SELECTOR, null);
  const openCreateEquipmentMaintenanceModal = (equipment: Equipment) => modalGroupController.openModal(Modals.CREATE, equipment);
  const openBindEquipmentLocationModal = (equipment: Equipment) => modalGroupController.openModal(Modals.BIND_LOCATION, equipment);

  const appendLocationIds = (selectedLocationId?: string) => {
    const locationIds = filterMethods.getValues('locationIds') ?? [];
    const idSet = new Set(locationIds);
    if (selectedLocationId) idSet.add(selectedLocationId);
    filterMethods.setValue('locationIds', [...idSet]);
  };

  const refetchEquipments = useCallback((nextPagination: NextPagination) => {
    const filterState = filterMethods.getValues();
    const installedFrom = filterState?.installedAt?.[0];
    const installedTo = filterState?.installedAt?.[1];
    const { offset, limit } = getNextParams(nextPagination, pageInfo);
    fetchEquipments({
      categoryIds: filterState?.categories?.map(({ id }) => id),
      deptNames: filterState?.deptCodes?.map(({ name }) => name),
      installedFrom: installedFrom && moment(installedFrom).startOf('day').format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
      installedTo: installedTo && moment(installedTo).endOf('day').format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
      equipmentIds: filterState?.equipments?.map(({ id }) => id),
      searchTerm: filterState?.searchTerm,
      locationIds: filterState?.locationIds,
      offset,
      limit,
    });
  }, [fetchEquipments, filterMethods, pageInfo]);

  useEffect(() => {
    const subscription = filterMethods.watch(() => refetchEquipments({ nextPage: 1 }));
    return () => subscription.unsubscribe();
  }, [filterMethods, filterMethods.watch, refetchEquipments]);

  return (
    <PageLayout title="設備清單">
      <EquipmentListFilter
        methods={filterMethods}
        openLocationSelectorModal={openLocationSelectorModal}
      />
      <EquipmentTable
        equipments={equipments}
        pageInfo={pageInfo}
        refetchEquipments={refetchEquipments}
        isLoading={isLoading}
        openCreateEquipmentMaintenanceModal={openCreateEquipmentMaintenanceModal}
        openBindEquipmentLocationModal={openBindEquipmentLocationModal}
      />
      <ModalGroup {...modalGroupController}>
        <LocationSelectorGroupModal showShelfSvg={false} onSubmit={appendLocationIds} />
        <BindEquipmentLocationModal
          onSubmit={async (payload: {
            equipmentId: string;
            stackId: string;
          }) => {
            try {
              await bindEquipmentStackId(payload);
              Message.success('綁定成功');
              await mutateGetEquipments();
            } catch (error) {
              Message.error('綁定失敗');
            }
          }}
        />
        <CreateEquipmentMaintenanceModal
          onSubmit={async (payload: CreateRepairRequestPayload) => {
            try {
              await createRepairRequest(payload);
              Message.success('申請報修成功');
              await navigate('/equipments/maintenance');
            } catch (error) {
              Message.error('建立失敗');
            }
          }}
        />
      </ModalGroup>
    </PageLayout>
  );
}
