import { ConfigureTableProps } from '../types';

export const selectedUnitsGenerator = ({
  source,
}: {
  source: Pick<ConfigureTableProps, 'materialStockUnit' | 'materialInputUnit'>;
}) => {
  const options: ConfigureTableProps['selectedUnit'][] = [
    // {
    //   id: 'materialStockUnit',
    //   name: `${source.materialStockUnit}    （基礎計量單位）`,
    // },
    {
      id: 'materialInputUnit',
      name: `${source.materialInputUnit}    （可用管理單位） `,
    },
  ];

  return options;
};
