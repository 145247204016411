import { useForm } from 'react-hook-form';
import styles from './../../TargetNumber.module.scss';
import {
  AutoCompleteField,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { Button, ButtonGroup, Typography } from '@mezzanine-ui/react';
import {
  TargetNumberRulesParams,
  useGetTargetRuleCustomersForSearch,
} from '@solar/data';
import { useState } from 'react';
import { Customer } from '../../TargetNumber.interface';

interface TargetRuleFilterProps {
  onSubmit: (filterParams: TargetNumberRulesParams) => void;
}

interface TransformedData {
  name?: string;
  rule_type?: string;
  status?: string;
  customer_name?: string;
  customer_id?: string;
  material_id?: string;
  [key: string]: string | undefined;
}

export function TargetRuleFilter({ onSubmit }: TargetRuleFilterProps) {
  const methods = useForm<TargetNumberRulesParams>({
    defaultValues: {
      name: '',
      ruleType: { id: '', name: '' },
      ruleStatus: { id: '', name: '全部' },
      customerName: { id: '', name: '' },
      customerId: { id: '', name: '' },
      materialId: '',
    },
  });

  const [customerQuery, setCustomerQuery] = useState('');
  const { targetRuleCustomers } =
    useGetTargetRuleCustomersForSearch(customerQuery);

  const handleFormSubmit = (data: TargetNumberRulesParams) => {
    const transformedData = {
      name: data.name || undefined,
      rule_type: data.ruleType?.id || undefined,
      status: data.ruleStatus?.id || undefined,
      // customer_name: data.customerName?.name || undefined,
      customer_id: data.customerId?.id || undefined,
      material_id: data.materialId || undefined,
    };
    Object.keys(transformedData).forEach((key) => {
      if ((transformedData as TransformedData)[key] === undefined) {
        delete (transformedData as TransformedData)[key];
      }
    });
    
    onSubmit(transformedData);
  };

  const handleCustomerSearch = (inputValue: string) => {
    setCustomerQuery(inputValue);
  };

  const handleCustomerSelect = (selectedOption: Customer | null) => {
    if (selectedOption) {
      methods.setValue('customerName', {
        id: selectedOption.id,
        name: selectedOption.name,
      });
      methods.setValue('customerId', {
        id: selectedOption.id,
        name: selectedOption.id,
      });
    } else {
      methods.setValue('customerName', { id: '', name: '' });
      methods.setValue('customerId', { id: '', name: '' });
    }
  };

  const handleCustomerIdChange = (selectedCustomerId: string | null) => {
    if (selectedCustomerId) {
      const selectedCustomer = targetRuleCustomers.find(
        (customer: Customer) => customer.id === selectedCustomerId
      );
      if (selectedCustomer) {
        methods.setValue('customerName', {
          id: selectedCustomer.id,
          name: selectedCustomer.name,
        });
        methods.setValue('customerId', {
          id: selectedCustomer.id,
          name: selectedCustomer.id,
        });
      }
    } else {
      methods.setValue('customerName', { id: '', name: '' });
      methods.setValue('customerId', { id: '', name: '' });
    }
  };
  return (
    <FormFieldsWrapper
      methods={methods}
      className={styles.filter}
      onSubmit={handleFormSubmit}
    >
      <div className={styles.formContent}>
        <Typography variant="h5">規則名稱:</Typography>
        <InputField
          size="medium"
          registerName="name"
          width={250}
          placeholder=""
        />
        <Typography variant="h5">規則類型:</Typography>
        <SelectField
          registerName="ruleType"
          options={[
            { id: 'internal', name: '內部' },
            { id: 'customer', name: '客戶' },
          ]}
          width={100}
        />
        <Typography variant="h5">狀況:</Typography>
        <SelectField
          registerName="ruleStatus"
          options={[
            { id: 'unused', name: '未使用' },
            { id: 'used', name: '已使用' },
            { id: 'disabled', name: '停用' },
          ]}
          placeholder=""
          width={120}
        />
        <Typography variant="h5">料號: </Typography>
        <InputField
          registerName="materialId"
          size="medium"
          width={400}
          placeholder=""
        />
      </div>
      <div className={styles.formContent}>
        <Typography variant="h5">客戶名稱(出貨對象):</Typography>
        <AutoCompleteField
          registerName="customerName"
          width={700}
          options={targetRuleCustomers || []}
          placeholder=""
          onChange={handleCustomerSelect}
        />
        <Typography variant="h5">客戶編號:</Typography>
        <AutoCompleteField
          registerName="customerId"
          width={265}
          options={(targetRuleCustomers || []).map((customer: Customer) => ({
            id: customer.id,
            name: customer.id,
          }))}
          placeholder=""
          onSearch={handleCustomerSearch}
          onChange={(selectedOption) =>
            handleCustomerIdChange(selectedOption ? selectedOption.id : null)
          }
        />
      </div>
      <ButtonGroup
        className={styles.buttonGroup}
        variant="contained"
        size="large"
      >
        <Button type="submit">查詢</Button>
        <Button onClick={() => methods.reset()}>清除</Button>
      </ButtonGroup>
    </FormFieldsWrapper>
  );
}
