import { useEffect, useState } from 'react';
import { API_NAMESPACE, request } from '../request';
import { message } from 'antd';

const namespace = API_NAMESPACE.DRAWINGS;

type GetPresignedFileLinkParams = {
  s3ObjectKey?: string;
};

export async function getPresignedFileLink({
  s3ObjectKey,
}: GetPresignedFileLinkParams) {
  const response = await request('/awsS3/generateS3PresignedFileLinks', {
    method: 'post',
    body: JSON.stringify({
      s3ObjectKeys: s3ObjectKey ? [s3ObjectKey] : [],
    }),
    responseParser: (res) => res,
    namespace,
  });

  const result = await response.json();

  return result;
}

export function usePresignedFileLink({
  s3ObjectKey,
}: GetPresignedFileLinkParams) {
  const [fileLink, setFileLink] = useState<string>();

  useEffect(() => {
    const getLink = async () => {
      if (s3ObjectKey) {
        try {
          const response = await getPresignedFileLink({ s3ObjectKey });
          const s3Url = response?.data?.[0]?.presignedUrl;
          setFileLink(s3Url);
        } catch (error) {
          message.error('圖號檔案讀取失敗');
        }
      }
    };
    getLink();
  }, [s3ObjectKey]);

  return { fileLink, setFileLink };
}
