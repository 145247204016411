import useSWR from 'swr';

type useZoneProps = {
  zoneID: string;
};

/**
 * @deprecated: 替換成 getLocationDisplayName
 */
export const getZoneDisplayName = (zone: any, separator?: string) => {
  const sep = separator ?? ' · ';
  const zoneName = zone?.name ?? '無';
  const areaName = zone?.area?.name;
  const floorName = zone?.area?.floor?.name;
  const buildingName = zone?.area?.floor?.building?.name;
  const factoryName = zone?.area?.floor?.building?.factory?.name;
  const renderName = (name: any) => (name ? `${name}${sep}` : '');
  return `${renderName(factoryName)}${renderName(buildingName)}${renderName(
    floorName
  )}${renderName(areaName)}${zoneName}`;
};

export function useZone({ zoneID }: useZoneProps) {
  const { data, error } = useSWR(`/zones/${zoneID}`);

  return {
    zones: data,
    isLoading: !error && !data,
  };
}
