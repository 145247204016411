import {
  Button,
  Message,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@mezzanine-ui/react';
import { useTargetModal } from '@solar/templates';
import { Col, Row, Space } from 'antd';
import {
  IncomingPurchaseOrder,
  updateIQCOrderStatus,
  useGetIQCDeliveryOrders,
  useGetIQCPurchaseOrders,
} from '@solar/data';
import { Modals } from './typings';
import { useEffect, useState } from 'react';

const defaultValues = {
  warehouseNote: '',
};

export function IncomingQualityControlModal({
  purchaseOrderId,
}: {
  purchaseOrderId?: string;
}) {
  const { open, data, closeModal } = useTargetModal<{
    action: '驗收' | '退貨';
    source: IncomingPurchaseOrder;
  }>(Modals.QUALITY_CONTROL);

  const [executing, setExecuting] = useState(false);

  // const methods = useForm<{
  //   warehouseNote: string;
  // }>({ defaultValues });

  const { mutateGetIQCPurchaseOrders } = useGetIQCPurchaseOrders({
    purchaseOrderId,
    statusIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  });

  const { mutateGetIQCDeliveryOrders } = useGetIQCDeliveryOrders({
    deliveryOrderId: purchaseOrderId,
    statusIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  });

  const source = data?.source;

  console.log(source);

  // useEffect(() => {
  //   if (open && source) {
  //     methods.setValue('warehouseNote', source?.warehouseNote ?? '');
  //   }
  // }, [open, source]);

  const onClose = () => {
    // methods.reset(defaultValues);
    closeModal();
  };

  return (
    <Modal style={{ width: 1000 }} open={open} onClose={onClose}>
      <ModalHeader>
        {source?.materialId ?? ''}-{source?.sapBatchId ?? ''}
      </ModalHeader>
      <ModalBody>
        {/* <FormFieldsWrapper methods={methods}>
          <Space direction="vertical" style={{ width: '100%' }} size="middle">
            <TextAreaField
              fullWidth
              registerName="warehouseNote"
              label="備註"
            />
          </Space>
        </FormFieldsWrapper> */}
      </ModalBody>
      <ModalFooter>
        <Row style={{ width: '100%' }} justify="end" gutter={[12, 12]}>
          <Col>
            <Button
              danger={data?.action === '退貨'}
              type="button"
              loading={executing}
              variant="outlined"
              onClick={async () => {
                try {
                  setExecuting(true);

                  if (!source?.id) throw new Error('order id is not exist');

                  // const formState = methods.getValues();

                  const updatedStatusId = (() => {
                    switch (data?.action) {
                      case '驗收':
                        return 10;
                      case '退貨':
                        return 11;
                      default:
                        return null;
                    }
                  })();

                  if (!updatedStatusId)
                    throw new Error('updated status id is not exist');

                  await updateIQCOrderStatus(source?.id, {
                    // warehouseNote: formState?.warehouseNote,
                    updatedStatusId,
                  });

                  await mutateGetIQCPurchaseOrders(undefined, true);
                  await mutateGetIQCDeliveryOrders(undefined, true);

                  Message.success(`${source?.id}:${data?.action}成功`);

                  onClose();
                } catch (error: any) {
                  console.error(error);

                  Message.error(
                    `${source?.id}:${data?.action}失敗：${
                      JSON.parse(error?.message)?.message
                    }`
                  );
                } finally {
                  setExecuting(false);
                }
              }}
            >
              確認{data?.action ?? ''}
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
}
