import {
  checkJWTRoles,
  TEMDrawing,
  useGetDrawingsAttributeCodes,
  usePresignedFileLink,
  useTEMDrawings,
} from '@solar/data';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Table,
  Tabs,
  Tooltip,
} from 'antd';
import styles from './drawing.module.scss';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PDFPreviewer } from '@solar/templates';
import moment from 'moment';
import { ColumnType } from 'antd/es/table';

type DrawingFilterType = {
  typeCode?: string[];
  compositeCode?: string[];
  shapeCode?: string[];
  sizeCodeOne?: string[];
  sizeCodeTwo?: string[];
  sizeCodeThree?: string[];
  layeringCode?: string[];
  sandblastingCode?: string[];
  drillingCode?: string[];
  slottingCode?: string[];
  completedStatusCode?: string[];
  isOring?: boolean[];
  isProfile?: boolean[];
  isTapping?: boolean[];
  isPerforation?: boolean[];
  isBlindHole?: boolean[];
};

const booleanOptions = [
  { text: '有', value: true },
  { text: '無', value: false },
];

export function TEMDrawingPage() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<TEMDrawing>();
  const [params, setParams] = useState<object>({ page: 1, size: 10 });
  const { drawings, pageInfo, isLoading } = useTEMDrawings({ params });
  const {
    typeCodes,
    compositeCodes,
    shapeCodes,
    sizeCodes,
    drillingCodes,
    slottingCodes,
    completedStatusCodes,
    sandblastingCodes,
  } = useGetDrawingsAttributeCodes({ bu: 'TEM' });
  const { fileLink } = usePresignedFileLink({
    s3ObjectKey: data?.s3ObjectKey,
  });
  const [filteredInfo, setFilteredInfo] = useState<DrawingFilterType>({});

  const temDrawingColumns: ColumnType<TEMDrawing>[] = [
    {
      title: '藍圖',
      align: 'center',
      width: 80,
      fixed: 'left',
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            setOpen(true);
            setData(record);
          }}
        >
          預覽
        </Button>
      ),
    },
    {
      dataIndex: 'oldDrawingFixed',
      title: '修正後舊圖號',
      width: 100,
      fixed: 'left',
    },
    {
      dataIndex: 'newDrawingCode',
      title: '新圖號',
      width: 220,
      fixed: 'left',
      render: (code, record) =>
        record?.newDrawingCode ? (
          <Link to={`/drawings/tem/${code}`}>{code}</Link>
        ) : (
          <Link
            to={`/drawings/tem/${code}?${new URLSearchParams({
              oldDrawingFixed: record?.oldDrawingFixed,
            }).toString()}`}
          >
            新增圖號
          </Link>
        ),
    },
    // {
    //   dataIndex: 'completedStatusCode',
    //   title: '完成別',
    //   filters: completedStatusCodes?.options,
    //   filteredValue: filteredInfo?.completedStatusCode ?? null,
    //   render: (code) => completedStatusCodes?.options?.find((option) => option?.value === code)?.text ?? '',
    //   width: 100,
    // },
    {
      dataIndex: 'compositeCode',
      title: '組合碼',
      align: 'center',
      width: 100,
      filters: compositeCodes?.options,
      filteredValue: filteredInfo?.compositeCode ?? null,
      render: (code, record) =>
        `${record?.compositeCode}（${record?.compositeName}）`,
    },
    {
      dataIndex: 'shapeCode',
      title: '形狀碼',
      align: 'center',
      width: 100,
      filters: shapeCodes?.options,
      filteredValue: filteredInfo?.shapeCode ?? null,
      render: (code, record) => `${record?.shapeCode}（${record?.shapeName}）`,
    },
    {
      dataIndex: 'sizeCodeOne',
      title: '尺寸一碼',
      align: 'center',
      width: 100,
      filters: sizeCodes?.options,
      filteredValue: filteredInfo?.sizeCodeOne ?? null,
      render: (code, record) => record?.sizeOne,
    },
    {
      dataIndex: 'sizeCodeTwo',
      title: '尺寸二碼',
      align: 'center',
      width: 100,
      filters: sizeCodes?.options,
      filteredValue: filteredInfo?.sizeCodeTwo ?? null,
      render: (code, record) => record?.sizeTwo,
    },
    {
      dataIndex: 'sizeCodeThree',
      title: '尺寸三碼',
      align: 'center',
      width: 100,
      filters: sizeCodes?.options,
      filteredValue: filteredInfo?.sizeCodeThree ?? null,
      render: (code, record) => record?.sizeThree,
    },
    {
      dataIndex: 'layeringCode',
      title: '分階碼',
      align: 'center',
      width: 100,
      filters: [
        { value: 'S', text: 'S' },
        { value: 'N', text: 'N' },
      ],
      filteredValue: filteredInfo?.layeringCode ?? null,
    },
    {
      dataIndex: 'sandblastingCode',
      title: '噴砂碼',
      align: 'center',
      width: 100,
      filters: sandblastingCodes?.options,
      filteredValue: filteredInfo?.sandblastingCode ?? null,
      render: (code, record) => {
        const nameCollection = [];
        if (record?.isTraditionalSandblasting) {
          nameCollection.push('傳統');
        }
        if (record?.isLaserSandblasting) {
          nameCollection.push('雷射');
        }
        if (record?.isGrooveSandblasting) {
          nameCollection.push('凹槽');
        }
        if (record?.isPlasmaSandblasting) {
          nameCollection.push('電漿');
        }
        return nameCollection?.length > 0
          ? `有${nameCollection.join('、')}`
          : '無';
      },
      // render: (code) => sandblastingCodes?.options?.find((option) => option?.value === code)?.text ?? '',
    },
    // {
    //   dataIndex: 'drillingCode',
    //   title: '鑽孔碼',
    //   align: 'center',
    //   width: 100,
    //   filters: drillingCodes?.options,
    //   filteredValue: filteredInfo?.drillingCode ?? null,
    //   render: (code) => drillingCodes?.options?.find((option) => option?.value === code)?.text ?? '',
    // },
    {
      dataIndex: 'isOring',
      title: 'Oring',
      align: 'center',
      width: 100,
      filterMultiple: false,
      filters: booleanOptions,
      filteredValue: filteredInfo?.isOring ?? null,
      render: (code) =>
        booleanOptions?.find((option) => option?.value === code)?.text ?? '',
    },
    {
      dataIndex: 'isProfile',
      title: 'Profile',
      align: 'center',
      width: 100,
      filterMultiple: false,
      filters: booleanOptions,
      filteredValue: filteredInfo?.isProfile ?? null,
      render: (code) =>
        booleanOptions?.find((option) => option?.value === code)?.text ?? '',
    },
    // {
    //   dataIndex: 'slottingCode',
    //   title: '開槽碼',
    //   align: 'center',
    //   width: 100,
    //   filters: slottingCodes?.options,
    //   filteredValue: filteredInfo?.slottingCode ?? null,
    //   render: (code) => slottingCodes?.options?.find((option) => option?.value === code)?.text ?? '',
    // },
    {
      dataIndex: 'isTapping',
      title: '攻牙',
      align: 'center',
      width: 100,
      filterMultiple: false,
      filters: booleanOptions,
      filteredValue: filteredInfo?.isTapping ?? null,
      render: (code) =>
        booleanOptions?.find((option) => option?.value === code)?.text ?? '',
    },
    {
      dataIndex: 'isPerforation',
      title: '穿孔',
      align: 'center',
      width: 100,
      filterMultiple: false,
      filters: booleanOptions,
      filteredValue: filteredInfo?.isPerforation ?? null,
      render: (code) =>
        booleanOptions?.find((option) => option?.value === code)?.text ?? '',
    },
    {
      dataIndex: 'isBlindHole',
      title: '盲孔',
      align: 'center',
      width: 100,
      filterMultiple: false,
      filters: booleanOptions,
      filteredValue: filteredInfo?.isBlindHole ?? null,
      render: (code) =>
        booleanOptions?.find((option) => option?.value === code)?.text ?? '',
    },
    {
      dataIndex: 'serialString',
      title: '流水三碼',
      align: 'center',
      width: 100,
    },
    {
      dataIndex: 'typeCode',
      title: '類型',
      align: 'center',
      width: 100,
      filters: typeCodes?.options,
      filteredValue: filteredInfo?.typeCode ?? null,
      render: (code) =>
        typeCodes?.options?.find((option) => option?.value === code)?.text ??
        '',
    },
    {
      dataIndex: 'createdAt',
      title: '建立日期',
      width: 100,
      render: (date) => (date ? moment(date).format('YYYY-MM-DD') : ''),
    },
    // {
    //   dataIndex: 'lastScheduleShipDate',
    //   title: '最後出貨日期',
    //   width: 100,
    //   render: (date) => (date ? moment(date).format('YYYY-MM-DD') : ''),
    // },
  ];

  const refetchDrawings = (
    pagination: { page?: number; size?: number },
    filters?: DrawingFilterType
  ) => {
    const formState = form.getFieldsValue();
    setFilteredInfo(filters ?? {});
    setParams((prevParams) => ({
      ...prevParams,
      ...formState,
      ...pagination,
      typeCodes: filters?.typeCode,
      compositeCodes: filters?.compositeCode,
      shapeCodes: filters?.shapeCode,
      sizeCodeOnes: filters?.sizeCodeOne,
      sizeCodeTwos: filters?.sizeCodeTwo,
      sizeCodeThrees: filters?.sizeCodeThree,
      layeringCodes: filters?.layeringCode,
      sandblastingCodes: filters?.sandblastingCode,
      drillingCodes: filters?.drillingCode,
      isOring: filters?.isOring,
      isProfile: filters?.isProfile,
      slottingCodes: filters?.slottingCode,
      isTapping: filters?.isTapping,
      isPerforation: filters?.isPerforation,
      isBlindHole: filters?.isBlindHole,
      completedStatusCodes: filters?.completedStatusCode,
    }));
  };

  const onClose = () => {
    setOpen(false);
    setData(undefined);
  };

  return (
    <div className={styles.page_content}>
      <Form form={form} style={{ rowGap: 8, columnGap: 16 }} layout="inline">
        {/* <Form.Item name="oldDrawingFixed" label="修正後舊圖號">
          <Input />
        </Form.Item> */}
        <Form.Item name="parentOldDrawingCode" label="舊成品圖號">
          <Input />
        </Form.Item>
        <Form.Item name="newDrawingCode" label="新圖號">
          <Input />
        </Form.Item>
        <Form.Item name="oldMaterialID" label="舊料號">
          <Input />
        </Form.Item>
        <Form.Item name="newMaterialID" label="新料號">
          <Input />
        </Form.Item>
        <Form.Item name="sizeOne" label="尺寸一碼">
          <InputNumber
            min={0}
            onChange={(value) => {
              form.setFieldValue(
                'sizeOne',
                value ? (value as number).toFixed(3) : null
              );
            }}
          />
        </Form.Item>
        <Form.Item name="sizeTwo" label="尺寸二碼">
          <InputNumber
            min={0}
            onChange={(value) => {
              form.setFieldValue(
                'sizeTwo',
                value ? (value as number).toFixed(3) : null
              );
            }}
          />
        </Form.Item>
        <Form.Item name="sizeThree" label="尺寸三碼">
          <InputNumber
            min={0}
            onChange={(value) => {
              form.setFieldValue(
                'sizeThree',
                value ? (value as number).toFixed(3) : null
              );
            }}
          />
        </Form.Item>
        <Button
          onClick={() => {
            refetchDrawings({
              page: 1,
              size: pageInfo.size,
            });
          }}
        >
          查詢
        </Button>
        {checkJWTRoles([106]) && (
          <Button
            disabled
            type="primary"
            onClick={() => navigate('/drawings/create')}
          >
            新增圖號
          </Button>
        )}
      </Form>
      <Table
        bordered
        size="small"
        style={{ width: '100%' }}
        pagination={{
          current: pageInfo.page,
          pageSize: pageInfo.size,
          hideOnSinglePage: true,
          total: pageInfo.count,
        }}
        scroll={{ x: 1200 }}
        rowKey="id"
        loading={isLoading}
        dataSource={drawings}
        columns={temDrawingColumns}
        onChange={(pagination, filters) => {
          refetchDrawings(
            {
              page: pagination.current,
              size: pagination.pageSize,
            },
            filters
          );
        }}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <Tabs
                type="card"
                items={[
                  {
                    key: 'material',
                    label: (
                      <Tooltip
                        title={
                          !(record?.materialDrawings?.length > 0)
                            ? '無料號'
                            : undefined
                        }
                      >
                        料號
                      </Tooltip>
                    ),
                    // disabled: !(record?.materialDrawings?.length > 0),
                    children: (
                      <Table
                        size="small"
                        pagination={{ hideOnSinglePage: true }}
                        dataSource={record?.materialDrawings}
                        columns={[
                          // {
                          //   dataIndex: 'scheduleShipDate',
                          //   title: '出貨日期',
                          //   render: (date) => date ? moment(date).format('YYYY-MM-DD') : '',
                          // },
                          {
                            dataIndex: 'newMaterialID',
                            title: '新料號',
                            width: 300,
                          },
                          {
                            dataIndex: 'oldMaterialID',
                            title: '舊料號',
                            width: 100,
                          },
                          {
                            dataIndex: 'desc',
                            title: '描述',
                          },
                        ]}
                      />
                    ),
                  },
                  {
                    key: 'childDrawing',
                    label: (
                      <Tooltip
                        title={
                          !(record?.childDrawings?.length > 0)
                            ? '無子圖'
                            : undefined
                        }
                      >
                        子圖
                      </Tooltip>
                    ),
                    // disabled: !(record?.childDrawings?.length > 0),
                    children: (
                      <Table
                        size="small"
                        scroll={{ x: 1200 }}
                        pagination={{ hideOnSinglePage: true }}
                        dataSource={record?.childDrawings?.map(
                          (item: any) => item?.temNewDrawing
                        )}
                        columns={temDrawingColumns}
                      />
                    ),
                  },
                ]}
              />
            );
          },
          rowExpandable: (record) =>
            record?.materialDrawings?.length > 0 ||
            record?.childDrawings?.length > 0,
        }}
      />
      <Modal width={1000} open={open} footer={false} onCancel={onClose}>
        <PDFPreviewer fileLink={fileLink} />
      </Modal>
    </div>
  );
}
