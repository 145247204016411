import { useState } from 'react';
import useSWR from 'swr';
import { OffsetBased, PageInfo } from '../pagination';
import { API_NAMESPACE, request } from '../request';
import { InventoryItem } from './inventory.type';
import { FetchingInventoryStockTypes } from '../materials';

const namespace = API_NAMESPACE.MATERIALS;

export enum InventoryByMaterialSearchType {
  LOADER_BARCODE = 'LOADER_BARCODE',
  MATERIAL_BARCODE = 'MATERIAL_BARCODE',
  MATERIAL_ID = 'MATERIAL_ID',
  MATERIAL_BATCH = 'MATERIAL_BATCH',
  MATERIAL_ID_OR_MATERIAL_NAME = 'MATERIAL_ID_OR_MATERIAL_NAME',
}

type GetInventoryByMaterialParams = OffsetBased & {
  enableUnavailable?: boolean;
  limit?: number;
  offset?: number;
  groupIds?: string[];
  categoryIds?: string[];
  sourceIds?: string[];
  type: InventoryByMaterialSearchType;
  searchTerms?: string[];
  materialIds?: string[];
  materialDescriptions?: string[];
  materialBarcodes?: string[];
  componentCodes?: string[];
  locationIds?: string[];
  loaderIds?: string[];
  areaIds?: string[];
  batchStoredLoaderRecordIds?: string[];
  factoryIds?: string[];
  materialBatchIds?: string[];
  withElementRatio?: boolean | null;
  createdAtDateFrom?: string;
  createdAtDateTo?: string;
  stockTypes?: FetchingInventoryStockTypes[];
  shouldFetch?: boolean;
};

export type Inventory = {
  batchId: string;
  batchStoredLoaderRecordId: string;
  createdAt: string;
  materialDescription: string;
  recordId: string;
  mainMaterialUnitQuantity: number;
  secondaryMaterialUnitQuantity: number;
  materialBarcode: string;
  loaderId: string;
  loaderBarcode: string;
  stackId: string;
  stackName: string;
  drawingCode: string;
  materialId: string;
  loaderName: string;
  staffName: string;
};

export function useGetInventoryByMaterial(
  defaultParams?: GetInventoryByMaterialParams
) {
  const [params, setParams] = useState<GetInventoryByMaterialParams>(
    defaultParams ?? {
      type: InventoryByMaterialSearchType.LOADER_BARCODE,
      // withElementRatio: true,
    }
  );
  const { data, error, isValidating, mutate } = useSWR<{
    records: InventoryItem[];
    pageInfo: PageInfo;
  }>(
    params?.shouldFetch
      ? [
          '/warehouses/inventory-by-material',
          {
            params,
            namespace,
          },
        ]
      : null
  );

  const mutateGetInventoryByMaterial = (
    nextParams: GetInventoryByMaterialParams
  ) => {
    setParams(nextParams);
  };

  return {
    inventoryByMaterial: data?.records || [],
    pageInfo: data?.pageInfo,
    isLoading: isValidating,
    mutateGetInventoryByMaterial,
    refetchInventory: mutate,
  };
}

export async function getInventoryByMaterial(
  params?: GetInventoryByMaterialParams
) {
  const response = (await request('/warehouses/inventory-by-material', {
    method: 'get',
    params,
    namespace,
  })) as Promise<{ records: InventoryItem[]; pageInfo: PageInfo }>;
  return await response;
}

export function syncInventoryMaterialsFromSAP() {
  return request('/sync/inventory-materials', {
    method: 'get',
    namespace,
    responseParser: (res) => res,
  });
}
