import {
  Button,
  Message,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
} from '@mezzanine-ui/react';
import {
  FormFieldsWrapper,
  InputField,
  InputTagsModeField,
  RadioGroupField,
} from '@mezzanine-ui/react-hook-form';
import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './form-template-creator.module.scss';
import {
  QUESTION_TYPE_OPTIONS,
  QuestionType,
  formTemplateCreatorSchema,
} from './creator-schema';
import { API_NAMESPACE, environment } from '@solar/data';
import { isNil, uniq } from 'lodash';

interface FormFields {
  fieldName?: string;
  name?: string;
  suffix?: string;
  type?: string;
  placeholder?: string;
  min?: number;
  max?: number;
  scale?: number;
  precision?: number;
  options?: string[];
}

const defaultValues = {
  fieldName: undefined,
  name: undefined,
  suffix: undefined,
  type: QuestionType.TEXT,
  placeholder: undefined,
  max: undefined,
  min: undefined,
  scale: undefined,
  precision: undefined,
  options: [],
} as FormFields;

export function FormTemplateCreator() {
  const [modalShown, setModalShown] = useState(false);

  const methods = useForm<FormFields>({
    defaultValues,
    resolver: yupResolver(formTemplateCreatorSchema),
  });

  const type = methods.watch('type');
  const [loading, setLoading] = useState(false);

  return (
    <Fragment>
      <Button variant="contained" onClick={() => setModalShown(true)}>
        新增題型
      </Button>
      <Modal open={modalShown} onClose={() => setModalShown(false)}>
        <ModalHeader>新增題目範本</ModalHeader>
        <ModalBody>
          <FormFieldsWrapper className={styles.form} methods={methods}>
            <InputField
              required
              registerName="fieldName"
              clearable
              label="參數名稱（唯一值）"
            />
            <InputField
              required
              registerName="name"
              clearable
              label="題目名稱（顯示名稱）"
            />
            <InputField
              registerName="suffix"
              placeholder="g"
              clearable
              label="單位、附註"
            />
            <RadioGroupField
              label="題目類型"
              required
              registerName="type"
              options={QUESTION_TYPE_OPTIONS}
            />
            <InputField registerName="placeholder" label="提示文字" />
            {type === QuestionType.NUMERIC ? (
              <div className={styles.numericGroup}>
                <InputField
                  clearable
                  registerName="min"
                  label="最小值"
                  type="number"
                />
                <InputField
                  clearable
                  registerName="max"
                  label="最大值"
                  type="number"
                />
                <InputField
                  clearable
                  registerName="precision"
                  label="整數位數"
                  type="number"
                />
                <InputField
                  clearable
                  registerName="scale"
                  label="小數位數"
                  type="number"
                />
              </div>
            ) : null}
            {type === QuestionType.ENUM ? (
              <InputTagsModeField
                required
                clearable
                registerName="options"
                label="選項"
                maxTagsLength={100}
                maxLength={100}
              />
            ) : null}
          </FormFieldsWrapper>
        </ModalBody>
        <ModalActions
          confirmText="確認新增"
          onConfirm={() => {
            methods.handleSubmit(async (data) => {
              setLoading(true);

              try {
                await fetch(
                  `${environment.API_HOST}${API_NAMESPACE.PP}/form-templates`,
                  {
                    method: 'POST',
                    headers: {
                      apikey: environment.API_KEY,
                      'Content-Type': 'application/json',
                      Accept: 'application/json',
                      ...(localStorage.getItem('accessToken')
                        ? {
                            Authorization: `Bearer ${localStorage.getItem(
                              'accessToken'
                            )}`,
                          }
                        : {}),
                    },
                    body: JSON.stringify({
                      fieldName: data.fieldName,
                      name: data.name,
                      suffix: data.suffix ?? null,
                      field: (() => {
                        switch (data.type) {
                          case QuestionType.ENUM:
                            return {
                              type: QuestionType.ENUM,
                              placeholder: data.placeholder ?? null,
                              enum: uniq(
                                (data.options || [])
                                  .map((option) => option.trim())
                                  .filter((option) => option)
                              ),
                            };

                          case QuestionType.NUMERIC:
                            return {
                              type: QuestionType.NUMERIC,
                              placeholder: data.placeholder ?? null,
                              ...(isNil(data.min) ? {} : { min: data.min }),
                              ...(isNil(data.max) ? {} : { max: data.max }),
                              ...(isNil(data.scale)
                                ? {}
                                : { scale: data.scale }),
                              ...(isNil(data.precision)
                                ? {}
                                : { precision: data.precision }),
                            };

                          case QuestionType.TIMESTAMP:
                            return {
                              type: QuestionType.TIMESTAMP,
                              placeholder: data.placeholder ?? null,
                            };

                          case QuestionType.TEXT:
                          default:
                            return {
                              type: QuestionType.TEXT,
                              placeholder: data.placeholder ?? null,
                            };
                        }
                      })(),
                    }),
                  }
                );

                Message.success('已建立題目');

                setModalShown(false);
              } catch (ex) {
                Message.error('建立題目失敗');
              } finally {
                setLoading(false);
              }
            })();
          }}
          confirmButtonProps={{
            loading,
            disabled: !methods.formState.isValid,
          }}
          cancelButtonProps={{
            loading,
            onClick: () => setModalShown(false),
          }}
          cancelText="取消"
        />
      </Modal>
    </Fragment>
  );
}
