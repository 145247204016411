import { InputField } from '@mezzanine-ui/react-hook-form';
import { TableColumnProps } from 'antd';
import { UseFormReturn, useWatch } from 'react-hook-form';
import classes from '../../inventory-detail.module.scss';
import {
  InventoryDetailFormProps,
  InventoryDetailTableProps,
} from '../../types';

type Props = {
  formContext: UseFormReturn<InventoryDetailFormProps, any>;
};

export function useTableColumn({ formContext }: Props) {
  const editable = useWatch({
    control: formContext?.control,
    name: 'editable',
  });

  const pagination = useWatch({
    control: formContext?.control,
    name: 'pagination',
  });

  const column: TableColumnProps<InventoryDetailTableProps>[] = [
    { title: '儲位', dataIndex: 'storageLocation' },
    { title: '載具', dataIndex: 'vehicles' },
    {
      title: '料號',
      dataIndex: 'materialId',
      render: (_, record) => {
        return (
          <span className={classes['line-break']}>{`${
            record['materialId'] ?? ''
          }`}</span>
        );
      },
    },
    {
      title: '品名',
      dataIndex: 'materialDescription',
      render: (_, record) => {
        return (
          <span className={classes['line-break']}>{`${
            record['materialDescription'] ?? ''
          }`}</span>
        );
      },
    },
    { title: '批號', dataIndex: 'sapBatchId' },
    {
      title: '基礎計量單位數量',
      dataIndex: 'availableMaterialStockUnitQuantity',
    },
    { title: '單位', dataIndex: 'materialStockUnit' },
    {
      title: '管理單位數量',
      dataIndex: 'availableMaterialInputUnitQuantity', // availableMaterialInputUnitQuantity
      width: 150,
      render: (_, record, index) => {
        return (
          <InputField
            disabled={!editable}
            registerName={`inventoryRows.${
              (pagination.current - 1) * pagination.pageSize + index
            }.availableMaterialInputUnitQuantity`}
          />
        );
      },
    },
    { title: '單位', dataIndex: 'availableMaterialInputUnit' },
    {
      title: '實際重量',
      dataIndex: 'actualWeight',
      width: 150,
      render: (_, record, index) => {
        return (
          <InputField
            disabled={!editable}
            registerName={`inventoryRows.${
              (pagination.current - 1) * pagination.pageSize + index
            }.actualWeight`}
          />
        );
      },
    },
    { title: '單位', dataIndex: 'weightUnit' },
  ];

  return column;
}
