import { ChangeEvent, useEffect, useState, MouseEvent } from "react";
import { FormFieldsWrapper, AutoCompleteField, InputField, DatePickerField } from "@mezzanine-ui/react-hook-form";
import {
  AutoComplete, Button, Checkbox,
  Message, Popconfirm, SelectValue, Table,
} from "@mezzanine-ui/react";
import { TableColumn } from "@mezzanine-ui/core/table";
import {
  useOrderPredict,
  useOrderPredictUpsert,
  parseOrderFromAPI,
  IOrderItem,
  IOrderDetail,
  parseOrderToAPI,
  insertOrderPredict,
  updateOrderPredict,
} from "@solar/data";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { PageLayout } from "../../Templates/PageLayout/PageLayout";
import styles from './OrderPredictUpsertPage.module.scss';
import OrderItemModal from "./OrderItemModal";

const deleteItemIds: string[] = [];

interface IDelInfo {
  id: string;
  key: string;
  name: string;
}

const OrderPredictUpsertPage = () => {
  const navigate = useNavigate();
  const [items, setItems] = useState<IOrderItem[]>([]);
  const [nowComponentData, setNowComponentData] = useState<IOrderItem | null>(null);


  const [onDeleteInfo, setOnDeleteInfo] = useState<IDelInfo | null>(null);
  const [onDelAnchor, setOnDelAnchor] = useState<HTMLButtonElement | null>(null);

  const [componentModalOpen, setComponentModalOpen] = useState(false);
  const params = useParams();
  const {
    autoCompleteOptionsCustomer,
    autoCompleteOptionsSales,
    autocompleteOptionsCertainty,
    autocompleteOptionsForecastType,
  } = useOrderPredict();
  const { orderId } = params;
  const formMethods = useForm();
  const title = orderId ? `編輯預測訂單 ID:${orderId}` : "新建預測訂單";

  const orderDetail = useOrderPredictUpsert(orderId);

  useEffect(() => {
    if (orderDetail) {
      // console.log('get orderDetail', orderDetail);
      const formOrderDetail = parseOrderFromAPI(
        orderDetail,
        autocompleteOptionsCertainty,
        autocompleteOptionsForecastType,
      );
      formMethods.reset(formOrderDetail);
      setItems(formOrderDetail.items);
    }
    return () => {
      deleteItemIds.length = 0;
      formMethods.reset();
    };
  }, [orderDetail, autocompleteOptionsCertainty, autocompleteOptionsForecastType, formMethods]);

  const onInsertCustomer = (text: string) => {
    return {
      id: text,
      name: text,
    };
  }

  const openOrderItemModal = () => {
    setComponentModalOpen(true);
  };

  const onFinishOrderItemModal = (orderItem: IOrderItem) => {
    // 1. find items by orderItem.key
    if (orderItem) {
      const newItems = [...items];
      const itemIndex = items.findIndex((item) => item.key === orderItem.key);
      if (itemIndex >= 0) {
        newItems[itemIndex] = orderItem;
      } else {
        newItems.push(orderItem);
      }
      setItems(newItems);
    }

    setComponentModalOpen(false);
    setNowComponentData(null);
  };

  const onClickEdit = (key: string) => {
    const nowComponent = items.find((item) => item.key === key);
    if (nowComponent) {
      setNowComponentData(nowComponent);
      setComponentModalOpen(true);
    }
  };

  const onCheckStatus = (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const newItems = [...items];
    const itemIndex = items.findIndex((item) => item.key === key);
    if (itemIndex >= 0) {
      newItems[itemIndex].status = e.target.checked ? 1 : 0;
    }
    setItems(newItems);
  };

  const onChangeCertainty = (key: string) => (value: SelectValue<string>) => {
    const newItems = [...items];
    const itemIndex = items.findIndex((item) => item.key === key);
    if (itemIndex >= 0) {
      newItems[itemIndex].certainty = value;
    }
    setItems(newItems);
  }

  const onClickConfirmDel = () => {
    if (onDeleteInfo) {
      const { key, id } = onDeleteInfo;
      const newItems = items.filter((item) => item.key !== key);
      setItems(newItems);
      if (orderId && id) {
        deleteItemIds.push(id);
      }
    }
    setOnDeleteInfo(null);
  };

  const onClickTableDel = (key: string, id: string, name: string) => (e: MouseEvent<HTMLButtonElement>) => {
    // console.log('onClickTableDel', key, id);
    setOnDelAnchor(e.currentTarget);
    setOnDeleteInfo({ key, id, name });
  };

  const columns: TableColumn<Record<string, unknown>>[] = [
    {
      title: '操作',
      width: 160,
      render: (record: any) => {
        return (
          <div className={styles.action}>
            <Button size="small" variant="text" onClick={() => (onClickEdit(record.key))}>編輯</Button>
            <Button size="small" variant="text" danger onClick={
              onClickTableDel(record.key, record.id, record.material.name)
            }>刪除</Button>
          </div>
        );
      },
    },
    {
      title: '料號',
      dataIndex: 'material.name',
      width: 450,
    },
    {
      title: '數量',
      dataIndex: 'qty',
    },
    {
      title: '單位',
      dataIndex: 'unit.name',
    },
    {
      title: '單價',
      dataIndex: 'price',
    },
    {
      title: '小計',
      dataIndex: 'totalPrice',
    },
    {
      title: '把握度',
      render: (record: any) => (
        <AutoComplete
          value={record.certainty}
          options={autocompleteOptionsCertainty}
          onChange={onChangeCertainty(record.key)}
        />
      ),
      width: 200,
    },
    ...(orderId
      ? [
        {
          title: '訂單已成立',
          render: (record: any) => (
            <Checkbox
              onChange={onCheckStatus(record.key)}
              checked={record.status === 1}
            >
              訂單已成立
            </Checkbox>
          ),
          width: 200,
        },
      ]
      : []), // 如果沒有 orderId 就不顯示這個欄位
    {
      title: '入/耗料',
      dataIndex: 'orderType.name',
    },
    {
      title: '純量重(理論)',
      dataIndex: 'standardWt',
    },
    {
      title: '成分代碼1',
      dataIndex: 'component1.name',
    },
    {
      renderTitle: () => (<div>
        <div>成分代碼1</div>
        <div>純量重(理論)</div>
      </div>),
      dataIndex: 'wt1',
    },
    {
      title: '成分代碼2',
      dataIndex: 'component2.name',
    },
    {
      renderTitle: () => (<div>
        <div>成分代碼2</div>
        <div>純量重(理論)</div>
      </div>),
      dataIndex: 'wt2',
    },

    {
      title: '成分代碼3',
      dataIndex: 'component3.name',
    },
    {
      renderTitle: () => (<div>
        <div>成分代碼3</div>
        <div>純量重(理論)</div>
      </div>),
      dataIndex: 'wt3',
    },

    {
      title: '成分代碼4',
      dataIndex: 'component4.name',
    },
    {
      renderTitle: () => (<div>
        <div>成分代碼4</div>
        <div>純量重(理論)</div>
      </div>),
      dataIndex: 'wt4',
    },

  ];

  const onSubmitOrder = async () => {
    const formValues = formMethods.getValues();
    // console.log(formValues);
    const oldOrderDetail: IOrderDetail = {
      ...(orderId && { id: orderId }),
      note: formValues.note,
      orderDate: formValues.orderDate,
      sales: formValues.sales,
      customerName: formValues.customerName,
      bu: formValues.bu,
      items: items,
    };
    const data = parseOrderToAPI(oldOrderDetail);
    data.deleteItemIds = deleteItemIds;
    let upsertFunc = insertOrderPredict;
    if (data.id) {
      upsertFunc = updateOrderPredict;
    }
    try {
      await upsertFunc(data);
      Message.success('儲存成功');
      navigate('/order/order-predict-search');
    } catch (e: any) {
      // 先判斷 e.message 是不是 JSON 格式的字串，如果是的話，就是後端回傳的錯誤訊息
      // 如果不是的話，就是其他錯誤，直接顯示 e.message
      if (e.message && e.message.startsWith('{')) {
        const error = JSON.parse(e.message);
        Message.error(error.message);
      } else {
        Message.error('發生錯誤');
      }
    }
  };

  // items 每個項目的 status 都是 1 的話，就是全部訂單已成立，這個時候 indeterminate = false , allCheck = true.
  // items 如果每個項目的 status 是 0 的話，就是全部訂單未成立，這個時候 indeterminate = false , allCheck = false.
  // items 如果有些項目的 status 是 1 有些是 0 的話，就是部分訂單已成立，這個時候 indeterminate = true , allCheck = false.
  const allCheckIndeterminate = items.some((item) => item.status === 1) && items.some((item) => item.status === 0);
  const allCheck = items.every((item) => item.status === 1);
  const onChangeAllCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const newItems = [...items];
    newItems.forEach((item) => {
      item.status = e.target.checked ? 1 : 0;
    });
    setItems(newItems);
  };


  return (
    <PageLayout title={title}>
      <FormFieldsWrapper
        methods={formMethods}
      >
        <div className={styles.space}>

          <AutoCompleteField
            registerName="customerName"
            label="客戶名稱"
            options={autoCompleteOptionsCustomer}
            addable
            onInsert={onInsertCustomer}
          />

          <DatePickerField
            registerName="orderDate"
            label="訂單日期"
          />

          <InputField
            registerName="note"
            label="備註"
          />

          <InputField
            registerName="bu"
            label="BU名稱"
          />

          <AutoCompleteField
            registerName="sales"
            label="業務"
            options={autoCompleteOptionsSales}
          />
        </div>
      </FormFieldsWrapper>
      {
        orderId ? (
          <Checkbox
            checked={allCheck}
            indeterminate={allCheckIndeterminate}
            onChange={onChangeAllCheck}
          >全部訂單已成立</Checkbox>
        ) : null
      }
      <div>
        <Button
          onClick={openOrderItemModal}
          variant="outlined"
        >
          新增項次
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={items}
        scroll={{ x: 2500 }}
      />
      <Button variant="contained" onClick={onSubmitOrder}>儲存</Button>
      <OrderItemModal
        open={componentModalOpen}
        onFinish={onFinishOrderItemModal}
        isCreate={!!orderId}
        nowComponentData={nowComponentData}
      />
      <Popconfirm
        open={!!onDeleteInfo}
        confirmButtonProps={{ danger: true }}
        title={`確定要刪除該筆資料?${onDeleteInfo?.name}`}
        cancelText="取消"
        confirmText="刪除"
        onConfirm={onClickConfirmDel}
        onCancel={() => setOnDeleteInfo(null)}
        anchor={onDelAnchor} />
    </PageLayout>
  );
}

export default OrderPredictUpsertPage;