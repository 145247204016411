import { Col, Collapse, Descriptions, Row } from 'antd';
import { FieldArrayWithId, UseFieldArrayRemove } from 'react-hook-form';
import { SendForInspectionFormValues } from './send-for-inspection';
import { Button, Checkbox } from '@mezzanine-ui/react';
import { AnalysisRequirementsTable } from './AnalysisRequirementsTable';

export function SampleList({
  fields,
  remove,
  factory,
}: {
  fields: FieldArrayWithId<SendForInspectionFormValues, 'samples', 'id'>[];
  remove: UseFieldArrayRemove;
  factory: string;
}) {
  return (
    <Collapse size="large">
      {fields?.map((field, index) => (
        <Collapse.Panel
          key={field.id}
          header={
            <Row justify="space-between">
              <Col>{field?.sampleName}</Col>
              <Col>
                <Button
                  danger
                  variant="contained"
                  onClick={() => remove(index)}
                >
                  移除
                </Button>
              </Col>
            </Row>
          }
        >
          <Descriptions>
            <Descriptions.Item label="樣品名稱">
              {field?.sampleName ?? '-'}
            </Descriptions.Item>
            <Descriptions.Item label="料號">
              {field?.materialId ?? '-'}
            </Descriptions.Item>
            <Descriptions.Item label="批號">
              {field?.sapBatchId ?? ''}-{field?.subBatchId ?? ''}
            </Descriptions.Item>
            <Descriptions.Item label="樣品狀態">
              {field?.sampleType ?? '-'}
            </Descriptions.Item>
            <Descriptions.Item label="測試種類">
              {[
                { value: '1', label: '首測' },
                { value: '2', label: '保留樣複測' },
                { value: '3', label: '重新取樣' },
              ]?.find((t) => t.value === field?.testType?.toString())?.label ??
                '-'}
            </Descriptions.Item>
            <Descriptions.Item label="">
              <Checkbox checked={field?.isRush}>急件</Checkbox>
              <Checkbox checked={field?.isReturnSample}>樣品取回</Checkbox>
              <Checkbox checked={field?.isSampleAccountTransfer}>
                樣品轉帳
              </Checkbox>
            </Descriptions.Item>
            <Descriptions.Item label="分析樣毛重">
              {field?.sampleAmount ?? '-'}
            </Descriptions.Item>

            <Descriptions.Item label="分析樣淨重">
              {field?.sampleNetWeight ?? '-'}
            </Descriptions.Item>
            <Descriptions.Item label="分析樣空重">
              {field?.emptyBagWeight ?? '-'}
            </Descriptions.Item>
            <Descriptions.Item label="分析後殘料重">
              {field?.sampleRemainWeight ?? '-'} {field?.sampleUnit ?? ''}
            </Descriptions.Item>
            <Descriptions.Item label="委託原因">
              {field?.submitReason ?? '-'}
            </Descriptions.Item>
            <Descriptions.Item label="規格">
              {field?.sampleSpec ?? '-'}
            </Descriptions.Item>
            <Descriptions.Item label="樣品說明/成分" span={3}>
              {field?.sampleDesc ?? '-'}
            </Descriptions.Item>
          </Descriptions>
          <AnalysisRequirementsTable
            factory={factory}
            ids={field?.analysisRequirementIds}
          />
        </Collapse.Panel>
      ))}
    </Collapse>
  );
}
