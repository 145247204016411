import { useState } from 'react';
import useSWR from 'swr';
import { API_NAMESPACE, request } from '../request';
import { APIResponse } from '../drawings/types';
import { MeltFactor } from './melt-factor.type';

const namespace = API_NAMESPACE.DRAWINGS;

export function useGetMeltFactor(materialId?: string) {
  const [params, setParams] = useState();
  const { data, error, mutate } = useSWR<APIResponse<MeltFactor>>(
    materialId
      ? [
          `/pp-material-allocations/materials/${materialId}/melt-factor`,
          { params, namespace },
        ]
      : null
  );

  return {
    meltFactor: data?.data,
    isLoading: !data && !error,
    refetchMeltFactor: setParams,
    mutateGetMeltFactor: mutate,
  };
}

export function saveMeltFactor(materialId: string, data: any) {
  return request(
    `/pp-material-allocations/materials/${materialId}/melt-factor`,
    {
      method: 'POST',
      body: JSON.stringify(data),
      namespace,
    }
  );
}
