import { useForm, useFormState } from 'react-hook-form';
import { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import isEqual from 'lodash/isEqual';

export const HubWarehouseFilterFormFieldName = {
  MATERIAL_ID: 'materialId',
  SAP_BATCH: 'sapBatch',
  CUSTOMER_ID: 'customerId',
} as const;

const _FormFieldName = HubWarehouseFilterFormFieldName;

const hubWarehouseFilterSchema = yup.object().shape({
  [_FormFieldName.MATERIAL_ID]: yup.string().optional(),
  [_FormFieldName.SAP_BATCH]: yup.string().optional(),
  [_FormFieldName.CUSTOMER_ID]: yup.string().optional(),
});

export type HubWarehouseFilterFormValues = yup.InferType<
  typeof hubWarehouseFilterSchema
>;

export function useHubWarehouseFilter() {
  const formMethods = useForm<HubWarehouseFilterFormValues>({
    resolver: yupResolver(hubWarehouseFilterSchema),
  });
  const formState = useFormState({ control: formMethods.control });

  const [filterCondition, setFilterCondition] =
    useState<HubWarehouseFilterFormValues>({});

  function updateFilterCondition(callback?: {
    onFilterConditionNoChange?: () => void;
    onUpdateSuccess?: () => void;
  }) {
    if (formMethods) {
      if (isEqual(filterCondition, formMethods.getValues())) {
        callback?.onFilterConditionNoChange?.();
      } else {
        setFilterCondition(formMethods.getValues());
        callback?.onUpdateSuccess?.();
      }
    }
  }

  return {
    formMethods,
    formState,
    filterCondition,
    updateFilterCondition,
  };
}
