import useSWR from 'swr';
import { API_NAMESPACE, request } from '../request';
import { useState } from 'react';
import { OffsetBased, PageInfo } from '../pagination';

const namespace = API_NAMESPACE.MATERIALS;

export type LoadersQueryParam = {
  categoryCodes: string[];
  searchTerm: string;
  shelfIds: string[];
  whenNotFoundAutoCreate?: boolean;
};

export type LoadersResponse = {
  pageInfo: PageInfo;
  records: {
    id: string;
    size: string | null;
    createdAt: string;
    deletedAt: string | null;
    shelfId: string;
    widthCm: string;
    lengthCm: string;
    heightCm: string;
    capacityL: string;
    category: {
      code: string;
      name: string;
    };
  }[];
};
export function useLoaders() {
  const [params, setParams] = useState<
    Partial<LoadersQueryParam> & OffsetBased
  >();
  const { data, error } = useSWR<LoadersResponse>(
    params
      ? [
          '/loaders',
          {
            namespace,
            params,
          },
        ]
      : null
  );

  return {
    loaders:
      // it's weird to do so (manually to compose new data source), but it may be modified.
      data?.records.map((record) => ({
        ...record,
        id: String(record.id),
        name: record.size ?? '',
      })) ?? [],
    pageInfo: data?.pageInfo,
    isLoading: !error && !data,
    mutateLoaderParams: setParams,
  };
}

export type DetailedLoaderProp = {
  id: string;
  name: string;
  size: string;
  barcode: string;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  shelfId: string;
  materialBatches: {
    id: string;
    name: string;
    createdAt: string;
    materialId: string;
  }[];
  storedBatchRecords: {
    id: number;
    loaderId: number;
    materialBatchId: string;
    mainMaterialUnitQuantity: number;
    secondaryMaterialUnitQuantity: number;
    barcode: string;
    createdAt: string;
    updatedAt: string | null;
    deletedAt: string | null;
  }[];
};

export function useLoadersByLoaderId({
  loaderId,
}: {
  loaderId?: string | null;
}) {
  const [params, setParams] = useState<{ noInventory: boolean } | undefined>();
  const { data, error, isValidating } = useSWR<DetailedLoaderProp>(
    loaderId
      ? [
          `/loaders/${encodeURIComponent(loaderId)}`,
          {
            params,
            namespace,
          },
        ]
      : null,
    {
      onErrorRetry: (error) => {
        if (
          error instanceof Error &&
          JSON.parse(error.message).statusCode === 404
        ) {
          return;
        }
      },
    }
  );

  return {
    data,
    error,
    isLoading: !error && !data && isValidating,
    setParams,
  };
}

export async function updateLoaderByLoaderId({
  loaderId,
  body,
}: {
  loaderId: string;
  body: Pick<DetailedLoaderProp, 'name' | 'size' | 'shelfId' | 'id'>;
}) {
  const response = await request(`/loaders/${loaderId}`, {
    method: 'put',
    body: JSON.stringify(body),
    responseParser: (res) => res,
    namespace,
  });
  return response;
}

export async function deleteLoaderByLoaderId({
  loaderId,
}: {
  loaderId: string;
}) {
  const response = await request(`/loaders/${loaderId}`, {
    method: 'delete',
    responseParser: (res) => res,
    namespace,
  });
  return response;
}

export async function createLoader(
  body: Pick<DetailedLoaderProp, 'size' | 'shelfId'>
) {
  const response = await request(`/loaders`, {
    method: 'post',
    body: JSON.stringify(body),
    responseParser: (res) => res,
    namespace,
  });
  return response;
}
