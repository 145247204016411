import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { OffsetBased, PaginatedResponse } from '../pagination';
import { API_NAMESPACE } from '../request';
import { MiscMaterial, MiscMaterialBatch } from './inventory.type';

const namespace = API_NAMESPACE.MATERIALS;

export type MiscMaterialInventoryParams = {
  materialId: string;
  description: string;
  groupId: string;
  categoryId: string;
  subCategoryId: string;
  searchTerm: string;
} & OffsetBased;
export function useGetMiscMaterialInventory() {
  const [params, setParams] = useState<Partial<MiscMaterialInventoryParams>>();
  const { data, error, mutate } = useSWR<PaginatedResponse<MiscMaterial>>([
    '/misc-material',
    { params, namespace },
  ]);

  const pageInfo = useMemo(() => {
    const page =
      (data?.pageInfo?.offset ?? 0) / (data?.pageInfo?.limit ?? 20) + 1;
    const pageSize = data?.pageInfo?.limit ?? 20;
    return { ...(data?.pageInfo ?? {}), page, pageSize };
  }, [data]);

  return {
    miscMaterialInventory: data?.records || [],
    pageInfo: pageInfo,
    isLoading: !data && !error,
    refetchGetMiscMaterialInventory: setParams,
    mutateGetMiscMaterialInventory: mutate,
  };
}

export function useMiscMaterialBatches() {
  const [params, setParams] = useState<Partial<MiscMaterialInventoryParams>>();
  const { data, error, mutate } = useSWR<PaginatedResponse<MiscMaterialBatch>>([
    '/misc-material/batch',
    { params, namespace },
  ]);

  const pageInfo = useMemo(() => {
    const page =
      (data?.pageInfo?.offset ?? 0) / (data?.pageInfo?.limit ?? 20) + 1;
    const pageSize = data?.pageInfo?.limit ?? 20;
    return { ...(data?.pageInfo ?? {}), page, pageSize };
  }, [data]);

  return {
    miscMaterialBatches: data?.records || [],
    pageInfo: pageInfo,
    isLoading: !data && !error,
    setParams,
    mutate,
  };
}

export function useGetMiscMaterialInventoryGroup() {
  const [params, setParams] = useState<any>();
  const { data, error, mutate } = useSWR<{ group: string }[]>([
    '/temp/misc-material-inventory/group',
    { params, namespace },
  ]);

  return {
    miscMaterialInventoryGroup: data || [],
    isLoading: !data && !error,
    refetchGetMiscMaterialInventoryGroup: setParams,
    mutateGetMiscMaterialInventoryGroup: mutate,
  };
}

export function useGetMiscMaterialInventoryCategory() {
  const [params, setParams] = useState<any>();
  const { data, error, mutate } = useSWR<{ category: string }[]>([
    '/temp/misc-material-inventory/category',
    { params, namespace },
  ]);

  return {
    miscMaterialInventoryCategory: data || [],
    isLoading: !data && !error,
    refetchGetMiscMaterialInventoryCategory: setParams,
    mutateGetMiscMaterialInventoryCategory: mutate,
  };
}

export function useGetMiscMaterialInventorySubCategory() {
  const [params, setParams] = useState<any>();
  const { data, error, mutate } = useSWR<{ subCategory: string }[]>([
    '/temp/misc-material-inventory/sub-category',
    { params, namespace },
  ]);

  return {
    miscMaterialInventorySubCategory: data || [],
    isLoading: !data && !error,
    refetchGetMiscMaterialInventorySubCategory: setParams,
    mutateGetMiscMaterialInventorySubCategory: mutate,
  };
}

export function useMiscMaterialGroups() {
  const { data, error, mutate } = useSWR<MiscMaterialRoot[]>([
    '/misc-material-groups',
    { namespace },
  ]);

  return {
    miscMaterialGroups: data || [],
    isLoading: !data && !error,
    mutate,
  };
}

export interface MiscMaterialRoot {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  categories: Category[];
}

export interface Category {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  groupId: string;
  subCategories: SubCategory[];
}

export interface SubCategory {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  categoryId: string;
  groupId: string;
}
