import {
  DateRangePickerField,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';

import classes from './filter.module.scss';

import { API_NAMESPACE, WORK_ORDER_SORT_TYPE_SELECTIONS } from '@solar/data';
import { factories } from '@solar/templates';
// import useSWR from 'swr';

// const namespace = API_NAMESPACE.MATERIALS;

export function Filter() {
  // const { data } = useSWR<{ code: string; name: string }[]>([
  //   '/warehouses/work-orders/types',
  //   { namespace },
  // ]);

  // const filteredOrderType = data?.reduce((accu, cur) => {
  //   if (!['1O', '2O', '3O'].includes(cur.code)) {
  //     accu.push({ id: cur.code, name: cur.name });
  //   }

  //   return accu;
  // }, [] as { id: string; name: string }[]);

  return (
    <div className={classes.host}>
      <InputField
        registerName="searchTerm"
        placeholder="請輸入關鍵字進行搜尋（銷售訂單、工單、建立者、工作中心名稱、工作中心代碼、料號、品名"
      />
      <SelectField
        label="狀態："
        mode="multiple"
        clearable
        registerName="states"
        options={[]}
      />
      <SelectField
        label="工廠："
        mode="multiple"
        clearable
        registerName="factories"
        options={factories.map((row) => ({
          id: row.id,
          name: `${row.name} ${row.id}`,
        }))}
      />
      {/* <SelectField
        label="工單類形："
        mode="multiple"
        clearable
        registerName="orderTypes"
        options={filteredOrderType ?? []}
      /> */}
      <DateRangePickerField
        fullWidth
        label="建立日期區間："
        registerName="createdDateRange"
      />
      {/* <DateRangePickerField
        fullWidth
        label="訂單交期區間："
        registerName="expectedCompletedDateRange"
      /> */}
      <SelectField
        label="建立日期："
        clearable
        defaultValue={WORK_ORDER_SORT_TYPE_SELECTIONS[0]}
        registerName="sortType"
        options={WORK_ORDER_SORT_TYPE_SELECTIONS}
      />
    </div>
  );
}
