import { API_NAMESPACE, request } from '@solar/data';
import { UpdateMiscMaterialPayload } from './types';

const namespace = API_NAMESPACE.MATERIALS;

export const createMiscMaterial = ({
  payload,
}: {
  payload: UpdateMiscMaterialPayload;
}): Promise<Response> => {
  return request('/misc-material', {
    namespace,
    method: 'post',
    body: JSON.stringify(payload),
    responseParser: (res) => res,
  });
};
