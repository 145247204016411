import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from '@mezzanine-ui/react';
import { useEffect, useState } from 'react';
import {
  getSPItemHistory,
  SPAPIOrderItemInfo,
  useStrikePrice,
} from '@solar/data';
import moment from 'moment';

const formatNumber = (key: string) => (record: any) => {
  // 使用 Number() 函數將文字的數字轉換為數字
  const parsedNumber = Number(record[key]);

  // 檢查是否能轉換成數字
  if (isNaN(parsedNumber)) {
    return record[key];
  }

  // 使用 .toLocaleString() 格式化數字
  return parsedNumber.toLocaleString();
};

interface ISPOrderHistoryProps {
  open: boolean;
  onFinish?: () => void;
  refId: string;
}

const SPItemHistoryModal = ({
  open,
  onFinish = () => {
    /** */
  },
  refId,
}: ISPOrderHistoryProps) => {
  const [dataList, setDataList] = useState<SPAPIOrderItemInfo[]>([]);
  const getHistoryList = async () => {
    const list = await getSPItemHistory(refId);
    setDataList(list);
  };

  const { autoCompleteReportPriceState } = useStrikePrice();

  const stateNameMap = autoCompleteReportPriceState.reduce((acc, cur) => {
    acc[cur.id] = cur.name;
    return acc;
  }, {} as any);

  useEffect(() => {
    if (open && refId) {
      getHistoryList();
    }
  }, [open, refId]);
  const columns = [
    {
      title: '修改時間',
      render: (record: any) =>
        record.createdAt
          ? moment(record.createdAt).format('YYYY/MM/DD HH:mm:ss')
          : '',
      renderTooltipTitle: (record: any) =>
        record.createdAt
          ? moment(record.createdAt).format('YYYY/MM/DD HH:mm:ss')
          : '',
      width: 220,
    },
    {
      title: '更改者',
      dataIndex: 'createUserName',
      width: 100,
    },
    {
      title: '敲價單狀態',
      render: (record: any) => stateNameMap[record.reportState],
      renderTooltipTitle: (record: any) => stateNameMap[record.reportState],
      width: 150,
    },
    {
      title: '平台盤價',
      width: 140,
      render: formatNumber('platformPrice'),
    },
    {
      title: '盤價幣別',
      dataIndex: 'standardCurrency',
      width: 90,
    },
    {
      title: '盤價單位',
      dataIndex: 'standardUnit',
      width: 90,
    },
    {
      title: '成交匯率',
      dataIndex: 'exchange',
      width: 140,
    },
    {
      title: '敲價幣別',
      dataIndex: 'standardCurrency',
      width: 90,
    },
    {
      title: '敲價單位',
      dataIndex: 'reportUnit',
      width: 90,
    },
    {
      title: '敲價單價',
      width: 140,
      render: formatNumber('reportUnitPrice'),
    },
    {
      title: '加減碼',
      dataIndex: 'premium',
      width: 80,
    },
    {
      title: '報價單價',
      width: 140,
      render: formatNumber('quoteUnitPrice'),
    },
    {
      title: '確認數量(G)',
      width: 140,
      render: formatNumber('checkQty'),
    },
    {
      title: '暫敲數量(G)',
      render: formatNumber('tempQty'),
    },
  ];

  return (
    <Modal
      open={open}
      disableCloseOnBackdropClick
      disableCloseOnEscapeKeyDown
      style={{ width: 1300 }}
      onClose={onFinish}
    >
      <ModalHeader>修改歷程</ModalHeader>
      <ModalBody>
        <Table columns={columns} dataSource={dataList} scroll={{ x: 1800 }} />
      </ModalBody>
    </Modal>
  );
};

export default SPItemHistoryModal;
