import { getRoleGroup } from './shared';
import { FIRST_SPEC_GROUP } from './target';

const EMBRYO_SMALL_GROUP = {
  1: {
    name: '未分類',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
};

const EMBRYO_MEDIUM_GROUP_CIRCULAR = {
  1: {
    name: '未分類',
    nextLevel: EMBRYO_SMALL_GROUP,
  },
};

const EMBRYO_MEDIUM_GROUP_FLAT = {
  C: {
    name: '圓餅',
    nextLevel: EMBRYO_SMALL_GROUP,
  },
  R: {
    name: '矩形',
    nextLevel: EMBRYO_SMALL_GROUP,
  },
  T: {
    name: '三角形',
    nextLevel: EMBRYO_SMALL_GROUP,
  },
  Z: {
    name: '異形',
    nextLevel: EMBRYO_SMALL_GROUP,
  },
};

export const EMBRYO_LARGE_GROUP = {
  F: {
    name: '平面靶',
    nextLevel: EMBRYO_MEDIUM_GROUP_FLAT,
  },
  R: {
    name: '旋轉靶',
    nextLevel: EMBRYO_MEDIUM_GROUP_CIRCULAR,
  },
};
