import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from '@mezzanine-ui/react';
import { Fragment, useState } from 'react';
import useSWR from 'swr';
import { API_NAMESPACE } from '@solar/data';
import {
  TemplateProductionForms,
  useFormTemplateProductionFormListColumns,
} from './use-form-template-production-form-list-columns';

export function FormTemplateProductionFormList({
  templateId,
  templateName,
  templateSuffix,
}: {
  templateId: string;
  templateName: string;
  templateSuffix: string | null;
}) {
  const [modalShown, setModalShown] = useState(false);

  const { data, isValidating } = useSWR<TemplateProductionForms[]>(
    modalShown
      ? [`/form-templates/${templateId}/forms`, { namespace: API_NAMESPACE.PP }]
      : undefined
  );

  const columns = useFormTemplateProductionFormListColumns({
    templateName,
    templateSuffix,
  });

  return (
    <Fragment>
      <Button
        size="small"
        type="button"
        variant="outlined"
        onClick={() => setModalShown(true)}
      >
        已綁定題目
      </Button>
      <Modal
        size="extraLarge"
        onClose={() => setModalShown(false)}
        open={modalShown}
      >
        <ModalHeader>已綁定規則</ModalHeader>
        <ModalBody>
          <Table
            columns={columns}
            loading={isValidating}
            dataSource={data ?? []}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
}
