import { TableColumn } from '@mezzanine-ui/core/table';
import { useMemo } from 'react';
import { RecipeRow, RecipeTableFormFields } from './typing';
import { UseFormReturn } from 'react-hook-form';
import { Button, Icon } from '@mezzanine-ui/react';
import { DocIcon } from '@mezzanine-ui/icons';
import styles from './recipe-table-page.module.scss';
import {
  MaterialField,
  MaterialHighlight,
} from '../../Material/components/MaterialHighlight/material-highlight';
import { ProductionFormCondition } from '@solar/data';

export function useRecipeTableColumns(
  methods: UseFormReturn<RecipeTableFormFields>,
  setLocationViewerAnchor: React.Dispatch<
    React.SetStateAction<HTMLElement | null | undefined>
  >,
  setDrawingViewerAnchor: React.Dispatch<
    React.SetStateAction<HTMLElement | null | undefined>
  >,
  setPreviewingFormConditions: (condition: ProductionFormCondition) => void
) {
  return useMemo<TableColumn<RecipeRow>[]>(
    () => [
      {
        title: '題參',
        width: 64,
        render: (recipe) => (
          <Button
            onClick={() =>
              setPreviewingFormConditions({
                materialId: recipe.producedMaterialId,
                recipeId: recipe.recipeId,
                recipeVersion: recipe.recipeVersion?.toString(),
                processingId: recipe.processing.id,
                workCenterId: recipe.workCenter?.id ?? undefined,
                equipmentId: recipe.workCenter?.equipments[0]?.id ?? undefined,
              })
            }
            className={styles.iconButton}
            color="secondary"
            type="button"
          >
            <Icon icon={DocIcon} />
          </Button>
        ),
      },
      {
        dataIndex: 'sequence',
        title: '序列',
        width: 64,
      },
      {
        dataIndex: 'workOrderSequence',
        title: '工單範圍',
        width: 90,
      },
      {
        dataIndex: 'processing.id',
        title: '行為碼',
        width: 84,
      },
      {
        dataIndex: 'processing.name',
        title: '行為',
        width: 120,
      },
      {
        dataIndex: 'workCenter.id',
        title: '工作中心碼',
        width: 140,
      },
      {
        dataIndex: 'workCenter.name',
        title: '工作中心',
        width: 220,
      },
      {
        title: '位置',
        width: 150,
        render: (row) =>
          row.workCenter.equipments.length ? (
            <div
              data-zone-id={row.workCenter.equipments[0].stackId}
              style={{ cursor: 'pointer' }}
              onMouseEnter={(event: any) => {
                if (row.workCenter.equipments[0].stackId) {
                  methods.setValue(
                    'selectedChartId',
                    row.workCenter.equipments[0].stackId
                  );

                  setLocationViewerAnchor(event.currentTarget);
                }
              }}
            >
              {row.workCenter.equipments[0].stackId}
            </div>
          ) : (
            '-'
          ),
      },
      {
        title: '投入料號',
        width: 400,
        render: (row) => (
          <MaterialHighlight
            copyable
            id={row.inputMaterialId}
            highlights={[MaterialField.FEATURE_CODE_1]}
          />
        ),
      },
      {
        title: '產出料號',
        width: 400,
        render: (row) => (
          <MaterialHighlight
            copyable
            id={row.producedMaterialId}
            highlights={[MaterialField.FEATURE_CODE_1]}
          />
        ),
      },
      {
        title: '投入標準重',
        width: 150,
        render: (row) => `${row.standardWeight} ${row.standardUnit}`,
      },
      {
        title: '標準產出重',
        width: 150,
        render: (row) =>
          `${row.standardOutputWeight} ${row.standardOutputUnit}`,
      },
      {
        dataIndex: 'standardManPowerMinutes',
        title: '人分',
        width: 120,
      },
      {
        dataIndex: 'standardMachineMinutes',
        title: '機分',
        width: 120,
      },
      {
        dataIndex: 'componentCode',
        title: '成分代碼',
        width: 120,
      },
      {
        dataIndex: 'oldComponentCode',
        title: '成分代碼(舊)',
        width: 120,
      },
      {
        title: '圖號',
        width: 240,
        render: (row) =>
          row.drawingCode ? (
            <div
              data-zone-id={row.drawingCode}
              style={{ cursor: 'pointer' }}
              onClick={(event: any) => {
                if (row.drawingCode) {
                  methods.setValue('selectedDrawingId', row.drawingCode);

                  // setDrawingViewerAnchor(event.currentTarget);
                }
              }}
            >
              {row.drawingCode}
            </div>
          ) : (
            '-'
          ),
      },
      {
        dataIndex: 'oldDrawingCode',
        title: '圖號(舊)',
        width: 120,
      },
    ],
    [
      methods,
      setDrawingViewerAnchor,
      setLocationViewerAnchor,
      setPreviewingFormConditions,
    ]
  );
}
