import { useEffect, useMemo, useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { fetchUrl } from './endpoint';
import { environment } from '../environments/environment';
import { API_NAMESPACE, request } from '../request';

export const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
  apikey: environment.API_KEY,
};

const namespace = API_NAMESPACE.HR;

const REPORTS_HR = '/reports/hr/payments';

export function useHumanResourceBUReport() {
  const url = fetchUrl(REPORTS_HR);

  const [data, setData] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const [baseYear, setBaseYear] = useState<string | undefined>(undefined);

  const init = useMemo(() => {
    if (!baseYear) return true;
    return false;
  }, [baseYear]);

  const years = [];
  const currYear = new Date().getFullYear();
  for (let i = currYear; i >= 1979; i--) {
    years.push({ id: String(i), name: String(i) });
  }

  // const { data: swrData } = useSWR([REPORTS_HR, { namespace }]);
  const { mutate } = useSWRConfig();

  const filterMutation = (payload: {
    baseYear: string;
    bonusConditions: string[];
  }) => {
    if (!payload.baseYear) return;
    setLoading(true);
    const formatConditions = payload.bonusConditions?.map((condition: string) =>
      condition.replace('-', '/')
    );
    const bodyPayload = {
      ...payload,
      bonusConditions: formatConditions ?? [],
    };

    const body = JSON.stringify(bodyPayload);
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    const update = async () => {
      const res = await request(REPORTS_HR, {
        method: 'POST',
        headers,
        body,
        namespace,
      });
      return res ?? null;
    };
    mutate(REPORTS_HR, update, false);
  };

  // useEffect(() => {
  //   if (!swrData || typeof swrData === 'string') return;
  //   setData(swrData);
  //   setLoading(false);
  // }, [swrData]);

  return {
    loading,
    init,
    baseYear,
    setBaseYear,
    data,
    years,
    filterMutation,
  };
}
