import {
  Navigation,
  NavigationItem,
  NavigationSubMenu,
} from '@mezzanine-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { standardOrderMenus } from './const';
import {
  reconcileResourcePathWithPermittedRoles,
  reorderSubsetMenus,
} from './helper';
import styles from './navigation-bar.module.scss';

export function NavigationBar({
  menusAndSubItems,
}: {
  menusAndSubItems: ReturnType<typeof reconcileResourcePathWithPermittedRoles>;
}) {
  const { pathname } = useLocation();

  const fixedOrderMenus = reorderSubsetMenus(
    menusAndSubItems,
    standardOrderMenus
  );

  if (pathname.includes('/auth/login')) return null;

  return (
    <Navigation orientation="vertical" className={styles.navigation}>
      {fixedOrderMenus?.map(({ parentModuleName, childModules }) => (
        <NavigationSubMenu key={parentModuleName} title={parentModuleName}>
          {childModules?.map(
            ({
              id,
              name,
              path,
            }: {
              id: number;
              name: string;
              path: string;
            }) => (
              <NavigationItem key={id} active={pathname.includes(path)}>
                <Link to={path} className={styles.link}>
                  {name}
                </Link>
              </NavigationItem>
            )
          )}
        </NavigationSubMenu>
      ))}
    </Navigation>
  );
}
