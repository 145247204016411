import { deleteDrawing, updateDrawing, useDrawingRefs, usePresignedFileLink } from "@solar/data";
import { Button, Modal, message } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { EditDrawingForm, useEditDrawingForm } from "../EditDrawingForm/EditDrawingForm";
import { useMemo } from "react";

export function EditDrawingPage() {
  const params = useParams();
  const navigate = useNavigate();
  const { form, uploading } = useEditDrawingForm();
  const { drawingRefs } = useDrawingRefs(params);
  const currentDrawing = drawingRefs?.me;
  const { fileLink: previewLink } = usePresignedFileLink({
  });
  const currentFile = useMemo(() => currentDrawing ? ({
    fileLink: currentDrawing?.fileLink,
    fileName: currentDrawing?.fileName,
    fileType: currentDrawing?.fileType,
    s3ObjectKey: currentDrawing?.s3ObjectKey,
  }) : null, [currentDrawing]);

  return (
    <EditDrawingForm
      form={form}
      title={currentDrawing?.newDrawingCode}
      currentDrawing={currentDrawing}
      previewLink={previewLink}
      parentDrawing={drawingRefs?.parentDrawings?.[0]}
      childDrawings={drawingRefs?.childDrawings}
      parentDrawingCode={currentDrawing?.parentDrawingCode ?? null}
      currentFile={currentFile}
      extraContent={(
        <div style={{ display: 'flex', gap: 'var(--mzn-spacing-4)'}}>
          <Button>
            <Link to={`/drawings/${currentDrawing?.newDrawingCode}`}>
              取消編輯
            </Link>
          </Button>
          <Button loading={uploading} type="primary" htmlType="submit">
            儲存
          </Button>
          <Button
            danger
            type="primary"
            onClick={() => {
              Modal.confirm({
                title: '確認刪除',
                okText: '確認',
                cancelText: '取消',
                onOk: async () => {
                  if (currentDrawing?.id) {
                    const response = await deleteDrawing({ id: currentDrawing?.id });
                    if (response?.code === 200) {
                      message.success('刪除成功');
                      navigate('/drawings');
                    } else {
                      message.error('刪除失敗');
                    }
                  }
                },
              })
            }}
          >
            刪除
          </Button>
        </div>
      )}
      onFinish={async (payload) => {
        try {
          if (currentDrawing?.id) {
            const response = await updateDrawing({ id: currentDrawing?.id, data: payload });
            const newDrawingCode = response?.data?.newDrawingCode;
            if (response?.code === 200 && newDrawingCode) {
              message.success('更新成功');
              navigate(`/drawings/${newDrawingCode}`);
            }
          }
        } catch (error) {
          message.error('建立失敗');
        }
      }}
    />
  )
}