import { SearchIcon } from '@mezzanine-ui/icons';
import {
  Button,
  Icon,
  Input,
  Message,
  Table,
  Typography,
} from '@mezzanine-ui/react';
import {
  API_NAMESPACE,
  IQCOrderType,
  IncomingPurchaseOrder,
  InventoryPurchaseOrderResponse,
  checkJWTRoles,
  useGetIQCOrderStatuses,
  useInventoryByPurchaseOrder,
  useLoaders,
  useOutSourcePurchaseOrder,
  useOutSourcePurchaseOrderV2,
} from '@solar/data';
import {
  BatchUpdateButton,
  CollapsibleContainer,
  CopyTextWrapper,
  LocationSelectorGroupModal,
  ModalGroup,
  useDraggableColumns,
  useLocationSelectorController,
  useModalGroupController,
} from '@solar/templates';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classes from './incoming-inspect-detail-page.module.scss';

import { useLocation, useParams } from 'react-router';
import { LOCATION_SELECTOR } from '../../Equipment/LocationSelector/LocationSelectorModal';
import styles from '../../SalesAndDistribution/PackagingPage/PackagingPage.module.scss';
import { OrderMetaInfo } from '../components';
import { PageLayout } from '../PageLayout/PageLayout';
import {
  OUTSOURCE_ORDER_META_TRANSLATION,
  PURCHASE_ORDER_META_TRANSLATION,
} from './constant';
import { detailPageHeader } from './detail-page-header';
import {
  useDebounceILikeSearch,
  usePurchaseOrderExpectedColumns,
  usePurchaseOrderReceivedColumns,
} from './hooks';
import { InspectMaterialType } from './inspect-modal-enum';
import { CancelReceiveModal } from './Modals/CancelReceiveModal';
import {
  MainMaterialUnitsObj,
  ReceiveActionInputs,
  TempReceiveActionModal,
} from './Modals/TempReceiveActionModal';
import { Modals, ReceiveActionModalOpenFunc } from './typings';
import {
  handleTableDataILikeSearch,
  // uncheckInventoryPurchaseOrders,
  uploadCsv,
} from './utils';
import { IncomingQualityControlTable } from '../../Qualifications/IncomingQualityControlTable/IncomingQualityControlTable';
import { Space } from 'antd';
import { IncomingQualityControlModal } from './IncomingQualityControlModal';
import { TempReceiveActionForOutsourceModal } from './Modals/TempReceiveActionForOutsourceModal';
import { TempRejectActionForOutsourceModal } from './Modals/TempRejectActionForOutsourceModal';

const namespace = API_NAMESPACE.MATERIALS;

export type ReceivableItemTable = {
  id: string;
  materialId: string;
  oldMaterialId: string;
  materialDescription: string;
  expectedMainMaterialUnitQuantity: number;
  receivedMainMaterialUnitQuantity?: number;
  receiveUnit: string;
  specId: string;
};

export type ReceivedMaterialItemTable = ReceivableItemTable &
  Pick<ReceiveActionInputs, 'loader' | 'warehouse'> & {
    subItems: ReceivedMaterialSubItem[];
  };

export type ReceivedMaterialSubItem = { id: string } & MainMaterialUnitsObj &
  Pick<ReceivableItemTable, 'receiveUnit'>;

export function OutsourcePurchaseOrderDetailPage() {
  const location = useLocation();
  const { orderId } = useParams();
  const { orders: data, isLoading } = useOutSourcePurchaseOrderV2({ orderId });
  const orders = data?.items ?? [];
  const [currentModal, setCurrentModal] = useState<
    Modals.ReceiveAction | Modals.RejectAction | null
  >(null);
  const expectedTableRef = useRef<HTMLTableElement>(null);
  const inventoryTableRef = useRef<HTMLTableElement>(null);
  // const logs = data?.logs ?? [];

  const { tableDataSearchTerms, debounceInputHandler } =
    useDebounceILikeSearch();

  const { statuses } = useGetIQCOrderStatuses();

  const orderMetaDataLabelAndContext = useMemo(
    () =>
      data
        ? Object.entries(OUTSOURCE_ORDER_META_TRANSLATION).map(([key, zh]) => ({
            label: zh,
            context: data[key as keyof typeof OUTSOURCE_ORDER_META_TRANSLATION],
          }))
        : [],
    [data]
  );

  const modalGroupController = useModalGroupController([
    { name: Modals.ReceiveAction, keepData: true },
    { name: Modals.RejectAction, keepData: true },
    { name: Modals.Cancel },
    { name: LOCATION_SELECTOR },
    { name: Modals.QUALITY_CONTROL },
  ]);

  const { loaders, mutateLoaderParams } = useLoaders();

  const locationSelectorController = useLocationSelectorController({
    resetAfterClosing: false,
    onSelectedIdsChange(selectedIds) {
      const shelfId =
        selectedIds?.shelfId ?? selectedIds?.stackId ?? selectedIds?.zoneId;

      if (shelfId) {
        mutateLoaderParams({
          shelfIds: [shelfId],
          whenNotFoundAutoCreate: true,
        });
      }
    },
  });

  useEffect(() => {
    if (locationSelectorController?.activeId) {
      mutateLoaderParams({
        shelfIds: [locationSelectorController.activeId],
      });
    }
  }, [locationSelectorController.activeId, mutateLoaderParams]);

  const svgMapController = useLocationSelectorController({
    resetAfterClosing: false,
  });

  const receiveActionModalOpen: ReceiveActionModalOpenFunc = useCallback(
    (item) => {
      const modalProps = modalGroupController.getModalProps(
        Modals.ReceiveAction
      );
      const data = modalProps?.data;
      modalGroupController.openModal(Modals.ReceiveAction, {
        ...data,
        ...item,
      });
    },
    [modalGroupController]
  );

  const rejectActionModalOpen = useCallback(
    (item: any) => {
      const modalProps = modalGroupController.getModalProps(
        Modals.RejectAction
      );
      const data = modalProps?.data;
      modalGroupController.openModal(Modals.RejectAction, {
        ...data,
        ...item,
      });
    },
    [modalGroupController]
  );

  const cancelModalOpen = useCallback(
    (cancelFunc: () => void) => {
      modalGroupController.openModal(Modals.Cancel, { cancelFunc: cancelFunc });
    },
    [modalGroupController]
  );

  const defaultColumn = usePurchaseOrderExpectedColumns({
    receiveActionModalOpen,
  });

  const expectedColumn = useDraggableColumns<
    InventoryPurchaseOrderResponse['items'][number]
  >(
    expectedTableRef,
    defaultColumn?.map((col, index) =>
      index === 0
        ? {
            title: '操作',
            width: 120,
            align: 'center' as const,
            render(source) {
              return (
                checkJWTRoles([82]) && (
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      receiveActionModalOpen(source as any);
                      setCurrentModal(Modals.ReceiveAction);
                    }}
                  >
                    收料
                  </Button>
                )
              );
            },
          }
        : col
    )
  );

  const inventoryColumns = useDraggableColumns(inventoryTableRef, [
    {
      title: '操作',
      width: 120,
      align: 'center' as const,
      render: (source: any) =>
        checkJWTRoles([82]) && (
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              rejectActionModalOpen(source);
              setCurrentModal(Modals.RejectAction);
            }}
          >
            退回
          </Button>
        ),
    },
    {
      title: '品名',
      width: 500,
      render: (source: any) => (
        <CopyTextWrapper text={source?.materialDescription ?? ''} />
      ),
    },
    {
      width: 200,
      title: '料號',
      render: (source: any) => (
        <CopyTextWrapper text={source?.materialId ?? ''} />
      ),
    },
    {
      title: '批號',
      render: (source: any) =>
        `${source?.materialSapBatchId}-${source?.materialSubBatchId}`,
    },
    {
      dataIndex: 'stockQuantity',
      title: '剩餘數量',
    },
    {
      dataIndex: 'stockUnit',
      title: '單位',
    },
  ]);

  const receivedMaterialColumn = usePurchaseOrderReceivedColumns({
    cancelModalOpen,
  });

  const onUploadCsv = useCallback(async (file: File) => {
    try {
      const res = await uploadCsv({
        file,
        endPoint: '/warehouses/inventory-purchase-orders/receipt-csv',
        namespace,
      });

      if (res.ok) {
        Message.success('批量入庫成功');
      }
    } catch (err) {
      if (err instanceof Error) {
        Message.error(JSON.parse(err.message).message);
      } else {
        Message.error('上傳失敗');
      }
    }
  }, []);

  const onQualityControl = useCallback(
    (action: '驗收' | '退貨', source: IncomingPurchaseOrder) => {
      modalGroupController.openModal(Modals.QUALITY_CONTROL, {
        action,
        source,
      });
    },
    [modalGroupController]
  );

  return (
    <PageLayout setGoBack>
      <div className={classes['row-block']}>
        <OrderMetaInfo labelAndContexts={orderMetaDataLabelAndContext ?? []} />
      </div>
      <CollapsibleContainer
        enableRowBlockStyle
        open
        header={
          <div className={classes['action-container']}>
            <Typography variant="h4" color="text-primary">
              應收項目
            </Typography>
            <Input
              placeholder="料號搜尋"
              prefix={<Icon icon={SearchIcon} />}
              size="large"
              clearable
              onChange={(e) =>
                debounceInputHandler(e, 'receivableMaterialIdSearch')
              }
            />
            <Input
              placeholder="品名搜尋"
              prefix={<Icon icon={SearchIcon} />}
              size="large"
              clearable
              onChange={(e) =>
                debounceInputHandler(e, 'receivableMaterialDescriptionSearch')
              }
            />
          </div>
        }
      >
        <br />
        <Table
          ref={expectedTableRef}
          columns={expectedColumn}
          dataSource={handleTableDataILikeSearch({
            dataSource: orders,
            fieldParams: {
              materialIdKey: 'receivableMaterialIdSearch',
              materialDescriptionKey: 'receivableMaterialDescriptionSearch',
            },
            tableDataSearchTerms,
          })}
          scroll={{ x: 1200 }}
          loading={isLoading}
          bodyClassName={styles.table}
          headerClassName={styles.tableHeader}
        />
      </CollapsibleContainer>
      <CollapsibleContainer
        enableRowBlockStyle
        open
        header={
          <div className={classes['action-container']}>
            <Typography variant="h4" color="text-primary">
              委外庫存
            </Typography>
          </div>
        }
      >
        <Table
          ref={inventoryTableRef}
          scroll={{ x: 1200 }}
          loading={isLoading}
          dataSource={data?.stocks ?? []}
          columns={inventoryColumns}
        />
      </CollapsibleContainer>
      <CollapsibleContainer
        enableRowBlockStyle
        open
        header={
          <div className={classes['action-container']}>
            <Typography variant="h4" color="text-primary">
              操作紀錄
            </Typography>
          </div>
        }
      >
        <br />
        <div style={{ width: '100%', overflow: 'scroll' }}>
          <IncomingQualityControlTable
            iqcOrderType={IQCOrderType.PURCHASE_ORDER}
            purchaseOrderId={orderId}
            statusIds={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
            getColumns={(defaultColumns) => [
              {
                width: 100,
                title: '操作',
                render: (source: IncomingPurchaseOrder) => (
                  <Space>
                    {(source.statusId === 2 || source.statusId === 8) && (
                      <Button
                        type="button"
                        variant="contained"
                        onClick={() => onQualityControl('驗收', source)}
                      >
                        驗收
                      </Button>
                    )}
                    {(source.statusId === 5 || source.statusId === 9) && (
                      <Button
                        danger
                        disabled
                        type="button"
                        variant="contained"
                        onClick={() => onQualityControl('退貨', source)}
                      >
                        退貨
                      </Button>
                    )}
                  </Space>
                ),
              },
              ...defaultColumns,
            ]}
          />
        </div>
      </CollapsibleContainer>
      <ModalGroup {...modalGroupController}>
        <CancelReceiveModal />
        <TempRejectActionForOutsourceModal
          loaders={loaders}
          locationSelectorController={locationSelectorController}
        />
        <TempReceiveActionForOutsourceModal
          loaders={loaders}
          stocks={data?.stocks ?? []}
          locationSelectorController={locationSelectorController}
        />
        <LocationSelectorGroupModal
          controller={svgMapController}
          showShelfSvg={true}
          onSubmit={(_, selectedIds) => {
            locationSelectorController.setSelectedIds(selectedIds ?? {});

            switch (currentModal) {
              case Modals.RejectAction:
                rejectActionModalOpen(null);
                break;
              case Modals.ReceiveAction:
                receiveActionModalOpen(null);
                break;
              default:
                break;
            }
          }}
          closeModal={() => {
            switch (currentModal) {
              case Modals.RejectAction:
                rejectActionModalOpen(null);
                break;
              case Modals.ReceiveAction:
                receiveActionModalOpen(null);
                break;
              default:
                break;
            }
          }}
        />
        <IncomingQualityControlModal purchaseOrderId={orderId} />
      </ModalGroup>
    </PageLayout>
  );
}
