import { useState, useMemo, useEffect, useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import useSWR from 'swr';
import { Submission, SubmissionResponse } from './typings';
import { API_NAMESPACE } from '@solar/data';

export interface SubmissionFilter {
  page: number;
  pageSize: number;
  sampleName: string;
  batchNumber: string;
}

const namespace = API_NAMESPACE.LIMS;

export function useSubmissions(
  methods: UseFormReturn<SubmissionFilter>,
  factoryId: string
): {
  submissions: Submission[];
  loading: boolean;
  pageInfo?: Omit<SubmissionResponse, 'submissions'>;
  updateQueryParams: () => void;
} {
  const page = methods.watch('page');
  const pageSize = methods.watch('pageSize');

  const [searchSampleName, setSearchSampleName] = useState(
    methods.getValues().sampleName ?? ''
  );

  const [searchBatchNumber, setSearchBatchNumber] = useState(
    methods.getValues().batchNumber ?? ''
  );

  const queryURL = useMemo(() => {
    const params = new URLSearchParams();

    params.append('factoryId', factoryId);
    params.append('limit', pageSize.toString());
    params.append('offset', (pageSize * (page - 1)).toString());

    if (searchSampleName?.trim()) {
      params.append('sampleName', searchSampleName.trim());
    }

    if (searchBatchNumber?.trim()) {
      params.append('batchNumber', searchBatchNumber.trim());
    }

    return `/lims/submissions?${params.toString()}`;
  }, [factoryId, pageSize, page, searchBatchNumber, searchSampleName]);

  const { data } = useSWR<SubmissionResponse>([queryURL, { namespace }]);

  const submissions = useMemo(() => data?.submissions ?? [], [data]);

  const updateQueryParams = useCallback(() => {
    const { sampleName, batchNumber } = methods.getValues();

    setSearchSampleName(sampleName);
    setSearchBatchNumber(batchNumber);
  }, [methods]);

  return {
    submissions,
    loading: !data,
    pageInfo: data
      ? {
          hasNext: data.hasNext,
          hasPrevious: data.hasPrevious,
          limit: data.limit,
          offset: data.offset,
          totalCount: data.totalCount,
          totalPages: data.totalPages,
        }
      : undefined,
    updateQueryParams,
  };
}
