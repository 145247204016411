import {
  AutoCompleteMultiField,
  DateRangePickerField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';

import classes from './filter.module.scss';
import { useLocation } from 'react-router-dom';

import { CustomInputSearch } from '@solar/templates';
import { useCustomers, useDebounce } from '@solar/data';
import { useEffect, useState } from 'react';
import { PACKED_STATES } from './const';

export function Filter() {
  const location = useLocation();

  const {
    records: customers,
    mutateParams: mutateCustomersParams,
    pageInfo,
  } = useCustomers();

  const [searchTerm, setSearchTerm] = useState('');
  const watchCustomers = useDebounce(searchTerm, 800);

  useEffect(() => {
    mutateCustomersParams((prev) => ({ ...prev, searchTerm: watchCustomers }));
  }, [mutateCustomersParams, watchCustomers]);

  return (
    <div className={classes.host}>
      <CustomInputSearch />
      <DateRangePickerField
        fullWidth
        label="日期區間："
        registerName="dateRange"
      />
      <AutoCompleteMultiField
        label="客戶名稱："
        registerName="customers"
        options={customers}
        onInput={(e) => {
          setSearchTerm((e.target as HTMLInputElement).value);
        }}
      />
      <SelectField
        label="狀態："
        mode="multiple"
        clearable
        registerName="packedStates"
        options={PACKED_STATES}
      />
    </div>
  );
}
