import { ReactNode } from 'react';

import {
  Loading,
  Modal,
  ModalBody,
  ModalHeader,
  ModalProps,
  cx,
} from '@mezzanine-ui/react';

import styles from './modal-layout.module.scss';

export type ModalLayoutProps = {
  open: boolean | undefined;
  data?: any;
  closeModal: () => void;
  modalHeader?: string | ReactNode;
  children?: ReactNode;
  className?: string;
  modalFooter?: ReactNode;
  loading?: boolean;
};

export function ModalLayout({
  open,
  data,
  closeModal,
  modalHeader,
  modalFooter,
  children,
  className,
  loading = false,
  ...restModalProps
}: ModalLayoutProps & ModalProps) {
  return (
    <Modal
      loading={loading}
      className={cx(styles.modal_wrapper, className)}
      open={open}
      onClose={closeModal}
      {...restModalProps}
    >
      {modalHeader && <ModalHeader>{modalHeader}</ModalHeader>}
      <Loading className={styles['modal-loading-wrapper']} loading={loading}>
        <ModalBody>{children}</ModalBody>
      </Loading>
      {modalFooter}
    </Modal>
  );
}
