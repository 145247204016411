import {
  Button,
  Message,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@mezzanine-ui/react';
import { useTargetModal } from '@solar/templates';
import { useForm } from 'react-hook-form';
import { Modals } from './Modals.enum';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import {
  MaterialStack,
  NextPagination,
  updateMaterialStack,
} from '@solar/data';

export function UpdateMaterialModal({
  refetchGetMaterialStacks,
}: {
  refetchGetMaterialStacks: (next: NextPagination) => void;
}) {
  const methods = useForm<MaterialStack>();
  const { open, data, closeModal } = useTargetModal<MaterialStack>(Modals.EDIT);

  const onClose = () => {
    refetchGetMaterialStacks({ nextPage: 1 });
    closeModal();
  };

  const onSubmit = methods.handleSubmit(async (formState) => {
    try {
      const mainMaterialUnitQuantity = Number(
        formState?.mainMaterialUnitQuantity
      );
      await updateMaterialStack({
        id: data?.id ?? -1,
        data: {
          ...formState,
          mainMaterialUnitQuantity: isNaN(mainMaterialUnitQuantity)
            ? undefined
            : mainMaterialUnitQuantity,
        },
      }).then(() => {
        console.log('update');
        onClose();
      });
    } catch (error) {
      Message.error('更新失敗');
    }
  });

  return (
    <Modal open={open} onClose={onClose}>
      <ModalHeader>更新物料</ModalHeader>
      <ModalBody>
        <FormFieldsWrapper
          methods={methods}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 'var(--mzn-spacing-4)',
          }}
        >
          <InputField
            label="RunCard單號"
            registerName="runCardNumber"
            defaultValue={data?.runCardNumber}
          />
          <InputField
            label="位置"
            registerName="stackId"
            defaultValue={data?.stackId}
          />
          <InputField
            label="舊料號"
            registerName="oldMaterialId"
            defaultValue={data?.oldMaterialId}
          />
          <InputField
            label="新料號"
            registerName="materialId"
            defaultValue={data?.materialId}
          />
          <InputField
            label="料號描述"
            registerName="description"
            defaultValue={data?.description}
          />
          <InputField
            label="批號"
            registerName="materialBatchId"
            defaultValue={data?.materialBatchId}
          />
          <InputField
            label="數量"
            registerName="mainMaterialUnitQuantity"
            defaultValue={data?.mainMaterialUnitQuantity?.toString()}
          />
          <InputField
            label="單位"
            registerName="mainMaterialUnit"
            defaultValue={data?.mainMaterialUnit}
          />
          <InputField
            label="管理單位"
            registerName="managementUnit"
            defaultValue={data?.managementUnit}
          />
          <InputField
            label="備註"
            registerName="note"
            defaultValue={data?.note}
          />
          <InputField
            label="高度"
            registerName="height"
            defaultValue={data?.height}
          />
          <InputField
            label="特徵碼"
            registerName="featureCode"
            defaultValue={data?.featureCode}
          />
          <InputField
            label="加工碼"
            registerName="machineCode"
            defaultValue={data?.machineCode}
          />
        </FormFieldsWrapper>
      </ModalBody>
      <ModalFooter
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 'var(--mzn-spacing-4)',
        }}
      >
        <Button onClick={onClose}>取消</Button>
        <Button variant="contained" onClick={onSubmit}>
          更新
        </Button>
      </ModalFooter>
    </Modal>
  );
}
