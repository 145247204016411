import { SelectValue, Typography } from '@mezzanine-ui/react';
import { InputField } from '@mezzanine-ui/react-hook-form';
import { CustomerInfo } from 'apps/frontend/src/app/Transportation/RequirementsManagementPage/RequirementManagement.interface';
import styles from './Delivery-Info-Start-End.module.scss';
import { useCallback, useMemo } from 'react';
import useSWR from 'swr';
import { useDebounce } from '@solar/data';
import { useFormContext } from 'react-hook-form';
import { InputWithDataList } from '../../Selectors/InputWithDataList/InputWithDataList';

type DeliveryInfoStartEndProps = {
  title: string;
  companyRegisterName: string;
  contactRegisterName: string;
  phoneRegisterName: string;
  addressRegisterName: string;
  factoryRegisterName: string;
};

export function DeliveryInfoStartEnd({
  title,
  companyRegisterName,
  contactRegisterName,
  phoneRegisterName,
  addressRegisterName,
  factoryRegisterName,
}: DeliveryInfoStartEndProps) {
  const { setValue, watch, resetField } = useFormContext();
  const nameValue = watch(companyRegisterName);
  const debounceName = useDebounce(nameValue, 500);

  const { data } = useSWR<CustomerInfo[]>(
    [
      `/logistics/customers?searchTerm=${debounceName || ''}`,
      { namespace: '/sd/sd' },
    ],
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    }
  );

  const companyOptions = useMemo(() => {
    return Array.from(new Set(data?.map((item) => item.name))).map(
      (item, index) => ({ id: index.toString(), name: item })
    );
  }, [data, debounceName]);

  const { phoneOptions, addressOptions, factoryOptions } = useMemo(() => {
    if (!data || !debounceName) return {};
    let phoneOptions: SelectValue[] = [];
    let addressOptions: SelectValue[] = [];
    let factoryOptions: SelectValue[] = [];
    data.forEach((item, index) => {
      const id = index.toString();
      const { phone, zipcode, city, address, factory } = item;
      const fullAddress = `${zipcode} ${city}${address}`;
      phoneOptions.push({ id, name: phone });
      addressOptions.push({ id, name: fullAddress });
      factoryOptions.push({ id, name: factory });
    });

    return { phoneOptions, addressOptions, factoryOptions };
  }, [data, debounceName]);

  const onNameSelect = useCallback(() => {
    resetField(phoneRegisterName);
    resetField(addressRegisterName);
    resetField(factoryRegisterName);
  }, [phoneRegisterName, addressRegisterName, factoryRegisterName]);

  const syncPhoneValue = useCallback(
    (option: SelectValue) => {
      if (!phoneOptions) return;
      const phoneOption = phoneOptions.find((item) => item.id === option.id);
      setValue(phoneRegisterName, phoneOption?.name);
    },
    [phoneOptions, phoneRegisterName]
  );
  const syncAddressValue = useCallback(
    (option: SelectValue) => {
      if (!addressOptions) return;
      const addressOption = addressOptions.find(
        (item) => item.id === option.id
      );
      setValue(addressRegisterName, addressOption?.name);
    },
    [addressOptions, addressRegisterName]
  );
  const syncFactoryValue = useCallback(
    (option: SelectValue) => {
      if (!factoryOptions) return;
      const factoryOption = factoryOptions.find(
        (item) => item.id === option.id
      );
      setValue(factoryRegisterName, factoryOption?.name);
    },
    [factoryOptions, factoryRegisterName]
  );

  const onPhoneSelect = useCallback(
    (option: SelectValue) => {
      syncAddressValue(option);
      syncFactoryValue(option);
    },
    [syncAddressValue, syncFactoryValue]
  );

  const onAddressSelect = useCallback(
    (option: SelectValue) => {
      syncPhoneValue(option);
      syncFactoryValue(option);
    },
    [syncPhoneValue, syncFactoryValue]
  );

  const onFactorySelect = useCallback(
    (option: SelectValue) => {
      syncPhoneValue(option);
      syncAddressValue(option);
    },
    [syncPhoneValue, syncAddressValue]
  );

  return (
    <div className={styles.container}>
      <Typography variant="h3" className={styles.title}>
        {title}
      </Typography>
      <InputWithDataList
        label="公司名稱"
        className={styles.name}
        registerName={companyRegisterName}
        options={companyOptions}
        onSelect={onNameSelect}
        required
      />
      <InputField label="聯絡人" registerName={contactRegisterName} required />
      <InputWithDataList
        label="聯絡電話"
        registerName={phoneRegisterName}
        required
        options={phoneOptions}
        onSelect={onPhoneSelect}
      />
      <InputWithDataList
        label="工廠"
        registerName={factoryRegisterName}
        options={factoryOptions}
        onSelect={onFactorySelect}
      />
      <InputWithDataList
        label="地址"
        className={styles.address}
        registerName={addressRegisterName}
        required
        options={addressOptions}
        onSelect={onAddressSelect}
      />
    </div>
  );
}
