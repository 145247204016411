import { InputField } from '@mezzanine-ui/react-hook-form';
import { useMiscMaterialGroups } from '@solar/data';
import { CustomDependentSelectors } from '../../MaterialEnquiry/Filters/CustomDependentSelectors';
import classes from './filter.module.scss';

export function Filter() {
  const { miscMaterialGroups } = useMiscMaterialGroups();

  return (
    <div className={classes.host}>
      <CustomDependentSelectors
        data={miscMaterialGroups}
        labelAndRegisterKeyArray={[
          { label: '群組：', registerName: 'groups' },
          {
            label: '大群組：',
            registerName: 'categories',
          },
          {
            label: '中類型：',
            registerName: 'subCategories',
          },
        ]}
        thisSelectorLevel={0}
      />
      <InputField registerName="materialId" placeholder="請輸入料號" />
      <InputField registerName="description" placeholder="請輸入品名" />
    </div>
  );
}
