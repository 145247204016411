import { useAutoAnimate } from '@formkit/auto-animate/react';
import { ChevronLeftIcon, ChevronUpIcon, TrashIcon } from '@mezzanine-ui/icons';
import {
  Button,
  Icon,
  IconButton,
  MenuDivider,
  Message,
  SelectValue,
  Typography,
} from '@mezzanine-ui/react';
import {
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  API_NAMESPACE,
  checkJWTRoles,
  MaterialShiftInputFields,
  OutsourcePurchaseMaterialRequestOrderResponse,
  request,
  useInventoryByMaterial,
  useMaterialRequestOrderLogs,
  useModalController,
  useOutsourcePurchaseMaterialRequestOrder,
  useOutSourcePurchaseOrder,
} from '@solar/data';
import {
  LocationSelectorGroupModal,
  LocationSelectorProvider,
  LOCATION_SELECTOR,
  ModalGroup,
  PageLayout,
  useLocationSelectorController,
  useModalGroupController,
} from '@solar/templates';
import { Descriptions, Table } from 'antd';
import { Decimal } from 'decimal.js';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { WarehouseAndVehicleFilter } from '../../Material/IncomingInspectOperationPage/Modals/WarehouseAndVehicleFilter';
import { BarcodeScanInput } from '../../Material/MaterialShift/BarcodeScanInput';
import { useLogsColumns } from './hooks/use-logs-columns';
import classes from './material-supply-to-work-order-page.module.scss';
import { MaterialSupplyByLoaderModalData } from './MaterialSupplyByLoaderModal';
import { MaterialSupplyByMaterialModalData } from './MaterialSupplyByMaterialModal';

type TmpShip = {
  id: string;
  specId: number;
  materialId: string;
  materialDescription: string;
  batchId: string;
  loaderId: number;
  stackId: string;
  availableMaterialStockUnitQuantity: string;
};

type RequestTable = Partial<
  OutsourcePurchaseMaterialRequestOrderResponse['items'][number]
> & {
  isChild?: true;
  isEnabled?: true;
  materialBarcode?: string;
  availableMaterialStockUnitQuantity?: string | undefined;
  stockOverInputUnitRatio?: string | undefined;
};

const namespace = API_NAMESPACE.MATERIALS;

export function MaterialSupplyToOutsourcingOrderPage() {
  const [dispatching, setDispatching] = useState(false);

  const navigate = useNavigate();
  const logsColumns = useLogsColumns();

  const [searchParams] = useSearchParams();
  /** @description 採購單號⭣ */
  const outsourcePurchaseOrderId = searchParams.get('outsourcePurchaseOrderId');
  // upper description table ⭣
  const { orders: upperDescriptionTableSource } = useOutSourcePurchaseOrder({
    orderId: outsourcePurchaseOrderId ?? undefined,
  });

  /** @description 發料單號⭣ */
  const params = useParams<{ outsourcingOrderId: string }>();
  // outsourcingOrderId 就是外層(上頁)清單 的 record(row) id,
  // for middle table: 投料需求 ⭣
  const { data: requestTableSource } = useOutsourcePurchaseMaterialRequestOrder(
    {
      orderId: params?.outsourcingOrderId,
    }
  );

  const requestOrderSpecsMethods = useForm<{
    recipient: SelectValue;
    receiverDept: SelectValue;
    requestTable: RequestTable[];
    tempRequestTable: (RequestTable & {
      availableMaterialInputUnitQuantity: string;
      tempQuantity: string;
      tempUnit: SelectValue;
    })[];

    insertChildIndex: number;
  }>({
    defaultValues: {
      requestTable: [],
    },
  });

  const {
    fields: tempRequestTable,
    insert: insertByTempRequestTable,
    remove: removeByTempRequestTable,
  } = useFieldArray({
    control: requestOrderSpecsMethods.control,
    name: 'tempRequestTable',
  });

  const { fields: requestTable, insert: insertByRequestTable } = useFieldArray({
    control: requestOrderSpecsMethods.control,
    name: 'requestTable',
  });

  const tableRef = useRef<HTMLTableElement>(null);

  const debounceInputHandler = useRef(
    debounce(
      ({ e, index }: { e: ChangeEvent<HTMLInputElement>; index: number }) => {
        const value = new Decimal(Number(e.target.value));

        if (!value.isNaN() && !value.isFinite()) {
          return;
        }

        if (
          !requestOrderSpecsMethods.getValues(`requestTable.${index}.isChild`)
        ) {
          return;
        }

        let upperParentIndex = index - 1;
        let upperTotal = new Decimal(0);

        while (
          requestOrderSpecsMethods.getValues(
            `requestTable.${upperParentIndex}.isChild`
          )
        ) {
          upperTotal = new Decimal(
            requestOrderSpecsMethods.getValues(
              `requestTable.${upperParentIndex}.availableMaterialStockUnitQuantity`
            ) ?? '0'
          )
            .times(
              Number(
                requestOrderSpecsMethods.getValues(
                  `requestTable.${upperParentIndex}.stockOverInputUnitRatio`
                ) ?? '0'
              )
            )
            .plus(upperTotal);

          upperParentIndex -= 1;
        }

        let lowerParentIndex = index + 1;
        let lowerTotal = new Decimal(0);

        while (
          requestOrderSpecsMethods.getValues(
            `requestTable.${lowerParentIndex}.isChild`
          )
        ) {
          lowerTotal = new Decimal(
            requestOrderSpecsMethods.getValues(
              `requestTable.${lowerParentIndex}.availableMaterialStockUnitQuantity`
            ) ?? '0'
          )
            .times(
              Number(
                requestOrderSpecsMethods.getValues(
                  `requestTable.${lowerParentIndex}.stockOverInputUnitRatio`
                ) ?? '0'
              )
            )
            .plus(lowerTotal);

          lowerParentIndex += 1;
        }

        requestOrderSpecsMethods.setValue(
          `requestTable.${upperParentIndex}.availableMaterialStockUnitQuantity`,
          String(upperTotal.plus(lowerTotal).plus(value))
        );
      },
      600
    )
  ).current;

  const { fetchInventoryByMaterial } = useInventoryByMaterial({
    defaultType: MaterialShiftInputFields.MATERIAL_BARCODE,
  });

  const controller = useLocationSelectorController({
    onSelectedIdsChange(selectedIds) {
      const shelfId =
        selectedIds?.shelfId ?? selectedIds?.stackId ?? selectedIds?.zoneId;
      if (shelfId) {
        // mutateLoaderParams({
        //   shelfIds: [shelfId],
        //   whenNotFoundAutoCreate: true,
        // });
      }
    },
  });

  const svgMapController = useLocationSelectorController({
    resetAfterClosing: false,
  });
  const locationSelectorController = useLocationSelectorController({
    onSelectedIdsChange(selectedIds) {
      const shelfId =
        selectedIds?.shelfId ?? selectedIds?.stackId ?? selectedIds?.zoneId;
      if (shelfId) {
        // mutateLoaderParams({
        //   shelfIds: [shelfId],
        //   whenNotFoundAutoCreate: true,
        // });
      }
    },
  });

  const warehouseAndVehicleFilterRef =
    useRef<React.ElementRef<typeof WarehouseAndVehicleFilter>>(null);

  // const { workOrder: data } = useWorkOrderInfo(
  //   params?.outsourcingOrderId ?? ''
  // );
  const { logs, isLoading: logsLoading } = useMaterialRequestOrderLogs(
    params?.outsourcingOrderId ?? ''
  );

  const [tmpShipList, setTmpShipList] = useState<TmpShip[]>([]);

  const materialSupplyByMaterialModalController =
    useModalController<MaterialSupplyByMaterialModalData>();
  const materialSupplyByLoaderModalController =
    useModalController<MaterialSupplyByLoaderModalData>();
  const modalGroupController = useModalGroupController([
    { name: LOCATION_SELECTOR },
  ]);

  useEffect(() => {
    // 投料需求 data source 綁定 form
    if (requestTableSource && requestTableSource?.items) {
      requestOrderSpecsMethods.setValue(
        'requestTable',
        requestTableSource?.items
      );
    }
  }, [requestTableSource]);

  useEffect(() => {
    return () => {
      debounceInputHandler.cancel();
    };
  }, [debounceInputHandler]);

  return (
    <>
      <div>
        <Button
          type="button"
          onClick={() => {
            if (window.history.state && window.history.state.key) {
              navigate(-1);
            } else {
              navigate('/', { replace: true });
            }
          }}
          prefix={<Icon style={{ color: '#8F8F8F' }} icon={ChevronLeftIcon} />}
          variant="text"
        >
          <Typography color="text-secondary" variant="button2">
            返回上一頁
          </Typography>
        </Button>
      </div>
      <PageLayout title="">
        <Descriptions bordered column={4}>
          <Descriptions.Item label="發料單號" span={1}>
            {params?.outsourcingOrderId}
          </Descriptions.Item>
          <Descriptions.Item label="採購單號" span={1}>
            {upperDescriptionTableSource?.id}
          </Descriptions.Item>
          <Descriptions.Item label="建立者" span={2}>
            {upperDescriptionTableSource?.staffName}
          </Descriptions.Item>
          <Descriptions.Item label="建立時間" span={1}>
            {moment(upperDescriptionTableSource?.createdAt).format(
              'YYYY/MM/DD HH:mm'
            )}
          </Descriptions.Item>
          <Descriptions.Item label="供應商名稱" span={1}>
            {upperDescriptionTableSource?.supplierName}
          </Descriptions.Item>
          <Descriptions.Item label="供應商代碼" span={2}>
            {upperDescriptionTableSource?.supplierId}
          </Descriptions.Item>
          {(upperDescriptionTableSource?.items ?? []).map((row) => {
            return (
              <>
                <Descriptions.Item label="目標料號">
                  {row?.materialId}
                </Descriptions.Item>
                <Descriptions.Item label="目標品名">
                  {row?.materialDescription}
                </Descriptions.Item>
                <Descriptions.Item label="需求數量">
                  {row?.expectedMaterialStockUnitQuantity}
                </Descriptions.Item>
                <Descriptions.Item label="單位">
                  {row?.materialStockUnit}
                </Descriptions.Item>
              </>
            );
          })}
        </Descriptions>
        <Typography variant="h3">發料作業</Typography>
        <MenuDivider />
        <LocationSelectorProvider controller={controller}>
          {checkJWTRoles([85]) && (
            <FormFieldsWrapper
              methods={requestOrderSpecsMethods}
              onSubmit={async (values) => {
                const { recipient, receiverDept, requestTable } = values;

                setDispatching(true);

                try {
                  const { selectedIds } = controller;
                  const shelfId =
                    selectedIds?.shelfId ??
                    selectedIds?.stackId ??
                    selectedIds?.zoneId;

                  // if (!shelfId) {
                  //   warehouseAndVehicleFilterRef.current?.setLocationRequired(
                  //     true
                  //   );
                  //   return;
                  // } else {
                  //   warehouseAndVehicleFilterRef.current?.setLocationRequired(
                  //     false
                  //   );
                  // }

                  const res = await request(
                    `/warehouses/outsource-purchase-material-request-orders/issue/${params.outsourcingOrderId}`,
                    {
                      method: 'put',
                      body: JSON.stringify({
                        toShelfId: shelfId,
                        toLoaderId: null,
                        recipient: recipient?.id,
                        receiverDeptId: receiverDept?.id,
                        items: requestOrderSpecsMethods
                          .getValues('tempRequestTable')
                          .map((row) => {
                            return {
                              materialBarcode: row.materialBarcode,
                              materialStockUnitQuantity:
                                row.tempUnit.id === 'materialInputUnit'
                                  ? new Decimal(String(row.tempQuantity)).times(
                                      row.stockOverInputUnitRatio as string
                                    )
                                  : row.tempQuantity,
                              materialWeightUnitQuantity: null,
                            };
                          }),
                      }),
                      responseParser: (res) => res,
                      namespace,
                    }
                  );

                  if (res.ok) {
                    Message.success('發料成功');
                  }
                } catch (err) {
                  if (err instanceof Error) {
                    Message.error(JSON.parse(err.message).message);
                  } else {
                    Message.error('發料失敗');
                  }
                } finally {
                  setDispatching(false);
                }
              }}
            >
              <br />
              <WarehouseAndVehicleFilter
                locationLabel="發料目的地："
                openLocationSelectorModal={() =>
                  modalGroupController.openModal(LOCATION_SELECTOR, null)
                }
                ref={warehouseAndVehicleFilterRef}
              />
              <br />

              <div ref={tableRef}>
                <Table
                  components={{
                    body: { wrapper: Wrapper, row: Row },
                  }}
                  rowClassName={classes['table-row']}
                  rowKey="id"
                  columns={[
                    {
                      title: '品名',
                      dataIndex: 'material',
                      render: (_, source) => {
                        if (source?.isChild) {
                          return <Icon size={20} icon={ChevronUpIcon} />;
                        }

                        return source?.materialDescription ?? '';
                      },
                    },
                    {
                      title: '料號',
                      dataIndex: 'material',
                      render: (_, record) => {
                        return record?.materialId;
                      },
                    },

                    {
                      title: '批號',
                      // dataIndex: 'materialBatchId',
                      render: (_, record) =>
                        record['materialSapBatchId'] &&
                        record['materialSubBatchId']
                          ? `${record['materialSapBatchId']}-${record['materialSubBatchId']}`
                          : '',
                    },
                    {
                      title: '基礎計量單位數量',
                      dataIndex:
                        'availableMaterialStockUnitQuantity-expectedMaterialStockUnitQuantity',
                      render: (_, record) =>
                        record?.availableMaterialStockUnitQuantity ??
                        record?.expectedMaterialStockUnitQuantity,
                    },
                    {
                      title: '基礎計量單位',
                      dataIndex: 'materialStockUnit-materialStockUnit',
                      render: (_, record) => {
                        return record?.stockUnit;
                      },
                    },
                    {
                      title: '管理數量',
                      dataIndex: 'availableMaterialInputUnitQuantity',
                    },
                    {
                      title: '管理單位',
                      dataIndex: 'materialInputUnit',
                    },
                    {
                      title: '單位',
                      dataIndex: 'mainMaterialUnit',
                    },
                  ]}
                  dataSource={requestTable ?? []}
                  loading={!requestTableSource || dispatching}
                />
                <Typography variant="h4">投料需求</Typography>
                <br />
                <BarcodeScanInput
                  inputStyle={classes['barcode-scan-input']}
                  otherOnKeyDownAction={async (value) => {
                    if (!requestTableSource) {
                      Message.warning('投入需求資料尚未載入');
                      return;
                    }

                    if (dispatching) {
                      Message.warning('發料中！請勿執行查詢物料');
                      return;
                    }
                    if (value) {
                      const res = await fetchInventoryByMaterial({
                        materialBarcodes: [value],
                      });

                      let isMatchedBatchId = false;

                      if (res?.records?.length) {
                        let successMatchOneCondition = false;

                        for (let i = 0; i < requestTable.length; i++) {
                          let matchedDataIndex = -1;
                          const row = requestTable[i];

                          if (row.materialId === res?.records[0]?.materialId) {
                            matchedDataIndex = i;
                          } else {
                            continue;
                          }

                          isMatchedBatchId =
                            `${row.materialSapBatchId}-${row.materialSubBatchId}` ===
                            res?.records[0]?.batchId;

                          if (isMatchedBatchId) {
                            insertByTempRequestTable(0, {
                              ...res?.records[0],
                              materialBatchId:
                                res?.records[0]?.materialSubBatchId,
                              tempQuantity:
                                res?.records[0]
                                  ?.availableMaterialStockUnitQuantity,
                              tempUnit: {
                                id: 'materialStockUnit',
                                name: res?.records[0]?.materialStockUnit,
                              },
                            });
                            successMatchOneCondition = true;
                          } else {
                            continue;
                          }
                        }
                        if (!successMatchOneCondition) {
                          Message.info('沒有匹配的資料');
                        }
                      } else {
                        Message.info('沒有匹配的資料');
                      }
                    }
                  }}
                />
                <Table
                  columns={[
                    {
                      title: '移除',
                      dataIndex: 'recover',
                      render: (_, source, index) => {
                        return (
                          <IconButton
                            type="button"
                            onClick={() => {
                              removeByTempRequestTable(index);
                            }}
                          >
                            <Icon icon={TrashIcon} />
                          </IconButton>
                        );
                      },
                    },

                    {
                      title: '物料條碼',
                      dataIndex: 'materialBarcode',
                    },
                    {
                      title: '數量',
                      dataIndex: 'tempQuantity',
                      render: (_, source, index) => {
                        return (
                          <InputField
                            registerName={`tempRequestTable.${index}.tempQuantity`}
                          />
                        );
                      },
                    },
                    {
                      title: '單位',
                      dataIndex: 'tempUnit',
                      render: (_, source, index: number) => {
                        return (
                          <>
                            <SelectField
                              registerName={`tempRequestTable.${index}.tempUnit`}
                              options={[
                                {
                                  id: 'materialStockUnit',
                                  name: source?.materialStockUnit as string,
                                },
                                {
                                  id: 'materialInputUnit',
                                  name: source?.materialInputUnit as string,
                                },
                              ]}
                              onChange={(obj) => {
                                if (obj.id === 'materialStockUnit') {
                                  requestOrderSpecsMethods.setValue(
                                    `tempRequestTable.${index}.tempQuantity`,
                                    source?.availableMaterialStockUnitQuantity ||
                                      '0'
                                  );
                                } else if (obj.id === 'materialInputUnit') {
                                  requestOrderSpecsMethods.setValue(
                                    `tempRequestTable.${index}.tempQuantity`,
                                    source?.availableMaterialStockUnitQuantity ||
                                      '0'
                                  );
                                }
                              }}
                            />
                          </>
                        );
                      },
                    },
                  ]}
                  dataSource={tempRequestTable ?? []}
                />
              </div>

              <div>
                <Button
                  variant="outlined"
                  style={{ float: 'right' }}
                  loading={dispatching}
                >
                  發料
                </Button>
              </div>
            </FormFieldsWrapper>
          )}
        </LocationSelectorProvider>
        <MenuDivider />
        <Typography variant="h4">發料紀錄</Typography>

        <Table
          columns={logsColumns as any}
          dataSource={logs ?? []}
          loading={logsLoading}
        />
        <br />
        <ModalGroup {...modalGroupController}>
          <LocationSelectorGroupModal
            controller={svgMapController}
            onSubmit={(activeId, selectedIds) => {
              locationSelectorController.setSelectedIds(selectedIds ?? {});

              if (activeId) {
                // mutateLoaderParams({
                //   shelfIds: [activeId],
                //   whenNotFoundAutoCreate: true,
                // });
              }
            }}
          />
        </ModalGroup>
      </PageLayout>
    </>
  );
}

const Wrapper = (props: React.HTMLAttributes<HTMLTableSectionElement>) => {
  const [parent] = useAutoAnimate();
  return (
    <tbody ref={parent} {...props}>
      {props.children}
    </tbody>
  );
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = (props: RowProps) => {
  return <tr key={props['data-row-key']} {...props} />;
};
