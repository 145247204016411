import { TableColumn } from '@mezzanine-ui/core/table';
import { Button, Message, SelectValue } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  getNextParams,
  NextPagination,
  SORT_TYPE_SELECTIONS,
  WorkOrdersRequestParams,
  WORK_ORDERS_STATES,
  useOutsourcePurchaseMaterialRequestOrders,
  OUTSOURCE_PURCHASE_STATES_TRANSLATION,
  checkJWTRoles,
  request,
  API_NAMESPACE,
} from '@solar/data';
import {
  FilterScaffold,
  ModalGroup,
  MznPaginationTable,
  useModalGroupController,
} from '@solar/templates';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { DispatchOrderPanelFilter } from './DispatchOrderPanelFilter';
import { DispatchMaterialViewModal } from './modal/DispatchMaterialViewModal';
import { DispatchSettingModal } from './modal/DispatchSettingModal';
import {
  DispatchMaterialViewModalProps,
  DispatchOrderPanelTableProps,
  Modals,
} from './types';

type FormProps = Omit<
  WorkOrdersRequestParams,
  | 'createdAtDateFrom'
  | 'createdAtDateTo'
  | 'expectedCompletedAtDateFrom'
  | 'expectedCompletedAtDateTo'
  | 'factoryId'
  | 'orderTypeCode'
  | 'sortType'
  | 'states'
> & {
  createdDateRange: [string, string];
  actualCompletedDateRange: [string, string];
  expectedCompletedDateRange: [string, string];
  factory: SelectValue;
  orderType: SelectValue;
  sortType: SelectValue;
  sourceType: SelectValue[];
  states: SelectValue[];
};

export function DispatchOrderPanel() {
  const { orderId } = useParams<{ orderId: string }>();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const methods = useForm<FormProps>({
    defaultValues: {
      sortType: SORT_TYPE_SELECTIONS[0],
    },
  });

  const {
    orders,
    mutateParams,
    pageInfo,
    isLoading,
    mutate: mutateOutsourcePurchaseMaterialRequestOrders,
  } = useOutsourcePurchaseMaterialRequestOrders({
    initiateFetching: false,
  });

  const modalGroupController = useModalGroupController([
    { name: Modals.DISPATCH_MATERIAL_VIEW },
    { name: Modals.DISPATCH_SETTING },
  ]);

  const refetchProductionDeliveryOrders = useCallback(
    (nextPagination: NextPagination) => {
      const filterState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);

      mutateParams({
        offset,
        limit,
        workOrderIds: orderId ? [orderId] : undefined,
        searchTerm: filterState?.searchTerm,
        expectedCompletedAtDateFrom: (filterState.expectedCompletedDateRange ??
          [])[0],
        expectedCompletedAtDateTo: (filterState.expectedCompletedDateRange ??
          [])[1],
        actualCompletedAtDateFrom: (filterState.actualCompletedDateRange ??
          [])[0],
        actualCompletedAtDateTo: (filterState.actualCompletedDateRange ??
          [])[1],
        // sourceTypes: filterState?.sourceType?.map(
        //   (type) => type.id as `${MaterialRequestOrderSourceType}`
        // ),
        sortType: filterState?.sortType
          ?.id as WorkOrdersRequestParams['sortType'],
        states: filterState?.states?.map(
          (type) => type.id as keyof typeof WORK_ORDERS_STATES
        ),
      });
    },
    [methods]
  );

  useEffect(() => {
    // page data init
    refetchProductionDeliveryOrders({ nextPage: 1 });
  }, []);

  useEffect(() => {
    mutateOutsourcePurchaseMaterialRequestOrders();
  }, [tab]);

  const [revoking, setRevoking] = useState(false);

  const onRevoke = useCallback(async (orderId: string) => {
    try {
      setRevoking(true);

      const response = await request(
        `/warehouses/outsource-purchase-material-request-orders/${orderId}/revoke`,
        {
          method: 'PUT',
          namespace: API_NAMESPACE.MATERIALS,
          responseParser: (res) => res,
        }
      );

      if (response.ok) {
        Message.success('作廢成功');
        await mutateOutsourcePurchaseMaterialRequestOrders();
      }
    } catch (error: any) {
      console.error(error);
      Message.error(`作廢失敗：${JSON.parse(error?.message)?.message}`);
    } finally {
      setRevoking(false);
    }
  }, []);

  const columns: TableColumn<DispatchOrderPanelTableProps>[] = [
    {
      title: '操作',
      width: 200,
      render: (source) => {
        return (
          <div>
            <Button
              type="button"
              onClick={() => {
                modalGroupController.openModal(Modals.DISPATCH_MATERIAL_VIEW, {
                  orderId: source['id'],
                });
              }}
            >
              檢視
            </Button>
            {checkJWTRoles([133]) && (
              <Button
                type="button"
                loading={revoking}
                onClick={() => {
                  if (source?.id) {
                    onRevoke(source?.id);
                    // modalGroupController.openModal<DispatchMaterialViewModalProps>(
                    //   Modals.DISPATCH_SETTING,
                    //   {
                    //     dispatchPreviewTable: null,
                    //     materialId: null,
                    //     orderId: source?.id,
                    //   }
                    // );
                  }
                }}
              >
                作廢
              </Button>
            )}
          </div>
        );
      },
    },
    {
      title: '預期發料日期',
      width: 200,
      render: (source) =>
        source?.expectedCompletedAt
          ? moment(source?.expectedCompletedAt).format('YYYY-MM-DD')
          : '',
    },
    { title: '發料單號', dataIndex: 'id', width: 200 },
    {
      title: '狀態',
      width: 200,
      render: (source) =>
        OUTSOURCE_PURCHASE_STATES_TRANSLATION[source['shippedState']],
    },
    {
      title: '實際發料日期',
      width: 200,
      render: (source) =>
        source?.actualCompletedAt
          ? moment(source?.actualCompletedAt).format('YYYY-MM-DD')
          : '',
    },
    { title: '領用人', dataIndex: 'recipeName' },
  ];

  return (
    <>
      <FormFieldsWrapper methods={methods}>
        <FilterScaffold
          mutateParams={refetchProductionDeliveryOrders}
          fetchAsParamsMutated={false}
        >
          <DispatchOrderPanelFilter />
          <Button
            type="button"
            variant="outlined"
            onClick={() => refetchProductionDeliveryOrders({ nextPage: 1 })}
          >
            確認
          </Button>
        </FilterScaffold>
        <br />
      </FormFieldsWrapper>
      <MznPaginationTable
        loading={isLoading}
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={orders ?? []}
        fetchData={refetchProductionDeliveryOrders}
        pageInfo={pageInfo}
      />
      <ModalGroup {...modalGroupController}>
        <DispatchMaterialViewModal />
        <DispatchSettingModal />
      </ModalGroup>
    </>
  );
}
