import { MaterialUnit } from '../materials';
import { FormAnswer } from '../production-process-form';
import { ProductionAnswerDto } from './production-answer.dto';

export type InventoryItem = MaterialUnit & {
  // PK
  batchStoredLoaderRecordId: number;

  availableState: boolean;
  /** @deprecated */
  recordId: number;

  /**
   * @description
   * 一開始的批號 現在是用 `${sapBatch}-${subBatch}` 組起來的
   * 後來要拆批 故把原本的 batch 改成 subBatch 新增 sapBatch
   */
  batchId: string;
  materialSapBatchId: string;
  materialSubBatchId: string;

  materialId: string;
  oldMaterialId: string;
  materialBarcode: string;
  materialSubBatchRemark: string | null;
  materialSapBatchRemark: string | null;
  materialDescription: string;

  loaderId: string;
  shelfId: string;
  shelfName: string;
  stackId: string;
  stackName: string;
  drawingCode: string;
  componentCode: string;
  staffName: string;
  createdAt: string;

  materialWeightUnitQuantity: string;

  /** @deprecated */
  loaderName: string;
  /** @deprecated */
  loaderBarcode: string;
  /** @deprecated 原本是sap有然後我覺得外圍會去處理但感覺目前不會去理他 所以這次沒有這個了 - Luca */
  lockedMainMaterialUnitQuantity: string;
  /** @deprecated 原本是sap有然後我覺得外圍會去處理但感覺目前不會去理他 所以這次沒有這個了 - Luca */
  inTransitMainMaterialUnitQuantity: string;

  /** @description 可用基礎計量單位數量 */
  availableMaterialStockUnitQuantity: string;
  /** @deprecated */
  mainMaterialUnitQuantity: number;

  /** @description 待檢基礎計量單位數量 */
  inspectionMaterialStockUnitQuantity: string;
  /** @deprecated */
  inspectionMainMaterialUnitQuantity: string;

  /** @description 實際重量單位數量 */
  actualMaterialWeightUnitQuantity: string;

  /** @description 可用管理單位數量 */
  availableMaterialInputUnitQuantity: string;
  /** @deprecated */
  secondaryMaterialUnitQuantity: number;

  /** @description 待檢管理單位數量 */
  inspectionMaterialInputUnitQuantity: string;

  salesOrderId: string | null;
  salesOrderLine: string | null;

  quantity: string;
  stockOverInputUnitRatio: string;

  deliveryOrderRecycleNumber: string | null;
  deliveryOrderWaiverNumber: string | null;

  inventoryOrderRecycleNumber: string | null;
  inventoryOrderWaiverNumber: string | null;

  elementRatioAnswers: FormAnswer[];
  componentItemNumber: string;
  wtRate: string;
};

export type InventoryPurchaseOrderItem = MaterialUnit & {
  id: string;
  specId: string;

  materialId: string;
  oldMaterialId: string;
  materialDescription: string;

  /** @description 預計購買基礎計量單位數量 */
  expectedMaterialStockUnitQuantity: string;
  /** @description 已收基礎計量單位數量 */
  receivedMaterialStockUnitQuantity: string;
  /** @description 退回基礎計量單位數量 */
  returnedMaterialStockUnitQuantity: string;
  /** @deprecated */
  expectedMainMaterialUnitQuantity: string;
  /** @deprecated */
  receivedMainMaterialUnitQuantity: string;
  /** @deprecated */
  returnedMainMaterialUnitQuantity: string;

  /** @description 預計購買管理單位數量 */
  expectedMaterialInputUnitQuantity: string;
  /** @description 已收管理單位數量 */
  receivedMaterialInputUnitQuantity: string;
  /** @description 退回管理單位數量 */
  returnedMaterialInputUnitQuantity: string;
  /** @deprecated */
  expectedSecondaryMaterialUnitQuantity: string;
  /** @deprecated */
  receivedSecondaryMaterialUnitQuantity: string;
  /** @deprecated */
  returnedSecondaryMaterialUnitQuantity: string;

  elementRatioAnswers: ProductionAnswerDto[];
};

export type MiscMaterial = {
  id: string;
  description: string;
  unit: string;
  eraseOnPick: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  groupId: string;
  categoryId: string;
  subCategoryId: string;
  subCategory: {
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    categoryId: string;
    groupId: string;
    category: {
      id: string;
      name: string;
      createdAt: string;
      updatedAt: string;
      deletedAt: string | null;
      groupId: string;
      group: {
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
        deletedAt: string | null;
      };
    };
  };
};

export type MiscMaterialBatch = {
  id: string;
  description: string;
  eraseOnPick: boolean;
  unit: string;
  subCategoryId: string;
  categoryId: string;
  batchId: string;
  quantity: string;
  batchShelfId: string;
  subCategoryName: string;
  categoryName: string;
  groupName: string;
};
