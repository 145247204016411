import {
  DateRangePickerField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { CREATE_ORDER, STATUS } from './const';
import classes from './filter.module.scss';

export function Filter() {
  return (
    <div className={classes.host}>
      <SelectField
        label="狀態："
        mode="multiple"
        clearable
        registerName="status"
        options={Object.entries(STATUS).map(([key, zh]) => {
          return {
            id: key,
            name: zh,
          };
        })}
      />
      <DateRangePickerField
        fullWidth
        label="日期區間："
        registerName="dateRange"
      />
      <SelectField
        label="建立日期："
        clearable
        defaultValue={{
          id: Object.keys(CREATE_ORDER)[0],
          name: CREATE_ORDER.createdAtAsce,
        }}
        registerName="createdAt"
        options={Object.entries(CREATE_ORDER).map(([key, zh]) => ({
          id: key,
          name: zh,
        }))}
      />
    </div>
  );
}
