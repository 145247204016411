import type { CustomerTradeConditionExportFormValues } from './useCustomerTradeConditionExport';
import type { UseFormReturn } from 'react-hook-form';
import React from 'react';
import isEqual from 'lodash/isEqual';

export function useCustomerTradeConditionFilter() {
  const [formMethods, bindFormMethodsForInitialization] =
    React.useState<UseFormReturn<CustomerTradeConditionExportFormValues> | null>(
      null
    );
  const [filterCondition, setFilterCondition] =
    React.useState<CustomerTradeConditionExportFormValues>({});

  function updateFilterCondition(callback?: {
    onFilterConditionNoChange?: () => void;
    onUpdateSuccess?: () => void;
  }) {
    if (formMethods) {
      if (isEqual(filterCondition, formMethods.getValues())) {
        callback?.onFilterConditionNoChange?.();
      } else {
        setFilterCondition(formMethods.getValues());
        callback?.onUpdateSuccess?.();
      }
    }
  }

  return {
    filterCondition,
    updateFilterCondition,
    bindFormMethodsForInitialization,
  };
}
