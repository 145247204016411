import useSWR from 'swr';
import { API_NAMESPACE, request } from '../request';

const namespace = API_NAMESPACE.MATERIALS;

export async function updateOutcourcePurchaseOrderReceipt(body: any) {
  const response: Promise<Response> = await request(
    '/warehouses/outsource-purchase-orders/receipt',
    {
      method: 'put',
      body: JSON.stringify(body),
      responseParser: (res) => res,
      namespace,
    }
  );

  return response;
}

export function useOutsourcePurchaseOrder({
  orderId,
}: {
  orderId?: string | undefined;
}) {
  const { data, error, mutate } = useSWR(
    orderId
      ? [`/warehouses/outsource-purchase-orders/v2/${orderId}`, { namespace }]
      : null
  );

  return {
    order: data,
    isLoading: !data && !error,
    mutate,
  };
}
