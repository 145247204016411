import { useCallback, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PaginationTable } from '../PaginationTable/PaginationTable';

import { TableColumn, TableExpandable } from '@mezzanine-ui/core/table';
import { ChevronDownIcon } from '@mezzanine-ui/icons';
import { Button, Icon, Message, SelectValue, cx } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  MiscPurchaseOrderOuterRecord,
  NextPagination,
  getNextParams,
  useFixedAssetPurchaseOrders,
} from '@solar/data';
import { FilterScaffold, mznTableRowExpandHelper } from '@solar/templates';
import { Col, Row } from 'antd';
import moment from 'moment';
import {
  InspectStatus,
  InspectStatusTag,
} from '../IncomingInspectOperationPage/InspectStatusTag';
import { PageLayout } from '../PageLayout/PageLayout';
import { Filter } from './Filter';
import classes from './miscellaneous-storage-in.module.scss';
import { syncFixedAssetPurchaseOrder } from './utils';

export function FixedAssetStorageInPage() {
  const filterMethods = useForm();
  const tableRef = useRef<HTMLTableElement>(null);
  const navigate = useNavigate();
  const [syncLoading, setSyncLoading] = useState(false);

  // table data
  const {
    orders,
    pageInfo,
    isLoading,
    mutateParams,
    mutateGetFixedAssetPurchaseOrders,
  } = useFixedAssetPurchaseOrders({
    defaultParams: {},
  });

  const refetchFixedAssetPurchaseOrders = useCallback(
    (nextPagination: NextPagination) => {
      const filterState = filterMethods?.getValues();

      const { offset, limit } = getNextParams(nextPagination, pageInfo);

      mutateParams({
        offset,
        limit,
        searchTerm: filterState?.searchTerm,
        dateFrom: (filterState?.dateRange ?? [])[0],
        dateTo: (filterState?.dateRange ?? [])[1],
        receivedStates: filterState?.status?.map(
          (status: SelectValue) => status?.id
        ),
        supplierIds: (filterState?.suppliers ?? []).map(
          (supplier: SelectValue) => supplier.id
        ),
        sortType: filterState?.sortType?.id ?? null,
      });
    },
    [filterMethods, mutateParams, pageInfo]
  );

  const columns: TableColumn<MiscPurchaseOrderOuterRecord>[] = useMemo(
    () => [
      {
        title: '操作',
        width: 120,
        align: 'start' as const,
        render(source) {
          return (
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                navigate(`fixed-asset/${source.id}`);
              }}
            >
              檢視
            </Button>
          );
        },
      },
      {
        title: '採購單號',
        render: (source, index) => {
          return (
            <>
              <Button
                type="button"
                onClick={() => {
                  mznTableRowExpandHelper({ ref: tableRef, index });
                }}
                prefix={<Icon icon={ChevronDownIcon} />}
              />
              <span>{source.id ?? ''}</span>
            </>
          );
        },
      },
      {
        title: '應收日',
        render: (source) =>
          source['expectedCompletedAt']
            ? moment(source['expectedCompletedAt']).format('YYYY-MM-DD')
            : '',
      },
      { title: '供應商名稱', dataIndex: 'supplierName' },
      { title: '供應商代碼', dataIndex: 'supplierId' },
      { title: '採購人員', dataIndex: 'staffName' },
      {
        title: '狀態',
        render: (source) => {
          return (
            <InspectStatusTag status={source.receivedState as InspectStatus} />
          );
        },
      },
    ],
    [navigate]
  );

  const expandable = useMemo<TableExpandable<MiscPurchaseOrderOuterRecord>>(
    () => ({
      rowExpandable: (record) => !!(record.items ?? []).length,
      expandedRowRender: (record) => {
        return {
          dataSource: record.items,
          columns: [
            { dataIndex: 'null-1' },
            { dataIndex: 'materialDescription' },
            { dataIndex: 'materialId' },
            { dataIndex: 'expectedMainMaterialUnitQuantity' },
            { dataIndex: 'null-2' },
            { dataIndex: 'null-3' },
            { dataIndex: 'null-4' },
          ],
        };
      },
    }),
    []
  );

  return (
    <PageLayout>
      <FormFieldsWrapper methods={filterMethods}>
        <FilterScaffold
          mutateParams={refetchFixedAssetPurchaseOrders}
          fetchAsParamsMutated={false}
        >
          <Filter />
        </FilterScaffold>
      </FormFieldsWrapper>
      <Row gutter={6}>
        <Col span={3}>
          <Button
            variant="outlined"
            type="button"
            onClick={() => refetchFixedAssetPurchaseOrders({ nextPage: 1 })}
          >
            搜尋
          </Button>
        </Col>
        <Col span={3}>
          <Button
            variant="contained"
            loading={syncLoading}
            type="button"
            onClick={async () => {
              try {
                setSyncLoading(true);
                const res = await syncFixedAssetPurchaseOrder();

                if (res.ok) {
                  Message.success('同步成功');

                  await mutateGetFixedAssetPurchaseOrders();
                }
              } catch (err) {
                if (err instanceof Error) {
                  Message.error(JSON.parse(err.message).massage);
                } else {
                  Message.error('同步失敗');
                }
              } finally {
                setSyncLoading(false);
              }
            }}
          >
            重新整理
          </Button>
        </Col>
      </Row>
      <div ref={tableRef}>
        <PaginationTable
          loading={isLoading}
          columns={columns}
          dataSource={orders ?? []}
          pageInfo={pageInfo}
          expandable={expandable}
          mutateParams={refetchFixedAssetPurchaseOrders}
          bodyClassName={cx(
            classes['table-body'],
            classes['custom-row-expand']
          )}
        />
      </div>
    </PageLayout>
  );
}
