import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { CommonTableFilterParam } from '../common-table-filter-param';
import { OffsetBased } from '../pagination';
import { API_NAMESPACE, request } from '../request';

export type InventoryPurchaseOrderParams = {
  supplierIds: string[];
  receivedStates: string[];
} & CommonTableFilterParam &
  OffsetBased;

const namespace = API_NAMESPACE.MATERIALS;

export function useInventoryByPurchaseOrders(
  defaultParams?: Partial<InventoryPurchaseOrderParams>
) {
  const [params, setParams] = useState<Partial<InventoryPurchaseOrderParams>>(
    defaultParams ?? {}
  );
  const { data, error, mutate } = useSWR(
    params
      ? ['/warehouses/inventory-purchase-orders', { params, namespace }]
      : null
  );

  const pageInfo = useMemo(() => {
    const page =
      (data?.pageInfo?.offset ?? 0) / (data?.pageInfo?.limit ?? 20) + 1;
    const pageSize = data?.pageInfo?.limit ?? 20;
    return { ...(data?.pageInfo ?? {}), page, pageSize };
  }, [data]);

  return {
    orders: data?.records || [],
    pageInfo,
    isLoading: !error && !data,
    setParams,
    mutateParams: mutate,
    refetchInventoryByPurchaseOrders: setParams,
    mutateInventoryByPurchaseOrders: mutate,
  };
}

export function syncPurchaseOrdersFromSAP() {
  return request('/sync/purchase-orders', {
    method: 'get',
    namespace,
    responseParser: (res) => res,
  });
}
