import { useCallback, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { fetchUrl } from '../../humanResources';
import { API_NAMESPACE } from '../../request';
import { FilterDataType } from '../mainFile';

type OrderMarksFilters = Record<
  'customers' | 'customerMaterials',
  FilterDataType[]
>;

const namespace = API_NAMESPACE.HR;

export const sdOpConvertCustomers = (
  customers?: FilterDataType[]
): FilterDataType[] => {
  if (!customers) return [];
  const final = customers.map((customer) => {
    const result = {
      id: customer.id,
      name: `${customer.name} (${customer.id})`,
    };
    return result;
  });
  return final;
};

export function useOrderMarksFilters() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<OrderMarksFilters | null>(null);

  const { data: swrData } = useSWR(['/orderMarks/filter', { namespace }]);

  const isOrderMarksFilters = useCallback(
    (object: unknown): object is OrderMarksFilters => {
      if (object && typeof object === 'object') return 'customers' in object;
      return false;
    },
    []
  );
  const customers = useMemo(
    () => sdOpConvertCustomers(swrData?.customers),
    [swrData]
  ) as FilterDataType[];

  useEffect(() => {
    if (!isOrderMarksFilters(swrData)) {
      setLoading(true);
      return;
    }

    setData(swrData);
    setLoading(false);
  }, [swrData]);

  return {
    loading,
    data,
    customers,
    isOrderMarksFilters,
  };
}
