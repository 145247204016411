import { Table, Typography } from "@mezzanine-ui/react";

export const columns = [{
  title: '料號',
  dataIndex: 'materialId',
}, {
  title: '批號',
  dataIndex: 'materialBatchId',
}, {
  title: '儲位',
  dataIndex: 'stack',
}, {
  width: 100,
  title: '數量',
  dataIndex: 'amount',
}];

type SelectedMaterialBatchListProps = {
  dataSource: any[],
};

export function SelectedMaterialBatchList({ dataSource }: SelectedMaterialBatchListProps) {
  const height = dataSource?.length >= 5 ? 350 : (dataSource?.length + 1) * 52;
  return (
    <div style={{ height }}>
      <Typography variant="h6" color="secondary">已選取批號</Typography>
      <Table columns={columns}  dataSource={dataSource} />
    </div>
  )
}