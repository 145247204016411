import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { APIResponse } from './types';
import { useMemo } from 'react';

const namespace = API_NAMESPACE.DRAWINGS;
export function useGetDrawingFilePaths(location?: string) {
  const params = useMemo(() => ({ location }), [location]);
  const { data, error } = useSWR<
    APIResponse<
      {
        id: string;
        location: string;
        s3FilePath: string;
      }[]
    >
  >(location ? ['/drawings/drawingFilePaths', { params, namespace }] : null);

  return {
    filePaths: data?.data ?? [],
    isLoading: !data && !error,
    error,
  };
}
