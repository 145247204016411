import { Message, ModalActions } from '@mezzanine-ui/react';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import {
  MiscMaterialBatch,
  NextPagination,
  PaginatedResponse,
} from '@solar/data';
import {
  LOCATION_SELECTOR,
  RowSection,
  useLocationSelectorController,
  useTargetModal,
} from '@solar/templates';
import { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { KeyedMutator } from 'swr';
import { WarehouseAndVehicleFilter } from '../../IncomingInspectOperationPage/Modals/WarehouseAndVehicleFilter';
import { ModalLayout } from '../../ModalLayout/ModalLayout';
import { Modals } from '../types';
import { postMiscErase, postMiscMove } from '../utils';

type Form = {
  quantity: string;
};

type Props = {
  locationSelectorController: ReturnType<typeof useLocationSelectorController>;
  refetch: KeyedMutator<PaginatedResponse<MiscMaterialBatch>>;
};

const THIS_FORM = Modals.DISPATCH;

export const Dispatch = ({ locationSelectorController, refetch }: Props) => {
  const methods = useForm<Form>();
  const modalController = useTargetModal<MiscMaterialBatch>(Modals.DISPATCH);
  const locationSelectorModalController = useTargetModal(LOCATION_SELECTOR);

  const closeModal = useCallback(() => {
    modalController.closeModal();
    methods.reset();
  }, [methods, modalController]);

  const { data: passedModalData } = modalController;
  const warehouseAndVehicleFilterRef =
    useRef<React.ElementRef<typeof WarehouseAndVehicleFilter>>(null);

  if (!passedModalData) return null;
  return (
    <ModalLayout
      modalHeader={<br />}
      loading={methods.formState.isSubmitting}
      {...modalController}
      closeModal={closeModal}
      modalFooter={
        <ModalActions
          confirmText="確認"
          cancelText="取消"
          confirmButtonProps={{ form: THIS_FORM }}
          onCancel={closeModal}
        />
      }
    >
      <FormFieldsWrapper
        id={THIS_FORM}
        methods={methods}
        onSubmit={async ({ quantity }) => {
          try {
            const { selectedIds } = locationSelectorController;
            const shelfId =
              selectedIds?.shelfId ??
              selectedIds?.stackId ??
              selectedIds?.zoneId;

            if (!shelfId) {
              warehouseAndVehicleFilterRef.current?.setLocationRequired(true);
              return;
            } else {
              warehouseAndVehicleFilterRef.current?.setLocationRequired(false);
            }

            const res = await (passedModalData.eraseOnPick
              ? postMiscErase
              : postMiscMove)({
              miscMaterialId: passedModalData.id,
              payload: {
                quantity: null,
                batchUsage: [{ batchId: passedModalData.batchId, quantity }],
                targetShelfId: shelfId,
              },
            });

            if (res?.ok) {
              refetch();
              closeModal();
              Message.success('成功');
            }
          } catch (err) {
            if (err instanceof Error) {
              Message.error(JSON.parse(err.message).message);
            } else {
              Message.error('系統錯誤');
            }
          }
        }}
      >
        <RowSection label="料號：">{passedModalData.id}</RowSection>
        <RowSection label="品名：">{passedModalData.description}</RowSection>
        <RowSection label="批號：">{passedModalData.batchId}</RowSection>
        <WarehouseAndVehicleFilter
          locationLabel="儲位："
          openLocationSelectorModal={() =>
            locationSelectorModalController.openModal(null)
          }
          ref={warehouseAndVehicleFilterRef}
        />
        <RowSection label="數量：">
          <InputField width={300} registerName="quantity" />
        </RowSection>
        <RowSection label="領用人：">
          <InputField width={300} registerName="user" />
        </RowSection>
      </FormFieldsWrapper>
    </ModalLayout>
  );
};
