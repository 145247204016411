import { useForm } from "react-hook-form";
import { PageTemplate } from "../../components/PageTemplate/PageTemplate";
import { ReturnMaterialFilter } from "./ReturnMaterialFilter";
import { ReturnMaterialTable } from "./ReturnMaterialTable";
import { useReturnMaterials } from "@solar/data";

export function ReturnMaterialPage() {
  const methods = useForm();
  const { returnMaterials } = useReturnMaterials();
  return (
    <PageTemplate title="倉庫退料">
      <ReturnMaterialFilter methods={methods} />
      <ReturnMaterialTable dataSource={returnMaterials} />
    </PageTemplate>
  )
}