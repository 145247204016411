import { useState } from 'react';
import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { OffsetBased, PageInfo } from '../pagination';
import { InventoryItem } from './inventory.type';
import { FetchingInventoryStockTypes } from '../materials';

const namespace = API_NAMESPACE.MATERIALS;

type GetInventoryByWarehouseParams = OffsetBased & {
  locationIds?: string[];
  withElementRatio?: boolean;
  stockTypes?: FetchingInventoryStockTypes[];
  shouldFetch?: boolean;
};

export function useGetInventoryByWarehouse(
  defaultParams?: GetInventoryByWarehouseParams
) {
  const [params, setParams] = useState<GetInventoryByWarehouseParams>(
    defaultParams ?? {
      withElementRatio: true,
    }
  );

  const { data, error, isValidating } = useSWR<{
    records: InventoryItem[];
    pageInfo: PageInfo;
  }>(
    params && params.shouldFetch
      ? [
          '/warehouses/inventory-by-warehouse',
          {
            params,
            namespace,
          },
        ]
      : null
  );

  // const mutateGetInventoryByWarehouse = (
  //   nextParams: GetInventoryByWarehouseParams
  // ) => {
  //   if (nextParams?.locationIds?.[0] !== params?.locationId) setParams(nextParams);
  // };

  return {
    inventoryByWarehouse: data?.records || [],
    pageInfo: data?.pageInfo,
    isLoading: isValidating,
    mutateGetInventoryByWarehouse: setParams,
  };
}
