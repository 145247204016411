import useSWR from 'swr';
import { API_NAMESPACE } from '../request';

export type ProductionDeliveryOrderTypes = {
  id: string;
  name: string;
  description: string;
};
const namespace = API_NAMESPACE.MATERIALS;
export const useProductionDeliveryOrderTypes = () => {
  const { data, error } = useSWR<ProductionDeliveryOrderTypes[]>([
    '/warehouses/production-delivery-orders/types',
    { namespace },
  ]);

  return {
    data,
    error,
  };
};
