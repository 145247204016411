import {
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
} from '@mezzanine-ui/react';
import styles from './../../../TargetNumber.module.scss';
import { disableTargetCodeRule } from '@solar/data';

export function TargetRuleMgmtDeleteModal({
  onClose,
  open,
  id,
  refetch,
}: {
  onClose: () => void;
  open: boolean;
  id: string | null;
  refetch: () => void;
}) {

  const handleConfirmDisable = async () => {
    if (id) {
      await disableTargetCodeRule({
        id: id,
        body: { is_disabled: true },
      });
      onClose();
      refetch();
    }
  };

  return (
    <Modal
      size="medium"
      hideCloseIcon
      className={styles.modal}
      onClose={onClose}
      open={open}
      severity="warning"
    >
      <ModalHeader className={styles.modalTitle} showSeverityIcon>
        停用靶編規則
      </ModalHeader>
      <ModalBody className={styles.modalContent}>
        確定停用此靶編規則？
      </ModalBody>
      <ModalActions
        cancelText="取消"
        confirmText="確定"
        onCancel={onClose}
        onConfirm={handleConfirmDisable}
      ></ModalActions>
    </Modal>
  );
}
