import { request, API_NAMESPACE } from '../../request';
import useSWR from 'swr';

export type PPOrdersFilterBU = {
  id: string;
  name: string;
};

export type PPOrdersFilterMaterialCategories = {
  name: string;
  id?: string;
  children?: PPOrdersFilterMaterialCategories[];
};

export type PPOrdersFilterConditionPayload = {
  bu: PPOrdersFilterBU[];
  materialCategories: PPOrdersFilterMaterialCategories[];
};

export function usePPOrdersFilterConditionGet() {
  const { data, mutate, isValidating } = useSWR<PPOrdersFilterConditionPayload>(
    validateSWRKey(),
    request,
    {
      revalidateOnFocus: false,
    }
  );

  const { bu, materialCategories } = data ?? {};

  return {
    buList: bu ?? [],
    materialCategories: materialCategories ?? [],
    isLoading: isValidating,
    refetchFilterCondition: mutate,
  };
}

function validateSWRKey() {
  return [
    '/production-planning/get/orders/filter',
    { namespace: API_NAMESPACE.HR },
  ];
}
