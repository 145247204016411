import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';
import {
  BooleanStrings,
  YesNoOptions,
} from 'apps/frontend/src/app/Transportation/RequirementsManagementPage/RequirementsManagement.config';
import { useFormContext } from 'react-hook-form';

export function SealingTypeSelector({ className }: { className?: string }) {
  const { watch } = useFormContext();
  const isRecycle = watch('isRecycle')?.id === BooleanStrings.True;

  return (
    <AutoCompleteField
      label="是否密封"
      required={isRecycle}
      disabled={!isRecycle}
      className={className}
      registerName="isSealed"
      options={YesNoOptions}
    />
  );
}
