import { Popper } from "@mezzanine-ui/react";
import { useState } from "react";
import { LocationSVGViewer } from "@solar/templates";
import { UseLocationSelectorControllerReturn } from "../LocationSelectorContext/useLocationSelectorContext";

type UseLocationSVGPopperProps = {
  handleNextLevel: UseLocationSelectorControllerReturn['handleNextLevel'];
};

export function useLocationSVGPopper({ handleNextLevel }: UseLocationSVGPopperProps) {
  const [anchorRef, setAnchorRef] = useState<HTMLElement | null>(null);

  const onMouseEnter = (id: string) => (event: MouseEvent) => {
    handleNextLevel(id);
    setAnchorRef(event?.currentTarget as HTMLElement);
  }

  const onMouseLeave = () => setAnchorRef(null);

  return {
    anchorRef,
    onMouseEnter,
    onMouseLeave,
  };
}

type LocationSVGPopperProps = {
  anchorRef: HTMLElement | null;
};

export function LocationSVGPopper({ anchorRef }: LocationSVGPopperProps) {
  return (
    <Popper
      anchor={anchorRef}
      open={Boolean(anchorRef)}
      style={{
        border: '1px solid #000',
        borderRadius: '5px',
        backgroundColor: '#fff',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.12)',
      }}
    >
      <LocationSVGViewer showShelfSvg={false} svgWrapperStyle={{ width: '540px', height: '405px' }} />
    </Popper>
  );
}