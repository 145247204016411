import { Icon, Typography } from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  AutoCompleteFieldProps,
} from '@mezzanine-ui/react-hook-form';
import { TimesCircleFilledIcon } from '@mezzanine-ui/icons';
import classes from './custom-auto-complete-field.module.scss';

export function CustomAutoCompleteField(props: AutoCompleteFieldProps) {
  return (
    <AutoCompleteField
      {...props}
      errorMsgRender={(value) => {
        // 目前 value.message 吃不到 yup 的訊息，原因未知
        return (
          <Typography
            color="error"
            variant="caption"
            className={classes['error-wrapper']}
          >
            <Icon
              icon={TimesCircleFilledIcon}
              size={16}
              style={{ marginRight: '4px' }}
            />
            {value.message ?? '必填'}
          </Typography>
        );
      }}
    />
  );
}
