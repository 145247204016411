import { Button } from '@mezzanine-ui/react';
import styles from './equipment-table.module.scss';
import moment from 'moment';
import {
  Equipment,
  NextPagination,
  PageInfo,
  getLocationDisplayName,
} from '@solar/data';
import { TableColumn } from '@mezzanine-ui/core/table';
import {
  LocationSVGPopper,
  LocationSelectorProvider,
  MznPaginationTable,
  useLocationSVGPopper,
  useLocationSelectorController,
} from '@solar/templates';
import { useForm } from 'react-hook-form';
import { useLocationViewerPopup } from '../../ProductionPlanning/RoutingPage/use-location-viewer-popup';

type EquipmentTableProps = {
  equipments: Equipment[];
  pageInfo?: PageInfo;
  isLoading: boolean;
  refetchEquipments: (nextPagination: NextPagination) => void;
  openBindEquipmentLocationModal: (equipment: Equipment) => void;
  openCreateEquipmentMaintenanceModal: (equipment: Equipment) => void;
};

export function EquipmentTable({
  equipments,
  pageInfo,
  refetchEquipments,
  isLoading,
  openBindEquipmentLocationModal,
  openCreateEquipmentMaintenanceModal,
}: EquipmentTableProps) {
  const controller = useLocationSelectorController({ noDefaultValue: true });
  const { anchorRef, onMouseEnter, onMouseLeave } = useLocationSVGPopper({
    handleNextLevel: controller?.handleNextLevel,
  });

  const methods = useForm<{ selectedChartId?: string }>({ defaultValues: {} });

  const [locationPopup, setLocationViewerAnchor] =
    useLocationViewerPopup(methods);

  const columns: TableColumn<Equipment>[] = [
    {
      title: '工作中心編碼',
      dataIndex: 'workCenter.id',
    },
    {
      title: '工作中心名稱',
      dataIndex: 'workCenter.name',
    },
    // { SOL-1277 拔掉
    //   title: '資產編號',
    //   dataIndex: 'assetId',
    // },
    {
      title: '設備名稱',
      dataIndex: 'name',
    },
    {
      title: '線邊倉',
      dataIndex: 'zone.id',
    },
    {
      title: '位置',
      ellipsis: false,
      render: (source) => (
        <div
          data-zone-id={source?.stack?.id}
          onMouseEnter={(event: any) => {
            if (source?.stack?.id) {
              // onMouseEnter(source?.stack?.id)(event);
              methods.setValue('selectedChartId', source?.stack?.id);

              setLocationViewerAnchor(event.currentTarget);
            }
          }}
          // onMouseLeave={onMouseLeave}
        >
          {getLocationDisplayName(source?.stack)}
        </div>
      ),
    },
    {
      title: '位置編號',
      dataIndex: 'stackId',
      ellipsis: false,
    },
    {
      width: 150,
      title: '裝機日期',
      render: (source) => moment(source?.installedAt).format('YYYY/MM/DD'),
    },
    {
      width: 80,
      title: '動作',
      align: 'center',
      render: (source) => (
        <Button onClick={() => openBindEquipmentLocationModal(source)}>
          綁定
        </Button>
      ),
    },
    {
      width: 80,
      title: '維修',
      align: 'center',
      render: (source) => (
        <Button onClick={() => openCreateEquipmentMaintenanceModal(source)}>
          報修
        </Button>
      ),
    },
  ];

  return (
    <>
      <LocationSelectorProvider controller={controller}>
        <MznPaginationTable
          pageInfo={pageInfo}
          fetchData={refetchEquipments}
          loading={isLoading}
          bodyClassName={styles.table}
          dataSource={equipments}
          columns={columns}
        />
        <LocationSVGPopper anchorRef={anchorRef} />
      </LocationSelectorProvider>
      {locationPopup}
    </>
  );
}
