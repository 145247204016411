import { TableColumn } from '@mezzanine-ui/core/table';
import { Button } from '@mezzanine-ui/react';
import { useMemo } from 'react';
import { ColumnHooks, MaterialStock } from '../types';
import Decimal from 'decimal.js';
import { CopyTextWrapper } from '../../../Templates/CopyTextWrapper/CopyTextWrapper';
import {
  InputUnitQuantityColumn,
  StockUnitQuantityColumn,
} from '../../../WarehouseEnquiry/hooks/useColumns';

export const useMaterialStockColumns = ({
  openEditModal,
  openCancelBindModal,
}: ColumnHooks) => {
  const columns = useMemo<TableColumn<MaterialStock>[]>(
    () => [
      {
        title: '操作',
        render: (source) => {
          const canOpenCancelBindModal =
            source.salesOrderId && source.salesOrderLine;

          return (
            <Button
              type="button"
              onClick={() => {
                if (canOpenCancelBindModal) {
                  openCancelBindModal(source);
                } else {
                  openEditModal({
                    loaderId: source.loaderId,
                    sapBatchId: source.materialSapBatchId,
                    subBatchId: source.materialSubBatchId,
                  });
                }
              }}
            >
              {canOpenCancelBindModal ? '解除綁定' : '編輯'}
            </Button>
          );
        },
      },
      { title: '銷售訂單代碼', dataIndex: 'salesOrderId' },
      { title: '銷售訂單次', dataIndex: 'salesOrderLine' },
      {
        width: 200,
        title: '料號',
        render: (source) => <CopyTextWrapper text={source?.materialId ?? ''} />,
      },
      {
        width: 500,
        title: '品名',
        render: (source) => (
          <CopyTextWrapper text={source?.materialDescription ?? ''} />
        ),
      },
      { title: '批號', dataIndex: 'batchId' },
      { title: '儲位', dataIndex: 'stackId' },
      { title: '載具', dataIndex: 'loaderId' },
      StockUnitQuantityColumn,
      {
        title: '基礎計量單位',
        dataIndex: 'materialStockUnit',
      },
      InputUnitQuantityColumn,
      {
        title: '管理單位',
        dataIndex: 'materialInputUnit',
      },
      {
        title: '備註',
        dataIndex: 'remark',
      },
    ],
    [openCancelBindModal, openEditModal]
  );

  return columns;
};
