import { API_NAMESPACE, request } from '@solar/data';

const namespace = API_NAMESPACE.MATERIALS;

export type CreateOutsourcePurchaseMaterialRequestOrderPayload = {
  outsourcePurchaseOrderId: string;
  expectedCompletedAt: string;
  items: Array<{
    materialId: string;
    materialSapBatchId: string;
    materialSubBatchId: string;
    loaderId: string;
    materialStockUnitQuantity: string;
  }>;
};

export const createOutsourcePurchaseMaterialRequestOrder = (
  payload: CreateOutsourcePurchaseMaterialRequestOrderPayload
): Promise<Response> => {
  return request('/warehouses/outsource-purchase-material-request-orders', {
    method: 'post',
    body: JSON.stringify(payload),
    responseParser: (res) => res,
    namespace,
  });
};
