import { checkJWTRoles, IncomingPurchaseOrder } from '@solar/data';
import {
  ModalGroup,
  PageLayout,
  useModalGroupController,
} from '@solar/templates';
import { Space } from 'antd';
import { IncomingQualityControlTable } from '../IncomingQualityControlTable/IncomingQualityControlTable';
import { Button, Typography } from '@mezzanine-ui/react';
import { Modals } from './Modals.enum';
import { IncomingQualityControlModal } from './IncomingQualityControlModal';
import { useCallback } from 'react';

export function IncomingQualityControlUserViewPage() {
  const modalGroupController = useModalGroupController([
    { name: Modals.QUALITY_CONTROL },
  ]);

  const onQualityControl = useCallback(
    (action: '特採' | '申請特採' | '退貨', source: IncomingPurchaseOrder) => {
      modalGroupController.openModal(Modals.QUALITY_CONTROL, {
        action,
        source,
      });
    },
    [modalGroupController]
  );

  return (
    <PageLayout title="進料複檢核">
      <Space size="middle" direction="vertical">
        <Typography variant="h4">待品檢</Typography>
        <IncomingQualityControlTable
          statusIds={[4, 5, 6, 7]}
          getColumns={(defaultColumns) => [
            {
              width: 200,
              title: '操作',
              render: (source: IncomingPurchaseOrder) => (
                <Space>
                  {checkJWTRoles([137]) && source.statusId === 4 && (
                    <Button
                      type="button"
                      variant="contained"
                      onClick={() => onQualityControl('申請特採', source)}
                    >
                      申請特採
                    </Button>
                  )}
                  {checkJWTRoles([137]) && source.statusId === 4 && (
                    <Button
                      danger
                      type="button"
                      variant="contained"
                      onClick={() => onQualityControl('退貨', source)}
                    >
                      退貨
                    </Button>
                  )}
                </Space>
              ),
            },
            ...defaultColumns,
          ]}
        />
        <Typography variant="h4">已簽核</Typography>
        <IncomingQualityControlTable
          statusIds={[8, 9, 10, 11]}
          getColumns={(defaultColumns) => [
            // {
            //   width: 200,
            //   dataIndex: 'actions',
            //   title: '操作',
            //   render: (source: IncomingPurchaseOrder) => (
            //     <Space>
            //       {source.statusId === 8 && (
            //         <Button
            //           type="button"
            //           variant="contained"
            //           onClick={() => onQualityControl('特採', source)}
            //         >
            //           特採
            //         </Button>
            //       )}
            //     </Space>
            //   ),
            // },
            ...defaultColumns,
          ]}
        />
      </Space>
      <ModalGroup {...modalGroupController}>
        <IncomingQualityControlModal />
      </ModalGroup>
    </PageLayout>
  );
}
