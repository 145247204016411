import { TrashIcon } from '@mezzanine-ui/icons';
import {
  Button,
  Icon,
  Modal,
  ModalActions,
  ModalBody,
  SelectValue,
} from '@mezzanine-ui/react';
import {
  FormFieldsDebug,
  FormFieldsWrapper,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { usePalletCategories } from '@solar/data';
import { RowSection, useTargetModal } from '@solar/templates';
import { useCallback } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Modals, PalletsList } from './types';

const palletsOptions = [
  {
    id: '1',
    name: '木棧板 120x120',
  },
  {
    id: '2',
    name: '木棧板 100x100',
  },
  {
    id: '3',
    name: '木棧板 120x120',
  },
  {
    id: '4',
    name: '木棧板 75x75',
  },
  {
    id: '5',
    name: '塑膠棧板 110x110 (厚)',
  },
  {
    id: '6',
    name: '塑膠棧板 110x110 (薄)',
  },
];

type PalletSelectorModalProps = {
  onSubmit: (pallets: PalletsList) => void;
};

export function PalletSelectorModal({ onSubmit }: PalletSelectorModalProps) {
  const methods = useForm<{ pallets: PalletsList }>({
    defaultValues: {
      pallets: [{}],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'pallets',
  });

  const { palletCategories } = usePalletCategories();
  const modalController = useTargetModal<Record<string, unknown>>(
    Modals.PALLET_SELECTORS
  );
  const { open, closeModal } = modalController;

  const handleModalClose = useCallback(() => {
    closeModal();
  }, []);

  return (
    <Modal open={open} onClose={handleModalClose}>
      <br />
      <br />
      <ModalBody>
        <FormFieldsWrapper
          id={Modals.PALLET_SELECTORS}
          methods={methods}
          onSubmit={(values) => {
            onSubmit(values['pallets']);
            handleModalClose();
          }}
        >
          {fields.map((row, index) => (
            <>
              <FormFieldsDebug />
              <RowSection
                label={`編號：${String(index + 1).padStart(3, '0')}`}
                key={row.id}
              >
                <SelectField
                  registerName={`pallets.${index}`}
                  options={(palletCategories ?? [])?.map((category) => ({
                    id: String(category.id),
                    name: `${category.name} ${category.lengthCm} x ${category.widthCm} `,
                  }))}
                  onChange={(e) => {
                    methods.setValue(`pallets.${index}`, {
                      id: row.id,
                      name: e.name,
                      realId: Number(e.id),
                    });
                  }}
                />
                {index > 0 && (
                  <Button
                    type="button"
                    prefix={<Icon color="warning" size={20} icon={TrashIcon} />}
                    onClick={() => remove(index)}
                  />
                )}
              </RowSection>
              <br />
            </>
          ))}
          <Button
            variant="outlined"
            type="button"
            onClick={() => append({ id: null, name: null, realId: null })}
          >
            ＋新增棧板
          </Button>
        </FormFieldsWrapper>
      </ModalBody>
      <ModalActions
        cancelText="取消"
        confirmText="確認"
        onCancel={() => {
          handleModalClose();
        }}
        confirmButtonProps={{ form: Modals.PALLET_SELECTORS }}
      />
    </Modal>
  );
}
