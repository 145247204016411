import React from 'react';
import { Table, Button } from '@mezzanine-ui/react';
import { PageLayout } from '@solar/templates';
import { FilterPanelForm } from './FilterPanelForm';
import { CustomerAutoComplete } from './FilterPanelForm/CustomerAutoComplete';
import { MaterialAutoComplete } from './FilterPanelForm/MaterialAutoComplete';
import { MaterialCategoryAutoComplete } from './FilterPanelForm/MaterialCategoryAutoComplete';
import { CreateModalButton } from './CreateModalButton';
import { ActionDeleteConfirmButton } from './ActionDeleteConfirmButton';
import { ActionUpdateModalButton } from './ActionUpdateModalButton';
import {
  useCustomerTradeConditionListGet,
  type CustomerTradeConditionListData,
  useCustomerTradeConditionFilter,
} from '@solar/data';

export function TradeConditionPage() {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);

  const {
    filterCondition,
    updateFilterCondition,
    bindFormMethodsForInitialization,
  } = useCustomerTradeConditionFilter();

  const {
    customerTradeConditionListData,
    customerTradeConditionListMeta,
    refetchCustomerTradeConditionList,
    isLoading,
  } = useCustomerTradeConditionListGet({
    page: currentPage,
    perPage: perPage,
    ...filterCondition,
  });

  const tableColumns = [
    {
      title: '操作',
      width: 180,
      render(source: CustomerTradeConditionListData) {
        return (
          <div style={{ display: 'flex', gap: '1rem' }}>
            <ActionUpdateModalButton
              dataSource={source}
              refetchDataTable={refetchCustomerTradeConditionList}
            />
            <ActionDeleteConfirmButton
              dataSource={source}
              refetchDataTable={() => {
                if (
                  currentPage == customerTradeConditionListMeta.lastPage &&
                  customerTradeConditionListMeta.total % perPage === 1
                ) {
                  const page = currentPage - 1;
                  setCurrentPage(page <= 0 ? 1 : page);
                } else {
                  refetchCustomerTradeConditionList();
                }
              }}
            />
          </div>
        );
      },
    },
    {
      title: '客戶名稱與代碼',
      dataIndex: 'customer',
      width: 280,
    },
    {
      title: '料號',
      dataIndex: 'materialId',
      width: 350,
    },
    {
      title: '品名',
      dataIndex: 'materialName',
      width: 350,
    },
    {
      title: '產品大類',
      dataIndex: 'classA',
      width: 150,
    },
    {
      title: '產品中類',
      dataIndex: 'classB',
      width: 150,
    },
    {
      title: '產品小類',
      dataIndex: 'classC',
      width: 150,
    },
    {
      title: '成分代碼1',
      dataIndex: 'componentItemNumber1',
      width: 120,
    },
    {
      title: '成分代碼1重量交易方式',
      dataIndex: '_weightMethod1',
      width: 190,
    },
    {
      title: '成分代碼1分析值交易方式',
      dataIndex: '_analysisValueMethod1',
      width: 190,
    },
    {
      title: '成分代碼2',
      dataIndex: 'componentItemNumber2',
      width: 120,
    },
    {
      title: '成分代碼2重量交易方式',
      dataIndex: '_weightMethod2',
      width: 190,
    },
    {
      title: '成分代碼2分析值交易方式',
      dataIndex: '_analysisValueMethod2',
      width: 190,
    },
    {
      title: '成分代碼3',
      dataIndex: 'componentItemNumber3',
      width: 120,
    },
    {
      title: '成分代碼3重量交易方式',
      dataIndex: '_weightMethod3',
      width: 190,
    },
    {
      title: '成分代碼3分析值交易方式',
      dataIndex: '_analysisValueMethod3',
      width: 190,
    },
    {
      title: '成分代碼4',
      dataIndex: 'componentItemNumber4',
      width: 120,
    },
    {
      title: '成分代碼4重量交易方式',
      dataIndex: '_weightMethod4',
      width: 190,
    },
    {
      title: '成分代碼4分析值交易方式',
      dataIndex: '_analysisValueMethod4',
      width: 190,
    },
  ];

  return (
    <PageLayout
      title="客戶料號交易條件設定"
      extraContent={
        <CreateModalButton
          refetchDataTable={refetchCustomerTradeConditionList}
        />
      }
    >
      <FilterPanelForm
        exposeFormMethodsForInitializeFilterPanel={
          bindFormMethodsForInitialization
        }
        applyFilterCondition={() => {
          updateFilterCondition({
            onFilterConditionNoChange: refetchCustomerTradeConditionList,
            onUpdateSuccess: () => setCurrentPage(1),
          });
        }}
      >
        <CustomerAutoComplete />
        <MaterialAutoComplete />
        <MaterialCategoryAutoComplete
          styles={{ label: { alignSelf: 'start' } }}
        />
      </FilterPanelForm>
      <Table
        scroll={{ x: 3610, y: 570, fixedFirstColumn: true }}
        draggable={{ enabled: false }}
        loading={isLoading}
        columns={tableColumns}
        dataSource={customerTradeConditionListData}
        pagination={{
          disableAutoSlicing: true,
          total: customerTradeConditionListMeta.total,
          current: currentPage,
          onChange: (page) => {
            setCurrentPage(page);
          },
          options: {
            pageSize: perPage,
            pageSizeLabel: '每頁顯示：',
            pageSizeUnit: '筆',
            onChangePageSize: (pageSize) => {
              setPerPage(pageSize);
              setCurrentPage(1);
            },
            renderPageSizeOptionName: (p) => `${p}`,
            showPageSizeOptions: true,
            showJumper: true,
            jumperButtonText: '前往',
            jumperHintText: '跳至',
          },
        }}
      />
    </PageLayout>
  );
}
