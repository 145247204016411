import {
  CheckboxField,
  type CheckboxFieldProps,
} from '@mezzanine-ui/react-hook-form';
import { CustomerTradeConditionCreateFormFieldName as FormFieldName } from '@solar/data';

type MaterialOptionalCheckboxProps = Omit<
  CheckboxFieldProps,
  'label' | 'registerName' | 'color'
>;

function MaterialOptionalCheckbox({ ...props }: MaterialOptionalCheckboxProps) {
  return (
    <>
      <div></div>
      <CheckboxField
        label="不分料號"
        registerName={FormFieldName._MATERIAL_OPTIONAL}
        color="text-secondary"
        {...props}
      />
    </>
  );
}

export { MaterialOptionalCheckbox };
