import { CSSProperties, useEffect, useRef } from "react";
import { useLocationSVGElements } from "./useLocationSVGElements";
import { useLocationSVGClick } from "./useLocationSVGClick";
import { useLocationSelectorContext } from "../LocationSelectorContext/useLocationSelectorContext";

type ShelfSVGProps = {
  className: string;
  style?: CSSProperties;
  onClick: (event: MouseEvent) => void;
};

export function ShelfSVG({ className, style, onClick }: ShelfSVGProps) {
  const x = 320;
  const y = 240;
  const padding = 20;
  const svgWrapperRef = useRef<HTMLDivElement>(null);
  const { currentLocation } = useLocationSelectorContext();
  const stackId = currentLocation?.stack?.id;
  const shelves = currentLocation?.stack?.shelves;

  useEffect(() => {
    if (svgWrapperRef.current) {
      const svgNode = svgWrapperRef.current?.querySelector('svg');
      const nodeList = svgWrapperRef.current?.querySelectorAll('polygon');
      const elements = Array.from(nodeList ?? []);
      elements.forEach((element) => {
        const elementStackId = element?.getAttribute('stackId');
        if (elementStackId !== stackId) element.remove();
      });
      shelves?.forEach((shelf, index) => {
        const isExist = elements.find((element) => {
          const id = element?.getAttribute('id');
          return id === shelf?.id;
        });
        if (!isExist) {
          const seq = index + 1;
          const leftX = padding;
          const rightX = x - padding;
          const topY = y - (seq + 1) * 30;
          const bottomY = y - seq * 30;
          const polygon = document.createElementNS("http://www.w3.org/2000/svg", "polygon");
          polygon.setAttribute('stackId', stackId ?? '');
          polygon.setAttribute('id', shelf?.id);
          polygon.setAttribute('name', shelf?.name);
          polygon.setAttribute('seq', seq.toString());
          polygon.setAttribute('category', 'shelf');
          polygon.setAttribute('style', 'fill: green; stroke: black; stroke-width: 2;');
          polygon.setAttribute('points', `${leftX}, ${bottomY}, ${rightX}, ${bottomY}, ${rightX}, ${topY}, ${leftX}, ${topY}`);
          svgNode?.appendChild(polygon);
        }
      });
    }
  }, [shelves, stackId]);

  useLocationSVGElements(svgWrapperRef);
  useLocationSVGClick(svgWrapperRef, { onClick });

  return (
    <div
      id="shelf-svg-wrapper"
      ref={svgWrapperRef}
      style={style}
      className={className}>
      <svg key={stackId} viewBox={`0 0 ${x} ${y}`} width="800" height="600" />
    </div>
  );
};
