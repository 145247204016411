import { Fragment } from 'react';
import styles from './preview-modal.module.scss';
import { ProductionParameterResponse } from '@solar/data';

export function ProductionParameters({
  parameters,
}: {
  parameters?: ProductionParameterResponse[];
}) {
  return (
    <Fragment>
      {(parameters ?? []).map((form) => (
        <div className={styles.parameter} key={form.id}>
          <span className={styles.title}>{form.key}</span>
          <span className={styles.value}>{form.value}</span>
        </div>
      ))}
    </Fragment>
  );
}
