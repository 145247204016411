import { Tab, TabPane, Tabs } from "@mezzanine-ui/react";
import { PageLayout } from "@solar/templates";
import { Key, useState } from "react";
import { DispatchOrderTabPaneContent } from "../OutsourcingOrderPage/DispatchOrderTabPaneContent";
import { DispatchInfoTabPaneContent } from "./DispatchInfoTabPaneContent";

export function OutsourcingOrderDetailPage() {
  const [activeTab, setActiveTab] = useState<Key>('dispatch-info');

  return (
    <PageLayout disabledDivider backTo="/production-planning/outsourcing-orders">
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane key="dispatch-info" tab={<Tab>基本資訊</Tab>}>
          <DispatchInfoTabPaneContent />
        </TabPane>
        <TabPane key="dispatch-order" tab={<Tab>發料單</Tab>}>
          <DispatchOrderTabPaneContent />
        </TabPane>
      </Tabs>
    </PageLayout>
  )
}