import { useState } from 'react';

export type ModalController = {
  modalController: ReturnType<typeof useModalController>;
};

export function useModalController<
  ModalData extends Record<string, unknown>
>() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<ModalData | null>(null);
  const openModal = (data: ModalData) => {
    setData(data);
    setOpen(true);
  };
  const closeModal = (options?: { keepData?: boolean }) => {
    if (!options?.keepData) setData(null);
    setOpen(false);
  };
  return { open, data, openModal, closeModal };
}
