import {
  Button,
  Message,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  SelectValue,
  Table,
  Typography,
  Input,
} from '@mezzanine-ui/react';
import { useEffect, useState } from 'react';
import {
  SPFormOrderItemInfo,
  SPMatchItem,
  useStrikePriceMatch,
  ISPPurchaseOrderItem,
  ISPEditPageMode,
  SPEditPageMode,
} from '@solar/data';
import Decimal from 'decimal.js';
import { useForm } from 'react-hook-form';
import {
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import styles from './SPItemMatchModal.module.scss';

export interface IMatchFinishParams {
  matchList: SPMatchItem[];
  unbindList: SPMatchItem[];
  unMatchQty: number;
}

interface ISPMatchModalProps {
  open: boolean;
  onFinish?: (d?: IMatchFinishParams) => void;
  itemInfo: SPFormOrderItemInfo | null;
  matchList?: SPMatchItem[];
  unbindList?: SPMatchItem[];
  pageMode: ISPEditPageMode;
}

interface SearchFormData {
  purchaseType: SelectValue<string>;
  purchaseId: string;
}

const SPPurchaseMatchModal = ({
  itemInfo,
  open,
  onFinish = () => {
    /** */
  },
  matchList = [],
  unbindList = [],
  pageMode,
}: ISPMatchModalProps) => {
  const searchForm = useForm<SearchFormData>({
    defaultValues: {
      purchaseType: { name: '一般採購單', id: '0' },
      // orderId: '2100000181',
    },
  });
  const [availableWeight, setAvailableWeight] = useState(0);
  const [tMatchList, setTMatchList] = useState<SPMatchItem[]>([]);
  const [tUnBindList, setTunBindList] = useState<SPMatchItem[]>([]);
  const {
    searchPurchase,
    searchItems,
    setSearchItems,
    isLoading,
    unbindPurchaseItem,
    bindPurchaseItem,
    clearSearchItems,
  } = useStrikePriceMatch();

  useEffect(() => {
    if (!open) {
      setAvailableWeight(0);
    }
    if (itemInfo) {
      // console.log(itemInfo);
      setAvailableWeight(Number(itemInfo.unMatchQty));

      // filter matchList
      const filterMatchList = matchList.filter((item) =>
        pageMode === SPEditPageMode.MATCH
          ? item.reportPriceItemId === itemInfo.id && item.state === 1
          : item.reportPriceItemId === itemInfo.id
      );
      const filterUnbindList = unbindList.filter(
        (item) => item.reportPriceItemId === itemInfo.id
      );
      setTMatchList(filterMatchList);
      setTunBindList(filterUnbindList);
    }
  }, [itemInfo, open]);

  const bodyRowClassName = (record: Record<string, unknown>): string =>
    record.state === 0 ? 'deleted-row' : '';

  // ZOR1,ZOR6,ZKE
  // 2024/09/06 新增 ZKE1
  const purchaseTypeOptions: SelectValue<string>[] = [
    { name: '一般採購單', id: '0' },
    { name: '委外採購單', id: '1' },
  ];

  const onSearch = async (data: SearchFormData) => {
    try {
      await searchPurchase(
        {
          purchaseId: data.purchaseId || '',
          purchaseType: data.purchaseType?.id || '',
          component: itemInfo?.component?.id || '',
        },
        tMatchList,
        tUnBindList
      );
    } catch (e: any) {
      Message.error(e.message);
    }
  };

  const onClickBind = (record: ISPPurchaseOrderItem) => {
    const matchItem: SPMatchItem = {
      key: `${record.orderId}${record.orderItem}`,
      reportPriceId: itemInfo?.reportPriceId,
      reportPriceItemId: itemInfo?.id,
      component: itemInfo?.component?.id || '',
      matchQty: record.unBindQty, // 預設綁定的重量就是全部位綁定的重量
      unMatchQty: 0, // 預設全都綁定了所以沒有未綁定的重量
      materialId: record.materialId,
      orderId: record.orderId,
      orderItem: record.orderItem,
      state: 1,
      originalWt: record.wt,
      originalUnBindQty: record.unBindQty,
    };

    // 如果 tUnBindList 有該比訂單項次則移除
    // setTunBindList(tUnBindList.filter(item => (item.orderId !== record.orderId && item.orderItem !== record.orderItem)));

    // 更新可對應重量
    const newWeight = new Decimal(availableWeight)
      .minus(record.unBindQty)
      .toNumber();
    if (newWeight > 0) {
      setAvailableWeight(newWeight);
      bindPurchaseItem(record, 0);
    } else {
      matchItem.matchQty = availableWeight;
      matchItem.unMatchQty = Math.abs(newWeight);
      bindPurchaseItem(record, Math.abs(newWeight));
      setAvailableWeight(0);
    }

    setTMatchList([...tMatchList, matchItem]);
  };

  const onClickUnBind = (record: SPMatchItem) => {
    const hasUnbind = tUnBindList.find(
      (item) =>
        item.orderId === record.orderId && item.orderItem === record.orderItem
    );
    if (!hasUnbind && record.id) {
      setTunBindList([...tUnBindList, record]);
    }
    setTMatchList(
      tMatchList.filter(
        (item) =>
          !(
            record.orderId === item.orderId &&
            record.orderItem === item.orderItem
          )
      )
    );
    unbindPurchaseItem(record);
    const newWeight = new Decimal(availableWeight)
      .plus(record.matchQty)
      .toNumber();
    setAvailableWeight(newWeight);
  };

  const renderWt = (record: ISPPurchaseOrderItem) => {
    if (record.canEditQuantity) {
      return (
        <div style={{ width: 80 }}>
          <Input
            className="table-edit-input"
            value={record.wt.toString()}
            onChange={(e) => {
              const newWt = e.target.value;
              const newSearchItems = searchItems.map((item) => {
                if (item.id === record.id) {
                  return { ...item, wt: newWt, unBindQty: newWt };
                }
                return item;
              });
              setSearchItems(newSearchItems);
            }}
          />
        </div>
      );
    }
    return record.wt;
  };

  const matchColumns = [
    {
      title: '操作',
      render: (record: ISPPurchaseOrderItem) => {
        return (
          <Button
            onClick={() => onClickBind(record)}
            variant="contained"
            size="small"
            disabled={!record.canMatch}
          >
            對應
          </Button>
        );
      },
    },
    {
      title: '項次',
      dataIndex: 'orderItem',
    },
    {
      title: '料號',
      dataIndex: 'materialId',
    },
    {
      title: '訂單重量(G)',
      render: renderWt,
    },
    {
      title: '未綁定重量(G)',
      dataIndex: 'unBindQty',
    },
  ];

  const unbindColumn = [
    {
      title: '操作',
      render:
        pageMode === SPEditPageMode.MATCH
          ? (record: SPMatchItem) => (
              <Button
                variant="contained"
                size="small"
                onClick={() => onClickUnBind(record)}
              >
                取消對應
              </Button>
            )
          : () => null,
    },
    {
      title: '訂單',
      dataIndex: 'orderId',
      width: 140,
    },
    {
      title: '項次',
      dataIndex: 'orderItem',
      width: 100,
    },
    {
      title: '料號',
      dataIndex: 'materialId',
    },
    {
      title: '對應重量(G)',
      dataIndex: 'matchQty',
    },
  ];

  if (pageMode !== SPEditPageMode.MATCH) {
    unbindColumn.splice(0, 1);
  }

  const onCloseModal = () => {
    onFinish();
    setTMatchList([]);
    setTunBindList([]);
    setAvailableWeight(0);
    clearSearchItems();
  };

  const onConfirm = () => {
    onFinish({
      matchList: tMatchList,
      unbindList: tUnBindList,
      unMatchQty: availableWeight,
    });
    setTMatchList([]);
    setTunBindList([]);
    setAvailableWeight(0);
    clearSearchItems();
  };

  return (
    <Modal
      open={open}
      disableCloseOnBackdropClick
      disableCloseOnEscapeKeyDown
      style={{ width: 1300 }}
      onClose={onFinish}
      hideCloseIcon
    >
      <ModalHeader>採購單對應</ModalHeader>
      <ModalBody>
        <div style={{ display: 'flex', gap: 12 }}>
          {pageMode === SPEditPageMode.MATCH && (
            <div style={{ flex: 1 }}>
              <Typography variant="h3">搜尋採購單對應</Typography>
              <Typography variant="h5" color="secondary">
                成分: {itemInfo?.component?.name}
              </Typography>
              <Typography variant="h5" color="secondary">
                可對應數量: {availableWeight} G
              </Typography>
              <div
                style={{ borderBottom: '1px solid #ccc', margin: '10px 0' }}
              />
              <FormFieldsWrapper methods={searchForm} onSubmit={onSearch}>
                <SelectField
                  style={{ width: '100%' }}
                  registerName="purchaseType"
                  options={purchaseTypeOptions}
                  label="採購單類型"
                />
                <InputField registerName="purchaseId" label="訂單編號" />
                <Button
                  style={{ marginTop: 12 }}
                  disabled={isLoading}
                  variant="contained"
                  type="submit"
                >
                  搜尋
                </Button>
              </FormFieldsWrapper>
              <div className={styles.spPurchaseTable}>
                <Table
                  style={{ marginTop: 12 }}
                  columns={matchColumns}
                  dataSource={searchItems}
                  loading={isLoading}
                />
              </div>
            </div>
          )}

          <div style={{ flex: 1 }} className={styles.spItemMatchTable}>
            <Typography variant="h3">已對應採購單</Typography>
            <Table
              style={{ marginTop: 12 }}
              columns={unbindColumn}
              dataSource={tMatchList}
              bodyRowClassName={bodyRowClassName}
            />
          </div>
        </div>
      </ModalBody>
      {pageMode === SPEditPageMode.MATCH ? (
        <ModalActions
          cancelText="取消"
          onCancel={onCloseModal}
          confirmText="確定"
          onConfirm={onConfirm}
        />
      ) : (
        <ModalActions
          cancelText="關閉"
          onCancel={onCloseModal}
          hideConfirmButton
        />
      )}
    </Modal>
  );
};

export default SPPurchaseMatchModal;
