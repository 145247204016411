import { Tab, TabPane, Tabs, Typography } from '@mezzanine-ui/react';
import styles from './material-supply-page.module.scss';
import { WorkOrderTable } from './WorkOrderTable';
import { DeliveryOrderTable } from './DeliveryOrderTable';
import { OutsourcingOrderTable } from './OutsourcingOrderTable';
import { OutsourcingProcessOrderTable } from './OutsourcingProcessOrderTable';
import { Key, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { VirtualOutPage } from './VirtualOutPage';

enum TabKeys {
  WORK_ORDER = 'work-order',
  DELIVERY_ORDER = 'delivery-order',
  OUTSOURCING_PURCHASE_ORDER = 'outsourcing-purchase-order',
  OUTSOURCING_PROCESS_ORDER = 'outsourcing-process-order',
  VIRTUAL_OUT = 'virtual-out',
}

export function MaterialSupplyPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabActiveKey = searchParams.get('activeKey') || TabKeys.WORK_ORDER;

  useEffect(() => {
    if (!searchParams.get('activeKey')) {
      setSearchParams({ activeKey: TabKeys.WORK_ORDER });
    }
  }, [searchParams, setSearchParams]);

  const onTabChange = (key: Key) => {
    setSearchParams({ activeKey: key as string });
  };

  return (
    <div className={styles.page}>
      <Typography variant="h1">倉庫發料</Typography>
      <Tabs activeKey={tabActiveKey} onChange={onTabChange}>
        <TabPane key={TabKeys.WORK_ORDER} tab={<Tab>工單</Tab>}>
          <WorkOrderTable />
        </TabPane>
        <TabPane key={TabKeys.DELIVERY_ORDER} tab={<Tab>出貨單</Tab>}>
          <DeliveryOrderTable />
        </TabPane>
        <TabPane
          key={TabKeys.OUTSOURCING_PURCHASE_ORDER}
          tab={<Tab>委外採購單</Tab>}
        >
          <OutsourcingOrderTable />
        </TabPane>
        <TabPane
          key={TabKeys.OUTSOURCING_PROCESS_ORDER}
          tab={<Tab>委外加工工單</Tab>}
        >
          <OutsourcingProcessOrderTable />
        </TabPane>
        <TabPane key={TabKeys.VIRTUAL_OUT} tab={<Tab>虛出</Tab>}>
          <VirtualOutPage />
        </TabPane>

        {/* <TabPane key="returnedOrder" tab={<Tab>退貨單</Tab>}>
          <ReturnedOrderTable />
        </TabPane> */}
      </Tabs>
    </div>
  );
}
