import { request, API_NAMESPACE } from '../../request';
import useSWR from 'swr';
import * as yup from 'yup';

type HookProps = {
  deliveryOrderDetailId?: string;
};

type HubOrderCustomerData = {
  id: string;
  name: string;
};

type HubOrdersFilterConditionPayload = {
  customers: HubOrderCustomerData[];
};

export function useHubOrdersFilterConditionGet(props?: HookProps) {
  const { data, mutate, isValidating } =
    useSWR<HubOrdersFilterConditionPayload>(
      validateSWRKey(props?.deliveryOrderDetailId),
      request,
      {
        revalidateOnFocus: false,
      }
    );

  const { customers } = data ?? {};

  return {
    customerList: customers ?? [],
    refetchFilterCondition: mutate,
    isLoading: isValidating,
  };
}

function validateSWRKey(deliveryOrderDetailId?: string) {
  try {
    const safeDeliveryOrderDetailId = yup
      .string()
      .optional()
      .validateSync(deliveryOrderDetailId);

    const params = safeDeliveryOrderDetailId
      ? {
          deliveryOrderDetailId: safeDeliveryOrderDetailId,
        }
      : undefined;

    return [
      `/hubDeliveryOrders/get/filter`,
      {
        namespace: API_NAMESPACE.HR,
        params,
      },
    ];
  } catch (error) {
    return false;
  }
}
