import { useCallback, useMemo, useState } from 'react';
import type { SelectValue } from '@mezzanine-ui/react';
import { AutoComplete, Message } from '@mezzanine-ui/react';
import { groupBy } from 'lodash';
import { useLocationControlContext } from '../LocationControlContext';
import { useGetAllLevelLocations } from '../hooks';
import { LocationLevel } from '../types';

export function LocationAllLevelSearch() {
  const { activeId, error, onChangeByAllLevelSearch } =
    useLocationControlContext();
  const [searchTerm, setSearchTerm] = useState('');
  const { locations } = useGetAllLevelLocations({ searchTerm });

  const searchValue = useMemo(
    () => (activeId ? { id: activeId, name: activeId } : null),
    [activeId]
  );

  const locationsGroupById = useMemo(
    () => groupBy(locations, 'id'),
    [locations]
  );

  const options = useMemo(
    () => Object.keys(locationsGroupById).map((id) => ({ id, name: id })),
    [locationsGroupById]
  );

  const onChange = useCallback(
    (value: SelectValue) => {
      let level = LocationLevel.FACTORY;

      if (value) {
        level = locationsGroupById[value.id]?.slice(-1)?.[0]?.level ?? null;

        if (!level) {
          Message.error('無法取得該位置階層');
          return;
        }
      }

      onChangeByAllLevelSearch?.(value, level);
    },
    [onChangeByAllLevelSearch, locationsGroupById]
  );

  return (
    <AutoComplete
      error={error}
      options={options}
      value={searchValue}
      onChange={onChange}
      onSearch={setSearchTerm}
      placeholder="請輸入關鍵字搜尋"
    />
  );
}
