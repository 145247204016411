import type { ComponentProps } from 'react';
import { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions,
  Button,
  Message,
  Table,
  Input,
  Select,
  Option,
  SelectValue,
} from '@mezzanine-ui/react';
import { FormProvider } from 'react-hook-form';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  useHubOrderDetailGet,
  useHubOrderUpdate,
  fetchReceivingHubOrderOptionsGet,
  WarehouseOption,
  OrderDetail,
  checkJWTRoles,
} from '@solar/data';
import { OrderDeliveryIdInput } from './OrderReceivingIdInput';
import { OrderAccountDatePicker } from './OrderAccountDatePicker';
import { OrderReverseDatePicker } from './OrderReverseDatePicker';
import styles from './index.module.scss';
import {
  ReceivingDeliveryOrdersPayload,
  ReceiveingItem,
  putReceivingOrdersReceipt,
} from '@solar/data';
import { set } from 'lodash';

type Props = ComponentProps<typeof Modal> & {
  hubDeliveryOrderId: string;
};

type EditableWarehouseOptions = WarehouseOption & {
  unitQuantity: string;
};

type EditableOrderDetail = OrderDetail & {
  receiveQuantity: string;
  submitDeliveryOrderId: string;
  submitLine: string;
};

const groupOptionsByMaterialId = (options: EditableWarehouseOptions[]) => {
  const groupedOptions: Record<string, EditableWarehouseOptions[]> = {};
  options.forEach((option) => {
    if (!groupedOptions[option.materialId]) {
      groupedOptions[option.materialId] = [];
    }
    groupedOptions[option.materialId].push(option);
  });
  return groupedOptions;
};

const splitOption = (option: string) => {
  const [batchGroup, quantity] = option.split(' ');
  const [batchId, subBatchId] = batchGroup.split('-');
  const trimmedQuantity = quantity.replace(/()/g, '');
  return {
    batchId,
    subBatchId,
    trimmedQuantity,
  };
};

export function ActionDetailModalButton({ hubDeliveryOrderId }: Props) {
  // swr 1.3.0 not support useSWRMutation, so declare reactive state to defer fetch
  const [deferHubDeliveryOrderId, setDeferHubDeliveryId] = useState<
    string | null
  >(null);
  const { formMethods, formSubmitCreator } = useHubOrderUpdate();
  const [sourceHubDeliveryOrderId, setSourceHubDeliveryOrderId] =
    useState<string>(''); // 搜尋出貨單號 live value
  const { hubOrderDetail, isLoading, refetchHubOrderDetail } =
    useHubOrderDetailGet({
      hubDeliveryOrderId: deferHubDeliveryOrderId,
      formMethods,
      type: 'RECEIVING',
      errorCallback: (error) =>
        Message.error('取得調撥明細失敗：' + error?.message),
    });
  const isDeducted = !!hubOrderDetail?.debitDate;
  // init order detail rows
  const setDefaultOrderDetailRows = () => {
    if (hubOrderDetail) {
      setOrderDetailRows(
        hubOrderDetail.details.map(
          (item) =>
            ({
              ...item,
              receiveQuantity: '',
              submitDeliveryOrderId: '',
              submitLine: '',
              unitQuantity: '',
            } || [])
        )
      );
    }
  };
  useEffect(() => setDefaultOrderDetailRows(), [hubOrderDetail]);

  // on drop down select value change
  useEffect(() => {
    if (!sourceHubDeliveryOrderId) return;
    fetchReceivingHubOrderOptionsGet({
      sourceHubDeliveryOrderId,
      hubDeliveryOrderId: deferHubDeliveryOrderId,
      type: 'RECEIVING',
      errorCallback: (error) => {
        Message.error(
          `Invalid Source Hub Delivery Order ID: ${sourceHubDeliveryOrderId}`
        );
      },
      successCallback: (data) => {
        if (data) {
          // set items
          setOrderDetailRows(
            data.details.map((item: any) => ({
              ...item,
              receiveQuantity: '',
            }))
          );

          // Record
          setGroupedWarehouseOptions(
            groupOptionsByMaterialId(
              data.warehouseOptions.map((option: any) => ({
                ...option,
                unitQuantity: '0',
              }))
            )
          );
        }
      },
    });
  }, [sourceHubDeliveryOrderId]);

  const [groupedWarehouseOptions, setGroupedWarehouseOptions] = useState<
    Record<string, EditableWarehouseOptions[]>
  >({});

  const submitReceivingQuantity = () => {
    // gloabl variables
    const payload: ReceivingDeliveryOrdersPayload =
      {} as ReceivingDeliveryOrdersPayload;
    // const specId = parseInt(hubOrderDetail?.deliveryOrderDetailId || '');
    const items = Array<ReceiveingItem>();
    // for each hubOrderDetail item
    hubOrderDetail?.details.map((item) => {
      const innerDetailRows = orderDetailRows.filter(
        (row) => row.line === item.line
      );
      // batch
      const batches = innerDetailRows.map((row) => {
        // const [parent, sub] = row.batchId.split('-');
        const { batchId, subBatchId, trimmedQuantity } = splitOption(
          row.batchId
        );
        return {
          materialInputUnitQuantity: row.receiveQuantity,
          materialWeightUnitQuantity: '0',
          materialGrossWeightUnitQuantity: '0',
          remark: null,
          deliveredOrderId: row.submitDeliveryOrderId,
          deliveredOrderLine: row.submitLine,
          deliveredSapBatchId: batchId,
          deliveredSubBatchId: subBatchId,
        };
      });
      items.push({
        specId: item.deliveryOrderDetailId,
        toShelfId: hubOrderDetail.shelfId,
        toLoaderId: null,
        materialInputUnit: item.quantityUnit,
        batches,
        remark: null,
      });
    });
    payload.items = items;
    payload.recycleNumber = null;
    putReceivingOrdersReceipt(payload)
      .then((data) => {
        setDeferHubDeliveryId(null);
        Message.success('收貨成功');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Message.error('收貨失敗：' + error?.message);
      });
  };

  // Order Detail - use as default rows for table
  const [orderDetailRows, setOrderDetailRows] = useState<EditableOrderDetail[]>(
    []
  );

  const handleReceiveQuantityChange = (index: number, value: string) => {
    const newOrderDetailRows = [...orderDetailRows];
    newOrderDetailRows[index] = {
      ...newOrderDetailRows[index],
      receiveQuantity: value || '',
    };
    setOrderDetailRows(newOrderDetailRows);
  };

  const handleBatchChange = (index: number, value: SelectValue<string>) => {
    const [deliveredOrderId, line] = value.id.split('-');
    const newOrderDetailRows = [...orderDetailRows];
    newOrderDetailRows[index] = {
      ...newOrderDetailRows[index],
      batchId: value.name || '',
      submitDeliveryOrderId: deliveredOrderId || '',
      submitLine: line || '',
    };
    setOrderDetailRows(newOrderDetailRows);
  };

  const resetForm = () => {
    setDefaultOrderDetailRows();
    // clear batch selector
    document.querySelectorAll('.batch-select').forEach((select) => {
      if (select.getElementsByTagName('i').length > 1) {
        select.getElementsByTagName('i')[1].click();
      }
    });
  };

  const optionsColumns = [
    {
      title: '項次',
      dataIndex: 'line',
    },
    {
      title: '母批子批',
      width: 300,
      render(record: any, _index: number) {
        return (
          <div
            style={{
              display: 'inline-grid',
              gridTemplateColumns: '250px repeat(1, 100px)',
              alignItems: 'center',
            }}
          >
            <Select
              className="batch-select"
              clearable
              required
              fullWidth
              placeholder="母批子批"
              onChange={(value) => {
                handleBatchChange(_index, value);
              }}
              style={{
                width: 380,
              }}
            >
              {groupedWarehouseOptions[
                orderDetailRows[_index]?.materialId
              ]?.map((option, index) => (
                // value: deliveryOrderId-line
                // name: sapBatchId-subBatchId
                <Option
                  key={option.deliveryOrderId + '-' + option.deliveryOrderLine}
                  value={
                    option.deliveryOrderId + '-' + option.deliveryOrderLine
                  }
                >
                  {`${option.sapBatchId}-${option.subBatchId} (已出貨數量: ${option.quantity})`}
                </Option>
              )) || (
                <Option key="no-data" value="no-data" disabled>
                  No Matching Batches
                </Option>
              )}
            </Select>
          </div>
        );
      },
    },
    {
      title: '收貨數量',
      render(record: any, _index: number) {
        return (
          <Input
            size="small"
            value={orderDetailRows[_index]?.receiveQuantity}
            onChange={(e) => {
              handleReceiveQuantityChange(_index, e.target.value);
            }}
          />
        );
      },
    },
    {
      title: '動作',
      // width: 300,
      render(record: any, _index: number) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                const newOrderDetailRows = [...orderDetailRows];
                newOrderDetailRows.splice(_index + 1, 0, {
                  ...orderDetailRows[_index],
                  receiveQuantity: '',
                  batchId: '',
                  submitDeliveryOrderId: '',
                  submitLine: '',
                });
                setOrderDetailRows(newOrderDetailRows);
              }}
            >
              新增
            </Button>
            <Button
              style={{
                marginLeft: '5px',
              }}
              variant="outlined"
              danger
              onClick={() => {
                const newOrderDetailRows = [...orderDetailRows];
                newOrderDetailRows.splice(_index, 1);
                setOrderDetailRows(newOrderDetailRows);
              }}
            >
              刪除
            </Button>
          </div>
        );
      },
    },
  ];

  const targetLineColumns = [
    {
      title: '項次',
      dataIndex: 'line',
    },
    {
      title: '料號',
      dataIndex: 'materialId',
    },
    {
      title: '數量',
      dataIndex: 'quantity',
    },
    {
      title: '單位',
      dataIndex: 'quantityUnit',
    },
  ];

  const isDeductedTableColumns = [
    {
      title: '項次',
      dataIndex: 'deliveryOrderLine',
    },
    {
      title: '母批',
      dataIndex: 'sapBatchId',
    },
    {
      title: '子批',
      dataIndex: 'subBatchId',
    },
    {
      title: '數量',
      dataIndex: 'quantity',
    },
  ];

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setDeferHubDeliveryId(hubDeliveryOrderId);
        }}
      >
        收貨明細
      </Button>
      <Modal
        hideCloseIcon={false}
        loading={isLoading}
        onClose={() => {
          setDeferHubDeliveryId(null);
        }}
        open={deferHubDeliveryOrderId !== null && !!hubOrderDetail}
        size="extraLarge"
        style={{
          overflow: 'auto',
        }}
      >
        <ModalHeader>HUB收貨單</ModalHeader>
        <FormProvider {...formMethods}>
          <FormFieldsWrapper
            methods={formMethods}
            onSubmit={formSubmitCreator({
              successCallback: () => {
                const isAccount = !hubOrderDetail?.debitDate;
                refetchHubOrderDetail();
                Message.success(`${isAccount ? '扣帳成功' : '迴轉成功'}`);
              },
              errorCallback: (error) => {
                const isAccount = !hubOrderDetail?.debitDate;
                Message.error(
                  `${isAccount ? '扣帳失敗' : '迴轉失敗'}：${error?.message}`
                );
              },
            })}
          >
            <ModalBody>
              <div className={styles.wrapper}>
                <OrderDeliveryIdInput id={hubOrderDetail?.id} />
                <div className={styles['col-span-1']}>客戶名稱／編號：</div>
                <div className={styles['col-span-3']}>
                  {hubOrderDetail?.customerName || '-'}
                </div>
                <div className={styles['col-span-1']}>收貨單日期：</div>
                <div className={styles['col-span-1']}>
                  {hubOrderDetail?.shipDate || '-'}
                </div>
                <OrderAccountDatePicker debitDate={hubOrderDetail?.debitDate} />
                <OrderReverseDatePicker
                  debitDate={hubOrderDetail?.debitDate}
                  reverseDate={hubOrderDetail?.reverseDate}
                />
                {!isDeducted && (
                  <>
                    <div className={styles['col-span-1']}>出貨單號：</div>
                    <div
                      className={styles['col-span-1']}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <Select
                        clearable
                        fullWidth
                        onChange={(value) => {
                          setSourceHubDeliveryOrderId(value.id);
                          resetForm();
                        }}
                      >
                        {hubOrderDetail?.sourceDeliveryOrderIdOptions?.map(
                          (item) => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          )
                        )}
                      </Select>
                    </div>
                  </>
                )}
              </div>
            </ModalBody>
            <ModalBody>
              <div className={styles.wrapper}>
                <Table
                  scroll={{ y: 250 }}
                  columns={targetLineColumns}
                  dataSource={hubOrderDetail?.details || []}
                />
              </div>
              <br />
              {!isDeducted ? (
                <div className={styles.wrapper}>
                  <Table
                    scroll={{ y: 250 }}
                    columns={optionsColumns}
                    dataSource={orderDetailRows}
                  />
                </div>
              ) : (
                <div className={styles.wrapper}>
                  <Table
                    scroll={{ y: 250 }}
                    columns={isDeductedTableColumns}
                    dataSource={hubOrderDetail?.warehouseOptions || []}
                  />
                </div>
              )}
            </ModalBody>
            {checkJWTRoles([150]) &&
              !hubOrderDetail?.reverseDate &&
              !hubOrderDetail?.debitDate && (
                <ModalActions
                  cancelText="取消"
                  confirmText="確認扣帳"
                  confirmButtonProps={{ type: 'submit' }}
                  cancelButtonProps={{ type: 'button' }}
                  onCancel={() => {
                    setDeferHubDeliveryId(null);
                  }}
                  onConfirm={() => {
                    submitReceivingQuantity();
                  }}
                ></ModalActions>
              )}
            {checkJWTRoles([150]) &&
              !hubOrderDetail?.reverseDate &&
              hubOrderDetail?.debitDate &&
              !isDeducted && (
                <Button
                  variant="contained"
                  danger
                  style={{
                    marginTop: '16px',
                    marginLeft: '32px',
                    marginBottom: '16px',
                  }}
                  onClick={() => {
                    if (hubOrderDetail?.reverseDate) {
                      setDeferHubDeliveryId(null);
                    }
                  }}
                >
                  迴轉
                </Button>
              )}
          </FormFieldsWrapper>
        </FormProvider>
      </Modal>
    </>
  );
}
