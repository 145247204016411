import { Drawing, createDrawing, deleteDrawing, environment, useDrawings, useDrawingsQueryDDL, useModalController } from "@solar/data";
import { AutoComplete, Button, Descriptions, Form, FormProps, Input, InputNumber, Modal, Radio, Select, Upload, UploadProps, message } from "antd";
import styles from './edit-drawing-form.module.scss';
import { ReactNode, useEffect, useRef, useState } from "react";
import { Typography } from "@mezzanine-ui/react";
import { UploadOutlined } from "@ant-design/icons";
import { PDFPreviewer } from "@solar/templates";

type DrawingFile = {
  fileType?: Drawing['fileType'];
  fileLink?: Drawing['fileLink'];
  fileName?: Drawing['fileName'];
  s3ObjectKey?: Drawing['s3ObjectKey'];
};

type EditDrawingFormProps = FormProps & {
  title?: ReactNode;
  extraContent?: ReactNode;
  previewLink?: string;
  currentDrawing?: Drawing;
  parentDrawing?: Drawing;
  childDrawings?: Drawing[];
  parentDrawingCode: string | null;
  currentFile: DrawingFile | null;
};

export function useEditDrawingForm() {
  const [form] = Form.useForm();

  const file = Form.useWatch('file', form);

  const uploading = file?.[0]?.status === 'uploading';

  return {
    form,
    uploading,
  };
}

export function EditDrawingForm({
  id,
  form,
  title,
  previewLink,
  currentDrawing,
  currentFile,
  extraContent,
  parentDrawing,
  childDrawings,
  parentDrawingCode,
  onFinish,
}: EditDrawingFormProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [createdChildNewDrawingCodes, setCreatedChildNewDrawingCodes] = useState<any[]>([]);
  const { drawings, isLoading } = useDrawings({ params: { size: 0 } });
  const [childDrawingForm] = Form.useForm();
  const isChildForm = id === 'childDrawingForm';

  const {
    open,
    openModal,
    closeModal,
  } = useModalController();

  const {
    typeCodes,
    compositeCodes,
    shapeCodes,
    sizeCodes,
    drillLayeringCodes,
    slottingCodes,
    backplateSourceCodes,
    completedStatusCodes,
  } = useDrawingsQueryDDL();

  const compositeCode = Form.useWatch('compositeCode', form);
  const shapeCode = Form.useWatch('shapeCode', form);
  const sizeLength = Form.useWatch('sizeLength', form);
  const isSandblasting = Form.useWatch('isSandblasting', form);
  const sizeWidth = Form.useWatch('sizeWidth', form);
  const sizeHeight = Form.useWatch('sizeHeight', form);
  const isDrilling = Form.useWatch('isDrilling', form);
  const isLayering = Form.useWatch('isLayering', form);
  const isSurfaceSlotting = Form.useWatch('isSurfaceSlotting', form);
  const isSideSlotting = Form.useWatch('isSideSlotting', form);
  const parentNewDrawingCode = Form.useWatch('parentNewDrawingCode', form);
  const childNewDrawingCodes = Form.useWatch('childNewDrawingCodes', form);

  const childDrawingFile = Form.useWatch('file', childDrawingForm);

  const drawingOptions = drawings?.reduce((options: { label: string; value: string; }[], drawing) => {
    if (drawing?.newDrawingCode !== currentDrawing?.newDrawingCode &&
      drawing?.newDrawingCode !== parentNewDrawingCode &&
      !(childNewDrawingCodes ?? []).includes(drawing?.newDrawingCode)) {
      options.push({
        label: drawing?.newDrawingCode,
        value: drawing?.newDrawingCode,
      });
    }
    return options;
  }, []);

  const getSizeCode = (mm: number) =>
    sizeCodes.data.find((item) => {
      const greaterThanLowerBound = !isNaN(+item.lowerBound) ? (+item.lowerBound) <= mm : false;
      const LessThanUpperBound = item?.upperBound ? (+item.upperBound) > mm : true;
      return greaterThanLowerBound && LessThanUpperBound;
    });

  const getDrillLayeringCode = (drilling: boolean, layering: boolean) =>
    drillLayeringCodes.data.find((item) =>
      item.isDrilling === drilling && item.isLayering === layering
    );

  const getSlottingCode = (surfaceSlotting: boolean, sideSlotting: boolean) => 
    slottingCodes.data.find((item) =>
      item.isSurfaceSlotting === surfaceSlotting && item.isSideSlotting === sideSlotting
    );

  const sizeLengthCode = sizeLength ? sizeCodes?.getSizeCodeOneBySizeLength(sizeLength) : null;
  const sandblastingCode = isSandblasting ? 'S' : 'Z';
  const sizeWidthCode = sizeWidth ? getSizeCode(sizeWidth) : null;
  const sizeHeightCode = sizeHeight ? getSizeCode(sizeHeight) : null;
  const drillLayeringCode = getDrillLayeringCode(isDrilling, isLayering);
  const slottingCode = getSlottingCode(isSurfaceSlotting, isSideSlotting);
  const newDrawingCode = `${compositeCode ?? '_'} ${shapeCode ?? '_'} ${sizeLengthCode ?? '_ _ _'} ${sandblastingCode ?? '_'} ${drillLayeringCode?.code ?? '_'} ${slottingCode?.code ?? '_'} _ _ _`;

  const RequiredMark = () => <span style={{ color: 'red', verticalAlign: 'super' }}>*</span>;

  useEffect(() => {
    if (ref?.current) {
      ref?.current?.parentElement?.parentElement?.setAttribute('colspan', '8');
    }
  }, [ref]);

  useEffect(() => {
    if (currentDrawing) {
      form?.setFieldsValue(currentDrawing);
    }
  }, [currentDrawing, form]);

  useEffect(() => {
    if (currentFile) {
      form?.setFieldValue('file', [{
        name: currentFile?.fileName,
        status: 'done',
        percent: 100,
        response: currentFile,
      }]);
    }
  }, [currentFile, form]);

  useEffect(() => {
    if (parentDrawing) {
      form?.setFieldValue('parentNewDrawingCode', parentDrawing?.newDrawingCode);
    }
  }, [parentDrawing, form]);

  useEffect(() => {
    if (childDrawings && childDrawings?.length > 0) {
      form?.setFieldValue('childNewDrawingCodes', childDrawings?.map((drawing) => drawing?.newDrawingCode)?.filter(Boolean));
    }
  }, [childDrawings, form]);

  const handleFormValuesChange: FormProps['onValuesChange'] = (changedValues, values) => {
    if (values?.compositeCode !== 'A') {
      form?.setFieldValue('compositeNumber', null);
    }
  };

  const handleCustomRequest: UploadProps['customRequest'] = ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('drawingFile', file);

    fetch(`${environment.API_HOST}/drawing/api/v1/awsS3/uploadFile`, {
      method: 'POST',
      body: formData,
      headers: { apikey: environment.API_KEY },
    })
      .then((response) => response.json())
      .then((data) => {
        onSuccess?.({
          fileLink: data?.data?.fileLink,
          fileName: data?.data?.fileName,
          fileType: data?.data?.fileType,
          s3ObjectKey: data?.data?.s3ObjectKey,
        });
      })
      .catch((error) => onError?.(error));
  };

  return (
    <div>
      <Form
        id={id}
        form={form}
        className={styles.page_content}
        onValuesChange={handleFormValuesChange}
        onFinish={(values) => {
          const { file, sizeLength, sizeWidth, sizeHeight, ...data } = values;
          onFinish?.({
            ...data,
            ...file?.[0]?.response,
            parentDrawingCode,
            parentNewDrawingCode,
            sizeLength: sizeLength?.toString(),
            sizeWidth: sizeWidth?.toString(),
            sizeHeight: sizeHeight?.toString(),
          });
        }}
      >
        <Descriptions
          bordered
          column={3}
          size="small"
          title={title && (<Typography variant="h1">{title}</Typography>)}
          style={{ borderRadius: 0 }}
          labelStyle={{ width: 140 }}
          extra={extraContent}>
          <Descriptions.Item label="檢查人">
            <Form.Item name="checker">
              <Input />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={<>完成別<RequiredMark /></>}>
            <Form.Item name="completedStatusCode" rules={[{ required: true, message: '必填' }]}>
              <Select options={completedStatusCodes?.options ?? []} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="類型">
            <Form.Item name="typeCode">
              <Select options={typeCodes?.options ?? []} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={<>修正後舊圖號<RequiredMark /></>}>
            <Form.Item name="oldDrawingFixed"  rules={[{ required: true, message: '必填' }]}>
              <Input />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="舊成品圖號">
            {parentDrawingCode}
          </Descriptions.Item>
          <Descriptions.Item label="背板來源">
            <Form.Item name="backplateSourceCode">
              <Select allowClear options={backplateSourceCodes?.options ?? []} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="新圖號">{newDrawingCode}</Descriptions.Item>
          <Descriptions.Item span={2} labelStyle={{ display: 'none' }}>
            <div ref={ref}>
              <Descriptions bordered column={3} size="small" style={{ borderRadius: 0 }} labelStyle={{ width: 140 }}>
                <Descriptions.Item label={<>組合碼<RequiredMark /></>}>
                  <Form.Item name="compositeCode" rules={[{ required: true, message: '必填' }]}>
                    <Select style={{ width: 150 }} options={compositeCodes?.options ?? []} />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="拼數" span={2}>
                  <Form.Item name="compositeNumber">
                    <InputNumber style={{ width: 150 }} disabled={compositeCode !== 'A'} />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={<>形狀碼<RequiredMark /></>} span={3}>
                  <Form.Item name="shapeCode" rules={[{ required: true, message: '必填' }]}>
                    <Select style={{ width: 150 }} options={shapeCodes?.options ?? []} />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="尺寸一碼" span={3}>
                  {sizeLengthCode ?? ''}
                </Descriptions.Item>
                <Descriptions.Item label="噴砂碼">
                  {isSandblasting ? 'S' : 'Z'}
                </Descriptions.Item>
                <Descriptions.Item  label={<>噴砂<RequiredMark /></>} span={2}>
                  <Form.Item name="isSandblasting" initialValue={false}>
                    <Radio.Group optionType="button" style={{ width: 100 }}>
                      <Radio value={true}>有</Radio>
                      <Radio value={false}>無</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="鑽孔分階碼">
                  {drillLayeringCode?.code || ''}
                </Descriptions.Item>
                <Descriptions.Item label={<>鑽孔<RequiredMark /></>}>
                  <Form.Item name="isDrilling" initialValue={true} rules={[{ required: true, message: '必填' }]}>
                    <Radio.Group optionType="button" style={{ width: 100 }}>
                      <Radio value={true}>有</Radio>
                      <Radio value={false}>無</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={<>分階<RequiredMark /></>}>
                  <Form.Item name="isLayering" initialValue={true} rules={[{ required: true, message: '必填' }]}>
                    <Radio.Group optionType="button" style={{ width: 100 }}>
                      <Radio value={true}>有</Radio>
                      <Radio value={false}>無</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="開槽碼">
                  {slottingCode?.code || ''}
                </Descriptions.Item>
                <Descriptions.Item label={<>表面開槽<RequiredMark /></>}>
                  <Form.Item name="isSurfaceSlotting" initialValue={true} rules={[{ required: true, message: '必填' }]}>
                    <Radio.Group optionType="button" style={{ width: 100 }}>
                      <Radio value={true}>有</Radio>
                      <Radio value={false}>無</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={<>側面開槽<RequiredMark /></>}>
                  <Form.Item name="isSideSlotting" initialValue={true} rules={[{ required: true, message: '必填' }]}>
                    <Radio.Group optionType="button" style={{ width: 100 }}>
                      <Radio value={true}>有</Radio>
                      <Radio value={false}>無</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="流水三碼" span={3}>{currentDrawing?.serialString ?? ''}</Descriptions.Item>
              </Descriptions>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="尺寸一碼">
            {sizeLengthCode ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label="尺寸二碼">
            {sizeWidthCode?.code ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label="尺寸三碼">
            {sizeHeightCode?.code ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label={<>長或外徑(mm)<RequiredMark /></>}>
            <Form.Item name="sizeLength" rules={[{ required: true, message: '必填' }]}>
              <InputNumber style={{ width: 150 }} min={0} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="寬或內徑(mm)">
            <Form.Item name="sizeWidth">
              <InputNumber style={{ width: 150 }} min={0} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="高(mm)">
            <Form.Item name="sizeHeight">
              <InputNumber style={{ width: 150 }} min={0} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="倒角">
            <Form.Item name="isChamfer" initialValue={false}>
              <Radio.Group optionType="button" style={{ width: 100 }}>
                <Radio value={true}>有</Radio>
                <Radio value={false}>無</Radio>
              </Radio.Group>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="刻字">
            <Form.Item name="isLettering" initialValue={false}>
              <Radio.Group optionType="button" style={{ width: 100 }}>
                <Radio value={true}>有</Radio>
                <Radio value={false}>無</Radio>
              </Radio.Group>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="攻牙">
            <Form.Item name="isTapping" initialValue={false}>
              <Radio.Group optionType="button" style={{ width: 100 }}>
                <Radio value={true}>有</Radio>
                <Radio value={false}>無</Radio>
              </Radio.Group>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item span={3} label="備註">
            <Form.Item name="remark">
              <Input.TextArea />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item span={3} label="母圖">
            <Form.Item name="parentNewDrawingCode">
              <AutoComplete disabled={isChildForm} filterOption options={drawingOptions ?? []}  />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item span={3} label="子圖">
            <div style={{ display: 'grid', gridTemplateColumns: 'auto 100px', gap: 'var(--mzn-spacing-2)' }}>
              <Form.Item name="childNewDrawingCodes">
                <Select
                  disabled={isChildForm}
                  mode="multiple"
                  options={drawingOptions ?? []}
                  loading={isLoading}
                  onDeselect={async (deselectedCode) => {
                    const createdChildSet = new Set(createdChildNewDrawingCodes);
                    if (createdChildSet.has(deselectedCode)) {
                      try {
                        const response = await deleteDrawing({ newDrawingCode: deselectedCode });
                        console.log(response);
                      } catch (error) {
                        message.error(`子圖 ${deselectedCode} 刪除失敗`);
                      }
                    }
                  }} />
              </Form.Item>
              <Button
                disabled={isChildForm}
                onClick={() => openModal()}
              >
                新增子圖
              </Button>
            </div>
          </Descriptions.Item>
          <Descriptions.Item span={3} label={<>藍圖</>}>
            <Form.Item
              name="file"
              valuePropName="fileList"
              getValueFromEvent={(e) => Array.isArray(e) ? e : e?.fileList}>
              <Upload
                maxCount={1}
                multiple={false}
                accept=".pdf,.jpg,.jpeg,.tif,.tiff"
                customRequest={handleCustomRequest}
                itemRender={(originNode, file) => (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div><Button type="link" style={{ pointerEvents: 'none' }}>{file?.response?.fileName}</Button></div>
                    <PDFPreviewer fileLink={previewLink} />
                  </div>
                )}
                onChange={(info) => {
                  switch (info.file.status) {
                    case 'done':
                      message.success(`${info.file.name} 上傳成功`);
                      break;
                    case 'error':
                      message.success(`${info.file.name} 上傳失敗`);
                      break;
                    default:
                      break;
                  }
                }}
              >
                <Button icon={<UploadOutlined />}>上傳</Button>
              </Upload>
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
      </Form>
      <Modal
        width={1200}
        open={open}
        title="新增子圖號"
        cancelText="取消"
        onCancel={() => {
          closeModal();
          childDrawingForm.setFieldsValue({});
        }}
        okText="建立"
        okButtonProps={{
          htmlType: 'submit',
          form: 'childDrawingForm',
          loading: childDrawingFile?.[0]?.status === 'uploading',
        }}>
        <EditDrawingForm
          id="childDrawingForm"
          form={childDrawingForm}
          parentDrawingCode={parentDrawingCode}
          previewLink={previewLink}
          currentFile={currentFile}
          onFinish={async (payload) => {
            try {
              const response = await createDrawing({ drawings: [payload] });
              const childNewDrawingCode = response?.data?.[0]?.newDrawingCode;
              if (childNewDrawingCode) {
                setCreatedChildNewDrawingCodes((prevCodes) => [...prevCodes, childNewDrawingCode]);
                form?.setFieldValue('childNewDrawingCodes', [...(childNewDrawingCodes ?? []), childNewDrawingCode]);
                message.success('子圖建立成功');
                closeModal();
              } else throw new Error();
            } catch (error) {
              message.error('子圖建立失敗');
            }
          }}
        />
      </Modal>
    </div>
  )
}