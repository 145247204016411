import { RefObject, useCallback, useEffect } from 'react';
import { useLocationSelectorContext } from '../LocationSelectorContext/useLocationSelectorContext';

type useLocationSVGClickOptions = {
  onClick?: (event: MouseEvent) => void;
};

export function useLocationSVGClick(
  svgWrapperRef: RefObject<HTMLDivElement>,
  options?: useLocationSVGClickOptions
) {
  const { selectedIds, activeId, setActiveId } = useLocationSelectorContext();

  const handleClick = useCallback(
    (event: MouseEvent) => {
      const element = event.target as SVGElement;
      const id = element.getAttribute('id');
      setActiveId?.(id ?? undefined);
      options?.onClick?.(event);
    },
    [options, setActiveId]
  );

  useEffect(() => {
    const svgWrapper = svgWrapperRef?.current;
    const svgElement = svgWrapper?.querySelector('svg');
    const polygonList = svgElement?.querySelectorAll('polygon');
    const polygonElements = Array.from(polygonList ?? []);

    polygonElements?.forEach((element) => {
      element.addEventListener('click', handleClick);
    });

    return () => {
      polygonElements?.forEach((element) => {
        element.removeEventListener('click', handleClick);
      });
    };
  }, [handleClick, svgWrapperRef, svgWrapperRef.current?.innerHTML]);

  useEffect(() => {
    const svgWrapper = svgWrapperRef?.current;
    const svgElement = svgWrapper?.querySelector('svg');
    const polygonList = svgElement?.querySelectorAll('polygon');
    const polygonElements = Array.from(polygonList ?? []);

    polygonElements.forEach((element) => {
      const elementId = element.getAttribute('id');
      // const level = element.getAttribute('level') as LocationLevel;
      const value = selectedIds?.shelfId
        ? activeId === elementId || selectedIds?.stackId === elementId
        : activeId === elementId;
      element?.setAttribute('active', value.toString());
    });
  }, [
    activeId,
    selectedIds?.shelfId,
    selectedIds?.stackId,
    svgWrapperRef,
    svgWrapperRef.current?.innerHTML,
  ]);
}
