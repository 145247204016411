import type { UseFormReturn } from 'react-hook-form';
import type { ShipmentBoxMergeFormValues } from './form-definition';
import { ShipmentBoxMergeFieldName as FormFieldName } from './form-definition';
import { request, API_NAMESPACE } from '../../request';
import * as yup from 'yup';
import useSWR from 'swr';

type HookProps = {
  deliveryOrderId: string;
  formMethods: UseFormReturn<ShipmentBoxMergeFormValues>;
};

export type PackageListsData = {
  boxNum: number;
  deliveryOrderLine: string;
  packingListId: number;
  snum: number;
  materialId: string;
  materialName: string;
  quantity: number;
  productUnit: string;
  packagingMaterialId: string;
  packagingName: string;
};

export function useShipmentPackageListsGet({
  deliveryOrderId,
  formMethods,
}: HookProps) {
  const { data, mutate, isValidating } = useSWR<PackageListsData[]>(
    validateSWRKey(deliveryOrderId),
    async (endpoint, requestOptions) => {
      return request(endpoint, requestOptions).then((dataSource) => {
        if (dataSource) {
          formMethods.reset({
            [FormFieldName.DELIVERY_ORDER_ID]: deliveryOrderId,
            [FormFieldName.BOX_MERGE_ITEMS]: dataSource.map(
              (data: PackageListsData) => ({
                [FormFieldName.BOX_NUMBER]: data.boxNum,
                [FormFieldName.PACKAGE_LIST_ID]: data.packingListId,
              })
            ),
          });
        }
        return dataSource;
      });
    },
    {
      revalidateOnFocus: false,
    }
  );

  return {
    packageLists: data ?? [],
    refetchPackageLists: mutate,
    isLoading: isValidating,
  };
}

function validateSWRKey(deliveryOrderId: string) {
  try {
    const safeDeliveryOrderId = yup
      .number()
      .integer()
      .moreThan(0)
      .validateSync(deliveryOrderId);
    return [
      `/deliveryOrders/get/${safeDeliveryOrderId}/packingLists`,
      {
        namespace: API_NAMESPACE.HR,
      },
    ];
  } catch (error) {
    return false;
  }
}
