import { Tab, TabPane, Tabs, Typography } from '@mezzanine-ui/react';
import { useQueryStringWithAutoRouting } from '@solar/templates';
import { useEffect, useState } from 'react';

import { createSearchParams, useSearchParams } from 'react-router-dom';
import { PageLayout } from '../../Material/PageLayout/PageLayout';

import { tabIds, tabsOptions } from './const';
import { FilterContext, FilterContextType } from './FilterContext';

export const WorkOrderPage = () => {
  const [workOrderId, setWorkOrderId] =
    useState<FilterContextType['workOrderId']>(null);

  const [materialRequestOrderId, setMaterialRequestOrderId] =
    useState<FilterContextType['workOrderId']>(null);

  const { activeTab, setSearchParams } = useQueryStringWithAutoRouting({
    enableAutoRouting: true,
    tabIds: tabIds,
  });

  const [searchParams] = useSearchParams();
  const defaultWorkOrderId = searchParams.get('workOrderId');
  const defaultMaterialRequestOrderId = searchParams.get(
    'materialRequestOrderId'
  );

  useEffect(() => {
    if (defaultWorkOrderId) {
      setWorkOrderId({ id: defaultWorkOrderId, name: defaultWorkOrderId });
    }
  }, [defaultWorkOrderId]);

  useEffect(() => {
    if (defaultMaterialRequestOrderId) {
      setMaterialRequestOrderId({
        id: defaultMaterialRequestOrderId,
        name: defaultMaterialRequestOrderId,
      });
    }
  }, [defaultMaterialRequestOrderId]);

  return (
    <FilterContext.Provider
      value={{
        workOrderId,
        setWorkOrderId,
        materialRequestOrderId,
        setMaterialRequestOrderId,
      }}
    >
      <PageLayout>
        <Typography variant="h1">工單/發料單列印</Typography>
        <Tabs
          activeKey={activeTab || ''}
          onChange={(tabId) => {
            setSearchParams(createSearchParams({ tab: String(tabId) }));
          }}
        >
          {tabsOptions.map((option) => (
            <TabPane key={option.id} tab={<Tab>{option.name}</Tab>}>
              <option.panel />
            </TabPane>
          ))}
        </Tabs>
      </PageLayout>
    </FilterContext.Provider>
  );
};
