import { TableColumn } from '@mezzanine-ui/core/table';
import {
  Modal, ModalBody,
  Table,
  Typography
} from '@mezzanine-ui/react';
import { useTargetModal } from '@solar/templates';
import { useCallback, useMemo } from 'react';
import { BATCH_SERIAL } from './constants';
import { BatchSerialProps } from './typings';


const mockDataSource = [
  {
    id: '1',
    batchSerialId: '38ASI001001',
    storageStatus: '銷售訂單',
    weight: 42800,
    unit: 'G',
    storageTime: '2023/08/27 15:22',
    staff: '方自仁',
    notes: '重量太重',
  },
  {
    id: '2',
    batchSerialId: '38ASI001002',
    storageStatus: '未限制',
    weight: 42800,
    unit: 'G',
    storageTime: '2023/08/27 18:22',
    staff: '方自仁',
    notes: '重量太重',
  },
  {
    id: '3',
    batchSerialId: '38ASI001001',
    storageStatus: '未限制',
    weight: 42800,
    unit: 'G',
    storageTime: '2023/08/27 19:22',
    staff: '方自仁',
    notes: '重量太重',
  },
] satisfies BatchSerialProps[];



export function BatchSerialModal() {
  const modalController = useTargetModal(BATCH_SERIAL);
  const { open, closeModal, data } = modalController;

  const handleModalClose = useCallback(() => {
    closeModal();
  }, []);

  const columns = useMemo<
    TableColumn<BatchSerialProps>[]
  >(() => {
    return [{ title: '批號流水序', dataIndex: 'batchSerialId' },{ title: '庫存狀態', dataIndex: 'storageStatus' },{ title: '實際重量', dataIndex: 'weight' }, { title: '單位', dataIndex: 'unit' },{ title: '細節入庫時間', dataIndex: 'storageTime' },{ title: '入庫人員', dataIndex: 'staff' },{ title: '備註', dataIndex: 'notes' }];
  }, []);

  return (
    <Modal style={{width: '50vw'}} open={open} onClose={handleModalClose}>
      <br />
      <br />
      <ModalBody>
        <Typography variant="h2">批號流水序</Typography>
        <br />
        <Table dataSource={mockDataSource} columns={columns} />
      </ModalBody>
    </Modal>
  );
}
