import { useMemo } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { Button } from 'antd';

interface DataType {
  materialRequestOrderId: string;
  materialId: string;
  materialSapBatchId: string;
  materialBatchId: string;
  availableMaterialStockUnitQuantity: string;
  materialStockUnit: string;
}

export const useMaterialLabelColumn = () => {
  return useMemo<ColumnsType<DataType>>(
    () => [
      {
        title: '操作',
        render: () => {
          return <Button>列印標籤</Button>;
        },
      },
      {
        title: '發料單號',
        dataIndex: 'materialRequestOrderId',
      },
      {
        title: '目標料號',
        dataIndex: 'materialId',
      },
      {
        title: '目標批號',
        render: (_, { materialSapBatchId, materialBatchId }) =>
          `${materialSapBatchId ?? ''}-${materialBatchId}`,
      },
      {
        title: '管理數量單位',
        dataIndex: 'availableMaterialStockUnitQuantity',
      },
      {
        title: '單位',
        dataIndex: 'materialStockUnit',
      },
    ],
    []
  );
};
