// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  API_HOST:
    process.env['NX_API_HOST'] || 'https://api.dev-edges.solartech.cloud/v1',
  API_KEY: process.env['NX_API_KEY'] || 'fdasfj3490ewjroq8',
  APP_ID: '02b52697-f7f4-4079-b7b4-279ca999b0d0',
};
