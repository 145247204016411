import {
  Loading,
  Message,
  Modal,
  ModalBody,
  ModalHeader,
} from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { ProductionFormResponse, getProductionFormAnswers } from '@solar/data';
import { ProductionForms } from '@solar/templates';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

interface TrackingTreeNodeModalProps {
  open: boolean;
  data: {
    materialId: string;
    materialSapBatchId: string;
    materialSubBatchId: string;
  } | null;
  onClose: () => void;
}

export function useTrackingTreeNodeModal() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState<{
    materialId: string;
    materialSapBatchId: string;
    materialSubBatchId: string;
  } | null>(null);

  const onOpen = useCallback((data: TrackingTreeNodeModalProps['data']) => {
    setModalData(data);
    setModalOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setModalData(null);
    setModalOpen(false);
  }, []);

  return { modalOpen, modalData, onOpen, onClose };
}

export function TrackingTreeNodeModal({
  open,
  data,
  onClose,
}: TrackingTreeNodeModalProps) {
  const [searching, setSearching] = useState(false);
  const [forms, setForms] = useState<ProductionFormResponse[]>([]);
  const methods = useForm();

  const _onClose = useCallback(() => {
    setForms([]);
    methods.reset({});
    onClose();
  }, [methods, onClose]);

  const onSearch = useCallback(
    async ({
      materialId,
      materialSapBatchId,
      materialSubBatchId,
    }: {
      materialId: string;
      materialSapBatchId: string;
      materialSubBatchId: string;
    }) => {
      try {
        setSearching(true);

        const productionFormAnsRes = await getProductionFormAnswers({
          offset: 0,
          materialId,
          materialSapBatchId,
          materialSubBatchId,
          withLims: false,
          selectDefaultAndPicked: false,
          templateTypes: ['PRODUCT', 'EQUIPMENT'],
          latestOnly: true,
        });

        const formAnswers = productionFormAnsRes?.records ?? [];

        if ((formAnswers?.length ?? 0) === 0) {
          Message.error('尚未有生產題目答案');
          _onClose();
          return;
        }

        setForms(formAnswers?.map((fa) => fa?.form) ?? []);

        formAnswers.forEach((formAnswer) => {
          methods.setValue(
            `forms.${formAnswer?.form?.id}`,
            formAnswer?.modifiedAnswer ?? formAnswer?.answer ?? null
          );
        });

        Message.success('題目讀取成功');
      } catch (error) {
        console.error(error);
        Message.error('題目讀取失敗');
      } finally {
        setSearching(false);
      }
    },
    [_onClose, methods]
  );

  useEffect(() => {
    if (open && data) {
      onSearch(data);
    }
  }, [data, onSearch, open]);

  return (
    <Modal open={open} onClose={_onClose}>
      <ModalHeader>題目</ModalHeader>
      <ModalBody>
        <Loading loading={searching}>
          <FormFieldsWrapper methods={methods}>
            <ProductionForms
              forms={forms}
              disableAllFields
              registerName="forms"
            />
          </FormFieldsWrapper>
        </Loading>
      </ModalBody>
    </Modal>
  );
}
