import useSWR from 'swr';
import { API_NAMESPACE, fetchRequest } from '../request';
import { useState } from 'react';
import {
  GetOOCTicketsParams,
  OOCTicket,
  UpdateOOCTicketPayload,
} from './ooc.type';
import { PageInfo } from '../pagination';

const namespace = API_NAMESPACE.QC;

export function useGetOOCTickets(defaultParams?: GetOOCTicketsParams) {
  const [params, setParams] = useState<GetOOCTicketsParams | undefined>(
    defaultParams
  );
  const { data, mutate, isValidating, error } = useSWR<{
    records: OOCTicket[];
    pageInfo: PageInfo;
  }>([
    '/ooc/tickets',
    {
      namespace,
      params,
    },
  ]);

  return {
    oocTickets: data?.records ?? [],
    pageInfo: data?.pageInfo,
    isLoading: !data && !error && !isValidating,
    refetchGetOOCTickets: setParams,
    mutateGetOOCTickets: mutate,
  };
}

export async function updateOOCTicket(
  ticketId: string,
  payload: UpdateOOCTicketPayload
) {
  const response = await fetchRequest({
    method: 'PUT',
    namespace,
    endpoint: `/ooc/tickets/${ticketId}`,
    body: JSON.stringify(payload),
  });

  return await response.json();
}
