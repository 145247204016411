import {
  BooleanStrings,
  CorrespondentTypes,
  YesNoOptions,
} from 'apps/frontend/src/app/Transportation/RequirementsManagementPage/RequirementsManagement.config';
import { InputField, SelectField } from '@mezzanine-ui/react-hook-form';
import { cx } from '@mezzanine-ui/react';
import styles from './RecycleType-Selector.module.scss';
import { useFormContext } from 'react-hook-form';

export function RecycleTypeSelector({ className }: { className?: string }) {
  const { watch } = useFormContext();
  const isRecycle = watch('isRecycle')?.id === BooleanStrings.True;
  const isCustomer =
    watch('correspondentType')?.id === CorrespondentTypes.Customer;

  return (
    <div className={cx(className, styles.container)}>
      <SelectField
        label="是否為申報回收作業"
        required
        registerName="isRecycle"
        options={YesNoOptions}
      />
      <InputField
        label="環保聯單號碼"
        registerName="environmentalFormId"
        required={isRecycle && !isCustomer}
        disabled={!isRecycle}
      />
    </div>
  );
}
