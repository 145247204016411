import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';
import { API_NAMESPACE, MaterialResponseData, PageInfo } from '@solar/data';
import { useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import useSWR from 'swr';
import styles from '../production-form-creator.module.scss';
import { Typography } from '@mezzanine-ui/react';
import { ProductionFormCreatorFormFields } from '../typings';

export function useAutoCompleteMaterialField({
  methods,
  enabled,
}: {
  methods: UseFormReturn<ProductionFormCreatorFormFields>;
  enabled?: boolean;
}): JSX.Element {
  const [searchTerm, setSearchTerm] = useState('');

  const { data } = useSWR<{
    materials: MaterialResponseData[];
    pageInfo: PageInfo;
  }>(
    enabled
      ? [
          `/materials?${
            searchTerm ? `searchTerm=${searchTerm}&searchType=ID` : ''
          }`,
          {
            namespace: API_NAMESPACE.MATERIALS,
          },
        ]
      : undefined
  );

  const materials = useMemo(
    () =>
      (data?.materials ?? []).map((material) => ({
        id: material.id,
        name: material.id,
      })),
    [data]
  );

  const selected = methods.watch('materialId');

  const { data: singleData } = useSWR<{
    materials: MaterialResponseData[];
    pageInfo: PageInfo;
  }>(
    selected
      ? [
          `/materials?searchTerm=${selected.id}&searchType=ID`,
          {
            namespace: API_NAMESPACE.MATERIALS,
          },
        ]
      : undefined
  );

  return (
    <div className={styles.fieldGroup}>
      <AutoCompleteField
        options={materials}
        label="物料"
        placeholder="請輸入物料編號"
        onInput={({ target }) => {
          const sanitized =
            (target as HTMLInputElement).value?.trim().toUpperCase() ?? '';

          setSearchTerm(sanitized);

          (target as HTMLInputElement).value = sanitized;
        }}
        registerName={'materialId'}
      />
      {singleData?.materials?.[0]?.description ? (
        <Typography variant="body2">
          {singleData.materials[0].description}
        </Typography>
      ) : selected ? (
        <Typography variant="body2">-</Typography>
      ) : null}
    </div>
  );
}
