import { Message, SelectValue } from '@mezzanine-ui/react';
import useSWR from 'swr';
import { API_NAMESPACE, request } from '../request';
import { useState } from 'react';

const namespace = API_NAMESPACE.MISC;

export type SPAPISearch = {
  showId?: string; // 敲價單號
  customerName?: string; // 客戶
  salesName?: string; // 業務員
  reportState?: string; // 敲價單狀態
  reportDateStart?: string; // 敲價單日期-start
  reportDateEnd?: string; // 敲價單日期-end
  dealDateStart?: string; // 成交日期-start
  dealDateEnd?: string; // 成交日期-end
};

export type SPFormSearch = {
  showId: string;
  customerName: string;
  salesName: string;
  status: SelectValue;
  dateRange: [string, string];
  dealDateRange?: [string, string]; // 可能為 undefined 的成交日期
};

export type SPPageInfo = {
  current: number;
  pageSize: number;
  total: number;
};

export type SPReportState = {
  canCheck: boolean;
  canDelete: boolean;
  canEdit: boolean;
  canMap: boolean;
  canBack: boolean;
  canMatch: boolean;
  code: string;
  name: string;
};

export const useStrikePriceSearch = () => {
  const [requestItems, setRequestItems] = useState<SPAPISearch>({});
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [serachResult, setSerachResult] = useState<any[]>([]);
  const [searchErrorMessage, setSearchErrorMessage] = useState<string>('');
  const [pageInfo, setPageInfo] = useState<SPPageInfo>({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const { data: reportPriceState } = useSWR<SPReportState[]>([
    '/selectItem/listReportPriceState',
    { namespace: API_NAMESPACE.MISC },
  ]);

  const autoCompleteOptionsState: SelectValue<string>[] = (
    reportPriceState || []
  ).map((item: { name: string; code: string }) => ({
    name: item.name,
    id: String(item.code),
  }));

  const doSearch = async (items: SPAPISearch, pagination: SPPageInfo) => {
    setSearchLoading(true);
    setSearchErrorMessage('');
    try {
      const requestBody = {
        ...items,
        pagination,
      };
      const response = await request('/reportPrice/search', {
        method: 'post',
        body: JSON.stringify(requestBody),
        responseParser: (res) => {
          //console.log(res);
          return res.json();
        },
        namespace,
      });
      if (Array.isArray(response.datas)) {
        setSerachResult(response.datas);
        setPageInfo(response.pagination);
      }
    } catch (e) {
      if (e instanceof Error) {
        console.log(e.message[0]);
        if (e.message[0] === '{') {
          const error = JSON.parse(e.message);
          console.log(error);
          setSearchErrorMessage(error.message);
        } else {
          setSearchErrorMessage('搜尋錯誤');
        }
      }
    }
    setSearchLoading(false);
  };

  const searchStrikePrice = async (
    items: SPAPISearch,
    pagination: SPPageInfo
  ) => {
    setRequestItems(items);
    doSearch(items, pagination);
  };

  const onPageChange = (page: number) => {
    const pagination = {
      ...pageInfo,
      current: page,
    };
    doSearch(requestItems, pagination);
  };

  const deleteStrikePrice = async (id: string) => {
    const requestBody = { id };
    try {
      await request('/reportPrice/delete', {
        method: 'post',
        body: JSON.stringify(requestBody),
        responseParser: (res) => {
          return res.json();
        },
        namespace,
      });
      doSearch(requestItems, pageInfo);
    } catch (e) {
      if (e instanceof Error) {
        // console.log(e.message[0]);
        if (e.message[0] === '{') {
          const error = JSON.parse(e.message);
          throw new Error(error.message);
        } else {
          throw new Error('刪除錯誤');
        }
      }
    }
  };

  const backStrikePrice = async (id: string) => {
    const spItem = serachResult.find((item) => item.id === id);
    const requestBody = { ...spItem, back: true };
    try {
      await request('/reportPrice/update', {
        method: 'post',
        body: JSON.stringify(requestBody),
        responseParser: (res) => {
          return res.json();
        },
        namespace,
      });
      doSearch(requestItems, pageInfo);
    } catch (e) {
      if (e instanceof Error) {
        // console.log(e.message[0]);
        if (e.message[0] === '{') {
          const error = JSON.parse(e.message);
          throw new Error(error.message);
        } else {
          throw new Error('退回錯誤');
        }
      }
    }
  };

  return {
    reportPriceState,
    autoCompleteOptionsState,
    searchStrikePrice,
    onPageChange,
    searchLoading,
    serachResult,
    searchErrorMessage,
    pageInfo,
    deleteStrikePrice,
    backStrikePrice,
  };
};

// Form search to API search converter
export function convertSPSearchFormToAPI(
  formSearch: SPFormSearch
): SPAPISearch {
  let reportState = undefined;
  if (
    formSearch.status?.id &&
    Number.isInteger(parseInt(formSearch.status?.id, 10))
  ) {
    reportState = formSearch.status?.id;
  }
  return {
    showId: formSearch.showId,
    customerName: formSearch.customerName,
    salesName: formSearch.salesName,
    reportState,
    reportDateStart: formSearch.dateRange?.[0],
    reportDateEnd: formSearch.dateRange?.[1],
    dealDateStart: formSearch.dealDateRange ? formSearch.dealDateRange[0] : '',
    dealDateEnd: formSearch.dealDateRange ? formSearch.dealDateRange[1] : '',
  };
}
