import { API_NAMESPACE, request } from '@solar/data';

const namespace = API_NAMESPACE.MATERIALS;

export type UpdateOutsourcePurchaseMaterialRequestOrderPayload = {
  expectedCompletedAt: string;
  items: Array<{
    materialId: string;
    materialSapBatchId: string;
    materialSubBatchId: string;
    loaderId: string;
    materialStockUnitQuantity: string;
  }>;
};

export const updateOutsourcePurchaseMaterialRequestOrder = ({
  payload,
  orderId,
}: {
  payload: UpdateOutsourcePurchaseMaterialRequestOrderPayload;
  orderId: string;
}): Promise<Response> => {
  return request(`/warehouses/outsource-purchase-material-request-orders/${orderId}`, {
    method: 'put',
    body: JSON.stringify(payload),
    responseParser: (res) => res,
    namespace,
  });
};
