export interface GetShippedOrdersParams {
  offset?: number;
  limit?: number;
  deliveryOrderId?: string;
  materialIds?: string;
  shippedBefore?: string;
  shippedAfter?: string;
}

export interface ShippedOrder {
  id: string;
  customerId: string;
  customerName: string;
  customerAddress: string;
  receiverId: string;
  receiverName: string;
  receiverAddress: string;
  employeeId: string;
  employee: Employee;
  type: string;
  orderType: OrderType;
  salesOrg: string;
  sn: string;
  conditions: string;
  materialMoveType: string;
  status: string;
  processType: string;
  conditionsNote: string;
  factoryId: string;
  factoryName: string;
  shipDate: string;
  debitDate: string;
  reverseDate: string;
  createdAt: string;
  deliveryOrderDetails: ShippedOrderDeliveryOrderDetail[];
}

export interface Employee {
  id: string;
  name: string;
}

export interface OrderType {
  id: string;
  name: string;
  description: string;
}

export interface ShippedOrderDeliveryOrderDetail {
  id: number;
  orderId: string;
  salesOrderId: string;
  salesOrderLine: string;
  orderDetailId: number;
  batchId: string;
  materialId: string;
  materialName: string;
  quantity: string;
  quantityReceived: string;
  quantityUnitCode: string;
  deliveryOrderLine: string;
  purchaseOrderId: string;
  purchaseOrderLine: string;
  customerMaterialId: string;
  customerMaterialNote: string;
  customerBatchSn: string;
  quantityConvertedUnitName: string;
  mainMaterialUnitName: string;
  elementCode: string;
  elementRatio: string;
  elementActualWeight: string;
  elementTheoreticalWeight: string;
  elementCode1: string;
  elementRatio1: string;
  elementActualWeight1: string;
  elementTheoreticalWeight1: string;
  elementCode2: string;
  elementRatio2: string;
  elementActualWeight2: string;
  elementTheoreticalWeight2: string;
  elementCode3: string;
  elementRatio3: string;
  elementActualWeight3: string;
  elementTheoreticalWeight3: string;
  elementWeightUnitName: string;
  createdAt: string;
  subBatchReferences: {
    materialId: string;
    materialSapBatchId: string;
    materialSubBatchId: string;
    deliveryOrderId: string;
    deliveryOrderLine: string;
  }[];
}
