import { Message, ModalActions } from '@mezzanine-ui/react';
import { InventoryItem } from '@solar/data';
import { useTargetModal } from '@solar/templates';
import { useCallback, useState } from 'react';
import { ModalLayout } from '../../ModalLayout/ModalLayout';
import { CancelBoundModalProps, Modals } from '../types';
import { cancelBoundSalesOrder } from '../utils';

const THIS_FORM = Modals.CANCEL_BIND;

export const CancelBound = ({ paginationCallBack }: CancelBoundModalProps) => {
  const [loading, setLoading] = useState(false);
  const modalController = useTargetModal<InventoryItem>(THIS_FORM);

  const closeModal = useCallback(() => {
    paginationCallBack({});
    modalController.closeModal();
  }, [modalController, paginationCallBack]);

  const onConfirm = useCallback(async () => {
    setLoading(true);
    const data = modalController.data;

    if (!data) return;

    try {
      const res = await cancelBoundSalesOrder({
        payload: {
          loaderId: data.loaderId,
          materialId: data.materialId,
          sapBatchId: data.materialSapBatchId,
          subBatchId: data.materialSubBatchId,
        },
      });

      if (res.ok) {
        closeModal();
        paginationCallBack({});
        Message.success('解除綁定成功');
      }
    } catch (err) {
      if (err instanceof Error) {
        Message.error(JSON.parse(err.message).message);
      } else {
        Message.error('解除綁定失敗');
      }
    } finally {
      setLoading(false);
    }
  }, [closeModal, modalController.data, paginationCallBack]);

  return (
    <ModalLayout
      loading={loading}
      modalHeader={<br />}
      // loading={methods.formState.isSubmitting}
      {...modalController}
      closeModal={closeModal}
      modalFooter={
        <ModalActions
          confirmText="確認"
          cancelText="取消"
          confirmButtonProps={{ form: THIS_FORM, danger: true }}
          onConfirm={onConfirm}
          onCancel={closeModal}
        />
      }
    >
      確認要解除綁定？
    </ModalLayout>
  );
};
