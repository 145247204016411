import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';
import { DeliveryAreaOptions } from 'apps/frontend/src/app/Transportation/RequirementsManagementPage/RequirementsManagement.config';

export function DeliveryAreaSelector() {
  return (
    <AutoCompleteField
      label="送達區域"
      required
      registerName="deliveryArea"
      options={DeliveryAreaOptions}
    />
  );
}
