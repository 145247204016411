import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  CalendarRanger,
  MultipleSelect,
  SearchInput,
} from '../../OrderManagement/FilterSearch/enhanceFilter/enhanceFilter';
import { FieldsType } from '@solar/data';
import styles from './SalesReturnPage.module.scss';
import { SelectValue } from '@mezzanine-ui/react';

export function useSalesReturnFilter(OrderTypeOptions: SelectValue[]) {
  const methods = useForm();
  const filtersData = useMemo(() => {
    const { register, setValue } = methods;
    return [
      {
        label: '出貨單號',
        fn: SearchInput,
        registerName: 'orderIds',
        props: {
          name: 'orderIds',
          clearable: true,
          placeholder: '請輸入出貨單號',
          register,
          setValue,
        },
      },
      {
        label: '客戶名稱',
        fn: SearchInput,
        registerName: 'customerNames',
        props: {
          name: 'customerNames',
          clearable: true,
          placeholder: '請輸入客戶名稱',
          register,
          setValue,
        },
      },
      {
        label: '業務人員',
        fn: SearchInput,
        registerName: 'employeeNames',
        props: {
          name: 'employeeNames',
          clearable: true,
          placeholder: '請輸入業務人員',
          register,
          setValue,
        },
      },
      {
        label: '日期區間',
        fn: CalendarRanger,
        registerName: 'expectedDateRange',
        props: {
          name: 'expectedDateRange',
          clearable: true,
          register,
          setValue,
        },
      },
      {
        label: '出貨單類型',
        fn: MultipleSelect,
        registerName: 'types',
        props: {
          className: styles.multipleSelectField,
          name: 'types',
          clearable: true,
          mode: 'multiple',
          register,
          setValue,
          options: OrderTypeOptions,
        },
      },
    ];
  }, [methods, OrderTypeOptions]);

  const filterMethods = useCallback((payload: FieldsType[]) => {
    // console.log('payload:', payload);
  }, []);

  return {
    methods,
    filtersData,
    filterMethods,
  };
}
