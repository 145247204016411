import { useState } from 'react';
import { API_NAMESPACE, request } from '../request';
import useSWR from 'swr';
import { Pagination } from '../pagination';
import { LoaderWithBatchRecords } from './loader.type';

const namespace = API_NAMESPACE.MATERIALS;

export function useGetLoaders() {
  const [params, setParams] = useState<{ searchTerm: string }>();
  const { data, error, mutate } = useSWR<{
    records: {
      id: string;
      shelfId: string;
      shelf: any;
      categoryCode: string;
      category: {
        code: string;
        name: string;
      };
      widthCm: string;
      lengthCm: string;
      heightCm: string;
      capacityL: string;
      barcode: string;
      createdAt: string;
    }[];
    pageInfo: Pagination;
  }>([
    `/loaders`,
    {
      params,
      namespace,
    },
  ]);

  return {
    loaders: data?.records ?? [],
    loading: !data && !error,
    refetchGetLoaders: setParams,
    mutateGetLoaders: mutate,
  };
}

export function checkIfLoaderIsEmpty({ loaderId }: { loaderId: string }) {
  return request(`/loaders/${encodeURIComponent(loaderId)}`, {
    namespace,
    method: 'get',
    params: { noInventory: true },
    responseParser: (res) => res,
  });
}
