import { Modal, ModalBody, ModalHeader, Table } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { useForm } from 'react-hook-form';
import styles from './storage-info-modal.module.scss';

import {
  useSVGs,
  getZoneDisplayName,
  useEquipments,
  LocationLevel,
} from '@solar/data';
import { useState, useEffect } from 'react';
import {
  LocationSVGViewer,
  transformZoneToLocation,
} from '../../Equipment/LocationSelector/LocationSVGViewer';
import moment from 'moment';

type StorageInfoModalProps = {
  open: boolean;
  data: any;
  closeModal: () => void;
};

export function StorageInfoModal({
  open,
  data,
  closeModal,
}: StorageInfoModalProps) {
  // form
  const { equipments, pageInfo, mutateGetEquipments, isLoading } =
    useEquipments();
  const { svg, mutateGetSVG, resetSVG } = useSVGs();
  const [currentZone, setCurrentZone] = useState<any>();
  const [anchorRef, setAnchorRef] = useState<HTMLButtonElement | null>();
  const methods = useForm();
  const onClose = () => {
    methods.reset({});
    closeModal();
  };
  const columns = [
    {
      title: '批號',
      dataIndex: 'name',
    },
    {
      width: 120,
      title: '標籤',
      dataIndex: 'category.name',
    },
    {
      title: '儲位',
      ellipsis: false,
      render: (source: any) => (
        <div
          data-zone-id={source?.zone?.id}
          onClick={(event: any) => {
            if (currentZone) {
              resetSVG();
              setCurrentZone(null);
              setAnchorRef(null);
            }
            if (source?.zone?.id) {
              setTimeout(() => {
                mutateGetSVG(source?.zone?.area?.id);
                setCurrentZone(source?.zone);
                setAnchorRef(event.currentTarget);
              }, 100);
            }
          }}
        >
          {getZoneDisplayName(source?.zone)}
        </div>
      ),
    },
    {
      title: '規格',
      dataIndex: 'workCenter.name',
    },
    {
      title: '數量',
      dataIndex: 'workCenter.name',
    },
    {
      width: 150,
      title: '裝機日期',
      render: (source: any) =>
        moment(source?.installed_at).format('YYYY/MM/DD'),
    },
    {
      title: '操作人員',
      dataIndex: 'workCenter.name',
    },
    {
      title: '自有/客供',
      dataIndex: 'workCenter.name',
    },
    {
      title: '是否有成本',
      dataIndex: 'workCenter.name',
    },
  ];

  const stats = [
    {
      title: '規格',
      dataIndex: 'name',
      width: 130,
    },
    {
      title: '數量',
      dataIndex: 'category.name',
    },
  ];

  return (
    <Modal className={styles.modal_wrapper} open={open} onClose={onClose}>
      <ModalHeader>{`${data?.id} 庫存詳細資訊`}</ModalHeader>
      <ModalBody>
        <FormFieldsWrapper methods={methods}>
          <h3>庫存明細</h3>
          <Table
            bodyClassName={styles.table}
            dataSource={equipments}
            columns={columns}
          />
        </FormFieldsWrapper>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ maxWidth: '250px' }}>
            <h3 style={{ marginTop: '0' }}>庫存統計</h3>
            <Table
              bodyClassName={styles.table}
              dataSource={equipments}
              columns={stats}
            />
          </div>
          <div
            style={{
              border: '1px solid #000',
              borderRadius: '5px',
              backgroundColor: '#fff',
            }}
          >
            <LocationSVGViewer
              svg={svg}
              currentLevel={LocationLevel.ZONE}
              currentLocation={transformZoneToLocation(currentZone)}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
