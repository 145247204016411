import { useEffect, useMemo } from 'react';
import { Typography, Button, Table, MenuDivider } from '@mezzanine-ui/react';
import { useNavigate } from 'react-router-dom';
import sharedStyles from '../shared-styles/index.module.scss';
import { useEquipments } from '@solar/data';
import { useForm } from 'react-hook-form';
import {
  InspectStatus,
  InspectStatusTag,
} from '../IncomingInspectOperationPage/InspectStatusTag';
import {
  MaterialCollectSourceType,
  renderMaterialCollectSourceTypeString,
} from './material-collect-enum';
import { useModalController } from '../ModalLayout/hooks';
import { CollectMaterialFirstLayerModal } from './Modal/CollectMaterialFirstLayerModal';
import { CollectMaterialRegisterModal } from './Modal/CollectMaterialRegisterModal';
import classes from './material-collect-page.module.scss';
import { MaterialCollectPageFilter } from './MaterialCollectPageFilter';
import { TableColumn } from '@mezzanine-ui/core/table';

type ExpectedTakeTableColumn = {
  materialId: string;
  receiveData: string;
  creator: string;
  status: InspectStatus;
  sourceType: MaterialCollectSourceType;
};

export function MaterialCollectPage() {
  const navigate = useNavigate();

  // 搜尋條件
  const filterMethods = useForm({});

  // modal controllers
  const firstLayerModalController = useModalController();
  const secondLayerModalController = useModalController();

  // table data
  const {
    equipments,
    pageInfo,
    mutateGetEquipments,
    isLoading: tableLoading,
  } = useEquipments(filterMethods);
  const mockDataSource = useMemo(
    () =>
      (equipments || [])?.map((packaging: any) => ({
        ...packaging,
        creator: '張博閔',
        status: Object.values(InspectStatus)[Math.floor(Math.random() * 3)],
        sourceType: Object.values(MaterialCollectSourceType)[
          Math.floor(Math.random() * 4)
        ],
      })),
    [equipments]
  );

  useEffect(() => {
    const subscription = filterMethods.watch(() =>
      mutateGetEquipments({ nextPage: 1 })
    );
    return () => subscription.unsubscribe();
  }, [filterMethods, filterMethods.watch, mutateGetEquipments]);

  // table column
  const columns: TableColumn<ExpectedTakeTableColumn>[] = useMemo(
    () => [
      { title: '採購單號', dataIndex: 'materialId' },
      { title: '預期領料日', dataIndex: 'receiveData' },
      { title: '開立者', dataIndex: 'creator' },
      {
        title: '狀態',
        render: (source) => {
          return <InspectStatusTag status={source.status} />;
        },
      },
      {
        title: '來源',
        render: (source) =>
          renderMaterialCollectSourceTypeString(source?.sourceType),
      },
      {
        title: '操作',
        width: 120,
        align: 'center' as const,
        render(source) {
          return (
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                firstLayerModalController.openModal({
                  modalHeader: source['sourceType'],
                  orderInfo: (() => {
                    const tmpData = [
                      {
                        label:
                          renderMaterialCollectSourceTypeString(
                            source['sourceType']
                          ).split(' ')[0] +
                          (/[A-Za-z]/.test(
                            renderMaterialCollectSourceTypeString(
                              source['sourceType']
                            ).split(' ')[0]
                          )
                            ? ' '
                            : '') +
                          '單號',
                        context: 'WW0010',
                      },
                      {
                        label: '預期領料日',
                        context: '2023/02/15',
                      },
                      {
                        label: '狀態',
                        context: (
                          <InspectStatusTag
                            status={source.status as InspectStatus}
                          />
                        ),
                      },
                    ];

                    if (
                      source['sourceType'] ===
                      MaterialCollectSourceType.DepartmentFee
                    )
                      tmpData.splice(2, 0, {
                        label: '領用部門',
                        context: '實驗課',
                      });

                    return tmpData;
                  })(),
                });
              }}
            >
              VIEW
            </Button>
          );
        },
      },
    ],
    [firstLayerModalController]
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes['header-wrapper']}>
        <Typography variant="h1">{'倉庫領料'}</Typography>
        <Button
          type="button"
          variant="outlined"
          onClick={() => navigate('/warehouse/material-collect/create')}
        >
          建立領料單
        </Button>
      </div>
      <MenuDivider />
      <MaterialCollectPageFilter methods={filterMethods} />
      <Table
        columns={columns}
        loading={tableLoading}
        pagination={{
          disableAutoSlicing: true,
          total: pageInfo?.totalCount ?? 0,
          current: pageInfo?.page ?? 1,
          onChange: (nextPage) => mutateGetEquipments({ nextPage }),
          options: {
            pageSize: pageInfo?.pageSize ?? 20,
            pageSizeLabel: '每頁顯示：',
            pageSizeUnit: '筆',
            onChangePageSize: (nextPageSize) =>
              mutateGetEquipments({ nextPage: 1, nextPageSize }),
            renderPageSizeOptionName: (p) => `${p}`,
            renderPaginationSummary: (start, end) =>
              `目前顯示 ${start} - ${end} 筆，共 ${end - start + 1} 筆資料`,
            showPageSizeOptions: true, // 開啟每頁顯示 N 筆
            showJumper: true, // 開啟跳頁功能
            jumperButtonText: '前往',
            jumperHintText: '跳至',
          },
        }}
        dataSource={mockDataSource ?? []}
        bodyClassName={sharedStyles.table}
      />
      <CollectMaterialFirstLayerModal
        {...firstLayerModalController}
        secondLayerModalController={secondLayerModalController}
      />
      <CollectMaterialRegisterModal {...secondLayerModalController} />
    </div>
  );
}
