import { useState } from 'react';
import useSWR from 'swr';
import { GetShippedOrdersParams, ShippedOrder } from './shipped-orders.type';
import { API_NAMESPACE, request } from '../request';

const namespace = API_NAMESPACE.MATERIALS;

export function useGetShippedOrders(defaultParams?: GetShippedOrdersParams) {
  const [params, setParams] = useState(defaultParams);
  const { data, error, mutate, isValidating } = useSWR<ShippedOrder[]>([
    '/shipped-orders',
    { params, namespace },
  ]);

  return {
    shippedOrders: data ?? [],
    loading: !data && !error && !isValidating,
    refetchGetShippedOrders: setParams,
    mutateGetShippedOrders: mutate,
  };
}

export function returnTargets(payload: {
  deliveryOrderId: string;
  pairs: {
    originDeliveryOrderId: string;
    originDeliveryOrderLine: string;
    deliveryOrderLine: string;
    materialSapBatchId: string;
    materialSubBatchId: string;
    productionWorkOrderId: string;
    actualWeight: string;
  }[];
  targetLoaderId: string;
  targetShelfId: string;
}) {
  return request('/return-targets', {
    method: 'post',
    namespace: API_NAMESPACE.PP,
    body: JSON.stringify(payload),
  });
}
