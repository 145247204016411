import { AutoComplete, SelectValue } from "@mezzanine-ui/react";
import { useLocationsBySearchTerm } from "@solar/data";
import { uniqBy } from "lodash";
import { useCallback, useState } from "react";
import { useLocationSelectorContext } from "../LocationSelectorContext/useLocationSelectorContext";

export function LocationSearchInput() {
  const { handleNextLevel } = useLocationSelectorContext();
  const [searchTerm] = useState<SelectValue>();
  const [searchText, setSearchText] = useState('');
  const { locations } = useLocationsBySearchTerm({ searchText });
  const onChange = useCallback(async (term: SelectValue) => {
    handleNextLevel(term?.id);
  }, [handleNextLevel]);

  return (
    <AutoComplete
      options={uniqBy(locations, 'id')?.map(({ id, level }) => ({ id, name: id, level }))}
      value={searchTerm}
      onChange={onChange}
      onSearch={setSearchText}
      placeholder="請輸入關鍵字搜尋" />
  );
}