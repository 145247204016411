import { Typography } from '@mezzanine-ui/react';
import { ReactNode } from 'react';
import { cx } from '@mezzanine-ui/react';

import classes from './row-section.module.scss';

interface RowSectionProps {
  colGap?: string;
  label: string;
  labelAlign?: 'top' | 'center';
  children: ReactNode;
  required?: boolean;
  childFullWidth?: boolean;
}

export function RowSection({
  colGap = '40px',
  label,
  labelAlign = 'center',
  children,
  required = false,
  childFullWidth = false,
}: RowSectionProps) {
  return (
    <div className={classes.host} style={{ gap: colGap }}>
      <Typography
        className={cx(
          { [classes.label]: labelAlign === 'center' },
          classes['label-font'],
          'mzn-form-field__label'
        )}
        color="text-secondary"
        // variant="h1"
        noWrap
      >
        {label}{' '}
        {required && (
          <>
            <span className={classes['require-tag']}>必填</span>
            <span style={{ color: 'red' }}>&#42;</span>
          </>
        )}
      </Typography>
      <div className={cx({ [classes['field-wrapper']]: childFullWidth })}>
        {children}
      </div>
    </div>
  );
}
