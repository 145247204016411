import { useCallback, useEffect, useRef, useState } from 'react';
import { usePageContext } from './use-page-context';

export function usePageModalsController() {
  const [currentModal, setCurrentModal] = useState<string | null>(null);
  const [currentModalData, setCurrentModalData] = useState<unknown>(null);

  const modalsRegisterSet = useRef<Set<string>>(new Set());

  const register = useCallback((name: string) => {
    if (modalsRegisterSet.current.has(name)) {
      throw new Error(`Modal with name '${name}' is already registered.`);
    }

    modalsRegisterSet.current.add(name);
  }, []);

  const unregister = useCallback((name?: string) => {
    if (!name) {
      modalsRegisterSet.current.clear();
      return;
    }

    if (!modalsRegisterSet.current.has(name)) {
      throw new Error(`Modal with name '${name}' is not registered.`);
    }

    modalsRegisterSet.current.delete(name);
  }, []);

  const openModal = useCallback((name: string, data: unknown) => {
    if (!modalsRegisterSet.current.has(name)) {
      throw new Error(`Modal with name '${name}' is not registered.`);
    }

    setCurrentModal(name);
    setCurrentModalData(data);
  }, []);

  const closeModal = useCallback(() => {
    setCurrentModal(null);
    setCurrentModalData(null);
  }, []);

  useEffect(() => {
    return () => {
      modalsRegisterSet.current.clear();
    };
  }, []);

  return {
    currentModal,
    currentModalData,
    register,
    unregister,
    openModal,
    closeModal,
  };
}

export function usePageModal<DataType = any>(name: string) {
  const { modalsController } = usePageContext();

  useEffect(() => {
    modalsController?.register(name);
    return () => {
      modalsController?.unregister(name);
    };
  }, [modalsController, name]);

  return {
    open: modalsController?.currentModal === name,
    data: modalsController?.currentModalData as DataType,
    openModal: modalsController?.openModal,
    closeModal: modalsController?.closeModal,
  };
}
