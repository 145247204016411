import {
  FormTemplateType,
  ProductionFormResponse,
} from './production-process-form.type';
import { SubSampleMethodItem } from '../lims/submission.type';
import { TableDataSourceWithID } from '@mezzanine-ui/core/table';

export interface GetProductionFormAnswerParams {
  limit?: number;
  offset?: number;
  factory?: string;
  materialId?: string;
  materialSapBatchId?: string;
  materialSubBatchId?: string;
  withLims?: boolean;
  selectDefaultAndPicked?: boolean;
  templateTypes: FormTemplateType[];
  latestOnly?: boolean;
}

export interface SaveProductionFormAnswersPayload {
  materialId: string;
  materialSapBatchId: string;
  materialSubBatchId: string;
  upsertAnswers: UpsertAnswer[];
  newAnswers: NewAnswer[];
}

export interface UpsertAnswer {
  productionAnswerId: string;
  modifiedAnswer: string | null;
  picked: boolean;
}

export interface NewAnswer {
  productionFormId: string;
  answer: string | null;
  picked: boolean;
}

export interface FormAnswer extends TableDataSourceWithID {
  id: string;
  verifiedAt: any;
  answer: string;
  modifiedAnswer: any;
  productionFormId: string;
  // form: Form;
  form: ProductionFormResponse;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  materialId: string;
  materialSapBatchId: string;
  materialSubBatchId: string;
  materialRequestOrderId: string;
  picked: boolean | null;
  subSampleMethodItem: SubSampleMethodItem;
}

export interface Form {
  id: string;
  title: string;
  suffix: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  templateId: string;
}

// export interface SubSampleMethodItem {
//   id: number;
//   factory: string;
//   submissionId: number;
//   subSampleId: number;
//   subSampleMethodId: number;
//   seq: number;
//   specStatus: any;
//   methodNumber: string;
//   testItem: string;
//   isReported: boolean;
//   reportUnit: string;
//   result: string;
//   processResult: number;
//   resultType: string;
//   formula: any;
//   matchCode: string;
//   pql: any;
//   lsl: any;
//   lcl: any;
//   mean: any;
//   ucl: any;
//   usl: any;
//   mdl: any;
//   dilution: any;
//   price: any;
//   textResult: any;
//   rawDataLink: any;
//   createdAt: any;
//   createdBy: any;
//   updatedAt: any;
//   updatedBy: any;
//   deletedAt: string;
//   showMultiResult: boolean;
//   rawDataAvg: number;
//   calResult: any;
//   calProcessResult: any;
//   loq: any;
//   uod: any;
//   lod: any;
//   productionAnswerId: string;
//   submission: Submission;
// }

// export interface Submission {
//   id: number;
//   factory: string;
//   status: number;
//   statusDesc: string;
//   subType: string;
//   subNumber: string;
//   rushPj: boolean;
//   subApplyTime: string;
//   subDueTime: any;
//   charge: number;
//   notice: any;
//   remark: any;
//   createdAt: string;
//   createdBy: string;
//   updatedAt: string;
//   updatedBy: string;
//   deletedAt: string;
//   updateHistory: string;
// }
