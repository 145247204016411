import { TableColumn } from '@mezzanine-ui/core/table';
import { useMemo } from 'react';
import { Button, Icon } from '@mezzanine-ui/react';
import { ExtendedRoutingRecord, LocalFilterOptions } from './typings';
import styles from './routing-table-column.module.scss';
import { UseFormReturn } from 'react-hook-form';
import { InputField, SelectField } from '@mezzanine-ui/react-hook-form';
import { MenuIcon } from '@mezzanine-ui/icons';
import { difference } from 'lodash';

type Props = {
  methods: UseFormReturn<LocalFilterOptions>;
  dataSource: ExtendedRoutingRecord[];
  workCenterGroupMap: Record<string, { id: string; name: string }[]>;
  setLocationViewerAnchor: React.Dispatch<
    React.SetStateAction<HTMLElement | null | undefined>
  >;
  imported?: boolean;
};

export function useRoutingTableColumn({
  methods,
  dataSource,
  workCenterGroupMap,
  setLocationViewerAnchor,
  imported,
}: Props): TableColumn<ExtendedRoutingRecord>[] {
  const editingRowId = methods.watch('editingRowId');

  const optionGroups = useMemo(
    () =>
      Object.entries(workCenterGroupMap).map(([key, value]) => ({
        label: key,
        options: value.map(({ id, name }) => ({
          id,
          name: `${id} ${name}`,
        })),
      })),
    [workCenterGroupMap]
  );

  return useMemo<TableColumn<ExtendedRoutingRecord>[]>(
    () => [
      {
        title: '#',
        dataIndex: 'orderNumber',
        width: 50,
      },
      {
        title: '動作',
        width: 270,
        render: (row: ExtendedRoutingRecord) => (
          <div className={styles.actionGroup}>
            <Button
              className="draggable-trigger"
              type="button"
              color="secondary"
              prefix={<Icon icon={MenuIcon} />}
            />
            <Button
              type="button"
              disabled={!!editingRowId && row.id !== editingRowId}
              onClick={() =>
                methods.setValue(
                  'editingRowId',
                  editingRowId ? undefined : row.id
                )
              }
            >
              {row.id === editingRowId ? '完成' : '編輯'}
            </Button>
            <Button
              type="button"
              onClick={() => methods.setValue('editingDetailId', row.id)}
              disabled={!!editingRowId && row.id !== editingRowId}
            >
              明細
            </Button>
            <Button
              type="button"
              disabled={!!editingRowId && row.id !== editingRowId}
              onClick={() => methods.setValue('deletingRowId', row.id)}
              danger
            >
              刪除
            </Button>
          </div>
        ),
      },
      {
        title: '行為碼',
        width: 84 + (editingRowId ? 50 : 0),
        render(row, index) {
          if (row.id !== editingRowId) {
            return (
              <span
                className={
                  row.actionCode !== dataSource[index]?.actionCode
                    ? styles.changedField
                    : undefined
                }
              >
                {row.actionCode}
              </span>
            );
          }

          return (
            <InputField
              key="actionCode"
              placeholder="請輸入行為代碼"
              registerName={`tableValues.${index}.actionCode`}
            />
          );
        },
      },
      {
        title: '行為',
        width: 160 + (editingRowId ? 50 : 0),
        render(row, index) {
          if (row.id !== editingRowId) {
            return (
              <span
                className={
                  row.action !== dataSource[index]?.action
                    ? styles.changedField
                    : undefined
                }
              >
                {row.action}
              </span>
            );
          }

          return (
            <InputField
              key="action"
              placeholder="請輸入行為名稱"
              registerName={`tableValues.${index}.action`}
            />
          );
        },
      },
      {
        title: '新 Recipe',
        width: 120 + (editingRowId ? 50 : 0),
        render(row, index) {
          if (row.id !== editingRowId) {
            return (
              <span
                className={
                  row.newRecipe !== dataSource[index]?.newRecipe
                    ? styles.changedField
                    : undefined
                }
              >
                {row.newRecipe}
              </span>
            );
          }

          return (
            <InputField
              key="newRecipe"
              placeholder="請輸入新Recipe"
              registerName={`tableValues.${index}.newRecipe`}
            />
          );
        },
      },
      {
        title: '路徑碼描述',
        dataIndex: 'recipeDesc',
        width: 120 + (editingRowId ? 50 : 0),
      },
      {
        title: '加工區描述',
        width: 100 + (editingRowId ? 50 : 0),
        render(row, index) {
          if (row.id !== editingRowId) {
            return (
              <span
                className={
                  row.areaDesc !== dataSource[index]?.areaDesc
                    ? styles.changedField
                    : undefined
                }
              >
                {row.areaDesc}
              </span>
            );
          }

          return (
            <InputField
              key="areaDesc"
              placeholder="請輸入加工區描述"
              registerName={`tableValues.${index}.areaDesc`}
            />
          );
        },
      },
      {
        title: '源料號',
        width: 280 + (editingRowId ? 50 : 0),
        render(row, index) {
          if (row.id !== editingRowId) {
            return (
              <span
                className={
                  row.sourceMaterialId !== dataSource[index]?.sourceMaterialId
                    ? styles.changedField
                    : undefined
                }
              >
                {row.sourceMaterialId}
              </span>
            );
          }

          return (
            <InputField
              width={280}
              key="sourceMaterialId"
              placeholder="請輸入源料號"
              registerName={`tableValues.${index}.sourceMaterialId`}
            />
          );
        },
      },
      {
        title: '汲料號',
        width: 280 + (editingRowId ? 50 : 0),
        render(row, index) {
          if (row.id !== editingRowId) {
            return (
              <span
                className={
                  row.destMaterialId !== dataSource[index]?.destMaterialId
                    ? styles.changedField
                    : undefined
                }
              >
                {row.destMaterialId}
              </span>
            );
          }

          return (
            <InputField
              width={280}
              key="destMaterialId"
              placeholder="請輸入汲料號"
              registerName={`tableValues.${index}.destMaterialId`}
            />
          );
        },
      },
      ...(imported
        ? [
            {
              title: '最終料號',
              dataIndex: 'finalDestinationMaterialId',
              width: 280,
            },
          ]
        : []),
      {
        title: '工作中心',
        width: 220 + (editingRowId ? 170 : 0),
        render(row, index) {
          if (row.id !== editingRowId) {
            const diff = difference(
              row.allowWorkCenterIds ?? [],
              dataSource[index]?.allowWorkCenterIds ?? []
            );

            return (
              <span className={diff ? undefined : styles.changedField}>
                {row.selectedWorkCenterOptions
                  .map((item) => item.name)
                  .join('/')}
              </span>
            );
          }

          return (
            <SelectField
              width={320}
              mode="multiple"
              key="allowWorkCenterIds"
              optionGroups={optionGroups}
              placeholder="請輸入工作中心碼"
              registerName={`tableValues.${index}.selectedWorkCenterOptions`}
            />
          );
        },
      },
      {
        title: '地點',
        width: 140 + (editingRowId ? 50 : 0),
        render(row, index) {
          if (row.id !== editingRowId) {
            return (
              <div
                data-zone-id={row.locationId}
                style={{ cursor: 'pointer' }}
                onMouseEnter={(event: any) => {
                  if (row.locationId) {
                    methods.setValue('selectedChartId', row.locationId);

                    setLocationViewerAnchor(event.currentTarget);
                  }
                }}
                className={
                  row.locationId !== dataSource[index]?.locationId
                    ? styles.changedField
                    : undefined
                }
              >
                {row.locationId}
              </div>
            );
          }

          return (
            <InputField
              key="locationId"
              placeholder="請輸入地點"
              registerName={`tableValues.${index}.locationId`}
            />
          );
        },
      },
      {
        title: '人分',
        width: 70 + (editingRowId ? 50 : 0),
        render(row, index) {
          if (row.id !== editingRowId) {
            return (
              <span
                className={
                  row.manHours !== dataSource[index]?.manHours
                    ? styles.changedField
                    : undefined
                }
              >
                {row.manHours}
              </span>
            );
          }

          return (
            <InputField
              key="manHours"
              valueAsNumber
              placeholder="請輸入人分"
              registerName={`tableValues.${index}.manHours`}
            />
          );
        },
      },
      {
        title: '機分',
        width: 70 + (editingRowId ? 50 : 0),
        render(row, index) {
          if (row.id !== editingRowId) {
            return (
              <span
                className={
                  row.machineHours !== dataSource[index]?.machineHours
                    ? styles.changedField
                    : undefined
                }
              >
                {row.machineHours}
              </span>
            );
          }

          return (
            <InputField
              key="machineHours"
              valueAsNumber
              placeholder="請輸入機分"
              registerName={`tableValues.${index}.machineHours`}
            />
          );
        },
      },
      {
        title: '標準重',
        width: 120 + (editingRowId ? 50 : 0),
        render(row, index) {
          if (row.id !== editingRowId) {
            return (
              <span
                className={
                  row.standardWeight !== dataSource[index]?.standardWeight
                    ? styles.changedField
                    : undefined
                }
              >
                {row.standardWeight}
              </span>
            );
          }

          return (
            <InputField
              key="standardWeight"
              valueAsNumber
              placeholder="請輸入標準重"
              registerName={`tableValues.${index}.standardWeight`}
            />
          );
        },
      },
    ],
    [
      imported,
      editingRowId,
      methods,
      dataSource,
      optionGroups,
      setLocationViewerAnchor,
    ]
  );
}
