import { Button, Table, Typography } from '@mezzanine-ui/react';
import { useMemo, useState } from 'react';
import {
  SalesReturnDetailMaterialItem,
  SalesReturnDetailMaterialItemWithKey,
} from './SalesReturnDetail.interface';
import { TableColumn } from '@mezzanine-ui/core/table';
import styles from './SalesReturnDetailPage.module.scss';

type ReceivableProps = {
  data: SalesReturnDetailMaterialItem[];
  onReceivableMatch: (item: SalesReturnDetailMaterialItem) => void;
};

export function ReceivableTable({ data, onReceivableMatch }: ReceivableProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const receivableColumns: TableColumn<SalesReturnDetailMaterialItem>[] = [
    {
      title: '操作',
      width: 88,
      render: (item: SalesReturnDetailMaterialItemWithKey, index: number) => {
        return (
          <Button variant="outlined" onClick={() => onReceivableMatch(item)}>
            匹配
          </Button>
        );
      },
    },
    {
      title: '品名',
      dataIndex: 'materialDescription',
      ellipsis: false,
      width: 280,
      bodyClassName: styles.wordWrap,
    },
    {
      title: '料號',
      dataIndex: 'materialId',
      ellipsis: false,
      width: 280,
      bodyClassName: styles.wordWrap,
    },
    {
      title: '應收量',
      dataIndex: 'quantity',
      ellipsis: false,
      width: 120,
      bodyClassName: styles.wordWrap,
    },
    {
      title: '已收量',
      dataIndex: 'quantityReceived',
      ellipsis: false,
      width: 120,
      bodyClassName: styles.wordWrap,
    },
    {
      title: '單位',
      dataIndex: 'quantityUnitName',
      ellipsis: false,
      width: 80,
    },
  ];

  const dataWithKey = useMemo<SalesReturnDetailMaterialItemWithKey[]>(
    () => data.map((item) => ({ ...item, key: item.materialId })),
    [data]
  );

  return (
    <div>
      <Typography variant="h3">應收項目</Typography>
      <Table
        columns={receivableColumns}
        dataSource={dataWithKey}
        pagination={{
          current: currentPage,
          total: data.length,
          onChange: setCurrentPage,
          options: {
            pageSize,
            onChangePageSize: (pageSize: number) => {
              setPageSize(pageSize);
              setCurrentPage(1);
            },
            showPageSizeOptions: true,
            pageSizeLabel: '每頁顯示：',
            pageSizeUnit: '筆',
          },
        }}
      ></Table>
    </div>
  );
}
