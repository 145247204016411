import useSWR from 'swr';
import { useCallback, useState } from 'react';
import { PageInfo } from '../pagination';
import { API_NAMESPACE, request } from '../request';
import { Equipment, GetEquipmentsParams } from './equipment.type';
import { Zone } from '../locations';

const namespace = API_NAMESPACE.EQUIPMENTS;

export async function getEquipmentsByZone({ zoneId }: { zoneId?: Zone['id'] }) {
  return request(`/equipments?zoneIds=${zoneId}`, {
    method: 'get',
    responseParser: (res) => res,
  });
}

/** @description: 使用方式請全域搜尋 useGetEquipments */
export function useGetEquipments() {
  const [params, setParams] = useState<GetEquipmentsParams>();
  const { data, error, mutate } = useSWR<{
    equipments: Equipment[];
    pageInfo: PageInfo;
  }>(['/equipments', { params, namespace }]);

  const fetchEquipments = useCallback(
    (nextParams: GetEquipmentsParams) => setParams(nextParams),
    []
  );

  return {
    equipments: data?.equipments ?? [],
    pageInfo: data?.pageInfo,
    isLoading: !data && !error,
    /** @description: 在 key 和 params 狀態改變的情況下，需要使用 fetch */
    fetchEquipments,
    /** @description: 在 key 和 params 狀態沒有改變的情況下，需要使用 mutate，使用情境有: POST, PUT, DELETE 成功之後 */
    mutateGetEquipments: mutate,
  };
}

export function useGetDeptCodes() {
  const { data: deptCodes, error } = useSWR<
    {
      deptCode: string;
      deptName: string;
    }[]
  >(['/equipments/dept-codes', { namespace }]);

  return {
    deptCodes: deptCodes ?? [],
    isLoading: !deptCodes && !error,
  };
}
