import { useMemo, Fragment, useState } from 'react';
import { ModalLayout, ModalLayoutProps } from '../../ModalLayout/ModalLayout';
import {
  Button,
  ModalActions,
  Popper,
  Table,
  Typography,
} from '@mezzanine-ui/react';
import classes from '../../IncomingInspectOperationPage/incoming-inspect-detail-page.module.scss';
import { Divider } from 'antd';
import { useModalController } from '../../ModalLayout/hooks';
import {
  LocationLevel,
  ZoneEntity,
  getZoneDisplayName,
  useEquipments,
  useSVGs,
} from '@solar/data';
import styles from '../../../SalesAndDistribution/PackagingPage/PackagingPage.module.scss';
import {
  LocationSVGViewer,
  transformZoneToLocation,
} from '../../../Equipment/LocationSelector/LocationSVGViewer';
import {
  MaterialCollectSourceType,
  renderMaterialCollectSourceTypeString,
} from '../material-collect-enum';
import { InspectStatusTag } from '../../IncomingInspectOperationPage/InspectStatusTag';
import { useForm } from 'react-hook-form';
import { MaterialRegisterEnum } from './register-material-modal-enum';
import useMznTablePagination from '../../hooks/useMznTablePagination';
import { TableColumn } from '@mezzanine-ui/core/table';
import { ExpectedTakeTableColumn } from '../create/Modal/ChooseMaterialFirstLayerModal';

type Props = ModalLayoutProps & {
  secondLayerModalController: ReturnType<typeof useModalController>;
};
export function CollectMaterialFirstLayerModal({
  open,
  data,
  closeModal,
  secondLayerModalController,
}: Props) {
  const filterMethods = useForm();
  const { equipments } = useEquipments(filterMethods);
  const modalHeader = data?.modalHeader as MaterialCollectSourceType;

  const [anchorRef, setAnchorRef] = useState<HTMLDivElement | null>();
  const { svg, mutateGetSVG, resetSVG } = useSVGs();
  const [currentZone, setCurrentZone] = useState<ZoneEntity | null>();

  const expectedTakeTableColumn: TableColumn<ExpectedTakeTableColumn>[] =
    useMemo(
      () => [
        { title: '料號', dataIndex: 'materialId' },
        { title: '規格', dataIndex: 'spec' },
        { title: '批號', dataIndex: 'batch' },
        {
          title: '儲位',
          ellipsis: false,
          render: (source) => (
            <div
              data-zone-id={source?.zone?.id}
              onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => {
                if (source?.zone?.id) {
                  mutateGetSVG(source?.zone?.area?.id);
                  setCurrentZone(source?.zone);
                  setAnchorRef(event.currentTarget);
                }
              }}
              onMouseLeave={() => {
                if (source?.zone?.id) {
                  resetSVG();
                  setCurrentZone(null);
                  setAnchorRef(null);
                }
              }}
            >
              {getZoneDisplayName(source?.zone)}
            </div>
          ),
        },
        { title: '載具', dataIndex: 'vehicle' },
        { title: '已領收量', dataIndex: 'received' },
        {
          title: '應領數量',
          render: () => <>1</>,
        },
        {
          title: '操作',
          width: 120,
          align: 'center' as const,
          render(source) {
            return (
              <Button
                type="button"
                variant="outlined"
                onClick={() => {
                  secondLayerModalController.openModal({
                    modalHeader: MaterialRegisterEnum.Expected,
                    id: source.materialId,
                  });
                }}
              >
                領料
              </Button>
            );
          },
        },
      ],
      [mutateGetSVG, resetSVG, secondLayerModalController]
    );

  const extraTakeTableColumn: TableColumn<ExpectedTakeTableColumn>[] = useMemo(
    () => [
      { title: '料號', dataIndex: 'materialId' },
      { title: '規格', dataIndex: 'spec' },
      { title: '批號', dataIndex: 'batch' },
      {
        title: '儲位',
        ellipsis: false,
        render: (source) => (
          <div
            data-zone-id={source?.zone?.id}
            onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => {
              if (source?.zone?.id) {
                mutateGetSVG(source?.zone?.area?.id);
                setCurrentZone(source?.zone);
                setAnchorRef(event.currentTarget);
              }
            }}
            onMouseLeave={() => {
              if (source?.zone?.id) {
                resetSVG();
                setCurrentZone(null);
                setAnchorRef(null);
              }
            }}
          >
            {getZoneDisplayName(source?.zone)}
          </div>
        ),
      },
      { title: '載具', dataIndex: 'vehicle' },
      { title: '已領數量', dataIndex: 'received' },
    ],
    [mutateGetSVG, resetSVG]
  );

  // table pagination control
  const { page: expectedCurrentPage, onChange: expectedTableOnChange } =
    useMznTablePagination();
  const { page: extraCurrentPage, onChange: extraTableOnChange } =
    useMznTablePagination();

  return (
    <>
      <ModalLayout
        modalHeader={
          renderMaterialCollectSourceTypeString(modalHeader) + '領料'
        }
        open={open}
        closeModal={closeModal}
        modalFooter={
          <ModalActions
            confirmText="完成"
            onConfirm={closeModal}
            cancelButtonProps={{ style: { display: 'none' } }}
          />
        }
      >
        {modalHeader !== MaterialCollectSourceType.DepartmentFee && (
          <div>
            <Button
              variant="outlined"
              onClick={() => {
                secondLayerModalController.openModal({
                  modalHeader: MaterialRegisterEnum.Extra,
                });
              }}
            >
              額外領料
            </Button>
          </div>
        )}
        <div className={classes['row-block']}>
          <div className={classes['material-info']}>
            {data?.orderInfo?.map(
              (arr: { label: string; context: any }, index: number) => (
                <Fragment key={`orderInfo-block-${index}`}>
                  {Boolean(index) && (
                    <Divider type="vertical" className={classes.divider} />
                  )}
                  <div className={classes.content}>
                    <Typography variant="h6" color="text-secondary">
                      {arr.label}
                    </Typography>
                    <Typography>
                      {arr.context in MaterialCollectSourceType ? (
                        <InspectStatusTag status={arr.context} />
                      ) : (
                        arr.context
                      )}
                    </Typography>
                  </div>
                </Fragment>
              )
            )}
          </div>
          {modalHeader === MaterialCollectSourceType.DepartmentFee && (
            <>
              <Divider />
              <div className={classes.content}>
                <Typography variant="h6" color="text-secondary">
                  領料事由
                </Typography>
                <Typography>某某部門需要領用作為測試與時間!</Typography>
              </div>
            </>
          )}
        </div>
        {/* 部門費用領料只有一個 table； 其餘類型有兩個 table */}
        {modalHeader === MaterialCollectSourceType.DepartmentFee ? (
          <div className={classes['row-block']}>
            <Typography variant="h4" color="text-primary">
              領取物料
            </Typography>
            <Table
              columns={expectedTakeTableColumn}
              dataSource={equipments ?? []}
              bodyClassName={styles.table}
              headerClassName={styles.tableHeader}
              pagination={{
                current: expectedCurrentPage,
                onChange: expectedTableOnChange,
                total: equipments?.length,
              }}
            />
          </div>
        ) : (
          <>
            <div className={classes['row-block']}>
              <Typography variant="h4" color="text-primary">
                預期領取物料
              </Typography>
              <Table
                columns={expectedTakeTableColumn}
                dataSource={equipments ?? []}
                bodyClassName={styles.table}
                headerClassName={styles.tableHeader}
                pagination={{
                  current: expectedCurrentPage,
                  onChange: expectedTableOnChange,
                  total: equipments?.length,
                }}
              />
            </div>
            <div className={classes['row-block']}>
              <Typography variant="h4" color="text-primary">
                額外領料
              </Typography>
              <Table
                columns={extraTakeTableColumn}
                dataSource={equipments ?? []}
                bodyClassName={styles.table}
                headerClassName={styles.tableHeader}
                pagination={{
                  current: extraCurrentPage,
                  onChange: extraTableOnChange,
                  total: equipments?.length,
                }}
              />
            </div>
          </>
        )}
      </ModalLayout>
      <Popper
        anchor={anchorRef}
        open={Boolean(anchorRef)}
        style={{
          border: '1px solid #000',
          borderRadius: '5px',
          backgroundColor: '#fff',
          zIndex: 1002,
        }}
        options={{
          placement: 'auto',
        }}
      >
        <LocationSVGViewer
          svg={svg}
          currentLevel={LocationLevel.ZONE}
          currentLocation={
            currentZone ? transformZoneToLocation(currentZone) : undefined
          }
        />
      </Popper>
    </>
  );
}
