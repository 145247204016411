import type { CustomerTradeConditionCreateFormValues } from '@solar/data';
import type { FieldPath } from 'react-hook-form';
import {
  SelectField,
  type SelectFieldProps,
} from '@mezzanine-ui/react-hook-form';
import { useFormContext } from 'react-hook-form';

type Props = {
  registerName: FieldPath<CustomerTradeConditionCreateFormValues>;
  options: SelectFieldProps['options'];
};

export function ComponentSelect({ registerName, options }: Props) {
  const formMethods = useFormContext<CustomerTradeConditionCreateFormValues>();

  const _PrivateFieldName = '_' + registerName;

  return (
    <SelectField
      control={formMethods.control}
      registerName={_PrivateFieldName}
      options={options}
      onChange={(selected) => {
        formMethods.setValue(registerName, selected.id);
      }}
    />
  );
}
