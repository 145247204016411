import {
  DateRangePickerField,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';

import {
  LocationLevel,
  MaterialRequestOrderSourceType,
  SOURCE_TYPE_TRANSLATION,
  useFactories,
} from '@solar/data';

import classes from './dispatch-orders-filter.module.scss';

const SORT_TYPE = {
  EXPECTED_COMPLETED_AT_DESC: '預期發料日期新 -> 舊',
  EXPECTED_COMPLETED_AT_ASC: '預期發料日期舊 -> 新',
};

const SORT_TYPE_SELECTIONS = Object.entries(SORT_TYPE).map(([key, value]) => ({
  id: key,
  name: value,
}));

const CREATE_SOURCE_TYPE_SELECTIONS = Object.values(
  MaterialRequestOrderSourceType
).map((key) => ({
  id: key,
  name: SOURCE_TYPE_TRANSLATION[key],
}));

export function DispatchOrdersFilter() {
  const { factories } = useFactories({ returnLevel: LocationLevel.FACTORY });
  return (
    <div className={classes.host}>
      <InputField
        registerName="materialRequestOrderId"
        placeholder="請輸入發料單號"
      />

      <InputField registerName="workOrderId" placeholder="請輸入工單單號" />
      <SelectField
        label="狀態："
        mode="multiple"
        clearable
        registerName="states"
        options={[]}
      />
      <SelectField
        label="工廠："
        mode="multiple"
        clearable
        registerName="factories"
        options={factories}
      />
      <SelectField
        label="建立來源："
        mode="multiple"
        clearable
        registerName="sourceType"
        options={CREATE_SOURCE_TYPE_SELECTIONS}
      />
      <DateRangePickerField
        fullWidth
        label="建立日期區間："
        registerName="createdDateRange"
      />
      <SelectField
        label="預期發料日期："
        clearable
        registerName="sortType"
        options={SORT_TYPE_SELECTIONS}
      />
    </div>
  );
}
