import { parseInt } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import useSWR from 'swr';
import {
  authDeleteFetcher,
  authGetFetcher,
  authPostFetcher,
  authPutFetcher,
} from './fetch';
import {
  areResourceActionsApiData,
  convertResourceActionsToTreeData,
  convertResourceAndActions,
  ResourceApiDataType,
  TreeDataType,
} from './functions';
import { CreateEditResponse, ModalType, useTenantList } from './useTenantList';

type RoleWithResourceAction = {
  id: number;
  name: string;
  tenant_id: number;
  tenant_subject_id: number;
  resource_with_actions: {
    id: number;
    name: string;
    actions: { id: number; name: string }[];
  }[];
};

type TenantWithRoleResourceAction = {
  has_roles: boolean;
  id: number;
  name: string;
  role_with_resource_actions: RoleWithResourceAction[];
};

export function useRoleList() {
  const navigate = useNavigate();

  const methods = useForm();
  const filterMethods = useForm();

  const { data: tenantsData, tenantsTreeDataWithoutRole } = useTenantList();

  const [dataSource, setDataSource] = useState<
    RoleWithResourceAction[] & { tenant_name: string }[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [openModal, setOpenModal] = useState<ModalType>(null);
  const [modalSource, setModalSource] = useState<any | null>(null);
  const [modalTitle, setModalTitle] = useState('');
  const [filterPoliciesData, setFilterPoliciesData] = useState<
    TreeDataType[] | null
  >(null);

  const {
    data: resourceActionsByTenants,
    mutate: mutateResourceActionsByTenants,
  } = useSWR(
    methods.control._formValues?.['module']?.id
      ? `/tenants/${methods.control._formValues['module']?.id}/resource_actions`
      : null,
    authGetFetcher
  );

  const tenantSelectData = useMemo(
    () =>
      tenantsData?.map((tenant) => ({ id: tenant.value, name: tenant.title })),
    [tenantsData]
  );

  const resources = useMemo(() => {
    const resources =
      resourceActionsByTenants?.resources_with_actions?.resources;

    return areResourceActionsApiData(resources)
      ? convertResourceActionsToTreeData(resources, 0, []).map((resource) => ({
          ...resource,
          disabled: true,
        }))
      : undefined;
  }, [resourceActionsByTenants]);

  const queryRoles = useCallback(async () => {
    filterMethods.watch();
    const formValues = filterMethods.control._formValues;
    const { policies, roleName } = formValues;

    const convertPolicies = (policies: { value: string }[]) => {
      const tenants = policies.filter(
        (policy) =>
          !policy.value.includes('role-') && !policy.value.includes('actions-')
      );
      const roles = policies.filter((policy) => policy.value.includes('role-'));
      const actions = policies.filter((policy) =>
        policy.value.includes('actions-')
      );
      const roleSet = new Set(
        roles.map((role) => parseInt(role.value.split('-')[2])) // 被勾選的role
      );
      actions.forEach((action) => {
        roleSet.add(parseInt(action.value.split('-')[1])); // 被勾選的 actions 有包含到的 role
      });

      const allRoles: TreeDataType[] = [];
      filterPoliciesData
        ?.filter((policy) => policy.children)
        ?.forEach((policy) => {
          policy.children?.forEach((child) => allRoles.push(child));
        });
      const selectedRoles = allRoles.filter(
        (role) => roleSet.has(parseInt(role.value.split('-')[2])) // 從所有 role 裡面找出所有有在 roleSet 裡面的
      );

      const tenantSet = new Set(
        tenants.map((tenant) => parseInt(tenant.value))
      );
      selectedRoles.forEach((role) => {
        const parentId = parseInt(role.value.split('-')[1]);
        tenantSet.add(parentId);
      });
      const tenant_id_with_resource_actions: {
        tenant_id: number;
        resource_id_with_actions: number[];
      }[] = [];
      tenantSet.forEach((tenant) => {
        tenant_id_with_resource_actions.push({
          tenant_id: tenant,
          resource_id_with_actions: [],
        });
      });
      const tenantObj: {
        [key: number]: { resource_id: number; actions_id: number[] }[];
      } = tenant_id_with_resource_actions.reduce(
        (acc, cur) => ({ ...acc, [cur.tenant_id]: [] }),
        {}
      );
      const actionRoleSet = new Set(
        selectedRoles.map((role) => role.value.split('-')[2])
      );
      const actionRoleArrFromSet: string[] = [];
      actionRoleSet.forEach((actionRole) =>
        actionRoleArrFromSet.push(actionRole)
      );
      const actionRoleObj: { [key: string]: number[] } =
        actionRoleArrFromSet.reduce((acc, cur) => ({ ...acc, [cur]: [] }), {});
      actions.forEach((action) => {
        const splitAction = action.value.split('-');
        const roleId = splitAction[1] as string;
        actionRoleObj[roleId].push(parseInt(splitAction[2]));
      });
      Object.keys(actionRoleObj).forEach((key) => {
        const filterRole = selectedRoles.filter(
          (role) => role.value.split('-')[2] === key
        )[0];
        const splitRole = filterRole.value.split('-');
        const parent = parseInt(splitRole[1]);
        tenantObj[parent].push({
          resource_id: parseInt(splitRole[2]),
          actions_id: actionRoleObj[key],
        });
      });
      const result = tenant_id_with_resource_actions.map((tenant) => {
        const tenantId = tenant.tenant_id;
        return { ...tenant, resource_id_with_actions: tenantObj[tenantId] };
      });
      return result;
    };

    const newPolicies = convertPolicies(policies ?? []);

    const result = await authPostFetcher('/query_roles', {
      role_name: roleName,
      tenant_id_with_resource_actions: newPolicies,
    });
    const tenant_with_role_resource_actions: TenantWithRoleResourceAction[] =
      result?.tenant_with_role_resource_actions;
    if (tenant_with_role_resource_actions) {
      const dataSourceArr: RoleWithResourceAction[] &
        { tenant_name: string }[] = [];
      tenant_with_role_resource_actions.forEach((tenant) => {
        tenant.role_with_resource_actions.forEach((resource_action) => {
          dataSourceArr.push({ ...resource_action, tenant_name: tenant.name });
        });
      });
      setDataSource(dataSourceArr);
    }
  }, [authPostFetcher, setDataSource, filterMethods, filterPoliciesData]);

  const onChangeCurrentPage = useCallback(
    (page: number) => setCurrentPage(page),
    [setCurrentPage]
  );

  const onChangePageSize = useCallback(
    (perPage: number) => setPageSize(perPage),
    [setPageSize]
  );

  const onCloseModal = useCallback(() => {
    methods.reset();
    setOpenModal(() => null);
    setModalSource(() => null);
  }, [setOpenModal, setModalSource, methods]);

  const onOpenWarningModal = useCallback(
    (source: any) => {
      setOpenModal('WarningModal');
      setModalSource(source);
    },
    [setOpenModal, setModalSource]
  );

  const confirmDelete = useCallback(async () => {
    const roleId = modalSource?.id;
    const result = await authDeleteFetcher(`/roles?role_ids=${roleId}`, {});
    await queryRoles();
    onCloseModal();
    return result;
  }, [modalSource, authDeleteFetcher, queryRoles, onCloseModal]);

  const onOpenCreateEditModal = useCallback(
    async (source: any, title: string) => {
      setOpenModal('CreateEditModal');
      setModalSource(source);
      setModalTitle(title);
      const defaultResouceActions: ResourceApiDataType[] =
        source?.resource_with_actions ?? [];
      const policiesValue: { value: string; label: string }[] = [];
      defaultResouceActions.forEach((resource) => {
        if (resource.actions) {
          resource.actions.forEach((action) => {
            const newAction = {
              value: `actions-${resource.id}-${action.id}`,
              label: action.name,
            };
            policiesValue.push(newAction);
          });
        } else {
          policiesValue.push({ value: `${resource.id}`, label: resource.name });
        }
      });
      methods.setValue('policies', policiesValue);
    },
    [
      setOpenModal,
      setModalSource,
      setModalTitle,
      methods,
      tenantSelectData,
      mutateResourceActionsByTenants,
      resources,
    ]
  );

  const createEditModal = useCallback(async () => {
    const roleId = modalSource?.id;
    const form = methods.control._formValues;
    const { policies: policiesForm, module, name } = form;
    const resource_actions = convertResourceAndActions(policiesForm);
    const payload = {
      role_name: name,
      tenant_id: parseInt(module.id),
      resource_id_with_actions: resource_actions,
    };
    let response: CreateEditResponse | null = null;
    if (roleId) {
      response = await authPutFetcher(`/roles/${roleId}`, payload);
    } else {
      response = await authPostFetcher('/roles', payload);
    }
    methods.reset();
    await queryRoles();
    onCloseModal();
    return response;
  }, [
    methods,
    modalSource,
    convertResourceAndActions,
    authPostFetcher,
    onCloseModal,
    queryRoles,
  ]);

  const onNavigate = useCallback(
    (id: string) => navigate(`/auth/role/${id}`),
    [navigate]
  );

  const onLoadFilterPoliciesData = useCallback(
    async (e: any) => {
      const tenantId = e.value as string;
      if (tenantId.includes('role-')) return;
      const result = await authGetFetcher(
        `/tenants/${tenantId}/resource_actions`
      );
      const resource_actions = result?.resources_with_actions ?? [];
      const resources = resource_actions?.resources ?? [];
      const resultResources = convertResourceActionsToTreeData(
        resources,
        0,
        []
      ).map((resource) => ({
        ...resource,
        value: `role-${tenantId}-${resource.value}`,
      }));

      setFilterPoliciesData(
        (tenants) =>
          tenants?.map((tenant) =>
            tenant.value === e.value
              ? { ...tenant, children: resultResources }
              : tenant
          ) ?? []
      );
    },
    [
      tenantsTreeDataWithoutRole,
      convertResourceActionsToTreeData,
      filterPoliciesData,
      setFilterPoliciesData,
    ]
  );

  useEffect(() => {
    setFilterPoliciesData(tenantsTreeDataWithoutRole ?? []);
  }, [tenantsTreeDataWithoutRole]);

  return {
    tenantSelectData,
    filterPoliciesData,
    setFilterPoliciesData,
    onLoadFilterPoliciesData,
    dataSource,
    resources,
    methods,
    filterMethods,
    currentPage,
    onChangeCurrentPage,
    pageSize,
    onChangePageSize,
    openModal,
    modalSource,
    modalTitle,
    onOpenWarningModal,
    onCloseModal,
    onOpenCreateEditModal,
    confirmDelete,
    onNavigate,
    queryRoles,
    createEditModal,
  };
}
