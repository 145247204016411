import type { CustomerTradeConditionCreateFormValues } from '@solar/data';
import styles from './index.module.scss';
import {
  CustomerTradeConditionCreateFormFieldName as FormFieldName,
  useCustomerTradeConditionFilterGet,
} from '@solar/data';
import { useFormContext } from 'react-hook-form';
import {
  AutoCompleteField,
  useAutoCompleteInput,
} from '@mezzanine-ui/react-hook-form';

const _PrivateFieldName = '_' + FormFieldName.MATERIAL_ID;

type Props = {
  defaultValue: { id: string; name: string };
};

export function MaterialReadOnlyAutoComplete({ defaultValue }: Props) {
  const formMethods = useFormContext<CustomerTradeConditionCreateFormValues>();

  const { input, onInput } = useAutoCompleteInput({ debounceMs: 300 });

  const { materials } = useCustomerTradeConditionFilterGet({
    materialKeyword: input,
  });

  const clearMaterialComposition = () => {
    formMethods.setValue(FormFieldName.COMPONENT_ITEM_NUMBER_1, undefined);
    formMethods.setValue(FormFieldName.COMPONENT_ITEM_NUMBER_2, undefined);
    formMethods.setValue(FormFieldName.COMPONENT_ITEM_NUMBER_3, undefined);
    formMethods.setValue(FormFieldName.COMPONENT_ITEM_NUMBER_4, undefined);
    formMethods.setValue(FormFieldName.WEIGHT_METHOD_1, undefined);
    formMethods.setValue(FormFieldName.WEIGHT_METHOD_2, undefined);
    formMethods.setValue(FormFieldName.WEIGHT_METHOD_3, undefined);
    formMethods.setValue(FormFieldName.WEIGHT_METHOD_4, undefined);
    formMethods.setValue(FormFieldName.ANALYSIS_VALUE_METHOD_1, undefined);
    formMethods.setValue(FormFieldName.ANALYSIS_VALUE_METHOD_2, undefined);
    formMethods.setValue(FormFieldName.ANALYSIS_VALUE_METHOD_3, undefined);
    formMethods.setValue(FormFieldName.ANALYSIS_VALUE_METHOD_4, undefined);
    // workaround
    formMethods.setValue(`_${FormFieldName.WEIGHT_METHOD_1}` as any, undefined);
    formMethods.setValue(`_${FormFieldName.WEIGHT_METHOD_2}` as any, undefined);
    formMethods.setValue(`_${FormFieldName.WEIGHT_METHOD_3}` as any, undefined);
    formMethods.setValue(`_${FormFieldName.WEIGHT_METHOD_4}` as any, undefined);
    formMethods.setValue(
      `_${FormFieldName.ANALYSIS_VALUE_METHOD_1}` as any,
      undefined
    );
    formMethods.setValue(
      `_${FormFieldName.ANALYSIS_VALUE_METHOD_2}` as any,
      undefined
    );
    formMethods.setValue(
      `_${FormFieldName.ANALYSIS_VALUE_METHOD_3}` as any,
      undefined
    );
    formMethods.setValue(
      `_${FormFieldName.ANALYSIS_VALUE_METHOD_4}` as any,
      undefined
    );
  };

  return (
    <>
      <div className={styles['col-span-1']}>產品料號：</div>
      <div className={styles['col-span-1']}>
        <AutoCompleteField
          control={formMethods.control}
          registerName={_PrivateFieldName}
          defaultValue={defaultValue}
          options={materials}
          onInput={onInput}
          onChange={(selected) => {
            // read only
            // clearMaterialComposition();
            // formMethods.setValue(FormFieldName.MATERIAL_ID, selected?.id);
          }}
          readOnly
          disabled
          // style={{ pointerEvents: 'none' }}
        />
      </div>
    </>
  );
}
