import {
  InputField,
  InputFieldProps,
  RadioGroupField,
  RadioGroupFieldProps,
  SelectField,
  SelectFieldProps,
} from '@mezzanine-ui/react-hook-form';
import { ProductionFormFieldEnum } from '@solar/data';
import { FocusEventHandler, useCallback, useMemo } from 'react';
import {
  FieldValues,
  UseFormRegister,
  Validate,
  useFormContext,
} from 'react-hook-form';

interface EnumSelectFieldProps {
  fields: ProductionFormFieldEnum;
  registerName: string;
  disabled?: boolean;
}

export function EnumSelectField({
  disabled,
  fields,
  registerName,
}: EnumSelectFieldProps) {
  const methods = useFormContext<FieldValues>();

  // const validate = useMemo(() => {
  //   const results: Record<string, Validate<any>> = {};

  //   if (fields?.required) {
  //     results.required = (v) => !!v || '必填';
  //   }

  //   return results;
  // }, [fields]);

  // const _register = useCallback<UseFormRegister<FieldValues>>(
  //   (name, options) => {
  //     return methods.register(name, Object.assign(options ?? {}, { validate }));
  //   },
  //   [methods, validate]
  // );

  const onBlur = useCallback(() => {
    methods.trigger(registerName);
  }, [methods, registerName]);

  // if (fields.enum.length > 3) {
  return (
    <SelectField
      fullWidth
      disabled={disabled}
      // register={_register}
      registerName={registerName}
      placeholder={fields?.placeholder ?? ''}
      options={fields.enum.map((id) => ({
        id,
        name: id,
      }))}
      onBlur={onBlur}
    />
  );
  // }

  // return (
  //   <RadioGroupField
  //     disabled={disabled}
  //     register={_register}
  //     registerName={registerName}
  //     options={fields.enum.map((id) => ({
  //       label: id,
  //       value: id,
  //     }))}
  //     placeholder={fields.placeholder}
  //     onBlur={onBlur}
  //   />
  // );
}
