import { getRoleGroup } from './shared';

const SECOND_SPEC_GROUP = Array.from(Array(100)).reduce(
  (vars, _, index) => ({
    ...vars,
    [index.toString().padStart(2, '0')]: {
      name: `${index}%`,
    },
  }),
  {
    ZZ: {
      name: '不標示濃度',
    },
  }
);

const FIRST_SPEC_GROUP = {
  C1: {
    name: '中華',
    nextLevel: SECOND_SPEC_GROUP,
  },
  H1: {
    name: '現代',
    nextLevel: SECOND_SPEC_GROUP,
  },
  I1: {
    name: 'INFINITI',
    nextLevel: SECOND_SPEC_GROUP,
  },
  F1: {
    name: '福特',
    nextLevel: SECOND_SPEC_GROUP,
  },
  Y1: {
    name: '裕隆',
    nextLevel: SECOND_SPEC_GROUP,
  },
  L1: {
    name: '納智捷',
    nextLevel: SECOND_SPEC_GROUP,
  },
  R1: {
    name: '富思特',
    nextLevel: SECOND_SPEC_GROUP,
  },
  A1: {
    name: '卡爾特克',
    nextLevel: SECOND_SPEC_GROUP,
  },
  S1: {
    name: 'SUM',
    nextLevel: SECOND_SPEC_GROUP,
  },
  T1: {
    name: '和運',
    nextLevel: SECOND_SPEC_GROUP,
  },
  M1: {
    name: '3M',
    nextLevel: SECOND_SPEC_GROUP,
  },
  FF: {
    name: '未分類',
    nextLevel: SECOND_SPEC_GROUP,
  },
  W1: {
    name: '伍爾特',
    nextLevel: SECOND_SPEC_GROUP,
  },
};

const TANK_SMALL_GROUP = {
  1: {
    name: '未分類',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  R: {
    name: '紅色',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  O: {
    name: '橘色',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  Y: {
    name: '黃色',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  G: {
    name: '綠色',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  B: {
    name: '藍色',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  P: {
    name: '粉紅色',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
};

const BASIC_MEDIUM_GROUP = {
  1: {
    name: '未分類',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
      },
    },
  },
};

const BREAK_OIL_MEDIUM_GROUP = {
  1: {
    name: '未分類',
  },
  A: {
    name: 'DOT3',
  },
  B: {
    name: 'DOT4',
  },
  C: {
    name: 'DOT4 super',
  },
  D: {
    name: 'DOT5.1',
  },
  E: {
    name: 'DOT1',
  },
};

const TANK_ESSENCE_MEDIUM_GROUP = {
  1: {
    name: '未分類',
    nextLevel: TANK_SMALL_GROUP,
  },
  A: {
    name: 'HOAT(U2)',
    nextLevel: TANK_SMALL_GROUP,
  },
  B: {
    name: 'HOAT(車廠)',
    nextLevel: TANK_SMALL_GROUP,
  },
  C: {
    name: 'HOAT(重柴)',
    nextLevel: TANK_SMALL_GROUP,
  },
  D: {
    name: 'OAT',
    nextLevel: TANK_SMALL_GROUP,
  },
  E: {
    name: 'E-coolant',
    nextLevel: TANK_SMALL_GROUP,
  },
  F: {
    name: 'showbrine',
    nextLevel: TANK_SMALL_GROUP,
  },
};

export const CAR_CHEMICAL_LARGE_GROUP = {
  A: {
    name: '水箱精/防凍液',
    nextLevel: TANK_ESSENCE_MEDIUM_GROUP,
  },
  B: {
    name: '剎車油',
    nextLevel: BREAK_OIL_MEDIUM_GROUP,
  },
  C: {
    name: '恆溫液',
    nextLevel: BASIC_MEDIUM_GROUP,
  },
  S: {
    name: '原料固體',
    nextLevel: BASIC_MEDIUM_GROUP,
  },
  L: {
    name: '原料液體',
    nextLevel: BASIC_MEDIUM_GROUP,
  },
  D: {
    name: '劑類',
    nextLevel: BASIC_MEDIUM_GROUP,
  },
  E: {
    name: '機油',
    nextLevel: BASIC_MEDIUM_GROUP,
  },
  W: {
    name: '雨刷精',
    nextLevel: BASIC_MEDIUM_GROUP,
  },
  M: {
    name: '保養品',
    nextLevel: BASIC_MEDIUM_GROUP,
  },
};
