import { DownloadIcon } from "@mezzanine-ui/icons";
import { Button, Icon, MenuDivider, Typography } from "@mezzanine-ui/react";
import styles from './material-mapping-page.module.scss';
import { useForm } from "react-hook-form";
import { FormFieldsWrapper, InputTagsModeField, SelectField } from "@mezzanine-ui/react-hook-form";
import { MaterialMappingTable } from "./MaterialMappingTable";
import { MaterialCategory, MaterialGroup, NextPagination, downloadMaterialExcel, useMaterialCategories, useMaterialGroups, useMaterialMapping } from "@solar/data";
import { useCallback, useEffect } from "react";

type FilterState = {
  newMaterialIds: string[],
  oldMaterialIds: string[],
  materialCategories: MaterialCategory[],
  materialGroups: MaterialGroup[],
};

export function MaterialMappingPage() {
  const defaultValues = {
    newMaterialIds: [],
    oldMaterialIds: [],
    materialCategories: [],
    materialGroups: [],
  };
  const methods = useForm<FilterState>({ defaultValues });
  const { materialGroups } = useMaterialGroups();
  const { materialCategories } = useMaterialCategories();
  const { materialMapping, pageInfo, mutateGetMaterialMapping, isLoading } = useMaterialMapping();

  const filterConverter = (filterState: FilterState) => ({
    newMaterialIds: filterState?.newMaterialIds ?? [],
    oldMaterialIds: filterState?.oldMaterialIds ?? [],
    categoryIds: filterState?.materialCategories?.map((category) => category?.id),
    groupIds: filterState?.materialGroups?.map((group) => group?.id),
  });

  const refetchGetMaterialMapping = useCallback((nextPagination: NextPagination) => {
    const filterState = methods.getValues();
    mutateGetMaterialMapping({
      newMaterialIds: filterState?.newMaterialIds ?? [],
      oldMaterialIds: filterState?.oldMaterialIds ?? [],
      categoryIds: filterState?.materialCategories?.map((category) => category?.id),
      groupIds: filterState?.materialGroups?.map((group) => group?.id),
      ...nextPagination
    });
  }, [methods, mutateGetMaterialMapping]);

  useEffect(() => {
    const subscription = methods.watch(() => refetchGetMaterialMapping({ nextPage: 1 }));
    return () => subscription.unsubscribe();
  }, [methods, methods.watch, refetchGetMaterialMapping]);

  return (
    <div className={styles.page}>
      <div className={styles.title}>
        <Typography variant="h1">新舊料號對應表</Typography>
        <Button
          prefix={<Icon icon={DownloadIcon} />}
          variant="contained"
          onClick={() => {
            const filterState = methods.getValues();
            downloadMaterialExcel(filterConverter(filterState));
          }}
        >
          匯出
        </Button>
      </div>
      <MenuDivider />
      <div className={styles.filter}>
        <Typography variant="h4">查詢</Typography>
        <MenuDivider />
        <FormFieldsWrapper methods={methods} className={styles.fields_wrapper}>
          <Typography variant="h6" color="secondary">新料號：</Typography>
          <InputTagsModeField registerName="newMaterialIds" maxLength={100} />
          <Typography variant="h6" color="secondary">舊料號：</Typography>
          <InputTagsModeField registerName="oldMaterialIds" maxLength={100} />
          <Typography variant="h6" color="secondary">物料類型：</Typography>
          <SelectField
            mode="multiple"
            options={materialCategories}
            registerName="materialCategories" />
          <Typography variant="h6" color="secondary">物料群組：</Typography>
          <SelectField
            mode="multiple"
            options={materialGroups}
            registerName="materialGroups" />
        </FormFieldsWrapper>
        <div className={styles.action_wrapper}>
          <Button onClick={() => methods.reset(defaultValues)}>清空條件</Button>
        </div>
      </div>
      <MaterialMappingTable
        materialMapping={materialMapping}
        isLoading={isLoading}
        pageInfo={pageInfo}
        refetchGetMaterialMapping={refetchGetMaterialMapping} />
    </div>
  )
}