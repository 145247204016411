import { TableColumn } from '@mezzanine-ui/core/table';
import { PlusIcon } from '@mezzanine-ui/icons';
import { Button, Icon } from '@mezzanine-ui/react';
import { fieldNamingMapping } from '../BasicInfoPanel';
import { PreviewInputHook } from '../types';

export const usePreviewInputColumn = ({
  methods,
  plusAction,
}: PreviewInputHook) => {
  const column: TableColumn<{
    inputMaterialId: string;
    inputMaterialDescription: string;
  }>[] = [
    {
      title: '',
      width: 50,
      render: (source) => (
        <Button
          type="button"
          prefix={<Icon icon={PlusIcon} />}
          onClick={() => {
            const { inputMaterialId, inputMaterialDescription } = source;

            plusAction({ inputMaterialId, inputMaterialDescription });
          }}
        />
      ),
    },
    {
      title: fieldNamingMapping['inputMaterialId'], //'投入料料號',
      dataIndex: 'inputMaterialId',
      width: 500,
    },
    {
      title: fieldNamingMapping['inputMaterialDescription'], //'投入料品名',
      dataIndex: 'inputMaterialDescription',
      ellipsis: false,
    },
    {
      title: fieldNamingMapping['requirementQuantity'],
      dataIndex: 'inputMaterialQuantity',
      width: 200,
    },
    {
      title: fieldNamingMapping['unit'],
      dataIndex: 'inputMaterialStockUnit',
      width: 100,
    },
    {
      title: fieldNamingMapping['alreadyUsedQuantity'],
      dataIndex: 'inputMaterialInputUnitQuantity',
      width: 200,
    },
    {
      title: fieldNamingMapping['unit'],
      dataIndex: 'inputMaterialInputUnit',
      width: 100,
    },
  ];

  return column;
};
