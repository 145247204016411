import {
  AutoCompleteField,
  DateRangePickerField,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  STOCK_CHANGE_ORDER_TYPES,
  useProductionDeliveryOrderTypes,
} from '@solar/data';

import classes from './filter.module.scss';

export type LMBRecordFormProps = {
  materialId: string;
  loaderId: string;
  barcode: string;
  sourceOrderType: { id: keyof typeof STOCK_CHANGE_ORDER_TYPES; name: string };
  sourceOrderId: string;
};

export const filteredOrderTypes = ['ZLR3'];
export function Filter() {
  return (
    <div className={classes.host}>
      <InputField registerName="materialId" placeholder="請輸入料號" />
      <InputField registerName="loaderId" placeholder="請輸入載具" />
      <InputField registerName="barcode" placeholder="請輸入庫存標籤" />
      <AutoCompleteField
        registerName="sourceOrderType"
        placeholder="來源單據類型"
        options={Object.entries(STOCK_CHANGE_ORDER_TYPES).map(([id, name]) => ({
          id,
          name,
        }))}
      />
      <InputField registerName="sourceOrderId" placeholder="來源單據編號" />
    </div>
  );
}
