import { Message } from '@mezzanine-ui/react';
import { API_NAMESPACE, request } from '@solar/data';

const namespace = API_NAMESPACE.MATERIALS;
export const uncheckInventoryPurchaseOrders = async (orderId: string) => {
  try {
    const res = await request(
      `/warehouses/inventory-purchase-orders/${orderId}/uncheck`,
      {
        method: 'put',
        namespace,
        responseParser: (res) => res,
      }
    );

    if (res.ok) {
      Message.success('驗收成功');
    }
  } catch (err) {
    if (err instanceof Error) {
      Message.error(JSON.parse(err.message).message);
    } else {
      Message.error('驗收失敗');
    }
  }
};
