import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { GetMaterialsParams } from './materials.type';

const namespace = API_NAMESPACE.MATERIALS;

/**
 * @todo: 將 suppliers 加入 params
 * */
export function useInventoryByCustomerSupplyOrders() {
  const [params, setParams] = useState<GetMaterialsParams>({});
  const { data, error } = useSWR(
    params
      ? ['/warehouses/customer-supply-orders', { params, namespace }]
      : null
  );

  const pageInfo = useMemo(() => {
    const page =
      (data?.pageInfo?.offset ?? 0) / (data?.pageInfo?.limit ?? 20) + 1;
    const pageSize = data?.pageInfo?.limit ?? 20;
    return { ...(data?.pageInfo ?? {}), page, pageSize };
  }, [data]);

  return {
    orders: data?.records || [],
    pageInfo,
    isLoading: !error && !data,
    mutateParams: setParams,
  };
}
