import { TableColumn } from '@mezzanine-ui/core/table';
import { Button, Table } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { getNextParams, NextPagination, useLoaders } from '@solar/data';
import {
  FilterScaffold,
  LocationSelectorGroupModal,
  LOCATION_SELECTOR,
  ModalGroup,
  PageLayout,
  useLocationSelectorController,
  useModalGroupController,
} from '@solar/templates';
import { Col, Row } from 'antd';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import sharedStyles from '../shared-styles/index.module.scss';
import { mockLoaderTypesList, Modals } from './const';
import { Filter } from './Filter';
import { LoaderWithCount, useLoadersWithCount } from '@solar/data';
import { CreateLoaders } from './modal/CreateLoaders';
import { ViewLoaders } from './modal/ViewLoaders';
import { ViewLoadersModalOpenData } from './types';

export function TempLoaderManagementPage() {
  const filterMethods = useForm();

  const { loaders, pageInfo, mutateLoaderParams } = useLoaders();
  const modalGroupController = useModalGroupController([
    { name: Modals.CreateLoaders },
    { name: Modals.ViewLoaders },
    { name: LOCATION_SELECTOR },
  ]);

  const { data } = useLoadersWithCount();

  const refetchLoaders = useCallback(
    (nextPagination: NextPagination) => {
      const filterState = filterMethods.getValues();

      const { offset, limit } = getNextParams(nextPagination, pageInfo);

      mutateLoaderParams({
        offset,
        limit,
        ...(filterState?.searchTerm && {
          searchTerm: filterState?.searchTerm,
        }),
      });
    },
    [filterMethods, mutateLoaderParams, pageInfo]
  );

  const columns: TableColumn<LoaderWithCount & { id: number }>[] = [
    {
      title: '操作',
      render: (source) => (
        <Button
          type="button"
          onClick={() =>
            modalGroupController.openModal<ViewLoadersModalOpenData>(
              Modals.ViewLoaders,
              { title: source.name, code: source.code }
            )
          }
        >
          檢視
        </Button>
      ),
    },
    {
      title: '類型（編碼）',
      render: (source) => `${source['name']}（${source['code']}）`,
    },
    { title: '數量（個）', dataIndex: 'equipmentQuantity' },
  ];

  const locationSelectorController = useLocationSelectorController({
    resetAfterClosing: false,
  });

  return (
    <>
      <PageLayout title="載具管理">
        {/* <FormFieldsWrapper methods={filterMethods}>
          <Row gutter={[12, 12]} wrap>
            <Col span={10}>
              <FilterScaffold mutateParams={refetchLoaders}>
                <Filter />
              </FilterScaffold>
            </Col>
            <Col offset={10}></Col>
          </Row>
        </FormFieldsWrapper> */}
        <div>
          <Button
            type="button"
            variant="outlined"
            onClick={() =>
              modalGroupController.openModal(Modals.CreateLoaders, null)
            }
          >
            新增載具
          </Button>
        </div>
        <Table
          bodyClassName={sharedStyles.table}
          columns={columns}
          dataSource={data?.map((row, index) => ({ id: index, ...row })) ?? []}
        />
      </PageLayout>
      <ModalGroup {...modalGroupController}>
        <ViewLoaders />
        <CreateLoaders
          locationSelectorController={locationSelectorController}
        />
        <LocationSelectorGroupModal
          controller={locationSelectorController}
          onSubmit={() => {
            modalGroupController.openModal(Modals.CreateLoaders, null);
          }}
          closeModal={() =>
            modalGroupController.openModal(Modals.CreateLoaders, null)
          }
        />
      </ModalGroup>
    </>
  );
}
