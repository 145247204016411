export const responseMap: Record<string, string> = {
  'invalid request': '錯誤',
  'invalid token': '無效的token',
  'invalid user status': '使用者未啟用',
  'invalid password': '密碼錯誤',
  'role_ids required': '請選擇角色',
  'user_ids required': '請選擇使用者',
  'action_ids required': '請選擇權限',
  'user not found': '找不到使用者',
  'user not active': '使用者未啟用',
  'user not in any tenants': '使用者不在模組內',
  'get resources actions error': '取得權限錯誤',
  'invalid user duplicate account': '帳號已被使用',
  'invalid user duplicate worker_number': '工號已被使用',
  'password required': '請填寫密碼',
};
