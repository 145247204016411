import { useAutoAnimate } from '@formkit/auto-animate/react';
import { TrashIcon } from '@mezzanine-ui/icons';
import {
  Button,
  Icon,
  MenuDivider,
  Message,
  ModalActions,
  SelectValue,
  Table,
  Typography,
} from '@mezzanine-ui/react';
import {
  FormFieldsDebug,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  API_NAMESPACE,
  request,
  updateOutcourcePurchaseOrderReceipt,
  updateProductionDeliveryOrderReceipt,
  updatePurchaseOrderReceipt,
  useGetIQCDeliveryOrders,
  useGetIQCPurchaseOrders,
  useInventoryByPurchaseOrder,
  useLoaders,
  useUnitConversionRatiosByMaterialId,
} from '@solar/data';
import {
  InputContainerWithUnit,
  LocationAndLoaderPickerField,
  LocationSelectorProvider,
  RowSection,
  useLocationSelectorController,
  useTargetModal,
} from '@solar/templates';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { LOCATION_SELECTOR } from '../../../Equipment/LocationSelector/LocationSelectorModal';
import { ModalLayout } from '../../ModalLayout/ModalLayout';
import { InspectMaterialType } from '../inspect-modal-enum';
import { ReceivedMaterialSubItem } from '../TempDetailPage';
import { ReceiveActionModalPassingData } from '../typings';
import { WarehouseAndVehicleFilter } from './WarehouseAndVehicleFilter';
import {
  OUTSOURCE_ORDER_META_TRANSLATION,
  OUTSOURCE_ORDER_META_TRANSLATION_IN_RECEIVE_MODAL,
} from '../constant';
import { OrderMetaInfo } from '../../components';
import classes from '../incoming-inspect-detail-page.module.scss';

export type RejectActionInputs = {
  materialStockUnitQuantity: string | null;
  warehouse?: SelectValue;
  toLoaderId: SelectValue | null;
  toShelfId: string | null;
};

export type MainMaterialUnitsObj = {
  mainMaterialUnit: SelectValue | null; // mainMaterialUnit api 待補
  // mainMaterialUnitQuantity: string | null;
  materialInputUnitQuantity: string | null;
  notes: string | null;
  // receiveWeight: string | null;
  materialWeightUnitQuantity: string | null;
  materialInputUnit: SelectValue | null;
  stickerNum: number | null;
};

type TempReceiveActionModal = {
  loaders: any[];
  locationSelectorController: ReturnType<typeof useLocationSelectorController>;
};

const statusIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export function TempRejectActionForOutsourceModal({
  loaders,
  locationSelectorController: controller,
}: TempReceiveActionModal) {
  const { orderType } = useParams<{
    orderType:
      | InspectMaterialType.CustomerOrder
      | InspectMaterialType.OutsourcingOrder
      | InspectMaterialType.PurchaseOrder;
  }>();
  const receiveOrReturnMaterialMethods = useForm<RejectActionInputs>();

  // const locationSelectorModalController =
  //   useTargetModal<null>(LOCATION_SELECTOR);
  const rejectActionModalController = useTargetModal<any>('RejectAction');

  const handleReceiveActionModalClose = useCallback(() => {
    receiveOrReturnMaterialMethods.reset();
    rejectActionModalController.closeModal();
  }, [rejectActionModalController, receiveOrReturnMaterialMethods]);

  // const warehouseAndVehicleFilterRef =
  //   useRef<React.ElementRef<typeof WarehouseAndVehicleFilter>>(null);

  const targetStock = rejectActionModalController?.data ?? {};
  const { materialId } = targetStock;

  const { orderId } = useParams();
  const location = useLocation();
  const { mutate } = useInventoryByPurchaseOrder({
    orderId:
      location.pathname.split('/')[2] === InspectMaterialType.PurchaseOrder
        ? orderId
        : undefined,
  });
  const { data: conversionUnits } = useUnitConversionRatiosByMaterialId({
    materialId,
  });
  const { mutateGetIQCPurchaseOrders } = useGetIQCPurchaseOrders({
    statusIds,
    purchaseOrderId: orderId,
  });
  const { mutateGetIQCDeliveryOrders } = useGetIQCDeliveryOrders({
    statusIds,
    deliveryOrderId: orderId,
  });

  const [parent] = useAutoAnimate();

  const [submitting, setSubmitting] = useState(false);

  return (
    <ModalLayout
      style={{ width: '1500px' }}
      loading={receiveOrReturnMaterialMethods.formState.isSubmitting}
      modalHeader="收料"
      modalFooter={
        <ModalActions
          confirmText="確認"
          cancelText="取消"
          confirmButtonProps={{
            form: 'receiveActionForm',
            loading: submitting,
          }}
          onCancel={() => handleReceiveActionModalClose()}
        />
      }
      {...rejectActionModalController}
      closeModal={handleReceiveActionModalClose}
    >
      {/* <LocationSelectorProvider controller={controller}> */}
      <FormFieldsWrapper
        id="receiveActionForm"
        methods={receiveOrReturnMaterialMethods}
        onSubmit={async (values) => {
          try {
            setSubmitting(true);

            // const { selectedIds } = controller;
            // const shelfId =
            //   selectedIds?.shelfId ??
            //   selectedIds?.stackId ??
            //   selectedIds?.zoneId;

            // if (!shelfId) {
            //   warehouseAndVehicleFilterRef.current?.setLocationRequired(true);
            //   return;
            // } else {
            //   warehouseAndVehicleFilterRef.current?.setLocationRequired(
            //     false
            //   );
            // }

            const body = JSON.stringify({
              toShelfId: values?.toShelfId,
              toLoaderId: values?.toLoaderId?.id,
              items: [
                {
                  stockId: targetStock?.stockId,
                  materialStockUnitQuantity:
                    values?.materialStockUnitQuantity ?? null,
                  materialWeightUnitQuantity: null,
                },
              ],
            });

            const res = await request(
              '/warehouses/outsource-purchase-material-request-orders/return',
              {
                method: 'put',
                body,
                namespace: API_NAMESPACE.MATERIALS,
                responseParser(res) {
                  return res;
                },
              }
            );

            if (res.ok) {
              await mutate?.(undefined, true);
              await mutateGetIQCPurchaseOrders?.(undefined, true);
              await mutateGetIQCDeliveryOrders?.(undefined, true);
              Message.success('退回成功');
              handleReceiveActionModalClose();
            }
          } catch (err) {
            if (err instanceof Error) {
              Message.error(JSON.parse(err.message).message);
            } else {
              Message.error('退回失敗');
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        <Table
          dataSource={[targetStock]}
          columns={[
            {
              dataIndex: 'materialDescription',
              title: '品名',
            },
            {
              dataIndex: 'materialId',
              title: '料號',
            },
            {
              title: '批號',
              render: (source: any) =>
                `${source?.materialSapBatchId ?? ''} ${
                  source?.materialSubBatchId ?? ''
                }`,
            },
            {
              dataIndex: 'stockQuantity',
              title: '剩餘數量',
            },
            {
              title: '本次退回數量',
              render: () => (
                <InputField registerName="materialStockUnitQuantity" />
              ),
            },
            {
              dataIndex: 'stockUnit',
              title: '單位',
            },
          ]}
        />
        <LocationAndLoaderPickerField
          locationIdLabel="存放儲位"
          locationIdRegisterName="toShelfId"
          loaderIdLabel="放入載具"
          loaderIdRegisterName="toLoaderId"
        />
        {/* <WarehouseAndVehicleFilter
            enableLoaderSelector
            enableDirectlyLoaderSearch
            openLocationSelectorModal={
              locationSelectorModalController.openModal
            }
            loaders={loaders}
            ref={warehouseAndVehicleFilterRef}
            locationLabel="存放儲位"
          /> */}
      </FormFieldsWrapper>
      {/* </LocationSelectorProvider> */}
    </ModalLayout>
  );
}
