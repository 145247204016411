import { TableColumn } from '@mezzanine-ui/core/table';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { MaterialShiftInputFields } from '../shift-action.enum';
import { Input, Typography } from '@mezzanine-ui/react';
import { ShiftTableColumnProps } from '../typings';
import Decimal from 'decimal.js';
import { Space } from 'antd';
import { CopyTextWrapper } from '../../../Templates/CopyTextWrapper/CopyTextWrapper';
import { translation } from '../../../WarehouseEnquiry/hooks/useColumns';

export function useMaterialShiftTableColumn<T extends ShiftTableColumnProps>({
  selectedRowKeys,
  dataSourceMaintainer,
  type,
  locationSVGPopperHandler,
}: {
  selectedRowKeys: string[];
  dataSourceMaintainer: Dispatch<SetStateAction<Array<T>>>;
  type: MaterialShiftInputFields;
  locationSVGPopperHandler: any;
}) {
  const column: { [key in MaterialShiftInputFields]: TableColumn<T>[] } =
    useMemo(
      () => ({
        [MaterialShiftInputFields?.MATERIAL_BARCODE]: [
          {
            title: '儲位',
            render: (source: T) => (
              <div
                data-zone-id={source?.shelfId}
                onMouseEnter={(event: any) => {
                  if (source?.shelfId) {
                    locationSVGPopperHandler.onMouseEnter(source?.shelfId)(
                      event
                    );
                  }
                }}
                onMouseLeave={locationSVGPopperHandler.onMouseLeave}
              >
                {source?.shelfId ?? '無'}
              </div>
            ),
          },
          {
            width: 200,
            title: '料號',
            render: (source) => (
              <CopyTextWrapper text={source?.materialId ?? ''} />
            ),
          },
          { title: '批號', dataIndex: 'batchId' },
          { title: '載具', dataIndex: 'loaderId' },
          {
            title: '狀態',
            render: (source: any) => {
              if (source.type in translation) {
                return (translation as any)?.[source.type as any];
              }
              return null;
            },
          },
          {
            title: '基礎計量單位數量',
            dataIndex: 'quantity',
          },
          {
            title: '可移轉庫存管理單位數量',
            render: (source: T) =>
              new Decimal(source?.quantity ?? '0')
                .div(source?.stockOverInputUnitRatio ?? '1')
                .toString(),
          },
          {
            title: '管理單位',
            render: (source: T) => source?.materialInputUnit ?? '',
          },
          {
            title: '移轉管理單位數量',
            render: (source: T, index: number) => {
              return (
                <LocalInput
                  source={source}
                  index={index}
                  selectedRowKeys={selectedRowKeys}
                  dataSourceMaintainer={dataSourceMaintainer}
                />
              );
            },
          },
          {
            title: '管理單位',
            render: (source: T) => source?.materialInputUnit ?? '',
          },
          {
            title: '環保連單號',
            render: (source: T) => (
              <Space direction="vertical">
                <div>{source?.inventoryOrderRecycleNumber}</div>
                <div>{source?.deliveryOrderRecycleNumber}</div>
              </Space>
            ),
          },
          {
            title: '特採單號',
            render: (source: T) => (
              <Space direction="vertical">
                <div>{source?.inventoryOrderWaiverNumber}</div>
                <div>{source?.deliveryOrderWaiverNumber}</div>
              </Space>
            ),
          },
          { title: '銷售訂單單號', dataIndex: 'salesOrderId' },
          { title: '銷售訂單項次', dataIndex: 'salesOrderLine' },
          {
            title: '實際重量',
            dataIndex: 'actualMaterialWeightUnitQuantity',
          },
          {
            title: '設定實際重量',
            render: (source: T, index: number) => (
              <ActualMaterialWeightUnitQuantityInput
                source={source}
                index={index}
                selectedRowKeys={selectedRowKeys}
                dataSourceMaintainer={dataSourceMaintainer}
              />
            ),
          },
        ],
        [MaterialShiftInputFields?.LOADER_BARCODE]: [
          { title: '料號', dataIndex: 'null-1' },
          { title: '批號', dataIndex: 'null-2' },
          { title: '載具', dataIndex: 'loaderId' },
          { title: '儲位', dataIndex: 'shelfId' },
          {
            title: '基礎計量單位數量',
            dataIndex: 'quantity',
          },
          {
            title: '可移轉庫存管理單位數量',
            render: (source: T) =>
              new Decimal(source?.quantity ?? '0')
                .div(source?.stockOverInputUnitRatio ?? '1')
                .toString(),
          },
          {
            title: '管理單位',
            render: (source: T) => source?.materialInputUnit ?? '',
          },
          {
            title: '移轉管理單位數量',
            dataIndex: 'availableMaterialInputUnitQuantity',
          },
          {
            title: '管理單位',
            render: (source: T) => source?.materialInputUnit ?? '',
          },
          {
            title: '環保連單號',
            render: (source: T) => (
              <Space direction="vertical">
                <div>{source?.inventoryOrderRecycleNumber}</div>
                <div>{source?.deliveryOrderRecycleNumber}</div>
              </Space>
            ),
          },
          {
            title: '特採單號',
            render: (source: T) => (
              <Space direction="vertical">
                <div>{source?.inventoryOrderWaiverNumber}</div>
                <div>{source?.deliveryOrderWaiverNumber}</div>
              </Space>
            ),
          },
          { title: '銷售訂單單號', dataIndex: 'salesOrderId' },
          { title: '銷售訂單項次', dataIndex: 'salesOrderLine' },
          { title: '實際重量', dataIndex: 'actualMaterialWeightUnitQuantity' },
          { title: '', dataIndex: 'remove', width: 100, align: 'start' },
        ],
        [MaterialShiftInputFields?.MATERIAL_ID_OR_MATERIAL_NAME]: [
          {
            width: 200,
            title: '料號',
            render: (source) => (
              <CopyTextWrapper text={source?.materialId ?? ''} />
            ),
          },
          {
            width: 500,
            title: '品名',
            render: (source) => (
              <CopyTextWrapper text={source?.materialDescription ?? ''} />
            ),
          },
          { title: '批號流水', dataIndex: 'batchId' },
          {
            title: '儲位',
            render: (source: T) => (
              <div
                data-zone-id={source?.shelfId}
                onMouseEnter={(event: any) => {
                  if (source?.shelfId) {
                    locationSVGPopperHandler.onMouseEnter(source?.shelfId)(
                      event
                    );
                  }
                }}
                onMouseLeave={locationSVGPopperHandler.onMouseLeave}
              >
                {source?.shelfId ?? '無'}
              </div>
            ),
          },
          { title: '載具', dataIndex: 'loaderId' },
          {
            title: '狀態',
            render: (source: any) => {
              if (source.type in translation) {
                return (translation as any)?.[source.type as any];
              }
              return null;
            },
          },
          {
            title: '基礎計量單位數量',
            dataIndex: 'quantity',
          },
          {
            title: '可移轉庫存管理單位數量',
            render: (source: T) =>
              new Decimal(source?.quantity ?? '0')
                .div(source?.stockOverInputUnitRatio ?? '1')
                .toString(),
          },
          {
            title: '管理單位',
            render: (source: T) => source?.materialInputUnit ?? '',
          },
          {
            title: '移轉管理單位數量',
            render: (source: T, index: number) => {
              return (
                <LocalInput
                  source={source}
                  index={index}
                  selectedRowKeys={selectedRowKeys}
                  dataSourceMaintainer={dataSourceMaintainer}
                />
              );
            },
          },
          {
            title: '管理單位',
            render: (source: T) => source?.materialInputUnit ?? '',
          },
          {
            title: '環保連單號',
            render: (source: T) => (
              <Space direction="vertical">
                <div>{source?.inventoryOrderRecycleNumber}</div>
                <div>{source?.deliveryOrderRecycleNumber}</div>
              </Space>
            ),
          },
          {
            title: '特採單號',
            render: (source: T) => (
              <Space direction="vertical">
                <div>{source?.inventoryOrderWaiverNumber}</div>
                <div>{source?.deliveryOrderWaiverNumber}</div>
              </Space>
            ),
          },
          { title: '銷售訂單單號', dataIndex: 'salesOrderId' },
          { title: '銷售訂單項次', dataIndex: 'salesOrderLine' },
          {
            title: '實際重量',
            dataIndex: 'actualMaterialWeightUnitQuantity',
          },
          {
            title: '設定實際重量',
            render: (source: T, index: number) => (
              <ActualMaterialWeightUnitQuantityInput
                source={source}
                index={index}
                selectedRowKeys={selectedRowKeys}
                dataSourceMaintainer={dataSourceMaintainer}
              />
            ),
          },
        ],
      }),
      [dataSourceMaintainer, selectedRowKeys]
    );

  return column[type];
}

function LocalInput<T extends ShiftTableColumnProps>({
  source,
  index,
  selectedRowKeys,
  dataSourceMaintainer,
}: {
  source: T;
  index: number;
  selectedRowKeys: Array<string>;
  dataSourceMaintainer: Dispatch<SetStateAction<Array<T>>>;
}) {
  const isQuantityExceedStorage =
    !!source['availableMaterialInputUnitQuantity'] &&
    Number(source['transferAmount']) >
      Number(source['availableMaterialInputUnitQuantity']);
  const isQuantityPositive = Number(source['transferAmount']) >= 0;

  return (
    <>
      <Input
        error={isQuantityExceedStorage || !isQuantityPositive}
        disabled={!selectedRowKeys.includes(String(source.id))}
        inputProps={{
          type: 'number',
        }}
        value={source['transferAmount']}
        onChange={(e) => {
          dataSourceMaintainer((prev) => {
            const result = prev.map((rowData, localIndex) => {
              if (index === localIndex) {
                return {
                  ...rowData,
                  transferAmount: e?.target?.value,
                };
              }

              return rowData;
            });

            return result;
          });
        }}
      />
      {isQuantityExceedStorage && (
        <Typography color="error" variant="caption" style={{ display: 'flex' }}>
          數量不得大於庫存數量！
        </Typography>
      )}
      {!isQuantityPositive && (
        <Typography color="error" variant="caption" style={{ display: 'flex' }}>
          數量不得為負！
        </Typography>
      )}
    </>
  );
}

function ActualMaterialWeightUnitQuantityInput<
  T extends ShiftTableColumnProps
>({
  source,
  index,
  selectedRowKeys,
  dataSourceMaintainer,
}: {
  source: T;
  index: number;
  selectedRowKeys: Array<string>;
  dataSourceMaintainer: Dispatch<SetStateAction<Array<T>>>;
}) {
  // const isQuantityExceedStorage =
  //   !!source['availableMaterialInputUnitQuantity'] &&
  //   Number(source['transferAmount']) >
  //     Number(source['availableMaterialInputUnitQuantity']);
  const isQuantityPositive = Number(source['materialWeightUnitQuantity']) >= 0;
  const error = !isQuantityPositive; // || isQuantityExceedStorage;

  return (
    <>
      <Input
        error={error}
        disabled={!selectedRowKeys.includes(String(source.id))}
        inputProps={{ type: 'number' }}
        value={source['materialWeightUnitQuantity']}
        onChange={(e) => {
          dataSourceMaintainer((prev) =>
            prev.map((rowData, localIndex) =>
              index === localIndex
                ? {
                    ...rowData,
                    materialWeightUnitQuantity: e?.target?.value,
                  }
                : rowData
            )
          );
        }}
      />
      {/* {isQuantityExceedStorage && (
        <Typography color="error" variant="caption" style={{ display: 'flex' }}>
          數量不得大於庫存數量！
        </Typography>
      )} */}
      {!isQuantityPositive && (
        <Typography color="error" variant="caption" style={{ display: 'flex' }}>
          數量不得為負！
        </Typography>
      )}
    </>
  );
}
