import { Button, Tab, TabPane, Tabs } from '@mezzanine-ui/react';
import { FormFieldsWrapper, SelectField } from '@mezzanine-ui/react-hook-form';
import { Key, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MznPaginationTable } from '../../Templates/PaginationTable/MznPaginationTable';
import { Col, Row, Space } from 'antd';
import { OutsourcingOrderTabPaneCheckIn } from './OutsourcingOrderTabPaneCheckIn';
import { OutsourcingOrderTabPaneCheckOut } from './OutsourcingOrderTabPaneCheckOut';
import { OutsourcingOrderTabPaneInventory } from './OutsourcingOrderTabPaneInventory';

export function OutsourcingOrderTabPaneContent() {
  const methods = useForm();
  const [activeTab, setActiveTab] = useState<Key>('CHECK_IN');

  return (
    <div>
      <FormFieldsWrapper methods={methods}>
        <Space direction="vertical" size="small">
          <SelectField registerName="workCenterId" label="工作中心代碼" />
          <SelectField registerName="workCenterName" label="工作中心名稱" />
          <MznPaginationTable
            columns={[
              {
                title: '工單單號',
                dataIndex: 'workOrderId',
              },
              {
                title: '發料單號',
                dataIndex: 'requestOrderId',
              },
              {
                title: '料號',
                dataIndex: 'materialId',
              },
              {
                title: '品名',
                dataIndex: 'materialDescription',
              },
              {
                title: '批號',
                dataIndex: 'materialBatchId',
              },
              {
                title: '數量',
                dataIndex: 'materialQuantity',
              },
              {
                title: '單位',
                dataIndex: 'materialUnit',
              },
            ]}
            dataSource={[]}
            pageInfo={{}}
            fetchData={console.log}
          />
          <Row justify="end" gutter={[12, 12]}>
            <Col>
              <Button type="button" variant="outlined">
                清空投入
              </Button>
            </Col>
            <Col>
              <Button type="button">退料</Button>
            </Col>
          </Row>
        </Space>
      </FormFieldsWrapper>
      <Tabs activeKey={activeTab} onChange={(tabId) => setActiveTab(tabId)}>
        <TabPane key="CHECK_IN" tab={<Tab>入站</Tab>}>
          <OutsourcingOrderTabPaneCheckIn />
        </TabPane>
        <TabPane key="CHECK_OUT" tab={<Tab>出站</Tab>}>
          <OutsourcingOrderTabPaneCheckOut />
        </TabPane>
        <TabPane key="INVENTORY" tab={<Tab>線邊倉庫存</Tab>}>
          <OutsourcingOrderTabPaneInventory />
        </TabPane>
      </Tabs>
    </div>
  );
}
