import {
  AutoCompleteField,
  AutoCompleteMultiField,
} from '@mezzanine-ui/react-hook-form';
import {
  useGetMaterialBatchesInInventory,
  useGetMaterialsInInventory,
} from '@solar/data';
import { useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { HorizontalField } from '../../HorizontalField';

interface FilterCallback<T> {
  (value: T, index: number, array: T[]): boolean;
}

interface MaterialAndBatchFieldProps {
  materialLabel: string;
  materialRegisterName: string;
  materialIdFilter?: FilterCallback<{
    materialId: string;
    materialDescription: string;
  }>;
  materialBatchLabel: string;
  materialBatchRegisterName: string;
  selectedMultipleBatches?: boolean;
  materialBatchesFilter?: FilterCallback<{
    materialId: string;
    materialDescription: string;
    sapBatchId: string;
    subBatchId: string;
    batchId: string;
  }>;
  withDeleted?: boolean;
}

export function MaterialAndBatchField({
  materialLabel,
  materialRegisterName,
  materialIdFilter = () => true,
  materialBatchLabel,
  materialBatchRegisterName,
  selectedMultipleBatches = false,
  materialBatchesFilter = () => true,
  withDeleted = false,
}: MaterialAndBatchFieldProps) {
  const methods = useFormContext();

  const material = useWatch({
    control: methods.control,
    name: materialRegisterName,
  });

  const { materials, refetchMaterialsInInventory } = useGetMaterialsInInventory(
    { withDeleted }
  );

  const { materialBatches, refetchMaterialBatchesInInventory } =
    useGetMaterialBatchesInInventory(material?.id, { withDeleted });

  const materialOptions = useMemo(
    () =>
      materials.filter(materialIdFilter).map((material) => ({
        id: material.materialId,
        name: material.materialId,
      })),
    [materialIdFilter, materials]
  );

  const materialBatchOptions = useMemo(
    () =>
      materialBatches.filter(materialBatchesFilter).map((batch) => ({
        id: batch.batchId,
        name: batch.batchId,
      })),
    [materialBatchesFilter, materialBatches]
  );

  const onMaterialSearch = useCallback(
    (searchTerm: string) => {
      refetchMaterialsInInventory({ searchTerm, withDeleted });
    },
    [refetchMaterialsInInventory, withDeleted]
  );

  const onMaterialChange = useCallback(() => {
    methods.setValue(materialBatchRegisterName, null);
  }, [materialBatchRegisterName, methods]);

  const onMaterialBatchSearch = useCallback(
    (searchTerm: string) => {
      refetchMaterialBatchesInInventory({ searchTerm, withDeleted });
    },
    [refetchMaterialBatchesInInventory, withDeleted]
  );

  const MaterialBatchAutoComplete = selectedMultipleBatches
    ? AutoCompleteMultiField
    : AutoCompleteField;

  return (
    <>
      <HorizontalField label={materialLabel ?? '料號'}>
        <AutoCompleteField
          registerName={materialRegisterName}
          options={materialOptions}
          onSearch={onMaterialSearch}
          onChange={onMaterialChange}
          required
        />
      </HorizontalField>
      <HorizontalField label={materialBatchLabel ?? '批號'}>
        <MaterialBatchAutoComplete
          disabled={!material}
          registerName={materialBatchRegisterName}
          options={materialBatchOptions}
          onSearch={onMaterialBatchSearch}
        />
      </HorizontalField>
    </>
  );
}
