import { API_NAMESPACE, request } from '../request';

const namespace = API_NAMESPACE.MATERIALS;

export async function updateProductionWorkOrderShip(
  orderId: string,
  items: any[]
) {
  const response = await request(
    `/warehouses/production-work-orders/ship/${orderId}`,
    {
      method: 'put',
      body: JSON.stringify({ items }),
      responseParser: (res) => res,
      namespace,
    }
  );
  const result = await response.json();
  return result;
}
