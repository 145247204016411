import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { SelectValue } from '@mezzanine-ui/react';

const namespace = API_NAMESPACE.MATERIALS;

export function useMaterialWasteCodes() {
  const { data, error } = useSWR<SelectValue[]>([
    '/material-properties/waste-codes',
    { namespace },
  ]);

  return {
    materialWasteCodes: data ?? [],
    isLoading: !error && !data,
  };
}
