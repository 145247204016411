import { SelectValue, Table } from '@mezzanine-ui/react';
import { FeatureCode, useGetMaterialFeatureCodes } from './hooks';
import { Space } from 'antd';
import { useForm, useWatch } from 'react-hook-form';
import {
  AutoCompleteField,
  FormFieldsWrapper,
} from '@mezzanine-ui/react-hook-form';
import { useMemo } from 'react';
import { uniqBy } from 'lodash';
import { TableColumn } from '@mezzanine-ui/core/table';

interface FilterValues {
  groupCode: SelectValue;
}

export function MaterialFeatureCodePanel() {
  const methods = useForm<FilterValues>();

  const { featureCodes, isLoading: isFeatureCodesLoading } =
    useGetMaterialFeatureCodes();

  const selectedGroupCode = useWatch({
    control: methods.control,
    name: 'groupCode',
  });

  const groupCodeOptions = uniqBy(
    featureCodes?.map((featureCode) => ({
      id: featureCode.materialGroup.id as string,
      name: `${featureCode.materialGroup.id}(${featureCode.materialGroup.name})`,
    })) ?? [],
    'id'
  );

  const dataSource = useMemo(
    () =>
      featureCodes?.filter((featureCode) =>
        selectedGroupCode
          ? featureCode.groupCode === selectedGroupCode.id
          : true
      ) ?? [],
    [featureCodes, selectedGroupCode]
  );

  return (
    <FormFieldsWrapper methods={methods}>
      <Space direction="vertical">
        <Space>
          <AutoCompleteField
            label="物料群組"
            registerName="groupCode"
            options={groupCodeOptions ?? []}
          />
        </Space>
        <Table
          loading={isFeatureCodesLoading}
          dataSource={dataSource}
          scroll={{ y: 550 }}
          columns={
            [
              { width: 150, title: '代碼', dataIndex: 'id' },
              {
                width: 150,
                title: '物料群組',
                dataIndex: 'groupCode',
              },
              { width: 150, title: '代碼', dataIndex: 'code' },
              { title: '對應值', dataIndex: 'name' },
            ] as TableColumn<FeatureCode>[]
          }
        />
      </Space>
    </FormFieldsWrapper>
  );
}
