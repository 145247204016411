import { Space } from 'antd';
import {
  LocationAllLevelSearch,
  LocationControlProvider,
  LocationPicker,
  LocationPickerModalButton,
} from '../LocationPicker';
import { HorizontalField } from '../HorizontalField';
import { useCallback, useEffect, useState } from 'react';
import {
  LocationControlContextValues,
  LocationIds,
  LocationLevel,
} from '../LocationPicker/types';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';
import { useGetLoaders } from './useGetLoaders';
import { SelectValue, Typography } from '@mezzanine-ui/react';
import { LocationLevels } from '../LocationPicker/LocationLevel';

interface LocationAndLoaderPickerProps {
  scopes?: LocationLevel[];
  locationIdLabel?: string;
  locationIdRegisterName: string;
  loaderIdLabel?: string;
  loaderIdRegisterName?: string;
  locationIdsRegisterName?: string;
  loaderShelfIdRegisterName?: string;
  disabledLoaderField?: boolean;
  checkLoaderPosition?: boolean;
}

export function LocationAndLoaderPickerField({
  scopes = [LocationLevel.ZONE, LocationLevel.STACK, LocationLevel.SHELF],
  locationIdLabel,
  locationIdRegisterName = 'locationId',
  locationIdsRegisterName = 'locationIds',
  loaderIdLabel,
  loaderIdRegisterName = 'loaderId',
  loaderShelfIdRegisterName = 'loaderShelfId',
  disabledLoaderField = false,
  checkLoaderPosition = false,
}: LocationAndLoaderPickerProps) {
  const methods = useFormContext();
  const [loaderSearchTerm, setLoaderSearchTerm] = useState<string>('');
  const [contextValues, setContextValues] = useState<
    LocationControlContextValues | undefined
  >();

  const { loaders, refetchLoaders } = useGetLoaders({});

  const onRefetchLoaders = useCallback(
    (locationIds?: LocationIds, searchTerm?: string) => {
      const shelfId =
        locationIds?.[LocationLevel.SHELF] ||
        locationIds?.[LocationLevel.STACK] ||
        locationIds?.[LocationLevel.ZONE];

      const shelfIds = checkLoaderPosition && shelfId ? [shelfId] : [];

      refetchLoaders({ searchTerm, shelfIds });
    },
    [checkLoaderPosition, refetchLoaders]
  );

  const { errors } = useFormState({
    name: locationIdsRegisterName,
    control: methods.control,
  });

  const locationIdErrorMsg = errors?.[locationIdRegisterName]?.message;
  const loaderIdErrorMsg = errors?.[loaderIdRegisterName]?.message;

  const _checkLocationScopes = useCallback(
    (locationIds: LocationIds) => {
      const isInScopes = scopes?.reduce(
        (result, scope) => result || locationIds?.[scope] !== null,
        false
      );

      if (!isInScopes) {
        methods.setError(locationIdRegisterName, {
          type: 'required',
          message: `請選擇${LocationLevels.filter((config) =>
            scopes?.some((scope) => scope === config.level)
          )
            .map((config) => config.label)
            .join('、')}範圍內的位置`,
        });
      } else {
        methods.clearErrors(locationIdRegisterName);
      }
    },
    [locationIdRegisterName, methods, scopes]
  );

  const _checkLoaderPosition = useCallback(
    (locationId?: string | null, loaderLocationId?: string) => {
      if (checkLoaderPosition && locationId !== loaderLocationId) {
        methods.setError(loaderIdRegisterName, {
          type: 'required',
          message: '載具不在選定位置上',
        });
      } else {
        methods.clearErrors(loaderIdRegisterName);
      }
    },
    [checkLoaderPosition, loaderIdRegisterName, methods]
  );

  const _onLocationChange = useCallback(
    (values: LocationControlContextValues) => {
      setContextValues(values);
      methods.setValue(locationIdRegisterName, values.activeId);
      methods.setValue(locationIdsRegisterName, values.locationIds);

      onRefetchLoaders(values.locationIds, loaderSearchTerm);

      _checkLocationScopes(values.locationIds);
      _checkLoaderPosition(
        values.activeId,
        methods.getValues(loaderShelfIdRegisterName)
      );
    },
    [
      methods,
      locationIdRegisterName,
      locationIdsRegisterName,
      onRefetchLoaders,
      loaderSearchTerm,
      _checkLocationScopes,
      _checkLoaderPosition,
      loaderShelfIdRegisterName,
    ]
  );

  const _onLoaderChange = useCallback(
    (option?: SelectValue) => {
      const loader = loaders.find(({ id }) => id === option?.id);
      methods.setValue(loaderShelfIdRegisterName, loader?.shelfId);

      if (!option) {
        methods.setError(loaderIdRegisterName, {
          type: 'required',
          message: '請選擇載具',
        });
        return;
      }

      const locationId = methods.getValues(locationIdRegisterName);
      _checkLoaderPosition(locationId, loader?.shelfId);
    },
    [
      _checkLoaderPosition,
      loaderIdRegisterName,
      loaderShelfIdRegisterName,
      loaders,
      locationIdRegisterName,
      methods,
    ]
  );

  const _onLoaderSearch = useCallback(
    (searchTerm: string) => {
      setLoaderSearchTerm(searchTerm);
      onRefetchLoaders(contextValues?.locationIds, searchTerm);
    },
    [onRefetchLoaders, contextValues?.locationIds]
  );

  const loaderShelfId = useWatch({
    name: loaderShelfIdRegisterName,
    control: methods.control,
  });

  return (
    <LocationControlProvider
      error={Boolean(locationIdErrorMsg)}
      onChange={_onLocationChange}
    >
      <HorizontalField label={locationIdLabel ?? '位置'}>
        <Space direction="vertical">
          <Space>
            <LocationAllLevelSearch />
            <LocationPickerModalButton />
          </Space>
          <LocationPicker />
          {methods.formState.errors?.[locationIdRegisterName] && (
            <span style={{ color: 'var(--mzn-color-error)' }}>
              {
                (methods.formState.errors[locationIdRegisterName]?.message ??
                  '') as string
              }
            </span>
          )}
        </Space>
      </HorizontalField>
      {!disabledLoaderField ? (
        <HorizontalField label={loaderIdLabel ?? '載具'}>
          <AutoCompleteField
            error={Boolean(loaderIdErrorMsg)}
            errorMsgRender={(error) => (
              <span style={{ color: 'var(--mzn-color-error)' }}>
                {error.message}
              </span>
            )}
            registerName={loaderIdRegisterName ?? 'loaderId'}
            options={loaders?.map(({ id }) => ({ id, name: id })) ?? []}
            onChange={_onLoaderChange}
            onSearch={_onLoaderSearch}
          />
          {loaderShelfId && (
            <Typography variant="h5">目前載具位置：{loaderShelfId}</Typography>
          )}
        </HorizontalField>
      ) : null}
    </LocationControlProvider>
  );
}
