import { API_NAMESPACE, request } from 'libs/data/src/request';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import { yupResolver } from '@hookform/resolvers/yup';
import { exportSchemas } from './export-schemas';
import { exportValidation } from './export-validation';
import { Message } from '@mezzanine-ui/react';

const namespace = API_NAMESPACE.HR;

const exportListUrl = '/reports/hr/salaries/export';
const companyListUrl = '/reports/hr/salaries/companyList';

export const getYearOptions = (startYear: number) => {
  const currentYear = new Date().getFullYear();
  const arr = [];
  for (let i = startYear; i <= currentYear; i++) {
    arr.push({ id: `${i}`, name: `${i}` });
  }
  return arr;
};

export function useHumanResourceEmp() {
  const defaultValues = {
    [exportSchemas.COMPANY_ID]: null,
    [exportSchemas.YEAR]: null,
    [exportSchemas.MONTH]: null,
    [exportSchemas.NAME]: '',
    [exportSchemas.CREATE_DATE]: '',
  };

  const methods = useForm<typeof defaultValues>({
    defaultValues,
    resolver: yupResolver(exportValidation()),
    mode: 'onChange',
  });
  const {
    control,
    getValues,
    formState: { isValid },
  } = methods;

  const [modal, setModal] = useState<boolean>(false);
  const [companyListData, setCompanyListData] = useState<
    CompanyList[] | undefined
  >(undefined);

  const { data: swrDataCompanyList } = useSWR([companyListUrl, { namespace }]);

  const monthOptions = [
    { id: '01', name: '01' },
    { id: '02', name: '02' },
    { id: '03', name: '03' },
    { id: '04', name: '04' },
    { id: '05', name: '05' },
    { id: '06', name: '06' },
    { id: '07', name: '07' },
    { id: '08', name: '08' },
    { id: '09', name: '09' },
    { id: '10', name: '10' },
    { id: '11', name: '11' },
    { id: '12', name: '12' },
  ];

  const handleOpenModal = () => {
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const onSubmit = async () => {
    const value = control._formValues;
    const company = getValues(
      exportSchemas.COMPANY_ID
    ) as unknown as CompanyList;
    const year = getValues(exportSchemas.YEAR) as unknown as CompanyList;
    const month = getValues(exportSchemas.MONTH) as unknown as CompanyList;
    const createDate = getValues(
      exportSchemas.CREATE_DATE
    ) as unknown as string;
    const formatCreateDate = createDate.split('T')[0].replace(/-/g, '/');
    const body = JSON.stringify({
      ...value,
      year: year.id,
      month: month.id,
      companyId: company.id,
      createDate: formatCreateDate,
    });
    try {
      await request(`${exportListUrl}`, { namespace, method: 'POST', body });
      Message['success']('製作中，請至薪資傳票匯出下載頁面檢視');
    } catch (error) {
      if (error instanceof Error) {
        Message['error'](JSON.parse(error.message).message);
      }
      Message['error']('失敗，請再試一次');
    }
    handleCloseModal();
  };

  useEffect(() => {
    if (isCompanyListData(swrDataCompanyList))
      setCompanyListData(swrDataCompanyList);
  }, [swrDataCompanyList]);

  return {
    methods,
    modal,
    companyListData,
    disabledOpenModalButton: !isValid,
    disableSubmit: !isValid || getValues(exportSchemas.NAME) === '',
    yearOptions: getYearOptions(1970),
    monthOptions,
    handleOpenModal,
    handleCloseModal,
    onSubmit,
  };
}

type CompanyList = {
  id: string;
  name: string;
};

function isCompanyListData(obj: unknown): obj is CompanyList[] {
  if (obj && Array.isArray(obj)) return obj.every((item) => item.id);
  return false;
}
