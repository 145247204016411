import { ExclamationCircleFilledIcon } from '@mezzanine-ui/icons';
import {
  Icon,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  Typography,
} from '@mezzanine-ui/react';
import { ModalLayoutProps } from '../ModalLayout';
import styles from './DeleteModal.module.scss';

type DeleteModalProps = Pick<
  ModalLayoutProps<any>,
  'open' | 'onClose' | 'source' | 'onConfirm' | 'deleteModalChildren'
>;

export function DeleteModal({
  open,
  onClose,
  source,
  onConfirm,
  deleteModalChildren,
}: DeleteModalProps) {
  return (
    <Modal open={open} onClose={onClose} style={{ width: 768 }} hideCloseIcon>
      <ModalHeader>
        <div
          className={styles.modalHeaderDiv}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 'var(--mzn-spacing-4)',
          }}
        >
          <Icon color="error" icon={ExclamationCircleFilledIcon} />
          <Typography variant="h3">確定刪除此內容？此操作無法還原。</Typography>
        </div>
      </ModalHeader>
      <ModalBody>{deleteModalChildren}</ModalBody>
      <ModalActions
        cancelText="取消"
        onCancel={onClose}
        confirmText="刪除"
        onConfirm={onConfirm}
        confirmButtonProps={{ danger: true }}
      />
    </Modal>
  );
}
