import { Tab, TabPane, Tabs, Typography } from '@mezzanine-ui/react';
import { useQueryString } from '../hooks/useQueryString';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import { WarehouseModule, WarehouseModuleZh } from '../constant';
import { tabsOptions } from './constant/tabs';

import { OperationHistoryPane } from './OperationHistoryPane';
import { PageLayout } from '../PageLayout/PageLayout';
import { useEffect } from 'react';
import { toDashConcatCase } from '../utils/toDashConcatCase';

export function OperationHistoryPage() {
  const { tab } = useQueryString();
  const navigate = useNavigate();

  useEffect(() => {
    if (!tab) {
      navigate({
        pathname: '/warehouse/operation-history',
        search: qs.stringify({ tab: toDashConcatCase(tabsOptions[0].id) }),
      });
    }
  }, [navigate, tab]);

  return (
    <PageLayout>
      <Typography variant="h1">
        {WarehouseModuleZh[WarehouseModule.OperationHistory]}
      </Typography>
      <Tabs
        activeKey={tab}
        onChange={(tabId) => {
          navigate({
            pathname: '/warehouse/operation-history',
            search: qs.stringify({ tab: tabId }),
          });
        }}
      >
        {tabsOptions.map((tab) => (
          <TabPane key={toDashConcatCase(tab.id)} tab={<Tab>{tab.name}</Tab>}>
            <OperationHistoryPane />
          </TabPane>
        ))}
      </Tabs>
    </PageLayout>
  );
}
