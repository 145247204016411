import { TableColumn } from '@mezzanine-ui/core/table';
import { Table, Typography } from '@mezzanine-ui/react';
import { useMemo } from 'react';
import { PageLayout } from '../PageLayout/PageLayout';
import { TEMP_CREATE_CONFIGURE_TABLE } from './const';
import { ConfigureTableProps } from './typings';

export function MaterialCodeConfigurationDetailPage() {
  const columns: TableColumn<ConfigureTableProps>[] = useMemo(
    () => [
      {
        title: '新料號',
        dataIndex: 'materialId',
      },
      {
        title: '新品名',
        dataIndex: 'materialDescription',
      },
      {
        title: '原料號',
        dataIndex: 'batchId',
      },
      {
        title: '原品名',
        dataIndex: 'notes',
      },
      {
        title: '批號',
        dataIndex: 'stackId',
      },
      {
        title: '流水序',
        dataIndex: 'loaderId',
      },
      {
        title: '儲位',
        dataIndex: 'stackId',
      },
      {
        title: '載具',
        dataIndex: 'loaderId',
      },
    ],
    []
  );
  return (
    <PageLayout setGoBack>
      <Typography variant="h1">MCR23090001</Typography>
      <div
        style={{
          display: 'flex',
          gap: 'var(--mzn-spacing-4)',
        }}
      >
        <span>建立日期：2023/09/01</span>
        <span>建立者：李南暹</span>
      </div>
      <div style={{ width: '100%', overflow: 'auto' }}>
        <Table
          columns={columns}
          dataSource={TEMP_CREATE_CONFIGURE_TABLE}
          scroll={{ fixedFirstColumn: true, x: 2500 }}
        />
      </div>
      <br />
    </PageLayout>
  );
}
