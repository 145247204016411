import {
  environment,
  ProductionParameterResponse,
  ProductionFormCondition,
} from '@solar/data';
import { useState, useEffect } from 'react';
import { uniqBy } from 'lodash';

export function useProductionParameters(
  conditions?: ProductionFormCondition[]
) {
  const [parameters, setParameters] = useState<ProductionParameterResponse[]>(
    []
  );

  useEffect(() => {
    let cancelled = false;

    conditions
      ?.map(
        ({ materialId, recipeId, processingId, workCenterId, equipmentId }) =>
          async (results: ProductionParameterResponse[]) => {
            const queryString = (() => {
              const params = new URLSearchParams();

              if (materialId) {
                params.append('materialId', materialId);
              }

              if (recipeId) {
                params.append('recipeId', recipeId);
              }

              if (processingId) {
                params.append('processingId', processingId);
              }

              if (workCenterId) {
                params.append('workCenterId', workCenterId);
              }

              if (equipmentId) {
                params.append('equipmentId', equipmentId);
              }

              return params.toString();
            })();

            if (!queryString) return results;

            const data = (await fetch(
              `${environment.API_HOST}${
                process.env['NX_API_PREFIX_PP'] ?? '/pp/pp'
              }/parameters?${queryString}`,
              {
                headers: {
                  apikey: environment.API_KEY,
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  ...(localStorage.getItem('accessToken')
                    ? {
                        Authorization: `Bearer ${localStorage.getItem(
                          'accessToken'
                        )}`,
                      }
                    : {}),
                },
              }
            ).then((res) => res.json())) as ProductionParameterResponse[];

            if (!Array.isArray(data)) return results;

            return [...results, ...data] as ProductionParameterResponse[];
          }
      )
      .reduce(
        (prev, next) => prev.then(next),
        Promise.resolve([] as ProductionParameterResponse[])
      )
      .then((responses) => {
        if (cancelled) return;

        setParameters(uniqBy(responses, (row) => row.id));
      });

    return () => {
      cancelled = true;
    };
  }, [conditions]);

  return parameters;
}
