import { getJWTPayload } from '@solar/data';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

const CHECK_INTERVAL = 1000 * 60 * 5; // 5 minutes

export function useCheckTokenInterval() {
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      const jwtPayload = getJWTPayload();
      if (
        !jwtPayload ||
        (jwtPayload?.exp && Date.now() >= jwtPayload?.exp * 1000)
      ) {
        navigate('/auth/login');
      }
    }, CHECK_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [navigate]);
}
