import { request, API_NAMESPACE } from '../../request';
import * as yup from 'yup';
import useSWR from 'swr';

const materialFilterQuery = yup.object().shape({
  materialKeyword: yup.string(),
});

export type MaterialFilterQuery = yup.InferType<typeof materialFilterQuery>;

export type CustomerFilterData = {
  id: string;
  name: string;
};

export type MaterialCategoryFilterData = {
  name: string;
  id?: string;
  children?: MaterialCategoryFilterData[];
};

export type MaterialFilterData = {
  id: string;
  name: string;
};

export type CustomerTradeConditionFilterPayload = {
  materials: MaterialFilterData[];
  customers: CustomerFilterData[];
  materialCategories: MaterialCategoryFilterData[];
};

export function useCustomerTradeConditionFilterGet(
  query?: MaterialFilterQuery
) {
  const {
    data: swrData,
    mutate,
    isValidating,
  } = useSWR<CustomerTradeConditionFilterPayload>(
    validateSWRKey(query),
    request,
    {
      revalidateOnFocus: false,
    }
  );

  const { materials, customers, materialCategories } = swrData ?? {};

  return {
    materials: transformMaterialsData(materials ?? []),
    customers: transformCustomersData(customers ?? []),
    materialCategories: materialCategories ?? [],
    isLoading: isValidating,
    refetchFilterCondition: mutate,
  };
}

function validateSWRKey(query?: MaterialFilterQuery) {
  try {
    const safeQuery = materialFilterQuery.validateSync(query);

    return [
      '/customers/customer-trade-condition/filter',
      { namespace: API_NAMESPACE.HR, params: safeQuery },
    ];
  } catch (error) {
    return false;
  }
}

function transformCustomersData(customers: CustomerFilterData[]) {
  return customers?.map((customer) => ({
    ...customer,
    name: `${customer.name}（${customer.id}）`,
  }));
}

function transformMaterialsData(materials: MaterialFilterData[]) {
  return materials?.map((material) => ({
    ...material,
    name: `${material.id}（${material.name}）`,
  }));
}
