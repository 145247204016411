import { SelectValue } from '@mezzanine-ui/react';
import { OffsetBased } from '../pagination';

export type GetMaterialResolvesParams = OffsetBased & {
  groupCode: string;
  bigClassCode: string;
  mediumClassCode: string;
  smallClassCode: string;
  materialSourceCode: string;
  componentCode: string;
  featureCodeOne: string;
  featureCodeTwo: string;
  drawingCode: string;
  sizeCodeOne: string;
  sizeCodeTwo: string;
  sizeCodeThree: string;
  machiningCode: string;
  samplingCode: string;
  packagingCode: string;
  wasteCode: string;
  idPrefix: string;
};

type SelectValueForForm = {
  id: SelectValue['id'];
  name?: SelectValue['name'];
};

export type MaterialResolvesFilter = OffsetBased & {
  resolveId?: string | null;
  groupCode?: SelectValueForForm | null;
  bigCode?: SelectValueForForm | null;
  mediumCode?: SelectValueForForm | null;
  smallCode?: SelectValueForForm | null;
  sourceCode?: SelectValueForForm | null;
  featureCodeOne?: SelectValueForForm | null;
  featureCodeTwo?: SelectValueForForm | null;
  machiningCode?: SelectValueForForm | null;
  samplingCode?: SelectValueForForm | null;
  wasteCode?: SelectValueForForm | null;
  componentCode?: SelectValueForForm | null;
  drawingCode?: string | null;
  sizeCodeOne?: string | null;
  sizeCodeTwo?: string | null;
  sizeCodeThree?: string | null;
  packagingCode?: string | null;
};

export type ParseMaterialIdParams = {
  id: string;
  materialIdLength: string;
  groupCode: string;
  group: SelectValue & { code: string };
  bigClassCode: string;
  mediumClassCode: string;
  smallClassCode: string;
  class: {
    id: string;
    groupId: string;
    bigCode: string | null;
    bigCodeName: string | null;
    mediumCode: string | null;
    mediumCodeName: string | null;
    smallCode: string | null;
    smallCodeName: string | null;
  } | null;
  materialSourceCode: string;
  source: SelectValue | null;
  componentCode: string;
  featureCodeOne: string;
  featureOne: (SelectValue & { code: string; groupCode: string }) | null;
  featureCodeTwo: string;
  featureTwo: (SelectValue & { code: string; groupCode: string }) | null;
  drawingCode: string;
  sizeCodeOne: string;
  sizeCodeTwo: string;
  sizeCodeThree: string;
  machining: (SelectValue & { code: string }) | null;
  machiningCode: string;
  sampling: SelectValue | null;
  samplingCode: string;
  wasteCode: string;
  waste: SelectValue | null;
  packagineCode: string;
  pcn: string;
  sequenceNumber: string;
  recipeCodePrefix: string;
};
