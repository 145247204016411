import { TableDataSourceWithID } from '@mezzanine-ui/core/table';
import useSWR from 'swr';

import { API_NAMESPACE } from '../request';
import type { MaterialRequestOrder } from './useMaterialRequestOrders';

export interface MaterialRequestOrderSpec extends TableDataSourceWithID {
  id: number;
  materialId: string;
  materialSapBatchId: string;
  materialBatchId: string;
  expectedMainMaterialUnitQuantity: string;
  expectedMaterialStockUnitQuantity: string;
  actualMainMaterialUnitQuantity: string;
  materialRequestOrderId: number;
  mainMaterialUnit: string;
  description: string;
  loaderId: number | null;
  loader: {
    shelf: {
      id: string;
      name: string;
      stackId: string;
    };
  };
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  material?: {
    id: string;
    description: string;
    mainMaterialUnitId: number;
    secondaryMaterialUnitId: number;
    categoryId: number;
    groupId: null;
    sourceId: null;
    oldMaterialId: string;
  };
}

export interface WorkOrderInto extends TableDataSourceWithID {
  id: number;
  workOrderId: string;
  creatorId: string;
  sourceType: 'REQUEST_ORDER';
  expectedCompletedAt: string;
  actualCompletedAt: string | null;
  barcode: string;
  materialRequestOrderSpecs: MaterialRequestOrderSpec[];
  materialRequestOrderBatches: [
    {
      id: number;
      loaderId: string;
      materialBarcode: string | null;
      materialBatchId: string;
      materialId: string;
      materialRequestOrderId: string;
      materialSapBatchId: string;
      used: boolean;
    }
  ];
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

const namespace = API_NAMESPACE.MATERIALS;

export function useMaterialRequestOrder({ orderId }: { orderId?: string }) {
  const { data, error } = useSWR<MaterialRequestOrder & WorkOrderInto>(
    orderId
      ? [`/warehouses/material-request-orders/${orderId}`, { namespace }]
      : null
  );

  return {
    data: data,
    isLoading: !error && !data,
  };
}
