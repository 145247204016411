import type { HubOrderUpdateFormValues } from '@solar/data';
import { HubOrderUpdateFormFieldName as FormFieldName } from '@solar/data';
import { useFormContext } from 'react-hook-form';
import { DatePickerField } from '@mezzanine-ui/react-hook-form';

export function OrderAccountDatePicker({ debitDate }: { debitDate?: string }) {
  const formMethods = useFormContext<HubOrderUpdateFormValues>();

  return (
    <>
      <div>扣帳日期：</div>
      <div>{debitDate ? debitDate : '-'}</div>
    </>
  );
}
