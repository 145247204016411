import { Equipment, LocationLevel, Stack, UseLocationSelector, ZoneEntity, getEquipmentByChartId, getStackByChartId, useCurrentLocationOptions } from "@solar/data";
import { Fragment, useEffect, useRef, useState } from "react";
import styles from './location-svg-viewer.module.scss';
import { Button, Icon } from "@mezzanine-ui/react";
import { ChevronLeftIcon } from "@mezzanine-ui/icons";
import { Popover } from "antd";
import polylabel from "polylabel";

type LocationSVGViewerProps = {
  svg: UseLocationSelector['svg'],
  selectedChartId?: string,
  currentLevel: UseLocationSelector['currentLevel'],
  currentLocation?: UseLocationSelector['currentLocation'],
  handlePrevLevel?: UseLocationSelector['handlePrevLevel'],
  handleNextLevel?: UseLocationSelector['handleNextLevel'],
  handleNextCurrentLocation?: UseLocationSelector['handleNextCurrentLocation'],
  width?: string,
  height?: string,
}

export const transformZoneToLocation = (zone?: ZoneEntity) => {
  const area = zone?.area;
  const floor = area?.floor;
  const building = floor?.building;
  const factory = building?.factory;
  return {
    zone: zone ? {
      id: zone?.id,
      name: zone?.name,
    } : undefined,
    area: area ? {
      id: area?.id,
      name: area?.name
    } : undefined,
    floor: floor ? {
      id: floor?.id,
      name: floor?.name,
    } : undefined,
    building: building ? {
      id: building?.id,
      name: building?.name,
    } : undefined,
    factory: factory ? {
      id: factory?.id,
      name: factory?.name,
    } : undefined,
  };
};

type CardDetail = {
  show: boolean,
  isBinding: boolean;
  chartId?: string;
  equipments?: Equipment[];
  stack?: Stack;
} | null;

export function LocationSVGViewer({
  svg,
  currentLevel,
  currentLocation,
  handlePrevLevel,
  handleNextLevel,
  selectedChartId: defaultSelectedChartId,
  width,
  height,
}: LocationSVGViewerProps) {
  const { zoneOptions, getTargetOption } = useCurrentLocationOptions(currentLocation ?? {});
  const [cardDetail, setCardDetail] = useState<CardDetail>({ show: false, isBinding: false });
  const svgWrapperRef = useRef<HTMLDivElement>(null);
  const popoverWrapperRef = useRef<HTMLDivElement>(null);
  const [popoverPosition, setPopoverPosition] = useState<{left: number, top: number}>();
  const [selectedChartId, setSelectedChartId] = useState(defaultSelectedChartId);
  const [polygons, setPolygons] = useState<NodeListOf<Element>>();

  function makeBG(text: SVGTextElement) {
    const bounds = text.getBBox();
    const bg = document.createElementNS("http://www.w3.org/2000/svg", "rect")
    const padding = 5;
    bg.setAttribute("x", (bounds.x - padding).toString());
    bg.setAttribute("y", (bounds.y - padding).toString());
    bg.setAttribute("width", (bounds.width + padding * 2).toString());
    bg.setAttribute("height", (bounds.height + padding * 2).toString());
    bg.setAttribute("fill", "white");
    bg.setAttribute("rx", "5");
    bg.setAttribute("stroke-width", "1px");
    bg.setAttribute("stroke", "black");
    bg.setAttribute("pointer-events", "none");
    text?.parentNode?.append(bg, text);
  }

  useEffect(() => {
    if (svg) {
      const nodeList = svgWrapperRef.current?.querySelectorAll('polygon');
      const svgNode = svgWrapperRef.current?.querySelector('svg');
      nodeList?.forEach((node) => {
        const nodeId = node.getAttribute('id');
        /** @description: 移除預設的透明效果 */
        node.removeAttribute("opacity");

        /** @description: 在 svg 裡顯示 name，並加上白底 */
        if (node?.points?.length >= 1) {
          const points = [];
          for (let i = 0; i < node?.points?.length; i++) {
            points.push([node?.points[i]?.x, node?.points[i]?.y]);
          }
          const position = polylabel([points], 1);
          const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
          const targetItem = getTargetOption(currentLevel, nodeId);
          text.setAttribute('text-id', nodeId ?? '');
          text.setAttribute('x', (position[0]).toString());
          text.setAttribute('y', (position[1]).toString());
          text.setAttribute('text-anchor', 'middle');
          text.textContent = targetItem?.name ?? '';
          svgNode?.appendChild(text);
          makeBG(text);
        }

        /** @description: 在 area（大區）圖層中，取消 zone（群落、庫位）觸發 pointer-events，為了確保只有 equipments 和 stacks 可以觸發 click event */
        if (!zoneOptions?.find((option) => option?.id === nodeId)) {
          node.parentNode?.appendChild(node);
        };

        /** @description: 預設 highlight 某個 ChartId */
        node.setAttribute('active', (nodeId === selectedChartId).toString());
      });
      setPolygons(nodeList);
    }
  }, [currentLevel, currentLocation, getTargetOption, selectedChartId, svg, zoneOptions]);

  useEffect(() => {
    const handleClick = async (event: any) => {
      const nodeId = event?.target?.getAttribute('id');
      const nodeCategory = event?.target?.getAttribute('category');
      setSelectedChartId(nodeId);
      switch (nodeCategory?.toLowerCase()) {
        case 'equipment': {
          const equipments = await getEquipmentByChartId({ chartId: nodeId });

          handleNextLevel?.({ chartId: nodeId, equipments });
          break;
        }
        case 'warehouse': {
          const stack = await getStackByChartId({ chartId: nodeId });
          handleNextLevel?.({ chartId: nodeId, stack });
          break;
        }
        default: {
          handleNextLevel?.(getTargetOption(currentLevel, nodeId));
          break;
        }
      }
    };

    const handleMouseEnter = async (event: any) => {
      const nodeId = event?.target?.getAttribute('id');
      const nodeRect = event?.target?.getBoundingClientRect();
      const anchorRect = popoverWrapperRef.current?.getBoundingClientRect();
      setPopoverPosition({
        left: nodeRect?.x + nodeRect?.width / 2 - (anchorRect?.x ?? 0),
        top: nodeRect?.y - (anchorRect?.y ?? 0),
      });

      const equipments = await getEquipmentByChartId({ chartId: nodeId }).catch(() => {
        console.warn('Equipment not found');
      });

      const stack = await getStackByChartId({ chartId: nodeId }).catch(() => {
        console.warn('Stack not found');
      });

      setCardDetail({
        show: true,
        isBinding: equipments?.length > 0 || !!stack,
        chartId: nodeId,
        equipments: equipments ?? [],
        stack: stack ?? undefined,
      });
    }

    const handleMouseOut = async () => {
      setCardDetail(null);
    }

    const elements = Array.from(polygons ?? []);

    const handleMouseMove = (event: MouseEvent) => {
      const isInsideElement = elements.reduce((result, element) => element.contains(event.target as Element) || result, false);
      if (!isInsideElement) setCardDetail(null);
    };

    elements.forEach((element) => {
      element.addEventListener('click', handleClick);
      element.addEventListener('mouseenter', handleMouseEnter);
      element.addEventListener('mouseout', handleMouseOut);
    });

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      elements.forEach((element) => {
        element.removeEventListener('click', handleClick);
        element.removeEventListener('mouseenter', handleMouseEnter);
        element.removeEventListener('mouseout', handleMouseOut);
      });
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [currentLevel, currentLocation, getTargetOption, handleNextLevel, polygons]);

  return (
    <div className={styles.svg_viewer}>
      {handlePrevLevel && (
        <div>
          {currentLevel === LocationLevel.AREA && (
            <Button
              prefix={<Icon icon={ChevronLeftIcon} />}
              onClick={handlePrevLevel}>
              返回選擇棟別
            </Button>
          )}
          {currentLevel === LocationLevel.ZONE && (
            <Button
              prefix={<Icon icon={ChevronLeftIcon} />}
              onClick={handlePrevLevel}>
              返回選擇大區
            </Button>
          )}
        </div>
      )}
      <div className={styles.svg_with_popover} style={{ width, height }}>
        <div ref={popoverWrapperRef} className={styles.popover_wrapper} onMouseOut={() => setCardDetail(null)}>
          {cardDetail && (
            <Popover
              trigger="focus"
              title="設備/儲位"
              style={{ pointerEvents: 'none' }}
              open={(cardDetail?.stack || cardDetail?.equipments) && cardDetail?.show}
              content={cardDetail?.stack?.id || cardDetail?.equipments?.length ? (
                <div>
                  <p>位置：{cardDetail?.chartId}</p>
                  {cardDetail?.stack?.name ? <p>儲位名稱：{cardDetail?.stack?.name ?? ''}</p> : null}
                  {cardDetail?.equipments?.length ? (
                    <Fragment>
                      <p>設備名稱：</p>
                      <ul className={styles.equipmentList}>
                        {cardDetail?.equipments?.map((equipment) => (<li>{equipment?.name}</li>))}
                      </ul>
                    </Fragment>
                  ) : null}
                </div>
              ) : (
                <div>
                  <p>位置：{cardDetail?.chartId}</p>
                  <p>未綁定</p>
                </div>
              )}
            >
              <div style={{ position: 'absolute', ...popoverPosition }} />
            </Popover>
          )}
        </div>
        <div
          ref={svgWrapperRef}
          style={{ width, height, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          className={styles.svg_wrapper}
          dangerouslySetInnerHTML={{ __html: svg?.statusCode === 404 ? '圖片不存在' : svg }} />
      </div>
    </div>
  )
}

LocationSVGViewer.defaultProps = {
  width: '800px',
  height: '600px',
};
