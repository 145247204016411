import {
  Button,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
} from '@mezzanine-ui/react';
import { DeleteModal } from './DeleteModal/DeleteModal';

export interface ModalLayoutProps<T> {
  title?: string;
  open: T | null;
  onClose: VoidFunction;
  width?: number | string;
  children: JSX.Element;
  confirmButtonText?: string;
  onConfirm: VoidFunction;
  onConfirmDelete: VoidFunction;
  confirmButtonDisable: boolean;
  deleteButton: boolean;
  source?: any;
  deleteModal: any;
  setDeleteModal: any;
  deleteModalChildren: JSX.Element;
  othersButton?: JSX.Element;
}

export function ModalLayout<T>({
  title,
  open,
  onClose,
  width,
  children,
  confirmButtonText,
  onConfirm,
  onConfirmDelete,
  confirmButtonDisable,
  deleteButton,
  source,
  deleteModal,
  setDeleteModal,
  deleteModalChildren,
  othersButton,
}: ModalLayoutProps<T>) {
  return (
    <Modal
      open={open !== null}
      onClose={onClose}
      style={{ width: width ? width : 'auto' }}
    >
      <DeleteModal
        open={deleteModal !== null}
        onClose={() => setDeleteModal(null)}
        onConfirm={onConfirmDelete}
        source={source}
        deleteModalChildren={deleteModalChildren}
      />
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalActions
        cancelText="取消"
        onCancel={onClose}
        confirmText={confirmButtonText}
        onConfirm={onConfirm}
        confirmButtonProps={{
          disabled: confirmButtonDisable,
        }}
      >
        {othersButton}
        {deleteButton && (
          <Button
            variant="text"
            size="medium"
            danger
            type="button"
            onClick={() => setDeleteModal(source)}
          >
            刪除
          </Button>
        )}
      </ModalActions>
    </Modal>
  );
}
