import {
  DateRangePickerField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import classes from './filter.module.scss';
import { useLocation } from 'react-router-dom';
import { CustomInputSearch } from '@solar/templates';
import { RETURN_ORDER_TYPES } from './return-order-type';
import { pageOptions } from './page-options';

export function Filter() {
  const location = useLocation();

  return (
    <div className={classes.host}>
      <CustomInputSearch />
      <DateRangePickerField
        fullWidth
        label="日期區間："
        registerName="dateRange"
      />
      <SelectField
        label={
          [pageOptions[0].id, pageOptions[1].id, pageOptions[2].id].includes(
            location.pathname.split('/').at(-1) || ''
          )
            ? '供應商名稱：'
            : '客戶名稱：'
        }
        mode="multiple"
        clearable
        registerName="suppliers"
        options={[]}
      />
      {location.pathname.split('/').at(-1) === 'purchase-order' && (
        <SelectField
          label="類型："
          mode="single"
          clearable
          registerName="status"
          options={[...RETURN_ORDER_TYPES, { id: 'ALL', name: 'ALL' }]}
        />
      )}
    </div>
  );
}
