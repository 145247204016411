import { TableColumn } from '@mezzanine-ui/core/table';
import { WarehouseModule } from '../../constant';
import { ReceivedMaterialItemTable } from '../../IncomingInspectOperationPage/IncomingInspectDetailPage';
import { OperationHistoryBaseColumn } from '../typings';
import { Button } from '@mezzanine-ui/react';
import { useMemo, useCallback } from 'react';

export function useTableColumn(onClickCancel: any) {
  const CanCelButton = useCallback(
    () => (
      <Button
        type="button"
        variant="outlined"
        onClick={() => onClickCancel?.()}
      >
        取消操作
      </Button>
    ),
    [onClickCancel]
  );

  const column = useMemo(
    () => ({
      [WarehouseModule.MaterialStorageIn]: [
        { title: '物料文件編號', dataIndex: 'materialId' },
        { title: '操作類型', dataIndex: 'actionType' },
        { title: '來源單號', dataIndex: 'orderId' },
        { title: '物料料號', dataIndex: 'materialId' },
        { title: '品名', dataIndex: 'materialDescription' },
        { title: '入庫儲位', dataIndex: 'shelfId' },
        { title: '批號', dataIndex: 'batchId' },
        { title: '載具編號', dataIndex: 'loaderId' },
        { title: '數量', dataIndex: 'mainMaterialUnitQuantity' },
        { title: '操作人員', dataIndex: 'staffName' },
        { title: '操作時間', dataIndex: 'operatedAt' },
        {
          title: '',
          dataIndex: 'cancel',
          render: () => <CanCelButton />,
        },
      ] as TableColumn<
        ReceivedMaterialItemTable & Partial<OperationHistoryBaseColumn>
      >[],
      [WarehouseModule.WarehouseDispatch]: [
        { title: '物料文件編號', dataIndex: 'materialFileId' },
        { title: '操作類型', dataIndex: 'actionType' },
        { title: '來源單號', dataIndex: 'orderId' },
        { title: '物料料號', dataIndex: 'materialId' },
        { title: '品名', dataIndex: 'materialDescription' },
        { title: '領出儲位', dataIndex: 'shelfId' },
        { title: '批號', dataIndex: 'batchId' },
        { title: '載具編號', dataIndex: 'loaderId' },
        { title: '數量', dataIndex: 'mainMaterialUnitQuantity' },
        { title: '操作人員', dataIndex: 'staffName' },
        { title: '操作時間', dataIndex: 'operatedAt' },
        {
          title: '',
          dataIndex: 'cancel',
          render: () => <CanCelButton />,
        },
      ] as TableColumn<
        ReceivedMaterialItemTable & Partial<OperationHistoryBaseColumn>
      >[],
      [WarehouseModule.GeneratedStorageIn]: [
        { title: '物料文件編號', dataIndex: 'materialFileId' },
        { title: '來源單號', dataIndex: 'orderId' },
        { title: '物料料號', dataIndex: 'materialId' },
        { title: '品名', dataIndex: 'materialDescription' },
        { title: '入庫儲位', dataIndex: 'shelfId' },
        { title: '批號', dataIndex: 'batchId' },
        { title: '載具編號', dataIndex: 'loaderId' },
        { title: '數量', dataIndex: 'mainMaterialUnitQuantity' },
        { title: '操作人員', dataIndex: 'staffName' },
        { title: '操作時間', dataIndex: 'operatedAt' },
        {
          title: '',
          dataIndex: 'cancel',
          render: () => <CanCelButton />,
        },
      ] as TableColumn<
        ReceivedMaterialItemTable & Partial<OperationHistoryBaseColumn>
      >[],
      [WarehouseModule.MaterialShift]: [
        { title: '物料文件編號', dataIndex: 'materialFileId' },
        { title: '物料料號', dataIndex: 'materialId' },
        { title: '品名', dataIndex: 'materialDescription' },
        { title: '存入儲位', dataIndex: 'shelfId' },
        { title: '批號', dataIndex: 'batchId' },
        { title: '載具編號', dataIndex: 'loaderId' },
        { title: '數量', dataIndex: 'mainMaterialUnitQuantity' },
        { title: '操作人員', dataIndex: 'staffName' },
        { title: '操作時間', dataIndex: 'operatedAt' },
        {
          title: '',
          dataIndex: 'cancel',
          render: () => <CanCelButton />,
        },
      ] as TableColumn<
        ReceivedMaterialItemTable & Partial<OperationHistoryBaseColumn>
      >[],
      [WarehouseModule.MiscellaneousStorageIn]: [
        { title: '物料文件編號', dataIndex: 'materialFileId' },
        { title: '來源單號', dataIndex: 'orderId' },
        { title: '物料料號', dataIndex: 'materialId' },
        { title: '品名', dataIndex: 'materialDescription' },
        { title: '入庫儲位', dataIndex: 'shelfId' },
        { title: '批號', dataIndex: 'batchId' },
        { title: '載具編號', dataIndex: 'loaderId' },
        { title: '數量', dataIndex: 'mainMaterialUnitQuantity' },
        { title: '操作人員', dataIndex: 'staffName' },
        { title: '操作時間', dataIndex: 'operatedAt' },
        {
          title: '',
          dataIndex: 'cancel',
          render: () => <CanCelButton />,
        },
      ] as TableColumn<
        ReceivedMaterialItemTable & Partial<OperationHistoryBaseColumn>
      >[],
      [WarehouseModule.MiscellaneousDispatchAndReturn]: [
        { title: '物料文件編號', dataIndex: 'materialFileId' },
        { title: '操作類型', dataIndex: 'actionType' },
        { title: '物料料號', dataIndex: 'materialId' },
        { title: '品名', dataIndex: 'materialDescription' },
        { title: '儲位', dataIndex: 'shelfId' },
        { title: '批號', dataIndex: 'batchId' },
        { title: '載具編號', dataIndex: 'loaderId' },
        { title: '數量', dataIndex: 'mainMaterialUnitQuantity' },
        { title: '操作人員', dataIndex: 'staffName' },
        { title: '操作時間', dataIndex: 'operatedAt' },
        {
          title: '',
          dataIndex: 'cancel',
          render: () => <CanCelButton />,
        },
      ] as TableColumn<
        ReceivedMaterialItemTable & Partial<OperationHistoryBaseColumn>
      >[],
    }),
    [CanCelButton]
  );

  return column;
}
