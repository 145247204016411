import type { ProductionPlanningOrdersExportFormValues } from './useProductionPlanningOrdersExport';
import type { UseFormReturn } from 'react-hook-form';
import { useState } from 'react';
import isEqual from 'lodash/isEqual';

export function useProductionPlanningOrdersFilter() {
  const [formMethods, bindFormMethodsForInitialization] =
    useState<UseFormReturn<ProductionPlanningOrdersExportFormValues> | null>(
      null
    );
  const [filterCondition, setFilterCondition] =
    useState<ProductionPlanningOrdersExportFormValues>({});

  function updateFilterCondition(callback?: {
    onFilterConditionNoChange?: () => void;
    onUpdateSuccess?: () => void;
  }) {
    if (formMethods) {
      if (isEqual(filterCondition, formMethods.getValues())) {
        // 使用者按下查詢，但篩選條件與上次相同（舉例：此處單純重新發送 API 即可，無需將列表回到第一頁）
        callback?.onFilterConditionNoChange?.();
      } else {
        // 使用者按下查詢，且篩選條件與上次不同（舉例：此處重新發送 API 以外，將列表倒回第一頁）
        setFilterCondition(formMethods.getValues());
        callback?.onUpdateSuccess?.();
      }
    }
  }

  return {
    filterCondition,
    updateFilterCondition,
    bindFormMethodsForInitialization,
  };
}
