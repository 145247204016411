import { Button, Popconfirm } from '@mezzanine-ui/react';
import { useLogout } from '../../helpers/use-logout';
import { useCallback, useRef, useState } from 'react';
import { ButtonProps } from 'antd';

export function LogoutButton({
  onClick,
}: {
  onClick?: ButtonProps['onClick'];
}) {
  const logout = useLogout();
  const [confirming, setConfirming] = useState(false);
  const anchor = useRef<HTMLButtonElement>(null);

  const onConfirm = useCallback(
    (event: any) => {
      logout();
      onClick?.(event);
    },
    [logout, onClick]
  );

  const onCancel = useCallback(
    (event: any) => {
      setConfirming(false);
      onClick?.(event);
    },
    [onClick]
  );

  return (
    <>
      <Button
        ref={anchor}
        onClick={() => setConfirming(true)}
        danger
        type="button"
      >
        登出系統
      </Button>
      <Popconfirm
        open={confirming}
        confirmButtonProps={{ danger: true }}
        title="確認登出系統？"
        cancelText="取消"
        confirmText="登出"
        onConfirm={onConfirm}
        onCancel={onCancel}
        anchor={anchor}
      />
    </>
  );
}
