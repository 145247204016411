import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { OffsetBased, PaginatedResponse } from '../pagination';
import {
  CommonTableFilterParam,
  UseDataSourceHook,
} from '../common-table-filter-param';
import { InspectStatus } from './enum';

const namespace = API_NAMESPACE.MATERIALS;

export const SORT_TYPE = {
  EXPECTED_COMPLETED_AT_DESC: 'EXPECTED_COMPLETED_AT_DESC',
  EXPECTED_COMPLETED_AT_ASC: 'EXPECTED_COMPLETED_AT_ASC',
} as const;

export const SORT_TYPE_SELECTIONS = [
  { id: SORT_TYPE.EXPECTED_COMPLETED_AT_DESC, name: '應收日 - 由近至遠排序' },
  { id: SORT_TYPE.EXPECTED_COMPLETED_AT_ASC, name: '應收日 - 由遠至近排序' },
];

export type GetMiscPurchaseOrdersParam = {
  supplierIds: string[];
  receivedStates: string[];
  sortType: keyof typeof SORT_TYPE;
} & CommonTableFilterParam &
  OffsetBased;

export type MiscPurchaseOrderOuterRecord = {
  id: string;
  createdAt: string;
  expectedCompletedAt: string;
  staffName: string;
  supplierId: string;
  supplierName: string;
  receivedState: `${InspectStatus}`;
  returnedState: `${InspectStatus}`;
  items: Array<MiscPurchaseOrderInnerRecord>;
};

export type MiscPurchaseOrderInnerRecord = {
  id: string;
  specId: string;
  expectedQuantity: string;
  receivedQuantity: string;
  materialDescription: string;
  inputUnit: string;

  expectedMaterialStockUnitQuantity: string;
  expectedMaterialInputUnitQuantity: string;
  materialStockUnit: string;
  materialInputUnit: string;
  miscMaterialIdFromSAP: string | null;
  fixedAssetMaterialIdFromSAP: string | null;

  /** @deprecated */
  expectedMainMaterialUnitQuantity: string;
  /** @deprecated */
  expectedSecondaryMaterialUnitQuantity: string;
  /** @deprecated */
  oldMaterialId: string;
  /** @deprecated */
  mainUnitCode: string;
  /** @deprecated */
  secondaryUnitCode: string | null;
  /** @deprecated */
  materialId: string;
};

export function useMiscPurchaseOrders({
  initiateFetching = false,
}: UseDataSourceHook) {
  const [params, setParams] = useState<Partial<GetMiscPurchaseOrdersParam>>();
  const { data, error } = useSWR<
    PaginatedResponse<MiscPurchaseOrderOuterRecord>
  >(
    initiateFetching || params
      ? ['/misc-purchase-orders', { params, namespace }]
      : null
  );

  const pageInfo = useMemo(() => {
    const page =
      (data?.pageInfo?.offset ?? 0) / (data?.pageInfo?.limit ?? 20) + 1;
    const pageSize = data?.pageInfo?.limit ?? 20;
    return { ...(data?.pageInfo ?? {}), page, pageSize };
  }, [data]);

  return {
    orders: data?.records || [],
    pageInfo,
    isLoading: !error && !data,
    mutateParams: setParams,
  };
}

export function useFixedAssetPurchaseOrders({
  defaultParams,
}: {
  defaultParams?: Partial<GetMiscPurchaseOrdersParam>;
}) {
  const [params, setParams] = useState<
    Partial<GetMiscPurchaseOrdersParam> | undefined
  >(defaultParams);
  const { data, mutate, error } = useSWR<
    PaginatedResponse<MiscPurchaseOrderOuterRecord>
  >(params ? ['/fixed-asset-purchase-orders', { params, namespace }] : null);

  return {
    orders: data?.records || [],
    pageInfo: data?.pageInfo ?? {},
    isLoading: !error && !data,
    mutateParams: setParams,
    mutateGetFixedAssetPurchaseOrders: mutate,
  };
}

export function useOutSourcePurchaseOrders({
  initiateFetching = false,
}: UseDataSourceHook) {
  const [params, setParams] = useState<Partial<GetMiscPurchaseOrdersParam>>();
  const { data, error } = useSWR<
    PaginatedResponse<MiscPurchaseOrderOuterRecord>
  >(
    initiateFetching || params
      ? ['/warehouses/outsource-purchase-orders', { params, namespace }]
      : null
  );

  const pageInfo = useMemo(() => {
    const page =
      (data?.pageInfo?.offset ?? 0) / (data?.pageInfo?.limit ?? 20) + 1;
    const pageSize = data?.pageInfo?.limit ?? 20;
    return { ...(data?.pageInfo ?? {}), page, pageSize };
  }, [data]);

  return {
    orders: data?.records || [],
    pageInfo,
    isLoading: !error && !data,
    mutateParams: setParams,
  };
}
