export function OrderAccountDatePicker({ debitDate }: { debitDate?: string }) {
  return (
    <>
      <div>扣帳日期：</div>
      <div>
        {debitDate || '-'}
      </div>
    </>
  );
}
