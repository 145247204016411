import { useAutoAnimate } from '@formkit/auto-animate/react';
import { ChevronLeftIcon, TrashIcon } from '@mezzanine-ui/icons';
import {
  Button,
  Icon,
  IconButton,
  MenuDivider,
  Message,
  SelectValue,
  Typography,
} from '@mezzanine-ui/react';
import {
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  API_NAMESPACE,
  checkJWTRoles,
  MaterialShiftInputFields,
  request,
  useGetDepts,
  useGetMembers,
  useInventoryByMaterial,
  useLoaders,
  useMaterialRequestOrderLogs,
  useModalController,
} from '@solar/data';
import {
  LocationSelectorGroupModal,
  LocationSelectorProvider,
  LOCATION_SELECTOR,
  ModalGroup,
  PageLayout,
  useLocationSelectorController,
  useModalGroupController,
} from '@solar/templates';
import { Descriptions, Table } from 'antd';
import { Decimal } from 'decimal.js';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { WarehouseAndVehicleFilter } from '../../Material/IncomingInspectOperationPage/Modals/WarehouseAndVehicleFilter';
import { BarcodeScanInput } from '../../Material/MaterialShift/BarcodeScanInput';
import { FilterFormFields } from '../../ProductionPlanning/NormalManufacturingPage/EquipmentSelector';
import {
  useWorkOrderInfo,
  WorkOrderInto,
} from '../../ProductionPlanning/NormalManufacturingPage/hooks/use-work-order-info';
import { EquipmentSelector } from './components/EquipmentSelector';
import { useLogsColumns } from './hooks/use-logs-columns';
import classes from './material-supply-to-work-order-page.module.scss';
import {
  MaterialSupplyByLoaderModal,
  MaterialSupplyByLoaderModalData,
} from './MaterialSupplyByLoaderModal';
import {
  MaterialSupplyByMaterialModal,
  MaterialSupplyByMaterialModalData,
} from './MaterialSupplyByMaterialModal';

type TmpShip = {
  id: string;
  specId: number;
  materialId: string;
  materialDescription: string;
  batchId: string;
  loaderId: number;
  stackId: string;
  availableMaterialStockUnitQuantity: string;
};

const namespace = API_NAMESPACE.MATERIALS;

type RequestTable = Partial<
  WorkOrderInto['materialRequestOrderSpecs'][number]
> & {
  id: number;
  isChild?: true;
  isEnabled?: true;
  parentOwnsChildrenQty?: number;
  materialBarcode?: string;
  // materialInputUnit?: string;
  availableMaterialStockUnitQuantity?: string | undefined;
  stockOverInputUnitRatio?: string | undefined;
};

export function MaterialSupplyToOutsourcingProcessOrderPage() {
  const [dispatching, setDispatching] = useState(false);

  const navigate = useNavigate();
  const params = useParams<{ outsourcingOrderId: string }>();
  const logsColumns = useLogsColumns();

  const { members, refetchGetMembers } = useGetMembers();
  const { depts, refetchGetDepts } = useGetDepts();

  const equipFilterMethods = useForm<FilterFormFields>({
    defaultValues: {
      workCenterBigClass: {
        id: 'Z',
        name: '外圍管制工作中心',
      },
    },
  });

  const requestOrderSpecsMethods = useForm<{
    loader: SelectValue;
    recipient: SelectValue;
    receiverDept: SelectValue;
    requestTable: RequestTable[];
    tempRequestTable: (RequestTable & {
      tempQuantity: string;
      tempUnit: SelectValue;
    })[];
    insertChildIndex: number;
  }>({
    defaultValues: {
      requestTable: [],
    },
  });

  const {
    fields: tempRequestTable,
    insert: insertByTempRequestTable,
    remove: removeByTempRequestTable,
  } = useFieldArray({
    control: requestOrderSpecsMethods.control,
    name: 'tempRequestTable',
  });

  const requestTable = useWatch({
    control: requestOrderSpecsMethods.control,
    name: 'requestTable',
  });

  // const tableRef = useRef<HTMLTableElement>(null);

  // const debounceInputHandler = useRef(
  //   debounce(
  //     ({ e, index }: { e: ChangeEvent<HTMLInputElement>; index: number }) => {
  //       const value = new Decimal(Number(e.target.value));

  //       if (!value.isNaN() && !value.isFinite()) {
  //         return;
  //       }

  //       if (
  //         !requestOrderSpecsMethods.getValues(`requestTable.${index}.isChild`)
  //       ) {
  //         return;
  //       }

  //       let upperParentIndex = index - 1;
  //       let upperTotal = new Decimal(0);

  //       while (
  //         requestOrderSpecsMethods.getValues(
  //           `requestTable.${upperParentIndex}.isChild`
  //         )
  //       ) {
  //         upperTotal = new Decimal(
  //           requestOrderSpecsMethods.getValues(
  //             `requestTable.${upperParentIndex}.availableMaterialStockUnitQuantity`
  //           ) ?? '0'
  //         )
  //           .times(
  //             Number(
  //               requestOrderSpecsMethods.getValues(
  //                 `requestTable.${upperParentIndex}.stockOverInputUnitRatio`
  //               ) ?? '0'
  //             )
  //           )
  //           .plus(upperTotal);

  //         upperParentIndex -= 1;
  //       }

  //       let lowerParentIndex = index + 1;
  //       let lowerTotal = new Decimal(0);

  //       while (
  //         requestOrderSpecsMethods.getValues(
  //           `requestTable.${lowerParentIndex}.isChild`
  //         )
  //       ) {
  //         lowerTotal = new Decimal(
  //           requestOrderSpecsMethods.getValues(
  //             `requestTable.${lowerParentIndex}.availableMaterialStockUnitQuantity`
  //           ) ?? '0'
  //         )
  //           .times(
  //             Number(
  //               requestOrderSpecsMethods.getValues(
  //                 `requestTable.${lowerParentIndex}.stockOverInputUnitRatio`
  //               ) ?? '0'
  //             )
  //           )
  //           .plus(lowerTotal);

  //         lowerParentIndex += 1;
  //       }

  //       requestOrderSpecsMethods.setValue(
  //         `requestTable.${upperParentIndex}.availableMaterialStockUnitQuantity`,
  //         String(upperTotal.plus(lowerTotal).plus(value))
  //       );
  //     },
  //     600
  //   )
  // ).current;

  const { fetchInventoryByMaterial } = useInventoryByMaterial({
    defaultType: MaterialShiftInputFields.MATERIAL_BARCODE,
  });

  const { loaders, mutateLoaderParams } = useLoaders();
  const locationSelectorController = useLocationSelectorController({
    onSelectedIdsChange(selectedIds) {
      const shelfId =
        selectedIds?.shelfId ?? selectedIds?.stackId ?? selectedIds?.zoneId;
      if (shelfId) {
        mutateLoaderParams({
          shelfIds: [shelfId],
          whenNotFoundAutoCreate: true,
        });
      }
    },
  });

  const svgMapController = useLocationSelectorController({
    resetAfterClosing: false,
  });

  const warehouseAndVehicleFilterRef =
    useRef<React.ElementRef<typeof WarehouseAndVehicleFilter>>(null);

  const { workOrder: data } = useWorkOrderInfo(
    params?.outsourcingOrderId ?? ''
  );
  const { logs, isLoading: logsLoading } = useMaterialRequestOrderLogs(
    params?.outsourcingOrderId ?? ''
  );

  const [tmpShipList, setTmpShipList] = useState<TmpShip[]>([]);

  const materialSupplyByMaterialModalController =
    useModalController<MaterialSupplyByMaterialModalData>();
  const materialSupplyByLoaderModalController =
    useModalController<MaterialSupplyByLoaderModalData>();
  const modalGroupController = useModalGroupController([
    { name: LOCATION_SELECTOR },
  ]);

  const handleAddItems = (items: TmpShip[]) => {
    setTmpShipList((prevItems) => [
      ...items,
      ...prevItems.filter(
        (prevItem) => !items.some((item) => item?.id === prevItem?.id)
      ),
    ]);
  };

  useEffect(() => {
    if (data && data?.materialRequestOrderSpecs) {
      requestOrderSpecsMethods.setValue(
        'requestTable',
        data?.materialRequestOrderSpecs
      );
    }
  }, [data]);

  // useEffect(() => {
  //   return () => {
  //     debounceInputHandler.cancel();
  //   };
  // }, [debounceInputHandler]);

  return (
    <>
      <div>
        <Button
          type="button"
          onClick={() => {
            if (window.history.state && window.history.state.key) {
              navigate(-1);
            } else {
              navigate('/', { replace: true });
            }
          }}
          prefix={<Icon style={{ color: '#8F8F8F' }} icon={ChevronLeftIcon} />}
          variant="text"
        >
          <Typography color="text-secondary" variant="button2">
            返回上一頁
          </Typography>
        </Button>
      </div>
      <PageLayout title="生產工單發料">
        <Descriptions>
          <Descriptions.Item label="發料單號">
            {data?.id ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label="工單單號">
            {data?.workOrderId ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label="預期領料日">
            {data?.expectedCompletedAt
              ? moment(data?.expectedCompletedAt).format('YYYY/MM/DD')
              : ''}
          </Descriptions.Item>
          <Descriptions.Item label="生管人員">
            {data?.creatorId ?? ''}
          </Descriptions.Item>
        </Descriptions>
        <Typography variant="h3">發料作業</Typography>
        <MenuDivider />
        <LocationSelectorProvider controller={locationSelectorController}>
          {checkJWTRoles([85]) && (
            <FormFieldsWrapper
              methods={requestOrderSpecsMethods}
              onSubmit={async (values) => {
                const { loader, recipient, receiverDept, tempRequestTable } =
                  values;

                setDispatching(true);

                try {
                  const res = await request(
                    '/pp/general-manufacturing/check-in',
                    {
                      method: 'post',
                      body: JSON.stringify({
                        equipmentId:
                          equipFilterMethods.getValues('equipment.id'),
                        materialRequestOrderId: data?.id,
                        createdAt: new Date().toISOString(),
                        items: tempRequestTable.map((row) => ({
                          inputMaterialBarcode: row.materialBarcode,
                          inputMaterialStockUnitQuantity:
                            row.tempUnit.id === 'materialInputUnit'
                              ? new Decimal(
                                  String(
                                    row?.availableMaterialStockUnitQuantity
                                  )
                                ).times(row.stockOverInputUnitRatio as string)
                              : row?.tempQuantity,
                          inputMaterialStockUnit: row?.materialStockUnit,
                          answers: [],
                        })),
                      }),
                      responseParser: (res) => res,
                      namespace: API_NAMESPACE.PP,
                    }
                  );

                  if (res.ok) {
                    Message.success('發料成功');
                  }
                } catch (err) {
                  if (err instanceof Error) {
                    Message.error(JSON.parse(err.message).message);
                  } else {
                    Message.error('發料失敗');
                  }
                } finally {
                  setDispatching(false);
                }
              }}
            >
              <EquipmentSelector methods={equipFilterMethods} />
              <br />

              {/* ref={tableRef} */}
              <div>
                <Table
                  components={{
                    body: { wrapper: Wrapper, row: Row },
                  }}
                  rowClassName={classes['table-row']}
                  rowKey="id"
                  columns={[
                    {
                      title: '品名',
                      dataIndex: 'material',
                      render: (_, source) => {
                        // if (source?.isChild) {
                        //   return <Icon size={20} icon={ChevronUpIcon} />;
                        // }

                        return (
                          (source?.material as any)?.description ??
                          source?.materialDescription ??
                          ''
                        );
                      },
                    },
                    {
                      title: '料號',
                      dataIndex: 'material',
                      render: (_, record) => {
                        return record?.material?.id;
                      },
                    },
                    {
                      title: '批號',
                      // dataIndex: 'materialBatchId',
                      render: (_, record) =>
                        record['materialSapBatchId'] &&
                        record['materialBatchId']
                          ? `${record['materialSapBatchId']}-${record['materialBatchId']}`
                          : '',
                    },
                    {
                      title: '基礎劑量單位數量',
                      dataIndex:
                        'availableMaterialStockUnitQuantity-expectedMaterialStockUnitQuantity',
                      render: (_, record) =>
                        record?.availableMaterialStockUnitQuantity ??
                        record?.expectedMaterialStockUnitQuantity,
                    },
                    {
                      title: '基礎計量單位',
                      dataIndex: 'materialStockUnit-materialStockUnit',
                      render: (_, record) =>
                        (record?.material as any)?.materialStockUnit ??
                        record?.materialStockUnit,
                    },
                    {
                      title: '管理單位數量',
                      dataIndex: 'expectedMaterialInputUnitQuantity',
                    },
                    {
                      title: '管理單位',
                      dataIndex: 'materialInputUnit',
                    },
                    // {
                    //   title: '領用數量',
                    //   dataIndex: 'id',
                    //   render: (_, record, index) =>
                    //     requestTable?.length > 0 &&
                    //     !requestOrderSpecsMethods.watch(
                    //       `requestTable.${index}.isChild`
                    //     ) &&
                    //     !requestOrderSpecsMethods.watch(
                    //       `requestTable.${index}.isEnabled`
                    //     ) ? (
                    //       <InputContainerWithUnit
                    //         suffixUnit={
                    //           (record?.material as any)?.materialStockUnit
                    //         }
                    //       >
                    //         <Input
                    //           disabled
                    //           value={requestOrderSpecsMethods.watch(
                    //             `requestTable.${index}.availableMaterialStockUnitQuantity` ||
                    //               '0'
                    //           )}
                    //         />
                    //       </InputContainerWithUnit>
                    //     ) : (
                    //       <InputContainerWithUnit
                    //         suffixUnit={
                    //           requestOrderSpecsMethods.watch(
                    //             `requestTable.${index}.materialInputUnit`
                    //           ) as string
                    //         }
                    //       >
                    //         <NumericInputField
                    //           validateMode="float"
                    //           width={200}
                    //           onChange={(e) => {
                    //             // debounceInputHandler({ e, index });
                    //           }}
                    //           onKeyDownCapture={(e) => {
                    //             if (e.key === 'Enter') {
                    //               e.preventDefault();
                    //               e.stopPropagation();
                    //             }
                    //           }}
                    //           registerName={`requestTable.${index}.availableMaterialStockUnitQuantity`}
                    //           disabled={
                    //             !requestOrderSpecsMethods.watch(
                    //               `requestTable.${index}.isEnabled`
                    //             )
                    //           }
                    //           placeholder={'請輸入數量'}
                    //         />
                    //       </InputContainerWithUnit>
                    //     ),
                    // },
                    {
                      title: '單位',
                      dataIndex: 'mainMaterialUnit',
                    },
                  ]}
                  dataSource={requestTable ?? []}
                  loading={!data || dispatching}
                />
                <Typography variant="h4">投料需求</Typography>
                <br />
                <BarcodeScanInput
                  inputStyle={classes['barcode-scan-input']}
                  otherOnKeyDownAction={async (value) => {
                    if (!data) {
                      Message.warning('投入需求資料尚未載入');
                      return;
                    }

                    if (dispatching) {
                      Message.warning('發料中！請勿執行查詢物料');
                      return;
                    }
                    if (value) {
                      const res = await fetchInventoryByMaterial({
                        materialBarcodes: [value],
                      });

                      let isMatchedBatchId = false;
                      // let isMatchedLoaderId = false;

                      if (res?.records?.length) {
                        let successMatchOneCondition = false;

                        // if (data?.materialRequestOrderSpecs === null) {
                        //   insertByRequestTable(0, {
                        //     ...res?.records[0],
                        //     materialBatchId: res?.records[0]?.materialSubBatchId,
                        //     isChild: true,
                        //     isEnabled: true,
                        //   });

                        // setTimeout(() => {
                        //   const targetBodyRow =
                        //     (tableRef?.current?.querySelectorAll('tbody > tr') ??
                        //       [])[0];

                        //   targetBodyRow?.setAttribute('disabled', 'false');
                        // }, 0);

                        //   return;
                        // }
                        for (let i = 0; i < requestTable.length; i++) {
                          let matchedDataIndex = -1;
                          const row = requestTable[i];

                          if (row.materialId === res?.records[0]?.materialId) {
                            matchedDataIndex = i;
                          } else {
                            continue;
                          }

                          isMatchedBatchId =
                            `${row.materialSapBatchId}-${row.materialBatchId}` ===
                            res?.records[0]?.batchId;

                          if (isMatchedBatchId) {
                            insertByTempRequestTable(0, {
                              ...res?.records[0],
                              materialBatchId:
                                res?.records[0]?.materialSubBatchId,
                              tempQuantity:
                                res?.records[0]
                                  ?.availableMaterialStockUnitQuantity,
                              tempUnit: {
                                id: res?.records[0]?.materialStockUnit,
                                name: res?.records[0]?.materialStockUnit,
                              },
                            });
                            successMatchOneCondition = true;
                          } else {
                            continue;
                          }

                          //   isMatchedLoaderId =
                          //     row.loaderId === res?.records[0]?.loaderId;

                          //   if (
                          //     matchedDataIndex !== -1 &&
                          //     requestTable[matchedDataIndex].materialSapBatchId &&
                          //     requestTable[matchedDataIndex].materialBatchId &&
                          //     isMatchedBatchId &&
                          //     isMatchedLoaderId
                          //   ) {
                          //     // const targetBodyRow =
                          //     //   (tableRef?.current?.querySelectorAll('tbody > tr') ??
                          //     //     [])[matchedDataIndex];

                          //     // targetBodyRow?.setAttribute('disabled', 'false');

                          //     if (isMatchedBatchId && isMatchedLoaderId) {
                          //       requestOrderSpecsMethods.setValue(
                          //         `requestTable.${matchedDataIndex}.isEnabled`,
                          //         true
                          //       );

                          //       requestOrderSpecsMethods.setValue(
                          //         `requestTable.${matchedDataIndex}.materialInputUnit`,
                          //         res?.records[0]?.materialInputUnit
                          //       );

                          //       requestOrderSpecsMethods.setValue(
                          //         `requestTable.${matchedDataIndex}.availableMaterialStockUnitQuantity`,
                          //         res?.records[0]
                          //           ?.availableMaterialStockUnitQuantity
                          //       );
                          //       requestOrderSpecsMethods.setValue(
                          //         `requestTable.${matchedDataIndex}.stockOverInputUnitRatio`,
                          //         res?.records[0]?.stockOverInputUnitRatio
                          //       );
                          //     }

                          //     requestOrderSpecsMethods.setValue(
                          //       `requestTable.${matchedDataIndex}.materialBarcode`,
                          //       value
                          //     );

                          //     successMatchOneCondition = true;
                          //   } else if (
                          //     matchedDataIndex !== -1 &&
                          //     !requestTable[matchedDataIndex].materialSapBatchId &&
                          //     !requestTable[matchedDataIndex].materialBatchId
                          //   ) {
                          //     // const targetBodyRow =
                          //     //   (tableRef?.current?.querySelectorAll('tbody > tr') ??
                          //     //     [])[matchedDataIndex];

                          //     // targetBodyRow?.setAttribute('disabled', 'false');

                          //     const parentRegisterNameByChildrenQty =
                          //       `requestTable.${matchedDataIndex}.parentOwnsChildrenQty` as const;
                          //     const parentOwnsChildrenQty =
                          //       requestOrderSpecsMethods.getValues(
                          //         parentRegisterNameByChildrenQty
                          //       );

                          //     requestOrderSpecsMethods.setValue(
                          //       parentRegisterNameByChildrenQty,
                          //       (parentOwnsChildrenQty ?? 0) + 1
                          //     );

                          //     const insertIndex = matchedDataIndex + 1;

                          //     insertByTempRequestTable(insertIndex, {
                          //       ...res?.records[0],
                          //       id: Math.random(),
                          //       materialBatchId:
                          //         res?.records[0]?.materialSubBatchId,
                          //       isChild: true,
                          //       isEnabled: true,
                          //     });

                          //     requestOrderSpecsMethods.setValue(
                          //       'insertChildIndex',
                          //       insertIndex
                          //     );

                          //     const e = {
                          //       target: {
                          //         value:
                          //           res?.records[0]
                          //             .availableMaterialStockUnitQuantity,
                          //       },
                          //     } as ChangeEvent<HTMLInputElement>;

                          //     // debounceInputHandler({ e, index: insertIndex });

                          //     successMatchOneCondition = true;
                          //     break;
                          //   }
                          // }
                        }
                        if (!successMatchOneCondition) {
                          Message.info('沒有匹配的資料');
                        }
                      } else {
                        Message.info('沒有匹配的資料');
                      }
                    }
                  }}
                />
                <Table
                  columns={[
                    {
                      title: '移除',
                      dataIndex: 'recover',
                      render: (_, source, index) => {
                        return (
                          <IconButton
                            type="button"
                            onClick={() => {
                              removeByTempRequestTable(index);
                            }}
                          >
                            <Icon icon={TrashIcon} />
                          </IconButton>
                        );
                      },
                    },

                    {
                      title: '物料條碼',
                      dataIndex: 'materialBarcode',
                    },
                    {
                      title: '數量',
                      dataIndex: 'tempQuantity',
                      render: (_, source, index) => {
                        return (
                          <InputField
                            registerName={`tempRequestTable.${index}.tempQuantity`}
                          />
                        );
                      },
                    },
                    {
                      title: '單位',
                      dataIndex: 'tempUnit',
                      render: (_, source, index: number) => {
                        return (
                          <SelectField
                            registerName={`tempRequestTable.${index}.tempUnit`}
                            options={[
                              {
                                id: 'materialStockUnit',
                                name: source?.materialStockUnit as string,
                              },
                              {
                                id: 'materialInputUnit',
                                name: source?.materialStockUnit as string,
                              },
                            ]}
                            onChange={(obj) => {
                              if (obj.id === 'materialStockUnit') {
                                requestOrderSpecsMethods.setValue(
                                  `tempRequestTable.${index}.tempQuantity`,
                                  source?.availableMaterialStockUnitQuantity ||
                                    '0'
                                );
                              } else if (obj.id === 'materialInputUnit') {
                                requestOrderSpecsMethods.setValue(
                                  `tempRequestTable.${index}.tempQuantity`,
                                  source?.availableMaterialStockUnitQuantity ||
                                    '0'
                                );
                              }
                            }}
                          />
                        );
                      },
                    },
                  ]}
                  dataSource={tempRequestTable ?? []}
                />
              </div>
              <div>
                <Button
                  variant="outlined"
                  style={{ float: 'right' }}
                  loading={dispatching}
                >
                  發料
                </Button>
              </div>
            </FormFieldsWrapper>
          )}
        </LocationSelectorProvider>
        <MenuDivider />
        <Typography variant="h4">發料紀錄</Typography>

        <Table
          columns={logsColumns as any}
          dataSource={logs ?? []}
          loading={logsLoading}
        />
        <div style={{ height: '16px' }}></div>
        <MaterialSupplyByMaterialModal
          {...materialSupplyByMaterialModalController}
          tmpShipList={tmpShipList}
          onSubmit={handleAddItems}
        />
        <MaterialSupplyByLoaderModal
          {...materialSupplyByLoaderModalController}
          tmpShipList={tmpShipList}
          onSubmit={handleAddItems}
        />
        <ModalGroup {...modalGroupController}>
          <LocationSelectorGroupModal
            controller={svgMapController}
            onSubmit={(activeId, selectedIds) => {
              locationSelectorController.setSelectedIds(selectedIds ?? {});

              if (activeId) {
                mutateLoaderParams({
                  shelfIds: [activeId],
                  whenNotFoundAutoCreate: true,
                });
              }
            }}
          />
        </ModalGroup>
      </PageLayout>
    </>
  );
}

const Wrapper = (props: React.HTMLAttributes<HTMLTableSectionElement>) => {
  const [parent] = useAutoAnimate();
  return (
    <tbody ref={parent} {...props}>
      {props.children}
    </tbody>
  );
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = (props: RowProps) => {
  return <tr key={props['data-row-key']} {...props} />;
};
