import type { HubOrdersFilterFormValues } from '@solar/data';
import { HubOrdersFilterFormFieldName as FormFieldName } from '@solar/data';
import { useFormContext, useWatch } from 'react-hook-form';
import { DatePickerField } from '@mezzanine-ui/react-hook-form';

export function DebitDatePicker() {
  const formMethods = useFormContext<HubOrdersFilterFormValues>();
  const disabledDebitDatePicker =
    useWatch({
      control: formMethods.control,
      name: FormFieldName.IS_DEBIT,
    }) !== 'Y';

  return (
    <>
      <div>扣帳日期：</div>
      <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <DatePickerField
          control={formMethods.control}
          registerName={FormFieldName.DEBIT_DATE}
          disabled={disabledDebitDatePicker}
        />
      </div>
    </>
  );
}
