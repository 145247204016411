import { request, API_NAMESPACE } from '../../request';
import * as yup from 'yup';
import useSWR from 'swr';

const WeightMethodType = {
  STANDARD: '標準值',
  ACTUAL: '實際值',
} as const;

const AnalysisValueMethodType = {
  STANDARD: '標準值',
  ACTUAL: '實際值',
};

const customerTradeConditionListQuerySchema = yup
  .object()
  .shape({
    page: yup.number().optional(),
    perPage: yup.number().optional(),
    materialId: yup.string().optional(),
    customerId: yup.string().optional(),
    materialCategoryId: yup.string().optional(),
  })
  .optional();

export type CustomerTradeConditionListQuery = yup.InferType<
  typeof customerTradeConditionListQuerySchema
>;

export type CustomerTradeConditionListMeta = {
  currentPage: number;
  perPage: number;
  from: number;
  to: number;
  total: number;
  firstPageUrl: string;
  lastPage: number;
  lastPageUrl: string;
  nextPageUrl: string;
  prevPageUrl: string;
};

export type CustomerTradeConditionListData = {
  customerId: string;
  customerName: string;
  materialId: string;
  materialName: string;
  classA: string;
  classB: string;
  classC: string;
  componentItemNumber1: string;
  weightMethod1: string;
  analysisValueMethod1: string;
  componentItemNumber2: string;
  weightMethod2: string;
  analysisValueMethod2: string;
  componentItemNumber3: string;
  weightMethod3: string;
  analysisValueMethod3: string;
  componentItemNumber4: string;
  weightMethod4: string;
  analysisValueMethod4: string;
};

export type CustomerTradeConditionListPayload = {
  data: CustomerTradeConditionListData[];
} & CustomerTradeConditionListMeta;

export function useCustomerTradeConditionListGet(
  query?: CustomerTradeConditionListQuery
) {
  const {
    data: swrData,
    mutate,
    isValidating,
  } = useSWR<CustomerTradeConditionListPayload>(
    validateSWRKey(query),
    request,
    {
      revalidateOnFocus: false,
    }
  );

  const { data, ...meta } = swrData ?? {};

  return {
    customerTradeConditionListData: transformCustomerTradeConditionListData(
      data ?? []
    ),
    customerTradeConditionListMeta:
      (meta as CustomerTradeConditionListMeta) ?? {},
    refetchCustomerTradeConditionList: mutate,
    isLoading: isValidating,
  };
}

function validateSWRKey(query?: CustomerTradeConditionListQuery) {
  try {
    const safeQuery = customerTradeConditionListQuerySchema.validateSync(query);
    return [
      `/customers/customer-trade-condition`,
      { namespace: API_NAMESPACE.HR, params: safeQuery },
    ];
  } catch (error) {
    return false;
  }
}

function transformCustomerTradeConditionListData(
  data: CustomerTradeConditionListData[]
) {
  return data.map((item) => ({
    ...item,
    id: `${item.customerId}-${item.materialId}`,
    customer: `${item.customerName}（${item.customerId}）`,
    _weightMethod1:
      WeightMethodType?.[item.weightMethod1 as keyof typeof WeightMethodType] ??
      item.weightMethod1,
    _weightMethod2:
      WeightMethodType?.[item.weightMethod2 as keyof typeof WeightMethodType] ??
      item.weightMethod2,
    _weightMethod3:
      WeightMethodType?.[item.weightMethod3 as keyof typeof WeightMethodType] ??
      item.weightMethod3,
    _weightMethod4:
      WeightMethodType?.[item.weightMethod4 as keyof typeof WeightMethodType] ??
      item.weightMethod4,
    _analysisValueMethod1:
      AnalysisValueMethodType?.[
        item.analysisValueMethod1 as keyof typeof AnalysisValueMethodType
      ] ?? item.analysisValueMethod1,
    _analysisValueMethod2:
      AnalysisValueMethodType?.[
        item.analysisValueMethod2 as keyof typeof AnalysisValueMethodType
      ] ?? item.analysisValueMethod2,
    _analysisValueMethod3:
      AnalysisValueMethodType?.[
        item.analysisValueMethod3 as keyof typeof AnalysisValueMethodType
      ] ?? item.analysisValueMethod3,
    _analysisValueMethod4:
      AnalysisValueMethodType?.[
        item.analysisValueMethod4 as keyof typeof AnalysisValueMethodType
      ] ?? item.analysisValueMethod4,
  }));
}
