import { useForm, useFormState } from 'react-hook-form';
import { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import isEqual from 'lodash/isEqual';

export const HubOrdersFilterFormFieldName = {
  HUB_DELIVERY_ORDER_ID: 'hubDeliveryOrderId',
  CUSTOMER_ID: 'customerId',
  SHIP_DATE_START: 'shipDateStart',
  SHIP_DATE_END: 'shipDateEnd',
  IS_DEBIT: 'isDebit',
  DEBIT_DATE: 'debitDate',
} as const;

const _FormFieldName = HubOrdersFilterFormFieldName;

const hubOrdersFilterSchema = yup.object().shape({
  [_FormFieldName.HUB_DELIVERY_ORDER_ID]: yup.string().optional(),
  [_FormFieldName.CUSTOMER_ID]: yup.string().optional(),
  [_FormFieldName.SHIP_DATE_START]: yup.string().optional(),
  [_FormFieldName.SHIP_DATE_END]: yup.string().optional(),
  [_FormFieldName.IS_DEBIT]: yup.string().oneOf(['', 'Y', 'N']).optional(),
  [_FormFieldName.DEBIT_DATE]: yup.string().optional(),
});

export type HubOrdersFilterFormValues = yup.InferType<
  typeof hubOrdersFilterSchema
>;

export function useHubOrdersFilter() {
  const formMethods = useForm<HubOrdersFilterFormValues>({
    resolver: yupResolver(hubOrdersFilterSchema),
  });
  const formState = useFormState({ control: formMethods.control });

  const [filterCondition, setFilterCondition] =
    useState<HubOrdersFilterFormValues>({});

  function updateFilterCondition(callback?: {
    onFilterConditionNoChange?: () => void;
    onUpdateSuccess?: () => void;
  }) {
    if (formMethods) {
      if (isEqual(filterCondition, formMethods.getValues())) {
        callback?.onFilterConditionNoChange?.();
      } else {
        setFilterCondition(formMethods.getValues());
        callback?.onUpdateSuccess?.();
      }
    }
  }

  return {
    formMethods,
    formState,
    filterCondition,
    updateFilterCondition,
  };
}
