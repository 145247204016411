import { Button, Popper, Table } from '@mezzanine-ui/react';
import styles from './supply-table.module.scss';
import { getZoneDisplayName, LocationLevel, useSVGs } from '@solar/data';
import { useState } from 'react';
import { SupplyWareHouseRadio } from '../const/supply-warehouse';
import {
  LocationSVGViewer,
  transformZoneToLocation,
} from '../../Equipment/LocationSelector/LocationSVGViewer';

type SupplyTableView = {
  equipments: any[];
  pageInfo: any;
  isLoading: boolean;
  defaultView: SupplyWareHouseRadio;
  mutateGetSupply: (arg0: any) => void;
  openSupplyDetailModal: (arg0: any) => void;
};

export function SupplyTable({
  equipments,
  pageInfo,
  isLoading,
  defaultView,
  mutateGetSupply,
  openSupplyDetailModal,
}: SupplyTableView) {
  const { svg, mutateGetSVG, resetSVG } = useSVGs();

  const [anchorRef, setAnchorRef] = useState<HTMLButtonElement | null>();
  const [currentZone, setCurrentZone] = useState<any>();

  const isSupplyView = defaultView === SupplyWareHouseRadio.SUPPLY_VIEW;
  const columns = [
    { title: '料號', width: 400, dataIndex: 'id' },
    { title: '規格', width: 400, dataIndex: 'category.name' },
    {
      title: isSupplyView ? '數量' : '儲位',
      [isSupplyView ? 'width' : '']: 80,
      ellipsis: false,
      render: (source: any) =>
        isSupplyView ? (
          <>1</>
        ) : (
          <div
            data-zone-id={source?.zone?.id}
            onMouseEnter={(event: any) => {
              if (source?.zone?.id) {
                mutateGetSVG(source?.zone?.area?.id);
                setCurrentZone(source?.zone);
                setAnchorRef(event.currentTarget);
              }
            }}
            onMouseLeave={() => {
              if (source?.zone?.id) {
                resetSVG();
                setCurrentZone(null);
                setAnchorRef(null);
              }
            }}
          >
            {getZoneDisplayName(source?.zone)}
          </div>
        ),
    },
    {
      title: isSupplyView ? '操作' : '數量',
      width: 80,
      align: 'center' as const,
      render: (source: any) =>
        isSupplyView ? (
          <Button onClick={() => openSupplyDetailModal(source)}>View</Button>
        ) : (
          <>1</>
        ),
    },
  ];

  return (
    <>
      <Table
        loading={isLoading}
        pagination={{
          disableAutoSlicing: true,
          total: pageInfo?.totalCount ?? 0,
          current: pageInfo?.page ?? 1,
          onChange: (nextPage) => mutateGetSupply({ nextPage }),
          options: {
            pageSize: pageInfo?.pageSize ?? 20,
            pageSizeLabel: '每頁顯示：',
            pageSizeUnit: '筆',
            onChangePageSize: (nextPageSize) =>
              mutateGetSupply({ nextPage: 1, nextPageSize }),
            renderPageSizeOptionName: (p) => `${p}`,
            showPageSizeOptions: true,
            showJumper: true,
            jumperButtonText: '前往',
            jumperHintText: '跳至',
          },
        }}
        bodyClassName={styles.table}
        dataSource={equipments}
        columns={columns}
      />
      <Popper
        anchor={anchorRef}
        open={Boolean(anchorRef)}
        style={{
          border: '1px solid #000',
          borderRadius: '5px',
          backgroundColor: '#fff',
        }}
      >
        <LocationSVGViewer
          svg={svg}
          currentLevel={LocationLevel.ZONE}
          currentLocation={transformZoneToLocation(currentZone)}
        />
      </Popper>
    </>
  );
}
