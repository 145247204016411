import { Col, Form, Row, Select } from 'antd';
import { useMemo, useRef } from 'react';

export function UsagePredictionPage() {
  const ref = useRef<HTMLDivElement>(null);
  const [form] = Form.useForm();

  const wc = Form.useWatch('wc', form);

  return (
    <div
      style={{
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'min-content auto',
      }}
    >
      <iframe
        title="SD報表"
        width="1140"
        height="541.25"
        src="https://app.powerbi.com/reportEmbed?reportId=2c87e29e-ab68-4f15-9f74-6b480be554bc&autoAuth=true&ctid=e3553a8e-c723-4b54-ac77-0bb3db8cf161"
        allowFullScreen
      ></iframe>
    </div>
  );
}
