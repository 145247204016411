import { useMemo, useState } from 'react';

export default function useMznTablePagination() {
  const [page, setPage] = useState<number>(1);

  return useMemo(
    () => ({
      page,
      onChange: (nextPage: number) => setPage(nextPage),
    }),
    [page],
  );
}
