import { TableColumn } from '@mezzanine-ui/core/table';
import { Button } from '@mezzanine-ui/react';
import {
  DateRangePickerField,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  getNextParams,
  MaterialRequestOrder,
  MATERIAL_REQUEST_ORDERS_STATES_SELECTIONS,
  NextPagination,
  useMaterialRequestOrders,
} from '@solar/data';
import {
  CopyTextWrapper,
  FilterScaffold,
  MznPaginationTable,
} from '@solar/templates';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { WorkOrderTableFilter } from './types';
import styles from './work-order-table.module.scss';

const TYPE_CODES = ['1O', '2O', '3O'];

export function OutsourcingProcessOrderTable() {
  const navigate = useNavigate();
  const methods = useForm<WorkOrderTableFilter>();

  const { orders, pageInfo, mutateParams } = useMaterialRequestOrders({
    initiateFetching: false,
  });

  const refetchMaterialRequestOrders = useCallback(
    (nextPagination: NextPagination) => {
      const formState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      mutateParams({
        expectedCompletedAtDateFrom: formState?.rangeOfDate?.[0],
        expectedCompletedAtDateTo: formState?.rangeOfDate?.[1],
        materialRequestOrderIds: formState?.materialRequestOrderId
          ? [formState.materialRequestOrderId]
          : undefined,
        workOrderIds: formState?.workOrderId
          ? [formState.workOrderId]
          : undefined,
        states: formState?.status?.map((obj) => obj.id),
        searchTerm: formState?.searchTerm,
        offset,
        limit,
        typeCodes: TYPE_CODES,
      });
    },
    [methods, pageInfo, mutateParams]
  );

  const columns: TableColumn<MaterialRequestOrder>[] = [
    {
      title: '操作',
      render: (source) => (
        <Button
          onClick={() =>
            navigate(
              `/warehouse/material-supply/outsourcing-process-order/${source?.id}`
            )
          }
        >
          檢視
        </Button>
      ),
    },
    {
      title: '發料單號',
      render: (source) => <CopyTextWrapper text={source?.id ?? ''} />,
    },
    {
      title: '工單單號',
      render: (source) => <CopyTextWrapper text={source?.workOrderId ?? ''} />,
    },
    {
      title: '預期領料日',
      render: (source) =>
        source?.expectedCompletedAt
          ? moment(source?.expectedCompletedAt).format('YYYY/MM/DD')
          : '',
    },
    {
      title: '生管人員',
      dataIndex: 'creatorId',
    },
    {
      title: '狀態',
      dataIndex: 'states',
    },
    {
      title: '領用人',
      dataIndex: 'users',
    },
  ];

  // init data
  useEffect(() => {
    refetchMaterialRequestOrders({ nextPage: 1 });
  }, []);

  return (
    <div className={styles.work_orders_wrapper}>
      <FormFieldsWrapper methods={methods}>
        <FilterScaffold
          mutateParams={refetchMaterialRequestOrders}
          fetchAsParamsMutated={false}
          className={styles.filter_form_wrapper}
        >
          <InputField
            placeholder="請輸入發料單號"
            registerName="materialRequestOrderId"
          />
          <InputField placeholder="請輸入工單單號" registerName="workOrderId" />
          <SelectField
            clearable
            mode="multiple"
            label="狀態"
            registerName="status"
            options={MATERIAL_REQUEST_ORDERS_STATES_SELECTIONS}
          />
          <DateRangePickerField label="日期區間" registerName="rangeOfDate" />
          <div>
            <Button
              variant="outlined"
              type="button"
              onClick={() => refetchMaterialRequestOrders({ nextPage: 1 })}
            >
              查詢
            </Button>
          </div>
        </FilterScaffold>
      </FormFieldsWrapper>
      <MznPaginationTable
        pageInfo={pageInfo}
        fetchData={refetchMaterialRequestOrders}
        dataSource={orders ?? []}
        columns={columns}
      />
    </div>
  );
}
