import { Tag } from "@mezzanine-ui/react";
import styles from './tag.module.scss';
import { RepairRequestPriority } from "@solar/data";

type RepairRequestPriorityTagProps = {
  priority?: RepairRequestPriority,
};

export function RepairRequestPriorityTag({ priority }: RepairRequestPriorityTagProps) {
  switch (priority) {
    case RepairRequestPriority.NORMAL:
      return <Tag className={styles.tag} style={{ color: '#454545', backgroundColor: 'rgba(143, 143, 143, 0.15)' }}>一般</Tag>;
    case RepairRequestPriority.PRIORITY:
      return <Tag className={styles.tag} style={{ color: '#F1842B', backgroundColor: 'rgba(253, 217, 72, 0.15)' }}>急件</Tag>;
    case RepairRequestPriority.EMERGENCY:
      return <Tag className={styles.tag} style={{ color: '#AF1C12', backgroundColor: 'rgba(219, 98, 88, 0.15)' }}>特急</Tag>;
    default:
      return null;
  }
}