import useSWR from 'swr';
import {
  APIResponse,
  BU,
  BackplateSourceCode,
  CompletedStatusCode,
  CompositeCode,
  DrillingCode,
  NotchCode,
  SandblastingCode,
  ShapeCode,
  SizeCodeOne,
  SlottingCode,
  TypeCode,
} from './types';
import { API_NAMESPACE } from '../request';
import { uniqBy } from 'lodash';

const namespace = API_NAMESPACE.DRAWINGS;

function getWordingByBoolean(bool: boolean) {
  return bool ? '有' : '無';
}

const findTargetOption =
  <T extends { [key: string]: any }>(options: T[], keys: string[]) =>
  (object: { [key: string]: any }) =>
    options.find((option) =>
      keys.reduce((result, key) => option[key] === object[key] && result, true)
    );

export function useGetTypeCodes() {
  const { data, error } = useSWR<APIResponse<TypeCode[]>>([
    '/drawings/typeCodes',
    { namespace },
  ]);

  const result = data?.data || [];

  const options = result.map(({ code, name }) => ({
    text: name,
    label: name,
    value: code,
  }));

  return {
    data: result,
    options,
    isLoading: !data && !error,
    error,
  };
}

export function useGetCompositeCodes() {
  const { data, error } = useSWR<APIResponse<CompositeCode[]>>([
    '/drawings/compositeCodes',
    { namespace },
  ]);

  const result = data?.data || [];

  const options = result.map(({ code, name }) => {
    const label = `${name}（${code}）`;
    return { label, text: label, value: code };
  });

  return {
    data: result,
    options,
    isLoading: !data && !error,
    error,
  };
}

export function useGetShapeCodes(params?: { bu: BU }) {
  const { data, error } = useSWR<APIResponse<ShapeCode[]>>([
    '/drawings/temFcc/shapeCodes',
    { namespace, params },
  ]);

  const result = data?.data || [];

  const options = result.map(({ id, code, name }) => {
    const label = `${name}（${code}）`;
    return { label, text: label, value: id };
  });

  return {
    data: result,
    options,
    isLoading: !data && !error,
    error,
  };
}

export const sizeCodeCharMap = new Map(
  Array.from({ length: 26 }, (_, index) => String.fromCharCode(index + 65))
    .filter((char) => char !== 'I' && char !== 'O' && char !== 'X')
    .map((char, index) => [index + 10, char])
);

// export function calculateTEMSizeCode(
//   mm: number | string,
//   divideNumber: number
// ) {
//   const value = Math.round(Number(mm));
//   const quotient = Math.floor(value / divideNumber);
//   const mod = value % divideNumber;
//   const modString = mod.toString().padStart(Math.log10(divideNumber), '0');
//   switch (true) {
//     case quotient < 10:
//       return `${quotient.toString()}${modString}`;
//     case quotient > 33:
//       return `Z${modString}`;
//     case quotient >= 10 && quotient <= 33:
//       return `${sizeCodeCharMap.get(quotient)}${modString}`;
//     default:
//       return '';
//   }
// }

// export function calculateFCCSizeCode(mm: number) {
//   if (mm > 9999) return '9999';
//   return mm ? Math.floor(mm).toString().padStart(4, 'X').slice(0, 4) : '';
// }

export const sizeCodeDecimalFirstCharMap = new Map([
  ['1', 'A'],
  ['2', 'B'],
  ['3', 'C'],
  ['4', 'D'],
  ['5', 'E'],
  ['6', 'F'],
  ['7', 'G'],
  ['8', 'H'],
  ['9', 'J'],
  ['0', 'K'],
]);

export function calculateSizeCode(mm: number) {
  if (!mm) return '';
  if (mm > 9999) return '9999';
  const [int, decimal] = mm.toString().split('.');
  const intDigits = int === '0' ? 0 : int?.length ?? 0;
  const decimalDigits = decimal?.length ?? 0;
  const xNumber = 4 - intDigits - decimalDigits;
  const [firstChar, ...otherChars] = decimal ?? '';
  const xWords = 'X'.repeat(xNumber > 0 ? xNumber : 0);
  const intWords = int === '0' ? '' : int;
  const decimalFirstChar = firstChar
    ? sizeCodeDecimalFirstCharMap.get(firstChar) ?? ''
    : '';
  const decimalOtherChars = otherChars.join('');
  return (xWords + intWords + decimalFirstChar + decimalOtherChars).slice(0, 4);
}

export function useGetSizeCodes() {
  const { data, error } = useSWR<APIResponse<SizeCodeOne[]>>([
    '/drawings/sizeCodes',
    { namespace },
  ]);

  const result = data?.data || [];

  const options = result.map(({ code, upperBound, lowerBound }) => ({
    text: `${lowerBound} mm ~ ${upperBound ? `${upperBound} mm` : ''}`,
    label: `${lowerBound} mm ~ ${upperBound ? `${upperBound} mm` : ''}`,
    value: code,
  }));

  const createTEMSizeCodeOne = (mm: number | string) =>
    calculateSizeCode(Number(mm));
  const createTEMSizeCodeTwo = (mm: number | string) =>
    calculateSizeCode(Number(mm));
  const createTEMSizeCodeThree = (mm: number | string) =>
    calculateSizeCode(Number(mm));

  const createFCCSizeCodeOne = (mm: number | string) =>
    calculateSizeCode(Number(mm));
  const createFCCSizeCodeTwo = (mm: number | string) =>
    calculateSizeCode(Number(mm));
  const createFCCSizeCodeThree = (mm: number | string) =>
    calculateSizeCode(Number(mm));

  return {
    data: result,
    options,
    createTEMSizeCodeOne,
    createTEMSizeCodeTwo,
    createTEMSizeCodeThree,
    createFCCSizeCodeOne,
    createFCCSizeCodeTwo,
    createFCCSizeCodeThree,
    isLoading: !data && !error,
    error,
  };
}

export function useGetSandblastingCodes() {
  const { data, error } = useSWR<APIResponse<SandblastingCode[]>>([
    '/drawings/temFcc/sandblastingCodes',
    { namespace },
  ]);

  const result = data?.data || [];

  const options = result?.map(
    ({
      code,
      isTraditionalSandblasting,
      isLaserSandblasting,
      isGrooveSandblasting,
      isPlasmaSandblasting,
    }) => {
      const nameCollection = [];
      if (isTraditionalSandblasting) {
        nameCollection.push('傳統');
      }
      if (isLaserSandblasting) {
        nameCollection.push('雷射');
      }
      if (isGrooveSandblasting) {
        nameCollection.push('凹槽');
      }
      if (isPlasmaSandblasting) {
        nameCollection.push('電漿');
      }
      const name =
        nameCollection?.length > 0 ? `有${nameCollection.join('、')}` : '無';
      const label = `${code}（${name}）`;
      return {
        label,
        text: label,
        value: code,
      };
    }
  );

  const findSandblastingCode = findTargetOption(result, [
    'isTraditionalSandblasting',
    'isLaserSandblasting',
    'isGrooveSandblasting',
    'isPlasmaSandblasting',
  ]);

  return {
    data: result,
    options,
    findSandblastingCode,
    isLoading: !data && !error,
    error,
  };
}

export function useGetDrillingCodes() {
  const { data, error } = useSWR<APIResponse<DrillingCode[]>>([
    '/drawings/temFcc/drillingCodes',
    { namespace },
  ]);

  const result = data?.data || [];

  const options = result.map(
    ({ code, isBlindHole, isPerforation, isTapping }) => {
      const tName = `${getWordingByBoolean(isTapping)}`;
      const pName = `${getWordingByBoolean(isPerforation)}`;
      const bName = `${getWordingByBoolean(isBlindHole)}`;
      const name = `${tName}、${pName}、${bName}`;
      const label = `${name}（${code}）`;
      return { label: code, text: code, value: code };
    }
  );

  const findDrillingCode = findTargetOption(result, [
    'isTapping',
    'isPerforation',
    'isBlindHole',
  ]);

  return {
    data: result,
    options,
    findDrillingCode,
    isLoading: !data && !error,
    error,
  };
}

export function useGetSlottingCodes() {
  const { data, error } = useSWR<APIResponse<SlottingCode[]>>([
    '/drawings/temFcc/slottingCodes',
    { namespace },
  ]);

  const result = data?.data || [];

  const options = result.map(({ code, isOring, isProfile }) => {
    const oName = `${getWordingByBoolean(isOring)}`;
    const pName = `${getWordingByBoolean(isProfile)}`;
    const name = `${oName}、${pName}`;
    const label = `${name}（${code}）`;
    return { label: code, text: code, value: code };
  });

  const findSlottingCode = findTargetOption(result, ['isOring', 'isProfile']);

  return {
    data: result,
    options,
    findSlottingCode,
    isLoading: !data && !error,
    error,
  };
}

export function useGetNotchCodes() {
  const { data, error } = useSWR<APIResponse<NotchCode[]>>([
    '/drawings/temFcc/notchCodes',
    { namespace },
  ]);

  const result = data?.data || [];

  const codeOptions = uniqBy(result, 'code')?.map((item) => ({
    text:
      item?.totalAngleCount === 0
        ? `${item.code}（無）`
        : `${item.code}（加工角*${item?.totalAngleCount}）`,
    value: item.code,
  }));

  const radiusAngleOptions = [
    ...new Set(result?.map((notch) => notch?.radiusAngleCount)),
  ]?.map((count) => ({ label: count, value: count }));

  return {
    data: result,
    codeOptions,
    radiusAngleOptions,
    isLoading: !data && !error,
    error,
  };
}

export function useGetBackplateSourceCodes() {
  const { data, error } = useSWR<APIResponse<BackplateSourceCode[]>>([
    '/drawings/backplateSources',
    { namespace },
  ]);

  const result = data?.data || [];

  const options = result.map(({ code, name }) => ({
    text: name,
    label: name,
    value: code,
  }));

  return {
    data: result,
    options,
    isLoading: !data && !error,
    error,
  };
}

export function useGetCompletedStatusCodes() {
  const { data, error } = useSWR<APIResponse<CompletedStatusCode[]>>([
    '/drawings/completedStatuses',
    { namespace },
  ]);

  const result = data?.data || [];

  const options = result.map(({ code, name }) => ({
    text: name,
    label: name,
    value: code,
  }));

  return {
    data: result,
    options,
    isLoading: !data && !error,
    error,
  };
}

export function useGetDrawingsAttributeCodes(params?: { bu: BU }) {
  const typeCodes = useGetTypeCodes();
  const compositeCodes = useGetCompositeCodes();
  const shapeCodes = useGetShapeCodes(params);
  const sizeCodes = useGetSizeCodes();
  const drillingCodes = useGetDrillingCodes();
  const slottingCodes = useGetSlottingCodes();
  const backplateSourceCodes = useGetBackplateSourceCodes();
  const completedStatusCodes = useGetCompletedStatusCodes();
  const sandblastingCodes = useGetSandblastingCodes();
  const notchCodes = useGetNotchCodes();
  return {
    typeCodes,
    compositeCodes,
    shapeCodes,
    sizeCodes,
    notchCodes,
    drillingCodes,
    slottingCodes,
    backplateSourceCodes,
    completedStatusCodes,
    sandblastingCodes,
  };
}
