import { ShapeCode, FCCDrawing, createFCCDrawing, deleteDrawing, environment, useGetDrawingsAttributeCodes, useModalController, useFCCDrawings, PendingDrawing, useGetFCCParentOldDrawingCodes } from "@solar/data";
import { AutoComplete, Button, Descriptions, Form, FormProps, Input, InputNumber, Modal, Radio, RadioGroupProps, Select, Upload, UploadProps, message } from "antd";
import styles from './edit-drawing-form.module.scss';
import { ReactNode, useEffect, useMemo, useState } from "react";
import { Typography } from "@mezzanine-ui/react";
import { ExpandDescriptionsItem } from "./ExpandDescriptionsItem";
import { UploadDrawingField } from "../UploadDrawingField/UploadDrawingField";

type FCCDrawingEditFormProps = FormProps & {
  title?: ReactNode;
  extraContent?: ReactNode;
  drawing?: FCCDrawing;
  pendingDrawing?: PendingDrawing;
};

export function useFCCDrawingEditForm() {
  const [form] = Form.useForm();

  const file = Form.useWatch('file', form);

  const uploading = file?.[0]?.status === 'uploading';

  return {
    form,
    uploading,
  };
}

export function FCCDrawingEditForm({
  id,
  form,
  title,
  drawing,
  pendingDrawing,
  extraContent,
  onFinish,
}: FCCDrawingEditFormProps) {
  const [createdChildNewDrawingCodeIDs, setCreatedChildNewDrawingCodeIDs] = useState<string[]>([]);
  const { drawings, mutateFCCDrawing, isLoading } = useFCCDrawings({ params: { size: 0 } });
  const [childDrawingForm] = Form.useForm();
  const isChildForm = id === 'childDrawingForm';

  const {
    open,
    openModal,
    closeModal,
  } = useModalController();

  const {
    typeCodes,
    compositeCodes,
    shapeCodes,
    sizeCodes,
    notchCodes,
    backplateSourceCodes,
    completedStatusCodes,
  } = useGetDrawingsAttributeCodes({ bu: 'FCC' });

  const { fccParentOldDrawingCodes, refetchGetFCCParentOldDrawingCodes } = useGetFCCParentOldDrawingCodes();
  const parentOldDrawingCodeOptions = fccParentOldDrawingCodes?.map((oldDrawingCode) => ({
    label: oldDrawingCode,
    value: oldDrawingCode,
  })) ?? [];

  const [shapeCode, setShapeCode] = useState<ShapeCode>();

  const disabledNotchCode = shapeCode?.code === 'Z';

  const compositeCode = Form.useWatch('compositeCode', form);
  const sizeCodeOne = Form.useWatch('sizeCodeOne', form);
  const sizeCodeTwo = Form.useWatch('sizeCodeTwo', form);
  const sizeCodeThree = Form.useWatch('sizeCodeThree', form);
  // const slottingCode = Form.useWatch('slottingCode', form);
  // const drillingCode = Form.useWatch('drillingCode', form);
  // const sandblastingCode = Form.useWatch('sandblastingCode', form);
  const notchCode = Form.useWatch('notchCode', form);
  const radiusAngleCount = Form.useWatch('radiusAngleCount', form);
  const parentNewDrawingCodeID = Form.useWatch('parentNewDrawingCodeID', form);
  const childNewDrawingCodeIDs = Form.useWatch('childNewDrawingCodeIDs', form);

  const newDrawingCode = 'F'
    + (compositeCode ?? '_')
    + (shapeCode?.code ?? '_')
    + (shapeCode?.requiredSizeCodeOne ? (sizeCodeOne || '____') : '')
    + (shapeCode?.requiredSizeCodeTwo ? (sizeCodeTwo || '____') : '')
    + (shapeCode?.requiredSizeCodeThree ? (sizeCodeThree  || '____') : '')
    + (disabledNotchCode ? '' : (notchCode ?? '_'));
    // + (sandblastingCode ?? '_')
    // + (slottingCode ?? '_')
    // + (drillingCode ?? '_');

  const childDrawingFile = Form.useWatch('file', childDrawingForm);

  const drawingOptionsForParentDrawing = drawings?.reduce((options: { label: string; value: string; }[], drawingOption: FCCDrawing) => {
    const isCurrentDrawing = drawing?.id === drawingOption?.id;
    const isChildDrawing = childNewDrawingCodeIDs?.includes(drawingOption?.id);
    if (!isCurrentDrawing && !isChildDrawing) {
      options.push({
        value: drawingOption?.id,
        label: drawingOption?.newDrawingCode,
      });
    }
    return options;
  }, []);


  const drawingOptionsForChildDrawings = useMemo(() => 
  drawings?.reduce((options: { label: string; value: string; }[], drawingOption: FCCDrawing) => {
    const isCurrentDrawing = drawing?.id === drawingOption?.id;
    const isParentDrawing = parentNewDrawingCodeID === drawingOption?.id;
    const isCurrentDrawingChild = childNewDrawingCodeIDs?.includes(drawingOption?.id);
    if (isCurrentDrawingChild || (!isCurrentDrawing && !isParentDrawing && !drawingOption?.hasParent)) {
      options.push({
        value: drawingOption?.id,
        label: drawingOption?.newDrawingCode,
      });
    }
    return options;
  }, []), [childNewDrawingCodeIDs, drawing, drawings, parentNewDrawingCodeID]);

  const RequiredMark = ({ hidden }: { hidden?: boolean }) => hidden ? null : (
    <span
      style={{
        color: 'red',
        verticalAlign: 'super',
        display: hidden ? 'inline-block' : '',
      }}>
      *
    </span>
  );

  useEffect(() => {
    const parentOldDrawingCode = drawing?.parentOldDrawingCode ?? pendingDrawing?.parentDrawingCode;
    
    if (parentOldDrawingCode) {
      form?.setFieldValue('parentOldDrawingCode', parentOldDrawingCode);
    }
  }, [drawing, form, pendingDrawing]);

  useEffect(() => {
    if (drawing && !isChildForm) {
      form?.setFieldsValue(drawing);

      const targetShapeCode = shapeCodes?.data?.find((sc) => sc?.id === drawing?.shapeCodeID);
      setShapeCode(targetShapeCode);

      if (drawing?.parentDrawings?.length > 0) {
        form?.setFieldValue('parentNewDrawingCodeID', drawing?.parentDrawings?.[0]?.id);
      }

      if (drawing?.childDrawings?.length > 0) {
        form?.setFieldValue('childNewDrawingCodeIDs', drawing?.childDrawings?.map((drawing) => drawing?.id));
      }
    }
  }, [drawing, form, isChildForm, shapeCodes?.data]);

  const handleShapeChange = (shapeCodeID: number) => {
    const targetShapeCode = shapeCodes?.data?.find((sc) => sc?.id === shapeCodeID);
    setShapeCode(targetShapeCode);
    const { sizeOne, sizeTwo, sizeThree } = form?.getFieldsValue() ?? {};
    form?.setFieldValue('sizeCodeOne', targetShapeCode?.requiredSizeCodeOne
      ? sizeCodes.createFCCSizeCodeOne(sizeOne)
      : ''
    );
    form?.setFieldValue('sizeCodeTwo', targetShapeCode?.requiredSizeCodeTwo
      ? sizeCodes.createFCCSizeCodeTwo(sizeTwo)
      : ''
    );
    form?.setFieldValue('sizeCodeThree', targetShapeCode?.requiredSizeCodeThree
      ? sizeCodes.createFCCSizeCodeThree(sizeThree)
      : ''
    );
  };

  const handleSizeOneChange = (value: number | string | null) => {
    const sizeCodeOne = shapeCode?.requiredSizeCodeOne ? sizeCodes.createFCCSizeCodeOne(value ?? 0) : '';
    form?.setFieldValue('sizeCodeOne', sizeCodeOne);
  };

  const handleSizeTwoChange = (value: number | string | null) => {
    const sizeCodeTwo = shapeCode?.requiredSizeCodeTwo ? sizeCodes.createFCCSizeCodeTwo(value ?? 0) : '';
    form?.setFieldValue('sizeCodeTwo', sizeCodeTwo);
  };

  const handleSizeThreeChange = (value: number | string | null) => {
    const sizeCodeThree = shapeCode?.requiredSizeCodeThree ? sizeCodes.createFCCSizeCodeThree(value ?? 0) : '';
    form?.setFieldValue('sizeCodeThree', sizeCodeThree);
  };

  const chamferAngleOptions = notchCodes?.data
    ?.filter((notch) => notch?.radiusAngleCount === radiusAngleCount)
    ?.map(({ chamferAngleCount }) => ({ label: chamferAngleCount, value: chamferAngleCount }));

  const handleRadiusAngleCountChange = (value: number) => {
    const defaultNotchCode = notchCodes?.data
      ?.filter((notch) => notch?.radiusAngleCount === value)
      ?.[0];
    form?.setFieldValue('notchCodeID', defaultNotchCode?.id);
    form?.setFieldValue('notchCode', defaultNotchCode?.code);
    form?.setFieldValue('radiusAngleCount', defaultNotchCode?.radiusAngleCount);
    form?.setFieldValue('chamferAngleCount', defaultNotchCode?.chamferAngleCount);
  };

  const handleChamferAngleCountChange = (value: number) => {
    const targetNotchCode = notchCodes?.data
      ?.find((notch) => notch?.radiusAngleCount === radiusAngleCount && notch?.chamferAngleCount === value);
    form?.setFieldValue('notchCodeID', targetNotchCode?.id);
    form?.setFieldValue('notchCode', targetNotchCode?.code);
    form?.setFieldValue('radiusAngleCount', targetNotchCode?.radiusAngleCount);
    form?.setFieldValue('chamferAngleCount', targetNotchCode?.chamferAngleCount);
  };

  return (
    <div>
      <Form
        id={id}
        form={form}
        className={styles.page_content}
        onFinish={(values) => {
          const { file, ...data } = values;
          onFinish?.({
            ...data,
            ...file?.fileList?.[0]?.response,
          });
        }}
      >
        <Descriptions
          bordered
          column={3}
          size="small"
          title={title && (<Typography variant="h1">{title}</Typography>)}
          style={{ borderRadius: 0 }}
          labelStyle={{ width: 140 }}
          extra={extraContent}>
          <Descriptions.Item label="檢查人">
            <Form.Item name="checker">
              <Input />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={<>完成別<RequiredMark /></>}>
            <Form.Item name="completedStatusCode" rules={[{ required: true, message: '必填' }]}>
              <Select options={completedStatusCodes?.options ?? []} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="類型">
            <Form.Item name="typeCode">
              <Select options={typeCodes?.options ?? []} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={<>修正後舊圖號<RequiredMark /></>}>
            <Form.Item name="oldDrawingFixed"  rules={[{ required: true, message: '必填' }]}>
              <Input />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="舊成品圖號">
            <Form.Item name="parentOldDrawingCode">
              <AutoComplete
                allowClear
                options={parentOldDrawingCodeOptions ?? []}
                onSearch={(searchTerm) => refetchGetFCCParentOldDrawingCodes({ searchTerm })}/>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="背板來源">
            <Form.Item name="backplateSourceCode">
              <Select allowClear options={backplateSourceCodes?.options ?? []} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="新圖號">
            <span style={{ letterSpacing: '5px' }}>{newDrawingCode}</span>
          </Descriptions.Item>
          <Descriptions.Item label="流水二碼">
            {drawing?.serialString ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label="年份">
            {drawing?.year ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label={<>組合碼<RequiredMark /></>}>
            <Form.Item name="compositeCode" rules={[{ required: true, message: '必填' }]}>
              <Select options={compositeCodes?.options ?? []} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="拼數">
            <Form.Item name="compositeNumber">
              <InputNumber style={{ width: '100%' }} disabled={compositeCode !== 'A'} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={<>形狀碼<RequiredMark /></>}>
            <Form.Item name="shapeCodeID" rules={[{ required: true, message: '必填' }]}>
              <Select options={shapeCodes?.options ?? []} onChange={handleShapeChange} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="尺寸一碼">
            <Form.Item name="sizeCodeOne">
              {sizeCodeOne}
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="尺寸二碼">
            <Form.Item name="sizeCodeTwo">
              {sizeCodeTwo}
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="尺寸三碼">
            <Form.Item name="sizeCodeThree">
              {sizeCodeThree}
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={<>尺寸一碼數值<RequiredMark hidden={!shapeCode?.requiredSizeCodeOne} /></>}>
            <Form.Item
              name="sizeOne"
              rules={shapeCode?.requiredSizeCodeOne ? [{ required: true, message: '必填' }] : []}
            >
              <InputNumber stringMode style={{ width: '100%' }} min={0} onChange={handleSizeOneChange} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={<>尺寸二碼數值<RequiredMark hidden={!shapeCode?.requiredSizeCodeTwo} /></>}>
            <Form.Item
              name="sizeTwo"
              rules={shapeCode?.requiredSizeCodeTwo ? [{ required: true, message: '必填' }] : []}
            >
              <InputNumber stringMode style={{ width: '100%' }} min={0} onChange={handleSizeTwoChange} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={<>尺寸三碼數值<RequiredMark hidden={!shapeCode?.requiredSizeCodeThree} /></>}>
            <Form.Item
              name="sizeThree"
              rules={shapeCode?.requiredSizeCodeThree ? [{ required: true, message: '必填' }] : []}
            >
              <InputNumber stringMode style={{ width: '100%' }} min={0} onChange={handleSizeThreeChange} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="角加工碼">
            <Form.Item name="notchCodeID" noStyle />
            <Form.Item name="notchCode">
              {disabledNotchCode ? '' : notchCode ?? ''}
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item
            span={2}
            labelStyle={{ display: 'none' }}>
            <ExpandDescriptionsItem colspan="4">
              <Descriptions
                bordered
                column={2}
                labelStyle={{ width: 140 }}
                size="small">
                <Descriptions.Item label="大Ｒ角">
                  <Form.Item name="radiusAngleCount">
                    <Select disabled={disabledNotchCode} options={notchCodes?.radiusAngleOptions} onChange={handleRadiusAngleCountChange} />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="大Ｃ角">
                  <Form.Item name="chamferAngleCount">
                    <Select disabled={disabledNotchCode} options={chamferAngleOptions} onChange={handleChamferAngleCountChange} />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </ExpandDescriptionsItem>
          </Descriptions.Item>
          <Descriptions.Item label="邊緣小Ｒ">
            <Form.Item name="edgeRadiusCount">
              <InputNumber disabled={disabledNotchCode} min={0} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="邊緣小Ｃ">
            <Form.Item name="edgeChamferCount">
              <InputNumber disabled={disabledNotchCode} min={0} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="噴砂">
            <Form.Item name="isSandblasting" initialValue={false}>
              <Radio.Group optionType="button" options={[{ label: '有', value: true }, { label: '無', value: false }]} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item span={3} label="備註">
            <Form.Item name="remark">
              <Input.TextArea />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item span={3} label="母圖">
            <Form.Item name="parentNewDrawingCodeID">
              <Select
                showSearch
                allowClear
                disabled={isChildForm}
                filterOption={(input, option) => (option?.label?.toUpperCase() ?? '').includes(input?.toUpperCase())}
                options={drawingOptionsForParentDrawing ?? []} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item span={3} label="子圖">
            <div style={{ display: 'grid', gridTemplateColumns: 'auto 100px', gap: 'var(--mzn-spacing-2)' }}>
              <Form.Item name="childNewDrawingCodeIDs">
                <Select
                  showSearch
                  mode="multiple"
                  disabled={isChildForm}
                  filterOption={(input, option) => (option?.label?.toUpperCase() ?? '').includes(input?.toUpperCase())}
                  options={drawingOptionsForChildDrawings ?? []}
                  loading={isLoading}
                  onDeselect={async (deselectedCodeID) => {
                    if (createdChildNewDrawingCodeIDs?.includes(deselectedCodeID)) {
                      try {
                        await deleteDrawing({ id: deselectedCodeID, bu: 'FCC' });
                      } catch (error) {
                        message.error('子圖刪除失敗');
                      }
                    }
                  }} />
              </Form.Item>
              <Button
                disabled={isChildForm}
                onClick={() => openModal()}
              >
                新增子圖
              </Button>
            </div>
          </Descriptions.Item>
          <Descriptions.Item span={3} label={<>藍圖</>}>
            <Form.Item
              name="file"
              rules={[{ required: true, message: '必填' }]}>
              <UploadDrawingField currentDrawing={drawing ?? pendingDrawing ?? null} />
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
      </Form>
      <Modal
        width={1200}
        open={open}
        title="新增子圖號"
        cancelText="取消"
        onCancel={() => {
          closeModal();
          childDrawingForm.setFieldsValue({});
        }}
        okText="建立"
        okButtonProps={{
          htmlType: 'submit',
          form: 'childDrawingForm',
          loading: childDrawingFile?.[0]?.status === 'uploading',
        }}>
        <FCCDrawingEditForm
          id="childDrawingForm"
          form={childDrawingForm}
          drawing={drawing}
          pendingDrawing={pendingDrawing}
          onFinish={async (payload) => {
            try {
              const response = await createFCCDrawing({ drawings: [ payload ] });
              const childNewDrawingID = response?.data?.[0]?.id;
              if (childNewDrawingID) {
                setCreatedChildNewDrawingCodeIDs((prevIDs) => [...prevIDs, childNewDrawingID]);
                message.success('子圖建立成功');
                closeModal();
                await mutateFCCDrawing();
                form?.setFieldValue('childNewDrawingCodeIDs', [...(childNewDrawingCodeIDs ?? []), childNewDrawingID]);
              }
            } catch (error: any) {
              const errorMsg = JSON.parse(error?.message);
              switch (true) {
                case errorMsg?.title === 'DuplicatedDrawingCode' && errorMsg?.message === 'oldDrawingFixed':
                  message.error('修正後舊圖號重複');
                  break;
                default:
                  message.error('子圖建立失敗');
                  break;
              }
            }
          }}
        />
      </Modal>
    </div>
  )
}