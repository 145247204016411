import { useState } from 'react';
import useSWR from 'swr';
import { API_NAMESPACE } from '../request';

export type LoaderLabelInfoParams = {
  loaderIds: string[];
  shelfIds: string[];
  categoryCodes: string[];
};

export type LoaderLabelInfoProps = {
  loaderId: string;
  loaderCategoryName: string;
  loaderWidthCm: string;
  loaderLengthCm: string;
  loaderHeightCm: string;
  loaderCapacityL: string;
};

const namespace = API_NAMESPACE.MATERIALS;
export const useLoaderLabelInfo = () => {
  const [params, setParams] = useState<
    Partial<LoaderLabelInfoParams> | undefined
  >();

  const { data } = useSWR<LoaderLabelInfoProps[]>(
    params
      ? [
          '/loaders/loader-label-info',
          {
            namespace,
            params,
          },
        ]
      : null
  );

  return {
    data: data ?? [],
    mutateParams: setParams,
  };
};
