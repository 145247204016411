import useSWR from 'swr';
import styles from './routing-page.module.scss';
import {
  Button,
  Message,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  SelectValue,
  Tab,
  TabPane,
  Table,
  Tabs,
  Tag,
  Typography,
} from '@mezzanine-ui/react';
import { useForm } from 'react-hook-form';
import {
  FormFieldsWrapper,
  SelectField,
  TextAreaField,
} from '@mezzanine-ui/react-hook-form';
import { RoutingFilters } from './RoutingFilters';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { API_NAMESPACE, request } from '@solar/data';
import {
  DetailFormOptions,
  DetailTabType,
  ExtendedRoutingRecord,
  FilterOptions,
  LocalFilterOptions,
  RoutingRecord,
} from './typings';
import { useRoutingTableColumn } from './use-routing-table-column';
import { uniqBy } from 'lodash';
import moment from 'moment';
import { useSortingTable } from './use-sorting-table';
import { RoutingDetailCustomFormCreator } from './RoutingDetailCustomFormCreator';
import { useLocationViewerPopup } from './use-location-viewer-popup';
import { useDrawingPreview } from './use-drawing-preview';
import { RoutingFlowChart } from './RoutingFlowChart';

export function RoutingPage({ imported }: { imported?: boolean }) {
  const methods = useForm<FilterOptions>();

  const localMethods = useForm<LocalFilterOptions>();
  const detailMethods = useForm<DetailFormOptions>();
  const defaultOldMaterialId = useMemo(
    () => (imported ? '102000113' : '161000242'),
    [imported]
  );

  useEffect(() => {
    methods.setValue('oldMaterialId', defaultOldMaterialId);
  }, [methods, defaultOldMaterialId]);

  const [queryParams, setQueryParams] = useState<FilterOptions>({
    oldMaterialId: defaultOldMaterialId,
  });

  const { data: workCenterData } = useSWR<{ id: string; name: string }[]>([
    `/routings${imported ? '/temp' : ''}/workCenters`,
    { namespace: API_NAMESPACE.MATERIALS },
  ]);

  const workCenterMap = useMemo<Record<string, string>>(
    () =>
      (workCenterData ?? []).reduce(
        (vars, row) => ({
          ...vars,
          [row.id]: row.name,
        }),
        {}
      ),
    [workCenterData]
  );

  const workCenterGroupMap = useMemo(
    () =>
      (workCenterData ?? []).reduce<
        Record<string, { id: string; name: string }[]>
      >(
        (vars, row) => ({
          ...vars,
          [row.id.substring(0, 3)]: [
            ...(vars[row.id.substring(0, 3)] || []),
            row,
          ],
        }),
        {}
      ),
    [workCenterData]
  );

  const updateQueryParams = useCallback(() => {
    setQueryParams(methods.getValues());
  }, [methods]);

  const queryURL = useMemo(() => {
    if (!Object.values(queryParams).filter((val) => val).length)
      return undefined;

    const params = new URLSearchParams();

    Object.entries(queryParams).forEach(([key, value]) => {
      params.set(key, value);
    });

    return [
      `/routings${imported ? '/temp' : ''}/newRoutings?${params.toString()}`,
      { namespace: API_NAMESPACE.MATERIALS },
    ];
  }, [queryParams, imported]);

  const { data, mutate } = useSWR<RoutingRecord[]>(queryURL);

  const mesVersion = localMethods.watch('mesRecipeVersion');
  const versionOptions = useMemo<SelectValue[]>(
    () =>
      uniqBy(data, 'mesRecipeVersion').map((record) => ({
        id: record.mesRecipeVersion?.toString() ?? '0',
        name: `版本：${record.mesRecipeVersion ?? 0}`,
      })),
    [data]
  );

  const dataSource = useMemo<ExtendedRoutingRecord[]>(() => {
    if (!mesVersion || !data) return [];

    let currentOrderNumber = 1;

    return data
      .filter((record) => record.mesRecipeVersion === Number(mesVersion.id))
      .map((record, index, records) => {
        if (
          index !== 0 &&
          records[index - 1].actionCode !== record.actionCode
        ) {
          currentOrderNumber += 1;
        }

        return {
          ...record,
          selectedWorkCenterOptions: (record.allowWorkCenterIds ?? []).map(
            (workCenterId) => ({
              id: workCenterId,
              name: `${workCenterId} ${workCenterMap[workCenterId] as string}`,
            })
          ),
          orderNumber: currentOrderNumber,
          workCenterNames: (record.allowWorkCenterIds ?? []).map(
            (workCenterId) =>
              `${workCenterId} ${workCenterMap[workCenterId] as string}`
          ),
        };
      });
  }, [data, mesVersion, workCenterMap]);

  const [popper, setLocationViewerAnchor] =
    useLocationViewerPopup(localMethods);

  const columns = useRoutingTableColumn({
    methods: localMethods,
    dataSource,
    workCenterGroupMap,
    setLocationViewerAnchor,
    imported,
  });

  useEffect(() => {
    if (
      !mesVersion ||
      !versionOptions.find((option) => option.id === mesVersion.id)
    ) {
      localMethods.setValue('mesRecipeVersion', versionOptions[0]);
    }
  }, [mesVersion, versionOptions, localMethods]);

  useEffect(() => {
    localMethods.setValue('tableValues', dataSource);
  }, [localMethods, dataSource]);

  const tableValues = localMethods.watch('tableValues');
  const editingRowId = localMethods.watch('editingRowId');
  const editingDetailId = localMethods.watch('editingDetailId');
  const deletingRowId = localMethods.watch('deletingRowId');

  useEffect(() => {
    localMethods.setValue('deletingRowId', undefined);
  }, [tableValues, localMethods]);

  const tableValuesWithOrderNumber = useMemo(
    () =>
      (tableValues ?? []).reduce(
        ({ list, currentOrderNumber }, record, index, records) => {
          const shouldAdd =
            index !== 0 && records[index - 1].actionCode !== record.actionCode;

          return {
            list: [
              ...list,
              {
                ...record,
                workCenterNames: record.selectedWorkCenterOptions.map(
                  ({ name }) => name
                ),
                sortingId: currentOrderNumber + (shouldAdd ? 1 : 0),
              },
            ],
            currentOrderNumber: currentOrderNumber + (shouldAdd ? 1 : 0),
          };
        },
        { list: [] as ExtendedRoutingRecord[], currentOrderNumber: 1 }
      ).list,
    [tableValues, editingRowId]
  ); // Force update when row edited

  const tableRef = useSortingTable(tableValuesWithOrderNumber, localMethods);
  const [detailTab, setDetailTab] = useState<DetailTabType>(
    DetailTabType.REMARK
  );

  useEffect(() => {
    if (editingDetailId) {
      const { tableValues } = localMethods.getValues();

      const targetRow = (tableValues ?? []).find(
        (row) => row.id === editingDetailId
      );

      detailMethods.reset(
        targetRow
          ? {
              remark: targetRow.remark ?? undefined,
              customForms: targetRow.customForms ?? [],
              productCustomForms: targetRow.productCustomForms ?? [],
            }
          : undefined
      );
    }
  }, [editingDetailId, detailMethods, localMethods]);

  const [syncLoading, setSyncLoading] = useState(false);

  const syncToDB = useCallback(async () => {
    const values = localMethods.getValues();

    setSyncLoading(true);

    await request(`/routings${imported ? '/temp' : ''}/newRoutings`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        routings: (values.tableValues ?? []).map((row, index) => ({
          ...row,
          sequence: index + 1,
          customForms: row.customForms ? JSON.stringify(row.customForms) : null,
          allowWorkCenterIds: (row.selectedWorkCenterOptions ?? []).map(
            ({ id }) => id
          ),
        })),
      }),
      namespace: API_NAMESPACE.MATERIALS,
    });

    setSyncLoading(false);

    Message.success('資料已同步完成');

    mutate();
  }, [localMethods, mutate, imported]);

  const [drawingPreview, showDrawingPreview] = useDrawingPreview();

  return (
    <div className={styles.wrapper}>
      <Typography variant="h2">生產路徑</Typography>
      <FormFieldsWrapper methods={methods}>
        <RoutingFilters updateQueryParams={updateQueryParams} />
      </FormFieldsWrapper>
      <FormFieldsWrapper
        className={styles.localFormWrapper}
        methods={localMethods}
      >
        {versionOptions.length > 0 ? (
          <div className={styles.localFilter}>
            <SelectField
              options={versionOptions}
              registerName="mesRecipeVersion"
            />
            {data?.[0]?.mesRecipe ? (
              <Tag size="small">Recipe: {data[0].mesRecipe}</Tag>
            ) : null}
            {data?.[0]?.oldMaterialId ? (
              <Tag size="small">舊料號: {data[0].oldMaterialId}</Tag>
            ) : null}
            {data?.[0]?.componentCode ? (
              <Tag size="small">成分代碼: {data[0].componentCode}</Tag>
            ) : null}
            {data?.[0]?.drawingCode ? (
              <Tag
                style={{ cursor: 'pointer' }}
                onMouseEnter={
                  data?.[0]?.newDrawingCode
                    ? (e) =>
                        showDrawingPreview(data[0].newDrawingCode as string)(e)
                    : undefined
                }
                onMouseLeave={
                  data?.[0]?.newDrawingCode
                    ? (e) => showDrawingPreview()(e)
                    : undefined
                }
                size="small"
              >
                圖號: {data[0].drawingCode}
              </Tag>
            ) : null}
            {data?.[0]?.newDrawingCode ? (
              <Tag
                style={{ cursor: 'pointer' }}
                onMouseEnter={(e) =>
                  showDrawingPreview(data[0].newDrawingCode as string)(e)
                }
                onMouseLeave={(e) => showDrawingPreview()(e)}
                size="small"
              >
                新圖號: {data[0].newDrawingCode}
              </Tag>
            ) : null}
            {data?.[0]?.sandBlasting ? (
              <Tag size="small">噴砂: {data[0].sandBlasting}</Tag>
            ) : null}
          </div>
        ) : null}
        {data?.[0]?.materialDescription ? (
          <Typography variant="body1" color="secondary-light">
            物料描述：
            {data?.[0]?.materialDescription}
          </Typography>
        ) : null}
        <div className={styles.tableWrapper}>
          <div
            className={styles.table}
            style={{
              height: 52 * ((tableValuesWithOrderNumber?.length ?? 0) + 1),
            }}
          >
            <Table
              ref={tableRef as any}
              className={styles.table}
              loading={false}
              bodyClassName={styles.tableBody}
              dataSource={tableValuesWithOrderNumber}
              columns={columns}
            />
          </div>
        </div>
        {versionOptions.length > 0 ? (
          <div className={styles.localFilter}>
            <Button
              disabled={syncLoading}
              variant="outlined"
              onClick={() =>
                localMethods.setValue('tableValues', [
                  ...tableValuesWithOrderNumber,
                  {
                    id: Math.random().toString(),
                    allowWorkCenterIds: [] as string[],
                    orderNumber: tableValuesWithOrderNumber
                      ? (tableValuesWithOrderNumber[
                          tableValuesWithOrderNumber.length - 1
                        ]?.orderNumber ?? 0) + 1
                      : 1,
                    oldMaterialId: data?.[0].oldMaterialId ?? '',
                    componentCode: data?.[0].componentCode ?? '',
                    drawingCode: data?.[0].drawingCode ?? '',
                    newDrawingCode: data?.[0].newDrawingCode ?? '',
                    sandBlasting: data?.[0].sandBlasting ?? '',
                    materialDescription: data?.[0].materialDescription ?? '',
                    mesRecipe: data?.[0].mesRecipe ?? '',
                    mesRecipeVersion: data?.[0].mesRecipeVersion ?? 0,
                    newRecipe: '',
                    areaDesc: '',
                    sequence: 0,
                    actionCode: '',
                    action: '',
                    sourceMaterialId: '',
                    destMaterialId: '',
                    workCenterId: '',
                    workCenterName: '',
                    locationId: '',
                    manHours: 0,
                    machineHours: 0,
                    standardWeight: 0,
                    createdAt: moment().toISOString(),
                    updatedAt: moment().toISOString(),
                    selectedWorkCenterOptions: [] as SelectValue[],
                  } as ExtendedRoutingRecord,
                ])
              }
            >
              新增行為
            </Button>
            <Button
              loading={syncLoading}
              onClick={syncToDB}
              type="button"
              variant="contained"
            >
              儲存至資料庫
            </Button>
          </div>
        ) : null}
      </FormFieldsWrapper>
      <Modal
        onClose={() => localMethods.setValue('editingDetailId', undefined)}
        severity="info"
        size="extraLarge"
        open={!!editingDetailId}
      >
        <ModalHeader>工作中心詳細資訊</ModalHeader>
        <ModalBody>
          <FormFieldsWrapper methods={detailMethods}>
            {((row: ExtendedRoutingRecord | undefined) => {
              if (!row) return null;

              return (
                <header className={styles.detailHeader}>
                  <Tag size="small">行為碼: {row.actionCode}</Tag>
                  <Tag size="small">行為: {row.action}</Tag>
                  <Tag size="small">工作中心代碼: {row.workCenterId}</Tag>
                  <Tag size="small">工作中心: {row.workCenterName}</Tag>
                  <Tag size="small">地點: {row.locationId}</Tag>
                  <Tag size="small">加工區: {row.areaDesc}</Tag>
                  <Tag size="small">原料號: {row.sourceMaterialId}</Tag>
                  <Tag size="small">汲料號: {row.destMaterialId}</Tag>
                </header>
              );
            })(
              tableValuesWithOrderNumber.find(
                (row) => row.id === editingDetailId
              )
            )}
            <Tabs
              activeKey={detailTab}
              onChange={(newTab) => setDetailTab(newTab as DetailTabType)}
            >
              <TabPane tab={<Tab>生產資訊</Tab>} key={DetailTabType.REMARK}>
                <TextAreaField
                  label="生產資訊"
                  width={600}
                  rows={12}
                  placeholder="請輸入生產參數，可多行填寫"
                  registerName="remark"
                />
              </TabPane>
              <TabPane tab={<Tab>機台參數</Tab>} key={DetailTabType.FORM}>
                <RoutingDetailCustomFormCreator
                  title="機台參數"
                  fieldName="customForms"
                />
              </TabPane>
              <TabPane
                tab={<Tab>產品參數</Tab>}
                key={DetailTabType.PRODUCT_FORM}
              >
                <RoutingDetailCustomFormCreator
                  title="產品參數"
                  fieldName="productCustomForms"
                />
              </TabPane>
            </Tabs>
          </FormFieldsWrapper>
        </ModalBody>
        <ModalActions
          confirmText="確認儲存"
          cancelText="取消"
          onConfirm={() => {
            const { remark, customForms, productCustomForms } =
              detailMethods.getValues();

            const editingIndex = tableValuesWithOrderNumber.findIndex(
              (row) => row.id === editingDetailId
            );

            if (~editingIndex) {
              localMethods.setValue('tableValues', [
                ...(tableValues ?? []).slice(0, editingIndex),
                {
                  ...(tableValues ?? [])[editingIndex],
                  remark,
                  customForms,
                  productCustomForms,
                },
                ...(tableValues ?? []).slice(editingIndex + 1),
              ]);
            }

            localMethods.setValue('editingDetailId', undefined);
          }}
          onCancel={() => localMethods.setValue('editingDetailId', undefined)}
        />
      </Modal>
      <Modal severity="warning" open={!!deletingRowId}>
        <ModalHeader>確認刪除</ModalHeader>
        <ModalBody>
          即將刪除行為 [
          {tableValuesWithOrderNumber?.find((row) => row.id === deletingRowId)
            ?.action ?? '-'}
          ]，請確認是否刪除？
        </ModalBody>
        <ModalActions
          confirmText="確認"
          cancelText="取消"
          confirmButtonProps={{
            danger: true,
          }}
          onConfirm={() =>
            localMethods.setValue(
              'tableValues',
              tableValuesWithOrderNumber?.filter(
                (record) => record.id !== deletingRowId
              ) ?? []
            )
          }
          onCancel={() => localMethods.setValue('deletingRowId', undefined)}
        />
      </Modal>
      {popper}
      {drawingPreview}
      <FormFieldsWrapper className={styles.chartWrapper} methods={localMethods}>
        <RoutingFlowChart />
      </FormFieldsWrapper>
    </div>
  );
}
