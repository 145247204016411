import {
  AutoCompleteField,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { useForm, useWatch } from 'react-hook-form';
import { Row, Space } from 'antd';
import {
  NextPagination,
  getNextParams,
  syncLimsSubmissionResults,
  useGetSubmissionOrganizations,
  useGetSubmissionResults,
  useGetSubmissionStatuses,
} from '@solar/data';
import { useCallback, useEffect, useState } from 'react';
import { Button, Message, SelectValue } from '@mezzanine-ui/react';
import {
  ModalGroup,
  PageLayout,
  MznPaginationTable,
  useModalGroupController,
} from '@solar/templates';
import { Modals } from './Modal.enum';
import { SubmissionDetailModal } from './SubmissionDetailModal';
import { SyncLimsButton } from '../SyncLimsButton/SyncLimsButton';
import { labOptions } from '../hooks/typing';

const defaultValues = {
  factory: labOptions[0],
  submissionId: '',
  createBy: '',
  department: null,
  statuses: null,
};

export function SubmissionResultsPage() {
  const methods = useForm<{
    factory: SelectValue;
    submissionId: string;
    createBy: string;
    department: SelectValue | null;
    statuses: SelectValue | null;
  }>({ defaultValues });

  const modalGroupController = useModalGroupController([
    { name: Modals.SUBMISSION_DETAIL },
  ]);

  const targetFactory =
    useWatch({
      control: methods.control,
      name: 'factory',
    })?.id ?? '1100';

  const { submissionResults, refetchGetSubmissionResults, pageInfo } =
    useGetSubmissionResults({ factory: targetFactory });
  const { submissionOrganizations, refetchGetSubmissionOrganizations } =
    useGetSubmissionOrganizations({ factory: targetFactory });
  const { submissionStatuses } = useGetSubmissionStatuses();

  const statusOptions = submissionStatuses?.map(({ status, statusDesc }) => ({
    id: status?.toString(),
    name: `${statusDesc}(${status})`,
  }));

  useEffect(() => {
    refetchGetSubmissionOrganizations({ factory: targetFactory });
  }, [refetchGetSubmissionOrganizations, targetFactory]);

  const organizationOptions = submissionOrganizations?.map(
    ({ department }) => ({
      id: department,
      name: department,
    })
  );

  const onOrganizationsSearch = (searchTerm: string) => {
    refetchGetSubmissionOrganizations({ factory: targetFactory, searchTerm });
  };

  const refetchGetSubmissionResultsWithPagination = useCallback(
    (nextPagination: NextPagination) => {
      const formState = methods?.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      refetchGetSubmissionResults({
        factory: targetFactory,
        offset,
        limit,
        submissionId: formState?.submissionId,
        createBy: formState?.createBy,
        statuses: formState?.statuses ? [formState?.statuses?.id] : [],
        department: formState?.department?.id,
      });
    },
    [methods, pageInfo, refetchGetSubmissionResults, targetFactory]
  );

  const onSearch = () =>
    refetchGetSubmissionResultsWithPagination({ nextPage: 1 });

  return (
    <PageLayout
      title="檢驗單結果"
      extraContent={
        <Row style={{ height: '100%' }} justify="end" align="bottom">
          <SyncLimsButton factory={targetFactory} afterSync={onSearch} />
        </Row>
      }
    >
      <FormFieldsWrapper methods={methods}>
        <Space align="end">
          <SelectField
            required
            registerName="factory"
            label="實驗室"
            options={labOptions}
          />
          <InputField registerName="submissionId" label="檢驗單號" />
          <AutoCompleteField
            label="委託部門"
            registerName="department"
            options={organizationOptions}
            onSearch={onOrganizationsSearch}
          />
          <InputField registerName="createBy" label="建單人" />
          <SelectField
            registerName="statuses"
            label="狀態"
            options={statusOptions}
          />
          <Button type="button" variant="contained" onClick={onSearch}>
            搜尋
          </Button>
        </Space>
      </FormFieldsWrapper>
      <MznPaginationTable
        columns={[
          {
            title: '操作',
            render: (source) => (
              <Space>
                <Button
                  type="button"
                  variant="contained"
                  onClick={() =>
                    modalGroupController.openModal(
                      Modals.SUBMISSION_DETAIL,
                      source
                    )
                  }
                >
                  檢視
                </Button>
              </Space>
            ),
          },
          {
            title: '狀態',
            dataIndex: 'status',
          },
          {
            title: '工廠',
            dataIndex: 'factory',
          },
          {
            title: '檢驗單號',
            dataIndex: 'id',
          },
          {
            title: '委託部門',
            dataIndex: 'metadata.customerName',
          },
          {
            title: '建單人',
            dataIndex: 'createdBy',
          },
        ]}
        dataSource={submissionResults ?? []}
        pageInfo={pageInfo}
        fetchData={refetchGetSubmissionResultsWithPagination}
      />
      <ModalGroup {...modalGroupController}>
        <SubmissionDetailModal />
      </ModalGroup>
    </PageLayout>
  );
}
