import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { OffsetBased } from '../pagination';
import { useState } from 'react';

const namespace = API_NAMESPACE.MATERIALS;

type GetDeptsParams = OffsetBased & {
  searchTerm?: string;
};

export function useGetDepts() {
  const [params, setParams] = useState<GetDeptsParams>();
  const { data, error, mutate } = useSWR(['/hr/depts', { params, namespace }]);

  return {
    depts: data?.records ?? [],
    isLoading: !data && !error,
    refetchGetDepts: setParams,
    mutateGetDepts: mutate,
  };
}
