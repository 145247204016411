import { Group } from '../typings';

const roleGroupCache = new Map<Record<string, Group>, Record<string, Group>>();

export function getRoleGroup(nextLevel?: Record<string, Group>) {
  if (nextLevel && roleGroupCache.has(nextLevel))
    return roleGroupCache.get(nextLevel);

  const generated = {
    S: {
      name: '客供',
      ...(nextLevel ? { nextLevel } : {}),
    },
    0: {
      name: '自有料(無價)',
      ...(nextLevel ? { nextLevel } : {}),
    },
    1: {
      name: '自有料(有價)',
      ...(nextLevel ? { nextLevel } : {}),
    },
    M: {
      name: '代工成品',
      ...(nextLevel ? { nextLevel } : {}),
    },
  };

  if (nextLevel) {
    roleGroupCache.set(nextLevel, generated);
  }

  return generated;
}
