/* eslint-disable @typescript-eslint/no-empty-function */
import { AutoComplete, Button, Icon, Option, Select, SelectValue, Typography } from "@mezzanine-ui/react";
import { useCallback, useEffect, useState } from "react";
import { CurrentLocation, LocationLevel, useSVGs, UseLocationSelector, useCurrentLocationOptions, useLocationsBySearchTerm } from "@solar/data";
import { LocationSVGViewer } from "./LocationSVGViewer";
import styles from './location-selector.module.scss';
import { ChevronDownIcon, ChevronUpIcon } from "@mezzanine-ui/icons";

type UseLocationSelectorProps = {
  showSVG?: boolean,
  onSelect?: (item: any) => void,
}

export function useLocationSelector(options?: UseLocationSelectorProps):UseLocationSelector {
  const showSVG = options?.showSVG ?? true;
  const [currentLevel, setCurrentLevel] = useState(LocationLevel.FACTORY);
  const [currentLocation, setCurrentLocation] = useState<CurrentLocation>({});
  const [selectedItem, setSelectedItem] = useState();
  const { svg, mutateGetSVG } = useSVGs({ showSVG });
  const {
    factoryOptions,
    buildingOptions,
    floorOptions,
    areaOptions,
  } = useCurrentLocationOptions(currentLocation);

  // const onSelect = options?.onSelect ?? (() => {});

  useEffect(() => {
    if (factoryOptions?.[0]) {
      const factory = factoryOptions?.[0];
      mutateGetSVG(factory?.id);
      setCurrentLevel(LocationLevel.BUILDING);
      setCurrentLocation({ factory });
    }
  }, [factoryOptions, mutateGetSVG]);

  /** @description: 依據傳入的 nextCurrentLocation 改變選擇器 */
  const handleNextCurrentLocation = useCallback((nextCurrentLocation: CurrentLocation) => {
    switch (true) {
      case !nextCurrentLocation?.factory: {
        const factory = factoryOptions?.[0];
        mutateGetSVG(factory?.id);
        setCurrentLevel(LocationLevel.BUILDING);
        setCurrentLocation({ factory });
        break;
      }
      case !nextCurrentLocation?.building: {
        const factory = factoryOptions?.find((option) => option?.id === nextCurrentLocation?.factory?.id);
        mutateGetSVG(factory?.id);
        setCurrentLevel(LocationLevel.BUILDING);
        setCurrentLocation({ factory });
        break;
      }
      case !nextCurrentLocation?.floor: {
        const factory = factoryOptions?.find((option) => option?.id === nextCurrentLocation?.factory?.id);
        const building = factory?.buildings?.find((option) => option?.id === nextCurrentLocation?.building?.id);
        const floor = building?.floors?.[0];
        mutateGetSVG(floor?.id);
        setCurrentLevel(LocationLevel.AREA);
        setCurrentLocation({ factory, building, floor });
        break;
      }
      case !nextCurrentLocation?.area: {
        mutateGetSVG(nextCurrentLocation?.floor?.id);
        setCurrentLevel(LocationLevel.AREA);
        setCurrentLocation(nextCurrentLocation);
        break;
      }
      default: {
        mutateGetSVG(nextCurrentLocation?.area?.id);
        setCurrentLevel(LocationLevel.ZONE);
        setCurrentLocation(nextCurrentLocation);
        break;
      }
    }
  }, [factoryOptions, mutateGetSVG]);

  const handlePrevLevel = () => {
    switch (currentLevel) {
      case LocationLevel.AREA:
        mutateGetSVG(currentLocation?.factory?.id);
        setCurrentLevel(LocationLevel.BUILDING);
        setCurrentLocation({ factory: currentLocation?.factory });
        break;
      case LocationLevel.ZONE:
      case LocationLevel.STACK:
        handleNextCurrentLocation({
          factory: currentLocation?.factory,
          building: currentLocation?.building,
          floor: currentLocation?.floor,
        });
        break;
      default:
        break;
    }
  }

  /** @description: 沒有指定 nextLevel，將視為 currentLevel 往下選一個階層 */
  const handleNextLevel = async (item: any, nextLevel?: LocationLevel) => {
    console.log({ nextLevel, currentLevel, item });

    switch (nextLevel ?? currentLevel) {
      case LocationLevel.FACTORY: {
        const targetFactory = factoryOptions?.find((option) => option?.id === item?.id);
        handleNextCurrentLocation({
          factory: targetFactory,
        });
        break;
      }
      case LocationLevel.BUILDING: {
        const targetBuilding = buildingOptions?.find((option) => option?.id === item?.id);
        handleNextCurrentLocation({
          factory: currentLocation?.factory,
          building: targetBuilding,
        });
        break;
      }
      case LocationLevel.FLOOR: {
        const targetFloor = floorOptions?.find((option) => option?.id === item?.id);
        handleNextCurrentLocation({
          factory: currentLocation?.factory,
          building: currentLocation?.building,
          floor: targetFloor,
        });
        break;
      }
      case LocationLevel.AREA: {
        const targetArea = areaOptions?.find((option) => option?.id === item?.id);
        handleNextCurrentLocation({
          factory: currentLocation?.factory,
          building: currentLocation?.building,
          floor: currentLocation?.floor,
          area: targetArea,
        });
        break;
      }
      case LocationLevel.ZONE: {
        switch (true) {
          case !!item?.equipments:
            handleNextCurrentLocation({
              factory: currentLocation?.factory,
              building: currentLocation?.building,
              floor: currentLocation?.floor,
              area: currentLocation?.area,
              zone: currentLocation?.zone,
              stack: { id: item?.chartId ?? '', name: '' },
            });
            setSelectedItem(item);
            break;
          case !!item?.stack:
            handleNextCurrentLocation({
              factory: currentLocation?.factory,
              building: currentLocation?.building,
              floor: currentLocation?.floor,
              area: currentLocation?.area,
              zone: currentLocation?.zone,
              stack: item?.stack,
            });
            break;
          default:
            break;
        }
        break;
      }
      case LocationLevel.STACK: {
        handleNextCurrentLocation({
          factory: currentLocation?.factory,
          building: currentLocation?.building,
          floor: currentLocation?.floor,
          area: currentLocation?.area,
          zone: currentLocation?.zone,
          stack: item,
        });
        break;
      }
      default:
        break;
    }
  };

  /** @description: 重設 廠區 和 棟樓 */
  const resetLocationSelector = useCallback((defaultCurrentLocation?: CurrentLocation) => {
    handleNextCurrentLocation(defaultCurrentLocation ?? {});
    setSelectedItem(undefined);
  }, [handleNextCurrentLocation]);

  // useEffect(() => {
  //   console.log('currentLevel: ', currentLevel);
  //   console.log('currentLocation: ', currentLocation);
  // }, [currentLevel, currentLocation]);

  /** @description: 預設 廠區 和 棟樓 */
  // useEffect(() => resetLocationSelector(), [resetLocationSelector]);

  return {
    currentLevel,
    currentLocation,
    setCurrentLocation,
    selectedItem,
    svg,
    handlePrevLevel,
    handleNextLevel,
    handleNextCurrentLocation,
    resetLocationSelector,
  };
}

type LocationSelectorProps = UseLocationSelector & {
  showSVG?: boolean,
  selectedChartId?: string,
  expandable?: boolean,
  selectedType?: 'equipment' | 'stack',
  onSelect?: () => void,
};

export function LocationSelector({
  currentLevel,
  currentLocation,
  svg,
  handlePrevLevel,
  handleNextLevel,
  handleNextCurrentLocation,
  showSVG = true,
  selectedChartId,
  selectedType,
  expandable = false,
  onSelect,
}: LocationSelectorProps) {
  const [searchTerm, setSearchTerm] = useState<SelectValue>();
  const [searchText, setSearchText] = useState('');
  const [svgExpand, setSvgExpand] = useState(true);
  const {
    factoryOptions,
    buildingOptions,
    floorOptions,
    areaOptions,
    stackOptions,
    getCurrentLocation,
  } = useCurrentLocationOptions(currentLocation);
  const { locations } = useLocationsBySearchTerm({ searchText });

  /** @description: 透過關鍵字輸入 id 尋找 currentLocation 並更新 locationSelector */
  useEffect(() => {
    if (searchTerm?.id) {
      handleNextCurrentLocation?.(getCurrentLocation(searchTerm));
    }
  }, [getCurrentLocation, handleNextCurrentLocation, searchTerm]);

  const renderOptions = (options: SelectValue[]) => options?.map((option) => (
    <Option key={option?.id} value={option?.id}>{option?.name}</Option>
  ));

  return (
    <div className={styles.location_selector_wrapper}>
      <div>
        <AutoComplete
          options={locations?.map(({ id, level }) => ({ id, name: id, level }))}
          value={searchTerm}
          onChange={setSearchTerm}
          onSearch={setSearchText}
          placeholder="請輸入關鍵字搜尋" />
      </div>
      <div style={{ display: 'grid', gap: 'var(--mzn-spacing-2)', gridTemplateColumns: 'repeat(5, 1fr)' }}>
        <div>
          <Typography variant="h6" color="secondary">廠區</Typography>
          <Select
            fullWidth
            placeholder="尚未選取"
            value={currentLocation?.factory ?? null}
            onChange={(option) => handleNextLevel(option, LocationLevel.FACTORY)}>
            {renderOptions(factoryOptions)}
          </Select>
        </div>
        {currentLocation?.factory && (
          <div>
            <Typography variant="h6" color="secondary">棟</Typography>
            <Select
              fullWidth
              placeholder="尚未選取"
              value={currentLocation?.building ?? null}
              onChange={(option) => handleNextLevel(option, LocationLevel.BUILDING)}>
              {renderOptions(buildingOptions)}
            </Select>
          </div>
        )}
        {currentLocation?.building && (
          <div>
            <Typography variant="h6" color="secondary">樓</Typography>
            <Select
              fullWidth
              placeholder="尚未選取"
              value={currentLocation?.floor ?? null}
              onChange={(option) => handleNextLevel(option, LocationLevel.FLOOR)}>
              {renderOptions(floorOptions)}
            </Select>
          </div>
        )}
        {currentLocation?.floor && (
          <div>
            <Typography variant="h6" color="secondary">大區</Typography>
            <Select
              fullWidth
              placeholder="尚未選取"
              value={currentLocation?.area ?? null}
              onChange={(option) => handleNextLevel(option, LocationLevel.AREA)}>
              {renderOptions(areaOptions)}
            </Select>
          </div>
        )}
        {currentLocation?.area && selectedType === 'stack' && (
          <div>
            <Typography variant="h6" color="secondary">儲位</Typography>
            <Select
              fullWidth
              placeholder="尚未選取"
              value={currentLocation?.stack ?? null}
              onChange={(option) => handleNextLevel(option, LocationLevel.STACK)}>
              {renderOptions(stackOptions)}
            </Select>
          </div>
        )}
      </div>
      {!!showSVG && svgExpand && (
        <LocationSVGViewer
          svg={svg}
          currentLevel={currentLevel}
          currentLocation={currentLocation}
          handlePrevLevel={handlePrevLevel}
          handleNextLevel={handleNextLevel}
          handleNextCurrentLocation={handleNextCurrentLocation}
          selectedChartId={selectedChartId} />
      )}
      {expandable && (
        <Button
          variant="contained"
          prefix={<Icon icon={svgExpand ? ChevronUpIcon : ChevronDownIcon} />}
          onClick={() => setSvgExpand((prev) => !prev)}
        >
          {svgExpand ? '收起地圖' : '顯示地圖'}
        </Button>
      )}
    </div>
  )
}
