import { Message } from '@mezzanine-ui/react';
import { parseInt } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import useSWR from 'swr';
import { API_NAMESPACE } from '../../request';
import { Modal } from '../mainFile';

const namespace = API_NAMESPACE.HR;

type Title = '刪除將會把本次展開的全數刪除' | '編輯' | '新增';

type SelectPackingType = {
  id: number;
  name: string;
};

type DeliveryOrdersDetailType = {
  boxNum: number;
  quantity: number;
  boxQuantity: number;
  deliveryOrderLine: string;
  id: number | string;
  materialId: string;
  materialName: string;
  packagingMaterialId: string;
  packagingName: string;
  packingId: number;
  deliveryOrderLineQuantity: number;
  productUnit: string;
  snum: string | number;
};

type DeliveryOrdersDataType = {
  boxTotal: number;
  createdAt: string;
  details: DeliveryOrdersDetailType[];
};

const isDeliveryOrdersData = (obj: unknown): obj is DeliveryOrdersDataType => {
  if (obj && typeof obj === 'object') return 'details' in obj;
  return false;
};

export function useShipmentPackingDetail() {
  const methods = useForm();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const deliveryOrderId = useMemo(() => {
    const splitPath = pathname.split('/');
    return splitPath[splitPath.length - 1];
  }, [pathname]);

  const endpoint = useMemo(
    () => `/deliveryOrders/get/${deliveryOrderId}/packings`,
    [deliveryOrderId]
  );

  const [modal, setModal] = useState<Modal<Title>>(null);
  const [deliveryOrdersDetails, setDeliveryOrdersDetails] = useState<
    DeliveryOrdersDetailType[]
  >([]);
  const [deliveryOrderCreatedat, setDeliveryOrderCreatedat] = useState('');
  const [selectPacking, setSelectPacking] = useState<SelectPackingType | null>(
    null
  );
  const [productNum, setProductNum] = useState('');

  const deliveryOrderDetailId = useMemo(
    () => modal?.source?.id?.split('-')[0],
    [modal]
  );

  const {
    data: deliveryOrdersData,
    mutate: mutateDeliveryOrderData,
    error: deliveryOrdersDataError,
  } = useSWR([endpoint, { namespace }]);
  const { data: deliveryOrdersFilterData } = useSWR(() =>
    deliveryOrderDetailId
      ? [
          `/deliveryOrders/get/filter?deliveryOrderDetailId=${deliveryOrderDetailId}`,
          { namespace },
        ]
      : null
  );

  const defaultLimitCount = useMemo(
    () =>
      deliveryOrdersFilterData?.packings.find(
        (item: any) => item.id === selectPacking?.id
      )?.limitCount ?? '',
    [deliveryOrdersFilterData, selectPacking]
  );

  const createModal = useMemo(
    () => [
      {
        label: '包材名稱',
        required: true,
        fn: 'EnhanceAutoComplete',
        componentsProps: {
          registerName: 'fraction',
          mode: 'single',
          width: 172,
          options: deliveryOrdersFilterData?.packings ?? [],
          clearable: false,
          placeholder: '請輸入',
          defaultValue: deliveryOrdersFilterData?.packings?.find(
            (packing: { id: number }) => packing.id === modal?.source?.packingId
          ),
          onChange: (value: SelectPackingType) => setSelectPacking(value),
        },
      },
      {
        label: '每箱數量',
        required: true,
        fn: 'EnhanceAccountInput',
        componentsProps: {
          registerName: 'limitCount',
          width: 128,
          clearable: true,
          placeholder: '0',
          register: methods.register,
          setValue: methods.setValue,
          name: 'limitCount',
          defaultValue: selectPacking
            ? defaultLimitCount
            : modal?.source.boxQuantity,
        },
      },
      {
        label: ' ',
        required: false,
        fn: 'Info',
        componentsProps: {
          width: 128,
          content: modal?.source?.productUnit,
        },
      },
    ],
    [
      methods,
      modal,
      deliveryOrdersFilterData,
      defaultLimitCount,
      modal?.source?.boxQuantity,
      selectPacking,
    ]
  );

  const disabledModalSubmitButton = useMemo(() => {
    methods.watch();
    const requiredFields = createModal.filter((field) => field.required);
    let disabled = false;
    requiredFields.forEach((field) => {
      const fieldValue = methods.getValues(
        field.componentsProps.registerName ?? field.componentsProps.name ?? ''
      );
      if (!fieldValue || fieldValue === '' || fieldValue === '0') {
        disabled = true;
      }
    });
    return disabled;
  }, [createModal, methods.watch()]);

  const disabledTableSubmitButton = useMemo(() => {
    if (deliveryOrdersDetails.length === 0) return true;
    return deliveryOrdersDetails.some(
      (detail) =>
        typeof detail.snum !== 'number' && isNaN(parseInt(detail.snum))
    );
  }, [deliveryOrdersDetails]);

  const boxTotalNum = useMemo(() => {
    const indexes = deliveryOrdersDetails.map((detail) => detail.boxNum);
    const maxIndex = Math.max.apply(0, indexes);
    return isFinite(maxIndex) ? maxIndex : 0;
  }, [deliveryOrdersDetails]);

  const closeModal = useCallback(() => {
    setModal(null);
    methods.reset();
    methods.setValue('limitCount', '');
    setSelectPacking(null);
    setProductNum('');
  }, []);

  // NOTE: 彈窗送出按鈕
  const handleUpdateDeliveryTable = useCallback(
    (orderLineId: string, oldLargestIndex: number) => {
      methods.watch();

      // NOTE: 拆解 ID，取第 0 項，去掉重複 ex. [1123-0, 1123-1] => [1123]
      // detail.id 是項次的 ID，但是跟顯示的會不一樣，不確定差別
      const detailSet = new Set(
        deliveryOrdersDetails.map((detail) => {
          return `${detail.id}`.split('-')[0];
        })
      );
      const classifyDetailArr: DeliveryOrdersDetailType[][] = [];

      detailSet.forEach((detailId) => {
        const filterDetail = deliveryOrdersDetails.filter(
          (detail) => `${detail.id}`.split('-')[0] === detailId
        );
        // orderLineId 是項次、detailId 項次
        if (detailId === orderLineId) {
          // 這裡的遞迴是用來展開箱數，例如包材是 400，使用者設定 220 一箱，那這裡就會執行兩次（220, 180），然後組成array
          const recursiveUpdateArr = (
            productNum: number,
            index: number,
            arr: any[]
          ): any[] => {
            if (productNum <= 0) return arr;
            const fractionValue = methods.getValues('fraction');
            const limitCount = methods.getValues('limitCount');
            const cur = {
              ...modal?.source,
              packingId: fractionValue?.id,
              packagingMaterialId: fractionValue?.id,
              packagingName: fractionValue?.name,
              snum:
                (modal?.source?.deliveryOrderLineQuantity * 10 ** 8) %
                  (limitCount * 10 ** 8) !==
                  0 && productNum === 1
                  ? 2
                  : 1,
              boxNum: index,
              quantity:
                (modal?.source?.deliveryOrderLineQuantity * 10 ** 8) %
                  (limitCount * 10 ** 8) !==
                  0 && productNum === 1
                  ? (modal?.source?.deliveryOrderLineQuantity % limitCount)
                      .toFixed(8)
                      .replace(/\.?0+$/, '')
                  : limitCount,
              boxQuantity: limitCount,
              boxTotal: productNum ?? modal?.source.boxTotal,
            };
            arr.push(cur);
            return recursiveUpdateArr(productNum - 1, index + 1, arr);
          };

          // 這會是一個 Array 的 Array
          // [[000010 的 object, 000010 的 object], [000020's object], [000030's object]]
          // 這裡的 object 就是上方的 cur
          // 第一層 array 表達的是不同的項次，然後第二層特定項次的展開（一個展開就是一個項次對一個料號，也就是一個箱子）
          // classifyDetailArr 是一個 Arr，然後每次 push 都是 push 進去一個 array
          classifyDetailArr.push(
            recursiveUpdateArr(
              parseInt(productNum) ?? 0,
              oldLargestIndex + 1,
              []
            )
          );
        } else {
          classifyDetailArr.push(filterDetail);
        }
      });
      const flatDetailArr = classifyDetailArr.flat();
      const noIndexDetails = flatDetailArr.filter(
        (detail) => typeof detail.boxNum !== 'number'
      );
      const sortDetails = flatDetailArr
        .filter((detail) => typeof detail.boxNum === 'number')
        .sort((a, b) => a.boxNum - b.boxNum)
        .map((item, index) => ({ ...item, boxNum: index + 1 }));
      noIndexDetails.forEach((detail) => {
        sortDetails.push(detail);
      });
      setDeliveryOrdersDetails(
        sortDetails.map((detail, index) => ({
          ...detail,
          id: `${detail.id}-${index}`,
        }))
      );
      Message['success'](
        `出貨單項次 ${modal?.source?.deliveryOrderLine} 更新成功`
      );
      closeModal();
    },
    [deliveryOrdersDetails, productNum, modal, methods.watch(), closeModal]
  );

  useEffect(() => {
    if (deliveryOrdersDataError) {
      const parseError = JSON.parse(deliveryOrdersDataError?.message).message;
      Message.add({
        key: 'error',
        children: parseError,
        duration: false,
        severity: 'error',
      });
      navigate('/sales-distribution/shipment');
    }
    if (isDeliveryOrdersData(deliveryOrdersData)) {
      Message.remove('error');
      const details = deliveryOrdersData.details.map((detail, index) => ({
        ...detail,
        id: `${detail.id}-${index}`,
      }));

      setDeliveryOrdersDetails(details);
      setDeliveryOrderCreatedat(deliveryOrdersData.createdAt);
    }
  }, [deliveryOrdersData, deliveryOrdersDataError]);

  useEffect(() => {
    methods.watch();
    const limitCount = methods.getValues('limitCount');
    if (!limitCount || `${limitCount}` === '0') {
      setProductNum('0');
      return;
    }
    const modalProductNum = modal?.source?.deliveryOrderLineQuantity;
    const parsedProductNum = isNaN(parseFloat(modalProductNum))
      ? 0
      : parseFloat(modalProductNum);
    setProductNum(`${Math.ceil(parsedProductNum / limitCount)}`);
  }, [methods.watch(), modal]);

  return {
    deliveryOrderId,
    endpoint,
    namespace,
    productNum,
    data: deliveryOrdersDetails,
    mutateDeliveryOrderData,
    boxTotalNum,
    deliveryOrderCreatedat,
    methods,
    modal,
    setModal,
    closeModal,
    createModal,
    disabledModalSubmitButton,
    disabledTableSubmitButton,
    handleUpdateDeliveryTable,
  };
}
