import { useEffect } from 'react';
import {
  // createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

type Props = {
  enableAutoRouting: boolean;
  tabIds: string[];
} | void;
export function useQueryStringWithAutoRouting(props: Props) {
  const { enableAutoRouting = false, tabIds } = props || {};
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const activeTab = searchParams.get('tab');

  useEffect(() => {
    if (
      enableAutoRouting &&
      tabIds?.length &&
      !tabIds.includes(activeTab ?? '')
    ) {
      // issue: setSearchParams replace 會造成無法返回上一個正確的路徑
      // solution: 改用 navigate
      // setSearchParams(createSearchParams({ tab: tabIds[0] }));
      navigate('?tab=' + tabIds[0], { replace: true });
    }
  }, [activeTab, enableAutoRouting, navigate, tabIds]);

  return { activeTab, searchParams, setSearchParams };
}
