import { useCallback } from 'react';

import { Button, MenuDivider, Typography } from '@mezzanine-ui/react';
import {
  LMBRecordParams,
  NextPagination,
  getNextParams,
  useLMBRecords,
} from '@solar/data';

import { PageLayout } from '../../Material/PageLayout/PageLayout';
import { PaginationTable } from '../../Material/PaginationTable/PaginationTable';

import { useColumns } from './hooks/useColumns';
import {
  FormFieldsDebug,
  FormFieldsWrapper,
} from '@mezzanine-ui/react-hook-form';
import { FilterScaffold } from '@solar/templates';
import { Filter, LMBRecordFormProps } from './filter/Filter';
import { useForm } from 'react-hook-form';

export function InventoryChange() {
  const { data, pageInfo, isLoading, setParams } = useLMBRecords({
    limit: 10,
    offset: 0,
  });
  const methods = useForm<Partial<LMBRecordFormProps>>();

  const columns = useColumns();

  const refetchLMBRecords = useCallback(
    (nextPagination: NextPagination) => {
      const { offset, limit } = getNextParams(nextPagination, pageInfo);

      const barcodesString = (methods.getValues('barcode') ?? '').split('_');
      const randomCode = barcodesString[barcodesString.length - 1];
      const loaderId = methods.getValues('loaderId');
      const materialId = methods.getValues('materialId');
      const sourceOrderId = methods.getValues('sourceOrderId');
      const sourceOrderType = methods.getValues('sourceOrderType')?.id;

      setParams({
        offset,
        limit,
        barcodes: randomCode ? [randomCode] : undefined,
        loaderIds: loaderId ? [loaderId] : undefined,
        materialIds: materialId ? [materialId] : undefined,
        sourceOrderIds: sourceOrderId ? [sourceOrderId] : undefined,
        sourceOrderTypes: sourceOrderType ? [sourceOrderType] : undefined,
      });
    },
    [setParams, pageInfo]
  );

  return (
    <PageLayout>
      <Typography variant="h1">庫存異動</Typography>
      <MenuDivider />
      <FormFieldsWrapper methods={methods}>
        <FilterScaffold
          mutateParams={refetchLMBRecords}
          fetchAsParamsMutated={false}
        >
          <Filter />
          <Button type="button" onClick={() => refetchLMBRecords({})}>
            確認
          </Button>
        </FilterScaffold>
      </FormFieldsWrapper>
      <PaginationTable
        scroll={{ x: 4000 }}
        loading={isLoading}
        columns={columns}
        dataSource={data ?? []}
        pageInfo={pageInfo}
        mutateParams={refetchLMBRecords}
      />
    </PageLayout>
  );
}
