import { InputField, InputFieldProps } from '@mezzanine-ui/react-hook-form';
import { ProductionFormFieldNumeric } from '@solar/data';
import Decimal from 'decimal.js';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  FieldValues,
  UseFormRegister,
  Validate,
  useFormContext,
} from 'react-hook-form';

interface NumericInputFieldProps extends InputFieldProps {
  fields: ProductionFormFieldNumeric;
}

export function NumericInputField({
  fields,
  registerName,
  ...props
}: NumericInputFieldProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const methods = useFormContext<FieldValues>();

  // const isNumber = useCallback((value?: number | string | null) => {
  //   return !Number.isNaN(Number(value || undefined));
  // }, []);

  // const validate = useMemo(() => {
  //   const results: Record<string, Validate<any>> = {};

  //   if (fields?.required) {
  //     results.required = (v) => !!v || '必填';
  //   }

  //   if (isNumber(fields?.min)) {
  //     results.min = (v) =>
  //       isNumber(v)
  //         ? new Decimal(v?.toString()).greaterThanOrEqualTo(
  //             fields.min as number
  //           ) || `必須大於等於最小值${fields.min}`
  //         : true;
  //   }

  //   if (isNumber(fields?.max)) {
  //     results.max = (v) =>
  //       isNumber(v)
  //         ? new Decimal(v?.toString()).lessThanOrEqualTo(
  //             (fields.max as number).toString()
  //           ) || `必須小於等於最大值${fields.max}`
  //         : true;
  //   }

  //   if (isNumber(fields?.specMin)) {
  //     results.specMin = (v) =>
  //       isNumber(v)
  //         ? new Decimal(v?.toString()).greaterThanOrEqualTo(
  //             (fields.specMin as number).toString()
  //           ) || `必須大於等於spec最小值${fields.specMin}`
  //         : true;
  //   }

  //   if (isNumber(fields?.specMax)) {
  //     results.specMax = (v) =>
  //       isNumber(v)
  //         ? new Decimal(v?.toString()).lessThanOrEqualTo(
  //             (fields.specMax as number).toString()
  //           ) || `必須小於等於spec最大值${fields.specMax}`
  //         : true;
  //   }

  //   if (isNumber(fields?.controlMin)) {
  //     results.controlMin = (v) =>
  //       isNumber(v)
  //         ? new Decimal(v?.toString()).greaterThanOrEqualTo(
  //             (fields.controlMin as number).toString()
  //           ) || `必須大於等於control最小值${fields.controlMin}`
  //         : true;
  //   }

  //   if (isNumber(fields?.controlMax)) {
  //     results.controlMax = (v) =>
  //       isNumber(v)
  //         ? new Decimal(v?.toString()).lessThanOrEqualTo(
  //             (fields.controlMax as number).toString()
  //           ) || `必須小於等於control最大值${fields.controlMax}`
  //         : true;
  //   }

  //   return results;
  // }, [fields, isNumber]);

  // const _register = useCallback<UseFormRegister<FieldValues>>(
  //   (name, options) => {
  //     // console.log('register: ', name, options);
  //     return methods.register(name, Object.assign(options ?? {}, { validate }));
  //   },
  //   [methods, validate]
  // );

  const onBlur = useCallback(() => {
    methods.trigger(registerName);
  }, [methods, registerName]);

  useEffect(() => {
    const handleWheel = (e: any) => {
      e?.currentTarget?.blur();
    };

    const inputElement = inputRef.current;

    inputElement?.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      inputElement?.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <InputField
      inputRef={inputRef}
      type="number"
      // register={_register}
      registerName={registerName}
      placeholder={fields?.placeholder ?? ''}
      onBlur={onBlur}
      {...props}
    />
  );
}
