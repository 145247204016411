import { Button, Message } from '@mezzanine-ui/react';
import {
  FormFieldsWrapper,
  TextAreaField,
} from '@mezzanine-ui/react-hook-form';
import { checkJWTRoles, useOutsourcePurchaseOrder } from '@solar/data';
import { ModalGroup, useModalGroupController } from '@solar/templates';
import { Descriptions, Table } from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMaterialLabelColumn } from './hooks';
import { DispatchSettingModal } from './modal/DispatchSettingModal';
import {
  DispatchMaterialViewModalProps,
  DispatchPreviewTableProps,
  Item,
  Modals,
} from './types';
import { updateRemark } from './utils';

export const fieldNamingMapping = {
  alreadyUsedQuantity: '需求管理數量', //'已耗用數量',
  createdAt: '建立日期',
  expectedCompletedAt: '訂單交期',
  expectedMaterialUnitQuantity: '預期產出數量',
  actualMainMaterialUnitQuantity: '已入庫數量',
  factory: '工廠',
  orderId: '採購單號',
  orderType: '工單類形',
  requirementQuantity: '需求基礎劑量單位數量', //'需求數量',
  saleOrderId: '銷售訂單單號',
  saleOrderItem: '銷售訂單項目',
  status: '狀態',
  targetMaterialId: '目標料號',
  targetMaterialDescription: '目標品名',
  byProductMaterialId: '副產品料號',
  byProductMaterialDescription: '副產品品名',
  workCenterId: '工作中心代碼',
  workCenterName: '工作中心名稱',
  inputMaterialId: '投入料料號',
  inputMaterialDescription: '投入料品名',
  targetBatchId: '目標批號',
  unit: '單位',
  supplier: '供應商名稱',
  supplierId: '供應商代碼',
  supplierAddress: '供應商地址',
};

type Form = {
  remark: string;
};

const REMARK = 'remark';
const ANTD_TH_BACKGROUND = 'rgba(0, 0, 0, 0.02)';

export const BasicInfoPanel = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const { order, mutate } = useOutsourcePurchaseOrder({ orderId });
  const materialLabelColumn = useMaterialLabelColumn();

  const methods = useForm<Form>();

  const bomRef = useRef<HTMLDivElement>(null);
  const routingRef = useRef<HTMLDivElement>(null);

  /** SOL-1256 自動儲存改成按儲存才送出 */
  // 處理備註欄位 debounce request
  // const debounceInputHandler = useRef(
  //   debounce((e: ChangeEvent<HTMLTextAreaElement>) => {
  //     if (orderId) {
  //       updateRemark({ remark: e.target.value, orderId });
  //     }
  //   }, 600)
  // ).current;

  const handleUpdateRemark = useCallback(
    ({ remark }: { remark: string }) => {
      if (orderId) {
        updateRemark({ remark, orderId })
          .then((res) => {
            if (res.ok) {
              Message.success('備註更新成功');
            }
          })
          .catch(() => {
            Message.success('備註更新失敗');
          })
          .finally(() => {
            mutate();
          });
      }
    },
    [orderId]
  );

  const navigate = useNavigate();

  const modalGroupController = useModalGroupController([
    { name: Modals.DISPATCH_SETTING },
  ]);

  useEffect(() => {
    if (order) {
      if (bomRef?.current) {
        bomRef.current?.parentElement?.parentElement?.setAttribute(
          'colspan',
          '8'
        );
      }
      if (routingRef?.current) {
        routingRef.current?.parentElement?.parentElement?.setAttribute(
          'colspan',
          '8'
        );
      }
    }
  }, [order]);

  return (
    <>
      <div style={{ overflowY: 'scroll' }}>
        <Descriptions bordered column={4}>
          <Descriptions.Item label={fieldNamingMapping['orderId']}>
            {orderId}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNamingMapping['factory']} span={3}>
            {order?.items[0]?.components[0]?.factoryId}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNamingMapping['status']}>
            {order?.workOrderStatusName}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNamingMapping['createdAt']} span={3}>
            {order?.createdAt
              ? moment(order?.createdAt).format('YYYY/MM/DD')
              : ''}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNamingMapping['supplier']}>
            {order?.supplier}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNamingMapping['supplierId']} span={3}>
            {order?.supplierId}
          </Descriptions.Item>
          <Descriptions.Item
            label={fieldNamingMapping['supplierAddress']}
            span={4}
          >
            {order?.supplierAddress}
          </Descriptions.Item>

          {(order?.items ?? [])?.map((row: any) => {
            return (
              <>
                <Descriptions.Item
                  label={fieldNamingMapping['targetMaterialId']}
                  span={2}
                >
                  {row?.materialId}
                </Descriptions.Item>
                <Descriptions.Item
                  label={fieldNamingMapping['actualMainMaterialUnitQuantity']}
                >
                  {row?.receivedMaterialStockUnitQuantity}
                </Descriptions.Item>
                <Descriptions.Item label={fieldNamingMapping['unit']}>
                  {row?.materialStockUnit}
                </Descriptions.Item>
                <Descriptions.Item
                  label={fieldNamingMapping['targetMaterialDescription']}
                  span={2}
                >
                  {row?.materialDescription}
                </Descriptions.Item>
                <Descriptions.Item
                  label={fieldNamingMapping['expectedMaterialUnitQuantity']}
                >
                  {row?.expectedMaterialStockUnitQuantity}
                </Descriptions.Item>
                <Descriptions.Item label={fieldNamingMapping['unit']}>
                  {row?.materialStockUnit}
                </Descriptions.Item>
              </>
            );
          })}

          <Descriptions.Item
            labelStyle={{ display: 'none' }}
            span={4}
            contentStyle={{
              backgroundColor: ANTD_TH_BACKGROUND,
            }}
            children={
              <>
                <div>需求料</div>
              </>
            }
          />

          {(order?.items ?? []).map((item: any) => {
            return (
              <>
                {item.components.map((component: any) => {
                  return (
                    <>
                      <Descriptions.Item
                        label={fieldNamingMapping['inputMaterialId']}
                        span={2}
                      >
                        {component.inputMaterialId}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={fieldNamingMapping['requirementQuantity']}
                      >
                        {component.inputMaterialQuantity}
                      </Descriptions.Item>
                      <Descriptions.Item label={fieldNamingMapping['unit']}>
                        {component.inputMaterialStockUnit}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={fieldNamingMapping['inputMaterialDescription']}
                        span={2}
                      >
                        {component.inputMaterialDescription}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={fieldNamingMapping['alreadyUsedQuantity']}
                      >
                        {component.inputMaterialInputUnitQuantity}
                      </Descriptions.Item>
                      <Descriptions.Item label={fieldNamingMapping['unit']}>
                        {component.inputMaterialInputUnit}
                      </Descriptions.Item>
                    </>
                  );
                })}
              </>
            );
          })}
        </Descriptions>
      </div>
      <br />
      <div
        style={{ display: 'flex', justifyContent: 'flex-start', gap: '12px' }}
      >
        {checkJWTRoles([115, 133]) && (
          <Button
            type="button"
            variant="outlined"
            onClick={() =>
              modalGroupController.openModal<DispatchMaterialViewModalProps>(
                Modals.DISPATCH_SETTING,
                {
                  dispatchPreviewTable: order?.items?.map((item: Item[]) => ({
                    ...item,
                  })) as Item[],
                  materialId:
                    order?.items?.map((item: any) => item.materialId) ?? [],
                  orderId: null,
                }
              )
            }
          >
            建立發料單
          </Button>
        )}
        {/* <Button
          type="button"
          variant="outlined"
          onClick={() =>
            navigate(
              `/production-planning/material-allocations?workOrderId=${orderId}`
            )
          }
        >
          前往配料
        </Button> */}
        {/* <Button
          type="button"
          variant="outlined"
          onClick={() => {
            window.open(
              `/print-management/work-order?tab=work-order&workOrderId=${orderId}`,
              '_blank'
            );
          }}
        >
          列印工單
        </Button> */}
      </div>
      <ModalGroup {...modalGroupController}>
        <DispatchSettingModal />
      </ModalGroup>
    </>
  );
};
