import {
  Button,
  Message,
  SelectValue,
  Table,
  Typography,
} from '@mezzanine-ui/react';
import styles from './tracking-page.module.scss';
// import { SubmissionFilter, useSubmissions } from './use-submissions';
import { useForm } from 'react-hook-form';
// import { useTableColumns } from './use-table-columns';
import { InputField, FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import useSWR from 'swr';
import { API_NAMESPACE, checkJWTRoles, request } from '@solar/data';
import { ProductionTrackingModel } from '@solar/data';
import { TrackingTree } from './TrackingTree';
import { useMemo, useState } from 'react';
import moment from 'moment';
import { MaterialAndBatchField } from '@solar/templates';

interface FormFields {
  materialId: SelectValue;
  batchId: SelectValue;
}
export function ProductionTrackingPage() {
  const methods = useForm<FormFields>({
    defaultValues: {
      materialId: {
        id: 'TCR1RU4192GCFF190E10A6WB',
        name: 'TCR1RU4192GCFF190E10A6WB',
      },
      batchId: { id: '46GSM11003-00001', name: '46GSM11003-00001' },
    },
  });
  const [queryMaterialId, setQueryMaterialId] = useState({
    materialId: 'TCR1RU4192GCFF190E10A6WB',
    sapBatchId: '46GSM11003',
    subBatchId: '00001',
  });

  const downloadCsv = (filePath: string): void => {
    const link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('download', filePath);
    link.setAttribute('href', filePath);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const shouldShow =
    queryMaterialId.materialId &&
    queryMaterialId.sapBatchId &&
    queryMaterialId.subBatchId;
  const [downloading, setDownloading] = useState(false);
  const {
    data: result,
    error,
    isValidating,
  } = useSWR<ProductionTrackingModel[]>(
    shouldShow
      ? [
          `/pp/material-produce-history/${queryMaterialId.materialId}/${queryMaterialId.sapBatchId}/${queryMaterialId.subBatchId}`,
          { namespace: API_NAMESPACE.PP },
        ]
      : null
  );
  const historyLoading = !result && !error && isValidating;
  const [allData, setAllData] = useState([]);
  const processedTree: ProductionTrackingModel | undefined = useMemo(() => {
    if (!result?.length) return undefined;
    return {
      id: 'root',
      sapBatchId: queryMaterialId.sapBatchId,
      subBatchId: queryMaterialId.subBatchId,
      materialId: queryMaterialId.materialId,
      parentRecords: result || [],
    };
  }, [result]);

  const exportTracing = async (payload: {
    sap_batch_id: string;
    sub_batch_id: string;
    material_id: string;
  }) => {
    setDownloading(true);
    setAllData([]);
    const options = {
      method: 'POST',
      body: JSON.stringify(payload),
    };

    const response = await request(`/export_tracing_history`, {
      ...options,
      namespace: '/qc',
      responseParser: (res) => res,
    });
    if (response.ok) {
      setDownloading(false);

      const contentDisposition = response.headers.get('content-disposition');
      const fileName = contentDisposition
        ? contentDisposition.split('filename=')[1]
        : payload.sap_batch_id +
          '_' +
          payload.sub_batch_id +
          '_' +
          moment().format('YYYYMM');

      const blob = await response.blob();

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      setDownloading(false);
      Message.error('下載失敗');
      throw await response.json();
    }
  };
  const combinationData = [processedTree, ...allData];

  return (
    <div className={styles.container}>
      <Typography variant="h2">批號追蹤</Typography>
      <FormFieldsWrapper methods={methods} className={styles.controller}>
        <div
          style={{
            width: '600px',
            display: 'flex',
            flexDirection: 'column',
            gap: 'var(--mzn-spacing-2)',
          }}
        >
          <MaterialAndBatchField
            materialLabel="物料編號"
            materialRegisterName="materialId"
            materialBatchLabel="批號"
            materialBatchRegisterName="batchId"
            withDeleted
          />
        </div>

        {/* <InputField label="物料編號" registerName="materialId" />
        <InputField label="母批號" registerName="sapBatchId" />
        <InputField label="子批號" registerName="subBatchId" /> */}

        <Button
          disabled={historyLoading || downloading}
          loading={historyLoading || downloading}
          onClick={() => {
            setAllData([]);
            const values = methods.getValues();

            if (!values.materialId || !values.batchId) {
              Message.error('請輸入物料編號及批號');
              return;
            }

            const [sapBatchId, subBatchId] = values.batchId?.id?.split('-');

            setQueryMaterialId({
              materialId: values.materialId?.id,
              sapBatchId,
              subBatchId,
            });
          }}
        >
          查詢
        </Button>
        {checkJWTRoles([127]) && (
          <Button
            disabled={downloading || isValidating}
            loading={downloading || isValidating}
            onClick={() => {
              const values = methods.getValues();

              if (!values.materialId || !values.batchId) {
                Message.error('請輸入物料編號及批號');
                return;
              }

              const [sapBatchId, subBatchId] = values.batchId?.id?.split('-');

              exportTracing({
                material_id: values.materialId?.id,
                sap_batch_id: sapBatchId,
                sub_batch_id: subBatchId,
              });
            }}
          >
            下載
          </Button>
        )}
      </FormFieldsWrapper>
      <TrackingTree data={processedTree} />
    </div>
  );
}
