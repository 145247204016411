import { TableDataSourceWithID } from '@mezzanine-ui/core/table';
import { API_NAMESPACE } from '@solar/data';
import { error } from 'console';
import useSWR from 'swr';

export interface MaterialRequestOrderSpec extends TableDataSourceWithID {
  id: number;
  materialId: string;
  materialBatchId: string;
  materialSapBatchId: string;
  // expectedMainMaterialUnitQuantity: string;
  // actualMainMaterialUnitQuantity: string;
  expectedMaterialStockUnitQuantity: string;
  actualMaterialStockUnitQuantity: string;
  expectedMaterialInputUnitQuantity: string;
  actualMaterialInputUnitQuantity: string;
  materialInputUnit: string;
  materialRequestOrderId: number;
  mainMaterialUnit: string; // API 待補
  description: string; // API 待補
  loaderId: number | null;
  loader: {
    shelf: {
      id: string;
      name: string;
      stackId: string;
    };
  };
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  material?: {
    id: string;
    materialStockUnit: string;
    description: string;
    mainMaterialUnitId: number;
    secondaryMaterialUnitId: number;
    categoryId: number;
    groupId: null;
    sourceId: null;
    oldMaterialId: string;
  };
}

interface WorkOrder {
  id: string;
  staffId: string;
  businessUnitId: any;
  processName: any;
  expectedCompletedAt: string;
  actualCompletedAt: any;
  itemId: string;
  typeCode: string;
  operationProcessingCode: string;
  factoryId: string;
  remark: any;
  mrpControllerId: string;
  mrpControllerName: string;
  workOrderStatusCode: string;
  workOrderStatus: string;
  salesOrderNote: any;
  operationCodes: OperationCode[];
  createdAt: string;
  updatedAt: string;
  syncAt: string;
  deletedAt: any;
  productionWorkOrderSpecs: {
    id: number;
    itemId?: string;
    materialId: string;
    productionWorkOrderId: string;
    expectedMaterialStockUnitQuantity: string;
    actualMaterialStockUnitQuantity: string;
    actualMaterialWeightUnitQuantity: any;
    materialStockUnit: string;
    materialInputUnit: string;
    stockOverInputUnitRatio: string;
    mainMaterialUnit: string;
    secondaryMaterialUnit: string;
    mainToSecondaryUnitRatio: string;
    expectedMainMaterialUnitQuantity: string;
    expectedSecondaryMaterialUnitQuantity: string;
    actualMainMaterialUnitQuantity: string;
    actualSecondaryMaterialUnitQuantity: string;
    type: string;
    salesOrderId?: string;
    salesOrderLineId?: string;
    workCenterId?: string;
    reservedId: string;
    reservedItemNumber?: string;
    index: number;
    processingId?: string;
    processingName?: string;
    operationCode?: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: any;
  }[];
}

interface OperationCode {
  id: number;
  productionWorkOrderId: string;
  workCenterId: string;
  operationCode: string;
  processingId: string;
  processingName: string;
  createdAt: string;
  updatedAt: string;
}

export interface WorkOrderInto extends TableDataSourceWithID {
  id: number;
  workOrderId: string;
  workOrder: WorkOrder;
  creatorId: string;
  sourceType: 'REQUEST_ORDER';
  expectedCompletedAt: string;
  actualCompletedAt: string | null;
  barcode: string;
  recipeId: string | null;
  recipeVersion: string | null;
  materialRequestOrderSpecs: MaterialRequestOrderSpec[];
  materialRequestOrderBatches: {
    materialRequestOrderId: number;
    materialId: string;
    materialBatchId: string;
    materialSapBatchId: string;
    materialBarcode: string;
    used: boolean;
  }[];
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export function useWorkOrderInfo(workOrderId?: string | null) {
  const { data, error, isValidating } = useSWR<WorkOrderInto>(
    workOrderId
      ? [
          `/warehouses/material-request-orders/${workOrderId}`,
          { namespace: API_NAMESPACE.MATERIALS },
        ]
      : null
  );

  return {
    workOrder: data,
    isLoading: isValidating && !data && !error,
  };
}
