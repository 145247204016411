import { API_NAMESPACE, DetailedLoaderProp, PageInfo } from '@solar/data';
import { useState } from 'react';
import useSWR from 'swr';

interface GetLoadersParams {
  searchTerm?: string;
  shelfIds?: string[];
}

export function useGetLoaders(defaultParams?: GetLoadersParams) {
  const [params, setParams] = useState<GetLoadersParams | undefined>(
    defaultParams
  );

  const { data, error, isValidating } = useSWR<{
    records: DetailedLoaderProp[];
    pageInfo: PageInfo;
  }>(
    params
      ? [
          `/loaders`,
          {
            params,
            namespace: API_NAMESPACE.MATERIALS,
          },
        ]
      : null
  );

  return {
    loaders: data?.records ?? [],
    error,
    isLoading: !error && !data && isValidating,
    refetchLoaders: setParams,
  };
}
