import useSWR from 'swr';
import { API_NAMESPACE, request } from '../request';
import { SelectValue } from '@mezzanine-ui/react';
import { useMemo } from 'react';
import * as yup from 'yup';
import { UploadFile } from 'antd';

export interface IReportPriceType {
  name: string;
  value: string;
  reportModes: string[];
}

export interface SPMatchItem {
  id?: string;
  key: string;
  reportPriceId?: number;
  reportPriceItemId?: number;
  component: string;
  matchQty: number | string;
  unMatchQty?: number | string;
  materialId: string;
  orderId: string;
  orderItem: string;
  state?: number;
  createUser?: string;
  updateUser?: string;
  originalWt?: number | string;
  originalUnBindQty?: number | string;
  [key: string]: any;
}

interface SPMaterialComponentItem {
  id: number;
  componentCode: string;
  componentItemNumber: string;
  componentItemSeq: string;
  wtRate: number;
  atRate: number;
}

interface SPMaterialComponent {
  materialId: string;
  componentCode: string;
  items: SPMaterialComponentItem[];
}

interface SPMaterial {
  id: string;
  description: string;
  unitCode: string;
  unitName: string;
  standardWt: number;
  component: SPMaterialComponent;
}

type CurrencyRate = {
  currency: string;
  currencyName: string;
  inRate: number | null; // 匯率可以是數字或 null
  outRate: number | null; // 匯率可以是數字或 null
};

export type SPAttachment = {
  id: number;
  refId: number;
  originalName: string;
  serialName: string;
  url: string;
  type: null | string;
  createTime: null | Date;
  version: number;
};

export type SPAPIOrderInfo = {
  id?: number; // 流水id
  showId?: string; // showId
  customerId?: string; // customerId
  tradeMode?: 0 | 1; // 交易模式: 0 - 買入, 1 - 賣出
  reportMode?: 0 | 1 | 2; // 敲價模式: 0 - 即時報價, 1 - 委託下單, 2 - 限價掛單
  reportType?: number; // 敲價型態
  note?: string; // 敲價備註
  entrustStartDate?: string; // 委託日期-start
  entrustEndDate?: string; // 委託日期-end
  materialId?: string; // 產品料號
  materialName?: string; // 產品料號名稱
  reportDate?: string; // 敲價單日期
  dealDate?: string; // 成交日期
  reportState?: number; // 敲價單狀態
  reportUserId?: string; // 敲價人員 id
  reportUserName?: string; // 敲價人員名稱
  salesId?: string; // 業務人員 id
  salesName?: string; // 業務員名稱
  checkUserId?: string; // 核價人員 id
  checkUserName?: string; // 核價人員名稱
  backNote?: string; // 退回備註
  reportQty?: number; // 敲價數量
  items?: SPAPIOrderItemInfo[]; // 敲價單項目
  fileKeys?: string[]; // 新增附件
  deleteFileKeys?: string[]; // 刪除附件
  customerName?: string; // 客戶名稱
  createUserName?: string; // 建立者名稱
  updateUserName?: string; // 更新者名稱
  type?: string; // 報價單類型
  attachments?: SPAttachment[]; // 附件
  quickCheck?: boolean;
};

export type SPFormOrderInfo = {
  id: number | null;
  showId: string;
  entrustDateRange: [string, string]; // 字符串數組表示日期範圍
  entrustDate?: string;
  reportNote: string;
  reportDate: string;
  dealDate: string;
  backNote: string;
  reportQty: string;
  reportMode: '0' | '1' | '2'; // 根據具體情況進行定義
  tradeMode: '0' | '1'; // 0:買入, 1:賣出
  customer: SelectValue<string>; // customer
  reportUser: SelectValue<string>; // reportUser
  reportState: SelectValue<string>; // reportState
  sales: SelectValue<string>; // sales
  checkUser: SelectValue<string>; // checkUser
  reportType: SelectValue<string>; // reportType
  material: SelectValue<string>; // material
  files?: UploadFile[]; // 附件
  deleteFileKeys?: string[]; // 刪除附件
  matchList?: SPMatchItem[];
};

export type SPAPIOrderItemInfo = {
  key: string;
  id?: number; // 流水id
  refId?: number; // historyid
  reportPriceId?: number; // 敲價單id
  componentRate?: number; // 成份比例
  component?: string; // 成份
  exchange?: number; // 匯率
  premium?: number; // 加減碼
  standardCurrency?: string; // 盤價幣別
  standardUnit?: string; // 盤價單位
  reportCurrency?: string; // 敲價幣別
  reportUnit?: string; // 敲價單位
  platformPrice?: number; // 平台盤價
  reportUnitPrice?: number; // 敲價單價
  quoteUnitPrice?: number; // 報價單價
  tempQty?: number; // 暫敲數量
  checkQty?: number; // 確認數量
  standardTime?: string; // 盤價時間
  subTotal?: number; // 小計
  createUserName?: string;
  unMatchQty?: number; // 未配對數量
  interPremium?: number;
  interUnitPrice?: number;
  exchangePremium?: number;
};

export type SPFormOrderItemInfo = {
  key: string;
  id?: number;
  refId?: number; // historyid
  reportPriceId?: number;
  subTotal?: string;
  premium?: string;
  componentRate?: string;
  exchange?: string;
  platformPrice?: string;
  standardTime?: string;
  reportUnitPrice?: string;
  quoteUnitPrice?: string;
  tempQty?: string;
  checkQty?: string;
  component?: SelectValue<string>;
  standardCurrency?: SelectValue<string>;
  reportCurrency?: SelectValue<string>;
  standardUnit?: SelectValue<string>;
  reportUnit?: SelectValue<string>;
  unitRate?: string;
  unMatchQty?: number; // 未配對數量
  interPremium?: string;
  interUnitPrice?: string;
  exchangePremium?: string;
};

export interface StrikePriceContextInterface {
  autoCompleteOptionsCustomer: SelectValue<string>[];
  autoCompleteOptionsMaterial: SelectValue<string>[];
  autoCompleteOptionsSales: SelectValue<string>[];
  autoCompleteReportPriceState: SelectValue<string>[];
  autoCompleteOptionsComponentNames: SelectValue<string>[];
  reportPriceType: IReportPriceType[]; // Replace 'any' with the specific type if available
  reportPriceState: any; // Replace 'any' with the specific type if available
  deletePredictOrder: (id: string) => Promise<any>; // Replace 'any' with the specific type if available
  exchangeRate: CurrencyRate[];
}

export const contextDefaultData: StrikePriceContextInterface = {
  autoCompleteOptionsCustomer: [],
  autoCompleteOptionsMaterial: [],
  autoCompleteOptionsSales: [],
  autoCompleteReportPriceState: [],
  autoCompleteOptionsComponentNames: [],
  reportPriceType: [],
  reportPriceState: [],
  deletePredictOrder: async () => null,
  exchangeRate: [],
};

export const useStrikePrice = (): StrikePriceContextInterface => {
  // 客戶
  const { data: customerList } = useSWR([
    '/selectItem/listCustomer',
    { namespace: API_NAMESPACE.MISC },
  ]);

  // 料號
  const { data: materialList } = useSWR([
    '/selectItem/listMaterial',
    {
      namespace: API_NAMESPACE.MISC,
      params: { isExpensive: true, isMetal: true },
    },
  ]);

  // 業務人員
  const { data: salesList } = useSWR([
    '/selectItem/listMember',
    { namespace: API_NAMESPACE.MISC },
  ]);

  // selectItem/listReportPriceType 敲價形態
  const { data: reportPriceType = [] } = useSWR<IReportPriceType[]>([
    '/selectItem/listReportPriceType',
    { namespace: API_NAMESPACE.MISC },
  ]);

  // selectItem/listReportPriceState 敲價單狀態
  const { data: reportPriceState } = useSWR([
    '/selectItem/listReportPriceState',
    { namespace: API_NAMESPACE.MISC },
  ]);

  // 成分
  const { data: componentNames } = useSWR([
    '/selectItem/listExpensiveComponent',
    { namespace: API_NAMESPACE.MISC },
  ]);

  // /selectItem/listExchangeRate 匯率
  const { data: exchangeRate } = useSWR([
    '/selectItem/listExchangeRate',
    { namespace: API_NAMESPACE.MISC },
  ]);

  const autoCompleteOptionsCustomer: SelectValue<string>[] = useMemo(() => {
    let options: SelectValue<string>[] = [];
    if (Array.isArray(customerList)) {
      options = customerList.map((customerData: any) => ({
        id: customerData.value,
        name: customerData.name,
      }));
    }
    return options;
  }, [customerList]);

  const autoCompleteOptionsMaterial: SelectValue<string>[] = useMemo(() => {
    let options: SelectValue<string>[] = [];
    if (Array.isArray(materialList)) {
      options = materialList.map((materialData: any) => ({
        id: materialData.value,
        name: `${materialData.value}${materialData.name ? '-' : ''}${
          materialData.name || ''
        }`,
      }));
    }
    return options;
  }, [materialList]);

  const autoCompleteOptionsSales: SelectValue<string>[] = useMemo(() => {
    let options: SelectValue<string>[] = [];
    if (Array.isArray(salesList)) {
      options = salesList.map((salesData: any) => ({
        id: salesData.value,
        name: salesData.name,
      }));
    }
    return options;
  }, [salesList]);

  const autoCompleteReportPriceState: SelectValue<string>[] = useMemo(() => {
    let options: SelectValue<string>[] = [];
    if (Array.isArray(reportPriceState)) {
      options = reportPriceState.map((reportPriceStateData: any) => ({
        id: String(reportPriceStateData.code),
        name: reportPriceStateData.name,
      }));
    }
    return options;
  }, [reportPriceState]);

  const autoCompleteOptionsComponentNames = useMemo(() => {
    let options: SelectValue<string>[] = [];
    if (Array.isArray(componentNames)) {
      options = componentNames.map((componentName: string) => ({
        id: componentName,
        name: componentName,
      }));
    }
    return options;
  }, [componentNames]);

  const deletePredictOrder = async (id: string) => {
    try {
      const body = { id };
      const result = await request('/forecastOrder/delete', {
        body: JSON.stringify(body),
        method: 'POST',
        namespace: API_NAMESPACE.MISC,
      });
      return result;
    } catch (e) {
      console.error(e);
    }
  };

  return {
    autoCompleteOptionsCustomer,
    autoCompleteOptionsMaterial,
    autoCompleteOptionsSales,
    autoCompleteReportPriceState,
    autoCompleteOptionsComponentNames,
    reportPriceType,
    reportPriceState,
    deletePredictOrder,
    exchangeRate,
  };
};

// 將 SPAPIOrderItemInfo 轉換為 SPFormOrderItemInfo
export function convertToSPFormOrderItemInfo(
  apiItem: SPAPIOrderItemInfo
): SPFormOrderItemInfo {
  return {
    key: apiItem.key || Math.random().toString(),
    id: apiItem.id,
    refId: apiItem.refId,
    reportPriceId: apiItem.reportPriceId,
    subTotal: apiItem.subTotal ? apiItem.subTotal.toString() : '',
    premium: apiItem.premium ? apiItem.premium.toString() : '0',
    interPremium: apiItem.interPremium ? apiItem.interPremium.toString() : '0',
    interUnitPrice: apiItem.interUnitPrice
      ? apiItem.interUnitPrice.toString()
      : '0',
    exchangePremium: apiItem.exchangePremium
      ? apiItem.exchangePremium.toString()
      : '0',
    componentRate: apiItem.componentRate
      ? apiItem.componentRate.toString()
      : '',
    exchange: apiItem.exchange ? apiItem.exchange.toString() : '',
    platformPrice: apiItem.platformPrice
      ? apiItem.platformPrice.toString()
      : '',
    standardTime: apiItem.standardTime || '',
    reportUnitPrice: apiItem.reportUnitPrice
      ? apiItem.reportUnitPrice.toString()
      : '',
    quoteUnitPrice: apiItem.quoteUnitPrice
      ? apiItem.quoteUnitPrice.toString()
      : '',
    tempQty: apiItem.tempQty ? apiItem.tempQty.toString() : '0',
    checkQty: apiItem.checkQty ? apiItem.checkQty.toString() : '0',
    component: { id: apiItem.component || '', name: apiItem.component || '' },
    standardCurrency: {
      id: apiItem.standardCurrency || '',
      name: apiItem.standardCurrency || '',
    },
    reportCurrency: {
      id: apiItem.reportCurrency || '',
      name: apiItem.reportCurrency || '',
    },
    standardUnit: {
      id: apiItem.standardUnit || '',
      name: apiItem.standardUnit || '',
    },
    reportUnit: {
      id: apiItem.reportUnit || '',
      name: apiItem.reportUnit || '',
    },
    unMatchQty: apiItem.unMatchQty,
  };
}

// 將 SPFormOrderItemInfo 轉換為 SPAPIOrderItemInfo
export function convertToSPAPIOrderItemInfo(
  formItem: SPFormOrderItemInfo
): SPAPIOrderItemInfo {
  return {
    key: formItem.key,
    id: formItem.id,
    refId: formItem.refId,
    reportPriceId: formItem.reportPriceId,
    subTotal: formItem.subTotal ? parseFloat(formItem.subTotal) : undefined,
    premium: formItem.premium ? parseFloat(formItem.premium) : undefined,
    interPremium: formItem.interPremium ? parseFloat(formItem.interPremium) : 0,
    interUnitPrice: formItem.interUnitPrice
      ? parseFloat(formItem.interUnitPrice)
      : 0,
    exchangePremium: formItem.exchangePremium
      ? parseFloat(formItem.exchangePremium)
      : 0,
    componentRate: formItem.componentRate
      ? parseFloat(formItem.componentRate)
      : undefined,
    exchange: formItem.exchange ? parseFloat(formItem.exchange) : undefined,
    platformPrice: formItem.platformPrice
      ? parseFloat(formItem.platformPrice)
      : undefined,
    standardTime: formItem.standardTime,
    reportUnitPrice: formItem.reportUnitPrice
      ? parseFloat(formItem.reportUnitPrice)
      : undefined,
    quoteUnitPrice: formItem.quoteUnitPrice
      ? parseFloat(formItem.quoteUnitPrice)
      : undefined,
    tempQty: formItem.tempQty ? parseFloat(formItem.tempQty) : undefined,
    checkQty: formItem.checkQty ? parseFloat(formItem.checkQty) : undefined,
    component: formItem.component?.id,
    standardCurrency: formItem.standardCurrency?.id,
    reportCurrency: formItem.reportCurrency?.id,
    standardUnit: formItem.standardUnit?.id,
    reportUnit: formItem.reportUnit?.id,
    unMatchQty: formItem.unMatchQty,
  };
}

export function convertToSPAPIOrderInfo(
  formOrderInfo: SPFormOrderInfo
): SPAPIOrderInfo {
  const reportModeMapping: Record<string, 0 | 1 | 2> = {
    '0': 0,
    '1': 1,
    '2': 2,
  };
  const tradeModeMapping: Record<string, 0 | 1> = {
    '0': 0,
    '1': 1,
  };
  const { files } = formOrderInfo;
  const fileKeys = files
    ?.filter((file) => !file.url)
    .map((file) => file.response.msg);

  const reportMode = formOrderInfo.reportMode
    ? reportModeMapping[formOrderInfo.reportMode]
    : undefined;

  // 修改 reportMode 的時候要同時修改 委託區間
  // 即時報價0: 系統自動顯示今日日期，無法修改
  // 委託下單1: 選擇一個日期，必須為系統日期今日(含)之後
  // 限價掛單2:選擇兩個日期，起始與到期日，起始日必須為系統日期今日(含)之後
  let entrustStartDate = Array.isArray(formOrderInfo.entrustDateRange)
    ? formOrderInfo.entrustDateRange[0]
    : undefined;
  let entrustEndDate = Array.isArray(formOrderInfo.entrustDateRange)
    ? formOrderInfo.entrustDateRange[1]
    : undefined;
  if (reportMode === 0 || reportMode === 1) {
    entrustStartDate = formOrderInfo.entrustDate;
    entrustEndDate = undefined;
  }

  return {
    id: formOrderInfo.id !== null ? formOrderInfo.id : undefined,
    showId: formOrderInfo.showId,
    entrustStartDate,
    entrustEndDate,
    note: formOrderInfo.reportNote,
    reportDate: formOrderInfo.reportDate,
    dealDate: formOrderInfo.dealDate,
    backNote: formOrderInfo.backNote,
    reportQty: formOrderInfo.reportQty ? parseInt(formOrderInfo.reportQty) : 0,
    reportMode,
    tradeMode: formOrderInfo.tradeMode
      ? tradeModeMapping[formOrderInfo.tradeMode]
      : undefined,
    reportUserId: formOrderInfo.reportUser?.id,
    reportUserName: formOrderInfo.reportUser?.name,
    reportState: formOrderInfo.reportState
      ? parseInt(formOrderInfo.reportState.id)
      : undefined,
    salesId: formOrderInfo.sales?.id,
    salesName: formOrderInfo.sales?.name,
    checkUserId: formOrderInfo.checkUser?.id,
    checkUserName: formOrderInfo.checkUser?.name,
    reportType: formOrderInfo.reportType
      ? parseInt(formOrderInfo.reportType.id)
      : undefined,
    materialId: formOrderInfo.material?.id,
    materialName: formOrderInfo.material?.name,
    customerId: formOrderInfo.customer?.id,
    customerName: formOrderInfo.customer?.name,
    fileKeys,
    deleteFileKeys: formOrderInfo.deleteFileKeys,
  };
}

export function convertSPAPIToForm(
  apiOrder: SPAPIOrderInfo,
  rp: IReportPriceType[],
  autoCompleteReportPriceState: SelectValue<string>[]
): SPFormOrderInfo {
  const reportState = autoCompleteReportPriceState.find(
    (item) => String(item.id) === String(apiOrder.reportState)
  );
  const reportTypeData = rp.find(
    (item) => String(item.value) === String(apiOrder.reportType)
  );
  let reportType: SelectValue<string> = { id: '', name: '' };
  if (reportTypeData) {
    reportType = { id: reportTypeData.value, name: reportTypeData.name };
  }
  return {
    id: apiOrder.id || null,
    showId: apiOrder.showId || '',
    entrustDateRange: [
      apiOrder.entrustStartDate || '',
      apiOrder.entrustEndDate || '',
    ],
    entrustDate: apiOrder.entrustStartDate || '',
    reportNote: apiOrder.note || '',
    reportDate: apiOrder.reportDate || '',
    dealDate: apiOrder.dealDate || '',
    backNote: apiOrder.backNote || '',
    reportQty: apiOrder.reportQty?.toString() || '',
    reportMode: (apiOrder.reportMode?.toString() as '0' | '1' | '2') || '0',
    tradeMode: (apiOrder.tradeMode?.toString() as '0' | '1') || '0',
    customer: {
      id: apiOrder.customerId || '',
      name: apiOrder.customerName
        ? `${apiOrder.customerId}-${apiOrder.customerName}`
        : apiOrder.customerId || '', // Show customerId only if customerName doesn't exist
    },
    reportUser: {
      id: apiOrder.reportUserId || '',
      name: apiOrder.reportUserName
        ? `${apiOrder.reportUserId}-${apiOrder.reportUserName}`
        : apiOrder.reportUserId || '', // Show reportUserId only if reportUserName doesn't exist
    },
    reportState: reportState || { id: '', name: '' },
    sales: {
      id: apiOrder.salesId || '',
      name: apiOrder.salesName
        ? `${apiOrder.salesId}-${apiOrder.salesName}`
        : apiOrder.salesId || '', // Show salesId only if salesName doesn't exist
    },
    checkUser: {
      id: apiOrder.checkUserId || '',
      name: apiOrder.checkUserName
        ? `${apiOrder.checkUserId}-${apiOrder.checkUserName}`
        : apiOrder.checkUserId || '', // Show checkUserId only if checkUserName doesn't exist
    },
    reportType: reportType || { id: '', name: '' },
    material: {
      id: apiOrder.materialId || '',
      name: apiOrder.materialName
        ? `${apiOrder.materialId}-${apiOrder.materialName}`
        : apiOrder.materialId || '', // Show materialId only if materialName doesn't exist
    },
    files: apiOrder.attachments?.map((attachment) => ({
      uid: attachment.id.toString(),
      name: attachment.originalName,
      status: 'done',
      url: attachment.url,
      response: {
        msg: attachment.serialName,
      },
    })),
  };
}

export const convertSPMaterialToOrderItem = (
  mci: SPMaterialComponentItem,
  unit: string,
  standardWt?: number
): SPFormOrderItemInfo => {
  const item: SPFormOrderItemInfo = {
    key: Math.random().toString(),
    componentRate: mci.wtRate.toString(),
    component: { id: mci.componentItemNumber, name: mci.componentItemNumber },
    reportUnit: { id: unit, name: unit },
  };
  if (standardWt) {
    item.tempQty = ((standardWt * mci.wtRate) / 100).toFixed(3);
  }
  return item;
};

export const insertSPOrder = async (orderData: any) => {
  return request('/reportPrice/insert', {
    body: JSON.stringify(orderData),
    method: 'POST',
    namespace: API_NAMESPACE.MISC,
  });
};

export const updateSPOrder = async (orderData: any) => {
  return request('/reportPrice/update', {
    body: JSON.stringify(orderData),
    method: 'POST',
    namespace: API_NAMESPACE.MISC,
  });
};

export const matchSPOrder = async (body: any) => {
  return request('/reportPrice/match', {
    body: JSON.stringify(body),
    method: 'POST',
    namespace: API_NAMESPACE.MISC,
  });
};

export const getSPMaterialDetail = async (id: string) => {
  return request(`/material/get`, {
    method: 'GET',
    params: { id, isMetal: true },
    namespace: API_NAMESPACE.MISC,
  }) as Promise<SPMaterial>;
};

export const getSPOrderDetail = async (id: string) => {
  return request(`/reportPrice/get`, {
    method: 'GET',
    params: { id },
    namespace: API_NAMESPACE.MISC,
  });
};

export const getSPItemHistory = async (refId: string) => {
  return request(`/reportPrice/itemHistory/get`, {
    method: 'GET',
    params: { refId },
    namespace: API_NAMESPACE.MISC,
  }) as Promise<SPAPIOrderItemInfo[]>;
};

export const optionsStandardCurrancy: SelectValue[] = [
  { name: 'NTD', id: 'NTD' },
  { name: 'USD', id: 'USD' },
  { name: 'CNY', id: 'CNY' },
];

export const optionsReportCurrancy: SelectValue[] = [
  { name: 'NTD', id: 'NTD' },
  { name: 'USD', id: 'USD' },
  { name: 'CNY', id: 'CNY' },
  { name: 'JPY', id: 'JPY' },
];

// MT、T.O、G、KG、LBS、TOZ、TT
export const optionsSPItemUnit: SelectValue[] = [
  { name: 'MT', id: 'MT' },
  { name: 'T.O', id: 'T.O' },
  { name: 'G', id: 'G' },
  { name: 'KG', id: 'KG' },
  { name: 'LBS', id: 'LBS' },
  { name: 'TOZ', id: 'TOZ' },
  { name: 'TT', id: 'TT' },
];

export const SPFormOrderInfoAddSchema = yup.object().shape({
  // showId: yup.string().required('敲價單號為必填項'),
  customer: yup
    .object()
    .shape({
      name: yup.string().required(),
      id: yup.string().required(),
    })
    .test('customerRequired', '客戶名稱為必填項', function (value) {
      // console.log('value', value);
      const { name, id } = value || {};

      if (!name && !id) {
        return this.createError({
          message: '客戶名稱為必填項',
          path: 'customer',
        });
      }

      return true;
    }),
  tradeMode: yup.string().required('交易模式為必填項'),
  reportMode: yup.string().required('敲價模式為必填項'),
  reportType: yup
    .object()
    .shape({
      name: yup.string().required('必填項'),
      id: yup.string().required('必填項'),
    })
    .test('reportTypeRequired', '敲價形態必填', function (value) {
      // console.log('value', value);
      const { name, id } = value || {};

      if (!name && !id) {
        return this.createError({
          message: '敲價形態必填',
          path: 'reportType',
        });
      }

      return true;
    }),
  // entrustDateRange: yup.array().of(yup.string()).required('委託區間為必填項'),
  reportNote: yup.string(),
  material: yup
    .object()
    .shape({
      name: yup.string(),
      id: yup.string(),
    })
    .nullable(),
  reportDate: yup.string().required('敲單日期為必填項'),
  dealDate: yup.string(),
  reportState: yup
    .object()
    .shape({
      name: yup.string(),
      id: yup.string(),
    })
    .nullable(),
  reportUser: yup
    .object()
    .shape({
      name: yup.string().required('敲價人員為必填項'),
      id: yup.string().required('敲價人員為必填項'),
    })
    .nullable()
    .test('reportUserRequired', '敲價人員為必填項', function (value) {
      // console.log('value', value);
      const { name, id } = value || {};

      if (!name && !id) {
        return this.createError({
          message: '敲價人員為必填項',
          path: 'reportUser',
        });
      }

      return true;
    }),
  sales: yup
    .object()
    .shape({
      name: yup.string().required('業務人員為必填項'),
      id: yup.string().required('業務人員為必填項'),
    })
    .nullable()
    .test('salesRequired', '業務人員為必填項', function (value) {
      const { name, id } = value || {};

      if (!name && !id) {
        return this.createError({
          message: '業務人員為必填項',
          path: 'sales',
        });
      }

      return true;
    }),
  checkUser: yup
    .object()
    .shape({
      name: yup.string(),
      id: yup.string(),
    })
    .nullable(),
  backNote: yup.string(),
  reportQty: yup
    .string() // 先验证为字符串类型
    .nullable(),
});

export const SPFormOrderItemInfoApproveSchema = yup
  .object()
  .shape({
    dealDate: yup.string(),
    reportState: yup
      .object()
      .shape({
        name: yup.string(),
        id: yup.string(),
      })
      .nullable(),
    checkUser: yup
      .object()
      .shape({
        name: yup.string(),
        id: yup.string(),
      })
      .nullable()
      .test('checkUserRequired', '核價人員為必填項', function (value) {
        // console.log('value', value);
        const { name, id } = value || {};
        if (!name || !id) {
          return this.createError({
            message: '核價人員為必填項',
            path: 'checkUser',
          });
        }
        return true;
      }),
    backNote: yup.string(),
  })
  .test('backnote rule', '退回備註必填規則', function (value) {
    const { backNote, reportState, checkUser } = value || {};
    if (reportState?.id === '2') {
      if (!backNote) {
        return this.createError({
          message: '退回備註必填',
          path: 'backNote',
        });
      }
    }
    return true;
  });

export const SPItemValidateSchema = yup
  .object()
  .shape({
    component: yup
      .object()
      .shape({
        name: yup.string().required(),
        id: yup.string().required(),
      })
      .test('componentRequired', '成分為必填項', function (value) {
        const { name, id } = value || {};

        if (!name && !id) {
          return this.createError({
            message: '成分為必填項',
            path: 'component',
          });
        }

        return true;
      }),
    reportUnit: yup
      .object()
      .shape({
        name: yup.string().required(),
        id: yup.string().required(),
      })
      .test('reportUnitRequired', '敲價單位為必填項', function (value) {
        const { name, id } = value || {};

        if (!name && !id) {
          return this.createError({
            message: '敲價單位為必填項',
            path: 'reportUnit',
          });
        }

        return true;
      }),
    reportUnitPrice: yup.string().required('敲價單價必填'),
    quoteUnitPrice: yup.string().required('報價單價必填'),
    reportCurrency: yup
      .object()
      .shape({
        name: yup.string().required(),
        id: yup.string().required(),
      })
      .test('reportCurrencyRequired', '敲價幣別為必填項', function (value) {
        const { name, id } = value || {};

        if (!name && !id) {
          return this.createError({
            message: '敲價幣別為必填項',
            path: 'reportCurrency',
          });
        }

        return true;
      }),
    tempQty: yup.string(),
    checkQty: yup.string(),
  })
  .test('oneOfRequired', 'tempQty 和 checkQty 至少填寫一個', function (value) {
    const { tempQty, checkQty } = value || {};

    if (!tempQty && !checkQty) {
      return this.createError({
        path: 'tempQty',
        message: '暫敲數量 和 確認數量 至少填寫一個',
      });
    }

    return true;
  });

export const SPItemValidateAddSchema = yup
  .object()
  .shape({
    component: yup
      .object()
      .shape({
        name: yup.string().required(),
        id: yup.string().required(),
      })
      .test('componentRequired', '成分為必填項', function (value) {
        const { name, id } = value || {};

        if (!name && !id) {
          return this.createError({
            message: '成分為必填項',
            path: 'component',
          });
        }

        return true;
      }),
    reportUnit: yup
      .object()
      .shape({
        name: yup.string().required(),
        id: yup.string().required(),
      })
      .test('reportUnitRequired', '敲價單位為必填項', function (value) {
        const { name, id } = value || {};

        if (!name && !id) {
          return this.createError({
            message: '敲價單位為必填項',
            path: 'reportUnit',
          });
        }

        return true;
      }),
    reportCurrency: yup
      .object()
      .shape({
        name: yup.string().required(),
        id: yup.string().required(),
      })
      .test('reportCurrencyRequired', '敲價幣別為必填項', function (value) {
        const { name, id } = value || {};

        if (!name && !id) {
          return this.createError({
            message: '敲價幣別為必填項',
            path: 'reportCurrency',
          });
        }

        return true;
      }),
    tempQty: yup.string(),
    checkQty: yup.string(),
  })
  .test('oneOfRequired', 'tempQty 和 checkQty 至少填寫一個', function (value) {
    const { tempQty, checkQty } = value || {};

    if (!tempQty && !checkQty) {
      return this.createError({
        path: 'tempQty',
        message: '暫敲數量 和 確認數量 至少填寫一個',
      });
    }

    return true;
  });
