import { useCallback, useMemo } from 'react';

import { Modal, ModalActions, ModalBody, Table } from '@mezzanine-ui/react';

import { TableColumn } from '@mezzanine-ui/core/table';
import { useTargetModal } from '@solar/templates';
import { Descriptions } from 'antd';
import classes from './setup-packaging-modal.module.scss';
import {
  Modals,
  PackageDetailBarcodeTable,
  ViewPackagedModalProps,
} from './types';

export function ViewPackagedModal() {
  const modalController = useTargetModal<ViewPackagedModalProps>(
    Modals.VIEW_PACKAGED
  );
  const { open, closeModal, data } = modalController;

  const handleModalClose = useCallback(() => {
    closeModal();
  }, []);

  const columns: TableColumn<PackageDetailBarcodeTable>[] = useMemo(
    () => [
      { title: '料號', dataIndex: 'materialId' },
      { title: '舊料號', dataIndex: 'oldMaterialId' },
      { title: '品名', dataIndex: 'materialDescription' },
      { title: '批號', dataIndex: 'batchId' },
      { title: '數量', dataIndex: 'mainMaterialUnitQuantity' },
    ],
    []
  );

  return (
    <Modal open={open} onClose={handleModalClose}>
      <br />
      <br />
      <ModalBody>
        <div>
          <Descriptions>
            <Descriptions.Item label="嘜頭編號">
              {data?.marks.id}
            </Descriptions.Item>
            <Descriptions.Item label="包裝淨重">
              {data?.grossWeight}
            </Descriptions.Item>
            <Descriptions.Item label="包裝毛重">
              {data?.grossWeight}
            </Descriptions.Item>
            <Descriptions.Item label="包材名稱">
              {data?.packageOption?.name.split(' ')[0]}
            </Descriptions.Item>
            <Descriptions.Item label="包材尺寸">
              {data?.packageOption?.name.split(' ')[1]}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <Table
            bodyClassName={classes['table-body']}
            columns={columns}
            dataSource={((data as any)?.table as any) ?? []}
          />
        </div>
      </ModalBody>
      <ModalActions
        cancelText="關閉"
        confirmButtonProps={{ style: { display: 'none' } }}
        onCancel={() => {
          handleModalClose();
        }}
      />
    </Modal>
  );
}
