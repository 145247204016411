import type { CustomerTradeConditionExportFormValues } from '@solar/data';
import React from 'react';
import {
  useCustomerTradeConditionFilterGet,
  CustomerTradeConditionExportFormFieldName as FormFieldName,
  type MaterialCategoryFilterData,
} from '@solar/data';
import { useFormContext } from 'react-hook-form';
import {
  AutoCompleteField,
  type AutoCompleteFieldProps,
} from '@mezzanine-ui/react-hook-form';

const _PrivateFieldsName = [
  '_material_category_first_level',
  '_material_category_second_level',
  '_material_category_thrid_level',
] as const;

type Props = {
  styles?: {
    label?: React.ComponentProps<'div'>['style'];
  };
};

export function MaterialCategoryAutoComplete({ styles }: Props) {
  const formMethods = useFormContext<CustomerTradeConditionExportFormValues>();

  const { materialCategories: firstLevelCategoryOptions } =
    useCustomerTradeConditionFilterGet();

  const [secondLevelCategoryOptions, setSecondLevelCategoryOptions] =
    React.useState<MaterialCategoryFilterData[]>([]);

  const [thirdLevelCategoryOptions, setThirdLevelCategoryOptions] =
    React.useState<MaterialCategoryFilterData[]>([]);

  // generate second level category options
  const generateSecondLevelCategoryOptions: AutoCompleteFieldProps['onChange'] =
    (selected) => {
      if (selected) {
        const options = firstLevelCategoryOptions?.find(
          (cat) => cat.name === selected?.name
        )?.children;
        setSecondLevelCategoryOptions(options ?? []);
      } else {
        setSecondLevelCategoryOptions([]);
      }
    };

  // generate third level category options
  const generateThridLevelCategoryOptions: AutoCompleteFieldProps['onChange'] =
    (selected) => {
      if (selected) {
        const options = secondLevelCategoryOptions?.find(
          (cat) => cat.name === selected?.name
        )?.children;
        setThirdLevelCategoryOptions(options ?? []);
      } else {
        setThirdLevelCategoryOptions([]);
      }
    };

  // clear second level autocomplete
  const clearSecondLevelSelected = () => {
    formMethods.setValue(_PrivateFieldsName[1] as any, null);
  };

  // clear third level autocomplete
  const clearThirdLevelSelected = () => {
    formMethods.setValue(_PrivateFieldsName[2] as any, null);
    formMethods.setValue(FormFieldName.MATERIAL_CATEGORY_ID, undefined);
  };

  return (
    <>
      <div style={{ ...styles?.label, height: '32px', lineHeight: '32px' }}>
        料號分類：
      </div>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <AutoCompleteField
          registerName={_PrivateFieldsName[0]}
          placeholder="產品銷售大類"
          options={firstLevelCategoryOptions.map((cat) => ({
            id: cat.name,
            name: cat.name,
          }))}
          onChange={(selected) => {
            clearSecondLevelSelected();
            clearThirdLevelSelected();
            generateSecondLevelCategoryOptions(selected);
            formMethods.setValue(_PrivateFieldsName[0] as any, selected);
          }}
        />
        <AutoCompleteField
          registerName={_PrivateFieldsName[1]}
          placeholder="產品銷售中類"
          options={secondLevelCategoryOptions.map((cat) => ({
            id: cat.name,
            name: cat.name,
          }))}
          onChange={(selected) => {
            clearThirdLevelSelected();
            generateThridLevelCategoryOptions(selected);
            formMethods.setValue(_PrivateFieldsName[1] as any, selected);
          }}
        />
        <AutoCompleteField
          registerName={_PrivateFieldsName[2]}
          placeholder="產品銷售小類"
          options={thirdLevelCategoryOptions.map((cat) => ({
            // here is 'id' not 'name'
            id: cat?.id ?? '',
            name: cat.name,
          }))}
          onChange={(selected) => {
            formMethods.setValue(
              FormFieldName.MATERIAL_CATEGORY_ID,
              selected?.id
            );
          }}
        />
      </div>
    </>
  );
}
