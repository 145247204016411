import { TEMDrawing, deleteDrawing, getPresignedFileLink, usePresignedFileLink, useTEMDrawingByNewDrawingCode } from "@solar/data";
import { Button, Descriptions, Modal, Typography, message } from "antd";
import { ReactNode, useEffect, useRef, useState } from "react";
import { ExpandDescriptionsItem } from "./ExpandDescriptionsItem";
import { PDFPreviewer } from "@solar/templates";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ECharts, init } from "echarts";

enum DrawingChartCategory {
  parent,
  current,
  children,
}

export function TEMDrawingViewForm() {
  const [graphChart, setGraphChart] = useState<ECharts>();
  const graphChartRef = useRef<HTMLDivElement>(null);
  const params = useParams();
  const navigate = useNavigate();

  const { drawing } = useTEMDrawingByNewDrawingCode({ newDrawingCode: params?.newDrawingCode });
  // const [fileLink, setFileLink] = useState<string>();
  const { fileLink } = usePresignedFileLink({
    s3ObjectKey: drawing?.s3ObjectKey,
  });

  // useEffect(() => {
  //   if (drawing) {
  //     getPresignedFileLink({ ids: [drawing?.id], bu: 'TEM' })
  //       .then((response) => console.log(response));
  //   }
  // }, [drawing?.id]);

  useEffect(() => {
    if (graphChartRef?.current) {
      setGraphChart(init(graphChartRef.current, 'dark', {
        width: 640,
        height: 480,
        renderer: 'svg',
        devicePixelRatio: 2,
      }));
    }
  }, [graphChartRef]);

  useEffect(() => {
    graphChart?.showLoading();
    if (graphChart && drawing) {
      graphChart.hideLoading();
      const parentTotalCount = drawing?.parentDrawings?.length ?? 0;
      const childrenTotalCount = drawing?.childDrawings?.length ?? 0;
  
      const getNodeX = (category: DrawingChartCategory.parent | DrawingChartCategory.current | DrawingChartCategory.children) => {
        const spacing = 4;
        switch (category) {
          case DrawingChartCategory.parent: return -spacing;
          case DrawingChartCategory.children: return spacing;
          default: return 0;
        }
      }

      const getNodeY = (category: DrawingChartCategory.parent | DrawingChartCategory.current | DrawingChartCategory.children, n: number) => {
        const spacing = 3;
        switch (category) {
          case DrawingChartCategory.parent:
            return ((parentTotalCount - 1) / 2 - n) * spacing;
          case DrawingChartCategory.children:
            return ((childrenTotalCount - 1) / 2 - n) * spacing;
          default: return 0;
        }
      }

      const transformDrawingToNode = (category: DrawingChartCategory.parent | DrawingChartCategory.current | DrawingChartCategory.children) => (d: TEMDrawing, index?: number) => ({
        id: d?.newDrawingCode,
        name: d?.newDrawingCode,
        category,
        fixed: false,
        value: d?.oldDrawingFixed,
        x: getNodeX(category),
        y: getNodeY(category, index ?? 0),
        label: { show: true },
      });

      const transformDrawingToLink = (category: DrawingChartCategory.parent | DrawingChartCategory.children, drawing: TEMDrawing) => (d: TEMDrawing) => {
        switch (category) {
          case DrawingChartCategory.parent: {
            const source = d?.newDrawingCode;
            const target = drawing?.newDrawingCode;
            return (source === target) ? undefined : { source, target };
          }
          case DrawingChartCategory.children: {
            const source = drawing?.newDrawingCode;
            const target = d?.newDrawingCode;
            return (source === target) ? undefined : { source, target };
          }
          default:
            return undefined;
        }
      }

      const nodes = [
        ...[
          drawing.parentDrawings.map(transformDrawingToNode(DrawingChartCategory.parent)),
          drawing.childDrawings.map(transformDrawingToNode(DrawingChartCategory.children)),
        ].flat().filter((node) => node?.id !== drawing?.newDrawingCode),
        transformDrawingToNode(DrawingChartCategory.current)(drawing),
      ];

      const links = [
        ...drawing.parentDrawings.map(transformDrawingToLink(DrawingChartCategory.parent, drawing)).filter(Boolean),
        ...drawing.childDrawings.map(transformDrawingToLink(DrawingChartCategory.children, drawing)).filter(Boolean),
      ];

      const categories = [
        { id: DrawingChartCategory.parent, name: '母圖號' },
        { id: DrawingChartCategory.current, name: '當前圖號' },
        { id: DrawingChartCategory.children, name: '子圖號' },
      ];
      graphChart.setOption({
        title: {
          text: '新舊圖號母子圖',
          top: 'bottom',
          left: 'right'
        },
        zoom: 0.1,
        tooltip: {},
        legend: [
          {
            // selectedMode: 'single',
            data: categories.map((c) => c?.name),
          }
        ],
        animationDuration: 1500,
        animationEasingUpdate: 'quinticInOut',
        series: [
          {
            name: '新舊圖號',
            type: 'graph',
            layout: 'none',
            data: nodes,
            links,
            categories,
            roam: true,
            symbolSize: 20,
            label: {
              position: 'right',
              formatter: '{b}'
            },
            lineStyle: {
              color: 'source',
              curveness: 0.3
            },
            emphasis: {
              focus: 'adjacency',
              lineStyle: {
                width: 5
              }
            }
          }
        ]
      });
    }
  }, [graphChart, drawing]);

  return (
    <Descriptions
      bordered
      column={3}
      size="small"
      title={<Typography.Title level={1}>{drawing?.newDrawingCode}</Typography.Title>}
      style={{ borderRadius: 0 }}
      labelStyle={{ width: 140 }}
      extra={(
        <div style={{ display: 'flex', gap: 'var(--mzn-spacing-4)'}}>
          <Link to="/drawings/tem">
            <Button>返回列表</Button>
          </Link>
          <Link to={`/drawings/tem/${drawing?.newDrawingCode}/update`}>
            <Button type="primary" disabled={!drawing}>編輯</Button>
          </Link>
          <Button
            danger
            type="primary"
            onClick={() => {
              Modal.confirm({
                title: '確認刪除',
                okText: '確認',
                cancelText: '取消',
                onOk: async () => {
                  if (drawing?.id) {
                    const response = await deleteDrawing({ id: drawing?.id, bu: 'TEM' });
                    if (response?.code === 200) {
                      message.success('刪除成功');
                      navigate('/drawings/tem');
                    } else {
                      message.error('刪除失敗');
                    }
                  }
                },
              })
            }}
          >
            刪除
          </Button>
        </div>
      )}>
      <Descriptions.Item label="檢查人">{drawing?.checker ?? ''}</Descriptions.Item>
      <Descriptions.Item label="完成別">{drawing?.completedStatusName ?? ''}</Descriptions.Item>
      <Descriptions.Item label="類型">{drawing?.typeName ?? ''}</Descriptions.Item>
      <Descriptions.Item label="修正後舊圖號">{drawing?.oldDrawingFixed ?? ''}</Descriptions.Item>
      <Descriptions.Item label="舊成品圖號">{drawing?.parentOldDrawingCode ?? ''}</Descriptions.Item>
      <Descriptions.Item label="背板來源">{drawing?.backplateSourceName ?? ''}</Descriptions.Item>
      <Descriptions.Item label="新圖號">{drawing?.newDrawingCode ?? ''}</Descriptions.Item>
      <Descriptions.Item label="流水三碼">{drawing?.serialString ?? ''}</Descriptions.Item>
      <Descriptions.Item label="年份">
        {drawing?.year ?? ''}
      </Descriptions.Item>
      <Descriptions.Item label="組合碼">
        {`${drawing?.compositeName ?? ''}（${drawing?.compositeCode ?? ''}）`}
      </Descriptions.Item>
      <Descriptions.Item label="拼數">{drawing?.compositeNumber ?? ''}</Descriptions.Item>
      <Descriptions.Item label="形狀碼">
        {`${drawing?.shapeName ?? ''}（${drawing?.shapeCode ?? ''}）`}
      </Descriptions.Item>
      <Descriptions.Item label="尺寸一碼">{drawing?.sizeCodeOne ?? ''}</Descriptions.Item>
      <Descriptions.Item label="尺寸二碼">{drawing?.sizeCodeTwo ?? ''}</Descriptions.Item>
      <Descriptions.Item label="尺寸三碼">{drawing?.sizeCodeThree ?? ''}</Descriptions.Item>
      <Descriptions.Item label="尺寸一碼數值">{drawing?.sizeOne ?? ''}</Descriptions.Item>
      <Descriptions.Item label="尺寸二碼數值">{drawing?.sizeTwo ?? ''}</Descriptions.Item>
      <Descriptions.Item label="尺寸三碼數值">{drawing?.sizeThree ?? ''}</Descriptions.Item>
      <Descriptions.Item label="分階碼" span={3}>{drawing?.layeringCode ?? ''}</Descriptions.Item>
      <Descriptions.Item label="噴砂碼">{drawing?.sandblastingCode ?? ''}</Descriptions.Item>
      <Descriptions.Item span={2} labelStyle={{ display: 'none' }}>
        <ExpandDescriptionsItem colspan="4">
          <Descriptions bordered column={2} labelStyle={{ width: 140 }} size="small">
            <Descriptions.Item label="傳統噴砂">
              {drawing?.isTraditionalSandblasting ? '有' : '無'}
            </Descriptions.Item>
            <Descriptions.Item label="雷射噴砂">
              {drawing?.isLaserSandblasting ? '有' : '無'}
            </Descriptions.Item>
            <Descriptions.Item label="凹槽噴砂">
              {drawing?.isGrooveSandblasting ? '有' : '無'}
            </Descriptions.Item>
            <Descriptions.Item label="電漿噴砂">
              {drawing?.isPlasmaSandblasting ? '有' : '無'}
            </Descriptions.Item>
          </Descriptions>
        </ExpandDescriptionsItem>
      </Descriptions.Item>
      <Descriptions.Item label="開槽碼">{drawing?.slottingCode ?? ''}</Descriptions.Item>
      <Descriptions.Item span={2} labelStyle={{ display: 'none' }}>
        <ExpandDescriptionsItem colspan="4">
          <Descriptions bordered column={2} labelStyle={{ width: 140 }} size="small">
            <Descriptions.Item label="Oring">
              {drawing?.isOring ? '有' : '無'}
            </Descriptions.Item>
            <Descriptions.Item label="Profile">
              {drawing?.isProfile ? '有' : '無'}
            </Descriptions.Item>
          </Descriptions>
        </ExpandDescriptionsItem>
      </Descriptions.Item>
      <Descriptions.Item label="鑽孔碼">{drawing?.drillingCode ?? ''}</Descriptions.Item>
      <Descriptions.Item span={2} labelStyle={{ display: 'none' }}>
        <ExpandDescriptionsItem colspan="4">
          <Descriptions bordered column={2} labelStyle={{ width: 140 }} size="small">
            <Descriptions.Item label="攻牙">
              {drawing?.isTapping ? '有' : '無'}
            </Descriptions.Item>
            <Descriptions.Item label="穿孔">
              {drawing?.isPerforation ? '有' : '無'}
            </Descriptions.Item>
            <Descriptions.Item label="盲孔">
              {drawing?.isBlindHole ? '有' : '無'}
            </Descriptions.Item>
          </Descriptions>
        </ExpandDescriptionsItem>
      </Descriptions.Item>          
      <Descriptions.Item span={3} label="備註">{drawing?.remark ?? ''}</Descriptions.Item>
      <Descriptions.Item span={3} label="母圖" >
        <div style={{display: 'flex', gap: '12px' }}>
          {drawing?.parentDrawings?.map((drawing) => <Link to={`/drawings/tem/${drawing?.newDrawingCode}`}>{drawing?.newDrawingCode}</Link>)}
        </div>
      </Descriptions.Item>
      <Descriptions.Item span={3} label="子圖" >
        <div style={{display: 'flex', gap: '12px' }}>
          {drawing?.childDrawings?.map((drawing) => <Link to={`/drawings/tem/${drawing?.newDrawingCode}`}>{drawing?.newDrawingCode}</Link>)}
        </div>
      </Descriptions.Item>
      <Descriptions.Item span={3} label="關係圖" >
        <div ref={graphChartRef} />
      </Descriptions.Item>
      <Descriptions.Item span={3} label="藍圖">
        <PDFPreviewer fileLink={fileLink} />
      </Descriptions.Item>
    </Descriptions>
  );
}