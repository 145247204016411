import { SelectValue } from '@mezzanine-ui/react';
import { Sample } from '@solar/data';
import { labOptions } from '../hooks/typing';

export interface SendForInspectionFormValues {
  factory: SelectValue;
  department: SelectValue | null;
  reportReceiver: string;
  reportReceiverEmail: string;
  samples: (Omit<
    Sample,
    'sampleAmount' | 'emptyBagWeight' | 'sampleNetWeight' | 'sampleRemainWeight'
  > & {
    sampleAmount: string;
    emptyBagWeight: string;
    sampleNetWeight: string;
    sampleRemainWeight: string;
  })[];
}

export const defaultValues: SendForInspectionFormValues = {
  factory: labOptions[0],
  department: null,
  reportReceiver: '',
  reportReceiverEmail: '',
  samples: [],
};
