import { PlusIcon } from '@mezzanine-ui/icons';
import { Button, Icon, Message, Table, Typography } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  checkJWTRoles,
  FieldsType,
  FilterDataType,
  ResponseStatus,
  useMainFileData,
  useMainFileFilters,
  useMainFileModal,
} from '@solar/data';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  AutoComplete,
  MultipleSelect,
  SearchInput,
} from '../../OrderManagement/FilterSearch/enhanceFilter/enhanceFilter';
import { SubmitButtonType } from '../Layout/Filter/Filter';
import { Layout } from '../Layout/Layout';
import { getComponent } from '../Layout/ModalLayout/enhanceModalActions/enhanceModalActions';
import { ModalLayout } from '../Layout/ModalLayout/ModalLayout';
import { MainFileDeleteModal } from './MainFileDeleteModal/MainFileDeleteModal';
import styles from './MainFilePage.module.scss';

export function MainFilePage() {
  const methods = useForm();
  const layoutMethods = useForm();

  const [disableSelect, setDisableSelect] = useState(true);

  const {
    data: mainFileData,
    filterMutation: filterMainFileData,
    page: currentPage,
    total,
    setPage: setCurrentPage,
    perPage,
    setPerPage,
  } = useMainFileData();

  const {
    deleteModal,
    setDeleteModal,
    modal,
    setModal,
    disableConfirm: disableConfirmModal,
    handleOpenModal,
    closeModal,
    confirmModal,
    createModal,
    updateModal,
    deleteModalMutation,
  } = useMainFileModal(methods);

  const {
    data: mainFileFiltersData,
    filterMutation: mainFileFilterMutation,
    customers,
  } = useMainFileFilters();

  const modalBodyComponents = useMemo(() => {
    const modalData = modal?.title === '編輯' ? updateModal : updateModal;
    return modalData.map((item) => ({
      ...item,
      fn: getComponent(item.fn),
    }));
  }, [modal, createModal, updateModal]);

  const basicBodyComponents = useMemo(
    () => modalBodyComponents.slice(0, 5),
    [modalBodyComponents]
  );
  const packageBodyComponents = useMemo(
    () => modalBodyComponents.slice(5),
    [modalBodyComponents]
  );

  const filtersData = useMemo(
    () => [
      {
        label: '產品料號/品名',
        fn: SearchInput,
        registerName: 'productPartNo',
        props: {
          width: 160,
          clearable: true,
          placeholder: '請輸入',
          className: styles.input,
          name: 'productPartNo',
          register: layoutMethods.register,
          setValue: layoutMethods.setValue,
        },
      },
      {
        label: '客戶名稱',
        fn: AutoComplete,
        registerName: 'customerName',
        props: {
          width: 160,
          options:
            customers.map((customer) => ({
              id: customer.id,
              name: customer.name ?? '',
            })) ?? [],
          placeholder: '請選擇',
          getValues: layoutMethods.getValues,
        },
      },
      {
        label: '包材類型',
        fn: AutoComplete,
        registerName: 'packageMaterialType',
        props: {
          width: 160,
          options: mainFileFiltersData?.packagingTypes ?? [],
          clearable: true,
          placeholder: '請選擇',
          getValues: layoutMethods.getValues,
        },
      },
      {
        label: '包材名稱',
        fn: MultipleSelect,
        registerName: 'packageMaterialName',
        props: {
          mode: 'single',
          width: 160,
          disabled: disableSelect,
          options: mainFileFiltersData?.packaging ?? [],
          clearable: true,
          placeholder: '請選擇',
        },
      },
    ],
    [mainFileFiltersData, disableSelect, customers]
  );

  const registers = useMemo(() => {
    layoutMethods.watch();
    return filtersData.map((data) => ({
      registerName: data.registerName,
      value: layoutMethods.getValues(data.registerName),
    }));
  }, [layoutMethods.watch()]);

  const registerDependencies = useMemo(
    () => registers.map((register) => register.value),
    [registers]
  );

  const filterButton = useMemo(
    () => ({
      name: '清空條件',
      onClick() {
        setCurrentPage(1);
        filtersData.forEach((filter) => {
          layoutMethods.setValue(filter.registerName, undefined);
        });
      },
    }),
    [layoutMethods]
  ) as SubmitButtonType;

  const columns = useMemo(
    () => [
      { title: '產品料號', dataIndex: 'materialId', width: 200 },
      { title: '產品品名', dataIndex: 'materialName', width: 180 },
      { title: '客戶名稱', dataIndex: 'customerName', width: 180 },
      { title: '包材類型', dataIndex: 'packagingType', width: 100 },
      { title: '包材名稱', dataIndex: 'packagingName', width: 180 },
      { title: '包裝數量', dataIndex: 'limitCount', width: 100 },
      { title: '單位', dataIndex: 'quantityUnit', width: 80 },
      {
        title: '不需等候包裝指示',
        width: 100,
        align: 'center' as const,
        render(source: any) {
          return source.passPackingRequest === 'Y' ? '不需等候' : '需等候';
        },
      },
      {
        title: '動作',
        width: 100,
        align: 'center' as const,
        render(source: any) {
          return (
            checkJWTRoles([72]) && (
              <Button
                type="button"
                variant="outlined"
                onClick={() => handleOpenModal(source)}
              >
                編輯
              </Button>
            )
          );
        },
      },
    ],
    []
  );

  const filterLayout = useCallback(
    async (payload: FieldsType[]) => {
      setCurrentPage(1);
      filterMainFileData(payload);
    },
    [setCurrentPage, filterMainFileData]
  );

  const confirmCreateModal = useCallback(async () => {
    const res = await confirmModal(methods);
    if (res.status === ResponseStatus.SUCCESS) {
      Message.success(res.message);
    } else {
      Message.error(res.message);
    }
  }, [methods, confirmModal]);

  useEffect(() => {
    layoutMethods.watch();
    const packagingType = layoutMethods.getValues(
      'packageMaterialType'
    ) as FilterDataType;
    if (!packagingType) {
      setDisableSelect(true);
      return;
    }

    mainFileFilterMutation(`&packagingType=${packagingType.id}`);
    setDisableSelect(false);
  }, [
    layoutMethods,
    layoutMethods.getValues('packageMaterialType'),
    mainFileFilterMutation,
  ]);

  return (
    <div className={styles.wrapper}>
      <ModalLayout
        title={modal?.title}
        open={modal}
        width={864}
        onClose={closeModal}
        confirmButtonText={modal?.confirmButton}
        onConfirm={confirmCreateModal}
        onConfirmDelete={() => deleteModalMutation(modal?.source?.id)}
        confirmButtonDisable={disableConfirmModal}
        deleteButton={modal?.title === '編輯'}
        source={modal?.source}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        deleteModalChildren={<MainFileDeleteModal source={modal?.source} />}
      >
        <div className={styles.modalWrapper}>
          <FormFieldsWrapper methods={methods} className={styles.formWrapper}>
            <div className={styles.modalBodyWithBorder}>
              <Typography variant="h4" color="text-primary">
                基本資訊
              </Typography>
              <div className={styles.body}>
                {basicBodyComponents.map((component) =>
                  component.fn(component)
                )}
              </div>
            </div>
            <div className={styles.modalBodyWithBorder}>
              <Typography variant="h4" color="text-primary">
                包裝資訊
              </Typography>
              <div className={styles.body}>
                {packageBodyComponents.map((component) =>
                  component.fn(component)
                )}
              </div>
            </div>
          </FormFieldsWrapper>
        </div>
      </ModalLayout>
      <Layout
        title="包裝主檔"
        button={
          <Button
            variant="contained"
            disabled={!checkJWTRoles([72])}
            size="large"
            prefix={<Icon icon={PlusIcon} />}
            onClick={() =>
              setModal({
                title: '新增包裝主檔',
                confirmButton: '新增',
                source: {},
              })
            }
          >
            新增
          </Button>
        }
        filterHeader="查詢"
        filtersData={filtersData}
        filterMethods={filterLayout}
        filterDependencies={registerDependencies}
        registers={registers}
        submitButton={filterButton}
        methods={layoutMethods}
      >
        <Table
          bodyClassName={styles.table}
          headerClassName={styles.tableHeader}
          columns={columns}
          dataSource={mainFileData ?? []}
          pagination={{
            disableAutoSlicing: true,
            current: currentPage,
            total: total,
            onChange(page) {
              setCurrentPage(page);
            },
            options: {
              pageSize: perPage,
              onChangePageSize: (pageSize: number) => {
                setPerPage(pageSize);
                setCurrentPage(1);
              },
              showPageSizeOptions: true,
              pageSizeLabel: '每頁顯示：',
              pageSizeUnit: '筆',
            },
          }}
        />
      </Layout>
    </div>
  );
}
