import { API_NAMESPACE, request } from '@solar/data';

const namespace = API_NAMESPACE.MATERIALS;

export type CreateWorkOrderPayload = {
  workOrderId: string;

  outputMaterialId: string;
  outputMaterialSapBatchId: string;

  /** @deprecated */
  materialSubBatchCount?: number;
  /** @description: 取代materialSubBatchCount */
  outputMaterialSubBatchCount: number;

  recipeId: string | null;

  expectedCompletedAt: string;
  sourceType: 'REQUEST_ORDER';
  designatedInventory: boolean;
  items: Array<{
    materialId: string;
    materialSapBatchId: string;
    materialSubBatchId: string;
    loaderId: string;
    materialStockUnitQuantity: string;
  }>;
};

export const createWorkOrder = (
  payload: CreateWorkOrderPayload
): Promise<Response> => {
  return request('/warehouses/material-request-orders', {
    method: 'post',
    body: JSON.stringify(payload),
    responseParser: (res) => res,
    namespace,
  });
};
