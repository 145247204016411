import { Button, Loading, Message, SelectValue } from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  FormFieldsWrapper,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  ProductionFormResponse,
  checkJWTRoles,
  getProductionFormAnswers,
  saveProductionFormAnswers,
  useBatchIdsByMaterialId,
  useMaterials,
} from '@solar/data';
import { MaterialAndBatchField, PageLayout } from '@solar/templates';
import { Space } from 'antd';
import { useCallback, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { ProductionForms } from '../ProductionForm/ProductionForms';

interface FormValues {
  materialId: SelectValue | null;
  batchId: SelectValue | null;
  forms: Record<
    string, // answerId
    any
  >;
}

export function ProductionFormEditorPage() {
  const [searching, setSearching] = useState(false);
  const [saving, setSaving] = useState(false);
  const methods = useForm<FormValues>();
  const [forms, setForms] = useState<ProductionFormResponse[]>([]);

  const watchedMaterial = useWatch({
    control: methods.control,
    name: 'materialId',
  });

  const watchedBatch = useWatch({
    control: methods.control,
    name: 'batchId',
  });

  const onSearch = useCallback(async () => {
    try {
      setSearching(true);

      const formState = methods.getValues();

      if (!formState?.materialId) {
        Message.error('請選擇料號');
        return;
      }
      if (!formState?.batchId) {
        Message.error('請選擇批號');
        return;
      }

      const [materialSapBatchId, materialSubBatchId] =
        formState?.batchId?.id?.split('-') ?? [];

      const productionFormAnsRes = await getProductionFormAnswers({
        offset: 0,
        materialId: formState.materialId.id,
        materialSapBatchId,
        materialSubBatchId,
        withLims: false,
        selectDefaultAndPicked: false,
        templateTypes: ['PRODUCT', 'EQUIPMENT'],
        latestOnly: true,
      });

      const formAnswers = productionFormAnsRes?.records ?? [];

      if ((formAnswers?.length ?? 0) === 0) {
        Message.error('尚未有生產題目答案');
        return;
      }

      setForms(formAnswers?.map((fa) => fa?.form) ?? []);

      formAnswers.forEach((formAnswer) => {
        methods.setValue(
          `forms.${formAnswer?.form?.id}`,
          formAnswer?.modifiedAnswer ?? formAnswer?.answer ?? null
        );
      });

      Message.success('題目讀取成功');
    } catch (error) {
      console.error(error);
      Message.error('題目讀取失敗');
    } finally {
      setSearching(false);
    }
  }, [methods]);

  const resetForms = useCallback(() => {
    methods.reset({
      materialId: null,
      batchId: null,
      forms: {},
    });
    setForms([]);
  }, []);

  const onSave = useCallback(async () => {
    try {
      setSaving(true);

      const formState = methods.getValues();

      if (!formState?.materialId) {
        Message.error('請選擇料號');
        return;
      }
      if (!formState?.batchId) {
        Message.error('請選擇批號');
        return;
      }

      const [materialSapBatchId, materialSubBatchId] =
        formState?.batchId?.id?.split('-') ?? [];

      const newAnswers = Object.entries(formState?.forms)?.reduce<
        {
          productionFormId: string;
          answer: any;
          picked: boolean;
        }[]
      >((results, [productionFormId, answer]) => {
        results.push({
          productionFormId,
          answer,
          picked: false,
        });

        return results;
      }, []);

      await saveProductionFormAnswers({
        materialId: formState?.materialId?.id ?? '',
        materialSapBatchId,
        materialSubBatchId,
        upsertAnswers: [],
        newAnswers,
      });

      resetForms();

      Message.success('題目儲存成功');
    } catch (error) {
      console.error(error);
      Message.error('題目儲存失敗');
    } finally {
      setSaving(false);
    }
  }, []);

  return (
    <PageLayout title="生產題目編輯">
      <FormFieldsWrapper methods={methods}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 'var(--mzn-spacing-2)',
            }}
          >
            <MaterialAndBatchField
              withDeleted
              materialLabel="料號"
              materialRegisterName="materialId"
              materialBatchLabel="批號"
              materialBatchRegisterName="batchId"
            />
          </div>
          <Button
            type="button"
            loading={searching}
            variant="contained"
            onClick={onSearch}
          >
            搜尋
          </Button>
          <Loading loading={searching}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                rowGap: '8px',
              }}
            >
              <ProductionForms
                forms={forms}
                getRegisterName={(form) => `forms.${form.id}`}
              />
            </div>
          </Loading>
          {checkJWTRoles([148]) && (
            <Button
              type="button"
              disabled={
                !watchedMaterial ||
                !watchedBatch ||
                saving ||
                searching ||
                (forms?.length ?? 0) === 0
              }
              variant="contained"
              loading={saving}
              onClick={onSave}
            >
              儲存
            </Button>
          )}
        </Space>
      </FormFieldsWrapper>
    </PageLayout>
  );
}
