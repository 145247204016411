import { Button, Empty, Loading, Typography } from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  FormFieldsWrapper,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  checkJWTRoles,
  useBatchIdsByMaterialId,
  useMaterials,
} from '@solar/data';
import {
  MaterialAndBatchField,
  MznPaginationTable,
  PageLayout,
  HorizontalField,
} from '@solar/templates';
import { Row, Space, Spin } from 'antd';
import { useForm, useWatch } from 'react-hook-form';
import { ManualProductionForms } from './ManualProductionForm';
import { SyncLimsButton } from '../SyncLimsButton/SyncLimsButton';
import { useInventoryByMaterial } from '../hooks/use-inventory-by-material';
import { FormValues, defaultValues, labOptions } from '../hooks/typing';
import { FinalAnswersCard } from '../AnswersCard/FinalAnswersCard';
import { SubmissionAnswersCard } from '../AnswersCard/SubmissionAnswersCard';
import { useSubmissionResults } from '../hooks/use-submission-results';

export function SubmissionResultsByInventoryPage() {
  const methods = useForm<FormValues>({ defaultValues });

  const { inventory, pageInfo, refetchGetInventoryByMaterial } =
    useInventoryByMaterial({ methods });

  const {
    searching,
    manualForms,
    submissionResults,
    onSearchSubmissionResults,
    resetSubmissionResults,
  } = useSubmissionResults({ methods });

  const factory = useWatch({
    control: methods.control,
    name: 'factory',
  })?.id;

  const watchedBatch = useWatch({
    control: methods.control,
    name: 'batchId',
  });

  const resetForm = () => {
    methods.reset(defaultValues);
    resetSubmissionResults();
    refetchGetInventoryByMaterial({ nextPage: 1 });
  };

  const onSearch = async () => {
    try {
      const formState = methods.getValues();
      await onSearchSubmissionResults({
        factory: formState?.factory?.id ?? '',
        materialId: formState?.materialId?.id ?? '',
        batchId: formState?.batchId?.id ?? '',
      });
      await refetchGetInventoryByMaterial({ nextPage: 1 });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PageLayout
      title="庫存檢驗結果"
      disabledDivider
      extraContent={
        <Row style={{ height: '100%' }} justify="end" align="bottom">
          <SyncLimsButton factory={factory} afterSync={onSearch} />
        </Row>
      }
    >
      <FormFieldsWrapper methods={methods}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 'var(--mzn-spacing-3)',
          }}
        >
          <HorizontalField label="實驗室">
            <SelectField required registerName="factory" options={labOptions} />
          </HorizontalField>
          <MaterialAndBatchField
            materialLabel="料號"
            materialRegisterName="materialId"
            materialBatchLabel="批號"
            materialBatchRegisterName="batchId"
            withDeleted
          />
          <Button
            type="button"
            loading={searching}
            variant="contained"
            onClick={onSearch}
          >
            搜尋
          </Button>
          <Loading stretch loading={searching}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography variant="h4">庫存清單</Typography>
              <MznPaginationTable
                columns={[
                  {
                    title: '料號',
                    dataIndex: 'materialId',
                  },
                  {
                    title: '品名',
                    dataIndex: 'materialDescription',
                  },
                  {
                    title: '批號',
                    dataIndex: 'batchId',
                  },
                  // {
                  //   title: '儲位',
                  //   dataIndex: 'stackId',
                  //   render: (source) => (
                  //     <div
                  //       data-zone-id={source?.stackId}
                  //       style={{ cursor: 'pointer' }}
                  //       onMouseEnter={(event: any) => {
                  //         if (source?.stackId) {
                  //           // locationSVGPopperHandler.onMouseEnter(source?.stackId)(event);
                  //           methods.setValue('selectedChartId', source?.stackId);

                  //           setLocationViewerAnchor(event.currentTarget);
                  //         }
                  //       }}
                  //       // onMouseLeave={locationSVGPopperHandler.onMouseLeave}
                  //     >
                  //       {source?.shelfId ?? ''}
                  //     </div>
                  //   ),
                  // },
                  {
                    title: '載具',
                    dataIndex: 'loaderId',
                  },
                  {
                    title: '基礎計量單位數量',
                    dataIndex: 'availableMaterialStockUnitQuantity', // 'mainMaterialUnitQuantity',
                  },
                  { title: '基礎計量單位', dataIndex: 'materialStockUnit' },
                  {
                    title: '管理單位數量',
                    dataIndex: 'availableMaterialInputUnitQuantity',
                  },
                  {
                    title: '管理單位',
                    dataIndex: 'materialInputUnit',
                  },
                  {
                    title: '實際重量',
                    dataIndex: 'actualMaterialWeightUnitQuantity',
                  },
                ]}
                dataSource={inventory}
                fetchData={refetchGetInventoryByMaterial}
                pageInfo={pageInfo}
                scroll={{ x: 2500 }}
              />

              <Space direction="vertical" style={{ width: '100%' }}>
                <Typography variant="h4">檢驗項目</Typography>
                {watchedBatch && manualForms?.length ? (
                  <ManualProductionForms forms={manualForms} />
                ) : (
                  <Space style={{ width: '100%', justifyContent: 'center' }}>
                    <Empty title="無檢驗項目" />
                  </Space>
                )}
              </Space>

              {watchedBatch && manualForms?.length ? (
                <FinalAnswersCard
                  hideSaveButton={!checkJWTRoles([103])}
                  forms={manualForms}
                  resetForm={resetForm}
                />
              ) : null}

              {watchedBatch && submissionResults?.length ? (
                <Space direction="vertical" style={{ width: '100%' }}>
                  {submissionResults?.map((item) => (
                    <SubmissionAnswersCard
                      key={item?.submission?.id}
                      title={
                        item?.submission?.subNumber
                          ? `檢驗單號：${item?.submission?.subNumber}`
                          : '未指定檢驗單號'
                      }
                      formAnswers={item?.formAnswers}
                    />
                  ))}
                </Space>
              ) : null}
            </Space>
          </Loading>
        </div>
      </FormFieldsWrapper>
    </PageLayout>
  );
}
