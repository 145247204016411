import { useTargetModal } from '@solar/templates';
import { Modals } from './Modals.enum';
import {
  Button,
  Message,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Typography,
} from '@mezzanine-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { Row, Space } from 'antd';
import {
  checkJWTRoles,
  OOCTicket,
  OOCTicketItem,
  updateOOCTicket,
  useGetOOCTickets,
} from '@solar/data';
import { TableColumn } from '@mezzanine-ui/core/table';
import { useForm } from 'react-hook-form';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';

interface FormValues {
  ticketItems: Record<string, string>; // ticketItemId, comment
}

export function InLineSPCAbnormalReportModal() {
  const { data, open, closeModal } = useTargetModal<OOCTicket>(
    Modals.IN_LINE_SPC_ABNORMAL_REPORT
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { mutateGetOOCTickets } = useGetOOCTickets();

  const methods = useForm<FormValues>();

  useEffect(() => {
    if (open && data) {
      methods.reset({
        ticketItems: data?.items.reduce<Record<string, string>>(
          (acc, cur) => ({
            ...acc,
            [cur.id]: cur.comment,
          }),
          {}
        ),
      });
    }
  }, [data, methods, open]);

  const onClose = useCallback(() => {
    closeModal();
    methods.reset({ ticketItems: {} });
  }, [closeModal, methods]);

  const onSubmit = useCallback(async () => {
    const formState = methods.getValues();

    if (!data?.id) {
      Message.error('基本資料錯誤');
      return;
    }

    try {
      setIsSubmitting(true);

      await updateOOCTicket(data.id, {
        items: Object.entries(formState.ticketItems).map(
          ([itemId, comment]) => ({
            itemId,
            comment,
          })
        ),
      });

      await mutateGetOOCTickets();

      Message.success('註記成功');
    } catch (error) {
      Message.error('註記失敗');
    } finally {
      setIsSubmitting(false);
    }
  }, [data, methods, mutateGetOOCTickets]);

  return (
    <Modal style={{ width: '900px' }} open={open} onClose={onClose}>
      <ModalHeader>異常明細</ModalHeader>
      <ModalBody>
        <FormFieldsWrapper methods={methods}>
          <Space direction="vertical">
            <Typography variant="h4">料號：{data?.materialId}</Typography>
            <Typography variant="h4">
              批號：{data?.sapBatchId}_{data?.subBatchId}
            </Typography>
            <Table
              dataSource={data?.items ?? []}
              columns={
                [
                  {
                    title: '題目',
                    render: (source) =>
                      source?.productionAnswer?.form?.title ?? '',
                  },
                  {
                    title: '值',
                    render: (source) =>
                      `${source?.productionAnswer?.answer ?? ''} ${
                        source?.productionAnswer?.form?.suffix ?? ''
                      }`,
                  },
                  { title: '異常原因', dataIndex: 'oocReason' },
                  {
                    title: '註記',
                    render: (source) => (
                      <InputField registerName={`ticketItems.${source?.id}`} />
                    ),
                  },
                ] as TableColumn<OOCTicketItem>[]
              }
            />
          </Space>
        </FormFieldsWrapper>
      </ModalBody>
      <ModalFooter>
        {checkJWTRoles([149]) && (
          <Row justify="end" style={{ width: '100%' }}>
            <Space>
              <Button type="button" variant="outlined" onClick={onClose}>
                取消
              </Button>
              <Button
                loading={isSubmitting}
                type="button"
                variant="contained"
                onClick={onSubmit}
              >
                確認
              </Button>
            </Space>
          </Row>
        )}
      </ModalFooter>
    </Modal>
  );
}
