import type { HubOrderUpdateFormValues } from '@solar/data';
import { HubOrderUpdateFormFieldName as FormFieldName } from '@solar/data';
import { useFormContext } from 'react-hook-form';
import { DatePickerField } from '@mezzanine-ui/react-hook-form';

export function OrderReverseDatePicker({
  debitDate,
  reverseDate,
}: {
  debitDate?: string;
  reverseDate?: string;
}) {
  const formMethods = useFormContext<HubOrderUpdateFormValues>();

  return (
    <>
      <div>迴轉日期：</div>
      <div>{reverseDate ? reverseDate : '-'}</div>
    </>
  );
}
