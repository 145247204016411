export enum MaterialShiftInputFields {
  'MATERIAL_ID_OR_MATERIAL_NAME' = 'MATERIAL_ID_OR_MATERIAL_NAME',
  'MATERIAL_BARCODE' = 'MATERIAL_BARCODE',
  'LOADER_BARCODE' = 'LOADER_BARCODE',
}

export type GeneratedStorageInInputFields = Exclude<
  MaterialShiftInputFields,
  MaterialShiftInputFields.MATERIAL_ID_OR_MATERIAL_NAME
>;

export enum ScanBarCodeActionResult {
  OK = 'OK',
  Prohibit = 'Prohibit',
}
