import { Button, SelectValue } from '@mezzanine-ui/react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormOutlined } from '@ant-design/icons';
import { RowSection } from '../RowSection/RowSection';
import {
  AutoCompleteField,
  AutoCompleteFieldProps,
  InputField,
  InputFieldProps,
  SelectFieldProps,
} from '@mezzanine-ui/react-hook-form';

interface EditableCellsScaffoldProp {
  staticLabelRegisterName: Array<{
    label: string;
    componentName: 'input' | 'select' | 'single-auto-complete';
    restProps: InputFieldProps | SelectFieldProps | AutoCompleteFieldProps;
  }>;
  onChange?: (value: string) => void;
  onSave?: () => Promise<void>;
}

export function EditableCellsScaffold({
  staticLabelRegisterName,
  onSave,
}: EditableCellsScaffoldProp) {
  const { watch } = useFormContext();

  const [editable, setEditable] = useState(false);

  return (
    <>
      {editable ? (
        <Button
          variant="contained"
          type="button"
          onClick={async () => {
            await onSave?.();
            setEditable(false);
          }}
        >
          儲存
        </Button>
      ) : (
        <Button
          variant="outlined"
          type="button"
          onClick={() => setEditable(true)}
        >
          <FormOutlined />
          &nbsp; 修改
        </Button>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          marginTop: '8px',
        }}
      >
        {editable
          ? staticLabelRegisterName.map((field) => {
              if (field.componentName === 'input') {
                const restProps = field?.restProps as InputFieldProps;

                return (
                  <RowSection label={field.label ?? ''}>
                    <InputField {...restProps} />
                  </RowSection>
                );
              } else if (field.componentName === 'single-auto-complete') {
                const restProps = field?.restProps as AutoCompleteFieldProps;
                return (
                  <RowSection label={field.label ?? ''}>
                    <AutoCompleteField {...restProps} />
                  </RowSection>
                );
              } else return ''; // reserved to be extended
            })
          : staticLabelRegisterName.map((field) => {
              let watchValue = watch(field?.restProps?.registerName);
              if (typeof watchValue === 'object') {
                watchValue = (watchValue as SelectValue)?.name || '無';
              }
              return (
                <RowSection label={field.label ?? ''}>{watchValue}</RowSection>
              );
            })}
      </div>
    </>
  );
}
