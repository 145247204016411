import { Button, Message, Table, Typography } from '@mezzanine-ui/react';
import {
  FilterPayload,
  ResponseStatus,
  useHumanResourceMembers,
  useMembersTreeSelect,
} from '@solar/data';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HumanResourceMemberFilter } from './HumanResourceMemberFilter/HumanResourceMemberFilter';
import styles from './HumanResourceMembersPage.module.scss';

export function HumanResourceMembersPage() {
  const {
    data: membersData,
    loading,
    filterMutation: filterHumanResourceMembers,
    exportMembers: exportHumanResourceMembers,
  } = useHumanResourceMembers();
  const membersList = membersData?.data;

  const { treeSelectData, treeValue, setTreeValue } = useMembersTreeSelect();

  const newMemberList = useMemo(
    () =>
      membersList?.map((item: any) => {
        const arr = [item.level5, item.level6, item.level7].filter(
          (level) => level !== ''
        );
        return arr.length === 0
          ? { ...item, unitPath1: '' }
          : { ...item, unitPath1: arr.join(' | ') };
      }),
    [membersList]
  );

  const methods = useForm();
  const [page, setPage] = useState(1);
  const [per_page, setPer_page] = useState(membersData?.per_page);
  const [updateFilterObject, setUpdateFilterObject] = useState(true);

  const columns = [
    { title: '工號', dataIndex: 'id', width: 100 },
    { title: '姓名', dataIndex: 'name', width: 100 },
    { title: '在職狀態', dataIndex: 'employeeChangeType', width: 100 },
    { title: '員工類別', dataIndex: 'employeeCategory', width: 100 },
    { title: 'BU', dataIndex: 'bu', width: 80 },
    { title: '處', dataIndex: 'level5', width: 120 },
    { title: '部', dataIndex: 'level6', width: 120 },
    { title: '課', dataIndex: 'level7', width: 120 },
    { title: '入職日期', dataIndex: 'onboard', width: 120 },
    { title: '離職日期', dataIndex: 'dismiss', width: 120 },
    { title: '年齡', dataIndex: 'age', width: 65 },
    { title: '年資', dataIndex: 'jobTenure', width: 65 },
    { title: '教育程度', dataIndex: 'educationStage', width: 120 },
  ];

  const categoryField = methods.getValues('categoryField');
  const employeeTypeField = methods.getValues('employeeTypeField');
  const businessUnitsField = methods.getValues('businessUnitsField');
  const codeSearchInput = methods.getValues('codeSearchInput');
  const nameSearchInput = methods.getValues('nameSearchInput');
  const onboardCalendar = methods.getValues('onboardCalendar');
  const dismissCalendar = methods.getValues('dismissCalendar');

  const filterObject: FilterPayload = {
    category: categoryField,
    employeeType: employeeTypeField,
    BU: businessUnitsField,
    code: codeSearchInput,
    name: nameSearchInput,
    onBoard: onboardCalendar,
    dismiss: dismissCalendar,
    unitPath: treeValue,
  };

  const searchedFilterObject = useMemo(() => {
    return filterObject;
  }, [updateFilterObject]);

  const onExport = async () => {
    const res = await exportHumanResourceMembers(filterObject);

    if (res === ResponseStatus.SUCCESS) {
      Message.success('匯出成功');
    } else {
      Message.error('失敗，請再試一次');
    }
  };

  return (
    <div>
      <div className={styles.filterFlex}>
        <Typography variant="h1" style={{ marginBottom: 32 }}>
          取得員工清單
        </Typography>
        <Button type="button" variant="contained" onClick={onExport}>
          匯出
        </Button>
      </div>
      <HumanResourceMemberFilter
        methods={methods}
        filterHumanResourceMembers={filterHumanResourceMembers}
        updateFilterObject={updateFilterObject}
        setUpdateFilterObject={setUpdateFilterObject}
        setPage={setPage}
        treeSelectData={treeSelectData}
        treeValue={treeValue}
        setTreeValue={setTreeValue}
      />
      <Table
        loading={loading}
        bodyClassName={styles.table}
        dataSource={newMemberList ?? []}
        columns={columns}
        pagination={{
          disableAutoSlicing: true,
          total: membersData?.total,
          current: page,
          onChange: (page) => {
            filterHumanResourceMembers({
              ...searchedFilterObject,
              per_page,
              page,
            });
            setPage(page);
          },
          options: {
            pageSize: per_page,
            onChangePageSize: (pageSize: number) => {
              filterHumanResourceMembers({
                ...searchedFilterObject,
                per_page: pageSize,
                page: 1,
              });
              setPer_page(pageSize);
              setPage(1);
            },
            showPageSizeOptions: true,
            pageSizeLabel: '每頁顯示：',
            pageSizeUnit: '筆',
          },
        }}
      />
    </div>
  );
}
