import { API_NAMESPACE, request } from '@solar/data';
import { APIResponse } from '@solar/data';

export type UpdateRemarksProps = {
  remark: string;
  orderId: string;
};

const namespace = API_NAMESPACE.MATERIALS;

type UpdateWorkOrderRemarkResponse = {
  id: string;
  staffId: string;
  businessUnitId: null;
  processName: null;
  expectedCompletedAt: string;
  actualCompletedAt: null;
  itemId: string;
  typeCode: string;
  operationProcessingCode: string;
  factoryId: string;
  remark: string;
  createdAt: string;
  updatedAt: string;
  syncAt: string;
  deletedAt: null;
};
export const updateRemark = ({
  remark,
  orderId,
}: UpdateRemarksProps): Promise<APIResponse<UpdateWorkOrderRemarkResponse>> => {
  return request(`/warehouses/work-orders/${orderId}/remark`, {
    method: 'put',
    body: JSON.stringify({ remark }),
    namespace,
    responseParser: (res) => res,
  });
};
