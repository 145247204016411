import { Menu, MenuItem, Popper, SelectValue } from '@mezzanine-ui/react';
import { InputField } from '@mezzanine-ui/react-hook-form';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './InputWithDataList.module.scss';

type InputWithDataListProps = {
  label: string;
  registerName: string;
  options?: SelectValue[];
  required?: boolean;
  className?: string;
  onSelect?: (value: SelectValue) => void;
  onChange?: (value: string) => void;
};

export function InputWithDataList({
  label,
  registerName,
  required,
  className,
  options: originalOptions,
  onSelect,
  onChange,
}: InputWithDataListProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { watch, setValue } = useFormContext();
  const inputValue = watch(registerName);
  const [isOpen, setIsOpen] = useState(false);

  const options = useMemo(() => {
    return originalOptions?.filter((item: SelectValue) => {
      return inputValue ? item.name?.includes(inputValue) : true;
    });
  }, [originalOptions, inputValue]);

  const onListItemClick = (option: SelectValue) => {
    setValue(registerName, option.name);
    setIsOpen(false);
    if (onSelect) {
      onSelect(option);
    }
  };

  useEffect(() => {
    const clickHandler = (e: Event) => {
      const el = e.target as Element;
      const clickInput = wrapperRef.current?.contains(el);
      const clickPopper = popperRef.current?.contains(el);
      if (isOpen && !clickInput && !clickPopper) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', clickHandler, true);
    return () => document.removeEventListener('click', clickHandler);
  }, [isOpen]);

  return (
    <div ref={wrapperRef} className={className}>
      <InputField
        inputRef={inputRef}
        label={label}
        registerName={registerName}
        required={required}
        onFocus={() => setIsOpen(true)}
        onChange={(e) => onChange?.(e.target.value)}
      ></InputField>
      <Popper
        className={styles.popper}
        ref={popperRef}
        anchor={inputRef}
        open={isOpen && !!options?.length}
        options={{
          placement: 'auto-start',
        }}
      >
        <Menu>
          {options?.map((option: SelectValue) => (
            <MenuItem key={option.id} onClick={() => onListItemClick(option)}>
              {option.name}
            </MenuItem>
          ))}
        </Menu>
      </Popper>
    </div>
  );
}
