import {
  Modal,
  ModalActions,
  ModalBody,
  Typography,
} from '@mezzanine-ui/react';
import { useTargetModal } from '@solar/templates';
import { useCallback } from 'react';
import { MiscellaneousStorageInModals } from '../enum';

export function CancelModal() {
  const modalController = useTargetModal<{ materialId: string }>(
    MiscellaneousStorageInModals.Cancel
  );
  const { open, closeModal, data } = modalController;

  const handleModalClose = useCallback(() => {
    closeModal();
  }, []);

  return (
    <Modal open={open} onClose={handleModalClose}>
      <br />
      <br />
      <ModalBody>
        <Typography variant="h2">是否取消出貨？ </Typography>
        <Typography>{`採購單號 ${data?.materialId ?? ''} `}</Typography>
      </ModalBody>
      <ModalActions
        cancelText="是"
        confirmText="否"
        onCancel={() => {
          handleModalClose();
        }}
        onConfirm={() => {
          handleModalClose();
        }}
      />
    </Modal>
  );
}
