import { IQCOrderType, IncomingPurchaseOrder } from '@solar/data';
import { IQCDeliveryOrderTable } from './IQCDeliveryOrderTable';
import { IQCPurchaseOrderTable } from './IQCPurchaseOrderTable';
import { IQCTableBaseProps } from './incoming-quality-control-table.type';
import { Tab, TabPane, Tabs } from '@mezzanine-ui/react';
import { Key, useState } from 'react';

interface IncomingQualityControlTableProps
  extends IQCTableBaseProps<IncomingPurchaseOrder> {
  iqcOrderType?: IQCOrderType;
  purchaseOrderId?: string;
  purchaseOrderType?: string;
  deliveryOrderId?: string;
}

export function IncomingQualityControlTable({
  iqcOrderType,
  purchaseOrderId,
  purchaseOrderType,
  deliveryOrderId,
  statusIds,
  getColumns,
  expandable,
}: IncomingQualityControlTableProps) {
  const [activeKey, setActiveKey] = useState<Key>(IQCOrderType.DELIVERY_ORDER);

  if (iqcOrderType === IQCOrderType.PURCHASE_ORDER) {
    return (
      <IQCPurchaseOrderTable
        iqcOrderType={IQCOrderType.PURCHASE_ORDER}
        purchaseOrderId={purchaseOrderId}
        purchaseOrderType={purchaseOrderType}
        statusIds={statusIds}
        getColumns={getColumns}
        expandable={expandable}
      />
    );
  }

  if (iqcOrderType === IQCOrderType.DELIVERY_ORDER) {
    return (
      <IQCDeliveryOrderTable
        iqcOrderType={IQCOrderType.DELIVERY_ORDER}
        deliveryOrderId={deliveryOrderId}
        statusIds={statusIds}
        getColumns={getColumns}
        expandable={expandable}
      />
    );
  }

  return (
    <Tabs activeKey={activeKey} onChange={setActiveKey}>
      <TabPane key={IQCOrderType.DELIVERY_ORDER} tab={<Tab>出貨單</Tab>}>
        <IQCDeliveryOrderTable
          iqcOrderType={IQCOrderType.DELIVERY_ORDER}
          deliveryOrderId={deliveryOrderId}
          statusIds={statusIds}
          getColumns={getColumns}
          expandable={expandable}
        />
      </TabPane>
      <TabPane key={IQCOrderType.PURCHASE_ORDER} tab={<Tab>採購單</Tab>}>
        <IQCPurchaseOrderTable
          iqcOrderType={IQCOrderType.PURCHASE_ORDER}
          purchaseOrderId={purchaseOrderId}
          purchaseOrderType={purchaseOrderType}
          statusIds={statusIds}
          getColumns={getColumns}
          expandable={expandable}
        />
      </TabPane>
    </Tabs>
  );
}
