import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { GROUP_CODE, useMaterialHierarchy } from '../use-material-hierarchy';
import styles from './viewer.module.scss';
import { maxBy } from 'lodash';
import { Button, SelectValue, Tag, cx } from '@mezzanine-ui/react';
import { Group, ResultDetail } from '../typings';
import { COLORS } from '../colors';
import { MaterialViewerTable, MaterialViewerTableFilter } from './MaterialViewerTable';
import { useForm } from 'react-hook-form';
import { MaterialPropsFilter, defaultValues } from './MaterialPropsFilter';
import { GetMaterialResolvesParams, MaterialResolvesFilter, NextPagination, OffsetBased, getNextParams, useGetMaterialResolves } from '@solar/data';

export function MaterialViewer() {
  const hierarchy = useMaterialHierarchy();

  const [value, onChange] = useState<string>('');

  const prefixLabels = useMemo(() => GROUP_CODE[value.substring(0, 1)]?.prefixLabels ?? [], [value]);
  const suffixKeys = useMemo(() => (GROUP_CODE[value.substring(0, 1)]?.suffixKeys ?? [])
    .map((suffixKey) => ({
      ...suffixKey,
      ...(typeof suffixKey.getSize === 'function' ? {
        size: suffixKey.getSize(value),
      } : {}),
    }))
    .filter((suffixKey) => suffixKey.size), [value]);

  const maxMatchSize = useMemo<number>(() => {
    if (!value) return 0;

    return maxBy(
      Array.from(hierarchy.keys()).filter((key) => key.startsWith(value.slice(0, 1))),
      (key) => key.length,
    )?.length ?? 0
  }, [hierarchy, value]);

  const suffixKeyOptions = useMemo(() => suffixKeys.reduce(({ cursor, results }, { label, size }) => ({
    cursor: cursor + size,
    results: [
      ...results,
      {
        label,
        cursor,
        length: size,
        fullMatchLength: cursor + size,
      },
    ],
  }), {
    cursor: maxMatchSize,
    results: [] as {
      label: string;
      cursor: number;
      length: number;
      fullMatchLength: number;
    }[],
  }).results, [maxMatchSize, suffixKeys]);

  const sortedKeys = useMemo(() => Array.from(hierarchy.keys())
    .sort((keyA, keyB) => keyA.length - keyB.length), [hierarchy]);

  const reversedKeys = useMemo(() => Array.from(sortedKeys).reverse(), [sortedKeys]);

  const matchedKey = useMemo<string | null>(() => {
    if (!value || !maxMatchSize) return null;

    const findCircular = (key: string): string | null => {
      if (!key) return null;

      return hierarchy.has(key) ? key : findCircular(key.substring(0, key.length - 1));
    };

    const strippedValue = value.substring(0, maxMatchSize);

    return sortedKeys.find(key => key.startsWith(strippedValue)) ?? findCircular(value.substring(0, value.length - 1));
  }, [hierarchy, maxMatchSize, sortedKeys, value]);

  const prefixMatched = useMemo<ResultDetail | null>(() => matchedKey ? (hierarchy.get(matchedKey) ?? null) : null, [hierarchy, matchedKey]);

  const maxMaterialLength = useMemo(() => {
    if (!suffixKeyOptions.length) {
      const strippedValue = value.substring(0, maxMatchSize);

      return reversedKeys.find(key => key.startsWith(strippedValue))?.length ?? 1;
    }

    const lastKey = suffixKeyOptions[suffixKeyOptions.length - 1];

    if (!lastKey) return 1;

    return lastKey.cursor + lastKey.length;
  }, [suffixKeyOptions, value, maxMatchSize, reversedKeys]);

  const hasError = useMemo(() => value.length <= maxMatchSize && (matchedKey?.length ?? 0) < value.length, [matchedKey?.length, maxMatchSize, value.length]);

  const isFullMatch = useMemo(() => {
    if (matchedKey?.length === value.length) return true;
    if (suffixKeyOptions.some(key => key.fullMatchLength === value.length)) return true;

    return false;
  }, [matchedKey?.length, value, suffixKeyOptions]);

  const nextLevelKeys = useMemo<Record<string, Group> | undefined>(() => {
    if (!prefixMatched) return GROUP_CODE;

    if (value.length > maxMatchSize) return undefined;

    if (isFullMatch && !hasError) return prefixMatched.nextLevel;

    const previousHierarchy = prefixMatched.hierarchy[prefixMatched.hierarchy.length - (hasError ? 1 : 2)];

    if (!previousHierarchy) return undefined;

    const previousKey = value.substring(0, previousHierarchy.cursor + previousHierarchy.length);

    return hierarchy.get(previousKey)?.nextLevel;
  }, [hierarchy, isFullMatch, maxMatchSize, prefixMatched, value, hasError]);

  const maxLength = useMemo(() => hasError ? value.length : maxMaterialLength, [hasError, maxMaterialLength, value.length]);

  const availableLength = useMemo(() => {
    const matchedLatestHierarchy = prefixMatched?.hierarchy[prefixMatched?.hierarchy.length - 1];
    const matchedMaxSize = (matchedLatestHierarchy?.cursor ?? 0) + (matchedLatestHierarchy?.length ?? 0);

    if (matchedMaxSize === maxMatchSize) return maxMaterialLength;

    return hasError ? maxLength : matchedMaxSize;
  }, [maxMatchSize, hasError, maxLength, prefixMatched?.hierarchy, maxMaterialLength]);

  useEffect(() => {
    if (value.length > availableLength) {
      onChange(value.substring(0, availableLength));
    }
  }, [availableLength, value]);

  const tableFilterMethods = useForm<MaterialViewerTableFilter>();

  const {
    materialResolves,
    isLoading,
    pageInfo,
    refetchGetMaterialResolves,
  } = useGetMaterialResolves();

  const methods = useForm<MaterialResolvesFilter>({
    defaultValues
  });

  const refetchMaterialResolvesWithPagination = useCallback((nextPagination: NextPagination) => {
    const formState = methods.getValues();
    const { offset, limit } = getNextParams(nextPagination, pageInfo);
    refetchGetMaterialResolves({
      groupCode: formState?.groupCode?.id ?? '',
      bigClassCode: formState?.bigCode?.id ?? '',
      mediumClassCode: formState?.mediumCode?.id ?? '',
      smallClassCode: formState?.smallCode?.id ?? '',
      materialSourceCode: formState?.sourceCode?.id ?? '',
      componentCode: formState?.componentCode?.id ?? '',
      featureCodeOne: formState?.featureCodeOne?.id ?? '',
      featureCodeTwo: formState?.featureCodeTwo?.id ?? '',
      machiningCode: formState?.machiningCode?.id ?? '',
      samplingCode: formState?.samplingCode?.id ?? '',
      wasteCode: formState?.wasteCode?.id ?? '',
      drawingCode: formState?.drawingCode ?? '',
      sizeCodeOne: formState?.sizeCodeOne ?? '',
      sizeCodeTwo: formState?.sizeCodeTwo ?? '',
      sizeCodeThree: formState?.sizeCodeThree ?? '',
      packagingCode: formState?.packagingCode ?? '',
      idPrefix: "",
      offset,
      limit,
    });
  }, [methods, pageInfo, refetchGetMaterialResolves]); 

  return (
    <div className={styles.wrapper}>
      {/* <div className={styles.inputBox}>
        <div className={styles.inputWrapper}>
          <span className={cx(styles.viewer, !prefixMatched && styles.failed)}>
            {prefixMatched ? (
              <>
                {prefixMatched.hierarchy.map((key, index) => (
                  <span key={key.cursor} data-color={COLORS[index]} style={{ color: COLORS[index] }}>
                    {
                      (value.length - 1) >= (key.cursor + key.length) ?
                        value.substring(key.cursor, key.length + key.cursor)
                        : (
                          <>
                            {value.substring(key.cursor, key.length + key.cursor)}
                            <span className={styles.suggestion}>
                              {matchedKey?.slice(value.length)}
                            </span>
                            {isFullMatch ? null : (
                              <span className={styles.nowSectionHint}>
                                {prefixLabels[index] ?? ''}
                              </span>
                            )}
                          </>
                        )
                    }
                  </span>
                ))}
                {isFullMatch && Object.values((prefixMatched.nextLevel ?? {})).length ? (
                  <>
                    <span className={styles.suggestion}>
                      {Object.keys((prefixMatched.nextLevel ?? {}))[0]}
                    </span>
                    <span className={styles.nowSectionHint}>
                      {prefixLabels[prefixMatched.hierarchy.length] ?? ''}
                    </span>
                  </>
                ) : null}
                {hasError ? (
                  <>
                    <span className={styles.failed}>
                      {value.slice(matchedKey?.length ?? 0)}
                    </span>
                    <span className={cx(styles.nowSectionHint, styles.failed)}>
                      錯誤
                    </span>
                  </>
                ) : null}
                {value.length >= maxMatchSize ? (
                  <>
                    {suffixKeyOptions.map((key, index) => {
                      const content = value.substring(key.cursor, key.length + key.cursor);

                      if (!content) return null;

                      return (
                        <Fragment key={key.cursor}>
                          <span data-color={COLORS[index + prefixMatched.hierarchy.length]} style={{ color: COLORS[index + prefixMatched.hierarchy.length] }}>
                            {content}
                          </span>
                          {content.length < key.length ? (
                            <>
                              <span className={styles.suggestion}>
                                {Array.from(Array(key.length - content.length)).map(() => '0').join('')}
                              </span>
                              <span className={styles.nowSectionHint}>
                                {`${key.label} (${key.length})`}
                              </span>
                            </>
                          ) : null}
                        </Fragment>
                      );
                    })}
                    {isFullMatch ? (
                      <>
                        <span className={styles.suggestion}>
                          {Array.from(Array(suffixKeyOptions.find(key => key.fullMatchLength > value.length)?.length ?? 0))
                            .map(() => '0').join('')
                          }
                        </span>
                        <span className={styles.nowSectionHint}>
                          {suffixKeyOptions.find(key => key.fullMatchLength > value.length)?.label ?? ''}
                        </span>
                      </>
                    ) : null}
                  </>
                ) : null}
              </>
            ) : (
              <>
                {value}
                {value.length ? (
                  <span className={cx(styles.nowSectionHint, styles.failed)}>
                    錯誤
                  </span>
                ) : (
                  <span className={styles.nowSectionHint}>
                    物料類型
                  </span>
                )}
              </>
            )}
          </span>
          <input
            autoFocus
            value={value}
            maxLength={maxLength}
            onChange={({ target }) => onChange((target.value ?? '').trim().replace(/[^0-9a-z]/ig, '').toUpperCase())}
            placeholder="請輸入料號"
            className={styles.input}
            type="text" />
        </div>
        <Button
          onClick={() => tableFilterMethods.setValue('idPrefix', value)}
          disabled={!value.length}
          size="large"
          variant="contained"
          type="button">
          查詢
        </Button>
      </div>
      
      <div className={styles.matchedKeysWrapper}>
        {(prefixMatched?.hierarchy ?? []).map((key, index) => (
          <Tag key={key.cursor} data-color={COLORS[index]}>{key.name}</Tag>
        ))}
        {prefixMatched && value.length > maxMatchSize ? (
          <>
            {suffixKeyOptions.map((key, index) => {
              const content = value.substring(key.cursor, key.length + key.cursor);

              if (!content) return null;

              return (
                <Tag key={key.cursor} data-color={COLORS[index + prefixMatched.hierarchy.length]}>
                  {`${key.label} (${key.length})`}
                  ：
                  {content}
                </Tag>
              );
            })}
          </>
        ) : null}
      </div>
      {nextLevelKeys ? (
        <div className={styles.nextLevelKeysWrapper}>
          {Object.entries(nextLevelKeys).map(([key, item]) => (
            <span className={styles.nextLevelKey} key={key}>
              <span className={styles.label}>{key}</span>
              {item.name}
            </span>
          ))}
        </div>
      ) : null} */}
      <MaterialPropsFilter
        methods={methods}
        refetchGetMaterialResolves={refetchGetMaterialResolves} />
      <MaterialViewerTable
        methods={tableFilterMethods}
        dataSource={materialResolves ?? []}
        pageInfo={pageInfo ?? {}}
        fetchData={refetchMaterialResolvesWithPagination}
        isLoading={isLoading} />
    </div>
  );
}
