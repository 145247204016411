import { Drawing, deleteDrawing, useDrawingRefs, usePresignedFileLink } from "@solar/data";
import { Button, Descriptions, Modal, message } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from './drawing-detail.module.scss';
import { useEffect, useRef, useState } from "react";
import { init, ECharts } from "echarts";
import { Typography } from "@mezzanine-ui/react";
import { PDFPreviewer } from "@solar/templates";

enum DrawingChartCategory {
  parent,
  current,
  children,
}

function RenderBool(bool?: boolean) {
  if (bool === undefined || bool === null) return '';
  return bool ? 'O' : 'X';
}

export function DrawingDetailPage() {
  const [graphChart, setGraphChart] = useState<ECharts>();
  const ref = useRef<HTMLDivElement>(null);
  const graphChartRef = useRef<HTMLDivElement>(null);
  const params = useParams();
  const navigate = useNavigate();

  const { drawingRefs } = useDrawingRefs(params);
  const currentDrawing = drawingRefs?.me;
  const { fileLink } = usePresignedFileLink({});

  useEffect(() => {
    if (ref?.current) {
      ref?.current?.parentElement?.parentElement?.setAttribute('colspan', '8');
    }
  }, [ref]);

  useEffect(() => {
    if (graphChartRef?.current) {
      setGraphChart(init(graphChartRef.current, 'dark', {
        width: 640,
        height: 480,
        renderer: 'svg',
        devicePixelRatio: 2,
      }));
    }
  }, [graphChartRef]);

  useEffect(() => {
    graphChart?.showLoading();
    if (graphChart && drawingRefs) {
      graphChart.hideLoading();
      const parentTotalCount = drawingRefs?.parentDrawings?.length ?? 0;
      const childrenTotalCount = drawingRefs?.childDrawings?.length ?? 0;
  
      const getNodeX = (category: DrawingChartCategory.parent | DrawingChartCategory.current | DrawingChartCategory.children) => {
        const spacing = 4;
        switch (category) {
          case DrawingChartCategory.parent: return -spacing;
          case DrawingChartCategory.children: return spacing;
          default: return 0;
        }
      }

      const getNodeY = (category: DrawingChartCategory.parent | DrawingChartCategory.current | DrawingChartCategory.children, n: number) => {
        const spacing = 3;
        switch (category) {
          case DrawingChartCategory.parent:
            return ((parentTotalCount - 1) / 2 - n) * spacing;
          case DrawingChartCategory.children:
            return ((childrenTotalCount - 1) / 2 - n) * spacing;
          default: return 0;
        }
      }

      const transformDrawingToNode = (category: DrawingChartCategory.parent | DrawingChartCategory.current | DrawingChartCategory.children) => (d: Drawing, index?: number) => ({
        id: d?.newDrawingCode,
        name: d?.newDrawingCode,
        category,
        fixed: false,
        value: d?.oldDrawingFixed,
        x: getNodeX(category),
        y: getNodeY(category, index ?? 0),
        label: { show: true },
      });

      const transformDrawingToLink = (category: DrawingChartCategory.parent | DrawingChartCategory.children, currentDrawing: Drawing) => (d: Drawing) => {
        switch (category) {
          case DrawingChartCategory.parent: {
            const source = d?.newDrawingCode;
            const target = currentDrawing?.newDrawingCode;
            return (source === target) ? undefined : { source, target };
          }
          case DrawingChartCategory.children: {
            const source = currentDrawing?.newDrawingCode;
            const target = d?.newDrawingCode;
            return (source === target) ? undefined : { source, target };
          }
          default:
            return undefined;
        }
      }

      const nodes = [
        ...[
          drawingRefs.parentDrawings.map(transformDrawingToNode(DrawingChartCategory.parent)),
          drawingRefs.childDrawings.map(transformDrawingToNode(DrawingChartCategory.children)),
        ].flat().filter((node) => node?.id !== drawingRefs?.me?.newDrawingCode),
        transformDrawingToNode(DrawingChartCategory.current)(drawingRefs.me),
      ];

      const links = [
        ...drawingRefs.parentDrawings.map(transformDrawingToLink(DrawingChartCategory.parent, drawingRefs.me)).filter(Boolean),
        ...drawingRefs.childDrawings.map(transformDrawingToLink(DrawingChartCategory.children, drawingRefs.me)).filter(Boolean),
      ];

      const categories = [
        { id: DrawingChartCategory.parent, name: '母圖號' },
        { id: DrawingChartCategory.current, name: '當前圖號' },
        { id: DrawingChartCategory.children, name: '子圖號' },
      ];
      graphChart.setOption({
        title: {
          text: '新舊圖號母子圖',
          top: 'bottom',
          left: 'right'
        },
        zoom: 0.1,
        tooltip: {},
        legend: [
          {
            // selectedMode: 'single',
            data: categories.map((c) => c?.name),
          }
        ],
        animationDuration: 1500,
        animationEasingUpdate: 'quinticInOut',
        series: [
          {
            name: '新舊圖號',
            type: 'graph',
            layout: 'none',
            data: nodes,
            links,
            categories,
            roam: true,
            symbolSize: 20,
            label: {
              position: 'right',
              formatter: '{b}'
            },
            lineStyle: {
              color: 'source',
              curveness: 0.3
            },
            emphasis: {
              focus: 'adjacency',
              lineStyle: {
                width: 5
              }
            }
          }
        ]
      });
    }
  }, [graphChart, drawingRefs]);

  return (
    <div className={styles.page_content}>
      <Descriptions
        bordered
        column={3}
        size="small"
        title={<Typography variant="h1">{currentDrawing?.newDrawingCode}</Typography>}
        style={{ borderRadius: 0 }}
        labelStyle={{ width: 140 }}
        extra={(
          <div style={{ display: 'flex', gap: 'var(--mzn-spacing-4)'}}>
            <Button>
              <Link to="/drawings">返回列表</Link>
            </Button>
            <Button type="primary">
              <Link to={`/drawings/${currentDrawing?.newDrawingCode}/update`}>編輯</Link>
            </Button>
            <Button
              danger
              type="primary"
              onClick={() => {
                Modal.confirm({
                  title: '確認刪除',
                  okText: '確認',
                  cancelText: '取消',
                  onOk: async () => {
                    if (currentDrawing?.id) {
                      const response = await deleteDrawing({ id: currentDrawing?.id });
                      if (response?.code === 200) {
                        message.success('刪除成功');
                        navigate('/drawings');
                      } else {
                        message.error('刪除失敗');
                      }
                    }
                  },
                })
              }}
            >
              刪除
            </Button>
          </div>
        )}>
        <Descriptions.Item label="檢查人">{currentDrawing?.checker || ''}</Descriptions.Item>
        <Descriptions.Item label="完成別">{currentDrawing?.drawingCompletedStatus?.name || ''}</Descriptions.Item>
        <Descriptions.Item label="類型">{currentDrawing?.drawingType?.name || ''}</Descriptions.Item>
        <Descriptions.Item label="修正後舊圖號">{currentDrawing?.oldDrawingFixed || ''}</Descriptions.Item>
        <Descriptions.Item label="舊圖號">{currentDrawing?.parentDrawingCode || ''}</Descriptions.Item>
        <Descriptions.Item label="背板來源">{currentDrawing?.backplateSource || ''}</Descriptions.Item>
        <Descriptions.Item label="新圖號">{currentDrawing?.newDrawingCode || ''}</Descriptions.Item>
        <Descriptions.Item span={2} labelStyle={{ display: 'none' }}>
          <div ref={ref}>
            <Descriptions bordered column={3} size="small" style={{ borderRadius: 0 }} labelStyle={{ width: 140 }}>
              <Descriptions.Item label="組合碼">
                {currentDrawing?.drawingCompositeCode?.code || ''}
                （{currentDrawing?.drawingCompositeCode?.name || ''}）
              </Descriptions.Item>
              <Descriptions.Item label="拼數" span={2}>
                {currentDrawing?.compositeNumber}
              </Descriptions.Item>
              <Descriptions.Item label="形狀碼" span={3}>
                {currentDrawing?.drawingShapeCode?.code || ''}
                （{currentDrawing?.drawingShapeCode?.name || ''}）
              </Descriptions.Item>
              <Descriptions.Item label="尺寸一碼" span={3}>{currentDrawing?.sizeCodeOne || ''}</Descriptions.Item>
              <Descriptions.Item label="噴砂碼">{currentDrawing?.isSandblasting ? 'S' : 'Z'}</Descriptions.Item>
              <Descriptions.Item label="噴砂" span={2}>{RenderBool(currentDrawing?.isSandblasting)}</Descriptions.Item>
              <Descriptions.Item label="鑽孔分階">{currentDrawing?.drawingDrillLayeringCode?.code || ''}</Descriptions.Item>
              <Descriptions.Item label="鑽孔">{RenderBool(currentDrawing?.isDrilling)}</Descriptions.Item>
              <Descriptions.Item label="分階">{RenderBool(currentDrawing?.isLayering)}</Descriptions.Item>
              <Descriptions.Item label="開槽碼">{currentDrawing?.drawingSlottingCode?.code || ''}</Descriptions.Item>
              <Descriptions.Item label="表面開槽">{RenderBool(currentDrawing?.isSurfaceSlotting)}</Descriptions.Item>
              <Descriptions.Item label="側面開槽">{RenderBool(currentDrawing?.isSideSlotting)}</Descriptions.Item>
              <Descriptions.Item label="流水三碼" span={3}>{currentDrawing?.serialString || ''}</Descriptions.Item>
            </Descriptions>
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="尺寸一碼">{currentDrawing?.sizeCodeOne || ''}</Descriptions.Item>
        <Descriptions.Item label="尺寸二碼">{currentDrawing?.sizeCodeTwo || ''}</Descriptions.Item>
        <Descriptions.Item label="尺寸三碼">{currentDrawing?.sizeCodeThree || ''}</Descriptions.Item>
        <Descriptions.Item label="長或外徑(mm)">{currentDrawing?.sizeLength || ''}</Descriptions.Item>
        <Descriptions.Item label="寬或內徑(mm)">{currentDrawing?.sizeWidth || ''}</Descriptions.Item>
        <Descriptions.Item label="高(mm)">{currentDrawing?.sizeHeight || ''}</Descriptions.Item>
        <Descriptions.Item label="倒角">{RenderBool(currentDrawing?.isChamfer)}</Descriptions.Item>
        <Descriptions.Item label="刻字">{RenderBool(currentDrawing?.isLettering)}</Descriptions.Item>
        <Descriptions.Item label="攻牙">{RenderBool(currentDrawing?.isTapping)}</Descriptions.Item>
        <Descriptions.Item span={3} label="備註">{currentDrawing?.remark}</Descriptions.Item>
        <Descriptions.Item span={3} label="母圖" >
          <div style={{display: 'flex', gap: '12px' }}>
            {drawingRefs?.parentDrawings?.map((drawing) => <Link to={`/drawings/${drawing?.newDrawingCode}`}>{drawing?.newDrawingCode}</Link>)}
          </div>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="子圖" >
          <div style={{display: 'flex', gap: '12px' }}>
            {drawingRefs?.childDrawings?.map((drawing) => <Link to={`/drawings/${drawing?.newDrawingCode}`}>{drawing?.newDrawingCode}</Link>)}
          </div>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="關係圖" >
          <div ref={graphChartRef} />
        </Descriptions.Item>
        <Descriptions.Item span={3} label="藍圖">
          <PDFPreviewer fileLink={fileLink} />
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}