const intRegex = /[0-9]/;
const floatRegex = /^(\d*\.?\d*)$/;

export const restrictNumeric = ({
  event,
  validateMode,
}: {
  event: React.KeyboardEvent<HTMLInputElement>;
  validateMode: 'int' | 'float';
}) => {
  const validateRule = validateMode === 'int' ? intRegex : floatRegex;

  if (
    !validateRule.test(
      event.key +
        (validateMode === 'float' ? (event?.target as any)?.value : '')
    )
  ) {
    event.preventDefault();
  }
};
