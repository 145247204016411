import { Input, SelectValue } from '@mezzanine-ui/react';
import { DateRangePickerField, FormFieldsWrapper, SelectField } from '@mezzanine-ui/react-hook-form';
import { Equipment, useDebounce, useEquipmentCategories, useGetDeptCodes } from '@solar/data';
import { UseFormReturn } from 'react-hook-form';
import styles from './equipment-list-filter.module.scss';
import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { LocationIdsField } from '@solar/templates';

export type EquipmentListFilterForm = {
  categories?: SelectValue[];
  installedAt?: [string, string];
  deptCodes?: SelectValue[];
  equipments?: Equipment[];
  searchTerm?: string;
  locationIds?: string[];
};

type EquipmentListFilterProps = {
  methods: UseFormReturn<EquipmentListFilterForm>,
  openLocationSelectorModal: () => void,
};

export function EquipmentListFilter({ methods, openLocationSelectorModal }: EquipmentListFilterProps) {
  const [searchText, setSearchText] = useState('');
  // const { categories } = useEquipmentCategories();
  const { deptCodes } = useGetDeptCodes();

  const searchTerm = useDebounce<string>(searchText, 700);

  useEffect(() => methods.setValue('searchTerm', searchTerm), [methods, searchTerm]);

  return (
    <FormFieldsWrapper
      methods={methods}
      className={styles.filter_form_wrapper}
    >
      <Row gutter={[8, 8]}>
        {/* <Col span={8}>
          <SelectField
            label="清單分類："
            mode="multiple"
            registerName="categories"
            options={categories ?? []} />
        </Col> */}
        <Col span={8}>
          <DateRangePickerField
            fullWidth
            label="裝機區間："
            registerName="installedAt" />
        </Col>
        <Col span={8}>
          <Input
            fullWidth
            placeholder="請輸入工作中心或資產編號關鍵字"
            value={searchText}
            onChange={(event) => setSearchText(event?.target?.value)} />
        </Col>
        <Col span={8}>
          <SelectField
            label="管理單位："
            registerName="deptCodes"
            mode="multiple"
            options={deptCodes?.map((code) => ({ id: code?.deptCode, name: code?.deptName })) ?? []} />
        </Col>
        <Col span={8}>
          <LocationIdsField
            methods={methods}
            registerName="locationIds"
            openModal={openLocationSelectorModal} />
        </Col>
      </Row>
    </FormFieldsWrapper>
  );
}
