import { Button, Icon, cx } from '@mezzanine-ui/react';
import './collapsible-container.scss';
import React, { useEffect, useRef, useState } from 'react';
import { ReactNode } from 'react';
import { CaretRightIcon } from '@mezzanine-ui/icons';

interface ICollapsibleContainerProps {
  open?: boolean;
  header?: string | React.ReactNode;
  headerClassName?: string;
  titleClassName?: string;
  iconButtonClassName?: string;
  contentClassName?: string;
  contentContainerClassName?: string;
  collapsibleClassName?: string;
  children: ReactNode;
  enableRowBlockStyle?: boolean;
}

export const CollapsibleContainer = ({
  open,
  collapsibleClassName = 'collapsible-card',
  headerClassName = 'collapsible-header',
  titleClassName = 'title-text',
  iconButtonClassName = 'collapsible-icon-button',
  contentClassName = 'collapsible-content',
  contentContainerClassName = 'collapsible-content-padding',
  children,
  header,
  enableRowBlockStyle = false,
}: ICollapsibleContainerProps) => {
  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState<number | undefined>(
    open ? undefined : 0
  );
  const ref = useRef<HTMLDivElement>(null);
  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined;

    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height);
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);

  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);

  return (
    <div
      className={cx(
        collapsibleClassName,
        enableRowBlockStyle ? 'row-block' : ''
      )}
    >
      <div className={headerClassName}>
        <div className={titleClassName}>{header}</div>
        <Button
          type="button"
          className={iconButtonClassName}
          prefix={
            <Icon
              color="primary"
              icon={CaretRightIcon}
              className={`fas fa-chevron-down ${
                isOpen ? 'rotate-center down' : 'rotate-center up'
              }`}
            />
          }
          onClick={handleFilterOpening}
        >
          {isOpen ? '收合' : '展開'}
        </Button>
      </div>
      <div className={contentClassName} style={{ height }}>
        <div ref={ref}>
          <div className={contentContainerClassName}>{children}</div>
        </div>
      </div>
    </div>
  );
};
