import { TableColumn } from '@mezzanine-ui/core/table';
import { Button, Message, SelectValue } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  getNextParams,
  NextPagination,
  WorkOrders,
  WorkOrdersRequestParams,
  WORK_ORDERS_STATES,
  SyncOutsourcePurchaseOrdersFromSAPParams,
  syncOutsourcePurchaseOrdersFromSAP,
  useOutsourcePurchaseOrders,
} from '@solar/data';
import {
  FilterScaffold,
  MznPaginationTable,
  PageLayout,
} from '@solar/templates';
import moment from 'moment';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { tabIds } from './const';
import { Filter } from './Filter';

type FormProps = Omit<
  WorkOrdersRequestParams,
  | 'createdAtDateFrom'
  | 'createdAtDateTo'
  | 'expectedCompletedAtDateFrom'
  | 'expectedCompletedAtDateTo'
  | 'factoryId'
  | 'orderTypeCode'
  | 'sortType'
  | 'states'
> & {
  createdDateRange: [string, string];
  expectedCompletedDateRange: [string, string];
  factories: SelectValue[];
  orderTypes: SelectValue[];
  sortType: SelectValue;
  states: SelectValue[];
};

export function OutsourcePurchaseOrderPage() {
  const [syncing, setSyncing] = useState(false);
  const methods = useForm<FormProps>();
  const navigate = useNavigate();

  const { orders, mutateParams, isLoading, pageInfo, refetchOrders } =
    useOutsourcePurchaseOrders({});

  const handleSyncSap = useCallback(
    async (param: SyncOutsourcePurchaseOrdersFromSAPParams) => {
      setSyncing(true);
      try {
        const res = await syncOutsourcePurchaseOrdersFromSAP(param);

        if (res.ok) {
          Message.success('同步成功');
        }

        await refetchOrders(undefined, true);
      } catch (err) {
        if (err instanceof Error) {
          Message.error(JSON.parse(err.message).message);
        } else {
          Message.error('同步失敗');
        }
      } finally {
        setSyncing(false);
      }
    },
    []
  );

  const refetchProductionDeliveryOrders = useCallback(
    (nextPagination: NextPagination) => {
      const filterState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);

      mutateParams({
        offset,
        limit,
        searchTerm: filterState?.searchTerm,
        dateFrom: (filterState.createdDateRange ?? [])[0],
        dateTo: (filterState.createdDateRange ?? [])[1],
        expectedCompletedAtDateFrom: (filterState.expectedCompletedDateRange ??
          [])[0],
        expectedCompletedAtDateTo: (filterState.expectedCompletedDateRange ??
          [])[1],
        sortType: filterState?.sortType
          ?.id as WorkOrdersRequestParams['sortType'],
        states: filterState?.states?.map(
          (type) => type.id as keyof typeof WORK_ORDERS_STATES
        ),
        // filteredOrderTypeCodes: ['1O', '2O', '3O'],
      });
    },
    [methods]
  );

  const columns: TableColumn<WorkOrders>[] = [
    {
      title: '操作',
      render: (source) => (
        <Button
          variant="outlined"
          type="button"
          onClick={() =>
            navigate({
              pathname: `${source['id']}`,
              search: qs.stringify({ tab: tabIds[0] }),
            })
          }
        >
          檢視
        </Button>
      ),
    },
    {
      title: '狀態',
      dataIndex: 'workOrderStatusName',
    },
    {
      title: '建立日期',
      render: (source) =>
        source?.createdAt ? moment(source?.createdAt).format('YYYY/MM/DD') : '',
    },
    { title: '工廠', dataIndex: 'items[0].components[0].factoryId' },
    // { title: '工單類型', dataIndex: 'orderTypeName' },
    { title: '採購單號', dataIndex: 'id' },
    // {
    //   title: '訂單交期',
    //   dataIndex: 'expectedDeliveryDate',
    //   render: (source) =>
    //     source?.expectedDeliveryDate
    //       ? moment(source?.expectedDeliveryDate).format('YYYY/MM/DD')
    //       : '',
    // },
    // { title: '銷售訂單號', dataIndex: 'salesOrderId' },
    // { title: '銷售訂單項目', dataIndex: 'salesOrderLineId' },
    { title: '目標料號', dataIndex: 'items[0].materialId', width: 300 },
    {
      title: '目標品名',
      dataIndex: 'items[0].materialDescription',
      width: 300,
    },
    // { title: 'MRP 控制員', dataIndex: 'mrpControllerName' },
    {
      title: '預期產出數量',
      dataIndex: 'items[0].expectedMaterialStockUnitQuantity',
    },
    { title: '單位', dataIndex: 'items[0].materialStockUnit' },
    {
      title: '已入庫數量',
      dataIndex: 'items[0].receivedMaterialStockUnitQuantity',
    },
    { title: '單位', dataIndex: 'items[0].materialStockUnit' },
    { title: '採購群組', dataIndex: 'TBD' },
  ];

  return (
    <PageLayout title="委外採購單">
      <div>
        <Button
          type="button"
          variant="outlined"
          loading={syncing}
          onClick={() => {
            const dateTo = moment().format('YYYYMMDD');
            const dateFrom = moment().subtract(6, 'd').format('YYYYMMDD');
            handleSyncSap({
              dateFrom,
              dateTo,
            });
          }}
        >
          重新整理
        </Button>
      </div>
      <FormFieldsWrapper methods={methods}>
        <FilterScaffold
          mutateParams={refetchProductionDeliveryOrders}
          fetchAsParamsMutated={false}
        >
          <Filter />
          <br />
          <Button
            type="button"
            variant="outlined"
            onClick={() => refetchProductionDeliveryOrders({ nextPage: 1 })}
          >
            確認
          </Button>
        </FilterScaffold>
      </FormFieldsWrapper>
      <MznPaginationTable
        scroll={{ x: 2500 }}
        loading={isLoading && syncing}
        columns={columns}
        dataSource={orders ?? []}
        fetchData={refetchProductionDeliveryOrders}
        pageInfo={pageInfo}
      />
    </PageLayout>
  );
}
