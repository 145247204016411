import { DownloadIcon } from '@mezzanine-ui/icons';
import { Button, Icon, Message, Typography } from '@mezzanine-ui/react';
import { ERFCState, FieldsType, ResponseStatus, useOrders } from '@solar/data';
import { Pagination, Table } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { FilterSearch } from '../FilterSearch/FilterSearch';
import styles from './OrderManagementPage.module.scss';
import { OrderDetailLine } from './dto/order-detail-line.dto';
import { ContractedAmountModal } from './ContractedAmountModal';

export function OrderManagementPage() {
  const {
    rfcState,
    setRfcState,
    data: orderManageData,
    total,
    loading,
    currentPage,
    setCurrentPage,
    perPage,
    setPerPage,
    handleUpdateRfc,
    filterMutation: filterOrders,
    exportData: exportOrders,
    queryParams: ordersQueryParams,
  } = useOrders();

  const [editingRow, setEditingRow] = useState<OrderDetailLine | null>(null);

  const columns = useMemo(
    () => [
      { title: 'BU', dataIndex: 'bu', width: 50 },
      { title: '訂單編號', dataIndex: 'orderId', width: 100 },
      { title: '項次', dataIndex: 'salesOrderLine', width: 30 },
      { title: '訂單日期', dataIndex: 'customerOrderDate', width: 50 },
      { title: '訂單類型', dataIndex: 'salesOrderType', width: 30 },
      { title: '訂單狀態', dataIndex: 'status', width: 50 },
      { title: '客戶編號', dataIndex: 'customerId', width: 50 },
      { title: '客戶名稱', dataIndex: 'customerName', width: 150 },
      { title: '客戶簡稱', dataIndex: 'customerShortName', width: 50 },
      { title: '客戶地址', dataIndex: 'customerAddress', width: 150 },
      { title: '付款條件', dataIndex: 'paymentTerm', width: 50 },
      { title: '收貨方編號', dataIndex: 'receiverId', width: 50 },
      { title: '收貨方名稱', dataIndex: 'receiverName', width: 150 },
      { title: '收貨方地址', dataIndex: 'receiverAddress', width: 150 },
      { title: '收貨人', dataIndex: 'contact', width: 150 },
      { title: '收貨人電話', dataIndex: 'contactPhone', width: 150 },
      { title: '運送方式', dataIndex: 'conditionsNote', width: 50 },
      { title: '客戶採購單號碼', dataIndex: 'customerPoNumber', width: 100 },
      { title: '指定生管', dataIndex: 'assignedOperationName', width: 100 },
      { title: '預定交期', dataIndex: 'expectedDeliveryDate', width: 100 },
      { title: '料號', dataIndex: 'materialId', width: 100 },
      { title: '客戶料號', dataIndex: 'customerMaterialId', width: 100 },
      { title: '品名', dataIndex: 'materialName', width: 100 },
      { title: '客戶品名', dataIndex: 'customerMaterialName', width: 100 },
      { title: '數量', dataIndex: 'quantity', width: 100 },
      {
        title: '約定純量',
        width: 120,
        render: (row: OrderDetailLine) => (
          <Button onClick={() => setEditingRow(row)} type="button" size="small">
            約定純量
          </Button>
        ),
      },
      { title: '單位', dataIndex: 'quantityUnit', width: 30 },
      { title: '實際毛重', dataIndex: 'grossWeight', width: 100 },
      { title: '工單作業', dataIndex: 'workOrderDescription', width: 150 },
      {
        title: '物料銷售內文',
        dataIndex: 'materialSellingContent',
        width: 100,
      },
      { title: '出貨單號碼', dataIndex: 'deliveryOrderId', width: 100 },
      { title: '出貨日期', dataIndex: 'debitDate', width: 100 },
      { title: '靶編(目前是批號)', dataIndex: 'batchId', width: 100 },
      { title: '出貨倉別', dataIndex: 'locationId', width: 100 },
      { title: '出貨點', dataIndex: 'shipLocationName', width: 100 },
      { title: '出貨廠區', dataIndex: 'shipFactoryName', width: 100 },
      { title: '沖款日期', dataIndex: 'settledDate', width: 100 },
      { title: '帳款逾期日期', dataIndex: 'overdueDate', width: 100 },
      { title: '原幣單價', dataIndex: 'unitPrice', width: 100 },
      { title: '幣別', dataIndex: 'currency', width: 100 },
      { title: '單價台幣', dataIndex: 'unitPriceTwd', width: 100 },
      { title: '發票號碼', dataIndex: 'invoiceNumber', width: 100 },
      { title: '稅率', dataIndex: 'taxRate', width: 100 },
      { title: '匯率', dataIndex: 'exchangeRate', width: 100 },
      { title: '訂單總金額', dataIndex: 'totalPrice', width: 100 },
      { title: '最後變更日期', dataIndex: 'lastUpdatedDate', width: 100 },
      { title: '分析元素-AU', dataIndex: 'zzau', width: 100 },
      { title: '分析元素-AG', dataIndex: 'zzag', width: 100 },
      { title: '分析元素-PT', dataIndex: 'zzpt', width: 100 },
      { title: '分析元素-PD', dataIndex: 'zzpd', width: 100 },
      { title: '分析元素-RU', dataIndex: 'zzru', width: 100 },
      { title: '分析元素-GA', dataIndex: 'zzga', width: 100 },
      { title: '分析元素-IN', dataIndex: 'zzin', width: 100 },
      { title: '分析元素-IR', dataIndex: 'zzir', width: 100 },
      { title: '分析元素-RE', dataIndex: 'zzre', width: 100 },
      { title: '分析元素-RH', dataIndex: 'zzrh', width: 100 },
      { title: '分析元素-NONE', dataIndex: 'zznone', width: 100 },
    ],
    []
  );

  const onExport = async () => {
    const res = await exportOrders();

    if (res === ResponseStatus.SUCCESS) {
      Message.success('匯出成功');
    } else {
      Message.error('失敗，請再試一次');
    }
  };

  const filterLayout = useCallback(
    async (payload: FieldsType[]) => {
      setCurrentPage(1);
      filterOrders(payload);
    },
    [setCurrentPage, filterOrders]
  );

  return (
    <div className={styles.wrapper}>
      {editingRow ? (
        <ContractedAmountModal
          {...editingRow}
          onClose={() => setEditingRow(null)}
        />
      ) : null}
      <div className={styles.title}>
        <Typography variant="h1">訂單管理</Typography>
        <div className={styles.buttonWrapper}>
          <Button
            variant="outlined"
            size="large"
            prefix={<Icon icon={DownloadIcon} />}
            type="button"
            onClick={onExport}
            disabled={ordersQueryParams === ''}
          >
            匯出
          </Button>
          <Button
            style={{
              display: 'none',
            }}
            type="button"
            variant="contained"
            size="large"
            disabled={rfcState === ERFCState.BUSY}
            onClick={() => {
              setRfcState(ERFCState.BUSY);
              Message['info']('更新中，請稍候');
              handleUpdateRfc();
            }}
          >
            更新 RFC
          </Button>
        </div>
      </div>
      <div className={styles.border}></div>
      <div className={styles.filterSearch}>
        <FilterSearch filterMethods={filterLayout} />
      </div>
      <Table
        size="small"
        rootClassName={styles.table}
        dataSource={orderManageData ?? []}
        columns={columns}
        loading={loading}
        scroll={{ x: 5000 }}
        pagination={false}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '12px',
        }}
      >
        <Pagination
          total={total}
          showSizeChanger={true}
          showQuickJumper
          onShowSizeChange={(_current: number, size: number) => {
            setCurrentPage(1);
            setPerPage(size);
          }}
          pageSize={perPage}
          current={currentPage}
          onChange={(page: number) => setCurrentPage(page)}
        />
      </div>
    </div>
  );
}
