/* eslint-disable @typescript-eslint/no-empty-function */
import { ReactNode, createContext, useCallback, useContext, useState } from "react";

type ModalProps = {
  name: string,
  open: boolean,
  data: any,
  keepData: boolean,
};

type OpenModal = <DataType>(name: string, data: DataType) => void;
type CloseModal = <DataType>(name: string, data?: DataType) => void;

type UseModalGroupControllerReturn = {
  modalsProps: ModalProps[];
  getModalProps: (name: string) => ModalProps | undefined;
  openModal: OpenModal;
  closeModal: CloseModal;
};

type UseModalGroupController = {
  name: string,
  keepData?: boolean,
}[];

type ModalGroupProps = UseModalGroupControllerReturn & {
  children: ReactNode;
}

const ModalGroupContext = createContext<UseModalGroupControllerReturn>({
  modalsProps: [],
  getModalProps: () => undefined,
  openModal: () => {},
  closeModal: () => {},
});

/**
 * @description: 使用 `useTargetModal(modalName)` 時，確保該 Modal Component 包在 `<ModalGroup {...modalGroupController}>` 裡，並且 modalName 有在 useModalGroupController([{ name: 'modalName' }]) 中帶入
 * @usage
 * ```tsx
 * export function ModalWrapper() {
 *    const modalGroupController = useModalGroupController([
 *      { name: 'VIEW' },
 *      { name: 'EDIT' },
 *    ]);
 * 
 *    const openViewModal = (something: SomethingType) => modalGroupController.openModal('VIEW', something);
 *    const openEditModal = (something: SomethingType) => modalGroupController.openModal('EDIT', something);
 * 
 *    return (
 *      <div>
 *         <OtherComponent openViewModal={openViewModal} openEditModal={openEditModal} />
 *         <ModalGroup {...modalGroupController}>
 *            <ViewSomethingModal />
 *            <EditSomethingModal onSubmit={console.log} />
 *         </ModalGroup>
 *      </div>
 *    );
 * }
 * 
 * export function ViewSomethingModal() {
 *    const { open, data: something, closeModal } = useTargetModal<SomethingType>('VIEW');
 *    return (
 *        <Modal open={open} onClose={() => closeModal()}>
 *          ...
 *        </Modal>
 *    );
 * }
 * 
 * export function EditSomethingModal({ onSubmit }) {
 *    const { open, data: something, closeModal } = useTargetModal<SomethingType>('EDIT');
 *    return (
 *        <Modal open={open} onClose={() => closeModal()}>
 *          ...
 *        </Modal>
 *    );
 * }
 * ```
 */

export function useModalGroupController(modals: UseModalGroupController) {
  const [modalsProps, setModalsProps] = useState<ModalProps[]>(modals.map((modal) => ({
    name: modal?.name ?? modal,
    open: false,
    data: null,
    keepData: modal?.keepData ?? false,
  })));

  const getModalProps = useCallback((name: string) => modalsProps.find((modalProps) => modalProps.name === name), [modalsProps]); 

  const openModal: OpenModal = (name, data) => setModalsProps((prevModalsProps) =>
    prevModalsProps?.map((prevModalData) => {
      const isTarget = prevModalData.name === name;
      const nextModalData = prevModalData.keepData ? prevModalData?.data : null;
      return {
        name: prevModalData.name,
        open: isTarget,
        data: isTarget ? data : nextModalData,
        keepData: prevModalData?.keepData ?? false,
      };
    })
  );

  const closeModal: CloseModal = (name, data) => setModalsProps((prevModalsProps) =>
    prevModalsProps?.map((prevModalData) => {
      const isTarget = prevModalData?.name === name;
      const nextData = data ?? prevModalData?.data;
      return isTarget ? {
        name: prevModalData?.name,
        open: false,
        data: isTarget && prevModalData?.keepData ? nextData : null,
        keepData: prevModalData?.keepData,
      } : prevModalData;
    })
  );

  return { modalsProps, getModalProps, openModal, closeModal };
}

export function ModalGroup({ modalsProps, getModalProps, openModal, closeModal, children }: ModalGroupProps) {
  return (
    <ModalGroupContext.Provider value={{ modalsProps, getModalProps, openModal, closeModal }}>
      {children}
    </ModalGroupContext.Provider>
  );
}

export function useTargetModal<DataType>(name: string) {
  const { getModalProps, openModal, closeModal } = useContext(ModalGroupContext);
  const props = getModalProps(name);
  const data: DataType | null = props?.data ?? null;

  return {
    open: props?.open ?? false,
    data,
    openModal: (data: DataType) => openModal(name, data),
    closeModal: (data?: DataType) => closeModal(name, data),
  };
}