import useSWR from 'swr';
import { APIResponse, TEMDrawing } from './types';
import { API_NAMESPACE, request } from '../request';
import { useState } from 'react';

const namespace = API_NAMESPACE.DRAWINGS;

export function useTEMDrawings({ params }: { params: object }) {
  const { data, error, mutate } = useSWR<APIResponse<TEMDrawing[]>>(
    params ? ['/drawings/tem', { params, namespace }] : null
  );

  const drawings = data?.data || [];

  return {
    drawings,
    error,
    mutateTEMDrawing: mutate,
    isLoading: !data && !error,
    pageInfo: {
      page: data?.page ?? 1,
      size: data?.size ?? 10,
      count: data?.count ?? 0,
    },
  };
}

export function useTEMDrawingByNewDrawingCode({
  newDrawingCode,
}: {
  newDrawingCode?: string;
}) {
  const { data, error } = useSWR<APIResponse<TEMDrawing>>(
    newDrawingCode ? [`/drawings/tem/${newDrawingCode}`, { namespace }] : null
  );

  const drawing = data?.data;

  return {
    drawing,
    error,
    isLoading: !data && !error,
    pageInfo: {
      page: data?.page ?? 1,
      size: data?.size ?? 10,
      count: data?.count ?? 0,
    },
  };
}

export async function createTEMDrawing(data: any) {
  const response = await request(`/drawings/tem`, {
    method: 'post',
    body: JSON.stringify(data),
    responseParser: (res) => res,
    namespace,
  });
  const result = await response.json();
  return result;
}

export async function updateTEMDrawing({ id, data }: any) {
  const response = await request(`/drawings/tem/${id}`, {
    method: 'put',
    body: JSON.stringify(data),
    responseParser: (res) => res,
    namespace,
  });
  const result = await response.json();
  return result;
}

export function useGetTEMParentOldDrawingCodes() {
  const [params, setParams] = useState<{
    searchTerm?: string;
    page?: number;
    size?: number;
  }>();
  const { data, error } = useSWR<APIResponse<string[]>>([
    '/drawings/temParentOldDrawingCodes',
    { params, namespace },
  ]);
  return {
    temParentOldDrawingCodes: data?.data ?? [],
    error,
    isLoading: !data && !error,
    refetchGetTEMParentOldDrawingCodes: setParams,
  };
}
