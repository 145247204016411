export enum CostWareHouseRadio {
  MATERIAL_VIEW = 'material-view',
  WAREHOUSE_VIEW = 'warehouse-view',
}

export const locationLabelAndKeys = [
  { label: '廠', registerName: 'factory' },
  { label: '棟', registerName: 'buildings' },
  { label: '樓', registerName: 'floors' },
  { label: '大區', registerName: 'areas' },
  { label: '小區', registerName: 'zones' },
  { label: 'stack', registerName: 'stacks' },
];
