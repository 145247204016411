import { MaterialStack, useGetMaterialStacks } from '@solar/data';
import { useCallback, useEffect, useState } from 'react';
import { ModalGroup, PageLayout, useModalGroupController, useLocationSelectorController, LocationSelectorGroup } from '@solar/templates';
import { Row } from 'antd';
import { Button, Table } from '@mezzanine-ui/react';
import { CreateMaterialModal } from './CreateMaterialModal';
import { Modals } from './Modals.enum';
import { UpdateMaterialModal } from './UpdateMaterialModal';
import { FEATURE_CODE_MAP } from '../WarehouseTempListPage/featureCode';
import { MACHINE_CODE_MAP } from '../WarehouseTempListPage/machineCode';
import moment from 'moment';
import { WarehouseEnquiryWarehouseView } from '../../WarehouseEnquiry/WarehouseView';
import { BatchSerialModal } from '../../WarehouseEnquiry/BatchSerialModal';

export function WarehouseMapPage() {
  const locationSelectorController = useLocationSelectorController({});
  const { materialStacks, pageInfo, fetchMaterialStacks, mutateMaterialStacks } = useGetMaterialStacks();
  const { currentLocation } = locationSelectorController;
  // const modalGroupController = useModalGroupController([{ name: Modals.CREATE }, { name: Modals.EDIT }]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const locationId = currentLocation?.shelf?.id ?? currentLocation?.stack?.id ?? currentLocation?.zone?.id ?? currentLocation?.area?.id;

  const refetchGetMaterialStacks = useCallback(
    (locationIds: string[]) => fetchMaterialStacks({ locationIds }),
    [pageInfo, locationId, fetchMaterialStacks, currentLocation]
  );

  useEffect(() => {
    if (locationId) refetchGetMaterialStacks([locationId]);
  }, [refetchGetMaterialStacks, locationId]);

  const [updateKey, setUpdateKey] = useState<string>();

  const modalGroupController = useModalGroupController([
    {
      name: 'BATCH_SERIAL',
    },
  ]);

  const batchSerialModalOpen = useCallback(() => {
    modalGroupController.openModal('BATCH_SERIAL', {});
  }, [modalGroupController]);

  return (
    <PageLayout title="倉庫地圖">
      <WarehouseEnquiryWarehouseView batchSerialModalOpen={batchSerialModalOpen} />
      <ModalGroup {...modalGroupController}>
        <BatchSerialModal />
      </ModalGroup>
      {/* <LocationSelectorGroup controller={locationSelectorController} expandable />
      <Row justify="end">
        <Button
          onClick={() => modalGroupController.openModal(Modals.CREATE, { stackId: currentLocation?.stack?.id })}>
          新增物料
        </Button>
      </Row>
      <div style={{ width: 2500 }}>
        <Table
          pagination={{
            total: pageInfo?.totalCount ?? 0,
            current: page,
            onChange: (nextPage) => {
              setPage(nextPage);
            },
            options: {
              pageSize,
              pageSizeLabel: '每頁顯示：',
              pageSizeUnit: '筆',
              onChangePageSize: (nextPageSize) => {
                setPageSize(nextPageSize);
                setPage(1);
              },
              renderPageSizeOptionName: (p) => `${p}`,
              renderPaginationSummary: (start, end) => `目前顯示 ${start} - ${end} 筆，共 ${end - start + 1} 筆資料`,
              showPageSizeOptions: true, // 開啟每頁顯示 N 筆
              showJumper: true, // 開啟跳頁功能
              jumperButtonText: '前往',
              jumperHintText: '跳至',
            }
          }}
          style={{ height: '100vh', width: 2400 }}
          dataSource={materialStacks ?? []}
          columns={[
            {
              title: '#',
              dataIndex: 'id',
              width: 80,
              render: (value: MaterialStack, index: number) => (
                index + 1
              ),
            },
            {
              title: '操作',
              dataIndex: 'action',
              width: 160,
              render: (source: MaterialStack) => (
                <div style={{ display: 'flex' }}>
                  <Button
                    onClick={() => {
                      modalGroupController.openModal(Modals.EDIT, source);

                      setUpdateKey(source.id.toString());
                    }}>
                    更新
                  </Button>
                </div>
              )
            },
            {
              title: '舊料號',
              width: 140,
              dataIndex: 'oldMaterialId',
            },
            {
              title: '料號描述',
              width: 300,
              dataIndex: 'description',
            },
            {
              title: '批號',
              width: 300,
              dataIndex: 'materialBatchId',
            },
            {
              title: '數量',
              width: 64,
              dataIndex: 'mainMaterialUnitQuantity',
            },
            {
              title: '單位',
              width: 96,
              dataIndex: 'mainMaterialUnit',
            },
            {
              title: 'RunCard單號',
              width: 220,
              dataIndex: 'runCardNumber',
            },
            {
              title: '新料號',
              width: 300,
              dataIndex: 'materialId',
            },
            {
              title: '特徵碼',
              width: 90,
              dataIndex: 'featureCode',
            },
            {
              title: '特徵碼名稱',
              width: 120,
              dataIndex: 'featureCode',
              render(source, index, column) {
                return FEATURE_CODE_MAP[source.featureCode as string] || source.featureCode || '-';
              },
            },
            {
              title: '加工碼',
              width: 90,
              dataIndex: 'machineCode',
            },
            {
              title: '加工碼名稱',
              width: 120,
              dataIndex: 'machineCode',
              render(source, index, column) {
                return MACHINE_CODE_MAP[source.machineCode as string] || source.machineCode || '-';
              },
            },
            {
              title: '入庫時間',
              width: 160,
              dataIndex: 'recordedAt',
              render(source: MaterialStack) {
                return moment(source.recordedAt).format('YYYY-MM-DD');
              },
            },
            {
              title: '備註',
              width: 300,
              dataIndex: 'note',
            },
          ]} />
      </div>
      <ModalGroup {...modalGroupController} key={updateKey}>
        <CreateMaterialModal refetchGetMaterialStacks={mutateMaterialStacks} currentLocation={currentLocation} />
        <UpdateMaterialModal refetchGetMaterialStacks={mutateMaterialStacks} />
      </ModalGroup> */}
    </PageLayout>
  );
}
