import { TableColumn } from '@mezzanine-ui/core/table';
import { DeliveryOrderDetail } from '@solar/data';
import { CopyTextWrapper } from '@solar/templates';
import { useMemo } from 'react';

export function useCustomerOrderExpectedColumns() {
  //   {
  //   receiveActionModalOpen,
  // }: {
  //   receiveActionModalOpen: ProductionDeliveryOrderReceiptModalOpenFunc;
  // }
  const column: TableColumn<
    DeliveryOrderDetail['specs'][number]['materialItems'][number]
  >[] = useMemo(
    () => [
      // {
      //   title: '操作',
      //   dataIndex: '',
      //   width: 120,
      //   align: 'center' as const,
      //   render(source) {
      //     return (
      //       <Button
      //         type="button"
      //         variant="outlined"
      //         onClick={() => {
      //           receiveActionModalOpen({
      //             specId: source.productionDeliveryOrderSpecId,
      //             materialId: source.materialId,
      //             materialDescription: source?.materialDescription,
      //             secondaryUnitName: source.quantityUnitName,
      //             mainUnitName: source.quantityUnitName,
      //             materialStockUnit: source.materialStockUnit,
      //             quantityUnit: source.quantityUnit,
      //           });
      //         }}
      //       >
      //         收料
      //       </Button>
      //     );
      //   },
      // },
      {
        width: 500,
        title: '品名',
        render: (source) => (
          <CopyTextWrapper text={source?.materialDescription} />
        ),
      },
      {
        width: 200,
        title: '料號',
        render: (source) => <CopyTextWrapper text={source?.materialId} />,
      },
      // { title: '舊料號', dataIndex: 'oldMaterialId' },
      // { title: '批號', dataIndex: 'sapBatchId' },
      {
        title: '應收量',
        dataIndex: 'quantity',
      },
      {
        title: '已收量',
        dataIndex: 'quantityReceived',
      },
      { title: '單位', dataIndex: 'quantityUnit' },
      {
        dataIndex: 'materialSellingContent',
        title: '物料銷售內文',
      },
      {
        dataIndex: 'workOrderDescription',
        title: '生產備註',
      },
      {
        dataIndex: 'workOrderDescription',
        title: '工單作業說明',
      },
      {
        dataIndex: 'deliveryNote',
        title: '出貨備註',
      },
    ],
    []
  );

  return column;
}
