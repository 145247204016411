import { InfoCircleFilledIcon } from '@mezzanine-ui/icons';
import { Button, Icon } from '@mezzanine-ui/react';
import moment from 'moment';
import { RepairRequestPriorityTag } from './RepairRequestPriorityTag';
import { RepairRequestStatusTag } from './RepairRequestStatusTag';
import { TableColumn } from '@mezzanine-ui/core/table';
import { NextPagination, PageInfo, RepairRequest } from '@solar/data';
import { MznPaginationTable } from '@solar/templates';

type EquipmentMaintenanceTableProps = {
  dataSource: RepairRequest[],
  pageInfo?: PageInfo,
  openViewModal: (repairRequest: RepairRequest) => void,
  openEditModal: (repairRequest: RepairRequest) => void,
  refetchRepairRequests: (nextPagination: NextPagination) => void,
}

export function EquipmentMaintenanceTable({ dataSource, pageInfo, refetchRepairRequests, openViewModal, openEditModal }: EquipmentMaintenanceTableProps) {
  const columns: TableColumn<RepairRequest>[] = [{
    title: '單號',
    dataIndex: 'id',
  }, {
    width: 100,
    title: '優先序',
    align: 'center',
    render: (source) => <RepairRequestPriorityTag priority={source?.priority} />,
  }, {
    title: '機台設備名稱',
    dataIndex: 'equipment.name',
  }, {
    title: '報修時間',
    render: (source) => moment(source?.createdAt).format("YYYY/MM/DD HH:mm"),
  }, {
    title: '狀態',
    render: (source) => <RepairRequestStatusTag status={source?.logs?.[0]?.status} />
  },{
    width: 100,
    title: '報修備註',
    align: 'center',
    forceShownTooltipWhenHovered: true,
    render: (source) => source?.remark
      ? <Icon color="primary" icon={InfoCircleFilledIcon} />
      : null,
    renderTooltipTitle: (source) => source?.remark,
  },{
    width: 100,
    title: '維修單',
    align: 'center',
    render: (source) => (
      <Button onClick={() => openViewModal(source)}>檢視</Button>
    ),
  }, {
    width: 100,
    title: '動作',
    align: 'center',
    render: (source) => (
      <Button variant="outlined" onClick={() => openEditModal(source)}>更新</Button>
    )
  }];

  return (
    <MznPaginationTable
      style={{ height: '50vh' }}
      pageInfo={pageInfo}
      fetchData={refetchRepairRequests}
      dataSource={dataSource}
      columns={columns} />
  );
}
