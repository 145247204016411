import { Tab, TabPane, Tabs } from '@mezzanine-ui/react';
import { PageLayout } from '@solar/templates';
import { Key, useState } from 'react';
import { MiscellaneousStorageInPage } from './MiscellaneousStorageInPage';
import { FixedAssetStorageInPage } from './FixedAssetStorageInPage';

export function FixedAssetsAndMiscStorageInPage() {
  const [activeKey, setActiveKey] = useState<Key>('misc');
  return (
    <PageLayout title="雜項入庫">
      <Tabs activeKey={activeKey} onChange={setActiveKey}>
        <TabPane key="misc" tab={<Tab>雜項</Tab>}>
          <MiscellaneousStorageInPage />
        </TabPane>
        <TabPane key="fixedAsset" tab={<Tab>固資</Tab>}>
          <FixedAssetStorageInPage />
        </TabPane>
      </Tabs>
    </PageLayout>
  );
}
