import { request, API_NAMESPACE } from '../../request';
import * as yup from 'yup';
import useSWR from 'swr';

const hubOrdersQuerySchema = yup
  .object()
  .shape({
    page: yup.number().optional(),
    perPage: yup.number().optional(),
    type: yup.string().oneOf(['RECEIVING']).optional(),
    hubDeliveryOrderId: yup.string().optional(),
    customerId: yup.string().optional(),
    shipDateStart: yup.string().optional(),
    shipDateEnd: yup.string().optional(),
    isDebit: yup.string().oneOf(['', 'Y', 'N']).optional(),
    debitDate: yup.string().optional(),
  })
  .optional();

export type HubOrdersQueryType = yup.InferType<typeof hubOrdersQuerySchema>;

export type HubOrdersMeta = {
  path: string;
  current_page: number;
  per_page: number;
  from: number;
  to: number;
  total: number;
  first_page_url: string;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  prev_page_url: string | null;
};

export type HubOrdersData = {
  id: string;
  shipDate: string;
  customerName: string;
  memberName: string;
  debitDate: string;
};

export type HubOrdersPayload = {
  data: HubOrdersData[];
} & HubOrdersMeta;

export function useHubOrdersGet(query?: HubOrdersQueryType) {
  const {
    data: swrData,
    mutate,
    isValidating,
  } = useSWR<HubOrdersPayload>(validateSWRKey(query), request, {
    revalidateOnFocus: false,
  });

  const { data, ...meta } = swrData ?? {};

  return {
    hubOrdersData: transformHubOrdersData(data ?? []),
    hubOrdersMeta: (meta as HubOrdersMeta) ?? {},
    refetechHubOrders: mutate,
    isLoading: isValidating,
  };
}

function validateSWRKey(query?: HubOrdersQueryType) {
  try {
    for (const key in query) {
      const _key = key as keyof HubOrdersQueryType;
      if (typeof query[_key] === 'undefined' || query[_key] === '') {
        delete query[_key];
      }
    }

    const safeQuery = hubOrdersQuerySchema.validateSync(query);
    return [
      `/hubDeliveryOrders/get`,
      { namespace: API_NAMESPACE.HR, params: safeQuery },
    ];
  } catch (error) {
    return false;
  }
}

function transformHubOrdersData(data: HubOrdersData[]) {
  return data.map((item) => ({
    ...item,
  }));
}
