import useSWR from 'swr';
import { API_NAMESPACE } from '../request';

const namespace = API_NAMESPACE.MATERIALS;

export type ProductionWorkOrderResponseData = {
  businessUnitName: string;
  expectedCompletedAt: string;
  id: string;
  materialId: string;
  materialDescription: string;
  processName: string;
  expectedMainMaterialUnitQuantity?: number;
  expectedSecondaryMaterialUnitQuantity?: number;
  shippedMainMaterialUnitQuantity?: number;
  shippedSecondaryMaterialUnitQuantity?: number;
  specId: string;
  state: string;
  staffName: string;
};

export function useGetProductionWorkOrder({
  workOrderId,
}: {
  workOrderId?: string;
}) {
  const { data, error } = useSWR<ProductionWorkOrderResponseData[]>(
    workOrderId
      ? [
          `/warehouses/production-work-orders/${workOrderId}`,
          {
            namespace,
          },
        ]
      : null
  );

  return {
    productionWorkOrderDetail: data?.[0],
    materialRequestList: data,
    isLoading: !data && !error,
  };
}
