import { TableColumn } from '@mezzanine-ui/core/table';
import { InputField, SelectField } from '@mezzanine-ui/react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MaterialRequestOrderSpec, WorkOrderInto } from './use-work-order-info';
import {
  Button,
  Checkbox,
  Input,
  Option,
  Select,
  SelectValue,
  Typography,
} from '@mezzanine-ui/react';
import {
  FieldValues,
  UseFieldArrayReturn,
  UseFormRegister,
  UseFormReturn,
  UseFormUnregister,
  useController,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';

export const checkInFormDefaultValues = {
  materialRequestOrderId: '',
  materialBarcode: '',
  materialCheckInList: [],
  createdAt: new Date().toISOString(),
};

export interface CheckInFormFields extends FieldValues {
  materialRequestOrderId: string;
  processingId: SelectValue;
  displayFormsByMaterialBarcode?: string;
  materialBarcode: string;
  salesOrderId: string | null;
  salesOrderLine: string | null;
  materialCheckInList: {
    inputMaterialBarcode: string;
    inputMaterialQuantity: string;
    inputMaterialStockUnit: string;
    inputMaterialInputUnit: string;
    stockOverInputUnitRatio: string;
    selectedUnit: SelectValue<
      'inputMaterialStockUnit' | 'inputMaterialInputUnit'
    >;
  }[];
  createdAt: string;
  forms: Record<string, any>;
}

export const getMatchId = (
  row:
    | MaterialRequestOrderSpec
    | WorkOrderInto['materialRequestOrderBatches'][number]
) => {
  return `${row.materialId}_${row.materialSapBatchId}_${row.materialBatchId}`;
};

export function useIsStationListColumns(
  requestMaterialBatchIds: readonly string[]
) {
  const [displayInputUnitIdsSet, setDisplayInputUnitIdsSet] = useState(
    new Set()
  );

  const isDisabled = useCallback(
    (row: any) =>
      !requestMaterialBatchIds?.some(
        (id) => id?.indexOf(getMatchId(row)) !== -1
      ),
    [requestMaterialBatchIds]
  );

  const getColor = useCallback(
    (row: any) => (isDisabled(row) ? 'text-disabled' : 'text-primary'),
    [isDisabled]
  );

  return useMemo<TableColumn<MaterialRequestOrderSpec>[]>(
    () => [
      {
        title: '品名',
        render: (row) => (
          <Typography variant="body1" color={getColor(row)}>
            {row?.material?.description}
          </Typography>
        ),
      },
      {
        title: '料號',
        render: (row) => (
          <Typography variant="body1" color={getColor(row)}>
            {row.materialId}
          </Typography>
        ),
      },
      {
        title: '批號',
        render: (row) => (
          <Typography variant="body1" color={getColor(row)}>
            {row['materialSapBatchId'] ?? ''}-{row['materialBatchId'] ?? ''}
          </Typography>
        ),
      },
      {
        title: '預期數量',
        render: (row) => (
          <Typography variant="body1" color={getColor(row)}>
            {displayInputUnitIdsSet.has(row?.id)
              ? row?.expectedMaterialInputUnitQuantity
              : row?.expectedMaterialStockUnitQuantity ?? ''}
          </Typography>
        ),
        // render: (row) => {
        //   const index = requestMaterialBatchIds?.findIndex(
        //     (id) => id?.indexOf(getMatchId(row)) !== -1
        //   );
        //   return (
        //     <InputField
        //       disabled={isDisabled(row)}
        //       placeholder={row.expectedMainMaterialUnitQuantity}
        //       registerName={`checkinForm.materialCheckinList.${index}.inputMaterialStockUnitQuantity`}
        //       type="number"
        //     />
        //   );
        // },
      },
      {
        title: '單位',
        render: (row) => (
          <Select
            color={getColor(row)}
            value={
              displayInputUnitIdsSet.has(row?.id)
                ? {
                    id: 'materialInputUnit',
                    name: row?.materialInputUnit ?? '',
                  }
                : {
                    id: 'materialStockUnit',
                    name: row?.material?.materialStockUnit ?? '',
                  }
            }
            onChange={(nextValue) => {
              switch (nextValue?.id) {
                case 'materialStockUnit':
                  setDisplayInputUnitIdsSet((prevSet) => {
                    prevSet.delete(row?.id);
                    return new Set([...prevSet]);
                  });
                  break;
                case 'materialInputUnit':
                  setDisplayInputUnitIdsSet((prevSet) => {
                    prevSet.add(row?.id);
                    return new Set([...prevSet]);
                  });
                  break;
                default:
                  break;
              }
            }}
          >
            <Option value="materialStockUnit" color={getColor(row)}>
              {row?.material?.materialStockUnit ?? ''}
            </Option>
            <Option value="materialInputUnit" color={getColor(row)}>
              {row?.materialInputUnit ?? ''}
            </Option>
          </Select>
        ),
      },
    ],
    [displayInputUnitIdsSet, getColor]
  );
}

function InputMaterialStockUnitQuantityField({
  index,
  register,
  unregister,
}: {
  index: number;
  register: UseFormRegister<FieldValues>;
  unregister: UseFormUnregister<CheckInFormFields>;
}) {
  useEffect(() => {
    return () => {
      // console.log('unregister', `materialCheckInList.${index}.inputMaterialStockUnitQuantity`);
      unregister(`materialCheckInList.${index}.inputMaterialStockUnitQuantity`);
    };
  }, [index, unregister]);

  return (
    <InputField
      register={register}
      registerName={`materialCheckInList.${index}.inputMaterialStockUnitQuantity`}
    />
  );
}

function InputMaterialStockUnitUnitField({
  index,
  register,
  unregister,
}: {
  index: number;
  register: UseFormRegister<FieldValues>;
  unregister: UseFormUnregister<CheckInFormFields>;
}) {
  useEffect(() => {
    return () => {
      // console.log('unregister', `materialCheckInList.${index}.inputMaterialStockUnit`);
      unregister(`materialCheckInList.${index}.inputMaterialStockUnit`);
    };
  }, [index, unregister]);

  return (
    <InputField
      register={register}
      registerName={`materialCheckInList.${index}.inputMaterialStockUnit`}
    />
  );
}

export function useCheckInListColumns(
  {
    fields,
    remove,
  }: UseFieldArrayReturn<CheckInFormFields, 'materialCheckInList', 'id'>,
  { watch, setValue }: UseFormReturn<CheckInFormFields>
) {
  // const customRegister = useCallback<UseFormRegister<FieldValues>>(
  //   (name, options) => {
  //     // console.log('regist', name);
  //     return register(name, options);
  //   },
  //   [register]
  // );

  // return useMemo<TableColumn<CheckInFormFields['materialCheckInList'][number]>[]>(
  //   () => [
  //     {
  //       title: '物料條碼',
  //       dataIndex: 'inputMaterialBarcode',
  //     },
  //     {
  //       width: 180,
  //       title: '數量',
  //       dataIndex: 'inputMaterialStockUnitQuantity',
  //       render: (row, index) => (
  //         <InputField
  //           registerName={`materialCheckInList.${index}.inputMaterialStockUnitQuantity`}
  //         />
  //       ),
  //       // render: (row, index) => (
  //       //   <InputMaterialStockUnitQuantityField
  //       //     index={index}
  //       //     register={customRegister}
  //       //     unregister={unregister} />
  //       // )
  //     },
  //     {
  //       width: 180,
  //       title: '單位',
  //       dataIndex: 'inputMaterialStockUnit',
  //       render: (row, index) => (
  //         <InputField
  //           registerName={`materialCheckInList.${index}.inputMaterialStockUnit`}
  //         />
  //       ),
  //       // render: (row, index) => (
  //       //   <InputMaterialStockUnitUnitField
  //       //     index={index}
  //       //     register={customRegister}
  //       //     unregister={unregister} />
  //       // )
  //     },
  //     {
  //       title: '操作',
  //       dataIndex: 'actions',
  //       render: (row, index) => (
  //         <Button
  //           danger
  //           type="button"
  //           variant="contained"
  //           onClick={() => {
  //             remove(index);
  //             console.log('removed', index);
  //           }}
  //         >
  //           刪除
  //         </Button>
  //       ),
  //     },
  //   ],
  //   [remove]
  // );
  return [
    {
      width: 100,
      title: '顯示題目',
      render: (row) => (
        <Checkbox
          checked={
            row.inputMaterialBarcode === watch('displayFormsByMaterialBarcode')
          }
          onChange={(event) =>
            setValue(
              'displayFormsByMaterialBarcode',
              event.target?.checked ? row.inputMaterialBarcode : undefined
            )
          }
        />
      ),
    },
    {
      title: '物料條碼',
      dataIndex: 'inputMaterialBarcode',
    },
    {
      width: 180,
      title: '數量',
      render: (row, index) => (
        <InputField
          registerName={`materialCheckInList.${index}.inputMaterialQuantity`}
        />
      ),
      // render: (row, index) => (
      //   <InputMaterialStockUnitQuantityField
      //     index={index}
      //     register={customRegister}
      //     unregister={unregister} />
      // )
    },
    {
      width: 180,
      title: '單位',
      render: (row, index) => (
        <SelectField
          registerName={`materialCheckInList.${index}.selectedUnit`}
          options={[
            // {
            //   id: 'inputMaterialStockUnit',
            //   name: row.inputMaterialStockUnit,
            // },
            {
              id: 'inputMaterialInputUnit',
              name: row.inputMaterialInputUnit,
            },
          ]}
        />
      ),
      // render: (row, index) => (
      //   <InputMaterialStockUnitUnitField
      //     index={index}
      //     register={customRegister}
      //     unregister={unregister} />
      // )
    },
    {
      width: 120,
      title: '操作',
      render: (row, index) => (
        <Button
          danger
          type="button"
          variant="contained"
          onClick={() => remove(index)}
        >
          刪除
        </Button>
      ),
    },
  ] as TableColumn<CheckInFormFields['materialCheckInList'][number]>[];
}
