import { API_NAMESPACE } from '../request';

import useSWR from 'swr';

export type MaterialRequestOrderLogs = {
  id: number;
  shelfId: string;
  materialBatchId: string;
  orderId: string;
  materialId: string;
  material: null;
  loaderId: number;
  mainMaterialUnitQuantity: number;
  lockedMainMaterialUnitQuantity: string;
  inspectionMainMaterialUnitQuantity: string;
  inTransitMainMaterialUnitQuantity: string;
  secondaryMaterialUnitQuantity: number;
  mainMaterialUnit: string;
  secondaryMaterialUnit: null;
  staffId: string;
  actionType: string;
  orderType: string;
  createdAt: string;
};

const namespace = API_NAMESPACE.MATERIALS;
export function useMaterialRequestOrderLogs(orderId: string) {
  const { data, error } = useSWR<{ logs: MaterialRequestOrderLogs[] }>(
    orderId
      ? [`/warehouses/material-request-orders/${orderId}/logs`, { namespace }]
      : null
  );

  return {
    logs: data?.logs || [],
    isLoading: !error && !data,
  };
}
