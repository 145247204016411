import {
  Button,
  Message,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@mezzanine-ui/react';
import { useTargetModal } from '@solar/templates';
import { Modals } from './Modals.enum';
import { Col, Row, Space } from 'antd';
import {
  IncomingPurchaseOrder,
  updateIQCOrderStatus,
  useGetIQCDeliveryOrders,
  useGetIQCPurchaseOrders,
} from '@solar/data';
import { useForm } from 'react-hook-form';
import {
  FormFieldsWrapper,
  InputField,
  TextAreaField,
} from '@mezzanine-ui/react-hook-form';
import { useEffect, useState } from 'react';

const defaultValues = {
  demandedUnitNote: '',
  waiverNumber: '',
};

export function IncomingQualityControlModal() {
  const { open, data, closeModal } = useTargetModal<{
    action: '特採' | '申請特採' | '退貨';
    source: IncomingPurchaseOrder;
  }>(Modals.QUALITY_CONTROL);

  const methods = useForm<{
    demandedUnitNote: string;
    waiverNumber: string;
  }>({ defaultValues });

  const [executing, setExecuting] = useState(false);

  const source = data?.source;

  useEffect(() => {
    if (open && source) {
      methods.setValue('demandedUnitNote', source?.demandedUnitNote ?? '');
      methods.setValue('waiverNumber', source?.sapBatch?.waiverNumber ?? '');
    }
  }, [open, source]);

  const { mutateGetIQCPurchaseOrders: mutateForPendingPurchaseOrder } =
    useGetIQCPurchaseOrders({
      statusIds: [4, 5, 6, 7],
    });

  const { mutateGetIQCDeliveryOrders: mutateForPendingDeliveryOrder } =
    useGetIQCDeliveryOrders({
      statusIds: [4, 5, 6, 7],
    });

  const { mutateGetIQCPurchaseOrders: mutateForAlreadyPurchaseOrder } =
    useGetIQCPurchaseOrders({
      statusIds: [8, 9, 10, 11],
    });
  const { mutateGetIQCDeliveryOrders: mutateForAlreadyDeliveryOrder } =
    useGetIQCDeliveryOrders({
      statusIds: [8, 9, 10, 11],
    });

  const onClose = () => {
    methods.reset(defaultValues);
    closeModal();
  };

  return (
    <Modal style={{ width: 1000 }} open={open} onClose={onClose}>
      <ModalHeader>
        {source?.materialId ?? ''}-{source?.sapBatchId ?? ''}
      </ModalHeader>
      <ModalBody>
        <FormFieldsWrapper methods={methods}>
          <Space direction="vertical" style={{ width: '100%' }} size="middle">
            {source?.statusId === 4 && (
              <TextAreaField
                fullWidth
                registerName="demandedUnitNote"
                label="備註"
              />
            )}
            {source?.statusId === 8 && (
              <InputField
                fullWidth
                registerName="waiverNumber"
                label="特採單號"
              />
            )}
          </Space>
        </FormFieldsWrapper>
      </ModalBody>
      <ModalFooter>
        <Row style={{ width: '100%' }} justify="end" gutter={[12, 12]}>
          <Col>
            <Button
              danger={data?.action === '退貨'}
              type="button"
              loading={executing}
              variant="outlined"
              onClick={async () => {
                try {
                  setExecuting(true);

                  if (!source?.id) throw new Error('order id is not exist');

                  const formState = methods.getValues();

                  const updatedStatusId = (() => {
                    switch (data?.action) {
                      case '特採':
                        return 10;
                      case '申請特採':
                        return 6;
                      case '退貨':
                        return 5;
                      default:
                        return null;
                    }
                  })();

                  if (!updatedStatusId)
                    throw new Error('updated status id is not exist');

                  await updateIQCOrderStatus(source?.id, {
                    demandedUnitNote: formState?.demandedUnitNote,
                    waiverNumber: formState?.waiverNumber,
                    updatedStatusId,
                  });

                  Message.success(
                    `${source?.purchaseOrderId}:${data?.action}成功`
                  );
                  await mutateForAlreadyDeliveryOrder();
                  await mutateForAlreadyPurchaseOrder();
                  await mutateForPendingDeliveryOrder();
                  await mutateForPendingPurchaseOrder();

                  onClose();
                } catch (error) {
                  console.error(error);
                  Message.error(
                    `${source?.purchaseOrderId}:${data?.action}失敗`
                  );
                } finally {
                  setExecuting(false);
                }
              }}
            >
              確認{data?.action ?? ''}
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
}
