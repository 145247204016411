import { TableDataSourceWithID } from '@mezzanine-ui/core/table';

export enum IQCOrderType {
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  DELIVERY_ORDER = 'DELIVERY_ORDER',
}

export interface GetIQCOrdersParams {
  offset?: number;
  limit?: number;
  iqcOrderId?: string;
  statusIds?: number[];
  materialId?: string;
  sapBatchId?: string;
  subBatchId?: string;
  withLogs?: boolean;
}

export interface GetIQCPurchaseOrdersParams extends GetIQCOrdersParams {
  purchaseOrderId?: string;
  purchaseOrderItemId?: string;
  purchaseOrderType?: string;
}

export interface GetIQCDeliveryOrdersParams extends GetIQCOrdersParams {
  deliveryOrderId?: string;
  deliveryOrderItemId?: string;
  deliveryOrderType?: string;
}

export enum PurchaseOrderType {
  DELIVERY_ORDER = 'DELIVERY_ORDER',
  INVENTORY = 'INVENTORY',
  OUTSOURCE = 'OUTSOURCE',
  MISC = 'MISC',
  FIXED_ASSET = 'FIXED_ASSET',
}
export interface IncomingPurchaseOrder extends TableDataSourceWithID {
  id: number;
  purchaseOrderId: string;
  purchaseOrderItemId: string;
  inventoryPurchaseOrderId: string;
  inventoryPurchaseOrderItemId: string;
  outsourcePurchaseOrderId: string;
  outsourcePurchaseOrderItemId: string;
  miscPurchaseOrderId: string;
  miscPurchaseOrderItemId: string;
  purchaseOrderType: PurchaseOrderType;
  materialId: string;
  sapBatchId: string;
  statusId: number;
  sapReceivingRefDocId: string;
  sapReceivingRefDocYear: string;
  sapInspectionRefDocId: any;
  sapInspectionRefDocYear: any;
  receivedQuantity: number;
  warehouseNote: any;
  qcNote: any;
  demandedUnitNote: any;
  createdBy: any;
  updatedBy: any;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  sapBatch: SapBatch;
  status: IQCOrderStatus;
  logs: IncomingPurchaseOrderLog[];
  iqcOrderItems?: {
    id: number;
    iqcOrderId: number;
    materialId: string;
    sapBatchId: string;
    receivedQuantity: string;
    createdBy: any;
    updatedBy: any;
    createdAt: string;
    updatedAt: string;
    deletedAt: any;
    inventoryPurchaseOrderId: any;
    inventoryPurchaseOrderItemId: any;
    outsourcePurchaseOrderId: any;
    outsourcePurchaseOrderItemId: any;
    miscPurchaseOrderId: any;
    miscPurchaseOrderItemId: any;
    deliveryOrderId: string;
    deliveryOrderItemId: string;
    deliveryOrderDetail: any;
    sapBatch: SapBatch;
    material: any;
  }[];
  inventoryPurchaseOrderSpec: {
    id: string;
    itemId: string;
    materialId: string;
    inventoryPurchaseOrderId: string;
    expectedMaterialStockUnitQuantity: string;
    receivedMaterialStockUnitQuantity: string;
    returnedMaterialStockUnitQuantity: string;
    materialStockUnit: string;
    materialInputUnit: string;
    stockOverInputUnitRatio: string;
    expectedMainMaterialUnitQuantity: string;
    expectedSecondaryMaterialUnitQuantity: string;
    receivedMainMaterialUnitQuantity: string;
    receivedSecondaryMaterialUnitQuantity: string;
    returnedMainMaterialUnitQuantity: string;
    returnedSecondaryMaterialUnitQuantity: string;
    mainMaterialUnit: string;
    secondaryMaterialUnit: string;
    mainToSecondaryUnitRatio: string;
    factoryId: string;
    limsBarcode: any;
    createdAt: string;
    updatedAt: string;
    deletedAt: any;
    syncAt: string;
  };
}

export interface SapBatch {
  id: string;
  materialId: string;
  availableMaterialStockUnitQuantity: string;
  inspectionMaterialStockUnitQuantity: string;
  mainMaterialUnitQuantity: string;
  secondaryMaterialUnitQuantity: string;
  lockedMainMaterialUnitQuantity: string;
  inspectionMainMaterialUnitQuantity: string;
  inTransitMainMaterialUnitQuantity: string;
  materialInputUnit: string;
  recycleNumber: any;
  createdAt: string;
  syncAt: string;
  remark: any;
  customerId: any;
  waiverNumber: string;
  subBatches: SubBatch[];
}

export interface SubBatch {
  id: string;
  materialId: string;
  sapBatchId: string;
  name: any;
  createdAt: string;
  oldBatchId: any;
  orderType: any;
  fromOrderId: any;
  remark: any;
  storedLoaderRecords: StoredLoaderRecord[];
}

interface StoredLoaderRecord extends TableDataSourceWithID {
  id: number;
  loaderId: string;
  materialId: string;
  materialSapBatchId: string;
  materialBatchId: string;
  quantity: string;
  type: string;
  actualMaterialWeightUnitQuantity: string;
  salesOrderId: any;
  salesOrderLine: any;
  barcode: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  shelfId: string;
}

export interface IQCOrderStatus {
  id: number;
  seq: number;
  code: string;
  name: string;
  createdAt: string;
}

export interface IncomingPurchaseOrderLog {
  id: number;
  iqcOrderId: number;
  purchaseOrderId: string;
  purchaseOrderItemId: string;
  purchaseOrderType: string;
  materialId: string;
  sapBatchId: string;
  statusId: number;
  sapReceivingRefDocId: string;
  sapReceivingRefDocYear: string;
  sapInspectionRefDocId: any;
  sapInspectionRefDocYear: any;
  receivedQuantity: number;
  warehouseNote?: string;
  qcNote: any;
  demandedUnitNote: any;
  createdBy: any;
  updatedBy: any;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
}
