import { useState } from 'react';
import { OffsetBased, PageInfo } from '../pagination';
import useSWR from 'swr';
import { API_NAMESPACE, request } from '../request';

const namespace = API_NAMESPACE.MATERIALS;

export type InventoryMiscByMaterialResponseData = {
  id: string;
  materialId: string;
  oldMaterialId: string;
  materialDescription: string;
  batchId: string;
  shelfId: string;
  shelfName: string;
  stackId: string;
  stackName: string;
  loaderId: string;
  loaderName: string;
  mainMaterialUnitQuantity: number;
  secondaryMaterialUnitQuantity: number;
  createdAt: string;
  staffName: string;
  drawingCode: string;
  loaderBarcode: string;
  materialBarcode: string;
};

export type InventoryMiscByMaterialParam = {
  searchTerms: string;
  type: 'LOADER_BARCODE';
};

export function useInventoryMiscByMaterial() {
  const [params, setParams] = useState<
    Partial<InventoryMiscByMaterialParam> & OffsetBased
  >({
    type: 'LOADER_BARCODE',
  });
  const { data, error } = useSWR<{
    records: InventoryMiscByMaterialResponseData[];
    pageInfo: PageInfo;
  }>([
    '/warehouses/inventory-misc-by-material',
    {
      params,
      namespace,
    },
  ]);

  const mutateParams = (
    payload: Partial<InventoryMiscByMaterialParam> & OffsetBased
  ) => {
    setParams(payload);
  };

  return {
    records: data?.records || [],
    pageInfo: data?.pageInfo,
    isLoading: !data && !error,
    mutateParams,
  };
}

export type ShipWarehousesInventoryMiscMaterial = {
  items: Array<{
    materialBarcode: string;
    userId: string;
    mainMaterialUnitQuantity: number;
    secondaryMaterialUnitQuantity: number;
  }>;
};

export async function shipWarehousesInventoryMiscMaterial(
  body: ShipWarehousesInventoryMiscMaterial
) {
  const response = await request('/warehouses/inventory-misc-material/ship', {
    method: 'put',
    body: JSON.stringify(body),
    namespace,
  });

  return response;
}

type ReturnWarehousesInventoryMiscMaterial = {
  items: Array<{
    materialId: string;
    loaderId: number;
    mainMaterialUnitQuantity: number;
    secondaryMaterialUnitQuantity: number;
  }>;
};

export async function returnWarehousesInventoryMiscMaterial(
  body: ReturnWarehousesInventoryMiscMaterial
) {
  const response = await request('/warehouses/inventory-misc-material/return', {
    method: 'put',
    body: JSON.stringify(body),
    namespace,
  });

  return response;
}
