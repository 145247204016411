import { useFormContext, useWatch } from 'react-hook-form';
import { BindingFormValues, VirtualInType } from '../typing';
import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';
import {
  useGetDeliveryOrderDetail,
  useInventoryByPurchaseOrder,
} from '@solar/data';
import { useCallback, useEffect, useMemo } from 'react';
import { Message, SelectValue } from '@mezzanine-ui/react';
import { isOneCharDifferent } from '../utils';

function PurchaseOrderItemSelector({
  virtualOutDeliveryOrderItemId,
  onOrderItemIdChange,
}: {
  virtualOutDeliveryOrderItemId: string;
  onOrderItemIdChange: ({
    targetOrderId,
    targetOrderItemId,
    targetMaterialId,
  }: {
    targetOrderId?: string;
    targetOrderItemId?: string;
    targetMaterialId?: string;
  }) => void;
}) {
  const methods = useFormContext<BindingFormValues>();

  const registerName =
    `${virtualOutDeliveryOrderItemId}.relatedOrderItemId` as const;

  const relatedOrderId = useWatch({
    control: methods.control,
    name: `${virtualOutDeliveryOrderItemId}.relatedOrderId`,
  });

  const { order } = useInventoryByPurchaseOrder({
    orderId: relatedOrderId?.id,
  });

  const onChange = useCallback(
    (selectValue: SelectValue) => {
      if (!selectValue) {
        methods.setValue(
          `${virtualOutDeliveryOrderItemId}.relatedOrderMaterialId`,
          null
        );
        return;
      }

      const selectedItem = order?.find(
        (item) => item.itemId === selectValue?.id
      );

      if (!selectedItem) {
        Message.error('無法取得此項次資訊');
        return;
      }

      onOrderItemIdChange({
        targetOrderId: relatedOrderId?.id,
        targetOrderItemId: selectValue?.id,
        targetMaterialId: selectedItem?.materialId,
      });
    },
    [
      methods,
      onOrderItemIdChange,
      order,
      relatedOrderId,
      virtualOutDeliveryOrderItemId,
    ]
  );

  return (
    <AutoCompleteField
      required
      registerName={registerName}
      options={order?.map((order) => ({
        id: order.itemId,
        name: order.itemId,
      }))}
      onChange={onChange}
    />
  );
}

function DeliveryOrderItemSelector({
  virtualOutDeliveryOrderItemId,
  onOrderItemIdChange,
}: {
  virtualOutDeliveryOrderItemId: string;
  onOrderItemIdChange: ({
    targetOrderId,
    targetOrderItemId,
    targetMaterialId,
  }: {
    targetOrderId?: string;
    targetOrderItemId?: string;
    targetMaterialId?: string;
  }) => void;
}) {
  const methods = useFormContext<BindingFormValues>();

  const watchedRelatedOrderId = useWatch({
    control: methods.control,
    name: `${virtualOutDeliveryOrderItemId}.relatedOrderId`,
  });

  const { deliveryOrder } = useGetDeliveryOrderDetail(
    watchedRelatedOrderId?.id
  );

  const materialItems = useMemo(() => {
    return deliveryOrder?.specs?.flatMap((spec) => spec.materialItems) ?? [];
  }, [deliveryOrder]);

  const options = useMemo(() => {
    return (
      materialItems.map((item) => ({
        id: item.productionDeliveryOrderLine,
        name: item.productionDeliveryOrderLine,
      })) ?? []
    );
  }, [materialItems]);

  const registerName =
    `${virtualOutDeliveryOrderItemId}.relatedOrderItemId` as const;

  const onChange = useCallback(
    (selectValue: SelectValue) => {
      if (!selectValue) {
        methods.setValue(
          `${virtualOutDeliveryOrderItemId}.relatedOrderMaterialId`,
          null
        );
        return;
      }

      const selectedItem = materialItems?.find(
        (item) => item.productionDeliveryOrderLine === selectValue?.id
      );

      if (!selectedItem) {
        Message.error('無法取得此項次資訊');
        return;
      }

      onOrderItemIdChange({
        targetOrderId: watchedRelatedOrderId?.id,
        targetOrderItemId: selectValue?.id,
        targetMaterialId: selectedItem?.materialId,
      });
    },
    [
      materialItems,
      methods,
      onOrderItemIdChange,
      virtualOutDeliveryOrderItemId,
      watchedRelatedOrderId?.id,
    ]
  );

  return (
    <AutoCompleteField
      required
      registerName={registerName}
      options={options}
      onChange={onChange}
    />
  );
}

export function VirtualInOrderItemIdSelector({
  virtualOutDeliveryOrderItemMaterialId,
  virtualOutDeliveryOrderItemId,
}: {
  virtualOutDeliveryOrderItemMaterialId: string;
  virtualOutDeliveryOrderItemId: string;
}) {
  const methods = useFormContext<BindingFormValues>();

  const watchedRelatedCategory = methods.watch(
    `${virtualOutDeliveryOrderItemId}.relatedCategory`
  );

  const onOrderItemIdChange = useCallback(
    ({
      targetOrderId,
      targetOrderItemId,
      targetMaterialId,
    }: {
      targetOrderId?: string;
      targetOrderItemId?: string;
      targetMaterialId?: string;
    }) => {
      if (!targetOrderId) {
        Message.error('請選擇虛入訂單單號');
        return;
      }

      if (!targetOrderItemId) {
        Message.error('請選擇虛入訂單項次');
        return;
      }

      if (!targetMaterialId) {
        Message.error(
          `此虛入單項次(${targetOrderId}-${targetOrderItemId})無料號`
        );
        methods.setValue(
          `${virtualOutDeliveryOrderItemId}.relatedOrderItemId`,
          null
        );
        methods.setValue(
          `${virtualOutDeliveryOrderItemId}.relatedOrderMaterialId`,
          null
        );
        return;
      }

      // 檢查此收料訂單項次之料號是否與虛出出貨單之料號長度相同且只差一個字元
      if (
        targetMaterialId.length !== virtualOutDeliveryOrderItemMaterialId.length
      ) {
        Message.error(
          `虛入單的料號(${targetMaterialId})長度與虛出出貨單的料號(${virtualOutDeliveryOrderItemMaterialId})長度不同`
        );
        methods.setValue(
          `${virtualOutDeliveryOrderItemId}.relatedOrderItemId`,
          null
        );
        methods.setValue(
          `${virtualOutDeliveryOrderItemId}.relatedOrderMaterialId`,
          null
        );
        return;
      }

      // if (
      //   !isOneCharDifferent(
      //     targetMaterialId,
      //     virtualOutDeliveryOrderItemMaterialId
      //   )
      // ) {
      //   Message.error(
      //     `虛入單的料號(${targetMaterialId})與虛出出貨單的料號(${virtualOutDeliveryOrderItemMaterialId})只能差一個字元`
      //   );
      //   methods.setValue(
      //     `${virtualOutDeliveryOrderItemId}.relatedOrderItemId`,
      //     null
      //   );
      //   methods.setValue(
      //     `${virtualOutDeliveryOrderItemId}.relatedOrderMaterialId`,
      //     null
      //   );
      //   return;
      // }

      methods.setValue(
        `${virtualOutDeliveryOrderItemId}.relatedOrderMaterialId`,
        targetMaterialId
      );
    },
    [
      methods,
      virtualOutDeliveryOrderItemId,
      virtualOutDeliveryOrderItemMaterialId,
    ]
  );

  if (watchedRelatedCategory?.id === VirtualInType.DELIVERY_ORDER)
    return (
      <DeliveryOrderItemSelector
        virtualOutDeliveryOrderItemId={virtualOutDeliveryOrderItemId}
        onOrderItemIdChange={onOrderItemIdChange}
      />
    );

  if (watchedRelatedCategory?.id === VirtualInType.INVENTORY_PURCHASE_ORDER)
    return (
      <PurchaseOrderItemSelector
        virtualOutDeliveryOrderItemId={virtualOutDeliveryOrderItemId}
        onOrderItemIdChange={onOrderItemIdChange}
      />
    );

  return null;
}
