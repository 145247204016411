import type { ComponentProps } from 'react';
import { useState, Fragment, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions,
  Button,
  Message,
  Table,
} from '@mezzanine-ui/react';
import { FormProvider } from 'react-hook-form';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { useHubOrderDetailGet, useHubOrderUpdate } from '@solar/data';
import { OrderDeliveryIdInput } from './OrderDeliveryIdInput';
import { OrderAccountDatePicker } from './OrderAccountDatePicker';
import { OrderReverseDatePicker } from './OrderReverseDatePicker';
import { useHubOrderGetById } from '@solar/data';
import { InputField } from '@mezzanine-ui/react-hook-form';
import { debounce } from 'lodash';
import styles from './index.module.scss';
import { postDeliveryOrderShip } from '@solar/data';

type Props = ComponentProps<typeof Modal> & {
  hubDeliveryOrderId: string;
};

export function ActionDetailModalButton({ hubDeliveryOrderId }: Props) {
  // swr 1.3.0 not support useSWRMutation, so declare reactive state to defer fetch
  const [deferHubDeliveryOrderId, setDeferHubDeliveryId] = useState<
    string | null
  >(null);

  const { data } = useHubOrderGetById({
    hubDeliveryOrderId: hubDeliveryOrderId,
    errorCallback: (error) =>
      Message.error('取得調撥明細失敗：' + error?.message),
  });

  const { formMethods, formSubmitCreator } = useHubOrderUpdate();

  const { hubOrderDetail, isLoading, refetchHubOrderDetail } =
    useHubOrderDetailGet({
      hubDeliveryOrderId: deferHubDeliveryOrderId,
      formMethods,
      errorCallback: (error) =>
        Message.error('取得調撥明細失敗：' + error?.message),
    });
  const isDeducted = !!hubOrderDetail?.debitDate;
  // table change methods
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  // can submit
  const [isFullfilled, setIsFullfilled] = useState<boolean>(false);
  const handleOutQuantityChange = (index: number, value: any) => {
    data.warehouseOptions[index].outQuantity = value;
    checkFulfillment();
  };
  const debounceHandleQuantityChange = debounce(handleOutQuantityChange, 300);

  const [loading, setLoading] = useState(false);

  const submitOutQuantity = () => {
    setLoading(true);
    if (!isFullfilled) {
      Message.error('出貨數目不足');
      return;
    }

    const keys = selectedRowKeys.map((key) => parseInt(key));
    const allocated = data.warehouseOptions.filter(
      (option) => keys.indexOf(option.id) !== -1 && option.outQuantity !== ''
    );

    // sort by material id
    allocated.sort((a, b) =>
      a.materialId > b.materialId ? 1 : b.materialId > a.materialId ? -1 : 0
    );

    // sort by outQuantity DESC
    allocated.sort((a, b) => {
      return parseFloat(a.outQuantity) > parseFloat(b.outQuantity) ? 1 : -1;
    });

    // calculate items
    const target = structuredClone(data.details);
    const allocated2 = structuredClone(allocated);
    // payload
    const items = [] as any[];
    target.forEach((target) => {
      // allocated.forEach((option) => {});
      while (parseFloat(target.quantity) > 0) {
        // find item in allocated of the same materiaId and subtract target.quantity
        const allocatedItem = allocated2.filter(
          (option) => option.materialId === target.materialId
        );
        if (allocatedItem.length === 0) {
          Message.error(
            `Invalid - No options with materialID: ${target.materialId} selected.`
          );
          break;
        }
        allocatedItem.forEach((option) => {
          if (parseFloat(option.outQuantity) > 0) {
            // if ask quantity <= allocated quantity
            if (parseFloat(target.quantity) <= parseFloat(option.outQuantity)) {
              // success - target item fulfilled
              option.outQuantity = (
                parseFloat(option.outQuantity) - parseFloat(target.quantity)
              ).toString();
              items.push({
                materialBarcode: option.barcode,
                materialInputUnitQuantity: target.quantity,
                materialInputUnit: target.quantityUnit,
                deliveryOrderDetailId: target.deliveryOrderDetailId,
              });
              target.quantity = '0';
            } else {
              target.quantity = (
                parseFloat(target.quantity) - parseFloat(option.outQuantity)
              ).toString();
              items.push({
                materialBarcode: option.barcode,
                materialInputUnitQuantity: option.outQuantity,
                materialInputUnit: target.quantityUnit,
                deliveryOrderDetailId: target.deliveryOrderDetailId,
              });
              option.outQuantity = '0';
            }
          }
        });
      }
    });

    const payload = {
      orderId: hubDeliveryOrderId,
      items: [
        {
          palletCategoryId: 1,
          totalGrossWeight: '0',
          totalNetWeight: '0',
          totalNumberOfBoxes: 0,
          volumeLength: '0',
          volumeWidth: '0',
          volumeHeight: '0',
          materialItems: items.filter(
            (item) => parseFloat(item.materialInputUnitQuantity) > 0
          ),
        },
      ],
    };
    postDeliveryOrderShip(payload)
      .then((res) => {
        Message.success('出貨成功');
        // refetchHubOrderDetail();
      })
      .then(() => {
        setDeferHubDeliveryId(null);
      })
      .catch((error) => {
        Message.error(`出貨失敗: ${error.message}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // table columns
  const notDeductedOptionsTable = [
    { title: '料號', dataIndex: 'materialId' },
    { title: '母批', dataIndex: 'sapBatchId' },
    { title: '子批', dataIndex: 'subBatchId' },
    { title: '光洋靶編', dataIndex: 'solarBatchId' },
    { title: '客戶靶編', dataIndex: 'customerBatchId' },
    // { title: 'BARCODE', dataIndex: 'barcode' },
    { title: '庫存數量', dataIndex: 'quantity' },
    {
      title: '出貨數量',
      render(record: any, _index: number) {
        return (
          <InputField
            type="number"
            registerName={`_test${_index}`}
            value={record.out_quantity}
            onChange={(e) =>
              debounceHandleQuantityChange(_index, e.target.value)
            }
          />
        );
      },
    },
  ];

  // read only
  const deductedOptionsTable = [
    { title: '項次', dataIndex: 'deliveryOrderLine' },
    { title: '母批', dataIndex: 'sapBatchId' },
    { title: '子批', dataIndex: 'subBatchId' },
    { title: '出貨數量', dataIndex: 'quantity' },
  ];

  const detailColumns = [
    { title: '項次', dataIndex: 'line' },
    { title: '料號', dataIndex: 'materialId' },
    { title: '數量', dataIndex: 'quantity' },
    { title: '單位', dataIndex: 'quantityUnit' },
  ];

  // calculate order fullfillment status
  const checkFulfillment = () => {
    if (!data.details || !data.warehouseOptions) return;
    // update data.details[n].fulfilled if satisfied
    const need = {} as { [key: string]: number };
    data.details.forEach((item) => {
      if (!need[item.materialId]) {
        need[item.materialId] = 0.0;
      }
      need[item.materialId] += parseFloat(item.quantity);
    });

    const partitioned = {} as { [key: string]: number };
    data.warehouseOptions.forEach((option) => {
      const keys = selectedRowKeys.map((key) => parseInt(key));
      if (keys.indexOf(option.id) === -1) {
        return;
      }
      if (!partitioned[option.materialId]) {
        partitioned[option.materialId] = 0.0;
      }
      partitioned[option.materialId] +=
        option.outQuantity !== '' ? parseFloat(option.outQuantity) : 0;
    });

    // check if each material in partitioned > need
    const fulfilled = [] as boolean[];
    Object.keys(need).forEach((key) => {
      fulfilled.push(partitioned[key] >= need[key]);
    });
    setIsFullfilled(fulfilled.every((item) => item));
  };

  useEffect(() => {
    checkFulfillment();
  }, [selectedRowKeys]);

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setDeferHubDeliveryId(hubDeliveryOrderId);
        }}
      >
        出貨明細
      </Button>
      <Modal
        hideCloseIcon={false}
        loading={isLoading}
        onClose={() => {
          setDeferHubDeliveryId(null);
        }}
        open={deferHubDeliveryOrderId !== null && !!hubOrderDetail}
        size="extraLarge"
        style={{
          overflow: 'auto',
        }}
      >
        <ModalHeader>HUB倉領用出貨單</ModalHeader>
        <FormProvider {...formMethods}>
          <FormFieldsWrapper
            methods={formMethods}
            onSubmit={formSubmitCreator({
              successCallback: () => {
                const isAccount = !hubOrderDetail?.debitDate;
                refetchHubOrderDetail();
                Message.success(`${isAccount ? '扣帳成功' : '迴轉成功'}`);
              },
              errorCallback: (error) => {
                const isAccount = !hubOrderDetail?.debitDate;
                Message.error(
                  `${isAccount ? '扣帳失敗' : '迴轉失敗'}：${error?.message}`
                );
              },
            })}
          >
            <ModalBody>
              <div className={styles.wrapper}>
                <OrderDeliveryIdInput id={hubOrderDetail?.id} />
                <div className={styles['col-span-1']}>客戶名稱／編號：</div>
                <div className={styles['col-span-3']}>
                  {hubOrderDetail?.customerName || '-'}
                </div>
                <div className={styles['col-span-1']}>出貨單日期：</div>
                <div className={styles['col-span-1']}>
                  {hubOrderDetail?.shipDate || '-'}
                </div>
                <OrderAccountDatePicker debitDate={hubOrderDetail?.debitDate} />
                <OrderReverseDatePicker
                  debitDate={hubOrderDetail?.debitDate}
                  reverseDate={hubOrderDetail?.reverseDate}
                />
              </div>
            </ModalBody>
            <ModalBody>
              <div className={styles.wrapper}>
                <Table
                  scroll={{ y: 250 }}
                  dataSource={
                    data.details?.map((item) => {
                      return {
                        ...item,
                        id: item.deliveryOrderDetailId,
                      };
                    }) || []
                  }
                  columns={detailColumns}
                />
              </div>
              <br />
              {!isDeducted ? (
                <div className={styles.wrapper}>
                  <Table
                    scroll={{ y: 250 }}
                    columns={notDeductedOptionsTable}
                    dataSource={data.warehouseOptions ?? []}
                    rowSelection={{
                      selectedRowKey: selectedRowKeys,
                      onChange: (keys) => setSelectedRowKeys(keys),
                    }}
                  />
                </div>
              ) : (
                <div className={styles.wrapper}>
                  <Table
                    scroll={{ y: 250 }}
                    columns={deductedOptionsTable}
                    dataSource={data.warehouseOptions ?? []}
                  />
                </div>
              )}
            </ModalBody>
            {!hubOrderDetail?.reverseDate && !hubOrderDetail?.debitDate && (
              <ModalActions
                cancelText="取消"
                confirmText="確認扣帳"
                confirmButtonProps={{ type: 'submit', loading: loading }}
                cancelButtonProps={{ type: 'button' }}
                onCancel={() => {
                  setDeferHubDeliveryId(null);
                }}
                onConfirm={() => {
                  submitOutQuantity();
                }}
              ></ModalActions>
            )}
            {!hubOrderDetail?.reverseDate &&
              hubOrderDetail?.debitDate &&
              !isDeducted && (
                <Button
                  variant="contained"
                  danger
                  style={{
                    marginTop: '16px',
                    marginLeft: '32px',
                    marginBottom: '16px',
                  }}
                  onClick={() => {
                    if (hubOrderDetail?.reverseDate) {
                      setDeferHubDeliveryId(null);
                    }
                  }}
                >
                  迴轉
                </Button>
              )}
          </FormFieldsWrapper>
        </FormProvider>
      </Modal>
    </>
  );
}
