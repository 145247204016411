import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { OffsetBased } from '../pagination';
import { useState } from 'react';

const namespace = API_NAMESPACE.MATERIALS;

type GetMembersParams = OffsetBased & {
  searchTerm?: string;
};

export function useGetMembers() {
  const [params, setParams] = useState<GetMembersParams>();
  const { data, error, mutate } = useSWR([
    '/hr/members',
    { params, namespace },
  ]);

  return {
    members: data?.records ?? [],
    isLoading: !data && !error,
    refetchGetMembers: setParams,
    mutateGetMembers: mutate,
  };
}
