import { Card, Col, Row, Space } from 'antd';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { BarcodeScanInput } from '../../Material/MaterialShift/BarcodeScanInput';
import {
  InventoryByMaterialSearchType,
  MaterialResponseData,
  getInventoryByMaterial,
  useUnitConversionRatiosByMaterialId,
} from '@solar/data';
import {
  Button,
  Message,
  Select,
  Option,
  Typography,
  SelectValue,
  Icon,
  Table,
} from '@mezzanine-ui/react';
import { UseFieldArrayReturn, useFormContext, useWatch } from 'react-hook-form';
import { InputField } from '@mezzanine-ui/react-hook-form';
import { FormValues } from './ExchangeMaterialsCard';
import { CancelIcon } from '@mezzanine-ui/icons';
import {
  CopyTextWrapper,
  QuantityWithUnitsColumn,
  useDraggableColumns,
  useTargetModal,
} from '@solar/templates';
import { ElementRatioTable } from './ElementRatioTable';
import { useParams } from 'react-router';

type UseExchangePairsArray = UseFieldArrayReturn<FormValues, 'exchangePairs'>;

export function ExchangeUnexpectedMaterialCard({
  item,
  arrayMethods,
  removeUnexpectedMaterial,
}: {
  item: MaterialResponseData;
  arrayMethods: UseExchangePairsArray;
  removeUnexpectedMaterial: () => void;
}) {
  const { orderId } = useParams<{ orderId: string }>();
  const methods = useFormContext<FormValues>();
  const { fields, append, remove } = arrayMethods;
  const [loading, setLoading] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState<SelectValue>();

  const unexpectedMaterialTable = useRef<HTMLTableElement>(null);
  const inputMaterialTable = useRef<HTMLTableElement>(null);

  const { data: units } = useUnitConversionRatiosByMaterialId({
    materialId: item?.id,
  });

  const targetIndex = fields.findIndex(
    (field) => !field.isExpected && field.toMaterialId === item?.id
  );

  const target = useWatch({
    name: `exchangePairs.${targetIndex}`,
    control: methods.control,
  });

  useEffect(() => {
    if (units?.[0]) {
      setSelectedUnit({
        id: units?.[0]?.materialInputUnit,
        name: units?.[0]?.materialInputUnit,
      });
    }
  }, [units]);

  const onBarcodeScan = useCallback(
    async ({
      searchTerm,
      searchType,
    }: {
      searchTerm: string | undefined;
      searchType: InventoryByMaterialSearchType;
    }) => {
      try {
        setLoading(true);

        if (!searchTerm) {
          Message.error('請輸入條碼');
          return;
        }

        const res = await getInventoryByMaterial({
          type: searchType,
          offset: 0,
          limit: 20,
          searchTerms: [searchTerm ?? ''],
        });

        if (res?.records?.length === 0) {
          Message.error('沒有匹配的資料');
          return;
        }

        const inventory = res?.records?.[0];

        const existedInventory = fields.find(
          (field) =>
            !field.isExpected &&
            field.toMaterialId === item?.id &&
            field.fromMaterialId === inventory?.materialId &&
            field.fromMaterialSapBatchId === inventory?.materialSapBatchId &&
            field.fromMaterialSubBatchId === inventory?.materialSubBatchId
        );

        if (existedInventory) {
          Message.error('非計畫產出已經存在投入物料條碼');
          return;
        }

        if (inventory?.materialInputUnit !== selectedUnit?.id) {
          Message.error(
            `物料單位不一致，此條碼管理單位為"${inventory?.materialInputUnit}"`
          );
          return;
        }

        append({
          isExpected: false,
          fromLoaderId: inventory?.loaderId ?? '',
          fromMaterialId: inventory?.materialId ?? '',
          fromMaterialSapBatchId: inventory?.materialSapBatchId ?? '',
          fromMaterialSubBatchId: inventory?.materialSubBatchId ?? '',
          fromMaterialDescription: inventory?.materialDescription ?? '',
          fromMaterialInputUnit: inventory?.materialInputUnit ?? '',
          toMaterialId: item?.id ?? '',
          materialBarcode: inventory?.materialBarcode ?? '',
          quantity: inventory?.availableMaterialInputUnitQuantity ?? '0',
          materialInputUnit: inventory?.materialInputUnit ?? '',
          materialStockUnit: inventory?.materialStockUnit ?? '',
          stockOverInputUnitRatio: inventory?.stockOverInputUnitRatio ?? '0',
          shelfId: inventory?.shelfId ?? '',
          loaderId: inventory?.loaderId ?? '',
        });
      } catch (err) {
        if (err instanceof Error) {
          Message.error(JSON.parse(err.message).message);
        } else {
          Message.error(JSON.stringify(err));
        }
      } finally {
        setLoading(false);
      }
    },
    [append, fields, item?.id, selectedUnit?.id]
  );

  const hasMatchedExchangePair = fields.some(
    (field) => !field.isExpected && field.toMaterialId === item?.id
  );

  const unexpectedMaterialTableColumns = useDraggableColumns<any>(
    unexpectedMaterialTable,
    [
      {
        draggable: true,
        width: 300,
        title: '物料',
        render: (source) => <CopyTextWrapper text={source?.id ?? ''} />,
      },
      {
        dataIndex: 'description',
        title: '品名',
      },
      {
        title: '單位',
        align: 'end',
        render: (source) => (
          <Select fullWidth value={selectedUnit} onChange={setSelectedUnit}>
            {units?.map((unit) => (
              <Option
                key={unit.materialInputUnit}
                value={unit.materialInputUnit}
              >
                {unit.materialInputUnit}
              </Option>
            ))}
          </Select>
        ),
      },
    ]
  );

  const inputMaterialTableColumns = useDraggableColumns<
    FormValues['exchangePairs'][number]
  >(inputMaterialTable, [
    {
      width: 120,
      title: '操作',
      render: (source) => (
        <Space>
          <Button
            danger
            variant="text"
            onClick={() => {
              const index = fields.findIndex(
                (field) =>
                  !field.isExpected &&
                  field.toMaterialId === item?.id &&
                  field.materialBarcode === source?.materialBarcode
              );
              remove(index);
            }}
          >
            移除
          </Button>
        </Space>
      ),
    },
    {
      draggable: true,
      width: 500,
      title: '投入物料條碼',
      render: (source) => (
        <CopyTextWrapper text={source?.materialBarcode ?? ''} />
      ),
    },
    {
      draggable: true,
      title: '品名',
      render: (source) => (
        <CopyTextWrapper text={source?.fromMaterialDescription ?? ''} />
      ),
    },
    {
      width: 180,
      dataIndex: 'shelfId',
      title: '儲位',
    },
    {
      width: 120,
      dataIndex: 'loaderId',
      title: '載具',
    },
    {
      width: 300,
      title: '投入數量',
      align: 'end',
      render: (source) => {
        const index = fields.findIndex(
          (field) =>
            !field.isExpected &&
            field.toMaterialId === item?.id &&
            field.materialBarcode === source?.materialBarcode
        );
        return (
          <Space>
            <InputField registerName={`exchangePairs.${index}.quantity`} />
            {source?.materialInputUnit}
          </Space>
        );
      },
    },
  ]);

  return (
    <Card
      style={{ width: '100%' }}
      extra={
        <Icon
          icon={CancelIcon}
          onClick={() => {
            removeUnexpectedMaterial();
          }}
        />
      }
    >
      <Space
        direction="vertical"
        size="large"
        style={{ width: '100%', padding: '16px 0' }}
      >
        <Card.Meta
          title={
            <Table
              ref={unexpectedMaterialTable}
              scroll={{ x: 1500 }}
              columns={unexpectedMaterialTableColumns}
              dataSource={[item]}
            />
          }
        />
        <BarcodeScanInput
          // disabled={hasMatchedExchangePair}
          placeholder="請輸入投入物料條碼"
          otherOnKeyDownAction={async (searchTerm) => {
            await onBarcodeScan({
              searchTerm,
              searchType: InventoryByMaterialSearchType.MATERIAL_BARCODE,
            });
          }}
        />
        <Table
          ref={inputMaterialTable}
          scroll={{ x: 1500 }}
          dataSource={fields.filter(
            (field) => !field?.isExpected && field.toMaterialId === item?.id
          )}
          columns={inputMaterialTableColumns}
          expandable={{
            rowExpandable: () => true,
            expandedRowRender: (record) => (
              <ElementRatioTable
                orderId={orderId}
                barcode={record?.materialBarcode as string}
              />
            ),
          }}
        />
      </Space>
    </Card>
  );
}
