import { useModalGroupController } from '../../Templates/Modal/ModalGroup';

export enum Modals {
  DISPATCH = 'DISPATCH',
  SCRAP = 'SCRAP',
}

export interface ColumnHooks {
  modalGroupController: ReturnType<typeof useModalGroupController>;
  prevModalNameRef: React.MutableRefObject<string | null>;
}

export type PostMiscMaterialPayload = {
  quantity: string | null;
  batchUsage: [
    {
      batchId: string;
      quantity: string;
    }
  ];
  targetShelfId: string | null;
};
