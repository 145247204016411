import { Table } from '@mezzanine-ui/react';
import { useGetMaterialMachiningCodes } from './hooks';

export function MaterialMachiningCodePanel() {
  const { machiningCodes, isLoading } = useGetMaterialMachiningCodes();
  return (
    <Table
      loading={isLoading}
      dataSource={machiningCodes ?? []}
      scroll={{ y: 550 }}
      columns={[
        { width: 150, title: '代碼', dataIndex: 'code' },
        { title: '對應值', dataIndex: 'name' },
      ]}
    />
  );
}
