export type Pagination = {
  page: number;
  pageSize: number;
};

export type NextPagination = {
  nextPage?: number;
  nextPageSize?: number;
};

export type OffsetBased = {
  limit?: number;
  offset?: number;
};

export type PageInfo = {
  limit?: number;
  offset?: number;
  totalCount?: number;
  totalPages?: number;
  hasNext?: boolean;
  hasPrevious?: boolean;
};

const defaultPageInfo = {
  limit: 20,
  offset: 0,
  totalCount: 0,
  totalPages: 0,
  hasNext: false,
  hasPrevious: false,
};

export function getNextParams(
  nextPagination: NextPagination,
  pageInfo?: PageInfo
) {
  const offset = pageInfo?.offset ?? defaultPageInfo.offset;
  const limit = pageInfo?.limit ?? defaultPageInfo.limit;
  const page = offset / limit + 1;
  const pageSize = limit;
  const nextPage = nextPagination?.nextPage ?? page;
  const nextPageSize = nextPagination?.nextPageSize ?? pageSize;

  return {
    offset: (nextPage - 1) * nextPageSize,
    limit: nextPageSize,
  };
}

export type PaginatedResponse<RecordProps> = {
  records: Array<RecordProps>;
  pageInfo: PageInfo;
};
