export const MACHINE_CODE_MAP: Record<string, string> = {
  AA: '脫水作業',
  AB: '剝金',
  AC: '濃縮',
  AD: '分類作業',
  AE: '拆解作業',
  AF: '顎碎作業',
  AG: '破碎作業',
  AH: '細碎作業',
  AJ: '手動配料',
  AK: '封罐作業',
  BA: '烘乾作業',
  BB: '熱處理作業',
  BC: '退火作業',
  BD: '退磁作業',
  BE: '氧化還原作業',
  BF: '煆燒製程',
  BG: '脫腊作業',
  BH: '預燒作業',
  BJ: '燒結作業',
  BK: '水焠火',
  BS: 'SPS燒結',
  CA: 'CIP作業',
  CB: 'HIP作業',
  CC: '壓鑄和沖壓',
  CD: '鍛壓作業',
  CE: '壓延',
  CF: '整平',
  CG: '矯直',
  CH: '銲合作業',
  DA: '鋸切作業',
  DB: '線割作業',
  DC: '裁切',
  DD: '圓鋸',
  DE: '水刀作業',
  DF: '修胚作業',
  DG: '粗車作業',
  DH: '精車作業',
  DI: '粗銑作業',
  DJ: '平面拋光作業',
  DK: '精銑作業',
  DL: '放電加工作業',
  DM: '粗磨作業',
  DN: '精磨作業',
  DP: '外圓粗研磨',
  DQ: '外圓精研磨',
  DR: '內圓精研磨',
  EA: '真空乾燥',
  EB: '噴砂作業',
  EC: '打印作業',
  ED: '粗洗作業',
  EM: '酸洗作業',
  EE: '清洗作業',
  EF: '包裝作業',
  EG: '水洗作業',
  EH: '裝箱作業',
  EL: 'UCSM雷射噴砂',
  EJ: 'UT探傷',
  EK: '手動拋磨作業',
  GA: '秤重',
  GB: '藥劑浸泡',
  GC: '水洗剝膜',
  GD: '分類',
  GE: '過篩',
  GF: '破碎',
  GG: '擠壓',
  GH: '脫水',
  GI: '烘乾',
  GJ: '焚化',
  GK: '重熔取樣',
  GL: '取樣機',
  GM: '研磨',
  GN: '20 mesh 過篩',
  GO: '35 mesh 過篩',
  GP: '混合',
  GQ: '六分',
  GR: '二分',
  GS: '取樣棒',
  GT: '勺取',
  GU: '製樣',
  GV: '酸溶取樣',
  GW: '廢液取樣',
  X0: '不良品待判定',
  X1: '不良品待加工',
  X2: '不良品待重熔',
  X3: '不良品待回收',
  R0: '回靶待判定',
  R1: '回靶待加工',
  R2: '回靶待重熔',
  R3: '回靶待回收',
  R4: '回靶待填料',
};
