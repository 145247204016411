import { useCallback } from 'react';
import { alertTemplate } from '../utils';
import {
  MaterialShiftInputFields,
  ScanBarCodeActionResult,
} from '../shift-action.enum';
import { KeySourcePairType, ShiftTableColumnProps } from '../typings';

export default function useInputGuard<T extends ShiftTableColumnProps>(
  keySourcePair: KeySourcePairType<T>
) {
  const result = useCallback(
    (targetField: MaterialShiftInputFields): ScanBarCodeActionResult => {
      const othersField = Object.keys(keySourcePair).filter(
        (key) => key !== targetField
      );

      for (let i = 0; i < othersField?.length; i++) {
        if (
          keySourcePair[othersField[i] as MaterialShiftInputFields].dataSource
            .length
        ) {
          alert(
            alertTemplate(
              keySourcePair[othersField[i] as MaterialShiftInputFields]
                .fieldTranslation
            )
          );

          return ScanBarCodeActionResult.Prohibit;
        }
      }

      return ScanBarCodeActionResult.OK;
    },
    [keySourcePair]
  );

  return result;
}
