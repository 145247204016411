import { SelectValue } from '@mezzanine-ui/react';
import { labelAndRegisterKeyArray } from './const';

export enum Modals {
  CreateMaterial = 'CreateMaterial',
}

export type UpdateMiscMaterialPayload = {
  groupId: string;
  categoryId: string;
  subCategoryId: string;
  description: string | null;
  eraseOnPick: boolean;
  unit?: string;
};

export type Writeable<T> = { -readonly [P in keyof T]: T[P] };
export type RegisterNames = {
  [K in typeof labelAndRegisterKeyArray[number]['registerName']]: SelectValue | null;
};
