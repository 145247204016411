import { API_NAMESPACE, request } from '@solar/data';
import { BaseLoaderSpec, CreateLoaderBody } from '../types';

const baseConfig = {
  namespace: API_NAMESPACE.MATERIALS,
  responseParser: (res: any) => res,
};

export const editLoaderSpec = (loaderId: string, body: BaseLoaderSpec) => {
  return request(`/loaders/${loaderId}`, {
    method: 'put',
    body: JSON.stringify({
      ...body,
    }),
    ...baseConfig,
  });
};

export const deleteLoaderByLoaderId = (loaderId: string) => {
  return request(`/loaders/${loaderId}`, {
    method: 'delete',
    ...baseConfig,
  });
};

export const createLoader = (body: CreateLoaderBody) => {
  return request(`/loaders`, {
    method: 'post',
    body: JSON.stringify(body),
    ...baseConfig,
  });
};
