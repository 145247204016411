import {
  Button,
  Message,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  Typography,
} from '@mezzanine-ui/react';
import {
  FormFieldsWrapper,
  InputField,
  RadioGroupField,
} from '@mezzanine-ui/react-hook-form';
import { Fragment, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAutoCompleteMaterialField } from './hooks/use-auto-complete-material-field';
import styles from './production-form-creator.module.scss';
import { ProductionFormCreatorFormFields } from './typings';
import { useAutoCompleteRecipeField } from './hooks/use-auto-complete-recipe-field';
import { useAutoCompleteProcessingField } from './hooks/use-auto-complete-processing-field';
import { useAutoCompleteWorkCenterField } from './hooks/use-auto-complete-work-center-field';
import { useAutoCompleteEquipmentField } from './hooks/use-auto-complete-equipment-field';
import { useAutoCompleteFormTemplateField } from './hooks/use-auto-complete-form-template-field';
import {
  API_NAMESPACE,
  checkJWTRoles,
  environment,
  ProductionFormCondition,
} from '@solar/data';

const TYPE_OPTIONS = [
  {
    value: 'QUESTION',
    label: '題目',
  },
  {
    value: 'PARAMETER',
    label: '參數',
  },
];

const defaultBaseValues = {
  type: 'QUESTION',
} as ProductionFormCreatorFormFields;

export function ProductionFormCreator({
  materialId,
  recipeId,
  processingId,
  workCenterId,
  equipmentId,
  formTemplate,
}: ProductionFormCondition) {
  const [modalShown, setModalShown] = useState(false);

  const defaultValues = useMemo<ProductionFormCreatorFormFields>(
    () => ({
      ...defaultBaseValues,
      formTemplateId: formTemplate
        ? {
            id: formTemplate.id,
            name: formTemplate.name,
          }
        : undefined,
      formTemplateVersion: formTemplate?.versions[0]?.version,
      materialId: materialId
        ? {
            id: materialId,
            name: materialId,
          }
        : undefined,
      recipeId: recipeId
        ? {
            id: recipeId,
            name: recipeId,
          }
        : undefined,
      processingId: processingId
        ? {
            id: processingId,
            name: processingId,
          }
        : undefined,
      workCenterId: workCenterId
        ? {
            id: workCenterId,
            name: workCenterId,
          }
        : undefined,
      equipmentId: equipmentId
        ? {
            id: equipmentId,
            name: equipmentId,
          }
        : undefined,
    }),
    [
      equipmentId,
      formTemplate,
      materialId,
      processingId,
      recipeId,
      workCenterId,
    ]
  );

  const methods = useForm<ProductionFormCreatorFormFields>({
    defaultValues,
  });

  const autoCompleteMaterialField = useAutoCompleteMaterialField({
    methods,
    enabled: modalShown,
  });

  const autoCompleteRecipeField = useAutoCompleteRecipeField({
    methods,
    enabled: modalShown,
  });

  const autoCompleteProcessingField = useAutoCompleteProcessingField({
    methods,
    enabled: modalShown,
  });

  const autoCompleteWorkCenterField = useAutoCompleteWorkCenterField({
    methods,
    enabled: modalShown,
  });

  const autoCompleteEquipmentField = useAutoCompleteEquipmentField({
    methods,
    enabled: modalShown,
  });

  const autoCompleteFormTemplateField = useAutoCompleteFormTemplateField({
    methods,
    enabled: modalShown,
    disabled: !!formTemplate,
  });

  const type = methods.watch('type');

  const [submitting, setSubmitting] = useState(false);

  return (
    <Fragment>
      {checkJWTRoles([122]) && (
        <Button
          size="small"
          type="button"
          variant="contained"
          onClick={() => setModalShown(true)}
        >
          建立題目/參數
        </Button>
      )}
      <Modal
        size="large"
        onClose={() => setModalShown(false)}
        open={modalShown}
      >
        <ModalHeader>建立並綁定題目/參數</ModalHeader>
        <ModalBody>
          <FormFieldsWrapper className={styles.form} methods={methods}>
            <Typography variant="h6">條件參數</Typography>
            <div className={styles.conditions}>
              {autoCompleteMaterialField}
              {autoCompleteRecipeField}
              {autoCompleteEquipmentField}
              {autoCompleteWorkCenterField}
              {autoCompleteProcessingField}
            </div>
            <RadioGroupField
              options={TYPE_OPTIONS}
              registerName="type"
              disabled={!!formTemplate}
              label="類型"
            />
            {type === 'QUESTION' ? (
              <Fragment>
                <div className={styles.fullWidthField}>
                  {autoCompleteFormTemplateField}
                </div>
                <div className={styles.twoColumns}>
                  <InputField label="題目名稱" registerName="title" />
                  <InputField label="單位" registerName="suffix" />
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <InputField required label="名稱" registerName="key" />
                <InputField required label="參數" registerName="value" />
              </Fragment>
            )}
          </FormFieldsWrapper>
        </ModalBody>
        <ModalActions
          cancelButtonProps={{
            disabled: submitting,
          }}
          confirmButtonProps={{
            disabled: submitting,
          }}
          onCancel={() => {
            setModalShown(false);

            methods.reset();
          }}
          onConfirm={() => {
            methods.handleSubmit(async (data) => {
              if (data.type === 'QUESTION') {
                setSubmitting(true);

                try {
                  await fetch(
                    `${environment.API_HOST}${API_NAMESPACE.PP}/forms/bind`,
                    {
                      method: 'POST',
                      body: JSON.stringify({
                        recipeId: data.recipeId?.id ?? null,
                        processingId: data.processingId?.id ?? null,
                        materialId: data.materialId?.id ?? null,
                        workCenterId: data.workCenterId?.id ?? null,
                        equipmentId: data.equipmentId?.id ?? null,
                        templateId: data.formTemplateId?.id ?? null,
                        version: data.formTemplateVersion ?? null,
                        title: data.title ?? null,
                        suffix: data.suffix ?? null,
                      }),
                      headers: {
                        apikey: environment.API_KEY,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        ...(localStorage.getItem('accessToken')
                          ? {
                              Authorization: `Bearer ${localStorage.getItem(
                                'accessToken'
                              )}`,
                            }
                          : {}),
                      },
                    }
                  ).then((res) => res.json());

                  Message.success('題目已綁定');

                  setModalShown(false);

                  methods.reset();
                } finally {
                  setSubmitting(false);
                }
              } else {
                setSubmitting(true);

                try {
                  await fetch(
                    `${environment.API_HOST}${API_NAMESPACE.PP}/parameters/bind`,
                    {
                      method: 'POST',
                      body: JSON.stringify({
                        recipeId: data.recipeId?.id ?? null,
                        processingId: data.processingId?.id ?? null,
                        materialId: data.materialId?.id ?? null,
                        workCenterId: data.workCenterId?.id ?? null,
                        equipmentId: data.equipmentId?.id ?? null,
                        key: data.key ?? null,
                        value: data.value ?? null,
                      }),
                      headers: {
                        apikey: environment.API_KEY,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        ...(localStorage.getItem('accessToken')
                          ? {
                              Authorization: `Bearer ${localStorage.getItem(
                                'accessToken'
                              )}`,
                            }
                          : {}),
                      },
                    }
                  ).then((res) => res.json());

                  Message.success('參數已綁定');

                  setModalShown(false);

                  methods.reset();
                } finally {
                  setSubmitting(false);
                }
              }
            })();
          }}
          cancelText="取消"
          confirmText="綁定題目"
        />
      </Modal>
    </Fragment>
  );
}
