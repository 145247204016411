import {
  AutoCompleteField,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  createSubmission,
  getJWTPayload,
  useGetSubmissionOrganizations,
} from '@solar/data';
import {
  ModalGroup,
  PageLayout,
  RowSection,
  useModalGroupController,
} from '@solar/templates';
import { Col, Row, Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import {
  SendForInspectionFormValues,
  defaultValues,
} from './send-for-inspection';
import { Button, Message } from '@mezzanine-ui/react';
import { Modals } from './modals.enum';
import { AppendSampleModal } from './AppendSampleModal';
import { SampleList } from './SampleList';
import { labOptions } from '../hooks/typing';

export function SendForInspectionPage() {
  const [isCreating, setIsCreating] = useState(false);
  const modalGroupController = useModalGroupController([
    { name: Modals.APPEND_SAMPLE },
  ]);
  const methods = useForm<SendForInspectionFormValues>({ defaultValues });

  const sampleMethods = useFieldArray({
    control: methods.control,
    name: 'samples',
  });

  const username = useMemo(() => {
    const userInfo = getJWTPayload();
    return userInfo?.Name;
  }, []);

  const openAppendSampleModal = () => {
    modalGroupController.openModal(Modals.APPEND_SAMPLE, {});
  };

  const targetFactory =
    useWatch({
      control: methods.control,
      name: 'factory',
    })?.id ?? '1100';

  useEffect(() => {
    refetchGetSubmissionOrganizations({ factory: targetFactory });
  }, [targetFactory]);

  const { submissionOrganizations, refetchGetSubmissionOrganizations } =
    useGetSubmissionOrganizations({ factory: targetFactory });

  const organizationOptions = submissionOrganizations?.map(
    ({ department }) => ({
      id: department,
      name: department,
    })
  );

  const onOrganizationsSearch = (searchTerm: string) => {
    refetchGetSubmissionOrganizations({ factory: targetFactory, searchTerm });
  };

  const onSubmit = methods.handleSubmit(async (data) => {
    try {
      setIsCreating(true);
      await createSubmission({
        ...data,
        factory: data.factory.id,
        createdBy: username,
        submissionOrganization: data.department?.id ?? '',
        needPaperReport: false,
        samples: data.samples.map((s) =>
          Object.assign(s, {
            sampleAmount: Number(s.sampleAmount),
            emptyBagWeight: Number(s.emptyBagWeight),
            sampleNetWeight: Number(s.sampleNetWeight),
            sampleRemainWeight: Number(s.sampleRemainWeight),
          })
        ),
      });
      Message.success('建立成功');
      methods.reset(defaultValues);
    } catch (error) {
      console.error(error);
      Message.error('建立失敗');
    } finally {
      setIsCreating(false);
    }
  });

  return (
    <PageLayout title="庫存送檢">
      <FormFieldsWrapper methods={methods}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <RowSection label="實驗室">
            <SelectField required registerName="factory" options={labOptions} />
          </RowSection>
          <RowSection label="委託部門">
            <AutoCompleteField
              required
              registerName="department"
              options={organizationOptions}
              onSearch={onOrganizationsSearch}
              disabledOptionsFilter
            />
          </RowSection>
          <RowSection label="報告收件人">
            <InputField registerName="reportReceiver" required />
          </RowSection>
          <RowSection label="E-mail">
            <InputField registerName="reportReceiverEmail" required />
          </RowSection>
          <RowSection label="建單人">{username}</RowSection>
          <Row justify="space-between">
            <Col>
              <Button
                type="button"
                variant="outlined"
                onClick={openAppendSampleModal}
              >
                新增樣品
              </Button>
            </Col>
            <Col>
              <Button
                type="button"
                variant="contained"
                loading={isCreating}
                onClick={onSubmit}
              >
                建立檢驗單
              </Button>
            </Col>
          </Row>
        </Space>
      </FormFieldsWrapper>
      <SampleList
        fields={sampleMethods.fields}
        remove={sampleMethods.remove}
        factory={targetFactory}
      />
      <ModalGroup {...modalGroupController}>
        <AppendSampleModal
          factory={targetFactory}
          appendSample={sampleMethods.append}
        />
      </ModalGroup>
    </PageLayout>
  );
}
