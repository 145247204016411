import { Tag } from '@mezzanine-ui/react';
import classes from './tag.module.scss';

export enum InspectStatus {
  PARTIAL_RECEIVE = 'PARTIAL',
  WAITING_RECEIVE = 'WAITING',
  FINISH_RECEIVE = 'COMPLETE',
}

type InspectStatusTag = {
  status: InspectStatus;
};
export function InspectStatusTag({ status }: InspectStatusTag) {
  switch (status) {
    case InspectStatus.PARTIAL_RECEIVE:
      return (
        <Tag
          className={classes.tag}
          style={{
            color: '#454545',
            backgroundColor: 'rgba(247, 172, 56, 0.2)',
          }}
        >
          部分已收
        </Tag>
      );
    case InspectStatus.WAITING_RECEIVE:
      return (
        <Tag
          className={classes.tag}
          style={{
            color: '#AF1C12',
            backgroundColor: 'rgba(219, 98, 88, 0.15)',
          }}
        >
          待收料
        </Tag>
      );
    case InspectStatus.FINISH_RECEIVE:
      return (
        <Tag
          className={classes.tag}
          style={{
            color: '#0C5D19',
            backgroundColor: 'rgba(66, 174, 74, 0.15)',
          }}
        >
          已收齊
        </Tag>
      );
    default:
      return <div>{null}</div>;
  }
}

export const INSPECT_STATUS_OPTIONS = [
  { id: InspectStatus.FINISH_RECEIVE, name: '已收齊' },
  { id: InspectStatus.PARTIAL_RECEIVE, name: '部分已收' },
  { id: InspectStatus.WAITING_RECEIVE, name: '待收料' },
];
