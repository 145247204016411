import type { CustomerTradeConditionCreateFormValues } from '@solar/data';
import styles from './index.module.scss';
import {
  CustomerTradeConditionCreateFormFieldName as FormFieldName,
  useCustomerTradeConditionFilterGet,
} from '@solar/data';
import { useFormContext } from 'react-hook-form';
import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';

const _PrivateFieldName = '_' + FormFieldName.CUSTOMER_ID;

export function CustomerAutoComplete() {
  const formMethods = useFormContext<CustomerTradeConditionCreateFormValues>();

  const { customers } = useCustomerTradeConditionFilterGet();

  return (
    <>
      <div className={styles['col-span-1']}>客戶：</div>
      <div className={styles['col-span-3']}>
        <AutoCompleteField
          registerName={_PrivateFieldName}
          options={customers}
          onChange={(selected) => {
            // update react-hook-form by oneself because the format from @mezzanine is not quite suitable.
            formMethods.setValue(FormFieldName.CUSTOMER_ID, selected.id);
          }}
        />
      </div>
    </>
  );
}
