import { SelectValue } from '@mezzanine-ui/react';
import { useState } from 'react';
import useSWR from 'swr';
import { OffsetBased, PageInfo } from '../pagination';
import { API_NAMESPACE } from '../request';

export type GetCustomersParams = {
  searchTerm: string;
};

const namespace = API_NAMESPACE.MATERIALS;
export function useCustomers() {
  const [params, setParams] = useState<
    OffsetBased & Partial<GetCustomersParams>
  >({
    offset: 0,
    limit: 20,
  });

  const { data, error } = useSWR<{
    records: Array<SelectValue>;
    pageInfo: PageInfo;
  }>([
    '/customers',
    {
      params,
      namespace,
    },
  ]);

  return {
    records: data?.records || [],
    pageInfo: data?.pageInfo,
    isLoading: !data && !error,
    mutateParams: setParams,
  };
}
