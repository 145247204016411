import { Button, Input, SelectValue } from '@mezzanine-ui/react';
import { FormFieldsWrapper, SelectField } from '@mezzanine-ui/react-hook-form';
import {
  useMaterialCategories,
  useMaterialGroups,
  useMaterialViewKeyword,
  GetMaterialsParams,
} from '@solar/data';
import { UseFormReturn } from 'react-hook-form';
import { Dispatch, SetStateAction, useState } from 'react';
import sharedStyles from '../shared-styles/index.module.scss';
import classes from './shared-material-basic-filter.module.scss';

type SharedMaterialBasicFilterProps = {
  methods: UseFormReturn;
  setParams: Dispatch<SetStateAction<GetMaterialsParams>>;
};

export function SharedMaterialBasicFilter({
  setParams,
  methods,
}: SharedMaterialBasicFilterProps) {
  const { materialCategories } = useMaterialCategories();
  const { materialGroups } = useMaterialGroups();
  const { materialViewKeyword } = useMaterialViewKeyword();

  const [keyword, setKeyword] = useState<string>('');

  return (
    <FormFieldsWrapper
      className={sharedStyles.filter_form_wrapper}
      methods={methods}
    >
      <div className={classes.host}>
        <SelectField
          label="物料類型："
          mode="multiple"
          registerName="categories"
          options={materialCategories ?? []}
        />
        <SelectField
          label="物料群組："
          mode="multiple"
          registerName="materialGroups"
          options={materialGroups ?? []}
        />
        <SelectField
          label="關鍵字對象："
          defaultValue={materialViewKeyword[0] as SelectValue}
          registerName="materialViewKeyword"
          options={materialViewKeyword ?? []}
        />
        <Input
          value={keyword}
          fullWidth
          onChange={(event) => setKeyword(event.target.value)}
          placeholder="請輸入關鍵字搜尋"
        />
        <div>
          <Button
            variant="contained"
            type="button"
            onClick={() => {
              const filterState = methods?.getValues();
              const searchType = filterState.materialViewKeyword.id ?? '';
              const searchTerm = filterState.searchInput ?? '';
              setParams((prevParams) => ({
                ...prevParams,
                searchType,
                searchTerm,
              }));
            }}
          >
            搜尋
          </Button>
        </div>
      </div>
    </FormFieldsWrapper>
  );
}
