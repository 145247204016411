import { useFormContext, useWatch } from 'react-hook-form';
import { BindingFormValues } from '../typing';

export function VirtualInMaterialId({
  virtualOutDeliveryOrderItemId,
}: {
  virtualOutDeliveryOrderItemId: string;
}) {
  const methods = useFormContext<BindingFormValues>();

  const watchedRelatedOrderMaterialId = useWatch({
    control: methods.control,
    name: `${virtualOutDeliveryOrderItemId}.relatedOrderMaterialId`,
  });

  return <span>{watchedRelatedOrderMaterialId}</span>;
}
