import useSWR from 'swr';
import {
  WorkCenter,
  WorkCenterBigClass,
  WorkCenterSmallClass,
} from './work-center.type';

export function useGetWorkCenters() {
  const { data, error } = useSWR<WorkCenter[]>('/utils/work-centers');

  return {
    workCenters: data,
    isLoading: !error && !data,
  };
}

export function useGetWorkCenterBigClasses() {
  const { data, error } = useSWR<WorkCenterBigClass[]>(
    '/utils/work-centers/big-classes'
  );

  return {
    workCenterBigClasses: data,
    isLoading: !error && !data,
  };
}

export function useGetWorkCenterSmallClasses({
  bigClassCode,
}: {
  bigClassCode?: string;
}) {
  const { data, error } = useSWR<WorkCenterSmallClass[]>(
    bigClassCode
      ? `/utils/work-centers/big-classes/${bigClassCode}/small-classes`
      : null
  );

  return {
    workCenterSmallClasses: data,
    isLoading: !error && !data,
  };
}
