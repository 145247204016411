import {
  GetDeliveryOrdersParams,
  NextPagination,
  WorkOrderStatus,
  getNextParams,
  useGetDeliveryOrders,
} from '@solar/data';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import {
  CopyTextWrapper,
  MznPaginationTable,
  PageLayout,
} from '@solar/templates';
import {
  DateRangePickerField,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { Button } from '@mezzanine-ui/react';
import moment from 'moment';
import { shippedStates } from '../Warehouse/MaterialSupplyPage/shipped-states';
import { Space } from 'antd';

type DeliveryOrderTableFilter = {
  rangeOfDate?: [string, string];
  status?: { id: WorkOrderStatus; name: string }[];
  searchTerm?: string;
};

const deliveryOrderTypes: GetDeliveryOrdersParams['types'] = [
  'ZLC',
  'ZLF1',
  'ZLF5',
];

export function VirtualOutAndInPage() {
  const navigate = useNavigate();
  const methods = useForm<DeliveryOrderTableFilter>();

  const { deliveryOrders, pageInfo, refetchGetDeliveryOrders } =
    useGetDeliveryOrders({
      processTypes: ['01'],
      types: deliveryOrderTypes,
    });

  const refetchGetDeliveryOrdersWithPagination = useCallback(
    (nextPagination: NextPagination) => {
      const formState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      refetchGetDeliveryOrders({
        processTypes: ['01'],
        types: deliveryOrderTypes,
        dateFrom: formState?.rangeOfDate?.[0],
        dateTo: formState?.rangeOfDate?.[1],
        packedStates: formState?.status?.map((obj) => obj.id),
        searchTerm: formState?.searchTerm,
        offset,
        limit,
      });
    },
    [methods, refetchGetDeliveryOrders, pageInfo]
  );

  useEffect(() => {
    refetchGetDeliveryOrdersWithPagination({ nextPage: 1 });
  }, [refetchGetDeliveryOrdersWithPagination]);

  return (
    <PageLayout title="虛出虛入綁定">
      <FormFieldsWrapper methods={methods}>
        <Space align="end">
          <InputField
            placeholder="請輸入關鍵字進行搜尋"
            registerName="searchTerm"
          />
          <SelectField
            mode="multiple"
            clearable
            label="狀態"
            registerName="status"
            options={shippedStates}
          />
          <DateRangePickerField label="日期區間" registerName="rangeOfDate" />
          <Button
            onClick={() =>
              refetchGetDeliveryOrdersWithPagination({
                nextPage: 1,
              })
            }
          >
            搜尋
          </Button>
        </Space>
      </FormFieldsWrapper>
      <MznPaginationTable
        pageInfo={pageInfo}
        fetchData={refetchGetDeliveryOrdersWithPagination}
        dataSource={deliveryOrders ?? []}
        columns={[
          {
            title: '操作',
            render: (source: any) => (
              <Button
                onClick={() =>
                  navigate(`/order/virtual-out-and-in/${source?.id}/binding`)
                }
              >
                檢視
              </Button>
            ),
          },
          {
            title: '出貨單號',
            render: (source: any) => (
              <CopyTextWrapper text={source?.id ?? ''} />
            ),
          },
          {
            title: '客戶名稱',
            dataIndex: 'customerName',
          },
          {
            title: '客戶代碼',
            dataIndex: 'customerId',
          },
          {
            title: '開立日期',
            render: (source) =>
              source?.createdAt
                ? moment(source?.createdAt).format('YYYY-MM-DD')
                : '',
          },
          {
            title: '狀態',
            render: (source) =>
              shippedStates.find((state) => state.id === source.packedState)
                ?.name,
          },
        ]}
      />
    </PageLayout>
  );
}
