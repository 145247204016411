import { Tab, TabPane, Tabs, Typography } from '@mezzanine-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerSupplyPanel } from './CustomerSupplyPanel';
import { InspectMaterialType } from './inspect-modal-enum';
import { OutsourcePurchaseOrderPanel } from './OutsourcePurchaseOrderPanel';
import { pageOptions } from './page-options';
import { PurchaseOrderPanel } from './PurchaseOrderPanel';
import { RentTargetPanel } from './RentTargetPanel';
import { HubReturnPanel } from './HubReturnPanel';
import { VirtualInPanel } from './VirtualInPanel';

export function IncomingInspectOperationPage() {
  const navigate = useNavigate();
  const { orderType } = useParams<{ orderType: `${InspectMaterialType}` }>();

  return (
    <div>
      <Typography variant="h1">{'物料入庫'}</Typography>
      <Tabs
        activeKey={orderType}
        onChange={(tabId) => navigate(`/incoming-inspect/${tabId}`)}
      >
        <TabPane key={pageOptions[0].id} tab={<Tab>{pageOptions[0].name}</Tab>}>
          <PurchaseOrderPanel />
        </TabPane>
        <TabPane key={pageOptions[1].id} tab={<Tab>{pageOptions[1].name}</Tab>}>
          <CustomerSupplyPanel />
        </TabPane>
        <TabPane key={pageOptions[2].id} tab={<Tab>{pageOptions[2].name}</Tab>}>
          <OutsourcePurchaseOrderPanel />
        </TabPane>
        <TabPane key="rent-target" tab={<Tab>一般與特殊銷退</Tab>}>
          <RentTargetPanel />
        </TabPane>
        <TabPane key="hub-return" tab={<Tab>hub 倉回廠</Tab>}>
          <HubReturnPanel />
        </TabPane>
        <TabPane key="virtual-in" tab={<Tab>虛入出貨單</Tab>}>
          <VirtualInPanel />
        </TabPane>
        {/* <TabPane key={pageOptions[3].id} tab={<Tab>{pageOptions[3].name}</Tab>}>
          <CustomerSupplyPanel />
        </TabPane> */}
      </Tabs>
    </div>
  );
}
