import { Checkbox, Modal, ModalBody, ModalHeader } from '@mezzanine-ui/react';
import { RowSection, useTargetModal } from '@solar/templates';
import { Modals } from './Modal.enum';
import { SubmissionResult } from '@solar/data';
import { Col, Collapse, Descriptions, Row, Space } from 'antd';
import { labOptions } from '../hooks/typing';

export function SubmissionDetailModal() {
  const { open, data, closeModal } = useTargetModal<SubmissionResult>(
    Modals.SUBMISSION_DETAIL
  );

  return (
    <Modal style={{ width: '1000px' }} open={open} onClose={closeModal}>
      <ModalHeader>{`檢驗單號：${data?.id}`}</ModalHeader>
      <ModalBody>
        <Descriptions column={4}>
          <Descriptions.Item label="實驗室">
            {labOptions?.find((lab) => lab?.id === data?.factory)?.name ?? '-'}
          </Descriptions.Item>
          <Descriptions.Item label="委託部門">
            {data?.metadata?.customerName ?? '-'}
          </Descriptions.Item>
          <Descriptions.Item label="建單人">
            {data?.createdBy ?? '-'}
          </Descriptions.Item>
          <Descriptions.Item label="狀態">
            {data?.statusDesc ?? '-'}({data?.status ?? '-'})
          </Descriptions.Item>
        </Descriptions>
        <Collapse size="large">
          {data?.samples?.map((sample, index) => (
            <Collapse.Panel
              key={sample.id}
              header={
                <Descriptions>
                  <Descriptions.Item label="樣品編號">
                    {sample?.sampleNumber ?? '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="料號">
                    {sample?.metadata?.materialId ?? '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="批號">
                    {sample?.metadata?.batchNumber ?? '-'}
                  </Descriptions.Item>
                </Descriptions>
              }
            >
              <Descriptions>
                <Descriptions.Item label="樣品名稱">
                  {sample?.name ?? '-'}
                </Descriptions.Item>
                <Descriptions.Item label="樣品狀態">
                  {sample?.metadata?.type ?? '-'}
                </Descriptions.Item>
                <Descriptions.Item label="測試種類">
                  {[
                    { value: '1', label: '首測' },
                    { value: '2', label: '保留樣複測' },
                    { value: '3', label: '重新取樣' },
                  ]?.find((t) => t.value === sample?.testType?.toString())
                    ?.label ?? '-'}
                </Descriptions.Item>
                <Descriptions.Item label="">
                  <Checkbox checked={sample?.metadata?.rushPj}>急件</Checkbox>
                  <Checkbox checked={sample?.metadata?.returnSample}>
                    樣品取回
                  </Checkbox>
                  <Checkbox checked={sample?.metadata?.accountTransfer}>
                    樣品轉帳
                  </Checkbox>
                </Descriptions.Item>
                <Descriptions.Item label="分析樣毛重">
                  {sample?.amount ?? '-'}
                </Descriptions.Item>

                <Descriptions.Item label="分析樣淨重">
                  {sample?.netWeight ?? '-'}
                </Descriptions.Item>
                <Descriptions.Item label="分析樣空重">
                  {sample?.emptyBagWeight ?? '-'}
                </Descriptions.Item>
                <Descriptions.Item label="分析後殘料重">
                  {sample?.remainingWeight ?? '-'} {sample?.sampleUnit ?? ''}
                </Descriptions.Item>
                <Descriptions.Item label="委託原因">
                  {sample?.metadata?.submitReason ?? '-'}
                </Descriptions.Item>
                <Descriptions.Item label="規格">
                  {sample?.metadata?.specification ?? '-'}
                </Descriptions.Item>
                <Descriptions.Item label="樣品說明/成分" span={3}>
                  {sample?.metadata?.description ?? '-'}
                </Descriptions.Item>
              </Descriptions>
              <Row>
                <Col flex="auto">檢測項目</Col>
                <Col flex="120px">項目結果</Col>
              </Row>
              {sample?.subSampleMethods?.flatMap((method) =>
                method?.subSampleMethodItems?.map((methodItem) => (
                  <Row key={methodItem?.id}>
                    <Col flex="auto">
                      <span>
                        {methodItem?.methodNumber ?? ''}|
                        {methodItem?.testItem?.split('_')?.[0] ?? ''}|
                        {methodItem?.factory ?? ''}
                      </span>
                    </Col>
                    <Col flex="120px">
                      <span>{methodItem?.result ?? ''}</span>
                      <span>{methodItem?.reportUnit ?? ''}</span>
                    </Col>
                  </Row>
                ))
              )}
            </Collapse.Panel>
          ))}
        </Collapse>
      </ModalBody>
    </Modal>
  );
}
