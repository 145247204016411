import { LocationLevel, LocationLevelConfig } from '../types';

export const LocationLevels: LocationLevelConfig[] = [
  {
    level: LocationLevel.FACTORY,
    label: '廠',
    svg: {
      show: true,
      includeLevels: [LocationLevel.BUILDING],
    },
  },
  {
    level: LocationLevel.BUILDING,
    label: '棟',
  },
  {
    level: LocationLevel.FLOOR,
    label: '樓',
    svg: {
      show: true,
      includeLevels: [LocationLevel.AREA],
    },
  },
  {
    level: LocationLevel.AREA,
    label: '大區',
    svg: {
      show: true,
      includeLevels: [
        LocationLevel.ZONE,
        LocationLevel.STACK,
        LocationLevel.SHELF,
      ],
    },
  },
  {
    level: LocationLevel.ZONE,
    label: '小區',
    svg: {
      show: false,
      includeLevels: [
        LocationLevel.ZONE,
        LocationLevel.STACK,
        LocationLevel.SHELF,
      ],
    },
  },
  {
    level: LocationLevel.STACK,
    label: '儲位',
    svg: {
      show: false,
      includeLevels: [
        LocationLevel.ZONE,
        LocationLevel.STACK,
        LocationLevel.SHELF,
      ],
    },
  },
  {
    level: LocationLevel.SHELF,
    label: '層架',
    svg: {
      show: false,
      includeLevels: [
        LocationLevel.ZONE,
        LocationLevel.STACK,
        LocationLevel.SHELF,
      ],
    },
  },
];
