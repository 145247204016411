import { Typography } from '@mezzanine-ui/react';
import { MainFileDeleteModalProps } from '../../MainFilePage/MainFileDeleteModal/MainFileDeleteModal';
import styles from '../../MainFilePage/MainFileDeleteModal/MainFileDeleteModal.module.scss';

export function OrderMarksDeleteModal({ source }: MainFileDeleteModalProps) {
  return (
    <div>
      <div className={styles.info}>
        <div className={styles.content}>
          <Typography variant="h6" color="text-secondary">
            客戶
          </Typography>
          <Typography>{source?.customerName}</Typography>
        </div>
        <div className={styles.fakeBorder}></div>
        <div className={styles.content}>
          <Typography variant="h6" color="text-secondary">
            嘜頭名稱
          </Typography>
          <Typography>{source?.name}</Typography>
        </div>
        <div className={styles.fakeBorder}></div>
        <div className={styles.content}>
          <Typography variant="h6" color="text-secondary">
            嘜頭尺寸 (長x寬 cm)
          </Typography>
          <Typography>{source?.size}</Typography>
        </div>
      </div>
      <div className={styles.border}></div>
      <div className={styles.info}>
        <div className={styles.content}>
          <Typography variant="h6" color="text-secondary">
            嘜頭料號
          </Typography>
          {source?.customerMaterialNumbers?.map((materialNumber: string) => (
            <Typography>{materialNumber}</Typography>
          ))}
        </div>
      </div>
    </div>
  );
}
