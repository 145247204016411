export const FEATURE_CODE_MAP: Record<string, string> = {
  CA: 'Pt<5%',
  CB: 'Pt<10%',
  CR: 'Pt>10%',
  CD: 'Pd<0.1%',
  CN: 'Pd>0.1%',
  CF: 'Ru<10%',
  CG: 'Ru>10%',
  CQ: 'Pd<5%',
  CI: 'Pt<5ppm、Pd<5ppm',
  CJ: 'Pt<20%或Pd<20%',
  CK: 'Pt<10%及Ru<10%',
  CL: 'Pt>15%及Ru>10%',
  CP: 'Pt>30%及Ru>10%',
  P1: '粉末製程版本1~9',
  PA: '自製Ru粉不純物大於V2規範',
  PB: '外購Ru粉不純物大於V2規範',
  PC: 'Pt粉穩懋規範',
  PD: 'Pt粉細粉特採',
  PE: 'Pt粉Osram規範',
  PF: 'Pt粉4N7特採',
  PG: 'RU粉物料來源天津鹼業氯化銨',
  PH: 'RU粉物料來源BASF氯化銨',
  PI: 'RU粉物料來源海化氯化銨',
  PJ: 'RU粉物料來源湖北氯化銨',
  PK: 'RU粉物料來源和達氯化銨',
  PL: 'RU粉-O：2000ppm<O含量 ≦2100ppm',
  PM: 'RU粉-CL：5ppm<Cl含量 ≦10ppm',
  PN: 'RU粉-NA3：Na>3ppm',
  PO: 'RU粉-NACl：夾渣Na or Cl元素，Cl含量 > 10ppm，Na>3ppm',
  PP: 'RU粉無還原前處理>Ru有還原/無還原',
  PQ: 'CM使用ZrO研磨球',
  PR: 'CM使用ST6研磨球',
  PS: 'Cr粉O<700ppm',
  PT: 'Co粉O<4500ppm',
  PU: 'Co粉Cl<10ppm',
  PV: 'Co粉Ni<50ppm',
  PW: 'Cr粉Zr<300ppm Fe<400ppm O<1800ppm N<500ppm',
  I1: '鑄錠製程版本1~9',
  IA: '方靶模具差異typeA',
  IB: '方靶模具差異typeB',
  IX: '低氧版',
  IY: 'BN粉品質差異D50<0.5um',
  IZ: 'BN粉品質差異D50<0.25um',
  E1: '靶胚製程版本1~9',
  TA: '靶材Type分類 A~Z',
  RF: '直接由回靶填補粉末製成新靶',
  RN: '直接由回靶改製薄靶出貨，半品補上爐號',
  UA: '長華科銀鉀,製程多一天淨化製程，與一般銀鉀成本不同',
  CE: 'UL批次,PGC設備需經清洗，要用純水不可使用母液，所以得料率比較差，生產成本較高。',
  SL: '低碳版',
  SF: '微碳版',
  SN: '無碳版',
  AE: 'N32EH',
  BM: 'N40M',
  BH: 'N40H',
  CM: 'N48M',
  VA: 'VIM',
  VH: 'VIM+HIP',
  HA: 'HP',
  HH: 'HP+HIP',
  CH: 'CANNING+HIP',
  AA: 'AIM',
  SH: 'SPS+HIP',
  FF: '未分類',
  CC: 'CP+CIP+脫蠟+燒結',
};
