import { request, API_NAMESPACE } from '../../request';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const CustomerTradeConditionCreateFormFieldName = {
  _MATERIAL_OPTIONAL: '_materialOptional',
  MATERIAL_ID: 'materialId',
  CUSTOMER_ID: 'customerId',
  COMPONENT_ITEM_NUMBER_1: 'componentItemNumber1',
  WEIGHT_METHOD_1: 'weightMethod1',
  ANALYSIS_VALUE_METHOD_1: 'analysisValueMethod1',
  COMPONENT_ITEM_NUMBER_2: 'componentItemNumber2',
  WEIGHT_METHOD_2: 'weightMethod2',
  ANALYSIS_VALUE_METHOD_2: 'analysisValueMethod2',
  COMPONENT_ITEM_NUMBER_3: 'componentItemNumber3',
  WEIGHT_METHOD_3: 'weightMethod3',
  ANALYSIS_VALUE_METHOD_3: 'analysisValueMethod3',
  COMPONENT_ITEM_NUMBER_4: 'componentItemNumber4',
  WEIGHT_METHOD_4: 'weightMethod4',
  ANALYSIS_VALUE_METHOD_4: 'analysisValueMethod4',
} as const;

const _FormFieldName = CustomerTradeConditionCreateFormFieldName;

export const customerTradeConditionCreateFormSchema = yup.object().shape({
  [_FormFieldName._MATERIAL_OPTIONAL]: yup.boolean(),
  [_FormFieldName.MATERIAL_ID]: yup.string().optional(),
  [_FormFieldName.CUSTOMER_ID]: yup.string().required(' '),
  [_FormFieldName.COMPONENT_ITEM_NUMBER_1]: yup.string().optional(),
  [_FormFieldName.WEIGHT_METHOD_1]: yup
    .string()
    .oneOf(['STANDARD', 'ACTUAL'])
    .optional(),
  [_FormFieldName.ANALYSIS_VALUE_METHOD_1]: yup
    .string()
    .oneOf(['STANDARD', 'ACTUAL'])
    .optional(),
  [_FormFieldName.COMPONENT_ITEM_NUMBER_2]: yup.string().optional(),
  [_FormFieldName.WEIGHT_METHOD_2]: yup
    .string()
    .oneOf(['STANDARD', 'ACTUAL'])
    .optional(),
  [_FormFieldName.ANALYSIS_VALUE_METHOD_2]: yup
    .string()
    .oneOf(['STANDARD', 'ACTUAL'])
    .optional(),
  [_FormFieldName.COMPONENT_ITEM_NUMBER_3]: yup.string().optional(),
  [_FormFieldName.WEIGHT_METHOD_3]: yup
    .string()
    .oneOf(['STANDARD', 'ACTUAL'])
    .optional(),
  [_FormFieldName.ANALYSIS_VALUE_METHOD_3]: yup
    .string()
    .oneOf(['STANDARD', 'ACTUAL'])
    .optional(),
  [_FormFieldName.COMPONENT_ITEM_NUMBER_4]: yup.string().optional(),
  [_FormFieldName.WEIGHT_METHOD_4]: yup
    .string()
    .oneOf(['STANDARD', 'ACTUAL'])
    .optional(),
  [_FormFieldName.ANALYSIS_VALUE_METHOD_4]: yup
    .string()
    .oneOf(['STANDARD', 'ACTUAL'])
    .optional(),
});

export type CustomerTradeConditionCreateFormValues = yup.InferType<
  typeof customerTradeConditionCreateFormSchema
>;

export function useCustomerTradeConditionCreate() {
  const formMethods = useForm<CustomerTradeConditionCreateFormValues>({
    defaultValues: {
      [_FormFieldName._MATERIAL_OPTIONAL]: false,
      [_FormFieldName.MATERIAL_ID]: undefined,
      [_FormFieldName.CUSTOMER_ID]: undefined,
      [_FormFieldName.COMPONENT_ITEM_NUMBER_1]: undefined,
      [_FormFieldName.WEIGHT_METHOD_1]: undefined,
      [_FormFieldName.ANALYSIS_VALUE_METHOD_1]: undefined,
      [_FormFieldName.COMPONENT_ITEM_NUMBER_2]: undefined,
      [_FormFieldName.WEIGHT_METHOD_2]: undefined,
      [_FormFieldName.ANALYSIS_VALUE_METHOD_2]: undefined,
      [_FormFieldName.COMPONENT_ITEM_NUMBER_3]: undefined,
      [_FormFieldName.WEIGHT_METHOD_3]: undefined,
      [_FormFieldName.ANALYSIS_VALUE_METHOD_3]: undefined,
      [_FormFieldName.COMPONENT_ITEM_NUMBER_4]: undefined,
      [_FormFieldName.WEIGHT_METHOD_4]: undefined,
      [_FormFieldName.ANALYSIS_VALUE_METHOD_4]: undefined,
    },
    resolver: yupResolver(customerTradeConditionCreateFormSchema),
  });

  return {
    formMethods,
    createFormSubmit,
    FormFieldName: _FormFieldName,
  };
}

async function postCustomerTradeConditionCreate({
  _materialOptional,
  materialId,
  customerId,
  componentItemNumber1,
  weightMethod1,
  analysisValueMethod1,
  componentItemNumber2,
  weightMethod2,
  analysisValueMethod2,
  componentItemNumber3,
  weightMethod3,
  analysisValueMethod3,
  componentItemNumber4,
  weightMethod4,
  analysisValueMethod4,
}: CustomerTradeConditionCreateFormValues) {
  try {
    const payload = {
      [_FormFieldName._MATERIAL_OPTIONAL]: _materialOptional,
      [_FormFieldName.MATERIAL_ID]: materialId,
      [_FormFieldName.CUSTOMER_ID]: customerId,
      [_FormFieldName.COMPONENT_ITEM_NUMBER_1]: componentItemNumber1,
      [_FormFieldName.WEIGHT_METHOD_1]: weightMethod1,
      [_FormFieldName.ANALYSIS_VALUE_METHOD_1]: analysisValueMethod1,
      [_FormFieldName.COMPONENT_ITEM_NUMBER_2]: componentItemNumber2,
      [_FormFieldName.WEIGHT_METHOD_2]: weightMethod2,
      [_FormFieldName.ANALYSIS_VALUE_METHOD_2]: analysisValueMethod2,
      [_FormFieldName.COMPONENT_ITEM_NUMBER_3]: componentItemNumber3,
      [_FormFieldName.WEIGHT_METHOD_3]: weightMethod3,
      [_FormFieldName.ANALYSIS_VALUE_METHOD_3]: analysisValueMethod3,
      [_FormFieldName.COMPONENT_ITEM_NUMBER_4]: componentItemNumber4,
      [_FormFieldName.WEIGHT_METHOD_4]: weightMethod4,
      [_FormFieldName.ANALYSIS_VALUE_METHOD_4]: analysisValueMethod4,
    };

    for (const key in payload) {
      const _key = key as keyof CustomerTradeConditionCreateFormValues;
      // remove undefined property
      if (typeof payload[_key] === 'undefined') delete payload[_key];
    }

    // remove materialId as materialOptional: true
    if (payload[_FormFieldName._MATERIAL_OPTIONAL]) {
      delete payload[_FormFieldName.MATERIAL_ID];
    }

    // remove private property
    delete payload[_FormFieldName._MATERIAL_OPTIONAL];

    return request('/customers/customer-trade-condition', {
      namespace: API_NAMESPACE.HR,
      method: 'POST',
      body: JSON.stringify(payload),
    });
  } catch (error) {
    return null;
  }
}

type FormSubmitCreatorProps = {
  successCallback?: (payload: any) => void;
  errorCallback?: (error: Error) => void;
};

function createFormSubmit({
  successCallback,
  errorCallback,
}: FormSubmitCreatorProps) {
  const onSubmit = async (data: CustomerTradeConditionCreateFormValues) => {
    postCustomerTradeConditionCreate(data)
      .then((payload) => {
        successCallback?.(payload);
      })
      .catch((error) => {
        errorCallback?.(error);
      });
  };

  return onSubmit;
}
