import { Table, Button, Popconfirm, Input } from '@mezzanine-ui/react';
import {
  ISPEditPageMode,
  SPFormOrderItemInfo,
  SPEditPageMode,
} from '@solar/data';
import { useState, MouseEvent } from 'react';
import debounce from 'lodash/debounce';
import SPItemHistoryModal from './SPItemHistoryModal';
import styles from './SPOrderItemTable.module.scss'

const formatNumber = (key: string) => (record: any) => {
  // 使用 Number() 函數將文字的數字轉換為數字
  const parsedNumber = Number(record[key]);

  // 檢查是否能轉換成數字
  if (isNaN(parsedNumber)) {
    return record[key];
  }

  // 使用 .toLocaleString() 格式化數字
  return parsedNumber.toLocaleString();
};

interface IDelInfo {
  id: string;
  key: string;
  name: string;
}

type SPOrderItemTableProps = {
  onClickEdit: (key: string) => void;
  onClickDelete: (key: string) => void;
  onClickMatch?: (key: string) => void;
  dataSource: SPFormOrderItemInfo[];
  pageMode: ISPEditPageMode;
  setItems: (newData: SPFormOrderItemInfo[]) => void;
};

const SPOrderItemTable = ({
  onClickEdit = () => {
    /** */
  },
  onClickDelete = () => {
    /** */
  },
  onClickMatch = () => {
    /** */
  },
  dataSource,
  pageMode,
  setItems,
}: SPOrderItemTableProps) => {
  const [onDeleteInfo, setOnDeleteInfo] = useState<IDelInfo | null>(null);
  const [onDelAnchor, setOnDelAnchor] = useState<HTMLButtonElement | null>(
    null
  );
  const [historyRefId, setHistoryRefId] = useState<string>('');
  const [histroyModalOpen, setHistroyModalOpen] = useState(false);
  const onClickTableDel =
    (key: string, id: string, name: string) =>
    (e: MouseEvent<HTMLButtonElement>) => {
      // console.log('onClickTableDel', key, id);
      setOnDelAnchor(e.currentTarget);
      setOnDeleteInfo({ key, id, name });
    };

  const onClickConfirmDel = () => {
    if (onDeleteInfo) {
      onClickDelete(onDeleteInfo.key);
    }
    setOnDeleteInfo(null);
  };

  const onClickHistory = (refId: string) => {
    setHistroyModalOpen(true);
    setHistoryRefId(refId);
  };
  const onModalFinish = () => {
    setHistroyModalOpen(false);
  };

  const onChangePremium = debounce((refId: number, value: string) => {
    // console.log(refId, value ,dataSource);
    const newDataSource = dataSource.map((item) => {
      // 找到符合条件的元素
      if (item.refId === refId) {
        // 修改 Premium 的值
        return { ...item, premium: value };
      }
      // 保持不变的元素
      return item;
    });
    setItems(newDataSource);
  }, 500);

  const columns = [
    {
      title: '操作',
      width: 160,
      render: (record: any) => {
        return (
          <div>
            {pageMode === SPEditPageMode.EDIT ||
            pageMode === SPEditPageMode.ADD ? (
              <div className={styles.buttonContainer}>
                <Button
                  size="small"
                  variant="text"
                  onClick={() => onClickEdit(record.key)}
                >
                  編輯
                </Button>
                <Button
                  size="small"
                  variant="text"
                  danger
                  onClick={onClickTableDel(
                    record.key,
                    record.id,
                    record.component.name
                  )}
                >
                  刪除
                </Button>
              </div>
            ) : null}
            {pageMode === SPEditPageMode.MATCH ? (
              <div className={styles.buttonContainer}>
                <Button
                  size="small"
                  variant="text"
                  onClick={() => onClickMatch(record.key)}
                >
                  對應
                </Button>
              </div>
            ) : null}
            {pageMode === SPEditPageMode.APPROVE ? (
              <div className={styles.buttonContainer}>
                <Button
                  size="small"
                  variant="text"
                  onClick={() => onClickEdit(record.key)}
                >
                  編輯
                </Button>
                <Button
                  size="small"
                  variant="text"
                  onClick={() => onClickHistory(record.refId)}
                >
                  修改歷程
                </Button>
              </div>
            ) : null}
            {pageMode === SPEditPageMode.VIEW ? (
              <div className={styles.buttonContainer}>
                <Button
                  size="small"
                  variant="text"
                  onClick={() => onClickHistory(record.refId)}
                >
                  修改歷程
                </Button>
                <Button
                  size="small"
                  variant="text"
                  onClick={() => onClickMatch(record.key)}
                >
                  對應記錄
                </Button>
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      title: '成分',
      dataIndex: 'component.name',
      width: 80,
    },
    {
      title: '平台盤價',
      width: 120,
      render: formatNumber('platformPrice'),
    },
    {
      title: '加減碼',
      dataIndex: 'premium',
      width: 80,
    },
    {
      title: '盤價幣別',
      dataIndex: 'standardCurrency.name',
    },
    {
      title: '盤價單位',
      dataIndex: 'standardUnit.name',
    },
    {
      title: '敲價幣別',
      dataIndex: 'reportCurrency.name',
    },
    {
      title: '敲價單位',
      dataIndex: 'reportUnit.name',
    },
    {
      title: '敲價單價',
      render: formatNumber('reportUnitPrice'),
    },
    {
      title: '未對應數量(G)',
      render: formatNumber('unMatchQty'),
    },
    {
      title: '確認數量(G)',
      render: formatNumber('checkQty'),
    },
    {
      title: '暫敲數量(G)',
      render: formatNumber('tempQty'),
    },
    {
      title: '成分比例',
      dataIndex: 'componentRate',
    },
    {
      title: '成交匯率',
      dataIndex: 'exchange',
    },
    {
      title: '報價單價',
      render: formatNumber('quoteUnitPrice'),
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={dataSource} scroll={{ x: 1800 }} />
      <Popconfirm
        open={!!onDeleteInfo}
        confirmButtonProps={{ danger: true }}
        title={`確定要刪除該筆資料?${onDeleteInfo?.name}`}
        cancelText="取消"
        confirmText="刪除"
        onConfirm={onClickConfirmDel}
        onCancel={() => setOnDeleteInfo(null)}
        anchor={onDelAnchor}
      />
      <SPItemHistoryModal
        open={histroyModalOpen}
        onFinish={onModalFinish}
        refId={historyRefId}
      />
    </div>
  );
};

export default SPOrderItemTable;
