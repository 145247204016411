import {
  AutoCompleteField,
  DatePickerField,
  FormFieldsWrapper,
  InputField,
} from '@mezzanine-ui/react-hook-form';
import {
  ModalGroup,
  PageLayout,
  RowSection,
  useModalGroupController,
} from '@solar/templates';
import { Col, Descriptions, Row, Space } from 'antd';
import { useForm } from 'react-hook-form';
import { SuggestAllocationsTable } from './SuggestAllocationsTable';
import { ManualAllocationsTable } from './ManualAllocationsTable';
import { DefaultAllocationsTable } from './DefaultAllocationsTable';
import {
  AutoComplete,
  Button,
  Message,
  SelectValue,
} from '@mezzanine-ui/react';
import { Modals } from './Modal.enum';
import { FixMeltingFactorModal } from './FixMeltingFactorModal';
import {
  checkJWTRoles,
  MaterialAllocationFormValues,
  saveAndCreateMaterialRequests,
  useGetMeltFactor,
  useGetWorkOrderDetailById,
  useGetWorkOrders,
} from '@solar/data';
import { useCallback, useEffect, useState } from 'react';
import { AddMaterialsModal } from './AddMaterialsModal';
import { SuggestedMaterialAllocationsModal } from './SuggestedMaterialAllocationsModal';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetSuggestedMaterialAllocations } from './use-get-suggested-material-allocations';
import { useGetCalculatedMaterialAllocations } from './use-get-calculated-material-allocations';
import { useManualData } from './use-manual-data';
import { useInitMaterialAllocationForm } from './use-init-material-allocation-form';

import Decimal from 'decimal.js';
import { useAutoCompleteRecipeField } from './useAutoCompleteRecipeField';

export function MaterialAllocationsPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamOfResultId = searchParams.get('resultId');
  const searchParamOfWorkOrderId = searchParams.get('workOrderId');

  const methods = useForm<MaterialAllocationFormValues>({
    defaultValues: {
      manualData: [],
    },
  });

  const manualData = methods?.watch('manualData') ?? [];

  const modalGroupController = useModalGroupController([
    { name: Modals.FIX_MELTING_FACTOR },
    { name: Modals.ADD_MATERIALS },
    { name: Modals.SUGGESTED_MATERIAL_ALLOCATIONS },
  ]);
  const { workOrders, refetchGetWorkOrders } = useGetWorkOrders();
  const { workOrder, mutateGetWorkOrderDetailById } = useGetWorkOrderDetailById(
    searchParamOfWorkOrderId ?? undefined
  );
  const mainProduct = workOrder?.expectedMainProduct?.[0];
  const mainProductUnit = mainProduct?.materialStockUnit?.toUpperCase() ?? '';

  const componentCode = mainProduct?.componentCode ?? '';
  const materialId = mainProduct?.materialId ?? '';
  const { meltFactor, mutateGetMeltFactor } = useGetMeltFactor(materialId);
  const [isSaving, setIsSaving] = useState(false);

  const getTargetWeightByUnit = useCallback(
    (targetWeight: number) => {
      const unitIsG = mainProductUnit === 'G';
      return unitIsG
        ? targetWeight ?? 0
        : new Decimal(targetWeight ?? 0).mul(1000).toNumber();
    },
    [mainProductUnit]
  );

  const { calculating, calculatedData, getCalculatedMaterialAllocations } =
    useGetCalculatedMaterialAllocations({
      methods,
      materialId,
      getTargetWeightByUnit,
    });

  const { suggesting, suggestedData, getSuggestedMaterialAllocations } =
    useGetSuggestedMaterialAllocations({
      methods,
      materialId,
      getTargetWeightByUnit,
    });

  const {
    appending,
    appendManualDataBySelectedIds,
    removeManualDataBySelectedIds,
  } = useManualData({ methods, suggestedData });

  const { result } = useInitMaterialAllocationForm({
    methods,
    getCalculatedMaterialAllocations,
  });

  useEffect(() => {
    if (mainProductUnit) {
      if (!['G', 'KG'].includes(mainProductUnit)) {
        Message.error('配料單位錯誤，只允許 "G" 或 "KG" 作為配料單位');
        return;
      }
    }
  }, [mainProductUnit]);

  const onSave = useCallback(
    async ({ needCreate = false }: { needCreate: boolean }) => {
      methods.handleSubmit(async (formState) => {
        try {
          setIsSaving(true);

          if (result?.status === 'FINISHED') {
            Message.error('配料結果已完成，無法再次儲存');
            return;
          }

          const response = await saveAndCreateMaterialRequests({
            workOrderId: workOrder?.id ?? '',
            data: {
              recipeId: formState?.recipeId?.id ?? null,
              calculatedResults: calculatedData ?? [],
              componentCode,
              materialResults:
                formState?.manualData?.map((item) => ({
                  ...item,
                  usedOldMaterial: item?.usedOldMaterial === 'true',
                  requestOrderActualWeightUnitQuantity: new Decimal(
                    item?.requestOrderStockUnitQuantity ?? '0'
                  )
                    .dividedBy(item?.availableMaterialStockUnitQuantity ?? '1')
                    .mul(item?.actualMaterialWeightUnitQuantity ?? '0')
                    .toString(),
                })) ?? [],
              needCreate,
              targetMaterialId: materialId,
              targetWeight: formState?.targetWeight ?? 0,
              targetMaterialSubBatchCount:
                formState?.targetMaterialSubBatchCount
                  ? Number(formState?.targetMaterialSubBatchCount)
                  : 0,
              expectedCompletedAt: formState?.expectedCompletedAt,
              productionExpectedFinishAt: formState?.productionExpectedFinishAt,
              recipeVersion: Number(formState?.recipeVersion?.id) ?? null,
              remark: formState?.remark,
            },
          });

          Message.success(needCreate ? '儲存並發送成功' : '儲存成功');

          const loaderMaterialBatchIds =
            response?.data?.createMaterialRequestOrderResp?.savedRequestedIds ??
            [];

          if (needCreate && loaderMaterialBatchIds.length > 0) {
            const params = new URLSearchParams();
            loaderMaterialBatchIds.forEach((id) => {
              params.append('loaderMaterialBatchId', id.toString());
            });
            navigate(
              `/print-management/label?tab=batch-sticker&from=MaterialSupplyToWorkOrder&${params.toString()}`
            );
          }
        } catch (error) {
          console.log(error);
          if (error instanceof Error) {
            Message.error(JSON.parse(error.message)?.message ?? '儲存失敗');
          }
        } finally {
          setIsSaving(false);
        }
      })();
    },
    [workOrder, methods, calculatedData, componentCode, result]
  );

  const RecipeAutoCompleteField = useAutoCompleteRecipeField({
    methods,
    enabled: true,
    materialId: workOrder?.materialId,
  });

  return (
    <PageLayout
      title="配料"
      // extraContent={
      //   <div
      //     style={{
      //       height: '100%',
      //       display: 'flex',
      //       justifyContent: 'flex-end',
      //       alignItems: 'center',
      //     }}
      //   >
      //     <Button
      //       type="button"
      //       onClick={() => {
      //         if (window.history.state && window.history.state.key) {
      //           navigate(-1);
      //         } else {
      //           navigate('/', { replace: true });
      //         }
      //       }}
      //       prefix={
      //         <Icon style={{ color: '#8F8F8F' }} icon={ChevronLeftIcon} />
      //       }
      //       variant="text"
      //     >
      //       <Typography color="text-secondary" variant="button2">
      //         返回上一頁
      //       </Typography>
      //     </Button>
      //   </div>
      // }
    >
      <FormFieldsWrapper methods={methods}>
        <Descriptions
          bordered
          column={4}
          layout="horizontal"
          labelStyle={{
            width: '120px',
            color: 'var(--mzn-color-action-inactive)',
          }}
          contentStyle={{
            minWidth: '200px',
          }}
        >
          <Descriptions.Item label="工單" span={2}>
            <AutoComplete
              disabled={!!searchParamOfResultId}
              value={
                searchParamOfWorkOrderId
                  ? {
                      id: searchParamOfWorkOrderId,
                      name: searchParamOfWorkOrderId,
                    }
                  : null
              }
              onChange={(value) => {
                setSearchParams({ workOrderId: value?.id ?? '' });
              }}
              onSearch={(searchTerm) => refetchGetWorkOrders({ searchTerm })}
              options={workOrders?.map((wo) => ({ id: wo?.id, name: wo?.id }))}
            />
          </Descriptions.Item>
          <Descriptions.Item label="工作中心" span={2}>
            {mainProduct?.workCenterName ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label="料號" span={4}>
            <Row gutter={[12, 12]} align="middle" justify="space-between">
              <Col>{mainProduct?.materialId ?? ''}</Col>
              <Col>
                {checkJWTRoles([117]) && (
                  <Button
                    variant="contained"
                    onClick={() =>
                      modalGroupController.openModal(
                        Modals.FIX_MELTING_FACTOR,
                        {}
                      )
                    }
                  >
                    修正熔煉因子
                  </Button>
                )}
              </Col>
            </Row>
          </Descriptions.Item>
          <Descriptions.Item label="料號描述" span={4}>
            {mainProduct?.materialDescription ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label="修正參考值" span={2}>
            {meltFactor?.factorType ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label="成份代碼" span={2}>
            {mainProduct?.componentCode ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label="需求數量" span={2}>
            {mainProduct?.expectedMaterialStockUnitQuantity ?? ''}
            {mainProductUnit}
          </Descriptions.Item>
          <Descriptions.Item label="已做數量" span={2}>
            {mainProduct?.actualMaterialStockUnitQuantity ?? ''}(
            {mainProductUnit})
          </Descriptions.Item>
          <Descriptions.Item label="設定子批數量" span={2}>
            <InputField required registerName="targetMaterialSubBatchCount" />
          </Descriptions.Item>
          <Descriptions.Item label="設定路徑碼" span={2}>
            <Space>
              {RecipeAutoCompleteField}
              <RowSection label="版次：" colGap="4px">
                <AutoCompleteField
                  required
                  options={methods.watch('recipeVersions') ?? []}
                  registerName="recipeVersion"
                />
              </RowSection>
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label="預期領料日期：" span={2}>
            <DatePickerField
              fullWidth
              required
              registerName="expectedCompletedAt"
            />
          </Descriptions.Item>
          <Descriptions.Item label="生管指定完工日：" span={2}>
            <DatePickerField
              fullWidth
              required
              registerName="productionExpectedFinishAt"
            />
          </Descriptions.Item>
          <Descriptions.Item label="備註：" span={2}>
            <InputField registerName="remark" />
          </Descriptions.Item>
        </Descriptions>
        <DefaultAllocationsTable
          calculating={calculating}
          calculateAllocationResult={getCalculatedMaterialAllocations}
          calculatedResult={calculatedData}
        />
        <ManualAllocationsTable
          removeManualData={removeManualDataBySelectedIds}
          openAddMaterialsModal={() =>
            modalGroupController.openModal(Modals.ADD_MATERIALS, {})
          }
        />
        <SuggestAllocationsTable
          dataSource={suggestedData ?? []}
          suggesting={suggesting}
          appending={appending}
          appendManualData={appendManualDataBySelectedIds}
          calculateAllocationResult={getCalculatedMaterialAllocations}
          openSuggestedMaterialAllocationsModal={() =>
            modalGroupController.openModal(
              Modals.SUGGESTED_MATERIAL_ALLOCATIONS,
              {}
            )
          }
        />
        {checkJWTRoles([117]) && (
          <Row justify="end" gutter={[12, 12]} style={{ marginTop: '12px' }}>
            <Col>
              <Button
                loading={isSaving}
                variant="contained"
                onClick={() => onSave({ needCreate: false })}
              >
                儲存
              </Button>
            </Col>
            <Col>
              <Button
                loading={isSaving}
                variant="contained"
                onClick={() => onSave({ needCreate: true })}
              >
                儲存並送出
              </Button>
            </Col>
          </Row>
        )}
      </FormFieldsWrapper>
      <ModalGroup {...modalGroupController}>
        <FixMeltingFactorModal
          materialId={materialId ?? ''}
          meltFactor={meltFactor}
          onAfterSave={async () => {
            await mutateGetMeltFactor();
            await mutateGetWorkOrderDetailById();
          }}
        />
        <AddMaterialsModal
          appending={appending}
          manualData={manualData}
          onSubmit={async (selectedMaterialRecordIds) => {
            try {
              await appendManualDataBySelectedIds(selectedMaterialRecordIds);
              modalGroupController.closeModal(Modals.ADD_MATERIALS);
            } catch (error) {
              console.log(error);
            }
          }}
        />
        <SuggestedMaterialAllocationsModal
          onSubmit={getSuggestedMaterialAllocations}
        />
      </ModalGroup>
    </PageLayout>
  );
}
