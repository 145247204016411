export enum MaterialCollectSourceType {
  IQC = 'iqc',
  Order = "order",
  IPQC = 'ipqc',
  DepartmentFee = "department_fee"
}

export function renderMaterialCollectSourceTypeString(sourceType?:  MaterialCollectSourceType):string {
  switch (sourceType) {
    case MaterialCollectSourceType.IQC:
      return 'IQC 品檢';
    case MaterialCollectSourceType.Order:
      return '工單';
    case MaterialCollectSourceType.IPQC:
      return 'IPQC 品檢';
    case MaterialCollectSourceType.DepartmentFee:
      return '部門費用'
    default:
      return '';
  }
}

export const SOURCE_TYPE_OPTIONS = Object.values(MaterialCollectSourceType).map((sourceType)=>({
  id: sourceType, name: renderMaterialCollectSourceTypeString(sourceType)
}))
