import { InputField, InputFieldProps } from '@mezzanine-ui/react-hook-form';
import { useCallback } from 'react';

const intRegex = /[0-9]/;
const floatRegex = /^(\d*\.?\d*)$/;
const nonAlphaNumericRegex = /^[A-Za-z0-9]+$/;

export const NumericInputField = ({
  validateMode = 'int',
  ...props
}: InputFieldProps & {
  validateMode: 'int' | 'float';
}) => {
  const handleKeyPressRestrictInt = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const validateRule = validateMode === 'int' ? intRegex : floatRegex;

      if (
        !validateRule.test(
          event.key +
            (validateMode === 'float' ? (event?.target as any)?.value : '')
        )
      ) {
        event.preventDefault();
      }

      props?.onKeyPress?.(event);
    },
    [props, validateMode]
  );

  return (
    <InputField
      {...props}
      onKeyPress={handleKeyPressRestrictInt}
      onChange={(event) => {
        if (
          (!floatRegex.test(event.target.value) ||
            !intRegex.test(event.target.value)) &&
          !nonAlphaNumericRegex.test(event.target.value)
        ) {
          event.target.value = '';
          event.preventDefault();
          return;
        }

        props?.onChange?.(event);
      }}
    />
  );
};
