import { useState } from 'react';

import { API_NAMESPACE, request } from '../request';
export enum MaterialShiftInputFields {
  'MATERIAL_ID_OR_MATERIAL_NAME' = 'MATERIAL_ID_OR_MATERIAL_NAME',
  'MATERIAL_BARCODE' = 'MATERIAL_BARCODE',
  'LOADER_BARCODE' = 'LOADER_BARCODE',
}

const namespace = API_NAMESPACE.MATERIALS;

const _fetchData = async (params: FetchingInventoryParams) => {
  try {
    const res = await request('/warehouses/inventory-by-material', {
      method: 'get',
      params: {
        limit: params?.limit,
        offset: params?.offset,
        type: params?.type,
        searchTerms: params?.searchTerms,
        materialBarcodes: params?.materialBarcodes,
        stockTypes: params?.stockTypes,
      },
      namespace,
    });

    return res;
  } catch (err) {
    console.log(err);
  }
};

export enum FetchingInventoryStockTypes {
  AVAILABLE = 'AVAILABLE',
  INSPECTION = 'INSPECTION',
  LOCKED = 'LOCKED',
  IN_TRANSIT = 'IN_TRANSIT',
  PENDING = 'PENDING',
  EXPECTED = 'EXPECTED',
  SALES = 'SALES',
  REQUESTED = 'REQUESTED',
  IN_TRANSIT_SALES = 'IN_TRANSIT_SALES',
  REQUESTED_SALES = 'REQUESTED_SALES',
  HUB = 'HUB',
}

export type FetchingInventoryParams = {
  limit?: number;
  offset?: number;
  type: MaterialShiftInputFields;
  searchTerms: string[];
  totalCount?: number;
  hasNext?: boolean;
  materialBarcodes?: string[];
  stockTypes?: FetchingInventoryStockTypes[];
};

export function useInventoryByMaterial({
  defaultType,
}: {
  defaultType: MaterialShiftInputFields;
}) {
  const [params, _setParams] = useState<FetchingInventoryParams>({
    offset: 0,
    limit: 20,
    type: defaultType,
    searchTerms: [],
    hasNext: false,
  });

  const fetchInventoryByMaterial = async ({
    type,
    searchTerms,
    offset,
    limit,
    materialBarcodes,
    stockTypes,
  }: Partial<FetchingInventoryParams>) => {
    return await _fetchData({
      ...params,
      type: type ? type : params.type,
      searchTerms: searchTerms ? searchTerms : params.searchTerms,
      offset: offset ? offset : params.offset,
      limit: limit !== undefined ? limit : params?.limit,
      stockTypes,
      materialBarcodes,
    });
  };

  return {
    fetchingInventoryParams: params,
    mutateFetchInventoryParams: _setParams,
    fetchInventoryByMaterial,
  };
}
