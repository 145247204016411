import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Typography } from "@mezzanine-ui/react";
import { UseModalControllerReturn, useMaterialGroups } from "@solar/data";
import styles from './material-transfer-modal.module.scss';
import { FormFieldsWrapper, InputField, SelectField } from "@mezzanine-ui/react-hook-form";
import { useForm } from "react-hook-form";
import { SelectedMaterialBatchList } from "./SelectedMaterialBatchList";

type UpdateMaterialIdModalProps = UseModalControllerReturn<any> & {
  onSubmit: (payload: any) => void,
}

export function UpdateMaterialIdModal({ open, data, closeModal, onSubmit }: UpdateMaterialIdModalProps) {
  const methods = useForm();
  const { materialGroups } = useMaterialGroups();
  const handleSubmit = methods.handleSubmit((formState) => {
    onSubmit(formState);
    closeModal();
  });

  return (
    <Modal className={styles.modal_wrapper} open={open} onClose={closeModal}>
      <ModalHeader>料號更換</ModalHeader>
      <ModalBody>
        <SelectedMaterialBatchList dataSource={data?.selectedRowKeys || []} />
        <FormFieldsWrapper methods={methods}>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 'var(--mzn-spacing-2)' }}>
            <SelectField
              label="物料群組"
              registerName="materialGroup"
              options={materialGroups ?? []}
            />
            <SelectField
              label="產品階層大"
              registerName="productStage1"
            />
            <SelectField
              label="產品階層中"
              registerName="productStage2"
            />
            <SelectField
              label="產品階層小"
              registerName="productStage3"
            />
            <SelectField
              label="身份碼"
              registerName="id"
            />
            <SelectField
              label="成分數量"
              registerName="amount"
            />
            <SelectField
              label="主要成份"
              registerName="amount"
            />
            <SelectField
              label="次要成分"
              registerName="amount"
            />
            <SelectField
              label="主成份比例"
              registerName="ratio"
            />
            <SelectField
              label="成份代碼"
              registerName="ratio"
            />
            <SelectField
              label="特徵碼一"
              registerName="feature1"
            />
            <SelectField
              label="特徵碼二"
              registerName="feature2"
            />
            <SelectField
              label="特徵碼三"
              registerName="feature3"
            />
            <SelectField
              label="圖號"
              registerName="drawing"
            />
            <SelectField
              label="尺寸1"
              registerName="size1"
            />
            <SelectField
              label="尺寸2"
              registerName="size2"
            />
            <SelectField
              label="尺寸3"
              registerName="size3"
            />
            <SelectField
              label="加工碼"
              registerName="ratio"
            />
          </div>
          
        </FormFieldsWrapper>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => closeModal()}>取消</Button>
        <Button onClick={handleSubmit}>確認</Button>
      </ModalFooter>
    </Modal>
  )
}