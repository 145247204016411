import {
  DateRangePickerField,
  InputField,
  InputTagsModeField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';

import {
  LocationLevel,
  useFactories,
  useMaterialCategories,
  useMaterialGroups,
  useMaterialSourceCodes,
} from '@solar/data';
// import { factories } from '../Templates/constant';
import classes from './filter.module.scss';

export function Filter() {
  const { materialCategories } = useMaterialCategories();
  const { materialGroups } = useMaterialGroups();
  const { materialSourceCodes } = useMaterialSourceCodes();
  const { factories } = useFactories({ returnLevel: LocationLevel.FACTORY });

  return (
    <div className={classes.host}>
      <SelectField
        label="物料類型："
        mode="multiple"
        registerName="categories"
        options={materialCategories ?? []}
      />
      <SelectField
        label="物料群組："
        mode="multiple"
        registerName="groups"
        options={materialGroups ?? []}
      />
      <SelectField
        label="身份碼："
        mode="multiple"
        registerName="sourceCodes"
        options={materialSourceCodes ?? []}
      />
      <DateRangePickerField
        fullWidth
        label="日期區間："
        registerName="createdAtDateRange"
      />
      <InputTagsModeField
        label="料號："
        registerName="materialIds"
        maxLength={100}
      />
      <InputTagsModeField
        label="品名："
        registerName="materialDescriptions"
        maxLength={200}
      />
      <InputField label="圖號：" registerName="drawingCode" />
      <InputField label="批號：" registerName="batchId" />
      <InputField label="批號備註：" registerName="batchNotes" />
      <InputTagsModeField label="關鍵字：" registerName="searchTerms" />
      <SelectField
        label="工廠："
        mode="multiple"
        clearable
        registerName="factories"
        options={factories}
      />
    </div>
  );
}
