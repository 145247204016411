import { LocationLevel } from '@solar/data';
import { useCallback, useEffect, useRef, useState } from 'react';

export function useLocationSVGPopover() {
  const popoverRef = useRef<HTMLDivElement>(null);
  const popoverWrapperRef = useRef<HTMLDivElement>(null);
  const [locked, setLocked] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>();
  const [selectedElement, setSelectedElement] = useState<Element>();
  const [popoverBox, setPopoverBox] = useState<{
    height: number;
    width: number;
    left: number;
    top: number;
  }>();

  const locationSvgWrapper = popoverWrapperRef.current?.querySelector(
    'div#location-svg-wrapper'
  );

  const shelfSvgWrapper = popoverWrapperRef.current?.querySelector(
    'div#shelf-svg-wrapper'
  );

  const closePopover = useCallback(() => {
    setLocked(false);
    const popoverWrapper = popoverWrapperRef?.current;
    const polygonList = popoverWrapper?.querySelectorAll('polygon');
    setSelectedId(null);
    setSelectedElement(undefined);
    setPopoverBox(undefined);
    polygonList?.forEach((polygon) => {
      polygon?.setAttribute('selected', 'false');
    });
  }, [popoverWrapperRef]);

  useEffect(() => {
    let mouseLeaveTimer: ReturnType<typeof setTimeout>;
    const popoverWrapper = popoverWrapperRef?.current;
    const polygonList = popoverWrapper?.querySelectorAll('polygon');

    const handleRightClick = (event: MouseEvent) => {
      event.preventDefault();
      setLocked((prev) => !prev);
    };

    const handleMouseEnter = (event: MouseEvent) => {
      clearTimeout(mouseLeaveTimer);
      if (!locked) {
        const element = event?.target as SVGRectElement;
        const id = element.getAttribute('id');
        const level = element.getAttribute('level') as LocationLevel;
        const category = element.getAttribute('category');
        const levelSet = new Set([
          LocationLevel.ZONE,
          LocationLevel.STACK,
          LocationLevel.SHELF,
        ]);

        if (
          id !== selectedId &&
          (levelSet.has(level) || category === 'Equipment')
        ) {
          polygonList?.forEach((polygon) => {
            polygon?.setAttribute('selected', 'false');
          });
          element?.setAttribute('selected', 'true');
          const elementRect = element?.getBoundingClientRect();
          const anchorRect = popoverWrapperRef.current?.getBoundingClientRect();
          setSelectedId(id);
          setSelectedElement(element);
          setPopoverBox({
            height: elementRect?.height ?? 0,
            width: elementRect?.width ?? 0,
            left: elementRect?.x - (anchorRect?.x ?? 0),
            top: elementRect?.y - (anchorRect?.y ?? 0),
          });
        }
      }
    };

    const handleMouseLeave = (event: MouseEvent) => {
      mouseLeaveTimer = setTimeout(() => {
        if (!locked) {
          closePopover();
        }
      }, 200);
    };

    polygonList?.forEach((element) => {
      element.addEventListener('contextmenu', handleRightClick);
      element.addEventListener('mouseenter', handleMouseEnter);
      element.addEventListener('mouseleave', handleMouseLeave);
    });

    return () => {
      clearTimeout(mouseLeaveTimer);
      polygonList?.forEach((element) => {
        element.removeEventListener('contextmenu', handleRightClick);
        element.removeEventListener('mouseenter', handleMouseEnter);
        element.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, [
    selectedId,
    popoverWrapperRef,
    locationSvgWrapper?.innerHTML,
    shelfSvgWrapper?.innerHTML,
    closePopover,
    locked,
  ]);

  return {
    open: !!selectedId && !!popoverBox,
    locked,
    popoverRef,
    popoverWrapperRef,
    selectedId,
    selectedElement,
    popoverBox,
    closePopover,
  };
}
