import {
  AutoCompleteField,
  FormFieldsWrapper,
  InputField,
} from '@mezzanine-ui/react-hook-form';
import {
  API_NAMESPACE,
  DeliveryOrderDetail,
  ShippedOrder,
  checkJWTRoles,
  request,
  returnTargets,
  useDebounce,
  useGetDeliveryOrderDetail,
  useGetShippedOrders,
} from '@solar/data';
import {
  CopyTextWrapper,
  LocationAndLoaderPickerField,
  PageLayout,
  useDraggableColumns,
} from '@solar/templates';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm, useFormContext, useWatch } from 'react-hook-form';
import { Col, Descriptions, Row, Space } from 'antd';
import {
  Button,
  Loading,
  Message,
  SelectValue,
  Table,
  Typography,
} from '@mezzanine-ui/react';
import { TableColumn, TableDataSourceWithID } from '@mezzanine-ui/core/table';
import { useNavigate, useParams } from 'react-router';
import { uniqBy } from 'lodash';

interface FormValues {
  deliveryOrderId: string;
  pairs: {
    originDeliveryOrderId: string;
    originDeliveryOrderLine: string;
    deliveryOrderLine: string;
    materialSapBatchId: string;
    materialSubBatchId: string;
    deliveryOrder: SelectValue | null;
    deliveryOrderId: string | null;
    batch: SelectValue | null;
    batchOptions: SelectValue[] | null;
    productionWorkOrderId: string;
    actualWeight: string;
  }[];
  loader: SelectValue | null;
  targetLoaderId: string;
  targetShelfId: string;
}

export function DeliveryOrderIdInputField({
  index,
  setOriginDeliveryOrderIdDataMap,
}: {
  index: number;
  setOriginDeliveryOrderIdDataMap: Dispatch<
    SetStateAction<Map<string, ShippedOrder[]>>
  >;
}) {
  const methods = useFormContext<FormValues>();
  const [loading, setLoading] = useState(false);

  const value = useWatch({
    control: methods.control,
    name: `pairs.${index}.originDeliveryOrderId`,
  });

  const deliveryOrderId = useDebounce(value, 800);

  useEffect(() => {
    if (!deliveryOrderId) return;
    (async () => {
      try {
        setLoading(true);

        const res = await request('/shipped-orders', {
          namespace: API_NAMESPACE.MATERIALS,
          params: { deliveryOrderId, limit: 0 },
          responseParser: (res) => res,
        });

        const data: ShippedOrder[] = await res.json();

        if (data.length === 0) {
          throw new Error('Delivery Order not found');
        }

        if (res.ok) {
          methods.clearErrors(`pairs.${index}.originDeliveryOrderId`);

          setOriginDeliveryOrderIdDataMap((prev) => {
            prev.set(deliveryOrderId, data);
            return prev;
          });

          const transformedSubBatchReferences = data.flatMap((data) =>
            data.deliveryOrderDetails.flatMap((detail, index) =>
              uniqBy(
                detail.subBatchReferences.map((subBatch) => ({
                  id: `${subBatch.materialSapBatchId}-${subBatch.materialSubBatchId}`,
                  name: `${subBatch.materialSapBatchId}-${subBatch.materialSubBatchId}`,
                })),
                'id'
              )
            )
          );

          methods.setValue(
            `pairs.${index}.batchOptions`,
            transformedSubBatchReferences
          );
        }
      } catch (err) {
        methods.setError(`pairs.${index}.originDeliveryOrderId`, {
          type: 'notFound',
          message: '出貨單不存在或無資料',
        });
        Message.error('出貨單不存在或無資料');
      } finally {
        setLoading(false);
      }
    })();
  }, [deliveryOrderId]);

  return (
    <Loading loading={loading}>
      <InputField
        registerName={`pairs.${index}.originDeliveryOrderId`}
        error={!!methods.formState.errors.pairs?.[index]?.originDeliveryOrderId}
      />
    </Loading>
  );
}

export function DeliveryOrderBatchSelectField({
  index,
  originDeliveryOrderIdDataMap,
}: {
  index: number;
  originDeliveryOrderIdDataMap: Map<string, ShippedOrder[]>;
}) {
  const methods = useFormContext<FormValues>();

  const targetDeliveryOrderId = useWatch({
    control: methods.control,
    name: `pairs.${index}.originDeliveryOrderId`,
  });

  const targetBatchOptions = useWatch({
    control: methods.control,
    name: `pairs.${index}.batchOptions`,
  });

  const onChange = useCallback(
    (option: SelectValue) => {
      const data =
        originDeliveryOrderIdDataMap.get(targetDeliveryOrderId) ?? [];
      const targets = data?.flatMap((order) =>
        order?.deliveryOrderDetails?.flatMap((detail) =>
          detail?.subBatchReferences?.filter(
            (subBatch) =>
              `${subBatch?.materialSapBatchId}-${subBatch?.materialSubBatchId}` ===
              option?.id
          )
        )
      );
      methods.setValue(
        `pairs.${index}.originDeliveryOrderLine`,
        targets?.[0]?.deliveryOrderLine
      );
    },
    [originDeliveryOrderIdDataMap]
  );

  return (
    <AutoCompleteField
      registerName={`pairs.${index}.batch`}
      options={targetBatchOptions ?? []}
      onChange={onChange}
    />
  );
}

export function RentTargetDetailPage() {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { deliveryOrder, isLoading } = useGetDeliveryOrderDetail(orderId);
  const tableRef = useRef<HTMLTableElement>(null);

  const { shippedOrders } = useGetShippedOrders({
    deliveryOrderId: deliveryOrder?.id,
  });

  const [originDeliveryOrderIdDataMap, setOriginDeliveryOrderIdDataMap] =
    useState<Map<string, ShippedOrder[]>>(new Map());

  const dataSource: (TableDataSourceWithID &
    DeliveryOrderDetail['specs'][number]['materialItems'][number])[] =
    deliveryOrder?.specs?.flatMap(
      (spec) =>
        spec?.materialItems?.map((item) =>
          Object.assign({ id: item?.productionDeliveryOrderSpecId }, item)
        ) ?? []
    ) ?? [];

  const defaultColumns = useMemo<
    TableColumn<DeliveryOrderDetail['specs'][number]['materialItems'][number]>[]
  >(() => {
    return [
      {
        width: 150,
        title: '項次',
        dataIndex: 'productionDeliveryOrderLine',
      },
      {
        width: 200,
        title: '料號',
        render: (source) => <CopyTextWrapper text={source?.materialId} />,
      },
      {
        width: 500,
        title: '品名',
        render: (source) => (
          <CopyTextWrapper text={source?.materialDescription} />
        ),
      },
      {
        title: '出廠出貨單',
        width: 350,
        render: (source, index) => (
          <DeliveryOrderIdInputField
            index={index}
            setOriginDeliveryOrderIdDataMap={setOriginDeliveryOrderIdDataMap}
          />
        ),
      },
      {
        title: '出廠批號',
        render: (source, index) => (
          <DeliveryOrderBatchSelectField
            index={index}
            originDeliveryOrderIdDataMap={originDeliveryOrderIdDataMap}
          />
        ),
      },
      {
        width: 150,
        title: '收貨重量',
        render: (source, index) => (
          <InputField
            required
            registerName={`pairs.${index}.actualWeight`}
            onChange={(e) => {
              const value = e.target.value;

              methods.setValue(
                `pairs.${index}.actualWeight`,
                value.match(/^\d*\.?\d{0,2}/)?.[0] || '',
                { shouldValidate: true }
              );
            }}
          />
        ),
      },
      {
        width: 150,
        title: '單位',
        dataIndex: 'materialStockUnit',
      },
      {
        title: '處理工單單號',
        render: (source, index) => (
          <InputField
            required
            registerName={`pairs.${index}.productionWorkOrderId`}
          />
        ),
      },
      {
        title: '物料銷售內文',
        dataIndex: 'materialSellingContent',
      },
      {
        title: '生產備註',
        dataIndex: 'scmNote ',
      },
      {
        title: '工單作業說明',
        dataIndex: 'workOrderDescription ',
      },
      {
        title: '出貨備註',
        dataIndex: 'deliveryNote',
      },
    ];
  }, [shippedOrders]);

  const columns = useDraggableColumns(tableRef, defaultColumns);

  const methods = useForm<FormValues>();

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = methods.handleSubmit(async (data) => {
    try {
      setSubmitting(true);

      if (!data?.loader?.id) {
        methods.setError('loader.id', {
          type: 'required',
          message: '必填',
        });
        return;
      }

      if (!deliveryOrder?.id) {
        Message.error('無出貨單單號');
        return;
      }

      const pairs =
        data?.pairs?.map((pair, index) => ({
          deliveryOrderLine: dataSource?.[index]?.productionDeliveryOrderLine,
          originDeliveryOrderId: pair?.originDeliveryOrderId,
          originDeliveryOrderLine: pair?.originDeliveryOrderLine,
          materialSapBatchId: pair?.batch?.name?.split('-')?.[0] ?? '',
          materialSubBatchId: pair?.batch?.name?.split('-')?.[1] ?? '',
          productionWorkOrderId: pair?.productionWorkOrderId,
          actualWeight: pair?.actualWeight,
        })) ?? [];

      await returnTargets({
        deliveryOrderId: deliveryOrder?.id,
        pairs,
        targetLoaderId: data?.loader?.id,
        targetShelfId: data?.targetShelfId,
      });

      Message.success('已完成');

      navigate('/incoming-inspect/rent-target/');
    } catch (error) {
      if (error instanceof Error) {
        switch (JSON.parse(error.message)?.message) {
          case 'Delivery Order not found':
            Message.error('出貨單不存在');
            break;
          default:
            Message.error('發生錯誤');
        }
      } else {
        Message.error('發生錯誤');
      }
    } finally {
      setSubmitting(false);
    }
  });

  return (
    <PageLayout backTo="/incoming-inspect/rent-target" disabledDivider>
      <FormFieldsWrapper methods={methods}>
        <Descriptions>
          <Descriptions.Item label="出貨單號">
            {deliveryOrder?.id}
          </Descriptions.Item>
          <Descriptions.Item label="客戶名稱">
            {deliveryOrder?.customerName}
          </Descriptions.Item>
          <Descriptions.Item label="客戶代碼">
            {deliveryOrder?.customerId}
          </Descriptions.Item>
          <Descriptions.Item label="業務人員">
            {deliveryOrder?.employeeName}
          </Descriptions.Item>
          <Descriptions.Item label="預計收貨日期">
            {deliveryOrder?.expectedCompletedAt}
          </Descriptions.Item>
        </Descriptions>
        <Table
          scroll={{ x: 2500 }}
          ref={tableRef}
          loading={isLoading}
          dataSource={dataSource}
          columns={columns}
        />
        {checkJWTRoles([82]) && (
          <Space direction="vertical">
            <Typography variant="h4">收料地點</Typography>
            <LocationAndLoaderPickerField
              locationIdRegisterName="targetShelfId"
              loaderIdRegisterName="loader"
              loaderShelfIdRegisterName="loaderShelfId"
              checkLoaderPosition
            />
            <Button variant="contained" loading={submitting} onClick={onSubmit}>
              儲存
            </Button>
          </Space>
        )}
      </FormFieldsWrapper>
    </PageLayout>
  );
}
