import { ReactNode } from 'react';
import { cx } from '@mezzanine-ui/react';
import sharedStyles from '../shared-styles/index.module.scss';
import classes from './form-basic-layout.module.scss';

type FormBasicLayoutProps = {
  children: ReactNode;
};
function FormBasicLayout({ children }: FormBasicLayoutProps) {
  return (
    <div className={cx(sharedStyles.filter_form_wrapper, classes.host)}>
      {children}
    </div>
  );
}

export default FormBasicLayout;
