import { useMemo, useState } from 'react';
import { API_NAMESPACE, request } from '../request';
import useSWR from 'swr';
import { UseDataSourceHook } from '../common-table-filter-param';
import { PageInfo } from '../pagination';
import { OutsourcePurchaseMaterialOrderRequestParams } from './useOutsourcePurchaseMaterialRequestOrders';

const namespace = API_NAMESPACE.MATERIALS;

export type OutsourcePurchaseOrders = {
  id: string;
  supplierId: string;
  supplierName: string;
  expectedCompletedAt: string;
  factoryId: string;
  createdAt: string;
  staffName: string;
  orderTypeName: string;
  expectedDeliveryDate?: string;
  workOrderStatusName?: string;
  items: OutsourcePurchaseOrderDetail[];
};

export type OutsourcePurchaseOrderDetail = {
  id: string;
  specId: string;
  expectedMaterialStockUnitQuantity: string;
  receivedMaterialStockUnitQuantity: string;
  returnedMaterialStockUnitQuantity: string;
  materialStockUnit: string;
  materialInputUnit: string;
  stockOverInputUnitRatio: string;
  materialDescription: string;
  oldMaterialId: string;
  materialId: string;
  expectedMaterialInputUnitQuantity: string;
  receivedMaterialInputUnitQuantity: string;
  returnedMaterialInputUnitQuantity: string;
};

export function useOutsourcePurchaseOrders(
  defaultParams?: Partial<OutsourcePurchaseMaterialOrderRequestParams>
) {
  const [params, setParams] = useState<
    Partial<OutsourcePurchaseMaterialOrderRequestParams> | undefined
  >(defaultParams);
  const { data, error, mutate, isValidating } = useSWR<{
    records: OutsourcePurchaseOrders[];
    pageInfo: PageInfo;
  }>(
    params
      ? ['/warehouses/outsource-purchase-orders', { params, namespace }]
      : null
  );

  return {
    orders: data?.records || [],
    pageInfo: data?.pageInfo,
    isLoading: !error && !data && isValidating,
    mutateParams: setParams,
    refetchOrders: mutate,
    refetchOutsourcePurchaseOrders: setParams,
    mutateOutsourcePurchaseOrders: mutate,
  };
}

export type SyncOutsourcePurchaseOrdersFromSAPParams = {
  dateFrom?: string;
  dateTo?: string;
};

export function syncOutsourcePurchaseOrdersFromSAP(
  params: SyncOutsourcePurchaseOrdersFromSAPParams
) {
  return request('/sync/outsource-purchase-orders', {
    method: 'get',
    namespace,
    responseParser: (res) => res,
    params,
  });
}
