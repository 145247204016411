import { Table } from '@mezzanine-ui/react';
import { useGetMaterialGroupCodes } from './hooks';

export function MaterialGroupCodePanel() {
  const { groupCodes, isLoading } = useGetMaterialGroupCodes();
  return (
    <Table
      loading={isLoading}
      dataSource={groupCodes ?? []}
      scroll={{ y: 550 }}
      columns={[
        { width: 150, title: '代碼', dataIndex: 'id' },
        { title: '對應值', dataIndex: 'name' },
      ]}
    />
  );
}
