import { useLocationControlContext } from '../LocationControlContext';
import { LocationSVG, ShelfSVG } from '../LocationSVG';
import { LocationLevel } from '../types';
import { Space } from 'antd';

export function LocationSVGPicker() {
  const { level } = useLocationControlContext();

  return (
    <Space style={{ width: '100%', overflowX: 'scroll' }}>
      <LocationSVG />
      {(level === LocationLevel.STACK || level === LocationLevel.SHELF) && (
        <ShelfSVG />
      )}
    </Space>
  );
}
