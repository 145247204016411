import { API_NAMESPACE, request } from '@solar/data';
import {
  UpdateWaitReceiptPayload,
  UpdateWaitReceiptStatusTagPayload,
} from '../types';

const namespace = API_NAMESPACE.PP;

export const updateWaitReceipt = ({
  payload,
}: {
  payload: UpdateWaitReceiptPayload;
}): Promise<Response> => {
  return request(`/pp/wait-receipt`, {
    namespace,
    method: 'put',
    body: JSON.stringify(payload),
    responseParser: (res) => res,
  });
};

export const updateWaitReceiptStatusTag = ({
  id,
  payload,
}: {
  id: string | number;
  payload: UpdateWaitReceiptStatusTagPayload;
}): Promise<Response> => {
  return request(`/pp/wait-receipt/${id}`, {
    namespace,
    method: 'put',
    body: JSON.stringify(payload),
    responseParser: (res) => res,
  });
};
