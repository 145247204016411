import { useCallback, useEffect, useState } from 'react';
import styles from './Delivery-Listing.module.scss';
import { SelectValue, Typography } from '@mezzanine-ui/react';
import {
  Table,
  Button,
  Icon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions,
} from '@mezzanine-ui/react';
import { PlusIcon, TimesCircleFilledIcon } from '@mezzanine-ui/icons';
import { useDeliveryListColumns } from './Delivery-Listing-Column';
import {
  FormFieldsWrapper,
  InputField,
  AutoCompleteField,
  SelectField,
  CheckboxField,
} from '@mezzanine-ui/react-hook-form';
import { useForm, useFormContext } from 'react-hook-form';
import { DeliveryListingFormFields } from 'apps/frontend/src/app/Transportation/RequirementsManagementPage/RequirementManagement.interface';
import {
  BooleanStrings,
  CorrespondentTypes,
  DeliveryItemDensityMapping,
  DeliveryItemDensityOptions,
  DeliveryItemUnitMapping,
  DeliveryItemUnitOptions,
  UnitOptions,
  YesNoOptions,
} from 'apps/frontend/src/app/Transportation/RequirementsManagementPage/RequirementsManagement.config';
import Decimal from 'decimal.js';
import { API_NAMESPACE, request } from '@solar/data';
import { InputWithDataList } from '../../Selectors/InputWithDataList/InputWithDataList';
import { debounce } from 'lodash';
import { Col, Row } from 'antd';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export function DeliveryListingForm() {
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editData, setEditData] = useState<DeliveryListingFormFields | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const rootForm = useFormContext();
  const deliveryItems = rootForm.watch('items');
  const { error } = rootForm.getFieldState('items');
  const { isSubmitted } = rootForm.formState;

  const editItem = (item: DeliveryListingFormFields, index: number) => {
    setEditData(item);
    setEditIndex(index);
    setIsModalOpen(true);
  };
  const removeItem = (index: number) => {
    const newList = [...deliveryItems];
    delete newList[index];
    rootForm.setValue('items', newList);
    rootForm.trigger('items');
  };
  const columns = useDeliveryListColumns(editItem, removeItem);

  const onAddSubmit = (value: DeliveryListingFormFields) => {
    const id = `${value.productName}-${value.productNumber}-${deliveryItems.length}`;
    rootForm.setValue('items', [...deliveryItems, { ...value, id }]);
    rootForm.trigger('items');
    closeModal();
  };

  const onEditSubmit = (value: DeliveryListingFormFields) => {
    const newList = [...deliveryItems];
    newList[editIndex as number] = value;
    rootForm.setValue('items', newList);
    closeModal();
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditIndex(null);
    setEditData(null);
  };

  return (
    <div className={styles.container}>
      <Typography variant="h3" className={styles.title}>
        品項清單
        {isSubmitted && error ? (
          <Typography className={styles.errMsg} variant="caption">
            <Icon icon={TimesCircleFilledIcon} className={styles.errMsgIcon} />
            {error.message}
          </Typography>
        ) : null}
      </Typography>

      <Button
        onClick={() => setIsModalOpen(true)}
        type="button"
        color="primary"
        prefix={<Icon icon={PlusIcon} />}
        size="large"
        className={styles.addListingButton}
        variant="contained"
      >
        新增資料
      </Button>
      <Table
        className={styles.table}
        columns={columns}
        dataSource={deliveryItems}
        bodyClassName={styles.tableBody}
        scroll={deliveryItems?.length ? { x: 1800 } : undefined}
      />
      <EditItemModal
        isOpen={isModalOpen}
        editData={editData}
        submit={editIndex === null ? onAddSubmit : onEditSubmit}
        closeModal={closeModal}
      />
    </div>
  );
}

const defaultValues = {
  productNumber: '',
  deliveryOrderId: '',
  totalGrossWeight: '',
  isStack: { id: BooleanStrings.False, name: '否' },
  isUsingPallet: { id: BooleanStrings.False, name: '否' },
};

const numberRequired = Yup.number().typeError('必填').required('必填');
const numberRequiredWhenPallet = Yup.mixed()
  .optional()
  .nullable()
  .when('isUsingPallet', {
    is: (value: SelectValue) => value?.id === BooleanStrings.True,
    then: (schema) =>
      schema.concat(Yup.number()).typeError('必填').required('必填'),
  });

type EditItemModalProps = {
  isOpen: boolean;
  editData: DeliveryListingFormFields | null;
  submit: (value: DeliveryListingFormFields) => void;
  closeModal: () => void;
};

function EditItemModal({
  isOpen,
  editData,
  submit,
  closeModal,
}: EditItemModalProps) {
  const rootForm = useFormContext();
  const correspondentType = rootForm.watch('correspondentType');
  const isCustomer = correspondentType?.id === CorrespondentTypes.Customer;

  const formSchema = Yup.object({
    productName: Yup.string().required('必填'),
    batchId: Yup.object().when('productNumber', ([productNumber], schema) => {
      if (!isCustomer && productNumber) {
        return schema.required('必填');
      }

      return schema.notRequired();
    }),
    pieceNumber: numberRequired,
    totalGrossWeight: Yup.string().required('必填'),
    totalGrossWeightUnit: Yup.object().required('必填'),
    volumeLength: numberRequired,
    volumeWidth: numberRequired,
    volumeHeight: numberRequired,
    // isUsingPallet: Yup.object().required('必填'),
    // palletSizeLength: numberRequiredWhenPallet,
    // palletSizeWidth: numberRequiredWhenPallet,
    // palletNumber: numberRequiredWhenPallet,
    // palletWeight: numberRequiredWhenPallet,
    // boxLength: numberRequired,
    // boxWidth: numberRequired,
    // boxHeight: numberRequired,
  });

  const methods = useForm<DeliveryListingFormFields>({
    defaultValues,
    resolver: yupResolver(formSchema),
  });
  const { handleSubmit, reset, watch } = methods;
  const isUsingPallet = watch('isUsingPallet');

  useEffect(() => {
    if (isOpen && editData) {
      Object.entries(editData).forEach(([key, value]) => {
        methods.setValue(key as keyof DeliveryListingFormFields, value);
      });
    } else {
      reset(defaultValues);
    }
  }, [isOpen]);

  // totalGrossWeight calculation
  const isConvert = methods.watch('convert');
  const unit = methods.watch('unit')?.id;
  const capacity = methods.watch('capacity') ?? 0;
  const density = methods.watch('density')?.id;
  const amount = methods.watch('amount') ?? 0;
  useEffect(() => {
    if (!unit || !capacity || !density || !amount) return;
    const unitRatio = unit ? DeliveryItemUnitMapping[unit] : 0;
    const densityRatio = density ? DeliveryItemDensityMapping[density] : 0;
    const weight = new Decimal(unitRatio)
      .mul(capacity)
      .mul(densityRatio)
      .mul(amount)
      .toString();
    methods.setValue('totalGrossWeight', weight);
  }, [unit, capacity, density, amount]);

  return (
    <Modal open={isOpen} onClose={() => closeModal()}>
      <ModalHeader>新增品項</ModalHeader>
      <ModalBody>
        <FormFieldsWrapper className={styles.container} methods={methods}>
          <CheckboxField
            className={styles.convert}
            label="比重換算重量"
            registerName="convert"
          />
          <InputField
            label="出貨單號"
            registerName="deliveryOrderId"
            valueAsNumber
          />
          <ProductNameNumberAndBatchId isCustomer={isCustomer} />
          {isConvert ? (
            <>
              <SelectField
                label="單位"
                registerName="unit"
                required
                options={DeliveryItemUnitOptions}
              />
              <InputField
                label="容量"
                registerName="capacity"
                valueAsNumber
                required
              />
              <SelectField
                label="比重"
                registerName="density"
                options={DeliveryItemDensityOptions}
                required
              />
              <InputField
                label="出貨數量"
                registerName="amount"
                valueAsNumber
                required
              />
            </>
          ) : null}
          <InputField label="件數" registerName="pieceNumber" required />
          <InputField
            label="合計毛重"
            registerName="totalGrossWeight"
            placeholder={isConvert ? '' : '請輸入'}
            required
            disabled={isConvert}
          />
          <AutoCompleteField
            label="單位"
            required
            registerName="totalGrossWeightUnit"
            options={UnitOptions}
          />
          <SizeInputs
            label="最大尺寸(cm)"
            lRegisterName="volumeLength"
            wRegisterName="volumeWidth"
            hRegisterName="volumeHeight"
            required
          />
          <SelectField
            label="是否堆疊"
            registerName="isStack"
            options={YesNoOptions}
            required
          />
          <SelectField
            label="有無棧板"
            registerName="isUsingPallet"
            options={YesNoOptions}
          />
          {/* {isUsingPallet?.id === BooleanStrings.True ? (
            <>
              <SizeInputs
                label="棧板尺寸(cm)"
                lRegisterName="palletSizeLength"
                wRegisterName="palletSizeWidth"
                required
              />
              <InputField
                label="棧板號"
                registerName="palletNumber"
                type="number"
                valueAsNumber
                required
              />
              <InputField
                label="棧板重量(KG)"
                registerName="palletWeight"
                type="number"
                valueAsNumber
                required
              />
            </>
          ) : null}

          <SizeInputs
            label="外箱尺寸(cm)"
            lRegisterName="boxLength"
            wRegisterName="boxWidth"
            hRegisterName="boxHeight"
            required
          /> */}
        </FormFieldsWrapper>
      </ModalBody>
      <ModalActions
        cancelText="取消"
        confirmText="確認"
        onCancel={() => closeModal()}
        onConfirm={handleSubmit(submit)}
      />
    </Modal>
  );
}

type Material = {
  materialId: string;
  materialDescription: string;
  sapBatchId: string;
  subBatchId: string;
  batchId: string;
};

enum MaterialKeys {
  Number = 'materialIds',
  Name = 'searchTerm',
}

function ProductNameNumberAndBatchId({ isCustomer }: { isCustomer: boolean }) {
  const [materials, setMaterials] = useState<Material[]>();
  const { setValue, watch } = useFormContext();
  const [numberOptions, setNumberOptions] = useState<SelectValue[]>([]);
  const [nameOptions, setNameOptions] = useState<SelectValue[]>([]);
  const [batchIdOptions, setBatchIdOptions] = useState<SelectValue[]>([]);

  const productNumber = watch('productNumber');
  const productName = watch('productName');
  useEffect(() => {
    if (productNumber) return;
    setValue('batchId', null);
    setValue('sapBatchId', null);
    setValue('itemBatchId', null);

    if (!productName) {
      setMaterials(undefined);
    }
  }, [productNumber, productName]);

  const fetchMaterials = useCallback((text: string, type: MaterialKeys) => {
    request(`/materials/batches?limit=100&offset=0&${type}=${text}`, {
      namespace: API_NAMESPACE.MATERIALS,
    }).then((res) => {
      setMaterials(res.records);
    });
  }, []);

  const onNumberChange = useCallback(
    debounce((value: string) => {
      if (value && value.length >= 3) {
        fetchMaterials(value, MaterialKeys.Number);
      }
    }, 500),
    []
  );

  const onNameChange = useCallback(
    debounce((value: string) => {
      if (value && value.length >= 3) {
        fetchMaterials(value, MaterialKeys.Name);
      }
    }, 500),
    []
  );

  useEffect(() => {
    const numberOptions = [];
    const nameOptions = [];
    const batchIdOptions = [];

    if (materials) {
      for (const [index, item] of materials.entries()) {
        numberOptions.push({
          id: index.toString(),
          name: item.materialId,
        });
        nameOptions.push({
          id: index.toString(),
          name: item.materialDescription,
        });
        batchIdOptions.push({
          id: index.toString(),
          name: item.batchId,
        });
      }
    }

    setNumberOptions(numberOptions);
    setNameOptions(nameOptions);
    setBatchIdOptions(batchIdOptions);
  }, [materials]);

  const onNumberSelect = (option: SelectValue) => {
    const material = materials?.find((m) => m.materialId === option.name);
    if (material) {
      setValue('productName', material.materialDescription);
      setBatchId(material.batchId, material);
      filterMaterialsByNumber(material.materialId);
    }
  };

  const onNameSelect = (option: SelectValue) => {
    const material = materials?.find(
      (m) => m.materialDescription === option.name
    );
    if (material) {
      setValue('productNumber', material.materialId);
      setBatchId(material.batchId, material);
      filterMaterialsByNumber(material.materialId);
    }
  };

  function setBatchId(batchId: string, material?: Material) {
    setValue('batchId', {
      id: batchId,
      name: batchId,
    });
    setValue('sapBatchId', material?.sapBatchId || '');
    setValue('itemBatchId', material?.subBatchId || '');
  }

  const onBatchIdChange = (value: SelectValue) => {
    const selectedMaterial = materials?.find(m => m.batchId === value.name);
    if (selectedMaterial) {
      setBatchId(selectedMaterial.batchId, selectedMaterial);
    }
  };

  function filterMaterialsByNumber(productNumber?: string) {
    const filterMaterials = materials?.filter(
      (item) => item.materialId === productNumber
    );
    setMaterials(filterMaterials);
  }

  return (
    <>
      <InputWithDataList
        label="品號"
        registerName="productNumber"
        options={numberOptions}
        onSelect={onNumberSelect}
        onChange={onNumberChange}
      />
      <InputWithDataList
        label="品名"
        registerName="productName"
        required
        options={nameOptions}
        onSelect={onNameSelect}
        onChange={onNameChange}
      />
      <AutoCompleteField
        label="批號"
        registerName="batchId"
        options={batchIdOptions.map((option) => ({
          id: option.name,
          name: option.name,
        }))}
        required={!isCustomer && productNumber}
        onChange={onBatchIdChange}
      />
    </>
  );
}

type SizeInputsProps = {
  label: string;
  lRegisterName: string;
  wRegisterName: string;
  hRegisterName?: string;
  required?: boolean;
};
function SizeInputs({
  label,
  lRegisterName,
  wRegisterName,
  hRegisterName,
  required,
}: SizeInputsProps) {
  return (
    <Row wrap={false} gutter={8}>
      <Col className={styles.label}>
        <Typography variant="h6">{label}</Typography>
      </Col>
      <Col>
        <InputField
          registerName={lRegisterName}
          label="長"
          type="number"
          valueAsNumber
          required={!!required}
        />
      </Col>
      <Col>
        <InputField
          registerName={wRegisterName}
          label="寬"
          type="number"
          valueAsNumber
          required={!!required}
        />
      </Col>
      {hRegisterName ? (
        <Col>
          <InputField
            registerName={hRegisterName}
            label="高"
            type="number"
            valueAsNumber
            required={!!required}
          />
        </Col>
      ) : null}
    </Row>
  );
}
