import {
  AutoComplete,
  Button,
  SelectProps,
  SelectValue,
} from '@mezzanine-ui/react';
import {
  SelectMultipleProps,
  // SelectSingleProps,
} from '@mezzanine-ui/react/Select/Select';
import { useLocationsBySearchTerm } from '@solar/data';
import { Col, Row, RowProps } from 'antd';
import { useState, useTransition } from 'react';
import { UseFormReturn } from 'react-hook-form';

type LocationIdsAutoFieldProps = SelectProps & {
  methods: UseFormReturn<any>;
  registerName: string;
  buttonText?: string;
  rowProps?: RowProps;
  openModal: () => void;
};

export function LocationIdsAutoField({
  methods,
  registerName,
  buttonText,
  rowProps,
  openModal,
  onChange,
}: // mode = 'multiple',
LocationIdsAutoFieldProps) {
  const locationIds = (methods.watch(registerName) ?? []) as SelectValue[];

  const onMultipleChange = (items: SelectValue[]) => {
    methods.setValue(registerName, items);
    if (Array.isArray(items) && onChange) {
      (onChange as SelectMultipleProps['onChange'])?.(items);
    }
  };

  const [searchText, setSearchText] = useState('');
  const [_isPending, startTransition] = useTransition();

  const { locations } = useLocationsBySearchTerm({ searchText });

  return (
    <Row gutter={[8, 8]} {...rowProps}>
      <Col span={16}>
        <AutoComplete
          fullWidth
          options={
            locations?.map(({ id, level }) => ({ id, name: id, level })) ?? []
          }
          onClear={() => methods.setValue(registerName, [])}
          mode="multiple"
          onChange={onMultipleChange}
          onSearch={(text) => {
            startTransition(() => {
              setSearchText(text);
            });
          }}
          value={locationIds}
        />
      </Col>
      <Col>
        <Button type="button" variant="outlined" onClick={openModal}>
          {buttonText ?? '地圖選取'}
        </Button>
      </Col>
    </Row>
  );
}
