import { TrashIcon } from '@mezzanine-ui/icons';
import { Button, Icon, SelectValue, Typography } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { MaterialResponseData, useMaterials } from '@solar/data';
import {
  CollapsibleContainer,
  CustomAutoCompleteField,
  RowSection,
} from '@solar/templates';
import { useCallback } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { PageLayout } from '../PageLayout/PageLayout';
import { TEMP_CREATE_CONFIGURE_TABLE } from './const';
import classes from './create-page.module.scss';
import { CreateConfigureTable } from './CreateConfigureTable';
import { ConfigurationForm } from './typings';

type Props = {
  materialId?: SelectValue;
  materialDescription?: SelectValue;
};

export function MaterialCodeConfigurationCreatePage() {
  const methods = useForm<Props>();
  const tableMethods = useForm<ConfigurationForm>({
    defaultValues: { tables: [] },
  });

  const { append, fields, remove } = useFieldArray({
    control: tableMethods.control,
    name: 'tables',
  });

  const { materials } = useMaterials(methods);

  const autoCompleteHandler = useCallback(
    (event: SelectValue, fieldName: 'materialId' | 'materialDescription') => {
      const targetId = event?.id;
      if (!targetId) return;
      if (!materials?.length) return;

      const relatedMaterials: { [id: string]: MaterialResponseData } = (
        materials ?? []
      )?.reduce((prev, material) => ({ ...prev, [material.id]: material }), {});
      if (fieldName === 'materialId')
        methods.setValue(fieldName, {
          id: relatedMaterials[targetId].id,
          name: relatedMaterials[targetId].id,
        });

      if (fieldName === 'materialDescription')
        methods.setValue(fieldName, {
          id: relatedMaterials[targetId].description,
          name: relatedMaterials[targetId].description,
        });
    },
    [materials, methods]
  );

  if (!materials) return null;

  return (
    <PageLayout setGoBack>
      <Typography variant="h1">新建變更</Typography>

      <FormFieldsWrapper methods={methods}>
        <div className={classes['selector-wrapper']}>
          <RowSection label="料號：">
            <CustomAutoCompleteField
              value={methods?.watch('materialId') ?? null}
              width={480}
              registerName="materialId"
              options={
                (materials ?? [])?.map((material) => ({
                  id: material.id,
                  name: material.id,
                })) ?? []
              }
              onChange={(event) =>
                autoCompleteHandler(event, 'materialDescription')
              }
            />
          </RowSection>
          <RowSection label="品名：">
            <CustomAutoCompleteField
              value={methods?.watch('materialDescription') ?? null}
              width={480}
              registerName="materialDescription"
              options={
                (materials ?? [])?.map((material) => ({
                  id: material?.id ?? '',
                  name: material?.description ?? '',
                })) ?? []
              }
              onChange={(event) => autoCompleteHandler(event, 'materialId')}
              errorMsgRender={(e) => e.message}
            />
          </RowSection>
        </div>
      </FormFieldsWrapper>
      <FormFieldsWrapper methods={tableMethods}>
        <div
          style={{
            display: 'flex',
            gap: 'var(--mzn-spacing-4)',
            marginBottom: 'var(--mzn-spacing-4)',
          }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() =>
              append({
                table: TEMP_CREATE_CONFIGURE_TABLE,
                selectedRowKeys: [],
                newMaterial: null,
              })
            }
          >
            加入
          </Button>
          <Button variant="contained" type="button" onClick={() => ''}>
            建立
          </Button>
        </div>
        {fields?.map((table, index) => (
          <div key={table.id} style={{ marginTop: 'var(--mzn-spacing-4)' }}>
            <CollapsibleContainer
              enableRowBlockStyle
              open
              header={
                <Button
                  danger
                  prefix={<Icon icon={TrashIcon} />}
                  type="button"
                  onClick={() => remove(index)}
                >
                  移除
                </Button>
              }
            >
              <CreateConfigureTable tableMethods={tableMethods} index={index} />
            </CollapsibleContainer>
          </div>
        ))}
      </FormFieldsWrapper>
    </PageLayout>
  );
}
