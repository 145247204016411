import { SelectValue } from '@mezzanine-ui/react';
import { FormAnswer } from '@solar/data';

export interface FormValues {
  factory: SelectValue;
  materialId: SelectValue | null;
  batchId: SelectValue | null;
  answers: Record<
    string, // answerId
    {
      form: FormAnswer['form'];
      formId: string;
      answer: string | null;
      picked: boolean | 'true' | 'false';
      modifiedAnswer?: string | null;
    }
  >;
  modifiedByForm: Record<
    string, // formId
    {
      form: FormAnswer['form'];
      picked: boolean | 'true' | 'false';
      answerId: string | null;
      modifiedAnswer: string | null;
    }
  >;
}

export const labOptions = [
  { id: '1100', name: '科工實驗室' },
  { id: '1200', name: '光科實驗室' },
];

export const defaultValues: FormValues = {
  factory: labOptions[0],
  materialId: null,
  batchId: null,
  answers: {},
  modifiedByForm: {},
};
