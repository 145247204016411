import { Button, Message, Typography } from '@mezzanine-ui/react';
import {
  MaterialResponseData,
  exchangeMaterials,
  getUnitConversionRatiosByMaterialId,
  useGetWorkOrderDetailById,
  useMaterials,
} from '@solar/data';
import { PageLayout } from '@solar/templates';
import { useCallback, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { ExchangeMaterialCard, FormValues } from './ExchangeMaterialsCard';
import Decimal from 'decimal.js';
import { ExchangeUnexpectedMaterialCard } from './ExchangeUnexpectedMaterialsCard';
import { Divider, Space } from 'antd';
import {
  AutoCompleteField,
  FormFieldsWrapper,
} from '@mezzanine-ui/react-hook-form';

export function ExchangeMaterialsDetailPage() {
  const { orderId } = useParams<{ orderId: string }>();

  const methods = useForm<FormValues>();

  const exchangePairsArrayMethods = useFieldArray({
    control: methods.control,
    name: 'exchangePairs',
  });

  const { workOrder } = useGetWorkOrderDetailById(orderId);

  const { materials, setParams: refetchGetMaterials } = useMaterials();

  const [unexpectedMaterials, setUnexpectedMaterials] = useState<
    MaterialResponseData[]
  >([]);

  const [exchanging, setExchanging] = useState(false);

  const exchangeTargets = useMemo(
    () =>
      (
        workOrder?.expectedMainProduct?.map((item) =>
          Object.assign(item, { isMainProduct: true })
        ) ?? []
      ).concat(
        workOrder?.expectedJointProduct?.map((item) =>
          Object.assign(item, { isMainProduct: false })
        ) ?? []
      ),
    [workOrder?.expectedJointProduct, workOrder?.expectedMainProduct]
  );

  const appendUnexpectedMaterial = useCallback(async () => {
    try {
      const formState = methods.getValues();
      const unexpectedMaterialId = formState.unexpectedMaterial?.id;

      const isExist = unexpectedMaterials?.find(
        (item) => item?.id === unexpectedMaterialId
      );

      if (isExist) {
        Message.error('料號已經存在非計畫產出物料中');
        return;
      }

      const targetMaterial = materials?.find(
        (item) => item?.id === unexpectedMaterialId
      );

      if (!targetMaterial) {
        Message.error('未找到匹配料號資料');
        return;
      }

      const units = await getUnitConversionRatiosByMaterialId({
        materialId: targetMaterial?.id,
      });

      if ((units?.length ?? 0) === 0) {
        Message.error('此料號無單位轉換資料，無法使用於此功能');
        return;
      }

      setUnexpectedMaterials((prev) => prev.concat([targetMaterial]));
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        if (JSON.parse(error.message)?.message === 'Not Found') {
          Message.error('此料號無單位轉換資料，無法使用於此功能');
        }
      }
    }
  }, [materials, methods, unexpectedMaterials]);

  const onSubmit = methods.handleSubmit(async (formState) => {
    try {
      setExchanging(true);

      // 檢查預期產出物是否有填
      for (const exchangeTarget of exchangeTargets) {
        const expectedExchangePairs = formState?.exchangePairs?.filter(
          (pair) =>
            pair?.isExpected &&
            exchangeTarget?.materialId === pair?.toMaterialId
        );

        if ((expectedExchangePairs?.length ?? 0) === 0) {
          Message.error(
            `請輸入預期產出物 ${exchangeTarget?.materialId} 的投入料`
          );
          return;
        }

        const expectedTotalQuantity =
          exchangeTarget?.expectedMaterialInputUnitQuantity ?? '0';

        const totalQuantity = expectedExchangePairs
          ?.reduce(
            (total, pair) => new Decimal(total).add(pair?.quantity ?? '0'),
            new Decimal('0')
          )
          ?.toString();

        if (!new Decimal(totalQuantity).equals(expectedTotalQuantity)) {
          Message.error(
            `${exchangeTarget?.materialId} 預期投入量加總不正確，請重新確認`
          );
          return;
        }
      }

      // 檢查已新增的非預期產出物是否有填
      unexpectedMaterials.forEach((unexpectedMaterial) => {
        const isExist = formState?.exchangePairs?.some(
          (pair) =>
            !pair?.isExpected && pair?.toMaterialId === unexpectedMaterial?.id
        );

        if (!isExist) {
          Message.error(
            `請輸入非預期產出物 ${unexpectedMaterial?.id} 的投入料`
          );
          return;
        }
      });

      const exchangePairs = formState?.exchangePairs?.map((pair) => ({
        fromMaterialId: pair?.fromMaterialId ?? '',
        fromMaterialSapBatchId: pair?.fromMaterialSapBatchId ?? '',
        fromMaterialSubBatchId: pair?.fromMaterialSubBatchId ?? '',
        fromLoaderId: pair?.fromLoaderId ?? '',
        toMaterialId: pair?.toMaterialId ?? '',
        quantity: new Decimal(pair?.quantity)
          .mul(pair?.stockOverInputUnitRatio ?? '0')
          .toString(),
      }));

      if (!workOrder?.id) {
        Message.error('工單不存在');
        return;
      }

      await exchangeMaterials({
        productionWorkOrderId: workOrder?.id,
        exchangePairs,
      });

      Message.success('換料成功');
    } catch (error) {
      Message.error('換料失敗');
    } finally {
      setExchanging(false);
    }
  });

  return (
    <PageLayout
      title={`換料工單：${orderId}`}
      backTo="/production-planning/exchange-materials"
    >
      <FormFieldsWrapper methods={methods}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography variant="h4">預期產出物</Typography>
          {exchangeTargets?.map((item) => (
            <ExchangeMaterialCard
              key={item?.materialId}
              item={item}
              arrayMethods={exchangePairsArrayMethods}
            />
          ))}
          <Divider style={{ margin: '8px 0' }} />
          <Space>
            <Typography variant="h4">非計畫產出物</Typography>
            <AutoCompleteField
              registerName="unexpectedMaterial"
              onSearch={(searchTerm) => {
                refetchGetMaterials({
                  searchType: 'ID',
                  searchTerm: searchTerm?.split('_')?.[0],
                });
              }}
              options={
                materials?.map((material) => ({
                  id: material?.id,
                  name: material?.id,
                })) ?? []
              }
            />
            <Button variant="outlined" onClick={appendUnexpectedMaterial}>
              新增
            </Button>
          </Space>
          {unexpectedMaterials?.map((item) => (
            <ExchangeUnexpectedMaterialCard
              key={item?.id}
              item={item}
              arrayMethods={exchangePairsArrayMethods}
              removeUnexpectedMaterial={() =>
                setUnexpectedMaterials((prev) =>
                  prev.filter(
                    (unexpectedMaterial) => unexpectedMaterial?.id !== item?.id
                  )
                )
              }
            />
          ))}
          <Button
            loading={exchanging}
            type="button"
            variant="contained"
            onClick={onSubmit}
          >
            確認
          </Button>
        </Space>
      </FormFieldsWrapper>
    </PageLayout>
  );
}
