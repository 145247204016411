import { Button } from '@mezzanine-ui/react';
import { checkJWTRoles, IncomingPurchaseOrder } from '@solar/data';
import {
  ModalGroup,
  PageLayout,
  useModalGroupController,
} from '@solar/templates';
import { Space } from 'antd';
import { useCallback } from 'react';
import { IncomingQualityControlModal } from './IncomingQualityControlModal';
import { Modals } from './Modals.enum';
import { IncomingQualityControlTable } from '../IncomingQualityControlTable/IncomingQualityControlTable';

export function IncomingQualityControlPage() {
  const modalGroupController = useModalGroupController([
    { name: Modals.QUALITY_CONTROL },
  ]);

  const onQualityControl = useCallback(
    (source: IncomingPurchaseOrder) => {
      modalGroupController.openModal(Modals.QUALITY_CONTROL, source);
    },
    [modalGroupController]
  );

  return (
    <PageLayout title="進料品檢">
      <IncomingQualityControlTable
        statusIds={[1, 2, 3, 4]}
        getColumns={(defaultColumns) => [
          {
            width: 100,
            title: '操作',
            render: (source: IncomingPurchaseOrder) =>
              source?.statusId === 1 ? (
                <Space>
                  {checkJWTRoles([136]) && (
                    <Button
                      type="button"
                      variant="contained"
                      onClick={() => onQualityControl(source)}
                    >
                      檢驗
                    </Button>
                  )}
                </Space>
              ) : null,
          },
          ...defaultColumns,
        ]}
      />
      <ModalGroup {...modalGroupController}>
        <IncomingQualityControlModal />
      </ModalGroup>
    </PageLayout>
  );
}
