import {
  Button,
  Icon,
  MenuItem,
  Popover,
  Table,
  Typography,
} from '@mezzanine-ui/react';
import {
  FormFieldsWrapper,
  SearchInputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { MoreVerticalIcon } from '@mezzanine-ui/icons';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import styles from './HumanResourceBUTeamPage.module.scss';
import { BUTeamModal } from '../BUTeamModal/BUTeamModal';
import {
  mutationPayload,
  useHumanResourceBUManage,
  useHumanResourceBUTeam,
} from '@solar/data';

type ColumnType = Record<'id' | 'name' | 'code' | 'businessUnitName', string>;

export function HumanResourceBUTeamPage() {
  const {
    data: teamData,
    loading,
    filterMutation: filterHumanResourceBUTeam,
    isBusinessUnit,
  } = useHumanResourceBUTeam();
  const teamList = teamData?.data;

  const { data: buList } = useHumanResourceBUManage();
  const buOptions = buList?.map((bu: { id: string; name: string }) => ({
    id: bu.id,
    name: bu.name,
  }));

  const methods = useForm();
  const [page, setPage] = useState(1);
  const [per_page, setPer_page] = useState(teamData?.per_page);
  const [popMenu, setPopMenu] = useState<{
    anchor: HTMLButtonElement;
    source: {
      name: string;
      code: string;
      id: string;
      businessUnitName: string;
    };
  } | null>(null);
  const [modal, setModal] = useState<{
    id: string;
    name: string;
    code: string;
    businessUnitName: string;
  } | null>(null);
  const [updateFilterObject, setUpdateFilterObject] = useState(true);

  const columns = [
    {
      title: '#',
      width: 120,
      render(source: unknown) {
        if (!isBusinessUnit(source)) return;
        if (!source.businessUnitName)
          return (
            <Typography variant="input2" color="error-light">
              {source.id}
            </Typography>
          );
        return source.id;
      },
    },
    {
      title: '部門名稱',
      width: 240,
      render(source: unknown) {
        if (!isBusinessUnit(source)) return;
        if (!source.businessUnitName)
          return (
            <Typography variant="input2" color="error-light">
              {source.name}
            </Typography>
          );
        return source.name;
      },
    },
    {
      title: '代碼',
      width: 220,
      render(source: unknown) {
        if (!isBusinessUnit(source)) return;
        if (!source.businessUnitName)
          return (
            <Typography variant="input2" color="error-light">
              {source.code}
            </Typography>
          );
        return source.code;
      },
    },
    {
      title: 'BU',
      render(source: unknown) {
        if (!isBusinessUnit(source)) return;
        if (!source.businessUnitName)
          return (
            <Typography variant="input2" color="error-light">
              尚未歸檔
            </Typography>
          );
        return source.businessUnitName;
      },
    },
    {
      title: '動作',
      width: 60,
      render(source: ColumnType) {
        return (
          <Button
            type="button"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();

              const getButtonElement = (
                elem = e.target
              ): HTMLButtonElement | null => {
                if (elem instanceof HTMLButtonElement) return elem;

                const parent = (elem as HTMLElement).parentNode;

                if (!parent) return null;
                return getButtonElement(parent);
              };

              const anchor = getButtonElement();

              if (anchor) {
                setPopMenu({
                  anchor,
                  source,
                });
              } else {
                setPopMenu(null);
              }
            }}
            prefix={<Icon icon={MoreVerticalIcon} />}
          />
        );
      },
    },
  ];

  const onCloseModal = () => {
    setModal(null);
  };

  const submitModal = () => {
    setModal(null);
  };

  const selectType = methods.getValues('type');
  const searchInput = methods.getValues('searchInput');
  const codeSearchInput = methods.getValues('codeSearchInput');

  const filterObject: mutationPayload = {
    unitId: selectType?.id,
    oldUnitId: searchInput,
    code: codeSearchInput,
  };

  const searchedFilterObject = useMemo(() => {
    return {
      unitId: selectType?.id,
      oldUnitId: searchInput,
      code: codeSearchInput,
    };
  }, [updateFilterObject]);

  return (
    <div>
      {modal && (
        <BUTeamModal
          id={modal.id}
          name={modal.name}
          code={modal.code}
          oldUnitName={modal.businessUnitName}
          onClose={onCloseModal}
          onSubmit={submitModal}
        />
      )}
      <Typography variant="h1" style={{ marginBottom: 32 }}>
        部門 BU 歸檔
      </Typography>
      <FormFieldsWrapper methods={methods} className={styles.flex}>
        <div style={{ display: 'flex' }}>
          <div className={styles.flex}>
            <Typography
              variant="h6"
              color="secondary"
              className={styles.buText}
            >
              BU：
            </Typography>
            <SelectField
              registerName="type"
              options={buOptions ?? []}
              clearable={true}
            ></SelectField>
          </div>
          <div className={styles.searchInput}>
            <Typography variant="h6" color="secondary" style={{ width: 90 }}>
              部門名稱：
            </Typography>
            <SearchInputField registerName="searchInput" clearable={true} />
          </div>
          <div className={styles.searchInput}>
            <Typography variant="h6" color="secondary" style={{ width: 55 }}>
              代碼：
            </Typography>
            <SearchInputField registerName="codeSearchInput" clearable={true} />
          </div>
        </div>
        <Button
          type="button"
          className={styles.filterButton}
          variant="contained"
          onClick={() => {
            setUpdateFilterObject(!updateFilterObject);
            filterHumanResourceBUTeam({
              ...filterObject,
              per_page,
              current_page: 1,
            });
            setPage(1);
          }}
        >
          送出篩選條件
        </Button>
      </FormFieldsWrapper>
      <Table
        loading={loading}
        style={{ marginTop: '24px' }}
        bodyClassName={styles.table}
        pagination={{
          disableAutoSlicing: true,
          total: teamData?.total,
          current: page,
          options: {
            pageSize: per_page,
            onChangePageSize: (pageSize: number) => {
              filterHumanResourceBUTeam({
                ...searchedFilterObject,
                current_page: 1,
                per_page: pageSize,
              });
              setPer_page(pageSize);
              setPage(1);
            },
            showPageSizeOptions: true,
            pageSizeLabel: '每頁顯示：',
            pageSizeUnit: '筆',
          },
          onChange: (page) => {
            filterHumanResourceBUTeam({
              ...searchedFilterObject,
              current_page: page,
              per_page,
            });
            setPage(page);
          },
        }}
        dataSource={teamList ?? []}
        columns={columns}
      />
      <Popover
        anchor={popMenu?.anchor}
        open={!!popMenu}
        onClose={() => setPopMenu(null)}
      >
        <MenuItem>
          <Button
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
            }}
            type="button"
            onClick={() => {
              if (!popMenu) return;
              setModal(popMenu.source);
              setPopMenu(null);
            }}
          >
            <Typography variant="button1" color="primary" align="left">
              編輯
            </Typography>
          </Button>
        </MenuItem>
      </Popover>
    </div>
  );
}
