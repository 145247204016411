import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Button, Typography } from '@mezzanine-ui/react';
import {
  DateTimePickerField,
  FormFieldsWrapper,
} from '@mezzanine-ui/react-hook-form';
import {
  LocationSelectorGroupModal,
  LocationSelectorProvider,
  LOCATION_SELECTOR,
  ModalGroup,
  RowSection,
  useLocationSelectorController,
  useModalGroupController,
} from '@solar/templates';
import { Descriptions, Table } from 'antd';
import moment from 'moment';
import { useRef } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { WarehouseAndVehicleFilter } from '../IncomingInspectOperationPage/Modals/WarehouseAndVehicleFilter';
import { PageLayout } from '../PageLayout/PageLayout';
import { A4Wrapper } from './A4Preview';
import { useTableColumn } from './hooks/detail-page/useTableColumn';
import { useTableColumnForPreview } from './hooks/detail-page/useTableColumnForPreview';
import classes from './inventory-detail.module.scss';
import { InventoryDetailFormProps, InventoryDetailTableProps } from './types';

const PAGE_SIZE = 5;
const mock = {
  id: 'test',
  storageLocation: '1100A1BC13',
  vehicles: '',
  materialId: 'A1234567891234567891234567891234567891234567',
  materialDescription: 'A1234567891234567891234567891234567891234567',
  sapBatchId: '2310310002-0001',
  availableMaterialStockUnitQuantity: '100',
  materialStockUnit: 'G',
  availableMaterialInputUnitQuantity: '100',
  availableMaterialInputUnit: 'ST',
  actualWeight: '100',
  weightUnit: 'g',
};

const labelStyle = {
  fontWeight: 'bold',
  color: 'black',
};
export function InventoryDetail() {
  const printRef = useRef<HTMLDivElement>(null);
  const methods = useForm<InventoryDetailFormProps>({
    defaultValues: {
      pagination: {
        current: 1,
        pageSize: PAGE_SIZE,
      },
      editable: false,
      inventoryRows: [
        mock,
        mock,
        mock,
        mock,
        mock,
        mock,
        mock,
        mock,
        mock,
        mock,
      ],
    },
  });

  const editable = useWatch({ control: methods.control, name: 'editable' });
  const lockTime = useWatch({ control: methods.control, name: 'lockTime' });
  const pagination = useWatch({ control: methods.control, name: 'pagination' });

  const { fields: inventoryRows, insert } = useFieldArray({
    control: methods.control,
    name: 'inventoryRows',
  });
  const column = useTableColumn({ formContext: methods });
  const previewColumn = useTableColumnForPreview();
  const { inventoryId } = useParams<{ inventoryId: string }>();

  const modalGroupController = useModalGroupController([
    { name: LOCATION_SELECTOR },
  ]);

  const warehouseAndVehicleFilterRef =
    useRef<React.ElementRef<typeof WarehouseAndVehicleFilter>>(null);

  const locationSelectorController = useLocationSelectorController({
    resetAfterClosing: false,
  });

  const svgMapController = useLocationSelectorController({
    resetAfterClosing: false,
  });

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
      @media print {
        .page-break {
          display: none !important;
        }
        .preview {
          border: none !important;
        }
        @page { size: landscape; }
      }
    `,
  });

  return (
    <>
      <PageLayout setGoBack>
        <LocationSelectorProvider controller={locationSelectorController}>
          <FormFieldsWrapper
            className={classes['filter-and-table']}
            methods={methods}
            onSubmit={() => ''}
          >
            {/* <FormFieldsDebug /> */}
            <Typography variant="h1">盤點文件名稱</Typography>
            <WarehouseAndVehicleFilter
              locationLabel="鎖定區域"
              openLocationSelectorModal={() =>
                modalGroupController.openModal(LOCATION_SELECTOR, null)
              }
              ref={warehouseAndVehicleFilterRef}
            />
            <RowSection label="盤點時間">
              <DateTimePickerField registerName="lockTime" hideSecond />
            </RowSection>
            <div className={classes['action-btn-wrapper']}>
              <Button variant="contained" type="button" onClick={handlePrint}>
                列印盤點表
              </Button>
              <Button
                variant={editable ? 'outlined' : 'contained'}
                type="button"
                onClick={() => {
                  methods.setValue('editable', !editable);
                }}
              >
                {editable ? '取消編輯' : '盤盈虧'}
              </Button>
              <Button
                variant="contained"
                type="button"
                onClick={() => {
                  methods.setValue('editable', false);
                }}
              >
                儲存
              </Button>
              <Button
                variant="outlined"
                type="button"
                onClick={() => {
                  insert(0, mock);
                }}
              >
                ＋新增庫存
              </Button>
            </div>
            <Table
              components={{
                body: { wrapper: Wrapper, row: Row },
              }}
              rowKey="id"
              columns={column}
              dataSource={inventoryRows}
              pagination={pagination}
              onChange={(nextPagination) => {
                methods.setValue(
                  'pagination',
                  nextPagination as InventoryDetailFormProps['pagination']
                );
              }}
            />
          </FormFieldsWrapper>
        </LocationSelectorProvider>
        {/* pdf print preview */}
        <div
          style={{
            overflowY: 'scroll',
          }}
        >
          <div ref={printRef}>
            {methods
              .watch('inventoryRows')
              .reduce((accu, cur, index) => {
                if (index % PAGE_SIZE === 0) {
                  const slice = methods
                    .watch('inventoryRows')
                    .slice(index, index + PAGE_SIZE);
                  accu.push(slice);
                }

                return accu;
              }, [] as Array<InventoryDetailTableProps[]>)
              .map((page) => {
                return (
                  <>
                    <A4Wrapper landscape>
                      <div className={classes['preview-table']}>
                        {/* 頁首 */}
                        <Descriptions>
                          <Descriptions.Item
                            labelStyle={labelStyle}
                            label="盤點文件"
                          >
                            &nbsp;
                          </Descriptions.Item>
                          <Descriptions.Item
                            labelStyle={labelStyle}
                            label="盤點位置"
                          >
                            {locationSelectorController?.activeId ?? '\u00A0'}
                          </Descriptions.Item>
                          <Descriptions.Item
                            labelStyle={labelStyle}
                            label="盤點截止時限"
                          >
                            {`${
                              lockTime
                                ? moment(lockTime).format('YYYY/MM/DD HH:mm')
                                : '\u00A0'
                            }`}
                          </Descriptions.Item>
                        </Descriptions>
                        <Table
                          components={{
                            body: { wrapper: Wrapper, row: Row },
                          }}
                          rowKey="id"
                          pagination={false}
                          columns={previewColumn}
                          dataSource={page}
                        />
                        {/* 頁尾 */}
                        <Descriptions column={4}>
                          <Descriptions.Item
                            labelStyle={labelStyle}
                            label="抽盤"
                          >
                            &nbsp;
                          </Descriptions.Item>
                          <Descriptions.Item
                            labelStyle={labelStyle}
                            label="複盤"
                          >
                            &nbsp;
                          </Descriptions.Item>
                          <Descriptions.Item
                            labelStyle={labelStyle}
                            label="主管"
                          >
                            &nbsp;
                          </Descriptions.Item>
                          <Descriptions.Item
                            labelStyle={labelStyle}
                            label="初盤"
                          >
                            &nbsp;
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                    </A4Wrapper>
                    <div className={classes['page-break']} />
                  </>
                );
              })}
          </div>
        </div>
      </PageLayout>
      <ModalGroup {...modalGroupController}>
        <LocationSelectorGroupModal
          controller={svgMapController}
          showShelfSvg={true}
          onSubmit={(_, selectedIds) => {
            locationSelectorController.setActiveId(_);
            locationSelectorController.setSelectedIds(selectedIds ?? {});
          }}
        />
      </ModalGroup>
    </>
  );
}

const Wrapper = (props: React.HTMLAttributes<HTMLTableSectionElement>) => {
  const [parent] = useAutoAnimate();
  return (
    <tbody ref={parent} {...props}>
      {props.children}
    </tbody>
  );
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = (props: RowProps) => {
  return <tr key={props['data-row-key']} {...props} />;
};
