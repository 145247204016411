import { Table } from '@mezzanine-ui/react';
import styles from './../../TargetNumber.module.scss';
import { useDispatchedTargetTableColumns } from './useDispatchedTargetTableColumns';
import { useEffect, useMemo, useState } from 'react';
import { DispatchedTargetEditModal } from './Modal/DispatchedTargetEditModal';
import {
  DispatchedTargetNumberRuleFilter,
  useGetDispatchedTargetNumberRule,
} from '@solar/data';
import { DispatchedTargetDisableModal } from './Modal/DispatchedTargetDisableModal';

interface TargetNumberRuleDispatchedFilterProps {
  filterParams: DispatchedTargetNumberRuleFilter;
}

export function DispatchedTargetTable({
  filterParams,
}: TargetNumberRuleDispatchedFilterProps) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [editModalShow, setEditModalShow] = useState(false);
  const [disableModalShow, setDisableModalShow] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [selectedNumberHistoryId, setSelectedNumberHistoryId] = useState<
    number | null
  >(null);
const [selectedItem, setSelectedItem] = useState<any | null>(null);

  const openEditModal = (numberHistoryId: number, item: any) => {
    setSelectedNumberHistoryId(numberHistoryId);
    setSelectedItem(item);
    setEditModalShow(true);
  };

  const closeEditModal = () => {
    setSelectedNumberHistoryId(null);
    setEditModalShow(false);
  };

  const openDisableModal = (numberHistoryId: number, item: any) => {
    setSelectedNumberHistoryId(numberHistoryId);
    setSelectedItem(item);
    setDisableModalShow(true);
  };

  const closeDisableModal = () => {
    setSelectedNumberHistoryId(null);
    setDisableModalShow(false);
  };

  const currentFilterParams = useMemo(
    () => ({ ...filterParams, page_at: page, page_size: pageSize, sort_by: 'issued_date', sort: 'desc' }),
    [filterParams, page, pageSize]
  );

  const {
    dispatchedNumberRules = [],
    isLoading: isLoadingRules,
    total,
  } = useGetDispatchedTargetNumberRule(currentFilterParams, refreshTrigger);

  const columns = useDispatchedTargetTableColumns({
    openEditModal,
    openDisableModal,
  });

  const triggerRefresh = () => {
    setRefreshTrigger(prev => !prev);
  };

  useEffect(() => {
    setPage(1);
  }, [filterParams]);

  return (
    <div>
      <Table
        className={styles.table}
        columns={columns}
        dataSource={dispatchedNumberRules}
        bodyClassName={styles.tableBody}
        loading={isLoadingRules}
        scroll={{ x: 3000 }}
        pagination={{
          disableAutoSlicing: true,
          current: page,
          total: total,
          onChange: setPage,
          options: {
            pageSize: pageSize,
            onChangePageSize: setPageSize,
            showJumper: true,
            jumperButtonText: '前往',
            jumperHintText: '跳至',
            jumperInputPlaceholder: '頁碼',
            className: styles.pagination,
          },
        }}
      />
      <DispatchedTargetEditModal
        open={editModalShow}
        onClose={closeEditModal}
        selectedNumberHistoryId={selectedNumberHistoryId}
        selectedItem={selectedItem}
        onSuccessfulDispatch={triggerRefresh}
      />
      <DispatchedTargetDisableModal
        open={disableModalShow}
        onClose={closeDisableModal}
        selectedNumberHistoryId={selectedNumberHistoryId}
        selectedItem={selectedItem}
        onSuccessfulDispatch={triggerRefresh}
      />
    </div>
  );
}
