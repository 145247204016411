import {
  FormAnswer,
  ProductionFormResponse,
  getProductionFormAnswers,
  getProductionForms,
} from '@solar/data';
import { useCallback, useState } from 'react';
import { FormValues } from './typing';
import { UseFormReturn } from 'react-hook-form';
import { groupBy } from 'lodash';
import { Message } from '@mezzanine-ui/react';

export function useSubmissionResults({
  methods,
}: {
  methods: UseFormReturn<FormValues>;
}) {
  const [searching, setSearching] = useState(false);

  const [allAnswers, setAllAnswers] = useState<FormAnswer[]>();
  const [manualForms, setManualForms] = useState<ProductionFormResponse[]>();
  const [submissionResults, setSubmissionResults] = useState<
    {
      submission: FormAnswer['subSampleMethodItem']['submission'];
      formAnswers: FormAnswer[];
    }[]
  >();

  const onSearchSubmissionResults = useCallback(
    async ({
      factory,
      materialId,
      batchId,
    }: {
      factory: string;
      materialId: string;
      batchId: string;
    }) => {
      if (!factory) {
        Message.error('請選擇工廠');
        return;
      }
      if (!materialId) {
        Message.error('請選擇料號');
        return;
      }
      if (!batchId) {
        Message.error('請選擇批號');
        return;
      }
      try {
        setSearching(true);
        const [materialSapBatchId, materialSubBatchId] =
          batchId?.split('-') ?? [];

        const { forms } = await getProductionForms([
          {
            templateTypes: ['COMPONENT_CODE'],
            materialId,
          },
        ]);

        const productionFormAnsRes = await getProductionFormAnswers({
          offset: 0,
          factory,
          materialId,
          materialSapBatchId,
          materialSubBatchId,
          withLims: true,
          selectDefaultAndPicked: false,
          templateTypes: ['COMPONENT_CODE'],
        });

        const formAnswers = productionFormAnsRes?.records ?? [];

        const submissionFormAnswersCollection = formAnswers?.reduce<
          FormAnswer[]
        >((collection, item) => {
          const isInForm = forms?.some((form) => form?.id === item?.form?.id);

          if (isInForm) {
            methods.setValue(`modifiedByForm.${item?.form?.id}`, {
              picked: item?.picked ?? false,
              answerId: item?.id ?? null,
              modifiedAnswer: item?.modifiedAnswer ?? item?.answer ?? null,
              form: item?.form,
            });
          } else {
            collection.push(item);

            methods?.setValue(`answers.${item?.id}`, {
              formId: item?.form?.id ?? null,
              form: item?.form,
              answer: item?.answer,
              picked: item?.picked ?? false,
            });
          }

          return collection;
        }, []);

        setAllAnswers(formAnswers);

        setManualForms(forms);

        setSubmissionResults(
          Object.entries(
            groupBy(
              submissionFormAnswersCollection,
              (item) => item?.subSampleMethodItem?.submissionId
            )
          ).map(([_key, formAnswers]) => ({
            submission:
              formAnswers?.[0]?.subSampleMethodItem?.submission ?? null,
            formAnswers,
          }))
        );
      } catch (error) {
        Message.error('有誤');
        console.error(error);
      } finally {
        setSearching(false);
      }
    },
    [methods]
  );

  const resetSubmissionResults = () => {
    setManualForms(undefined);
    setSubmissionResults(undefined);
    setAllAnswers(undefined);
  };

  return {
    searching,
    allAnswers,
    manualForms,
    submissionResults,
    onSearchSubmissionResults,
    resetSubmissionResults,
  };
}
