export const mockLoaderTypesList = [
  {
    id: 'A',
    type: '台車',
  },
  { id: 'B', type: '袋子' },
  {
    id: 'C',
    type: '管',
  },
];

export enum Modals {
  CreateLoaders = 'CreateLoaders',
  ViewLoaders = 'ViewLoaders ',
}
