import { WaitReceiptStatus } from '@solar/data';
import { ExceptionPanel } from './panel/ExceptionPanel';
import { WaitStorageInPanel } from './panel/WaitStorageInPanel';
import { TabsEnum } from './types';
import * as Yup from 'yup';
import { RecordPanel } from './panel/RecordPanel';

export const tabsOptions = [
  {
    id: TabsEnum.StorageIn,
    name: '待入庫',
    panel: WaitStorageInPanel,
  },
  {
    id: TabsEnum.Exception,
    name: '異常待檢',
    panel: ExceptionPanel,
  },
  { id: TabsEnum.Record, name: '入庫紀錄', panel: RecordPanel },
];

export const tabIds = tabsOptions.map((tab) => tab.id);

export const waitReceiptStatus = {
  [TabsEnum.StorageIn]: [WaitReceiptStatus.NORMAL],
  [TabsEnum.Exception]: [WaitReceiptStatus.ABNORMAL],
  [TabsEnum.Record]: [
    WaitReceiptStatus.ABNORMAL_EXECUTED,
    WaitReceiptStatus.NORMAL_EXECUTED,
  ],
} as const;

export const inspectModalFormValidator = ({
  isException = false,
  isStorageInAction = false,
}: {
  isException?: boolean;
  isStorageInAction?: boolean;
}) =>
  Yup.object({
    materialWeightUnitQuantity: Yup.string().required('必填'),
    ...(isException &&
      isStorageInAction && {
        materialId: Yup.object()
          .shape({
            id: Yup.string().required('必填'),
          })
          .required('進行入庫時，料號必填'),
      }),
  });
