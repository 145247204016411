import { SearchIcon } from '@mezzanine-ui/icons';
import {
  Button,
  Icon,
  Input,
  Message,
  Table,
  Typography,
} from '@mezzanine-ui/react';
import {
  API_NAMESPACE,
  IQCOrderType,
  IncomingPurchaseOrder,
  checkJWTRoles,
  useGetIQCOrderStatuses,
  useInventoryByPurchaseOrder,
} from '@solar/data';
import {
  BatchUpdateButton,
  CollapsibleContainer,
  LocationSelectorGroupModal,
  ModalGroup,
  useDraggableColumns,
  useLocationSelectorController,
  useModalGroupController,
} from '@solar/templates';
import { useCallback, useMemo, useRef, useState } from 'react';
import classes from './incoming-inspect-detail-page.module.scss';

import { useLocation, useParams } from 'react-router';
import { LOCATION_SELECTOR } from '../../Equipment/LocationSelector/LocationSelectorModal';
import styles from '../../SalesAndDistribution/PackagingPage/PackagingPage.module.scss';
import { OrderMetaInfo } from '../components';
import { PageLayout } from '../PageLayout/PageLayout';
import { PURCHASE_ORDER_META_TRANSLATION } from './constant';
import { detailPageHeader } from './detail-page-header';
import {
  useDebounceILikeSearch,
  usePurchaseOrderExpectedColumns,
  usePurchaseOrderReceivedColumns,
} from './hooks';
import { InspectMaterialType } from './inspect-modal-enum';
import { CancelReceiveModal } from './Modals/CancelReceiveModal';
import {
  MainMaterialUnitsObj,
  ReceiveActionInputs,
  TempReceiveActionModal,
} from './Modals/TempReceiveActionModal';
import { Modals, ReceiveActionModalOpenFunc } from './typings';
import {
  handleTableDataILikeSearch,
  // uncheckInventoryPurchaseOrders,
  uploadCsv,
} from './utils';
import { IncomingQualityControlTable } from '../../Qualifications/IncomingQualityControlTable/IncomingQualityControlTable';
import { Space } from 'antd';
import { IncomingQualityControlModal } from './IncomingQualityControlModal';

const namespace = API_NAMESPACE.MATERIALS;

export type ReceivableItemTable = {
  id: string;
  materialId: string;
  oldMaterialId: string;
  materialDescription: string;
  expectedMainMaterialUnitQuantity: number;
  receivedMainMaterialUnitQuantity?: number;
  receiveUnit: string;
  specId: string;
};

export type ReceivedMaterialItemTable = ReceivableItemTable &
  Pick<ReceiveActionInputs, 'loader' | 'warehouse'> & {
    subItems: ReceivedMaterialSubItem[];
  };

export type ReceivedMaterialSubItem = { id: string } & MainMaterialUnitsObj &
  Pick<ReceivableItemTable, 'receiveUnit'>;

export function TempDetailPage() {
  const location = useLocation();
  const { orderId } = useParams();
  const { data } = useInventoryByPurchaseOrder({ orderId });
  const orders = data?.items ?? [];
  const logs = data?.logs ?? [];
  const expectedTableRef = useRef<HTMLTableElement>(null);

  const { tableDataSearchTerms, debounceInputHandler } =
    useDebounceILikeSearch();

  const { statuses } = useGetIQCOrderStatuses();

  const orderMetaDataLabelAndContext = useMemo(
    () =>
      data
        ? Object.entries(PURCHASE_ORDER_META_TRANSLATION).map(([key, zh]) => ({
            label: zh,
            context: data[key as keyof typeof PURCHASE_ORDER_META_TRANSLATION],
          }))
        : [],
    [data]
  );

  const modalGroupController = useModalGroupController([
    { name: Modals.ReceiveAction, keepData: true },
    { name: Modals.Cancel },
    { name: LOCATION_SELECTOR },
    { name: Modals.QUALITY_CONTROL },
  ]);

  const locationSelectorController = useLocationSelectorController({
    resetAfterClosing: false,
  });

  const svgMapController = useLocationSelectorController({
    resetAfterClosing: false,
  });

  const receiveActionModalOpen: ReceiveActionModalOpenFunc = useCallback(
    (item) => {
      const modalProps = modalGroupController.getModalProps(
        Modals.ReceiveAction
      );
      const data = modalProps?.data;
      modalGroupController.openModal(Modals.ReceiveAction, {
        ...data,
        ...item,
      });
    },
    [modalGroupController]
  );

  const cancelModalOpen = useCallback(
    (cancelFunc: () => void) => {
      modalGroupController.openModal(Modals.Cancel, { cancelFunc: cancelFunc });
    },
    [modalGroupController]
  );

  const defaultExpectedColumn = usePurchaseOrderExpectedColumns({
    receiveActionModalOpen,
  });

  const expectedColumn = useDraggableColumns(
    expectedTableRef,
    defaultExpectedColumn
  );

  const receivedMaterialColumn = usePurchaseOrderReceivedColumns({
    cancelModalOpen,
  });

  const [uploading, setUploading] = useState(false);

  const onUploadCsv = useCallback(async (file: File) => {
    try {
      setUploading(true);

      const res = await uploadCsv({
        file,
        endPoint: '/warehouses/inventory-purchase-orders/receipt-csv',
        namespace,
      });

      if (res.ok) {
        Message.success('批量入庫成功');
      }
    } catch (err) {
      if (err instanceof Error) {
        Message.error(JSON.parse(err.message).message);
      } else {
        Message.error('上傳失敗');
      }
    } finally {
      setUploading(false);
    }
  }, []);

  const onQualityControl = useCallback(
    (action: '驗收' | '退貨', source: IncomingPurchaseOrder) => {
      modalGroupController.openModal(Modals.QUALITY_CONTROL, {
        action,
        source,
      });
    },
    [modalGroupController]
  );

  return (
    <>
      <PageLayout setGoBack>
        <div className={classes['action-container']}>
          <Typography variant="h1">
            {
              detailPageHeader[
                location.pathname.split('/')[2] as InspectMaterialType
              ]
            }
          </Typography>
          {checkJWTRoles([82]) && (
            <div>
              <BatchUpdateButton
                loading={uploading}
                buttonText="批量入庫"
                onUploadCsv={onUploadCsv}
              />
            </div>
          )}
          {/* <div style={{ marginLeft: 'auto' }}>
            <Button
              variant="outlined"
              style={{ height: '50px' }}
              type="button"
              onClick={async () => {
                orderId && (await uncheckInventoryPurchaseOrders(orderId));
              }}
            >
              驗收
            </Button>
          </div> */}
        </div>
        <div className={classes['row-block']}>
          <OrderMetaInfo
            labelAndContexts={orderMetaDataLabelAndContext ?? []}
          />
        </div>
        <CollapsibleContainer
          enableRowBlockStyle
          open
          header={
            <div className={classes['action-container']}>
              <Typography variant="h4" color="text-primary">
                應收項目
              </Typography>
              <Input
                placeholder="料號搜尋"
                prefix={<Icon icon={SearchIcon} />}
                size="large"
                clearable
                onChange={(e) =>
                  debounceInputHandler(e, 'receivableMaterialIdSearch')
                }
              />
              <Input
                placeholder="品名搜尋"
                prefix={<Icon icon={SearchIcon} />}
                size="large"
                clearable
                onChange={(e) =>
                  debounceInputHandler(e, 'receivableMaterialDescriptionSearch')
                }
              />
            </div>
          }
        >
          <br />
          <Table
            ref={expectedTableRef}
            columns={expectedColumn}
            dataSource={handleTableDataILikeSearch({
              dataSource: orders,
              fieldParams: {
                materialIdKey: 'receivableMaterialIdSearch',
                materialDescriptionKey: 'receivableMaterialDescriptionSearch',
              },
              tableDataSearchTerms,
            })}
            scroll={{ x: 1200 }}
            bodyClassName={styles.table}
            headerClassName={styles.tableHeader}
          />
        </CollapsibleContainer>
        <CollapsibleContainer
          enableRowBlockStyle
          open
          header={
            <div className={classes['action-container']}>
              <Typography variant="h4" color="text-primary">
                已收紀錄
              </Typography>
              {/* <Input
                placeholder="料號搜尋"
                prefix={<Icon icon={SearchIcon} />}
                size="large"
                clearable
                onChange={(e) =>
                  debounceInputHandler(e, 'receivedMaterialIdSearch')
                }
              />
              <Input
                placeholder="品名搜尋"
                prefix={<Icon icon={SearchIcon} />}
                size="large"
                clearable
                onChange={(e) =>
                  debounceInputHandler(e, 'receivedMaterialDescriptionSearch')
                }
              /> */}
            </div>
          }
        >
          <br />
          <div style={{ width: '100%', overflow: 'scroll' }}>
            <IncomingQualityControlTable
              iqcOrderType={IQCOrderType.PURCHASE_ORDER}
              purchaseOrderId={orderId}
              statusIds={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
              getColumns={(defaultColumns) => [
                {
                  width: 100,
                  title: '操作',
                  render: (source: IncomingPurchaseOrder) => (
                    <Space>
                      {checkJWTRoles([82]) &&
                        (source.statusId === 2 || source.statusId === 8) && (
                          <Button
                            type="button"
                            variant="contained"
                            onClick={() => onQualityControl('驗收', source)}
                          >
                            驗收
                          </Button>
                        )}
                      {checkJWTRoles([82]) &&
                        (source.statusId === 5 || source.statusId === 9) && (
                          <Button
                            danger
                            type="button"
                            variant="contained"
                            onClick={() => onQualityControl('退貨', source)}
                          >
                            退貨
                          </Button>
                        )}
                    </Space>
                  ),
                },
                ...defaultColumns,
              ]}
            />
            {/* <Table
              columns={receivedMaterialColumn}
              dataSource={handleTableDataILikeSearch({
                dataSource: logs,
                fieldParams: {
                  materialIdKey: 'receivedMaterialIdSearch',
                  materialDescriptionKey: 'receivedMaterialDescriptionSearch',
                },
                tableDataSearchTerms,
              })}
              bodyClassName={styles.table}
              headerClassName={styles.tableHeader}
              scroll={{ x: 2500 }}
            /> */}
          </div>
        </CollapsibleContainer>
        <div style={{ height: '16px' }}></div>
      </PageLayout>
      <ModalGroup {...modalGroupController}>
        <CancelReceiveModal />
        <TempReceiveActionModal
          locationSelectorController={locationSelectorController}
        />
        <LocationSelectorGroupModal
          controller={svgMapController}
          showShelfSvg={true}
          onSubmit={(_, selectedIds) => {
            locationSelectorController.setSelectedIds(selectedIds ?? {});
            receiveActionModalOpen(null);
          }}
          closeModal={() => receiveActionModalOpen(null)}
        />
        <IncomingQualityControlModal purchaseOrderId={orderId} />
      </ModalGroup>
    </>
  );
}
