import useSWR from "swr";
import { API_NAMESPACE, request } from '../request';
import { SelectValue } from "@mezzanine-ui/react";
import { useMemo, useState } from "react";

export const useOrderPredict = () => {

  const [orderList, setOrderList] = useState([]);
  // 客戶
  const { data: customerList } = useSWR([
    '/selectItem/listCustomer',
    { namespace: API_NAMESPACE.MISC, }
  ]);

  // 料號
  const { data: materialList } = useSWR([
    '/selectItem/listMaterial',
    {
      namespace: API_NAMESPACE.MISC,
      // 20240115: pm 說預測訂單不用指定貴金屬料號了
      // params: { isExpensive: true }
    }
  ]);

  // 業務人員
  const { data: salesList } = useSWR([
    '/selectItem/listMember',
    { namespace: API_NAMESPACE.MISC }
  ]);

  // 預測訂單類型
  const { data: forecastTypeList } = useSWR([
    '/selectItem/listForecastType',
    { namespace: API_NAMESPACE.MISC }
  ]);

  // 把握度
  const { data: certaintyList } = useSWR([
    '/selectItem//listCertainty',
    { namespace: API_NAMESPACE.MISC }
  ]);

  // 單位
  const { data: unitList } = useSWR([
    '/selectItem/listMaterialUnit',
    { namespace: API_NAMESPACE.MISC }
  ]);

  // 貴金屬成分
  const { data: expensiveCompNames } = useSWR([
    '/selectItem/listExpensiveComponent',
    { namespace: API_NAMESPACE.MISC },
  ]);

  const autoCompleteOptionsCustomer: SelectValue<string>[]
    = useMemo(() => {
      let options: SelectValue<string>[] = [];
      if (Array.isArray(customerList)) {
        options = customerList.map((customerData: any) => ({
          id: customerData.value,
          name: customerData.name,
        }));
      }
      return options;
    }, [customerList]);

  const autoCompleteOptionsMaterial: SelectValue<string>[]
    = useMemo(() => {
      let options: SelectValue<string>[] = [];
      if (Array.isArray(materialList)) {
        options = materialList.map((materialData: any) => ({
          id: materialData.value,
          name: `${materialData.value}${materialData.name ? '-' : ''}${materialData.name || ''}`,
        }));
      }
      return options;
    }, [materialList]);

  const autoCompleteOptionsSales: SelectValue<string>[]
    = useMemo(() => {
      let options: SelectValue<string>[] = [];
      if (Array.isArray(salesList)) {
        options = salesList.map((salesData: any) => ({
          id: salesData.value,
          name: salesData.name,
        }));
      }
      return options;
    }, [salesList]);

  const autocompleteOptionsForecastType: SelectValue<string>[]
    = useMemo(() => {
      let options: SelectValue<string>[] = [];
      if (Array.isArray(forecastTypeList)) {
        options = forecastTypeList.map((forecastTypeData: any) => ({
          id: forecastTypeData.value,
          name: forecastTypeData.name,
        }));
      }
      return options;
    }, [forecastTypeList]);

  const autocompleteOptionsCertainty: SelectValue<string>[]
    = useMemo(() => {
      let options: SelectValue<string>[] = [];
      if (Array.isArray(certaintyList)) {
        options = certaintyList.map((certaintyData: any) => ({
          id: certaintyData.value,
          name: certaintyData.name,
        }));
      }
      return options;
    }, [certaintyList]);

  const autocompleteOptionsUnit: SelectValue<string>[]
    = useMemo(() => {
      let options: SelectValue<string>[] = [];
      if (Array.isArray(unitList)) {
        options = unitList.map((unitData: any) => ({
          id: unitData.value,
          name: unitData.name,
        }));
      }
      return options;
    }, [unitList]);

  const searchPredictOrder = async (params: any) => {
    try {
      const result = await request('/forecastOrder/search', {
        params: params,
        method: 'GET',
        namespace: API_NAMESPACE.MISC,
      });
      setOrderList(result.map((d: any) => ({ ...d, id: d.id.toString() })));
    } catch (e) {
      console.error(e);
    }
  }

  const deletePredictOrder = async (id: string) => {
    try {
      const body = { id };
      const result = await request('/forecastOrder/delete', {
        body: JSON.stringify(body),
        method: 'POST',
        namespace: API_NAMESPACE.MISC,
      });
      return result;
    } catch (e) {
      console.error(e);
    }
  };

  return {
    autoCompleteOptionsCustomer,
    autoCompleteOptionsMaterial,
    autoCompleteOptionsSales,
    autocompleteOptionsForecastType,
    autocompleteOptionsCertainty,
    autocompleteOptionsUnit,
    searchPredictOrder,
    deletePredictOrder,
    orderList,
    expensiveCompNames,
  }
};


export const getOrderMaterialDetail = async (materialId: string) => {
  try {
    const result = await request('/material/get', {
      params: { id: materialId },
      method: 'GET',
      namespace: API_NAMESPACE.MISC,
    });
    return result;
  } catch (e) {
    console.error(e);
  }
};

export const getOrderPredict = async (id: string) => {
  return request('/forecastOrder/get', {
    params: { id },
    method: 'GET',
    namespace: API_NAMESPACE.MISC,
  });
};

export interface IMaterialComponentItem {
  component: string;
  rate: number;
  wt: number;
}

interface IOrderItem {
  // id?: number;
  materialId: string,
  forecastOrderId?: number,
  unit: string;
  price: number;
  qty: number;
  certainty: number;
  orderType: number;
  standardWt: number;
  stockArea: string;
  components: IMaterialComponentItem[];
  // key: string;
}

export interface ICompMaterialItem {
  atRate: number;
  componentCode: string;
  componentItemNumber: string;
  componentItemSeq: string;
  id: number;
  wtRate: number;
}