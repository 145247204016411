import sortBy from 'lodash/sortBy';
import { resourcesWithRoutes } from './const';
/**
 * @todo 此處型別之後再補上
 */

/**
 * @description Helper function to find the resource object by id
 * @description rename from rangeMenu to reconcileResourcePathWithPermittedRoles
 */
export const reconcileResourcePathWithPermittedRoles = (
  data: any
): Array<{
  parentModuleName: string;
  childModules: Array<{ id: number; name: string; path: string }>;
}> => {
  const menuMap = new Map();
  // console.log(data);
  try {
    for (const item of data) {
      if (!(item?.id !== 0)) continue;

      for (const resource of item.resource_with_actions) {
        const parentModuleName = resource.name;
        const parentId = resource.id;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!resourcesWithRoutes[resource.id]) {
          continue;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const pathsObj = resourcesWithRoutes[resource.id];

        const childModules = resource.actions
          .map((action: { id: number; name: any }) => {
            const resourceObj = pathsObj[action.id];

            if (resourceObj) {
              return {
                id: action.id,
                name: action.name.replace('_READONLY', ''),
                path: pathsObj[action.id],
              };
            }
            return null;
          })
          .filter(Boolean);
        if (childModules.length > 0) {
          if (menuMap.has(parentId)) {
            // Parent module already exists, check for existing child modules
            const existingChildModules = menuMap.get(parentId).childModules;
            const newChildModules = childModules.filter(
              (childModule: { id: any; path: string }) =>
                !existingChildModules.some(
                  (existingModule: { id: any; path: string }) =>
                    existingModule.id === childModule.id ||
                    existingModule.path === childModule.path
                )
            );

            if (newChildModules.length > 0) {
              // Append new child modules to the existing array
              existingChildModules.push(...newChildModules);
            }
          } else {
            // Parent module doesn't exist, create a new entry
            menuMap.set(parentId, {
              parentModuleName,
              parentModuleId: parentId,
              childModules,
            });
          }
        }
      }
    }
    const mappedResults = Array.from(menuMap.values());
    return mappedResults;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export function reorderSubsetMenus(subset: Array<any>, superset: Array<any>) {
  // Create a map for quick lookup of parent modules in the superset by their ID
  const parentMap = new Map(
    superset.map((parent: { childModules: any; parentModuleId: any }) => [
      parent.parentModuleId,
      parent,
    ])
  );

  // Sort the subset parent modules based on the order of parentModuleId in the superset
  const sortedSubset = sortBy(subset, (parent) => {
    const superIndex = superset.findIndex(
      (superParent: { parentModuleId: any }) =>
        superParent.parentModuleId === parent.parentModuleId
    );
    return superIndex >= 0 ? superIndex : Number.MAX_SAFE_INTEGER;
  });

  // Sort each parent's child modules based on the order in the corresponding superset parent module
  sortedSubset.forEach((parent) => {
    const superParent = parentMap.get(parent.parentModuleId);
    if (superParent) {
      parent.childModules = sortBy(parent.childModules, (child) => {
        const childIndex = superParent.childModules.findIndex(
          (superChild: { id: number }) => superChild.id === child.id
        );
        return childIndex >= 0 ? childIndex : Number.MAX_SAFE_INTEGER;
      });
    }
  });

  return sortedSubset;
}
