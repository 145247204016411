import { request, API_NAMESPACE } from '../../request';
import * as yup from 'yup';
import useSWR from 'swr';

const hubWarehouseQuerySchema = yup
  .object()
  .shape({
    page: yup.number().optional(),
    perPage: yup.number().optional(),
    materialId: yup.string().optional(),
    sapBatch: yup.string().optional(),
  })
  .optional();

export type HubWarehouseQueryType = yup.InferType<
  typeof hubWarehouseQuerySchema
>;

export type HubWarehouseMeta = {
  path: string;
  current_page: number;
  per_page: number;
  from: number;
  to: number;
  total: number;
  first_page_url: string;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  prev_page_url: string | null;
};

export type HubWarehouseData = {
  id: string;
  materialId: string;
  sapBatchId: string;
  subBatchId: string;
  barcode: string;
  quantity: string;
};

export type HubWarehousePayload = {
  data: HubWarehouseData[];
} & HubWarehouseMeta;

export function useHubWarehouseGet(query?: HubWarehouseQueryType) {
  const {
    data: swrData,
    mutate,
    isValidating,
  } = useSWR<HubWarehousePayload>(validateSWRKey(query), request, {
    revalidateOnFocus: false,
  });

  const { data, ...meta } = swrData ?? {};

  return {
    hubWarehouseData: transformWarehouseData(data ?? []),
    hubWarehouseMeta: (meta as HubWarehouseMeta) ?? {},
    refetechHubWarehouse: mutate,
    isLoading: isValidating,
  };
}

function validateSWRKey(query?: HubWarehouseQueryType) {
  try {
    for (const key in query) {
      const _key = key as keyof HubWarehouseQueryType;
      if (typeof query[_key] === 'undefined' || query[_key] === '') {
        delete query[_key];
      }
    }

    const safeQuery = hubWarehouseQuerySchema.validateSync(query);
    return [
      `/hubDeliveryOrders/get/warehouse`,
      { namespace: API_NAMESPACE.HR, params: safeQuery },
    ];
  } catch (error) {
    return false;
  }
}

function transformWarehouseData(data: HubWarehouseData[]) {
  return data.map((item) => ({
    ...item,
  }));
}
