import { Button } from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  DateRangePickerField,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { Col, Row } from 'antd';
import { useForm } from 'react-hook-form';
import { MznPaginationTable } from '@solar/templates';
import { Link } from 'react-router-dom';

export function DispatchSettingTabPaneContent() {
  const methods = useForm();

  const handleSearch = methods?.handleSubmit(() => {
    console.log();
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <FormFieldsWrapper methods={methods}>
        <Row gutter={[12, 12]}>
          <Col>
            <InputField registerName="sourceId" placeholder="請輸入來源單號" />
          </Col>
          <Col>
            <AutoCompleteField
              registerName="supplier"
              placeholder="請輸入供應商名稱"
              options={[]}
            />
          </Col>
          <Col>
            <AutoCompleteField
              registerName="workCenter"
              placeholder="請輸入工作中心名稱"
              options={[]}
            />
          </Col>
          <Col>
            <SelectField
              registerName="sourceType"
              placeholder="請輸入來源"
              options={[]}
            />
          </Col>
          <Col>
            <DateRangePickerField
              registerName="range"
              placeholder="請設定日期區間"
            />
          </Col>
          <Col>
            <Button variant="contained" onClick={handleSearch}>
              確認
            </Button>
          </Col>
        </Row>
      </FormFieldsWrapper>
      <MznPaginationTable
        fetchData={console.log}
        pageInfo={{}}
        dataSource={[{ id: '123' } as any]}
        columns={[
          {
            title: '操作',
            render: (source) => (
              <Row>
                <Col>
                  <Link
                    to={`/production-planning/outsourcing-orders/${source?.id}`}
                  >
                    <Button variant="outlined">檢視</Button>
                  </Link>
                </Col>
              </Row>
            ),
          },
          {
            dataIndex: 'orderDeliveryDate',
            title: '訂單交期',
          },
          {
            dataIndex: 'sourceType',
            title: '來源',
          },
          {
            dataIndex: 'sourceId',
            title: '來源單號',
          },
          {
            dataIndex: 'supplier',
            title: '供應商名稱',
          },
          {
            dataIndex: 'supplierCode',
            title: '供應商代碼',
          },
          {
            dataIndex: 'workCenter',
            title: '工作中心名稱',
          },
          {
            dataIndex: 'workCenterCode',
            title: '工作中心代碼',
          },
        ]}
      />
    </div>
  );
}
