import {
  takeOutMaterials,
  useEquipmentMaterials,
} from './hooks/use-equipment-materials';
import styles from './equipment-materials-list.module.scss';
import {
  Button,
  Message,
  SelectValue,
  Table,
  Typography,
} from '@mezzanine-ui/react';
import { useEquipmentMaterialsListColumns } from './hooks/use-equipment-materials-list-columns';
import { useMemo, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import { UseFormReturn } from 'react-hook-form';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { useDraggableColumns } from '../../Templates/PaginationTable';
import Decimal from 'decimal.js';

export type MaterialListFormFields = {
  selectedEquipmentMaterials: {
    id: string;
    outputMaterialWeightUnitQuantity: string;
    outputMaterialQuantity: string;
    outputMaterialUnit: SelectValue<'stockUnit' | 'inputUnit'> | null;
    outputMaterialStockUnitQuantity: string;
    stockOverInputUnitRatio: string;
  }[];
};

export const equipmentMaterialsDefaultValues = {
  selectedEquipmentMaterials: [],
};

export function EquipmentMaterialsList({
  methods,
  equipmentId,
}: {
  methods: UseFormReturn<MaterialListFormFields>;
  equipmentId: string | null;
}) {
  const tableRef = useRef<HTMLTableElement>(null);
  const { equipmentMaterials, mutateGetEquipmentMaterials, isLoading } =
    useEquipmentMaterials(equipmentId);
  const defaultColumns = useEquipmentMaterialsListColumns();
  const columns = useDraggableColumns(tableRef, defaultColumns);

  const [isClearing, setIsClearing] = useState(false);

  const selectedEquipmentMaterials = methods?.watch(
    'selectedEquipmentMaterials'
  );

  const rowSelection = useMemo(
    () => ({
      selectedRowKey: selectedEquipmentMaterials?.map((item) => item?.id),
      onChange: (selectedKeys: string[]) => {
        const currentSelectedEquipmentMaterials = methods?.getValues(
          'selectedEquipmentMaterials'
        );
        methods?.setValue(
          'selectedEquipmentMaterials',
          selectedKeys?.map((key) => {
            const existSelectedItem = currentSelectedEquipmentMaterials?.find(
              (item) => item?.id === key
            );
            const targetMaterial = equipmentMaterials?.inputMaterials?.find(
              (item) => item?.equipmentMaterialRecordId?.toString() === key
            );
            return (
              existSelectedItem ?? {
                id: key,
                outputMaterialWeightUnitQuantity:
                  targetMaterial?.inputMaterialWeightUnitQuantity ?? '0',
                outputMaterialQuantity:
                  targetMaterial?.inputMaterialStockUnitQuantity ?? '0',
                outputMaterialUnit: targetMaterial
                  ? {
                      id: 'stockUnit',
                      name: targetMaterial?.inputMaterialStockUnit,
                    }
                  : null,
                outputMaterialStockUnitQuantity:
                  targetMaterial?.inputMaterialStockUnitQuantity ?? '0',
                stockOverInputUnitRatio:
                  targetMaterial?.stockOverInputUnitRatio ?? '1',
              }
            );
          })
        );
      },
    }),
    [equipmentMaterials?.inputMaterials, methods, selectedEquipmentMaterials]
  );

  const [takeOuting, setTakeOuting] = useState(false);

  return (
    <FormFieldsWrapper methods={methods}>
      <Row align="middle" justify="space-between">
        <Col>
          <Typography variant="h5">站內物料</Typography>
        </Col>
        {/* <Col>
          總重量：
          <span
            style={{
              display: 'inline-block',
              minWidth: '80px',
              textAlign: 'right',
            }}
          >
            {equipmentMaterials?.inputTotalMaterialWeightUnitQuantity ?? ''}
          </span>
        </Col> */}
      </Row>
      <Table
        ref={tableRef}
        scroll={{ x: 1800 }}
        loading={isClearing || isLoading}
        className={styles.table}
        columns={columns}
        dataSource={
          equipmentMaterials?.inputMaterials?.map((em) =>
            Object.assign({}, em, {
              id: em.equipmentMaterialRecordId?.toString(),
            })
          ) ?? []
        }
        rowSelection={rowSelection}
      />
      <Row gutter={[12, 12]} justify="end">
        {/* <Col>
          <Button
            type="button"
            loading={isClearing}
            disabled={!equipmentId}
            variant="outlined"
            onClick={async () => {
              try {
                setIsClearing(true);
                if (equipmentId) {
                  await clearMaterialsInEquipment({ equipmentId });
                  await mutateGetEquipmentMaterials();
                  methods?.setValue('selectedEquipmentMaterials', []);
                  Message.success('清空完成');
                }
              } catch (err: any) {
                console.error(err);
              } finally {
                setIsClearing(false);
              }
            }}
          >
            清空投入
          </Button>
        </Col> */}
        <Col>
          <Button
            type="button"
            loading={takeOuting}
            disabled={(selectedEquipmentMaterials?.length ?? -1) < 1}
            variant="outlined"
            onClick={async () => {
              const selectedEquipmentMaterials = methods?.getValues(
                'selectedEquipmentMaterials'
              );

              try {
                setTakeOuting(true);

                if (selectedEquipmentMaterials?.length > 0) {
                  const items =
                    selectedEquipmentMaterials?.reduce<
                      {
                        recordId: number;
                        outputMaterialWeightUnitQuantity: string;
                        outputMaterialStockUnitQuantity: string;
                      }[]
                    >((acc, item) => {
                      if (item?.id) {
                        acc.push({
                          recordId: Number(item?.id),
                          outputMaterialWeightUnitQuantity:
                            item?.outputMaterialWeightUnitQuantity,
                          outputMaterialStockUnitQuantity: (() => {
                            switch (item?.outputMaterialUnit?.id) {
                              case 'stockUnit':
                                return item?.outputMaterialQuantity;
                              case 'inputUnit':
                                return new Decimal(
                                  item?.outputMaterialQuantity ?? '0'
                                )
                                  .mul(item?.stockOverInputUnitRatio ?? '1')
                                  .toString();
                              default:
                                return item?.outputMaterialQuantity;
                            }
                          })(),
                        });
                      }
                      return acc;
                    }, []) ?? [];
                  await takeOutMaterials({ items });

                  setTakeOuting(false);

                  await mutateGetEquipmentMaterials(undefined, true);
                  methods?.setValue('selectedEquipmentMaterials', []);
                  Message.success('退料完成');
                }
              } catch (error) {
                console.error(error);
              } finally {
                setTakeOuting(false);
              }
            }}
          >
            退料
          </Button>
        </Col>
      </Row>
    </FormFieldsWrapper>
  );
}
