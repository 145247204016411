import { TableDataSourceWithID } from '@mezzanine-ui/core/table';
import { PageInfo } from '../pagination';

export interface GetSampleSpecificationsParams {
  limit?: number;
  offset?: number;
  factory: string;
  searchTerm?: string;
  ids?: string[];
}

export interface GetSampleAnalysisRequirementsParams {
  limit?: number;
  offset?: number;
  factory: string;
  searchTerm?: string;
  ids?: string[];
}

export interface GetSampleSubmissionReasonsParams {
  limit?: number;
  offset?: number;
  factory: string;
  searchTerm?: string;
  ids?: string[];
}

export interface GetSampleTypesParams {
  limit?: number;
  offset?: number;
  factory: string;
  searchTerm?: string;
  ids?: string[];
}

export interface AnalysisRequirementsResponse {
  records: AnalysisRequirement[];
  pageInfo: PageInfo;
}

export interface AnalysisRequirement extends TableDataSourceWithID {
  id: number;
  factory: string;
  analysisRequirementType: string;
  analysisRequirement: string;
  analysisItems: null;
  processTime: number;
  rushProcessTime: number;
  price: number;
  remark?: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt: string;
  locked: boolean;
  productCodes?: string;
  methods: Method[];
}

export interface Method {
  id: number;
  factory: string;
  analysisRequirementId: number;
  seq: number;
  methodNumber: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt: string;
}
export interface SubmissionReason {
  id: number;
  name: string;
  items: SubmissionReasonItem[];
}

export interface SubmissionReasonItem {
  id: number;
  listId: number;
  seq: number;
  listName: string;
  itemName: string;
}

export interface SampleSpecResponse {
  records: SampleSpec[];
  pageInfo: PageInfo;
}

export interface SampleSpec {
  id: number;
  name: string;
  limsCode: string;
  remark?: string;
  updateHistory?: string;
}

export interface SampleStatus {
  id: number;
  name: string;
  items: SampleStatusItem[];
}

export interface SampleStatusItem {
  id: number;
  listId: number;
  seq: number;
  listName: string;
  itemName: string;
}

export interface Sample {
  materialId: string;
  sampleName: string;
  sampleAmount: number;
  emptyBagWeight: number;
  sampleNetWeight: number;
  sampleRemainWeight: number;
  sampleUnit: string;
  // batchId: string;
  sapBatchId: string;
  subBatchId: string;
  submitReason: string;
  sampleType: string;
  sampleSpec: string;
  sampleDesc: string;
  isRush: boolean;
  isSampleAccountTransfer: boolean;
  isReturnSample: boolean;
  testType: number;
  analysisRequirementIds: string[];
  methodIds: string[];
}
