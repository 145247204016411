import { useAutoAnimate } from '@formkit/auto-animate/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { PlusIcon, TrashIcon } from '@mezzanine-ui/icons';
import {
  Button,
  Icon,
  Message,
  ModalActions,
  SelectValue,
  Table,
} from '@mezzanine-ui/react';
import {
  DatePickerField,
  FormFieldsWrapper,
} from '@mezzanine-ui/react-hook-form';
import {
  getInventoryByMaterial,
  InventoryByMaterialSearchType,
  MaterialRequestOrderSpec,
  MaterialResponseData,
  useMaterials,
  useOutsourcePurchaseMaterialRequestOrder,
  useOutsourcePurchaseMaterialRequestOrders,
} from '@solar/data';
import {
  CollapsibleContainer,
  CustomAutoCompleteField,
  NumericInputField,
  RowSection,
  useTargetModal,
} from '@solar/templates';
import Decimal from 'decimal.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ModalLayout } from '../../../Material/ModalLayout/ModalLayout';

import {
  useAutoCompleteRecipeField,
  useInputNeedsColumn,
  usePreviewInputColumn,
} from '../hooks';
import {
  ConfigureTableProps,
  DispatchMaterialViewModalProps,
  DispatchSettingModalFormProps,
  Modals,
} from '../types';
import {
  quantityValidation,
  selectedUnitsGenerator,
  updateWorkOrder,
  UpdateWorkOrderPayload,
} from '../utils';
import { CreateConfigureTable } from './CreateConfigureTable';
import classes from './dispatch-setting-modal.module.scss';
import {
  CreateOutsourcePurchaseMaterialRequestOrderPayload,
  createOutsourcePurchaseMaterialRequestOrder,
} from '../utils/create-outsource-purchase-material-request-order';
import {
  UpdateOutsourcePurchaseMaterialRequestOrderPayload,
  updateOutsourcePurchaseMaterialRequestOrder,
} from '../utils/update-outsource-purchase-material-request-order';

export function DispatchSettingModal() {
  const [workOrderLoading, setWorkOrderLoading] = useState(false);
  const addConfigurationTableButtonRef = useRef<HTMLButtonElement>(null);
  const inputNeedsTableRef = useRef<HTMLTableElement>(null);
  const [parent] = useAutoAnimate();

  const { orderId } = useParams<{ orderId: string }>();

  const modalController = useTargetModal<DispatchMaterialViewModalProps>(
    Modals.DISPATCH_SETTING
  );
  const {
    materialId: materialIdFromModalProps,
    orderId: workOrderIdFromModalProps,
  } = modalController?.data ?? {};

  const methods = useForm<DispatchSettingModalFormProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: {
      materialSubBatchCount: undefined,
      materialId: null,

      materialDescription: null,
      tables: [],
      tempMaterialRequestOrderSpecs: [],
      expectedCompletedAt: new Date().toISOString(),
    },
    resolver: yupResolver(
      quantityValidation({
        materialSubBatchCountRequired: !!materialIdFromModalProps,
      })
    ),
  });

  const {
    isLoading: fetchMaterialsLoading,
    materials,
    setParams: refetchGetMaterials,
  } = useMaterials(methods);
  const { insert, fields, remove } = useFieldArray({
    control: methods.control,
    name: 'tables',
  });

  const {
    mutate: fetchMaterialRequestOrdersByWorkId,
    mutateParams: mutateRequestOrdersParams,
  } = useOutsourcePurchaseMaterialRequestOrders({
    initiateFetching: false,
  });

  // const {  mutate: mutateWorkOrderByOrderId } = useWorkOrder({ orderId });
  const { data: inputNeeds, isLoading: materialRequestOrderSpecsLoading } =
    useOutsourcePurchaseMaterialRequestOrder({
      orderId: workOrderIdFromModalProps ?? undefined,
    });

  const previewInputColumn = usePreviewInputColumn({
    methods,
    plusAction: ({ inputMaterialId, inputMaterialDescription }) => {
      // 更新 materials
      refetchGetMaterials({ searchTerm: inputMaterialId, searchType: 'ID' });

      methods.setValue('materialId', {
        id: inputMaterialId,
        name: inputMaterialId,
      });
      methods.setValue('materialDescription', {
        id: inputMaterialId,
        name: inputMaterialDescription,
      });

      addConfigurationTableButtonRef?.current?.click();
    },
  });
  const inputNeedsColumn = useInputNeedsColumn({
    methods,
    tableRef: inputNeedsTableRef,
  });

  const autoCompleteHandler = useCallback(
    (event: SelectValue, fieldName: 'materialId' | 'materialDescription') => {
      const targetId = event?.id;
      if (!targetId) return;
      if (!materials?.length) return;

      const relatedMaterials: { [id: string]: MaterialResponseData } = (
        materials ?? []
      )?.reduce((prev, material) => ({ ...prev, [material.id]: material }), {});
      if (fieldName === 'materialId')
        methods.setValue(fieldName, {
          id: relatedMaterials[targetId].id,
          name: relatedMaterials[targetId].id,
        });

      if (fieldName === 'materialDescription')
        methods.setValue(fieldName, {
          id: relatedMaterials[targetId]?.description,
          name: relatedMaterials[targetId]?.description,
        });
    },
    [materials, methods, methods.watch]
  );

  // 預產母批號 每開一次 modal 就產生一個新的 SOL-1132
  // useEffect(()=> {
  //   if (materialIdFromModalProps && orderId) {
  //     generateSapBatchId({ materialId: materialIdFromModalProps, workOrderId: orderId})
  //       .then((res) => {

  //         // if (res[0]) {
  //           setPregeneratedSapInfo(res[0]?? null)
  //         // }

  //     }).catch((e) => {

  //     })
  //   }
  // }, [modalController?.data, orderId])

  useEffect(() => {
    if (orderId) {
      mutateRequestOrdersParams({ workOrderIds: [orderId] });
    }
  }, [orderId]);

  // 編輯發料單： 判斷 materialIdFromModalProps
  useEffect(() => {
    if (inputNeeds && !materialIdFromModalProps) {
      methods.setValue(
        'tempMaterialRequestOrderSpecs',
        (inputNeeds?.items as any) ?? []
      );
    }
  }, [inputNeeds, materialIdFromModalProps]);

  return (
    <ModalLayout
      className={classes['modal-width']}
      loading={methods.formState.isSubmitting}
      modalHeader="發料設定"
      modalFooter={
        <ModalActions
          confirmText={workOrderIdFromModalProps ? '更新發料單' : '建立發料單'}
          cancelText="取消"
          confirmButtonProps={{ form: Modals.DISPATCH_SETTING }}
          onCancel={() => modalController.closeModal()}
        />
      }
      open={modalController.open}
      closeModal={() => modalController.closeModal()}
    >
      <CollapsibleContainer
        enableRowBlockStyle
        header={materialIdFromModalProps ? '投入料預覽' : '投料需求編輯'}
        open
      >
        {materialIdFromModalProps && (
          <Table
            scroll={{ x: 1500 }}
            columns={previewInputColumn}
            dataSource={
              modalController?.data?.dispatchPreviewTable?.flatMap((item) =>
                item.components.map((component) => ({
                  ...component,
                  key: `${item.specId}-${component.componentId}`,
                }))
              ) ?? []
            }
          />
        )}
        {!materialIdFromModalProps && (
          <Table
            ref={inputNeedsTableRef}
            scroll={{ x: 1500 }}
            bodyRowClassName={classes['custom-row']}
            columns={inputNeedsColumn}
            dataSource={methods.watch('tempMaterialRequestOrderSpecs') ?? []}
          />
        )}
      </CollapsibleContainer>
      <br />
      <br />
      <FormFieldsWrapper
        methods={methods}
        id={Modals.DISPATCH_SETTING}
        onSubmit={async (values) => {
          try {
            const { materialSubBatchCount, expectedCompletedAt, tables } =
              values;

            const items = tables.reduce((accu, cur) => {
              const selectedRowKeys = cur?.selectedRowKeys ?? [];

              if (!cur?.table.length || !selectedRowKeys.length) {
                return accu;
              }

              accu.push(
                ...cur.preserveTable.map((row) => {
                  const receiveQuantity =
                    cur.selectedRowKeyToReceiveQuantity?.[
                      row.batchStoredLoaderRecordId
                    ] || '0';
                  const materialStockUnitQuantity =
                    row.selectedUnit.id === 'materialInputUnit'
                      ? new Decimal(receiveQuantity)
                          .times(new Decimal(row.stockOverInputUnitRatio))
                          .toString()
                      : receiveQuantity;

                  return {
                    materialId: row.materialId,
                    materialSapBatchId: row.materialSapBatchId,
                    materialSubBatchId: row.materialSubBatchId,
                    loaderId: row.loaderId,
                    materialStockUnitQuantity,
                  };
                })
              );

              return accu;
            }, [] as CreateOutsourcePurchaseMaterialRequestOrderPayload['items']);

            // 編輯發料單
            if (workOrderIdFromModalProps) {
              const mergedItems: UpdateOutsourcePurchaseMaterialRequestOrderPayload['items'] =
                [];
              const keyMap = new Map<string, string>();
              const oldItems = (
                methods.getValues('tempMaterialRequestOrderSpecs') ?? []
              )?.reduce((accu, row, index) => {
                if (!row.deleted) {
                  accu.push({
                    ...row,
                    materialSubBatchId: row.materialSubBatchId,
                    materialStockUnitQuantity:
                      row.expectedMaterialStockUnitQuantity,
                    loaderId: row.loaderId,
                  });
                }

                return accu;
              }, [] as (MaterialRequestOrderSpec & { materialStockUnitQuantity: string })[]);

              [...items, ...oldItems].forEach((item) => {
                const {
                  materialSapBatchId,
                  materialSubBatchId,
                  materialId,
                  materialStockUnitQuantity,
                  loaderId,
                } = item;
                const compositeKey = `${materialSapBatchId}-${materialSubBatchId}-${materialId}-${loaderId}`;

                if (keyMap.has(compositeKey)) {
                  // If it exists, update the amount
                  keyMap.set(
                    compositeKey,
                    String(
                      new Decimal(keyMap.get(compositeKey)!).plus(
                        new Decimal(materialStockUnitQuantity)
                      )
                    )
                  );
                } else {
                  // If it doesn't exist, add a new entry in the map
                  keyMap.set(compositeKey, String(materialStockUnitQuantity));
                }
              });

              keyMap.forEach((materialStockUnitQuantity, compositeKey) => {
                const [
                  materialSapBatchId,
                  materialSubBatchId,
                  materialId,
                  loaderId,
                ] = compositeKey.split('-');
                mergedItems.push({
                  materialId,
                  materialSapBatchId,
                  materialSubBatchId,
                  materialStockUnitQuantity,
                  loaderId,
                });
              });

              const updatePayload: UpdateOutsourcePurchaseMaterialRequestOrderPayload =
                {
                  expectedCompletedAt,
                  items: mergedItems,
                };

              const updateResponse =
                await updateOutsourcePurchaseMaterialRequestOrder({
                  payload: updatePayload,
                  orderId: workOrderIdFromModalProps,
                });

              if (updateResponse.ok) {
                Message.success('發料單修改成功');
                return;
              }
            }

            // 以下是新建發料單

            if (!orderId) {
              Message.error('系統錯誤');
              return;
            }

            const payload = {
              outsourcePurchaseOrderId: orderId,
              expectedCompletedAt: expectedCompletedAt,
              items: items,
            };

            const postResponse =
              await createOutsourcePurchaseMaterialRequestOrder(payload);

            if (postResponse.ok) {
              Message.success('建立發料成功');
            }
          } catch (err) {
            if (err instanceof Error) {
              Message.error(JSON.parse(err.message).message);
            } else {
              Message.error(
                workOrderIdFromModalProps ? '更新發料單失敗' : '建立發料失敗'
              );
            }
          } finally {
            // mutateWorkOrderByOrderId()
            fetchMaterialRequestOrdersByWorkId();
            modalController.closeModal();
          }
        }}
      >
        <div className={classes['selector-wrapper']}>
          <RowSection label="料號：" childFullWidth>
            <CustomAutoCompleteField
              value={methods?.watch('materialId') ?? null}
              registerName="materialId"
              options={
                (materials ?? [])?.map((material) => ({
                  id: material.id,
                  name: material.id,
                })) ?? []
              }
              onSearch={(searchTerm) => {
                refetchGetMaterials({ searchTerm, searchType: 'ID' });
              }}
              onChange={(event) =>
                autoCompleteHandler(event, 'materialDescription')
              }
            />
          </RowSection>
          <RowSection label="品名：" childFullWidth>
            <CustomAutoCompleteField
              value={methods?.watch('materialDescription') ?? null}
              registerName="materialDescription"
              options={
                (materials ?? [])?.map((material) => ({
                  id: material?.id ?? '',
                  name: material?.description ?? '',
                })) ?? []
              }
              onChange={(event) => autoCompleteHandler(event, 'materialId')}
              errorMsgRender={(e) => e.message}
            />
          </RowSection>
          <RowSection label="預期領料日期：">
            <DatePickerField registerName="expectedCompletedAt" />
          </RowSection>
        </div>
        <div>
          <Button
            loading={workOrderLoading}
            prefix={<Icon icon={PlusIcon} />}
            type="button"
            variant="outlined"
            ref={addConfigurationTableButtonRef}
            onClick={async () => {
              const materialId = methods.getValues('materialId.id');
              const materialDescription = methods.getValues(
                'materialDescription.name'
              );
              if (!materialId) {
                Message.info('請先選取料號');
                return;
              }

              const materialIds = [materialId];
              try {
                setWorkOrderLoading(true);
                const res = await getInventoryByMaterial({
                  materialIds,
                  type: InventoryByMaterialSearchType.LOADER_BARCODE,
                  enableUnavailable: true,
                });

                insert(0, {
                  isReachEnd: !res.pageInfo.hasNext!,
                  materialId: materialId,
                  materialDescription: materialDescription,
                  pageOffset: res.pageInfo.offset!,
                  preserveTable: [],
                  table: res.records.map((row) => ({
                    ...row,
                    id: row.batchStoredLoaderRecordId,
                    availableState: row?.availableState,
                    selectedUnit: selectedUnitsGenerator({ source: row })[0],
                  })) as unknown as ConfigureTableProps[], //TEMP_CREATE_CONFIGURE_TABLE,
                  selectedRowKeys: [],
                  specifiedQuantity: '0',
                  subTotal: '0',
                  designatedInventory: false,
                  selectedRowKeyToReceiveQuantity: {},
                });
              } catch (err) {
                Message.error('系統錯誤');
              } finally {
                setWorkOrderLoading(false);
              }
            }}
          >
            加入
          </Button>
        </div>
        <div ref={parent}>
          {fields?.map((table, index) => (
            <div
              key={`${table.id}-${index}`}
              style={{ marginTop: 'var(--mzn-spacing-4)' }}
            >
              <CollapsibleContainer
                enableRowBlockStyle
                open
                header={
                  <Button
                    danger
                    prefix={<Icon icon={TrashIcon} />}
                    type="button"
                    variant="contained"
                    onClick={() => remove(index)}
                  >
                    移除
                  </Button>
                }
              >
                <CreateConfigureTable index={index} />
              </CollapsibleContainer>
            </div>
          ))}
        </div>
      </FormFieldsWrapper>
    </ModalLayout>
  );
}
