import { SelectValue } from '@mezzanine-ui/react';
import useSWR from 'swr';
import { OffsetBased, PageInfo } from '../pagination';
import { API_NAMESPACE } from '../request';
import { useState } from 'react';

const namespace = API_NAMESPACE.MATERIALS;

export type InventoryTransferOrder = {
  id: number;
  creatorId: string;
  receiverDeptId: string;
  receiverId: string;
  sourceType: string;
  approvalStatus: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  items: InventoryTransferOrderItem[];
};

export type InventoryTransferOrderItem = {
  id: number;
  specId: number;
  orderId: string;
  /**
   * REQUEST_ORDER = '發料單'
   * INVENTORY_TRANSFER_ORDER = '移轉'
   */
  sourceType: 'REQUEST_ORDER' | 'INVENTORY_TRANSFER_ORDER';
  createdAt: string;
  creatorId: string;
  creatorName: string;
  receiverId: string;
  receiverName: string;
  transferMaterialStockUnitQuantity: string;
  transferMaterialInputUnitQuantity: string;
  actualMaterialWeightUnitQuantity: string;
  materialSubBatchId: string;
  materialSapBatchId: string;
  materialId: string;
  materialDescription: string;
  materialStockUnit: string;
  materialStockUnitName: string;
  materialInputUnit: string;
  fromLoaderId: string;
  fromShelfId: string;
  toShelfId: string;
  toLoaderId: string;
  materialBatchId: string;
};

export type InventoryTransferOrdersResponse = {
  pageInfo: PageInfo;
  records: InventoryTransferOrder[];
  // records: {
  //   id: number;
  //   creatorId: string;
  //   creator: SelectValue;
  //   receiverId: string;
  //   receiver: SelectValue;
  //   approvalStatus: null;
  //   /**
  //    * REQUEST_ORDER = '發料單'
  //    * INVENTORY_TRANSFER_ORDER = '移轉'
  //    */
  //   sourceType: 'REQUEST_ORDER' | 'INVENTORY_TRANSFER_ORDER';
  //   updatedAt: string;
  //   inventoryTransferOrderSpecs: InventoryTransferOrderSpecs[];
  //   size: string | null;
  //   createdAt: string;
  //   deletedAt: string | null;
  //   shelfId: string;
  // }[];
};

export type InventoryTransferOrderSpecs = {
  id: number;
  inventoryTransferOrderId: number;
  /**
   * REQUEST_ORDER = '發料單'
   * INVENTORY_TRANSFER_ORDER = '移轉'
   */
  sourceType: 'REQUEST_ORDER' | 'INVENTORY_TRANSFER_ORDER';
  materialBatchId: string;
  materialBatch: {
    id: string;
    name: string;
    createdAt: string;
    materialId: string;
    oldBatchId: null;
    orderType: null;
    fromOrderId: null;
    sapBatchId: null;
    remark: null;
  };
  fromLoaderId: number;
  fromLoader: {
    id: number;
    name: string;
    size: string;
    barcode: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: null;
    shelfId: string;
    source: string;
  };
  toShelfId: string;
  toShelf: {
    id: string;
    name: string;
    stackId: string;
  };
  toLoaderId: number;
  toLoader: {
    id: number;
    name: string;
    size: string;
    barcode: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: null;
    shelfId: string;
    source: string;
  };
  mainMaterialUnitQuantity: string;
  secondaryMaterialUnitQuantity: null;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
};

type GetTransferOrdersParams = OffsetBased & {
  searchTerm?: string;
  approvalStatus?: boolean;
  creators?: string[];
  materialIds?: string[];
  materialBarcodes?: string[];

  /** @deprecated: use toShelfIds and fromShelfIds instead */
  shelfIds?: string[];

  toShelfIds?: string[];
  fromShelfIds?: string[];

  /** @deprecated: use toLoaderIds and fromLoaderIds instead */
  loaderIds?: string[];

  toLoaderIds?: string[];
  fromLoaderIds?: string[];
};

export function useInventoryTransferOrders() {
  const [params, setParams] = useState<GetTransferOrdersParams>();
  const { data, mutate, error } = useSWR<InventoryTransferOrdersResponse>([
    '/warehouses/inventory-transfer-orders',
    {
      params,
      namespace,
    },
  ]);

  // const reorderedRecords = data?.records.reduce(
  //   (acc, cur) => {
  //     // Flatten the inventory and add the isFirstRow attribute based on the local index.
  //     const inventoryWithFirstRow = cur.inventoryTransferOrderSpecs.map(
  //       (item, index) => ({
  //         ...item,
  //         id: item?.inventoryTransferOrderId,
  //         sourceType: cur?.sourceType,
  //         isFirstRow: index === 0,
  //       })
  //     );

  //     // Concatenate the inventory with the result array.
  //     acc.flattenedOrderSpecs.push(...inventoryWithFirstRow);

  //     // Store the rest of the data (mapping between IDs and creators) in the creatorMap object.
  //     acc.ordersMeta[cur.id] = cur;
  //     // acc.ordersMeta['1'];

  //     return acc;
  //   },
  //   {
  //     ordersMeta: {},
  //     flattenedOrderSpecs: [],
  //   } as {
  //     ordersMeta: Record<
  //       string,
  //       InventoryTransferOrdersResponse['records'][number]
  //     >;
  //     flattenedOrderSpecs: (InventoryTransferOrderSpecs & {
  //       isFirstRow: boolean;
  //     })[];
  //   }
  // );

  return {
    isLoading: !error && !data,
    pageInfo: data?.pageInfo,
    records: data?.records,
    // reorderedRecords,
    refetchGetTransferOrders: setParams,
    mutateGetTransferOrders: mutate,
  };
}
