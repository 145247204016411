import { Message } from '@mezzanine-ui/react';
import * as Yup from 'yup';

import { PalletsList } from '../types';

export const customSchemaGenerator = (arr: PalletsList) => {
  const validateObj: Record<string, unknown> = {};

  arr.forEach((obj) => {
    validateObj[obj.id as string] = Yup.object().shape({
      tableRows: Yup.array().of(
        Yup.object().shape({
          subItems: Yup.array().of(
            Yup.object().shape({
              mainMaterialUnitQuantity: Yup.mixed().test(
                'limit',
                '總量超過上限',
                function (_value, context) {
                  if (context && context.from) {
                    const subQuantitySum =
                      context?.from[1].value.subItems?.reduce(
                        (
                          acc: number,
                          cur: { mainMaterialUnitQuantity: string }
                        ) => {
                          return acc + Number(cur.mainMaterialUnitQuantity);
                        },
                        0
                      );

                    if (subQuantitySum > context?.from[1].value.boxQuantity) {
                      Message.error(
                        `箱號 ${context?.from[1].value.index} 總量超過上限`
                      );

                      return false;
                    }

                    return true;
                  }

                  return true;
                }
              ),
            })
          ),
        })
      ),
    });
  });

  return Yup.object().shape(validateObj as any);
};
