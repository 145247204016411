import { Button } from '@mezzanine-ui/react';
import {
  DateRangePickerField,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  NextPagination,
  WorkOrderStatus,
  getNextParams,
  useGetDeliveryOrders,
  useGetProductionWorkOrders,
} from '@solar/data';
import moment from 'moment';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styles from './work-order-table.module.scss';
import { useNavigate } from 'react-router';
import { CopyTextWrapper, MznPaginationTable } from '@solar/templates';
import { shippedStates } from './shipped-states';

type DeliveryOrderTableFilter = {
  rangeOfDate?: [string, string];
  status?: { id: WorkOrderStatus; name: string }[];
  searchTerm?: string;
};

export function DeliveryOrderTable() {
  const navigate = useNavigate();
  const methods = useForm<DeliveryOrderTableFilter>();

  const { deliveryOrders, pageInfo, refetchGetDeliveryOrders } =
    useGetDeliveryOrders();

  const refetchGetDeliveryOrdersWithPagination = useCallback(
    (nextPagination: NextPagination) => {
      const formState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      refetchGetDeliveryOrders({
        types: ['ZLF3', 'ZLF4', 'ZLF1', 'ZLC', 'ZLF5'],
        dateFrom: formState?.rangeOfDate?.[0],
        dateTo: formState?.rangeOfDate?.[1],
        packedStates: formState?.status?.map((obj) => obj.id),
        searchTerm: formState?.searchTerm,
        offset,
        limit,
      });
    },
    [methods, refetchGetDeliveryOrders, pageInfo]
  );

  useEffect(() => {
    const subscription = methods.watch(() =>
      refetchGetDeliveryOrdersWithPagination({ nextPage: 1 })
    );
    return () => subscription.unsubscribe();
  }, [methods, methods.watch, refetchGetDeliveryOrdersWithPagination]);

  useEffect(() => {
    refetchGetDeliveryOrdersWithPagination({ nextPage: 1 });
  }, []);

  return (
    <div className={styles.work_orders_wrapper}>
      <FormFieldsWrapper
        methods={methods}
        className={styles.filter_form_wrapper}
      >
        <InputField
          placeholder="請輸入關鍵字進行搜尋"
          registerName="searchTerm"
        />
        <SelectField
          mode="multiple"
          clearable
          label="狀態"
          registerName="status"
          options={shippedStates}
        />
        <DateRangePickerField label="日期區間" registerName="rangeOfDate" />
      </FormFieldsWrapper>
      <MznPaginationTable
        pageInfo={pageInfo}
        fetchData={refetchGetDeliveryOrdersWithPagination}
        dataSource={deliveryOrders ?? []}
        columns={[
          {
            title: '操作',
            render: (source: any) => (
              <Button
                onClick={() =>
                  navigate(
                    `/warehouse/material-supply/delivery-order/${source?.id}`
                  )
                }
              >
                檢視
              </Button>
            ),
          },
          {
            title: '出貨單號',
            render: (source: any) => (
              <CopyTextWrapper text={source?.id ?? ''} />
            ),
          },
          {
            title: '客戶名稱',
            dataIndex: 'customerName',
          },
          {
            title: '客戶代碼',
            dataIndex: 'customerId',
          },
          {
            title: '開立日期',
            render: (source) =>
              source?.createdAt
                ? moment(source?.createdAt).format('YYYY-MM-DD')
                : '',
          },
          {
            title: '狀態',
            render: (source) =>
              shippedStates.find((state) => state.id === source.packedState)
                ?.name,
          },
        ]}
      />
    </div>
  );
}
