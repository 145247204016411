// input must be as the format as 'hello-world'
export function toUpperCamelCase(str: string) {
  if (!str) return '';
  return str
    .split('-')
    .map((word) => {
      const firstLetter = word[0].toUpperCase();
      const restLetters = word.substring(1);
      return firstLetter + restLetters;
    })
    .join('');
}
