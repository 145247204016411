import { Typography } from '@mezzanine-ui/react';
import { TargetNumberDispatchedFilter } from './components/DispatchedTarget/DispatchedFilter';
import { DispatchedTargetTable } from './components/DispatchedTarget/DispatchedTargetTable';
import styles from './TargetNumber.module.scss';
import { useState } from 'react';
import { DispatchedTargetNumberRuleFilter } from '@solar/data';

export function TargetNumberDispatchedSearchPage() {
  const [filterParams, setFilterParams] = useState<DispatchedTargetNumberRuleFilter>({});

const handleFilterSubmit = (newFilterParams: DispatchedTargetNumberRuleFilter) => {
  setFilterParams(newFilterParams);
};

  return (
    <div className={styles.container}>
      <Typography variant="h1">已派發靶編查詢</Typography>
      <TargetNumberDispatchedFilter onSubmit={handleFilterSubmit} />
      <DispatchedTargetTable filterParams={filterParams} />
    </div>
  );
}
