import { Fragment, useCallback } from 'react';
import styles from './preview-modal.module.scss';
import { ProductionFormFieldType, ProductionFormResponse } from '@solar/data';
import {
  InputField,
  TextAreaField,
  SelectField,
  RadioGroupField,
  DateTimePickerField,
} from '@mezzanine-ui/react-hook-form';

export function ProductionForms({
  forms,
  disableAllFields = false,
  getRegisterName,
}: {
  forms: ProductionFormResponse[];
  disableAllFields?: boolean;
  getRegisterName?: (form: ProductionFormResponse) => string;
}) {
  const _getRegisterName = useCallback(
    (form: ProductionFormResponse) => getRegisterName?.(form) ?? form.id,
    [getRegisterName]
  );

  return (
    <Fragment>
      {(forms ?? []).map((form) => (
        <div className={styles.question} key={form.id}>
          <span className={styles.title}>
            {form.title ?? '-'}
            {/* <span className={styles.titleDesc} title={form.fieldName ?? '-'}>
              {form.fieldName ?? '-'}
            </span> */}
          </span>
          {form.fields.type === ProductionFormFieldType.TEXT ? (
            <InputField
              key={_getRegisterName(form)}
              disabled={disableAllFields}
              placeholder={form.fields.placeholder}
              registerName={_getRegisterName(form)}
            />
          ) : null}
          {/* {form.fields.type ===
          ProductionFormFieldType.TEXT_AREA ? (
            <TextAreaField
              disabled={disableAllFields}
              placeholder={form.fields.placeholder}
              registerName={_getRegisterName(form)}
            />
          ) : null} */}
          {form.fields.type === ProductionFormFieldType.NUMERIC ? (
            <InputField
              type="number"
              key={_getRegisterName(form)}
              disabled={disableAllFields}
              max={form.fields.max}
              min={form.fields.min}
              placeholder={form.fields.placeholder}
              registerName={_getRegisterName(form)}
            />
          ) : null}
          {form.fields.type === ProductionFormFieldType.ENUM ? (
            form.fields.enum.length > 3 ? (
              <SelectField
                type="number"
                key={_getRegisterName(form)}
                disabled={disableAllFields}
                options={form.fields.enum.map((id) => ({
                  id,
                  name: id,
                }))}
                placeholder={form.fields.placeholder}
                registerName={_getRegisterName(form)}
              />
            ) : (
              <RadioGroupField
                disabled={disableAllFields}
                key={_getRegisterName(form)}
                options={form.fields.enum.map((id) => ({
                  label: id,
                  value: id,
                }))}
                placeholder={form.fields.placeholder}
                registerName={_getRegisterName(form)}
              />
            )
          ) : null}
          {form.fields.type === ProductionFormFieldType.TIMESTAMP ? (
            <DateTimePickerField
              disabled={disableAllFields}
              key={_getRegisterName(form)}
              hideSecond
              format="YYYY/MM/DD HH:mm"
              placeholder={form.fields.placeholder}
              registerName={_getRegisterName(form)}
            />
          ) : null}
          <span>{form.suffix ?? form.suffix ?? ''}</span>
        </div>
      ))}
    </Fragment>
  );
}
