import { Message, ModalActions, SelectValue } from '@mezzanine-ui/react';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useModalController } from '../../ModalLayout/hooks';
import { ModalLayout } from '../../ModalLayout/ModalLayout';

import { MiscPurchaseOrderInnerRecord } from '@solar/data';
import {
  InputContainerWithUnit,
  useTargetModal,
  HorizontalField,
  LocationAndLoaderPickerField,
} from '@solar/templates';
import * as Yup from 'yup';
import { MiscellaneousStorageInModals } from '../enum';
import {
  createPurchaseFixedAssetMaterial,
  createPurchaseMiscMaterial,
} from '../utils';

type ReceiveActionModalProps<T> = {
  modalController?: ReturnType<typeof useModalController>;
  setReceivedMaterialTableData: Dispatch<
    SetStateAction<(T & ReceiveActionInputs)[]>
  >;
  tempRowMaterialData: T | null;
  setTempRowMaterialData: Dispatch<
    SetStateAction<(T & Omit<ReceiveActionInputs, 'loader'>) | null>
  >;
};

export type ReceiveActionInputs = {
  mainMaterialUnitQuantity?: number;
  warehouse?: SelectValue;
  loader: SelectValue | null;
};

export type Form = {
  // miscPurchaseOrderSpecId: SelectValue | null;
  quantity: string;
  materialId: SelectValue | null;
  orderItemQuantity: string | null;
  displayedMiscUnit?: string;
  shelfId: string;
};

const schema = Yup.object().shape({
  mainMaterialUnitQuantity: Yup.string().required('必填'),
});

export function ReceiveActionModal<T>({
  setReceivedMaterialTableData,
  tempRowMaterialData,
  setTempRowMaterialData,
}: ReceiveActionModalProps<T>) {
  const receiveOrReturnMaterialMethods = useForm<Form>({
    // resolver: yupResolver(schema),
  });
  const receiveActionModalController =
    useTargetModal<MiscPurchaseOrderInnerRecord>( //MiscPurchaseOrderOuterRecord
      MiscellaneousStorageInModals.ReceiveAction
    );

  const handleReceiveActionModalClose = useCallback(() => {
    receiveOrReturnMaterialMethods.reset();
    receiveActionModalController.closeModal();
  }, []);

  useEffect(() => {
    if (
      receiveActionModalController?.open &&
      (receiveActionModalController?.data?.miscMaterialIdFromSAP ||
        receiveActionModalController?.data?.fixedAssetMaterialIdFromSAP)
    ) {
      const id =
        receiveActionModalController.data?.miscMaterialIdFromSAP ??
        receiveActionModalController.data?.fixedAssetMaterialIdFromSAP;
      const description = receiveActionModalController.data.materialDescription;

      if (id) {
        receiveOrReturnMaterialMethods.setValue('materialId', {
          id,
          name: `${id}(${description})`,
        });
      }
    }
  }, [receiveActionModalController?.data]);

  const materialId = useWatch({
    control: receiveOrReturnMaterialMethods.control,
    name: 'materialId',
  });

  if (!receiveActionModalController?.data) return null;

  return (
    <ModalLayout
      loading={receiveOrReturnMaterialMethods.formState.isSubmitting}
      modalHeader="收料"
      modalFooter={
        <ModalActions
          confirmText="確認"
          cancelText="取消"
          confirmButtonProps={{ form: 'receiveActionForm' }}
          onCancel={() => handleReceiveActionModalClose()}
        />
      }
      {...receiveActionModalController}
      closeModal={handleReceiveActionModalClose}
    >
      <FormFieldsWrapper
        id="receiveActionForm"
        methods={receiveOrReturnMaterialMethods}
        onSubmit={async (formState) => {
          const orderData = receiveActionModalController.data;
          const { materialId, orderItemQuantity, quantity, shelfId } =
            formState;

          if (!orderData) {
            Message.error('資料錯誤');
            return;
          }
          if (!materialId) {
            Message.error('品號必填');
            return;
          }
          if (!orderItemQuantity) {
            Message.error('收貨數量必填');
            return;
          }
          if (!quantity) {
            Message.error('入庫數量必填');
            return;
          }

          try {
            switch (true) {
              case 'miscMaterialIdFromSAP' in orderData: {
                const res = await createPurchaseMiscMaterial({
                  payload: {
                    purchaseItems: [
                      {
                        materialId: materialId.id,
                        orderItemQuantity,
                        quantity: quantity,
                        miscPurchaseOrderSpecId: orderData?.specId ?? '',
                        shelfId,
                      },
                    ],
                  },
                });
                if (res.ok) {
                  Message.success('雜項收料成功');
                  handleReceiveActionModalClose();
                }
                break;
              }
              case 'fixedAssetMaterialIdFromSAP' in orderData: {
                const res = await createPurchaseFixedAssetMaterial({
                  payload: {
                    purchaseItems: [
                      {
                        materialId: materialId.id,
                        orderItemQuantity,
                        quantity: quantity,
                        fixedAssetPurchaseOrderSpecId:
                          (orderData as MiscPurchaseOrderInnerRecord)?.specId ??
                          '',
                        shelfId,
                      },
                    ],
                  },
                });
                if (res.ok) {
                  Message.success('固資收料成功');
                  handleReceiveActionModalClose();
                }
                break;
              }
              default: {
                Message.error('資料錯誤');
                return;
              }
            }
          } catch (err) {
            if (err instanceof Error) {
              Message.error(JSON.parse(err.message).message);
            } else {
              Message.error('系統錯誤');
            }
          }

          setTempRowMaterialData(null);
        }}
      >
        <HorizontalField label="品名">
          {receiveActionModalController?.data.materialDescription}
        </HorizontalField>
        <HorizontalField label="品號">{materialId?.name ?? ''}</HorizontalField>
        <HorizontalField label="收貨數量">
          <InputContainerWithUnit
            suffixUnit={receiveActionModalController.data.inputUnit}
          >
            <InputField registerName="orderItemQuantity" />
          </InputContainerWithUnit>
        </HorizontalField>
        <HorizontalField label="入庫數量">
          <InputContainerWithUnit
            suffixUnit={receiveOrReturnMaterialMethods.watch(
              'displayedMiscUnit'
            )}
          >
            <InputField registerName="quantity" />
          </InputContainerWithUnit>
        </HorizontalField>
        <LocationAndLoaderPickerField
          disabledLoaderField
          locationIdLabel="存放儲位"
          locationIdRegisterName="shelfId"
        />
      </FormFieldsWrapper>
    </ModalLayout>
  );
}
