import {
  Button,
  Input,
  MenuDivider,
  Message,
  Table,
} from '@mezzanine-ui/react';
import {
  BaseField,
  FormFieldsWrapper,
  InputField,
} from '@mezzanine-ui/react-hook-form';
import {
  API_NAMESPACE,
  InventoryByMaterialSearchType,
  VirtualDeliveryOrderBindingItem,
  checkJWTRoles,
  getInventoryByMaterial,
  request,
  useGetVirtualDeliveryOrderDetail,
} from '@solar/data';
import { PageLayout, useDraggableColumns } from '@solar/templates';
import { Descriptions, Space } from 'antd';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  UseFieldArrayReturn,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useParams } from 'react-router';

interface VirtualDeliveryOrderFormValues {
  palletCategoryId: number;
  totalNumberOfBoxes: number;
  volumeHeight: string;
  volumeLength: string;
  volumeWidth: string;
  materialItems: {
    materialBarcode: string;
    materialInputUnitQuantity: string;
    actualMaterialWeightUnitQuantity: string;
    materialInputUnit: string;
    deliveryOrderDetailId: number;
    materialId?: string;
    materialBatchId?: string;
  }[];
}

const defaultValues: VirtualDeliveryOrderFormValues = {
  palletCategoryId: 7, // 無棧板
  totalNumberOfBoxes: 1,
  volumeHeight: '1',
  volumeLength: '1',
  volumeWidth: '1',
  materialItems: [],
};

export function VirtualOutDetailPage() {
  const params = useParams<{ virtualDeliveryOrderId: string }>();
  const tableRef = useRef<HTMLTableElement>(null);
  const { deliveryOrder } = useGetVirtualDeliveryOrderDetail(
    params?.virtualDeliveryOrderId
  );

  const methods = useForm<VirtualDeliveryOrderFormValues>({
    defaultValues,
  });

  const { fields, remove, append } = useFieldArray({
    control: methods.control,
    name: 'materialItems',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const columns = useDraggableColumns(tableRef, [
    {
      width: 200,
      title: '虛出出貨單項次',
      dataIndex: 'productionDeliveryOrderLine',
    },
    {
      draggable: true,
      width: 400,
      title: '料號',
      dataIndex: 'materialId',
    },
    {
      draggable: true,
      width: 400,
      title: '品名',
      dataIndex: 'materialDescription',
    },
  ]);

  const dataSource =
    deliveryOrder?.specs?.[0]?.materialItems?.map((item) =>
      Object.assign(
        {
          id: `${item.productionDeliveryOrderId}-${item.productionDeliveryOrderLine}`,
        },
        item
      )
    ) ?? [];

  const materialItemsUnderVirtualDeliveryOrder = useMemo(
    () => deliveryOrder?.specs.flatMap((row) => row.materialItems) ?? [],
    [deliveryOrder?.specs]
  );

  const netWeightList = useWatch({
    control: methods.control,
    name: fields.map(
      (f, index) => `materialItems.${index}.materialInputUnitQuantity` as const
    ),
  });

  const totalNetWeight = useMemo(() => {
    return netWeightList.reduce((result: number, item) => {
      result += Number(item) ?? 0;
      return result;
    }, 0);
  }, [netWeightList]);

  const grossWeightList = useWatch({
    control: methods.control,
    name: fields.map(
      (f, index) =>
        `materialItems.${index}.actualMaterialWeightUnitQuantity` as const
    ),
  });

  const totalGrossWeight = useMemo(() => {
    return grossWeightList?.reduce((result: number, item) => {
      result += Number(item) ?? 0;
      return result;
    }, 0);
  }, [grossWeightList]);

  const onSubmit = methods.handleSubmit(async (values) => {
    setIsSubmitting(true);

    try {
      await request(
        '/warehouses/production-virtual-delivery-orders/ship-and-receipt',
        {
          method: 'post',
          namespace: API_NAMESPACE.MATERIALS,
          body: JSON.stringify({
            orderId: params?.virtualDeliveryOrderId,
            items: [
              Object.assign(values, {
                totalGrossWeight: totalGrossWeight.toString(),
                totalNetWeight: totalNetWeight.toString(),
              }),
            ],
          }),
          responseParser: (response) => response,
        }
      );

      Message.success('出貨成功');
    } catch (error) {
      Message.error('出貨失敗');
    } finally {
      setIsSubmitting(false);
    }
  });

  return (
    <PageLayout
      title={`出貨單：(${params?.virtualDeliveryOrderId})`}
      backTo="/warehouse/material-supply?activeKey=virtual-out"
    >
      <Space direction="vertical">
        <Table
          ref={tableRef}
          columns={columns}
          dataSource={dataSource ?? []}
          scroll={{ x: 1000 }}
          expandable={{
            rowExpandable: () => true,
            expandedRowRender: (item: typeof dataSource[number]) => (
              <BindingTable
                dataSource={deliveryOrder?.bindings?.find(
                  (binding) =>
                    binding?.deliveryOrderId ===
                      item?.productionDeliveryOrderId &&
                    binding?.deliveryOrderLine ===
                      item?.productionDeliveryOrderLine
                )}
              />
            ),
          }}
        />
        <MenuDivider />

        <FormFieldsWrapper
          methods={methods}
          style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: 'var(--mzn-spacing-3)',
            }}
          >
            <BaseField name="palletId" label="樣板編號">
              001
            </BaseField>
            <BaseField name="palletCategoryId" label="樣板類型">
              無障礙版 0x0
            </BaseField>
            <div />
            <BaseField name="totalGrossWeight" label="總毛重">
              {totalGrossWeight || ''}
            </BaseField>
            <BaseField name="totalNetWeight" label="總淨重">
              {totalNetWeight || ''}
            </BaseField>
            <BaseField name="totalNumberOfBoxes" label="總箱數">
              1
            </BaseField>
            <BaseField name="volumeLength" label="材積(長)">
              1
            </BaseField>
            <BaseField name="volumeWidth" label="材積(寬)">
              1
            </BaseField>
            <BaseField name="volumeHeight" label="材積(高)">
              1
            </BaseField>
            <div style={{ gridColumn: '1/-1' }}>
              <BaseField name="materialItems" label="棧板內容物">
                <Table
                  loading={isSubmitting}
                  dataSource={materialItemsUnderVirtualDeliveryOrder}
                  columns={[
                    {
                      width: 120,
                      dataIndex: 'productionDeliveryOrderLine',
                      title: '項次',
                    },
                    {
                      dataIndex: 'materialId',
                      title: '料號',
                    },
                    {
                      dataIndex: 'materialDescription',
                      title: '品名',
                    },
                    {
                      dataIndex: 'quantity',
                      title: '數量',
                    },
                    {
                      dataIndex: 'quantityUnit',
                      title: '單位',
                    },
                  ]}
                  expandable={{
                    rowExpandable: () => true,
                    expandedRowRender: (
                      source: typeof materialItemsUnderVirtualDeliveryOrder[number]
                    ) => (
                      <AppendMaterialTable
                        deliveryOrderDetailId={
                          source?.productionDeliveryOrderSpecId
                        }
                        materialId={source?.materialId}
                        inputUnit={source?.quantityUnit}
                        fields={fields}
                        append={append}
                        remove={remove}
                      />
                    ),
                  }}
                />
              </BaseField>
              {checkJWTRoles([85]) && (
                <Button loading={isSubmitting} type="button" onClick={onSubmit}>
                  送出
                </Button>
              )}
            </div>
          </div>
        </FormFieldsWrapper>
      </Space>
    </PageLayout>
  );
}

function BindingTable({
  dataSource,
}: {
  dataSource?: VirtualDeliveryOrderBindingItem;
}) {
  return (
    <Space
      direction="vertical"
      style={{
        marginLeft: 50,
        padding: '10px 0',
        width: 1000,
      }}
    >
      <Table
        dataSource={dataSource ? [dataSource] : []}
        scroll={{ x: 1000 }}
        columns={[
          {
            width: 200,
            title: '虛入種類',
            render: (source) => {
              switch (true) {
                case !!source?.relatedDeliveryOrderId:
                  return '虛入出貨單';
                case !!source?.relatedInventoryPurchaseOrderId:
                  return '採購單';
                default:
                  return '';
              }
            },
          },
          {
            width: 200,
            title: '虛入單號',
            render: (source) =>
              source?.relatedDeliveryOrderId ??
              source?.relatedInventoryPurchaseOrderId ??
              '',
          },
          {
            width: 200,
            title: '虛入單號項次',
            render: (source) =>
              source?.relatedDeliveryOrderLine ??
              source?.relatedInventoryPurchaseOrderItemId ??
              '',
          },
          {
            title: '虛入料號',
            render: (source) =>
              source?.relatedDeliveryOrderMaterialId ??
              source?.relatedInventoryPurchaseOrderMaterialId ??
              '',
          },
        ]}
      />
      <MenuDivider />
      <Table
        dataSource={dataSource?.relations ?? []}
        scroll={{ x: 1000 }}
        columns={[
          {
            width: 200,
            title: '收料訂單',
            dataIndex: 'relatedOrderId',
          },
          {
            width: 200,
            title: '收料訂單項次',
            dataIndex: 'relatedOrderDetailId',
          },
          {
            title: '收料訂單料號',
            dataIndex: 'relatedOrderDetailMaterialId',
          },
        ]}
      />
    </Space>
  );
}

function AppendMaterialTable({
  deliveryOrderDetailId,
  materialId,
  inputUnit,
  fields,
  append,
  remove,
}: Partial<
  UseFieldArrayReturn<VirtualDeliveryOrderFormValues, 'materialItems'>
> & {
  deliveryOrderDetailId: number;
  materialId: string;
  inputUnit: string;
}) {
  const [materialBarcode, setMaterialBarcode] = useState<string>('');

  const [isAppending, setIsAppending] = useState(false);

  const appendMaterialToPallet = useCallback(async () => {
    try {
      setIsAppending(true);

      if (!materialBarcode) {
        Message.error('請輸入物料條碼');
        return;
      }

      const isExist = fields?.find((row) => {
        return row?.materialBarcode === materialBarcode;
      });

      if (isExist) {
        Message.error('此條碼已經存在棧板內');
        return;
      }

      const response = await getInventoryByMaterial({
        type: InventoryByMaterialSearchType.MATERIAL_BARCODE,
        materialBarcodes: [materialBarcode],
      });

      if (!response || !response?.records?.length) {
        Message.error('查無此條碼資料');
        return;
      }

      const targetMaterial = response?.records?.[0];

      if (targetMaterial?.materialId !== materialId) {
        Message.error('料號不一致');
        return;
      }

      if (targetMaterial?.materialInputUnit !== inputUnit) {
        Message.error('單位不一致');
        return;
      }

      append?.({
        materialBarcode,
        deliveryOrderDetailId,
        materialInputUnitQuantity: '',
        materialInputUnit: inputUnit,
        actualMaterialWeightUnitQuantity:
          targetMaterial?.actualMaterialWeightUnitQuantity,
        materialId: targetMaterial?.materialId,
        materialBatchId: targetMaterial?.batchId,
      });

      setMaterialBarcode('');
    } catch (error) {
      console.log(error);
    } finally {
      setIsAppending(false);
    }
  }, [fields, append, materialBarcode, deliveryOrderDetailId]);

  console.log('fields', fields);

  return (
    <Space direction="vertical" style={{ marginLeft: 50, padding: '10px 0' }}>
      <Space>
        <Input
          fullWidth
          placeholder="請刷物料條碼"
          value={materialBarcode}
          onChange={(e) => setMaterialBarcode(e.target.value)}
          inputProps={{
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                appendMaterialToPallet();
              }
            },
          }}
        />
        <Button
          type="button"
          loading={isAppending}
          onClick={() => {
            appendMaterialToPallet();
          }}
        >
          加入棧板
        </Button>
      </Space>
      <Table
        loading={isAppending}
        dataSource={
          fields?.filter(
            (f) => f?.deliveryOrderDetailId === deliveryOrderDetailId
          ) ?? []
        }
        columns={[
          {
            width: 120,
            title: '操作',
            render: (record) => (
              <Button
                danger
                type="button"
                onClick={() => {
                  const index = fields?.findIndex(
                    (f) =>
                      f?.deliveryOrderDetailId === deliveryOrderDetailId &&
                      f?.materialBarcode === record?.materialBarcode
                  );

                  if (index === -1) {
                    Message.error('找不到對應出貨單資訊');
                    return;
                  }

                  remove?.(index);
                }}
              >
                刪除
              </Button>
            ),
          },
          {
            dataIndex: 'materialId',
            title: '料號',
          },
          {
            dataIndex: 'materialBatchId',
            title: '批號',
          },
          {
            title: '數量',
            render: (record) => {
              const index = fields?.findIndex(
                (f) =>
                  f?.materialBarcode === record?.materialBarcode &&
                  f?.deliveryOrderDetailId === deliveryOrderDetailId
              );
              return (
                <div
                  style={{
                    display: 'grid',
                    gap: '4px',
                    gridTemplateColumns: '1fr 0.5fr',
                  }}
                >
                  <InputField
                    disabled={index === -1}
                    registerName={`materialItems.${index}.materialInputUnitQuantity`}
                    suffix={inputUnit}
                  />
                </div>
              );
            },
          },
        ]}
      />
    </Space>
  );
}
