import polylabel from 'polylabel';
import { RefObject, useCallback, useEffect } from 'react';
import { LocationLevel } from '@solar/data';
import { useLocationSelectorContext } from '../LocationSelectorContext/useLocationSelectorContext';

const xmlns = 'http://www.w3.org/2000/svg';

export function useLocationSVGElements(
  svgWrapperRef: RefObject<HTMLDivElement>
) {
  const { locationOptions } = useLocationSelectorContext();

  const getElementInfo = useCallback(
    (id: string | null) => {
      for (const [key, options] of Object.entries(locationOptions ?? {})) {
        if (options) {
          for (const option of options) {
            if (option?.id === id) {
              return Object.assign(option, {
                level: key.toUpperCase(),
              }) as {
                id: string;
                name: string;
                level: string;
                equipmentQuantity?: number;
              };
            }
          }
        }
      }
      return undefined;
    },
    [locationOptions]
  );

  const createText = useCallback((element: SVGPolygonElement) => {
    const parent = element?.parentElement;
    const pointList = element?.points;
    const id = element?.getAttribute('id');
    const name = element?.getAttribute('name');
    const isExist = !!parent?.querySelector(`[text-id="${id}"]`);

    if (parent && pointList?.length >= 1 && id && name && !isExist) {
      const points = Object.values(pointList)?.map((point) => [
        point.x,
        point.y,
      ]);
      const position = polylabel([points], 1);
      const g = document.createElementNS(xmlns, 'g');
      g.setAttribute('text-id', id);
      parent?.appendChild(g);

      const rect = document.createElementNS(xmlns, 'rect');
      rect.setAttribute('fill', 'white');
      rect.setAttribute('rx', '5');
      rect.setAttribute('stroke-width', '1px');
      rect.setAttribute('stroke', 'black');
      rect.setAttribute('pointer-events', 'none');
      g.appendChild(rect);

      const text = document.createElementNS(xmlns, 'text');

      text.setAttribute('dominant-baseline', 'central');
      text.setAttribute('text-anchor', 'middle');
      text.append(name);
      g.appendChild(text);

      const bounds = text.getBBox();
      const padding = 5;
      const rectWidth = bounds.width + padding * 2;
      const rectHeight = bounds.height + padding * 2;

      g.setAttribute(
        'transform',
        'translate(' +
          (position[0] - rectWidth / 2) +
          ',' +
          (position[1] - rectHeight / 2) +
          ')'
      );

      text.setAttribute('x', (rectWidth / 2).toString());
      text.setAttribute('y', (rectHeight / 2).toString());

      rect.setAttribute('width', rectWidth.toString());
      rect.setAttribute('height', rectHeight.toString());
    }
  }, []);

  const setPolygonElement = useCallback(
    (element: SVGPolygonElement) => {
      const id = element.getAttribute('id');
      const info = getElementInfo(id);
      if (info) {
        element.setAttribute('name', info.name);
        element.setAttribute('level', info.level);
        element.setAttribute(
          'equipment-quantity',
          info?.equipmentQuantity?.toString() ?? ''
        );
      }

      element.removeAttribute('opacity');
      element.removeAttribute('fill');
      element.removeAttribute('fill-opacity');

      const level = element.getAttribute('level');

      if (level === LocationLevel.STACK) {
        element?.parentElement?.appendChild(element);
      }

      if (level !== LocationLevel.STACK) {
        createText(element);
      }
    },
    [createText, getElementInfo]
  );

  useEffect(() => {
    const svgWrapper = svgWrapperRef?.current;
    const svgElement = svgWrapper?.querySelector('svg');
    const polygonList = svgElement?.querySelectorAll('polygon');
    const polygonElements = Array.from(polygonList ?? []);

    const wrapperRect = svgWrapper?.getBoundingClientRect();
    if (wrapperRect?.width) {
      svgElement?.setAttribute('width', `${wrapperRect.width}px`);
    }

    polygonElements?.forEach((element: SVGPolygonElement) => {
      const category = element.getAttribute('category');

      if (category === 'Equipment') {
        element?.parentElement?.appendChild(element);
      }
    });

    polygonElements?.forEach(setPolygonElement);

    const gElements = svgElement?.querySelectorAll('g');
    gElements?.forEach((element) => {
      element?.parentElement?.appendChild(element);
    });
  }, [svgWrapperRef, svgWrapperRef.current?.innerHTML, setPolygonElement]);
}
