import { Button, SelectValue } from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  FormFieldsWrapper,
} from '@mezzanine-ui/react-hook-form';
import { MznPaginationTable, PageLayout } from '@solar/templates';
import { useForm } from 'react-hook-form';
import { InventoryCountDocField } from './InventoryCountDocField';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useInventoryCountColumns } from './use-inventory-count-columns';
import {
  API_NAMESPACE,
  getNextParams,
  NextPagination,
  PageInfo,
} from '@solar/data';
import { useCallback, useMemo, useState } from 'react';
import useSWR from 'swr';
import { GetResultsParams } from './InventoryCountReports';

interface FormValues {
  inventoryTakingId: SelectValue;
}

const schema = yup.object().shape({
  inventoryTakingId: yup
    .object()
    .shape({
      id: yup.string().required('必填'),
      name: yup.string().required('必填'),
    })
    .required('必填'),
});

export function InventoryCountResults() {
  const methods = useForm<FormValues>({
    resolver: yupResolver(schema),
  });
  const { handleSubmit } = methods;

  const [params, setParams] = useState<GetResultsParams | undefined>();
  const { data, error, mutate, isValidating } = useSWR<
    PageInfo & {
      results: { id: string; name: string }[];
    }
  >(
    params && params?.inventoryTakingId
      ? [
          `/warehouses/takings/${params.inventoryTakingId}/results`,
          {
            namespace: API_NAMESPACE.MATERIALS,
            params,
          },
        ]
      : null
  );
  const searching = !data && !error && isValidating;
  const pageInfo: PageInfo = useMemo(() => {
    return {
      limit: data?.limit ?? 10,
      offset: data?.offset ?? 0,
      totalCount: data?.totalCount ?? 0,
    };
  }, [data]);

  const onSearch = useCallback(
    async (nextPagination?: NextPagination) => {
      const { offset, limit } = getNextParams(
        nextPagination ?? { nextPage: 1 },
        pageInfo
      );
      handleSubmit((data) => {
        setParams({
          inventoryTakingId: data.inventoryTakingId.id,
          offset,
          limit,
        });
      })();
    },
    [pageInfo]
  );

  const columns = useInventoryCountColumns({
    inventoryTakingData: data?.results ?? [],
    editable: false,
  });

  return (
    <PageLayout title="盤點結果">
      <FormFieldsWrapper
        methods={methods}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 'var(--mzn-spacing-4)',
            alignItems: 'flex-end',
          }}
        >
          <InventoryCountDocField />
          <Button
            loading={searching}
            type="button"
            variant="outlined"
            onClick={() => onSearch()}
          >
            確認
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 'var(--mzn-spacing-4)',
            alignItems: 'flex-end',
          }}
        >
          <Button disabled type="button" variant="outlined" onClick={() => {}}>
            執行盤盈虧
          </Button>
        </div>
      </FormFieldsWrapper>
      <MznPaginationTable
        scroll={{ x: 4000 }}
        loading={searching}
        dataSource={data?.results ?? []}
        columns={columns}
        fetchData={onSearch}
        pageInfo={pageInfo}
      />
    </PageLayout>
  );
}
