import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { API_NAMESPACE, request } from '../request';
import { APIResponse, Drawing, PendingDrawing } from './types';

const namespace = API_NAMESPACE.DRAWINGS;

export function useGetPendingDrawingById({ id }: { id: string | null }) {
  const { data, error } = useSWR<APIResponse<PendingDrawing>>(
    id
      ? [
          `/drawings/pendings/${id}`,
          {
            namespace,
          },
        ]
      : null
  );

  return {
    pendingDrawing: data?.data,
    error,
    isLoading: !data && !error,
    pageInfo: {
      page: data?.page ?? 1,
      size: data?.size ?? 10,
      count: data?.count ?? 0,
    },
  };
}

export function useGetPendingDrawings() {
  const [params, setParams] = useState({ page: 1, size: 10 });

  const { data, error, mutate } = useSWR<APIResponse<PendingDrawing[]>>([
    '/drawings/pendings',
    {
      params,
      namespace,
    },
  ]);

  const fetchPendingDrawings = setParams;

  return {
    pendingDrawings: data?.data ?? [],
    fetchPendingDrawings,
    mutateGetPendingDrawings: mutate,
    error,
    isLoading: !data && !error,
    pageInfo: {
      page: data?.page ?? 1,
      size: data?.size ?? 10,
      count: data?.count ?? 0,
    },
  };
}

export function useGetPendingDrawingsDDL({
  category,
}: {
  category: 'drawing_type' | 'location';
}) {
  const { data, error } = useSWR<APIResponse<any[]>>(
    category
      ? [
          '/drawings/pendings/queryDDL',
          {
            params: { category },
            namespace,
          },
        ]
      : null
  );

  return {
    data: data?.data ?? [],
    error,
    isLoading: !data && !error,
  };
}

export function createPendingDrawing(
  data: Partial<{
    parentDrawingCode: string;
    location: string;
    s3FilePath: string;
    s3ObjectKey: string;
    fileName: string;
    fileLink: string;
  }>
) {
  return request('/drawings/pendings', {
    method: 'post',
    namespace,
    body: JSON.stringify(data),
    responseParser: (res) => res,
  });
}

export function updatePendingDrawing({
  id,
  data,
}: {
  id: string;
  data: Partial<{
    parentDrawingCode: string;
    location: string;
    s3FilePath: string;
    s3ObjectKey: string;
    fileName: string;
    fileLink: string;
  }>;
}) {
  return request(`/drawings/pendings/${id}`, {
    method: 'put',
    body: JSON.stringify(data),
    responseParser: (res) => res,
    namespace,
  });
}

export function deletePendingDrawing({
  parentDrawingCodes,
}: {
  parentDrawingCodes: string[];
}) {
  return request(`/drawings/pendings`, {
    method: 'delete',
    params: { parentDrawingCodes },
    responseParser: (res) => res,
    namespace,
  });
}
