import { request, API_NAMESPACE } from '../../request';
import useSWR from 'swr';
import * as yup from 'yup';

export type HubDeliveryOrder = {
  id: string;
  customerName: string;
  shipDate: string;
  debitDate: string;
  reverseDate: string;
  details: HubDeliveryOrderDetail[];
  warehouseOptions: HubDeliveryOrderWarehouseOption[];
};

export type HubDeliveryOrderDetail = {
  deliveryOrderDetailId: number;
  line: string;
  materialId: string;
  quantity: string;
  quantityUnit: string;
};

export type HubDeliveryOrderWarehouseOption = {
  id: number;
  materialId: string;
  sapBatchId: string;
  subBatchId: string;
  solarBatchId: string;
  customerBatchId: string;
  barcode: string;
  quantity: string;
  outQuantity: string;
};

type HookProps = {
  hubDeliveryOrderId: string | null;
  errorCallback?: (error: Error) => void;
};

export const useHubOrderGetById = ({
  hubDeliveryOrderId,
  errorCallback,
}: HookProps) => {
  const { data, mutate, isValidating } = useSWR<HubDeliveryOrder>(
    validateSWRKey(hubDeliveryOrderId),
    request,
    {
      revalidateOnFocus: false,
      onError: (error) => errorCallback?.(error),
    }
  );
  return {
    data: data ?? ({} as HubDeliveryOrder),
    isLoading: isValidating,
    refetchHubDeliveryOrder: mutate,
  };
};

function validateSWRKey(hubDeliveryOrderId: string | null) {
  try {
    const safeHubDevlieryOrderId = yup
      .string()
      .required()
      .validateSync(hubDeliveryOrderId);
    return [
      `/hubDeliveryOrders/get/${safeHubDevlieryOrderId}`,
      {
        namespace: API_NAMESPACE.HR,
      },
    ];
  } catch (error) {
    return false;
  }
}
