import type { CustomerTradeConditionExportFormValues } from '@solar/data';
import { CustomerTradeConditionExportFormFieldName as FormFieldName } from '@solar/data';
import { useFormContext } from 'react-hook-form';
import { InputField } from '@mezzanine-ui/react-hook-form';

export function MaterialAutoComplete() {
  const formMethods = useFormContext<CustomerTradeConditionExportFormValues>();

  return (
    <>
      <div>產品料號：</div>
      <div>
        <InputField
          placeholder="輸入產品料號或品名"
          control={formMethods.control}
          registerName={FormFieldName.MATERIAL_ID}
        />
      </div>
    </>
  );
}
