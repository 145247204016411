import { createContext } from 'react';

export enum LocalTabs {
  CHECK_IN = 'CHECK_IN',
  CHECK_OUT = 'CHECK_OUT',
  GEN_PARAMS = 'GEN_PARAMS',
}

export const PAGE_OPTIONS = {
  [LocalTabs.CHECK_IN]: { id: LocalTabs.CHECK_IN, name: '入站' },
  [LocalTabs.CHECK_OUT]: { id: LocalTabs.CHECK_OUT, name: '出站' },
  [LocalTabs.GEN_PARAMS]: { id: LocalTabs.GEN_PARAMS, name: '生產參數' },
};

export interface SiteInfo {
  equipmentId?: string | null;
  workCenterId?: string | null;
  processingId?: string | null;
  recipeId?: string | null;
}

export const SiteInfoContext = createContext<SiteInfo>({});

export const defaultValues = {
  workCenterBigClass: null,
  workCenterSmallClass: null,
  equipment: null,
  selectedEquipmentMaterials: [],

  checkoutForm: {
    materialRequestOrderId: '', // '202312190009',
    materialBarcode: '', //'FEPR1FIO203IAX545X156X133_2312190018_00001_lqxuzqNoL0M',
    createdAt: new Date().toISOString(),
    materialCheckoutList: [],
  },
  checkinForm: {
    materialRequestOrderId: '', //'202312190009',
    materialBarcode: '', // 'FEPR1FIO203IAX640X187X159D_2023111202_00001_o3h6QwUXtm4',
    createdAt: new Date().toISOString(),
    materialCheckinList: [],
  },
};
