import { Message, ModalActions, SelectValue } from '@mezzanine-ui/react';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import { useForm } from 'react-hook-form';
import { WarehouseAndVehicleFilter } from '../IncomingInspectOperationPage/Modals/WarehouseAndVehicleFilter';
import { ModalLayout } from '../ModalLayout/ModalLayout';
import {
  MaterialResponseData,
  returnWarehousesInventoryMiscMaterial,
  useLoaders,
  useMaterials,
} from '@solar/data';
import { MiscellaneousDispatchAndReturnModal } from './enum';
import { useCallback, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { validateRequiredSelect } from '../utils/validateRequiredSelect';
import {
  CustomAutoCompleteField,
  LocationIdsField,
  LocationSelectorProvider,
  RowSection,
  useLocationSelectorController,
  useTargetModal,
} from '@solar/templates';

export type ReturnModalForm = {
  materialId: SelectValue;
  materialDescription: SelectValue;
  mainMaterialUnitQuantity: number;
  loader: SelectValue;
  locationIds: Array<string>;
};

export const returnSchema: Yup.ObjectSchema<{
  materialId: SelectValue;
  materialDescription: SelectValue;
  mainMaterialUnitQuantity: number;
  // loader: SelectValue;
}> = Yup.object().shape({
  materialId: validateRequiredSelect(),
  materialDescription: validateRequiredSelect(),
  mainMaterialUnitQuantity: Yup.number().typeError('必填').required('必填'),
  // loader: validateRequiredSelect(),
});

export function MiscellaneousReturnModal({
  methods,
  openLocationSelectorModal,
}: any) {
  const modalController = useTargetModal<ReturnModalForm>(
    MiscellaneousDispatchAndReturnModal.Return
  );
  const { open, data } = modalController;

  const warehouseAndVehicleFilterRef =
    useRef<React.ElementRef<typeof WarehouseAndVehicleFilter>>(null);

  const { loaders, mutateLoaderParams } = useLoaders();
  const controller = useLocationSelectorController({
    onSelectedIdsChange(selectedIds) {
      const shelfId =
        selectedIds?.shelfId ?? selectedIds?.stackId ?? selectedIds?.zoneId;
      if (shelfId) {
        mutateLoaderParams({
          shelfIds: [shelfId],
          whenNotFoundAutoCreate: true,
        });
      }
    },
  });

  useEffect(() => {
    if (open && data) {
      methods.reset(data);
    }
  }, [methods, open, data]);

  const closeReturnModal = ({ keepFormData = false }) => {
    /**
     * It's a temp workaround to keep last form data of opened modal
     *  as you switch to LocationSelectorModal
     */
    modalController.closeModal(keepFormData ? methods.getValues() : undefined);
  };

  const { materials } = useMaterials(methods);

  const autoCompleteHandler = useCallback(
    (event: SelectValue, fieldName: 'materialId' | 'materialDescription') => {
      const targetId = event?.id;
      if (!targetId) return;
      if (!materials?.length) return;

      const relatedMaterials: { [id: string]: MaterialResponseData } =
        materials.reduce(
          (prev, material) => ({ ...prev, [material.id]: material }),
          {}
        );
      if (fieldName === 'materialId')
        methods.setValue(fieldName, {
          id: relatedMaterials[targetId].id,
          name: relatedMaterials[targetId].id,
        });

      if (fieldName === 'materialDescription')
        methods.setValue(fieldName, {
          id: relatedMaterials[targetId].description,
          name: relatedMaterials[targetId].description,
        });
    },
    [materials, methods]
  );

  return (
    <ModalLayout
      modalHeader="退料"
      loading={methods?.formState?.isSubmitting}
      modalFooter={
        <ModalActions
          confirmText="確認"
          cancelText="取消"
          onCancel={() => closeReturnModal({ keepFormData: false })}
          confirmButtonProps={{
            form: MiscellaneousDispatchAndReturnModal.Return,
          }}
        />
      }
      {...modalController}
    >
      <FormFieldsWrapper
        id={MiscellaneousDispatchAndReturnModal.Return}
        methods={methods}
        onSubmit={async (values) => {
          const { selectedIds } = controller;
          const shelfId =
            selectedIds?.shelfId ?? selectedIds?.stackId ?? selectedIds?.zoneId;

          if (!shelfId) {
            warehouseAndVehicleFilterRef.current?.setLocationRequired(true);
            return;
          } else {
            warehouseAndVehicleFilterRef.current?.setLocationRequired(false);
          }

          if (!values.loader) {
            methods.setError('loader', {
              type: 'required',
              message: '必填',
            });
            return;
          }

          try {
            const { materialId, mainMaterialUnitQuantity, loader } = values;
            const res = await returnWarehousesInventoryMiscMaterial({
              items: [
                {
                  materialId: materialId.id,
                  loaderId: Number(loader.id),
                  mainMaterialUnitQuantity: Number(mainMaterialUnitQuantity),
                  secondaryMaterialUnitQuantity: 0,
                },
              ],
            });

            if (res[0].id) {
              Message.success('發料成功');
              modalController?.closeModal();
            }
          } catch (err) {
            Message.error(JSON.stringify(err));
            Message.error('退料失敗');
          }
        }}
      >
        <RowSection label="料號：">
          <CustomAutoCompleteField
            value={methods?.watch('materialId') ?? null}
            width={480}
            registerName="materialId"
            options={
              materials?.map((material) => ({
                id: material.id,
                name: material.id,
              })) ?? []
            }
            onChange={(event) =>
              autoCompleteHandler(event, 'materialDescription')
            }
          />
        </RowSection>
        <RowSection label="料號：">
          <CustomAutoCompleteField
            value={methods?.watch('materialId') ?? null}
            width={480}
            registerName="materialId"
            options={
              materials?.map((material) => ({
                id: material.id,
                name: material.id,
              })) ?? []
            }
            onChange={(event) =>
              autoCompleteHandler(event, 'materialDescription')
            }
          />
        </RowSection>
        <RowSection label="品名：">
          <CustomAutoCompleteField
            value={methods?.watch('materialDescription') ?? null}
            width={480}
            registerName="materialDescription"
            options={
              materials?.map((material) => ({
                id: material?.id ?? '',
                name: material?.description ?? '',
              })) ?? []
            }
            onChange={(event) => autoCompleteHandler(event, 'materialId')}
            errorMsgRender={(e) => e.message}
          />
        </RowSection>
        <RowSection label="數量：">
          <InputField
            width={480}
            registerName="mainMaterialUnitQuantity"
            valueAsNumber
          />
        </RowSection>
        {/* <RowSection label="儲位："> */}
        <WarehouseAndVehicleFilter
          enableLoaderSelector
          enableDirectlyLoaderSearch={false}
          openLocationSelectorModal={() => {
            closeReturnModal({ keepFormData: true });
            openLocationSelectorModal();
          }}
          loaders={loaders}
          ref={warehouseAndVehicleFilterRef}
        />
        {/* </RowSection> */}
        <RowSection label="品名：">
          <CustomAutoCompleteField
            value={methods?.watch('materialDescription') ?? null}
            width={480}
            registerName="materialDescription"
            options={
              materials?.map((material) => ({
                id: material?.id ?? '',
                name: material?.description ?? '',
              })) ?? []
            }
            onChange={(event) => autoCompleteHandler(event, 'materialId')}
            errorMsgRender={(e) => e.message}
          />
        </RowSection>
        <RowSection label="數量：">
          <InputField
            width={480}
            registerName="mainMaterialUnitQuantity"
            valueAsNumber
          />
        </RowSection>
        <RowSection label="儲位：">
          <LocationIdsField
            mode="single"
            rowProps={{ align: 'middle' }}
            methods={methods}
            registerName="locationIds"
            openModal={openLocationSelectorModal}
          />
        </RowSection>
      </FormFieldsWrapper>
    </ModalLayout>
  );
}
