/* eslint-disable @typescript-eslint/no-empty-function */
import {
  LocationLevel,
  SelectedIds,
  getLocationRelationParent,
  request,
  useFactories,
  useGetLocation,
  useGetLocationOptions,
} from '@solar/data';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { get, isEqual } from 'lodash';
import { message } from 'antd';

type LocationPath = (
  | 'shelf'
  | 'stack'
  | 'zone'
  | 'area'
  | 'floor'
  | 'building'
  | 'factory'
)[];

export type UseLocationSelectorControllerProps = {
  noDefaultValue?: boolean;
  onSelectedIdsChange?: (selectedIds?: SelectedIds) => void;
  resetAfterClosing?: boolean;
};

export function useLocationSelectorController({
  noDefaultValue = false,
  onSelectedIdsChange,
  resetAfterClosing = true,
}: UseLocationSelectorControllerProps) {
  const [svgId, setSvgId] = useState<string>();
  const [selectedIds, setSelectedIds] = useState<SelectedIds>({});
  const { factories } = useFactories();
  const currentLocation = useGetLocation(selectedIds);
  const locationOptions = useGetLocationOptions(factories, currentLocation);
  const [activeId, setActiveId] = useState<string>();

  useEffect(() => {
    if (!noDefaultValue && factories?.[0]) {
      const factoryId = factories[0]?.id;
      setSvgId(factoryId);
      setSelectedIds({ factoryId });
      setActiveId(factoryId);
    }
  }, [factories, noDefaultValue]);

  const resetLocationSelector = useCallback(() => {
    if (factories?.[0] && resetAfterClosing) {
      const factoryId = factories[0]?.id;
      setSvgId(factoryId);
      setActiveId(factoryId);
      setSelectedIds({ factoryId });
    }
  }, [factories, resetAfterClosing]);

  const getSelectedIdsByLocationId = useCallback(async (locationId: string) => {
    try {
      const res = await getLocationRelationParent(locationId);

      const path = await [
        'shelf',
        'stack',
        'zone',
        'area',
        'floor',
        'building',
        'factory',
      ]?.reduce<LocationPath>((result, _, index, keys) => {
        const path = keys.slice(index);
        const target = get(res, path);
        return target ? (keys.slice(index - 1) as LocationPath) : result;
      }, []);

      if (path?.length === 0 && res)
        return { factoryId: res?.id } as SelectedIds;

      return (await path.reduce((result, key, index, keys) => {
        const keyPath = keys.slice(1, index + 1);
        const value = keyPath?.length === 0 ? res?.id : get(res, keyPath)?.id;
        return {
          ...result,
          [`${key}Id`]: value,
        };
      }, {})) as SelectedIds;
    } catch (err) {
      message.error(`查無位置：${locationId}`);
    }
    return undefined;
  }, []);

  const handleNextLevel = useCallback(
    async (id?: string, level?: LocationLevel) => {
      let targetId = id;

      if (id && level === LocationLevel.BUILDING) {
        const building = await request(`/buildings/${id}`);
        targetId = building?.floors?.[0]?.id;
      }

      if (!targetId) {
        throw new Error('id not found');
      }

      const nextSelectedIds = await getSelectedIdsByLocationId(targetId);

      if (!nextSelectedIds) {
        throw new Error('locationId not found');
      }

      setActiveId(targetId);
      setSvgId(
        nextSelectedIds?.areaId ??
          nextSelectedIds?.floorId ??
          nextSelectedIds?.factoryId
      );
      setSelectedIds((prevSelectedIds) =>
        isEqual(prevSelectedIds, nextSelectedIds)
          ? prevSelectedIds
          : nextSelectedIds ?? {}
      );

      await onSelectedIdsChange?.(nextSelectedIds);
    },
    [getSelectedIdsByLocationId, onSelectedIdsChange]
  );

  return {
    svgId,
    selectedIds,
    setSelectedIds,
    currentLocation,
    locationOptions,
    handleNextLevel,
    activeId,
    setActiveId,
    resetLocationSelector,
  };
}

export type UseLocationSelectorControllerReturn = ReturnType<
  typeof useLocationSelectorController
>;

export const LocationSelectorContext =
  createContext<UseLocationSelectorControllerReturn>({
    svgId: undefined,
    selectedIds: {},
    currentLocation: {
      factory: undefined,
      building: undefined,
      floor: undefined,
      area: undefined,
      zone: undefined,
      stack: undefined,
      shelf: undefined,
    },
    locationOptions: {
      factory: undefined,
      building: undefined,
      floor: undefined,
      area: undefined,
      zone: undefined,
      stack: undefined,
      shelf: undefined,
    },
    setSelectedIds: () => {},
    handleNextLevel: async () => {},
    activeId: undefined,
    setActiveId: () => {},
    resetLocationSelector: () => {},
  });

export function useLocationSelectorContext() {
  return useContext(LocationSelectorContext);
}

export type LocationSelectorProviderProps = {
  children: ReactNode;
  controller: UseLocationSelectorControllerReturn;
};

export function LocationSelectorProvider({
  children,
  controller,
}: LocationSelectorProviderProps) {
  return (
    <LocationSelectorContext.Provider value={controller}>
      {children}
    </LocationSelectorContext.Provider>
  );
}
