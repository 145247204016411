import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Message,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalProps,
} from '@mezzanine-ui/react';
import {
  FormFieldsWrapper,
  PasswordField,
} from '@mezzanine-ui/react-hook-form';
import { API_NAMESPACE, request } from '@solar/data';
import { notification } from 'antd';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface ResetPasswordFormValues {
  newPassword: string;
  confirmNewPassword: string;
}

const schema = yup.object().shape({
  newPassword: yup.string().required('必填'),
  confirmNewPassword: yup
    .string()
    .required('必填')
    .oneOf([yup.ref('newPassword')], '密碼不一致'),
});

interface ResetPasswordModalProps extends ModalProps {
  data?: {
    account?: string;
    name?: string;
  };
}

export function ResetPasswordModal({
  data,
  open,
  onClose,
}: ResetPasswordModalProps) {
  const [resetting, setResetting] = useState(false);

  const methods = useForm<ResetPasswordFormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      setResetting(true);

      if (!data?.account) {
        Message.error('尚未選擇帳號');
        return;
      }

      await request(`/users/account/${data?.account}/reset_password`, {
        method: 'PUT',
        namespace: API_NAMESPACE.IAM,
        body: JSON.stringify({
          new_password: values.newPassword,
        }),
      });

      notification.success({
        message: '密碼重設成功',
        description: `${data?.account}：${values?.newPassword}`,
      });

      onClose?.();
    } catch (error: any) {
      if (error instanceof Error) {
        Message.error(`密碼重設失敗：${JSON.parse(error?.message)?.message}`);
      }
    }
  });

  return (
    <Modal open={open} onClose={onClose}>
      <ModalHeader>
        重設密碼：{data?.account ?? ''} {data?.name}
      </ModalHeader>
      <ModalBody>
        <FormFieldsWrapper methods={methods}>
          <PasswordField label="新密碼" registerName="newPassword" />
          <PasswordField label="新密碼確認" registerName="confirmNewPassword" />
        </FormFieldsWrapper>
      </ModalBody>
      <ModalFooter
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 'var(--mzn-spacing-4)',
        }}
      >
        <Button type="button" variant="outlined" onClick={onClose}>
          取消
        </Button>
        <Button
          loading={resetting}
          type="submit"
          variant="contained"
          color="primary"
          onClick={onSubmit}
        >
          送出
        </Button>
      </ModalFooter>
    </Modal>
  );
}
