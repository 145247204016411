import { ModalLayout } from '../../../ModalLayout/ModalLayout';
import { Loading, ModalActions, Typography } from '@mezzanine-ui/react';
import { useModalController } from '../../../ModalLayout/hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import { useRef } from 'react';
import classes from '../../Modal/collect-material-register-modal.module.scss';
import { enhanceCustomMznComponent } from '../../../IncomingInspectOperationPage/Modals/InspectMaterialSecondLayerModal';

const expectRegisterSchema = Yup.object({
  amount: Yup.string().required('必填'),
});

type ChooseMaterialSecondLayerModalProps = {
  firstLayerModalController: ReturnType<typeof useModalController>;
  secondLayerModalController: ReturnType<typeof useModalController>;
  append: any;
};

export function ChooseMaterialSecondLayerModal({
  firstLayerModalController,
  secondLayerModalController,
  append,
}: ChooseMaterialSecondLayerModalProps) {
  const methods = useForm({
    resolver: yupResolver(expectRegisterSchema),
  });

  const {
    formState: { isSubmitting },
    reset,
    getValues,
  } = methods;
  const submitRef = useRef<HTMLInputElement | null>(null);
  const handleOnConfirm = () => {
    submitRef.current?.click();
  };

  const handleClose = () => {
    secondLayerModalController.closeModal();
    reset();
  };

  return (
    <ModalLayout
      modalHeader="新建領料單選取數量"
      className={classes.modal}
      open={secondLayerModalController.open}
      closeModal={handleClose}
      modalFooter={
        <ModalActions
          confirmText="確認"
          cancelText="取消"
          onConfirm={() => handleOnConfirm()}
          onCancel={() => handleClose()}
          confirmButtonProps={{ type: 'submit' }}
        />
      }
    >
      <FormFieldsWrapper
        methods={methods}
        onSubmit={(v) => {
          append({
            zone: secondLayerModalController?.data?.zone,
            amount: Number(getValues().amount),
          });
          firstLayerModalController.closeModal();
          secondLayerModalController.closeModal();
          reset();
        }}
      >
        <Loading loading={isSubmitting}>
          <div className={classes['input-wrapper']}>
            <Typography>料號：AABBBCD02921</Typography>
            <Typography>批號：20230405025-001</Typography>
            <Typography>儲位：1100A1C4J1B / 5</Typography>

            <div className={classes['amount']}>
              {enhanceCustomMznComponent(InputField)({
                props: {
                  registerName: 'amount',
                  type: 'number',
                },
                label: '欲領取數量',
              })}
              <Typography>/</Typography>
              <Typography variant="h4">5</Typography>
            </div>

            <input type="submit" ref={submitRef} style={{ display: 'none' }} />
          </div>
        </Loading>
      </FormFieldsWrapper>
    </ModalLayout>
  );
}
