import { MaterialTransferTable } from "./MaterialTransferTable";
import { useForm } from "react-hook-form";
import { MenuDivider, Typography } from "@mezzanine-ui/react";
import { MaterialTransferFilter } from "./MaterialTransferFilter";
import { useMaterials } from "@solar/data";

export function MaterialTransferPage() {
  const methods = useForm();
  const { materials, pageInfo, mutateGetMaterials } = useMaterials();
  return (
    <div style={{ display: 'grid', gap: 'var(--mzn-spacing-4)' }}>
      <div style={{ display: 'grid', gap: 'var(--mzn-spacing-2)' }}>
        <Typography variant="h1">物料調撥</Typography>
        <MenuDivider />
      </div>
      <MaterialTransferFilter methods={methods} />
      <MaterialTransferTable materials={materials} pageInfo={pageInfo} mutateGetMaterials={mutateGetMaterials} /> 
    </div>
  )
}