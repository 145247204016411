import { useCallback, useState } from 'react';
import { authPostFetcher } from '../fetch';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

interface Token {
  exp: number;
}
export function isTokenExpired(accessToken: string | null): boolean {
  if (!accessToken) return false;

  const decodedToken = jwtDecode<Token>(accessToken);

  return (
    typeof decodedToken.exp === 'number' && Date.now() / 1000 > decodedToken.exp
  );
}

export function useLogin() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = useCallback(async () => {
    const result = await authPostFetcher(
      '/login',
      { account: username, password },
      true
    );

    if (result.status === 'success') {
      const token = result.token;
      localStorage.setItem('accessToken', token);
      setUsername('');
      navigate('/');
    }
    setPassword('');
    return result;
  }, [username, password, navigate]);

  return {
    username,
    setUsername,
    password,
    setPassword,
    error: false,
    handleLogin,
  };
}
