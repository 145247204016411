import { TableDataSourceWithID } from '@mezzanine-ui/core/table';
import { PageInfo } from '../pagination';
import { Sample } from './sample.type';

export interface SubmissionStatus {
  id: number;
  status: number;
  statusDesc: string;
  createdAt: string;
}

export interface GetSubmissionOrganizationsParams {
  factory: string;
  searchTerm?: string;
}

export interface SubmissionOrgResponse {
  records: SubmissionOrg[];
  pageInfo: PageInfo;
}

export interface SubmissionOrg {
  id: number;
  factory: string;
  seq: string;
  limsCode: string;
  depCode: string;
  department: string;
  remark: string;
  locked: boolean;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt: string;
}

export interface CreateSubmissionPayload {
  factory: string;
  submissionOrganization: string;
  reportReceiver: string;
  reportReceiverEmail: string;
  needPaperReport: boolean;
  createdBy: string;
  samples: Sample[];
}

export interface GetSubmissionResultsByInventoryParams {
  factory: string;
  materialId: string;
  batchId: string;
  offset?: number;
  limit?: number;
}

export interface GetSubmissionResultsByInventoryResponse {
  records: SubmissionResult[];
  pageInfo: PageInfo;
}

export interface SubmissionResult extends TableDataSourceWithID {
  id: number;
  factory: string;
  status: number;
  statusDesc: string;
  subType: string;
  subNumber: string;
  rushPj: boolean;
  subApplyTime: string;
  subDueTime: any;
  charge: number;
  notice: string;
  remark: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt: any;
  updateHistory: string;
  metadata: Metadata;
  samples: SubmissionSample[];
}

export interface SubmissionSample extends TableDataSourceWithID {
  id: number;
  factory: string;
  status: number;
  statusDescription: string;
  submissionId: number;
  sampleNumber: string;
  name: string;
  amount: string;
  netWeight: string;
  remainingWeight: string;
  emptyBagWeight: string;
  sampleUnit: string;
  testPlan: any;
  charge: number;
  remark: any;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt: any;
  metadata: Metadata;
  analysisRequirements: SubmissionAnalysisRequirement[];
  subSampleMethods: SubSampleMethod[];
}

export interface Submission {
  id: number;
  factory: string;
  status: number;
  statusDesc: string;
  subType: string;
  subNumber: string;
  rushPj: boolean;
  subApplyTime: string;
  subDueTime: any;
  charge: number;
  notice: string;
  remark: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt: any;
  updateHistory: string;
}

export interface Metadata {
  id: number;
  submissionId: number;
  subSampleId: number;
  rushPj: boolean;
  batchNumber: string;
  materialId: string;
  submitReason: string;
  type: string;
  customerName: string;
  dueDate: any;
  userType1: any;
  userType2: any;
  userType3: any;
  accountTransfer: boolean;
  returnSample: boolean;
  specification: string;
  registeredAt: any;
  sampleRegisteredBy: any;
  barcode: string;
  previousSampleNumber: any;
  description: string;
  arrivedAt: any;
  arrivedBy: any;
  reportIssuedAt: any;
  reportIssuedBy: any;
  cancelledAt: any;
  cancelledBy: any;
  cancelReason: any;
  mesMemo: string;
  returnTo: any;
  returnAt: any;
  estimatedDestroyAt: any;
  destoriedBy: any;
  destroyedAt: any;
  storagePosition: any;
  testType: any;
  traceSampleNumber: any;
  regainAt: any;
  regainBy: any;
}

export interface SubmissionAnalysisRequirement {
  id: number;
  factory: string;
  submissionId: number;
  subSampleId: number;
  analysisRequirementType: string;
  analysisRequirement: string;
  processTime: number;
  rushProcessTime: number;
  price: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
}

export interface SubSampleMethod {
  id: number;
  factory: string;
  submissionId: number;
  subSampleId: number;
  seq: number;
  workStatus: number;
  workStatusDescription: string;
  specStatus: any;
  specStatusDescription: any;
  dueTime: string;
  methodNumber: string;
  methodName: any;
  price: number;
  assignedOp: string;
  assignedOpLimsUserId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  sampleAnalizerValidateDate: string;
  sampleAnalizerValidateUser: string;
  sampleConfirmValidateDate: string;
  sampleConfirmValidateUser: string;
  sampleRejectDate: any;
  sampleRejectUser: any;
  sampleRejectReason: any;
  subSampleMethodItems: SubSampleMethodItem[];
}

export interface SubSampleMethodItem {
  id: number;
  factory: string;
  submissionId: number;
  subSampleId: number;
  subSampleMethodId: number;
  seq: number;
  methodNumber: string;
  testItem: string;
  report?: boolean;
  reportUnit: string;
  result?: string;
  processResult?: number;
  resultType: string;
  formula: any;
  matchCode: string;
  pql: any;
  lsl: any;
  lcl: any;
  mean: any;
  ucl: any;
  usl: any;
  mdl: any;
  dilution: any;
  price: any;
  textResult: any;
  rawDataLink: any;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt: any;
  showMultiResult: boolean;
  rawDataAvg?: string;
  calResult: any;
  calProcessResult: any;
  loq: any;
  uod: any;
  lod: any;
  submission: Submission;
  // itemRawRecords: ItemRawRecord[]
}

export interface GetSubmissionResultsParams {
  factory: string;
  // materialId: string;
  // batchId: string;
  offset?: number;
  limit?: number;
  subNumber?: string;
  submissionId?: string;
  department?: string;
  createBy?: string;
  statuses?: string[];
}

export interface GetSubmissionResultsResponse {
  records: SubmissionResult[];
  pageInfo: PageInfo;
}
