import { Message } from '@mezzanine-ui/react';
import {
  MaterialAllocationFormValues,
  useGetMaterialAllocationsResultById,
} from '@solar/data';
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

export function useInitMaterialAllocationForm({
  methods,
  getCalculatedMaterialAllocations,
}: {
  methods: UseFormReturn<MaterialAllocationFormValues>;
  getCalculatedMaterialAllocations: () => void;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamOfResultId = searchParams.get('resultId');

  const { result } = useGetMaterialAllocationsResultById({
    id: searchParamOfResultId,
  });

  useEffect(() => {
    if (searchParamOfResultId && result) {
      if (!result?.workOrderId) {
        Message.error('配料結果無工單資料');
        return;
      }

      setSearchParams({
        resultId: result?.id?.toString(),
        workOrderId: result?.workOrderId?.toString(),
      });

      const manualData =
        result?.materialItems?.map((item) => ({
          ...item,
          id: item?.batchStoredLoaderRecordId?.toString(),
          batchStoredLoaderRecordId:
            item?.batchStoredLoaderRecordId?.toString(),
          usedOldMaterial: (item?.usedOldMaterial ?? true)?.toString() as
            | 'true'
            | 'false',
          usedMaterialUnitQuantity: item?.usedMaterialUnitQuantity ?? '0',
        })) ?? [];

      methods?.reset({
        targetWeight: Number(result?.targetWeight ?? '0'),
        manualData,
        recipeId: result?.recipeId
          ? { id: result?.recipeId, name: result?.recipeId }
          : null,
        recipeVersion: result?.recipeVersion
          ? { id: result?.recipeVersion, name: result?.recipeVersion }
          : undefined,
        productionExpectedFinishAt:
          result?.productionExpectedFinishAt ?? undefined,
        expectedCompletedAt: result?.expectedCompletedAt ?? undefined,
        remark: result?.remark ?? undefined,
        targetMaterialSubBatchCount:
          result?.targetMaterialSubBatchCount ?? null,
      });

      getCalculatedMaterialAllocations();
    }
  }, [
    getCalculatedMaterialAllocations,
    methods,
    result,
    searchParamOfResultId,
    setSearchParams,
  ]);

  return { result };
}
