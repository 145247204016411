import { useEffect, useState } from 'react';
import styles from './../../TargetNumber.module.scss';
import { useTargetRuleCreateApplyMaterialColumns } from './useTargetRuleCreateApplyMaterialColumns';
import { Table, Tag, Typography } from '@mezzanine-ui/react';
import {
  FormFieldsWrapper,
  SearchInputField,
} from '@mezzanine-ui/react-hook-form';
import { TargetRuleCreateFormFields } from '../../TargetNumber.interface';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useGetCustomerMaterialId, useGetMaterialIdInternalRule } from '@solar/data';

interface TargetRuleCreateApplyMaterialProps {
  customerIds: string[];
  isViewOnly: boolean;
  ruleTypeForDisplay: string;
  materialIdForViewOnly: Material[];
  applyCustomerIsEmpty: boolean;
  isUsed: boolean;
}

interface Material {
  number_rule_id: number;
  material_id: string;
  is_disabled: boolean;
  created_at: string;
  updated_at: string;
  number_rule_material_id: number;
  description: string;
}

interface MaterialItem {
  material_id: string;
  description: string;
}

export function TargetRuleCreateApplyMaterial({
  customerIds,
  isViewOnly,
  ruleTypeForDisplay,
  materialIdForViewOnly,
  applyCustomerIsEmpty,
  isUsed,
}: TargetRuleCreateApplyMaterialProps) {
  const methods = useFormContext<TargetRuleCreateFormFields>();
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const customerIdsString = customerIds.join(',');
  const { control, setValue } = methods;
  const { fields: materialIds } = useFieldArray({
    control,
    name: 'materialIds',
  });

  const { customerMaterialId, isLoading, total } = useGetCustomerMaterialId({
    customerIds: customerIdsString,
    query: searchQuery,
    pageAt: page,
    pageSize: pageSize,
  });

  const customerMaterialIdWithKey = customerMaterialId?.map(
    (item: MaterialItem) => ({
      ...item,
      key: item.material_id,
    })
  );
  const tableData = customerMaterialIdWithKey || [];
  const columns = useTargetRuleCreateApplyMaterialColumns();

  const { materialIdForInternalRule, isLoadingForInternalRule, totalForInternalRule } = useGetMaterialIdInternalRule({
    query: searchQuery,
    pageAt: page,
    pageSize: pageSize,
  });

  const MaterialIdWithKeyInternalRule = materialIdForInternalRule?.map(
    (item: MaterialItem) => ({
      ...item,
      key: item.material_id,
    })
  );
  const tableDataForInternalRule = MaterialIdWithKeyInternalRule || [];

  useEffect(() => {
    const totalPages = Math.ceil(totalForInternalRule / pageSize);

    if (page > totalPages) {
      setPage(totalPages);
    }
  }, [totalForInternalRule, pageSize, page]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>(
    materialIds.map((field) => field.materialId)
  );

  const handleRowSelectionChange = (newSelectedRowKeys: string[]) => {
    setSelectedRowKeys(newSelectedRowKeys);

    const updatedMaterialIds = newSelectedRowKeys.map((key) => ({
      materialId: key,
    }));
    setValue('materialIds', updatedMaterialIds, { shouldValidate: true });
  };

  useEffect(() => {
    if (isViewOnly) {
      const viewOnlySelectedRowKeys = materialIds.map(
        (field) => field.materialId
      );
      setSelectedRowKeys(viewOnlySelectedRowKeys);
    }
  }, [isViewOnly, materialIds]);

  const rowSelection = {
    selectedRowKey: selectedRowKeys,
    onChange: handleRowSelectionChange,
  };

  const handleTagClose = (closedKey: string) => {
    const newSelectedRowKeys = selectedRowKeys.filter(
      (key) => key !== closedKey
    );
    setSelectedRowKeys(newSelectedRowKeys);

    const updatedMaterialIds = newSelectedRowKeys.map((key) => ({
      materialId: key,
    }));
    setValue('materialIds', updatedMaterialIds, { shouldValidate: true });
  };

  return (
    <FormFieldsWrapper methods={methods} className={styles.createTable}>
      {isViewOnly ? (
        <Typography variant="h3">已套用料號</Typography>
      ) : (
        <Typography variant="h3">設定套用料號</Typography>
      )}
      <>
        {isViewOnly ? null : (
          <div>
            <SearchInputField
              registerName="item_id"
              width={400}
              size="medium"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        )}
        {applyCustomerIsEmpty && ruleTypeForDisplay !== 'internal' ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '40px',
            }}
          >
            <Typography variant="h3">請先選擇套用客戶</Typography>
          </div>
        ) : null}
        {isViewOnly ? null : (
          <div key={selectedRowKeys.join(',')} className={styles.tag}>
            {selectedRowKeys.map((key) => (
              <Tag
                key={key}
                size="medium"
                closable
                onClose={() => handleTagClose(key)}
              >
                {key}
              </Tag>
            ))}
          </div>
        )}
        {ruleTypeForDisplay === 'internal' ? (
          // <Table
          //   className={styles.table}
          //   columns={columns}
          //   dataSource={materialIdForViewOnly.map((material) => ({
          //     ...material,
          //     id: material.material_id,
          //   }))}
          //   loading={isLoading}
          //   bodyClassName={styles.tableBody}
          // />
          <Table
            className={styles.table}
            columns={columns}
            dataSource={tableDataForInternalRule}
            loading={isLoadingForInternalRule}
            rowSelection={rowSelection}
            bodyClassName={styles.tableBody}
            pagination={{
              disableAutoSlicing: true,
              current: page,
              total: totalForInternalRule,
              onChange: setPage,
              options: {
                pageSize: pageSize,
                onChangePageSize: setPageSize,
                showJumper: true,
                jumperButtonText: '前往',
                jumperHintText: '跳至',
                jumperInputPlaceholder: '頁碼',
                className: styles.pagination,
              },
            }}
          />
        ) : (
          <Table
            className={styles.table}
            columns={columns}
            dataSource={tableData}
            loading={isLoading}
            rowSelection={rowSelection}
            bodyClassName={styles.tableBody}
            pagination={{
              disableAutoSlicing: true,
              current: page,
              total: total,
              onChange: setPage,
              options: {
                pageSize: pageSize,
                onChangePageSize: setPageSize,
                showJumper: true,
                jumperButtonText: '前往',
                jumperHintText: '跳至',
                jumperInputPlaceholder: '頁碼',
                className: styles.pagination,
              },
            }}
          />
        )}
      </>
    </FormFieldsWrapper>
  );
}
