import { Button, Input, Typography } from '@mezzanine-ui/react';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useCallback } from 'react';

type BarcodeScanInputProps = {
  customOnchange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  inputValue?: string;
  inputStyle?: string;
  setInputValue?: Dispatch<SetStateAction<string>>;
  isEnterHit?: boolean;
  setEnterHit?: Dispatch<SetStateAction<boolean>>;
  otherOnKeyDownAction?: (inputValue?: string) => Promise<void> | void;
  label?: string;
  disableSearchBtn?: boolean;
  placeholder?: string;
};

/**
 *  input value and isEnterHit control can either be controlled by Caller
 *  or will be provided by the internal state.
 */
export function BarcodeScanInput({
  customOnchange,
  disabled,
  inputValue,
  inputStyle,
  setInputValue,
  isEnterHit,
  setEnterHit,
  otherOnKeyDownAction,
  label,
  disableSearchBtn = false,
  placeholder = '請掃描條碼',
}: BarcodeScanInputProps) {
  const [_localInputValue, _setLocalInputValue] = useState<
    string | undefined
  >();
  const [_isLocalEnterHit, _setLocalLocalEnterHit] = useState<boolean>(false);
  const [loading, setBarcodeScanLoading] = useState(false);
  const ref = useRef<HTMLInputElement | null>(null);

  const onKeyDownHandler = useCallback(
    (
      e:
        | React.KeyboardEvent<HTMLInputElement>
        | React.MouseEvent<HTMLButtonElement>
    ) => {
      // 清空前一次 input
      if (isEnterHit || _isLocalEnterHit) {
        setEnterHit?.(false);
        _setLocalLocalEnterHit(false);

        setInputValue?.('');
        _setLocalInputValue?.('');
      }

      if (
        (e.type === 'keydown' && (e as React.KeyboardEvent).key === 'Enter') ||
        e.currentTarget.name === 'barcode-scan-btn'
      ) {
        e.preventDefault();
        e.stopPropagation();

        if (inputValue?.length === 0) return;

        setEnterHit?.(true);
        _setLocalLocalEnterHit(true);

        setBarcodeScanLoading(true);

        Promise.resolve(
          otherOnKeyDownAction?.(
            typeof inputValue === 'undefined' ? _localInputValue : inputValue
          )
        ).then(() => {
          setBarcodeScanLoading(false);

          // 修正 otherOnKeyDownAction 執行後 input 被 blur 行為
          setTimeout(
            () =>
              (
                ref.current?.querySelector('div > input') as HTMLInputElement
              )?.focus(),
            100
          );
        });
      }
    },
    [
      _isLocalEnterHit,
      _localInputValue,
      inputValue,
      isEnterHit,
      otherOnKeyDownAction,
      setEnterHit,
      setInputValue,
    ]
  );

  return (
    <div>
      {label && <Typography>{label}</Typography>}
      <Input
        ref={ref}
        clearable
        className={inputStyle}
        disabled={loading || disabled}
        value={
          typeof inputValue === 'undefined' ? _localInputValue : inputValue
        }
        onChange={(e) => {
          setInputValue?.(e.target.value);
          _setLocalInputValue?.(e.target.value);
          customOnchange?.(e);
        }}
        inputProps={{
          onKeyDown: onKeyDownHandler,
        }}
        placeholder={placeholder}
      />
      {!disableSearchBtn && (
        <Button
          loading={loading}
          type="button"
          name="barcode-scan-btn"
          onClick={(e) => {
            onKeyDownHandler(e);
          }}
        >
          查詢
        </Button>
      )}
    </div>
  );
}
