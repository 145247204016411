import { useForm } from 'react-hook-form';
import styles from './../../..//TargetNumber.module.scss';
import { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  Typography,
} from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { EditableProTable, ProColumns } from '@ant-design/pro-components';
import { createManualTargetNumberDispatch } from '@solar/data';
import { message } from 'antd';

interface TargetManualDispatcherModalProps {
  open: boolean;
  onClose: () => void;
  onSuccessfulDispatch?: () => void;
  ruleDetails?: {
    customer_id: string;
    customer_name: string;
    material_id: string;
    order_id: string;
    order_detail_id: number;
    sales_order_line: string;
    quantity: number;
    usage_amount: number;
  };
}

interface DataSourceType {
  id: React.Key;
  customer_name?: string;
  material_id?: string;
  quantity?: number;
  internal?: string;
  customer?: string;
}

export function TargetManualDispatcherModal({
  open,
  onClose,
  ruleDetails,
  onSuccessfulDispatch,
}: TargetManualDispatcherModalProps) {
  const upperLimit =
    (ruleDetails?.quantity ?? 0) - (ruleDetails?.usage_amount ?? 0);

  const methods = useForm();

  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>();
  const [dataSource, setDataSource] = useState<readonly DataSourceType[]>([]);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const columns: ProColumns<DataSourceType>[] = [
    {
      title: '序號',
      dataIndex: 'serial',
      valueType: 'index',
      width: 50,
    },
    {
      title: '內部靶編',
      dataIndex: 'internal',
      formItemProps: {
        rules: [
          { required: true, message: '請輸入內部靶編' },
          {
            validator: async (_, value) => {
              if (value && value.length > 50) {
                return Promise.reject(new Error('內部靶編不能超過50個字符'));
              }
              return Promise.resolve();
            },
          },
        ],
      },
      valueType: 'text',
    },
    {
      title: '客戶靶編',
      dataIndex: 'customer',
      formItemProps: {
        rules: [
          { required: true, message: '請輸入內部靶編' },
          {
            validator: async (_, value) => {
              if (value && value.length > 50) {
                return Promise.reject(new Error('內部靶編不能超過50個字符'));
              }
              return Promise.resolve();
            },
          },
        ],
      },
      valueType: 'text',
    },
    {
      title: '操作',
      valueType: 'option',
      width: 80,
      render: () => {
        return null;
      },
    },
  ];

  useEffect(() => {
    if (open) {
      setDataSource([
        {
          id: Date.now(),
        },
      ]);
      setEditableRowKeys([Date.now()]);
    }
  }, [open]);

  const onSubmit = methods.handleSubmit((formData) => {

    setIsLoading(true);

    const isDataSourceValid = dataSource.every(
      ({ internal, customer }) => internal && customer
    );

    if (!isDataSourceValid) {
      message.error('請輸入完整的靶編資料');
      setIsLoading(false);
      return;
    }

    if (typeof ruleDetails?.order_detail_id === 'number') {
      const submissionData = {
        order_detail_id: ruleDetails.order_detail_id,
        numbers: dataSource.map(({ internal, customer }) => ({
          internal: internal ?? '',
          customer: customer ?? '',
        })),
      };

      createManualTargetNumberDispatch(submissionData)
        .then((response) => {
          message.success('手動派發 - 成功');
          onClose();
          if (onSuccessfulDispatch) {
            onSuccessfulDispatch();
          }
        })
        .catch((error) => {
          console.error('Dispatch failed:', error);
          message.error('手動派發 - 失敗');
          onClose();
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      console.error('order_detail_id is undefined');
      setIsLoading(false);
    }
  });

  useEffect(() => {
    const disable =
      dataSource.length === 0 ||
      dataSource.some(
        (row) =>
          !row.internal ||
          row.internal === '' ||
          !row.customer ||
          row.customer === ''
      ) ||
      upperLimit === 0;
    setIsConfirmDisabled(disable);
  }, [dataSource, upperLimit]);

  return (
    <Modal
      className={styles.modal}
      onClose={onClose}
      open={open}
      hideCloseIcon
      size="large"
      loading={isLoading}
    >
      <ModalHeader>手動取號</ModalHeader>
      <ModalBody>
        <Typography variant="h5">
          客戶名稱: {ruleDetails?.customer_name}
        </Typography>
        <Typography variant="h5">
          訂單: {ruleDetails?.order_id} - {ruleDetails?.sales_order_line}
        </Typography>
        <Typography variant="h5">料號: {ruleDetails?.material_id}</Typography>
        <Typography variant="h5">
          已取靶編數量: {ruleDetails?.usage_amount} 組 -- 剩餘可取數量:{' '}
          {upperLimit} 組
        </Typography>
        <br />
        <FormFieldsWrapper methods={methods} className={styles.creatingForm}>
          <div>
            <EditableProTable<DataSourceType>
              rowKey="id"
              maxLength={upperLimit}
              recordCreatorProps={{
                creatorButtonText: `新增 (還剩${
                  upperLimit - dataSource.length
                }/${upperLimit}組)`,
                newRecordType: 'dataSource',
                position: 'bottom',
                record: () => {
                  return {
                    id: Date.now(),
                  };
                },
              }}
              columns={columns}
              value={dataSource}
              onChange={setDataSource}
              editable={{
                type: 'multiple',
                editableKeys,
                onChange: setEditableRowKeys,
                actionRender: (row, _, defaultDom) => {
                  return [
                    <Button
                      type="submit"
                      onClick={() => {
                        setDataSource(
                          dataSource.filter((item) => item.id !== row.id)
                        );
                      }}
                    >
                      刪除
                    </Button>,
                  ];
                },
                onValuesChange: (record, recordList) => {
                  setDataSource(recordList);
                },
              }}
            />
          </div>
        </FormFieldsWrapper>
      </ModalBody>
      <ModalActions
        cancelText="取消"
        confirmText="產出"
        onCancel={onClose}
        onConfirm={onSubmit}
        confirmButtonProps={{
          disabled: isConfirmDisabled,
        }}
      />
    </Modal>
  );
}
