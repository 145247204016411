import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Message } from '@mezzanine-ui/react';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import { API_NAMESPACE, request } from '@solar/data';
import { PageLayout } from '@solar/templates';
import { Space } from 'antd';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const schema = yup.object().shape({
  shipId: yup.string().required('必填'),
});

interface FormValues {
  shipId: string;
}

export function ShippedRecordsPage() {
  const methods = useForm<FormValues>({
    defaultValues: { shipId: undefined },
    resolver: yupResolver(schema),
  });

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = methods.handleSubmit(async (data) => {
    setSubmitting(true);

    try {
      await request(`/rollbacks/ship/${data.shipId}`, {
        method: 'POST',
        namespace: API_NAMESPACE.PP,
      });

      Message.success(`出貨單（${data.shipId}）迴轉成功`);
    } catch (error: any) {
      Message.error(
        `出貨單（${data.shipId}）迴轉失敗: ${
          JSON.parse(error.message)?.message
        }`
      );
    } finally {
      setSubmitting(false);
    }
  });

  return (
    <PageLayout title="出貨單迴轉">
      <FormFieldsWrapper methods={methods}>
        <Space>
          <InputField registerName="shipId" placeholder="請輸入出貨單號" />
          <Button type="button" loading={submitting} onClick={onSubmit}>
            確認按鈕
          </Button>
        </Space>
      </FormFieldsWrapper>
    </PageLayout>
  );
}
