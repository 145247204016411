import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { MaterialCategory } from './material-categories.type';

const namespace = API_NAMESPACE.MATERIALS;

export function useMaterialCategories() {
  const { data, error } = useSWR<MaterialCategory[]>([
    '/materials/categories',
    namespace,
  ]);

  return {
    materialCategories: data ?? [],
    isLoading: !error && !data,
  };
}
