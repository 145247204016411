import { useMemo } from 'react';
import { WorkOrderInto } from './use-work-order-info';
import { SelectValue } from '@mezzanine-ui/react';

export const workCenterIdProcessingIdsMap = new Map<string, SelectValue[]>([
  ['ZFCNG01', [{ id: 'HA', name: '（HA）人工操作紀錄' }]],
  ['ZFOSC01', [{ id: 'OD', name: '（OD）委外機加工' }]],
  ['ZFUTC01', [{ id: 'QU', name: '（QU）UT探傷' }]],
  ['ZTANA01', [{ id: 'QC', name: '（QC）QC製程中檢驗' }]],
  ['ZTCQC01', [{ id: 'QC', name: '（QC）QC製程中檢驗' }]],
  ['ZTENG01', [{ id: 'EC', name: '（EC）打印' }]],
  [
    'ZTFQC01',
    [
      { id: 'QC', name: '（QC）QC製程中檢驗' },
      { id: 'QF', name: '（QF）FQC製程最終檢驗' },
      { id: 'QO', name: '（QO）OQC出貨檢驗' },
    ],
  ],
  [
    'ZTOQC01',
    [
      { id: 'EU', name: '（EU）取樣' },
      { id: 'QC', name: '（QC）QC製程中檢驗' },
      { id: 'QO', name: '（QO）OQC出貨檢驗' },
    ],
  ],
  ['ZTOSC01', [{ id: 'OA', name: '（OA）委外切割' }]],
  ['ZTOSC02', [{ id: 'OB', name: '（OB）委外熱處理' }]],
  ['ZTOSC03', [{ id: 'OC', name: '（OC）委外成形' }]],
  ['ZTOSC05', [{ id: 'OE', name: '（OE）委外組合' }]],
  [
    'ZTPTF01',
    [
      { id: 'QC', name: '（QC）QC製程中檢驗' },
      { id: 'QP', name: '（QP）PPTF' },
    ],
  ],
  ['ZTQCC01', [{ id: 'QC', name: '（QC）QC製程中檢驗' }]],
  [
    'ZTSAM01',
    [
      { id: 'EU', name: '（EU）取樣' },
      { id: 'GZ', name: '（GZ）PGM製樣' },
    ],
  ],
  ['ZTUCS01', [{ id: 'EN', name: '（EN）UCSM' }]],
  [
    'ZTUTC01',
    [
      { id: 'QF', name: '（QF）FQC製程最終檢驗' },
      { id: 'QU', name: '（QU）UT探傷' },
    ],
  ],
  ['ZTUTC02', [{ id: 'QU', name: '（QU）UT探傷' }]],
]);

export function useProcessingOptions(
  workCenterId?: string | null,
  workOrder?: WorkOrderInto
) {
  return useMemo(() => {
    const defaultProcessingIds = workCenterId
      ? workCenterIdProcessingIdsMap.get(workCenterId) ?? []
      : [];
    return (
      workOrder?.workOrder?.operationCodes
        ?.map((code) => ({
          id: code.processingId,
          name: `（${code.processingId}）${code.processingName}`,
        }))
        ?.concat(defaultProcessingIds) ?? []
    );
  }, [workCenterId, workOrder]);
}
