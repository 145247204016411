import { Message } from '@mezzanine-ui/react';
import {
  CalculateSuggestedMaterialAllocationsResult,
  MaterialAllocationFormValues,
  calculateSuggestedMaterialAllocations,
} from '@solar/data';
import { notification } from 'antd';
import { useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

export function useGetSuggestedMaterialAllocations({
  methods,
  materialId,
  getTargetWeightByUnit,
}: {
  methods: UseFormReturn<MaterialAllocationFormValues>;
  materialId: string;
  getTargetWeightByUnit: (
    targetWeight: MaterialAllocationFormValues['targetWeight']
  ) => MaterialAllocationFormValues['targetWeight'];
}) {
  const [suggesting, setSuggesting] = useState<boolean>(false);
  const [suggestedData, setSuggestedData] = useState<
    CalculateSuggestedMaterialAllocationsResult['data']['records']
  >([]);

  const getSuggestedMaterialAllocations = useCallback(
    async (payload: {
      stockQueryFactoryId: string;
      stockQueryAreaIds: string[];
    }) => {
      try {
        setSuggesting(true);

        if (materialId) {
          const formState = methods?.getValues();
          const response = await calculateSuggestedMaterialAllocations(
            materialId,
            Object.assign(
              {
                calculationInput: {
                  manualData:
                    formState?.manualData?.map((item) => ({
                      ...item,
                      usedOldMaterial: item?.usedOldMaterial === 'true',
                    })) ?? [],
                  targetWeight: getTargetWeightByUnit(
                    formState?.targetWeight ?? 0
                  ),
                },
              },
              payload
            )
          );

          if (response?.data?.hasError) {
            notification.error({
              message: '計算錯誤',
              description: (
                <ul>
                  {response?.data?.errorRecords?.map((error: any) => (
                    <li>
                      {`${error?.componentItemNumber}: ${(() => {
                        switch (error?.componentCodeError?.ErrorMessage) {
                          case 'StockNotFound':
                            return '查無庫存';
                          default:
                            return '未知錯誤';
                        }
                      })()}`}
                    </li>
                  ))}
                </ul>
              ),
              style: { width: '500px' },
              duration: 500,
            });
          } else {
            setSuggestedData(response?.data?.records);
          }
        }
      } catch (error) {
        Message.error('推薦請求錯誤');
      } finally {
        setSuggesting(false);
      }
    },
    [materialId, methods, getTargetWeightByUnit]
  );

  return {
    suggestedData,
    suggesting,
    getSuggestedMaterialAllocations,
  };
}
