import { Button, Message, Table } from '@mezzanine-ui/react';
import { checkJWTRoles, ERFCState, useShipmentPackingList } from '@solar/data';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  AutoComplete,
  CalendarRanger,
  SearchInput,
} from '../../OrderManagement/FilterSearch/enhanceFilter/enhanceFilter';
import { SubmitButtonType } from '../Layout/Filter/Filter';
import { Layout } from '../Layout/Layout';
import styles from './ShipmentPackingPage.module.scss';

export function ShipmentPackingPage() {
  const layoutMethods = useForm();

  const {
    rfcState,
    setRfcState,
    data: shipmentPackingPageData,
    total,
    filterData: shipmentFilterData,
    handleUpdateRfc,
    handleSearchFilter,
    queryParams,
    handleChangePage,
    handleChangePerPage,
    resetQueryParams,
  } = useShipmentPackingList();

  const filtersData = useMemo(
    () => [
      {
        label: '出貨單編號',
        fn: SearchInput,
        registerName: 'deliveryOrderName',
        props: {
          width: 160,
          clearable: true,
          placeholder: '請輸入',
          className: styles.input,
          name: 'deliveryOrderName',
          register: layoutMethods.register,
          setValue: layoutMethods.setValue,
        },
      },
      {
        label: '客戶代號',
        fn: AutoComplete,
        registerName: 'customerName',
        props: {
          mode: 'single',
          clearable: true,
          placeholder: '請選擇或輸入',
          options:
            shipmentFilterData?.customers?.map(
              (customer: { id: string; name: string }) => ({
                id: customer.id,
                name: `${customer.name} (${customer.id})` ?? '',
              })
            ) ?? [],
          getValues: layoutMethods.getValues,
        },
      },
      {
        label: '訂單日期',
        fn: CalendarRanger,
        registerName: 'orderDate',
        isDate: true,
        props: {
          width: 300,
          clearable: true,
          placeholder: 'yyyy/mm/dd',
        },
      },
    ],
    [layoutMethods, shipmentFilterData]
  );

  const registers = useMemo(() => {
    layoutMethods.watch();
    return filtersData.map((data) => ({
      registerName: data.registerName,
      value: layoutMethods.getValues(data.registerName),
    }));
  }, [layoutMethods.watch(), filtersData]);

  const registerDependencies = useMemo(
    () => registers.map((register) => register.value),
    [registers]
  );

  const filterButton = useMemo(
    () => ({
      name: '清空條件',
      onClick() {
        filtersData.forEach((filter) => {
          layoutMethods.setValue(filter.registerName, undefined);
        });
        resetQueryParams();
      },
    }),
    [resetQueryParams, filtersData, layoutMethods.setValue]
  ) as SubmitButtonType;

  const columns = useMemo(
    () => [
      { title: '出貨單編號', dataIndex: 'id', width: 170 },
      { title: '出貨單日期', dataIndex: 'shipDate', width: 150 },
      { title: '客戶名稱', dataIndex: 'customerName' },
      { title: '建立者', dataIndex: 'memberName' },
      {
        title: '動作',
        align: 'center' as const,
        render(source: any) {
          return (
            checkJWTRoles([74]) && (
              <div className={styles.actionWrapper}>
                <Link
                  to={`/sales-distribution/shipment/${source.id}/box-merge`}
                  className={!source.isPacked ? styles['link-disabled'] : ''}
                  aria-disabled={!source.isPacked}
                  tabIndex={!source.isPacked ? -1 : undefined}
                >
                  <Button
                    type="button"
                    variant="outlined"
                    disabled={!source.isPacked}
                  >
                    併箱
                  </Button>
                </Link>
                <Link to={`/sales-distribution/shipment/${source.id}`}>
                  <Button type="button" variant="outlined">
                    Packing
                  </Button>
                </Link>
                <a
                  target="_blank"
                  href={`/sales-distribution/shipment/${source.id}/pdfPrint`}
                >
                  <Button type="button" variant="outlined">
                    Print
                  </Button>
                </a>
              </div>
            )
          );
        },
      },
    ],
    []
  );

  return (
    <div className={styles.wrapper}>
      <Layout
        title="出貨單包裝"
        filterHeader="查詢"
        button={
          <Button
            style={{
              display: 'none',
            }}
            type="button"
            variant="contained"
            disabled={rfcState === ERFCState.BUSY}
            onClick={() => {
              setRfcState(ERFCState.BUSY);
              Message['info']('更新中，請稍候');
              handleUpdateRfc();
            }}
          >
            更新 RFC
          </Button>
        }
        filtersData={filtersData}
        filterMethods={handleSearchFilter}
        filterDependencies={registerDependencies}
        registers={registers}
        submitButton={filterButton}
        methods={layoutMethods}
      >
        <Table
          columns={columns}
          dataSource={shipmentPackingPageData ?? []}
          bodyClassName={styles.table}
          headerClassName={styles.tableHeader}
          pagination={{
            disableAutoSlicing: true,
            total,
            current: queryParams['page'],
            onChange: handleChangePage,
            options: {
              pageSize: queryParams['perPage'],
              onChangePageSize: handleChangePerPage,
              showPageSizeOptions: true,
              pageSizeLabel: '每頁顯示：',
              pageSizeUnit: '筆',
            },
          }}
        />
      </Layout>
    </div>
  );
}
