import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@mezzanine-ui/react';
import { Modals } from './Modal.enum';
import { useTargetModal } from '@solar/templates';
import { useForm } from 'react-hook-form';
import {
  CheckboxField,
  FormFieldsWrapper,
  InputField,
  RadioGroupField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { useEffect, useState } from 'react';
import { Col, Descriptions, Divider, Row, Table, message } from 'antd';
import { saveMeltFactor, useGetMeltFactor } from '@solar/data';

type FixMeltingFactorModalProps = {
  materialId: string;
  meltFactor: ReturnType<typeof useGetMeltFactor>['meltFactor'];
  onAfterSave: () => Promise<void>;
};

export function FixMeltingFactorModal({
  meltFactor,
  onAfterSave,
  materialId,
}: FixMeltingFactorModalProps) {
  const [isSaving, setIsSaving] = useState(false);
  const methods = useForm();
  const { open, data, closeModal } = useTargetModal<{}>(
    Modals.FIX_MELTING_FACTOR
  );

  useEffect(() => {
    if (open) {
      const useOldMaterialLimit =
        (meltFactor?.oldMaterialLimit ?? null) !== null;
      methods.reset({
        id: meltFactor?.id,
        factorType: meltFactor?.factorType,
        useOldMaterialLimit,
        oldMaterialLimit: useOldMaterialLimit
          ? meltFactor?.oldMaterialLimit
          : null,
        decimalLength: meltFactor?.decimalLength,
        meltFactorItems:
          meltFactor?.meltFactorItems?.map((item) => ({
            ...item,
            oldMaterialMeltFactor: item?.oldMaterialMeltFactor || '0',
            newMaterialMeltFactor: item?.newMaterialMeltFactor || '0',
            fixedMeltFactor: item?.fixedMeltFactor || '0',
          })) ?? [],
      });
    } else {
      methods.reset({});
    }
  }, [open, data, meltFactor]);

  const meltFactorItems = methods.watch('meltFactorItems') ?? [];
  const useOldMaterialLimit = methods.watch('useOldMaterialLimit');

  const onClose = () => {
    closeModal();
  };

  const onSave = async () => {
    try {
      setIsSaving(true);
      const formState = methods.getValues();

      await saveMeltFactor(materialId, {
        ...formState,
        decimalLength: Number(formState?.decimalLength) || null,
        oldMaterialLimit: formState?.oldMaterialLimit || null,
      });
      await onAfterSave();
      message.success('儲存成功');
      closeModal();
    } catch (e) {
      console.log(e);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal style={{ width: '1200px' }} open={open} onClose={onClose}>
      <ModalHeader>修正熔煉因子</ModalHeader>
      <ModalBody>
        <FormFieldsWrapper methods={methods}>
          <Descriptions column={3} bordered labelStyle={{ width: '160px' }}>
            <Descriptions.Item label="工廠" span={3}>
              {''}
            </Descriptions.Item>
            <Descriptions.Item label="料號" span={1}>
              {meltFactor?.materialID}
            </Descriptions.Item>
            <Descriptions.Item label="料號描述" span={2}>
              {''}
            </Descriptions.Item>
            <Descriptions.Item label="成份代碼">
              {meltFactor?.componentCode}
            </Descriptions.Item>
            <Descriptions.Item label="修正熔煉參考值" span={2}>
              <RadioGroupField
                registerName="factorType"
                options={[
                  { label: 'At%', value: 'At%' },
                  { label: 'Wt%', value: 'Wt%' },
                ]}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <Row align="middle">
                  <CheckboxField
                    registerName="useOldMaterialLimit"
                    onChange={(e) => {
                      const checked = e.target.checked;

                      if (!checked) methods.setValue('oldMaterialLimit', null);
                    }}
                  />
                  舊料使用上限
                </Row>
              }
            >
              <InputField
                registerName="oldMaterialLimit"
                disabled={!useOldMaterialLimit}
                suffix="%"
              />
            </Descriptions.Item>
            <Descriptions.Item label="小數位數設定">
              <InputField registerName="decimalLength" />
            </Descriptions.Item>
          </Descriptions>
          <Divider orientation="left">熔煉損耗/理論實際差異補償</Divider>
          <Table
            pagination={false}
            dataSource={meltFactorItems}
            columns={[
              {
                title: '元素料號',
                dataIndex: 'componentItemNumber',
              },
              {
                title: '理論值(Wt%)',
                dataIndex: 'wtRate',
              },
              {
                title: '原子比(At%)',
                dataIndex: 'atRate',
              },
              {
                title: '舊料熔煉修正因子(%)',
                render: (value, record, index) => (
                  <InputField
                    clearable={false}
                    registerName={`meltFactorItems.${index}.oldMaterialMeltFactor`}
                  />
                ),
              },
              {
                title: '新料熔煉修正因子(%)',
                render: (value, record, index) => (
                  <InputField
                    clearable={false}
                    registerName={`meltFactorItems.${index}.newMaterialMeltFactor`}
                  />
                ),
              },
              {
                title: '固定熔煉修正因子(%)',
                render: (value, record, index) => (
                  <InputField
                    clearable={false}
                    registerName={`meltFactorItems.${index}.fixedMeltFactor`}
                  />
                ),
              },
              {
                title: '次序',
                dataIndex: 'componentItemSeq',
              },
              {
                title: '理論實際差異補償',
                dataIndex: 'theoreticalActualDiffCompensation',
              },
            ]}
          />
        </FormFieldsWrapper>
      </ModalBody>
      <ModalFooter>
        <Row style={{ width: '100%' }} justify="end" gutter={[12, 12]}>
          <Col>
            <Button onClick={onClose}>取消</Button>
          </Col>
          <Col>
            <Button loading={isSaving} variant="contained" onClick={onSave}>
              儲存
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
}
