import { TableColumn } from '@mezzanine-ui/core/table';
import { Button, Message } from '@mezzanine-ui/react';
import {
  SOURCE_TYPE_TRANSLATION,
  checkJWTRoles,
  revokeMaterialRequestOrder,
} from '@solar/data';
import { useModalGroupController } from '@solar/templates';
import moment from 'moment';
import {
  DispatchMaterialViewModalProps,
  DispatchOrderPanelTableProps,
  Modals,
} from '../../WorkOrderManagementPage/types';
import { useState } from 'react';
import { KeyedMutator } from 'swr';

export const useDispatchOrdersColumn = ({
  modalGroupController,
  mutate,
}: {
  modalGroupController: ReturnType<typeof useModalGroupController>;
  mutate: KeyedMutator<any>;
}) => {
  const [revoking, setRevoking] = useState(false);

  const columns: TableColumn<DispatchOrderPanelTableProps>[] = [
    {
      title: '操作',
      render: (source) => {
        return (
          <div>
            <Button
              type="button"
              onClick={() => {
                modalGroupController.openModal(Modals.DISPATCH_MATERIAL_VIEW, {
                  orderId: source['id'],
                });
              }}
            >
              檢視
            </Button>
            {/* <Button
              type="button"
              onClick={() => {
                if (source?.id) {
                  modalGroupController.openModal<DispatchMaterialViewModalProps>(
                    Modals.DISPATCH_SETTING,
                    {
                      dispatchPreviewTable: null,
                      materialId: null,
                      orderId: source?.id,
                      recipeId: source?.recipeId ?? null,
                    }
                  );
                }
              }}
            >
              編輯
            </Button> */}
            {checkJWTRoles([85, 116]) && (
              <Button
                danger
                type="button"
                loading={revoking}
                onClick={async () => {
                  try {
                    setRevoking(true);
                    const res = await revokeMaterialRequestOrder(source['id']);

                    if (res.ok) {
                      Message.success('作廢成功');
                    } else {
                      throw new Error('作廢失敗');
                    }

                    await mutate(undefined, true);
                  } catch (error) {
                    Message.error('作廢失敗');
                  } finally {
                    setRevoking(false);
                  }
                }}
              >
                作廢
              </Button>
            )}
          </div>
        );
      },
    },
    { title: '狀態', dataIndex: 'state' },
    { title: '發料單號', dataIndex: 'id' },
    {
      title: '建立來源',
      render: (source) => SOURCE_TYPE_TRANSLATION[source['sourceType']],
    },
    {
      title: '建立日期',
      render: (source) =>
        source?.createdAt ? moment(source?.createdAt).format('YYYY-MM-DD') : '',
    },

    { title: '工廠', dataIndex: 'factoryId' },
    { title: '工單類形', dataIndex: 'orderType' },
    { title: '工單單號', dataIndex: 'workOrderId' },
    // {
    //   title: '預期發料日期',
    //   dataIndex: 'expectedCompletedAt',
    //   render: (source) =>
    //     source?.expectedCompletedAt
    //       ? moment(source?.expectedCompletedAt).format('YYYY-MM-DD')
    //       : '',
    // },

    // { title: '領用人', dataIndex: 'recipientId' },
  ];

  return columns;
};
