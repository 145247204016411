import { TableColumn } from '@mezzanine-ui/core/table';
import { Button, Message, SelectValue } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  getNextParams,
  MaterialRequestOrderSourceType,
  NextPagination,
  SORT_TYPE_SELECTIONS,
  SOURCE_TYPE_TRANSLATION,
  useMaterialRequestOrders,
  WorkOrdersRequestParams,
  WORK_ORDERS_STATES,
  revokeMaterialRequestOrder,
  checkJWTRoles,
} from '@solar/data';
import {
  FilterScaffold,
  ModalGroup,
  MznPaginationTable,
  useModalGroupController,
} from '@solar/templates';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { DispatchOrderPanelFilter } from './DispatchOrderPanelFilter';
import { DispatchMaterialViewModal } from './modal/DispatchMaterialViewModal';
import { DispatchSettingModal } from './modal/DispatchSettingModal';
import {
  DispatchMaterialViewModalProps,
  DispatchOrderPanelTableProps,
  Modals,
} from './types';

type FormProps = Omit<
  WorkOrdersRequestParams,
  | 'createdAtDateFrom'
  | 'createdAtDateTo'
  | 'expectedCompletedAtDateFrom'
  | 'expectedCompletedAtDateTo'
  | 'factoryId'
  | 'orderTypeCode'
  | 'sortType'
  | 'states'
> & {
  createdDateRange: [string, string];
  expectedCompletedDateRange: [string, string];
  factory: SelectValue;
  orderType: SelectValue;
  sortType: SelectValue;
  sourceType: SelectValue[];
  states: SelectValue[];
};

export function DispatchOrderPanel() {
  const { orderId } = useParams<{ orderId: string }>();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const [revoking, setRevoking] = useState(false);

  const methods = useForm<FormProps>({
    defaultValues: {
      sortType: SORT_TYPE_SELECTIONS[0],
    },
  });

  const { orders, mutateParams, pageInfo, isLoading, mutate } =
    useMaterialRequestOrders({
      defaultParams: {
        workOrderIds: orderId ? [orderId] : undefined,
        sortType: SORT_TYPE_SELECTIONS[0].id,
      },
    });

  const modalGroupController = useModalGroupController([
    { name: Modals.DISPATCH_MATERIAL_VIEW },
    { name: Modals.DISPATCH_SETTING },
  ]);

  const refetchProductionDeliveryOrders = useCallback(
    (nextPagination: NextPagination) => {
      const filterState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);

      mutateParams({
        offset,
        limit,
        workOrderIds: orderId ? [orderId] : undefined,
        searchTerm: filterState?.searchTerm,
        expectedCompletedAtDateFrom: (filterState.createdDateRange ?? [])[0],
        expectedCompletedAtDateTo: (filterState.createdDateRange ?? [])[1],
        sourceTypes: filterState?.sourceType?.map(
          (type) => type.id as `${MaterialRequestOrderSourceType}`
        ),
        sortType: filterState?.sortType
          ?.id as WorkOrdersRequestParams['sortType'],
        states: filterState?.states?.map(
          (type) => type.id as keyof typeof WORK_ORDERS_STATES
        ),
      });
    },
    [methods, orderId]
  );

  // useEffect(() => {
  //   mutate();
  // }, [tab]);

  const columns: TableColumn<DispatchOrderPanelTableProps>[] = [
    {
      title: '操作',
      render: (source) => {
        return (
          <div>
            <Button
              type="button"
              onClick={() => {
                modalGroupController.openModal(Modals.DISPATCH_MATERIAL_VIEW, {
                  orderId: source['id'],
                });
              }}
            >
              檢視
            </Button>
            {/* <Button
              type="button"
              onClick={() => {
                if (source?.id) {
                  modalGroupController.openModal<DispatchMaterialViewModalProps>(
                    Modals.DISPATCH_SETTING,
                    {
                      dispatchPreviewTable: null,
                      materialId: null,
                      orderId: source?.id,
                      recipeId: source?.recipeId,
                    }
                  );
                }
              }}
            >
              編輯
            </Button> */}
            {checkJWTRoles([85, 116]) && (
              <Button
                danger
                type="button"
                loading={revoking}
                onClick={async () => {
                  try {
                    setRevoking(true);
                    const res = await revokeMaterialRequestOrder(source['id']);

                    if (res.ok) {
                      Message.success('作廢成功');
                    } else {
                      throw new Error('作廢失敗');
                    }

                    await mutate(undefined, true);
                  } catch (error) {
                    Message.error('作廢失敗');
                  } finally {
                    setRevoking(false);
                  }
                }}
              >
                作廢
              </Button>
            )}
          </div>
        );
      },
    },
    {
      title: '預期發料日期',
      render: (source) =>
        source?.expectedCompletedAt
          ? moment(source?.expectedCompletedAt).format('YYYY-MM-DD')
          : '',
    },
    { title: '發料單號', dataIndex: 'id' },
    // { title: '批號', dataIndex: 'batchId' }, // SOL-1289
    {
      title: '建立來源',
      render: (source) => SOURCE_TYPE_TRANSLATION[source['sourceType']],
    },
    { title: '狀態', dataIndex: 'state' },
    { title: '領用人', dataIndex: 'recipientId' },
  ];

  return (
    <>
      <FormFieldsWrapper methods={methods}>
        <FilterScaffold
          mutateParams={refetchProductionDeliveryOrders}
          fetchAsParamsMutated={false}
        >
          <DispatchOrderPanelFilter />
          <Button
            type="button"
            variant="outlined"
            onClick={() => refetchProductionDeliveryOrders({ nextPage: 1 })}
          >
            確認
          </Button>
        </FilterScaffold>
        <br />
      </FormFieldsWrapper>
      <MznPaginationTable
        loading={isLoading}
        scroll={{ x: 2300 }}
        columns={columns}
        dataSource={orders ?? []}
        fetchData={refetchProductionDeliveryOrders}
        pageInfo={pageInfo}
      />
      <ModalGroup {...modalGroupController}>
        <DispatchMaterialViewModal />
        <DispatchSettingModal />
      </ModalGroup>
    </>
  );
}
