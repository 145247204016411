import {
  Button,
  Empty,
  Loading,
  Message,
  Table,
  Typography,
} from '@mezzanine-ui/react';
import {
  DatePickerField,
  FormFieldsWrapper,
  InputField,
} from '@mezzanine-ui/react-hook-form';
import {
  ProductionForms,
  RowSection,
  TableForFormFieldArray,
  useGetProductionForms,
  useGetProductionFormsAnswers,
} from '@solar/templates';
import styles from './in-station-panel.module.scss';
import {
  CheckInFormFields,
  checkInFormDefaultValues,
  getMatchId,
  useCheckInListColumns,
} from '../hooks/use-in-station-list-columns';
import { useWorkOrderInfo } from '../hooks/use-work-order-info';
import { UseFormReturn, useFieldArray, useWatch } from 'react-hook-form';
import {
  KeyboardEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  checkInMaterialsInEquipment,
  useEquipmentMaterials,
} from '../hooks/use-equipment-materials';
import { SiteInfoContext } from '../const';
import { useProductionParameters } from '../hooks/use-production-parameters';
import { ProductionParameters } from '../../ProductionForm/ProductionParameters';
import { MaterialRequestOrderTable } from './MaterialRequestOrderTable';
import { BarcodeScanInput } from '../../../Material/MaterialShift/BarcodeScanInput';
import {
  API_NAMESPACE,
  ProductionFormCondition,
  getMaterialInfo,
} from '@solar/data';
import Decimal from 'decimal.js';
import useSWR from 'swr';

export function InStationPanel({
  methods,
}: {
  methods: UseFormReturn<CheckInFormFields>;
}) {
  const [isCheckIn, setIsCheckIn] = useState(false);

  const siteInfo = useContext(SiteInfoContext);

  const { mutateGetEquipmentMaterials } = useEquipmentMaterials(
    siteInfo?.equipmentId ?? null
  );

  const materialCheckInListMethods = useFieldArray({
    control: methods.control,
    name: 'materialCheckInList',
  });

  // const materialCheckInList = methods.watch('materialCheckInList');
  // const materialCheckInList = materialCheckInListMethods?.fields;

  // console.log('watch: ', methods.watch('materialCheckInList'));
  // console.log('field: ', materialCheckInListMethods?.fields);

  const queryWorkOrderId = useWatch({
    name: 'materialRequestOrderId',
    control: methods.control,
  });

  const { workOrder, isLoading } = useWorkOrderInfo(queryWorkOrderId);

  const { data: qcTriggers } = useSWR<
    {
      actionName: string;
      workCenterName: string;
      qcType: 'FQC';
    }[]
  >([
    '/qc/triggers',
    {
      namespace: API_NAMESPACE.QC,
      params: { qcTypes: 'FQC' },
    },
  ]);

  const isFQC = useMemo(
    () =>
      qcTriggers?.some(
        (trigger) => trigger.workCenterName === siteInfo.workCenterId
      ),
    [qcTriggers, siteInfo]
  );

  useEffect(() => {
    if (!isFQC) return;

    const mainProduct = workOrder?.workOrder?.productionWorkOrderSpecs?.find(
      (spec) => spec.type === 'EXPECTED_MAIN_PRODUCT'
    );

    if (!mainProduct) return;

    methods.setValue('salesOrderId', mainProduct?.salesOrderId ?? null);
    methods.setValue('salesOrderLine', mainProduct?.salesOrderLineId ?? null);
  }, [workOrder, methods, isFQC]);

  const onWorkOrderQuery = useCallback<KeyboardEventHandler<HTMLDivElement>>(
    (event) => {
      if (event?.code === 'Enter') {
        methods.setValue('materialBarcode', '');
        methods.setValue('materialCheckInList', []);
      }
    },
    [methods]
  );

  /**
   * @description:
   * 當 materialRequestOrderSpecs
   * - 為 null 時，此發料單不指定料號，check in 不檢查入站物料
   * - 為陣列時，此發料單指定料號，check in 檢查入站物料批號是否正確
   * - 為 undefined 時，為無效發料單，不給輸入 barcode
   */
  const workOrderBatches = workOrder?.materialRequestOrderBatches;
  const workOrderSpecs = workOrder?.materialRequestOrderSpecs;
  const invalidOrder = workOrderSpecs === undefined;
  const noOrderSpecs = workOrderSpecs === null;
  const hasOrderSpecs = Array.isArray(workOrderSpecs);

  const requestMaterialBatchIds = materialCheckInListMethods?.fields?.map(
    (item) => item?.inputMaterialBarcode
  );
  // const requestMaterialBatchIds = methods.watch(
  //   materialCheckInList?.map(
  //     (item, index) =>
  //       `materialCheckInList.${index}.inputMaterialBarcode` as const
  //   ) ?? []
  // );

  const onBarcodeQuery = useCallback(
    async (enteredValue?: string) => {
      if (invalidOrder) {
        Message.error('無效發料單');
        return;
      }

      const enteredBarcode = enteredValue?.trim();

      if (!enteredBarcode) {
        Message.error('無效批號條碼');
        return;
      }

      const info = await getMaterialInfo(enteredBarcode).catch(() => {});

      if (!info) {
        Message.error('無此批號條碼的基本資訊');
        return;
      }

      if (hasOrderSpecs) {
        const matchBarcode = enteredBarcode?.split('_')?.slice(0, 3)?.join('_');

        const matchedSpec = workOrderSpecs?.find(
          (spec) => getMatchId(spec) === matchBarcode
        );

        const matchedBatch = workOrderBatches?.find(
          (batch) => getMatchId(batch) === matchBarcode
        );

        if (!matchedSpec && !matchedBatch) {
          Message.error('此批號條碼不存在於預期產出');
          return;
        }

        materialCheckInListMethods?.append({
          inputMaterialBarcode: enteredBarcode,
          inputMaterialQuantity:
            new Decimal(matchedSpec?.expectedMaterialStockUnitQuantity ?? '0')
              .div(info?.stockOverInputUnitRatio ?? '1')
              .toString() ?? '',
          inputMaterialStockUnit: info.materialStockUnit,
          inputMaterialInputUnit: info.materialInputUnit,
          stockOverInputUnitRatio: info.stockOverInputUnitRatio,
          selectedUnit: {
            id: 'inputMaterialInputUnit',
            name: info.materialInputUnit,
          },
        });

        methods.setValue('displayFormsByMaterialBarcode', enteredBarcode);

        return;
      }

      if (noOrderSpecs) {
        materialCheckInListMethods?.append({
          inputMaterialBarcode: enteredBarcode,
          inputMaterialQuantity: '',
          inputMaterialStockUnit: info.materialStockUnit,
          inputMaterialInputUnit: info.materialInputUnit,
          stockOverInputUnitRatio: info.stockOverInputUnitRatio,
          selectedUnit: {
            id: 'inputMaterialInputUnit',
            name: info.materialInputUnit,
          },
        });

        methods.setValue('displayFormsByMaterialBarcode', enteredBarcode);

        return;
      }
    },
    [materialCheckInListMethods, workOrder]
  );

  const columns = useCheckInListColumns(materialCheckInListMethods, methods);

  const displayFormsByMaterialBarcode = useWatch({
    name: 'displayFormsByMaterialBarcode',
    control: methods.control,
  });

  const productionFormQueryConditions = useMemo<ProductionFormCondition[]>(
    () =>
      displayFormsByMaterialBarcode &&
      workOrder?.recipeId &&
      siteInfo?.equipmentId
        ? [
            {
              materialId: displayFormsByMaterialBarcode?.split('_')?.[0],
              recipeId: workOrder?.recipeId ?? undefined,
              processingId: undefined,
              workCenterId: siteInfo.workCenterId ?? undefined,
              equipmentId: siteInfo.equipmentId ?? undefined,
            },
          ]
        : [],
    [workOrder, siteInfo, displayFormsByMaterialBarcode]
  );

  // const productionFormQueryConditions = useMemo<ProductionFormCondition[]>(
  //   () =>
  //     workOrder?.materialRequestOrderBatches?.map(({ materialId }) => ({
  //       materialId,
  //       recipeId: workOrder?.recipeId ?? undefined,
  //       processingId: siteInfo.processingId ?? undefined,
  //       workCenterId: siteInfo.workCenterId ?? undefined,
  //       equipmentId: siteInfo.equipmentId ?? undefined,
  //     })) ?? [],
  //   [workOrder, siteInfo]
  // );

  const { forms, formsMap, formsLoading, isFormRendered, onFormRender } =
    useGetProductionForms(productionFormQueryConditions);
  const parameters = useProductionParameters(productionFormQueryConditions);

  const material = useMemo(() => {
    return displayFormsByMaterialBarcode?.split('_')?.slice(0, 3)?.join('_');
  }, [displayFormsByMaterialBarcode]);

  const productionFormAnswersQueryConditions = useMemo(() => {
    const [materialId, materialSapBatchId, materialSubBatchId] = (
      displayFormsByMaterialBarcode ?? ''
    ).split('_');
    return [
      {
        materialId,
        materialSapBatchId,
        materialSubBatchId,
      },
    ];
  }, [displayFormsByMaterialBarcode]);

  const resetForm = useCallback(() => {
    methods?.reset(checkInFormDefaultValues);
  }, []);

  const { formAnswersLoading } = useGetProductionFormsAnswers({
    methods,
    formsMap,
    registerName: 'forms',
    queryConditions: productionFormAnswersQueryConditions,
    isFormRendered,
  });

  return (
    <FormFieldsWrapper methods={methods} className={styles.panelContainer}>
      <RowSection label="發料單號：">
        <InputField
          onKeyUp={onWorkOrderQuery}
          registerName="materialRequestOrderId"
        />
      </RowSection>
      {isFQC ? (
        <>
          <RowSection label="訂單編號：">
            <InputField registerName="salesOrderId" />
          </RowSection>
          <RowSection label="訂單項次：">
            <InputField registerName="salesOrderLine" />
          </RowSection>
        </>
      ) : null}
      {/* <RowSection label="行為碼：">
        <SelectField registerName="processingId" options={processingOptions} />
      </RowSection> */}
      <MaterialRequestOrderTable queryWorkOrderId={queryWorkOrderId} />
      <RowSection label="物料條碼：">
        <BarcodeScanInput
          disableSearchBtn
          disabled={invalidOrder}
          inputStyle={styles.scanInput}
          otherOnKeyDownAction={onBarcodeQuery}
        />
      </RowSection>
      <RowSection label="入站時間：">
        <DatePickerField
          required
          registerName="createdAt"
          format="yyyy/MM/DD HH:mm:ss"
        />
      </RowSection>
      <div className={styles.tableContainer}>
        <header className={styles.tableCaption}>
          <Typography variant="h4">準備入站的物料</Typography>
          <Button
            type="button"
            loading={isCheckIn || formsLoading || formAnswersLoading}
            variant="contained"
            // disabled={!requestMaterialBatchIds?.length}
            onClick={async () => {
              try {
                setIsCheckIn(true);
                const formState = methods?.getValues();
                const equipmentId = siteInfo?.equipmentId;
                const materialRequestOrderId =
                  formState?.materialRequestOrderId;
                if (!equipmentId) {
                  Message.error('請先選擇設備');
                  return;
                }
                if (!materialRequestOrderId) {
                  Message.error('請先選擇發料單號');
                  return;
                }
                if (formState?.materialCheckInList.length) {
                  let hasEmptyQuantity = false;

                  formState?.materialCheckInList.forEach((item, index) => {
                    if (
                      !new Decimal(
                        item?.inputMaterialQuantity ?? '0'
                      ).greaterThan(0)
                    ) {
                      hasEmptyQuantity = true;
                      methods.setError(
                        `materialCheckInList.${index}.inputMaterialQuantity`,
                        {
                          type: 'required',
                          message: '投入數量需大於0',
                        }
                      );
                    }
                  });

                  if (hasEmptyQuantity) {
                    Message.error(
                      '投入數量輸入錯誤，請重新檢查是否填入或大於0'
                    );
                    return;
                  }
                }

                await checkInMaterialsInEquipment({
                  equipmentId,
                  materialRequestOrderId,
                  salesOrderId: formState?.salesOrderId || null,
                  salesOrderLine: formState?.salesOrderLine || null,
                  createdAt: formState?.createdAt,
                  items:
                    formState?.materialCheckInList?.map((item) => ({
                      inputMaterialBarcode: item?.inputMaterialBarcode,
                      inputMaterialStockUnitQuantity: (() => {
                        switch (item?.selectedUnit?.id) {
                          case 'inputMaterialStockUnit':
                            return item?.inputMaterialQuantity;
                          case 'inputMaterialInputUnit':
                            return new Decimal(
                              item?.inputMaterialQuantity ?? '0'
                            )
                              .mul(item?.stockOverInputUnitRatio)
                              .toString();
                          default:
                            return item?.inputMaterialQuantity;
                        }
                      })(),
                      inputMaterialStockUnit: item?.inputMaterialStockUnit,
                      answers: [],
                    })) ?? [],
                });

                setIsCheckIn(false);
                resetForm();
                await mutateGetEquipmentMaterials(undefined, true);
                Message.success('Check In 完成');
              } catch (error: any) {
                Message.error(
                  JSON.parse(error?.message ?? '')?.message ?? '發生錯誤'
                );
              } finally {
                setIsCheckIn(false);
              }
            }}
          >
            Check In
          </Button>
        </header>
        <TableForFormFieldArray
          loading={isLoading}
          columns={columns}
          dataSource={materialCheckInListMethods?.fields ?? []}
        />
      </div>
      <div className={styles.formContainer}>
        <Typography variant="h4">
          題目與生產資訊（批號：{displayFormsByMaterialBarcode}）
        </Typography>
        {forms?.length && material ? (
          <ProductionForms
            disableAllFields
            forms={forms}
            registerName={`forms.${material}`}
            loading={formsLoading || formAnswersLoading}
            onFormRender={onFormRender}
          />
        ) : (
          <Empty title="無題目資料" />
        )}
      </div>
      {parameters?.length ? (
        <div className={styles.formContainer}>
          <Typography variant="h4">生產參數</Typography>
          <ProductionParameters parameters={parameters} />
        </div>
      ) : null}
    </FormFieldsWrapper>
  );
}
