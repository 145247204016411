import { Popper } from '@mezzanine-ui/react';
import { API_NAMESPACE, request } from '@solar/data';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

export function useDrawingPreview(): [
  JSX.Element | null,
  (targetDrawingCode?: string) => (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void,
] {
  const [drawingCode, setDrawingCode] = useState<string | null>(null);
  const [anchorRef, setAnchorRef] = useState<HTMLElement | null>();

  const { data } = useSWR<{ data?: { me?: { id: string } } }>(
    drawingCode ? ['/drawings/componentDrawingRefs', {
      params: { newDrawingCode: drawingCode },
      namespace: API_NAMESPACE.DRAWINGS,
    }] : null
  );

  const fileId = useMemo(() => data?.data?.me?.id ?? null, [data]);
  const [fileLink, setFileLink] = useState<string | null>(null);

  useEffect(() => {
    if (fileId) {
      let cancelled = false;

      request(
        `/drawings/generateDrawingPresignedFileLinks`,
        {
          method: 'post',
          body: JSON.stringify({
            ids: [fileId],
          }),
          responseParser: (res) => res.json(),
          namespace: API_NAMESPACE.DRAWINGS,
        },
      ).then(({ data: { results } }: {
        data: {
          results: {
            id: number;
            presignedFileLink: string;
          }[];
        };
      }) => {
        if (cancelled) return;

        if (results?.length) {
          setFileLink(results[0].presignedFileLink);
        }
      });

      return () => { cancelled = true; }
    }

    return () => {
      void 0;
    };
  }, [fileId]);

  const [page, setPage] = useState<number | null>(1);

  const showPreview = useCallback((targetDrawingCode?: string) => (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (targetDrawingCode) {
      if (e.target) {
        setAnchorRef(e.target as HTMLElement);
      }

      setDrawingCode(targetDrawingCode);
    } else {
      setDrawingCode(null);
    }
  }, []);

  const popper = useMemo(() => drawingCode && fileLink ? (
    <Popper
      anchor={anchorRef}
      options={{
        placement: 'bottom-end',
      }}
      open={Boolean(anchorRef)}
      style={{ border: '1px solid #000', borderRadius: '5px', backgroundColor: '#fff' }}
    >
      <Document
        file={{ url: fileLink }}
        onLoadSuccess={(pdf) => {
          setPage(pdf?.numPages);
        }}
      >
        {Array.from(Array(page), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </Popper>
  ) : null, [anchorRef, drawingCode, fileLink, page]);

  return [popper, showPreview];
}
