import { Equipment } from '../equipments';
import { OffsetBased } from '../pagination';
import {
  RepairRequestPriority,
  RepairRequestStatus,
  RepairRequestTargetFinishedDays,
} from './repairRequest.enum';

export type RepairRequest = {
  id: number;
  priority: RepairRequestPriority;
  targetFinishedDays: RepairRequestTargetFinishedDays;
  remark: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  creator: any;
  equipment: Equipment;
  files: object[];
  logs: RepairRequestLog[];
};

export type RepairRequestLog = {
  id: number;
  priority: RepairRequestPriority;
  status: RepairRequestStatus;
  remark: string;
  menCount: number;
  hoursCount: string;
  sendOutAt: string;
  fixedAt: string;
  predictFulfilledAt: string;
  fulfilledAt: string;
  internalRemark: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  files: any[];
  supplier: {
    id: string;
    name: string;
  };
  members: any[];
};

export type RepairRequestPriorityOption = {
  id: RepairRequestPriority;
  name: string;
};

export type RepairRequestStatusOption = {
  id: RepairRequestStatus;
  name: string;
};

export type RepairRequestTargetFinishedDayOption = {
  id: RepairRequestTargetFinishedDays;
  name: string;
};

export type GetRepairRequestsParams = OffsetBased & {
  requestFrom?: string;
  requestTo?: string;
  priority?: RepairRequestPriority;
  status?: RepairRequestStatus;
  equipmentIds?: string[];
};

export type CreateRepairRequestPayload = {
  equipmentId?: Equipment['id'];
  priority: RepairRequestPriorityOption['id'];
  targetFinishedDays: RepairRequestTargetFinishedDayOption['id'];
  remark?: string;
  files?: any[];
};

export type CreateRepairRequestLogPayload = {
  id: RepairRequest['id'];
  status: RepairRequestStatusOption['id'];
  priority: RepairRequestPriorityOption['id'];
  predict_fulfilled_at: RepairRequestLog['predictFulfilledAt'];
  fulfilled_at?: RepairRequestLog['fulfilledAt'];
  remark?: RepairRequestLog['remark'];
  internalInformation?: {
    men_count?: RepairRequestLog['menCount'];
    member_ids?: string[];
    hours_count?: RepairRequestLog['hoursCount'];
    remark?: RepairRequestLog['internalRemark'];
  };
  externalInformation?: {
    supplier?: RepairRequestLog['supplier']['name'];
    send_out_at?: RepairRequestLog['sendOutAt'];
    fixed_at?: RepairRequestLog['fixedAt'];
    files?: any[];
  };
};
