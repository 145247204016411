import { ReactNode, useRef } from 'react';
import { Button, Icon } from '@mezzanine-ui/react';
import { UploadIcon } from '@mezzanine-ui/icons';

type UploadFileButtonProps = {
  buttonText?: string | ReactNode;
  accept?: string;
  loading?: boolean;
  onUploadCsv: (file: File) => void;
};

export const BatchUpdateButton = ({
  buttonText = '匯入檔案',
  accept = '.csv',
  loading,
  onUploadCsv,
}: UploadFileButtonProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Button
        type="button"
        loading={loading}
        prefix={<Icon icon={UploadIcon} />}
        variant="outlined"
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        {buttonText}
      </Button>

      <input
        ref={inputRef}
        accept={accept}
        multiple={false}
        onClick={(event) => {
          event.currentTarget.value = '';
          event.stopPropagation();
          event.nativeEvent.stopImmediatePropagation();
        }}
        onChange={(event) => {
          const { files } = event.target;

          if (files) {
            const file = files[0];

            onUploadCsv(file);
          }
        }}
        type="file"
        style={{ position: 'absolute', width: 0 }}
      />
    </>
  );
};
