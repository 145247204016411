import { yupResolver } from '@hookform/resolvers/yup';
import { Button, MenuDivider, Message, Table, Typography } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { API_NAMESPACE, request } from '@solar/data';
import { CollapsibleContainer, ModalGroup, PageLayout, useModalGroupController } from '@solar/templates';
import { Descriptions } from 'antd';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import classes from './packaging-station-detail-page.module.scss';
import { PackagingTable } from './PackagingTable';
import { PalletSelectorModal } from './PalletSelectorModal';
import { Modals, PackingPayload, PackingsResponse, PalletsList, SetupPackagingMethods } from './types';
import { customSchemaGenerator } from './utils';
import { ViewPackagedModal } from './ViewPackagedModal';

const namespace_hr = API_NAMESPACE.HR;
const namespace_material = API_NAMESPACE.MATERIALS;

export function PackagingStationDetailPage() {
  const params = useParams<{ orderId: string }>();

  const [pallets, setPallets] = useState<PalletsList>([]); // step 1

  const setupPackagingMethods = useForm<SetupPackagingMethods>({
    mode: 'all',
    ...(pallets && { resolver: yupResolver(customSchemaGenerator(pallets)) }),
  });

  const modalGroupController = useModalGroupController([
    { name: Modals.PALLET_SELECTORS },
    { name: Modals.SETUP_PACKAGING },
  ]);

  const openPalletSelectorModal = () =>
    modalGroupController.openModal(Modals.PALLET_SELECTORS, null);

  const { data, error } = useSWR<PackingsResponse>([
    `/deliveryOrders/get/${params.orderId}/packings`,
    { namespace: namespace_hr },
  ]);
  const orders = data?.details ?? [];

  const handleSave = async ({ payload }: { payload: PackingPayload }) => {
    const res = await request(
      '/warehouses/production-delivery-orders/packing',
      {
        method: 'put',
        responseParser: (res) => res,
        body: JSON.stringify(payload),
        namespace: namespace_material,
      }
    );

    return res
  };

  const handlePalletSelectorModalSubmit = useCallback(
    (incomingPallets: PalletsList) => {
      const currentPalletsIndices = pallets.map((pallet) => pallet.id);

      const palletList = incomingPallets.filter((pallet) => pallet.id);
      palletList.forEach((pallet) => {
        if (pallet.id && !currentPalletsIndices.includes(pallet.id))
          setupPackagingMethods.setValue(pallet.id, { tableRows: [] });
      });

      setPallets(palletList);
    },
    [pallets, setPallets]
  );

  return (
    <PageLayout title={`出貨單${params.orderId ?? ''}`}>
      <Descriptions>
        <Descriptions.Item label="訂單單號">
          {orders[0]?.id ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label="客戶名稱">
          {/* {order[0]?.categoryName ?? ''} */}
        </Descriptions.Item>
        <Descriptions.Item label="業務人員">
          {/* {order[0]?.groupName ?? ''} */}
        </Descriptions.Item>
        <Descriptions.Item label="開立日期">
          {/* {order[0]?.expectedCompletedAt
            ? moment(order[0]?.expectedCompletedAt).format('YYYY/MM/DD')
            : ''} */}
        </Descriptions.Item>
        <Descriptions.Item label="生管人員">
          {/* {order[0]?.staffName ?? ''} */}
        </Descriptions.Item>
      </Descriptions>
      <CollapsibleContainer
        enableRowBlockStyle
        open
        header={<Typography variant="h4">包裝需求明細</Typography>}
      >
        <Table
          style={{ height: '500px' }}
          loading={error ?? !data}
          columns={[
            {
              title: '箱號',
              dataIndex: 'index',
            },
            {
              title: '包材料號',
              dataIndex: 'packagingMaterialId',
            },
            {
              title: '包材名稱',
              dataIndex: 'packagingName',
            },
            {
              title: '料號',
              dataIndex: 'materialId',
            },
            {
              title: '品名',
              dataIndex: 'materialName',
            },
            {
              title: '數量',
              dataIndex: 'boxQuantity',
            },
            {
              title: '單位',
              dataIndex: 'productUnit',
            },
          ]}
          dataSource={orders}
        />
      </CollapsibleContainer>
      <div
        style={{
          display: 'flex',
          gap: 'var(--mzn-spacing-2)',
          alignItems: 'center',
        }}
      >
        <Button
          variant="outlined"
          type="button"
          onClick={() => openPalletSelectorModal()}
        >
          棧板設定
        </Button>
      </div>
      <MenuDivider />
      <CollapsibleContainer
        enableRowBlockStyle
        open
        key={pallets.length}
        header={<Typography variant="h4">已包裝項目</Typography>}
      >
        <FormFieldsWrapper
          id="packing"
          methods={setupPackagingMethods}
          onSubmit={async (values) => {
            try {
              const body: PackingPayload['item'] = [];

              pallets.forEach((pallet) => {
                const tmp = values[pallet.id as string].tableRows.map(
                  (outerRow) => {
                    const batches = outerRow.subItems.map(
                      ({ materialBarcode, mainMaterialUnitQuantity }) => ({
                        barcode: materialBarcode,
                        mainMaterialUnitQuantity: Number(
                          mainMaterialUnitQuantity
                        ),
                      })
                    );

                    return {
                      packingListId: outerRow.packingId,
                      palletId: pallet.realId as number,
                      batches,
                    };
                  }
                );

                body.push(...tmp);
              });

              const payload = { item: body } satisfies PackingPayload;
              const res = await handleSave({ payload });

              if(res.ok) {
                Message.success('儲存成功')
              }
              
            } catch (err) {
              if (err instanceof Error) {
                Message.error(JSON.parse(err.message).message);
              } else {
                Message.error('儲存失敗');
              }
            }
          }}
        >
          {pallets.map((pallet, index) => {
            return (
              <PackagingTable
                key={pallet.id}
                pallets={pallets}
                palletIndex={index}
              />
            );
          })}
        </FormFieldsWrapper>
      </CollapsibleContainer>
      <div className={classes['save-button-wrapper']}>
        <Button type="submit" form="packing">
          儲存
        </Button>
      </div>
      <ModalGroup {...modalGroupController}>
        <PalletSelectorModal onSubmit={handlePalletSelectorModalSubmit} />
        <ViewPackagedModal />
      </ModalGroup>
    </PageLayout>
  );
}
