import { useCallback, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { API_NAMESPACE } from '../../request';
import { sdOpConvertCustomers } from '../orderMarks';

export type FilterDataType = {
  id: string;
  name: string;
};

type MainFileFilters = Record<
  | 'materials'
  | 'selectByMaterials'
  | 'customers'
  | 'packagingTypes'
  | 'packaging'
  | 'quantityUnits',
  FilterDataType[]
>;

const namespace = API_NAMESPACE.HR;

type QueryObjectType = Record<
  'packagingType' | 'materialId' | 'materialKeyword',
  string | null
>;

export function useMainFileFilters() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<MainFileFilters>({
    customers: [],
    materials: [],
    selectByMaterials: [],
    packaging: [],
    packagingTypes: [],
    quantityUnits: [],
  });
  const [queryParams, setQueryParams] = useState('');
  const [queryObject, setQueryObject] = useState<QueryObjectType>({
    packagingType: null,
    materialId: null,
    materialKeyword: '',
  });
  const [initFilter, setInitFilter] = useState(true);
  const [allPackagingNames, setAllPackagingNames] = useState<FilterDataType[]>(
    []
  );

  const { data: swrData } = useSWR([
    `/packings/get/filter${queryParams}`,
    { namespace },
  ]);

  const clearSelectByMaterials = () => {
    setData((old) => ({ ...old, selectByMaterials: [] }));
  };

  const filterMutation = async (payload: string) => {
    if (payload) {
      const result = payload.replace('&', '?');
      setQueryParams(result);
    }
  };

  const handleFilterByKeyword = (value?: string) => {
    if (!value || value === '') {
      setQueryObject((params) => ({ ...params, materialKeyword: '' }));
      return;
    }
    setQueryObject((params) => ({ ...params, materialKeyword: value }));
  };

  const isMainfileFilters = useCallback(
    (object: unknown): object is MainFileFilters => {
      if (object && typeof object === 'object') return 'customers' in object;
      return false;
    },
    []
  );

  const customers = useMemo(
    () => sdOpConvertCustomers(swrData?.customers),
    [swrData]
  ) as FilterDataType[];

  useEffect(() => {
    const result = Object.keys(queryObject).reduce((acc, cur) => {
      if (queryObject[cur as keyof QueryObjectType])
        acc += `&${cur}=${queryObject[cur as keyof QueryObjectType]}`;
      return acc;
    }, '');
    filterMutation(result);
  }, [queryObject]);

  useEffect(() => {
    if (!isMainfileFilters(swrData)) {
      setLoading(true);
      return;
    }
    setData(swrData);
    setLoading(false);
  }, [swrData]);

  useEffect(() => {
    if (!initFilter) return;
    if (isMainfileFilters(swrData)) {
      setAllPackagingNames(swrData.packaging);
      setInitFilter(false);
    }
  }, [swrData]);

  return {
    loading,
    data,
    setData,
    isMainfileFilters,
    filterMutation,
    queryObject,
    setQueryObject,
    allPackagingNames,
    customers,
    clearSelectByMaterials,
    handleFilterByKeyword,
  };
}
