import { useFormContext } from 'react-hook-form';
import { FormValues } from '../hooks/typing';
import { Card, Col, Row } from 'antd';
import { FormAnswer } from '@solar/data';
import { CheckboxField } from '@mezzanine-ui/react-hook-form';

export function SubmissionAnswersCard({
  title,
  formAnswers,
  disabledAllFields = false,
}: {
  title: string;
  formAnswers: FormAnswer[];
  disabledAllFields?: boolean;
}) {
  const methods = useFormContext<FormValues>();

  const onCheckboxChange = (formAnswer: FormAnswer) => (event: any) => {
    const checked = (event?.target as HTMLInputElement)?.checked;

    if (checked) {
      // 當有結果時，取消其他相同 form.id 的檢測結果
      const answers = methods.getValues('answers');
      Object.entries(answers)?.forEach(([answerId, item]) => {
        if (
          answerId !== formAnswer?.id &&
          formAnswer?.form?.id === item?.form?.id &&
          item?.picked
        ) {
          methods.setValue(`answers.${answerId}.picked`, false);
        }
      });
    }
  };

  return (
    <Card title={title}>
      <Row>
        <Col flex="50px" />
        <Col flex="auto">檢測項目</Col>
        <Col flex="120px">項目結果</Col>
      </Row>
      {formAnswers?.map((formAnswer) => (
        <Row key={formAnswer?.form?.id}>
          <Col flex="50px">
            <CheckboxField
              disabled={disabledAllFields}
              registerName={`answers.${formAnswer?.id}.picked`}
              onChange={onCheckboxChange(formAnswer)}
            />
          </Col>
          <Col flex="auto">
            <span>{formAnswer?.form?.title ?? ''}</span>
          </Col>
          <Col flex="120px">
            <span>{formAnswer?.answer ?? ''}</span>
            <span>{formAnswer?.form?.suffix ?? ''}</span>
          </Col>
        </Row>
      ))}
    </Card>
  );
}
