import useSWR from 'swr';
import { API_NAMESPACE } from '../request';

export type ProductionDeliveryOrderDetail = {
  // packedStates: `${PackedStates}`;
  id: string;

  expectedCompletedAt: string;
  supplierId: string;
  supplierName: string;
  staffName: string;
  // receivedState: {};
  specId: 'string';
  expectedMainMaterialUnitQuantity: 0;
  expectedSecondaryMaterialUnitQuantity: 0;
  receivedMainMaterialUnitQuantity: 0;
  receivedSecondaryMaterialUnitQuantity: 0;
  materialId: 'string';
  oldMaterialId: 'string';
  materialDescription: 'string';
  categoryName: 'string';
  groupName: 'string';
};

const namespace = API_NAMESPACE.MATERIALS;

export function useProductionDeliveryOrder({
  orderId,
}: {
  orderId?: string | undefined;
}) {
  const { data, error } = useSWR<ProductionDeliveryOrderDetail[]>(
    orderId
      ? [`/warehouses/production-delivery-orders/${orderId}`, { namespace }]
      : null
  );

  return {
    order: data ?? [],
    isLoading: !data && !error,
  };
}
