import { Tab, TabPane, Tabs } from "@mezzanine-ui/react";
import { PageLayout } from "@solar/templates";
import { Key, useState } from "react";
import { DispatchSettingTabPaneContent } from "./DispatchSettingTabPaneContent";
import { DispatchOrderTabPaneContent } from "./DispatchOrderTabPaneContent";
import { OutsourcingOrderTabPaneContent } from "./OutsourcingOrderTabPaneContent";

export function OutsourcingOrderPage() {
  const [activeTab, setActiveTab] = useState<Key>('dispatch-setting');

  return (
    <PageLayout title="委外加工">
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane key="dispatch-setting" tab={<Tab>發料設定</Tab>}>
          <DispatchSettingTabPaneContent />
        </TabPane>
        <TabPane key="dispatch-order" tab={<Tab>發料單</Tab>}>
          <DispatchOrderTabPaneContent />
        </TabPane>
        <TabPane key="outsourcing-order" tab={<Tab>委外加工</Tab>}>
          <OutsourcingOrderTabPaneContent />
        </TabPane>
      </Tabs>
    </PageLayout>
  )
}