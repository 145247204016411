import { cx, Typography } from '@mezzanine-ui/react';
import {
  HookFormFieldComponent,
  SelectFieldProps,
} from '@mezzanine-ui/react-hook-form';
import React, { ReactChild, ReactElement, ReactNode } from 'react';

import classes from './input-container-with-unit.module.scss';

interface InputFieldWithUnitProps {
  htmlFor?: string;
  prefixAdj?: string;
  suffixUnit?: string | ReactElement;
  children?: ReactNode;
  withBorder?: boolean;
}

export function InputContainerWithUnit({
  prefixAdj,
  suffixUnit,
  children,
  htmlFor,
  withBorder = false,
}: InputFieldWithUnitProps) {
  return (
    <div className={cx(classes.host, { [classes.border]: withBorder })}>
      {prefixAdj && (
        <label htmlFor={htmlFor} className={classes.prefixAdj}>
          {prefixAdj}
        </label>
      )}
      <div className={classes['input-wrapper']}>{children}</div>
      {suffixUnit && typeof suffixUnit === 'string' ? (
        <label htmlFor={htmlFor} className={classes.suffixAdj}>
          {suffixUnit}
        </label>
      ) : (
        <>{suffixUnit}</>
      )}
    </div>
  );
}

export default InputContainerWithUnit;
