import { SelectField } from '@mezzanine-ui/react-hook-form';
import { CustomInputSearch } from '@solar/templates';
import classes from './filter.module.scss';
function Filter() {
  // may be replaced by api data in the near future
  const typeOptions = [
    { id: '生產模具', name: '生產模具' },
    { id: '包裝材', name: '包裝材' },
    { id: '消耗品', name: '消耗品' },
  ];
  return (
    <div className={classes.host}>
      <SelectField
        label="類型："
        registerName="type"
        options={typeOptions ?? []}
      />
      <CustomInputSearch />
    </div>
  );
}

export default Filter;
