import type { HubOrderDetailData } from './useHubOrderDetailGet';
import React from 'react';
import { useFieldArray, useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { request, API_NAMESPACE } from '../../request';

export const HubOrderUpdateFormFieldName = {
  HUB_DELIVERY_ORDER_ID: 'hubDeliveryOrderId',
  HUB_DELIVERY_ORDER_LINE: 'line',
  DEBIT_DATE: 'debitDate',
  // fields array
  BATCH_ITEMS: 'details',
  DETAIL_ID: 'id',
  BATCH_SN: 'batchSn',
  BATCH_ID: 'batchId',
  CUSTOMER_BATCH_SN: 'customerBatchSn',
  NET_WEIGHT: 'netWeight',
  GROSS_WEIGHT: 'grossWeight',
} as const;

const _FormFieldName = HubOrderUpdateFormFieldName;

const hubOrderUpdateFormSchema = yup.object().shape({
  [_FormFieldName.HUB_DELIVERY_ORDER_ID]: yup.string().required(),
  [_FormFieldName.DEBIT_DATE]: yup.string().required(),
  [_FormFieldName.BATCH_ITEMS]: yup.array().of(
    yup.object().shape({
      [_FormFieldName.HUB_DELIVERY_ORDER_LINE]: yup.string().required(),
      [_FormFieldName.DETAIL_ID]: yup.number().required(),
      [_FormFieldName.BATCH_ID]: yup.string().required(' '), // 覆蓋掉預設錯誤訊息
      [_FormFieldName.BATCH_SN]: yup.string().required(' ').test('len', '請輸入5個數字', function (val) {
        val = val ?? '';
        return val.length === 5 && /^\d+$/.test(val);
      }),
      [_FormFieldName.CUSTOMER_BATCH_SN]: yup.string(),
      [_FormFieldName.NET_WEIGHT]: yup.string().required(' '),
      [_FormFieldName.GROSS_WEIGHT]: yup.string().required(' '),
    })
  ),
});

export type HubOrderUpdateFormValues = yup.InferType<
  typeof hubOrderUpdateFormSchema
>;

export function useHubOrderUpdate() {
  const formMethods = useForm<HubOrderUpdateFormValues>({
    resolver: yupResolver(hubOrderUpdateFormSchema),
  });
  const formState = useFormState({ control: formMethods.control });

  const { fields: batchFieldArray } = useFieldArray({
    name: _FormFieldName.BATCH_ITEMS,
    control: formMethods.control,
  });

  return {
    formMethods,
    formState,
    formSubmitCreator,
    batchFieldArray, // 應該用不到，但順便
    generateBatchSnFieldName,
    generateBatchIdFieldName,
    generateCustomerBatchSnFieldName,
    generateNetWeightFieldName,
    generateGrossWeightFieldName,
  };
}

// 生成動態欄位 BATCH_SN 名稱
function generateBatchSnFieldName(id: number) {
  return `${_FormFieldName.BATCH_ITEMS}.${id}.${_FormFieldName.BATCH_SN}`;
}

// 生成動態欄位 BATCH_ID 名稱
function generateBatchIdFieldName(id: number) {
  return `${_FormFieldName.BATCH_ITEMS}.${id}.${_FormFieldName.BATCH_ID}`;
}

// 生成動態欄位 CUSTOMER_BATCH_SN 名稱
function generateCustomerBatchSnFieldName(id: number) {
  return `${_FormFieldName.BATCH_ITEMS}.${id}.${_FormFieldName.CUSTOMER_BATCH_SN}`;
}

// 生成動態欄位 NET_WEIGHT 名稱
function generateNetWeightFieldName(id: number) {
  return `${_FormFieldName.BATCH_ITEMS}.${id}.${_FormFieldName.NET_WEIGHT}`;
}

// 生成動態欄位 GROSS_WEIGHT 名稱
function generateGrossWeightFieldName(id: number) {
  return `${_FormFieldName.BATCH_ITEMS}.${id}.${_FormFieldName.GROSS_WEIGHT}`;
}

async function putHubOrderUpdate({
  hubDeliveryOrderId,
  debitDate,
  details,
}: HubOrderUpdateFormValues) {
  try {
    if (!hubDeliveryOrderId || !debitDate || !details) throw new Error('parameter error');

    return request(`/hubDeliveryOrders/${hubDeliveryOrderId}`, {
      namespace: API_NAMESPACE.HR,
      method: 'PUT',
      body: JSON.stringify({
        debitDate: transformDateFormat(debitDate),
        details: details,
      }),
    });
  } catch (error) {
    return null;
  }
}

function transformDateFormat(date: string) {
  const _date = new Date(date);
  const yyyy = _date.getFullYear();
  const mm = String(_date.getMonth() + 1).padStart(2, '0');
  const dd = String(_date.getDate()).padStart(2, '0');
  return `${yyyy}/${mm}/${dd}`;
}

type FormSubmitCreatorProps = {
  successCallback?: (payload: { filePath: string }) => void;
  errorCallback?: (error: Error) => void;
};

function formSubmitCreator({
  successCallback,
  errorCallback,
}: FormSubmitCreatorProps) {
  const onSubmit = async (data: HubOrderUpdateFormValues) => {
    // 檢查取得資料，是否有 details
    console.log('data :>> ', data);

    putHubOrderUpdate(data)
      .then((payload) => {
        successCallback?.(payload);
      })
      .catch((error) => {
        errorCallback?.(error);
      });
  };
  return onSubmit;
}
