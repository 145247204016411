import { TableColumn } from '@mezzanine-ui/core/table';
import { Button } from '@mezzanine-ui/react';
import { WaitReceiptObj, statusTag } from '@solar/data';
import { useTargetModal } from '@solar/templates';
import moment from 'moment';
import { useMemo } from 'react';
import { Modals, WaitingStorageInColumnProps } from '../types';

export const useExceptionColumn = () => {
  const openInspectModal = useTargetModal<WaitReceiptObj>(
    Modals.Inspect
  ).openModal;

  const openDeleteWaitReceiptModal = useTargetModal<{ id: string }>(
    Modals.DeleteWaitReceipt
  ).openModal;

  const column: TableColumn<WaitReceiptObj>[] = useMemo(
    () => [
      {
        title: '操作',
        width: 220,
        align: 'center' as const,
        ellipsis: false,
        render(source) {
          return (
            <div
              style={{
                display: 'flex',
                visibility: 'unset',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              <Button
                type="button"
                variant="outlined"
                onClick={() => {
                  openInspectModal(source);
                }}
              >
                判定
              </Button>
              <Button
                type="button"
                variant="outlined"
                onClick={() => {
                  openDeleteWaitReceiptModal({ id: source.id });
                }}
              >
                刪除
              </Button>
            </div>
          );
        },
      },
      { title: '儲存地點', dataIndex: 'shelfId' },
      {
        title: '狀態',
        width: 100,
        render: (source) => {
          if (source?.statusTag) {
            return statusTag[source?.statusTag];
          }

          return null;
        },
      },

      {
        title: '出站時間',
        render: (source) =>
          source ? moment(source.createdAt).format('YYYY-MM-DD') : '',
      },
      { title: '工單單號', dataIndex: 'workOrderId' },
      { title: '料號', dataIndex: 'materialId' },
      { title: '品名', dataIndex: 'materialDescription' },
      {
        title: '批號',
        render: (source) =>
          `${source.materialSapBatchId ?? ''}-${
            source.materialSubBatchId ?? ''
          }`,
      },
      { title: '載具', dataIndex: 'loaderId' },
      {
        title: '管理單位數量',
        dataIndex: 'materialInputUnitQuantity',
      },
      { title: '單位', dataIndex: 'materialInputUnit' },
      { title: '實際重量', dataIndex: 'materialWeightUnitQuantity' },
      { title: '單位', dataIndex: 'actualWeightUnit' },
      { title: '特採單號', dataIndex: 'waiverNumber' },
    ],
    [openInspectModal, openDeleteWaitReceiptModal]
  );

  return column;
};
