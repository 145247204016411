import { SelectValue } from '@mezzanine-ui/react';
import { API_NAMESPACE, ProductionFormCondition, request } from '@solar/data';
import { useCallback, useEffect, useState } from 'react';

export function useGetIQCFormsQueryConditions(materials: string[]) {
  const [productionFormQueryConditions, setProductionFormQueryConditions] =
    useState<ProductionFormCondition[]>();

  const [iqcVersionLoading, setIqcVersionLoading] = useState(false);

  const [iqcVersionOptionsMap, setIqcVersionOptionsMap] =
    useState<Map<string, SelectValue<string>[]>>();

  useEffect(() => {
    const fetchIQCVersions = async (materialIds: string[]) => {
      try {
        return (await request('/forms/iqc-versions', {
          namespace: API_NAMESPACE.PP,
          params: { materialIds },
        })) as Promise<{ materialId: string; iqcVersion: number[] }[]>;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    let isMounted = true;

    if (!isMounted || !materials.length) {
      return;
    }

    (async () => {
      try {
        setIqcVersionLoading(true);

        const materialIds = Array.from(
          new Set<string>(
            materials.map((material) => material?.split('_')?.[0])
          )
        );

        const response = await fetchIQCVersions(materialIds);

        const result =
          response?.map(
            (item) =>
              [
                item?.materialId,
                item?.iqcVersion?.map((version) => ({
                  id: version?.toString() ?? '1',
                  name: version?.toString() ?? '1',
                })),
              ] as const
          ) ?? [];

        setIqcVersionOptionsMap(new Map(result));

        setProductionFormQueryConditions(
          result.map(([materialId, iqcVersions]) => ({
            materialId,
            iqcVersion: iqcVersions?.[0]?.id?.toString() ?? '1',
            templateTypes: ['IQC'],
          }))
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIqcVersionLoading(false);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [materials]);

  const getIQCVersionSelectValue = useCallback(
    (materialId: string) => {
      const version = productionFormQueryConditions?.find(
        (condition) => condition?.materialId === materialId
      )?.iqcVersion;

      return version ? { id: version, name: version } : null;
    },
    [productionFormQueryConditions]
  );

  const onIQCVersionChange = useCallback((materialId: string) => {
    return (value: SelectValue<string>) =>
      setProductionFormQueryConditions((prev) =>
        prev?.map((condition) =>
          condition?.materialId === materialId
            ? {
                ...condition,
                iqcVersion: value?.id?.toString() ?? '1',
              }
            : condition
        )
      );
  }, []);

  return {
    productionFormQueryConditions,
    iqcVersionLoading,
    iqcVersionOptionsMap,
    getIQCVersionSelectValue,
    onIQCVersionChange,
  };
}
