import { AnalyzeStickerPanel } from './AnalyzeStickerPanel';
import { BatchStickerPanel } from './BatchStickerPanel';
import { LoaderStickerPanel } from './LoaderStickerPanel';
import { SingleStickerProps } from './types';
export const tabsOptions = [
  {
    id: 'batch-sticker',
    name: '批號貼紙',
    panel: BatchStickerPanel,
  },
  {
    id: 'loader-sticker',
    name: '載具貼紙',
    panel: LoaderStickerPanel,
  },
  {
    id: 'analyze-sticker',
    name: '分析樣貼紙',
    panel: AnalyzeStickerPanel,
  },
];

export const tabIds = tabsOptions.map((tab) => tab.id);

export const stickerInitValue = {
  material: null,
  materialDescription: null,
  batch: null,
  // date: null,
  quantity: null,
  staff: null,
  remark: null,
  batchStoredLoaderRecordId: null,

  loader: null,
  type: null,
  searchBarcode: null,
  triggerRerenderBoolean: false,
} satisfies SingleStickerProps;

export const stockTypes = {
  AVAILABLE: '未限制',
  INSPECTION: '品檢中',
  IN_TRANSIT: '移轉中',
  SALES: '銷售訂單',
  REQUESTED: '預約發料',
  IN_TRANSIT_SALES: '銷售訂單移轉中',
  LOCKED: 'WIP 庫存',
  PENDING: '待入庫',
  EXPECTED: '預產批號',
};
