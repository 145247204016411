import { useState } from 'react';
import { ModalLayout } from '../../ModalLayout/ModalLayout';
import { Button, Message, Typography, cx } from '@mezzanine-ui/react';
import { useModalController } from '../../ModalLayout/hooks';
import classes from './material-scrapping-modal.module.scss';
import shareStyles from '../../shared-styles/index.module.scss';
import { RecoverScrappingDoneModal } from './RecoverScrappingDoneModal';

export function SuccessScrapModal(
  props: ReturnType<typeof useModalController>
) {
  const { open, closeModal } = props;

  const [mockLoading, setLoading] = useState(false);
  const recoverScrappingModalController = useModalController();

  const handleClose = () => {
    closeModal();
  };

  return (
    <>
      <ModalLayout
        modalHeader="報廢成功"
        className={cx(classes.modal)}
        open={open}
        closeModal={handleClose}
        modalFooter={
          <div
            className={cx(shareStyles['row-flex'], shareStyles['row-block'])}
          >
            <Button
              loading={mockLoading}
              danger
              variant="outlined"
              onClick={() => {
                setLoading(true);
                setTimeout(() => {
                  try {
                    if (Math.random() > 0.5) {
                      closeModal();
                      recoverScrappingModalController.openModal({});
                    } else throw new Error();
                  } catch (err) {
                    Message.error('操作失敗');
                  } finally {
                    setLoading(false);
                  }
                }, 1000);
              }}
            >
              取消操作
            </Button>
            <Button loading={mockLoading} variant="contained">
              關閉
            </Button>
          </div>
        }
      >
        <div style={{ overflow: 'hidden' }}>
          <div className={shareStyles['modal-body-wrapper']}>
            <div className={shareStyles['row-block']}>
              <div className={shareStyles['row-flex']}>
                <div className={classes['half-space']}>
                  <Typography ellipsis={true}>
                    料號：AABBBCD02921料號：AABBBCD02921料號：AABBBCD02921料號：AABBBCD02921
                  </Typography>
                </div>
                <div className={classes['half-space']}>
                  <Typography ellipsis={true}>批號：20230405025-001</Typography>
                </div>
              </div>
              <div className={shareStyles['row-flex']}>
                <div className={classes['half-space']}>
                  <Typography ellipsis={true}>儲位：1100A1C4J1B</Typography>
                </div>
                <div className={classes['half-space']}>
                  <Typography ellipsis={true}>入庫日期：2020/02/04</Typography>
                </div>
              </div>
            </div>
            <div className={shareStyles['row-block']}>
              <div className={shareStyles['row-flex']}>
                <div className={classes['half-space']}>
                  <Typography ellipsis={true}>
                    料號：AABBBCD02921料號：AABBBCD02921料號：AABBBCD02921料號：AABBBCD02921
                  </Typography>
                </div>
                <div className={classes['half-space']}>
                  <Typography ellipsis={true}>批號：20230405025-001</Typography>
                </div>
              </div>
              <div className={shareStyles['row-flex']}>
                <div className={classes['half-space']}>
                  <Typography ellipsis={true}>儲位：1100A1C4J1B</Typography>
                </div>
                <div className={classes['half-space']}>
                  <Typography ellipsis={true}>入庫日期：2020/02/04</Typography>
                </div>
              </div>
            </div>
            <div className={shareStyles['row-block']}>
              <Typography>規格：</Typography>
              <Typography>報廢原因：</Typography>
            </div>
          </div>
        </div>
      </ModalLayout>
      <RecoverScrappingDoneModal {...recoverScrappingModalController} />
    </>
  );
}
