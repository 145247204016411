import { MenuDivider, Typography } from "@mezzanine-ui/react";
import { ReactNode } from "react";
import styles from './page-template.module.scss';

type PageTemplateProps = {
  title: string,
  extraContent?: ReactNode,
  children: ReactNode,
};

export function PageTemplate({ title, extraContent, children }: PageTemplateProps) {
  return (
    <div className={styles.page}>
      <div className={styles.title}>
        <Typography variant="h1">{title}</Typography>
        {extraContent}
      </div>
      <MenuDivider />
      {children}
    </div>
  )
}