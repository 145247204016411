import { ShipmentBoxMergeFieldName as FieldName } from './form-definition';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const ShipmentBoxMergeItemSchema = yup.object().shape({
  [FieldName.PACKAGE_LIST_ID]: yup.number().required(),
  [FieldName.BOX_NUMBER]: yup
    .number()
    .integer()
    .test((value, ctx) => {
      if (!value || value <= 0) {
        return ctx.createError({ message: '' });
      }
      return true;
    }),
});

const ShipmentBoxMergeFormSchema = yup.object().shape({
  [FieldName.DELIVERY_ORDER_ID]: yup.string().required(),
  [FieldName.BOX_MERGE_ITEMS]: yup.array().of(ShipmentBoxMergeItemSchema),
});

export const shipmentBoxMergeFormResolver = yupResolver(
  ShipmentBoxMergeFormSchema
);
