import { useWatch } from 'react-hook-form';
import styles from './routing-flow-chart.module.scss';
import { EdgeCfg, FlowAnalysisGraph, FlowAnalysisGraphConfig, FlowGraphEdgeData, NodeCfg } from '@ant-design/graphs';
import { FlowAnalysisNodeData } from '@ant-design/graphs/es/components/flow-analysis-graph';
import { useMemo } from 'react';
import { ExtendedRoutingRecord } from './typings';

export function RoutingFlowChart() {
  const tableValues = useWatch({ name: 'tableValues' }) as ExtendedRoutingRecord[];

  const sourceMap = useMemo<Record<string, string>>(() => (tableValues ?? [])
    .filter(row => row.sourceMaterialId)
    .reduce((vars, row) => ({
      ...vars,
      [row.sourceMaterialId as string]: row.id,
    }), {}), [tableValues]);

  const targetMap = useMemo<Record<string, string>>(() => (tableValues ?? [])
    .filter(row => row.destMaterialId)
    .reduce((vars, row) => ({
      ...vars,
      [row.destMaterialId as string]: row.id,
    }), {}), [tableValues]);

  console.log(sourceMap, targetMap);

  const chartData = useMemo<{
    nodes: FlowAnalysisNodeData[];
    edges: FlowGraphEdgeData[];
  }>(() => ({
    nodes: (tableValues ?? []).map((row) => ({
      id: row.id,
      value: {
        title: row.action,
        items: [{
          text: row.areaDesc,
          value: row.workCenterId,
        }],
      },
    })) as unknown as FlowAnalysisNodeData[],
    edges: (tableValues ?? []).map((row) => ({
      source: targetMap[row.sourceMaterialId as string],
      target: sourceMap[row.destMaterialId as string],
    })),
  }), [tableValues, sourceMap, targetMap]);

  const chartConfig = useMemo<FlowAnalysisGraphConfig>(() => ({
    data: chartData,
    nodeCfg: {
      size: [140, 25],
      nodeStateStyles: {
        hover: {
          stroke: '#1890ff',
          lineWidth: 2,
        },
      },
      title: {
        containerStyle: {
          fill: 'transparent',
        },
        style: {
          fill: '#000',
          fontSize: 12,
        },
      },
      style: {
        fill: '#E6EAF1',
        stroke: '#B2BED5',
        radius: 2,
      },
    } as NodeCfg,
    edgeCfg: {
      label: {
        style: {
          fill: '#aaa',
          fontSize: 12,
          fillOpacity: 1,
        },
      },
      edgeStateStyles: {
        hover: {
          lineWidth: 2,
          strokeOpacity: 1,
        },
      },
    } as EdgeCfg,
    behaviors: ['drag-canvas', 'drag-node'],
  }), [chartData]);

  console.log({ tableValues, chartData });

  return (
    <div className={styles.wrapper}>
      <FlowAnalysisGraph {...chartConfig} />
    </div>
  );
}
