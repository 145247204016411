import { SelectValue } from '@mezzanine-ui/react';
import { createContext, ReactNode, useContext, useState } from 'react';

export type FilterContextType = {
  workOrderId: SelectValue | null;
  setWorkOrderId: React.Dispatch<
    React.SetStateAction<SelectValue | null>
  > | null;
  materialRequestOrderId: SelectValue | null;
  setMaterialRequestOrderId: React.Dispatch<
    React.SetStateAction<SelectValue | null>
  > | null;
};

export const FilterContext = createContext<FilterContextType>({
  workOrderId: null,
  setWorkOrderId: null,
  materialRequestOrderId: null,
  setMaterialRequestOrderId: null,
});

export function useFilterContext() {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useMyContext must be used within a MyContextProvider');
  }

  return context;
}

interface Props {
  children: ReactNode;
}

// export const FilterContextProvider = ({ children }: Props) => {
//   const [workOrderId, setWorkOrderId] =
//     useState<FilterContextType['workOrderId']>(null);

//   return (
//     <FilterContext.Provider value={{ workOrderId, setWorkOrderId }}>
//       {children}
//     </FilterContext.Provider>
//   );
// };
