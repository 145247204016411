import { Button, Empty, MenuDivider, SelectValue } from '@mezzanine-ui/react';
import { FormFieldsWrapper, SelectField } from '@mezzanine-ui/react-hook-form';
import { saveProductionFormAnswers } from '@solar/data';
import {
  HorizontalField,
  MaterialAndBatchField,
  PageLayout,
} from '@solar/templates';
import { Row, Space, notification } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useSubmissionResults } from '../hooks/use-submission-results';
import { FormValues, defaultValues, labOptions } from '../hooks/typing';
import { SubmissionAnswersCard } from '../AnswersCard/SubmissionAnswersCard';
import { FinalAnswersCard } from '../AnswersCard/FinalAnswersCard';

interface CopyAnswersFormValues extends FormValues {
  sourceMaterial?: SelectValue | null;
  sourceBatch?: SelectValue | null;
  targetMaterial?: SelectValue | null;
  targetBatches?: SelectValue[];
}

export function CopyAnswersByMaterialBatchPage() {
  const methods = useForm<CopyAnswersFormValues>({ defaultValues });
  const [saving, setSaving] = useState(false);

  const {
    searching,
    allAnswers,
    manualForms,
    submissionResults,
    onSearchSubmissionResults,
    resetSubmissionResults,
  } = useSubmissionResults({ methods });

  const sourceMaterial = useWatch({
    control: methods.control,
    name: 'sourceMaterial',
  });

  const sourceBatch = useWatch({
    control: methods.control,
    name: 'sourceBatch',
  });

  const targetMaterialBatchesFilter = useCallback(
    (batch: {
      materialId: string;
      materialDescription: string;
      sapBatchId: string;
      subBatchId: string;
      batchId: string;
    }) =>
      !(
        batch?.materialId === sourceMaterial?.id &&
        batch?.batchId === sourceBatch?.id
      ),
    [sourceMaterial, sourceBatch]
  );

  const resetForm = useCallback(() => {
    methods.reset(
      Object.assign({}, defaultValues, {
        sourceMaterial: null,
        sourceBatch: null,
        targetMaterial: null,
        targetBatches: [],
      })
    );
    resetSubmissionResults();
  }, []);

  const onSourceSearch = useCallback(async () => {
    const formState = methods.getValues();
    await onSearchSubmissionResults({
      factory: formState?.factory?.id ?? '',
      materialId: formState?.sourceMaterial?.id ?? '',
      batchId: formState?.sourceBatch?.id ?? '',
    });
  }, []);

  const modifiedByForm = useWatch({
    control: methods.control,
    name: 'modifiedByForm',
  });

  const bindManualAnswers = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(modifiedByForm)?.map(
          ([formId, item]) =>
            [
              item?.answerId ?? '',
              {
                formId,
                form: item?.form,
                answer: null,
                modifiedAnswer: item?.modifiedAnswer ?? null,
                picked: item?.picked,
              },
            ] as const
        )
      ),
    [modifiedByForm]
  );

  const onSave = useCallback(async () => {
    try {
      setSaving(true);
      const formState = methods.getValues();

      for (const targetBatch of formState?.targetBatches ?? []) {
        try {
          const [materialSapBatchId, materialSubBatchId] =
            targetBatch?.id?.split('-') ?? [];

          await saveProductionFormAnswers({
            materialId: formState?.targetMaterial?.id ?? '',
            materialSapBatchId,
            materialSubBatchId,
            upsertAnswers: [],
            newAnswers:
              allAnswers?.map((formAnswer) => ({
                productionFormId: formAnswer?.form?.id,
                answer: formAnswer?.answer,
                picked: formAnswer?.picked ?? false,
              })) ?? [],
          });

          notification.success({
            message: `綁定成功`,
            description: `
              來源：${formState?.sourceMaterial?.id}-${formState?.sourceBatch?.id}
              目標：${formState?.targetMaterial?.id}-${targetBatch?.id}
            `,
            duration: 0,
          });
        } catch (error) {
          console.error(error);
          notification.error({
            message: `${targetBatch?.id} 綁定失敗`,
            description: '',
            duration: 0,
          });
          throw error;
        }
      }

      resetForm();
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  }, [allAnswers]);

  return (
    <PageLayout title="庫存綁檢驗結果">
      <FormFieldsWrapper methods={methods}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 'var(--mzn-spacing-3)',
          }}
        >
          <HorizontalField label="實驗室">
            <SelectField required registerName="factory" options={labOptions} />
          </HorizontalField>
          <MaterialAndBatchField
            materialLabel="來源料號"
            materialRegisterName="sourceMaterial"
            materialBatchLabel="來源批號"
            materialBatchRegisterName="sourceBatch"
            withDeleted
          />

          <Button
            type="button"
            loading={searching}
            variant="contained"
            onClick={onSourceSearch}
            disabled={!sourceMaterial || !sourceBatch}
          >
            搜尋
          </Button>

          {manualForms?.length ? (
            <FinalAnswersCard
              hideSaveButton
              forms={manualForms}
              resetForm={resetForm}
              bindManualAnswers={bindManualAnswers}
            />
          ) : null}

          {submissionResults?.length ? (
            <Space direction="vertical" style={{ width: '100%' }}>
              {submissionResults?.map((item) => (
                <SubmissionAnswersCard
                  key={item?.submission?.id}
                  title={
                    item?.submission?.subNumber
                      ? `檢驗單號：${item?.submission?.subNumber}`
                      : '未指定檢驗單號'
                  }
                  disabledAllFields
                  formAnswers={item?.formAnswers}
                />
              ))}
            </Space>
          ) : (
            <Empty title="無題目答案" />
          )}

          <MenuDivider />

          <MaterialAndBatchField
            materialLabel="目標料號"
            materialRegisterName="targetMaterial"
            materialBatchLabel="目標批號"
            materialBatchRegisterName="targetBatches"
            materialBatchesFilter={targetMaterialBatchesFilter}
            selectedMultipleBatches
            withDeleted
          />

          <Button
            type="button"
            variant="contained"
            disabled={(allAnswers?.length ?? 0) === 0}
            loading={saving}
            onClick={onSave}
          >
            綁定檢驗結果
          </Button>
        </div>
      </FormFieldsWrapper>
    </PageLayout>
  );
}
