import { ChevronUpIcon } from '@mezzanine-ui/icons';
import { Button, Icon, Typography } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { useForm } from 'react-hook-form';
import styles from './FilterSearch.module.scss';
import { useCallback, useEffect, useMemo } from 'react';
import {
  CalendarRanger,
  MultipleSelect,
  SearchInput,
  AutoComplete,
} from './enhanceFilter/enhanceFilter';
import { FieldsType, useOrdersFilters } from '@solar/data';

export const dateFormat = (date: string) =>
  date.split('T')[0].replace(/-/g, '/');

interface FilterSearchProps {
  filterMethods: (payload: FieldsType[]) => Promise<void>;
}

export function FilterSearch({ filterMethods }: FilterSearchProps) {
  const methods = useForm();

  const { data: ordersFiltersData } = useOrdersFilters();

  const {
    customers,
    orderStatus,
    orderTypes,
    bu,
  } = ordersFiltersData;

  const filtersData = useMemo(() => {
    return [
      {
        label: '訂單日期',
        fn: CalendarRanger,
        registerName: 'orderDate',
        isDate: true,
        props: {
          width: 300,
          clearable: true,
          placeholder: 'yyyy/mm/dd',
        },
      },
      {
        label: '預定交期',
        fn: CalendarRanger,
        registerName: 'reserveDate',
        isDate: true,
        props: {
          width: 300,
          clearable: true,
          placeholder: 'yyyy/mm/dd',
        },
      },
      {
        label: '訂單類型',
        fn: MultipleSelect,
        registerName: 'orderTypes',
        props: {
          mode: 'multiple',
          width: 260,
          options: orderTypes,
          clearable: true,
          placeholder: '請選擇',
        },
      },
      {
        label: '訂單狀態',
        fn: MultipleSelect,
        registerName: 'orderStatus',
        props: {
          mode: 'multiple',
          width: 160,
          options: orderStatus,
          clearable: true,
          placeholder: '請選擇',
        },
      },
      {
        label: '產品料號',
        fn: SearchInput,
        registerName: 'productNumber',
        props: {
          width: 160,
          clearable: true,
          placeholder: '請輸入',
          className: styles.input,
          name: 'productNumber',
          register: methods.register,
          setValue: methods.setValue,
        },
      },
      {
        label: '客戶名稱',
        fn: AutoComplete,
        registerName: 'clientName',
        props: {
          width: 160,
          options: customers,
          placeholder: '請選擇',
          getValues: methods.getValues,
        },
      },
      {
        label: '客戶PO',
        fn: SearchInput,
        registerName: 'customerPoNumber',
        props: {
          width: 160,
          clearable: true,
          placeholder: '請輸入',
          className: styles.input,
          name: 'customerPoNumber',
          register: methods.register,
          setValue: methods.setValue,
        },
      },
      {
        label: '訂單編號',
        fn: SearchInput,
        registerName: 'orderNumber',
        props: {
          width: 160,
          clearable: true,
          placeholder: '請輸入',
          className: styles.input,
          name: 'orderNumber',
          register: methods.register,
          setValue: methods.setValue,
        },
      },
      {
        label: '出貨單號',
        fn: SearchInput,
        registerName: 'deliveryOrderId',
        props: {
          width: 160,
          clearable: true,
          placeholder: '請輸入',
          className: styles.input,
          name: 'deliveryOrderId',
          register: methods.register,
          setValue: methods.setValue,
        },
      },
      {
        label: '發票號碼',
        fn: SearchInput,
        registerName: 'invoiceNumber',
        props: {
          width: 160,
          clearable: true,
          placeholder: '請輸入',
          className: styles.input,
          name: 'invoiceNumber',
          register: methods.register,
          setValue: methods.setValue,
        },
      },
      {
        label: 'BU',
        fn: MultipleSelect,
        registerName: 'bu',
        props: {
          mode: 'multiple',
          width: 100,
          options: bu,
          clearable: true,
          placeholder: '請選擇',
        },
      },
    ];
  }, [ordersFiltersData]);

  const registers = useMemo(() => {
    methods.watch();
    return filtersData.map((data) => ({
      registerName: data.registerName,
      value: methods.getValues(data.registerName),
    }));
  }, [methods.watch()]);

  const registerDependencies = useMemo(
    () => registers.map((register) => register.value),
    [registers]
  );

  const deleteAllTags = useCallback(() => {
    filtersData.forEach((filter) => {
      methods.setValue(filter.registerName, undefined);
    });
  }, [methods]);

  useEffect(() => {
    filterMethods(registers);
  }, registerDependencies);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <Typography variant="h4">查詢</Typography>
        <Icon icon={ChevronUpIcon} />
      </div>
      <div className={styles.border}></div>
      <FormFieldsWrapper className={styles.filters} methods={methods}>
        {filtersData.map((data) =>
          data.fn(data.label, data.props, data.registerName)
        )}
        <div className={styles.buttonWrapper}>
          <Button variant="text" type="button" onClick={deleteAllTags}>
            清空條件
          </Button>
        </div>
      </FormFieldsWrapper>
    </div>
  );
}
