import { TableColumn } from '@mezzanine-ui/core/table';

import {
  DispatchMaterialRecordProps,
  InputMaterialTableProps,
  Modals,
} from './types';

import { Button, ModalHeader, Table } from '@mezzanine-ui/react';

import { CollapsibleContainer, useTargetModal } from '@solar/templates';
import {
  useMaterialRequestOrder,
  useMaterialRequestOrderLogs,
} from '@solar/data';
import { ModalLayout } from '../../Material/ModalLayout/ModalLayout';

export type DispatchMaterialViewModalProps = {
  orderId: string;
};

export function DispatchMaterialViewModal() {
  const modalController = useTargetModal<DispatchMaterialViewModalProps>(
    Modals.DISPATCH_MATERIAL_VIEW
  );

  const { data: modalProps } = modalController;

  const { data: inputNeeds, isLoading: materialRequestOrderSpecsLoading } =
    useMaterialRequestOrder({
      orderId: modalProps?.orderId,
    });

  const { logs } = useMaterialRequestOrderLogs(modalProps?.orderId ?? '');
  const inputMaterialColumns: TableColumn<InputMaterialTableProps>[] = [
    { title: '品名', dataIndex: 'material.description', ellipsis: false },
    {
      title: '料號',
      dataIndex: 'materialId',
      width: 500,
    },
    {
      title: '批號',
      render: (source) =>
        `${source['materialSapBatchId'] ?? ''}-${
          source['materialBatchId'] ?? ''
        }`,
    },
    { title: '儲存位置', dataIndex: 'loader.shelfId' },
    { title: '載具', dataIndex: 'loaderId' },
    {
      title: '應發數量',
      dataIndex: 'expectedMainMaterialUnitQuantity',
    },
    {
      title: '已發數量',
      dataIndex: 'actualMainMaterialUnitQuantity',
    },
    { title: '單位', dataIndex: 'mainMaterialUnit', width: 100 },
  ];

  const dispatchMaterialRecordColumns: TableColumn<DispatchMaterialRecordProps>[] =
    [
      { title: '發料時間', dataIndex: 'issueTime' },
      { title: '領料人', dataIndex: 'picker' },
      { title: '品名', dataIndex: 'materialDescription' },
      { title: '料號', dataIndex: 'materialId' },
      { title: '批號流水', dataIndex: 'lotNumberFlow' },
      { title: '發料位置', dataIndex: 'stackId' },
      { title: '發料載具', dataIndex: 'loaderId' },
      { title: '發料數量', dataIndex: 'theNumberOfMaterialsIssued' },
      { title: '單位', dataIndex: 'unit', width: 100 },
    ];

  return (
    <ModalLayout {...modalController}>
      <ModalHeader>{`發料單號：${modalProps?.orderId}`}</ModalHeader>
      {/* <div style={{ display: 'flex', gap: 'var(--mzn-spacing-4)' }}>
        <Button
          variant="outlined"
          type="button"
          onClick={() => {
            window.open(
              `/print-management/work-order?tab=work-order&workOrderId=${inputNeeds?.workOrderId}`,
              '_blank'
            );
          }}
        >
          列印工單 {inputNeeds?.workOrderId}
        </Button>
        <Button
          variant="outlined"
          type="button"
          onClick={() => {
            window.open(
              `/print-management/work-order?tab=dispatch-material-order&workOrderId=${inputNeeds?.workOrderId}&materialRequestOrderId=${inputNeeds?.materialRequestOrderSpecs?.[0]?.materialRequestOrderId}`,
              '_blank'
            );
          }}
        >
          列印發料單{' '}
          {inputNeeds?.materialRequestOrderSpecs?.[0]?.materialRequestOrderId}
        </Button>
      </div> */}
      <br />
      <CollapsibleContainer header="" enableRowBlockStyle open>
        <Table
          columns={[
            {
              title: '目標料號',
              dataIndex: 'materialId',
            },
            {
              title: '目標批號',
              render: (source) =>
                `${source?.materialSapBatchId ?? ''}-${
                  source?.materialBatchId ?? ''
                }`,
            },
          ]}
          dataSource={inputNeeds?.materialRequestOrderBatches ?? []}
        />
      </CollapsibleContainer>
      <br />
      {/* <Descriptions>
        {inputNeeds?.materialRequestOrderBatches.map((row) => {
          return (
            <>
              <Descriptions.Item label="目標料號">
                {row?.materialId}
              </Descriptions.Item>
              <Descriptions.Item label="目標批號">
                {row?.materialSapBatchId ?? ''}-{row?.materialBatchId ?? ''}
              </Descriptions.Item>
            </>
          );
        })}
      </Descriptions> */}
      <CollapsibleContainer header="投料需求" enableRowBlockStyle open>
        <Table
          scroll={{ x: 1800 }}
          columns={inputMaterialColumns}
          loading={materialRequestOrderSpecsLoading}
          dataSource={inputNeeds?.materialRequestOrderSpecs ?? []}
        />
      </CollapsibleContainer>
      <br />
      <CollapsibleContainer header="發料紀錄" enableRowBlockStyle open>
        <Table
          scroll={{ x: 1800 }}
          columns={dispatchMaterialRecordColumns}
          dataSource={logs}
        />
      </CollapsibleContainer>
    </ModalLayout>
  );
}
