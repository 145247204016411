import useSWR from 'swr';
import {
  Area,
  Building,
  Factory,
  Floor,
  LocationLevel,
  Shelf,
  Stack,
  Zone,
} from '../types';
import { useMemo, useState } from 'react';
import { useLocationControlContext } from '../LocationControlContext';

export function useGetFactories() {
  const { data, error, isValidating } = useSWR<Factory[]>('/factorys');

  return {
    options: data ?? [],
    error,
    loading: !data && !error && isValidating,
  };
}

export function useGetBuildings(active = true) {
  const { locationIds } = useLocationControlContext();
  const factoryId = locationIds[LocationLevel.FACTORY];
  const [searchTerm, setSearchTerm] = useState<string>();

  const params = useMemo(
    () =>
      active
        ? {
            factoryId,
            searchTerm: searchTerm || locationIds[LocationLevel.FACTORY],
          }
        : null,
    [active, factoryId, locationIds, searchTerm]
  );

  const { data, error, isValidating } = useSWR<Building[]>(
    params ? ['/buildings', { params }] : null
  );

  return {
    options: data ?? [],
    error,
    loading: !data && !error && isValidating,
    onSearch: setSearchTerm,
  };
}

export function useGetFloors(active = true) {
  const { locationIds } = useLocationControlContext();
  const buildingId = locationIds[LocationLevel.BUILDING];
  const [searchTerm, setSearchTerm] = useState<string>();

  const params = useMemo(
    () =>
      active
        ? {
            buildingId,
            searchTerm:
              searchTerm ||
              locationIds[LocationLevel.BUILDING] ||
              locationIds[LocationLevel.FACTORY],
          }
        : null,
    [active, buildingId, locationIds, searchTerm]
  );

  const { data, error, isValidating } = useSWR<Floor[]>(
    params ? ['/floors', { params }] : null
  );

  return {
    options: data ?? [],
    error,
    loading: !data && !error && isValidating,
    onSearch: setSearchTerm,
  };
}

export function useGetAreas(active = true) {
  const { locationIds } = useLocationControlContext();
  const floorId = locationIds[LocationLevel.FLOOR];
  const [searchTerm, setSearchTerm] = useState<string>();

  const params = useMemo(
    () =>
      active
        ? {
            floorId,
            searchTerm:
              searchTerm ||
              locationIds[LocationLevel.FLOOR] ||
              locationIds[LocationLevel.BUILDING] ||
              locationIds[LocationLevel.FACTORY],
          }
        : null,
    [active, floorId, locationIds, searchTerm]
  );

  const { data, error, isValidating } = useSWR<Area[]>(
    params ? ['/areas', { params }] : null
  );

  return {
    options: data ?? [],
    error,
    loading: !data && !error && isValidating,
    onSearch: setSearchTerm,
  };
}

export function useGetZones(active = true) {
  const { locationIds } = useLocationControlContext();
  const areaId = locationIds[LocationLevel.AREA];
  const [searchTerm, setSearchTerm] = useState<string>();

  const params = useMemo(
    () =>
      active
        ? {
            areaId,
            searchTerm:
              searchTerm ||
              locationIds[LocationLevel.AREA] ||
              locationIds[LocationLevel.FLOOR] ||
              locationIds[LocationLevel.BUILDING] ||
              locationIds[LocationLevel.FACTORY],
          }
        : null,
    [active, areaId, locationIds, searchTerm]
  );

  const { data, error, isValidating } = useSWR<Zone[]>(
    params ? ['/zones', { params }] : null
  );

  return {
    options: data ?? [],
    error,
    loading: !data && !error && isValidating,
    onSearch: setSearchTerm,
  };
}

export function useGetStacks(active = true) {
  const { locationIds } = useLocationControlContext();
  const zoneId = locationIds[LocationLevel.ZONE];
  const [searchTerm, setSearchTerm] = useState<string>();

  const params = useMemo(
    () =>
      active
        ? {
            zoneId,
            searchTerm:
              searchTerm ||
              locationIds[LocationLevel.ZONE] ||
              locationIds[LocationLevel.AREA] ||
              locationIds[LocationLevel.FLOOR] ||
              locationIds[LocationLevel.BUILDING] ||
              locationIds[LocationLevel.FACTORY],
          }
        : null,
    [active, zoneId, searchTerm, locationIds]
  );

  const { data, error, isValidating } = useSWR<Stack[]>(
    params ? ['/stacks', { params }] : null
  );

  return {
    options: data ?? [],
    error,
    loading: !data && !error && isValidating,
    onSearch: setSearchTerm,
  };
}

export function useGetShelves(active = true) {
  const { locationIds } = useLocationControlContext();
  const stackId = locationIds[LocationLevel.STACK];
  const [searchTerm, setSearchTerm] = useState<string>();

  const params = useMemo(
    () =>
      active
        ? {
            stackId,
            searchTerm:
              searchTerm ||
              locationIds[LocationLevel.STACK] ||
              locationIds[LocationLevel.ZONE] ||
              locationIds[LocationLevel.AREA] ||
              locationIds[LocationLevel.FLOOR] ||
              locationIds[LocationLevel.BUILDING] ||
              locationIds[LocationLevel.FACTORY],
          }
        : null,
    [active, stackId, searchTerm, locationIds]
  );

  const { data, error, isValidating } = useSWR<Shelf[]>(
    params ? ['/shelves', { params }] : null
  );

  return {
    options: data ?? [],
    error,
    loading: !data && !error && isValidating,
    onSearch: setSearchTerm,
  };
}
