import type { HubOrderUpdateFormValues } from '@solar/data';
import { HubOrderUpdateFormFieldName as FormFieldName } from '@solar/data';
import { useFormContext } from 'react-hook-form';
import { InputField } from '@mezzanine-ui/react-hook-form';

export function OrderDeliveryIdInput({ id }: { id?: string }) {
  const formMethods = useFormContext<HubOrderUpdateFormValues>();

  return (
    <>
      <div>出貨單號：</div>
      <div>
        <div style={{ display: 'none' }}>
          <InputField
            control={formMethods.control}
            registerName={FormFieldName.HUB_DELIVERY_ORDER_ID}
            defaultValue={id}
          />
        </div>
        {id || '-'}
      </div>
    </>
  );
}
