import {
  useInventoryByPurchaseOrders,
  useInventoryByPurchaseOrder,
  useInventoryByCustomerSupplyOrders,
  useInventoryByCustomerSupplyOrder,
} from '@solar/data';

export const pageOptions = [
  {
    id: 'storage-purchase-order',
    name: '庫存採購單',
    fetchOrders: useInventoryByPurchaseOrders,
    fetchOrder: useInventoryByPurchaseOrder,
  },
  {
    id: 'misc-purchase-order',
    name: '雜項採購單',
    fetchOrders: useInventoryByPurchaseOrders,
    fetchOrder: useInventoryByPurchaseOrder,
  },
  {
    id: 'outsource-purchase-order',
    name: '委外加工採購單',
    fetchOrders: useInventoryByPurchaseOrders,
    fetchOrder: useInventoryByPurchaseOrder,
  },
  {
    id: 'customer-order',
    name: '客供料',
    fetchOrders: useInventoryByCustomerSupplyOrders,
    fetchOrder: useInventoryByCustomerSupplyOrder,
  },
];
