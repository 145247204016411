import {
  Button,
  Input,
  MenuDivider,
  Message,
  SelectValue,
  Table,
  Typography,
} from '@mezzanine-ui/react';
import {
  InventoryByMaterialSearchType,
  updateProductionWorkOrderShip,
  useGetProductionWorkOrder,
  useModalController,
} from '@solar/data';
import { Descriptions, message } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  MaterialSupplyByMaterialModal,
  MaterialSupplyByMaterialModalData,
} from './MaterialSupplyByMaterialModal';
import {
  MaterialSupplyByLoaderModal,
  MaterialSupplyByLoaderModalData,
} from './MaterialSupplyByLoaderModal';
import {
  LOCATION_SELECTOR,
  LocationSelectorGroupModal,
  ModalGroup,
  PageLayout,
  useModalGroupController,
} from '@solar/templates';
import { useForm } from 'react-hook-form';

type TmpShip = {
  id: string;
  specId: number;
  materialId: string;
  materialDescription: string;
  batchId: string;
  loaderId: number;
  stackId: string;
  mainMaterialUnitQuantity: number;
};

export function MaterialSupplyToReturnedOrderPage() {
  const navigate = useNavigate();
  const params = useParams<{ returnedOrderId: string }>();

  const [materialBarcode, setMaterialBarcode] = useState('');
  const [staffId, setStaffId] = useState('');

  const methods = useForm<{ loader: SelectValue }>();

  const { productionWorkOrderDetail, materialRequestList } =
    useGetProductionWorkOrder({ workOrderId: params?.returnedOrderId });
  const [tmpShipList, setTmpShipList] = useState<TmpShip[]>([]);

  const materialSupplyByMaterialModalController =
    useModalController<MaterialSupplyByMaterialModalData>();
  const materialSupplyByLoaderModalController =
    useModalController<MaterialSupplyByLoaderModalData>();
  const modalGroupController = useModalGroupController([
    { name: LOCATION_SELECTOR },
  ]);

  const handleAddItems = (items: TmpShip[]) => {
    setTmpShipList((prevItems) => [
      ...items,
      ...prevItems.filter(
        (prevItem) => !items.some((item) => item?.id === prevItem?.id)
      ),
    ]);
  };

  const handleSave = async () => {
    try {
      await updateProductionWorkOrderShip(
        params?.returnedOrderId ?? '',
        tmpShipList?.map((item) => ({
          ...item,
          loaderId: methods?.getValues('loader')?.id || null,
        }))
      );

      message.success('發料成功', 1000);
      setTimeout(() => navigate(0), 1000);
    } catch (error) {
      message.error('發料失敗');
    }
  };

  return (
    <PageLayout title="退貨單發料">
      <Descriptions>
        <Descriptions.Item label="退貨單號">
          {productionWorkOrderDetail?.id ?? ''}
        </Descriptions.Item>
        <Descriptions.Item label="建立日">
          {productionWorkOrderDetail?.expectedCompletedAt
            ? moment(productionWorkOrderDetail?.expectedCompletedAt).format(
                'YYYY/MM/DD'
              )
            : ''}
        </Descriptions.Item>
        <Descriptions.Item label="開單人員">
          {productionWorkOrderDetail?.staffName ?? ''}
        </Descriptions.Item>
      </Descriptions>
      <Typography variant="h5">需退貨物料</Typography>
      <Table
        style={{ height: '500px' }}
        columns={[
          {
            title: '品名',
            dataIndex: 'materialDescription',
          },
          {
            title: '料號',
            dataIndex: 'materialId',
          },
          {
            title: '舊料號',
            dataIndex: 'oldMaterialId',
          },
          {
            title: '批號',
            dataIndex: 'materialBatchId',
          },
          {
            title: '存放位置',
            dataIndex: 'location',
          },
          {
            title: '應退數量',
            dataIndex: 'expectedMainMaterialUnitQuantity',
          },
          {
            title: '已退數量',
            dataIndex: 'shippedMainMaterialUnitQuantity',
          },
        ]}
        dataSource={
          materialRequestList?.map((item) => ({ ...item, id: item?.specId })) ??
          []
        }
      />
      <div
        style={{
          display: 'flex',
          gap: 'var(--mzn-spacing-2)',
          alignItems: 'center',
        }}
      >
        <Typography variant="input1">物料條碼</Typography>
        <Input
          value={materialBarcode}
          onChange={(event) => setMaterialBarcode(event.target.value)}
          inputProps={{
            onKeyDown: (event) => {
              if (event.key === 'Enter') {
                if (!materialBarcode) {
                  Message.warning('請輸入物料條碼');
                  return;
                }

                materialSupplyByMaterialModalController.openModal({
                  type: InventoryByMaterialSearchType.MATERIAL_BARCODE,
                  materialBarcode,
                  productionWorkOrder: productionWorkOrderDetail,
                });
              }
            },
          }}
        />
      </div>
      <MenuDivider />
      {/* <LocationSelectorProvider controller={controller}>
        <FormFieldsWrapper methods={methods} onSubmit={async () => {}}>
          <WarehouseAndVehicleFilter
            openLocationSelectorModal={() =>
              modalGroupController.openModal(LOCATION_SELECTOR, null)
            }
            loaders={loaders}
            ref={warehouseAndVehicleFilterRef}
          />
        </FormFieldsWrapper>
      </LocationSelectorProvider> */}

      <MenuDivider />
      <Typography variant="h5">退貨清單</Typography>
      <Table
        style={{ height: '500px' }}
        columns={[
          {
            title: '品名',
            dataIndex: 'materialDescription',
          },
          {
            title: '料號',
            dataIndex: 'materialId',
          },
          {
            title: '舊料號',
            dataIndex: 'oldMaterialId',
          },
          {
            title: '批號',
            dataIndex: 'batchId',
          },
          {
            title: '存放位置',
            dataIndex: 'stackId',
          },
          {
            title: '退貨數量',
            dataIndex: 'mainMaterialUnitQuantity',
          },
          {
            title: '操作',
            render: (source: any) => (
              <Button
                danger
                onClick={() =>
                  setTmpShipList((prevItems) =>
                    prevItems.filter((prevItem) => prevItem?.id !== source?.id)
                  )
                }
              >
                移除
              </Button>
            ),
          },
        ]}
        dataSource={tmpShipList ?? []}
      />
      <div
        style={{
          display: 'flex',
          gap: 'var(--mzn-spacing-2)',
          alignItems: 'center',
          justifyContent: 'end',
        }}
      >
        <Button
          variant="contained"
          disabled={staffId === ''}
          onClick={handleSave}
        >
          退貨
        </Button>
      </div>
      <MaterialSupplyByMaterialModal
        {...materialSupplyByMaterialModalController}
        tmpShipList={tmpShipList}
        onSubmit={handleAddItems}
      />
      <MaterialSupplyByLoaderModal
        {...materialSupplyByLoaderModalController}
        tmpShipList={tmpShipList}
        onSubmit={handleAddItems}
      />
      <ModalGroup {...modalGroupController}>
        <LocationSelectorGroupModal onSubmit={() => ''} />
      </ModalGroup>
    </PageLayout>
  );
}
