import { Table } from '@mezzanine-ui/react';
import { useGetMaterialSourceCodes } from './hooks';

export function MaterialSourceCodePanel() {
  const { sourceCodes, isLoading } = useGetMaterialSourceCodes();

  return (
    <Table
      loading={isLoading}
      dataSource={sourceCodes ?? []}
      scroll={{ y: 550 }}
      columns={[
        { width: 150, title: '代碼', dataIndex: 'id' },
        { title: '對應值', dataIndex: 'name' },
      ]}
    />
  );
}
