import { TableColumn, TableDataSource } from '@mezzanine-ui/core/table';
import { ChevronDownIcon } from '@mezzanine-ui/icons';
import { Button, Icon, Table } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { useCallback, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import styles from './index.module.scss';
import { checkJWTRoles } from '@solar/data';

export type FilterType = {
  label: string;
  fn: Function;
  registerName?: string;
  props?: object;
  customComponent?: boolean;
};

export type ExpandableType = {
  expandedRowRender: (record: any) => JSX.Element;
  rowExpandable: (record: any) => boolean;
};

export interface AuthListLayoutProps {
  title: string;
  titleButton: JSX.Element;
  filtersData: FilterType[][];
  columns: TableColumn<any>[];
  dataSource: TableDataSource[];
  tableTotal: number;
  tableCurrent: number;
  onChangeCurrentPage: (page: number) => void;
  tablePageSize: number;
  onChangePageSize: (pageSize: number) => void;
  methods: UseFormReturn<FieldValues, any>;
  expandable?: ExpandableType;
  onSearch: () => void;
  noPagination?: boolean;
}

export function AuthListLayout({
  title,
  titleButton,
  filtersData,
  columns,
  dataSource,
  tableTotal,
  tableCurrent,
  onChangeCurrentPage,
  tablePageSize,
  onChangePageSize,
  methods,
  expandable,
  onSearch,
  noPagination,
}: AuthListLayoutProps) {
  const [expandFilter, setExpandFilter] = useState(true);

  const toggleExpandIcon = useCallback(
    () => setExpandFilter((expand) => !expand),
    [setExpandFilter]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <h1>{title}</h1>
        {checkJWTRoles([61]) && titleButton}
      </div>
      <div className={styles.filterWrapper}>
        <div className={styles.filterTitleWrapper}>
          <h2>查詢</h2>
          <Icon
            icon={ChevronDownIcon}
            onClick={toggleExpandIcon}
            className={expandFilter ? styles.noRotateIcon : styles.rotateIcon}
          />
        </div>
        {expandFilter && (
          <FormFieldsWrapper
            methods={methods}
            className={styles.formFieldsWrapper}
          >
            {filtersData.map((filters, index) => (
              <div key={index} className={styles.filters}>
                {filters.map((filter) =>
                  filter.customComponent ? (
                    <div key={`customComponent-${filter.label}`}>
                      {filter.fn()}
                    </div>
                  ) : (
                    filter.fn(filter.label, filter.props, filter.registerName)
                  )
                )}

                {index === filtersData.length - 1 && (
                  <div className={styles.submitButton}>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={onSearch}
                    >
                      送出查詢
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </FormFieldsWrapper>
        )}
      </div>
      <div className={styles.tableWrapper}>
        {noPagination ? (
          <Table
            expandable={expandable}
            bodyClassName={styles.table}
            columns={columns}
            dataSource={dataSource}
          />
        ) : (
          <Table
            expandable={expandable}
            bodyClassName={styles.table}
            columns={columns}
            dataSource={dataSource}
            pagination={{
              disableAutoSlicing: true,
              total: tableTotal,
              current: tableCurrent,
              options: {
                pageSize: tablePageSize,
                onChangePageSize,
                showPageSizeOptions: true,
                pageSizeLabel: '每頁顯示：',
                pageSizeUnit: '筆',
              },
              onChange: onChangeCurrentPage,
            }}
          />
        )}
      </div>
    </div>
  );
}
