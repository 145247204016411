import type { ProductionPlanningOrdersExportFormValues } from '@solar/data';
import { PPOrdersExportFormFieldName as FormFieldName } from '@solar/data';
import { useFormContext } from 'react-hook-form';
import { DatePickerField } from '@mezzanine-ui/react-hook-form';
import { CancelIcon } from '@mezzanine-ui/icons';
import { Icon } from '@mezzanine-ui/react';

export function CustomerOrderDatePicker() {
  const formMethods =
    useFormContext<ProductionPlanningOrdersExportFormValues>();

  return (
    <>
      <div>訂單日期：</div>
      <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <DatePickerField
          control={formMethods.control}
          registerName={FormFieldName.ORDER_DATE_START}
        />
        <DatePickerField
          control={formMethods.control}
          registerName={FormFieldName.ORDER_DATE_END}
        />
        <Icon
          icon={CancelIcon}
          style={{
            fontSize: '1.15rem',
            marginLeft: '-0.25rem',
            color: 'GrayText',
          }}
          onClick={() => {
            formMethods.resetField(FormFieldName.ORDER_DATE_START);
            formMethods.resetField(FormFieldName.ORDER_DATE_END);
          }}
        />
      </div>
    </>
  );
}
