import {
  useStrikePrice,
  SPFormOrderItemInfo,
  insertSPOrder,
  convertToSPAPIOrderItemInfo,
  convertToSPAPIOrderInfo,
  getSPOrderDetail,
  convertSPAPIToForm,
  convertToSPFormOrderItemInfo,
  SPFormOrderInfo,
  SPEditPageMode,
  ISPEditPageMode,
  updateSPOrder,
  SPFormOrderInfoAddSchema,
  SPFormOrderItemInfoApproveSchema,
  SPMatchItem,
  matchSPOrder,
} from '@solar/data';
import jwtDecode from 'jwt-decode';
import { yupResolver } from '@hookform/resolvers/yup';
import { PageLayout } from '../Templates/PageLayout/PageLayout';
import SPOrderForm from './components/SPOrderForm';
import SPOrderItemTable from './components/SPOrderItemTable';
import SPItemFormModal from './components/SPItemFormModal';
import { useEffect, useState } from 'react';
import {
  Button,
  Message,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  SelectValue,
} from '@mezzanine-ui/react';
import { useForm } from 'react-hook-form';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import SPPurchaseMatchModal from './components/SPPurchaseMatchModal';
import SPItemMatchModal, {
  IMatchFinishParams,
} from './components/SPItemMatchModal';

const getUserInfo = (): SelectValue<string> => {
  try {
    const jsonData: any = jwtDecode(
      localStorage.getItem('accessToken') || '{}'
    );
    const { Account, WorkerNumber } = jsonData;
    return { name: `${WorkerNumber}-${Account}`, id: WorkerNumber };
  } catch (e) {
    return { name: '', id: '' };
  }
};

const StrikePriceUpsert = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  // 判斷頁面目前狀態，是新增、編輯、審核
  const isAddMode = pathname === '/strike-price/add';
  const isEditMode = pathname.startsWith('/strike-price/edit') && id;
  const isApproveMode = pathname.startsWith('/strike-price/validate') && id;
  const isViewMode = pathname.startsWith('/strike-price/view') && id;
  const isMatchMode = pathname.startsWith('/strike-price/match') && id;

  let pageMode: ISPEditPageMode = SPEditPageMode.ADD;

  let title = '';

  if (isAddMode) {
    pageMode = SPEditPageMode.ADD;
    title = '新增敲價單';
  } else if (isViewMode) {
    pageMode = SPEditPageMode.VIEW;
    title = '檢視敲價單';
  } else if (isEditMode) {
    pageMode = SPEditPageMode.EDIT;
    title = '編輯敲價單';
  } else if (isApproveMode) {
    pageMode = SPEditPageMode.APPROVE;
    title = '審核敲價單';
  } else if (isMatchMode) {
    pageMode = SPEditPageMode.MATCH;
    title = '對應敲價單';
  }
  const formOption = {
    resolver: yupResolver(
      isApproveMode
        ? SPFormOrderItemInfoApproveSchema
        : SPFormOrderInfoAddSchema
    ),
  };

  const spForm = useForm<SPFormOrderInfo>(formOption);
  const {
    autoCompleteOptionsMaterial,
    autoCompleteOptionsSales,
    autoCompleteReportPriceState,
    autoCompleteOptionsComponentNames,
    autoCompleteOptionsCustomer,
    reportPriceType,
  } = useStrikePrice();
  const navigate = useNavigate();
  const [items, setItems] = useState<SPFormOrderItemInfo[]>([]);
  const [itemModalOpen, setItemModalOpen] = useState(false);
  const [matchModalOpen, setMatchModalOpen] = useState(false);
  const [nowItemInfo, setNowItemInfo] = useState<SPFormOrderItemInfo | null>(
    null
  );
  const [submitLoading, setSubmitLoading] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [matchList, setMatchList] = useState<SPMatchItem[]>([]);
  const [unBindList, setUnBindList] = useState<SPMatchItem[]>([]);
  const onClickEdit = (key: string) => {
    const newInfo = items.find((item) => item.key === key);
    if (newInfo) {
      setNowItemInfo(newInfo);
      setItemModalOpen(true);
    }
  };

  const onClickMatch = (key: string) => {
    const newInfo = items.find((item) => item.key === key);
    if (newInfo) {
      setNowItemInfo(newInfo);
      setMatchModalOpen(true);
    }
  };

  const onClickAddItem = () => {
    setNowItemInfo({
      key: Math.random().toString(),
    });
    setItemModalOpen(true);
  };

  const onClickDelete = (key: string) => {
    const newItems = items.filter((item) => item.key !== key);
    setItems(newItems);
  };

  const onModalFinish = (item?: SPFormOrderItemInfo) => {
    if (item) {
      // find item by key
      const index = items.findIndex((i) => i.key === item.key);
      if (index === -1) {
        setItems([...items, item]);
      } else {
        const newItems = [...items];
        newItems[index] = item;
        setItems(newItems);
      }
    }
    setNowItemInfo(null);
    setItemModalOpen(false);
  };

  const onFormCheck = (formData: SPFormOrderInfo) => {
    submit(formData, true);
  };

  const onFormFinish = (formData: SPFormOrderInfo) => {
    submit(formData);
  };

  const submit = async (formData: SPFormOrderInfo, quickCheck = false) => {
    setSubmitLoading(true);
    const data = {
      ...convertToSPAPIOrderInfo(formData),
      items: items.map(convertToSPAPIOrderItemInfo),
      quickCheck,
    };
    const upsertFunc = isAddMode ? insertSPOrder : updateSPOrder;
    try {
      const response = await upsertFunc(data);
      if (quickCheck && response.msg) {
        setResultMessage(response.msg);
      } else {
        setResultMessage('儲存成功');
      }
    } catch (e: any) {
      // 先判斷 e.message 是不是 JSON 格式的字串，如果是的話，就是後端回傳的錯誤訊息
      // 如果不是的話，就是其他錯誤，直接顯示 e.message
      if (e.message && e.message.startsWith('{')) {
        const error = JSON.parse(e.message);
        Message.error(error.message);
      } else {
        Message.error('發生錯誤');
      }
    }
    setSubmitLoading(false);
  };

  const getOrderDetailById = async (id: string) => {
    try {
      const response = await getSPOrderDetail(id);
      // console.log(response);
      const d = convertSPAPIToForm(
        response,
        reportPriceType,
        autoCompleteReportPriceState
      );
      if (isApproveMode) {
        if (!d.dealDate) {
          d.dealDate = new Date().toISOString();
        }
        d.checkUser = getUserInfo();
      }
      spForm.reset(d);
      if (Array.isArray(response.items) && response.items.length) {
        const formItems = response.items.map(convertToSPFormOrderItemInfo);
        setItems(formItems);
      }
      if (Array.isArray(response.matchList) && response.matchList.length) {
        setMatchList(response.matchList);
      }
      if (
        Array.isArray(response.purchaseMatchList) &&
        response.purchaseMatchList.length
      ) {
        setMatchList(response.purchaseMatchList);
      }
      setUnBindList([]);
    } catch (e: any) {
      Message.error('發生錯誤');
      navigate('/');
    }
  };

  // mount 的時候去檢查頁面資訊，如果網址是 strike-price/add 就是新增模式
  // 如果是 strike-price/edit/:id 就是編輯模式
  // 編輯模式下要檢查有沒有 id 如果沒有就要回到上一頁
  useEffect(() => {
    if (isAddMode) {
      spForm.reset({
        reportDate: new Date().toISOString(),
        reportState: { id: '0', name: '未確認' },
        // 預設帶入目前登入人員
        reportUser: getUserInfo(),
      });
    } else if (id) {
      // 如果是編輯模式或是審核模式，要檢查有沒有 id
      // 取得詳細資訊
      if (reportPriceType.length && autoCompleteReportPriceState.length) {
        getOrderDetailById(id);
      }
    } else {
      // 回到上一頁
      navigate(-1);
    }
  }, [pathname, id, reportPriceType, autoCompleteReportPriceState]);

  const [tm, rm, rt] = spForm.watch([
    'tradeMode',
    'reportMode',
    'reportType.id',
  ]);
  // 當交易模式、報價模式、報價類型有任何一個沒有值的時候，新增項次的按鈕要 disable
  const disabledAdd = !tm || !rm || !rt || pageMode !== SPEditPageMode.ADD;
  // 當交易模式、報價模式、報價類型有任何一個有改變的時候，清空項次
  const modifyItems = (newItems?: SPFormOrderItemInfo[]) => {
    if (newItems) {
      setItems(newItems);
    } else {
      setItems([]);
    }
  };

  const onMatchFinish = (data?: IMatchFinishParams) => {
    // console.log(data);
    if (data) {
      setItems(
        items.map((item) => {
          if (item.id === nowItemInfo?.id) {
            return {
              ...item,
              unMatchQty: data?.unMatchQty || 0,
            };
          }
          return item;
        })
      );
      const newMatchLlist = matchList.filter(
        (d) => d.reportPriceItemId !== nowItemInfo?.id
      );
      setMatchList([...newMatchLlist, ...data.matchList]);
      const newUbbindList = unBindList.filter(
        (d) => d.reportPriceItemId !== nowItemInfo?.id
      );
      setUnBindList([...newUbbindList, ...data.unbindList]);
    }

    setNowItemInfo(null);
    setMatchModalOpen(false);
  };

  const onSubmitMatch = async () => {
    setSubmitLoading(true);
    const data = {
      matchList,
      unBindList,
      type: tm, // 2024/10/09 根據新需求:把 trademode 告訴後端
    };
    // console.log(data);
    try {
      await matchSPOrder(data);
      setResultMessage('儲存成功');
    } catch (e: any) {
      // 先判斷 e.message 是不是 JSON 格式的字串，如果是的話，就是後端回傳的錯誤訊息
      // 如果不是的話，就是其他錯誤，直接顯示 e.message
      if (e.message && e.message.startsWith('{')) {
        const error = JSON.parse(e.message);
        Message.error(error.message);
      } else {
        Message.error('發生錯誤');
      }
    }
    setSubmitLoading(false);
  };

  return (
    <PageLayout title={title}>
      <SPOrderForm
        reportPriceType={reportPriceType}
        autoCompleteOptionsMaterial={autoCompleteOptionsMaterial}
        autoCompleteOptionsSales={autoCompleteOptionsSales}
        autoCompleteReportPriceState={autoCompleteReportPriceState}
        autoCompleteOptionsCustomer={autoCompleteOptionsCustomer}
        autoCompleteOptionsComponentNames={autoCompleteOptionsComponentNames}
        formMethods={spForm}
        pageMode={pageMode}
        modifyItems={modifyItems}
      />
      <Button
        variant="contained"
        onClick={onClickAddItem}
        disabled={disabledAdd}
      >
        新增項次
      </Button>
      <SPOrderItemTable
        dataSource={items}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        onClickMatch={onClickMatch}
        pageMode={pageMode}
        setItems={setItems}
      />
      <SPItemFormModal
        open={itemModalOpen}
        itemInfo={nowItemInfo}
        autoCompleteOptionsComponentNames={autoCompleteOptionsComponentNames}
        onFinish={onModalFinish}
        reportType={rt}
        tradeMode={tm}
        reportMode={rm}
        pageMode={pageMode}
      />
      {
        // 2024/10/09 新需求：如果交易模式買進(tm==='0')，要開啟採購單對應 SPPurchaseMatchModal
        // 如果是賣出，要開啟報價單對應 SPItemMatchModal
      }
      <SPPurchaseMatchModal
        itemInfo={nowItemInfo}
        open={matchModalOpen && tm === '0'}
        onFinish={onMatchFinish}
        matchList={matchList}
        pageMode={pageMode}
      />
      <SPItemMatchModal
        itemInfo={nowItemInfo}
        open={matchModalOpen && tm === '1'}
        onFinish={onMatchFinish}
        matchList={matchList}
        pageMode={pageMode}
      />
      <div>
        {pageMode === SPEditPageMode.MATCH ? (
          <Button
            style={{ marginRight: 12 }}
            variant="contained"
            onClick={onSubmitMatch}
            loading={submitLoading}
          >
            儲存對應
          </Button>
        ) : (
          <Button
            style={{ marginRight: 12 }}
            variant="contained"
            onClick={spForm.handleSubmit(onFormFinish)}
            loading={submitLoading}
            disabled={pageMode === SPEditPageMode.VIEW}
          >
            {pageMode === SPEditPageMode.ADD ? '送出' : '儲存'}
          </Button>
        )}

        {pageMode === SPEditPageMode.EDIT && (
          <Button
            variant="contained"
            onClick={spForm.handleSubmit(onFormCheck)}
            loading={submitLoading}
          >
            快速確認
          </Button>
        )}
      </div>
      {
        // 下面是儲存結果提示訊息，會做成 modal 是因為當快速確認時間超過營業時間要有提示
        // 如果用 Notification/Message 沒辦法保留到回到上一頁
      }
      <Modal open={Boolean(resultMessage)} onClose={() => navigate(-1)}>
        <ModalHeader>結果</ModalHeader>
        <ModalBody>
          <div>{resultMessage}</div>
        </ModalBody>
        <ModalActions
          hideCancelButton
          onConfirm={() => navigate(-1)}
          confirmText="確認"
        />
      </Modal>
    </PageLayout>
  );
};

export default StrikePriceUpsert;
