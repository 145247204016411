import { Table, Typography, SelectValue } from '@mezzanine-ui/react';
import styles from './form-template-page.module.scss';
import {
  API_NAMESPACE,
  NextPagination,
  OffsetBased,
  PageInfo,
  getNextParams,
  useGetEquipments,
  useMaterials,
  useWorkCenters,
} from '@solar/data';
import useSWR from 'swr';
import { FormTemplateResponse } from './typing';
import { useFormTemplateColumns } from './use-form-template-columns';
import {
  AutoCompleteMultiField,
  FormFieldsWrapper,
} from '@mezzanine-ui/react-hook-form';
import { useForm } from 'react-hook-form';
import { FormTemplateCreator } from './FormTemplateCreator';
import { MznPaginationTable } from '../../Templates/PaginationTable/MznPaginationTable';
import { useCallback, useState } from 'react';

interface FilterFormValues {
  equipmentIds: SelectValue[];
  processingIds: SelectValue[];
  materialIds: SelectValue[];
  workCenterIds: SelectValue[];
  recipeIds: SelectValue[];
}

export function FormTemplatePage() {
  const methods = useForm<FilterFormValues>({
    defaultValues: {
      equipmentIds: [],
      processingIds: [],
      materialIds: [],
      workCenterIds: [],
      recipeIds: [],
    },
  });

  const [formTemplatesParams, setFormTemplatesParams] = useState<
    {
      processingIds: string;
      equipmentIds: string[];
      materialIds: string[];
      workCenterIds: string[];
      recipeIds: string[];
    } & OffsetBased
  >({
    processingIds: '',
    equipmentIds: [],
    materialIds: [],
    workCenterIds: [],
    recipeIds: [],
  });

  const { data, error, isValidating } = useSWR<{
    records: FormTemplateResponse[];
    pageInfo: PageInfo;
  }>(
    formTemplatesParams?.processingIds !== ''
      ? [
          `/form-templates`,
          { namespace: API_NAMESPACE.PP, params: formTemplatesParams },
        ]
      : null
  );

  const dataSource = data?.records ?? [];
  const pageInfo = data?.pageInfo;
  const loading = isValidating && !data && !error;

  const refetchGetFormTemplatesWithPagination = useCallback(
    (nextPagination: NextPagination) => {
      const formState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      const processingIds = methods.getValues('processingIds');
      setFormTemplatesParams({
        processingIds: processingIds
          .map((processing) => processing.id)
          .join(','),
        equipmentIds:
          formState.equipmentIds?.map((equipment) => equipment.id) ?? [],
        materialIds:
          formState.materialIds?.map((material) => material.id) ?? [],
        workCenterIds:
          formState.workCenterIds?.map((workCenter) => workCenter.id) ?? [],
        recipeIds: formState.recipeIds?.map((recipe) => recipe.id) ?? [],
        offset,
        limit,
      });
    },
    [methods, pageInfo]
  );

  const { data: processingData } = useSWR<{ id: string; name: string }[]>([
    `/processing`,
    { namespace: API_NAMESPACE.PP },
  ]);

  const columns = useFormTemplateColumns();

  const { equipments } = useGetEquipments();

  const equipmentOptions =
    equipments?.map((equipment) => ({
      id: equipment?.id ?? '',
      name: equipment?.name ?? '',
    })) ?? [];

  const { materials, setParams: setMaterialParams } = useMaterials();

  const materialOptions =
    materials?.map((material) => ({
      id: material?.id ?? '',
      name: material?.id ?? '',
    })) ?? [];

  const { workCenters } = useWorkCenters();

  const workCenterOptions =
    workCenters?.map((workCenter) => ({
      id: workCenter?.id ?? '',
      name: `${workCenter?.name}(${workCenter?.id})` ?? '',
    })) ?? [];

  const { data: recipeData } = useSWR<
    {
      id: string;
      featureCode: {
        code: string;
        name: string;
      };
      group: {
        code: string;
        name: string;
      };
    }[]
  >([
    '/pp/recipes',
    {
      namespace: API_NAMESPACE.PP,
    },
  ]);

  const recipeOptions =
    recipeData?.map((recipe) => ({
      id: recipe?.id ?? '',
      name: recipe?.id ?? '',
    })) ?? [];

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <Typography variant="h2">題庫管理</Typography>
        <FormTemplateCreator />
      </header>
      <FormFieldsWrapper className={styles.form} methods={methods}>
        <AutoCompleteMultiField
          label="設備"
          registerName="equipmentIds"
          options={equipmentOptions ?? []}
          onChange={() =>
            refetchGetFormTemplatesWithPagination({ nextPage: 1 })
          }
        />
        <AutoCompleteMultiField
          label="行為"
          registerName="processingIds"
          options={processingData ?? []}
          onChange={() =>
            refetchGetFormTemplatesWithPagination({ nextPage: 1 })
          }
        />
        <AutoCompleteMultiField
          label="料號"
          registerName="materialIds"
          options={materialOptions ?? []}
          onChange={() =>
            refetchGetFormTemplatesWithPagination({ nextPage: 1 })
          }
          onSearch={(input) => {
            setMaterialParams({ searchTerm: input });
          }}
        />
        <AutoCompleteMultiField
          label="工作中心"
          registerName="workCenterIds"
          options={workCenterOptions ?? []}
          onChange={() =>
            refetchGetFormTemplatesWithPagination({ nextPage: 1 })
          }
        />
        <AutoCompleteMultiField
          label="路徑碼"
          registerName="recipeIds"
          options={recipeOptions ?? []}
          onChange={() =>
            refetchGetFormTemplatesWithPagination({ nextPage: 1 })
          }
        />
      </FormFieldsWrapper>
      <MznPaginationTable
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pageInfo={pageInfo}
        fetchData={refetchGetFormTemplatesWithPagination}
      />
    </div>
  );
}
