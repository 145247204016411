import { Button, Table, Typography } from '@mezzanine-ui/react';
import { useCallback, useMemo, useState } from 'react';
import {
  ReceivableItemTable,
  ReceivedMaterialItemTable,
} from '../IncomingInspectOperationPage/IncomingInspectDetailPage';

import { PageLayout } from '../PageLayout/PageLayout';

import {
  IQCOrderType,
  IncomingPurchaseOrder,
  MiscPurchaseOrderInnerRecord,
  checkJWTRoles,
  useFixedAssetPurchaseOrder,
} from '@solar/data';
import { useParams } from 'react-router-dom';

import { TableColumn } from '@mezzanine-ui/core/table';
import classes from '../IncomingInspectOperationPage/incoming-inspect-detail-page.module.scss';
import sharedStyles from '../shared-styles/index.module.scss';

import {
  CollapsibleContainer,
  ModalGroup,
  useModalGroupController,
} from '@solar/templates';
import { OrderMetaInfo } from '../components';
import { MiscellaneousStorageInModals } from './enum';

import { ReceiveActionModal } from './modals';
import { AcceptanceModal } from './modals/AcceptanceModal';
import { CancelModal } from './modals/CancelModal';
import { IncomingQualityControlTable } from '../../Qualifications/IncomingQualityControlTable/IncomingQualityControlTable';
import { Space } from 'antd';
import { IncomingQualityControlModal } from '../IncomingInspectOperationPage/IncomingQualityControlModal';

const META_TRANSLATION = {
  id: '採購單號',
  supplierName: '供應商名稱',
  supplierId: '供應商代碼',
  staffName: '採購人員',
  expectedCompletedAt: '物料收貨日',
  receivedState: '狀態',
} as const;

export function FixedAssetStorageInDetailPage() {
  const { orderId } = useParams();

  const { orders } = useFixedAssetPurchaseOrder({ orderId });

  const orderMetaDataLabelAndContext = useMemo(
    () =>
      orders
        ? Object.entries(META_TRANSLATION).map(([key, zh]) => ({
            label: zh,
            context: orders[key as keyof typeof META_TRANSLATION],
          }))
        : [],
    [orders]
  );

  const modalGroupController = useModalGroupController([
    { name: MiscellaneousStorageInModals.ReceiveAction, keepData: true },
    { name: MiscellaneousStorageInModals.Acceptance },
    { name: MiscellaneousStorageInModals.Cancel },
    { name: MiscellaneousStorageInModals.QUALITY_CONTROL },
  ]);

  const receiveActionModalOpen = useCallback(
    (item: MiscPurchaseOrderInnerRecord | null) => {
      const modalProps = modalGroupController.getModalProps(
        MiscellaneousStorageInModals.ReceiveAction
      );
      const data = modalProps?.data;
      modalGroupController.openModal(
        MiscellaneousStorageInModals.ReceiveAction,
        { ...data, ...item }
      );
    },
    [modalGroupController]
  );

  const acceptanceModalOpen = useCallback(
    (item: { materialId: string }) => {
      modalGroupController.openModal(MiscellaneousStorageInModals.Acceptance, {
        ...item,
      });
    },
    [modalGroupController]
  );

  const cancelModalOpen = useCallback(
    (item: { materialId: string }) => {
      modalGroupController.openModal(MiscellaneousStorageInModals.Cancel, {
        ...item,
      });
    },
    [modalGroupController]
  );

  const column: TableColumn<MiscPurchaseOrderInnerRecord>[] = useMemo(
    () => [
      {
        title: '操作',
        width: 120,
        align: 'center' as const,
        render(source) {
          return (
            checkJWTRoles([91]) && (
              <Button
                type="button"
                variant="outlined"
                onClick={() => {
                  receiveActionModalOpen(source);
                }}
              >
                收料
              </Button>
            )
          );
        },
      },
      { title: '料號', dataIndex: 'fixedAssetMaterialIdFromSAP' },
      { title: '品名', dataIndex: 'materialDescription' },

      { title: '應收量', dataIndex: 'expectedQuantity' },
      { title: '已收量', dataIndex: 'receivedQuantity' },
      { title: '單位', dataIndex: 'inputUnit' },
    ],
    [receiveActionModalOpen]
  );

  const receivedMaterialColumn: TableColumn<ReceivedMaterialItemTable>[] =
    useMemo(
      () => [
        {
          title: '狀態',
          render: (source) => '',
        },
        { title: '品名', dataIndex: 'materialDescription' },
        { title: '品號', dataIndex: 'materialId' },
        { title: '收料量', dataIndex: 'mainMaterialUnitQuantity' },
        { title: '單位', render: () => '個' },
        { title: '儲位', dataIndex: 'warehouse.name' },
        {
          title: '收貨時間',
          render: () => '2023/8/22 17:01:43',
        },
      ],
      []
    );
  const [receivedMaterialTableData, setReceivedMaterialTableData] = useState<
    ReceivedMaterialItemTable[]
  >([]);
  const [tempRowMaterialData, setTempRowMaterialData] = useState<Omit<
    ReceivedMaterialItemTable,
    'loader'
  > | null>(null);

  const onQualityControl = useCallback(
    (action: '驗收' | '退貨', source: IncomingPurchaseOrder) => {
      modalGroupController.openModal(
        MiscellaneousStorageInModals.QUALITY_CONTROL,
        {
          action,
          source,
        }
      );
    },
    [modalGroupController]
  );

  return (
    <>
      <PageLayout setGoBack>
        <Typography variant="h1">固資入庫</Typography>
        <div className={classes['row-block']}>
          <OrderMetaInfo
            labelAndContexts={orderMetaDataLabelAndContext ?? []}
          />
        </div>
        <CollapsibleContainer
          enableRowBlockStyle
          open
          header={
            <Typography variant="h4" color="text-primary">
              應收項目
            </Typography>
          }
        >
          <Table
            columns={column}
            dataSource={orders?.items ?? []}
            bodyClassName={sharedStyles.table}
          />
        </CollapsibleContainer>
        {/* <div className={classes['row-block']}>
          <Typography variant="h4" color="text-primary">
            已收紀錄
          </Typography>
          <Table
            columns={receivedMaterialColumn}
            dataSource={[]}
            bodyClassName={sharedStyles.table}
          />
        </div> */}
        <div style={{ width: '100%', overflow: 'scroll' }}>
          <IncomingQualityControlTable
            iqcOrderType={IQCOrderType.PURCHASE_ORDER}
            purchaseOrderId={orderId}
            statusIds={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
            getColumns={(defaultColumns) => [
              {
                width: 100,
                title: '操作',
                render: (source: IncomingPurchaseOrder) =>
                  checkJWTRoles([91]) && (
                    <Space>
                      {checkJWTRoles([82]) &&
                        (source.statusId === 2 || source.statusId === 8) && (
                          <Button
                            type="button"
                            variant="contained"
                            onClick={() => onQualityControl('驗收', source)}
                          >
                            驗收
                          </Button>
                        )}
                      {checkJWTRoles([82]) &&
                        (source.statusId === 5 || source.statusId === 9) && (
                          <Button
                            danger
                            disabled
                            type="button"
                            variant="contained"
                            onClick={() => onQualityControl('退貨', source)}
                          >
                            退貨
                          </Button>
                        )}
                    </Space>
                  ),
              },
              ...defaultColumns,
            ]}
          />
        </div>
      </PageLayout>
      <ModalGroup {...modalGroupController}>
        <ReceiveActionModal<ReceivableItemTable>
          setReceivedMaterialTableData={setReceivedMaterialTableData}
          tempRowMaterialData={tempRowMaterialData}
          setTempRowMaterialData={setTempRowMaterialData}
        />
        <AcceptanceModal />
        <CancelModal />
        <IncomingQualityControlModal purchaseOrderId={orderId} />
      </ModalGroup>
    </>
  );
}
