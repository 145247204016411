export const tableInputSearchParams = [
  'receivableMaterialIdSearch',
  'receivableMaterialDescriptionSearch',
  'receivedMaterialIdSearch',
  'receivedMaterialDescriptionSearch',
] as const;

export const PURCHASE_ORDER_META_TRANSLATION = {
  id: '採購單號',
  supplierName: '供應商名稱',
  supplierId: '供應商代碼',
  staffName: '請購人員',
  expectedCompletedAt: '物料收貨日',
  receivedState: '狀態',
} as const;

export const CUSTOMER_ORDER_META_TRANSLATION = {
  id: '出貨單號',
  customerName: '客戶名稱',
  customerId: '客戶代碼',
  employeeName: '業務人員',
  expectedCompletedAt: '物料收貨日',
} as const;

export const OUTSOURCE_ORDER_META_TRANSLATION = {
  id: '採購單號',
  supplierName: '供應商名稱',
  supplierId: '供應商代碼',
  createdAt: '建立時間',
  staffName: '建立者',
} as const;

export const OUTSOURCE_ORDER_META_TRANSLATION_IN_RECEIVE_MODAL = {
  materialDescription: '品名',
  materialId: '料號',
  expectedMaterialInputUnitQuantity: '應收量',
  returnedMaterialInputUnitQuantity: '已收量',
  materialInputUnit: '單位',
  factory: '工廠',
} as const;
