import { InputField } from '@mezzanine-ui/react-hook-form';
import styles from './routing-filters.module.scss';
import { Button } from '@mezzanine-ui/react';

export function RoutingFilters({ updateQueryParams }: { updateQueryParams: () => void }) {
  return (
    <div className={styles.filters}>
      <InputField
        label="Recipe ID"
        clearable
        registerName="recipe" />
      <InputField
        label="舊料號"
        clearable
        registerName="oldMaterialId" />
      <InputField
        label="新料號"
        clearable
        registerName="newMaterialId" />
      <Button type="button" onClick={updateQueryParams} variant="outlined">
        查詢
      </Button>
    </div>
  );
}
