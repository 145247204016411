import { Button, Input } from '@mezzanine-ui/react';
import { FormFieldsWrapper, SelectField } from '@mezzanine-ui/react-hook-form';
import { UseFormReturn } from 'react-hook-form';
import sharedStyles from '../../../shared-styles/index.module.scss';
import { useState } from 'react';
import classes from './choose-material-first-layer-modal-filter.module.scss';

type EquipmentListFilterProps = {
  methods: UseFormReturn;
};

export function ChooseMaterialFirstLayerModalFilter({
  methods,
}: EquipmentListFilterProps) {
  const [keyword, setKeyword] = useState<string>('');

  return (
    <FormFieldsWrapper
      methods={methods}
      className={sharedStyles.filter_form_wrapper}
    >
      <div className={classes.host}>
        <SelectField
          label="物料類型："
          mode="multiple"
          clearable
          registerName="categories"
          options={[]}
        />
        <SelectField
          label="物料群組："
          mode="multiple"
          clearable
          registerName="status"
          options={[]}
        />
        <div className={sharedStyles['input-search']}>
          <SelectField
            placeholder="關鍵字對象"
            mode="multiple"
            clearable
            registerName="categories"
            options={[]}
          />
          <Input
            value={keyword}
            fullWidth
            onChange={(event) => setKeyword(event.target.value)}
            placeholder="請輸入關鍵字搜尋"
          />
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              methods.setValue('keyword', keyword);
            }}
          >
            確認
          </Button>
        </div>
      </div>
    </FormFieldsWrapper>
  );
}
