import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';
import { LogisticTypeOptions } from 'apps/frontend/src/app/Transportation/RequirementsManagementPage/RequirementsManagement.config';

export function LogisticsTypeSelector() {
  return (
    <AutoCompleteField
      label="事由"
      required
      registerName="logisticType"
      options={LogisticTypeOptions}
    />
  );
}
