import { OffsetBased, PageInfo } from '../pagination';

export type MaterialStack = {
  id: number;
  locationId: string;
  materialId: string;
  materialBatchId: string;
  stackId: string;
  mainMaterialUnitQuantity?: number;
  mainMaterialUnit: string;
  oldMaterialId: string;
  note: string;
  managementUnit: string;
  createdAt: string;
  height: string;
  featureCode: string;
  machineCode: string;
  runCardNumber: string;
  placement?: string;
  recordedAt: string;
  description: string;
};

export type GetMaterialStacksParams = OffsetBased & {
  locationIds: string[];
};

export type GetMaterialStacksResponse = {
  records: MaterialStack[];
  pageInfo: PageInfo;
};
