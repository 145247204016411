import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Typography,
} from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  DatePickerField,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { exportSchemas, useHumanResourceEmp } from '@solar/data';
import styles from './HumanResourceEmpPage.module.scss';

export function HumanResourceEmpPage() {
  const {
    methods,
    modal,
    yearOptions,
    disabledOpenModalButton,
    disableSubmit,
    companyListData,
    monthOptions,
    handleOpenModal,
    handleCloseModal,
    onSubmit,
  } = useHumanResourceEmp();

  return (
    <>
      <Typography variant="h1">薪資傳票匯出</Typography>
      <FormFieldsWrapper methods={methods} className={styles.filterWrapper}>
        <Modal onClose={handleCloseModal} open={modal} className={styles.modal}>
          <ModalHeader>匯出薪資傳票</ModalHeader>
          <ModalBody>
            <InputField
              label="報表名稱"
              registerName={exportSchemas.NAME}
              size="large"
            />
          </ModalBody>
          <ModalFooter className={styles.footer}>
            <Button type="button" variant="outlined" onClick={handleCloseModal}>
              取消
            </Button>
            <Button
              type="button"
              variant="contained"
              disabled={disableSubmit}
              onClick={onSubmit}
            >
              確定
            </Button>
          </ModalFooter>
        </Modal>
        <div className={styles.filter}>
          <Typography>薪資年月：</Typography>
          <div className={styles.yearMonthWrapper}>
            <AutoCompleteField
              required
              registerName={exportSchemas.YEAR}
              options={yearOptions}
            />
            <SelectField
              registerName={exportSchemas.MONTH}
              required
              options={monthOptions}
              clearable
              width={120}
            />
          </div>
        </div>
        <div className={styles.filter}>
          <Typography>法人：</Typography>
          <SelectField
            required
            registerName={exportSchemas.COMPANY_ID}
            clearable
            options={companyListData ?? []}
          />
        </div>
        <div className={styles.filter}>
          <Typography>傳票建立日期：</Typography>
          <DatePickerField
            required
            registerName={exportSchemas.CREATE_DATE}
            clearable
          />
        </div>
        <div className={styles.submitButton}>
          <Button
            type="button"
            variant="contained"
            onClick={handleOpenModal}
            disabled={disabledOpenModalButton}
          >
            匯出
          </Button>
        </div>
      </FormFieldsWrapper>
    </>
  );
}
