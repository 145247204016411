import styles from './index.module.scss';
import React from 'react';
import {
  Icon,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions,
  Message,
  Table,
} from '@mezzanine-ui/react';
import { PlusIcon } from '@mezzanine-ui/icons';
import { FormProvider, useWatch } from 'react-hook-form';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  useCustomerTradeConditionCreate,
  useMaterialCompositionGet,
  useMaterialCompositionAllGet,
  CustomerTradeConditionCreateFormFieldName as FormFieldName,
} from '@solar/data';
import { CustomerAutoComplete } from './CustomerAutoComplete';
import { MaterialAutoComplete } from './MaterialAutoComplete';
import { MaterialOptionalCheckbox } from './MaterialOptionalCheckbox';
import { AnalysisValueMethodSelect } from './AnalysisValueMethodSelect';
import { WeightMethodSelect } from './WeightMethodSelect';
import { ComponentSelect } from './ComponentSelect';

type Props = {
  refetchDataTable?: () => void;
};

export function CreateModalButton({ refetchDataTable }: Props) {
  const { formMethods, createFormSubmit } = useCustomerTradeConditionCreate();

  const [activeModal, setActiveModal] = React.useState(false);

  const openModal = () => {
    formMethods.reset();
    setActiveModal(true);
  };
  const closeModal = () => {
    formMethods.reset();
    setActiveModal(false);
  };

  const materialId = useWatch({
    control: formMethods.control,
    name: FormFieldName.MATERIAL_ID,
  });

  const materialOptional = useWatch({
    control: formMethods.control,
    name: FormFieldName._MATERIAL_OPTIONAL,
  });

  const { materialCompositionAll } = useMaterialCompositionAllGet();

  const { materialComposition, isLoading } = useMaterialCompositionGet({
    materialId: materialId ?? '',
  });

  const tableColumns = [
    {
      title: '成分',
      dataIndex: 'id',
    },
    {
      title: '重量交易方式',
      render(source: { id: string }, index: number) {
        const registerNames = [
          FormFieldName.WEIGHT_METHOD_1,
          FormFieldName.WEIGHT_METHOD_2,
          FormFieldName.WEIGHT_METHOD_3,
          FormFieldName.WEIGHT_METHOD_4,
        ];

        return <WeightMethodSelect registerName={registerNames[index]} />;
      },
    },
    {
      title: '分析值交易方式',
      render(source: { id: string }, index: number) {
        const registerNames = [
          FormFieldName.ANALYSIS_VALUE_METHOD_1,
          FormFieldName.ANALYSIS_VALUE_METHOD_2,
          FormFieldName.ANALYSIS_VALUE_METHOD_3,
          FormFieldName.ANALYSIS_VALUE_METHOD_4,
        ];

        return (
          <AnalysisValueMethodSelect registerName={registerNames[index]} />
        );
      },
    },
  ];

  // replace first table columns as materialOptional be true
  if (materialOptional) {
    tableColumns.shift();
    tableColumns.unshift({
      title: '成分',
      render(source: { id: string }, index: number) {
        const registerNames = [
          FormFieldName.COMPONENT_ITEM_NUMBER_1,
          FormFieldName.COMPONENT_ITEM_NUMBER_2,
          FormFieldName.COMPONENT_ITEM_NUMBER_3,
          FormFieldName.COMPONENT_ITEM_NUMBER_4,
        ];

        return (
          <ComponentSelect
            options={materialCompositionAll.map((n) => ({
              id: n.id,
              name: n.id,
            }))}
            registerName={registerNames[index]}
          />
        );
      },
    });
  }

  React.useEffect(() => {
    // 不處於不分料號模式，且 API 取回成分後才設定 Hook Form
    if (!materialOptional) {
      const registerNames = [
        FormFieldName.COMPONENT_ITEM_NUMBER_1,
        FormFieldName.COMPONENT_ITEM_NUMBER_2,
        FormFieldName.COMPONENT_ITEM_NUMBER_3,
        FormFieldName.COMPONENT_ITEM_NUMBER_4,
      ];

      registerNames.forEach((name, index) => {
        formMethods.setValue(name, materialComposition?.[index]?.id);
      });
    }
  }, [materialComposition, formMethods, materialOptional]);

  return (
    <>
      <Button
        variant="contained"
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
        }}
        size="large"
        onClick={openModal}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            marginLeft: '-4px',
          }}
        >
          <Icon icon={PlusIcon} />
          新增
        </div>
      </Button>
      <Modal
        hideCloseIcon={false}
        loading={isLoading}
        onClose={closeModal}
        open={activeModal}
        size="large"
      >
        <ModalHeader>新增交易模式</ModalHeader>
        <FormProvider {...formMethods}>
          <FormFieldsWrapper
            methods={formMethods}
            onSubmit={createFormSubmit({
              successCallback: () => {
                Message.success('交易條件建立成功');
                refetchDataTable?.();
                closeModal();
              },
              errorCallback: (error) => {
                Message.error(`交易條件建立失敗：${error?.message}`);
              },
            })}
          >
            <ModalBody>
              <div className={styles.wrapper}>
                <CustomerAutoComplete />
                <MaterialAutoComplete />
                <MaterialOptionalCheckbox style={{ marginLeft: '-8px' }} />
              </div>
              <div style={{ marginTop: '2.5rem' }}>
                {materialOptional ? (
                  <Table
                    columns={tableColumns}
                    dataSource={[
                      { id: '_placeholder_1' },
                      { id: '_placeholder_2' },
                      { id: '_placeholder_3' },
                      { id: '_placeholder_4' },
                    ]}
                  />
                ) : (
                  <Table
                    loading={isLoading}
                    columns={materialId ? tableColumns : []}
                    dataSource={materialComposition}
                  />
                )}
              </div>
            </ModalBody>
            <ModalActions
              cancelText="重置"
              confirmText="新增"
              confirmButtonProps={{ type: 'submit' }}
              cancelButtonProps={{ type: 'button' }}
              onCancel={() => {
                formMethods.reset();
              }}
            ></ModalActions>
          </FormFieldsWrapper>
        </FormProvider>
      </Modal>
    </>
  );
}
