import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SelectValue,
  Table,
  Typography,
} from '@mezzanine-ui/react';
import {
  MznPaginationTable,
  RowSection,
  useTargetModal,
} from '@solar/templates';
import { Modals } from './modals.enum';
import {
  AutoCompleteField,
  CheckboxField,
  CheckboxGroupField,
  FormFieldsWrapper,
  InputField,
  RadioGroupField,
  SelectField,
  TextAreaField,
} from '@mezzanine-ui/react-hook-form';
import { UseFieldArrayAppend, useForm, useWatch } from 'react-hook-form';
import { Col, Row, Space } from 'antd';
import {
  NextPagination,
  getNextParams,
  useBatchIdsByMaterialId,
  useGetSampleAnalysisRequirements,
  useGetSampleSpecifications,
  useGetSampleStatus,
  useGetSampleSubmissionReasons,
  useMaterials,
} from '@solar/data';
import { useCallback, useEffect, useState } from 'react';
import { SendForInspectionFormValues } from './send-for-inspection';

interface FormValues {
  materialId: SelectValue | null;
  batchId: SelectValue | null;
  sampleName: string;
  isRush: boolean;
  isSampleAccountTransfer: boolean;
  isReturnSample: boolean;
  testType: string;
  sampleAmount: string;
  emptyBagWeight: string;
  sampleNetWeight: string;
  sampleRemainWeight: string;
  sampleUnit: SelectValue | null;
  submitReason: SelectValue | null;
  sampleType: SelectValue | null;
  sampleSpec: SelectValue | null;
  sampleDesc: string;
  // analysisRequirementIds: string[];
  // methodIds: string[];
}

const defaultValues: FormValues = {
  materialId: null,
  batchId: null,
  sampleName: '',
  isRush: false,
  isSampleAccountTransfer: false,
  isReturnSample: false,
  testType: '1',
  sampleAmount: '',
  emptyBagWeight: '',
  sampleNetWeight: '',
  sampleRemainWeight: '',
  sampleUnit: null,
  submitReason: null,
  sampleType: null,
  sampleSpec: null,
  sampleDesc: '',
  // analysisRequirementIds: [],
  // methodIds: [],
};

export function ExpandRow({ row }: { row: any }) {
  const [search, setSearch] = useState('');

  return (
    <Space
      direction="vertical"
      style={{ width: '100%', paddingLeft: 85, margin: 15 }}
    >
      <Space align="center">
        <Typography variant="h5">產品編號</Typography>
        <Input
          placeholder="搜尋產品編號"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Space>
      <Space style={{ width: '100%' }}>
        {row.productCodes
          ?.split('/')
          ?.filter((code: string) => (search ? code.includes(search) : true))}
      </Space>
    </Space>
  );
}

export function AppendSampleModal({
  factory,
  appendSample,
}: {
  factory: string;
  appendSample: UseFieldArrayAppend<SendForInspectionFormValues, 'samples'>;
}) {
  const [selectedRowKey, setSelectedRowKey] = useState<string[]>([]);
  const [requirementSearchTerm, setRequirementSearchTerm] =
    useState<string>('');
  const { open, closeModal } = useTargetModal(Modals.APPEND_SAMPLE);

  const methods = useForm<FormValues>({ defaultValues });

  const watchedMaterial = useWatch({
    control: methods.control,
    name: 'materialId',
  });

  const { data: batches, setParams: mutateBatchIdsByMaterialParams } =
    useBatchIdsByMaterialId({
      materialId: watchedMaterial?.id ?? null,
    });

  const { materials, setParams: refetchGetMaterials } = useMaterials();

  const { sampleSpecifications, refetchGetSampleSpecifications } =
    useGetSampleSpecifications();
  const { sampleStatus, refetchGetSampleStatus } = useGetSampleStatus({
    factory,
  });
  const {
    sampleAnalysisRequirements,
    pageInfo,
    refetchGetSampleAnalysisRequirements,
  } = useGetSampleAnalysisRequirements();
  const { sampleSubmissionReasons, refetchGetSampleSubmissionReasons } =
    useGetSampleSubmissionReasons({
      factory,
    });

  useEffect(() => {
    refetchGetSampleStatus({ factory });
    refetchGetSampleSubmissionReasons({ factory });
  }, [factory]);

  const specOptions = sampleSpecifications?.map((spec) => ({
    id: spec.name,
    name: spec.name,
  }));

  const statusOptions = sampleStatus?.map((status) => ({
    id: status.itemName,
    name: status.itemName,
  }));

  const reasonOptions = sampleSubmissionReasons?.map((reason) => ({
    id: reason.itemName,
    name: reason.itemName,
  }));

  const onSpecSearch = useCallback(
    (searchTerm: string) => {
      refetchGetSampleSpecifications({ factory, searchTerm });
    },
    [factory]
  );

  const refetchGetSampleAnalysisRequirementsWithPagination = useCallback(
    (nextPagination: NextPagination) => {
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      refetchGetSampleAnalysisRequirements({
        offset,
        limit,
        factory,
        searchTerm: requirementSearchTerm,
      });
    },
    [refetchGetSampleAnalysisRequirements, factory, requirementSearchTerm]
  );

  useEffect(() => {
    refetchGetSampleSpecifications({ factory });
    refetchGetSampleAnalysisRequirements({ factory });
  }, [factory]);

  const resetForm = useCallback(() => {
    methods.reset(defaultValues);
    setSelectedRowKey([]);
  }, [methods]);

  const onClose = useCallback(() => {
    resetForm();
    closeModal();
  }, [resetForm, closeModal]);

  const onSubmit = methods.handleSubmit((data) => {
    const targetBatch = batches?.find(
      (batch) => batch.batchId === data?.batchId?.id
    );
    appendSample({
      ...data,
      materialId: data?.materialId?.id ?? '',
      // batchId: data?.batchId?.id ?? '',
      sapBatchId: targetBatch?.sapBatchId ?? '',
      subBatchId: targetBatch?.subBatchId ?? '',
      sampleType: data?.sampleType?.id ?? '',
      sampleSpec: data?.sampleSpec?.id ?? '',
      submitReason: data?.submitReason?.id ?? '',
      sampleUnit: data?.sampleUnit?.id ?? '',
      analysisRequirementIds: selectedRowKey?.map((id) => id?.toString()) ?? [],
      testType: Number(data?.testType),
      methodIds: [],
    });
    resetForm();
    closeModal();
  });

  return (
    <Modal open={open} onClose={onClose} style={{ width: '1000px' }}>
      <ModalHeader>新增樣品</ModalHeader>
      <ModalBody>
        <FormFieldsWrapper methods={methods}>
          <Space direction="vertical">
            <Space>
              <RowSection label="料號">
                <AutoCompleteField
                  registerName="materialId"
                  onSearch={(searchTerm) =>
                    refetchGetMaterials({ searchTerm, searchType: 'ID' })
                  }
                  options={
                    materials?.map((material) => ({
                      id: material?.id,
                      name: material?.id,
                    })) ?? []
                  }
                  required
                />
              </RowSection>
              <RowSection label="批號">
                <AutoCompleteField
                  required
                  disabled={!watchedMaterial}
                  registerName="batchId"
                  onInput={({ target }) => {
                    mutateBatchIdsByMaterialParams((prev) => ({
                      ...prev,
                      searchTerm: (target as HTMLInputElement).value,
                    }));
                  }}
                  options={
                    batches.map((row) => ({
                      id: row.batchId,
                      name: row.batchId,
                    })) ?? []
                  }
                />
              </RowSection>
              {/* <RowSection label="條碼">
                <InputField registerName="materialBarcode" />
              </RowSection> */}
            </Space>
            <Space>
              <RowSection label="樣品名稱">
                <InputField registerName="sampleName" required />
              </RowSection>
              <Space>
                <CheckboxField
                  registerName="isRush"
                  label="急件"
                  onChange={(e) => console.log(e.target.checked)}
                />
                <CheckboxField
                  registerName="isReturnSample"
                  label="樣品取回"
                  onChange={(e) => console.log(e.target.checked)}
                />
                <CheckboxField
                  registerName="isSampleAccountTransfer"
                  label="樣品轉帳"
                  onChange={(e) => console.log(e.target.checked)}
                />
              </Space>
              <RadioGroupField
                required
                registerName="testType"
                options={[
                  { value: '1', label: '首測' },
                  { value: '2', label: '保留樣複測', disabled: true },
                  { value: '3', label: '重新取樣', disabled: true },
                ]}
              />
            </Space>
            <Space>
              <RowSection label="樣品狀態">
                <SelectField
                  required
                  registerName="sampleType"
                  options={statusOptions}
                />
              </RowSection>
              <RowSection label="分析樣毛重">
                <InputField registerName="sampleAmount" required />
              </RowSection>
              <RowSection label="分析樣淨重">
                <InputField registerName="sampleNetWeight" required />
              </RowSection>
            </Space>
            <Space>
              <RowSection label="分析樣空重">
                <InputField registerName="emptyBagWeight" required />
              </RowSection>
              {/* <RowSection label="分析後殘料重">
                <Space>
                  <InputField registerName="sampleRemainWeight" required />
                  <SelectField
                    required
                    registerName="sampleUnit"
                    options={[
                      { id: 'g', name: 'g' },
                      { id: 'ml', name: 'ml' },
                    ]}
                  />
                </Space>
              </RowSection> */}
            </Space>
            <Space>
              <RowSection label="委託原因">
                <SelectField
                  required
                  registerName="submitReason"
                  options={reasonOptions}
                />
              </RowSection>
              <RowSection label="規格">
                <AutoCompleteField
                  required
                  registerName="sampleSpec"
                  options={specOptions}
                  onSearch={onSpecSearch}
                />
              </RowSection>
            </Space>
            <RowSection label="樣品說明/成分">
              <TextAreaField fullWidth registerName="sampleDesc" />
            </RowSection>
            <RowSection label="勾選委託需求">
              <Space>
                <Input
                  fullWidth
                  placeholder="輸入分析需求"
                  value={requirementSearchTerm}
                  onChange={(event) =>
                    setRequirementSearchTerm(event.target.value)
                  }
                />
                <Button
                  type="button"
                  onClick={() =>
                    refetchGetSampleAnalysisRequirementsWithPagination({
                      nextPage: 1,
                    })
                  }
                >
                  搜尋
                </Button>
              </Space>
            </RowSection>
            <MznPaginationTable
              pageInfo={pageInfo}
              rowSelection={{
                selectedRowKey,
                onChange: setSelectedRowKey,
              }}
              dataSource={sampleAnalysisRequirements ?? []}
              fetchData={refetchGetSampleAnalysisRequirementsWithPagination}
              expandable={{
                rowExpandable: (row) => !!row?.productCodes,
                expandedRowRender: (row) => <ExpandRow row={row} />,
              }}
              columns={[
                {
                  title: '需求類別',
                  dataIndex: 'analysisRequirementType',
                },
                {
                  title: '分析需求',
                  dataIndex: 'analysisRequirement',
                },
                {
                  title: '作業時間',
                  dataIndex: 'processTime',
                },
                {
                  title: '急件作業時間',
                  dataIndex: 'rushProcessTime',
                },
                {
                  title: '費用 (急件*1.33)',
                  dataIndex: 'price',
                },
              ]}
            />
          </Space>
        </FormFieldsWrapper>
      </ModalBody>
      <ModalFooter>
        <Row gutter={[12, 12]} justify="end" style={{ width: '100%' }}>
          <Col>
            <Button type="button" variant="outlined" onClick={closeModal}>
              取消
            </Button>
          </Col>
          <Col>
            <Button type="button" variant="contained" onClick={onSubmit}>
              確認
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
}
