import {
  deleteDrawing,
  updateFCCDrawing,
  useFCCDrawingByNewDrawingCode,
} from '@solar/data';
import { Button, Modal, message } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEditDrawingForm } from '../EditDrawingForm/EditDrawingForm';
import { FCCDrawingEditForm } from '../DrawingForm/FCCDrawingEditForm';

export function FCCEditDrawingPage() {
  const params = useParams();
  const navigate = useNavigate();
  const { form, uploading } = useEditDrawingForm();
  const { drawing } = useFCCDrawingByNewDrawingCode({
    newDrawingCode: params?.newDrawingCode,
  });

  return (
    <FCCDrawingEditForm
      form={form}
      title={drawing?.newDrawingCode}
      drawing={drawing}
      extraContent={
        <div style={{ display: 'flex', gap: 'var(--mzn-spacing-4)' }}>
          <Link to={`/drawings/fcc/${drawing?.newDrawingCode}`}>
            <Button>取消編輯</Button>
          </Link>
          <Button loading={uploading} type="primary" htmlType="submit">
            儲存
          </Button>
          <Button
            danger
            type="primary"
            onClick={() => {
              Modal.confirm({
                title: '確認刪除',
                okText: '確認',
                cancelText: '取消',
                onOk: async () => {
                  if (drawing?.id) {
                    const response = await deleteDrawing({
                      id: drawing?.id,
                      bu: 'FCC',
                    });
                    if (response?.code === 200) {
                      message.success('刪除成功');
                      navigate('/drawings/fcc');
                    } else {
                      message.error('刪除失敗');
                    }
                  }
                },
              });
            }}
          >
            刪除
          </Button>
        </div>
      }
      onFinish={async (payload) => {
        try {
          if (drawing?.id) {
            const response = await updateFCCDrawing({
              id: drawing?.id,
              data: payload,
            });
            const newDrawingCode = response?.data?.newDrawingCode;
            if (response?.code === 200 && newDrawingCode) {
              message.success('更新成功');
              navigate(`/drawings/fcc/${newDrawingCode}`);
            }
          }
        } catch (error: Error | any) {
          switch (JSON.parse(error.message)?.message ?? '') {
            case 'ParentDrawingCodeNotMatch':
              message.error('新舊成品圖號不一致，請重新檢查');
              break;
            case 'InvalidParentOldDrawingCode':
              message.error('查無此舊成品圖號，請重新檢查');
              break;
            default:
              message.error('更新失敗');
              break;
          }
        }
      }}
    />
  );
}
