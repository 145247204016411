import {
  Button,
  Icon,
  Modal,
  ModalActions,
  ModalBody,
  Typography,
} from '@mezzanine-ui/react';
import { useNavigate, useParams } from 'react-router';
import { Descriptions } from 'antd';
import { ChevronLeftIcon } from '@mezzanine-ui/icons';
import styles from './SalesReturnDetailPage.module.scss';
import { ReceivableTable } from './ReceivableTable';
import { useCallback, useEffect, useState } from 'react';
import { MatchedTable } from './MatchedTable';
import {
  AutoCompleteField,
  FormFieldsWrapper,
} from '@mezzanine-ui/react-hook-form';
import { useForm } from 'react-hook-form';
import { API_NAMESPACE, request } from '@solar/data';
import {
  SalesReturnDetail,
  SalesReturnDetailMaterialItem,
} from './SalesReturnDetail.interface';
import dayjs from 'dayjs';

export function SalesReturnDetailPage() {
  const navigate = useNavigate();
  const methods = useForm({});
  const [orderData, setOrderData] = useState<SalesReturnDetail>();
  const [receivableList, setReceivableList] = useState<
    SalesReturnDetailMaterialItem[]
  >([]);
  const [matched, setMatched] = useState([]);
  const [ticketNo, setTicketNo] = useState('');
  // const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState<SalesReturnDetailMaterialItem>();

  const { id } = useParams();
  useEffect(() => {
    request(`/warehouses/production-delivery-orders/${id}`, {
      method: 'get',
      namespace: API_NAMESPACE.MATERIALS,
    }).then((res: SalesReturnDetail) => {
      setOrderData(res);
      const materialItems = res.specs.reduce((result, spec) => {
        return [...result, ...spec.materialItems];
      }, [] as SalesReturnDetailMaterialItem[]);
      setReceivableList(materialItems);
    });
  }, [id]);

  const onReceivableMatch = useCallback(
    (item: SalesReturnDetailMaterialItem) => {
      setModalData(item);
    },
    []
  );
  const onMatchedEdit = useCallback((index: number) => {
    // console.log('編輯 Click：', index);
    // setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => setModalData(undefined), []);
  return (
    <>
      <div className={styles.wrapper}>
        <div>
          <Button
            type="button"
            onClick={() => navigate('..')}
            prefix={
              <Icon style={{ color: '#8F8F8F' }} icon={ChevronLeftIcon} />
            }
            variant="text"
          >
            <Typography color="text-secondary" variant="button2">
              返回上一頁
            </Typography>
          </Button>
        </div>

        <Descriptions>
          <Descriptions.Item label="出貨單號">
            {orderData?.id}
          </Descriptions.Item>
          <Descriptions.Item label="客戶名稱">
            {orderData?.customerName}
          </Descriptions.Item>
          <Descriptions.Item label="客戶代碼">
            {orderData?.customerId}
          </Descriptions.Item>
          <Descriptions.Item label="業務人員">
            {orderData?.employeeName}
          </Descriptions.Item>
          <Descriptions.Item label="預計收貨日期">
            {dayjs(orderData?.expectedCompletedAt).format('YYYY/MM/DD')}
          </Descriptions.Item>
        </Descriptions>

        <ReceivableTable
          data={receivableList}
          onReceivableMatch={onReceivableMatch}
        ></ReceivableTable>

        <MatchedTable
          data={matched}
          onMatchedEdit={onMatchedEdit}
        ></MatchedTable>
      </div>

      <Modal
        size="small"
        hideCloseIcon={true}
        open={!!modalData}
        onClose={closeModal}
      >
        <ModalBody>
          <FormFieldsWrapper className={styles.form} methods={methods}>
            <AutoCompleteField
              label="客戶靶編"
              registerName="code"
              options={[]}
            ></AutoCompleteField>
            <AutoCompleteField
              label="出貨批號"
              registerName="batch"
              options={[]}
            ></AutoCompleteField>
            <div className={styles.formLabel}>歷史出貨單號： {ticketNo}</div>
          </FormFieldsWrapper>
        </ModalBody>
        <ModalActions
          cancelText="取消"
          confirmText="確認"
          onCancel={closeModal}
          onConfirm={closeModal}
        ></ModalActions>
      </Modal>
    </>
  );
}
