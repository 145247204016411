import useSWR from 'swr';
import { request } from '../request';
import { Message } from '@mezzanine-ui/react';
import {
  CreateRepairRequestPayload,
  CreateRepairRequestLogPayload,
  RepairRequest,
  RepairRequestLog,
} from './repairRequest.type';

export async function downloadFile({
  filename,
  title,
}: {
  title: string;
  filename: string;
}) {
  try {
    const response = await requestFile({ filename });
    const link = document.createElement('a');
    link.href = await response;
    link.setAttribute('download', title);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e: any) {
    switch (e?.statusCode) {
      case 404:
        Message['error']('檔案不存在');
        break;
      default:
        Message['error']('下載失敗');
        break;
    }
  }
}

export async function requestFile({ filename }: { filename: string }) {
  const response = await request(`/files/${filename}`, {
    method: 'get',
    responseParser: (res) => res,
  });
  if (response.ok) {
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } else {
    throw await response.json();
  }
}

export function createRepairRequest(payload: CreateRepairRequestPayload) {
  return request('/repair-requests', {
    method: 'post',
    body: JSON.stringify(payload),
    responseParser: (res) => res,
  });
}

export async function createRepairRequestLog({
  id,
  ...payload
}: CreateRepairRequestLogPayload) {
  return request(`/repair-requests/${id}/logs`, {
    method: 'post',
    body: JSON.stringify(payload),
    responseParser: (res) => res,
  });
}

type useGetRepairRequest = {
  requestId?: string | number;
};

export function useGetRepairRequest({ requestId }: useGetRepairRequest) {
  const { data, error } = useSWR<RepairRequest>(
    requestId ? [`/repair-requests/${requestId}`] : null
  );

  return {
    repairRequest: data,
    isLoading: !data && !error,
  };
}

export function useGetRepairRequestLogs({ requestId }: useGetRepairRequest) {
  const { data, error } = useSWR<RepairRequestLog[]>(
    requestId ? [`/repair-requests/${requestId}/logs`] : null
  );

  return {
    repairRequestLogs: data,
    isLoading: !data && !error,
  };
}
