import { API_NAMESPACE, request } from '@solar/data';
import { UpdateSubBatchRemarkResponse } from '../LabelPage/types';

export type UpdateSubBatchRemarkParams = {
  materialId: string;
  sapBatchId: string;
  subBatchId: string;
};

export type UpdateSubBatchRemarkPayload = {
  remark: string;
};

const namespace = API_NAMESPACE.MATERIALS;
export const updateSubBatchRemark = (
  params: UpdateSubBatchRemarkParams,
  payload: UpdateSubBatchRemarkPayload
): Promise<UpdateSubBatchRemarkResponse> => {
  const { materialId, sapBatchId, subBatchId } = params;

  const { remark } = payload;
  return request(
    `/materials/${materialId}/${sapBatchId}/${subBatchId}/remark`,
    {
      method: 'put',
      body: JSON.stringify({ remark }),
      namespace,
      responseParser: (res) => res.json(),
    }
  );
};
