import { TableColumn } from '@mezzanine-ui/core/table';
import { MoreVerticalIcon } from '@mezzanine-ui/icons';
import { Icon, Message } from '@mezzanine-ui/react';
import Decimal from 'decimal.js';
import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react';

interface DraggableTitleProps {
  tableRef: RefObject<HTMLTableElement>;
  columnIndex: number;
  title: TableColumn<any>['title'];
  align: TableColumn<any>['align'];
}

export function DraggableTitle({
  tableRef,
  columnIndex,
  title,
  align,
}: DraggableTitleProps) {
  const iconRef = useRef<HTMLDivElement>(null);

  const justifyContent = useMemo(() => {
    switch (align) {
      case 'center':
        return 'center';
      case 'end':
        return 'flex-end';
      default:
        return 'flex-start';
    }
  }, [align]);

  const getTableHeaders = useCallback(() => {
    const tableElement = tableRef.current;

    return tableElement?.querySelectorAll<HTMLElement>(
      'th.mzn-table__header__cellWrapper'
    );
  }, [tableRef]);

  const getTableCells = useCallback(() => {
    const tableElement = tableRef.current;

    return tableElement?.querySelectorAll<HTMLElement>(
      `.mzn-table__body__row__cellWrapper:not([aria-label])`
    );
  }, [tableRef]);

  const getCurrentWidth = useCallback((element: HTMLElement) => {
    const currentWidth = element.style.width.replace('px', '').trim();

    if (Number.isNaN(Number(currentWidth))) {
      console.error(
        'currentWidth is not a number, currentWidth: ',
        currentWidth
      );
      return undefined;
    }

    return currentWidth;
  }, []);

  const setColumnWidth = useCallback(
    (nextWidth: number) => {
      const headers = getTableHeaders();
      const cells = getTableCells();

      const columnLength = headers?.length ?? 0;
      const targetHeader = headers?.[columnIndex];

      if (!targetHeader) {
        Message.error('找不到表頭');
        return;
      }

      const getTargetCells = (nodeList?: NodeListOf<HTMLElement>) => {
        return Array.from(nodeList ?? []).filter(
          (row, i) => i % columnLength === columnIndex
        );
      };

      const rows = getTargetCells(cells);

      const setStyles = (element: HTMLElement) => {
        element.style.minWidth = `100px`;
        element.style.maxWidth = `800px`;
        element.style.width = `${nextWidth}px`;
        element.style.flex = 'none';
      };

      setStyles(targetHeader);

      rows.forEach((row) => setStyles(row));
    },
    [getTableCells, getTableHeaders, columnIndex]
  );

  const eventRef = useRef<{
    onDragMove: (event: any) => void;
  }>();

  const onMouseDown = useCallback(
    (mouseDownEvent: any) => {
      mouseDownEvent?.preventDefault();

      const headers = getTableHeaders();
      const targetHeader = headers?.[columnIndex];

      if (!targetHeader) {
        Message.error('找不到表頭');
        return;
      }

      const currentWidth = getCurrentWidth(targetHeader);

      if (!currentWidth) {
        Message.error('找不到表頭寬度');
        return;
      }

      const startX = mouseDownEvent?.clientX;

      if (!startX) {
        Message.error('找不到滑鼠起始點');
        return;
      }

      const onDragMove = (moveEvent: any) => {
        moveEvent?.preventDefault();

        const moveX = moveEvent?.clientX;

        setColumnWidth(
          new Decimal(currentWidth).minus(startX).add(moveX).toNumber()
        );
      };

      eventRef.current = { onDragMove };

      document.addEventListener('mousemove', onDragMove);
    },
    [columnIndex, getCurrentWidth, getTableHeaders, setColumnWidth]
  );

  const onTouchStart = useCallback(
    (touchStartEvent: any) => {
      touchStartEvent?.preventDefault();

      const headers = getTableHeaders();
      const targetHeader = headers?.[columnIndex];

      if (!targetHeader) {
        Message.error('找不到表頭');
        return;
      }

      const currentWidth = getCurrentWidth(targetHeader);

      if (!currentWidth) {
        Message.error('找不到表頭寬度');
        return;
      }

      const startX =
        touchStartEvent?.touches?.length === 1
          ? touchStartEvent?.touches?.[0]?.clientX
          : undefined;

      if (!startX) {
        Message.error('找不到起始點');
        return;
      }

      const onDragMove = (moveEvent: any) => {
        moveEvent?.preventDefault();

        const moveX =
          moveEvent?.touches?.length === 1
            ? moveEvent?.touches?.[0]?.clientX
            : undefined;

        setColumnWidth(
          new Decimal(currentWidth).minus(startX).add(moveX).toNumber()
        );
      };

      eventRef.current = { onDragMove };

      document.addEventListener('touchmove', onDragMove);
    },
    [columnIndex, getCurrentWidth, getTableHeaders, setColumnWidth]
  );

  (
    onTouchStart as {
      (touchStartEvent: any): void;
      passive: boolean;
    }
  ).passive = true;

  useEffect(() => {
    function onDragEnd() {
      if (eventRef.current) {
        document.removeEventListener('mousemove', eventRef.current?.onDragMove);
        document.removeEventListener('touchmove', eventRef.current?.onDragMove);
        eventRef.current = undefined;
      }
    }
    document.addEventListener('mouseup', onDragEnd);
    document.addEventListener('touchend', onDragEnd);

    return () => {
      document.removeEventListener('mouseup', onDragEnd);
      document.removeEventListener('touchend', onDragEnd);
    };
  }, []);

  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent,
      }}
    >
      {title}
      <Icon
        size={20}
        color="primary"
        style={{
          position: 'absolute',
          right: 5,
          pointerEvents: 'auto',
          cursor: 'grab',
        }}
        ref={iconRef}
        icon={MoreVerticalIcon}
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
      />
    </div>
  );
}
