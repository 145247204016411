import { useCallback, useMemo } from 'react';
import { Button, SelectValue, Typography } from '@mezzanine-ui/react';
import { useForm } from 'react-hook-form';
import {
  getNextParams,
  NextPagination,
  useInventoryByPurchaseOrders,
} from '@solar/data';
import { TableColumn } from '@mezzanine-ui/core/table';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { FilterScaffold, MznPaginationTable } from '@solar/templates';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from '../PageLayout/PageLayout';
import sharedStyles from '../shared-styles/index.module.scss';
import { Filter } from './Filter';
import { PurchaseOrderTableProps } from './typings';

export function MaterialCodeTransform() {
  const filterMethods = useForm();
  const navigate = useNavigate();

  // table data
  const { orders, pageInfo, mutateParams } = useInventoryByPurchaseOrders();

  const refetchInventoryByPurchaseOrders = useCallback(
    (nextPagination: NextPagination) => {
      const filterState = filterMethods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);

      mutateParams({
        offset,
        limit,
        searchTerm: filterState?.searchTerm,
        dateFrom: (filterState?.dateRange ?? [])[0],
        dateTo: (filterState?.dateRange ?? [])[1],
        supplierIds: filterState?.suppliers?.map(
          (supplier: SelectValue) => supplier.id
        ),
        receivedStates: filterState?.status?.map(
          (status: SelectValue) => status?.id
        ),
      });
    },
    [filterMethods, mutateParams, pageInfo]
  );

  const columns: TableColumn<PurchaseOrderTableProps>[] = useMemo(
    () => [
      {
        title: '操作',
        width: 240,
        align: 'center' as const,

        render: (source) => {
          return (
            <Button
              type="button"
              variant="outlined"
              onClick={() => navigate(`${source['id']}`)}
            >
              檢視
            </Button>
          );
        },
      },
      {
        title: '單號',
        dataIndex: 'id',
      },
      {
        title: '建立日期',
        render: (source) =>
          source['expectedCompletedAt']
            ? moment(source['expectedCompletedAt']).format('YYYY-MM-DD')
            : '',
      },

      { title: '建立者', dataIndex: 'staffName' },
      {
        title: '狀態',
        render: () => '未完成',
      },
    ],
    [navigate]
  );

  return (
    <PageLayout>
      <Typography variant="h1">料號轉換</Typography>

      <FormFieldsWrapper methods={filterMethods}>
        <FilterScaffold
          mutateParams={refetchInventoryByPurchaseOrders}
          fetchAsParamsMutated={false}
        >
          <Filter />
        </FilterScaffold>
      </FormFieldsWrapper>
      <div>
        <Button
          variant="outlined"
          type="button"
          onClick={() => refetchInventoryByPurchaseOrders({ nextPage: 1 })}
        >
          查詢
        </Button>
      </div>
      <MznPaginationTable
        columns={columns}
        dataSource={orders ?? []}
        fetchData={refetchInventoryByPurchaseOrders}
        pageInfo={pageInfo}
        bodyClassName={sharedStyles.table}
      />
    </PageLayout>
  );
}
