import { Button, Message, SelectValue, Typography } from '@mezzanine-ui/react';
import { useLoaders, useLoadersByLoaderId } from '@solar/data';
import {
  forwardRef,
  useImperativeHandle,
  useState,
  useCallback,
  useEffect,
} from 'react';

import {
  CustomAutoCompleteField,
  LocationSearchInput,
  LocationSelector,
  RowSection,
} from '@solar/templates';
import { Col, Row } from 'antd';

import { useFormContext } from 'react-hook-form';
import { debounce } from 'lodash';

type WarehouseAndVehicleFilterProps =
  | {
      enableLoaderSelector?: never | false;
      enableDirectlyLoaderSearch?: never;
      locationLabel?: string;
      loaders?: never;
      loaderLabel?: never;
      openLocationSelectorModal: (arg0: any) => void;
    }
  | {
      enableLoaderSelector: true;
      enableDirectlyLoaderSearch: boolean;
      locationLabel?: string;
      loaders: ReturnType<typeof useLoaders>['loaders'];
      loaderLabel?: string;
      openLocationSelectorModal: (arg0: any) => void;
    };

/**
 * @description 
 * 1. comment out 的部分為舊需求
 * 2. caller 請宣告 const warehouseAndVehicleFilterRef =
    useRef<React.ElementRef<typeof WarehouseAndVehicleFilter>>(null);
 *  以讀取 setLocationRequired，供 onSubmit(form) 判斷 user 是否填寫 『儲位』
 * 3. 當前需求為 儲位必填，因此需在 FormFieldsWrapper onSubmit 帶入
 *    if (!currentLocationSearchTerm?.id && !currentLocationActiveId) {
        warehouseAndVehicleFilterRef.current?.setLocationRequired(true);
        return;
      } else {
        warehouseAndVehicleFilterRef.current?.setLocationRequired(false);
      }
 */
export const WarehouseAndVehicleFilter = forwardRef(
  (
    {
      enableLoaderSelector = false,
      enableDirectlyLoaderSearch,
      openLocationSelectorModal,
      locationLabel = '移轉儲位：',
      loaders,
      loaderLabel = '放入載具：',
    }: WarehouseAndVehicleFilterProps,
    ref: React.Ref<{
      setLocationRequired: React.Dispatch<React.SetStateAction<boolean>>;
    }>
  ) => {
    // const currentLocationActiveId = locationSelectorController?.activeId;
    // const currentLocationSearchTerm = locationSelectorController?.searchTerm;

    // LocationSelector 元件複雜且尚未 expose onChange，暫時手動驗證是否填寫
    const [isLocationRequired, setLocationRequired] = useState(false);

    const [internalLoaderId, setInternalLoaderId] = useState<string | null>();
    /** enableDirectlyLoaderSearch 讓使用者輸入成為選項  */
    // const {
    //   data: loadersWithInventory,
    //   error: loadersWithInventoryError,
    //   isLoading: loadersWithInventoryLoading,
    //   setParams: setParamsOfLoadersByLoaderId,
    // } = useLoadersByLoaderId({
    //   loaderId: enableDirectlyLoaderSearch ? internalLoaderId : null,
    // });

    const loaderDirectSearchDebounce = useCallback(
      debounce((searchText: string) => {
        setInternalLoaderId(searchText);
      }, 700),
      []
    );

    const { clearErrors } = useFormContext();

    useImperativeHandle(ref, () => ({
      setLocationRequired: setLocationRequired,
    }));

    // useEffect(() => {
    //   setParamsOfLoadersByLoaderId({ noInventory: true });
    // }, []);

    // useEffect(() => {
    //   if (
    //     enableDirectlyLoaderSearch &&
    //     internalLoaderId &&
    //     !loadersWithInventoryLoading &&
    //     loadersWithInventoryError
    //   ) {
    //     Message.error('該載具非空的載具');
    //   }
    // }, [
    //   enableDirectlyLoaderSearch,
    //   internalLoaderId,
    //   loadersWithInventoryLoading,
    //   loadersWithInventoryError,
    // ]);
    // const { loaders, mutateLoaderParams } = useLoaders();
    // const LocationSelectorContext = createContext<UseLocationSelectorControllerReturn>({

    // const controller = useLocationSelectorController({
    //   onSelectedIdsChange(selectedIds) {
    //     if (selectedIds?.shelfId && enableLoaderSelector) {
    //       mutateLoaderParams({
    //         shelfIds: [selectedIds?.shelfId],
    //       });
    //     }
    //   },
    // });

    // useEffect(() => {
    //   if (currentLocationSearchTerm?.id || currentLocationActiveId) {
    //     if (enableLoaderSelector) {
    //       mutateLoaderParams({
    //         shelfIds: [
    //           currentLocationSearchTerm?.id ??
    //             (currentLocationActiveId as string),
    //         ],
    //       });
    //     }
    //   }
    // }, [currentLocationSearchTerm?.id, currentLocationActiveId]);

    // const { shelves, mutateGetShelvesParams } = useGetShelves();
    // const { loaders, mutateLoaderParams } = useLoaders();

    // const inputHandler = useCallback(
    //   (e: ChangeEvent<HTMLInputElement>, field: 'shelf' | 'loader') => {
    //     if (field === 'shelf')
    //       mutateGetShelvesParams({
    //         searchTerm: e.target.value,
    //       });
    //     else if (field === 'loader') {
    //       if (true)
    //         mutateLoaderParams({
    //           searchTerm: e.target.value,
    //         });
    //     }
    //   },
    //   []
    // );

    // const debounceInputHandler = useRef(
    //   debounce(
    //     (e: ChangeEvent<HTMLInputElement>, field: 'loader' | 'shelf') =>
    //       inputHandler(e, field),
    //     800
    //   )
    // ).current;

    // const handleEnterKeyPress = useCallback((e: any) => {
    //   if (e.key === 'Enter') {
    //     e.stopPropagation();
    //     e.preventDefault();
    //   }
    // }, []);

    // useEffect(() => {
    //   window.addEventListener('keydown', handleEnterKeyPress);

    //   return () => window.removeEventListener('keydown', handleEnterKeyPress);
    // }, [handleEnterKeyPress]);

    // useEffect(() => {
    //   return () => {
    //     debounceInputHandler.cancel();
    //   };
    // }, [debounceInputHandler]);

    return (
      <>
        <RowSection label={locationLabel} labelAlign="top">
          <Row gutter={8} justify="start">
            <Col>
              <LocationSearchInput />
            </Col>
            <Col>
              <Button
                style={{ alignSelf: 'end' }}
                variant="outlined"
                onClick={() => openLocationSelectorModal(null)}
                type="button"
              >
                地圖選取
              </Button>
            </Col>
          </Row>
          <div style={{ marginTop: '16px' }}>
            <LocationSelector />
          </div>
          <Typography color="error" variant="h6">
            {isLocationRequired && '請填寫儲位資訊！'}
          </Typography>
        </RowSection>
        {enableLoaderSelector && (
          <div style={{ marginTop: '16px' }}>
            <RowSection label={loaderLabel}>
              <CustomAutoCompleteField
                registerName="loader"
                width={480}
                onClear={() => {
                  setInternalLoaderId(null);
                }}
                options={(enableDirectlyLoaderSearch &&
                internalLoaderId &&
                !loaders.some((row) => row?.id === internalLoaderId)
                  ? [
                      {
                        id: internalLoaderId,
                        name: internalLoaderId,
                      },
                    ]
                  : loaders ?? []
                )?.map((row: SelectValue) => ({
                  ...row,
                  name: `${row.id}`,
                }))}
                onChange={() => clearErrors('loader.id')}
                onSearch={loaderDirectSearchDebounce}
              />
            </RowSection>
          </div>
        )}
        {/* <div style={{ display: 'flex', gap: '16px' }}>
        <RowSection label="移轉儲位：">
          <AutoCompleteField
            registerName="warehouse"
            onInput={(e: ChangeEvent<HTMLInputElement>) =>
              debounceInputHandler(e, 'shelf')
            }
            options={shelves ?? []}
            onChange={(selectValue) => {
              if (selectValue?.id)
                mutateLoaderParams({
                  shelfIds: [selectValue.id],
                });
            }}
          />
        </RowSection>
        <Button
          style={{ alignSelf: 'end' }}
          variant="outlined"
          onClick={openLocationSelectorModal}
          type="button"
        >
          地圖選取
        </Button>
      </div>
      <div>
        <RowSection label="放入載具：">
          <AutoCompleteField
            errorMsgRender={(v) => {
              return (
                <Typography
                  color="error"
                  variant="caption"
                  style={{ display: 'flex', marginTop: '4px' }}
                >
                  <Icon
                    icon={TimesCircleFilledIcon}
                    size={16}
                    style={{ marginRight: '4px' }}
                  />
                  {v?.message ?? '必填'}
                </Typography>
              );
            }}
            // update: 當前『放入載具』options 需與『移轉儲位』掛鉤(需選完儲位接著查詢儲位底下有哪些loader)
            // 因此暫時 comment out 底下動態模糊搜尋
            // onInput={(e: ChangeEvent<HTMLInputElement>) =>
            //   debounceInputHandler(e, 'loader')
            // }
            registerName="loader"
            width={480}
            options={(loaders ?? [])?.map((row: SelectValue) => ({
              ...row,
              name: `${row.id} (${row.name})`,
            }))}
          />
        </RowSection>
      </div> */}
      </>
    );
  }
);
