import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import {
  downloadCsv,
  FieldsType,
  headers,
  opFilterMutation,
  ResponseStatus,
} from '../../humanResources';
import { API_NAMESPACE, request } from '../../request';

const namespace = API_NAMESPACE.HR;

type PackagingEstimateDetailDataType = {
  id: number | string;
  index: number;
  packagingMaterialType: string;
  packagingName: string;
  salesOrderLine: null | string;
  packingQuantity: number;
  materialId: null | string;
  materialName: string;
  quantity: null | string;
  unit: string;
};

type PackagingEstimateDataType = {
  id: string;
  customerId: string;
  customerName: string;
  customerOrderDate: string | null;
  detailCount: number;
  details: PackagingEstimateDetailDataType[];
  orderId: string;
};

const isPackagingEstimate = (
  object: unknown
): object is { currentPage: number; data: PackagingEstimateDataType[] } => {
  if (object && typeof object === 'object') return 'data' in object;
  return false;
};

export function usePackagingEstimate() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<PackagingEstimateDataType[] | null>(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [queryParams, setQueryParams] = useState('');
  const [resultData, setResultData] = useState<
    PackagingEstimateDetailDataType[] | null
  >(null);

  const tableQueries = useMemo(
    () => `?page=${page}&perPage=${perPage}`,
    [page, perPage]
  );

  const { data: swrData } = useSWR([
    `/packings/estimate/orders${tableQueries}${queryParams}`,
    { namespace },
  ]);

  const total = useMemo(() => swrData?.total ?? 0, [swrData]) as number;

  const filterMutation = async (payload: FieldsType[]) => {
    const dateRange = payload.filter(
      (item) => item.registerName === 'orderDate'
    )?.[0]?.value;

    const withOutDate = payload.filter(
      (item) => item.registerName !== 'orderDate'
    );
    const result = [
      ...withOutDate,
      { registerName: 'startDate', value: dateRange?.[0] },
      { registerName: 'endDate', value: dateRange?.[1] },
    ];

    const mapping = [
      {
        registerName: 'startDate',
        query: 'customerOrderDateStart',
        isDate: true,
      },
      {
        registerName: 'endDate',
        query: 'customerOrderDateEnd',
        isDate: true
      },
      { registerName: 'orderNumber', query: 'orderId', isDate: false },
      { registerName: 'customerName', query: 'customerId', isDate: false },
    ];
    const newResult = result.map((item) =>
      item.registerName === 'customerName'
        ? {
            registerName: item.registerName,
            value: item.value ? [{ ...item.value }] : undefined,
          }
        : item
    );

    const joinResult = opFilterMutation(newResult, mapping);

    joinResult === '' ? setQueryParams('') : setQueryParams(`&${joinResult}`);
  };
  const fetchResult = async (id: string, payload: { detailIds: string[] }) => {
    const options = {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    };

    const json = await request(`/packings/estimate/order/${id}/result`, {
      ...options,
      namespace,
    });

    if (json && typeof json === 'object')
      setResultData(
        json?.details?.map((item: any, index: number) => ({
          ...item,
          id: `${item.index}.${index}`,
        }))
      );

    return json ?? null;
  };

  const exportCaculate = async (
    id: string,
    payload: { detailIds: string[] }
  ) => {
    const options = {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    };

    const json = await request(`/packings/estimate/order/${id}/export`, {
      ...options,
      namespace,
    });
    const filePath = json?.filePath;

    downloadCsv(filePath);
    if (filePath) return ResponseStatus.SUCCESS;
    return ResponseStatus.FAIL;
  };

  useEffect(() => {
    if (isPackagingEstimate(swrData)) {
      const result = swrData.data.map((item) => ({
        ...item,
        id: `${Math.random()}`,
      }));
      setData(result);
      setLoading(false);
      return;
    }

    setLoading(true);
  }, [swrData]);

  return {
    loading,
    data,
    total,
    filterMutation,
    fetchResult,
    exportCaculate,
    page,
    setPage,
    perPage,
    setPerPage,
    resultData,
  };
}
