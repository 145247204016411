export enum EHumanResourceDepartmentInformationState {
  ALL = 'all',
  EFFECT = 'effect',
  INVALID = 'invalid',
}

export const humanResourceDepartmentInformationSchemas = {
  BU: 'bu',
  LEVEL_ID: 'levelId',
  STATE: 'state',
  CODE: 'code',
  NAME: 'name',
  COST_CENTER_ID: 'costCenterId',
} as const;

export const humanResourceDepartmentInformationFilterFormDefaultValues: HumanResourceDepartmentInformationFilterFormField =
  {
    [humanResourceDepartmentInformationSchemas.BU]: '',
    [humanResourceDepartmentInformationSchemas.LEVEL_ID]: undefined,
    [humanResourceDepartmentInformationSchemas.STATE]: EHumanResourceDepartmentInformationState.ALL,
    [humanResourceDepartmentInformationSchemas.COST_CENTER_ID]: '',
  };

export type GenericHumanResourceDepartmentInformationList<D> = {
  currentPage: number;
  data: D;
  firstPageUrl: string;
  from: number;
  lastPage: number;
  lastPageUrl: string;
  nextPageUrl: string;
  path: string;
  perPage: number;
  prevPageUrl: number | null;
  to: number;
  total: number;
};

export type HumanResourceDepartmentInformationData = {
  id: number;
  code: string;
  name: string;
  state: string;
  levelId: string | undefined;
  bu: string;
  costCenterId: string;
};

export type HumanResourceDepartmentInformationApiResponse =
  GenericHumanResourceDepartmentInformationList<
    HumanResourceDepartmentInformationData[]
  >;

export type HumanResourceDepartmentInformationFilterFormField = {
  [humanResourceDepartmentInformationSchemas.BU]: string;
  [humanResourceDepartmentInformationSchemas.LEVEL_ID]:
    | HumanResourceDepartmentInformationLevelList
    | undefined;
  [humanResourceDepartmentInformationSchemas.STATE]:
    | EHumanResourceDepartmentInformationState.ALL
    | 0
    | 1;
  [humanResourceDepartmentInformationSchemas.COST_CENTER_ID]: string;
};

export type HumanResourceDepartmentInformationTableField = {
  page: number;
  perPage: number;
};

export type HumanResourceDepartmentInformationLevelList = {
  id: string;
  name: string;
};

export type HumanResourceDepartmentInformationModalField = {
  [humanResourceDepartmentInformationSchemas.BU]: string;
  [humanResourceDepartmentInformationSchemas.LEVEL_ID]:
    | HumanResourceDepartmentInformationLevelList
    | undefined;
  [humanResourceDepartmentInformationSchemas.COST_CENTER_ID]: string;
};

export function formatHumanResourceDepartmentInformationState(
  state: EHumanResourceDepartmentInformationState
): string {
  if (state === EHumanResourceDepartmentInformationState.ALL) return '全部';
  if (state === EHumanResourceDepartmentInformationState.EFFECT) return '有效';
  if (state === EHumanResourceDepartmentInformationState.INVALID) return '無效';
  return '';
}
