import { TableColumn } from '@mezzanine-ui/core/table';
import { Button, SelectValue } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  getNextParams,
  NextPagination,
  useProductionDeliveryOrders,
} from '@solar/data';
import {
  FilterScaffold,
  MznPaginationTable,
  PageLayout,
} from '@solar/templates';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Filter } from './Filter';
import { PackagingStation } from './types';
import classes from './packaging-station-page.module.scss';
import { PACKED_STATES } from './const';

export function PackagingStationPage() {
  const methods = useForm();
  const navigate = useNavigate();

  const { data, mutateParams, pageInfo } = useProductionDeliveryOrders({
    initiateFetching: true,
  });

  const refetchProductionDeliveryOrders = useCallback(
    (nextPagination: NextPagination) => {
      const filterState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);

      mutateParams({
        offset,
        limit,
        searchTerm: filterState?.searchTerm,
        dateFrom: (filterState?.dateRange ?? [])[0],
        dateTo: (filterState?.dateRange ?? [])[1],
        customerIds: filterState?.suppliers?.map(
          (supplier: SelectValue) => supplier.id
        ),
        packedStates: filterState?.packedStates?.map(
          (state: SelectValue) => state.id
        ),
      });
    },
    [methods]
  );

  const columns: TableColumn<PackagingStation>[] = useMemo(
    () => [
      { title: '出貨單號', dataIndex: 'id' },
      { title: '訂單單號', dataIndex: 'id' },
      { title: '客戶名稱', dataIndex: 'customerName' },
      { title: '採購人員', dataIndex: 'staffName' },
      { title: '開立人員', dataIndex: 'createdAt' },
      {
        title: '狀態',
        render: (source) => {
          return (
            <>
              {
                PACKED_STATES.find((state) => state.id === source.packedState)
                  ?.name
              }
            </>
          );
        },
      },
      {
        title: '操作',
        width: 120,
        align: 'center' as const,
        render(source) {
          return (
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                navigate(`${source.id}`);
              }}
            >
              VIEW
            </Button>
          );
        },
      },
    ],
    [navigate]
  );

  return (
    <PageLayout title="包裝站">
      <FormFieldsWrapper methods={methods}>
        <FilterScaffold mutateParams={refetchProductionDeliveryOrders}>
          <Filter />
        </FilterScaffold>
      </FormFieldsWrapper>
      <MznPaginationTable
        columns={columns}
        dataSource={data ?? []}
        fetchData={refetchProductionDeliveryOrders}
        pageInfo={pageInfo}
        bodyClassName={classes['table-body']}
      />
    </PageLayout>
  );
}
