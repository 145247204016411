import { ReactNode } from "react";

export function A4Wrapper({ children, landscape = false }: { children: ReactNode; landscape?: boolean; }) {
  const landscapeStyle = {
    width: '29.7cm',
    minHeight: '21cm',
  };

  const portraitStyle = {
    width: '21cm',
    minHeight: '29.7cm',
  };

  return (
    <div
      style={{
        ...landscape ? landscapeStyle : portraitStyle,
        padding: '5mm',
        border: '1px #D3D3D3 solid',
      }}
    >
      {children}
    </div>
  );
}