import { Typography } from '@mezzanine-ui/react';

import {
  HookFormFieldComponent,
  HookFormFieldProps,
} from '@mezzanine-ui/react-hook-form';
import { FieldValues } from 'react-hook-form';

export const enhanceCustomMznComponent =
  (Component: HookFormFieldComponent<any>) =>
  ({
    props,
    label,
  }: {
    props: HookFormFieldProps<FieldValues, FieldValues>;
    label?: string;
  }) => {
    return (
      <div>
        <Typography variant="h6" color="text-secondary">
          {label}
          <span className="mzn-form-field__asterisk">*</span>
        </Typography>
        <Component {...props} />
      </div>
    );
  };
