import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Space,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from 'antd';
import { PDFPreviewer } from '../../Templates/PDFPreviewer/PDFPreviewer';
import { UploadOutlined } from '@ant-design/icons';
import {
  BU,
  FCCDrawing,
  PendingDrawing,
  TEMDrawing,
  environment,
  useGetDrawingFilePaths,
  usePresignedFileLink,
} from '@solar/data';
import { useCallback, useEffect, useMemo, useState } from 'react';

type UploadFileResponse = {
  id?: string;
  fileLink?: string;
  fileName?: string;
  fileType?: string;
  s3ObjectKey?: string;
  location?: string;
  s3FilePath?: string;
};

export type UploadDrawingValue = {
  location?: string;
  s3FilePath?: string;
  fileList?: UploadProps<UploadFileResponse>['fileList'];
};

export type UploadDrawingFieldProps = {
  currentDrawing: FCCDrawing | TEMDrawing | PendingDrawing | null;
  value?: UploadDrawingValue;
  onChange?: (value: UploadDrawingValue) => void;
};

export function UploadDrawingField({
  currentDrawing,
  value,
  onChange,
}: UploadDrawingFieldProps) {
  const status = Form.Item.useStatus();
  const hasError = status?.status === 'error';
  const targetFile = value?.fileList?.[0];
  const { filePaths } = useGetDrawingFilePaths(value?.location ?? '台灣');
  const form = Form.useFormInstance();

  const { fileLink, setFileLink } = usePresignedFileLink({
    s3ObjectKey: targetFile?.response?.s3ObjectKey,
  });

  const defaultFile = useMemo(
    () => ({
      location: '台灣',
      s3FilePath: null,
      fileList: [
        {
          uid: currentDrawing?.id?.toString() ?? '',
          name: currentDrawing?.fileName ?? '',
          status: 'done',
          percent: 100,
          response: {
            id: currentDrawing?.id?.toString() ?? '',
            fileLink: currentDrawing?.fileLink,
            fileName: currentDrawing?.fileName,
            s3ObjectKey: currentDrawing?.s3ObjectKey,
          },
        },
      ],
    }),
    [currentDrawing]
  );

  useEffect(() => {
    if (currentDrawing?.fileLink || currentDrawing?.s3ObjectKey) {
      form?.setFieldValue('file', defaultFile);
    }
  }, [currentDrawing, form, defaultFile]);

  const handleCustomRequest = useCallback<
    NonNullable<UploadProps['customRequest']>
  >(
    async ({ file, onSuccess, onError }) => {
      try {
        const formData = new FormData();
        formData.append('drawingFile', file);
        formData.append('drawingFilePath', value?.s3FilePath ?? '');
        const response = await fetch(
          `${environment.API_HOST}/drawing/api/v1/awsS3/uploadFile`,
          {
            method: 'POST',
            body: formData,
            headers: { apikey: environment.API_KEY },
          }
        );

        if (!response?.ok) {
          throw new Error('Upload failed');
        }

        const result = await response.json();
        onSuccess?.({
          id: result?.data?.id,
          fileLink: result?.data?.fileLink,
          fileName: result?.data?.fileName,
          fileType: result?.data?.fileType,
          s3ObjectKey: result?.data?.s3ObjectKey,
          location: value?.location,
          s3FilePath: value?.s3FilePath,
        });
      } catch (err: any) {
        onError?.(err);
      }
    },
    [value]
  );

  const handleFilePathChange = useCallback(
    (s3FilePath: string) => {
      onChange?.({
        location: value?.location,
        s3FilePath,
        fileList: [],
      });
      setFileLink(undefined);
    },
    [value, onChange, setFileLink]
  );

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space style={{ width: '100%' }}>
        <Select
          style={{ width: '200px' }}
          value={value?.s3FilePath}
          onChange={handleFilePathChange}
          options={filePaths?.map((item) => ({
            label: item?.s3FilePath,
            value: item?.s3FilePath,
          }))}
        />
        <Upload
          disabled={!value?.s3FilePath}
          fileList={value?.fileList ?? []}
          maxCount={1}
          multiple={false}
          accept=".pdf"
          customRequest={handleCustomRequest}
          itemRender={() => null}
          onChange={(info) => {
            onChange?.({
              location: value?.location,
              s3FilePath: value?.s3FilePath,
              fileList: info?.fileList,
            });
            switch (info.file.status) {
              case 'done':
                message.success(`${info.file.name} 上傳成功`);
                break;
              case 'error':
                message.error(`${info.file.name} 上傳失敗`);
                break;
              default:
                break;
            }
          }}
        >
          <Button
            danger={hasError}
            disabled={!value?.s3FilePath}
            icon={<UploadOutlined />}
          >
            上傳
          </Button>
        </Upload>
      </Space>
      <Button type="link" style={{ pointerEvents: 'none' }}>
        {targetFile?.response?.fileName}
      </Button>
      {fileLink && <PDFPreviewer fileLink={fileLink} />}
    </Space>
  );
}
