import { Table, Button } from "@mezzanine-ui/react";
import { useNavigate } from "react-router";

type ReturnMaterialTableProps = {
  dataSource: any[],
};

export function ReturnMaterialTable({ dataSource }: ReturnMaterialTableProps) {
  const navigate = useNavigate();
  return (
    <Table
      style={{ height: '50vh' }}
      columns={[
        {
          title: '來源單號',
          dataIndex: 'id',
        },
        {
          title: '領料日',
          dataIndex: 'date',
        },
        {
          title: '開立者',
          dataIndex: 'creator',
        },
        {
          title: '來源',
          render: (source: any) => {
            switch(source?.resourceType) {
              case '1':
                return '工單';
              case '2':
                return '部門費用';
              case '3':
                return 'IPQC 檢測';
              default:
                return '';
            }
          },
        },
        {
          title: '操作',
          render: (source: any) => (
            <Button onClick={() => navigate(`/warehouse/return-material/${source?.id}`)}>View</Button>
          ),
        },
      ]}
      dataSource={dataSource} />
  )
}
