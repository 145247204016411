import { Button, Popper, Table } from '@mezzanine-ui/react';
import {
  LocationLevel,
  ZoneEntity,
  getZoneDisplayName,
  useSVGs,
} from '@solar/data';
import { useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  LocationSVGViewer,
  transformZoneToLocation,
} from '../../../Equipment/LocationSelector/LocationSVGViewer';
import { ChooseMaterialFirstLayerModal } from './Modal/ChooseMaterialFirstLayerModal';
import { ChooseMaterialSecondLayerModal } from './Modal/ChooseMaterialSecondLayerModal';
import { useModalController } from '../../ModalLayout/hooks';
import { TableColumn } from '@mezzanine-ui/core/table';
import { ExpectedTakeTableColumn } from './Modal/ChooseMaterialFirstLayerModal';

export function CreateMaterialRegisterOrderTable({
  bodyClassName,
}: {
  bodyClassName: string;
}) {
  const [anchorRef, setAnchorRef] = useState<HTMLDivElement | null>();
  const { svg, mutateGetSVG, resetSVG } = useSVGs();
  const [currentZone, setCurrentZone] = useState<ZoneEntity | null>();

  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tableValues',
  });

  // modal controllers
  const firstLayerModalController = useModalController();
  const secondLayerModalController = useModalController();

  const columns: TableColumn<ExpectedTakeTableColumn>[] = useMemo(
    () => [
      { title: '料號', dataIndex: 'materialId' },
      { title: '規格', dataIndex: 'spec' },
      { title: '批號', dataIndex: 'batch' },
      {
        title: '儲位',
        ellipsis: false,
        render: (source) => (
          <div
            data-zone-id={source?.zone?.id}
            onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => {
              if (source?.zone?.id) {
                mutateGetSVG(source?.zone?.area?.id);
                setCurrentZone(source?.zone);
                setAnchorRef(event.currentTarget);
              }
            }}
            onMouseLeave={() => {
              if (source?.zone?.id) {
                resetSVG();
                setCurrentZone(null);
                setAnchorRef(null);
              }
            }}
          >
            {getZoneDisplayName(source?.zone)}
          </div>
        ),
      },
      {
        title: '載具',
        dataIndex: 'vehicle',
      },
      {
        title: '領取數量',
        dataIndex: 'amount',
      },
      {
        title: '操作',
        width: 120,
        align: 'center' as const,
        render(source, index) {
          return (
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                remove(index);
              }}
            >
              刪除
            </Button>
          );
        },
      },
    ],
    [mutateGetSVG, remove, resetSVG]
  );

  return (
    <>
      <div>
        <Button
          type="button"
          variant="outlined"
          onClick={() => firstLayerModalController.openModal({})}
        >
          選擇物料
        </Button>
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={(fields ?? []).map((v) => v)}
          bodyClassName={bodyClassName}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained">送出</Button>
      </div>
      <Popper
        anchor={anchorRef}
        open={Boolean(anchorRef)}
        style={{
          border: '1px solid #000',
          borderRadius: '5px',
          backgroundColor: '#fff',
        }}
        options={{
          placement: 'auto',
        }}
      >
        <LocationSVGViewer
          svg={svg}
          currentLevel={LocationLevel.ZONE}
          currentLocation={
            currentZone ? transformZoneToLocation(currentZone) : undefined
          }
        />
      </Popper>
      <ChooseMaterialFirstLayerModal
        {...firstLayerModalController}
        secondLayerModalController={secondLayerModalController}
      />
      <ChooseMaterialSecondLayerModal
        firstLayerModalController={firstLayerModalController}
        secondLayerModalController={secondLayerModalController}
        append={append}
      />
    </>
  );
}
