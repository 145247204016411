import { Modal, ModalBody, ModalHeader } from '@mezzanine-ui/react';
import styles from './location-selector.module.scss';
import { LocationSelector, useLocationSelector } from './LocationSelector';
import { useCallback, useEffect } from 'react';
import { useTargetModal } from '@solar/templates';

export const LOCATION_SELECTOR = 'LOCATION_SELECTOR';

type LocationSelectorModalProps = {
  open?: boolean;
  data?: any;
  onSubmit: (item: any) => void;
  closeModal?: () => void;
  headerText?: string;
};

export function LocationSelectorModal({
  open,
  onSubmit,
  closeModal,
  headerText = '設備位置',
}: LocationSelectorModalProps) {
  const modalController = useTargetModal(LOCATION_SELECTOR);
  const locationSelectorController = useLocationSelector();
  const { selectedItem, resetLocationSelector } = locationSelectorController;

  const modalOpen = modalController?.open ?? open ?? false;

  const onClose = useCallback(() => {
    resetLocationSelector();
    (closeModal ?? modalController?.closeModal)?.();
  }, [closeModal, modalController, resetLocationSelector]);

  useEffect(() => {
    if (selectedItem) {
      onSubmit(selectedItem);
      onClose();
    }
  }, [onClose, onSubmit, selectedItem]);

  useEffect(() => {
    if (modalOpen) {
      resetLocationSelector();
    }
  }, [modalOpen, resetLocationSelector]);

  return (
    <Modal className={styles.modal_wrapper} open={modalOpen} onClose={onClose}>
      <ModalHeader>{headerText}</ModalHeader>
      <ModalBody>
        <LocationSelector {...locationSelectorController} />
      </ModalBody>
    </Modal>
  );
}
