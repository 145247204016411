import { TableColumn } from '@mezzanine-ui/core/table';
import { RefObject, useMemo } from 'react';
import { DraggableTitle } from './DraggableTitle';

export function useDraggableColumns<DataType = Record<string, unknown>>(
  tableRef: RefObject<HTMLTableElement>,
  columns: (TableColumn<DataType> & { draggable?: boolean })[]
) {
  return useMemo<TableColumn<DataType & Record<string, unknown>>[]>(() => {
    const defaultDraggableColumns = ['料號', '品名'];
    const defaultColumnWidthMap = new Map([
      ['料號', 200],
      ['品名', 500],
    ]);

    return columns.map((column, index) => {
      const width =
        column?.width ?? defaultColumnWidthMap.get(column?.title ?? '');
      const hasRenderTitle =
        column?.draggable ||
        !!column?.renderTitle ||
        defaultDraggableColumns.includes(column?.title ?? '');

      if (hasRenderTitle) {
        return {
          ...column,
          width,
          title: undefined,
          renderTitle:
            column?.renderTitle ??
            (() => (
              <DraggableTitle
                columnIndex={index}
                tableRef={tableRef}
                title={column.title}
                align={column.align}
              />
            )),
        };
      } else {
        return {
          ...column,
          width,
          title: column?.title ?? '',
          renderTitle: undefined,
        };
      }
    });
  }, [columns, tableRef]);
}
