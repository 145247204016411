import { useDebounce } from '@solar/data';
import useSWR from 'swr';
import { LocationLevel } from '../types';

interface UseGetAllLevelLocationsProps {
  searchTerm: string;
}

export function useGetAllLevelLocations({
  searchTerm,
}: UseGetAllLevelLocationsProps) {
  const locationId = useDebounce(searchTerm, 1000);

  const { data, error, isValidating } = useSWR<
    {
      id: string;
      level: LocationLevel;
    }[]
  >(locationId ? [`/locations/${locationId}`] : null);

  return {
    locations: data ?? [],
    error,
    loading: !data && !error && isValidating,
  };
}
