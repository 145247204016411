import { useState } from 'react';
// import useSWR from "swr";
import { NextPagination } from '../pagination';

export function useReturnMaterials() {
  const [params, setParams] = useState<any>();
  // const { data, error } = useSWR(['/materials/mapping-old-materials', { params }]);
  const data = [
    {
      id: 'DLY0001',
      date: '2023/02/15',
      creator: '張博閔',
      resourceType: '1',
    },
    {
      id: 'DLY0002',
      date: '2023/02/15',
      creator: '張博閔',
      resourceType: '2',
    },
    {
      id: 'DLY0003',
      date: '2023/02/15',
      creator: '張博閔',
      resourceType: '3',
    },
  ];

  const mutateGetReturnMaterials = (nextParams: any) => {
    setParams(nextParams);
  };

  return {
    returnMaterials: data || [],
    pageInfo: {},
    // isLoading: !data && !error,
    mutateGetReturnMaterials,
  };
}
