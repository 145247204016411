import { Button, SelectValue } from '@mezzanine-ui/react';
import {
  FormFieldsWrapper,
  SearchInputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  useMaterialCategories,
  useMaterialGroups,
  useMaterialViewKeyword,
  GetMaterialsParams,
} from '@solar/data';
import { UseFormReturn } from 'react-hook-form';
import styles from './material-view-filter.module.scss';
import { Dispatch, SetStateAction } from 'react';

type MaterialViewFilterProps = {
  methods: UseFormReturn;
  setParams: Dispatch<SetStateAction<GetMaterialsParams>>;
  openLocationSelectorModal: (arg0: any) => void;
};

export function MaterialViewFilter({
  setParams,
  methods,
  openLocationSelectorModal,
}: MaterialViewFilterProps) {
  const { materialCategories } = useMaterialCategories();
  const { materialGroups } = useMaterialGroups();
  const { materialViewKeyword } = useMaterialViewKeyword();

  return (
    <FormFieldsWrapper className={styles.filter_form_wrapper} methods={methods}>
      <SelectField
        label="物料類型："
        mode="multiple"
        registerName="categories"
        options={materialCategories ?? []}
      />
      <SelectField
        label="物料群組："
        mode="multiple"
        registerName="materialGroups"
        options={materialGroups ?? []}
      />
      <SelectField
        label="關鍵字對象："
        defaultValue={materialViewKeyword[0] as SelectValue}
        registerName="materialViewKeyword"
        options={materialViewKeyword ?? []}
      />
      <SearchInputField
        registerName="searchInput"
        className={styles.searchInput}
        placeholder="輸入關鍵字"
        clearable
      />
      <div>
        <Button
          variant="contained"
          type="button"
          onClick={() => {
            const filterState = methods?.getValues();
            const searchType = filterState.materialViewKeyword.id ?? '';
            const searchTerm = filterState.searchInput ?? '';
            setParams((prevParams) => ({
              ...prevParams,
              searchType,
              searchTerm,
            }));
          }}
        >
          搜尋
        </Button>
      </div>
    </FormFieldsWrapper>
  );
}
