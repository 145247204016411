import { ModalLayout } from '../../ModalLayout/ModalLayout';
import { Loading, ModalActions, Typography } from '@mezzanine-ui/react';

import { useModalController } from '../../ModalLayout/hooks';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import { useRef } from 'react';
import classes from './collect-material-register-modal.module.scss';
import {
  MaterialRegisterEnum,
  renderMaterialRegisterString,
} from './register-material-modal-enum';
import { enhanceCustomMznComponent } from '../../IncomingInspectOperationPage/Modals/InspectMaterialSecondLayerModal';

const expectRegisterSchema = Yup.object({
  amount: Yup.string().required('必填'),
  vehicle: Yup.string().required('必填'),
});

const extraRegisterSchema = Yup.object({
  materialCode: Yup.string().required('必填'),
  location: Yup.string().required('必填'),
  amount: Yup.string().required('必填'),
  vehicle: Yup.string().required('必填'),
});

export function CollectMaterialRegisterModal({
  open,
  data,
  closeModal,
}: ReturnType<typeof useModalController>) {
  const methods = useForm({
    resolver: yupResolver(
      data?.modalHeader === MaterialRegisterEnum.Expected
        ? expectRegisterSchema
        : extraRegisterSchema
    ),
  });

  const {
    formState: { isSubmitting },
    reset,
  } = methods;
  const submitRef = useRef<HTMLInputElement | null>(null);
  const handleOnConfirm = () => {
    submitRef.current?.click();
  };

  const handleClose = () => {
    closeModal();
    reset();
  };

  return (
    <ModalLayout
      className={classes.modal}
      modalHeader={renderMaterialRegisterString(
        data?.modalHeader as MaterialRegisterEnum
      )}
      open={open}
      closeModal={handleClose}
      modalFooter={
        <ModalActions
          confirmText="確認"
          cancelText="取消"
          onConfirm={() => handleOnConfirm()}
          onCancel={() => handleClose()}
          confirmButtonProps={{ type: 'submit' }}
        />
      }
    >
      <FormFieldsWrapper
        methods={methods}
        onSubmit={(v) => {
          closeModal();
        }}
      >
        <Loading loading={isSubmitting}>
          <div className={classes['input-wrapper']}>
            {data?.modalHeader === MaterialRegisterEnum.Extra && (
              <>
                {enhanceCustomMznComponent(InputField)({
                  props: {
                    registerName: 'materialCode',
                  },
                  label: '料號',
                })}
                {enhanceCustomMznComponent(InputField)({
                  props: {
                    registerName: 'location',
                  },
                  label: '儲位',
                })}
              </>
            )}
            {data?.modalHeader === MaterialRegisterEnum.Expected && (
              <>
                <Typography>料號：AABBBCD02921</Typography>
                <Typography>批號：20230405025-001</Typography>
                <Typography>儲位：1100A1C4J1B / 5</Typography>
              </>
            )}
            <div className={classes['amount']}>
              {enhanceCustomMznComponent(InputField)({
                props: {
                  registerName: 'amount',
                },
                label: '領取數量',
              })}
              <Typography>/</Typography>
              <Typography variant="h4">5</Typography>
            </div>
            {enhanceCustomMznComponent(InputField)({
              props: {
                registerName: 'vehicle',
              },
              label: '載具編號',
            })}
            <input type="submit" ref={submitRef} style={{ display: 'none' }} />
          </div>
        </Loading>
      </FormFieldsWrapper>
    </ModalLayout>
  );
}
