import { API_NAMESPACE, environment, request } from '@solar/data';
import { Message } from '@mezzanine-ui/react';
import { DownloadBarcodeStickerProps, LabelStickerEndpoint } from '../typings';

const namespace = API_NAMESPACE.MATERIALS;

export const downloadBarcodeSticker = async (
  props: DownloadBarcodeStickerProps
) => {
  const { endpoint } = props;

  try {
    let params = {};

    if (endpoint === LabelStickerEndpoint.MaterialLabel) {
      params = { batchIds: props.batchIds };
    } else if (endpoint === LabelStickerEndpoint.LoaderLabel) {
      params = { loaderIds: props.loaderIds };
    }

    const res = await request(endpoint, {
      params,
      headers: {
        Accept: 'application/pdf',
        apikey: environment.API_KEY,
      },
      namespace,
      responseParser: (res) => res,
    });

    const blob = await res.blob();

    return new Blob([blob], { type: 'application/pdf' });
  } catch {
    Message.error('列印失敗');

    return null;
  }
};
