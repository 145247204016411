import { Message } from '@mezzanine-ui/react';
import { NextPagination, WaitReceiptObj } from '@solar/data';
import { useModalGroupController } from '@solar/templates';
import { Modals } from '../types';

export const handleMaterialIdBarcodeScan = async ({
  data,
  inputValue,
  modalGroupController,
  refetchWaitReceiptByPagination,
}: {
  inputValue: string;
  data: WaitReceiptObj[];
  modalGroupController: ReturnType<typeof useModalGroupController>;
  refetchWaitReceiptByPagination: (nextPagination: NextPagination) => void;
}) => {
  const matchedRowData = data.find((row) => row.materialBarcode === inputValue);

  if (matchedRowData) {
    modalGroupController.openModal(Modals.Inspect, matchedRowData);

    refetchWaitReceiptByPagination({ nextPage: 1 });
  } else {
    Message.warning('找不到匹配的資料');
  }
};
