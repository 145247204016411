import { useState } from 'react';
import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { OffsetBased, PageInfo } from '../pagination';

const namespace = API_NAMESPACE.MATERIALS;

export enum OrderTypes {
  INVENTORY_PURCHASE_ORDER = 'INVENTORY_PURCHASE_ORDER',
  PRODUCTION_WORK_ORDER = 'PRODUCTION_WORK_ORDER',
  CUSTOMER_SUPPLY_ORDER = 'CUSTOMER_SUPPLY_ORDER',
  MISC_PURCHASE_ORDER = ' MISC_PURCHASE_ORDER',
  NO_ORDER = 'NO_ORDER',
}

export enum ActionTypes {
  RECEIPT = 'RECEIPT',
  RETURN_TO_SUPPLIER = 'RETURN_TO_SUPPLIER',
  SHIP = 'SHIP',
  RETURN_TO_WAREHOUSE = 'RETURN_TO_WAREHOUSE',
  TRANSFER = 'TRANSFER',
}

export type InventoryByMaterialLogParam = {
  orderTypes: OrderTypes[];
  actionTypes: ActionTypes[];
  searchTerms: string;
  dateFrom: string;
  dateTo: string;
};

export function useInventoryByMaterialLog() {
  const [params, setParams] = useState<
    Partial<InventoryByMaterialLogParam> & OffsetBased
  >();
  const { data, error } = useSWR<{
    records: any[];
    pageInfo: PageInfo;
  }>(
    params
      ? [
          '/warehouses/inventory-material-log',
          {
            params,
            namespace,
          },
        ]
      : null
  );

  const mutateParams = (
    payload: Partial<InventoryByMaterialLogParam> & OffsetBased
  ) => {
    setParams(payload);
  };

  return {
    records: data?.records || [],
    pageInfo: data?.pageInfo,
    isLoading: !data && !error,
    mutateParams,
  };
}
