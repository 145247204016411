import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { SalesReturnListItem } from './SalesReturnPage.interface';
import { Button } from '@mezzanine-ui/react';
import { TableColumn } from '@mezzanine-ui/core/table';
import dayjs from 'dayjs';

export function useSalesReturnTableColumns(): TableColumn<SalesReturnListItem>[] {
  const navigate = useNavigate();

  return useMemo(
    () => [
      {
        title: '操作',
        render: (item: SalesReturnListItem, index: number) => {
          return (
            <Button
              variant="outlined"
              onClick={() => {
                navigate(`/sales-distribution/sales-return/${item.id}`);
              }}
            >
              檢視
            </Button>
          );
        },
      },
      { title: '出貨單號', dataIndex: 'id', ellipsis: false },
      { title: '出貨單類型', dataIndex: 'orderTypeName', ellipsis: false },
      {
        title: '預計收貨日期',
        ellipsis: false,
        render: (record) => {
          return dayjs(record.expectedCompletedAt).format('YYYY/MM/DD');
        },
      },
      { title: '客戶名稱', dataIndex: 'customerName', ellipsis: false },
      { title: '客戶代碼', dataIndex: 'customerId', ellipsis: false },
      { title: '業務人', dataIndex: 'employeeName', ellipsis: false },
    ],
    [navigate]
  );
}
