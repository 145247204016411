import {
  Message,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  Typography,
} from '@mezzanine-ui/react';
import { useForm } from 'react-hook-form';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import styles from './../../../TargetNumber.module.scss';
import { DispatchedTargetEditModalFields } from '../../../TargetNumber.interface';
import { useEffect, useState } from 'react';
import { updateNumberHistoryById } from '@solar/data';

export function DispatchedTargetEditModal({
  open,
  onClose,
  selectedNumberHistoryId,
  selectedItem,
  onSuccessfulDispatch,
}: {
  open: boolean;
  onClose: () => void;
  selectedNumberHistoryId: number | null;
  selectedItem: any | null;
  onSuccessfulDispatch?: () => void;
}) {
  const methods = useForm<DispatchedTargetEditModalFields>({
    defaultValues: {
      customerCombination: '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      methods.reset();
    }
  }, [open, methods]);

  const handleSubmit = methods.handleSubmit(async (data) => {
    if (selectedNumberHistoryId) {
      setIsLoading(true);
      try {
        await updateNumberHistoryById(selectedNumberHistoryId, {
          customer_issued_number: data.customerCombination,
        });
        Message.success('靶編更新成功');
        onClose();
        if (onSuccessfulDispatch) {
          onSuccessfulDispatch();
        }
      } catch (error) {
        Message.error('靶編更新失敗');
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  });

  return (
    <Modal
      hideCloseIcon
      className={styles.modal}
      onClose={onClose}
      open={open}
      size="medium"
      loading={isLoading}
    >
      <ModalHeader>編輯已取號客戶靶編</ModalHeader>
      <ModalBody>
        <Typography variant="h5">
          客戶名稱: {selectedItem?.customer_name} -{' '}
          {selectedItem?.customer_short_name} - {selectedItem?.customer_id}
        </Typography>
        <Typography variant="h5">
          訂單: {selectedItem?.order_id} - {selectedItem?.sales_order_line}
        </Typography>
        <Typography variant="h5">料號: {selectedItem?.material_id}</Typography>
        <br />
        <Typography variant="h5">
          光洋靶編: {selectedItem?.internal_issued_number}
        </Typography>
        <br />
        <Typography variant="h5">
          客戶靶編: {selectedItem?.customer_issued_number}
        </Typography>
        <br />
          <FormFieldsWrapper methods={methods} className={styles.creatingForm}>
            <InputField label="新客戶靶編" registerName="customerCombination" required />
          </FormFieldsWrapper>
      </ModalBody>
      <ModalActions
        cancelText="取消"
        confirmText="確定"
        onCancel={onClose}
        onConfirm={handleSubmit}
      />
    </Modal>
  );
}
