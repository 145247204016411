import { SelectValue } from '@mezzanine-ui/react';
import { InventoryItem } from '@solar/data';

export type ManualData = Omit<
  Partial<InventoryItem>,
  'batchStoredLoaderRecordId'
> & {
  batchStoredLoaderRecordId: string;
  materialID: string;
  materialSapBatchId: string;
  materialSubBatchId: string;
  usedOldMaterial: 'true' | 'false';
  loaderId: string;
  // materialQuantity: number;
  usedMaterialUnitQuantity: string;
  requestOrderStockUnitQuantity: string;
  // requestOrderActualWeightUnitQuantity: string;
};

export type MaterialAllocationFormValues = {
  targetWeight: number;
  manualData: ManualData[];
  targetMaterialSubBatchCount: number | null;
  recipeId: SelectValue | null;
  recipeVersion: SelectValue;
  recipeVersions: SelectValue[];
  expectedCompletedAt: string;
  productionExpectedFinishAt: string;
  remark: string;
};

export type CalculateMaterialAllocationsPayload = {
  manualData: (Omit<ManualData, 'usedOldMaterial'> & {
    usedOldMaterial: boolean;
  })[];
  targetWeight: number;
};

export type CalculateSuggestedMaterialAllocationsPayload = {
  calculationInput: CalculateMaterialAllocationsPayload;
  stockQueryFactoryId: string;
  stockQueryAreaIds: string[];
};

export type CalculateSuggestedMaterialAllocationsResult = {
  data: {
    errorRecords: {
      componentCodeError: {
        componentCode: string;
        errorMessage: string[];
      }[];
      componentItemNumber: string;
    }[];
    hasError: boolean;
    records: (Omit<Partial<InventoryItem>, 'batchStoredLoaderRecordId'> & {
      batchStoredLoaderRecordId: string;
      suggestedMaterialUnitQuantity: string;
      usedOldMaterial: 'true' | 'false';
    })[];
  };
};

export type MaterialAllocationResult = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  workOrderId: string;
  targetMaterialId: string;
  targetWeight: string;
  componentCode: string;
  status: 'EDITING' | 'FINISHED';
  calculationItems: Array<{
    id: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    resultId: string;
    actualRequired: string;
    actualWtPercent: string;
    adjustedAtPercent: string;
    adjustedWtPercent: string;
    atomicWeight: string;
    batchAllocated: string;
    batchRequired: string;
    difference: string;
    elementName: string;
    fixMaterialMeltFactor: string;
    gapAdjust: string;
    issuedQuality: string;
    meltAdjust: string;
    newMaterialMeltFactor: string;
    oldMaterialMeltFactor: string;
    productAdjustRate: string;
    standardAtPercent: string;
    standardWtPercent: string;
  }>;
  materialItems: Array<{
    id: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string;
    resultId: string;
    materialId: string;
    loaderId: string;
    usedOldMaterial?: boolean;
    availableMaterialStockUnitQuantity: string;
    usedMaterialUnitQuantity: string;
    materialSapBatchId: string;
    materialSubBatchId: string;
    batchStoredLoaderRecordId: number;
  }>;
  materialRequestOrderId: string;
  recipeId: string | null;
  recipeVersion: string | null;
  remark: string | null;
  expectedCompletedAt: string | null;
  productionExpectedFinishAt: string | null;
  targetMaterialSubBatchCount: number | null;
};

export interface SaveAndCreateMaterialRequestPayload {
  calculatedResults: CalculatedResult[];
  componentCode: string;
  expectedCompletedAt: string;
  materialResults: MaterialResult[];
  needCreate: boolean;
  productionExpectedFinishAt: string;
  recipeId: string | null;
  recipeVersion: number | null;
  remark: string;
  targetMaterialId: string;
  targetMaterialSubBatchCount: number;
  targetWeight: number;
}

export interface CalculatedResult {
  actualRequired: number;
  actualWtPercent: number;
  adjustedAtPercent: number;
  adjustedWtPercent: number;
  atomicWeight: number;
  batchAllocated: number;
  batchRequired: number;
  difference: number;
  elementName: string;
  fixMaterialMeltFactor: number;
  gapAdjust: number;
  issuedQuality: number;
  meltAdjust: number;
  newMaterialMeltFactor: number;
  oldMaterialMeltFactor: number;
  productAdjustRate: number;
  standardAtPercent: number;
  standardWtPercent: number;
}

export interface MaterialResult
  extends Partial<Omit<InventoryItem, 'batchStoredLoaderRecordId'>> {
  batchStoredLoaderRecordId: string;
  loaderId: string;
  materialID: string;
  materialSapBatchId: string;
  // materialStockUnit: string;
  materialSubBatchId: string;
  requestOrderActualWeightUnitQuantity: string;
  requestOrderStockUnitQuantity: string;
  // shelfId: string;
  usedMaterialUnitQuantity: string;
  usedOldMaterial: boolean;
}
