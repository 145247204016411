import { Button, Table, Typography } from '@mezzanine-ui/react';
import { FormFieldsWrapper, SelectField } from '@mezzanine-ui/react-hook-form';
import { useHumanResourceBUReport } from '@solar/data';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styles from './HumanResourceReportPage.module.scss';
import { Header } from './HumanResourceReportsComponents/HumanResourceReportsComponents';
import { ExportModal } from './Modals/Modals';
import { CalendarInputTagsTemplate } from './CalendarInputTagsTemplate/CalendarInputTagsTemplate';

enum Modal {
  EXPORT = 'EXPORT',
}

export function HumanResourceReportPage() {
  const {
    data: reportList,
    loading,
    baseYear: baseYearData,
    setBaseYear: setBaseYearData,
    years: yearOptions,
    filterMutation: filterBUReport,
  } = useHumanResourceBUReport();
  const methods = useForm();
  const [modal, setModal] = useState<{
    type: Modal;
    baseYear: { name: string; id: string };
    bonusConditions: string[];
  } | null>(null);

  const columns = [
    { title: '工號', dataIndex: 'id', width: 120 },
    { title: '姓名', dataIndex: 'name', width: 120 },
    { title: 'BU', dataIndex: 'bu', width: 120 },
    { title: '所屬部門', dataIndex: 'unitPath' },
    { title: '固定薪', dataIndex: 'salary', width: 120 },
    { title: '獎金', dataIndex: 'bonus', width: 100 },
    { title: '年薪', dataIndex: 'total', width: 140 },
  ];

  const onClose = () => {
    setModal(null);
  };

  const baseYear = methods.getValues('yearSelect');
  const bonusConditions = methods.getValues('input-tags-field');
  const openExportModal = () => {
    if (!baseYear) return;
    setModal({
      type: Modal.EXPORT,
      baseYear,
      bonusConditions,
    });
  };

  useEffect(() => {
    methods.watch();
    setBaseYearData(baseYear);
  }, [baseYear]);

  return (
    <div className={styles.wrapper}>
      {modal && (
        <ExportModal
          onClose={onClose}
          baseYear={modal.baseYear.name}
          bonusConditions={modal.bonusConditions}
        />
      )}
      <Header
        title="市場薪資結構報告"
        button={null}
        customChildren={
          <Typography variant="h5" color="error-light">
            請點選年份和獎金條件，以下為局部預覽結果
          </Typography>
        }
      />
      <FormFieldsWrapper methods={methods}>
        <div className={styles.filter}>
          <div className={styles.leftFilter}>
            <Typography className={styles.year}>固定年份：</Typography>
            <SelectField
              registerName="yearSelect"
              placeholder="預設文字"
              options={yearOptions}
              clearable={true}
            ></SelectField>
            <Typography className={styles.date}>績效獎金條件：</Typography>
            <CalendarInputTagsTemplate methods={methods} />
          </div>
          <div>
            <Button
              type="button"
              className={styles.filterButton}
              variant="contained"
              disabled={!baseYearData}
              onClick={() =>
                filterBUReport({
                  baseYear: methods.getValues('yearSelect')?.name,
                  bonusConditions: methods.getValues('input-tags-field'),
                })
              }
            >
              送出篩選條件
            </Button>
            <Button
              type="button"
              size="medium"
              variant="contained"
              disabled={!baseYearData}
              onClick={openExportModal}
            >
              匯出
            </Button>
          </div>
        </div>
        <Table
          // loading={loading}
          bodyClassName={styles.table}
          dataSource={reportList ?? []}
          columns={columns}
        />
      </FormFieldsWrapper>
    </div>
  );
}
