import * as Yup from 'yup';
import { ConfigureTableProps, DispatchSettingModalFormProps } from '../types';

type TableProps =
  DispatchSettingModalFormProps['tables'][number]['table'][number];

function isKey<T extends object>(x: T, k: PropertyKey): k is keyof T {
  return k in x;
}

const isDispatchSettingModalTable = (data: object): data is TableProps => {
  let bool = true;

  const keys = Object.keys(data);
  keys.forEach((key) => {
    if (!isKey(data, key)) {
      bool = false;
    }
  });

  return bool;
};

const EXCEED_LIMIT = '領用超過庫存數量';
const POSITIVE_NEEDED = '需大於 0';
const REQUIRED = '必填';

export const quantityValidation = ({
  materialSubBatchCountRequired,
}: {
  materialSubBatchCountRequired: boolean;
}) => {
  const validateObj = Yup.object().shape({
    tables: Yup.array().of(
      Yup.object().shape({
        preserveTable: Yup.array().of(
          Yup.object().shape({
            receiveQuantity: Yup.mixed().test(
              'limit',
              '領用超過庫存數量',
              function (_, context) {
                if (
                  context &&
                  context.parent &&
                  isDispatchSettingModalTable(context.parent)
                ) {
                  const inputValue =
                    context?.from?.[1]?.value?.selectedRowKeyToReceiveQuantity[
                      context.parent?.id
                    ];
                  const storageMap: {
                    [key in ConfigureTableProps['selectedUnit'][][number]['id']]: string;
                  } = {
                    materialStockUnit:
                      context.parent?.availableMaterialStockUnitQuantity,
                    materialInputUnit:
                      context.parent?.availableMaterialInputUnitQuantity,
                  };

                  const storage = Number(
                    storageMap?.[context.parent?.selectedUnit?.id] || 0
                    // context.parent?.availableMaterialInputUnitQuantity || 0
                  );

                  const selectedRowKeys =
                    (context?.from?.[1].value as any)?.selectedRowKeys ??
                    ([] as string[]);

                  const isTickedOn = selectedRowKeys.includes(
                    context.parent?.id
                  );

                  if (storage < Number(inputValue || 0)) {
                    return this.createError({ message: EXCEED_LIMIT });
                  }

                  if (!inputValue && isTickedOn) {
                    return this.createError({
                      message: REQUIRED,
                    });
                  }

                  if (
                    isTickedOn &&
                    Boolean(inputValue) &&
                    Number(inputValue || 0) <= 0
                  ) {
                    return this.createError({ message: POSITIVE_NEEDED });
                  }

                  return true;
                }

                return true;
              }
            ),
          })
        ),
        table: Yup.array().of(
          Yup.object().shape({
            receiveQuantity: Yup.mixed().test(
              'limit',
              '領用超過庫存數量 | 需大於0',
              function (_, context) {
                if (
                  context &&
                  context.parent &&
                  isDispatchSettingModalTable(context.parent)
                ) {
                  const inputValue =
                    context?.from?.[1]?.value?.selectedRowKeyToReceiveQuantity[
                      context.parent?.id
                    ];

                  const storageMap: {
                    [key in ConfigureTableProps['selectedUnit'][][number]['id']]: string;
                  } = {
                    materialStockUnit:
                      context.parent?.availableMaterialStockUnitQuantity,
                    materialInputUnit:
                      context.parent?.availableMaterialInputUnitQuantity,
                  };

                  const storage = Number(
                    storageMap?.[context.parent?.selectedUnit?.id] || 0
                    // context.parent?.availableMaterialInputUnitQuantity || 0
                  );

                  const selectedRowKeys =
                    (context?.from?.[1].value as any)?.selectedRowKeys ??
                    ([] as string[]);

                  const isTickedOn = selectedRowKeys.includes(
                    context.parent?.id
                  );

                  if (isTickedOn && storage < Number(inputValue || 0)) {
                    return this.createError({ message: EXCEED_LIMIT });
                  }

                  if (!inputValue && isTickedOn) {
                    return this.createError({ message: REQUIRED });
                  }

                  if (
                    isTickedOn &&
                    Boolean(inputValue) &&
                    Number(inputValue || 0) <= 0
                  ) {
                    return this.createError({ message: POSITIVE_NEEDED });
                  }

                  return true;
                }

                return true;
              }
            ),
          })
        ),
      })
    ),
  });

  return validateObj;
};
