import { SelectValue } from '@mezzanine-ui/react';
import {
  FormFieldsWrapper,
  SearchInputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  useMaterialCategories,
  useMaterialGroups,
  useMaterialViewKeyword,
} from '@solar/data';
import { UseFormReturn } from 'react-hook-form';
import styles from './material-transfer-filter.module.scss';

type MaterialTransferFilterProps = {
  methods: UseFormReturn;
};

export function MaterialTransferFilter({ methods }: MaterialTransferFilterProps) {
  const { materialCategories } = useMaterialCategories();
  const { materialGroups } = useMaterialGroups();
  const { materialViewKeyword } = useMaterialViewKeyword();

  return (
    <FormFieldsWrapper className={styles.filter_form_wrapper} methods={methods}>
      <SelectField
        label="物料類型："
        mode="multiple"
        registerName="categories"
        options={materialCategories ?? []}
      />
      <SelectField
        label="物料群組："
        mode="multiple"
        registerName="materialGroups"
        options={materialGroups ?? []}
      />
      <SelectField
        label="關鍵字對象："
        defaultValue={materialViewKeyword[0] as SelectValue}
        registerName="materialViewKeyword"
        options={materialViewKeyword ?? []}
      />
      <SearchInputField
        registerName="searchInput"
        className={styles.searchInput}
        placeholder="輸入關鍵字"
        clearable
      />
    </FormFieldsWrapper>
  );
}
