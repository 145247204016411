import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  ModalActions,
  SelectValue,
  Message,
} from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  FormFieldsDebug,
  FormFieldsWrapper,
  InputField,
  TextAreaField,
} from '@mezzanine-ui/react-hook-form';
import {
  PageInfo,
  WaitReceiptObj,
  statusTag,
  useMaterialsInInventory,
} from '@solar/data';
import {
  InputContainerWithUnit,
  NumericInputField,
  RowSection,
  useTargetModal,
} from '@solar/templates';
// import { message } from 'antd';
import Decimal from 'decimal.js';
import { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { KeyedMutator } from 'swr';
import { ModalLayout } from '../../../Material/ModalLayout/ModalLayout';
import { inspectModalFormValidator } from '../const';
import { Modals, TabsEnum } from '../types';
import {
  exceptionStatusConvert,
  updateWaitReceipt,
  updateWaitReceiptStatusTag,
} from '../utils';

type FormProps = {
  materialId: SelectValue | null;
  materialDescription: string;
  materialInputUnitQuantity: string;
  materialWeightUnitQuantity: string;
  remark: string;
  statusTag: { id: keyof typeof statusTag; name: string };
  waiverNumber: string | null;
};

const sysErrorMsg = '系統錯誤';

export const InspectModal = ({
  mutate,
  resetFilter,
}: {
  resetFilter: () => void;
  mutate: KeyedMutator<{
    records: WaitReceiptObj[];
    pageInfo: PageInfo;
  }>;
}) => {
  const inspectModalController = useTargetModal<WaitReceiptObj>(Modals.Inspect);
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') as `${TabsEnum}`;

  // const [Message, contextHolder] = message.useMessage();

  const modalData = inspectModalController.data;

  const { data: materials, setParams: mutateMaterialsParams } =
    useMaterialsInInventory();

  // const { data } = useUnitConversionRatiosByMaterialId({
  //   materialId: modalData?.materialId,
  // });
  // const { stockOverInputUnitRatio } = data?.[0] ?? {};

  const methods = useForm<FormProps>({
    resolver: yupResolver(
      inspectModalFormValidator({
        isException: activeTab === TabsEnum.Exception,
        isStorageInAction: true,
      })
    ),
  });

  const watchedMaterialId = useWatch({
    control: methods.control,
    name: 'materialId',
  });
  const watchedMaterialDescription = useWatch({
    control: methods.control,
    name: 'materialDescription',
  });

  useEffect(() => {
    if (inspectModalController.open && modalData) {
      methods.setValue(
        'materialId',
        activeTab === TabsEnum.Exception
          ? null
          : {
              id: modalData.materialId,
              name: modalData.materialId,
            }
      );
      methods.setValue('materialDescription', modalData.materialDescription);
      methods.setValue(
        'materialInputUnitQuantity',
        modalData.materialInputUnitQuantity
      );
      methods.setValue(
        'materialWeightUnitQuantity',
        modalData?.materialWeightUnitQuantity
      );
      methods.setValue('remark', modalData?.waitReceiptItemRemark ?? '');
      methods.setValue('statusTag', {
        id: modalData?.statusTag,
        name: statusTag[modalData?.statusTag],
      });
      methods.setValue('waiverNumber', modalData?.waiverNumber ?? '');
    }
  }, [inspectModalController.open]);

  const [tempSaving, setTempSaving] = useState(false);

  const onTempSave = useCallback(async () => {
    if (!modalData?.id) {
      Message.error('無資料來源');
      return;
    }

    try {
      setTempSaving(true);
      const formValue = methods.getValues();

      const res = await updateWaitReceiptStatusTag({
        id: modalData?.id,
        payload: {
          materialStockUnitQuantity: new Decimal(
            formValue.materialInputUnitQuantity
          )
            .mul(modalData.stockOverInputUnitRatio)
            .toString(),
          materialWeightUnitQuantity: formValue.materialWeightUnitQuantity,
          remark: formValue.remark ?? '',
          statusTag: formValue.statusTag?.id!,
          waiverNumber: formValue.waiverNumber ?? '',
        },
      });

      if (res.ok) {
        methods.reset();
        inspectModalController.closeModal();
        Message.success('成功暫存');
        await mutate(undefined, true);
      }
    } catch (err) {
      console.log(err);
      Message.error('系統錯誤');
    } finally {
      setTempSaving(false);
    }
  }, [modalData, mutate]);

  const [abnormaling, setAbnormaling] = useState(false);

  const onAbnormal = useCallback(async () => {
    if (!modalData?.id) {
      Message.error('無資料來源');
      return;
    }

    try {
      setAbnormaling(true);
      const res = await exceptionStatusConvert({
        id: modalData?.id,
        updateStatus: 'ABNORMAL',
      });

      if (res.ok) {
        Message.success('成功轉為異常待檢');
        inspectModalController.closeModal();
        await mutate(undefined, true);
      }
    } catch (err) {
      Message.error('系統錯誤');
    } finally {
      setAbnormaling(false);
    }
  }, [modalData, mutate]);

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = useCallback(
    async (formValue: FormProps) => {
      if (!modalData?.id) {
        Message.error('無資料來源');
        return;
      }

      try {
        setSubmitting(true);

        let localRemark = formValue.remark;
        if (activeTab === TabsEnum.Exception) {
          localRemark += formValue.statusTag.name ?? '';
          localRemark += formValue.waiverNumber ?? '';
          methods.setValue('remark', localRemark);
        }

        const res = await updateWaitReceipt({
          payload: {
            id: Number(modalData?.id),
            materialStockUnitQuantity: new Decimal(
              formValue.materialInputUnitQuantity
            )
              .mul(modalData.stockOverInputUnitRatio)
              .toString(),
            newMaterialId: formValue.materialId?.id ?? modalData.materialId,
            materialWeightUnitQuantity: formValue.materialWeightUnitQuantity,
            remark: localRemark,
          },
        });

        if (res.ok) {
          resetFilter();
          Message.success('入庫成功');
          inspectModalController.closeModal();
          await mutate(undefined, true);
        }
      } catch (err: any) {
        console.log(err);
        if (err instanceof Error) {
          switch (JSON.parse(err.message)?.message) {
            case 'Duplicate transaction detected':
              Message.error('入庫執行序列過多，請 5 秒後再試一次');
              break;
            default:
              Message.error('系統錯誤');
              break;
          }
        } else {
          Message.error('系統錯誤');
        }
      } finally {
        setSubmitting(false);
      }
    },
    [mutate, modalData]
  );

  return (
    <>
      {/* {contextHolder} */}
      <ModalLayout
        style={{ width: '600px' }}
        modalHeader={<br />}
        loading={methods.formState.isSubmitting}
        modalFooter={
          <div
            // {...(activeTab === TabsEnum.StorageIn && {
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            // })}
          >
            {activeTab === TabsEnum.Exception && (
              <Button
                style={{ marginLeft: '16px' }}
                type="button"
                variant="contained"
                loading={tempSaving}
                onClick={onTempSave}
              >
                暫存
              </Button>
            )}
            {activeTab === TabsEnum.StorageIn && (
              <Button
                style={{ marginLeft: '16px' }}
                type="button"
                variant="contained"
                loading={abnormaling}
                onClick={onAbnormal}
              >
                異常待檢
              </Button>
            )}
            <ModalActions
              confirmText="確認"
              cancelText="取消"
              confirmButtonProps={{
                form: 'inspectForm',
                loading: submitting,
              }}
              onCancel={() => inspectModalController.closeModal()}
            />
          </div>
        }
        {...inspectModalController}
      >
        <FormFieldsWrapper
          id="inspectForm"
          methods={methods}
          onSubmit={onSubmit}
        >
          <RowSection label="料號：">
            {activeTab === TabsEnum.Exception && (
              <AutoCompleteField
                registerName="materialId"
                options={(materials ?? [])?.map((material) => ({
                  id: material.materialId,
                  name: material.materialId,
                }))}
                onChange={(obj) => {
                  const targetMaterial = materials?.find(
                    (row) => row?.materialId === obj?.id
                  );

                  const materialId = targetMaterial?.materialId;

                  if (materialId) {
                    methods.setValue(
                      'materialDescription',
                      targetMaterial.materialDescription
                    );
                  }
                }}
                onSearch={(searchTerm) => mutateMaterialsParams({ searchTerm })}
              />
            )}
            {activeTab === TabsEnum.StorageIn && <>{watchedMaterialId?.id}</>}
          </RowSection>
          <RowSection label="品名：">
            {activeTab === 'storage-in' && modalData?.materialDescription}
            {activeTab === 'exception' && watchedMaterialDescription}
          </RowSection>
          <RowSection label="批號：">
            {modalData?.materialSapBatchId ?? ''}-
            {modalData?.materialSubBatchId ?? ''}
          </RowSection>
          <RowSection label="管理單位數量：">
            <InputContainerWithUnit
              suffixUnit={modalData?.materialInputUnit ?? ''}
            >
              <NumericInputField
                registerName="materialInputUnitQuantity"
                clearable
                validateMode="float"
              />
            </InputContainerWithUnit>
          </RowSection>
          <RowSection label="實際重量：" required>
            <InputContainerWithUnit suffixUnit="G">
              <NumericInputField
                registerName="materialWeightUnitQuantity"
                validateMode="float"
              />
            </InputContainerWithUnit>
          </RowSection>
          {activeTab === TabsEnum.Exception && (
            <RowSection label="狀態：" required>
              <AutoCompleteField
                registerName="statusTag"
                options={Object.keys(statusTag).map((statusKey) => ({
                  id: statusKey,
                  name: statusTag[statusKey as keyof typeof statusTag],
                }))}
              />
            </RowSection>
          )}
          {activeTab === TabsEnum.Exception && (
            <RowSection label="特採單號：">
              <InputField registerName="waiverNumber" />
            </RowSection>
          )}
          <RowSection childFullWidth label="備註：">
            <TextAreaField registerName="remark" />
          </RowSection>
        </FormFieldsWrapper>
      </ModalLayout>
    </>
  );
};
