import { Typography } from '@mezzanine-ui/react';
import styles from './../../TargetNumber.module.scss';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';
import {
  CheckboxField,
  FormFieldsWrapper,
  InputField,
  RadioGroupField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  RuleOption,
  TargetNumberRuleForViewOnly,
  TargetRuleCreateFormFields,
} from '../../TargetNumber.interface';
import { useCallback, useEffect, useState } from 'react';
import { useGetInternalTargetNumberRuleByCustomerId } from '@solar/data';

interface TargetRuleCreateTableProps {
  ruleTypeOptions: RuleOption[] | null;
  customerIds: string[];
  resetCriterion: string;
  isViewOnly?: boolean;
  targetNumberRule: TargetNumberRuleForViewOnly;
  isUsed?: boolean;
}

interface CustomerInternalNumberRule {
  number_rule_id: number;
  number_rule_name: string;
}

interface DisabledFields {
  [key: string]: boolean;
}

export function TargetRuleCreateTable({
  ruleTypeOptions,
  customerIds,
  resetCriterion,
  isViewOnly = false,
  targetNumberRule,
  isUsed,
}: TargetRuleCreateTableProps) {
  const { watch } = useFormContext();
  const methods = useFormContext<TargetRuleCreateFormFields>();
  const [checkedItems, setCheckedItems] = useState<boolean[]>([]);
  const [selectedResetCriterion, setSelectedResetCriterion] = useState<
    string | null
  >(null);
  const [disabledFields, setDisabledFields] = useState<DisabledFields>({});

  const { internalTargetNumberRule, isLoading, error } =
    useGetInternalTargetNumberRuleByCustomerId(customerIds.join(','));

  const codeRulesWatched = watch('codeRules');

  useEffect(() => {
    const formValues = methods.getValues();
    const codeRules = formValues.codeRules || [];

    const newCheckedItems = codeRules.map((rule) => rule.is_enable);

    setCheckedItems(newCheckedItems);
  }, [methods, ruleTypeOptions]);

  const handleCheckboxChange = (index: number) => {
    setCheckedItems((prevState) => {
      const newCheckedItems = [...prevState];
      newCheckedItems[index] = !newCheckedItems[index];
      return newCheckedItems;
    });
  };

  const handleParameterCheckboxChange = useCallback(
    (codeParameterId: number, isChecked: boolean) => {
      setDisabledFields((prevState) => {
        const newState: DisabledFields = { ...prevState };

        ruleTypeOptions?.forEach((rule) => {
          rule.parameters.forEach((param) => {
            // if (param.code_parameter_id === codeParameterId) {
            //   newState[param.code_parameter_id] = isChecked;
            // }

            if (param.ref_parameter_id === codeParameterId) {
              newState[param.code_parameter_id] = isChecked;

              if (isChecked && param.data_type === 'text') {
                const ruleIndex = ruleTypeOptions.findIndex(
                  (r) => r.code_rule_id === rule.code_rule_id
                );
                const paramIndex = rule.parameters.findIndex(
                  (p) => p.code_parameter_id === param.code_parameter_id
                );

                const fieldName = `codeRules[${ruleIndex}].parameters[${paramIndex}].value`;
                methods.setValue(
                  fieldName as keyof TargetRuleCreateFormFields,
                  '-1'
                );
              }
            }
          });
        });

        if (codeParameterId === 40 && isChecked) {
          newState[39] = true;
        }

        if (codeParameterId === 45 && !isChecked) {
          newState[38] = false;
          newState[37] = false;
        }

        const booleanNewState = Object.keys(newState).reduce((acc, key) => {
          acc[key] = Boolean(newState[key]);
          return acc;
        }, {} as DisabledFields);

        return booleanNewState;
      });
    },
    [ruleTypeOptions, methods]
  );

  useEffect(() => {
    if (!ruleTypeOptions) return;

    const checkboxFieldNames = ruleTypeOptions.flatMap((rule, ruleIndex) =>
      rule.parameters
        .filter((param) => param.data_type === 'checkbox')
        .map(
          (param, paramIndex) =>
            `codeRules[${ruleIndex}].parameters[${paramIndex}].value`
        )
    );

    const watchedCheckboxValues = watch(checkboxFieldNames);

    checkboxFieldNames.forEach((fieldName, index) => {
      const matchResult = fieldName.match(/\d+/g);
      if (!matchResult) return;

      const [ruleIndex, paramIndex] = matchResult.map(Number);
      const isChecked = watchedCheckboxValues[index];
      const paramId =
        ruleTypeOptions[ruleIndex]?.parameters[paramIndex]?.code_parameter_id;

      if (paramId !== undefined) {
        handleParameterCheckboxChange(paramId, isChecked);
      }
    });
  }, [watch, ruleTypeOptions, handleParameterCheckboxChange]);

  useEffect(() => {
    if (!codeRulesWatched || !Array.isArray(codeRulesWatched)) return;
    codeRulesWatched.forEach((rule, ruleIndex) => {
      if (!rule.parameters || !Array.isArray(rule.parameters)) return;
      rule.parameters.forEach((param: any, paramIndex: number) => {
        if (
          !param ||
          typeof param.isChecked === 'undefined' ||
          typeof param.id === 'undefined'
        )
          return;
        const isChecked = param.isChecked;
        const codeParameterId = param.id;
        handleParameterCheckboxChange(codeParameterId, isChecked);
      });
    });
  }, [codeRulesWatched, handleParameterCheckboxChange]);

  if (!ruleTypeOptions) {
    return null;
  }

  const shouldRenderRadioGroup =
    Array.isArray(ruleTypeOptions) &&
    ruleTypeOptions.some(
      (item, index) => checkedItems[index] && item.is_reset_criterion
    );

  const radioGroupOptions = Array.isArray(ruleTypeOptions)
    ? [
        { value: 'nope', label: '不重設' },
        ...ruleTypeOptions
          .filter(
            (item, index) => checkedItems[index] && item.is_reset_criterion
          )
          .map((item) => ({
            value: `${item.code_rule_id}`,
            label: `${item.code_rule_name}`,
          })),
      ]
    : [];

  return isViewOnly || isUsed ? (
    <div className={styles.createTable}>
      <Typography variant="h3">已套用編碼規則設定</Typography>
      {targetNumberRule &&
        (() => {
          const activeCodeRules = targetNumberRule.code_rules.filter(
            (codeRule) => !codeRule.is_disabled
          );

          const resetCriterionRule = activeCodeRules.find(
            (codeRule) => codeRule.is_reset_criterion
          );

          const resetCriterionRuleOption = ruleTypeOptions?.find(
            (option) => option.code_rule_id === resetCriterionRule?.code_rule_id
          );

          const descriptionsAndParameters = _.sortBy(
            activeCodeRules,
            'seq'
          ).map((codeRule) => {
            const ruleOption = ruleTypeOptions.find(
              (ruleOption) => ruleOption.code_rule_id === codeRule.code_rule_id
            );
            const description = ruleOption
              ? `[碼序: ${codeRule.seq}] ${ruleOption.code_rule_name}${ruleOption.deleted_at ? ' - [此規則大類已被停用]' : ''}`
              : '';
              const parameters = codeRule.parameters
              ?.filter((param) =>
                typeof param.value === 'boolean' ? param.value !== false : true
              )
              .filter((param) => param.value !== '-1')
              .map((param) => {
                const paramOption = ruleOption?.parameters.find(
                  (p) => p.code_parameter_id === param.code_parameter_id
                );
                const paramName = paramOption
                  ? paramOption.code_parameter_name
                  : 'Unknown Parameter';
                let paramValue: string | number | boolean = param.value;

                if (
                  (typeof paramValue === 'boolean' && paramValue === true) ||
                  paramValue === 'true'
                ) {
                  paramValue = '是';
                }

                if (paramName === '年碼規則' || paramName === '月碼規則') {
                  paramValue += '碼';
                }
                return `${paramName}: ${paramValue}`;
              })
              .join(', ');
            return { description, parameters };
          });

          return (
            <>
              {descriptionsAndParameters.map(
                ({ description, parameters }, index) => (
                  <div key={index}>
                    <Typography variant="h4">{description}</Typography>
                    <Typography variant="body1">{parameters}</Typography>
                  </div>
                )
              )}
              {resetCriterionRuleOption && (
                <Typography variant="h4">
                  重設條件
                  <Typography variant="body1">
                    {resetCriterionRuleOption.code_rule_name}
                  </Typography>
                </Typography>
              )}
            </>
          );
        })()}
    </div>
  ) : (
    <FormFieldsWrapper methods={methods} className={styles.createTable}>
      <Typography variant="h3">編碼規則設定</Typography>
      {ruleTypeOptions.map((item, index) => (
        <div key={index} className={styles.space}>
          <CheckboxField
            registerName={`codeRules[${index}].is_enable`}
            checked={checkedItems[index]}
            onChange={() => handleCheckboxChange(index)}
          />
          <Typography variant="h4">{item.code_rule_name} </Typography>
          <input
            type="hidden"
            {...methods.register(
              `codeRules[${index}].code_rule_id` as `codeRules.${number}.code_rule_id`
            )}
            value={item.code_rule_id}
          />
          {checkedItems[index] && (
            <>
              <InputField
                label="碼序"
                width={100}
                registerName={`codeRules[${index}].seq`}
                type="number"
                placeholder="碼序"
                required
                disabled={isViewOnly}
              />
              <input
                type="hidden"
                {...methods.register(
                  `codeRules[${index}].code_rule_id` as `codeRules.${number}.code_rule_id`
                )}
                value={item.code_rule_id}
              />
              {item.parameters
                .sort((a, b) => a.seq - b.seq)
                .map((param, paramIndex) => {
                  const isDisabled = disabledFields[param.code_parameter_id];
                  return (
                    <>
                      <input
                        type="hidden"
                        {...methods.register(
                          `codeRules[${index}].parameters[${paramIndex}].code_parameter_id` as `codeRules.${number}.parameters.${number}.code_parameter_id`
                        )}
                        value={param.code_parameter_id}
                      />
                      {(() => {
                        switch (param.data_type) {
                          case 'number':
                          case 'text':
                            return (
                              <InputField
                                key={paramIndex}
                                width={400}
                                registerName={`codeRules[${index}].parameters[${paramIndex}].value`}
                                label={`${param.code_parameter_name} - ${param.description}`}
                                type={
                                  param.data_type === 'number'
                                    ? 'number'
                                    : 'text'
                                }
                                required={true}
                                disabled={isDisabled || isViewOnly}
                              />
                            );
                          case 'options':
                            return (
                              <SelectField
                                key={paramIndex}
                                registerName={`codeRules[${index}].parameters[${paramIndex}].value`}
                                label={`${param.code_parameter_name} - (${param.description})`}
                                width={320}
                                required={true}
                                disabled={isViewOnly}
                                options={param.options.map((option) => ({
                                  id: option.option_value ?? 'defaultId',
                                  name: option.option_name ?? 'defaultName',
                                }))}
                              />
                            );
                          case 'checkbox':
                            return (
                              <CheckboxField
                                key={paramIndex}
                                registerName={`codeRules[${index}].parameters[${paramIndex}].value`}
                                label={`${param.code_parameter_name} - (${param.description})`}
                                disabled={isViewOnly}
                                onChange={(e) =>
                                  handleParameterCheckboxChange(
                                    param.code_parameter_id,
                                    e.target.checked
                                  )
                                }
                                width={250}
                              />
                            );
                          case 'other_options':
                            return (
                              <SelectField
                                key={paramIndex}
                                width={250}
                                registerName={`codeRules[${index}].parameters[${param.code_parameter_id}].value`}
                                options={internalTargetNumberRule?.map(
                                  (rule: CustomerInternalNumberRule) => ({
                                    id: rule.number_rule_id,
                                    name: rule.number_rule_name,
                                  })
                                )}
                              />
                            );
                          default:
                            return null;
                        }
                      })()}
                    </>
                  );
                })}
            </>
          )}
        </div>
      ))}
      {shouldRenderRadioGroup && (
        <>
          <Typography variant="h4">重設條件</Typography>
          <RadioGroupField
            registerName="resetCriterion"
            options={radioGroupOptions}
            defaultValue={resetCriterion}
            onChange={(e) => setSelectedResetCriterion(e.target.value)}
            required={true}
            disabled={isViewOnly}
          />
        </>
      )}
    </FormFieldsWrapper>
  );
}
