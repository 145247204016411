import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { ModalType } from './useTenantList';

export function useRoleTenant() {
  const navigate = useNavigate();

  const methods = useForm();
  const filterMethods = useForm();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [openModal, setOpenModal] = useState<ModalType>(null);
  const [modalSource, setModalSource] = useState<any | null>(null);
  const [modalTitle, setModalTitle] = useState('');

  const onChangeCurrentPage = useCallback(
    (page: number) => setCurrentPage(page),
    [setCurrentPage]
  );

  const onChangePageSize = useCallback(
    (perPage: number) => setPageSize(perPage),
    [setPageSize]
  );

  const onCloseModal = useCallback(() => {
    setOpenModal(() => null);
    setModalSource(() => null);
  }, [setOpenModal, setModalSource]);

  const onOpenWarningModal = useCallback(
    (source: any) => {
      setOpenModal('WarningModal');
      setModalSource(source);
    },
    [setOpenModal, setModalSource]
  );

  const onOpenCreateEditModal = useCallback(
    (source: any, title: string) => {
      setOpenModal('CreateEditModal');
      setModalSource(source);
      setModalTitle(title);
    },
    [setOpenModal, setModalSource, setModalTitle]
  );
  const onNavigate = useCallback(() => navigate('/auth/role'), [navigate]);

  return {
    methods,
    filterMethods,
    currentPage,
    onChangeCurrentPage,
    pageSize,
    onChangePageSize,
    openModal,
    modalSource,
    modalTitle,
    onOpenWarningModal,
    onCloseModal,
    onOpenCreateEditModal,
    onNavigate,
  };
}
