import { TableColumn } from '@mezzanine-ui/core/table';
import { useMemo } from 'react';
import { FormTemplateResponse } from './typing';
import { ProductionFormCreator } from '../ProductionFormCreator/ProductionFormCreator';
import { FormTemplateProductionFormList } from './FormTemplateProductionFormList';

export function useFormTemplateColumns() {
  return useMemo<TableColumn<FormTemplateResponse>[]>(
    () => [
      {
        key: 'fieldName',
        title: '欄位代碼',
        dataIndex: 'fieldName',
        width: 280,
      },
      {
        key: 'name',
        title: '名稱',
        dataIndex: 'name',
      },
      {
        key: 'suffix',
        title: '單位',
        dataIndex: 'suffix',
        width: 96,
      },
      {
        key: 'versions',
        title: '當前版本',
        dataIndex: 'versions.0.version',
        width: 96,
      },
      {
        key: 'name',
        title: '建立綁定',
        width: 150,
        render: (data) => <ProductionFormCreator formTemplate={data} />,
      },
      {
        key: 'name',
        title: '已綁定項目',
        width: 150,
        render: (data) => (
          <FormTemplateProductionFormList
            templateName={data.name}
            templateSuffix={data.suffix}
            templateId={data.id}
          />
        ),
      },
    ],
    []
  );
}
