import useSWR from 'swr';
import { useDebounce } from '../hooks';
import { Area, Building, Floor, Stack, Zone } from './location.type';
import { Factory } from './types';

export type Location = {
  id:
    | Factory['id']
    | Building['id']
    | Floor['id']
    | Area['id']
    | Zone['id']
    | Stack['id'];
  level:
    | 'factory'
    | 'building'
    | 'floor'
    | 'building_floor'
    | 'area'
    | 'zone'
    | 'stack';
};

export function useLocationsBySearchTerm({
  searchText,
}: {
  searchText: string;
}) {
  const search = useDebounce<string>(searchText, 1000);
  const { data, error } = useSWR<Location[]>(
    search !== '' ? `/locations/${search}` : null
  );

  return {
    locations: data ?? [],
    isLoading: !data && !error,
  };
}
