import { Button } from "@mezzanine-ui/react";
import { Col, ConfigProvider, Descriptions, Row, Table } from "antd";

export function DispatchInfoTabPaneContent() {
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 0,
        },
      }}
    >
      <Descriptions bordered labelStyle={{ width: 150 }}>
        <Descriptions.Item label="來源">
          {''}
        </Descriptions.Item>
        <Descriptions.Item label="來源單號">
          {''}
        </Descriptions.Item>
        <Descriptions.Item label="訂單單號">
          {''}
        </Descriptions.Item>
        <Descriptions.Item label="供應商代碼">
          {''}
        </Descriptions.Item>
        <Descriptions.Item label="工作中心代碼">
          {''}
        </Descriptions.Item>
        <Descriptions.Item label="訂單交期">
          {''}
        </Descriptions.Item>
        <Descriptions.Item label="供應商名稱">
          {''}
        </Descriptions.Item>
        <Descriptions.Item label="工作中心名稱">
          {''}
        </Descriptions.Item>
        <Descriptions.Item label="建立者">
          {''}
        </Descriptions.Item>
      </Descriptions>
      <Table
        style={{ borderLeft: '1px solid #F0F0F0', borderRight: '1px solid #F0F0F0' }}
        dataSource={[]}
        columns={[
          {
            dataIndex: 'materialId',
            title: '目標料號',
          },
          {
            dataIndex: 'materialDescription',
            title: '目標品名',
          },
          {
            dataIndex: 'expectedMaterialUnitQuantity',
            title: '需求數量',
          },
          {
            dataIndex: 'mainUnit',
            title: '單位',
          },
        ]}
      />
      <Table
        style={{
          borderLeft: '1px solid #F0F0F0',
          borderRight: '1px solid #F0F0F0',
        }}
        dataSource={[]}
        columns={[
          {
            dataIndex: 'materialId',
            title: () => '需求料號',
          },
          {
            dataIndex: 'materialDescription',
            title: '需求品名',
          },
          {
            dataIndex: 'expectedMaterialUnitQuantity',
            title: '需求數量',
          },
          {
            dataIndex: 'mainUnit',
            title: '單位',
          },
        ]}
      />
      <Descriptions
        bordered
        style={{ borderTop: 'none' }}
        labelStyle={{ width: 150 }}
      >
        <Descriptions.Item label="目標批號"  span={3}>
          {''}
        </Descriptions.Item>
        <Descriptions.Item label="備註" span={3}>
          {''}
        </Descriptions.Item>
      </Descriptions>
      <Row style={{ margin: '20px 0' }}>
        <Col>
          <Button variant="outlined">建立發料</Button>
        </Col>
      </Row>
    </ConfigProvider>
  );
}