import {
  deletePendingDrawing,
  useGetPendingDrawings,
  useGetPendingDrawingsDDL,
} from '@solar/data';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
} from 'antd';
import styles from './pending-drawings-page.module.scss';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  ModalGroup,
  useModalGroupController,
} from '../../Templates/Modal/ModalGroup';
import { Modals } from './Modals';
import { CreatePendingDrawingModal } from './CreateModal';
import { EditPendingDrawingModal } from './EditModal';

export function PendingDrawingsPage() {
  const [form] = Form.useForm();
  const [tableFilters, setTableFilters] = useState<any>();
  const {
    pendingDrawings,
    fetchPendingDrawings,
    pageInfo,
    isLoading,
    mutateGetPendingDrawings,
  } = useGetPendingDrawings();
  const { data: drawingTypeOptions } = useGetPendingDrawingsDDL({
    category: 'drawing_type',
  });
  // const { data: locationOptions } = useGetPendingDrawingsDDL({ category: 'location' });
  const modalGroupController = useModalGroupController([
    { name: Modals.CREATE },
    { name: Modals.EDIT },
  ]);

  const refetchPendingDrawings = (
    { page, size }: { page?: number; size?: number },
    filters?: any
  ) => {
    const formState = form.getFieldsValue();
    fetchPendingDrawings((prevParams) => ({
      ...prevParams,
      ...formState,
      queryModifyTimeFrom: formState?.modifyTime?.[0]
        ? dayjs(formState.modifyTime[0])?.toISOString()
        : null,
      queryModifyTimeTo: formState?.modifyTime?.[1]
        ? dayjs(formState.modifyTime[1])?.toISOString()
        : null,
      page,
      size,
    }));
  };

  return (
    <div className={styles.page_content}>
      <Form form={form} style={{ rowGap: 8, columnGap: 16 }} layout="inline">
        <Form.Item name="parentDrawingCode" label="舊成品圖號">
          <Input />
        </Form.Item>
        {/* <Form.Item name="location" label="公司別">
          <Select style={{ width: 200 }} allowClear options={locationOptions} />
        </Form.Item> */}
        <Form.Item name="drawingType" label="圖號種類">
          <Select
            style={{ width: 200 }}
            allowClear
            options={drawingTypeOptions}
          />
        </Form.Item>
        <Form.Item name="modifyTime" label="更新日期">
          <DatePicker.RangePicker placeholder={['開始日期', '結束日期']} />
        </Form.Item>
        <Form.Item>
          <Button
            onClick={() => {
              refetchPendingDrawings(
                {
                  page: 1,
                  size: pageInfo.size,
                },
                tableFilters
              );
            }}
          >
            查詢
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            onClick={() => {
              modalGroupController?.openModal(Modals.CREATE, {});
            }}
          >
            新增
          </Button>
        </Form.Item>
      </Form>
      <Table
        bordered
        size="small"
        pagination={{
          current: pageInfo.page,
          pageSize: pageInfo.size,
          hideOnSinglePage: true,
          total: pageInfo.count,
        }}
        rowKey="newDrawingCode"
        loading={isLoading}
        dataSource={pendingDrawings}
        columns={[
          // {
          //   dataIndex: 'location',
          //   title: '公司別',
          // },
          {
            dataIndex: 'drawingType',
            title: '圖號種類',
          },
          {
            dataIndex: 'parentDrawingCode',
            title: '舊成品圖號',
            render: (value, record) => (
              <div style={{ display: 'flex', gap: '12px' }}>
                <span>{value}</span>
                <Link to={`/drawings/create?id=${record?.id}`}>新增</Link>
              </div>
            ),
          },
          // {
          //   dataIndex: 'skDepart',
          //   title: '部門',
          // },
          {
            dataIndex: 'modifyTime',
            title: '更新日期',
            render: (value) => moment(value).format('YYYY/MM/DD'),
          },
          {
            title: '操作',
            dataIndex: 'id',
            render: (id, record) => (
              <Row gutter={[12, 12]}>
                <Col>
                  <Button
                    onClick={() =>
                      modalGroupController?.openModal(Modals.EDIT, { id })
                    }
                  >
                    更新
                  </Button>
                </Col>
                <Col>
                  <Button
                    danger
                    disabled={!record?.parentDrawingCode}
                    onClick={() => {
                      Modal.confirm({
                        title: '確認刪除',
                        content: `舊成品圖號: ${record?.parentDrawingCode}`,
                        cancelText: '取消',
                        okText: '確認刪除',
                        okButtonProps: { danger: true },
                        onOk: async () => {
                          await deletePendingDrawing({
                            parentDrawingCodes: [record?.parentDrawingCode],
                          });
                          await mutateGetPendingDrawings();
                        },
                      });
                    }}
                  >
                    刪除
                  </Button>
                </Col>
              </Row>
            ),
          },
        ]}
        onChange={(pagination, filters) => {
          setTableFilters(filters);
          refetchPendingDrawings(
            {
              page: pagination.current,
              size: pagination.pageSize,
            },
            filters
          );
        }}
      />
      <ModalGroup {...modalGroupController}>
        <CreatePendingDrawingModal
          mutateGetPendingDrawings={mutateGetPendingDrawings}
        />
        <EditPendingDrawingModal
          mutateGetPendingDrawings={mutateGetPendingDrawings}
        />
      </ModalGroup>
    </div>
  );
}
