import { PageLayout } from '@solar/templates';

export function CPIIframe() {
  return (
    <PageLayout title="CPI報表">
      <iframe
        title="cpi"
        style={{ width: '100%' }}
        height="800"
        src="https://app.powerbi.com/reportEmbed?reportId=70911988-e27b-4b34-8ceb-6d0b5c5a0260&autoAuth=true&ctid=e3553a8e-c723-4b54-ac77-0bb3db8cf161"
        allowFullScreen
      />
    </PageLayout>
  );
}
