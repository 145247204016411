import { useFormContext, useWatch } from 'react-hook-form';
import { FormValues } from '../hooks/typing';
import { Card, Col, Row } from 'antd';
import { Button, Checkbox, Message } from '@mezzanine-ui/react';
import {
  Form,
  NewAnswer,
  ProductionFormResponse,
  saveProductionFormAnswers,
} from '@solar/data';
import { useState } from 'react';

export function FinalAnswersCard({
  forms,
  hideSaveButton = false,
  resetForm,
  bindManualAnswers = {},
}: {
  forms: ProductionFormResponse[];
  hideSaveButton?: boolean;
  resetForm: () => void;
  bindManualAnswers?: Record<
    string,
    {
      form: ProductionFormResponse;
      formId: string;
      answer: string | null;
      picked: boolean | 'true' | 'false';
      modifiedAnswer: string | null;
    }
  >;
}) {
  const [saving, setSaving] = useState(false);
  const methods = useFormContext<FormValues>();

  const watchedAnswers = useWatch({
    control: methods.control,
    name: 'answers',
  });

  const onSave = async () => {
    try {
      setSaving(true);
      const formState = methods.getValues();
      const [materialSapBatchId, materialSubBatchId] =
        formState?.batchId?.id?.split('-') ?? [];

      const upsertAnswers =
        Object.entries(formState?.answers)?.map(([answerId, item]) => ({
          productionAnswerId: answerId,
          modifiedAnswer: item.answer,
          picked: item.picked === 'true' || item.picked === true,
        })) ?? [];

      const newAnswers = Object.entries(formState?.modifiedByForm)?.reduce<
        NewAnswer[]
      >((result, [formId, item]) => {
        if (item?.answerId) {
          upsertAnswers.push({
            productionAnswerId: item.answerId,
            modifiedAnswer: item.modifiedAnswer,
            picked: item.picked === 'true' || item.picked === true,
          });
        } else {
          if (item?.picked) {
            result.push({
              productionFormId: formId,
              answer: item?.modifiedAnswer,
              picked: item.picked === 'true' || item.picked === true,
            });
          }
        }
        return result;
      }, []);

      await saveProductionFormAnswers({
        materialId: formState?.materialId?.id ?? '',
        materialSapBatchId,
        materialSubBatchId,
        upsertAnswers,
        newAnswers,
      });

      resetForm();

      Message.success('儲存成功');
    } catch (error) {
      console.error(error);
      Message.error('儲存失敗');
    } finally {
      setSaving(false);
    }
  };

  return (
    <Card
      title="最終檢驗結果"
      extra={
        hideSaveButton ? undefined : (
          <Button variant="contained" loading={saving} onClick={onSave}>
            儲存
          </Button>
        )
      }
    >
      <Row>
        <Col flex="50px" />
        <Col flex="auto">檢測項目</Col>
        <Col flex="120px">項目結果</Col>
      </Row>
      {Object.entries(Object.assign(watchedAnswers, bindManualAnswers))
        ?.filter(([answerId, item]) => item?.picked)
        ?.map(([answerId, item]) => (
          <Row key={answerId}>
            <Col flex="50px">
              <Checkbox disabled checked={true} />
            </Col>
            <Col flex="auto">
              <span>{item?.form?.title ?? ''}</span>
            </Col>
            <Col flex="120px">
              <span>
                {((modifiedAnswer) =>
                  forms?.some((form) => form?.id === item?.form?.id)
                    ? modifiedAnswer
                    : item?.answer ?? '')(
                  methods.watch(
                    `modifiedByForm.${item?.form?.id}.modifiedAnswer`
                  )
                )}
              </span>
              <span>{item?.form?.suffix ?? ''}</span>
            </Col>
          </Row>
        ))}
    </Card>
  );
}
