import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Message, Typography } from '@mezzanine-ui/react';
import {
  BaseField,
  FormFieldsWrapper,
  PasswordField,
} from '@mezzanine-ui/react-hook-form';
import { API_NAMESPACE, JWTPayload, request } from '@solar/data';
import { Space } from 'antd';
import jwtDecode from 'jwt-decode';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

interface ResetPasswordFormValues {
  account: string;
  originalPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

enum PasswordRules {
  at_least_12_chars = '需至少12個字元',
  at_least_one_uppercase_letter = '需包含至少一個大寫英文字母',
  at_least_one_lowercase_letter = '需包含至少一個小寫英文字母',
  at_least_one_number = '需至少包含一個數字（0-9）',
  at_least_one_special_symbol = `需至少包含一個特殊符號（!@#$%^&*()_+-=[]{};':"\\|,.<>/?）`,
}

const schema = yup.object().shape({
  originalPassword: yup.string().required('必填'),
  newPassword: yup
    .string()
    .required('必填')
    .min(12, PasswordRules.at_least_12_chars)
    .matches(/[A-Z]/, PasswordRules.at_least_one_uppercase_letter)
    .matches(/[a-z]/, PasswordRules.at_least_one_lowercase_letter)
    .matches(/\d/, PasswordRules.at_least_one_number)
    .matches(
      /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/,
      PasswordRules.at_least_one_special_symbol
    ),
  confirmNewPassword: yup
    .string()
    .required('必填')
    .oneOf([yup.ref('newPassword')], '密碼不一致'),
});

export function ResetPasswordPage() {
  const navigate = useNavigate();
  const methods = useForm<ResetPasswordFormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const [tokenForResetPassword, setTokenForResetPassword] = useState<string>();
  const [isResetting, setIsResetting] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('accessToken')) {
      Message.error('請先登入');
      navigate('/login');
    } else {
      const token = localStorage.getItem('accessToken');
      setTokenForResetPassword(token ?? undefined);
    }
  }, [navigate]);

  const resetPassword = methods.handleSubmit(async (formValues) => {
    try {
      if (!tokenForResetPassword) {
        Message.error('請先登入');
        navigate('/login');
        return;
      }
      setIsResetting(true);

      const response = await request('/users/me/reset_password', {
        method: 'PUT',
        namespace: API_NAMESPACE.IAM,
        auth: false,
        headers: {
          Authorization: `Bearer ${tokenForResetPassword}`,
        },
        body: JSON.stringify({
          new_password: formValues.newPassword,
          original_password: formValues.originalPassword,
        }),
      });

      if (response.status === 'success') {
        Message.success('重設密碼成功，請以新密碼重新登入');
        navigate('/login');
      }
    } catch (error) {
      if (error instanceof Error) {
        const errMessage: any = JSON.parse(error?.message ?? '') ?? '';

        switch (errMessage?.status) {
          case 400:
            Message.error('帳號或密碼不正確');
            break;
          case 401:
            Message.error('權限不足');
            break;
          default:
            // Message.error(defaultResponse);
            break;
        }
      } else {
        // Message.error(defaultResponse);
      }
    } finally {
      setIsResetting(false);
    }
  });

  const newPasswordErrorMessage = methods.formState.errors.newPassword?.message;

  const formState = useFormState({
    control: methods.control,
  });

  console.log('formState', formState);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <FormFieldsWrapper methods={methods} style={{ width: '500px' }}>
        <Typography variant="h4" align="center">
          密碼重設
        </Typography>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <BaseField label="帳號" name="account">
            <Typography variant="h5">
              {tokenForResetPassword
                ? jwtDecode<JWTPayload>(tokenForResetPassword)?.Account ?? ''
                : ''}
            </Typography>
          </BaseField>
          <PasswordField label="原密碼" registerName="originalPassword" />
          <PasswordField label="新密碼" registerName="newPassword" />
          <PasswordField label="新密碼確認" registerName="confirmNewPassword" />
          <BaseField label="密碼規則" name="rules">
            <Typography
              variant="h5"
              color={
                newPasswordErrorMessage === PasswordRules.at_least_12_chars
                  ? 'error'
                  : 'secondary'
              }
            >
              {PasswordRules.at_least_12_chars}
            </Typography>
            <Typography
              variant="h5"
              color={
                newPasswordErrorMessage ===
                PasswordRules.at_least_one_uppercase_letter
                  ? 'error'
                  : 'secondary'
              }
            >
              {PasswordRules.at_least_one_uppercase_letter}
            </Typography>
            <Typography
              variant="h5"
              color={
                newPasswordErrorMessage ===
                PasswordRules.at_least_one_lowercase_letter
                  ? 'error'
                  : 'secondary'
              }
            >
              {PasswordRules.at_least_one_lowercase_letter}
            </Typography>
            <Typography
              variant="h5"
              color={
                newPasswordErrorMessage === PasswordRules.at_least_one_number
                  ? 'error'
                  : 'secondary'
              }
            >
              {PasswordRules.at_least_one_number}
            </Typography>
            <Typography
              variant="h5"
              color={
                newPasswordErrorMessage ===
                PasswordRules.at_least_one_special_symbol
                  ? 'error'
                  : 'secondary'
              }
            >
              {PasswordRules.at_least_one_special_symbol}
            </Typography>
          </BaseField>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Button
              type="button"
              variant="contained"
              disabled={!isEmpty(formState.errors)}
              loading={isResetting}
              onClick={resetPassword}
            >
              確認修改
            </Button>
          </div>
        </Space>
      </FormFieldsWrapper>
    </div>
  );
}
