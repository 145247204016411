import { getRoleGroup } from './shared';

const FIRST_SPEC_GROUP = {
  BA: {
    name: '烘乾作業',
  },
  BB: {
    name: '熱處理作業',
  },
  BC: {
    name: '退火作業',
  },
  CG: {
    name: '矯直',
  },
  CH: {
    name: '銲合作業',
  },
  DA: {
    name: '鋸切作業',
  },
  DB: {
    name: '線割作業',
  },
  DD: {
    name: '圓鋸',
  },
  DG: {
    name: '粗車作業',
  },
  DH: {
    name: '精車作業',
  },
  DL: {
    name: '放電加工作業',
  },
  DP: {
    name: '外圓粗研磨',
  },
  DQ: {
    name: '外圓精研磨',
  },
  DR: {
    name: '內圓精研磨',
  },
  DJ: {
    name: '平面拋光作業',
  },
  DI: {
    name: '粗銑作業',
  },
  DK: {
    name: '精銑作業',
  },
  DM: {
    name: '粗磨作業',
  },
  DN: {
    name: '精磨作業',
  },
  EA: {
    name: '真空乾燥',
  },
  EB: {
    name: '噴砂作業',
  },
  EC: {
    name: '打印作業',
  },
  ED: {
    name: '粗洗作業',
  },
  EM: {
    name: '酸洗作業',
  },
  EE: {
    name: '清洗作業',
  },
  EL: {
    name: 'UCSM雷射噴砂',
  },
  EJ: {
    name: 'UT探傷',
  },
  EK: {
    name: '手動拋磨作業',
  },
  X0: {
    name: '不良品待判定',
  },
  X1: {
    name: '不良品待加工',
  },
  X2: {
    name: '不良品待重熔',
  },
  X3: {
    name: '不良品待回收',
  },
  CF: {
    name: '整平',
  },
};

const BACK_PLATE_SMALL_GROUP = {
  1: {
    name: '未分類',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
};

const BACK_PLATE_MEDIUM_GROUP_FLAT = {
  C: {
    name: '圓餅',
  },
  R: {
    name: '矩形',
  },
};

const BACK_PLATE_MEDIUM_GROUP_CIRCULAR = {
  1: {
    name: '未分類',
    nextLevel: BACK_PLATE_SMALL_GROUP,
  },
};

export const BACK_PLATE_LARGE_GROUP = {
  F: {
    name: '平面',
    nextLevel: BACK_PLATE_MEDIUM_GROUP_FLAT,
  },
  R: {
    name: '旋轉',
    nextLevel: BACK_PLATE_MEDIUM_GROUP_CIRCULAR,
  },
};
