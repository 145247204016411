import { useState } from 'react';
import useSWR from 'swr';
import { API_NAMESPACE, request } from '../request';
import moment from 'moment';
import { MaterialMappingParams } from './old-materials-mapping.type';

const namespace = API_NAMESPACE.MATERIALS;

export function useMaterialMapping() {
  const [params, setParams] = useState<MaterialMappingParams>();
  const { data, error } = useSWR([
    '/materials/mapping-old-materials',
    { params, namespace },
  ]);

  return {
    materialMapping: data?.materials || [],
    pageInfo: data?.pageInfo,
    isLoading: !data && !error,
    mutateGetMaterialMapping: setParams,
  };
}

export async function downloadMaterialExcel(params: any) {
  const result = await request('/materials/mapping-old-materials-export-csv', {
    method: 'post',
    params,
    namespace,
    responseParser: (res) => res.text(),
  });
  if (result) {
    const blob = new Blob([result], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `_${moment().format('YYYYMMDD')}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
