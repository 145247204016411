import { SelectValue } from '@mezzanine-ui/react';
import {
  MaterialRequestOrderSourceType,
  MaterialRequestOrderSpec,
  OutsourcePurchaseShippedState,
} from '@solar/data';
import { UseFormReturn } from 'react-hook-form';

export type Props = {
  id: string;
  operate: string;
  state: string;
  dateOfEstablishment: string;
  factory: string;
  ticketType: string;
  ticketNumber: string;
  orderDeliveryTime: string;
  theSalesOrderNumber: string;
  salesOrderItems: string;
  targetPartNumber: string;
  targetProductName: string;
  expectedNumberOfOutputs: string;
  unit: string;
  inboundQuantity: string;
  secondaryUnit: string;
};

export type ConfigureTableProps = {
  id: string;
  availableState: boolean;
  batchStoredLoaderRecordId: string;
  materialId: string;
  materialDescription: string;
  materialSapBatchId: string;
  materialSubBatchId: string;

  /** @deprecated: materialInputUnit 取代之 */
  mainMaterialUnit: string;
  materialInputUnit: string;

  /** @deprecated: availableMaterialInputUnitQuantity 取代之 */
  mainMaterialUnitQuantity: number;
  availableMaterialInputUnitQuantity: string;

  availableMaterialStockUnitQuantity: string;

  actualMaterialWeightUnitQuantity: string;

  materialStockUnit: string;

  stockOverInputUnitRatio: string;

  materialSapBatchRemark: string | null;
  materialSubBatchRemark: string | null;
  batchId: string;
  notes: string;
  stackId: string;
  loaderId: string;
  storageStatus: string;
  storage: number;
  storageUnit: string;
  weight: number;
  weightUnit: string;
  receiveQuantity: string;

  /**
   * @description
   * -  index: 0 => materialStockUnit 基礎計量單位;
   * -  index: 1 => materialInputUnit 可用管理單位
   */
  selectedUnit:
    | { id: 'materialStockUnit'; name: string }
    | { id: 'materialInputUnit'; name: string };
};

export enum Modals {
  DISPATCH_SETTING = 'DISPATCH_SETTING',
  DISPATCH_MATERIAL_VIEW = 'DISPATCH_MATERIAL_VIEW',
}

export type DispatchSettingModalFormProps = {
  materialSubBatchCount: number | null;
  expectedCompletedAt: string;
  materialId: SelectValue | null;
  materialDescription: SelectValue | null;
  recipeId: SelectValue | null;
  tables: {
    designatedInventory: boolean;
    isReachEnd: boolean;
    materialId: string;
    materialDescription: string | null;
    pageOffset: number;
    selectedRowKeys: string[];
    specifiedQuantity: string | null;
    subTotal: string;
    preserveTable: ConfigureTableProps[];
    table: ConfigureTableProps[];
    selectedRowKeyToReceiveQuantity: {
      [batchStoredLoaderRecordId: string]: string;
    };
  }[];
  tempMaterialRequestOrderSpecs: (MaterialRequestOrderSpec & {
    deleted?: boolean;
  })[];
};

export type DispatchOrderPanelTableProps = {
  id: string;
  operate: string;
  createdAt: string;
  expectedCompletedAt: string;
  actualCompletedAt: string;
  issueNumber: string;
  batchId: string;
  sourceType: `${MaterialRequestOrderSourceType}`;
  shippedState: `${OutsourcePurchaseShippedState}`;
  state: string;
  recipients: string;
  workOrderId: string;
};

export type InputMaterialTableProps = {
  id: string;
  materialDescription: string;
  materialId: string;
  batchId: string;
  stackId: string;
  loader: string;
  theNumberOfDues: string;
  numberOfIssued: string;
  unit: string;
  materialSapBatchId: string;
  materialSubBatchId: string;
};

export type DispatchMaterialRecordProps = {
  id: string;
  issueTime: string;
  picker: string;
  materialDescription: string;
  materialId: string;
  lotNumberFlow: string;
  stackId: string;
  loaderId: string;
  theNumberOfMaterialsIssued: string;
  unit: string;
};

export type DispatchPreviewTableProps = {
  id: string;
  materialId: string;
  materialDescription: string;
  expectedMainMaterialUnitQuantity: string;
  materialStockUnit: string;
  expectedMaterialInputUnitQuantity: string;
  materialInputUnit: string;
};

export type Item = {
  orderId: string
  specId: string
  expectedMaterialStockUnitQuantity: string
  receivedMaterialStockUnitQuantity: string
  returnedMaterialStockUnitQuantity: string
  materialStockUnit: string
  materialInputUnit: string
  stockOverInputUnitRatio: string
  materialDescription: string
  oldMaterialId: string
  materialId: string
  expectedMaterialInputUnitQuantity: string
  receivedMaterialInputUnitQuantity: string
  returnedMaterialInputUnitQuantity: string
  components: Component[]
}

export interface Component {
  specId: string
  componentId: string
  itemId: string
  inputMaterialId: string
  factoryId: string
  inputMaterialStockUnit: string
  inputMaterialInputUnit: string
  inputMaterialDescription: string
  inputMaterialQuantity: string
  inputMaterialInputUnitQuantity: string
}

export type DispatchMaterialViewModalProps =
  | {
      dispatchPreviewTable: Item[];
      materialId: string;
      orderId: null;
    }
  | {
      dispatchPreviewTable: null;
      materialId: null;
      orderId: string;
    };

export interface PreviewInputHook {
  methods: UseFormReturn<DispatchSettingModalFormProps>;
  plusAction: ({
    inputMaterialId,
    inputMaterialDescription,
  }: {
    inputMaterialId: string;
    inputMaterialDescription: string;
  }) => void;
}
