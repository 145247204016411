import { Button, Icon, Modal, ModalBody, ModalFooter, ModalHeader } from "@mezzanine-ui/react";
import { BaseField, FormFieldsWrapper, SelectField, TextAreaField } from "@mezzanine-ui/react-hook-form";
import { useForm } from "react-hook-form";
import styles from './equipment-maintenance-modal.module.scss';
import { Upload } from "antd";
import { CreateRepairRequestPayload, Equipment, RepairRequestPriorityOption, RepairRequestPriorityOptions, RepairRequestTargetFinishedDayOption, environment, repairRequestTargetFinishedDayOptions } from "@solar/data";
import { UploadIcon } from "@mezzanine-ui/icons";
import { Modals } from "./EquipmentListModals.enum";
import { useTargetModal } from "@solar/templates";

export type CreateRepairRequestForm = {
  equipment: Equipment;
  priority: RepairRequestPriorityOption;
  targetFinishedDays: RepairRequestTargetFinishedDayOption;
  remark?: string;
  files?: any[];
};

type CreateEquipmentMaintenanceModalProps = {
  onSubmit: (payload: CreateRepairRequestPayload) => void;
};

export function CreateEquipmentMaintenanceModal({ onSubmit }: CreateEquipmentMaintenanceModalProps) {
  const methods = useForm<CreateRepairRequestForm>();
  const { open, data: equipment, closeModal } = useTargetModal<Equipment>(Modals.CREATE);

  const onClose = () => {
    methods.reset({});
    closeModal();
  }

  const handleSubmit = methods.handleSubmit((formState) => {
    onSubmit({
      equipmentId: equipment?.id,
      priority: formState?.priority?.id,
      targetFinishedDays: formState?.targetFinishedDays?.id,
      remark: formState?.remark,
      files: formState?.files?.map((file: any) => file?.response) || [],
    });
    onClose();
  });

  return (
    <Modal
      className={styles.modal_wrapper}
      open={open}
      onClose={onClose}
    >
      <ModalHeader>申請報修</ModalHeader>
      <ModalBody>
        <FormFieldsWrapper methods={methods}>
          <h3>{equipment?.name}</h3>
          <SelectField
            required
            width={160}
            label="優先序："
            registerName="priority"
            options={RepairRequestPriorityOptions} />
          <SelectField
            required
            width={160}
            label="期望完成時間"
            registerName="targetFinishedDays"
            options={repairRequestTargetFinishedDayOptions} />
          <BaseField name="files" label="附件">
            <Upload
              accept="image/jpg, image/jpeg, image/png, .pdf"
              action={`${environment.API_HOST}/files`}
              headers={{ apikey: environment.API_KEY }}
              onChange={({ file, fileList }) => {
                methods.setValue('files', fileList);
              }}>
              <Button variant="outlined" prefix={<Icon icon={UploadIcon} />}>
                上傳附件
              </Button>
            </Upload>
          </BaseField>
          <TextAreaField
            placeholder="備註"
            registerName="remark"
            maxLength={150} />
        </FormFieldsWrapper>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>
          取消
        </Button>
        <Button onClick={handleSubmit}>
          送出
        </Button>
      </ModalFooter>
    </Modal>
  );
}
