import { Message, ModalActions } from '@mezzanine-ui/react';
import {
  FormFieldsDebug,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  LocationSelectorProvider,
  LOCATION_SELECTOR,
  RowSection,
  useLocationSelectorController,
  useTargetModal,
} from '@solar/templates';
import { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { WarehouseAndVehicleFilter } from '../../IncomingInspectOperationPage/Modals/WarehouseAndVehicleFilter';
import { ModalLayout } from '../../ModalLayout/ModalLayout';
import { Modals } from '../const';
import { useLoadersCategories } from '@solar/data';
import { useLoadersWithCount } from '@solar/data';
import { CreateLoaderFormProps } from '../types';
import { createLoader } from '../utils';

type Props = {
  locationSelectorController: ReturnType<typeof useLocationSelectorController>;
};

export function CreateLoaders({ locationSelectorController }: Props) {
  const warehouseAndVehicleFilterRef =
    useRef<React.ElementRef<typeof WarehouseAndVehicleFilter>>(null);

  const { data: loadersCategories } = useLoadersCategories();

  const methods = useForm<CreateLoaderFormProps>();

  const { mutate: refetchLoadersWithCount } = useLoadersWithCount();

  const modalController = useTargetModal<Record<string, unknown>>(
    Modals.CreateLoaders
  );

  const locationSelectorModalController =
    useTargetModal<null>(LOCATION_SELECTOR);

  const handleModalClose = useCallback(() => {
    modalController.closeModal();
  }, []);

  const handleSubmit = useCallback(
    async (value: CreateLoaderFormProps) => {
      const { selectedIds } = locationSelectorController;
      const shelfId =
        selectedIds?.shelfId ?? selectedIds?.stackId ?? selectedIds?.zoneId;

      if (!shelfId) {
        warehouseAndVehicleFilterRef.current?.setLocationRequired(true);
        return;
      } else {
        warehouseAndVehicleFilterRef.current?.setLocationRequired(false);
      }

      try {
        const res = await createLoader({
          categoryCode: value?.categoryCode?.id ?? '',
          widthCm: value?.widthCm || '0',
          lengthCm: value?.lengthCm || '0',
          heightCm: value?.widthCm || '0',
          capacityL: value?.capacityL || '0',
          shelfId: shelfId,
          count: value?.count,
        });

        if (res.ok) {
          const data = await res.json();
          Message.success(`${data?.[0]?.id} 新增載具成功`);
          refetchLoadersWithCount();
        }
      } catch (err) {
        if (err instanceof Error) {
          Message.error(JSON.parse(err.message).message);
        } else {
          Message.error('新增失敗');
        }
      } finally {
        handleModalClose();
      }
    },
    [locationSelectorController]
  );

  return (
    <ModalLayout
      modalHeader="新增載具"
      {...modalController}
      closeModal={handleModalClose}
      modalFooter={
        <ModalActions
          confirmText="建立"
          cancelText="取消"
          confirmButtonProps={{ form: Modals.CreateLoaders }}
          onCancel={() => handleModalClose()}
        />
      }
    >
      <LocationSelectorProvider controller={locationSelectorController}>
        <FormFieldsWrapper
          id={Modals.CreateLoaders}
          methods={methods}
          onSubmit={handleSubmit}
        >
          <RowSection label={'類型：'}>
            <SelectField
              registerName="categoryCode"
              options={loadersCategories?.map((row) => ({
                id: row.code,
                name: row.name,
              }))}
            />
          </RowSection>
          <RowSection label="數量：">
            <InputField registerName="count" type="number" valueAsNumber />
          </RowSection>
          <RowSection label="長(cm)：">
            <InputField registerName="lengthCm" />
          </RowSection>
          <RowSection label="寬(cm)：">
            <InputField registerName="widthCm" />
          </RowSection>
          <RowSection label="高(cm)：">
            <InputField registerName="heightCm" />
          </RowSection>
          <RowSection label="容量(L)：">
            <InputField registerName="capacityL" />
          </RowSection>
          <WarehouseAndVehicleFilter
            locationLabel="位置："
            openLocationSelectorModal={
              locationSelectorModalController.openModal
            }
            ref={warehouseAndVehicleFilterRef}
          />
        </FormFieldsWrapper>
      </LocationSelectorProvider>
    </ModalLayout>
  );
}
