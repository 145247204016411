import { useEffect, useMemo, useState } from 'react';
import styles from '../Equipment/EquipmentListPage/equipment-list-page.module.scss';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  MenuDivider,
  Radio,
  RadioGroup,
  RadioGroupOption,
  Typography,
} from '@mezzanine-ui/react';
import { SupplyWareHouseRadio } from './const/supply-warehouse';
import { MaterialViewFilter } from '../MaterialEnquiry/Filters/MaterialViewFilter';
import { WarehouseViewFilter } from '../MaterialEnquiry/Filters/WarehouseViewFilter';
import { useModalController } from '../MaterialEnquiry/CostWarehousePage/CostWarehousePage';
import { useMaterials, useMaterialsByLocation } from '@solar/data';
import { StorageInfoModal } from '../MaterialEnquiry/Modal/StorageInfoModal';
import { LocationSelectorModal } from '../Equipment/LocationSelector/LocationSelectorModal';
import { SupplyTable } from './SupplyTable/SupplyTable';

export function CusTomerSupplyWarehousePage() {
  const filterMethods = useForm();
  const filterMethodsBylocation = useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const locationSelectorModalController = useModalController();
  const createSupplyMaintenanceModalController = useModalController();

  // @TODO: 換資料來源
  const { materials, pageInfo, mutateGetMaterials, isLoading, setParams } =
    useMaterials(filterMethods);
  const {
    materials: materialsByLocation,
    pageInfo: pageInfoByLocation,
    isLoading: isLoadingByLocation,
    mutateGetMaterials: mutateGetMaterialsByLocation,
  } = useMaterialsByLocation(filterMethodsBylocation);

  const [defaultView, setDefaultView] = useState(() =>
    location.pathname === '/customer-supply/supply-view'
      ? SupplyWareHouseRadio.SUPPLY_VIEW
      : SupplyWareHouseRadio.WAREHOUSE_VIEW
  );

  const isSupplyView = useMemo(
    () => defaultView === SupplyWareHouseRadio.SUPPLY_VIEW,
    [defaultView]
  );

  const options: RadioGroupOption[] = [
    { value: 'supply-view', label: '客供料視角' },
    { value: 'warehouse-view', label: '儲位視角' },
  ];

  useEffect(() => {
    const subscription = isSupplyView
      ? filterMethods.watch(() => mutateGetMaterials({ nextPage: 1 }))
      : filterMethodsBylocation.watch(() =>
          mutateGetMaterialsByLocation({ nextPage: 1 })
        );

    return () => subscription.unsubscribe();
  }, [
    filterMethods,
    filterMethodsBylocation,
    filterMethods.watch(),
    filterMethodsBylocation.watch(),
    mutateGetMaterials,
    mutateGetMaterialsByLocation,
    isSupplyView,
  ]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div>
          <Typography variant="h1">客供料查詢</Typography>
          <RadioGroup
            defaultValue={defaultView}
            onChange={(e) => {
              navigate(`/customer-supply/${e.target.value}`);
              setDefaultView(e.target.value as SupplyWareHouseRadio);
            }}
          >
            {options?.map(({ label, value }: RadioGroupOption) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </RadioGroup>
        </div>
      </div>
      <MenuDivider />
      {isSupplyView ? (
        <MaterialViewFilter
          setParams={setParams}
          methods={filterMethods}
          openLocationSelectorModal={locationSelectorModalController.openModal}
        />
      ) : (
        <WarehouseViewFilter
          setParams={setParams}
          methods={filterMethodsBylocation}
          openLocationSelectorModal={locationSelectorModalController.openModal}
        />
      )}
      <SupplyTable
        defaultView={defaultView}
        equipments={isSupplyView ? materials : materialsByLocation}
        pageInfo={isSupplyView ? pageInfo : pageInfoByLocation}
        mutateGetSupply={
          isSupplyView ? mutateGetMaterials : mutateGetMaterialsByLocation
        }
        isLoading={isSupplyView ? isLoading : isLoadingByLocation}
        openSupplyDetailModal={createSupplyMaintenanceModalController.openModal}
      />
      <StorageInfoModal {...createSupplyMaintenanceModalController} />
      <LocationSelectorModal
        {...locationSelectorModalController}
        headerText="儲位位置"
        onSubmit={() => {}}
      />
    </div>
  );
}
