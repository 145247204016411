import { MznPaginationTable } from '@solar/templates';

import { useWaitingStorageInColumn } from '../hooks';
import { PanelProps } from '../types';
import { useCallback } from 'react';
import { API_NAMESPACE, request } from '@solar/data';
import { Message } from '@mezzanine-ui/react';

export const RecordPanel = ({
  refetchWaitReceipt,
  mutateWaitReceipt,
  pageInfo,
  tableData,
  isLoading,
}: PanelProps) => {
  const rollback = useCallback(async (id: string) => {
    try {
      const response = await request(`/rollbacks/receipt-items/${id}`, {
        namespace: API_NAMESPACE.PP,
        method: 'POST',
        responseParser: (res) => res,
      });

      if (response?.ok) {
        Message.success('迴轉成功');
        await mutateWaitReceipt?.(undefined, true);
      }
    } catch (error: any) {
      Message.error(`迴轉失敗：${JSON.parse(error.message)?.message}`);
    }
  }, []);
  const columns = useWaitingStorageInColumn({ rollback });

  return (
    <MznPaginationTable
      scroll={{ x: 2500 }}
      columns={columns}
      dataSource={tableData ?? []}
      fetchData={refetchWaitReceipt}
      hideDefaultExpandColumn
      pageInfo={pageInfo}
      loading={isLoading}
    />
  );
};
