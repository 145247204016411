import { useParams } from 'react-router';
import { PageLayout } from '../Templates/PageLayout';
import { Button, Message, Table } from '@mezzanine-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useDraggableColumns } from '../Templates/PaginationTable';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { useForm } from 'react-hook-form';
import {
  API_NAMESPACE,
  DeliveryOrderDetail,
  request,
  useGetDeliveryOrderDetail,
  useGetVirtualDeliveryOrderDetail,
} from '@solar/data';
import { BindingFormValues, VirtualInType } from './typing';
import { SalesOrderItemsSelector } from './components/SalesOrderItemsSelector';
import { Space } from 'antd';

type DeliveryOrderDetailMaterialItem =
  DeliveryOrderDetail['specs'][number]['materialItems'][number];

export function VirtualOutAndInBindingPage() {
  const params = useParams();
  const tableRef = useRef<HTMLTableElement>(null);
  const methods = useForm<BindingFormValues>();
  const { deliveryOrder } = useGetDeliveryOrderDetail(params?.deliveryOrderId);

  const { deliveryOrder: bindingData } = useGetVirtualDeliveryOrderDetail(
    params?.deliveryOrderId
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const columns = useDraggableColumns<DeliveryOrderDetailMaterialItem>(
    tableRef,
    [
      {
        width: 200,
        title: '虛出出貨單項次',
        dataIndex: 'productionDeliveryOrderLine',
      },
      {
        draggable: true,
        width: 400,
        title: '料號',
        dataIndex: 'materialId',
      },
      {
        draggable: true,
        width: 400,
        title: '品名',
        dataIndex: 'materialDescription',
      },
    ]
  );

  const dataSource =
    deliveryOrder?.specs?.flatMap((spec) =>
      spec?.materialItems?.map((item) =>
        Object.assign(
          {
            id: `${item.productionDeliveryOrderId}-${item.productionDeliveryOrderLine}`,
          },
          item
        )
      )
    ) ?? [];
  // deliveryOrder?.specs?.[0]?.materialItems?.map((item) =>
  //   Object.assign(
  //     {
  //       id: `${item.productionDeliveryOrderId}-${item.productionDeliveryOrderLine}`,
  //     },
  //     item
  //   )
  // ) ?? [];

  useEffect(() => {
    bindingData?.bindings?.forEach((binding) => {
      switch (true) {
        case !!binding?.relatedDeliveryOrderId: {
          methods.setValue(binding.deliveryOrderLine, {
            relatedCategory: {
              id: VirtualInType.DELIVERY_ORDER,
              name: '虛入出貨單',
            },
            relatedOrderId: {
              id: binding.relatedDeliveryOrderId,
              name: binding.relatedDeliveryOrderId,
            },
            relatedOrderItemId: {
              id: binding.relatedDeliveryOrderLine,
              name: binding.relatedDeliveryOrderLine,
            },
            relatedOrderMaterialId: binding.relatedDeliveryOrderMaterialId,
            receivedItems: binding.relations.map((item) => ({
              relatedOrderId: item.relatedOrderId,
              relatedOrderItemId: item.relatedOrderDetailId,
              relatedOrderMaterialId: item.relatedOrderDetailMaterialId,
            })),
          });
          break;
        }

        case !!binding?.relatedInventoryPurchaseOrderId: {
          methods.setValue(binding.deliveryOrderLine, {
            relatedCategory: {
              id: VirtualInType.INVENTORY_PURCHASE_ORDER,
              name: '採購單',
            },
            relatedOrderId: {
              id: binding.relatedInventoryPurchaseOrderId,
              name: binding.relatedInventoryPurchaseOrderId,
            },
            relatedOrderItemId: {
              id: binding.relatedInventoryPurchaseOrderItemId,
              name: binding.relatedInventoryPurchaseOrderItemId,
            },
            relatedOrderMaterialId:
              binding.relatedInventoryPurchaseOrderMaterialId,
            receivedItems: binding.relations.map((item) => ({
              relatedOrderId: item.relatedOrderId,
              relatedOrderItemId: item.relatedOrderDetailId,
              relatedOrderMaterialId: item.relatedOrderDetailMaterialId,
            })),
          });
          break;
        }
        default:
          break;
      }
    });
  }, [bindingData, methods]);

  const onSubmit = methods.handleSubmit(async (values) => {
    try {
      setIsSubmitting(true);
      const items = Object.entries(values).map(([orderLine, value]) => ({
        deliveredOrderId: params?.deliveryOrderId,
        deliveredOrderLine: orderLine,
        receivedItems: value?.receivedItems ?? [],
        relatedCategory: value?.relatedCategory?.id,
        relatedOrderId: value?.relatedOrderId?.id,
        relatedOrderItemId: value?.relatedOrderItemId?.id,
        relatedOrderMaterialId: value?.relatedOrderMaterialId,
      }));

      if (!items?.length) {
        Message.error('無虛出出貨單項次資訊');
        return;
      }

      for (const item of items) {
        if (!item?.relatedCategory) {
          Message.error('請選擇虛入種類');
          methods.setFocus(`${item.deliveredOrderLine}.relatedCategory`);
          return;
        }

        if (!item?.relatedOrderId) {
          Message.error('請選擇虛入單號');
          methods.setFocus(`${item.deliveredOrderLine}.relatedOrderId`);
          return;
        }

        if (!item?.relatedOrderItemId) {
          Message.error('請選擇虛入單號項次');
          methods.setFocus(`${item.deliveredOrderLine}.relatedOrderItemId`);
          return;
        }

        if (!item?.relatedOrderMaterialId) {
          Message.error('此項次無料號資訊');
          return;
        }

        // if (!item?.receivedItems?.length) {
        //   Message.error('請新增收料訂單，至少一項');
        //   return;
        // }
      }

      const response = await request(
        '/warehouses/production-virtual-delivery-orders/binding',
        {
          method: 'POST',
          namespace: API_NAMESPACE.MATERIALS,
          body: JSON.stringify({ items }),
          responseParser: (res) => res,
        }
      );

      if (response.ok) {
        Message.success('綁定成功');
      }
    } catch (error: any) {
      console.error(error);
      Message.error(`綁定失敗：${JSON.parse(error?.message)?.message}`);
    } finally {
      setIsSubmitting(false);
    }
  });

  if (!params?.deliveryOrderId) return null;

  return (
    <PageLayout
      title={`出貨單：${params?.deliveryOrderId}`}
      backTo="/order/virtual-out-and-in"
    >
      <FormFieldsWrapper methods={methods}>
        <Space direction="vertical">
          <Table
            ref={tableRef}
            columns={columns}
            dataSource={dataSource}
            scroll={{ x: 1000 }}
            expandable={{
              rowExpandable: () => true,
              expandedRowRender: (source: DeliveryOrderDetailMaterialItem) => (
                <SalesOrderItemsSelector
                  virtualOutDeliveryOrderItemId={
                    source?.productionDeliveryOrderLine
                  }
                  virtualOutDeliveryOrderItemMaterialId={source?.materialId}
                />
              ),
            }}
          />
          <Button variant="contained" loading={isSubmitting} onClick={onSubmit}>
            儲存
          </Button>
        </Space>
      </FormFieldsWrapper>
    </PageLayout>
  );
}
