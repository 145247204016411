export function useTargetRuleCreateApplyMaterialColumns() {
  return [
    {
      title: '料號編碼',
      dataIndex: 'material_id',
      width: 400,
      key: 'material_id',
    },
    {
      title: '料號敘述',
      dataIndex: 'description',
    },
  ];
}
