import {
  ButtonGroup,
  CalendarConfigProvider,
  Typography,
  Button,
} from '@mezzanine-ui/react';
import styles from './../../TargetNumber.module.scss';
import {
  AutoCompleteField,
  DateRangePickerField,
  FormFieldsWrapper,
  InputField,
} from '@mezzanine-ui/react-hook-form';
import { useForm } from 'react-hook-form';
import CalendarMethodsMoment from '@mezzanine-ui/core/calendarMethodsMoment';
import {
  DispatcherTargetNumberRule,
  useGetTargetRuleCustomers,
} from '@solar/data';
import { useState } from 'react';
import { Customer } from '../../TargetNumber.interface';

interface TargetNumberRuleDispatcherFilterProps {
  onSubmit: (filterParams: DispatcherTargetNumberRule) => void;
}

interface mappedFilter {
  customer_id?: string;
  order_id?: string;
  start_date?: string;
  end_date?: string;
  [key: string]: string | undefined;
}

export function TargetNumberDispatcherFilter({
  onSubmit,
}: TargetNumberRuleDispatcherFilterProps) {
  const methods = useForm<DispatcherTargetNumberRule>({
    defaultValues: {
      customerName: { id: '', name: '' },
      salesOrderNumber: '',
      date: [],
    },
  });
  const [customerQuery, setCustomerQuery] = useState('');
  const { targetRuleCustomers } = useGetTargetRuleCustomers(customerQuery);

  const handleFilterSubmit = (data: DispatcherTargetNumberRule) => {
    const mappedFilter = {
      customer_id: data?.customerId || undefined,
      order_id: data.salesOrderNumber || undefined,
      // start_date: data.date?.[0] || undefined,
      // end_date: data.date?.[1] || undefined,
    };
    Object.keys(mappedFilter).forEach((key) => {
      if ((mappedFilter as mappedFilter)[key] === undefined) {
        delete (mappedFilter as mappedFilter)[key];
      }
    });
    onSubmit(mappedFilter);
  };

  const handleCustomerSelect = (selectedOption: Customer) => {
    if (selectedOption) {
      methods.setValue('customerId', selectedOption.id);
    } else {
      methods.setValue('customerId', '');
    }
  };

  return (
    <CalendarConfigProvider methods={CalendarMethodsMoment}>
      <FormFieldsWrapper
        className={styles.filter}
        methods={methods}
        onSubmit={handleFilterSubmit}
      >
        <div className={styles.formContent}>
          <Typography variant="h4">客戶名稱(出貨對象):</Typography>
          <AutoCompleteField
            registerName="customerName"
            options={targetRuleCustomers || []}
            width={500}
            onSearch={(inputValue) => setCustomerQuery(inputValue)}
            onChange={handleCustomerSelect}
          />
          <Typography variant="h4">訂單編號:</Typography>
          <InputField registerName="salesOrderNumber" width={250} />
        </div>
        {/* <div className={styles.formContent}>
        <Typography variant="h4">日期:</Typography>
          <DateRangePickerField registerName="date" width={400} />
        </div> */}
        <ButtonGroup className={styles.buttonGroup} size="large">
          <Button variant="contained" type="submit">
            查詢
          </Button>
          <Button variant="outlined" onClick={() => methods.reset()}>
            清除
          </Button>
        </ButtonGroup>
      </FormFieldsWrapper>
    </CalendarConfigProvider>
  );
}
