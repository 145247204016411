import { useState } from 'react';
import { request, API_NAMESPACE } from '../request';
import { SPMatchItem } from './useStrikePrice';
import Decimal from 'decimal.js';

const namespace = API_NAMESPACE.MISC;

interface ISearchParams {
  orderType: string;
  orderId: string;
  component: string;
}

interface ISearchPurchaseParams {
  purchaseType: string;
  purchaseId: string;
  component: string;
}

export interface ISPPurchaseOrderItem {
  id: string;
  key: string;
  materialId: string;
  orderId: string;
  orderItem: string;
  quantity: number;
  quantityUnit: string;
  standardWt: number;
  unBindQty: number | string;
  wt: number | string;
  canEditQuantity: boolean;
  canMatch: boolean;
  [key: string]: any;
}

export const useStrikePriceMatch = () => {
  const [searchItems, setSearchItems] = useState<ISPPurchaseOrderItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const searchOrder = async (
    params: ISearchParams,
    tempMatchList: SPMatchItem[],
    tempUnbindList: SPMatchItem[]
  ) => {
    setIsLoading(true);
    setSearchItems([]);
    try {
      const response = (await request('/order/getItemForReportPrice', {
        method: 'GET',
        namespace,
        responseParser: (res) => {
          // debugger;
          return res.json().then((resJson) => {
            if (Array.isArray(resJson)) {
              const q = resJson.map((d: ISPPurchaseOrderItem) => {
                const matchItem = tempMatchList
                  .filter((d) => !d.id)
                  .find(
                    (item) =>
                      item.orderId === d.orderId &&
                      item.orderItem === d.orderItem
                  );
                const unbindItem = tempUnbindList.find(
                  (item) =>
                    item.orderId === d.orderId && item.orderItem === d.orderItem
                );
                const unBindQty = new Decimal(
                  d.unBindQty === null ? 0 : d.unBindQty
                )
                  .plus(unbindItem?.matchQty || 0)
                  .minus(matchItem?.matchQty || 0)
                  .toNumber();
                const res = {
                  ...d,
                  key: `${d.orderId}${d.orderItem}`,
                  unBindQty,
                };
                // console.log(res);
                return res;
              });
              return q;
            }
            return [];
          });
        },
        params,
      })) as ISPPurchaseOrderItem[];
      // console.log(response);
      setSearchItems(response);
    } catch (e: any) {
      // console.log(e);
      if (e.message && e.message.startsWith('{')) {
        const error = JSON.parse(e.message);
        if (error.message) {
          throw new Error(error.message);
        }
      }
      throw new Error('發生錯誤，請稍後再試');
    } finally {
      setIsLoading(false);
    }
  };

  const bindSearchItem = (item: ISPPurchaseOrderItem, newUnbindQty: number) => {
    const newSearchItems = searchItems.map((searchItem) => {
      if (
        searchItem.orderId === item.orderId &&
        searchItem.orderItem === item.orderItem
      ) {
        return {
          ...searchItem,
          unBindQty: newUnbindQty,
        };
      }
      return searchItem;
    });
    setSearchItems(newSearchItems);
  };

  const unbindSearchItem = (item: SPMatchItem) => {
    const newSearchItems = searchItems.map((searchItem) => {
      if (
        searchItem.orderId === item.orderId &&
        searchItem.orderItem === item.orderItem
      ) {
        return {
          ...searchItem,
          unBindQty: new Decimal(searchItem.unBindQty)
            .add(item.matchQty)
            .toNumber(),
        };
      }
      return searchItem;
    });
    setSearchItems(newSearchItems);
  };

  const clearSearchItems = () => {
    setSearchItems([]);
  };

  const searchPurchase = async (
    params: ISearchPurchaseParams,
    tempMatchList: SPMatchItem[],
    tempUnbindList: SPMatchItem[]
  ) => {
    setIsLoading(true);
    setSearchItems([]);
    try {
      const response = (await request('/purchase/getItemForReportPrice', {
        method: 'GET',
        namespace,
        responseParser: (res) => {
          // debugger;
          return res.json().then((resJson) => {
            if (Array.isArray(resJson)) {
              const q = resJson.map(
                (purchaseOrderItem: ISPPurchaseOrderItem) => {
                  const matchItem = tempMatchList
                    .filter((d) => !d.id)
                    .find(
                      (item) =>
                        item.orderId === purchaseOrderItem.orderId &&
                        item.orderItem === purchaseOrderItem.orderItem
                    );
                  const unbindItem = tempUnbindList.find(
                    (item) =>
                      item.orderId === purchaseOrderItem.orderId &&
                      item.orderItem === purchaseOrderItem.orderItem
                  );
                  const unBindQty = new Decimal(
                    matchItem?.originalUnBindQty ||
                      purchaseOrderItem?.unBindQty ||
                      0
                  )
                    .plus(unbindItem?.matchQty || 0)
                    .minus(matchItem?.matchQty || 0)
                    .toNumber();
                  const res = {
                    ...purchaseOrderItem,
                    key: `${purchaseOrderItem.orderId}${purchaseOrderItem.orderItem}`,
                    unBindQty,
                    wt: matchItem?.originalWt || purchaseOrderItem.wt,
                  };
                  const isHMaterial =
                    purchaseOrderItem.materialId.startsWith('H');
                  if (unbindItem && unBindQty === purchaseOrderItem.wt) {
                    if (isHMaterial) {
                      res.canEditQuantity = true;
                    } else {
                      res.canEditQuantity = false;
                    }
                    res.canMatch = true;
                  }
                  if (matchItem) {
                    res.canEditQuantity = false;
                    res.canMatch = false;
                  }
                  // console.log(res);
                  return res;
                }
              );
              return q;
            }
            return [];
          });
        },
        params,
      })) as ISPPurchaseOrderItem[];
      // console.log(response);
      setSearchItems(response);
    } catch (e: any) {
      // console.log(e);
      if (e.message && e.message.startsWith('{')) {
        const error = JSON.parse(e.message);
        if (error.message) {
          throw new Error(error.message);
        }
      }
      throw new Error('發生錯誤，請稍後再試');
    } finally {
      setIsLoading(false);
    }
  };

  const bindPurchaseItem = (
    item: ISPPurchaseOrderItem,
    newUnbindQty: number
  ) => {
    const newSearchItems = searchItems.map((searchItem) => {
      if (
        searchItem.orderId === item.orderId &&
        searchItem.orderItem === item.orderItem
      ) {
        return {
          ...searchItem,
          unBindQty: newUnbindQty,
          canEditQuantity: false,
          canMatch: false,
        };
      }
      return searchItem;
    });
    setSearchItems(newSearchItems);
  };

  const unbindPurchaseItem = (item: SPMatchItem) => {
    const newSearchItems = searchItems.map((searchItem) => {
      if (
        searchItem.orderId === item.orderId &&
        searchItem.orderItem === item.orderItem
      ) {
        return {
          ...searchItem,
          unBindQty: new Decimal(searchItem.unBindQty)
            .add(item.matchQty)
            .toNumber(),
          canEditQuantity: searchItem.materialId.startsWith('H'),
          canMatch: true,
        };
      }
      return searchItem;
    });
    setSearchItems(newSearchItems);
  };

  return {
    searchPurchase,
    searchOrder,
    searchItems,
    setSearchItems,
    isLoading,
    unbindSearchItem,
    bindSearchItem,
    clearSearchItems,
    bindPurchaseItem,
    unbindPurchaseItem,
  };
};
