import { Button, Typography } from '@mezzanine-ui/react';
import {
  IQCOrderType,
  IncomingPurchaseOrder,
  NextPagination,
  getNextParams,
  useGetIQCOrderStatuses,
  useGetIQCPurchaseOrders,
} from '@solar/data';
import { useCallback } from 'react';
import { CopyTextWrapper, MznPaginationTable } from '@solar/templates';
import { Space } from 'antd';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import { useForm } from 'react-hook-form';
import { IQCTableBaseProps } from './incoming-quality-control-table.type';
import { TableColumn } from '@mezzanine-ui/core/table';

interface IQCPurchaseOrderTableProps
  extends IQCTableBaseProps<IncomingPurchaseOrder> {
  iqcOrderType: IQCOrderType.PURCHASE_ORDER;
  purchaseOrderId?: string;
  purchaseOrderType?: string;
}

export function IQCPurchaseOrderTable({
  purchaseOrderId,
  statusIds,
  purchaseOrderType,
  getColumns,
  expandable,
}: IQCPurchaseOrderTableProps) {
  const methods = useForm();
  const {
    iqcPurchaseOrders,
    isLoading,
    pageInfo,
    refetchGetIQCPurchaseOrders,
  } = useGetIQCPurchaseOrders({
    statusIds,
    purchaseOrderId,
    purchaseOrderType,
  });

  const { statuses } = useGetIQCOrderStatuses();

  const refetchGetIQCPurchaseOrdersWithPagination = useCallback(
    (nextPagination: NextPagination) => {
      const formState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      refetchGetIQCPurchaseOrders({
        offset,
        limit,
        statusIds,
        purchaseOrderId: purchaseOrderId ?? formState?.purchaseOrderId,
        materialId: formState?.materialId,
        sapBatchId: formState?.sapBatchId,
      });
    },
    [methods, pageInfo, purchaseOrderId, refetchGetIQCPurchaseOrders, statusIds]
  );

  const onSearch = () =>
    refetchGetIQCPurchaseOrdersWithPagination({ nextPage: 1 });

  const defaultColumns: TableColumn<IncomingPurchaseOrder>[] = [
    {
      width: 150,
      title: '採購類型',
      render: (source) => {
        switch (source?.purchaseOrderType) {
          case 'INVENTORY':
            return '庫存採購單';
          case 'MISC':
            return '雜項採購單';
          case 'OUTSOURCE':
            return '委外採購單';
          case 'FIXED_ASSET':
            return '固資採購單';
          default:
            return '';
        }
      },
    },
    {
      width: 150,
      title: '採購單號',
      render: (source) => (
        <CopyTextWrapper
          text={(() => {
            switch (source?.purchaseOrderType) {
              case 'INVENTORY':
                return source?.inventoryPurchaseOrderId as string;
              case 'MISC':
              case 'FIXED_ASSET':
                return source?.miscPurchaseOrderId as string;
              case 'OUTSOURCE':
                return source?.outsourcePurchaseOrderId as string;
              default:
                return '';
            }
          })()}
        />
      ),
    },
    {
      width: 100,
      title: '項次',
      render: (source) => (
        <CopyTextWrapper
          text={(() => {
            switch (source?.purchaseOrderType) {
              case 'INVENTORY':
                return source?.inventoryPurchaseOrderItemId as string;
              case 'MISC':
              case 'FIXED_ASSET':
                return source?.miscPurchaseOrderItemId as string;
              case 'OUTSOURCE':
                return source?.outsourcePurchaseOrderItemId as string;
              default:
                return '';
            }
          })()}
        />
      ),
    },
    {
      width: 300,
      title: '料號',
      render: (source: any) => (
        <CopyTextWrapper text={source?.materialId ?? ''} />
      ),
    },
    {
      width: 150,
      dataIndex: 'sapBatchId',
      title: '批號',
    },
    {
      width: 500,
      title: '品名',
      render: (source: any) => (
        <CopyTextWrapper
          text={
            source?.material?.description ??
            source?.miscMaterial?.description ??
            ''
          }
        />
      ),
    },
    {
      width: 200,
      title: '狀態',
      render: ({ statusId }: { statusId: number }) =>
        statuses?.find((status) => status.id === statusId)?.name ?? '',
    },
    {
      width: 100,
      dataIndex: 'receivedQuantity',
      title: '數量',
    },
    {
      width: 100,
      render: (source: any) => {
        return source?.sapBatch?.materialInputUnit ?? '';
      },
      title: '單位',
    },
  ];

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <FormFieldsWrapper methods={methods}>
        <Space align="end">
          {!purchaseOrderId && (
            <InputField label="採購單號" registerName="purchaseOrderId" />
          )}
          <InputField label="料號" registerName="materialId" />
          <InputField label="批號" registerName="sapBatchId" />
          <Button
            type="button"
            variant="outlined"
            loading={isLoading}
            onClick={onSearch}
          >
            搜尋
          </Button>
        </Space>
      </FormFieldsWrapper>
      <MznPaginationTable
        dataSource={iqcPurchaseOrders}
        pageInfo={pageInfo}
        loading={isLoading}
        fetchData={refetchGetIQCPurchaseOrdersWithPagination}
        columns={getColumns?.(defaultColumns) ?? defaultColumns}
        scroll={{ x: 2000 }}
        expandable={
          expandable ?? {
            rowExpandable: () => true,
            expandedRowRender: (source: IncomingPurchaseOrder) => (
              <Space direction="vertical" style={{ padding: 12 }}>
                <Space>
                  <Typography variant="caption">子批號：</Typography>
                  <Typography variant="body1">
                    {source?.sapBatch?.subBatches
                      ?.map((subBatch) => subBatch?.id ?? '')
                      ?.join(',')}
                  </Typography>
                </Space>
                <Space>
                  <Typography variant="caption">檢驗備註：</Typography>
                  <Typography variant="body1">
                    {source?.qcNote ?? '無'}
                  </Typography>
                </Space>
                <Space>
                  <Typography variant="caption">倉管備註：</Typography>
                  <Typography variant="body1">
                    {source?.warehouseNote ?? '無'}
                  </Typography>
                </Space>
                <Space>
                  <Typography variant="caption">需求單位備註：</Typography>
                  <Typography variant="body1">
                    {source?.demandedUnitNote ?? '無'}
                  </Typography>
                </Space>
              </Space>
            ),
          }
        }
      />
    </Space>
  );
}
