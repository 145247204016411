import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { MaterialSourceCode } from './material-source-codes.type';

const namespace = API_NAMESPACE.MATERIALS;

export function useMaterialSourceCodes() {
  const { data, error } = useSWR<MaterialSourceCode[]>([
    '/material-properties/source-codes',
    { namespace },
  ]);

  return {
    materialSourceCodes: data ?? [],
    isLoading: !error && !data,
  };
}
