import { useState } from 'react';
import useSWR from 'swr';
import {
  CommonTableFilterParam,
  UseDataSourceHook,
} from '../common-table-filter-param';
import { OffsetBased, PageInfo } from '../pagination';
import { API_NAMESPACE, request } from '../request';
import { PackedStates } from './enum';

export type ProductionDeliveryOrders = {
  packedStates: `${PackedStates}`;
  id: string;
  orderId: string;
  staffName: string;
  customerId: string;
  customerName: string;
  createdAt: string;
};

export type GetProductionDeliveryParam = {
  customerIds: string[];
  packedStates: `${PackedStates}`;
};

const namespace = API_NAMESPACE.MATERIALS;
export function useProductionDeliveryOrders({
  initiateFetching = false,
}: UseDataSourceHook) {
  const [params, setParams] = useState<
    OffsetBased & CommonTableFilterParam & GetProductionDeliveryParam
  >();
  const { data, error } = useSWR<{
    records: ProductionDeliveryOrders[];
    pageInfo: PageInfo;
  }>(
    initiateFetching || params
      ? ['/warehouses/production-delivery-orders', { params, namespace }]
      : null
  );

  return {
    data: data?.records || [],
    pageInfo: data?.pageInfo,
    isLoading: !error && !data,
    mutateParams: setParams,
  };
}

export type ProductionDeliveryOrderReceiptBody = {
  items: {
    specId: string | number;
    toShelfId: string;
    materialInputUnit: string;
    batches: {
      materialInputUnitQuantity: string;
      materialWeightUnitQuantity: string;
      materialGrossWeightUnitQuantity: string;
      remark?: string | null;
      oldBatchId: null;
    }[];
    remark?: string | null;
  }[];
  recycleNumber?: string;
};

export async function updateProductionDeliveryOrderReceipt(
  body: ProductionDeliveryOrderReceiptBody
) {
  const response: Promise<Response> = await request(
    '/warehouses/production-delivery-orders/receipt',
    {
      method: 'put',
      body: JSON.stringify(body),
      responseParser: (res) => res,
      namespace,
    }
  );

  return response;
}
