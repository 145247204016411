import { Button, Tab, TabPane, Tabs, Typography } from '@mezzanine-ui/react';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import {
  FilterScaffold,
  LOCATION_SELECTOR,
  LocationSelectorGroupModal,
  ModalGroup,
  useModalGroupController,
  useQueryStringWithAutoRouting,
} from '@solar/templates';
import { useForm } from 'react-hook-form';

import { createSearchParams } from 'react-router-dom';
import { PageLayout } from '../../Material/PageLayout/PageLayout';

import { NextPagination, getNextParams, useWaitReceipt } from '@solar/data';
import { useCallback, useEffect } from 'react';
import { BarcodeScanInput } from '../../Material/MaterialShift/BarcodeScanInput';
import { LocationIdsAutoField } from './LocationIdsAutoField';
import { tabIds, tabsOptions, waitReceiptStatus } from './const';
import filterClasses from './filter.module.scss';
import { DeleteWaitReceiptModal } from './modal/DeleteWaitReceiptModal';
import { InspectModal } from './modal/InspectModal';
import { FilterPrams, Modals, TabsEnum } from './types';
import { handleMaterialIdBarcodeScan } from './utils';
import classes from './wait-storage-in-page.module.scss';

export const WaitStorageInPage = () => {
  const { activeTab, setSearchParams } = useQueryStringWithAutoRouting({
    enableAutoRouting: true,
    tabIds: tabIds,
  });

  const methods = useForm<FilterPrams>({
    defaultValues: {
      barcode: '',
      batchId: '',
      locationIds: [],
      materialId: '',
      workOrderId: '',
    },
  });

  const { data, refetchWaitReceipt, pageInfo, mutateWaitReceipt, isLoading } =
    useWaitReceipt();

  const refetchWaitReceiptByPagination = useCallback(
    (nextPagination: NextPagination) => {
      const filterState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);

      refetchWaitReceipt({
        offset,
        limit,
        status: waitReceiptStatus[activeTab as TabsEnum],
        materialIds: filterState.materialId
          ? [filterState.materialId]
          : undefined,
        materialBatchIds: filterState.batchId
          ? [filterState.batchId]
          : undefined,
        workOrderIds: filterState?.workOrderId
          ? [filterState?.workOrderId]
          : undefined,
        shelfIds: filterState.locationIds?.map((row) => row.id),
        materialBarcodes: filterState.barcode
          ? [filterState.barcode]
          : undefined,
      });
    },
    [methods, refetchWaitReceipt, pageInfo, activeTab]
  );

  const modalGroupController = useModalGroupController([
    { name: Modals.Inspect },
    { name: LOCATION_SELECTOR },
    { name: Modals.DeleteWaitReceipt },
  ]);

  const openLocationSelectorModal = useCallback(
    () => modalGroupController.openModal(LOCATION_SELECTOR, null),
    []
  );

  const appendLocationIds = useCallback(
    (selectedLocationId?: string) => {
      const locationIds = methods.getValues('locationIds') ?? [];
      const idSet = new Set(locationIds.map((location) => location.id));
      if (selectedLocationId) idSet.add(selectedLocationId);

      methods.setValue(
        'locationIds',
        [...idSet].map((location) => ({ id: location, name: location }))
      );
    },
    [methods]
  );

  return (
    <>
      <ModalGroup {...modalGroupController}>
        <FormFieldsWrapper methods={methods}>
          <PageLayout>
            <Typography variant="h1">待入庫清單</Typography>
            <FilterScaffold
              mutateParams={() => {}}
              fetchAsParamsMutated={false}
            >
              <div className={filterClasses['filters']}>
                <BarcodeScanInput
                  disableSearchBtn
                  inputStyle={classes['barcode-scan-input']}
                  otherOnKeyDownAction={async (inputValue) => {
                    if (inputValue) {
                      handleMaterialIdBarcodeScan({
                        inputValue,
                        data,
                        modalGroupController,
                        refetchWaitReceiptByPagination,
                      });
                    }
                  }}
                  customOnchange={(e) => {
                    methods.setValue('barcode', e.target.value);
                  }}
                />
                <InputField
                  placeholder="請輸入料號"
                  registerName="materialId"
                />
                <InputField placeholder="請輸入批號" registerName="batchId" />
                <InputField
                  placeholder="請輸入工單號碼"
                  registerName="workOrderId"
                />
                <LocationIdsAutoField
                  methods={methods}
                  registerName="locationIds"
                  openModal={openLocationSelectorModal}
                />
              </div>
              <br />
              <Button
                type="button"
                variant="outlined"
                onClick={() => {
                  refetchWaitReceiptByPagination({ nextPage: 1 });
                }}
              >
                確認
              </Button>
            </FilterScaffold>
            <Tabs
              activeKey={activeTab || ''}
              onChange={(tabId) => {
                mutateWaitReceipt(undefined, { revalidate: false });
                setSearchParams(createSearchParams({ tab: String(tabId) }));
              }}
            >
              {tabsOptions.map((option) => (
                <TabPane key={option.id} tab={<Tab>{option.name}</Tab>}>
                  <option.panel
                    refetchWaitReceipt={refetchWaitReceiptByPagination}
                    mutateWaitReceipt={mutateWaitReceipt}
                    tableData={data}
                    pageInfo={pageInfo}
                    isLoading={isLoading}
                  />
                </TabPane>
              ))}
            </Tabs>
          </PageLayout>
        </FormFieldsWrapper>

        <LocationSelectorGroupModal showShelfSvg onSubmit={appendLocationIds} />
        <InspectModal
          mutate={mutateWaitReceipt}
          resetFilter={() => {
            methods.reset();
          }}
        />
        <DeleteWaitReceiptModal mutate={mutateWaitReceipt} />
      </ModalGroup>
    </>
  );
};
