import { TablePagination } from '@mezzanine-ui/core/table';
import { Button, Message, Popconfirm, Table } from '@mezzanine-ui/react';
import {
  checkJWTRoles,
  ISPSearchPageMode,
  SPPageInfo,
  SPReportState,
  SPSearchPageMode,
} from '@solar/data';
import { useState, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

interface ISPSearchTableProps {
  pageMode: ISPSearchPageMode;
  dataSource: any[];
  pageInfo: SPPageInfo;
  searchLoading: boolean;
  onPageChange: (page: number) => void;
  reportPriceState: SPReportState[];
  onDelete: (id: string) => void;
  onBack: (id: string) => void;
}

const SPSearchTable = ({
  pageMode,
  dataSource,
  pageInfo,
  onPageChange,
  searchLoading,
  reportPriceState,
  onDelete,
  onBack,
}: ISPSearchTableProps) => {
  const [onDeleteShowId, setOnDeleteShowId] = useState<string | null>(null);
  const [onDelAnchor, setOnDelAnchor] = useState<HTMLButtonElement | null>(
    null
  );
  const [onBackShowId, setOnBackShowId] = useState<string | null>(null);
  const [onBackAnchor, setOnBackAnchor] = useState<HTMLButtonElement | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  const onClickConfirmDel = async () => {
    if (onDeleteShowId) {
      setIsLoading(true);
      try {
        // find id by showId
        const item = dataSource.find((item) => item.showId === onDeleteShowId);
        if (item) {
          await onDelete(item.id);
          Message.success('已刪除');
          setOnDeleteShowId(null);
        }
      } catch (e: any) {
        Message.error(e.message);
      }
      setIsLoading(false);
    }
  };

  const onClickConfirmBack = async () => {
    if (onBackShowId) {
      setIsLoading(true);
      try {
        // find id by showId
        const item = dataSource.find((item) => item.showId === onBackShowId);
        if (item) {
          await onBack(item.id);
          Message.success('已取回');
          setOnBackShowId(null);
        }
      } catch (e: any) {
        Message.error(e.message);
      }
      setIsLoading(false);
    }
  };

  const onClickTableDel =
    (showId: any) => (e: MouseEvent<HTMLButtonElement>) => {
      setOnDelAnchor(e.currentTarget);
      setOnDeleteShowId(showId);
    };

  const onClickTableBack =
    (showId: any) => (e: MouseEvent<HTMLButtonElement>) => {
      setOnBackAnchor(e.currentTarget);
      setOnBackShowId(showId);
    };

  const actionRenderSearch = (record: any) => {
    const { reportState } = record;
    const stateMap = reportPriceState.find((item) => item.code === reportState);
    return (
      <div>
        {stateMap?.canBack && checkJWTRoles([111, 112]) && (
          <Button
            variant="text"
            onClick={onClickTableBack(record.showId)}
            disabled={isLoading}
          >
            取回
          </Button>
        )}
        {stateMap?.canMatch && checkJWTRoles([111, 112]) && (
          <Link to={`/strike-price/match/${record.id}`}>
            <Button variant="text">對應</Button>
          </Link>
        )}
        {stateMap?.canEdit && checkJWTRoles([111, 112]) && (
          <Link to={`/strike-price/edit/${record.id}`}>
            <Button variant="text" disabled={isLoading}>
              編輯
            </Button>
          </Link>
        )}
        {stateMap?.canDelete && checkJWTRoles([111, 112]) && (
          <Button
            variant="text"
            danger
            onClick={onClickTableDel(record.showId)}
            disabled={isLoading}
          >
            刪除
          </Button>
        )}
        {!stateMap?.canEdit &&
          !stateMap?.canMatch &&
          checkJWTRoles([111, 112]) && (
            <Link to={`/strike-price/view/${record.id}`}>
              <Button variant="text">檢視</Button>
            </Link>
          )}
      </div>
    );
  };

  const actionRenderApprove = (record: any) => {
    const { reportState } = record;
    const stateMap = reportPriceState.find((item) => item.code === reportState);
    return (
      <div>
        {stateMap?.canCheck && checkJWTRoles([111, 112]) ? (
          <Link to={`/strike-price/validate/${record.id}`}>
            <Button variant="text">審核</Button>
          </Link>
        ) : (
          <Link to={`/strike-price/view/${record.id}`}>
            <Button variant="text">檢視</Button>
          </Link>
        )}
      </div>
    );
  };

  const stateNameMap = reportPriceState.reduce((acc, cur) => {
    acc[cur.code] = cur.name;
    return acc;
  }, {} as any);

  const actionRender =
    pageMode === SPSearchPageMode.SEARCH
      ? actionRenderSearch
      : actionRenderApprove;

  const columns = [
    {
      title: '操作',
      render: actionRender,
    },
    {
      title: '單號',
      dataIndex: 'showId',
    },
    {
      title: '狀態',
      render: (record: any) => stateNameMap[record.reportState],
      renderTooltipTitle: (record: any) => stateNameMap[record.reportState],
    },
    {
      title: '客戶',
      render: (record: any) => `${record.customerId}-${record.customerName}`,
      renderTooltipTitle: (record: any) =>
        `${record.customerId}-${record.customerName}`,
    },
    {
      title: '業務',
      render: (record: any) => `${record.salesId}-${record.salesName}`,
      renderTooltipTitle: (record: any) =>
        `${record.salesId}-${record.salesName}`,
    },
    {
      title: '敲單日期',
      render: (record: any) =>
        record.reportDate
          ? moment(record.reportDate).format('YYYY/MM/DD HH:mm:ss')
          : '',
      renderTooltipTitle: (record: any) =>
        record.reportDate
          ? moment(record.reportDate).format('YYYY/MM/DD HH:mm:ss')
          : '',
    },
    {
      title: '成交日期',
      render: (record: any) =>
        record.dealDate ? moment(record.dealDate).format('YYYY/MM/DD') : '',
      renderTooltipTitle: (record: any) =>
        record.dealDate ? moment(record.dealDate).format('YYYY/MM/DD') : '',
    },
  ];
  const pagination: TablePagination = {
    current: pageInfo?.current || 1,
    total: pageInfo?.total || 0,
    onChange: onPageChange,
    disableAutoSlicing: true,
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        loading={searchLoading}
        scroll={{ x: 1100 }}
      />
      <Popconfirm
        open={!!onDeleteShowId}
        cancelButtonProps={{ disabled: isLoading }}
        confirmButtonProps={{
          danger: true,
          disabled: isLoading,
          loading: isLoading,
        }}
        title={`確定要刪除該筆資料? ID:${onDeleteShowId}`}
        cancelText="取消"
        confirmText="刪除"
        onConfirm={onClickConfirmDel}
        onCancel={() => setOnDeleteShowId(null)}
        anchor={onDelAnchor}
      />

      <Popconfirm
        open={!!onBackShowId}
        cancelButtonProps={{ disabled: isLoading }}
        confirmButtonProps={{ disabled: isLoading, loading: isLoading }}
        title={`確定要取回該筆資料? ID:${onBackShowId}`}
        cancelText="取消"
        confirmText="取回"
        onConfirm={onClickConfirmBack}
        onCancel={() => setOnBackShowId(null)}
        anchor={onBackAnchor}
      />
    </div>
  );
};

export default SPSearchTable;
