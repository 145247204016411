import {
  Message,
  ModalActions,
  SelectValue,
  Typography,
} from '@mezzanine-ui/react';
import {
  CheckboxField,
  FormFieldsDebug,
  FormFieldsWrapper,
  InputField,
} from '@mezzanine-ui/react-hook-form';
import { useMiscMaterialGroups } from '@solar/data';
import { FilterScaffold, RowSection, useTargetModal } from '@solar/templates';
import { useCallback } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { ModalLayout } from '../../../Material/ModalLayout/ModalLayout';
import { CustomDependentSelectors } from '../../../MaterialEnquiry/Filters/CustomDependentSelectors';
import { Modals } from '../types';
import { createMiscMaterial } from '../utils';

const THIS_FORM = 'CreateMaterial';

export const CreateMaterial = () => {
  const methods = useForm<{
    groups: SelectValue;
    categories: SelectValue;
    subCategories: SelectValue;
    description: string;
    unit: string;
    eraseOnPick: boolean;
  }>();
  const modalController = useTargetModal(Modals.CreateMaterial);

  const closeModal = useCallback(() => {
    modalController.closeModal();
    methods.reset();
  }, [methods, modalController]);

  const { miscMaterialGroups } = useMiscMaterialGroups();

  return (
    <ModalLayout
      modalHeader={<br />}
      loading={methods.formState.isSubmitting}
      {...modalController}
      closeModal={closeModal}
      modalFooter={
        <ModalActions
          confirmText="確認"
          cancelText="取消"
          confirmButtonProps={{ form: THIS_FORM }}
          onCancel={closeModal}
        />
      }
    >
      <FormFieldsWrapper
        id={THIS_FORM}
        methods={methods}
        onSubmit={async ({
          groups,
          categories,
          subCategories,
          description,
          eraseOnPick,
          unit,
        }) => {
          try {
            const res = await createMiscMaterial({
              payload: {
                groupId: groups.id,
                categoryId: categories.id,
                subCategoryId: subCategories?.id,
                description,
                // 高單價[checked] => eraseOnPick: false
                eraseOnPick: !eraseOnPick,
                unit,
              },
            });

            if (res.ok) {
              Message.success('更新成功');
              modalController.closeModal();
            }
          } catch (err) {
            if (err instanceof Error) {
              Message.error(JSON.parse(err.message).message);
            } else {
              Message.error('更新失敗');
            }
          }
        }}
      >
        <FilterScaffold>
          <FormFieldsDebug />
          <CustomDependentSelectors
            data={miscMaterialGroups}
            labelAndRegisterKeyArray={[
              { label: '群組：', registerName: 'groups' },
              {
                label: '大群組：',
                registerName: 'categories',
              },
              {
                label: '中類型：',
                registerName: 'subCategories',
              },
            ]}
            thisSelectorLevel={0}
          />
        </FilterScaffold>

        <RowSection label="品名：">
          <InputField registerName="description" />
        </RowSection>
        <RowSection label="單位：">
          <InputField registerName="unit" />
        </RowSection>
        <RowSection label="高單價：">
          <CheckboxField registerName="eraseOnPick" />
        </RowSection>
      </FormFieldsWrapper>
    </ModalLayout>
  );
};
