import {
  Modal,
  ModalBody,
  ModalHeader,
  Popper,
  Typography,
} from '@mezzanine-ui/react';
import { API_NAMESPACE, environment } from '@solar/data';
import { useEffect, useMemo, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import useSWR from 'swr';
import { PDFPreviewer } from '../../Templates';

interface FormFields {
  selectedDrawingId?: string;
}

export function useDrawingViewerPopup(
  methods: UseFormReturn<FormFields>
): [
  JSX.Element,
  React.Dispatch<React.SetStateAction<HTMLElement | null | undefined>>
] {
  const [anchorRef, setAnchorRef] = useState<HTMLElement | null>();
  const popperRef = useRef<HTMLDivElement>(null);

  const selectedDrawingId = methods.watch('selectedDrawingId');

  const { data } = useSWR<{ data: { id: string } }>(
    selectedDrawingId
      ? [
          `/drawings/${
            selectedDrawingId.startsWith('F') ? 'fcc' : 'tem'
          }/${selectedDrawingId}`,
          { namespace: API_NAMESPACE.DRAWINGS },
        ]
      : undefined
  );

  const drawingId = useMemo(() => data?.data?.id ?? null, [data]);

  const [fileLink, setFileLink] = useState<string | null>(null);

  useEffect(() => {
    if (!selectedDrawingId || !drawingId) return;

    let cancelled = false;

    fetch(
      `${environment.API_HOST}${API_NAMESPACE.DRAWINGS}/drawings/generateDrawingPresignedFileLinks`,
      {
        method: 'POST',
        body: JSON.stringify({
          bu: selectedDrawingId.startsWith('F') ? 'FCC' : 'TEM',
          ids: [drawingId],
        }),
        headers: {
          'Content-Type': 'application/json',
          apikey: environment.API_KEY,
          ...(localStorage.getItem('accessToken')
            ? {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              }
            : {}),
        },
      }
    )
      .then((res) => res.json())
      .then(
        ({
          data: { results },
        }: {
          data: { results: { id: number; presignedFileLink: string }[] };
        }) => {
          if (cancelled) return;

          setFileLink(results[0]?.presignedFileLink ?? null);
        }
      );

    return () => {
      cancelled = true;
    };
  }, [drawingId, selectedDrawingId]);

  // useEffect(() => {
  //   const onClick = ({ target }: MouseEvent) => {
  //     if (!target || popperRef.current?.contains(target as HTMLElement)) return;

  //     setAnchorRef(null);
  //   };

  //   if (anchorRef) {
  //     document.body.addEventListener('click', onClick, false);
  //   }

  //   return () => {
  //     document.body.removeEventListener('click', onClick, false);
  //   };
  // }, [anchorRef]);

  console.log(selectedDrawingId);

  const popper = useMemo(
    () => (
      <Modal
        style={{ width: 1000 }}
        open={!!selectedDrawingId}
        onClose={() => methods.setValue('selectedDrawingId', undefined)}
      >
        <ModalHeader>
          <Typography variant="h3">{selectedDrawingId}</Typography>
        </ModalHeader>
        <ModalBody>
          {fileLink && <PDFPreviewer fileLink={fileLink} />}
        </ModalBody>
      </Modal>
    ),
    [fileLink, methods, selectedDrawingId]
  );

  return [popper, setAnchorRef];
}
