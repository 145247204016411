import { Button, Typography } from '@mezzanine-ui/react';
import React from 'react';
import styles from './Header.module.scss';

export interface HeaderProps {
  title: string;
  button?: React.ReactElement;
}

export function Header({ title, button }: HeaderProps) {
  return (
    <div className={styles.wrapper}>
      <Typography variant="h1">{title}</Typography>
      {button}
    </div>
  );
}
