import { useEffect, useState, useRef, useDebugValue } from 'react';
import type { TreeGraph, TreeGraphData, Node, INode, IGraph } from '@ant-design/charts'
import useSWR from 'swr';
import { fetchUrl } from './endpoint';

// api endpoint
const UNITS_TREE = 'units/tree'

// type of api response data
type UnitsTreeType = {
  id: string | number;
  name: string;
  code: string;
  headCount: number;
  children: UnitsTreeType[];
};

// type of data transfer
type RecursiveUnitsTreeDataType = {
  id: string | number;
  label: string;
  description: string;
  children: RecursiveUnitsTreeDataType[];
};

// main
/**
 *  Flow: 
 *    node:click -> updateChildrenHandler() -> useGraphUpdateChildren() 
 */
export function useMembersTreeUpdate() {
  const root = { id: 'root', label: 'Solar', description: '', children: [] };
  const [graph, setGraph] = useState<TreeGraph | undefined>();
  const [node, setNode] = useState<Node | INode | undefined>();
  const [query, setQuery] = useState('');
  
  const url = fetchUrl(UNITS_TREE);
  const { data, isValidating } = useSWR([url, query], fetcher)
  useGraphUpdateChildren(data, query, graph, node);  // 資料插入 TreeGraph

  const updateChildrenHandler = useCreateUpdateChildrenHandler(setQuery, setGraph, setNode);
  const updateGraphHandler = useCreateUpdateGraphHandler();

  return {
    root,
    isValidating,
    updateChildrenHandler,  // 事件處理器：用來綁定 node:click 事件
    updateGraphHandler,
    collapseNodeHandler: useCreateCollapseNodeHandler(),
  };
}

function useCreateCollapseNodeHandler() {
  return function collapseNodeHandler(graph: TreeGraph | undefined) {
    if (!graph) return;
    const root = graph.findById('root') as Node;
    // FIXME: 目前還未找到取的形狀組合的 API，暫時寫 HardCode
    const marker = (root as any)._cfg.group?.cfg?.children[3];

    // 拋出點擊事件（收合）
    graph.emit('node:click', {
      item: root,
      target: marker,
    });
  }
}

function useCreateUpdateGraphHandler() {
  // api
  const { data, isValidating } = useSWR(fetchUrl('units/tree/all'), fetcher);
  // event handler
  return function updateGraphHandler(graph: TreeGraph | undefined) {
    if (!graph) return;
    // TODO: 加入收合功能
    (graph as any).updateChildren(children(data), 'root');

    function children(data: UnitsTreeType[]): any[] {
      return recursiveUnitsTree(data, 0, []);
    }
  }
}

// inner custom hook
function useGraphUpdateChildren(data: UnitsTreeType, query: string, graph: any, node: any) {
  useEffect(() => {
    // 檢查 API Response Data 類型
    if (!areUnitsTree(data)) return;
    // 檢查 Canvas Graph 是否已掛載
    if (!graphMounted(graph)) return;
    // 新增 Nodes 至 Graph
    graph?.updateChildren(children(data), parentId(query));
    // 視圖移動
    zoomToCurrentNode();
  }, [data, graph, node])

  // 內部函式 =====================================================================
  function graphMounted(graph: TreeGraph | undefined) {
    return graph != undefined;
  }
  function parentId(queryParam: string) { 
    let result = queryParam.split('=').pop();
    return (result == undefined || result === '') ? 'root' : result;
  }
  function children(data: UnitsTreeType[]): any[] {
    return recursiveUnitsTree(data, 0, []);
  }
  function zoomToCurrentNode() {
    graph.zoomTo(1.0, {x: node.getModel().x, y: node.getModel().y});
    graph.focusItem(node);
  }
}

function useCreateUpdateChildrenHandler(setQuery: any, setGraph: any, setNode: any) {
  return function updateChildren(node: INode, graph: IGraph) {
    if (hasChildren()) return;
    
    (isRoot()) ? setQuery('') : setQuery('&unitId=' + node.getID())

    attachGraph(graph);

    attachNode(node);
    // 內部函式 =====================================================================
    function hasChildren() {
      return node.getOutEdges().length > 0;
    }
    function isRoot() {
      return node.getID() === 'root';
    }
    function attachGraph(graph: IGraph) {
      setGraph(graph)
    }
    function attachNode(node: INode) {
      setNode(node);
    }
  }
}

// inner function
async function fetcher(url: string, queryParams: string = '') {
  const res = await fetch(`${url}${queryParams}`);
  const json = await res.json();

  return json ?? null;
};

function areUnitsTree(object: unknown): object is UnitsTreeType[] {
  if (object !== null && object instanceof Array && object.length != 0) {
    return object.every((data) => isUnitsTree(data))
  }
  return false;
};

function isUnitsTree(object: unknown): object is UnitsTreeType {
  if (object !== null && typeof object === 'object') {
    return 'id' in object && 'name' in object && 'code' in object && 'headCount' in object;
  }
  return false;
}

function recursiveUnitsTree(
  data: UnitsTreeType[],
  index: number,
  arr: RecursiveUnitsTreeDataType[]
): RecursiveUnitsTreeDataType[] {
  const current = data?.[index];
  if (!current) return arr;

  const newArr = [
    ...arr,
    {
      id: `${current.id}`,
      label: current.name,
      description: `人數：${current.headCount}`,
      children: recursiveUnitsTree(current.children, 0, []),
    },
  ] as RecursiveUnitsTreeDataType[];
  return recursiveUnitsTree(data, index + 1, newArr);
};
