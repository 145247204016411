import {
  Input,
  Option,
  Select,
  SelectValue,
  Typography,
} from '@mezzanine-ui/react';
import { Space } from 'antd';
import Decimal from 'decimal.js';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface QuantityWithUnitsFieldProps {
  stockUnitQuantityRegisterName?: string;
  inputUnitQuantityRegisterName?: string;
  materialStockUnit: string;
  materialInputUnit: string;
  stockOverInputUnitRatio: string;
}

/**
 * @description
 * 有兩種單位：
 * 1. stockUnit（基礎計量單位）：SAP 溝通用的單位
 * 2. inputUnit（管理單位）：使用者使用的單位
 *
 * 使用情境：
 * 使用者可以輸入數量，並選擇單位，component 會換算
 * 如果有帶入 inputUnitQuantityRegisterName，則會將 inputUnitQuantity 寫入，沒帶就不寫入，stockUnitQuantityRegisterName 亦然
 */

export function QuantityWithUnitsField(props: QuantityWithUnitsFieldProps) {
  const {
    stockUnitQuantityRegisterName,
    inputUnitQuantityRegisterName,
    materialInputUnit,
    materialStockUnit,
    stockOverInputUnitRatio,
  } = props;

  const methods = useFormContext();

  const [quantity, setQuantity] = useState<string>('');

  const [selectedUnit, setSelectedUnit] = useState<SelectValue>({
    id: 'stockUnit',
    name: materialStockUnit,
  });

  useEffect(() => {
    switch (selectedUnit.id) {
      case 'stockUnit': {
        if (stockUnitQuantityRegisterName)
          methods.setValue(stockUnitQuantityRegisterName, quantity);
        if (inputUnitQuantityRegisterName)
          methods.setValue(
            inputUnitQuantityRegisterName,
            new Decimal(quantity).div(stockOverInputUnitRatio).toString()
          );
        break;
      }
      case 'inputUnit': {
        if (inputUnitQuantityRegisterName)
          methods.setValue(inputUnitQuantityRegisterName, quantity);
        if (stockUnitQuantityRegisterName)
          methods.setValue(
            stockUnitQuantityRegisterName,
            new Decimal(quantity).mul(stockOverInputUnitRatio).toString()
          );
        break;
      }
      default:
        break;
    }
  }, [
    methods,
    quantity,
    selectedUnit,
    stockOverInputUnitRatio,
    inputUnitQuantityRegisterName,
    stockUnitQuantityRegisterName,
  ]);

  return (
    <Space direction="vertical">
      <Typography variant="h6" color="secondary">
        數量：
      </Typography>
      <Space>
        <Input
          value={quantity}
          onChange={(e) => {
            setQuantity(e.target.value);
          }}
        />
        <Select
          required
          mode="single"
          value={selectedUnit}
          onChange={(value) => {
            setSelectedUnit(value);
          }}
        >
          <Option value="stockUnit">{materialStockUnit}</Option>
          <Option value="inputUnit">{materialInputUnit}</Option>
        </Select>
      </Space>
    </Space>
  );
}
