import {
  AutoComplete,
  Button,
  MenuDivider,
  Message,
  SelectValue,
  Table,
} from '@mezzanine-ui/react';
import { useGetSalesOrders } from '@solar/data';
import { useCallback, useMemo, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { BindingFormValues } from '../typing';
import { Space } from 'antd';
import { isOneCharDifferent } from '../utils';
import { VirtualInTypeSelector } from './VirtualInTypeSelector';
import { VirtualInOrderIdSelector } from './VirtualInOrderIdSelector';
import { VirtualInOrderItemIdSelector } from './VirtualInOrderItemIdSelector';
import { VirtualInMaterialId } from './VirtualInMaterialId';

export function SalesOrderItemsSelector({
  virtualOutDeliveryOrderItemId,
  virtualOutDeliveryOrderItemMaterialId,
}: {
  virtualOutDeliveryOrderItemId: string;
  virtualOutDeliveryOrderItemMaterialId: string;
}) {
  const methods = useFormContext<BindingFormValues>();
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: `${virtualOutDeliveryOrderItemId}.receivedItems`,
  });

  const [appending, setAppending] = useState(false);

  const watchedItem = useWatch({
    control: methods.control,
    name: `${virtualOutDeliveryOrderItemId}`,
  });

  const [selectedOrder, setSelectedOrder] = useState<SelectValue | null>();
  const [selectedOrderItem, setSelectedOrderItem] =
    useState<SelectValue | null>();

  const { salesOrders, refetchSalesOrders } = useGetSalesOrders();

  const ordersFilterBySalesOrderId = useMemo(() => {
    return salesOrders
      ?.find((order) => order.id === selectedOrder?.id)
      ?.orderDetails.find(
        (detail) => detail.salesOrderLine === selectedOrderItem?.id
      );
  }, [salesOrders, selectedOrder, selectedOrderItem]);

  const salesOrdersOptions = useMemo(() => {
    return salesOrders.map((order) => ({
      id: order?.id,
      name: order?.id,
    }));
  }, [salesOrders]);

  const salesOrderLinesOptions = useMemo(() => {
    return (
      salesOrders
        ?.find((order) => order.id === selectedOrder?.id)
        ?.orderDetails.map((detail) => ({
          id: detail.salesOrderLine,
          name: detail.salesOrderLine,
        })) ?? []
    );
  }, [salesOrders, selectedOrder]);

  const onSalesOrderChange = useCallback(
    (order: SelectValue | null) => {
      setSelectedOrder(order);
      if (order) {
        const targetSalesOrder = salesOrders?.find((o) => o.id === order?.id);
        if (!selectedOrderItem && !!targetSalesOrder?.orderDetails?.[0]) {
          setSelectedOrderItem({
            id: targetSalesOrder.orderDetails[0]?.salesOrderLine,
            name: targetSalesOrder.orderDetails[0]?.salesOrderLine,
          });
        }
      } else {
        setSelectedOrderItem(null);
      }
    },
    [salesOrders, selectedOrderItem]
  );

  const onSalesOrderSearch = useCallback(
    (searchTerm: string) => {
      refetchSalesOrders({
        orderIds: searchTerm,
      });
    },
    [refetchSalesOrders]
  );

  const onAppend = useCallback(() => {
    try {
      setAppending(true);
      if (!selectedOrder?.id) {
        Message.error('請選擇收料訂單');
        return;
      }

      if (!selectedOrderItem?.id) {
        Message.error('請選擇收料訂單項次');
        return;
      }

      const isExist = fields.find((field) => {
        return (
          field.relatedOrderId === selectedOrder?.id &&
          field.relatedOrderItemId === selectedOrderItem?.id
        );
      });

      if (isExist) {
        Message.error('此收料訂單項次已存在在列表中');
        return;
      }

      if (!ordersFilterBySalesOrderId?.materialId) {
        Message.error(
          `此收料訂單項次(${selectedOrder?.id}-${selectedOrderItem?.id})無料號`
        );
        return;
      }

      // 檢查此收料訂單項次之料號是否與虛出出貨單之料號長度相同且只差一個字元
      // if (
      //   ordersFilterBySalesOrderId?.materialId.length !==
      //   virtualOutDeliveryOrderItemMaterialId.length
      // ) {
      //   Message.error(
      //     `此收料訂單料號(${ordersFilterBySalesOrderId?.materialId})之料號長度與虛出出貨單之料號(${virtualOutDeliveryOrderItemMaterialId})長度不同`
      //   );
      //   return;
      // }

      // if (
      //   !isOneCharDifferent(
      //     ordersFilterBySalesOrderId?.materialId,
      //     virtualOutDeliveryOrderItemMaterialId
      //   )
      // ) {
      //   Message.error(
      //     `此收料訂單料號(${ordersFilterBySalesOrderId?.materialId})之料號與虛出出貨單之料號(${virtualOutDeliveryOrderItemMaterialId})只能差一個字元`
      //   );
      //   return;
      // }

      append({
        relatedOrderId: selectedOrder?.id,
        relatedOrderItemId: selectedOrderItem?.id,
        relatedOrderMaterialId: ordersFilterBySalesOrderId?.materialId,
      });

      setSelectedOrder(null);
      setSelectedOrderItem(null);
    } catch (error) {
      console.error(error);
    } finally {
      setAppending(false);
    }
  }, [
    append,
    fields,
    ordersFilterBySalesOrderId,
    selectedOrder,
    selectedOrderItem,
  ]);

  return (
    <Space
      direction="vertical"
      style={{
        padding: 12,
        width: 1000,
      }}
    >
      <Table
        dataSource={[
          Object.assign(
            {
              id: virtualOutDeliveryOrderItemId,
            },
            watchedItem
          ),
        ]}
        scroll={{ x: 1000 }}
        columns={[
          {
            width: 200,
            title: '虛入種類',
            render: () => (
              <VirtualInTypeSelector
                virtualOutDeliveryOrderItemId={virtualOutDeliveryOrderItemId}
              />
            ),
          },
          {
            width: 200,
            title: '虛入單號',
            render: () => (
              <VirtualInOrderIdSelector
                virtualOutDeliveryOrderItemId={virtualOutDeliveryOrderItemId}
              />
            ),
          },
          {
            width: 200,
            title: '虛入單號項次',
            render: () => (
              <VirtualInOrderItemIdSelector
                virtualOutDeliveryOrderItemMaterialId={
                  virtualOutDeliveryOrderItemMaterialId
                }
                virtualOutDeliveryOrderItemId={virtualOutDeliveryOrderItemId}
              />
            ),
          },
          {
            title: '虛入料號',
            render: () => (
              <VirtualInMaterialId
                virtualOutDeliveryOrderItemId={virtualOutDeliveryOrderItemId}
              />
            ),
          },
        ]}
      />
      <MenuDivider />
      <Space>
        <AutoComplete
          placeholder="輸入收料訂單單號"
          value={selectedOrder}
          options={salesOrdersOptions ?? []}
          onChange={onSalesOrderChange}
          onSearch={onSalesOrderSearch}
        />
        <AutoComplete
          value={selectedOrderItem}
          onChange={setSelectedOrderItem}
          options={salesOrderLinesOptions ?? []}
        />
        <Button loading={appending} onClick={onAppend}>
          新增收料訂單
        </Button>
      </Space>
      <Table
        dataSource={fields ?? []}
        scroll={{ x: 1000 }}
        columns={[
          {
            width: 200,
            title: '操作',
            render: (source, index) => (
              <Space>
                <Button danger onClick={() => remove(index)}>
                  刪除
                </Button>
              </Space>
            ),
          },
          {
            width: 200,
            title: '收料訂單',
            dataIndex: 'relatedOrderId',
          },
          {
            width: 200,
            title: '收料訂單項次',
            dataIndex: 'relatedOrderItemId',
          },
          {
            title: '收料訂單料號',
            dataIndex: 'relatedOrderMaterialId',
          },
        ]}
      />
    </Space>
  );
}
