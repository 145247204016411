import { TableColumn } from '@mezzanine-ui/core/table';
import { Button, Typography } from '@mezzanine-ui/react';
import { LMBRecord } from '@solar/data';

import { useMemo } from 'react';

const diffValueRenderer = ({
  beforeValue,
  afterValue,
}: {
  beforeValue?: string | null;
  afterValue?: string | null;
}) => {
  if (beforeValue === afterValue || !beforeValue || !afterValue) {
    return (
      <div>
        <Typography>{String(beforeValue)}</Typography>
        <div style={{ border: '1px solid black' }}> </div>
        <Typography>{String(afterValue)}</Typography>
      </div>
    );
  }

  return (
    <div>
      <Typography>{beforeValue}</Typography>
      <div style={{ border: '1px solid black' }}> </div>
      <Typography color="error">{afterValue}</Typography>
    </div>
  );
};

export const useColumns = () => {
  const columns: TableColumn<LMBRecord>[] = useMemo(
    () => [
      // {
      //   title: '操作',
      //   width: 120,
      //   align: 'start' as const,
      //   render(source) {
      //     return (
      //       <Button type="button" variant="outlined" onClick={() => {}}>
      //         檢視
      //       </Button>
      //     );
      //   },
      // },
      {
        title: '來源單據類型',
        dataIndex: 'sourceOrderType',
      },
      {
        title: '來源單據 id',
        dataIndex: 'sourceOrderId',
      },
      {
        title: '異動類型',
        dataIndex: 'moveType',
      },
      {
        title: '異動時間',
        dataIndex: 'recordCreatedAt',
      },
      {
        title: '料號',
        render: (source) => {
          return diffValueRenderer({
            beforeValue: source?.beforeMaterialId,
            afterValue: source?.afterMaterialId,
          });
        },
      },
      {
        title: '母批號',
        render: (source) => {
          return diffValueRenderer({
            beforeValue: source?.beforeMaterialSapBatchId,
            afterValue: source?.afterMaterialSapBatchId,
          });
        },
      },
      {
        title: '子批號',
        render: (source) => {
          return diffValueRenderer({
            beforeValue: source?.beforeMaterialSubBatchId,
            afterValue: source?.afterMaterialSubBatchId,
          });
        },
      },
      {
        title: '載具',
        render: (source) => {
          return diffValueRenderer({
            beforeValue: source?.beforeLoaderId,
            afterValue: source?.afterLoaderId,
          });
        },
      },
      {
        title: '儲存位置',
        render: (source) => {
          return diffValueRenderer({
            beforeValue: source?.beforeLocationId,
            afterValue: source?.afterLocationId,
          });
        },
      },
      {
        title: 'Barcode',
        render: (source) => {
          return diffValueRenderer({
            beforeValue: source?.beforeBarcode,
            afterValue: source?.afterBarcode,
          });
        },
      },
      {
        title: '數量',
        render: (source) => {
          return diffValueRenderer({
            beforeValue: source?.beforeQuantity,
            afterValue: source?.afterQuantity,
          });
        },
      },
      {
        title: '實際重量',
        render: (source) => {
          return diffValueRenderer({
            beforeValue: source?.beforeActualWeightQuantity,
            afterValue: source?.afterActualWeightQuantity,
          });
        },
      },
      {
        title: '庫存類型',
        render: (source) => {
          return diffValueRenderer({
            beforeValue: source?.beforeStockType,
            afterValue: source?.afterStockType,
          });
        },
      },
      {
        title: '封存時間',
        render: (source) => {
          return diffValueRenderer({
            beforeValue: source?.beforeDeletedAt,
            afterValue: source?.afterDeletedAt,
          });
        },
      },
    ],
    []
  );
  return columns;
};
