import type { HubOrdersData } from '@solar/data';
import { useState } from 'react';
import { PageLayout } from '@solar/templates';
import { Table } from '@mezzanine-ui/react';
import {
  useHubOrdersGet,
  useHubOrdersFilter,
  checkJWTRoles,
} from '@solar/data';
import { ActionDetailModalButton } from './ActionDetailModalButton';
import { FilterPanelForm } from './FilterPanelForm';
import { OrderIdInput } from './FilterPanelForm/OrderIdInput';
import { CustomerIdAutoComplete } from './FilterPanelForm/CustomerIdAutoComplete';
import { DebitConfirmRadioGroup } from './FilterPanelForm/DebitConfirmRadioGroup';
import { DebitDatePicker } from './FilterPanelForm/DebitDatePicker';
import { ShipDatePicker } from './FilterPanelForm/ShipDatePicker';
// import { ReverseDatePicker } from './ReverseDatePicker';

export function HubReceivingOrderPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { formMethods, filterCondition, updateFilterCondition } =
    useHubOrdersFilter();
  const { hubOrdersData, hubOrdersMeta, refetechHubOrders } = useHubOrdersGet({
    page: currentPage,
    perPage: perPage,
    type: 'RECEIVING',
    ...filterCondition,
  });

  const tableColumns = [
    { title: '出貨單編號', dataIndex: 'id', width: 200 },
    { title: '出貨單日期', dataIndex: 'shipDate', width: 150 },
    { title: '客戶名稱', dataIndex: 'customerName', width: 600 },
    { title: '客戶編號', dataIndex: 'customerId', width: 150 },
    { title: '建立者', dataIndex: 'memberName', width: 150 },
    { title: '扣帳日期', dataIndex: 'debitDate', width: 150 },
    {
      title: '操作',
      width: 200,
      render: (source: HubOrdersData, index: number) => {
        return (
          checkJWTRoles([150]) && (
            <ActionDetailModalButton hubDeliveryOrderId={source.id} />
          )
        );
      },
    },
  ];

  return (
    <PageLayout title="HUB倉收貨">
      <FilterPanelForm
        formMethods={formMethods}
        applyFilterCondition={() => {
          updateFilterCondition({
            onFilterConditionNoChange: refetechHubOrders,
            onUpdateSuccess: () => setCurrentPage(1),
          });
        }}
      >
        <OrderIdInput />
        <CustomerIdAutoComplete />
        <DebitConfirmRadioGroup />
        <ShipDatePicker />
        <DebitDatePicker />
      </FilterPanelForm>
      <Table
        columns={tableColumns}
        dataSource={hubOrdersData}
        pagination={{
          disableAutoSlicing: true,
          total: hubOrdersMeta.total,
          current: currentPage,
          onChange: (page) => {
            setCurrentPage(page);
          },
          options: {
            pageSize: perPage,
            pageSizeLabel: '每頁顯示：',
            pageSizeUnit: '筆',
            onChangePageSize: (pageSize) => {
              setPerPage(pageSize);
              setCurrentPage(1);
            },
            renderPageSizeOptionName: (p) => `${p}`,
            showPageSizeOptions: true,
            showJumper: true,
            jumperButtonText: '前往',
            jumperHintText: '跳至',
          },
        }}
      />
    </PageLayout>
  );
}
