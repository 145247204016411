import {
  InventoryByMaterialSearchType,
  InventoryItem,
  PageInfo,
} from '@solar/data';

export type MaterialStock = InventoryItem;

export type MaterialIdUpdateForm = {
  pageInfo: PageInfo;
  searchTerm: string;
} & {
  searchType: InventoryByMaterialSearchType;
  records: MaterialStock[];
};

export enum Modals {
  EDIT = 'EDIT',
}

export interface ColumnHooks {
  openEditModal: (data: MaterialIdTransferObj) => void;
}

export type MaterialIdTransferObj = {
  materialId: string;
  materialDescription?: string;
  sapBatchId: string;
  subBatchId: string;
  loaderId: string;
  newMaterialId: string;
};
