import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { FilterDataType, isOption, Modal, ModalBody } from '../mainFile';
import { useOrderMarks } from './useOrderMarks';
import { useOrderMarksFilters } from './useOrderMarksFilters';

export const isOptionObject = (object: unknown): object is FilterDataType => {
  if (object && typeof object === 'object') return 'id' in object;
  return false;
};

type Title = '新增' | '編輯';

export function useOrderMarksModal(methods: UseFormReturn<FieldValues, any>) {
  const { data: orderMarksFiltersData } = useOrderMarksFilters();
  const { createMutation, updateMutation, deleteMutation } = useOrderMarks();

  const [deleteModal, setDeleteModal] = useState<any>(null);
  const [modal, setModal] = useState<Modal<Title>>(null);
  const [disableConfirm, setDisableConfirm] = useState(true);

  const createModal = useMemo(
    () => [
      {
        label: '客戶',
        required: true,
        fn: 'EnhanceSelect',
        componentsProps: {
          registerName: 'customers',
          mode: 'single',
          width: 172,
          options: orderMarksFiltersData?.customers ?? [],
          defaultValue:
            orderMarksFiltersData?.customers.find(
              (customer) => customer.id === modal?.source?.customerId
            ) ?? null,
          clearable: true,
          placeholder: '請選擇',
        },
      },
      {
        label: '嘜頭名稱',
        required: true,
        fn: 'EnhanceModalSearchInput',
        registerName: 'productName',
        componentsProps: {
          registerName: 'productName',
          width: 172,
          placeholder: '請輸入',
          register: methods.register,
          setValue: methods.setValue,
          name: 'productName',
          defaultValue: modal?.source?.name,
        },
      },
      {
        label: '嘜頭尺寸 (長x寬 cm)',
        required: true,
        fn: 'EnhanceModalSearchInput',
        registerName: 'size',
        componentsProps: {
          registerName: 'size',
          register: methods.register,
          setValue: methods.setValue,
          getValues: methods.getValues,
          name: 'size',
          placeholder: '請輸入',
          defaultValue: modal?.source?.size,
        },
      },
      {
        required: false,
        fn: 'Br',
      },
      {
        label: '嘜頭料號',
        required: false,
        fn: 'EnhanceSelect',
        componentsProps: {
          registerName: 'materialId',
          mode: 'multiple',
          width: 700,
          fullWidth: true,
          style: { width: '100%' },
          options: orderMarksFiltersData?.customerMaterials ?? [],
          defaultValue:
            modal?.source?.customerMaterialNumbers.reduce(
              (acc: FilterDataType[], cur: string) => [
                ...acc,
                orderMarksFiltersData?.customerMaterials?.find(
                  (item) => item.name === cur
                ),
              ],
              []
            ) ?? [],
          clearable: true,
          placeholder: '請選擇',
        },
      },
    ],
    [orderMarksFiltersData, modal]
  ) as ModalBody[];

  const updateModal = useMemo(() => {
    const filterData = createModal.map((item) =>
      item.fn === 'Border' ? { ...item, fn: 'Br' } : item
    );
    const map = filterData.map((item) => ({
      ...item,
      componentsProps: { ...item.componentsProps, clearable: false },
    }));
    return map;
  }, [createModal]) as ModalBody[];

  const closeModal = useCallback(() => setModal(null), []);

  const confirmModal = useCallback(
    async (methods: UseFormReturn<FieldValues, any>) => {
      const data = methods.watch();

      const objectKeys = Object.keys(data);

      const mapping = [
        { body: 'customerId', name: 'customers' },
        { body: 'name', name: 'productName' },
        { body: 'size', name: 'size' },
        { body: 'customerMaterials', name: 'materialId' },
      ];

      const reduceData = objectKeys.reduce((acc, cur) => {
        if (isOptionObject(data[cur]))
          return {
            ...acc,
            [mapping.find((item) => item.name === cur)?.body ?? '']:
              data[cur]?.id,
          };
        if (isOption(data[cur]))
          return {
            ...acc,
            [mapping.find((item) => item.name === cur)?.body ?? '']: data[
              cur
            ]?.map((item: FilterDataType) => item?.id),
          };
        return {
          ...acc,
          [mapping.find((item) => item.name === cur)?.body ?? '']: data[cur],
        };
      }, {});

      let response;

      if (modal?.source) {
        response = await updateMutation(modal?.source?.id, reduceData);
      } else {
        response = await createMutation(reduceData);
      }

      closeModal();

      return response;
    },
    [closeModal, modal]
  );

  const deleteModalMutation = useCallback(
    (id: string) => {
      deleteMutation(id);

      setDeleteModal(null);
      closeModal();
    },
    [closeModal]
  );

  useEffect(() => {
    methods.watch();
    const requiredFields = createModal.filter(
      (field) => field.required == true
    );

    let disabled = false;

    requiredFields.forEach((field) => {
      const registerName = field.componentsProps.registerName;
      const values = methods.getValues(registerName);

      if (!values || (typeof values === 'string' && values.trim() === ''))
        disabled = true;

      setDisableConfirm(disabled);
    });
  }, [createModal, methods.watch()]);

  return {
    deleteModal,
    setDeleteModal,
    modal,
    setModal,
    disableConfirm,
    closeModal,
    createModal,
    updateModal,
    confirmModal,
    deleteModalMutation,
  };
}
