import { UseFormReturn } from 'react-hook-form';
import { ProductionFormCreatorFormFields } from '../typings';
import useSWR from 'swr';
import { useMemo } from 'react';
import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';

export function useAutoCompleteWorkCenterField({
  methods,
  enabled,
}: {
  methods: UseFormReturn<ProductionFormCreatorFormFields>;
  enabled?: boolean;
}): JSX.Element {
  const { data } = useSWR<{ id: string; name: string }[]>(
    enabled ? [`/utils/work-centers`] : undefined
  );

  const workCenters = useMemo(
    () =>
      (data ?? []).map((workCenter) => ({
        id: workCenter.id,
        name: `${workCenter.id} ${workCenter.name}`,
      })),
    [data]
  );

  return (
    <AutoCompleteField
      options={workCenters}
      label="工作中心"
      placeholder="請選擇工作中心"
      registerName="workCenterId"
    />
  );
}
