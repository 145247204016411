import { useState } from 'react';
import useSWR from 'swr';
import {
  GetMaterialResolvesParams,
  ParseMaterialIdParams,
} from './material-resolves.type';
import { API_NAMESPACE, request } from '../request';
import { PageInfo } from '../pagination';

const namespace = API_NAMESPACE.MATERIALS;

export function useGetMaterialResolves() {
  const [params, setParams] = useState<GetMaterialResolvesParams>();
  const { data, mutate, error } = useSWR<{
    records: {
      materialId: string;
      description: string;
      oldMaterialId: string;
    }[];
    pageInfo: PageInfo;
  }>([`/materials/material-ids-by-resolves`, { params, namespace }]);

  return {
    materialResolves: data?.records,
    pageInfo: data?.pageInfo,
    isLoading: !error && !data,
    mutateGetMaterialResolves: mutate,
    refetchGetMaterialResolves: setParams,
  };
}

export function resolveMaterialId({ id }: { id: string }) {
  return request('/materials/material-resolves', {
    method: 'get',
    namespace,
    params: { id },
  }) as Promise<ParseMaterialIdParams>;
}
