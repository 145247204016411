import { MinusCircleOutlined, RedoOutlined } from '@ant-design/icons';
import { TableColumn } from '@mezzanine-ui/core/table';
import { Button } from '@mezzanine-ui/react';
import { MaterialRequestOrderSpec } from '@solar/data';
import { useMemo } from 'react';
import { fieldNamingMapping } from '../BasicInfoPanel';
import { PreviewInputHook } from '../types';

export const useInputNeedsColumn = ({
  methods,
  tableRef,
}: Pick<PreviewInputHook, 'methods'> & {
  tableRef: React.RefObject<HTMLTableElement>;
}) => {
  const column = useMemo<TableColumn<MaterialRequestOrderSpec>[]>(
    () => [
      {
        title: '',
        width: 100,
        render: (source, index) => (
          <div>
            <Button
              type="button"
              prefix={<RedoOutlined style={{ fontSize: '150%' }} />}
              onClick={() => {
                const targetBodyRow = (tableRef?.current?.querySelectorAll(
                  'tbody > tr'
                ) ?? [])[index];

                targetBodyRow?.setAttribute('strike-through', 'false');

                methods.setValue(
                  `tempMaterialRequestOrderSpecs.${index}.deleted`,
                  false
                );
              }}
            />
            <Button
              type="button"
              prefix={<MinusCircleOutlined style={{ fontSize: '150%' }} />}
              onClick={() => {
                const targetBodyRow = (tableRef?.current?.querySelectorAll(
                  'tbody > tr'
                ) ?? [])[index];

                targetBodyRow?.setAttribute('strike-through', 'true');

                methods.setValue(
                  `tempMaterialRequestOrderSpecs.${index}.deleted`,
                  true
                );
              }}
            />
          </div>
        ),
      },
      {
        title: '品名',
        dataIndex: 'materialDescription',
        width: 500,
      },
      {
        title: '料號',
        dataIndex: 'materialId',
        ellipsis: false,
      },
      {
        title: '批號',
        width: 200,
        render: (source) =>
          `${source?.materialSapBatchId ?? ''}-${source?.materialSubBatchId}`,
      },
      {
        title: '預期數量',
        dataIndex: 'expectedMaterialStockUnitQuantity',
        width: 100,
      },
      {
        title: fieldNamingMapping['unit'],
        dataIndex: 'stockUnit',
        width: 100,
      },
    ],
    [methods, tableRef]
  );

  return column;
};
