import { Popper } from '@mezzanine-ui/react';
import {
  CurrentLocation,
  LocationLevel,
  useLocationsBySearchTerm,
} from '@solar/data';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { LocationSVGViewer } from '../../Equipment/LocationSelector/LocationSVGViewer';
import useSWR from 'swr';

interface FormFields {
  selectedChartId?: string;
}

export function useLocationViewerPopup(
  methods: UseFormReturn<FormFields>
): [
  JSX.Element,
  React.Dispatch<React.SetStateAction<HTMLElement | null | undefined>>
] {
  const selectedChartId = methods.watch('selectedChartId');

  const [anchorRef, setAnchorRef] = useState<HTMLElement | null>();
  const { locations } = useLocationsBySearchTerm({
    searchText: selectedChartId ?? '',
  });
  const targetLocation = locations.find(
    (location) => location?.id === selectedChartId
  ) ?? { id: selectedChartId, level: 'stack' };

  const currentLocation: CurrentLocation & { filename: string } =
    useMemo(() => {
      const factoryId = targetLocation?.id?.slice(0, 4) ?? '';
      const buildingId = targetLocation?.id?.slice(0, 5) ?? '';
      const floorId = targetLocation?.id?.slice(0, 6) ?? '';
      const areaId = targetLocation?.id?.slice(0, 7) ?? '';
      const zoneId = targetLocation?.id?.slice(0, 8) ?? '';

      switch (targetLocation?.level) {
        case 'factory':
          return {
            factory: { id: factoryId, name: '' },
            filename: factoryId,
          };

        case 'building':
          return {
            factory: { id: factoryId, name: '' },
            building: { id: buildingId, name: '' },
            filename: factoryId,
          };

        case 'floor':
          return {
            factory: { id: factoryId, name: '' },
            building: { id: buildingId, name: '' },
            floor: { id: floorId, name: '' },
            filename: floorId,
          };

        case 'area':
          return {
            factory: { id: factoryId, name: '' },
            building: { id: buildingId, name: '' },
            floor: { id: floorId, name: '' },
            area: { id: areaId, name: '' },
            filename: areaId,
          };

        case 'zone':
          return {
            factory: { id: factoryId, name: '' },
            building: { id: buildingId, name: '' },
            floor: { id: floorId, name: '' },
            area: { id: areaId, name: '' },
            zone: { id: zoneId, name: '' },
            filename: areaId,
          };

        default:
          return {
            factory: { id: factoryId, name: '' },
            building: { id: buildingId, name: '' },
            floor: { id: floorId, name: '' },
            area: { id: areaId, name: '' },
            zone: { id: zoneId, name: '' },
            filename: areaId,
          };
      }
    }, [targetLocation?.id, targetLocation?.level]);

  const responseParser = useCallback((res: Response) => {
    if (!res.ok) return res.json();

    return res.text();
  }, []);

  const { data } = useSWR(
    currentLocation.filename
      ? [`/svgs/${currentLocation.filename}.svg`, { responseParser }]
      : null
  );

  const popperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onClick = ({ target }: MouseEvent) => {
      if (!target || popperRef.current?.contains(target as HTMLElement)) return;

      setAnchorRef(null);
    };

    if (anchorRef) {
      document.body.addEventListener('click', onClick, false);
    }

    return () => {
      document.body.removeEventListener('click', onClick, false);
    };
  }, [anchorRef]);

  const popper = useMemo(
    () => (
      <Popper
        ref={popperRef}
        anchor={anchorRef}
        open={Boolean(anchorRef)}
        onMouseLeave={() => setAnchorRef(null)}
        style={{
          border: '1px solid #000',
          borderRadius: '5px',
          backgroundColor: '#fff',
          boxShadow: '0 2px 12px rgba(0, 0, 0, 0.12)',
        }}
      >
        <LocationSVGViewer
          svg={data}
          width="540px"
          height="50vh"
          currentLevel={LocationLevel.ZONE}
          currentLocation={currentLocation}
          selectedChartId={selectedChartId}
        />
      </Popper>
    ),
    [anchorRef, currentLocation, data, selectedChartId]
  );

  return [popper, setAnchorRef];
}
