import { SelectField } from '@mezzanine-ui/react-hook-form';
import { BindingFormValues, VirtualInType } from '../typing';
import { useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { SelectValue } from '@mezzanine-ui/react';

export function VirtualInTypeSelector({
  virtualOutDeliveryOrderItemId,
}: {
  virtualOutDeliveryOrderItemId: string;
}) {
  const { control, getValues, setValue } = useFormContext<BindingFormValues>();

  const watchedRelatedCategory = useWatch({
    control: control,
    name: `${virtualOutDeliveryOrderItemId}.relatedCategory`,
  });

  useEffect(() => {
    if (!watchedRelatedCategory?.id) {
      setValue(`${virtualOutDeliveryOrderItemId}.relatedCategory`, {
        id: VirtualInType.DELIVERY_ORDER,
        name: '虛入出貨單',
      });
    }
  }, [setValue, virtualOutDeliveryOrderItemId, watchedRelatedCategory?.id]);

  const onTypeChange = useCallback(
    (selectValue: SelectValue<VirtualInType>) => {
      const values = getValues();

      Object.entries(values).forEach(([key]) => {
        setValue(`${key}.relatedCategory`, selectValue);
        setValue(`${key}.relatedOrderId`, null);
        setValue(`${key}.relatedOrderItemId`, null);
        setValue(`${key}.relatedOrderMaterialId`, null);
      });
    },
    [getValues, setValue]
  );

  return (
    <SelectField
      registerName={`${virtualOutDeliveryOrderItemId}.relatedCategory`}
      options={[
        { id: VirtualInType.DELIVERY_ORDER, name: '虛入出貨單' },
        { id: VirtualInType.INVENTORY_PURCHASE_ORDER, name: '採購單' },
      ]}
      onChange={onTypeChange}
    />
  );
}
