import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Select, Table, Typography } from "@mezzanine-ui/react";
import { UseModalControllerReturn } from "@solar/data";
import styles from './material-transfer-modal.module.scss';
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import { useEffect, useState } from "react";
import { InputNumber } from "antd";
import { FormFieldsWrapper } from "@mezzanine-ui/react-hook-form";
import { SelectedMaterialBatchList } from "./SelectedMaterialBatchList";

type SplitMaterialBatchModalProps = UseModalControllerReturn<any> & {
  onSubmit: (payload: any) => void,
}

export function SplitMaterialBatchModal({ open, data, closeModal, onSubmit }: SplitMaterialBatchModalProps) {
  const [splitNumber, setSplitNumber] = useState<number | null>();
  const methods = useForm();
  const handleSubmit = methods.handleSubmit((formState) => {
    onSubmit(formState);
    // closeModal();
  });
  const columns = [{
    title: '料號',
    dataIndex: 'materialId',
  }, {
    title: '批號',
    dataIndex: 'materialBatchId',
  }, {
    title: '儲位',
    dataIndex: 'stack',
  }, {
    width: 100,
    title: '數量',
    dataIndex: 'amount',
  }];

  useEffect(() => {
    methods.setValue(
      'formData',
      Array(splitNumber ?? 0).fill(0).map((_, index) => ({
        id: index,
        amount: null,
      }))
    );
  }, [methods, splitNumber]);

  console.log(methods.watch());

  return (
    <Modal className={styles.modal_wrapper} open={open} onClose={closeModal}>
      <ModalHeader>批號合併</ModalHeader>
      <ModalBody>
        <SelectedMaterialBatchList dataSource={data?.selectedRowKeys || []} />
        <div style={{ height: 350 }}>
          <Typography variant="h6" color="secondary">新批號設定</Typography>
          <div style={{ display: 'flex', alignItems: 'center', gap: 'var(--mzn-spacing-4)', margin: 'var(--mzn-spacing-4) 0' }}>
            <Typography variant="h6">批次數量</Typography>
            <InputNumber value={splitNumber} onChange={(value) => setSplitNumber(value)} />
          </div>
          <FormProvider {...methods}>
            <Table
              columns={[
                {
                  title: '西元年碼',
                  render: () => moment().format('YYYY').slice(2),
                },
                {
                  title: '月碼',
                  render: () => moment().month().toString(16).toUpperCase(),
                },
                {
                  title: '廠區碼',
                  render: () => (
                    <Select>

                    </Select>
                  ),
                },
                {
                  title: '課級碼',
                  render: () => (
                    <Select>

                    </Select>
                  ),
                },
                {
                  title: '組級碼',
                  render: () => (
                    <Select>

                    </Select>
                  ),
                },
                {
                  title: '數量',
                  render: (record) => (
                    <InputNumber
                      value={methods.watch(`formData.${record?.id}.amount`)}
                      onChange={(value) => methods.setValue(`formData.${record?.id}.amount`, value)} />
                  ),
                },
              ]}
              dataSource={methods.watch('formData')}
            />
          </FormProvider>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => closeModal()}>取消</Button>
        <Button onClick={handleSubmit}>確認</Button>
      </ModalFooter>
    </Modal>
  )
}