import {
  Button,
  Message,
  Modal,
  ModalActions,
  ModalBody,
  SelectValue,
  Typography,
} from '@mezzanine-ui/react';
import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ModalLayout } from '../../ModalLayout/ModalLayout';

import { TableColumn } from '@mezzanine-ui/core/table';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import { EditableBodyCellProps } from '@mezzanine-ui/react/Table';
import { getNextParams, NextPagination, useLoadersByCode } from '@solar/data';
import { useTargetModal } from '@solar/templates';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { PaginationTable } from '../../PaginationTable/PaginationTable';
import { Modals } from '../const';
import { ViewLoadersModalOpenData } from '../types';
import { deleteLoaderByLoaderId, editLoaderSpec } from '../utils';

export type ReceiveActionInputs = {
  mainMaterialUnitQuantity?: number;
  warehouse?: SelectValue;
  loader: SelectValue | null;
};

const schema = Yup.object().shape({
  mainMaterialUnitQuantity: Yup.string().required('必填'),
});

type ViewLoaderTableProps = {
  id: string;
  name: string;
  rowIndex: number;
  lengthCm: string;
  widthCm: string;
  heightCm: string;
  capacityL: string;
};

export function ViewLoaders() {
  const [editingKey, setEditingKey] = useState('');
  const [deleteLoaderId, setDeleteLoaderId] = useState<string>();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const methods = useForm<{ table: ViewLoaderTableProps[] }>({
    defaultValues: {
      table: [],
    },
  });

  const modalController = useTargetModal<ViewLoadersModalOpenData>(
    Modals.ViewLoaders
  );

  const categoryCodes = modalController?.data?.code ?? '';

  const { loaders, mutateParams, pageInfo, mutate } = useLoadersByCode();

  const mappedLoaders = useMemo(
    () =>
      loaders?.map((row, index) => ({
        ...row,
        rowIndex: index,
        name: row.category?.name,
      })),
    [loaders]
  );

  const refetchLoaders = useCallback(
    (nextPagination: NextPagination) => {
      const { offset, limit } = getNextParams(nextPagination, pageInfo);

      mutateParams({
        offset,
        limit,
        categoryCodes: categoryCodes,
      });
    },
    [mutateParams, pageInfo]
  );

  const handleReceiveActionModalClose = useCallback(() => {
    modalController.closeModal();
  }, []);

  const renderRowActions: TableColumn<ViewLoaderTableProps>['render'] = (
    source
  ) => (
    <div
      style={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      {editingKey === source.id ? (
        <Button
          type="button"
          onClick={async () => {
            const lengthCm =
              methods.getValues(`table.${source.rowIndex}.lengthCm`) ?? '';
            const widthCm =
              methods.getValues(`table.${source.rowIndex}.widthCm`) ?? '';
            const heightCm =
              methods.getValues(`table.${source.rowIndex}.heightCm`) ?? '';
            const capacityL =
              methods.getValues(`table.${source.rowIndex}.capacityL`) ?? '';

            setEditingKey('');

            try {
              const res = await editLoaderSpec(source?.id, {
                lengthCm,
                widthCm,
                heightCm,
                capacityL,
              });

              if (res.ok) {
                Message.success('修改成功');
              }
            } catch (err) {
              if (err instanceof Error) {
                Message.error(JSON.parse(err.message).message);
              } else {
                Message.error('修改失敗');
              }
            }
          }}
        >
          完成
        </Button>
      ) : (
        <>
          <Button
            type="button"
            disabled={!!editingKey}
            onClick={() => {
              setEditingKey(source.id);
            }}
          >
            編輯
          </Button>
          <Button
            type="button"
            danger
            disabled={!!editingKey}
            onClick={() => {
              setDeleteLoaderId(source.id);
              setDeleteModalOpen(true);
            }}
          >
            刪除
          </Button>
        </>
      )}
    </div>
  );

  const editableColumns: TableColumn<ViewLoaderTableProps>[] = useMemo(
    () => [
      {
        title: '操作',
        width: 160,
        render: renderRowActions,
      },
      {
        title: '編號',
        dataIndex: 'id',
        editable: false,
        width: 120,
      },
      {
        title: '位置',
        dataIndex: 'shelfId',
        editable: false,
      },
      {
        title: '長',
        dataIndex: 'lengthCm',
        editable: true,
      },
      {
        title: '寬',
        dataIndex: 'widthCm',
        editable: true,
      },
      {
        title: '高',
        dataIndex: 'heightCm',
        editable: true,
      },
      {
        title: '容量',
        dataIndex: 'capacityL',
        editable: true,
      },
    ],
    []
  );

  const mergeColumnWithCustomProps = editableColumns.map((column) => {
    if (!column.editable) return column;

    return {
      ...column,
      /** inject some custom props to your custom cell */
      setCellProps: (source: ViewLoaderTableProps) => ({
        isEditing: editingKey === source.id,
      }),
    };
  });

  useEffect(() => {
    if (mappedLoaders) {
      methods.setValue('table', mappedLoaders as ViewLoaderTableProps[]);
    }
  }, [mappedLoaders?.length, methods]);

  useEffect(() => {
    // categoryCodes 一開始可能是 undefined
    if (categoryCodes) {
      mutateParams({ categoryCodes: categoryCodes });
    }
  }, [categoryCodes]);

  return (
    <>
      <ModalLayout
        modalHeader={modalController?.data?.title}
        {...modalController}
        closeModal={handleReceiveActionModalClose}
      >
        <FormFieldsWrapper methods={methods}>
          <PaginationTable
            mutateParams={refetchLoaders}
            pageInfo={pageInfo}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            columns={mergeColumnWithCustomProps}
            dataSource={methods.watch('table')}
          />
        </FormFieldsWrapper>
      </ModalLayout>
      <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <ModalBody>
          <br />
          <br />
          <Typography variant="body1" color="error">
            確認是否刪除載具
          </Typography>
          <strong>編號：{deleteLoaderId ?? ''}</strong>
        </ModalBody>
        <ModalActions
          confirmText="確認"
          cancelText="取消"
          confirmButtonProps={{ danger: true }}
          onConfirm={async () => {
            try {
              const res = await deleteLoaderByLoaderId(deleteLoaderId ?? '');
              if (res.ok) {
                mutate();
                Message.error('刪除成功');
              }
            } catch (err) {
              if (err instanceof Error) {
                Message.error(JSON.parse(err.message).message);
              } else {
                Message.error('刪除失敗');
              }
            } finally {
              setDeleteModalOpen(false);
            }
          }}
          onCancel={() => setDeleteModalOpen(false)}
        />
      </Modal>
    </>
  );
}

interface EditableCellProps extends EditableBodyCellProps {
  /** some extra props come from `setCellProps` */
  isEditing: boolean;
}

const EditableCell: FC<EditableCellProps> = (props) => {
  const { children, dataIndex, editable, isEditing, rowData } = props;

  return (
    editable ? (
      isEditing ? (
        <div>
          <InputField
            registerName={`table.${rowData.rowIndex}.${dataIndex}`}
            type="number"
          />
        </div>
      ) : (
        <>{rowData[dataIndex ?? '']}</>
      )
    ) : (
      children
    )
  ) as ReactElement;
};
