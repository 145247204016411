import { useState } from 'react';
import { PageLayout } from '@solar/templates';
import { Table } from '@mezzanine-ui/react';
import { useHubWarehouseFilter, useHubWarehouseGet } from '@solar/data';
import { FilterPanelForm } from './FilterPanelForm';
import { MaterialIdInput } from './FilterPanelForm/MaterialIdInput';
import { SapBatchInput } from './FilterPanelForm/SapBatchInput';
import { CustomerIdInput } from './FilterPanelForm/CustomerIdInput';

export function HubWarehousePage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const { formMethods, filterCondition, updateFilterCondition } =
    useHubWarehouseFilter();

  const { hubWarehouseData, hubWarehouseMeta, refetechHubWarehouse } =
    useHubWarehouseGet({
      page: currentPage,
      perPage: perPage,
      ...filterCondition,
    });

  const tableColumns = [
    { title: '客戶名稱', dataIndex: 'customerName', width: 400 },
    { title: '客戶編號', dataIndex: 'customerId', width: 150 },
    { title: '料號', dataIndex: 'materialId', width: 400 },
    { title: '母批', dataIndex: 'sapBatchId', width: 150 },
    { title: '子批', dataIndex: 'subBatchId', width: 150 },
    { title: 'Barcode', dataIndex: 'barcode', width: 150 },
    { title: '數量', dataIndex: 'quantity', width: 150 },
  ];

  return (
    <PageLayout title="HUB 倉庫存清單">
      <FilterPanelForm
        formMethods={formMethods}
        applyFilterCondition={() => {
          updateFilterCondition({
            onFilterConditionNoChange: refetechHubWarehouse,
            onUpdateSuccess: () => setCurrentPage(1),
          });
        }}
      >
        <MaterialIdInput />
        <SapBatchInput />
        <CustomerIdInput />
      </FilterPanelForm>
      <Table
        columns={tableColumns}
        dataSource={hubWarehouseData}
        pagination={{
          disableAutoSlicing: true,
          total: hubWarehouseMeta.total,
          current: currentPage,
          onChange: (page) => {
            setCurrentPage(page);
          },
          options: {
            pageSize: perPage,
            pageSizeLabel: '每頁顯示：',
            pageSizeUnit: '筆',
            onChangePageSize: (pageSize) => {
              setPerPage(pageSize);
              setCurrentPage(1);
            },
            renderPageSizeOptionName: (p) => `${p}`,
            showPageSizeOptions: true,
            showJumper: true,
            jumperButtonText: '前往',
            jumperHintText: '跳至',
          },
        }}
      />
    </PageLayout>
  );
}
