import { useAutoAnimate } from '@formkit/auto-animate/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { PlusIcon, TrashIcon } from '@mezzanine-ui/icons';
import {
  Button,
  Icon,
  Message,
  ModalActions,
  SelectValue,
  Table,
} from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  DatePickerField,
  FormFieldsWrapper,
  InputField,
} from '@mezzanine-ui/react-hook-form';
import {
  FetchingInventoryStockTypes,
  getInventoryByMaterial,
  InventoryByMaterialSearchType,
  MaterialRequestOrderSpec,
  MaterialResponseData,
  useMaterialRequestOrder,
  useMaterialRequestOrders,
  useMaterials,
  useWorkOrder,
} from '@solar/data';
import {
  CollapsibleContainer,
  CustomAutoCompleteField,
  NumericInputField,
  RowSection,
  useTargetModal,
} from '@solar/templates';
import Decimal from 'decimal.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ModalLayout } from '../../../Material/ModalLayout/ModalLayout';

import {
  useAutoCompleteRecipeField,
  useInputNeedsColumn,
  usePreviewInputColumn,
} from '../hooks';
import {
  ConfigureTableProps,
  DispatchMaterialViewModalProps,
  DispatchSettingModalFormProps,
  Modals,
} from '../types';
import {
  createWorkOrder,
  CreateWorkOrderPayload,
  generateSapBatchId,
  GenerateSapBatchIdResponse,
  quantityValidation,
  selectedUnitsGenerator,
  updateWorkOrder,
  UpdateWorkOrderPayload,
} from '../utils';
import { CreateConfigureTable } from './CreateConfigureTable';
import classes from './dispatch-setting-modal.module.scss';

const SPECIFIED_PROCESSING_ID = 'EF';

const defaultValues = {
  materialSubBatchCount: undefined,
  materialId: null,

  materialDescription: null,
  tables: [],
  tempMaterialRequestOrderSpecs: [],
  expectedCompletedAt: new Date().toISOString(),
  recipeId: null,
};

export function DispatchSettingModal() {
  const [pregeneratedSapInfo, setPregeneratedSapInfo] = useState<Array<
    GenerateSapBatchIdResponse[0]
  > | null>(null);
  const [workOrderLoading, setWorkOrderLoading] = useState(false);
  const addConfigurationTableButtonRef = useRef<HTMLButtonElement>(null);
  const inputNeedsTableRef = useRef<HTMLTableElement>(null);
  const [parent] = useAutoAnimate();

  const { orderId } = useParams<{ orderId: string }>();

  const modalController = useTargetModal<DispatchMaterialViewModalProps>(
    Modals.DISPATCH_SETTING
  );
  const {
    materialId: materialIdFromModalProps,
    orderId: workOrderIdFromModalProps,
    processingIdIncludesEF,
  } = modalController?.data ?? {};

  const methods = useForm<DispatchSettingModalFormProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues,
    resolver: yupResolver(
      quantityValidation({
        materialSubBatchCountRequired: !!materialIdFromModalProps,
      })
    ),
  });

  useEffect(() => {
    if (modalController.open && modalController.data) {
      const recipeId = modalController?.data?.recipeId;
      methods.setValue(
        'recipeId',
        recipeId ? { id: recipeId, name: recipeId } : null
      );
    }
  }, [modalController.open, modalController.data]);

  const RecipeAutoCompleteField = useAutoCompleteRecipeField({
    methods,
    enabled: true,
    materialId: materialIdFromModalProps ?? undefined,
    // disabled: !materialIdFromModalProps
  });

  const {
    isLoading: fetchMaterialsLoading,
    materials,
    setParams: refetchGetMaterials,
  } = useMaterials(methods);
  const { insert, fields, remove } = useFieldArray({
    control: methods.control,
    name: 'tables',
  });

  const {
    mutate: fetchMaterialRequestOrdersByWorkId,
    mutateParams: mutateRequestOrdersParams,
  } = useMaterialRequestOrders({
    initiateFetching: false,
  });

  const { mutate: mutateWorkOrderByOrderId } = useWorkOrder({ orderId });
  const { data: inputNeeds, isLoading: materialRequestOrderSpecsLoading } =
    useMaterialRequestOrder({
      orderId: workOrderIdFromModalProps ?? undefined,
    });

  const previewInputColumn = usePreviewInputColumn({
    methods,
    plusAction: ({ materialId, materialDescription }) => {
      // 更新 materials
      refetchGetMaterials({ searchTerm: materialId, searchType: 'ID' });

      methods.setValue('materialId', { id: materialId, name: materialId });
      methods.setValue('materialDescription', {
        id: materialId,
        name: materialDescription,
      });

      addConfigurationTableButtonRef?.current?.click();
    },
  });
  const inputNeedsColumn = useInputNeedsColumn({
    methods,
    tableRef: inputNeedsTableRef,
  });

  const autoCompleteHandler = useCallback(
    (event: SelectValue, fieldName: 'materialId' | 'materialDescription') => {
      const targetId = event?.id;
      if (!targetId) return;
      if (!materials?.length) return;

      const relatedMaterials: { [id: string]: MaterialResponseData } = (
        materials ?? []
      )?.reduce((prev, material) => ({ ...prev, [material.id]: material }), {});
      if (fieldName === 'materialId')
        methods.setValue(fieldName, {
          id: relatedMaterials[targetId].id,
          name: relatedMaterials[targetId].id,
        });

      if (fieldName === 'materialDescription')
        methods.setValue(fieldName, {
          id: relatedMaterials[targetId]?.description,
          name: relatedMaterials[targetId]?.description,
        });
    },
    [materials, methods, methods.watch]
  );

  // 預產母批號 每開一次 modal 就產生一個新的 SOL-1132
  useEffect(() => {
    if (
      orderId &&
      modalController.open &&
      !workOrderIdFromModalProps &&
      !processingIdIncludesEF
    ) {
      generateSapBatchId({ workOrderId: orderId, count: 1 })
        .then((res) => {
          // if (res[0]) {
          setPregeneratedSapInfo(res ?? null);
          // }
        })
        .catch((err) => {
          if (err instanceof Error && JSON.parse(err.message)?.statusCode) {
            setPregeneratedSapInfo([
              {
                workOrderId: 'PregeneratedSapInfoError',
                materialId: 'PregeneratedSapInfoError',
                sapBatchId: '無法建立預產批號',
              },
            ]);
          }
        });
    }
  }, [
    orderId,
    modalController.open,
    processingIdIncludesEF,
    workOrderIdFromModalProps,
  ]);

  useEffect(() => {
    if (orderId) {
      mutateRequestOrdersParams({ workOrderIds: [orderId] });
    }
  }, [orderId]);

  // 編輯發料單： 判斷 materialIdFromModalProps
  useEffect(() => {
    if (inputNeeds && workOrderIdFromModalProps) {
      methods.setValue(
        'tempMaterialRequestOrderSpecs',
        inputNeeds?.materialRequestOrderSpecs ?? []
      );
    }
  }, [inputNeeds, workOrderIdFromModalProps]);

  const onClose = useCallback(() => {
    methods.reset(defaultValues);
    modalController.closeModal();
  }, [modalController, methods]);

  return (
    <ModalLayout
      className={classes['modal-width']}
      loading={methods.formState.isSubmitting}
      modalHeader="發料設定"
      modalFooter={
        <ModalActions
          confirmText={workOrderIdFromModalProps ? '更新發料單' : '建立發料單'}
          cancelText="取消"
          confirmButtonProps={{ form: Modals.DISPATCH_SETTING }}
          onCancel={onClose}
        />
      }
      open={modalController.open}
      closeModal={onClose}
    >
      <CollapsibleContainer
        enableRowBlockStyle
        header={workOrderIdFromModalProps ? '投料需求編輯' : '投入料預覽'}
        open
      >
        {!workOrderIdFromModalProps && (
          <Table
            scroll={{ x: 1500 }}
            columns={previewInputColumn}
            dataSource={modalController?.data?.dispatchPreviewTable ?? []}
          />
        )}
        {workOrderIdFromModalProps && (
          <Table
            ref={inputNeedsTableRef}
            scroll={{ x: 1500 }}
            bodyRowClassName={classes['custom-row']}
            columns={inputNeedsColumn}
            dataSource={methods.watch('tempMaterialRequestOrderSpecs') ?? []}
          />
        )}
      </CollapsibleContainer>
      <br />
      <br />
      <FormFieldsWrapper
        methods={methods}
        id={Modals.DISPATCH_SETTING}
        onSubmit={async (values) => {
          try {
            const {
              materialSubBatchCount,
              expectedCompletedAt,
              tables,
              recipeId,
              productionExpectedFinishAt,
              remark,
              recipeVersion,
            } = values;

            const items = tables.reduce((accu, cur) => {
              const selectedRowKeys = cur?.selectedRowKeys ?? [];

              if (!cur?.table.length || !selectedRowKeys.length) {
                return accu;
              }

              accu.push(
                ...cur.preserveTable.map((row) => {
                  const receiveQuantity =
                    cur.selectedRowKeyToReceiveQuantity?.[
                      row.batchStoredLoaderRecordId
                    ] || '0';
                  const materialStockUnitQuantity =
                    row.selectedUnit.id === 'materialInputUnit'
                      ? new Decimal(receiveQuantity)
                          .times(new Decimal(row.stockOverInputUnitRatio))
                          .toString()
                      : receiveQuantity;

                  const materialWeightUnitQuantity =
                    cur.selectedRowKeyToMaterialWeightUnitQuantity?.[
                      row.batchStoredLoaderRecordId
                    ] || '0';

                  return {
                    materialId: row.materialId,
                    materialSapBatchId: row.materialSapBatchId,
                    materialSubBatchId: row.materialSubBatchId,
                    loaderId: row.loaderId,
                    materialStockUnitQuantity: String(
                      materialStockUnitQuantity
                    ),
                    materialWeightUnitQuantity: String(
                      materialWeightUnitQuantity
                    ),
                  };
                })
              );

              return accu;
            }, [] as CreateWorkOrderPayload['items']);

            // 編輯發料單
            if (workOrderIdFromModalProps) {
              const mergedItems: UpdateWorkOrderPayload['items'] = [];
              const keyMap = new Map<string, string>();
              const loaderIdRecord = new Map<string, string>();
              const oldItems = (
                methods.getValues('tempMaterialRequestOrderSpecs') ?? []
              )?.reduce((accu, row, index) => {
                if (!row.deleted) {
                  accu.push({
                    ...row,
                    materialSubBatchId: row.materialBatchId,
                    loaderId: row?.loaderId ?? null,
                    materialStockUnitQuantity: String(
                      row.expectedMaterialStockUnitQuantity
                    ),
                    materialWeightUnitQuantity:
                      row?.materialWeightUnitQuantity ?? null,
                  });
                }

                return accu;
              }, [] as (MaterialRequestOrderSpec & { materialStockUnitQuantity: string })[]);

              [...items, ...oldItems].forEach((item) => {
                const {
                  materialSapBatchId,
                  materialSubBatchId,
                  materialId,
                  materialStockUnitQuantity,
                } = item;
                const compositeKey = `${materialSapBatchId}-${materialSubBatchId}-${materialId}`;

                if (keyMap.has(compositeKey)) {
                  // If it exists, update the amount
                  keyMap.set(
                    compositeKey,
                    String(
                      new Decimal(keyMap.get(compositeKey)!).plus(
                        new Decimal(materialStockUnitQuantity)
                      )
                    )
                  );
                } else {
                  // If it doesn't exist, add a new entry in the map
                  keyMap.set(compositeKey, String(materialStockUnitQuantity));
                  loaderIdRecord.set(compositeKey, String(item.loaderId));
                }
              });

              keyMap.forEach((materialStockUnitQuantity, compositeKey) => {
                const [materialSapBatchId, materialSubBatchId, materialId] =
                  compositeKey.split('-');
                mergedItems.push({
                  materialId,
                  materialSapBatchId,
                  materialSubBatchId,
                  materialStockUnitQuantity,
                  loaderId:
                    loaderIdRecord.get(compositeKey) === 'null'
                      ? null
                      : loaderIdRecord.get(compositeKey) ?? '',
                });
              });

              const updateResponse = await updateWorkOrder({
                payload: {
                  expectedCompletedAt,
                  designatedInventory: !!mergedItems.length,
                  items: mergedItems,
                  recipeId: recipeId?.id ?? null,
                },
                orderId: workOrderIdFromModalProps,
              });

              if (updateResponse.ok) {
                Message.success('發料單修改成功');
                return;
              }
            }

            // 以下是新建發料單

            if (
              !orderId ||
              !materialSubBatchCount ||
              !pregeneratedSapInfo?.[0]
            ) {
              Message.error('系統錯誤');
              return;
            }

            const payload: CreateWorkOrderPayload = {
              workOrderId: orderId,
              outputMaterialId: pregeneratedSapInfo?.[0]?.materialId ?? '',
              outputMaterialSapBatchIds: processingIdIncludesEF
                ? [
                    ...new Set(
                      pregeneratedSapInfo?.map((date) => date.sapBatchId)
                    ),
                  ]
                : [pregeneratedSapInfo?.[0]?.sapBatchId],
              outputMaterialSubBatchCount: processingIdIncludesEF
                ? 1
                : Number(materialSubBatchCount),
              recipeId: recipeId?.id ?? null,
              expectedCompletedAt: expectedCompletedAt,
              productionExpectedFinishAt: productionExpectedFinishAt ?? null,
              remark: remark ?? null,
              recipeVersion: recipeVersion?.name
                ? Number(recipeVersion?.name)
                : null,
              sourceType: 'REQUEST_ORDER',
              designatedInventory: !!items.length,
              items: items,
            };

            const postResponse = await createWorkOrder(payload);

            if (postResponse.ok) {
              Message.success('建立發料成功');

              const postResponseData = (await postResponse.json()) as {
                savedRequestedIds: Array<string>;
              };

              const params = new URLSearchParams();

              (postResponseData?.savedRequestedIds ?? [])?.forEach((id) => {
                params.append('loaderMaterialBatchId', id);
              });

              window.open(
                `/print-management/label?tab=batch-sticker&from=WorkOrderManagement&${params.toString()}`,
                '_blank'
              );
            }
          } catch (err) {
            if (err instanceof Error) {
              Message.error(JSON.parse(err.message).message);
            } else {
              Message.error(
                workOrderIdFromModalProps ? '更新發料單失敗' : '建立發料失敗'
              );
            }
          } finally {
            mutateWorkOrderByOrderId();
            fetchMaterialRequestOrdersByWorkId();
            onClose();
          }
        }}
      >
        <div className={classes['selector-wrapper']}>
          <RowSection label="批號預覽：">
            {workOrderIdFromModalProps ? (
              inputNeeds?.materialRequestOrderBatches[0]?.materialSapBatchId ??
              null
            ) : (
              <div
                style={{
                  backgroundColor: 'white',
                  overflow: 'auto',
                  maxHeight: '100px',
                }}
              >
                {[
                  ...new Set(
                    pregeneratedSapInfo?.map((date) => date.sapBatchId)
                  ),
                ]?.map((sapBatchId) => (
                  <div>{sapBatchId}</div>
                ))}
              </div>
            )}
            {/* ?? '載入中......' */}
          </RowSection>
          {processingIdIncludesEF && (
            <RowSection label="母批號數量：">
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <NumericInputField
                  disabled={!!workOrderIdFromModalProps}
                  registerName="materialSubBatchCount"
                  validateMode="int"
                />
                <Button
                  type="button"
                  onClick={() => {
                    const materialSubBatchCount = methods.getValues(
                      'materialSubBatchCount'
                    );

                    if (!Number(materialSubBatchCount)) {
                      Message.error('請輸入母批號數量');
                      return;
                    }

                    generateSapBatchId({
                      workOrderId: orderId!,
                      count: Number(materialSubBatchCount),
                    })
                      .then((res) => {
                        setPregeneratedSapInfo(res ?? null);
                      })
                      .catch((err) => {
                        if (
                          err instanceof Error &&
                          JSON.parse(err.message)?.statusCode
                        ) {
                          setPregeneratedSapInfo([
                            {
                              workOrderId: 'PregeneratedSapInfoError',
                              materialId: 'PregeneratedSapInfoError',
                              sapBatchId: '無法建立預產批號',
                            },
                          ]);
                        }
                      });
                  }}
                >
                  確認
                </Button>
              </div>
            </RowSection>
          )}
          {!processingIdIncludesEF && (
            <RowSection label="子批號數量：">
              <NumericInputField
                disabled={!!workOrderIdFromModalProps}
                registerName="materialSubBatchCount"
                validateMode="int"
              />
            </RowSection>
          )}
          <RowSection label="預期領料日期：">
            <DatePickerField registerName="expectedCompletedAt" />
          </RowSection>
          <div style={{ display: 'flex', gap: '4px' }}>
            <RowSection label="路徑碼：" colGap="4px">
              {RecipeAutoCompleteField}
            </RowSection>
            <RowSection label="版次：" colGap="4px">
              <AutoCompleteField
                options={methods.watch('recipeVersions') ?? []}
                registerName="recipeVersion"
              />
            </RowSection>
          </div>
          <RowSection label="生管指定完工日：">
            <DatePickerField registerName="productionExpectedFinishAt" />
          </RowSection>
          <RowSection label="備註：">
            <InputField registerName="remark" />
          </RowSection>
          <RowSection label="料號：" childFullWidth>
            <CustomAutoCompleteField
              value={methods?.watch('materialId') ?? null}
              registerName="materialId"
              options={
                (materials ?? [])?.map((material) => ({
                  id: material.id,
                  name: material.id,
                })) ?? []
              }
              onSearch={(searchTerm) => {
                refetchGetMaterials({ searchTerm, searchType: 'ID' });
              }}
              onChange={(event) =>
                autoCompleteHandler(event, 'materialDescription')
              }
            />
          </RowSection>
          <RowSection label="品名：" childFullWidth>
            <CustomAutoCompleteField
              value={methods?.watch('materialDescription') ?? null}
              registerName="materialDescription"
              options={
                (materials ?? [])?.map((material) => ({
                  id: material?.id ?? '',
                  name: material?.description ?? '',
                })) ?? []
              }
              onChange={(event) => autoCompleteHandler(event, 'materialId')}
              errorMsgRender={(e) => e.message}
            />
          </RowSection>
        </div>
        <div>
          <Button
            loading={workOrderLoading}
            prefix={<Icon icon={PlusIcon} />}
            type="button"
            variant="outlined"
            ref={addConfigurationTableButtonRef}
            onClick={async () => {
              const materialId = methods.getValues('materialId.id');
              const materialDescription = methods.getValues(
                'materialDescription.name'
              );
              if (!materialId) {
                Message.info('請先選取料號');
                return;
              }

              const materialIds = [materialId];
              try {
                setWorkOrderLoading(true);
                const res = await getInventoryByMaterial({
                  materialIds,
                  type: InventoryByMaterialSearchType.LOADER_BARCODE,
                  enableUnavailable: true,
                  stockTypes: [
                    FetchingInventoryStockTypes.AVAILABLE,
                    // FetchingInventoryStockTypes.EXPECTED,
                    FetchingInventoryStockTypes.SALES,
                    // FetchingInventoryStockTypes.PENDING,
                  ],
                  withElementRatio: null,
                });

                insert(0, {
                  isReachEnd: !res.pageInfo.hasNext!,
                  materialId: materialId,
                  materialDescription: materialDescription,
                  pageOffset: res.pageInfo.offset!,
                  preserveTable: [],
                  table: res.records.map((row) => ({
                    ...row,
                    id: row.batchStoredLoaderRecordId,
                    availableState: row?.availableState,
                    selectedUnit: selectedUnitsGenerator({ source: row })[0],
                  })) as unknown as ConfigureTableProps[], //TEMP_CREATE_CONFIGURE_TABLE,
                  selectedRowKeys: [],
                  specifiedQuantity: '0',
                  subTotal: '0',
                  designatedInventory: false,
                  selectedRowKeyToReceiveQuantity: {},
                  selectedRowKeyToMaterialWeightUnitQuantity: {},
                });
              } catch (err) {
                Message.error('系統錯誤');
              } finally {
                setWorkOrderLoading(false);
              }
            }}
          >
            加入
          </Button>
        </div>
        <div ref={parent}>
          {fields?.map((table, index) => (
            <div key={table.id} style={{ marginTop: 'var(--mzn-spacing-4)' }}>
              <CollapsibleContainer
                enableRowBlockStyle
                open
                header={
                  <Button
                    danger
                    prefix={<Icon icon={TrashIcon} />}
                    type="button"
                    variant="contained"
                    onClick={() => remove(index)}
                  >
                    移除
                  </Button>
                }
              >
                <CreateConfigureTable index={index} />
              </CollapsibleContainer>
            </div>
          ))}
        </div>
      </FormFieldsWrapper>
    </ModalLayout>
  );
}
