import { SelectValue } from '@mezzanine-ui/react';

export type SalesReturnQueryRes = {
  records: SalesReturnListItem[];
  pageInfo: SalesReturnPageInfo;
};

export type SalesReturnListItem = {
  id: string;
  customerId: string;
  customerName: string;
  receiverId: string;
  receiverName: string;
  receiverAddress: string;
  createdAt: string;
  packedState: unknown;
  specs: string[];
  orderType: 'ZLR' | 'ZLR2' | 'ZLR4';
  orderTypeName: string;
  expectedCompletedAt: string;
  employeeName: unknown;
};

export type SalesReturnPageInfo = {
  limit: number;
  offset: number;
  totalPages: number;
  totalCount: number;
  hasNext: boolean;
  hasPrevious: boolean;
};

export enum SortTypes {
  // CreatedAtDesc = 'CREATED_AT_DESC',
  // CreatedAtAsc = 'CREATED_AT_ASC',
  ExpectedCompletedAtDesc = 'EXPECTED_COMPLETED_AT_DESC',
  ExpectedCompletedAtAsc = 'EXPECTED_COMPLETED_AT_ASC',
}

export type SalesReturnFilters = {
  orderIds?: string;
  customerNames?: string;
  employeeNames?: string;
  types?: SelectValue<'ZLR' | 'ZLR2' | 'ZLR4'>[];
  expectedDateRange?: [string, string];
};
