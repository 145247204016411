import {
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
} from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { ModalType } from '@solar/data';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { FilterType } from '../Layout/ListLayout';
import styles from './CreateEditModal.module.scss';

interface CreateEditModalProps {
  title: string;
  openModal: ModalType;
  onCloseModal: () => void;
  methods: UseFormReturn<FieldValues, any>;
  fields: FilterType[];
  source: any;
  table?: JSX.Element;
  width?: number;
  onConfirm: () => void;
  confirmButtonDisalbed?: boolean;
}

export function CreateEditModal({
  title,
  openModal,
  onCloseModal,
  methods,
  fields,
  table,
  width,
  onConfirm,
  confirmButtonDisalbed,
}: CreateEditModalProps) {
  return (
    <Modal
      open={openModal === 'CreateEditModal'}
      style={{ width: width ?? 400 }}
      onClose={onCloseModal}
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody className={styles.modalBody}>
        <FormFieldsWrapper methods={methods} className={styles.formWrapper}>
          {fields.map((field) => field.fn(field))}
          {table}
        </FormFieldsWrapper>
      </ModalBody>
      <ModalActions
        cancelText="取消"
        onCancel={onCloseModal}
        confirmText="確定"
        onConfirm={onConfirm}
        confirmButtonProps={{
          disabled: confirmButtonDisalbed ?? false,
        }}
      />
    </Modal>
  );
}
