import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';
import { API_NAMESPACE, PageInfo } from '@solar/data';
import { useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import useSWR from 'swr';
import styles from '../production-form-creator.module.scss';
import { Typography } from '@mezzanine-ui/react';
import { ProductionFormCreatorFormFields } from '../typings';

export function useAutoCompleteEquipmentField({
  methods,
  enabled,
}: {
  methods: UseFormReturn<ProductionFormCreatorFormFields>;
  enabled?: boolean;
}): JSX.Element {
  const [searchTerm, setSearchTerm] = useState('');

  const { data } = useSWR<{
    equipments: { id: string; name: string }[];
    pageInfo: PageInfo;
  }>(
    enabled
      ? [
          `/equipments?${searchTerm ? `searchTerm=${searchTerm}` : ''}`,
          {
            namespace: API_NAMESPACE.EQUIPMENTS,
          },
        ]
      : undefined
  );

  const equipments = useMemo(
    () =>
      (data?.equipments ?? []).map((equipment) => ({
        id: equipment.id,
        name: equipment.name,
      })),
    [data]
  );

  const selected = methods.watch('equipmentId');

  const { data: singleData } = useSWR<{ id: string; name: string }>(
    selected
      ? [
          `/equipments/${selected.id}`,
          {
            namespace: API_NAMESPACE.EQUIPMENTS,
          },
        ]
      : undefined
  );

  return (
    <div className={styles.fieldGroup}>
      <AutoCompleteField
        options={equipments}
        label="設備"
        placeholder="請輸入設備編號"
        onInput={({ target }) => {
          const sanitized =
            (target as HTMLInputElement).value?.trim().toUpperCase() ?? '';

          setSearchTerm(sanitized);

          (target as HTMLInputElement).value = sanitized;
        }}
        registerName={'equipmentId'}
      />
      {singleData?.name ? (
        <Typography variant="body2">{singleData.name}</Typography>
      ) : selected ? (
        <Typography variant="body2">-</Typography>
      ) : null}
    </div>
  );
}
