import { Typography } from '@mezzanine-ui/react';
import { ReactNode } from 'react';

interface HorizontalFieldProps {
  label: ReactNode;
  children: ReactNode;
}

export function HorizontalField({ label, children }: HorizontalFieldProps) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 'var(--mzn-spacing-3)',
      }}
    >
      <div style={{ flex: '0 0 100px', padding: 5 }}>
        {typeof label === 'string' ? (
          <Typography variant="h5" color="secondary-light">
            {label}
          </Typography>
        ) : (
          label
        )}
      </div>
      {children}
    </div>
  );
}
