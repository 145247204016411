import { UseFormReturn } from 'react-hook-form';
import { ProductionFormCreatorFormFields } from '../typings';
import { useMemo } from 'react';
import { API_NAMESPACE } from '@solar/data';
import useSWR from 'swr';
import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';
import styles from '../production-form-creator.module.scss';
import { Typography } from '@mezzanine-ui/react';

export function useAutoCompleteRecipeField({
  methods,
  enabled,
}: {
  methods: UseFormReturn<ProductionFormCreatorFormFields>;
  enabled?: boolean;
}): JSX.Element {
  const { data } = useSWR<
    {
      id: string;
      featureCode: {
        code: string;
        name: string;
      };
      group: {
        code: string;
        name: string;
      };
    }[]
  >(
    enabled
      ? [
          '/pp/recipes',
          {
            namespace: API_NAMESPACE.PP,
          },
        ]
      : undefined
  );

  const recipes = useMemo(
    () =>
      (data ?? []).map((recipe) => ({
        id: recipe.id,
        name: `${recipe.id} [${recipe.group.name}_${recipe.featureCode.name}]`,
      })),
    [data]
  );

  const recipe = methods.watch('recipeId');

  return (
    <div className={styles.fieldGroup}>
      <AutoCompleteField
        options={recipes}
        label="路徑碼"
        placeholder="FTPB029"
        registerName="recipeId"
      />
      {recipe ? (
        <Typography variant="body2">
          {recipes
            .find((r) => r.id === recipe?.id)
            ?.name.replace(/^[A-Z0-9]+\s\[(.+)\]$/i, '$1')}
        </Typography>
      ) : null}
    </div>
  );
}
