import { fetchUrl } from '../endpoint';
import useSWR from 'swr';

const UNITS_TREE_ALLWITHMEMBERS = fetchUrl('units/tree/allWithMembers');

const fetcher = async (url: string) => {
  const res = await fetch(url);
  const json = await res.json();
  return json ?? null;
};

export type UnitsTreeAllWithMembersType = {
  id: number;
  name: string;
  code: string;
  headCount: number;
  members:
    | {
        id: string;
        name: string;
      }
    | undefined;
  children: UnitsTreeAllWithMembersType[] | undefined;
};

function isUnitsTreeAllWithMembersType(
  data: unknown
): data is UnitsTreeAllWithMembersType {
  if (data === null || typeof data !== 'object') return false;
  return (
    'id' in data && 'name' in data && 'code' in data && 'headCount' in data
  );
}

function areUnitsTreeAllWithMembersType(
  data: unknown
): data is UnitsTreeAllWithMembersType[] {
  if (!(data instanceof Array) || data.length === 0) return false;
  return data.every((d) => isUnitsTreeAllWithMembersType(d));
}

export type RCTreeDataType = {
  key: string;
  title: string;
  where: string;
  type: 'Member' | 'Unit';
  name: string;
  id: string | number;
  hasMembers: boolean;
  disabled?: boolean;
  selectable?: boolean;
  children: RCTreeDataType[] | undefined;
};

function transformToRCTreeData(
  data: UnitsTreeAllWithMembersType[] | undefined,
  where: Array<string | undefined>
): RCTreeDataType[] | undefined {
  if (!(data instanceof Array) || data.length === 0) return;

  const result = data.reduce((accumulator: any, current: any) => {
    accumulator.push(generateRCTreeData(current, where));
    return accumulator;
  }, []);

  return result;
}

export function useFetchTreeAllWithMembers() {
  const { data: apiData, isValidating } = useSWR(
    UNITS_TREE_ALLWITHMEMBERS,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  const data = areUnitsTreeAllWithMembersType(apiData)
    ? transformToRCTreeData(apiData, [])
    : undefined;

  return {
    data,
    isValidating,
  };
}

function isUnit(current: UnitsTreeAllWithMembersType) {
  return current.headCount !== undefined;
}

function unitTitle(current: UnitsTreeAllWithMembersType) {
  return String(current.code) + ' ' + String(current.name);
}

function memberTitle(current: UnitsTreeAllWithMembersType) {
  return String(current.id) + ' ' + String(current.name);
}

function unitType(current: UnitsTreeAllWithMembersType) {
  return 'Unit';
}

function memberType(current: UnitsTreeAllWithMembersType) {
  return 'Member';
}

function unitId(current: UnitsTreeAllWithMembersType) {
  return current.code;
}

function memberId(current: UnitsTreeAllWithMembersType) {
  return current.id;
}

function hasMembers(current: UnitsTreeAllWithMembersType) {
  return current.code !== '';
}

function concateMembersAndChildren(members: any, children: any) {
  if (members instanceof Array && children instanceof Array) {
    return Array.prototype.concat(members, children);
  }
  if (members instanceof Array) return members;
  if (children instanceof Array) return children;
  return undefined;
}

function generateRCTreeData(
  current: UnitsTreeAllWithMembersType,
  where: Array<string | undefined>
) {
  const comeFrom = Array.from(where);

  if (isUnit(current)) {
    comeFrom.push(current.name);
  }

  return {
    key: current.id,
    title: isUnit(current) ? unitTitle(current) : memberTitle(current),
    where: comeFrom.join('｜'),
    type: isUnit(current) ? unitType(current) : memberType(current),
    name: current.name,
    id: isUnit(current) ? unitId(current) : memberId(current),
    hasMembers: hasMembers(current),
    children: transformToRCTreeData(
      concateMembersAndChildren(current.members, current.children),
      comeFrom
    ),
  };
}
