import { UseFormReturn } from 'react-hook-form';

import {
  AutoCompleteField,
  AutoCompleteFieldProps,
} from '@mezzanine-ui/react-hook-form';
import { API_NAMESPACE } from '@solar/data';
import { useMemo } from 'react';
import useSWR from 'swr';

import { DispatchSettingModalFormProps } from '../types';

export function useAutoCompleteRecipeField({
  methods,
  enabled,
  ...restAutoCompleteFieldProps
}: {
  methods: UseFormReturn<DispatchSettingModalFormProps>;
  enabled?: boolean;
} & Omit<
  AutoCompleteFieldProps,
  'registerName' | 'placeholder' | 'options'
>): JSX.Element {
  const { data } = useSWR<
    {
      id: string;
      featureCode: {
        code: string;
        name: string;
      };
      group: {
        code: string;
        name: string;
      };
    }[]
  >(
    enabled
      ? [
          '/pp/recipes',
          {
            namespace: API_NAMESPACE.PP,
          },
        ]
      : undefined
  );

  const recipes = useMemo(
    () =>
      (data ?? []).map((recipe) => ({
        id: recipe.id,
        name: `${recipe.id} [${recipe.group.name}_${recipe.featureCode.name}]`,
      })),
    [data]
  );

  const recipe = methods.watch('recipeId');

  return (
    <AutoCompleteField
      options={recipes}
      placeholder="FTPB029"
      registerName="recipeId"
      {...restAutoCompleteFieldProps}
    />
  );
}
