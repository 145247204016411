import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';
import { useApplyUnit } from 'apps/frontend/src/app/Transportation/RequirementPage/useApplyUnit';
// import { ApplyUnitOptions } from 'apps/frontend/src/app/Transportation/RequirementsManagementPage/RequirementsManagement.config';

export function ApplyUntSelector() {
  const { applyUnitOptions } = useApplyUnit();

  return (
    <AutoCompleteField
      label="申請單位"
      required
      registerName="applyUnit"
      options={applyUnitOptions || []}
    />
  );
}
