import {
  AutoCompleteMultiField,
  DateRangePickerField,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { INSPECT_STATUS_OPTIONS } from '../IncomingInspectOperationPage/InspectStatusTag';
import classes from './filter.module.scss';
import { SORT_TYPE_SELECTIONS, useDebounce, useSuppliers } from '@solar/data';
import { useEffect, useState } from 'react';

export function Filter() {
  const { records: suppliers, mutateParams: mutateSuppliersParams } =
    useSuppliers();

  const [searchTerm, setSearchTerm] = useState('');
  const watchSuppliers = useDebounce(searchTerm, 800);

  useEffect(() => {
    mutateSuppliersParams(
      watchSuppliers ? { searchTerms: watchSuppliers } : undefined
    );
  }, [mutateSuppliersParams, watchSuppliers]);

  return (
    <div className={classes.host}>
      <InputField registerName="searchTerm" placeholder="請輸入關鍵字搜尋" />
      <DateRangePickerField
        fullWidth
        label="日期區間："
        registerName="dateRange"
      />
      <SelectField
        label="應收日："
        clearable
        defaultValue={SORT_TYPE_SELECTIONS[0]}
        registerName="sortType"
        options={SORT_TYPE_SELECTIONS}
      />
      <AutoCompleteMultiField
        label="供應商名稱："
        registerName="suppliers"
        options={suppliers}
        onInput={(e) => {
          setSearchTerm((e.target as HTMLInputElement).value);
        }}
      />
      <SelectField
        label="狀態："
        mode="multiple"
        clearable
        registerName="status"
        options={[...INSPECT_STATUS_OPTIONS]}
      />
    </div>
  );
}
