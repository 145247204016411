import { Modal, ModalBody, ModalHeader } from '@mezzanine-ui/react';
import styles from './preview-modal.module.scss';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { useForm } from 'react-hook-form';
import { ProductionFormDisplayTable } from './DisplayTable';
import { ProductionFormCreator } from '../ProductionFormCreator/ProductionFormCreator';
import { ProductionFormCondition } from '@solar/data';

export function ProductionFormPreviewModal({
  materialId,
  recipeId,
  recipeVersion,
  processingId,
  workCenterId,
  equipmentId,
  clear,
}: ProductionFormCondition & {
  clear: () => void;
}) {
  const shouldShow = !!(
    materialId ||
    recipeId ||
    recipeVersion ||
    processingId ||
    workCenterId ||
    equipmentId
  );

  const methods = useForm();

  return (
    <Modal size="large" open={shouldShow} onClose={clear}>
      <ModalHeader>
        <div className={styles.creatorLine}>
          題目/參數預覽
          {/* <ProductionFormCreator
            materialId={materialId}
            recipeId={recipeId}
            processingId={processingId}
            workCenterId={workCenterId}
            equipmentId={equipmentId}
          /> */}
        </div>
      </ModalHeader>
      <ModalBody>
        <FormFieldsWrapper methods={methods} className={styles.container}>
          <ProductionFormDisplayTable
            materialId={materialId}
            recipeId={recipeId}
            recipeVersion={recipeVersion}
            processingId={processingId}
            workCenterId={workCenterId}
            equipmentId={equipmentId}
          />
        </FormFieldsWrapper>
      </ModalBody>
    </Modal>
  );
}
