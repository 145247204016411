import { Message } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  FetchingInventoryStockTypes,
  getInventoryByMaterial,
  getNextParams,
  InventoryByMaterialSearchType,
  InventoryItem,
  NextPagination,
} from '@solar/data';
import {
  ModalGroup,
  MznPaginationTable,
  PageLayout,
  useModalGroupController,
} from '@solar/templates';
import { useCallback, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { BarcodeScanInput } from '../MaterialShift/BarcodeScanInput';
import { PaginationTable } from '../PaginationTable/PaginationTable';
import { useMaterialStockColumns } from './hooks';
import { CancelBound } from './modals/CancelBound';
import { Edit } from './modals/Edit';
import styles from './stock-bind-order-page.module.scss';
import { Modals, StockBindOrderForm } from './types';

const PAGE_LIMIT = 20;
export function StockBindOrder() {
  const [loading, setLoading] = useState<boolean>(false);
  const modalGroupController = useModalGroupController([
    { name: Modals.EDIT },
    { name: Modals.CANCEL_BIND },
  ]);

  const tableMethods = useForm<StockBindOrderForm>({
    defaultValues: {
      searchTerm: '',
      searchType: InventoryByMaterialSearchType.MATERIAL_BARCODE,
      pageInfo: {},
      records: [],
    },
  });

  const watchedSearchType = useWatch({
    control: tableMethods.control,
    name: 'searchType',
  });

  const watchedRecords = useWatch({
    control: tableMethods.control,
    name: 'records',
  });

  const watchedPageInfo = useWatch({
    control: tableMethods.control,
    name: 'pageInfo',
  });

  const watchedSearchTerm = useWatch({
    control: tableMethods.control,
    name: 'searchTerm',
  });

  const scannerKeyDownAction = useCallback(
    async ({
      searchTerm,
      searchType,
    }: {
      searchTerm: string | undefined;
      searchType: InventoryByMaterialSearchType;
    }) => {
      tableMethods.setValue('searchType', searchType);
      tableMethods.setValue('searchTerm', searchTerm ?? '');

      setLoading(true);
      try {
        if (searchTerm) {
          const res = await getInventoryByMaterial({
            type: searchType,
            offset: 0,
            limit: PAGE_LIMIT,
            searchTerms: [searchTerm ?? ''],
            stockTypes: [
              FetchingInventoryStockTypes.AVAILABLE,
              FetchingInventoryStockTypes.SALES,
            ],
          });

          tableMethods.setValue('records', res?.records);
          tableMethods.setValue('pageInfo', res?.pageInfo);

          if (res?.records?.length === 0) {
            throw '沒有匹配的資料';
          }
        }
      } catch (err) {
        if (err instanceof Error) {
          Message.error(JSON.parse(err.message).message);
        } else {
          Message.error(JSON.stringify(err));
        }
      } finally {
        setLoading(false);
      }
    },
    [setLoading]
  );

  const paginationCallBack = useCallback(
    async (nextPagination: NextPagination) => {
      console.log(nextPagination);
      const { offset, limit } = getNextParams(nextPagination, watchedPageInfo);

      setLoading(true);
      try {
        const res = await getInventoryByMaterial({
          searchTerms: [watchedSearchTerm ?? ''],
          type: watchedSearchType,
          stockTypes: [
            FetchingInventoryStockTypes.AVAILABLE,
            FetchingInventoryStockTypes.SALES,
          ],
          offset,
          limit,
        });

        tableMethods.setValue('records', res?.records);
        tableMethods.setValue('pageInfo', res?.pageInfo);
      } catch (err) {
        if (err instanceof Error) {
          Message.error(JSON.parse(err.message).message);
        } else {
          Message.error(JSON.stringify(err));
        }
      } finally {
        setLoading(false);
      }
    },

    [watchedSearchType, watchedSearchTerm, watchedPageInfo, setLoading]
  );

  const materialStockColumns = useMaterialStockColumns({
    openEditModal: (data: Record<string, unknown>) => {
      modalGroupController.openModal(Modals.EDIT, data);
    },
    openCancelBindModal: (data: Record<string, unknown>) => {
      const canOpenCancelBindModal = ((data): data is InventoryItem => {
        return (
          typeof !!data &&
          typeof data === 'object' &&
          'salesOrderId' in data &&
          typeof data.salesOrderId === 'string' &&
          'salesOrderLine' in data &&
          typeof data.salesOrderLine === 'string'
        );
      })(data);

      if (canOpenCancelBindModal) {
        modalGroupController.openModal(Modals.CANCEL_BIND, data);
      }
    },
  });

  return (
    <>
      <PageLayout title="庫存綁訂單">
        <BarcodeScanInput
          inputStyle={styles['barcode-scan-input']}
          label="物料條碼"
          otherOnKeyDownAction={async (searchTerm) => {
            await scannerKeyDownAction({
              searchTerm,
              searchType: InventoryByMaterialSearchType.MATERIAL_BARCODE,
            });
          }}
        />
        <BarcodeScanInput
          label="載具條碼"
          inputStyle={styles['barcode-scan-input']}
          otherOnKeyDownAction={async (searchTerm) => {
            await scannerKeyDownAction({
              searchTerm,
              searchType: InventoryByMaterialSearchType.LOADER_BARCODE,
            });
          }}
        />
        <BarcodeScanInput
          label="物料料號／品名"
          inputStyle={styles['barcode-scan-input']}
          otherOnKeyDownAction={async (searchTerm) => {
            await scannerKeyDownAction({
              searchTerm,
              searchType:
                InventoryByMaterialSearchType.MATERIAL_ID_OR_MATERIAL_NAME,
            });
          }}
        />
        <FormFieldsWrapper methods={tableMethods}>
          <MznPaginationTable
            scroll={{
              x: 2500,
            }}
            columns={materialStockColumns}
            dataSource={watchedRecords.map((row) => ({
              ...row,
              id: row.batchStoredLoaderRecordId,
            }))}
            loading={loading}
            fetchData={paginationCallBack}
            pageInfo={watchedPageInfo}
          />
        </FormFieldsWrapper>
      </PageLayout>
      <ModalGroup {...modalGroupController}>
        <Edit paginationCallBack={paginationCallBack} />
        <CancelBound paginationCallBack={paginationCallBack} />
      </ModalGroup>
    </>
  );
}
