import {
  Button,
  Tab,
  TabPane,
  Table,
  Tabs,
  Typography,
} from '@mezzanine-ui/react';
import styles from './recipe-table-page.module.scss';
import {
  AutoCompleteField,
  FormFieldsWrapper,
  InputField,
} from '@mezzanine-ui/react-hook-form';
import { useForm, useWatch } from 'react-hook-form';
import { Fragment, Key, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { API_NAMESPACE, ProductionFormCondition } from '@solar/data';
import { Recipe, RecipeRow, RecipeTableFormFields } from './typing';
import { useRecipeTableColumns } from './use-recipe-table-columns';
import { useLocationViewerPopup } from '../RoutingPage/use-location-viewer-popup';
import { useDrawingViewerPopup } from './use-drawing-viewer-popup';
import { ProductionFormPreviewModal } from '../ProductionForm/PreviewModal';
import { Space } from 'antd';
import { useAutoCompleteRecipeField } from '../MaterialAllocationsPage/useAutoCompleteRecipeField';

export function RecipeTablePage() {
  const [filterKey, setFilterKey] = useState(Date.now());
  const methods = useForm<RecipeTableFormFields>({
    defaultValues: {},
  });

  const RecipeAutoCompleteField = useAutoCompleteRecipeField({
    methods,
    enabled: true,
    registerName: 'recipe',
    label: '路徑碼',
    placeholder: 'FTPB029',
    style: { width: 300 },
    optionTransformer: (item) => ({
      id: item.id,
      name: `${item.id} [${item.group.name}_${item.featureCode.name}]`,
    }),
  });

  const [variables, setVariables] = useState<RecipeTableFormFields>({});

  const params = useMemo(() => {
    const params = new URLSearchParams();

    if (variables.recipe) {
      params.append('recipeId', variables.recipe.id);
    }

    if (variables.recipeVersion) {
      params.append('recipeVersion', variables.recipeVersion.id);
    }

    if (variables.finalDestinationMaterialId) {
      params.append(
        'finalDestinationMaterialId',
        variables.finalDestinationMaterialId
      );
    }

    if (variables.materialId) {
      params.append('materialId', variables.materialId);
    }

    if (variables.componentCode) {
      params.append('componentCode', variables.componentCode);
    }

    if (variables.legacyComponentCode) {
      params.append('legacyComponentCode', variables.legacyComponentCode);
    }

    if (variables.drawingCode) {
      params.append('drawingCode', variables.drawingCode);
    }

    if (variables.legacyDrawingCode) {
      params.append('legacyDrawingCode', variables.legacyDrawingCode);
    }

    return params.toString();
  }, [variables]);

  const { data } = useSWR<Recipe[]>(
    params ? [`/recipes?${params}`, { namespace: API_NAMESPACE.PP }] : undefined
  );

  const [activatedRecipeId, activateRecipeId] = useState<Key | null>(null);

  useEffect(() => {
    if (
      data?.length &&
      !data.find((recipe) => recipe.id === activatedRecipeId)
    ) {
      activateRecipeId(data[0].id);
    } else if (activatedRecipeId && !data?.length) {
      activateRecipeId(null);
    }
  }, [activatedRecipeId, data]);

  const [locationPopup, setLocationViewerAnchor] =
    useLocationViewerPopup(methods);

  const [drawingPopup, setDrawingViewerAnchor] = useDrawingViewerPopup(methods);

  const [previewingFormConditions, setPreviewingFormConditions] =
    useState<ProductionFormCondition>({});

  const columns = useRecipeTableColumns(
    methods,
    setLocationViewerAnchor,
    setDrawingViewerAnchor,
    setPreviewingFormConditions
  );

  const recipeMap = useMemo<Record<string, RecipeRow[]>>(() => {
    const recipes = data || [];

    return recipes.reduce(
      (vars, recipe) => ({
        ...vars,
        [recipe.id]: recipe.recipeRoutings
          .map((recipeRouting, recipeRoutingIndex) => {
            const baseSequence =
              recipe.recipeRoutings
                .slice(0, recipeRoutingIndex)
                .reduce(
                  (sum, recipeRouting) =>
                    sum + recipeRouting.routingGroup.routings.length,
                  0
                ) * 10;

            return recipeRouting.routingGroup.routings.map((routing, index) =>
              index === 0
                ? recipeRouting.bomGroup.inputs.map((input) => ({
                    id: `${recipe.id}-${routing.sequence + baseSequence}-${
                      input.inputMaterialId
                    }`,
                    recipeId: recipe.id,
                    recipeVersion: recipe.version,
                    workOrderSequence: routing.sequence,
                    sequence: routing.sequence + baseSequence,
                    producedMaterialFactoryId: recipeRouting.bomGroup.factoryId,
                    producedMaterialId:
                      recipeRouting.bomGroup.producedMaterialId,
                    standardOutputUnit:
                      recipeRouting.bomGroup.standardOutputUnit,
                    standardOutputWeight:
                      recipeRouting.bomGroup.standardOutputWeight,
                    inputMaterialId: input.inputMaterialId,
                    inputMaterialFactoryId: input.factoryId,
                    standardUnit: input.standardUnit,
                    standardWeight: input.standardWeight,
                    standardMachineMinutes: routing.standardMachineMinutes,
                    standardManPowerMinutes: routing.standardManPowerMinutes,
                    workCenter: routing.workCenter,
                    processing: routing.processing,
                    componentCode:
                      recipe.finalDestinationMaterial.componentCode,
                    drawingCode: recipe.finalDestinationMaterial.drawingCode,
                    recipeCodePrefix:
                      recipe.finalDestinationMaterial.recipeCodePrefix,
                    oldComponentCode:
                      recipe.finalDestinationMaterial.oldComponentCode,
                    oldDrawingCode:
                      recipe.finalDestinationMaterial.oldDrawingCode,
                  }))
                : {
                    id: `${recipe.id}-${routing.sequence + baseSequence}-${
                      recipeRouting.bomGroup.producedMaterialId
                    }`,
                    recipeId: recipe.id,
                    recipeVersion: recipe.version,
                    workOrderSequence: routing.sequence,
                    sequence: routing.sequence + baseSequence,
                    producedMaterialFactoryId: recipeRouting.bomGroup.factoryId,
                    producedMaterialId:
                      recipeRouting.bomGroup.producedMaterialId,
                    standardOutputUnit:
                      recipeRouting.bomGroup.standardOutputUnit,
                    standardOutputWeight:
                      recipeRouting.bomGroup.standardOutputWeight,
                    inputMaterialId: recipeRouting.bomGroup.producedMaterialId,
                    inputMaterialFactoryId: recipeRouting.bomGroup.factoryId,
                    standardUnit: recipeRouting.bomGroup.standardOutputUnit,
                    standardWeight: recipeRouting.bomGroup.standardOutputWeight,
                    standardMachineMinutes: routing.standardMachineMinutes,
                    standardManPowerMinutes: routing.standardManPowerMinutes,
                    workCenter: routing.workCenter,
                    processing: routing.processing,
                    componentCode:
                      recipe.finalDestinationMaterial.componentCode,
                    drawingCode: recipe.finalDestinationMaterial.drawingCode,
                    recipeCodePrefix:
                      recipe.finalDestinationMaterial.recipeCodePrefix,
                    oldComponentCode:
                      recipe.finalDestinationMaterial.oldComponentCode,
                    oldDrawingCode:
                      recipe.finalDestinationMaterial.oldDrawingCode,
                  }
            );
          })
          .flat()
          .flat(),
      }),
      {}
    );
  }, [data]);

  const formData = methods.watch();
  const { setValue } = methods;

  useEffect(() => {
    if (
      formData.finalDestinationMaterialId &&
      formData.finalDestinationMaterialId !==
        formData.finalDestinationMaterialId.toUpperCase()
    ) {
      setValue(
        'finalDestinationMaterialId',
        formData.finalDestinationMaterialId.toUpperCase()
      );
    }

    if (
      formData.materialId &&
      formData.materialId !== formData.materialId.toUpperCase()
    ) {
      setValue('materialId', formData.materialId.toUpperCase());
    }

    if (
      formData.componentCode &&
      formData.componentCode !== formData.componentCode.toUpperCase()
    ) {
      setValue('componentCode', formData.componentCode.toUpperCase());
    }

    if (
      formData.drawingCode &&
      formData.drawingCode !== formData.drawingCode.toUpperCase()
    ) {
      setValue('drawingCode', formData.drawingCode.toUpperCase());
    }

    if (
      formData.legacyComponentCode &&
      formData.legacyComponentCode !==
        formData.legacyComponentCode.toUpperCase()
    ) {
      setValue(
        'legacyComponentCode',
        formData.legacyComponentCode.toUpperCase()
      );
    }

    if (
      formData.legacyDrawingCode &&
      formData.legacyDrawingCode !== formData.legacyDrawingCode.toUpperCase()
    ) {
      setValue('legacyDrawingCode', formData.legacyDrawingCode.toUpperCase());
    }
  }, [formData, setValue]);

  const { data: recipeData } = useSWR<
    {
      id: string;
      featureCode: {
        code: string;
        name: string;
      };
      group: {
        code: string;
        name: string;
      };
    }[]
  >(['/pp/recipes', { namespace: API_NAMESPACE.PP }]);

  const recipes = useMemo(
    () =>
      (recipeData ?? []).map((recipe) => ({
        id: recipe.id,
        name: `${recipe.id} [${recipe.group.name}_${recipe.featureCode.name}]`,
      })),
    [recipeData]
  );

  const recipe = methods.watch('recipe');

  return (
    <div className={styles.wrapper}>
      <Typography variant="h2">生產路徑</Typography>
      <FormFieldsWrapper methods={methods} className={styles.controller}>
        <div style={{ paddingBottom: 20 }} className={styles.recipeContainer}>
          {/* <AutoCompleteField
            key={filterKey}
            options={recipes}
            label="路徑碼"
            placeholder="FTPB029"
            registerName="recipe"
          /> */}
          <Space>
            {RecipeAutoCompleteField}
            <AutoCompleteField
              required
              label="版次"
              options={methods.watch('recipeVersions') ?? []}
              registerName="recipeVersion"
            />
          </Space>
          {recipe ? (
            <span className={styles.recipeHelper}>
              {recipe.name.replace(/^[A-Z0-9]+\s\[(.+)\]$/i, '$1')}
            </span>
          ) : null}
        </div>
        <InputField
          label="最終料號"
          placeholder="FTRZ1MO2039PBFARX900X810XXX8W01"
          registerName="finalDestinationMaterialId"
        />
        <InputField
          label="任意料號"
          placeholder="FTRZ1MO2039PBX900X810XXX8W"
          registerName="materialId"
        />
        <InputField
          label="成分代碼"
          placeholder="MO2039"
          registerName="componentCode"
        />
        <InputField
          label="圖號"
          placeholder="FARX900X810XXX8W01"
          registerName="drawingCode"
        />
        <InputField
          label="成分代碼(舊)"
          placeholder="2214"
          registerName="legacyComponentCode"
        />
        <InputField
          label="圖號(舊)"
          placeholder="0960Y-3"
          registerName="legacyDrawingCode"
        />
        <div className={styles.actions}>
          <Button
            color="secondary"
            variant="outlined"
            type="button"
            onClick={() => {
              methods.reset({});

              setFilterKey(Date.now());
            }}
          >
            清除
          </Button>
          <Button
            onClick={() => setVariables(methods.getValues())}
            variant="contained"
            color="primary"
            type="submit"
          >
            查詢
          </Button>
        </div>
      </FormFieldsWrapper>
      {data?.length === 0 ? (
        <Typography variant="body1" color="error">
          找不到資料
        </Typography>
      ) : null}
      {activatedRecipeId ? (
        <Tabs
          activeKey={activatedRecipeId}
          onChange={activateRecipeId}
          className={styles.recipes}
        >
          {Object.entries(recipeMap).map(([id, recipeRoutings]) => (
            <TabPane key={id} tab={<Tab>{id}</Tab>}>
              <Fragment>
                <p className={styles.recipeLine}>
                  {recipes
                    .find((recipe) => recipe.id === id)
                    ?.name.replace(/^[A-Z0-9]+\s\[(.+)\]$/i, '$1') ?? ''}
                </p>
                <Table
                  scroll={{ x: 2800 }}
                  columns={columns}
                  dataSource={recipeRoutings}
                />
              </Fragment>
            </TabPane>
          ))}
        </Tabs>
      ) : null}
      <ProductionFormPreviewModal
        clear={() => setPreviewingFormConditions({})}
        {...previewingFormConditions}
      />
      {locationPopup}
      {drawingPopup}
    </div>
  );
}
