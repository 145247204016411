import { Button, Table, Typography } from '@mezzanine-ui/react';
import { useCallback, useMemo, useState } from 'react';
import {
  ReceivableItemTable,
  ReceivedMaterialItemTable,
} from '../IncomingInspectOperationPage/IncomingInspectDetailPage';

import { PageLayout } from '../PageLayout/PageLayout';

import {
  checkJWTRoles,
  MiscPurchaseOrderInnerRecord,
  useMiscPurchaseOrder,
} from '@solar/data';
import { useParams } from 'react-router-dom';

import { TableColumn } from '@mezzanine-ui/core/table';
import classes from '../IncomingInspectOperationPage/incoming-inspect-detail-page.module.scss';
import { WarehouseModule, WarehouseModuleZh } from '../constant';
import sharedStyles from '../shared-styles/index.module.scss';

import {
  CollapsibleContainer,
  ModalGroup,
  useModalGroupController,
} from '@solar/templates';
import { OrderMetaInfo } from '../components';
import { MiscellaneousStorageInModals } from './enum';

import { ReceiveActionModal } from './modals';
import { AcceptanceModal } from './modals/AcceptanceModal';
import { CancelModal } from './modals/CancelModal';

const META_TRANSLATION = {
  id: '採購單號',
  supplierName: '供應商名稱',
  supplierId: '供應商代碼',
  staffName: '採購人員',
  expectedCompletedAt: '物料收貨日',
  receivedState: '狀態',
} as const;

export function MiscellaneousStorageInDetailPage() {
  const { orderId } = useParams();

  const { orders } = useMiscPurchaseOrder({ orderId });

  const orderMetaDataLabelAndContext = useMemo(
    () =>
      orders
        ? Object.entries(META_TRANSLATION).map(([key, zh]) => ({
            label: zh,
            context: orders[key as keyof typeof META_TRANSLATION],
          }))
        : [],
    [orders]
  );

  const modalGroupController = useModalGroupController([
    { name: MiscellaneousStorageInModals.ReceiveAction, keepData: true },
    { name: MiscellaneousStorageInModals.Acceptance },
    { name: MiscellaneousStorageInModals.Cancel },
  ]);

  const receiveActionModalOpen = useCallback(
    (item: MiscPurchaseOrderInnerRecord | null) => {
      const modalProps = modalGroupController.getModalProps(
        MiscellaneousStorageInModals.ReceiveAction
      );
      const data = modalProps?.data;
      modalGroupController.openModal(
        MiscellaneousStorageInModals.ReceiveAction,
        { ...data, ...item }
      );
    },
    [modalGroupController]
  );

  const acceptanceModalOpen = useCallback(
    (item: { materialId: string }) => {
      modalGroupController.openModal(MiscellaneousStorageInModals.Acceptance, {
        ...item,
      });
    },
    [modalGroupController]
  );

  const cancelModalOpen = useCallback(
    (item: { materialId: string }) => {
      modalGroupController.openModal(MiscellaneousStorageInModals.Cancel, {
        ...item,
      });
    },
    [modalGroupController]
  );

  const column: TableColumn<MiscPurchaseOrderInnerRecord>[] = useMemo(
    () => [
      {
        title: '操作',
        width: 120,
        align: 'center' as const,
        render(source) {
          return (
            checkJWTRoles([91]) && (
              <Button
                type="button"
                variant="outlined"
                onClick={() => {
                  receiveActionModalOpen(source);
                }}
              >
                收料
              </Button>
            )
          );
        },
      },
      { title: '工廠', dataIndex: 'factoryId' },
      { title: '料號', dataIndex: 'miscMaterialIdFromSAP' },
      { title: '品名', dataIndex: 'materialDescription' },

      { title: '應收量', dataIndex: 'expectedQuantity' },
      { title: '已收量', dataIndex: 'receivedQuantity' },
      { title: '單位', dataIndex: 'inputUnit' },
    ],
    [receiveActionModalOpen]
  );

  const receivedMaterialColumn: TableColumn<ReceivedMaterialItemTable>[] =
    useMemo(
      () => [
        {
          title: '狀態',
          render: (source) => '',
        },
        { title: '品名', dataIndex: 'materialDescription' },
        { title: '品號', dataIndex: 'materialId' },
        { title: '收料量', dataIndex: 'mainMaterialUnitQuantity' },
        { title: '單位', render: () => '個' },
        { title: '儲位', dataIndex: 'warehouse.name' },
        {
          title: '收貨時間',
          render: () => '2023/8/22 17:01:43',
        },
      ],
      []
    );
  const [receivedMaterialTableData, setReceivedMaterialTableData] = useState<
    ReceivedMaterialItemTable[]
  >([]);
  const [tempRowMaterialData, setTempRowMaterialData] = useState<Omit<
    ReceivedMaterialItemTable,
    'loader'
  > | null>(null);

  return (
    <>
      <PageLayout setGoBack>
        <Typography variant="h1">
          {WarehouseModuleZh[WarehouseModule.MiscellaneousStorageIn]}
        </Typography>
        <div className={classes['row-block']}>
          <OrderMetaInfo
            labelAndContexts={orderMetaDataLabelAndContext ?? []}
          />
        </div>
        <CollapsibleContainer
          enableRowBlockStyle
          open
          header={
            <Typography variant="h4" color="text-primary">
              應收項目
            </Typography>
          }
        >
          <Table
            columns={column}
            dataSource={orders?.items ?? []}
            bodyClassName={sharedStyles.table}
          />
        </CollapsibleContainer>
        <div className={classes['row-block']}>
          <Typography variant="h4" color="text-primary">
            已收紀錄
          </Typography>
          <Table
            columns={receivedMaterialColumn}
            dataSource={[]}
            bodyClassName={sharedStyles.table}
          />
        </div>
      </PageLayout>
      <ModalGroup {...modalGroupController}>
        <ReceiveActionModal<ReceivableItemTable>
          setReceivedMaterialTableData={setReceivedMaterialTableData}
          tempRowMaterialData={tempRowMaterialData}
          setTempRowMaterialData={setTempRowMaterialData}
        />
        <AcceptanceModal />
        <CancelModal />
      </ModalGroup>
    </>
  );
}
