import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import { API_NAMESPACE } from '../../request';
import { FilterDataType } from '../mainFile';

function debounce(func: (...args: any[]) => void, delay: number) {
  let timeoutId: ReturnType<typeof setTimeout>;

  return (...args: any[]) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

const namespace = API_NAMESPACE.HR;

export type PackagingFilters = Record<
  'materials' | 'packagingTypes' | 'selectByMaterials',
  FilterDataType[]
>;

export function usePackagingFilter() {
  const layoutMethods = useForm();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<PackagingFilters | null>(null);
  const [queryParams, setQueryParams] = useState({ materialKeyword: '' });

  const { data: swrData } = useSWR([
    '/packaging/get/filter',
    { namespace, params: queryParams },
  ]);

  const isPackagingFilters = useCallback(
    (object: unknown): object is PackagingFilters => {
      if (object && typeof object === 'object') return 'materials' in object;
      return false;
    },
    []
  );

  const clearSelectByMaterials = () => {
    setData((old) => (old ? { ...old, selectByMaterials: [] } : null));
  };

  const handleFilterByKeyword = debounce((value: string) => {
    if (!value || value === '') {
      setQueryParams((params) => ({ ...params, materialKeyword: '' }));
      return;
    }
    setQueryParams((params) => ({ ...params, materialKeyword: value }));
  }, 300);

  useEffect(() => {
    if (!isPackagingFilters(swrData)) {
      setLoading(true);
      return;
    }

    setData(swrData);
    setLoading(false);
  }, [swrData]);

  return {
    queryParams,
    layoutMethods,
    loading,
    data,
    setData,
    isPackagingFilters,
    clearSelectByMaterials,
    handleFilterByKeyword,
  };
}
