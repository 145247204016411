import {
  Button,
  Switch,
  Tab,
  TabPane,
  Tabs,
  Typography,
  cx,
} from '@mezzanine-ui/react';
import styles from './recipe-page.module.scss';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import { useForm } from 'react-hook-form';
import { Fragment, Key, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { API_NAMESPACE } from '@solar/data';
import { Recipe } from './typing';

interface FormFields {
  materialId: string;
}

export function RecipePage() {
  const methods = useForm<FormFields>({
    defaultValues: {
      materialId: 'EC11NI2007VAFFX183XX15',
    },
  });

  const [queryMaterialId, setQueryMaterialId] = useState('');
  const [onlyDest, setOnlyDest] = useState(true);
  const [activatedRecipeId, activateRecipeId] = useState<Key | null>(null);

  const { data } = useSWR<Recipe[]>(
    queryMaterialId
      ? [
          `/recipes?${
            onlyDest ? 'finalDestinationMaterialId' : 'materialId'
          }=${queryMaterialId}`,
          { namespace: API_NAMESPACE.PP },
        ]
      : undefined
  );

  useEffect(() => {
    if (
      data?.length &&
      !data.find((recipe) => recipe.id === activatedRecipeId)
    ) {
      activateRecipeId(data[0].id);
    } else if (activatedRecipeId && !data?.length) {
      activateRecipeId(null);
    }
  }, [activatedRecipeId, data]);

  const recipes = useMemo(() => {
    if (onlyDest) return data || [];

    return (data || []).map((recipe) => {
      const targetIndex = recipe.recipeRoutings.findIndex(
        (recipeRouting) =>
          recipeRouting.bomGroup.producedMaterialId === queryMaterialId
      );

      return {
        ...recipe,
        recipeRoutings: recipe.recipeRoutings
          .slice(0, targetIndex + 1)
          .reverse(),
      };
    });
  }, [data, onlyDest, queryMaterialId]);

  return (
    <div className={styles.wrapper}>
      <Typography variant="h2">生產路徑 Recipe</Typography>
      <FormFieldsWrapper methods={methods} className={styles.controller}>
        <InputField label="物料編號" registerName="materialId" />
        <Button
          onClick={() => setQueryMaterialId(methods.getValues('materialId'))}
          variant="contained"
          color="primary"
        >
          查詢
        </Button>
        <div className={styles.onlyAfterController}>
          <Switch
            checked={onlyDest}
            onChange={() => setOnlyDest(!onlyDest)}
            size="large"
          />
          <span className={styles.onlyAfterDesc}>僅查詢最終料號</span>
        </div>
      </FormFieldsWrapper>
      {data?.length === 0 ? (
        <Typography variant="body1" color="error">
          找不到料號 {queryMaterialId} 的 Recipe
        </Typography>
      ) : null}
      {activatedRecipeId ? (
        <Tabs
          activeKey={activatedRecipeId}
          onChange={activateRecipeId}
          className={styles.recipes}
        >
          {recipes.map((recipe) => (
            <TabPane key={recipe.id} tab={<Tab>{recipe.id}</Tab>}>
              <div className={styles.recipe}>
                {recipe.recipeRoutings.reverse().map((recipeRouting, index) => (
                  <Fragment key={recipeRouting.id}>
                    {index !== 0 ? (
                      <span className={styles.recipeRoutingDivider} />
                    ) : null}
                    <div className={styles.recipeRouting}>
                      <div className={styles.bomGroup}>
                        <Typography variant="h4">
                          BOM
                          <span className={styles.titleDecoration}>
                            id: {recipeRouting.bomGroup.bomId} [
                            {recipeRouting.bomGroup.factoryId}]
                          </span>
                        </Typography>
                        <Typography
                          variant="h5"
                          className={styles.titleWithDivider}
                        >
                          投入
                        </Typography>
                        {recipeRouting.bomGroup.inputs.map((input) => (
                          <Fragment key={input.inputMaterialId}>
                            <p className={styles.dataLine}>
                              <span className={styles.dataLabel}>料號</span>
                              <span className={styles.dataValue}>
                                {input.inputMaterialId}
                              </span>
                            </p>
                            <p className={styles.dataLine}>
                              <span className={styles.dataLabel}>
                                標準投入量
                              </span>
                              <span className={styles.dataValue}>
                                {input.standardWeight} {input.standardUnit}
                              </span>
                            </p>
                          </Fragment>
                        ))}
                        <Typography
                          variant="h5"
                          className={styles.titleWithDivider}
                        >
                          產出
                        </Typography>
                        <p className={styles.dataLine}>
                          <span className={styles.dataLabel}>料號</span>
                          <span
                            className={cx(
                              styles.dataValue,
                              recipeRouting.bomGroup.producedMaterialId ===
                                queryMaterialId && styles.dataValueHighlight
                            )}
                          >
                            {recipeRouting.bomGroup.producedMaterialId}
                          </span>
                        </p>
                        <p className={styles.dataLine}>
                          <span className={styles.dataLabel}>標準產出量</span>
                          <span className={styles.dataValue}>
                            {recipeRouting.bomGroup.standardOutputWeight}{' '}
                            {recipeRouting.bomGroup.standardOutputUnit}
                          </span>
                        </p>
                      </div>
                      <div className={styles.routingGroup}>
                        <Typography variant="h4">
                          Routing
                          <span className={styles.titleDecoration}>
                            id: {recipeRouting.routingGroup.id} ver.{' '}
                            {recipeRouting.routingGroup.version} [
                            {recipeRouting.routingGroup.factoryId}]
                          </span>
                        </Typography>
                        {recipeRouting.routingGroup.routings.map((routing) => (
                          <div className={styles.routing} key={routing.id}>
                            <Typography
                              variant="h5"
                              className={styles.titleWithDivider}
                            >
                              <span className={styles.sequence}>
                                {routing.sequence}
                              </span>{' '}
                              {routing.processing.name} @{' '}
                              {routing.workCenter.name}
                            </Typography>
                            <p className={styles.dataLine}>
                              <span className={styles.dataLabel}>製程</span>
                              <span className={styles.dataValue}>
                                [{routing.processing.id}]{' '}
                                {routing.processing.name}
                              </span>
                            </p>
                            <p className={styles.dataLine}>
                              <span className={styles.dataLabel}>工作中心</span>
                              <span className={styles.dataValue}>
                                [{routing.workCenter.id}]{' '}
                                {routing.workCenter.name}
                              </span>
                            </p>
                            <p className={styles.dataLine}>
                              <span className={styles.dataLabel}>標準機分</span>
                              <span className={styles.dataValue}>
                                {routing.standardMachineMinutes}
                              </span>
                            </p>
                            <p className={styles.dataLine}>
                              <span className={styles.dataLabel}>標準人分</span>
                              <span className={styles.dataValue}>
                                {routing.standardManPowerMinutes}
                              </span>
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Fragment>
                ))}
              </div>
            </TabPane>
          ))}
        </Tabs>
      ) : null}
    </div>
  );
}
