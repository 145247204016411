import {
  CopyTextWrapper,
  ModalGroup,
  PageWrapper,
  useModalGroupController,
} from '@solar/templates';
import { IncomingQualityControlTable } from '../../Qualifications/IncomingQualityControlTable/IncomingQualityControlTable';
import { IQCOrderType, IncomingPurchaseOrder } from '@solar/data';
import { Space } from 'antd';
import { Typography } from '@mezzanine-ui/react';
import { StoredLoaderRecordsTable } from './StoredLoaderRecordsTable';
import {
  RETURN_STORED_LOADER_RECORD_MODAL,
  ReturnStoredLoaderRecordModal,
} from './ReturnStoredLoaderRecordModal';
import { useEffect } from 'react';

export function ReturnPurchaseOrderPage() {
  const modalGroupController = useModalGroupController([
    { name: RETURN_STORED_LOADER_RECORD_MODAL },
  ]);
  return (
    <PageWrapper title="採購單退貨">
      <ModalGroup {...modalGroupController}>
        <IncomingQualityControlTable
          iqcOrderType={IQCOrderType.PURCHASE_ORDER}
          statusIds={[10]}
          purchaseOrderType="INVENTORY"
          getColumns={() => [
            {
              width: 150,
              title: '採購單號',
              render: (source) => (
                <CopyTextWrapper
                  text={(() => {
                    switch (source?.purchaseOrderType) {
                      case 'INVENTORY':
                        return source?.inventoryPurchaseOrderId as string;
                      case 'MISC':
                        return source?.miscPurchaseOrderId as string;
                      case 'OUTSOURCE':
                        return source?.outsourcePurchaseOrderId as string;
                      default:
                        return '';
                    }
                  })()}
                />
              ),
            },
            {
              width: 100,
              title: '項次',
              render: (source) => (
                <CopyTextWrapper
                  text={(() => {
                    switch (source?.purchaseOrderType) {
                      case 'INVENTORY':
                        return source?.inventoryPurchaseOrderItemId as string;
                      case 'MISC':
                        return source?.miscPurchaseOrderItemId as string;
                      case 'OUTSOURCE':
                        return source?.outsourcePurchaseOrderItemId as string;
                      default:
                        return '';
                    }
                  })()}
                />
              ),
            },
            {
              width: 300,
              title: '料號',
              render: (source: any) => (
                <CopyTextWrapper text={source?.materialId ?? ''} />
              ),
            },
            {
              width: 500,
              title: '品名',
              render: (source: any) => (
                <CopyTextWrapper text={source?.material?.description ?? ''} />
              ),
            },
            {
              dataIndex: 'waiverNumber',
              title: '特採單號',
            },
            {
              title: '倉管備註',
              dataIndex: 'warehouseNote',
            },
            {
              title: '檢驗備註',
              dataIndex: 'qcNote',
            },
          ]}
          expandable={{
            rowExpandable: () => true,
            expandedRowRender: (source: IncomingPurchaseOrder) => (
              <Space direction="vertical" style={{ paddingBottom: 12 }}>
                <StoredLoaderRecordsTable iqcOrderId={source?.id?.toString()} />
              </Space>
            ),
          }}
        />
        <ReturnStoredLoaderRecordModal />
      </ModalGroup>
    </PageWrapper>
  );
}
