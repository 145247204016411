import { Button, Table } from "@mezzanine-ui/react";
import { UpdateStackModal } from "./UpdateStackModal";
import { UpdateMaterialIdModal } from "./UpdateMaterialIdModal";
import { useModalController } from "@solar/data";
import styles from './material-transfer-table.module.scss';
import { MergeMaterialBatchModal } from "./MergeMaterialBatchModal";
import { SplitMaterialBatchModal } from "./SplitMaterialBatchModal";
import { useState } from "react";
import { columns } from "./SelectedMaterialBatchList";

type MaterialTransferTableProps = {
  materials: any,
  pageInfo: any,
  mutateGetMaterials: (payload: any) => void,
};

export function MaterialTransferTable({ materials, pageInfo, mutateGetMaterials }: MaterialTransferTableProps) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const updateStackModalController = useModalController();
  const updateMaterialIdModalController = useModalController();
  const mergeMaterialBatchModalController = useModalController();
  const splitMaterialBatchModalController = useModalController();

  const isSingleSelection = selectedRowKeys?.length === 1;
  const isMultipleSelection = selectedRowKeys?.length > 1;
  const haveSameMaterialId = true;

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'end', gap: 'var(--mzn-spacing-4)', marginBottom: 'var(--mzn-spacing-4)' }}>
        <Button
          variant="contained"
          disabled={!(isMultipleSelection && haveSameMaterialId)}
          onClick={() => mergeMaterialBatchModalController.openModal({ selectedRowKeys })}>
          批號合併
        </Button>
        <Button
          variant="contained"
          disabled={!isSingleSelection}
          onClick={() => splitMaterialBatchModalController.openModal({ selectedRowKeys })}>
          批號拆分
        </Button>
        <Button
          variant="contained"
          disabled={!(isMultipleSelection && haveSameMaterialId || isSingleSelection)}
          onClick={() => updateMaterialIdModalController.openModal({ selectedRowKeys })}>
          料號變更
        </Button>
        <Button
          variant="contained"
          disabled={!(selectedRowKeys?.length > 0)}
          onClick={() => updateStackModalController.openModal({ selectedRowKeys })}>
          儲位變更
        </Button>
      </div>
      <Table
        // loading={isLoading}
        pagination={{
          disableAutoSlicing: true,
          total: pageInfo?.totalCount,
          current: pageInfo?.page,
          onChange: (nextPage) => mutateGetMaterials({ nextPage }),
          options: {
            pageSize: pageInfo?.pageSize ?? 20,
            pageSizeLabel: '每頁顯示：',
            pageSizeUnit: '筆',
            onChangePageSize: (nextPageSize) => mutateGetMaterials({ nextPage: 1, nextPageSize }),
            renderPageSizeOptionName: (p) => `${p}`,
            renderPaginationSummary: (start, end) => `目前顯示 ${start} - ${end} 筆，共 ${end - start + 1} 筆資料`,
            showPageSizeOptions: true, // 開啟每頁顯示 N 筆
            showJumper: true, // 開啟跳頁功能
            jumperButtonText: '前往',
            jumperHintText: '跳至',
          }
        }}
        rowSelection={{
          selectedRowKey: selectedRowKeys,
          onChange: setSelectedRowKeys,
        }}
        bodyClassName={styles.table}
        dataSource={materials}
        columns={columns} />
      <UpdateStackModal
        {...updateStackModalController}
        onSubmit={console.log} />
      <UpdateMaterialIdModal
        {...updateMaterialIdModalController}
        onSubmit={console.log}  />
      <MergeMaterialBatchModal {...mergeMaterialBatchModalController} onSubmit={console.log} />
      <SplitMaterialBatchModal {...splitMaterialBatchModalController} onSubmit={console.log} />
    </div>
  )
}