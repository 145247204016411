import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { LocationLevel } from '../types';

export function useLocationSVGPopover(
  svgWrapperRef: RefObject<HTMLDivElement>
) {
  const popoverRef = useRef<HTMLDivElement>(null);
  const popoverWrapperRef = useRef<HTMLDivElement>(null);
  const [locked, setLocked] = useState(false);
  const [popoverId, setPopoverId] = useState<string | null>();
  const [popoverElement, setPopoverElement] = useState<Element>();
  const [popoverBox, setPopoverBox] = useState<{
    height: number;
    width: number;
    left: number;
    top: number;
  }>();

  const closePopover = useCallback(() => {
    setLocked(false);
    const popoverWrapper = popoverWrapperRef?.current;
    const polygonList = popoverWrapper?.querySelectorAll('polygon');
    setPopoverId(null);
    setPopoverElement(undefined);
    setPopoverBox(undefined);
    polygonList?.forEach((polygon) => {
      polygon?.setAttribute('Popover', 'false');
    });
  }, [popoverWrapperRef]);

  useEffect(() => {
    let mouseLeaveTimer: ReturnType<typeof setTimeout>;
    const popoverWrapper = popoverWrapperRef?.current;
    const polygonList = popoverWrapper?.querySelectorAll('polygon');

    const handleRightClick = (event: MouseEvent) => {
      event.preventDefault();
      setLocked((prev) => !prev);
    };

    const handleMouseEnter = (event: MouseEvent) => {
      clearTimeout(mouseLeaveTimer);
      if (!locked) {
        const element = event?.target as SVGElement;
        const id = element.getAttribute('id');
        const level = element.getAttribute('level') as LocationLevel;
        const category = element.getAttribute('category');
        const levelSet = new Set([
          LocationLevel.ZONE,
          LocationLevel.STACK,
          LocationLevel.SHELF,
        ]);

        if (
          id !== popoverId &&
          (levelSet.has(level) || category === 'Equipment')
        ) {
          polygonList?.forEach((polygon) => {
            polygon?.setAttribute('Popover', 'false');
          });
          element?.setAttribute('Popover', 'true');
          const elementRect = element?.getBoundingClientRect();
          const anchorRect = popoverWrapperRef.current?.getBoundingClientRect();
          setPopoverId(id);
          setPopoverElement(element);
          setPopoverBox({
            height: elementRect?.height ?? 0,
            width: elementRect?.width ?? 0,
            left: elementRect?.x - (anchorRect?.x ?? 0),
            top: elementRect?.y - (anchorRect?.y ?? 0),
          });
        }
      }
    };

    const handleMouseLeave = (event: MouseEvent) => {
      mouseLeaveTimer = setTimeout(() => {
        if (!locked) closePopover();
      }, 200);
    };

    polygonList?.forEach((element) => {
      element.addEventListener('contextmenu', handleRightClick);
      element.addEventListener('mouseenter', handleMouseEnter);
      element.addEventListener('mouseleave', handleMouseLeave);
    });

    return () => {
      clearTimeout(mouseLeaveTimer);
      polygonList?.forEach((element) => {
        element.removeEventListener('contextmenu', handleRightClick);
        element.removeEventListener('mouseenter', handleMouseEnter);
        element.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, [
    popoverId,
    popoverWrapperRef,
    svgWrapperRef.current?.innerHTML,
    closePopover,
    locked,
  ]);

  return {
    open: !!popoverId && !!popoverBox,
    locked,
    popoverRef,
    popoverWrapperRef,
    popoverId,
    popoverElement,
    popoverBox,
    closePopover,
  };
}
