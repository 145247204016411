import { getRoleGroup } from './shared';

const DEFAULT_SPEC = {
  FF: {
    name: '未分類',
    nextLevel: {
      '01': {
        name: '未分類',
      },
    },
  },
};

const DEFAULT_SMALL_GROUP = {
  1: {
    name: '未分類',
    nextLevel: getRoleGroup(DEFAULT_SPEC),
  },
};

const ALKALINE_SMALL_GROUP = {
  A: {
    name: '氨水',
    nextLevel: getRoleGroup(DEFAULT_SPEC),
  },
  1: {
    name: '未分類',
    nextLevel: getRoleGroup(DEFAULT_SPEC),
  },
};

const ACID_SMALL_GROUP = {
  N: {
    name: '硝酸',
    nextLevel: getRoleGroup(DEFAULT_SPEC),
  },
  S: {
    name: '硫酸',
    nextLevel: getRoleGroup(DEFAULT_SPEC),
  },
  H: {
    name: '甲酸',
    nextLevel: getRoleGroup(DEFAULT_SPEC),
  },
  C: {
    name: '鹽酸',
    nextLevel: getRoleGroup(DEFAULT_SPEC),
  },
  A: {
    name: '硫氰酸銨',
    nextLevel: getRoleGroup(DEFAULT_SPEC),
  },
  B: {
    name: '氫溴酸',
    nextLevel: getRoleGroup(DEFAULT_SPEC),
  },
  1: {
    name: '未分類',
    nextLevel: getRoleGroup(DEFAULT_SPEC),
  },
};

const LIQUID_MEDIUM_GROUP = {
  W: {
    name: '王水液',
    nextLevel: DEFAULT_SMALL_GROUP,
  },
  P: {
    name: '剝金液',
    nextLevel: DEFAULT_SMALL_GROUP,
  },
  C: {
    name: '電鍍液',
    nextLevel: DEFAULT_SMALL_GROUP,
  },
  G: {
    name: '硝酸銀',
    nextLevel: DEFAULT_SMALL_GROUP,
  },
  A: {
    name: '酸液',
    nextLevel: ACID_SMALL_GROUP,
  },
  T: {
    name: '二氯四氨鈀',
    nextLevel: DEFAULT_SMALL_GROUP,
  },
  B: {
    name: '鹼液',
    nextLevel: ALKALINE_SMALL_GROUP,
  },
};

const CYANIDE_SECOND_SPEC = {
  C1: {
    name: '全回收',
  },
  '01': {
    name: '未分類',
  },
};

const CYANIDE_FIRST_SPEC = {
  CL: {
    name: '長華科銀鉀,製程多一天淨化製程，與一般銀鉀成本不同',
    nextLevel: CYANIDE_SECOND_SPEC,
  },
  FF: {
    name: '未分類',
    nextLevel: CYANIDE_SECOND_SPEC,
  },
};

const CYANIDE_SMALL_GROUP = {
  1: {
    name: '未分類',
    nextLevel: getRoleGroup(CYANIDE_FIRST_SPEC),
  },
};

const CYANIDE_MEDIUM_GROUP = {
  U: {
    name: '氰化金鉀',
    nextLevel: CYANIDE_SMALL_GROUP,
  },
  N: {
    name: '氰化銀',
    nextLevel: CYANIDE_SMALL_GROUP,
  },
  G: {
    name: '氰化銀鉀',
    nextLevel: CYANIDE_SMALL_GROUP,
  },
  K: {
    name: '氰化鉀',
    nextLevel: CYANIDE_SMALL_GROUP,
  },
  A: {
    name: '氰化鈉',
    nextLevel: CYANIDE_SMALL_GROUP,
  },
};

const ACID_MEDIUM_GROUP = {
  G: {
    name: '硝酸銀',
    nextLevel: DEFAULT_SMALL_GROUP,
  },
  M: {
    name: '硫酸錳',
    nextLevel: DEFAULT_SMALL_GROUP,
  },
};

const CATALYST_MEDIUM_GROUP = {
  A: {
    name: '碳鉑觸媒',
    nextLevel: DEFAULT_SMALL_GROUP,
  },
  B: {
    name: '碳基鈀觸媒',
    nextLevel: DEFAULT_SMALL_GROUP,
  },
  C: {
    name: '活性碳',
    nextLevel: DEFAULT_SMALL_GROUP,
  },
};

export const CHEMICAL_LARGE_GROUP = {
  L: {
    name: '液體',
    nextLevel: LIQUID_MEDIUM_GROUP,
  },
  N: {
    name: '氰化物',
    nextLevel: CYANIDE_MEDIUM_GROUP,
  },
  A: {
    name: '酸類',
    nextLevel: ACID_MEDIUM_GROUP,
  },
  C: {
    name: '酸類',
    nextLevel: CATALYST_MEDIUM_GROUP,
  },
};
