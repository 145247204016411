import {
  CSSProperties,
  ReactNode,
  RefObject,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import styles from './location-svg.module.scss';
import { Space } from 'antd';
import { Equipment, useGetStackJoinEquipmentsAndMaterials } from '@solar/data';
import { useLocationSVGPopover } from './useLocationSVGPopover';
import {
  Icon,
  IconButton,
  Loading,
  Popover,
  Table,
  Typography,
} from '@mezzanine-ui/react';
import { CancelIcon } from '@mezzanine-ui/icons';

type LocationSVGPopoverProps = {
  svgWrapperRef: RefObject<HTMLDivElement>;
  style?: CSSProperties;
  children: ReactNode;
};

export function LocationSVGPopover({
  svgWrapperRef,
  style,
  children,
}: LocationSVGPopoverProps) {
  const anchorRef = useRef<HTMLDivElement>(null);
  const {
    locked,
    popoverWrapperRef,
    popoverId,
    popoverElement,
    popoverBox,
    closePopover,
  } = useLocationSVGPopover(svgWrapperRef);
  const { stackJoinEquipmentsAndMaterials, isLoading } =
    useGetStackJoinEquipmentsAndMaterials(popoverId ?? undefined);
  const equipments = stackJoinEquipmentsAndMaterials?.equipments;
  const materials =
    stackJoinEquipmentsAndMaterials?.id === popoverId
      ? stackJoinEquipmentsAndMaterials?.materials
      : stackJoinEquipmentsAndMaterials?.shelfMaterials?.find(
          (item) => item?.shelf_id === popoverId
        )?.materials;

  const category = useMemo(
    () => popoverElement?.getAttribute('category')?.toLowerCase(),
    [popoverElement]
  );

  return (
    <div
      ref={popoverWrapperRef}
      className={styles.svg_with_popover}
      style={style}
    >
      <Popover
        key={popoverId}
        style={{ borderColor: 'var(--mzn-color-primary)' }}
        anchor={anchorRef.current}
        open={!!popoverId && !!popoverBox}
        title={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h4" color="primary">
              位置：{popoverId}
            </Typography>
            <IconButton size="small" color="primary" onClick={closePopover}>
              <Icon icon={CancelIcon} />
            </IconButton>
          </div>
        }
      >
        <Loading stretch loading={isLoading}>
          <Space
            direction="vertical"
            size={12}
            style={{ width: 500, maxHeight: 400, overflow: 'scroll' }}
          >
            {category !== 'warehouse' && (
              <Space direction="vertical">
                <ul style={{ margin: 0, padding: 0 }}>
                  <span>設備</span>
                  {equipments?.map((equipment) => (
                    <li key={equipment?.id}>
                      {equipment?.workCenter?.id
                        ? `${equipment?.workCenter?.id} / `
                        : ''}
                      {equipment?.name}
                    </li>
                  ))}
                </ul>
              </Space>
            )}
            {locked ? (
              <Space direction="vertical">
                <ul style={{ margin: 0, padding: 0 }}>
                  <span>物料</span>
                  {materials?.map((material) => (
                    <li key={material?.material_id}>
                      {`${material?.material_id} / ${material?.main_material_unit_quantity} ${material?.main_material_unit_name}`}
                    </li>
                  )) ?? <div>(無)</div>}
                </ul>
              </Space>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography color="primary" variant="h6">
                  右鍵展開物料資訊
                </Typography>
              </div>
            )}
          </Space>
        </Loading>
      </Popover>
      <div
        ref={anchorRef}
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          ...popoverBox,
        }}
      />
      {children}
    </div>
  );
}
