import { FlowAnalysisGraph } from '@ant-design/graphs';
import { DagreLayout } from '@antv/layout';
import useSWR from 'swr';
import { request } from '@solar/data';
import { FlowAnalysisGraphConfig } from '@ant-design/charts';
import Spreadsheet from 'x-data-spreadsheet';
import styles from './work-order-page.module.scss';
import { Button, Message, SelectValue, Typography, cx } from '@mezzanine-ui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { groupBy, toArray, uniqBy } from 'lodash';
import { useForm } from 'react-hook-form';
import { FormFieldsWrapper, InputField, SelectField } from '@mezzanine-ui/react-hook-form';
import { API_NAMESPACE } from '@solar/data';

declare module '@ant-design/charts' {
  interface EdgeCfg {
    target?: string;
  }
}

interface RoutingItem {
  materialId: string;
  newMaterialId: string;
  recipe: string;
  version: number;
  area: string;
  areaDesc: string;
  index: number;
  stage: string;
  internalCode: string;
  action: string;
  sapWorkCenterId: string;
  sapWorkCenter: string;
  workCenterId: string;
  workCenter: string;
  locationId: string;
  inUnit: string;
  outUnit: string;
}

interface SavedData {
  materialId: string;
  newMaterialId: string;
  recipe: string;
  version: number;
  area: string;
  areaDesc: string;
  rows: any[];
}

const CHART_DEFAULT_DATA = {};

const FIRST_ROW_CELLS = [
  { text: '#/機台/工作中心', style: 0, editable: false },
  { text: '起源料號', style: 0, editable: false },
  { text: '投料量', style: 0, editable: false },
  { text: '單位', style: 0, editable: false },
  { text: '產出料號', style: 0, editable: false },
  { text: '產出量', style: 0, editable: false },
  { text: '單位', style: 0, editable: false },
  { text: 'Location', style: 0, editable: false },
  { text: '工作中心代碼', style: 0, editable: false },
  { text: '工作中心(勿改)', style: 0, editable: false },
];

const DEFAULT_SHEET_DATA = {
  cols: {
    '0': { width: 140 },
    '1': { width: 100 },
    '2': { width: 50 },
    '3': { width: 40 },
    '4': { width: 100 },
    '5': { width: 50 },
    '6': { width: 40 },
    '8': { width: 100 },
    '9': { width: 180 },
    len: 10,
  },
  rows: {
    '0': {
      cells: FIRST_ROW_CELLS,
    },
    '1': {
      cells: [
        { text: '氧化銦酸溶設備' },
        { text: '銦水花' },
        { text: '100' },
        { text: 'g' },
        { text: '已酸溶In' },
        { text: '100' },
        { text: 'g' },
      ],
    },
    '2': {
      cells: [
        { text: '氧化銦中和設備' },
        { text: '已酸溶In' },
        { text: '100' },
        { text: 'g' },
        { text: '已中和In' },
        { text: '100' },
        { text: 'g' },
      ],
    },
    '3': {
      cells: [
        { text: '氧化銦水洗設備' },
        { text: '已中和In' },
        { text: '100' },
        { text: 'g' },
        { text: '已水洗In' },
        { text: '100' },
        { text: 'g' },
      ],
    },
    '4': {
      cells: [
        { text: '雙開過濾式台車乾燥爐 A' },
        { text: '已水洗In' },
        { text: '100' },
        { text: 'g' },
        { text: '已烘乾In' },
        { text: '100' },
        { text: 'g' },
      ],
    },
    '5': {
      cells: [
        { text: '台車式煆燒爐 LH-3500TA-EB2 A' },
        { text: '已烘乾In' },
        { text: '100' },
        { text: 'g' },
        { text: '煆燒銦粉' },
        { text: '100' },
        { text: 'g' },
      ],
    },
    '6': {
      cells: [
        { text: '氧化錫酸溶設備' },
        { text: '錫水花' },
        { text: '100' },
        { text: 'g' },
        { text: '已酸溶Sn' },
        { text: '100' },
        { text: 'g' },
      ],
    },
    '7': {
      cells: [
        { text: '氧化錫中和設備' },
        { text: '已酸溶Sn' },
        { text: '100' },
        { text: 'g' },
        { text: '已中和Sn' },
        { text: '100' },
        { text: 'g' },
      ],
    },
    '8': {
      cells: [
        { text: '氧化錫水洗設備' },
        { text: '已中和Sn' },
        { text: '100' },
        { text: 'g' },
        { text: '已水洗Sn' },
        { text: '100' },
        { text: 'g' },
      ],
    },
    '9': {
      cells: [
        { text: '雙開過濾式台車乾燥爐 B' },
        { text: '已水洗Sn' },
        { text: '100' },
        { text: 'g' },
        { text: '已烘乾Sn' },
        { text: '100' },
        { text: 'g' },
      ],
    },
    '10': {
      cells: [
        { text: '台車式煆燒爐 LH-3500TA-EB2 B' },
        { text: '已烘乾Sn' },
        { text: '100' },
        { text: 'g' },
        { text: '煆燒錫粉' },
        { text: '100' },
        { text: 'g' },
      ],
    },
    '11': {
      cells: [
        { text: '球磨機-03' },
        { text: '煆燒銦粉' },
        { text: '100' },
        { text: 'g' },
        { text: 'In2O3 球' },
        { text: '200' },
        { text: 'g' },
      ],
    },
    '12': {
      cells: [
        { text: '球磨機-03' },
        { text: '煆燒錫粉' },
        { text: '100' },
        { text: 'g' },
      ],
    },
    '13': {
      cells: [
        { text: '噴霧造粒機(BDP-22E)' },
        { text: 'In2O3 球' },
        { text: '200' },
        { text: 'g' },
        { text: 'In2O3 粒' },
        { text: '200' },
        { text: 'g' },
      ],
    },
    '14': {
      cells: [
        { text: 'CIP02' },
        { text: 'In2O3 粒' },
        { text: '200' },
        { text: 'g' },
        { text: 'In2O3 已成形' },
        { text: '200' },
        { text: 'g' },
      ],
    },
    '15': {
      cells: [
        { text: '堯富1200型脫蠟爐' },
        { text: 'In2O3 已成形' },
        { text: '200' },
        { text: 'g' },
        { text: 'In2O3 已脫臘' },
        { text: '200' },
        { text: 'g' },
      ],
    },
    '16': {
      cells: [
        { text: '堯富1200型燒結爐' },
        { text: 'In2O3 已脫臘' },
        { text: '200' },
        { text: 'g' },
        { text: 'Embryo' },
        { text: '180' },
        { text: 'g' },
      ],
    },
    '17': {
      cells: {
        '0': { text: '堯富1200型燒結爐' },
        '4': { text: '下腳料' },
        '5': { text: '20' },
        '6': { text: 'g' },
      },
    },
    len: 20,
  },
  styles: [{
    align: 'center',
    font: {
      bold: true,
    },
    color: '#777',
  }, {
    color: '#aaa',
  }],
};

interface SheetRow {
  cells: { text: string }[];
}

export function WorkOrderPage() {
  const sheet = useRef<Spreadsheet | null>(null);
  const [sheetData, setSheetData] = useState<Record<string, any>>(DEFAULT_SHEET_DATA);

  const chartData = useMemo(() => {
    const validRows = toArray<SheetRow>(sheetData.rows)
      .filter((row) => row.cells?.[0]?.text)
      .slice(1);

    const allProducedMaterialSet = validRows.reduce((set, row) => {
      if (row.cells[4]?.text) {
        set.add(row.cells[4].text);
      }

      return set;
    }, new Set<string>());

    const allNoEquipmentMaterialNodes = validRows
      .filter((row) => row.cells[1]?.text && !allProducedMaterialSet.has(row.cells[1].text))
      .map((row) => ({
        id: row.cells[1].text,
        value: {
          title: `[採購/倉儲] ${row.cells[1].text}`,
          items: [{
            text: '採購',
            value: `${row.cells[1].text} ${row.cells[2]?.text ?? ''}${row.cells[3]?.text ?? ''}`
          }],
        },
      }));

    const nodes = Object.entries(groupBy(
      validRows,
      (row) => row.cells[0].text,
    )).map(([workCenter, workCenterRows]) => ({
      id: workCenter,
      value: {
        title: workCenter,
        items: [
          ...uniqBy(
            workCenterRows.filter(row => row.cells[1]?.text),
            row => row.cells[1].text,
          ).map(row => ({
            text: '入料',
            value: `${row.cells[1].text} ${row.cells[2]?.text ?? ''}${row.cells[3]?.text ?? ''}`
          })),
          ...uniqBy(
            workCenterRows.filter(row => row.cells[4]?.text),
            row => row.cells[4].text,
          ).map(row => ({
            text: '出料',
            value: `${row.cells[4].text} ${row.cells[5]?.text ?? ''}${row.cells[6]?.text ?? ''}`
          })),
        ]
      },
    }));

    const producedMachineMap = validRows.reduce((map, row) => {
      if (row.cells[0]?.text && row.cells[4]?.text) {
        map.set(
          row.cells[4]?.text,
          row.cells[0]?.text,
        );
      }

      return map;
    }, new Map<string, string>());

    const edges = validRows.reduce<{
      source: string;
      target: string;
    }[]>((sumEdges, row) => {
      if (row.cells[0]?.text && producedMachineMap.get(row.cells[1]?.text)) {
        sumEdges.push({
          source: producedMachineMap.get(row.cells[1]?.text) as string,
          target: row.cells[0]?.text,
        });
      }

      return sumEdges;
    }, []);

    return {
      ...CHART_DEFAULT_DATA,
      nodes: [
        ...nodes,
        ...allNoEquipmentMaterialNodes,
      ],
      edges,
    };
  }, [sheetData]);

  const config = useMemo<FlowAnalysisGraphConfig>(() => ({
    data: chartData,
    layout: {
      rankdir: 'TB',
    } as DagreLayout,
    autoFit: true,
    nodeCfg: {
      autoWidth: true,
      anchorPoints: [
        [0.5, 0],
        [0.5, 1],
      ],
    },
    edgeCfg: {
      type: 'polyline',
      endArrow: {
        show: true,
      },
    },
    behaviors: ['drag-canvas', 'zoom-canvas', 'drag-node'],
  }), [chartData]);

  useEffect(() => {
    if (sheet.current) return;

    const onCellEdited = async (text: string, rowIndex: number, columnIndex: number) => {
      if (columnIndex !== 8) return;

      const { name } = await request(`/routings/workCenters/${text}`, {
        method: 'get',
        namespace: API_NAMESPACE.MATERIALS,
      });

      (sheet.current?.cellText(rowIndex, 9, name || '-', 0) as unknown as { reRender: () => void }).reRender();
    };

    sheet.current = new Spreadsheet('#spreadsheet', {
      showToolbar: true,
      showBottomBar: false,
      showContextmenu: true,
      row: {
        height: 25,
        len: 20,
      },
      col: {
        len: 10,
        width: 80,
        indexWidth: 60,
        minWidth: 60,
      },
      view: {
        width: () => 1000,
        height: () => (sheet.current?.getData()?.rows?.len ?? 20) * 27 + 27 + 80,
      },
    }).loadData(sheetData)
      .change(setSheetData);

    sheet.current.on('cell-edited', onCellEdited);
  }, [sheetData]);

  const methods = useForm();

  const materialId = methods.watch('materialId');

  const [queryMaterialId, setQueryMaterialId] = useState<string | null>(null);

  const { data } = useSWR<RoutingItem[][] | SavedData>(
    queryMaterialId
      ? [`/routings/${queryMaterialId}`, { namespace: API_NAMESPACE.MATERIALS }]
      : null,
  );

  const onSubmitQuery = useCallback(() => {
    setQueryMaterialId(materialId.trim());
  }, [materialId]);

  const [versionIndex, setVersionIndex] = useState(0);

  const queriedData = useMemo(() => {
    if (!data && !queryMaterialId) return DEFAULT_SHEET_DATA;

    const queriedResult = (data ?? [[]]);

    const rows = Array.isArray(queriedResult) ? queriedResult[versionIndex].reduce((vars, routing, index) => ({
      ...vars,
      [index + 1]: {
        cells: [
          { text: `${index + 1}. ${routing.workCenterId}/${routing.workCenter || '-'}` },
          { text: `${routing.materialId}${routing.index}` },
          { text: '1' },
          { text: routing.inUnit || 'PCS' },
          { text: (index + 1) === queriedResult[versionIndex].length ? '製成品' : `${routing.materialId}${queriedResult[versionIndex][index + 1]?.index ?? ''}` },
          { text: '1' },
          { text: routing.outUnit || 'PCS' },
          { text: routing.locationId || '-' },
          { text: routing.workCenterId || '-' },
          { text: routing.workCenter || '-', style: 1 },
        ],
      },
    }), {
      '0': {
        cells: FIRST_ROW_CELLS,
      },
    }) : {
      '0': {
        cells: FIRST_ROW_CELLS,
      },
      ...(data as SavedData).rows,
    };

    methods.setValue('storedMaterialId', queryMaterialId);

    return {
      ...DEFAULT_SHEET_DATA,
      rows,
    };
  }, [data, queryMaterialId, methods, versionIndex]);

  useEffect(() => {
    if (sheet.current) {
      sheet.current.loadData(queriedData);

      setSheetData(queriedData);
    }
  }, [queriedData]);

  const storedMaterialId = methods.watch('storedMaterialId');

  const [uploadLoading, setUpdateLoading] = useState(false);

  const onSubmitSave = useCallback(async () => {
    const willSaveData = {
      ...sheetData.rows,
    };

    delete willSaveData['0'];

    setUpdateLoading(true);

    if (Array.isArray(data)) {
      await request(`/routings/${storedMaterialId}`, {
        method: 'put',
        namespace: API_NAMESPACE.MATERIALS,
        body: JSON.stringify({
          routingJSON: {
            materialId: data?.[0]?.[0]?.materialId,
            newMaterialId: data?.[0]?.[0]?.newMaterialId,
            recipe: data?.[0]?.[0]?.recipe,
            version: data?.[0]?.[0]?.version,
            area: data?.[0]?.[0]?.area,
            areaDesc: data?.[0]?.[0]?.areaDesc,
            rows: willSaveData,
          } satisfies SavedData,
        }),
      });
    } else if (data) {
      await request(`/routings/${storedMaterialId}`, {
        method: 'put',
        namespace: API_NAMESPACE.MATERIALS,
        body: JSON.stringify({
          routingJSON: {
            materialId: data.materialId,
            newMaterialId: data.newMaterialId,
            recipe: data.recipe,
            version: data.version,
            area: data.area,
            areaDesc: data.areaDesc,
            rows: willSaveData,
          } satisfies SavedData,
        }),
      });
    }

    Message.success('已成功保存 Routing');

    setUpdateLoading(false);
  }, [data, sheetData, storedMaterialId]);

  const versionOptions = useMemo<SelectValue[]>(() => {
    if (!data) return [];

    if (Array.isArray(data)) {
      return Array.from(Array(data?.length ?? 0))
        .map((_, index) => ({
          id: index.toString(),
          name: `版本：${data[index][0].version}`,
        }));
    }

    return [{
      id: '0',
      name: `版本：${data.version}`,
    }];
  }, [data]);

  useEffect(() => {
    if (versionOptions.length) {
      methods.setValue('versionIndex', versionOptions[0]);
    }
  }, [versionOptions, methods]);

  const selectedVersionIndex = methods.watch('versionIndex');

  useEffect(() => {
    if (selectedVersionIndex) {
      setVersionIndex(Number(selectedVersionIndex.id));
    }
  }, [selectedVersionIndex]);

  return (
    <div className={styles.wrapper}>
      <FormFieldsWrapper methods={methods} className={styles.tableWrapper}>
        <Typography variant="h2">
          Routing Definitions
        </Typography>
        <div className={styles.filters}>
          <InputField
            label="物料編號(舊/新) or Recipe"
            placeholder="101002211"
            registerName="materialId" />
          <Button
            onClick={onSubmitQuery}
            type="button"
            variant="outlined">
            查詢
          </Button>
        </div>
        {data && versionOptions.length ? (
          <div className={styles.filters}>
            <SelectField
              disabled={!Array.isArray(data)}
              registerName="versionIndex"
              options={versionOptions} />
          </div>
        ) : null}
        {data ? (
          <div className={cx(styles.filters, styles.centerVertical)}>
            <Typography variant="body1">
              {`物料編號(舊)：${Array.isArray(data) ? data[0]?.[0]?.materialId || '-' : data.materialId}`}
            </Typography>
            <Typography variant="body1">
              {` | 物料編號(新)：${Array.isArray(data) ? data[0]?.[0]?.newMaterialId || '-' : data.newMaterialId}`}
            </Typography>
            <Typography variant="body1">
              {` | Recipe：${Array.isArray(data) ? data[0]?.[0]?.recipe ?? '-' : data.recipe}`}
            </Typography>
            <Typography variant="body1">
              {` | Area：${Array.isArray(data) ? data[0]?.[0]?.area ?? '-' : data.area}`}
            </Typography>
            <Typography variant="body1">
              {` | Area Desc：${Array.isArray(data) ? data[0]?.[0]?.areaDesc ?? '-' : data.areaDesc}`}
            </Typography>
          </div>
        ) : null}
        <div id="spreadsheet" />
        <div className={styles.filters}>
          <InputField
            label="儲存為料號"
            placeholder="101002211"
            registerName="storedMaterialId" />
          <Button
            loading={uploadLoading}
            disabled={!storedMaterialId}
            onClick={onSubmitSave}
            type="button"
            variant="contained">
            保存
          </Button>
        </div>
      </FormFieldsWrapper>
      <div className={styles.chartWrapper}>
        <FlowAnalysisGraph key={Math.random()} {...config} />
      </div>
    </div>
  );
}
