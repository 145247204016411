export enum MaterialRegisterEnum {
  NewCreated="new_created",
  Expected="expected",
  Extra="extra"
}

export function renderMaterialRegisterString(type?:  MaterialRegisterEnum):string {
  switch (type) {
    case MaterialRegisterEnum.Expected:
      return '預期領料';
    case MaterialRegisterEnum.Extra:
      return '額外領料';
    case MaterialRegisterEnum.NewCreated:
      return '新建料號碼';
    default:
      return '';
  }
}
