import { TableColumn } from '@mezzanine-ui/core/table';
import { useMemo, useState } from 'react';
import { EquipmentMaterial } from './use-equipment-materials';
import { useFormContext } from 'react-hook-form';
import styles from '../equipment-materials-list.module.scss';
import { Option, Select, SelectValue } from '@mezzanine-ui/react';
import { InputField, SelectField } from '@mezzanine-ui/react-hook-form';
import { MaterialListFormFields } from '../EquipmentMaterialsList';
import { Button, Space } from 'antd';
import { DispatchMaterialViewModalProps, Modals } from '../types';
import { useTargetModal } from '@solar/templates';

function MaterialQuantityColumnRender({
  source,
}: {
  source: EquipmentMaterial;
}) {
  const [selectUnit, setSelectUnit] = useState<SelectValue<
    'stockUnit' | 'inputUnit'
  > | null>({ id: 'stockUnit', name: source?.inputMaterialStockUnit });
  const methods = useFormContext<MaterialListFormFields>();
  const selectedEquipmentMaterials = methods?.watch(
    'selectedEquipmentMaterials'
  );
  const index = selectedEquipmentMaterials?.findIndex(
    (item) => item?.id === source?.equipmentMaterialRecordId?.toString()
  );

  return (
    <Space key={index}>
      {index !== -1 ? (
        <>
          <InputField
            required
            registerName={`selectedEquipmentMaterials.${index}.outputMaterialQuantity`}
          />
          <SelectField
            required
            registerName={`selectedEquipmentMaterials.${index}.outputMaterialUnit`}
            className={styles.select_unit}
            mode="single"
            options={[
              {
                id: 'stockUnit',
                name: source?.inputMaterialStockUnit,
              },
              {
                id: 'inputUnit',
                name: source?.inputMaterialInputUnit,
              },
            ]}
          />
        </>
      ) : (
        <>
          <span>
            {(() => {
              switch (selectUnit?.id) {
                case 'stockUnit':
                  return source?.inputMaterialStockUnitQuantity;
                case 'inputUnit':
                  return source?.inputMaterialInputUnitQuantity;
                default:
                  return '';
              }
            })()}
          </span>
          <Select
            className={styles.select_unit}
            mode="single"
            value={selectUnit}
            onChange={(value) =>
              setSelectUnit(value as SelectValue<'inputUnit' | 'stockUnit'>)
            }
          >
            <Option value="inputUnit">{source?.inputMaterialInputUnit}</Option>
            <Option value="stockUnit">{source?.inputMaterialStockUnit}</Option>
          </Select>
        </>
      )}
    </Space>
  );
}

function MaterialWeightColumnRender({ source }: { source: EquipmentMaterial }) {
  const methods = useFormContext<MaterialListFormFields>();
  const selectedEquipmentMaterials = methods?.watch(
    'selectedEquipmentMaterials'
  );
  const index = selectedEquipmentMaterials?.findIndex(
    (item) => item?.id === source?.equipmentMaterialRecordId?.toString()
  );

  return index !== -1 ? (
    <InputField
      key={index}
      required
      registerName={`selectedEquipmentMaterials.${index}.outputMaterialWeightUnitQuantity`}
    />
  ) : (
    <span>{source?.inputMaterialWeightUnitQuantity}</span>
  );
}

export function useEquipmentMaterialsListColumns() {
  const { openModal } = useTargetModal(Modals.DISPATCH_MATERIAL_VIEW);
  return useMemo<TableColumn<EquipmentMaterial>[]>(
    () => [
      {
        width: 200,
        // dataIndex: 'materialRequestOrderId',
        title: '發料單號',
        render: (source) => {
          return (
            <Button
              type="link"
              onClick={() =>
                openModal({
                  orderId: source?.materialRequestOrderId ?? '',
                })
              }
            >
              {source?.materialRequestOrderId ?? ''}
            </Button>
          );
        },
      },
      {
        width: 200,
        dataIndex: 'inputMaterialId',
        title: '料號',
      },
      {
        width: 450,
        dataIndex: 'inputMaterialDescription',
        title: '品名',
      },
      {
        width: 200,
        title: '批號',
        render: (source) =>
          `${source?.inputMaterialSapBatchId}-${source?.inputMaterialSubBatchId}`,
      },
      {
        width: 250,
        align: 'end',
        title: '數量',
        render: (source) => <MaterialQuantityColumnRender source={source} />,
      },
      {
        width: 250,
        align: 'end',
        title: '實際重量',
        render: (source) => <MaterialWeightColumnRender source={source} />,
      },
      {
        width: 250,
        dataIndex: 'inputMaterialSubBatchRemark',
        title: '批次備註',
      },
      // {
      //   width: 64,
      //   align: 'end',
      //   dataIndex: 'inputMaterialStockUnit',
      //   title: '單位',
      // },
      // {
      //   width: 150,
      //   align: 'end',
      //   dataIndex: 'outputMaterialStockUnitQuantity',
      //   title: '退出重量',
      //   render: (source) => {
      //     const index = selectedEquipmentMaterials?.findIndex(
      //       (item) => item?.id === source?.id
      //     );
      //     return index !== -1 && (
      //       <InputField
      //         type=""
      //         max={{
      //           value: source?.inputMaterialStockUnitQuantity ?? '0',
      //           message: '請輸入正確的退出重量',
      //         }}
      //         min={{
      //           value: '0',
      //           message: '請輸入正確的退出重量',
      //         }}
      //         disabled={index === -1}
      //         registerName={`selectedEquipmentMaterials.${index}.outputMaterialStockUnitQuantity`}
      //       />
      //     );
      //   },
      // },
    ],
    []
  );
}
