import { Message } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  FetchingInventoryStockTypes,
  getInventoryByMaterial,
  getNextParams,
  InventoryByMaterialSearchType,
  NextPagination,
} from '@solar/data';
import {
  ModalGroup,
  MznPaginationTable,
  PageLayout,
  useModalGroupController,
} from '@solar/templates';
import { useCallback, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { BarcodeScanInput } from '../MaterialShift/BarcodeScanInput';
import { useMaterialStockColumns } from './hooks';
import styles from './material-id-update-page.module.scss';
import { Edit } from './modals/Edit';
import { MaterialIdTransferObj, MaterialIdUpdateForm, Modals } from './types';

const PAGE_LIMIT = 20;
export function MaterialIdUpdatePage() {
  const [loading, setLoading] = useState<boolean>(false);
  const modalGroupController = useModalGroupController([{ name: Modals.EDIT }]);

  const tableMethods = useForm<MaterialIdUpdateForm>({
    defaultValues: {
      searchTerm: '',
      searchType: InventoryByMaterialSearchType.MATERIAL_BARCODE,
      pageInfo: {},
      records: [],
    },
  });

  const watchedSearchType = useWatch({
    control: tableMethods.control,
    name: 'searchType',
  });

  const watchedRecords = useWatch({
    control: tableMethods.control,
    name: 'records',
  });

  const watchedPageInfo = useWatch({
    control: tableMethods.control,
    name: 'pageInfo',
  });

  const watchedSearchTerm = useWatch({
    control: tableMethods.control,
    name: 'searchTerm',
  });

  const scannerKeyDownAction = useCallback(
    async ({
      searchTerm,
      searchType,
    }: {
      searchTerm: string | undefined;
      searchType: InventoryByMaterialSearchType;
    }) => {
      tableMethods.setValue('searchType', searchType);
      tableMethods.setValue('searchTerm', searchTerm ?? '');

      setLoading(true);
      try {
        if (searchTerm) {
          const res = await getInventoryByMaterial({
            type: searchType,
            offset: 0,
            limit: PAGE_LIMIT,
            stockTypes: [FetchingInventoryStockTypes.AVAILABLE],
            searchTerms: [searchTerm ?? ''],
          });

          tableMethods.setValue('records', res?.records);
          tableMethods.setValue('pageInfo', res?.pageInfo);

          if (res?.records?.length === 0) {
            throw '沒有匹配的資料';
          }
        }
      } catch (err) {
        if (err instanceof Error) {
          Message.error(JSON.parse(err.message).message);
        } else {
          Message.error(JSON.stringify(err));
        }
      } finally {
        setLoading(false);
      }
    },
    [tableMethods]
  );

  const paginationCallBack = useCallback(
    async (nextPagination: NextPagination) => {
      const { offset, limit } = getNextParams(nextPagination, watchedPageInfo);

      setLoading(true);
      try {
        const res = await getInventoryByMaterial({
          searchTerms: [watchedSearchTerm ?? ''],
          type: watchedSearchType,
          stockTypes: [FetchingInventoryStockTypes.AVAILABLE],
          offset,
          limit,
        });

        tableMethods.setValue('records', res?.records);
        tableMethods.setValue('pageInfo', res?.pageInfo);
      } catch (err) {
        if (err instanceof Error) {
          Message.error(JSON.parse(err.message).message);
        } else {
          Message.error(JSON.stringify(err));
        }
      } finally {
        setLoading(false);
      }
    },

    [watchedPageInfo, watchedSearchTerm, watchedSearchType, tableMethods]
  );

  const materialStockColumns = useMaterialStockColumns({
    openEditModal: (data: MaterialIdTransferObj) => {
      modalGroupController.openModal<MaterialIdTransferObj>(Modals.EDIT, data);
    },
  });

  return (
    <>
      <PageLayout title="庫存料號變更">
        <BarcodeScanInput
          inputStyle={styles['barcode-scan-input']}
          label="物料條碼"
          otherOnKeyDownAction={async (searchTerm) => {
            await scannerKeyDownAction({
              searchTerm,
              searchType: InventoryByMaterialSearchType.MATERIAL_BARCODE,
            });
          }}
        />
        <BarcodeScanInput
          label="載具條碼"
          inputStyle={styles['barcode-scan-input']}
          otherOnKeyDownAction={async (searchTerm) => {
            await scannerKeyDownAction({
              searchTerm,
              searchType: InventoryByMaterialSearchType.LOADER_BARCODE,
            });
          }}
        />
        <BarcodeScanInput
          label="物料料號／品名"
          inputStyle={styles['barcode-scan-input']}
          otherOnKeyDownAction={async (searchTerm) => {
            await scannerKeyDownAction({
              searchTerm,
              searchType:
                InventoryByMaterialSearchType.MATERIAL_ID_OR_MATERIAL_NAME,
            });
          }}
        />
        <FormFieldsWrapper methods={tableMethods}>
          <MznPaginationTable
            scroll={{
              x: 2500,
            }}
            columns={materialStockColumns}
            dataSource={watchedRecords.map((row) => ({
              ...row,
              id: row.batchStoredLoaderRecordId,
            }))}
            loading={loading}
            fetchData={paginationCallBack}
            pageInfo={watchedPageInfo}
          />
        </FormFieldsWrapper>
      </PageLayout>
      <ModalGroup {...modalGroupController}>
        <Edit tableMethods={tableMethods} />
      </ModalGroup>
    </>
  );
}
