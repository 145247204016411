import { SelectValue } from '@mezzanine-ui/react';
import { LocationLevel } from './location-level';

export type LocationId = string;
export type LocationIds = Record<LocationLevel, LocationId | null>;

export interface LocationControlContextValues {
  activeId: LocationId | null;
  locationIds: LocationIds;
}

export interface LocationControlContextMethods {
  resetContextValue?: LocationControlResetContextValueFunc;
  onChangeBySVG?: (id: string, level: LocationLevel) => void;
  onChangeByLocationPicker?: LocationControlSelectFunc;
  onChangeByAllLevelSearch?: LocationControlSelectFunc;
}

export interface LocationControlResetContextValueFunc {
  (
    payload:
      | { id: LocationId; level: LocationLevel }
      | { id: LocationId; locationIds: LocationIds }
  ): void;
}

export interface LocationControlSelectFunc {
  (value: SelectValue, level: LocationLevel): void;
}

export interface LocationControlContextInterface
  extends LocationControlContextValues,
    LocationControlContextMethods {
  level: LocationLevel | null;
  loading: boolean;
  error: boolean;
}
