import { UseFormReturn } from 'react-hook-form';
import { ProductionFormCreatorFormFields } from '../typings';
import useSWR from 'swr';
import { API_NAMESPACE } from '@solar/data';
import { useMemo } from 'react';
import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';

export function useAutoCompleteProcessingField({
  methods,
  enabled,
}: {
  methods: UseFormReturn<ProductionFormCreatorFormFields>;
  enabled?: boolean;
}): JSX.Element {
  const { data: processingData } = useSWR<{ id: string; name: string }[]>(
    enabled ? [`/processing`, { namespace: API_NAMESPACE.PP }] : undefined
  );

  const processing = useMemo(
    () =>
      (processingData ?? []).map((processing) => ({
        id: processing.id,
        name: processing.name,
      })),
    [processingData]
  );

  return (
    <AutoCompleteField
      options={processing}
      label="行為"
      placeholder="請輸入行為編號"
      registerName="processingId"
    />
  );
}
