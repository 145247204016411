import { TableColumn } from '@mezzanine-ui/core/table';
import { Button } from '@mezzanine-ui/react';
import { OOCTicket } from '@solar/data';
import { Space } from 'antd';
import { Modals } from '../Modals.enum';

export interface UseOOCTocketsTableColumnsProps {
  openModal: (name: string, payload: any) => void;
}

export function useOOCTocketsTableColumns(
  props: UseOOCTocketsTableColumnsProps
) {
  const { openModal } = props;
  return [
    {
      width: 120,
      title: '操作',
      render: (source) => (
        <Space>
          <Button
            onClick={() =>
              openModal(Modals.IN_LINE_SPC_ABNORMAL_REPORT, source)
            }
          >
            檢視
          </Button>
        </Space>
      ),
    },
    { dataIndex: 'materialId', title: '料號' },
    {
      title: '批號',
      render: (source) => `${source.sapBatchId}_${source.subBatchId}`,
    },
    {
      title: '狀態',
      render: (source) => {
        const isClear = source.status === 'CLEAR';

        return isClear ? '已完成' : '未完成';
      },
    },
  ] as TableColumn<OOCTicket>[];
}
