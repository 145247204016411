import { useTargetModal } from '@solar/templates';
import { Modals } from './Modals';
import { Form, Input, Modal, UploadProps, message } from 'antd';
import { createPendingDrawing } from '@solar/data';
import {
  UploadDrawingField,
  UploadDrawingValue,
} from '../UploadDrawingField/UploadDrawingField';
import { useEffect } from 'react';

export function CreatePendingDrawingModal({
  mutateGetPendingDrawings,
}: {
  mutateGetPendingDrawings: () => void;
}) {
  const [form] = Form.useForm<{
    parentDrawingCode: string;
    file: UploadDrawingValue;
  }>();
  const { open, closeModal } = useTargetModal(Modals.CREATE);

  useEffect(() => {
    if (open) {
      form.setFieldValue(['file', 'location'], '台灣');
    }
  }, [open]);

  return (
    <Modal
      width={1000}
      open={open}
      title="新增"
      onOk={async () => {
        try {
          const result = await form?.validateFields();
          const targetFile = result?.file?.fileList?.[0];

          if (targetFile?.response) {
            await createPendingDrawing({
              parentDrawingCode: result?.parentDrawingCode,
              ...targetFile.response,
              location: '台灣',
            });

            message.success('新增成功');
            await mutateGetPendingDrawings();
            closeModal();
          }
        } catch (err) {
          console.log(err);
        }
      }}
      onCancel={() => closeModal()}
      okText="確認"
      cancelText="取消"
    >
      <Form form={form}>
        <Form.Item label="舊成品圖號" name="parentDrawingCode">
          <Input />
        </Form.Item>
        <Form.Item
          label="檔案"
          name="file"
          required
          rules={[{ required: true, message: '必填' }]}
        >
          <UploadDrawingField currentDrawing={null} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
