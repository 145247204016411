export enum ReturnOrderType {
  STORAGE = 'STORAGE',
  MISC = 'MISC',
  OUTSOURCING = 'OUTSOURCING',
}

export const RETURN_ORDER_TYPES = [
  { id: ReturnOrderType.STORAGE, name: '庫存' },
  { id: ReturnOrderType.MISC, name: '雜項' },
  { id: ReturnOrderType.OUTSOURCING, name: '委外加工' },
];
