import { TableColumn } from '@mezzanine-ui/core/table';
import { Button } from '@mezzanine-ui/react';
import { checkJWTRoles, InventoryPurchaseOrderResponse } from '@solar/data';
import { useMemo } from 'react';
import { ReceiveActionModalOpenFunc } from '../../../typings';
import { CopyTextWrapper } from '@solar/templates';

export function usePurchaseOrderExpectedColumns({
  receiveActionModalOpen,
}: {
  receiveActionModalOpen: ReceiveActionModalOpenFunc;
}) {
  const column: TableColumn<InventoryPurchaseOrderResponse['items'][number]>[] =
    useMemo(
      () => [
        {
          title: '操作',
          width: 120,
          align: 'center' as const,
          render(source) {
            return (
              checkJWTRoles([82]) && (
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => {
                    receiveActionModalOpen({
                      specId: source.specId,
                      materialId: source.materialId,
                      materialDescription: source?.materialDescription,
                      secondaryUnitName: source.secondaryUnitName,
                      mainUnitName: source.mainUnitName,
                      materialStockUnit: source.materialStockUnit,
                    });
                  }}
                >
                  收料
                </Button>
              )
            );
          },
        },
        { title: '工廠', dataIndex: 'factoryId' },
        {
          width: 200,
          title: '料號',
          render: (source) => (
            <CopyTextWrapper text={source?.materialId ?? ''} />
          ),
        },
        { title: '舊料號', dataIndex: 'oldMaterialId' },
        {
          width: 500,
          title: '品名',
          render: (source) => (
            <CopyTextWrapper text={source?.materialDescription ?? ''} />
          ),
        },
        {
          title: '應收量',
          dataIndex: 'expectedMaterialStockUnitQuantity', //old: expectedMainMaterialUnitQuantity
        },
        {
          title: '已收量',
          dataIndex: 'receivedMaterialStockUnitQuantity', // old: receivedMainMaterialUnitQuantity
        },
        { title: '單位', dataIndex: 'unit' },
      ],
      [receiveActionModalOpen]
    );

  return column;
}
