import { ConfigProvider, Descriptions, Table } from 'antd';
import Decimal from 'decimal.js';
import moment from 'moment';
import { forwardRef, useEffect, useRef } from 'react';
import {
  ANTD_TH_BACKGROUND,
  fieldNamingMapping,
} from '../../ProductionPlanning/WorkOrderManagementPage/BasicInfoPanel';
import styles from './WorkOrderPrint.module.scss';
import { useMaterialLabelColumn } from './useMaterialLabelColumn';

type WorkOrderPrintPageProps = {
  workOrder?: any;
};

export const WorkOrderPrintPage = forwardRef<
  HTMLDivElement,
  WorkOrderPrintPageProps
>(({ workOrder }, ref) => {
  const mainProduct = workOrder?.expectedMainProduct?.[0];
  const orderId = workOrder?.id;

  const bomRef = useRef<HTMLDivElement>(null);
  const routingRef = useRef<HTMLDivElement>(null);

  const materialLabelColumn = useMaterialLabelColumn();

  useEffect(() => {
    if (workOrder) {
      if (bomRef?.current) {
        bomRef.current?.parentElement?.parentElement?.setAttribute(
          'colspan',
          '8'
        );
      }
      if (routingRef?.current) {
        routingRef.current?.parentElement?.parentElement?.setAttribute(
          'colspan',
          '8'
        );
      }
    }
  }, [workOrder]);

  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 0,
          fontSize: 10,
        },
      }}
    >
      <div ref={ref} className={styles.wrapper}>
        <Descriptions
          bordered
          column={4}
          size="small"
          labelStyle={{ width: '3cm' }}
          contentStyle={{ width: '7cm' }}
        >
          <Descriptions.Item label={fieldNamingMapping['orderId']}>
            {orderId}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNamingMapping['status']}>
            {workOrder?.workOrderStatusName}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNamingMapping['factory']}>
            {workOrder?.factoryId}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNamingMapping['mrpController']}>
            {workOrder?.mrpControllerName}
          </Descriptions.Item>
          {/*  */}
          <Descriptions.Item label={fieldNamingMapping['orderType']} span={2}>
            {workOrder?.orderTypeName}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNamingMapping['createdAt']} span={2}>
            {workOrder?.createdAt
              ? moment(workOrder?.createdAt).format('YYYY/MM/DD')
              : ''}
          </Descriptions.Item>
          <Descriptions.Item label={fieldNamingMapping['saleOrderId']}>
            {workOrder?.salesOrderId}
          </Descriptions.Item>

          <Descriptions.Item label={fieldNamingMapping['saleOrderItem']}>
            {workOrder?.salesOrderLineId}
          </Descriptions.Item>
          <Descriptions.Item
            label={fieldNamingMapping['expectedCompletedAt']}
            span={2}
          >
            {workOrder?.expectedDeliveryDate
              ? moment(workOrder?.expectedDeliveryDate).format('YYYY/MM/DD')
              : ''}
          </Descriptions.Item>
          <Descriptions.Item
            labelStyle={{ padding: '0px' }}
            contentStyle={{ padding: '0px' }}
            label={
              <div>
                <div style={{ padding: '16px 24px' }}>
                  {fieldNamingMapping['targetMaterialId']}
                </div>
                <hr style={{ border: '1px solid rgba(5, 5, 5, 0.06)' }} />
                <div style={{ padding: '16px 24px' }}>
                  {fieldNamingMapping['targetMaterialDescription']}
                </div>
              </div>
            }
            span={2}
          >
            <div style={{ padding: '16px 24px' }}>
              {((workOrder?.expectedMainProduct ?? [])[0] ?? {})?.materialId}
            </div>
            <hr style={{ border: '1px solid rgba(5, 5, 5, 0.06)' }} />
            <div style={{ padding: '16px 24px' }}>
              {
                ((workOrder?.expectedMainProduct ?? [])[0] ?? {})
                  ?.materialDescription
              }
            </div>
          </Descriptions.Item>
          <Descriptions.Item
            label={fieldNamingMapping['expectedMaterialUnitQuantity']}
            contentStyle={{ padding: '0px' }}
          >
            <div style={{ padding: '16px 24px' }}>
              {`${new Decimal(
                ((workOrder?.expectedMainProduct ?? [])[0] ?? {})
                  ?.expectedMaterialStockUnitQuantity ?? '0'
              )
                .div(workOrder?.stockOverInputUnitRatio ?? '1')
                .toString()} ${workOrder?.materialInputUnit}`}
            </div>
            <hr style={{ border: '1px solid rgba(5, 5, 5, 0.06)' }} />
            <div style={{ padding: '16px 24px' }}>
              {`${
                ((workOrder?.expectedMainProduct ?? [])[0] ?? {})
                  ?.expectedMaterialStockUnitQuantity
              } ${workOrder?.materialStockUnit}`}
            </div>
          </Descriptions.Item>
          <Descriptions.Item
            label={fieldNamingMapping['actualMainMaterialUnitQuantity']}
            contentStyle={{ padding: '0px' }}
          >
            <div style={{ padding: '16px 24px' }}>
              {`${new Decimal(
                ((workOrder?.expectedMainProduct ?? [])[0] ?? {})
                  ?.actualMaterialStockUnitQuantity ?? '0'
              )
                .div(workOrder?.stockOverInputUnitRatio ?? '1')
                .toString()} ${workOrder?.materialInputUnit}`}
            </div>
            <hr style={{ border: '1px solid rgba(5, 5, 5, 0.06)' }} />
            <div style={{ padding: '16px 24px' }}>
              {`${
                ((workOrder?.expectedMainProduct ?? [])[0] ?? {})
                  ?.actualMaterialStockUnitQuantity
              } ${workOrder?.materialStockUnit}`}
            </div>
          </Descriptions.Item>

          {/*  */}
          {(workOrder?.expectedSecondaryProduct ?? [])?.map((row: any) => {
            return (
              <>
                <Descriptions.Item
                  label={fieldNamingMapping['byProductMaterialId']}
                  span={2}
                >
                  {row?.materialId}
                </Descriptions.Item>
                <Descriptions.Item
                  label={fieldNamingMapping['actualMainMaterialUnitQuantity']}
                >
                  {row?.actualMaterialStockUnitQuantity}
                </Descriptions.Item>
                <Descriptions.Item label={fieldNamingMapping['unit']}>
                  {row?.mainMaterialUnit}
                </Descriptions.Item>
                <Descriptions.Item
                  label={fieldNamingMapping['byProductMaterialDescription']}
                  span={2}
                >
                  {row?.materialDescription}
                </Descriptions.Item>
                <Descriptions.Item
                  label={fieldNamingMapping['expectedMaterialUnitQuantity']}
                >
                  {row?.expectedMainMaterialUnitQuantity}
                </Descriptions.Item>
                <Descriptions.Item label={fieldNamingMapping['unit']}>
                  {row?.mainMaterialUnit}
                </Descriptions.Item>
              </>
            );
          })}

          {/*  */}
          {workOrder?.expectedJointProduct.map((row: any) => {
            return (
              <>
                <Descriptions.Item label="聯產品料號" span={2}>
                  {row?.materialId}
                </Descriptions.Item>
                <Descriptions.Item label="已入庫數量">
                  {row?.actualMaterialStockUnitQuantity}
                </Descriptions.Item>
                <Descriptions.Item label="單位">
                  {row?.materialStockUnit}
                </Descriptions.Item>

                <Descriptions.Item label="聯產品品名" span={2}>
                  {row?.materialDescription}
                </Descriptions.Item>
                <Descriptions.Item label="預期產出數量">
                  {row?.expectedMaterialStockUnitQuantity}
                </Descriptions.Item>
                <Descriptions.Item label="單位">
                  {row?.materialStockUnit}
                </Descriptions.Item>
              </>
            );
          })}

          <Descriptions.Item
            labelStyle={{ display: 'none' }}
            span={4}
            contentStyle={{
              backgroundColor: ANTD_TH_BACKGROUND,
              textAlign: 'center',
            }}
            children={<div ref={bomRef}>工單 BOM</div>}
          />

          {(workOrder?.expectedMaterials ?? []).map((row: any) => {
            return (
              <>
                <Descriptions.Item
                  label={fieldNamingMapping['inputMaterialId']}
                  span={2}
                >
                  {row.materialId}
                </Descriptions.Item>
                <Descriptions.Item
                  label={fieldNamingMapping['requirementQuantity']}
                >
                  {row.expectedMaterialStockUnitQuantity}
                </Descriptions.Item>
                <Descriptions.Item label={fieldNamingMapping['unit']}>
                  {row.materialStockUnit}
                </Descriptions.Item>
                <Descriptions.Item
                  label={fieldNamingMapping['inputMaterialDescription']}
                  span={2}
                >
                  {row.materialDescription}
                </Descriptions.Item>
                <Descriptions.Item
                  label={fieldNamingMapping['alreadyUsedQuantity']}
                >
                  {row.expectedMaterialInputUnitQuantity}
                </Descriptions.Item>
                <Descriptions.Item label={fieldNamingMapping['unit']}>
                  {row.materialInputUnit}
                </Descriptions.Item>
              </>
            );
          })}

          <Descriptions.Item
            labelStyle={{ display: 'none' }}
            span={4}
            contentStyle={{
              backgroundColor: ANTD_TH_BACKGROUND,
              textAlign: 'center',
            }}
            children={<div ref={routingRef}>Routing</div>}
          />
          {(workOrder?.operationCodes ?? []).map((row: any) => {
            return (
              <>
                <Descriptions.Item label={fieldNamingMapping['workCenterId']}>
                  {row.workCenterId}
                </Descriptions.Item>
                <Descriptions.Item label={fieldNamingMapping['workCenterName']}>
                  {row.workCenterName}
                </Descriptions.Item>
                <Descriptions.Item label="內文碼">
                  {row.processingName}
                </Descriptions.Item>
                <Descriptions.Item label="工序">
                  {row.operationCode}
                </Descriptions.Item>
              </>
            );
          })}

          {/*  */}
          <Descriptions.Item label="銷售訂單備註" span={2}>
            {workOrder?.salesOrderNote}
          </Descriptions.Item>
          <Descriptions.Item label="備註" span={2}>
            {workOrder?.remark}
          </Descriptions.Item>
        </Descriptions>
        <br />
        <Table
          columns={materialLabelColumn}
          dataSource={workOrder?.batches ?? []}
          pagination={false}
        />
      </div>
    </ConfigProvider>
  );
});
