import { ProductionFormResponse } from '@solar/data';
import { CheckboxField } from '@mezzanine-ui/react-hook-form';
import { Space } from 'antd';
import { useFormContext } from 'react-hook-form';
import { ProductionForms } from '@solar/templates';
import { Typography } from '@mezzanine-ui/react';
import { useCallback } from 'react';

export function ManualProductionForms({
  forms,
}: {
  forms: ProductionFormResponse[];
}) {
  const methods = useFormContext();

  const getAnswerFieldRegisterName = useCallback(
    (form: ProductionFormResponse) =>
      `modifiedByForm.${form.id}.modifiedAnswer`,
    []
  );
  const getPickedFieldRegisterName = useCallback(
    (form: ProductionFormResponse) => `modifiedByForm.${form.id}.picked`,
    []
  );
  return (
    <ProductionForms
      forms={forms}
      registerName="modifiedByForm"
      getFieldRegisterName={getAnswerFieldRegisterName}
      renderTitle={(form) => (
        <Space>
          <CheckboxField
            registerName={getPickedFieldRegisterName(form)}
            onChange={(event) => {
              if (!event.target.checked) {
                methods.setValue(
                  `modifiedByForm.${form.id}.modifiedAnswer`,
                  null
                );
              }
            }}
          />
          <Typography variant="h5">{form.title ?? '-'}</Typography>
        </Space>
      )}
    />
  );
}
