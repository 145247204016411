import { getRoleGroup } from './shared';

const BASIC_MEDIUM_GROUP = {
  1: {
    name: '未分類',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup({
          FF: {
            name: '未分類',
            nextLevel: {
              '01': {
                name: '未分類',
              },
            },
          },
        }),
      },
    },
  },
};

export const PART_LARGE_GROUP = {
  S: {
    name: '防著板',
    nextLevel: BASIC_MEDIUM_GROUP,
  },
  F: {
    name: '墊片',
    nextLevel: BASIC_MEDIUM_GROUP,
  },
  1: {
    name: '未分類',
    nextLevel: BASIC_MEDIUM_GROUP,
  },
};
