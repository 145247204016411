import { TableColumn } from '@mezzanine-ui/core/table';
import { Button } from '@mezzanine-ui/react';
import moment from 'moment';
import { Dispatch, MutableRefObject, SetStateAction, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FilterFormType, MaterialViewBaseColumns } from '../typings';
import { ProductionAnswerDto } from '@solar/data';
import { CopyTextWrapper } from '../../Templates/CopyTextWrapper/CopyTextWrapper';
import Decimal from 'decimal.js';
import { MaterialStock } from '../../Material/StockBindOrder/types';

export const StockUnitQuantityColumn: TableColumn<
  MaterialViewBaseColumns | MaterialStock
> = {
  dataIndex: 'quantity',
  title: '基礎計量單位數量',
};

export const translation = {
  PENDING: '待入庫',
  AVAILABLE: '未限制',
  INSPECTION: '品檢中',
  IN_TRANSIT: '移轉中',
  SALES: '銷售訂單',
  REQUESTED: '預約發料',
  IN_TRANSIT_SALES: '銷售訂單移轉中',
  LOCKED: 'WIP 庫存',
  REQUESTED_SALES: '預約發料訂單庫存',
  HUB: '庫存',
};

export const InputUnitQuantityColumn: TableColumn<
  MaterialViewBaseColumns | MaterialStock
> = {
  title: '管理單位數量',
  render: (source) =>
    new Decimal(source?.quantity ?? 0)
      .div(source?.stockOverInputUnitRatio ?? 1)
      .toNumber(),
};

export const useMaterialViewBaseColumns = ({
  batchSerialModalOpen,
  methods,
  setLocationViewerAnchor,
  setElementRatioInfo,
  elementRatioAnchorRef,
}: {
  batchSerialModalOpen: () => void;
  methods: UseFormReturn<FilterFormType>;
  setLocationViewerAnchor: Dispatch<
    SetStateAction<HTMLElement | null | undefined>
  >;
  setElementRatioInfo: Dispatch<SetStateAction<ProductionAnswerDto[]>>;
  elementRatioAnchorRef: MutableRefObject<HTMLButtonElement | null>;
}) =>
  useMemo<TableColumn<MaterialViewBaseColumns>[]>(
    () => [
      {
        width: 200,
        title: '料號',
        render: (source) => {
          return <CopyTextWrapper text={source?.materialId ?? ''} />;
        },
      },
      {
        width: 500,
        title: '品名',
        render: (source) => {
          return <CopyTextWrapper text={source?.materialDescription ?? ''} />;
        },
      },
      {
        width: 200,
        title: '圖號',
        render: (source) => {
          return <CopyTextWrapper text={source?.drawingCode ?? ''} />;
        },
      },
      {
        width: 200,
        title: '批號',
        render: (source) => {
          return <CopyTextWrapper text={source?.batchId ?? ''} />;
        },
      },
      {
        title: '儲位',
        render: (source) => (
          <div
            data-zone-id={source?.stackId}
            style={{ cursor: 'pointer' }}
            onMouseEnter={(event: any) => {
              if (source?.stackId) {
                // locationSVGPopperHandler.onMouseEnter(source?.stackId)(event);
                methods.setValue('selectedChartId', source?.stackId);

                setLocationViewerAnchor(event.currentTarget);
              }
            }}
            // onMouseLeave={locationSVGPopperHandler.onMouseLeave}
          >
            {source?.shelfId ?? ''}
          </div>
        ),
      },
      {
        title: '載具',
        dataIndex: 'loaderId',
      },
      {
        title: '狀態',
        render: (source) => {
          if (source.type in translation) {
            return (translation as any)?.[source.type as any];
          }
          return null;
        },
      },
      StockUnitQuantityColumn,
      { title: '基礎計量單位', dataIndex: 'materialStockUnit' },
      InputUnitQuantityColumn,
      {
        title: '管理單位',
        dataIndex: 'materialInputUnit',
      },
      {
        title: '實際重量',
        dataIndex: 'actualMaterialWeightUnitQuantity',
      },
      {
        title: '純量',
        width: 86,
        render: (source) =>
          source.elementRatioAnswers.length ? (
            <Button
              size="small"
              onMouseEnter={({ target }) => {
                elementRatioAnchorRef.current = target as HTMLButtonElement;

                setElementRatioInfo(source.elementRatioAnswers);
              }}
              onMouseLeave={() => {
                elementRatioAnchorRef.current = null;

                setElementRatioInfo([]);
              }}
              variant="outlined"
              type="button"
            >
              顯示
            </Button>
          ) : (
            '-'
          ),
      },
      {
        title: '入庫時間',
        render: (source) =>
          source?.createdAt
            ? moment(source['createdAt']).format('YYYY/MM/DD')
            : '',
      },
      {
        title: '入庫人員',
        dataIndex: 'staffName',
      },
      {
        title: '環保聯單號',
        render: (source) => {
          return (
            source?.inventoryOrderRecycleNumber ??
            source?.deliveryOrderRecycleNumber ??
            '-'
          );
        },
      },
      {
        title: '銷售訂單號碼',
        dataIndex: 'salesOrderId',
      },
      {
        title: '特採單號',
        render: (source) => {
          return (
            source?.inventoryOrderWaiverNumber ??
            source?.deliveryOrderWaiverNumber ??
            '-'
          );
        },
      },
      {
        title: '批號備註',
        dataIndex: 'materialSubBatchRemark',
      },
    ],
    [
      elementRatioAnchorRef,
      methods,
      setElementRatioInfo,
      setLocationViewerAnchor,
    ]
    // batchSerialModalOpen,
    //   elementRatioAnchorRef,
    //   methods,
    //   setElementRatioInfo,
    //   setLocationViewerAnchor,
  );

// export const useMaterialViewExtendedColumns = ({
//   methods,
//   setLocationViewerAnchor,
// }: {
//   methods: UseFormReturn<FilterFormType>;
//   setLocationViewerAnchor: React.Dispatch<
//     React.SetStateAction<HTMLElement | null | undefined>
//   >;
// }) =>
//   useMemo<TableColumn<MaterialViewBaseColumns>[]>(
//     () => [
//       {
//         width: 200,
//         title: '料號',
//         dataIndex: 'materialId',
//         render: (source) => {
//           return <CopyTextWrapper text={source?.materialId ?? ''} />;
//         },
//       },
//       {
//         width: 500,
//         title: '品名',
//         dataIndex: 'materialDescription',
//         render: (source) => {
//           return <CopyTextWrapper text={source?.materialDescription ?? ''} />;
//         },
//       },
//       {
//         title: '圖號',
//         dataIndex: 'drawingCode',
//       },
//       {
//         width: 200,
//         title: '批號',
//         dataIndex: 'batchId',
//         render: (source) => {
//           return <CopyTextWrapper text={source?.batchId ?? ''} />;
//         },
//       },
//       {
//         title: '流水序',
//         dataIndex: 'serialNumber',
//       },
//       {
//         title: '批次備註',
//         dataIndex: 'batchNotes',
//       },
//       {
//         title: '備註',
//         dataIndex: 'notes',
//       },
//       {
//         title: '儲位',
//         dataIndex: 'stackId',
//         render: (source) => (
//           <div
//             data-zone-id={source?.stackId}
//             style={{ cursor: 'pointer' }}
//             onMouseEnter={(event: any) => {
//               console.log('source: ', source);
//               if (source?.stackId) {
//                 // locationSVGPopperHandler.onMouseEnter(source?.stackId)(event);
//                 methods.setValue('selectedChartId', source?.stackId);

//                 setLocationViewerAnchor(event.currentTarget);
//               }
//             }}
//             // onMouseLeave={locationSVGPopperHandler.onMouseLeave}
//           >
//             {source?.shelfId ?? ''}
//           </div>
//         ),
//       },
//       {
//         title: '載具',
//         dataIndex: 'loaderId',
//       },
//       {
//         title: '庫存數量',
//         dataIndex: 'mainMaterialUnitQuantity',
//       },
//       {
//         title: '單位',
//         dataIndex: 'mainMaterialUnitQuantity',
//       },
//       {
//         title: '實際重量',
//         dataIndex: 'mainMaterialUnitQuantity',
//       },
//       {
//         title: '單位',
//         dataIndex: 'mainMaterialUnitQuantity',
//       },
//       {
//         title: '最新入庫時間',
//         dataIndex: 'createdAt',
//         render: (source) =>
//           source?.createdAt
//             ? moment(source?.createdAt).format('YYYY/MM/DD')
//             : '',
//       },
//       {
//         title: '入庫人員',
//         dataIndex: 'staffName',
//       },
//       {
//         title: '批號備註',
//         dataIndex: 'materialSubBatchRemark',
//       },
//     ],
//     [methods, setLocationViewerAnchor]
//   );
