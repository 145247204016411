import { MznPaginationTable } from '@solar/templates';
import { useExceptionColumn } from '../hooks';
import { PanelProps } from '../types';

export const ExceptionPanel = ({
  refetchWaitReceipt,
  isLoading,
  pageInfo,
  tableData,
}: PanelProps) => {
  const columns = useExceptionColumn();

  return (
    <MznPaginationTable
      scroll={{ x: 2000 }}
      columns={columns}
      dataSource={tableData ?? []}
      fetchData={refetchWaitReceipt}
      hideDefaultExpandColumn
      pageInfo={pageInfo}
      loading={isLoading}
    />
  );
};
