import { useRef, useCallback, useState, ChangeEvent, useEffect } from 'react';
import { TableInputSearch } from '../../../typings';
import debounce from 'lodash/debounce';
export function useDebounceILikeSearch() {
  const [tableDataSearchTerms, setTableDataSearchTerms] = useState<
    Record<TableInputSearch, string>
  >({
    receivableMaterialIdSearch: '',
    receivableMaterialDescriptionSearch: '',
    receivedMaterialIdSearch: '',
    receivedMaterialDescriptionSearch: '',
  });

  const inputHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>, field: TableInputSearch) => {
      setTableDataSearchTerms((prev) => ({ ...prev, [field]: e.target.value }));
    },
    []
  );

  const debounceInputHandler = useRef(
    debounce(
      (e: ChangeEvent<HTMLInputElement>, field: TableInputSearch) =>
        inputHandler(e, field),
      800
    )
  ).current;

  useEffect(() => {
    return () => {
      debounceInputHandler.cancel();
    };
  }, [debounceInputHandler]);

  return {
    tableDataSearchTerms,
    debounceInputHandler,
  };
}
