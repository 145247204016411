import {
  DateTimePickerField as MznDateTimePickerField,
  DateTimePickerFieldProps as MznDateTimePickerFieldProps,
} from '@mezzanine-ui/react-hook-form';
import { ProductionFormFieldTimestamp } from '@solar/data';
import { useCallback, useMemo } from 'react';
import {
  FieldValues,
  UseFormRegister,
  Validate,
  useFormContext,
} from 'react-hook-form';

interface DateTimePickerFieldProps extends MznDateTimePickerFieldProps {
  fields: ProductionFormFieldTimestamp;
}

export function DateTimePickerField({
  fields,
  registerName,
  ...props
}: DateTimePickerFieldProps) {
  const methods = useFormContext<FieldValues>();

  // const validate = useMemo(() => {
  //   const results: Record<string, Validate<any>> = {};

  //   if (fields?.required) {
  //     results.required = (v) => !!v || '必填';
  //   }

  //   return results;
  // }, [fields?.required]);

  // const _register = useCallback<UseFormRegister<FieldValues>>(
  //   (name, options) => {
  //     return methods.register(name, Object.assign(options ?? {}, { validate }));
  //   },
  //   [methods, validate]
  // );

  const onBlur = useCallback(() => {
    methods.trigger(registerName);
  }, [methods, registerName]);

  return (
    <MznDateTimePickerField
      // register={_register}
      registerName={registerName}
      placeholder={fields?.placeholder ?? ''}
      onBlur={onBlur}
      {...props}
    />
  );
}
