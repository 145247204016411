import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { API_NAMESPACE, request } from '../../request';
import { isOptionObject } from '../orderMarks';
import { useMainFileData } from './useMainFileData';
import { FilterDataType, useMainFileFilters } from './useMainFileFilters';

const namespace = API_NAMESPACE.HR;

export const isOption = (object: unknown): object is FilterDataType[] => {
  if (object && object.constructor === Array)
    return object[0] && 'id' in object[0];
  return false;
};

export type ModalBody = {
  label?: string;
  required: boolean;
  fn: string;
  componentsProps: any;
};

type Title = '新增包裝主檔' | '編輯';

export type Modal<T> = {
  title: T;
  confirmButton: '新增' | '更新' | '確定';
  source: any;
} | null;

const initialDefaultInfo = '一';

export function useMainFileModal(methods: UseFormReturn<FieldValues, any>) {
  const {
    data: mainFileFiltersData,
    setData: setMainFileFiltersData,
    setQueryObject,
    allPackagingNames,
    isMainfileFilters,
    clearSelectByMaterials,
    handleFilterByKeyword,
  } = useMainFileFilters();
  const { createMutation, updateMutation, deleteMutation } = useMainFileData();

  const [deleteModal, setDeleteModal] = useState<any>(null);
  const [modal, setModal] = useState<Modal<Title>>(null);
  const [disableConfirm, setDisableConfirm] = useState(true);
  const [defaultInfo, setDefaultInfo] = useState(initialDefaultInfo);

  const [disableQuantityUnitSelect, setDisableQuantityUnitSelect] =
    useState(true);
  const [disablePackagingNameSelect, setDisablePackagingNameSelect] =
    useState(true);
  const [passPackingRequest, setPassPackingRequest] = useState(false);

  const createModal = [
    {
      label: '產品料號',
      required: true,
      fn: 'EnhanceAutoCompleteUpdateOptions',
      componentsProps: {
        registerName: 'materialId',
        mode: 'single',
        name: 'materialId',
        width: 320,
        form: methods,
        options:
          mainFileFiltersData?.selectByMaterials.map((item) => {
            // Autocomplete (component) use 'name' to display option label, so replace it and  return new array
            return { ...item, name: item.id };
          }) ?? [],
        handleSearch: handleFilterByKeyword,
        placeholder: '請選擇',
        defaultValue: modal?.source?.materialId,
      },
    },
    {
      label: '產品品名(由料號帶入)',
      required: false,
      fn: 'Info',
      componentsProps: {
        content: defaultInfo,
      },
    },
    {
      label: '單位',
      required: !passPackingRequest,
      fn: 'EnhanceSelect',
      componentsProps: {
        registerName: 'quantityUnit',
        mode: 'single',
        width: 100,
        options: mainFileFiltersData?.quantityUnits ?? [],
        defaultValue:
          mainFileFiltersData?.quantityUnits.filter(
            (item) => item.id === modal?.source?.quantityUnit
          )[0] ?? null,
        clearable: false,
        placeholder: '單位',
        disabled: disableQuantityUnitSelect || passPackingRequest,
      },
    },
    {
      label: '客戶',
      required: !passPackingRequest,
      fn: 'EnhanceAutoComplete',
      componentsProps: {
        registerName: 'customerId',
        mode: 'single',
        width: 172,
        options:
          mainFileFiltersData?.customers?.map((item) => ({
            id: item.id,
            name: `${item?.name ?? ''}（${item?.id ?? ''}）`,
          })) ?? [],
        defaultValue:
          mainFileFiltersData?.customers.find(
            (customer) => customer.id === modal?.source?.customerId
          ) ?? null,
        clearable: true,
        placeholder: '請選擇',
        disabled: passPackingRequest,
      },
    },
    {
      label: '不需等候包裝指示',
      required: true,
      fn: 'EnhanceSelect',
      componentsProps: {
        registerName: 'passPackingRequest',
        mode: 'single',
        width: 172,
        options: [
          {
            id: 'N',
            name: '需等候',
          },
          {
            id: 'Y',
            name: '不需等候',
          },
        ],
        clearable: false,
        placeholder: '請選擇',
      },
    },
    {
      label: '包材類型',
      required: !passPackingRequest,
      fn: 'EnhanceSelect',
      componentsProps: {
        registerName: 'packagingType',
        mode: 'single',
        width: 172,
        options: mainFileFiltersData?.packagingTypes ?? [],
        defaultValue:
          mainFileFiltersData?.packagingTypes.find(
            (item) => item.name === modal?.source?.packagingType
          ) ?? null,
        clearable: true,
        placeholder: '請選擇',
        disabled: passPackingRequest,
      },
    },
    {
      label: '包材名稱',
      required: !passPackingRequest,
      fn: 'EnhanceAutoComplete',
      componentsProps: {
        registerName: 'packagingId',
        mode: 'single',
        disabled: disablePackagingNameSelect || passPackingRequest,
        width: 172,
        options:
          mainFileFiltersData?.packaging?.filter((item) => item.name) ?? [],
        defaultValue:
          allPackagingNames?.find(
            (item) => item.id === modal?.source?.packagingId
          ) ?? null,
        clearable: true,
        placeholder: '請選擇',
      },
    },
    {
      label: '包裝數量',
      required: !passPackingRequest,
      fn: 'EnhanceAccountInput',
      componentsProps: {
        registerName: 'limitCount',
        width: 128,
        clearable: true,
        placeholder: '請選擇',
        register: methods.register,
        setValue: methods.setValue,
        name: 'limitCount',
        defaultValue: `${modal?.source?.limitCount}` ?? null,
        disabled: passPackingRequest,
      },
    },
  ] as ModalBody[];

  const updateModal = useMemo(() => {
    const filterData = createModal.map((item) =>
      item.fn === 'Border' ? { ...item, fn: 'Br' } : item
    );
    const map = filterData.map((item) => ({
      ...item,
      componentsProps: { ...item.componentsProps, clearable: false },
    }));
    return map;
  }, [createModal]) as ModalBody[];

  const handleOpenModal = async (source: any) => {
    const passPackingRequest = source.passPackingRequest === 'Y' ? {
      id: 'Y',
      name: '不需等候',
    } : {
      id: 'N',
      name: '需等候',
    };
    methods.setValue('passPackingRequest', passPackingRequest);
    const materialId = source?.materialId ?? '';
    setModal({ title: '編輯', confirmButton: '更新', source });
    const result = await request('/packings/get/filter', {
      namespace,
      params: { materialKeyword: materialId },
    });
    setQueryObject((query) => {
      return { ...query, materialKeyword: materialId };
    });
    if (isMainfileFilters(result)) {
      methods.setValue('materialId', materialId);
      setMainFileFiltersData(result);
      const { selectByMaterials } = result;
      setDefaultInfo(
        selectByMaterials.find((material) => material.id === materialId)
          ?.name ?? initialDefaultInfo
      );
    }
  };

  const closeModal = () => {
    setModal(null);
    setDefaultInfo(initialDefaultInfo);
    clearSelectByMaterials();
    setDisablePackagingNameSelect(true);
    setDisableQuantityUnitSelect(true);
    methods.setValue('passPackingRequest', {
      id: 'N',
      name: '需等候',
    });
    methods.setValue('materialId', undefined);
    setQueryObject((query) => ({
      ...query,
      materialKeyword: '',
      materialId: '',
    }));
  };

  const confirmModal = useCallback(
    async (methods: any) => {
      const data = methods.watch();

      const objectKeys = Object.keys(data);

      const mapping = [
        { body: 'customerId', name: 'customerId' },
        { body: 'materialId', name: 'materialId' },
        { body: 'packagingId', name: 'packagingId' },
        { body: 'limitCount', name: 'limitCount' },
        { body: 'quantityUnit', name: 'quantityUnit' },
        { body: 'passPackingRequest', name: 'passPackingRequest' },
      ];

      const reduceData: { shouldDrop: any } & any = objectKeys.reduce(
        (acc, cur) => {
          if (isOptionObject(data[cur]))
            return {
              ...acc,
              [mapping.find((item) => item.name === cur)?.body ?? 'shouldDrop']:
                data[cur]?.id,
            };
          if (isOption(data[cur]))
            return {
              ...acc,
              [mapping.find((item) => item.name === cur)?.body ?? 'shouldDrop']:
                data[cur]?.map((item: FilterDataType) => item.id),
            };
          return {
            ...acc,
            [mapping.find((item) => item.name === cur)?.body ?? 'shouldDrop']:
              data[cur],
          };
        },
        {}
      );

      const { shouldDrop, ...others } = reduceData;

      const finalData = others;

      let response;

      if (modal?.source?.id) {
        response = await updateMutation(modal.source?.id, finalData);
      } else {
        response = await createMutation(finalData);
      }

      closeModal();

      return response;
    },
    [closeModal, modal]
  );

  const deleteModalMutation = useCallback(
    (id: string) => {
      deleteMutation(id);

      setDeleteModal(null);
      closeModal();
    },
    [closeModal]
  );

  useEffect(() => {
    const materialId = methods.watch()['materialId'];
    setDefaultInfo(
      mainFileFiltersData.selectByMaterials.find(
        (material) => material.id === materialId
      )?.name ?? initialDefaultInfo
    );
  }, [methods.watch(), mainFileFiltersData]);

  useEffect(() => {
    methods.watch();
    const packagingType = methods.getValues('packagingType');
    const materialId = methods.getValues('materialId');

    let newQueryObject = {};

    if (packagingType) {
      newQueryObject = { ...newQueryObject, packagingType: packagingType.id };
      setDisablePackagingNameSelect(false);
    } else {
      setDisablePackagingNameSelect(true);
    }

    if (materialId) {
      newQueryObject = { ...newQueryObject, materialId };
      setDisableQuantityUnitSelect(false);
    } else {
      setDisableQuantityUnitSelect(true);
    }

    setQueryObject((query) => ({ ...query, ...newQueryObject }));
  }, [
    setQueryObject,
    methods.getValues('packagingType'),
    methods.getValues('materialId'),
  ]);

  useEffect(() => {
    if (defaultInfo === initialDefaultInfo) {
      setDisableConfirm(true);
      return;
    }
    methods.watch();
    const requiredFields = createModal.filter(
      (field) => field.required === true
    );

    const disabled = requiredFields.some((field) => {
      const registerName = field.componentsProps.registerName;
      const value = methods.getValues(registerName);

      if (typeof value === 'string')
        return value.trim() === '' || value === 'undefined';
      return value == undefined || value === null;
    });

    setDisableConfirm(disabled);
  }, [methods.watch(), methods, defaultInfo]);

  useEffect(() => {
    methods.getValues('passPackingRequest')?.id === 'Y' ? setPassPackingRequest(true) : setPassPackingRequest(false);
  }, [
    methods.getValues('passPackingRequest'),
  ]);

  return {
    deleteModal,
    setDeleteModal,
    modal,
    setModal,
    disableConfirm,
    handleOpenModal,
    closeModal,
    confirmModal,
    createModal,
    updateModal,
    deleteModalMutation,
  };
}
