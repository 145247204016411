import { request, API_NAMESPACE } from '../../request';
import * as yup from 'yup';
import useSWR from 'swr';

const materialCompositionAllGetDataSchema = yup.object().shape({
  components: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
      })
    )
    .required(),
});

type MaterialCompositionAllGetData = yup.InferType<
  typeof materialCompositionAllGetDataSchema
>;

function useMaterialCompositionAllGet() {
  const { data, mutate, isValidating } = useSWR<MaterialCompositionAllGetData>(
    validateSWrKey(),
    request,
    {
      revalidateOnFocus: false,
    }
  );

  return {
    materialCompositionAll: data?.components ?? [],
    isValidating,
    revalidateData: mutate,
  };
}

function validateSWrKey() {
  return [
    '/customers/customer-trade-condition/components/all',
    {
      namespace: API_NAMESPACE.HR,
    },
  ];
}

export { useMaterialCompositionAllGet };
