import type { ProductionPlanningOrdersData } from '@solar/data';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import { checkJWTRoles, usePlannedDeliveryNoteInlineForm } from '@solar/data';
import { Message, Button } from '@mezzanine-ui/react';

type Props = {
  source: ProductionPlanningOrdersData;
  refetchProductionPlanningOrders: () => void;
};

export function PlannedDeliveryNoteInlineForm({
  source,
  refetchProductionPlanningOrders,
}: Props) {
  const { formMethods, formSubmitCreator, FormFieldName } =
    usePlannedDeliveryNoteInlineForm({
      dataSource: source,
    });

  return (
    <FormFieldsWrapper
      methods={formMethods}
      onSubmit={formSubmitCreator({
        successCallback: () => {
          Message.success('更新成功');
          refetchProductionPlanningOrders();
        },
        errorCallback: (error) => {
          try {
            if (!error?.message) return;
            const { message } = JSON.parse(error.message) ?? {};
            Message.error(`更新失敗${message ? '：' + message : ''}`);
          } catch (error) {
            Message.error('更新失敗');
          }
        },
      })}
      style={{ display: 'flex', gap: '1rem' }}
    >
      <InputField registerName={FormFieldName.PLANNED_DELIVERY_NOTE} />
      {checkJWTRoles([114]) && (
        <Button type="submit" variant="contained">
          更新
        </Button>
      )}
    </FormFieldsWrapper>
  );
}
