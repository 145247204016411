import {
  DateRangePickerField,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { useProductionDeliveryOrderTypes } from '@solar/data';
import { INSPECT_STATUS_OPTIONS } from '../../InspectStatusTag';
import classes from './filter.module.scss';

export const EXPECTED_RECEIVE_DATE_OPTIONS = [
  { id: 'EXPECTED_COMPLETED_AT_DESC', name: '預期收貨日期近 -> 遠' },
  { id: 'EXPECTED_COMPLETED_AT_ASC', name: '預期收貨日期遠 -> 近' },
];

export const filteredOrderTypes = ['ZLR3'];
export function Filter() {
  const { data: deliveryOrderTypes } = useProductionDeliveryOrderTypes();

  return (
    <div className={classes.host}>
      <InputField registerName="orderId" placeholder="請輸入出貨單號" />
      <InputField registerName="customerName" placeholder="請輸入客戶名稱" />
      <InputField registerName="employeeName" placeholder="請輸入業務人員" />
      <SelectField
        registerName="types"
        placeholder="出貨單類型"
        options={
          deliveryOrderTypes?.filter(
            (row) => !filteredOrderTypes.includes(row.id)
          ) ?? []
        }
        mode="multiple"
      />
      <DateRangePickerField
        fullWidth
        label="日期區間："
        registerName="createdDateRange"
      />
      <SelectField
        label="狀態："
        mode="multiple"
        clearable
        registerName="packedStates"
        options={[...INSPECT_STATUS_OPTIONS]}
      />
      <SelectField
        label="日期排序："
        clearable
        registerName="sortType"
        options={EXPECTED_RECEIVE_DATE_OPTIONS}
      />
    </div>
  );
}
