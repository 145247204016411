import {
  Button,
  Icon,
  Select,
  Table,
  Option,
  SelectValue,
} from '@mezzanine-ui/react';
import { Layout } from '../Layout/Layout';
import { useMemo, useState } from 'react';
import { API_NAMESPACE } from '@solar/data';
import { useSalesReturnFilter } from './useSalesReturnFilter';
import { useSalesReturnTableColumns } from './useSalesReturnTableColumns';
import {
  SalesReturnFilters,
  SalesReturnQueryRes,
  SortTypes,
} from './SalesReturnPage.interface';
import useSWR from 'swr';
import dayjs from 'dayjs';
import { SubmitButtonType } from '../Layout/Filter/Filter';
import { ResetIcon } from '@mezzanine-ui/icons';
import styles from './SalesReturnPage.module.scss';

export function SalesReturnPage() {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filters, setFilters] = useState<SalesReturnFilters>();
  const [loading, setLoading] = useState(false);

  const OrderTypeOptions = useMemo(
    () => [
      { id: 'ZLR', name: '銷退收貨' },
      { id: 'ZLR2', name: 'HUB倉回廠' },
      { id: 'ZLR4', name: '租賃回廠' },
    ],
    []
  );

  const SortTypeOptions = useMemo(
    () => [
      { id: SortTypes.ExpectedCompletedAtAsc, name: '預期收穫日期 近 -> 遠' },
      { id: SortTypes.ExpectedCompletedAtDesc, name: '預期收穫日期 遠 -> 近' },
    ],
    []
  );
  const [sortType, setSortType] = useState<SelectValue>(SortTypeOptions[0]);

  const query = useMemo(() => {
    const { orderIds, customerNames, employeeNames, types, expectedDateRange } =
      filters ?? {};

    const values = Object.entries({
      orderIds,
      customerNames,
      employeeNames,
      types: types?.length ? types : OrderTypeOptions,
      expectedCompletedDateFrom: expectedDateRange?.[0],
      expectedCompletedDateTo: expectedDateRange?.[1],
      limit,
      offset,
      sortType: sortType.id,
    }).reduce((result, [key, value]) => {
      if (value === undefined || value === null || value === '') {
        return result;
      }
      if (typeof value === 'number') {
        return [...result, [key, value.toString()]];
      }
      if (Array.isArray(value)) {
        return [...result, ...value.map((v) => [key, v.id])];
      }
      if (
        key === 'expectedCompletedDateFrom' ||
        key === 'expectedCompletedDateTo'
      ) {
        return [...result, [key, dayjs(value).format('YYYYMMDD')]];
      }
      return [...result, [key, value]];
    }, [] as string[][]);
    return new URLSearchParams(values).toString();
  }, [filters, limit, offset, OrderTypeOptions, sortType]);

  const { methods, filtersData, filterMethods } =
    useSalesReturnFilter(OrderTypeOptions);

  const columns = useSalesReturnTableColumns();

  const { data, mutate } = useSWR<SalesReturnQueryRes>([
    `/warehouses/production-delivery-orders?${query}`,
    { namespace: API_NAMESPACE.MATERIALS },
  ]);

  const submitBtn = useMemo(() => {
    return {
      name: '確認',
      onClick: () => setFilters(methods.getValues()),
    } as SubmitButtonType;
  }, [methods]);

  return (
    <Layout
      title="銷退匹配"
      button={
        <Button
          variant="contained"
          size="large"
          prefix={<Icon icon={ResetIcon} />}
          onClick={async () => {
            setLoading(true);
            await mutate();
            setLoading(false);
          }}
        >
          重新整理
        </Button>
      }
      filterHeader="查詢"
      filtersData={filtersData}
      filterMethods={filterMethods}
      filterDependencies={[]}
      registers={null}
      submitButton={submitBtn}
      methods={methods}
    >
      <>
        <div className={styles.sortSelector}>
          <Select value={sortType} onChange={setSortType}>
            {SortTypeOptions.map((option) => (
              <Option value={option.id}>{option.name}</Option>
            ))}
          </Select>
        </div>
        <Table
          loading={!data || loading}
          columns={columns}
          dataSource={data?.records || []}
          pagination={{
            disableAutoSlicing: true,
            current: offset / limit + 1,
            total: data?.pageInfo.totalCount,
            onChange: (nextPage: number) => {
              setOffset((nextPage - 1) * limit);
            },
            options: {
              pageSize: limit,
              onChangePageSize: setLimit,
              showPageSizeOptions: true,
              pageSizeLabel: '每頁顯示：',
              pageSizeUnit: '筆',
            },
          }}
        />
      </>
    </Layout>
  );
}
