import { MenuIcon } from '@mezzanine-ui/icons';
import {
  AppBar,
  AppBarBrand,
  AppBarSupport,
  Button,
  cx,
  Icon,
  Popover,
  Typography,
} from '@mezzanine-ui/react';
import { API_NAMESPACE, getJWTPayload } from '@solar/data';
import { ReactNode, useEffect, useState } from 'react';
import useSWR from 'swr';
import styles from './app.module.scss';
import { LogoutButton } from './Auth/LogoutButton/LogoutButton';

import { reconcileResourcePathWithPermittedRoles } from './NavigationBar/helper';
import { NavigationBar } from './NavigationBar/NavigationBar';
import { useSideBarHidden } from './use-side-bar-hidden';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

type SideBarWrapper = {
  children: ReactNode;
};

export function AppSideBarWrapper({ children }: SideBarWrapper) {
  let payload;
  try {
    payload = getJWTPayload();
  } catch {
    payload = {};
  }

  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  const permittedParentModuleToSubmoduleRoutes =
    reconcileResourcePathWithPermittedRoles(payload?.roles ?? []);
  // const allPathsWithParentModule =
  //   permittedParentModuleToSubmoduleRoutes.flatMap((item) =>
  //     item.childModules.map((childModule) => childModule.path)
  //   );

  const [sidebarHidden, sidebarHiddenController, disableSidebar] =
    useSideBarHidden();

  const { toggle: toggleSideBar } = sidebarHiddenController;

  const [sapClient, setSapClient] = useState('000');

  const { data } = useSWR<string>([
    '/sap-client',
    { namespace: API_NAMESPACE.MATERIALS },
  ]);

  useEffect(() => {
    if (data) {
      setSapClient(data);
    }
  }, [data]);

  return (
    <div
      className={cx(
        styles.container,
        sidebarHidden && styles['container--side-bar-hidden']
      )}
    >
      <AppBar orientation="horizontal" className={styles.header}>
        {disableSidebar ? (
          <></>
        ) : (
          <div className={styles.menu}>
            <Button className={styles.menu__icon} onClick={toggleSideBar}>
              <div className={styles.menu__icon__container}>
                <Icon icon={MenuIcon} />
              </div>
            </Button>
          </div>
        )}

        <AppBarBrand>
          <img src="/assets/brand.png" alt="Solar" />
        </AppBarBrand>
        <AppBarSupport>
          <Typography variant="body2" color="primary">
            SAP 環境: {sapClient}
          </Typography>
          {!isEmpty(payload) && (
            <Button
              variant="outlined"
              onClick={(event) => {
                event.stopPropagation();
                setAnchor(
                  anchor === event.currentTarget ? null : event.currentTarget
                );
              }}
            >
              {payload?.Account} {payload?.Name}
            </Button>
          )}
          <Popover
            anchor={anchor}
            onClose={() => setAnchor(null)}
            open={Boolean(anchor)}
            options={{
              placement: 'bottom-end',
            }}
          >
            <div
              style={{
                width: 200,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
              }}
            >
              <Link
                to="/auth/reset-password"
                style={{
                  cursor: 'default',
                  textDecoration: 'none',
                }}
              >
                <Button
                  style={{ width: '100%' }}
                  onClick={() => setAnchor(null)}
                >
                  重設密碼
                </Button>
              </Link>

              <LogoutButton onClick={() => setAnchor(null)} />
            </div>
          </Popover>
          {/* <Routes>
            {disableSidebar ? null : (
              <Route path="*" element={<LogoutButton />} />
            )}
          </Routes> */}
        </AppBarSupport>
      </AppBar>
      <AppBar
        className={cx(
          styles.sidebar,
          sidebarHidden && styles['sidebar--hidden']
        )}
        orientation="vertical"
      >
        <NavigationBar
          menusAndSubItems={permittedParentModuleToSubmoduleRoutes}
        />
      </AppBar>
      <div className={styles.page}>
        <main className={styles.main}>{children}</main>
      </div>
    </div>
  );
}
