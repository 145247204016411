import { MznPaginationTable } from '@solar/templates';

import { useWaitingStorageInColumn } from '../hooks';
import { PanelProps } from '../types';

export const WaitStorageInPanel = ({
  refetchWaitReceipt,
  pageInfo,
  tableData,
  isLoading,
}: PanelProps) => {
  const columns = useWaitingStorageInColumn({});

  return (
    <MznPaginationTable
      loading={isLoading}
      scroll={{ x: 2500 }}
      columns={columns}
      dataSource={tableData ?? []}
      fetchData={refetchWaitReceipt}
      hideDefaultExpandColumn
      pageInfo={pageInfo}
    />
  );
};
