import { Button } from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  DateRangePickerField,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  ModalGroup,
  MznPaginationTable,
  useModalGroupController,
} from '@solar/templates';
import { Col, Row } from 'antd';
import { useForm } from 'react-hook-form';
import { Modals } from './Modals.enum';
import { DispatchOrderViewModal } from './DispatchOrderViewModal';

export function DispatchOrderTabPaneContent() {
  const methods = useForm();
  const modalGroupController = useModalGroupController([
    { name: Modals.VIEW_DISPATCH_ORDER },
  ]);

  const handleSearch = methods?.handleSubmit(() => {
    console.log();
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <FormFieldsWrapper methods={methods}>
        <Row gutter={[12, 12]}>
          <Col>
            <InputField registerName="sourceId" placeholder="請輸入來源單號" />
          </Col>
          <Col>
            <AutoCompleteField
              registerName="dispatchId"
              placeholder="請輸入發料單號"
              options={[]}
            />
          </Col>
          <Col>
            <AutoCompleteField
              registerName="supplier"
              placeholder="請輸入供應商名稱"
              options={[]}
            />
          </Col>
          <Col>
            <AutoCompleteField
              registerName="workCenter"
              placeholder="請輸入工作中心名稱"
              options={[]}
            />
          </Col>
          <Col>
            <SelectField
              registerName="status"
              placeholder="請輸入狀態"
              options={[]}
            />
          </Col>
          <Col>
            <SelectField
              registerName="sourceType"
              placeholder="請輸入來源"
              options={[]}
            />
          </Col>
          <Col>
            <DateRangePickerField
              registerName="range"
              placeholder="請設定日期區間"
            />
          </Col>
          <Col>
            <Button variant="contained" onClick={handleSearch}>
              確認
            </Button>
          </Col>
        </Row>
      </FormFieldsWrapper>
      <MznPaginationTable
        fetchData={console.log}
        pageInfo={{}}
        dataSource={[{} as any]}
        columns={[
          {
            title: '操作',
            render: () => (
              <Row>
                <Col>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      modalGroupController.openModal(
                        Modals.VIEW_DISPATCH_ORDER,
                        {}
                      );
                    }}
                  >
                    檢視
                  </Button>
                </Col>
              </Row>
            ),
          },
          {
            dataIndex: 'expectedDeliveryDate',
            title: '預期領料日',
          },

          {
            dataIndex: 'sourceType',
            title: '來源',
          },
          {
            dataIndex: 'sourceId',
            title: '來源單號',
          },
          {
            dataIndex: 'dispatchId',
            title: '發料單號',
          },
          {
            dataIndex: 'workCenter',
            title: '工作中心名稱',
          },
          {
            dataIndex: 'workCenterCode',
            title: '工作中心代碼',
          },
          {
            dataIndex: 'supplier',
            title: '供應商名稱',
          },
          {
            dataIndex: 'supplierCode',
            title: '供應商代碼',
          },
          {
            dataIndex: 'creator',
            title: '建立者',
          },
          {
            dataIndex: 'orderDeliveryDate',
            title: '訂單交期',
          },
          {
            dataIndex: 'status',
            title: '狀態',
          },
        ]}
      />
      <ModalGroup {...modalGroupController}>
        <DispatchOrderViewModal />
      </ModalGroup>
    </div>
  );
}
