import { useEffect } from 'react';
import { createLoader, useGetShelves } from '@solar/data';
import { Link, useNavigate } from 'react-router-dom';
import { PageLayout, RowSection } from '@solar/templates';
import { Button, Message, SelectValue } from '@mezzanine-ui/react';

import {
  FormFieldsWrapper,
  InputField,
  AutoCompleteField,
  useAutoCompleteInput,
} from '@mezzanine-ui/react-hook-form';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'antd';

export function CreateLoaderPage() {
  const editLoaderInfoMethods = useForm<{
    size: string;
    shelfId: SelectValue;
  }>();

  const navigate = useNavigate();
  const { input: shelfInput, onInput } = useAutoCompleteInput();
  const { shelves, mutateGetShelvesParams } = useGetShelves();

  useEffect(() => {
    mutateGetShelvesParams({ searchTerm: shelfInput });
  }, [shelfInput]);

  return (
    <PageLayout
      title={
        <div>
          <Button type="button">
            <Link to="/warehouse/loader-management">返回列表</Link>
          </Button>
          <div>{'新增載具'}</div>
        </div>
      }
    >
      <FormFieldsWrapper
        methods={editLoaderInfoMethods}
        onSubmit={async (values) => {
          const res = await createLoader({
            size: values.size,
            shelfId: values.shelfId.id,
          });

          if (res.ok) {
            Message.success('建立成功');
            navigate('/warehouse/loader-management');
          }
        }}
      >
        <Row>
          <Col span={5}>
            <RowSection label="尺寸：">
              <InputField registerName="size" />
            </RowSection>
          </Col>
        </Row>
        <Row style={{ marginTop: '8px' }}>
          <Col span={5}>
            <RowSection label="位置：">
              <AutoCompleteField
                registerName="shelfId"
                options={
                  shelves?.map((shelve) => ({
                    id: shelve.id,
                    name: shelve?.name ?? '無',
                  })) ?? []
                }
                onInput={onInput}
              />
            </RowSection>
          </Col>
        </Row>
        <Row>
          <div>
            <Button variant="contained">建立</Button>
          </div>
        </Row>
      </FormFieldsWrapper>
    </PageLayout>
  );
}
