import { useState } from 'react';
import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { OffsetBased, PageInfo } from '../pagination';
import { SelectValue } from '@mezzanine-ui/react';

const namespace = API_NAMESPACE.MATERIALS;

export type SuppliersParams = {
  searchTerms: string;
};

export function useSuppliers() {
  const [_params, _setParams] = useState<
    Partial<SuppliersParams> & OffsetBased
  >();

  const { data, error } = useSWR<{
    records: Array<SelectValue>;
    pageInfo: PageInfo;
  }>([
    '/suppliers',
    {
      params: _params,
      namespace,
    },
  ]);

  return {
    records: data?.records || [],
    pageInfo: data?.pageInfo,
    isLoading: !data && !error,
    mutateParams: _setParams,
  };
}
