import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetchUrl } from './endpoint';

const UNITS_TREE_ALL = 'units/tree/all';

const fetcher = async (url: string) => {
  const res = await fetch(url);
  const json = await res.json();

  return json ?? null;
};

type UnitsTreeType = {
  id: string | number;
  name: string;
  code: string;
  headCount: number;
  children: UnitsTreeType[];
};

const isUnitsTree = (object: unknown): object is UnitsTreeType[] => {
  if (object && object instanceof Array && object.length > 0)
    return 'id' in object[0];
  return false;
};

type RecursiveUnitsTreeDataType = {
  id: string | number;
  label: string;
  description: string;
  children: RecursiveUnitsTreeDataType[];
};

const recursiveUnitsTree = (
  data: UnitsTreeType[],
  index: number,
  arr: RecursiveUnitsTreeDataType[]
): RecursiveUnitsTreeDataType[] => {
  const current = data?.[index];
  if (!current) return arr;

  const newArr = [
    ...arr,
    {
      id: `${current.id}`,
      label: current.name,
      description: `人數：${current.headCount}`,
      children: recursiveUnitsTree(current.children, 0, []),
    },
  ] as RecursiveUnitsTreeDataType[];
  return recursiveUnitsTree(data, index + 1, newArr);
};

export type TreeSelectDataType = {
  disabled?: boolean;
  value: string;
  title: string;
  children?: TreeSelectDataType[];
};

const recursiveTreeSelect = (
  data: UnitsTreeType[],
  index: number,
  arr: TreeSelectDataType[]
): TreeSelectDataType[] => {
  const current = data?.[index];
  if (!current) return arr;

  const newArr = [
    ...arr,
    {
      value: current.id,
      title: current.name,
      selectable: current.code !== '',
      children: recursiveTreeSelect(current.children, 0, []),
    },
  ] as TreeSelectDataType[];

  return recursiveTreeSelect(data, index + 1, newArr);
};

export function useMembersTreeSelect() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<RecursiveUnitsTreeDataType | null>(null);
  const [treeSelectData, setTreeSelectData] = useState<TreeSelectDataType[]>(
    []
  );
  const [treeValue, setTreeValue] = useState<string[] | number[]>([]);

  const url = fetchUrl(UNITS_TREE_ALL);

  const { data: swrData } = useSWR(url, fetcher);

  useEffect(() => {
    if (isUnitsTree(swrData)) {
      const result = recursiveUnitsTree(swrData, 0, []);
      setData({
        id: 'root',
        label: 'Solar',
        description: '',
        children: result,
      });

      const treeSelectResult = recursiveTreeSelect(swrData, 0, []);
      setTreeSelectData(treeSelectResult);

      setLoading(false);
      return;
    }
    setLoading(true);
  }, [swrData]);

  return {
    data,
    loading,
    treeSelectData,
    treeValue,
    setTreeValue,
  };
}
