import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { useCallback, useMemo } from 'react';
import { FilterScaffold } from '@solar/templates';
import { Filter } from './Filter';
import { useForm } from 'react-hook-form';
import sharedStyles from '../shared-styles/index.module.scss';
import { ModalActions } from '@mezzanine-ui/react';
import { useTableColumn } from './constant/table-column';
import { PageLayout } from '../PageLayout/PageLayout';
import { useQueryString } from '../hooks/useQueryString';
import { toUpperCamelCase } from '../utils/toUpperCamelCase';
import { useModalController } from '../ModalLayout/hooks';
import { ModalLayout } from '../ModalLayout/ModalLayout';

import { WarehouseModule } from '../constant/index';
import { useEffect } from 'react';
import {
  ActionTypes,
  NextPagination,
  OrderTypes,
  getNextParams,
  useInventoryByMaterialLog,
} from '@solar/data';
import { MznPaginationTable } from '../../Templates/PaginationTable/MznPaginationTable';

export function OperationHistoryPane() {
  const { tab } = useQueryString();
  const upperCaseTab = toUpperCamelCase(tab ?? '');
  const filterMethods = useForm();
  const cancelModalController = useModalController();

  const tableColumn = useTableColumn(cancelModalController.openModal);

  // pick the corresponding qs when switching between tabs
  const queryStringPair = useMemo(() => {
    return {
      [WarehouseModule.MaterialStorageIn]: {
        actionTypes: [ActionTypes.RECEIPT],
        orderTypes: [
          OrderTypes.INVENTORY_PURCHASE_ORDER,
          OrderTypes.CUSTOMER_SUPPLY_ORDER,
        ],
      },
      [WarehouseModule.WarehouseDispatch]: {
        actionTypes: [ActionTypes.SHIP],
        orderTypes: [OrderTypes.PRODUCTION_WORK_ORDER],
      },
      [WarehouseModule.GeneratedStorageIn]: {
        actionTypes: [ActionTypes.RECEIPT],
        orderTypes: [OrderTypes.PRODUCTION_WORK_ORDER],
      },
      [WarehouseModule.MaterialShift]: {
        actionTypes: [ActionTypes.TRANSFER],
        orderTypes: [],
      },
      [WarehouseModule.MiscellaneousStorageIn]: {
        actionTypes: [ActionTypes.RECEIPT],
        orderTypes: [OrderTypes.MISC_PURCHASE_ORDER],
      },
      [WarehouseModule.MiscellaneousDispatchAndReturn]: {
        actionTypes: [ActionTypes.RETURN_TO_WAREHOUSE, ActionTypes.SHIP],
        orderTypes: [],
      },
    };
  }, []);

  const { records, pageInfo, mutateParams } = useInventoryByMaterialLog();

  useEffect(() => {
    if (
      tab &&
      Object.values(WarehouseModule).includes(upperCaseTab as WarehouseModule)
    ) {
      mutateParams(
        queryStringPair[upperCaseTab as keyof typeof queryStringPair]
      );
    }
  }, [mutateParams, queryStringPair, tab, upperCaseTab]);

  const refetchInventoryByMaterialLog = useCallback(
    (nextPagination: NextPagination) => {
      const filterState = filterMethods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      mutateParams({
        ...queryStringPair[upperCaseTab as keyof typeof queryStringPair],
        ...(filterState?.searchTerm && { searchTerm: filterState?.searchTerm }),
        ...(filterState?.dateRange && { dateFrom: filterState?.dateRange[0] }),
        ...(filterState?.dateRange && { dateTo: filterState?.dateRange[1] }),
        offset,
        limit,
      });
    },
    [filterMethods, mutateParams, pageInfo, queryStringPair, upperCaseTab]
  );

  return (
    <>
      <PageLayout>
        <FormFieldsWrapper methods={filterMethods}>
          <FilterScaffold mutateParams={refetchInventoryByMaterialLog}>
            <Filter />
          </FilterScaffold>
        </FormFieldsWrapper>
        <MznPaginationTable
          fetchData={refetchInventoryByMaterialLog}
          dataSource={records ?? []}
          pageInfo={pageInfo}
          columns={
            upperCaseTab && upperCaseTab in tableColumn
              ? tableColumn[upperCaseTab as keyof typeof tableColumn]
              : tableColumn.GeneratedStorageIn
          }
          bodyClassName={sharedStyles.table}
        />
      </PageLayout>
      <ModalLayout
        severity="error"
        modalHeader="是否取消此操作"
        modalFooter={
          <ModalActions
            confirmText="確認"
            cancelText="取消"
            onConfirm={() => {
              cancelModalController.closeModal();
            }}
            onCancel={() => cancelModalController.closeModal()}
          />
        }
        {...cancelModalController}
      >
        此行為將連動 SAP 的物料庫存資訊，
        <br />
        請確認是否取消此 操作？
      </ModalLayout>
    </>
  );
}
