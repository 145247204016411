import { Table } from '@mezzanine-ui/react';
import styles from '../../Equipment/EquipmentTable/equipment-table.module.scss';

import { TableProps } from '@mezzanine-ui/react/Table/Table';
import { PageInfo } from '@solar/data';

export function PaginationTable({
  columns,
  dataSource,
  emptyProps,
  headerClassName,
  pagination,
  mutateParams,
  pageInfo,
  ...tableProps
}: Omit<TableProps<Record<string, unknown>>, 'fetchMore'> & {
  mutateParams: any;
  pageInfo: PageInfo;
}) {
  const pageSize = pageInfo?.limit ?? 20;
  const page = (pageInfo?.offset ?? 0) / pageSize + 1;

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={{
        disableAutoSlicing: true,
        total: pageInfo?.totalCount ?? 0,
        current: page,
        onChange: (nextPage) => mutateParams({ nextPage }),
        options: {
          pageSize,
          pageSizeLabel: '每頁顯示：',
          pageSizeUnit: '筆',
          onChangePageSize: (nextPageSize) =>
            mutateParams({ nextPage: 1, nextPageSize }),
          renderPageSizeOptionName: (p) => `${p}`,
          renderPaginationSummary: (start, end) =>
            `目前顯示 ${start} - ${end} 筆，共 ${end - start + 1} 筆資料`,
          showPageSizeOptions: true, // 開啟每頁顯示 N 筆
          showJumper: true, // 開啟跳頁功能
          jumperButtonText: '前往',
          jumperHintText: '跳至',
        },
      }}
      bodyClassName={styles.table}
      {...tableProps}
    />
  );
}
