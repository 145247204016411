import { isEmpty, merge } from 'lodash';
import { useCallback, useMemo } from 'react';
import { FieldError, FieldErrorsImpl, FormState, Merge } from 'react-hook-form';

export type FormStateErrors =
  | Merge<FieldError, FieldErrorsImpl<any>>
  | undefined;

export function useGetFormValidation({
  formState,
  registerName,
}: {
  formState: FormState<any>;
  registerName: string;
}) {
  const validation = useMemo(() => {
    const errors = formState.errors?.[registerName] as FormStateErrors;
    let invalid: Record<string, any> = {};
    let oos: Record<string, any> = {};
    let ooc: Record<string, any> = {};
    Object.entries(errors ?? {}).forEach(([batchId, formsError]) => {
      Object.entries(formsError ?? {}).forEach(([formId, error]) => {
        switch (error?.type) {
          case 'specMin':
          case 'specMax':
            oos = merge(oos, { [batchId]: { [formId]: error } });
            break;
          case 'controlMax':
          case 'controlMin':
            ooc = merge(ooc, { [batchId]: { [formId]: error } });
            break;
          default:
            invalid = merge(invalid, {
              [batchId]: { [formId]: error },
            });
            break;
        }
      });
    });

    return {
      invalid,
      oos,
      ooc,
    };
  }, [formState, registerName]);

  const getErrorsByBatchId = useCallback(
    (batchId?: string) => {
      if (!batchId) {
        return {
          validation: { invalid: {}, oos: {}, ooc: {} },
          hasInvalidError: false,
          hasOOSError: false,
          hasOOCError: false,
        };
      }

      const invalid = validation?.invalid[batchId] ?? {};
      const oos = validation?.oos[batchId] ?? {};
      const ooc = validation?.ooc[batchId] ?? {};
      return {
        validation: { invalid, oos, ooc },
        hasInvalidError: !isEmpty(invalid),
        hasOOSError: !isEmpty(oos),
        hasOOCError: !isEmpty(ooc),
      };
    },
    [validation]
  );

  return {
    validation,
    hasInvalidError: !isEmpty(validation?.invalid),
    hasOOSError: !isEmpty(validation?.oos),
    hasOOCError: !isEmpty(validation?.ooc),
    getErrorsByBatchId,
  };
}
