import type { PackageListsData } from '@solar/data';
import { useParams } from 'react-router-dom';
import { Header } from '../Layout/Header/Header';
import { Table, Button, Message } from '@mezzanine-ui/react';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import { FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  useShipmentPackageListsGet,
  useShipmentBoxMergeForm,
} from '@solar/data';

export function ShipmentBoxMergePage() {
  const { shipmentId } = useParams();
  const {
    formMethods,
    formState,
    formFieldBoxNumberName,
    formSubmitCreator,
    isError,
  } = useShipmentBoxMergeForm();
  const { packageLists, isLoading, refetchPackageLists } =
    useShipmentPackageListsGet({
      deliveryOrderId: shipmentId ?? '',
      formMethods,
    });

  const tableColumns = [
    {
      title: '箱號',
      width: 160,
      render(source: PackageListsData, index: number) {
        return (
          <InputField
            control={formMethods.control}
            registerName={formFieldBoxNumberName(index)}
          />
        );
      },
    },
    { title: '出貨單項次', dataIndex: 'deliveryOrderLine', width: 130 },
    {
      title: '出貨序號',
      dataIndex: 'snum',
      width: 130,
    },
    { title: '料號', dataIndex: 'materialId' },
    { title: '品名', dataIndex: 'materialName', width: 200 },
    {
      title: '數量／單位',
      width: 130,
      render(source: PackageListsData) {
        return (
          <span>
            {source.quantity}／{source.productUnit}
          </span>
        );
      },
    },
    { title: '包裝料號', dataIndex: 'packagingMaterialId', width: 130 },
  ];

  return (
    <div>
      <Header title="包裝編輯" />
      <div style={{ marginTop: 24 }}></div>
      <FormProvider {...formMethods}>
        <FormFieldsWrapper
          methods={formMethods}
          onSubmit={formSubmitCreator({
            successCallback: () => {
              Message.success('更新成功');
              refetchPackageLists();
            },
            errorCallback: (error) => {
              try {
                if (!error?.message) return;
                const { message } = JSON.parse(error.message) ?? {};
                Message.error(`更新失敗${message ? '：' + message : ''}`);
              } catch (error) {
                Message.error('更新失敗');
              }
            },
          })}
        >
          <Table
            loading={isLoading}
            columns={tableColumns}
            dataSource={packageLists.map((pack, index) => ({
              key: index.toString(),
              ...pack,
            }))}
          />
          <div
            style={{
              marginTop: 24,
              display: 'flex',
              gap: 24,
              alignItems: 'center',
            }}
          >
            <Link to={`/sales-distribution/shipment`}>
              <Button type="button" variant="outlined">
                上一步
              </Button>
            </Link>
            <Button
              type="submit"
              variant="contained"
              loading={formState.isSubmitting}
            >
              儲存
            </Button>
            <div>
              {!isLoading && isError ? (
                <span style={{ color: '#AB0C0C' }}>箱號需為正整數</span>
              ) : null}
            </div>
          </div>
        </FormFieldsWrapper>
      </FormProvider>
    </div>
  );
}
