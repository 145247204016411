import { TableDataSourceWithID } from '@mezzanine-ui/core/table';
import { OffsetBased } from '../pagination';

export type DeliveryOrder = {
  id: string;
  customerId: string;
  receiverId: string;
  receiverName: string;
  receiverAddress: string;
  createdAt: string;
  customerName: string;
  packedState: string;
};

export type DeliveryOrderDetail = {
  id: string;
  customerId: string;
  employeeName: string;
  receiverId: string;
  receiverName: string;
  receiverAddress: string;
  createdAt: string;
  expectedCompletedAt: string;
  customerName: string;
  packedState: string;
  specs: Array<{
    orderId: string;
    expectedDeliveryDate: string;
    materialItems: Array<
      {
        materialStockUnit: string;
        productionDeliveryOrderSpecId: number; // 未來可能會變為 string
        quantity: string;
        productionDeliveryOrderId: string;
        productionDeliveryOrderLine: string;
        expectedDeliveryDate: string;
        orderId: string;
        /** @deprecated */
        unitName: string;
        quantityUnit: string;
        quantityUnitName: string;
        materialDescription: string;
        materialId: string;
      } & TableDataSourceWithID
    >;
  }>;
  bindings: {
    deliveryOrderId: string;
    deliveryOrderLine: string;
    relatedDeliveryOrderId: string;
    relatedDeliveryOrderLine: string;
    relatedDeliveryOrderMaterialId: string;
    relatedInventoryPurchaseOrderId: any;
    relatedInventoryPurchaseOrderItemId: any;
    relatedInventoryPurchaseOrderMaterialId: any;
    createdAt: string;
    updatedAt: string;
    deletedAt: any;
    relations: {
      deliveryOrderId: string;
      deliveryOrderLine: string;
      relatedOrderId: string;
      relatedOrderDetailId: string;
      relatedOrderDetailMaterialId: string;
    }[];
  }[];
};

export type GetDeliveryOrdersParams = OffsetBased & {
  searchTerm?: string;
  dateFrom?: string;
  dateTo?: string;
  customerIds?: string[];
  packedStates?: ('WAITING' | 'PARTIAL' | 'COMPLETE')[];
  processTypes?: string[];
  sortType?:
    | 'CREATED_AT_DESC'
    | 'CREATED_AT_ASC'
    | 'EXPECTED_COMPLETED_AT_DESC'
    | 'EXPECTED_COMPLETED_AT_ASC';

  customerNames?: string[];
  createdDateFrom?: string;
  createdDateTo?: string;
  expectedCompletedDateFrom?: string;
  expectedCompletedDateTo?: string;
  employeeNames?: string[];
  orderIds?: string[];
  types?: (
    | 'ZLR'
    | 'ZLR2'
    | 'ZLR3'
    | 'ZLR4'
    | 'ZLF1'
    | 'ZLC'
    | 'ZLF2'
    | 'ZLF3'
    | 'ZLF4'
    | 'ZLF5'
  )[];
};
