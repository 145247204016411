import { useMemo } from 'react';
import { TableColumn } from '@mezzanine-ui/core/table';
import { InventoryPurchaseOrderResponse } from '@solar/data';
import { CancelModalOpenFunc } from '../../../typings';
import { Button } from '@mezzanine-ui/react';
import moment from 'moment';

export function usePurchaseOrderReceivedColumns({
  cancelModalOpen,
}: {
  cancelModalOpen: CancelModalOpenFunc;
}) {
  const column: TableColumn<InventoryPurchaseOrderResponse['logs'][number]>[] =
    useMemo(
      () => [
        { title: '狀態', dataIndex: 'actionType' },
        { title: '品名', dataIndex: 'material.description' },
        { title: '料號', dataIndex: 'materialId' },
        { title: '舊料號', dataIndex: 'oldMaterialId' },
        { title: '批號流水序', dataIndex: 'materialBatchId' },
        { title: '收料數量', dataIndex: 'secondaryMaterialUnitQuantity' },
        { title: '單位', dataIndex: 'material?.secondaryMaterialUnit' },
        { title: '實際重量', dataIndex: 'mainMaterialUnitQuantity' },
        { title: '單位', dataIndex: 'material?.mainMaterialUnit?.code' },
        { title: '儲位', dataIndex: 'shelfId' },
        { title: '載具', dataIndex: 'loaderId' },
        {
          title: '收貨時間',
          render: (source) =>
            moment(source?.createdAt).format('YYYY/MM/DD HH:mm:ss'),
        },
        {
          title: '',
          render: (source) => {
            return (
              <Button type="button" onClick={() => cancelModalOpen(() => '')}>
                取消
              </Button>
            );
          },
        },
      ],
      [cancelModalOpen]
    );

  return column;
}
