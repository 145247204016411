import { TableColumn } from '@mezzanine-ui/core/table';
import { Button } from '@mezzanine-ui/react';
import { useMemo } from 'react';
import { ColumnHooks, MaterialStock } from '../types';
import {
  InputUnitQuantityColumn,
  StockUnitQuantityColumn,
} from '../../../WarehouseEnquiry/hooks/useColumns';

export const useMaterialStockColumns = ({ openEditModal }: ColumnHooks) => {
  const columns = useMemo<TableColumn<MaterialStock>[]>(
    () => [
      {
        title: '操作',
        render: (source) => {
          return (
            <Button
              type="button"
              onClick={() => {
                openEditModal({
                  loaderId: source.loaderId,
                  materialId: source.materialId,
                  materialDescription: source.materialDescription,
                  sapBatchId: source.materialSapBatchId,
                  subBatchId: source.materialSubBatchId,
                  newMaterialId: source.materialId,
                });
              }}
            >
              編輯
            </Button>
          );
        },
      },
      { title: '銷售訂單代碼', dataIndex: 'salesOrderId' },
      { title: '料號', dataIndex: 'materialId' },
      { title: '品名', dataIndex: 'materialDescription' },
      { title: '批號', dataIndex: 'batchId' },
      { title: '儲位', dataIndex: 'stackId' },
      { title: '載具', dataIndex: 'loaderId' },
      StockUnitQuantityColumn,
      { title: '基礎計量單位', dataIndex: 'materialStockUnit' },
      InputUnitQuantityColumn,
      {
        title: '管理單位數量',
        dataIndex: 'availableMaterialInputUnitQuantity',
      },
      {
        title: '管理單位',
        dataIndex: 'materialInputUnit',
      },
      {
        title: '備註',
        dataIndex: 'remark',
      },
    ],
    [openEditModal]
  );

  return columns;
};
