import { ChevronDownIcon } from '@mezzanine-ui/icons';
import { Button, Icon, Typography } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import styles from './Filter.module.scss';

type SubmitButtonOnClickType = () => {};

export type SubmitButtonType = {
  name: string;
  onClick: SubmitButtonOnClickType;
};

export interface FilterProps {
  filterHeader: string;
  noFilterHeaderBorder?: boolean;
  filtersData: {
    label: string;
    fn: Function;
    registerName?: string;
    props?: object;
  }[];
  registers: any;
  filterMethods: Function;
  filterDependencies: any[];
  submitButton: SubmitButtonType | null;
  methods: UseFormReturn<FieldValues, any>;
}

export function Filter({
  filterHeader,
  noFilterHeaderBorder,
  filtersData,
  registers,
  filterMethods,
  filterDependencies,
  submitButton,
  methods,
}: FilterProps) {
  const [expandFilter, setExpandFilter] = useState(false);

  const toggleExpandIcon = useCallback(
    () => setExpandFilter((expand) => !expand),
    []
  );

  useEffect(() => {
    filterMethods(registers);
  }, filterDependencies);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Typography variant="h4">{filterHeader}</Typography>
        <Icon
          icon={ChevronDownIcon}
          onClick={toggleExpandIcon}
          className={expandFilter ? styles.noRotateIcon : styles.rotateIcon}
        />
      </div>
      {noFilterHeaderBorder === true ? null : (
        <div className={styles.border}></div>
      )}
      <FormFieldsWrapper
        methods={methods}
        className={expandFilter ? styles.displayNone : ''}
      >
        <div className={styles.filter}>
          {filtersData.map((item, index) => (
            <div key={`${item.registerName ?? ''}-${index}`}>
              {item.fn(item.label, item.props, item.registerName)}
            </div>
          ))}
        </div>
        {submitButton !== null && (
          <div className={styles.button}>
            <Button
              variant="text"
              size="medium"
              type="button"
              onClick={submitButton.onClick}
            >
              {submitButton.name}
            </Button>
          </div>
        )}
      </FormFieldsWrapper>
    </div>
  );
}
