import { MaterialUnit } from '../materials';

export type WorkOrder = MaterialUnit & {
  id: string;
  expectedCompletedAt: string;
  factoryId: string;
  remark: string;
  createdAt: string;
  orderTypeName: string;
  salesOrderId: string;
  salesOrderLineId: string;
  materialId: string;
  materialDescription: string;

  /** @description 預計生產基礎計量單位數量 */
  expectedMaterialStockUnitQuantity: string;
  /** @description 實際生產基礎計量單位數量 */
  actualMaterialStockUnitQuantity: string;
  /** @deprecated */
  expectedMainMaterialUnitQuantity: string;
  /** @deprecated */
  actualMainMaterialUnitQuantity: string;

  /** @description 預計生產管理單位數量 */
  expectedMaterialInputUnitQuantity: string;
  /** @description 實際生產管理單位數量 */
  actualMaterialInputUnitQuantity: string;
  /** @deprecated */
  expectedSecondaryMaterialUnitQuantity: string;
  /** @deprecated */
  actualSecondaryMaterialUnitQuantity: string;

  /** @description 實際重量單位數量 */
  actualMaterialWeightUnitQuantity: string;
};

export type WorkOrderItem = MaterialUnit & {
  type: string;
  materialId: string;
  workCenterId: string;
  materialDescription: string;
  workCenterName: string;
  componentCode: string;

  /** @description 預計生產基礎計量單位數量 */
  expectedMaterialStockUnitQuantity: string;
  /** @description 實際生產基礎計量單位數量 */
  actualMaterialStockUnitQuantity: string;
  /** @deprecated */
  expectedMainMaterialUnitQuantity: string;
  /** @deprecated */
  actualMainMaterialUnitQuantity: string;

  /** @description 預計生產管理單位數量 */
  expectedMaterialInputUnitQuantity: string;
  /** @description 實際生產管理單位數量 */
  actualMaterialInputUnitQuantity: string;
  /** @deprecated */
  expectedSecondaryMaterialUnitQuantity: string;
  /** @deprecated */
  actualSecondaryMaterialUnitQuantity: string;

  /** @description 實際重量單位數量 */
  actualMaterialWeightUnitQuantity: string;
};

export type WorkOrderDetail = WorkOrder & {
  expectedMainProduct: WorkOrderItem[];
  expectedJointProduct: WorkOrderItem[];
  expectedMaterials: WorkOrderItem[];
  batches: any[];
};
