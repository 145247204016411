import {
  Button,
  Checkbox,
  Message,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  InventoryByMaterialSearchType,
  ProductionWorkOrderResponseData,
  UseModalControllerReturn,
  getInventoryByMaterial,
} from '@solar/data';
import { Descriptions, InputNumber, Table } from 'antd';
import { Key, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

export type MaterialSupplyByMaterialModalData = {
  type: InventoryByMaterialSearchType;
  materialId?: ProductionWorkOrderResponseData['materialId'];
  materialBarcode?: string;
  productionWorkOrder?: ProductionWorkOrderResponseData;
};

type MaterialSupplyByMaterialModalProps =
  UseModalControllerReturn<MaterialSupplyByMaterialModalData> & {
    tmpShipList: any[];
    onSubmit: (data: any) => void;
  };

export function MaterialSupplyByMaterialModal({
  open,
  data,
  closeModal,
  tmpShipList,
  onSubmit,
}: MaterialSupplyByMaterialModalProps) {
  const methods = useForm();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const calcMaterialUnitQuantity = useCallback(
    (record: any) => {
      const totalUnitQuantity = tmpShipList?.reduce(
        (acc, cur) =>
          cur?.recordId === record?.recordId
            ? acc + Number(cur?.mainMaterialUnitQuantity)
            : acc,
        0
      );
      return Number(record?.mainMaterialUnitQuantity) - totalUnitQuantity;
    },
    [tmpShipList]
  );

  useEffect(() => {
    if (open && data) {
      getInventoryByMaterial({
        limit: 0,
        type: data.type,
        searchTerms: [data?.materialId, data?.materialBarcode].filter(
          Boolean
        ) as string[],
      }).then((response) => {
        if ((response?.records?.length ?? 0) > 0) {
          setDataSource(
            response?.records?.map((record) => ({
              id: record?.recordId,
              ...record,
            }))
          );
        } else {
          Message.info('查無資料');
        }
      });
    }
  }, [open, data]);

  const onClose = () => {
    closeModal();
    methods.reset({});
    setDataSource([]);
    setSelectedRowKeys([]);
  };

  const handleSubmit = methods.handleSubmit((formState) => {
    const items = selectedRowKeys?.map((key) => {
      const targetSource: any = dataSource?.find(
        (data: any) => data?.recordId === key
      );
      const mainMaterialUnitQuantity = formState[key.toString()];
      const loaderId =
        formState?.loaderId === '' ? 1 : Number(formState?.loaderId);
      return {
        id: `${targetSource?.recordId}-${loaderId}`,
        recordId: targetSource?.recordId,
        specId: Number(data?.productionWorkOrder?.specId),
        materialId: targetSource?.materialId,
        materialDescription: targetSource?.materialDescription,
        batchId: targetSource?.batchId,
        stackId: targetSource?.stackId,
        loaderId,
        mainMaterialUnitQuantity,
        materialBarcode: targetSource?.materialBarcode,
      };
    });
    onSubmit(items);
    onClose();
  });

  return (
    <Modal style={{ width: '60vw' }} open={open} onClose={onClose}>
      <ModalHeader>庫存（物料視角）</ModalHeader>
      <ModalBody>
        <FormFieldsWrapper methods={methods}>
          <Descriptions>
            <Descriptions.Item label="料號">
              {data?.productionWorkOrder?.materialId}
            </Descriptions.Item>
            <Descriptions.Item label="品名">
              {data?.productionWorkOrder?.materialDescription}
            </Descriptions.Item>
          </Descriptions>
          <Table
            style={{ height: 500 }}
            scroll={{ y: 400 }}
            rowKey="recordId"
            dataSource={dataSource ?? []}
            pagination={{ hideOnSinglePage: true }}
            rowSelection={{
              selectedRowKeys,
              onChange: (keys) => setSelectedRowKeys(keys),
              renderCell: (value, record: any) => (
                <Checkbox
                  checked={selectedRowKeys?.includes(record?.recordId)}
                  onChange={() => {
                    const keySet = new Set(selectedRowKeys);
                    if (value) keySet.delete(record?.recordId);
                    else keySet.add(record?.recordId);
                    setSelectedRowKeys([...keySet]);
                  }}
                  disabled={calcMaterialUnitQuantity(record) <= 0}
                />
              ),
              columnTitle: <></>,
            }}
            columns={[
              {
                title: '批號',
                dataIndex: 'batchId',
              },
              {
                title: '儲位',
                dataIndex: 'stackId',
              },
              {
                title: '載具',
                dataIndex: 'loaderId',
              },
              {
                title: '庫存數量',
                dataIndex: 'mainMaterialUnitQuantity',
                render: (_v, record) => calcMaterialUnitQuantity(record),
              },
              {
                title: '發料數量',
                dataIndex: 'shipMainMaterialUnitQuantity',
                render: (_v, record) => (
                  <InputNumber
                    disabled={!selectedRowKeys.includes(record?.recordId)}
                    max={calcMaterialUnitQuantity(record)}
                    value={methods.watch(record?.recordId?.toString())}
                    onChange={(value) => {
                      methods.setValue(record?.recordId?.toString(), value);
                    }}
                  />
                ),
              },
            ]}
          />
          {/* <InputField label="放入載具" registerName="loaderId" /> */}
        </FormFieldsWrapper>
      </ModalBody>
      <ModalFooter
        style={{
          display: 'flex',
          justifyContent: 'end',
          gap: 'var(--mzn-spacing-2)',
        }}
      >
        <Button onClick={onClose}>取消</Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={(selectedRowKeys?.length ?? 0) <= 0}
        >
          新增
        </Button>
      </ModalFooter>
    </Modal>
  );
}
