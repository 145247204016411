import { DateRangePickerField, FormFieldsWrapper, InputField, SelectField } from "@mezzanine-ui/react-hook-form";
import { UseFormReturn } from "react-hook-form";
import styles from './return-material-filter.module.scss';

type ReturnMaterialFilter = {
  methods: UseFormReturn,
}

export function ReturnMaterialFilter({ methods }: ReturnMaterialFilter) {
  return (
    <FormFieldsWrapper methods={methods} className={styles.filter_form}>
      <InputField
        width={500}
        registerName="keyword"
        placeholder="請輸入關鍵字進行搜尋" />
      <SelectField
        registerName="resourceType"
        placeholder="來源"
        options={[
          {
            id: '1',
            name: '工單',
          },
          {
            id: '2',
            name: '部門費用',
          },
          {
            id: '3',
            name: 'IPQC 檢測',
          },
        ]} />
      <DateRangePickerField registerName="dateRange" />
    </FormFieldsWrapper>
  )
}