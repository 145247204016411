import { TableColumn } from '@mezzanine-ui/core/table';
import {
  Message,
  Option,
  Select,
  Switch,
  Table,
  Typography,
  TypographyColor,
} from '@mezzanine-ui/react';
import { NumericInputField, RowSection } from '@solar/templates';
import { useCallback, useMemo, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import classes from './create-configure-table.module.scss';

import {
  FetchingInventoryStockTypes,
  InventoryByMaterialSearchType,
  getInventoryByMaterial,
} from '@solar/data';
import { xor } from 'lodash';
import { BarcodeScanInput } from '../../../Material/MaterialShift/BarcodeScanInput';
import {
  AvailableStats,
  ConfigureTableProps,
  DispatchSettingModalFormProps,
} from '../types';
import { selectedUnitsGenerator } from '../utils';
import Decimal from 'decimal.js';
import { FormFieldsDebug } from '@mezzanine-ui/react-hook-form';

const FETCH_LIMIT = 20;

const textColor = {
  true: 'inherit' as TypographyColor,
  false: 'error' as TypographyColor,
};

const availableStats = {
  [AvailableStats.AVAILABLE]: '未限制',
  [AvailableStats.SALES]: '銷售訂單',
  [AvailableStats.PENDING]: '預產批號',
  [AvailableStats.EXPECTED]: '預產批號',
};

export function CreateConfigureTable({ index }: { index: number }) {
  const [switchChecked, setSwitchChecked] = useState(false);

  const methods = useFormContext<DispatchSettingModalFormProps>();

  const fields = useWatch({
    control: methods.control,
    name: `tables.${index}.table`,
  });

  const preserveTable = useWatch({
    control: methods.control,
    name: `tables.${index}.preserveTable`,
  });

  const selectedRowKeyToReceiveQuantity = useWatch({
    control: methods.control,
    name: `tables.${index}.selectedRowKeyToReceiveQuantity`,
  });

  const watchSelectedRowKeys = useWatch({
    control: methods.control,
    name: `tables.${index}.selectedRowKeys`,
  });

  const offset = useWatch({
    control: methods.control,
    name: `tables.${index}.pageOffset`,
  });

  const isReachEnd = useWatch({
    control: methods.control,
    name: `tables.${index}.isReachEnd`,
  });

  const { append } = useFieldArray({
    control: methods.control,
    name: `tables.${index}.table`,
  });

  const materialId = useWatch({
    control: methods.control,
    name: `tables.${index}.materialId`,
  });

  const materialDescription = useWatch({
    control: methods.control,
    name: `tables.${index}.materialDescription`,
  });

  const handleReceiveQuantity = useCallback(
    ({
      value,
      source,
      localIndex,
      targetRowField,
    }:
      | {
          value: string;
          source: ConfigureTableProps;
          localIndex: number;
          targetRowField: 'receiveQuantity';
        }
      | {
          value: ConfigureTableProps['selectedUnit'];
          source: ConfigureTableProps;
          localIndex: number;
          targetRowField: 'selectedUnit';
        }) =>
      // | {
      //     value: string;
      //     source: ConfigureTableProps;
      //     localIndex: number;
      //     targetRowField: 'materialWeightUnitQuantity';
      //   }
      {
        if (switchChecked === false) {
          methods.setValue(
            `tables.${index}.table.${localIndex}.${targetRowField}`,
            value
          );

          const findIndex = preserveTable.findIndex(
            (row) =>
              row.batchStoredLoaderRecordId ===
              source['batchStoredLoaderRecordId']
          );

          if (findIndex !== -1) {
            methods.setValue(
              `tables.${index}.preserveTable.${findIndex}.${targetRowField}`,
              value
            );
          }
        }

        if (switchChecked === true) {
          methods.setValue(
            `tables.${index}.preserveTable.${localIndex}.${targetRowField}`,
            value
          );

          const findIndex = fields.findIndex(
            (row) =>
              row.batchStoredLoaderRecordId ===
              source['batchStoredLoaderRecordId']
          );

          if (findIndex !== -1) {
            methods.setValue(
              `tables.${index}.table.${findIndex}.${targetRowField}`,
              value
            );
          }
        }

        // 需求變更: 原為自行輸入領用實際重量。現為按比例 [可用數量：實際重量] 程式計算
        if (targetRowField === 'receiveQuantity') {
          const countedWeight = new Decimal(
            source['actualMaterialWeightUnitQuantity']
          )
            .mul(value)
            .div(source['availableMaterialInputUnitQuantity']);

          methods.setValue(
            `tables.${index}.selectedRowKeyToMaterialWeightUnitQuantity.${source['batchStoredLoaderRecordId']}`,
            countedWeight.toString()
          );
        }

        fields.forEach((_, i) => {
          methods.trigger(`tables.${index}.table.${i}.${targetRowField}`);
        });

        preserveTable.forEach((_, i) => {
          methods.trigger(
            `tables.${index}.preserveTable.${i}.${targetRowField}`
          );
        });
      },
    [switchChecked, methods, fields, preserveTable]
  );

  const columns: TableColumn<ConfigureTableProps>[] = useMemo(
    () => [
      {
        title: '批號',
        width: 200,
        render: (source) => (
          <Typography color={textColor[`${source.availableState}`]}>
            {source['batchId']}
          </Typography>
        ),
      },
      {
        title: '批次備註',
        render: (source) => (
          <Typography color={textColor[`${source.availableState}`]} ellipsis>
            {source['materialSapBatchRemark']}
          </Typography>
        ),
      },
      {
        title: '備註',
        render: (source) => (
          <Typography color={textColor[`${source.availableState}`]} ellipsis>
            {source['materialSubBatchRemark']}
          </Typography>
        ),
      },
      {
        title: '儲位',
        width: 150,
        render: (source) => (
          <Typography color={textColor[`${source.availableState}`]} ellipsis>
            {source['stackId']}
          </Typography>
        ),
      },
      {
        title: '載具',
        render: (source) => (
          <Typography color={textColor[`${source.availableState}`]} ellipsis>
            {source['loaderId']}
          </Typography>
        ),
      },
      {
        title: '庫存狀態',
        render: (source) => (
          <Typography color={textColor[`${source.availableState}`]} ellipsis>
            {availableStats[source['type']]}
          </Typography>
        ),
      },
      {
        title: '可用數量',
        render: (source, localIndex) => {
          const err =
            switchChecked === false
              ? ((methods?.formState?.errors?.tables ?? [])[index]?.table ??
                  [])[localIndex]?.receiveQuantity
              : ((methods?.formState?.errors?.tables ?? [])[index]
                  ?.preserveTable ?? [])[localIndex]?.receiveQuantity;

          return (
            <div
              style={{
                border: err ? '1px solid var(--mzn-color-error)' : 'white',
              }}
            >
              <Typography
                color={textColor[`${source.availableState}`]}
                ellipsis
              >
                {/* {source?.selectedUnit?.id === 'materialStockUnit' &&
                  source['availableMaterialStockUnitQuantity']} */}
                {source?.selectedUnit?.id === 'materialInputUnit' &&
                  source['availableMaterialInputUnitQuantity']}
              </Typography>
            </div>
          );
        },
      },
      {
        title: '單位',
        width: 140,
        render: (source, localIndex) => {
          const options = selectedUnitsGenerator({ source });

          return (
            <Select
              className={classes['select']}
              value={source['selectedUnit']}
              onChange={(selectValue: ConfigureTableProps['selectedUnit']) => {
                handleReceiveQuantity({
                  value: selectValue,
                  source,
                  localIndex,
                  targetRowField: 'selectedUnit',
                });

                methods.trigger(
                  `tables.${index}.table.${localIndex}.receiveQuantity`
                );
                methods.trigger(
                  `tables.${index}.preserveTable.${localIndex}.receiveQuantity`
                );
              }}
              fullWidth
            >
              {options.map((option) => {
                return (
                  <Option key={option.id} value={option.id}>
                    {option.name}
                  </Option>
                );
              })}
            </Select>
          );
        },
      },
      {
        title: '實際重量',
        render: (source) => {
          return (
            <Typography color={textColor[`${source.availableState}`]} ellipsis>
              {source['actualMaterialWeightUnitQuantity']}
            </Typography>
          );
        },
      },
      {
        title: '單位',
        width: 80,
        render: (source) => {
          return (
            <Typography color={textColor[`${source.availableState}`]} ellipsis>
              {/* {source['materialStockUnit']} */}G
            </Typography>
          );
        },
      },
      {
        title: '領用數量',
        width: 200,
        render: (source, localIndex) => {
          const err =
            switchChecked === false
              ? ((methods?.formState?.errors?.tables ?? [])[index]?.table ??
                  [])[localIndex]?.receiveQuantity
              : ((methods?.formState?.errors?.tables ?? [])[index]
                  ?.preserveTable ?? [])[localIndex]?.receiveQuantity;

          return (
            <>
              <NumericInputField
                key={source.id}
                onChange={(e) => {
                  if (isNaN(Number(e.target.value))) {
                    e.preventDefault();
                    return;
                  }

                  handleReceiveQuantity({
                    value: e.target.value,
                    source,
                    localIndex,
                    targetRowField: 'receiveQuantity',
                  });
                }}
                disabled={!watchSelectedRowKeys.includes(source['id'])}
                valueAsNumber
                registerName={`tables.${index}.selectedRowKeyToReceiveQuantity.${source['batchStoredLoaderRecordId']}`}
                validateMode="float"
              />
              {err && (
                <Typography
                  color="error"
                  variant="caption"
                  style={{ display: 'flex' }}
                >
                  {err.message}
                </Typography>
              )}
            </>
          );
        },
      },
      {
        title: '領用實際重量',
        width: 200,
        render: (source, localIndex) => {
          return (
            <NumericInputField
              key={source.id}
              disabled={true} //!watchSelectedRowKeys.includes(source['id'])
              valueAsNumber
              registerName={`tables.${index}.selectedRowKeyToMaterialWeightUnitQuantity.${source['batchStoredLoaderRecordId']}`}
              validateMode="float"
            />
          );
        },
      },
    ],
    [
      watchSelectedRowKeys,
      fields,
      // watchSpecifiedQuantity,
      methods,
      preserveTable,
      switchChecked,
      index,
    ]
  );

  // 針對批號搜尋物料
  const searchMaterialsByMaterialSapBatchId = useCallback(
    async (materialBatchId: string | undefined) => {
      methods.setValue(`tables.${index}.pageOffset`, 0);

      setSwitchChecked(false);

      try {
        const res = await getInventoryByMaterial({
          materialIds: [materialId],
          materialBatchIds: materialBatchId ? [materialBatchId] : [],
          offset: 0,
          type: InventoryByMaterialSearchType.LOADER_BARCODE,
          enableUnavailable: true,
          stockTypes: [
            FetchingInventoryStockTypes.AVAILABLE,
            FetchingInventoryStockTypes.EXPECTED,
            FetchingInventoryStockTypes.SALES,
            FetchingInventoryStockTypes.PENDING,
          ],
          withElementRatio: null,
        });

        if (res?.records?.length > 0) {
          const records = res?.records?.map((row) => ({
            ...row,
            id: row.batchStoredLoaderRecordId,
            availableState: row?.availableState,
            selectedUnit: selectedUnitsGenerator({ source: row })[0],
          })) as unknown as ConfigureTableProps[];

          methods.setValue(`tables.${index}.table`, records);
          methods.setValue(
            `tables.${index}.isReachEnd`,
            !res?.pageInfo.hasNext!
          );

          methods.setValue(`tables.${index}.pageOffset`, res?.pageInfo.offset!);
        }
      } catch (err) {
        if (err instanceof Error) {
          Message.error(JSON.parse(err.message).message);
        }
      }
    },
    []
  );

  const getMaterialsOnRowSelect = useCallback(
    async ({
      batchStoredLoaderRecordIds,
      currentUnitOfSelectedRows,
      selectedRowIds,
    }: {
      batchStoredLoaderRecordIds: string[];
      currentUnitOfSelectedRows: ConfigureTableProps['selectedUnit'][];
      selectedRowIds: string[];
    }) => {
      if (batchStoredLoaderRecordIds.length === 0) {
        methods.setValue(`tables.${index}.preserveTable`, []);

        return;
      }

      try {
        const res = await getInventoryByMaterial({
          limit: 0,
          materialIds: [materialId],
          offset: 0,
          batchStoredLoaderRecordIds,
          type: InventoryByMaterialSearchType.LOADER_BARCODE,
          enableUnavailable: true,
        });

        if (res?.records?.length > 0) {
          const records = res?.records?.map((row, index) => {
            return {
              ...row,
              id: row.batchStoredLoaderRecordId,
              // 強迫
              availableMaterialInputUnitQuantity:
                row?.availableMaterialInputUnitQuantity,

              // 直接指定固定的單位 materialInputUnit
              selectedUnit: selectedUnitsGenerator({
                source: row,
              })[0],
              // row.batchStoredLoaderRecordId === Number(selectedRowIds[index])
              //   ? currentUnitOfSelectedRows[index]
              //   : fields.find(
              //       (preserveTableRow) =>
              //         row.batchStoredLoaderRecordId ===
              //         Number(preserveTableRow.batchStoredLoaderRecordId)
              //     )?.selectedUnit,
            };
          }) as unknown as ConfigureTableProps[];

          methods.setValue(`tables.${index}.preserveTable`, records);
        }
      } catch (err) {
        if (err instanceof Error) {
          Message.error(JSON.parse(err.message).message);
        }
      }
    },
    [index, fields, methods, materialId, getInventoryByMaterial]
  );

  // useEffect(() => {
  //   let subTotal = new Decimal(0);

  //   Object.keys(selectedRowKeyToReceiveQuantity).forEach((selectedRowKey) => {
  //     subTotal = new Decimal(
  //       selectedRowKeyToReceiveQuantity[selectedRowKey] || 0
  //     ).plus(subTotal);
  //   });

  //   methods.setValue(`tables.${index}.subTotal`, String(subTotal));
  // }, [selectedRowKeyToReceiveQuantity]);

  if (!fields) return null;

  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: 'var(--mzn-spacing-4)',
          alignItems: 'baseline',
        }}
      >
        <RowSection label="料號：" colGap="var(--mzn-spacing-4)">
          {materialId}
        </RowSection>
        <RowSection label="品名：" colGap="var(--mzn-spacing-4)">
          {materialDescription}
        </RowSection>
        {/* <div style={{ display: 'flex', gap: '8px', alignItems: 'baseline' }}>
          <CheckboxField
            registerName={`tables.${index}.designatedInventory`}
            style={{ alignSelf: 'center' }}
            inputProps={{ id: `tables.${index}.designatedInventory` }}
            // onChange={() => setCheckBoxTicked((prev) => !prev)}
          />
          <label htmlFor="specifyBatch">指定批號</label>
        </div> */}
        <RowSection label="搜尋批號：">
          <BarcodeScanInput
            placeholder="請輸入批號"
            otherOnKeyDownAction={searchMaterialsByMaterialSapBatchId}
          />
        </RowSection>
        {/* <div className={classes['already-select-container']}>
          <Typography
            className={cx(
              classes.label,
              classes['label-font'],
              'mzn-form-field__label'
            )}
            color="text-secondary"
            variant="h1"
            noWrap
          >
            已選取數量：
          </Typography>
          <label></label>
          <Input
            className={classes['input-field']}
            value={subTotal || '0'}
            disabled
          />
        </div> */}
      </div>
      <br />
      <div style={{ width: '100%', overflow: 'auto' }}>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <button
            style={{ visibility: 'hidden' }}
            onClick={() => {
              console.log(methods.getValues(`tables.${index}`));
            }}
          >
            1
          </button>
          <label htmlFor="switch">顯示已勾選（關／開）</label>
          <Switch
            inputProps={{ id: 'switch' }}
            checked={switchChecked}
            onChange={async () => {
              if (switchChecked === false) {
                preserveTable.forEach((row, localIndex) => {
                  methods.setValue(
                    `tables.${index}.preserveTable.${localIndex}.receiveQuantity`,
                    selectedRowKeyToReceiveQuantity?.[
                      row?.batchStoredLoaderRecordId
                    ]
                  );
                });

                const preserved = methods.getValues(
                  `tables.${index}.preserveTable`
                );

                preserved.forEach((_, index) => {
                  methods.trigger(
                    `tables.${index}.preserveTable.${index}.receiveQuantity`
                  );
                });
              } else if (switchChecked === true) {
                fields.forEach((_, index) => {
                  methods.trigger(
                    `tables.${index}.table.${index}.receiveQuantity`
                  );
                });
              }

              setSwitchChecked((prev) => !prev);
            }}
          />
        </div>
        <Table
          columns={columns}
          className={classes.actionTable} //"QQQ"
          dataSource={switchChecked ? preserveTable : fields}
          style={{ maxHeight: '800px' }}
          scroll={{ y: 300, x: 1700 }}
          {...(switchChecked === false && {
            fetchMore: {
              callback: async () => {
                try {
                  const res = await getInventoryByMaterial({
                    materialIds: [materialId],
                    offset: offset + FETCH_LIMIT,
                    type: InventoryByMaterialSearchType.LOADER_BARCODE,
                    enableUnavailable: true,
                    stockTypes: [
                      FetchingInventoryStockTypes.AVAILABLE,
                      FetchingInventoryStockTypes.SALES,
                    ],
                  });

                  if (res?.records?.length > 0) {
                    const records = res?.records?.map((row) => ({
                      ...row,
                      id: row.batchStoredLoaderRecordId,
                      selectedUnit: selectedUnitsGenerator({ source: row })[0],
                    })) as unknown as ConfigureTableProps[];
                    append(records);
                  }

                  methods.setValue(
                    `tables.${index}.isReachEnd`,
                    !res?.pageInfo.hasNext!
                  );

                  methods.setValue(
                    `tables.${index}.pageOffset`,
                    res?.pageInfo.offset!
                  );
                } catch (err) {
                  Message.error('資料讀取有誤！');
                }
              },
              isReachEnd: isReachEnd,
            },
          })}
          rowSelection={{
            selectedRowKey: watchSelectedRowKeys,
            onChange: async (keys) => {
              const prevSelectedRowKeys = methods.getValues(
                `tables.${index}.selectedRowKeys`
              );
              const nextSelectedRowKeys = keys;

              const difference = xor(prevSelectedRowKeys, nextSelectedRowKeys);

              methods.setValue(`tables.${index}.selectedRowKeys`, keys);

              const currentUnitOfSelectedRow = fields.filter(
                (row) => row.batchStoredLoaderRecordId === difference[0]
              )[0].selectedUnit;

              const currentUnitOfSelectedRows = fields.reduce((accu, cur) => {
                if (difference.includes(cur.batchStoredLoaderRecordId)) {
                  accu.push(cur.selectedUnit);
                }

                return accu;
              }, [] as ConfigureTableProps['selectedUnit'][]);

              await getMaterialsOnRowSelect({
                batchStoredLoaderRecordIds: keys,
                currentUnitOfSelectedRows,
                selectedRowIds: difference,
              });

              // 以下兩段處理 onTick check-box trigger yup validation
              fields.forEach((_, i) => {
                methods.trigger(`tables.${index}.table.${i}.receiveQuantity`);
              });
              preserveTable.forEach((_, i) => {
                methods.trigger(
                  `tables.${index}.preserveTable.${i}.receiveQuantity`
                );
              });
            },
          }}
        />
        {window.location.host === 'dev-edges.solartech.cloud' && (
          <FormFieldsDebug />
        )}
      </div>
    </>
  );
}
