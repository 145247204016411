import { SearchIcon } from '@mezzanine-ui/icons';
import { Button, Icon, Input, Table, Typography } from '@mezzanine-ui/react';
import {
  API_NAMESPACE,
  IQCOrderType,
  IncomingPurchaseOrder,
  checkJWTRoles,
  useGetDeliveryOrderDetail,
} from '@solar/data';
import {
  CollapsibleContainer,
  LocationSelectorGroupModal,
  ModalGroup,
  useDraggableColumns,
  useLocationSelectorController,
  useModalGroupController,
} from '@solar/templates';
import { useCallback, useMemo, useRef, useState } from 'react';
import classes from './incoming-inspect-detail-page.module.scss';

import moment from 'moment';
import { useLocation, useParams } from 'react-router';
import { LOCATION_SELECTOR } from '../../Equipment/LocationSelector/LocationSelectorModal';
import styles from '../../SalesAndDistribution/PackagingPage/PackagingPage.module.scss';
import { OrderMetaInfo } from '../components';
import { PageLayout } from '../PageLayout/PageLayout';
import { CUSTOMER_ORDER_META_TRANSLATION } from './constant';
import {
  useCustomerOrderExpectedColumns,
  useCustomerOrderReceivedColumns,
  useDebounceILikeSearch,
} from './hooks';
import { CancelReceiveModal } from './Modals/CancelReceiveModal';
import { ProductionDeliveryOrderReceiptModal } from './Modals/ProductionDeliveryOrderReceiptModal';
import {
  MainMaterialUnitsObj,
  ReceiveActionInputs,
} from './Modals/TempReceiveActionModal';
import { Modals, ProductionDeliveryOrderReceiptModalOpenFunc } from './typings';
import { handleTableDataILikeSearch } from './utils';
import { Space } from 'antd';
import { IncomingQualityControlTable } from '../../Qualifications/IncomingQualityControlTable/IncomingQualityControlTable';
import { IncomingQualityControlModal } from './IncomingQualityControlModal';

const namespace = API_NAMESPACE.MATERIALS;

export type ReceivableItemTable = {
  id: string;
  materialId: string;
  oldMaterialId: string;
  materialDescription: string;
  expectedMainMaterialUnitQuantity: number;
  receivedMainMaterialUnitQuantity?: number;
  receiveUnit: string;
  specId: string;
};

export type ReceivedMaterialItemTable = ReceivableItemTable &
  Pick<ReceiveActionInputs, 'loader' | 'warehouse'> & {
    subItems: ReceivedMaterialSubItem[];
  };

export type ReceivedMaterialSubItem = { id: string } & MainMaterialUnitsObj &
  Pick<ReceivableItemTable, 'receiveUnit'>;

export function VirtualInDetailPage() {
  const location = useLocation();
  const { orderId } = useParams();
  const { deliveryOrder: data } = useGetDeliveryOrderDetail(orderId);
  const orders = data?.specs.flatMap((row) => row.materialItems) ?? [];
  const expectedTableRef = useRef<HTMLTableElement>(null);
  const receivedTableRef = useRef<HTMLTableElement>(null);

  const { tableDataSearchTerms, debounceInputHandler } =
    useDebounceILikeSearch();

  const orderMetaDataLabelAndContext = useMemo(
    () =>
      data
        ? Object.entries(CUSTOMER_ORDER_META_TRANSLATION).map(([key, zh]) => ({
            label: zh,
            context: ['expectedCompletedAt'].includes(key)
              ? moment(
                  data[key as keyof typeof CUSTOMER_ORDER_META_TRANSLATION]
                ).format('YYYY/MM/DD HH:mm')
              : data[key as keyof typeof CUSTOMER_ORDER_META_TRANSLATION],
          }))
        : [],
    [data]
  );

  const modalGroupController = useModalGroupController([
    { name: Modals.ReceiveAction, keepData: true },
    { name: Modals.Cancel },
    { name: LOCATION_SELECTOR },
    { name: Modals.QUALITY_CONTROL },
  ]);

  const locationSelectorController = useLocationSelectorController({
    resetAfterClosing: false,
  });

  const svgMapController = useLocationSelectorController({
    resetAfterClosing: false,
  });

  const receiveActionModalOpen: ProductionDeliveryOrderReceiptModalOpenFunc =
    useCallback(
      (item) => {
        const modalProps = modalGroupController.getModalProps(
          Modals.ReceiveAction
        );
        // const data = modalProps?.data;
        modalGroupController.openModal(Modals.ReceiveAction, [...item]);
      },
      [modalGroupController]
    );

  const cancelModalOpen = useCallback(
    (cancelFunc: () => void) => {
      modalGroupController.openModal(Modals.Cancel, { cancelFunc: cancelFunc });
    },
    [modalGroupController]
  );

  const defaultExpectedColumn = useCustomerOrderExpectedColumns();
  //   {
  //   receiveActionModalOpen,
  // }
  const expectedColumn = useDraggableColumns(
    expectedTableRef,
    defaultExpectedColumn
  );

  const defaultReceivedColumn = useCustomerOrderReceivedColumns({
    cancelModalOpen,
  });

  const onQualityControl = useCallback(
    (action: '驗收' | '退貨', source: IncomingPurchaseOrder) => {
      modalGroupController.openModal(Modals.QUALITY_CONTROL, {
        action,
        source,
      });
    },
    [modalGroupController]
  );

  return (
    <>
      <PageLayout setGoBack>
        <div className={classes['row-block']}>
          <OrderMetaInfo
            labelAndContexts={orderMetaDataLabelAndContext ?? []}
          />
        </div>
        <CollapsibleContainer
          enableRowBlockStyle
          open
          header={
            <div className={classes['action-container']}>
              <Typography variant="h4" color="text-primary">
                應收項目
              </Typography>
              <Input
                placeholder="料號搜尋"
                prefix={<Icon icon={SearchIcon} />}
                size="large"
                clearable
                onChange={(e) =>
                  debounceInputHandler(e, 'receivableMaterialIdSearch')
                }
              />
              <Input
                placeholder="品名搜尋"
                prefix={<Icon icon={SearchIcon} />}
                size="large"
                clearable
                onChange={(e) =>
                  debounceInputHandler(e, 'receivableMaterialDescriptionSearch')
                }
              />
            </div>
          }
        >
          {/* {checkJWTRoles([82]) && (
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                receiveActionModalOpen(orders);
              }}
            >
              收料
            </Button>
          )} */}
          <br />
          <br />
          <Table
            ref={expectedTableRef}
            columns={expectedColumn}
            dataSource={handleTableDataILikeSearch({
              dataSource: orders,
              fieldParams: {
                materialIdKey: 'receivableMaterialIdSearch',
                materialDescriptionKey: 'receivableMaterialDescriptionSearch',
              },
              tableDataSearchTerms,
            })}
            scroll={{ x: 1200 }}
            bodyClassName={styles.table}
            headerClassName={styles.tableHeader}
          />
        </CollapsibleContainer>
        <CollapsibleContainer
          enableRowBlockStyle
          open
          header={
            <div className={classes['action-container']}>
              <Typography variant="h4" color="text-primary">
                已收紀錄
              </Typography>
              {/* <Input
                placeholder="料號搜尋"
                prefix={<Icon icon={SearchIcon} />}
                size="large"
                clearable
                onChange={(e) =>
                  debounceInputHandler(e, 'receivedMaterialIdSearch')
                }
              />
              <Input
                placeholder="品名搜尋"
                prefix={<Icon icon={SearchIcon} />}
                size="large"
                clearable
                onChange={(e) =>
                  debounceInputHandler(e, 'receivedMaterialDescriptionSearch')
                }
              /> */}
            </div>
          }
        >
          <br />
          <div style={{ width: '100%', overflow: 'scroll' }}>
            <IncomingQualityControlTable
              iqcOrderType={IQCOrderType.DELIVERY_ORDER}
              deliveryOrderId={orderId}
              statusIds={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
              getColumns={(defaultColumns) => [
                {
                  width: 100,
                  title: '操作',
                  render: (source: IncomingPurchaseOrder) => (
                    <Space>
                      {checkJWTRoles([82]) &&
                        (source.statusId === 2 || source.statusId === 8) && (
                          <Button
                            type="button"
                            variant="contained"
                            onClick={() => onQualityControl('驗收', source)}
                          >
                            驗收
                          </Button>
                        )}
                      {checkJWTRoles([82]) &&
                        (source.statusId === 5 || source.statusId === 9) && (
                          <Button
                            danger
                            disabled
                            type="button"
                            variant="contained"
                            onClick={() => onQualityControl('退貨', source)}
                          >
                            退貨
                          </Button>
                        )}
                    </Space>
                  ),
                },
                ...defaultColumns,
              ]}
            />
          </div>
          {/* <div style={{ width: '100%', overflow: 'scroll' }}>
            <Table
              ref={receivedTableRef}
              columns={receivedMaterialColumn}
              dataSource={handleTableDataILikeSearch({
                dataSource: [],
                fieldParams: {
                  materialIdKey: 'receivedMaterialIdSearch',
                  materialDescriptionKey: 'receivedMaterialDescriptionSearch',
                },
                tableDataSearchTerms,
              })}
              bodyClassName={styles.table}
              headerClassName={styles.tableHeader}
              scroll={{ x: 2500 }}
            />
          </div> */}
        </CollapsibleContainer>
        <div style={{ height: '16px' }}></div>
      </PageLayout>
      <ModalGroup {...modalGroupController}>
        <CancelReceiveModal />
        <ProductionDeliveryOrderReceiptModal
          locationSelectorController={locationSelectorController}
        />
        <LocationSelectorGroupModal
          controller={svgMapController}
          showShelfSvg={true}
          onSubmit={(_, selectedIds) => {
            locationSelectorController.setSelectedIds(selectedIds ?? {});
            receiveActionModalOpen([]);
          }}
          closeModal={() => receiveActionModalOpen([])}
        />
        <IncomingQualityControlModal purchaseOrderId={orderId} />
      </ModalGroup>
    </>
  );
}
