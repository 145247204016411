import { forwardRef } from 'react';
import { LoadersResponse } from '@solar/data';
import classes from './loader-label-pdf.module.scss';
import { cx } from '@mezzanine-ui/react';

export type LoaderLabelPdfProps = {
  canvasId: string;
  selectedLoader: LoadersResponse['records'][number] | null;
};

export const LoaderLabelPdf = forwardRef<HTMLDivElement, LoaderLabelPdfProps>(
  ({ canvasId, selectedLoader }, ref) => {
    return (
      // <ConfigProvider
      //   theme={{
      //     token: {
      //       borderRadius: 20,
      //       fontSize: 10,
      //     },
      //   }}
      // >
      <div ref={ref} className={cx(classes['wrapper'], 'pdf-wrapper')}>
        <div>
          <img
            src="/assets/brand.png"
            alt="Solar"
            width={253}
            height={73}
            className={classes['logo']}
          />
        </div>
        <div className={classes['middle']}>
          <canvas className={classes['canvas']} id={canvasId} />
          <h1 style={{ marginLeft: 'var(--mzn-spacing-4)' }}>
            {selectedLoader?.id}
          </h1>
        </div>
        {/* <Descriptions
            bordered
            column={3}
            size="small"
            labelStyle={{ width: '3cm' }}
            contentStyle={{ width: '7cm' }}
          >
            <Descriptions.Item label="載具編號" span={3}>
              {selectedLoader?.id ?? ''}
            </Descriptions.Item>
            <Descriptions.Item label="載具類型" span={3}>
              {selectedLoader?.category.name ?? ''}
            </Descriptions.Item>
            <Descriptions.Item label="容量" span={3}>
              {selectedLoader?.capacityL ?? ''}
            </Descriptions.Item>
            <Descriptions.Item label="長" span={1}>
              {selectedLoader?.lengthCm ?? ''}
            </Descriptions.Item>
            <Descriptions.Item label="寬" span={1}>
              {selectedLoader?.widthCm ?? ''}
            </Descriptions.Item>
            <Descriptions.Item label="高" span={1}>
              {selectedLoader?.heightCm ?? ''}
            </Descriptions.Item>

          <Descriptions.Item label="條碼" span={2}>
              <div style={{ width: '168px', height: '168px' }}></div>
            </Descriptions.Item>
          </Descriptions> */}
      </div>
      // </ConfigProvider>
    );
  }
);
