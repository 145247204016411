import { Button, Modal, ModalBody, Typography } from '@mezzanine-ui/react';
import { ModalType } from '@solar/data';
import styles from './WarningModal.module.scss';

interface WarningModalProps {
  title: string;
  openModal: ModalType;
  source: any | null;
  onCloseModal: () => void;
  danger?: boolean;
  onConfirmModal: () => void;
}

export function WarningModal({
  title,
  openModal,
  onCloseModal,
  danger,
  onConfirmModal,
}: WarningModalProps) {
  return (
    <Modal
      open={openModal === 'WarningModal'}
      style={{ width: 400 }}
      hideCloseIcon
    >
      <div className={styles.wrapper}>
        <ModalBody className={styles.modalBody}>
          <Typography variant="h3">{title}</Typography>
          <div className={styles.buttonsWrapper}>
            <Button
              type="button"
              variant="outlined"
              style={{ width: 100 }}
              onClick={onCloseModal}
            >
              取消
            </Button>
            <Button
              type="button"
              variant="contained"
              danger={danger}
              style={{ width: 100 }}
              onClick={onConfirmModal}
            >
              確認
            </Button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}
