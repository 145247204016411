import {
  AutoCompleteField,
  FormFieldsWrapper,
  InputField,
  RadioGroupField,
} from '@mezzanine-ui/react-hook-form';
import styles from './HumanResourceDepartmentInformationPage.module.scss';
import {
  checkJWTRoles,
  EHumanResourceDepartmentInformationState,
  HumanResourceDepartmentInformationData,
  humanResourceDepartmentInformationSchemas,
  useHumanResourceDepartmentInformation,
} from '@solar/data';
import { Button, Table, Typography } from '@mezzanine-ui/react';
import { HumanResourceDepartmentInformationModal } from './HumanResourceDepartmentInformationModal/HumanResourceDepartmentInformationModal';

export function HumanResourceDepartmentInformationPage() {
  const {
    methods,
    filterMethods,
    data,
    total,
    levelListOptions,
    modal,
    queryParams,
    handleSubmitFilter,
    handleChangePage,
    handleChangePageSize,
    handleSubmitModal,
    handleOpenModal,
    handleCloseModal,
  } = useHumanResourceDepartmentInformation();

  const columns = [
    {
      dataIndex: humanResourceDepartmentInformationSchemas.STATE,
      title: '是否有效',
    },
    {
      dataIndex: humanResourceDepartmentInformationSchemas.CODE,
      title: '部門編號',
    },
    {
      dataIndex: humanResourceDepartmentInformationSchemas.NAME,
      title: '部門名稱',
    },
    { dataIndex: humanResourceDepartmentInformationSchemas.BU, title: 'BU' },
    {
      // dataIndex: humanResourceDepartmentInformationSchemas.LEVEL_ID,
      title: '層級',
      render: (source: HumanResourceDepartmentInformationData) => {
        const levelId = source.levelId;
        const levelName =
          levelListOptions?.find((option) => `${option.id}` === `${levelId}`)
            ?.name ?? '';
        return <Typography>{levelName}</Typography>;
      },
    },
    {
      dataIndex: humanResourceDepartmentInformationSchemas.COST_CENTER_ID,
      title: '成本中心',
    },
    {
      title: '編輯',
      render: (source: HumanResourceDepartmentInformationData) => {
        return (
          checkJWTRoles([66]) && (
            <Button type="button" onClick={() => handleOpenModal(source)}>
              編輯
            </Button>
          )
        );
      },
    },
  ];

  return (
    <div>
      <Typography variant="h1">部門資料管理</Typography>
      <FormFieldsWrapper
        methods={filterMethods}
        className={styles.filterWrapper}
        onSubmit={handleSubmitFilter}
      >
        <div className={styles.filter}>
          <Typography noWrap>層級：</Typography>
          <AutoCompleteField
            registerName={humanResourceDepartmentInformationSchemas.LEVEL_ID}
            options={levelListOptions ?? []}
          />
        </div>
        <div className={styles.filter}>
          <Typography noWrap>BU：</Typography>
          <InputField
            registerName={humanResourceDepartmentInformationSchemas.BU}
          />
        </div>
        <div className={styles.radioFilter}>
          <Typography noWrap>是否有效：</Typography>
          <RadioGroupField
            registerName={humanResourceDepartmentInformationSchemas.STATE}
            options={[
              {
                value: EHumanResourceDepartmentInformationState.ALL,
                label: '全部',
              },
              {
                value: '0',
                label: '有效',
              },
              {
                value: '1',
                label: '無效',
              },
            ]}
          />
        </div>
        <div className={styles.submitButton}>
          <Button type="submit" variant="contained">
            搜尋
          </Button>
        </div>
      </FormFieldsWrapper>
      <Table
        className={styles.table}
        columns={columns}
        dataSource={data ?? []}
        pagination={{
          disableAutoSlicing: true,
          total,
          current: queryParams['page'],
          onChange: handleChangePage,
          options: {
            pageSize: queryParams['perPage'],
            onChangePageSize: handleChangePageSize,
            showPageSizeOptions: true,
            pageSizeLabel: '每頁顯示：',
            pageSizeUnit: '筆',
            renderPaginationSummary: () => '',
          },
        }}
      />
      <HumanResourceDepartmentInformationModal
        methods={methods}
        levelListOptions={levelListOptions}
        modal={modal}
        onSubmit={handleSubmitModal}
        onClose={handleCloseModal}
      />
    </div>
  );
}
