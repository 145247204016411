import { TableColumn } from '@mezzanine-ui/core/table';
import { Button } from '@mezzanine-ui/react';
import { DeliveryListingFormFields } from 'apps/frontend/src/app/Transportation/RequirementsManagementPage/RequirementManagement.interface';

export function useDeliveryListColumns(
  editItem: (item: DeliveryListingFormFields, index: number) => void,
  removeItem: (index: number) => void
): TableColumn<DeliveryListingFormFields>[] {
  return [
    {
      title: '操作',
      render: (item: DeliveryListingFormFields, index: number) => (
        <>
          <Button type="button" onClick={() => editItem(item, index)}>
            編輯
          </Button>
          <Button danger type="button" onClick={() => removeItem(index)}>
            刪除
          </Button>
        </>
      ),
      width: 190,
    },
    {
      title: '品號',
      dataIndex: 'productNumber',
      width: 160,
    },
    {
      title: '品名',
      dataIndex: 'productName',
      width: 160,
    },
    {
      title: '批號',
      width: 160,
      render: (item: DeliveryListingFormFields) =>
        `${item.sapBatchId ?? ''} - ${item.itemBatchId ?? ''}`,
      renderTooltipTitle: (item: DeliveryListingFormFields) =>
        `${item.sapBatchId ?? ''} - ${item.itemBatchId ?? ''}`,
    },
    {
      title: '件數',
      dataIndex: 'pieceNumber',
      width: 100,
    },
    {
      title: '毛重',
      dataIndex: 'totalGrossWeight',
      width: 100,
    },
    {
      title: '毛重單位',
      width: 100,
      render: (item: DeliveryListingFormFields) =>
        item.totalGrossWeightUnit?.name,
    },
    {
      title: '尺寸(cm)',
      width: 180,
      render: (item: DeliveryListingFormFields) => {
        const { volumeLength: l, volumeWidth: w, volumeHeight: h } = item;
        if (!l || !w || !h) return '';
        return `${l} x ${w} x ${h}`;
      },
    },
    {
      title: '是否堆疊',
      width: 100,
      render: (item: DeliveryListingFormFields) => item.isStack?.name,
    },
    {
      title: '有無棧板',
      width: 100,
      render: (item: DeliveryListingFormFields) => item.isUsingPallet?.name,
    },
    {
      title: '棧板尺寸(cm)',
      width: 180,
      render: (item: DeliveryListingFormFields) => {
        const { palletSizeLength: l, palletSizeWidth: w } = item;
        if (!l || !w) return '';
        return `${l} x ${w}`;
      },
    },
    {
      title: '棧板號',
      dataIndex: 'palletNumber',
      width: 100,
    },
    {
      title: '棧板重量(KG)',
      dataIndex: 'palletWeight',
      width: 140,
    },
    {
      title: '尺寸(cm)',
      width: 180,
      render: (item: DeliveryListingFormFields) => {
        const { boxLength: l, boxWidth: w, boxHeight: h } = item;
        if (!l || !w || !h) return '';
        return `${l} x ${w} x ${h}`;
      },
    },
  ];
}
