import {
  Button,
  Message,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Typography,
} from '@mezzanine-ui/react';
import { FormFieldsWrapper, SelectField } from '@mezzanine-ui/react-hook-form';
import {
  ResponseStatus,
  useHumanResourceBUManage,
  useHumanResourceBUTeam,
} from '@solar/data';
import { FieldValues, useForm } from 'react-hook-form';
import styles from './BUTeamModal.module.scss';

interface BUTeamModalProps {
  id: string | number;
  code: string;
  name: string;
  oldUnitName: string;
  onClose: () => void;
  onSubmit: () => void;
}

export function BUTeamModal({
  id,
  code,
  name,
  oldUnitName,
  onClose,
  onSubmit,
}: BUTeamModalProps) {
  const { data: buList } = useHumanResourceBUManage();
  const buOptions = buList?.map((bu: { id: string; name: string }) => ({
    id: bu.id,
    name: bu.name,
  }));

  const oldUnitId: { id: string; name: string }[] =
    buOptions?.filter(
      (bu: { id: string; name: string }) => bu.name === oldUnitName
    ) ?? [];
  const defaultValues: FieldValues = {
    select:
      oldUnitId.length === 0
        ? null
        : { id: oldUnitId[0].id, name: oldUnitName },
  };
  const methods = useForm({ defaultValues });

  const { updateMutation: updateHumanResourceBUTeam } =
    useHumanResourceBUTeam();

  const submitModal = async () => {
    const selectField = methods.getValues('selectField');
    if (!selectField) return;
    const res = await updateHumanResourceBUTeam({
      unitId: selectField.id,
      oldUnitId: id,
    });
    if (res === ResponseStatus.SUCCESS) {
      Message.success('儲存成功');
    } else {
      Message.error('儲存失敗');
    }
    onSubmit();
  };

  return (
    <Modal open={true} style={{ width: 864 }} onClose={onClose}>
      <FormFieldsWrapper methods={methods} onSubmit={submitModal}>
        <ModalHeader>BU 歸檔</ModalHeader>
        <ModalBody className={styles.body}>
          <div className={styles.wrapper}>
            <div>
              <Typography color="secondary-light" variant="h6">
                部門名稱
              </Typography>
              <Typography color="primary">{name}</Typography>
            </div>
            <div>
              <Typography color="secondary-light" variant="h6">
                代碼
              </Typography>
              <Typography color="primary">{code}</Typography>
            </div>
            <div
              style={{ background: '#D9D9D9', width: '100%', height: 1 }}
            ></div>
            <SelectField
              registerName="selectField"
              options={buOptions ?? []}
              defaultValue={defaultValues.select}
            ></SelectField>
          </div>
        </ModalBody>
        <ModalFooter style={{ height: 72 }} className={styles.footer}>
          <Button
            variant="outlined"
            className={styles.button}
            style={{ marginRight: 16 }}
            type="button"
            onClick={onClose}
            size="large"
          >
            取消
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={styles.button}
            size="large"
          >
            儲存
          </Button>
        </ModalFooter>
      </FormFieldsWrapper>
    </Modal>
  );
}
