import { getRoleGroup } from './shared';

const SECOND_SPEC_GROUP = {
  AB: {
    name: '低氧版',
  },
  AD: {
    name: '低碳版',
  },
  AE: {
    name: '微碳版',
  },
  AF: {
    name: '無碳版',
  },
  AG: {
    name: '5N金不純物飛利浦規範',
  },
  AH: {
    name: '南茂不純物規範',
  },
  AI: {
    name: '穩懋不純物規範',
  },
  PY: {
    name: 'CM使用ZrO研磨球',
  },
  PZ: {
    name: 'CM使用ST6研磨球',
  },
  C4: {
    name: '雷射噴砂',
  },
  C5: {
    name: 'UCSM',
  },
  R1: {
    name: 'FIST',
  },
  R2: {
    name: '直接由回靶改製薄靶出貨，半品補上爐號',
  },
  R3: {
    name: 'NOVA',
  },
  P1: {
    name: '粉末製程版本3~9',
  },
  I1: {
    name: '鑄錠製程版本1~9',
  },
  E1: {
    name: '靶胚製程版本1~9',
  },
  '01': {
    name: '未分類',
  },
};

export const FIRST_SPEC_GROUP = {
  AA: {
    name: 'AIM',
    nextLevel: SECOND_SPEC_GROUP,
  },
  VA: {
    name: 'VIM',
    nextLevel: SECOND_SPEC_GROUP,
  },
  VH: {
    name: 'VIM+HIP',
    nextLevel: SECOND_SPEC_GROUP,
  },
  PN: {
    name: '銅錳PCN流程',
    nextLevel: SECOND_SPEC_GROUP,
  },
  CP: {
    name: '銅磷流程',
    nextLevel: SECOND_SPEC_GROUP,
  },
  HA: {
    name: 'HP',
    nextLevel: SECOND_SPEC_GROUP,
  },
  HB: {
    name: 'HIP',
    nextLevel: SECOND_SPEC_GROUP,
  },
  HH: {
    name: 'HP+HIP',
    nextLevel: SECOND_SPEC_GROUP,
  },
  HC: {
    name: 'HP+Canning+HIP',
    nextLevel: SECOND_SPEC_GROUP,
  },
  SA: {
    name: 'SPS',
    nextLevel: SECOND_SPEC_GROUP,
  },
  SH: {
    name: 'SPS+HIP',
    nextLevel: SECOND_SPEC_GROUP,
  },
  SC: {
    name: 'SPS+Canning+HIP',
    nextLevel: SECOND_SPEC_GROUP,
  },
  EA: {
    name: 'Ebeam',
    nextLevel: SECOND_SPEC_GROUP,
  },
  CC: {
    name: 'CP+CIP',
    nextLevel: SECOND_SPEC_GROUP,
  },
  CI: {
    name: 'CIP+CIP',
    nextLevel: SECOND_SPEC_GROUP,
  },
  CS: {
    name: 'CP+Sintering',
    nextLevel: SECOND_SPEC_GROUP,
  },
  OA: {
    name: '外購',
    nextLevel: SECOND_SPEC_GROUP,
  },
  OB: {
    name: '外部託工',
    nextLevel: SECOND_SPEC_GROUP,
  },
  FF: {
    name: '未分類',
    nextLevel: SECOND_SPEC_GROUP,
  },
};

const TARGET_SMALL_GROUP = {
  1: {
    name: '1拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  2: {
    name: '2拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  3: {
    name: '3拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  4: {
    name: '4拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  5: {
    name: '5拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  6: {
    name: '6拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  7: {
    name: '7拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  8: {
    name: '8拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  9: {
    name: '9拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  A: {
    name: '10拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  B: {
    name: '11拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  C: {
    name: '12拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  D: {
    name: '13拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  E: {
    name: '14拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  F: {
    name: '15拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  G: {
    name: '16拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  H: {
    name: '17拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  J: {
    name: '18拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  K: {
    name: '19拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  L: {
    name: '20拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  M: {
    name: '21拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  N: {
    name: '22拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  P: {
    name: '23拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  Q: {
    name: '24拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  R: {
    name: '25拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  S: {
    name: '26拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  T: {
    name: '27拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  U: {
    name: '28拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  V: {
    name: '29拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  W: {
    name: '30拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  X: {
    name: '31拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  Y: {
    name: '32拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
  Z: {
    name: '33或以上拼',
    nextLevel: getRoleGroup(FIRST_SPEC_GROUP),
  },
};

const TARGET_MEDIUM_GROUP_BACK_PLATE = {
  B: {
    name: '含背板',
    nextLevel: TARGET_SMALL_GROUP,
  },
  R: {
    name: '無背板',
    nextLevel: TARGET_SMALL_GROUP,
  },
};

const TARGET_MEDIUM_GROUP_CIRCULAR = {
  1: {
    name: '未分類',
    nextLevel: TARGET_SMALL_GROUP,
  },
  S: {
    name: '矽環',
    nextLevel: TARGET_SMALL_GROUP,
  },
  T: {
    name: '鉭線圈',
    nextLevel: TARGET_SMALL_GROUP,
  },
};

const TARGET_MEDIUM_GROUP_ANODE = {
  1: {
    name: '未分類',
    nextLevel: TARGET_SMALL_GROUP,
  },
};

export const TARGET_LARGE_GROUP = {
  F: {
    name: '平面靶',
    nextLevel: TARGET_MEDIUM_GROUP_BACK_PLATE,
  },
  R: {
    name: '旋轉靶',
    nextLevel: TARGET_MEDIUM_GROUP_BACK_PLATE,
  },
  O: {
    name: '環形靶',
    nextLevel: TARGET_MEDIUM_GROUP_CIRCULAR,
  },
  A: {
    name: '陽極',
    nextLevel: TARGET_MEDIUM_GROUP_ANODE,
  },
};
