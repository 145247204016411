import useSWR from 'swr';
import { API_NAMESPACE, request } from '../request';

const namespace = API_NAMESPACE.MATERIALS;

export async function updateCustomerSupplyReceipt(body: any) {
  const response = await request('/warehouses/customer-supply-orders/receipt', {
    method: 'put',
    body: JSON.stringify(body),
    responseParser: (res) => res,
    namespace,
  });

  return response;
}

export function useInventoryByCustomerSupplyOrder({
  orderId,
}: {
  orderId?: string | undefined;
}) {
  const { data, error } = useSWR(
    orderId
      ? [`/warehouses/customer-supply-orders/${orderId}`, { namespace }]
      : null
  );

  return {
    order: data ?? [],
    isLoading: !data && !error,
  };
}
