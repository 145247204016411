import {
  Message,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  Typography,
} from '@mezzanine-ui/react';
import styles from './../../../TargetNumber.module.scss';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  createAutoTargetNumberDispatch,
  useGetCustomerTargetNumberRuleForAutoDispatcher,
  useInternalTargetRuleChecker,
} from '@solar/data';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { disabledSideBarRoutes } from 'apps/frontend/src/app/use-side-bar-hidden';

interface TargetAutoDispatcherModalProps {
  open: boolean;
  onClose: () => void;
  onSuccessfulDispatch?: () => void;
  ruleDetails?: {
    customer_id: string;
    customer_name: string;
    material_id: string;
    order_id: string;
    order_detail_id: number;
    sales_order_line: string;
    quantity: number;
    usage_amount: number;
  };
}

interface AutoDispatcherCustomerRule {
  number_rule_id: number;
  number_rule_name: string;
}

export function TargetAutoDispatcherModal({
  open,
  onClose,
  ruleDetails,
  onSuccessfulDispatch,
}: TargetAutoDispatcherModalProps) {
  const [isTargetRuleEnabled, setIsTargetRuleEnabled] = useState<
    boolean | null
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const upperLimit =
    (ruleDetails?.quantity ?? 0) - (ruleDetails?.usage_amount ?? 0);

  useEffect(() => {
    if (ruleDetails?.order_detail_id) {
      const checkBody = {
        order_detail_id: ruleDetails?.order_detail_id,
      };

      useInternalTargetRuleChecker(checkBody)
        .then((response) => {
          // console.log('Internal target rule check response:', response);
          setIsTargetRuleEnabled(response.data.enabled);
        })
        .catch((error) => {
          console.error('Error checking internal target rule:', error);
        });
    }
  }, [ruleDetails?.order_detail_id]);

  const schemaAuto = yup
    .object({
      autoTargetNumberQuantity: yup
        .number()
        .nullable()
        .transform((value, originalValue) =>
          String(originalValue).trim() === '' ? null : value
        )
        .test('is-required', '必填', (value) => value !== null)
        .test(
          'is-number',
          'Must be a number',
          (value) => value !== null && value !== undefined && !isNaN(value)
        )
        .test(
          'is-within-upper-limit',
          `不能超過${upperLimit}組`,
          (value) =>
            value === null || (value !== undefined && value <= upperLimit)
        ),
    })
    .required();

  const methods = useForm({
    resolver: yupResolver(schemaAuto),
  });
  const {
    reset,
    watch,
    trigger,
    formState: { errors },
  } = methods;
  const quantity = watch('autoTargetNumberQuantity');
  const { customerTargetNumberRule } =
    useGetCustomerTargetNumberRuleForAutoDispatcher(
      ruleDetails?.customer_id,
      ruleDetails?.material_id
    );
  const enhancedOnClose = () => {
    methods.reset({
      autoTargetNumberQuantity: '',
      codeRule: { id: 0, name: '' },
    });
    onClose();
  };

  useEffect(() => {
    trigger('autoTargetNumberQuantity');
  }, [quantity, trigger]);

  useEffect(() => {
    if (open) {
      methods.reset({
        autoTargetNumberQuantity: '',
        codeRule: { id: 0, name: '' },
      });
    }
  }, [open, methods, ruleDetails]);

  const onSubmit = methods.handleSubmit((formData) => {
    setIsLoading(true);
    if (typeof ruleDetails?.order_detail_id !== 'number') {
      console.error('order_detail_id is undefined or not a number');
      return;
    }

    const customerNumberRuleId = Number(formData.codeRule?.id);
    const quantity = Number(formData.autoTargetNumberQuantity);

    if (customerNumberRuleId === undefined || customerNumberRuleId === 0) {
      const errorMessage = '請選擇適用的靶編套用規則';
      Message.error(errorMessage);
      return;
    }

    const payload = {
      order_detail_id: ruleDetails?.order_detail_id,
      customer_number_rule_id: customerNumberRuleId,
      quantity: quantity,
    };

    setIsLoading(true);

    createAutoTargetNumberDispatch(payload)
      .then(() => {
        Message.success('自動派發成功');
        onClose();
        reset();
        onSuccessfulDispatch?.();
      })
      .catch((error) => {
        console.error('Error dispatching target number:', error);
        try {
          const errorObj = JSON.parse(error.message);
          const errorDetail = errorObj.detail;
          if (errorDetail && typeof errorDetail === 'object') {
            const errorMessage = Object.keys(errorDetail)
              .map((key) => `${key}: ${errorDetail[key]}`)
              .join(', ');
            Message.error(errorMessage);
          } else {
            Message.error('自動派發失敗');
          }
        } catch (e) {
          console.error('Error parsing error message:', e);
          Message.error('自動派發失敗');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  });

  return (
    <Modal className={styles.modal} onClose={onClose} open={open} hideCloseIcon loading={isLoading}>
      <ModalHeader>自動取號</ModalHeader>
      <ModalBody>
        <Typography variant="h5">
          客戶名稱: {ruleDetails?.customer_name}
        </Typography>
        <Typography variant="h5">
          訂單: {ruleDetails?.order_id} - {ruleDetails?.sales_order_line}
        </Typography>
        <Typography variant="h5">料號: {ruleDetails?.material_id}</Typography>
        <Typography variant="h5">
          已取靶編數量: {ruleDetails?.usage_amount} 組 -- 剩餘可取數量:{' '}
          {upperLimit} 組
        </Typography>
        <br />
        <Typography variant="h4">內部靶編規則:</Typography>
        {isTargetRuleEnabled ? (
          <Typography variant="h5" color='success'>此料號有可套用內部靶編規則</Typography>
        ) : (
          <Typography variant="h5" color="error">
            此料號無可套用內部靶編規則
          </Typography>
        )}
        <br />
        <FormFieldsWrapper methods={methods} className={styles.creatingForm}>
        <Typography variant="h4">客戶靶編規則:</Typography>
          <div className={styles.formContent}>
            <Typography variant="h4">套用規則:</Typography>
            <SelectField
              registerName="codeRule"
              options={
                (customerTargetNumberRule || []).length > 0
                  ? customerTargetNumberRule.map(
                      (rule: AutoDispatcherCustomerRule) => ({
                        name: rule.number_rule_name,
                        id: rule.number_rule_id.toString(),
                      })
                    )
                  : [{}]
              }
              disabled={
                (customerTargetNumberRule || []).length === 1 ||
                (customerTargetNumberRule || []).length === 0
              }
              defaultValue={
                (customerTargetNumberRule || []).length === 1
                  ? {
                      id: customerTargetNumberRule[0].number_rule_id.toString(),
                      name: customerTargetNumberRule[0].number_rule_name,
                    }
                  : (customerTargetNumberRule || []).length === 0
                  ? { id: '0', name: '無可套用規則' }
                  : undefined
              }
            />
          </div>
          <div className={styles.formContent}>
            <Typography variant="h4">取號數量:</Typography>
            <InputField
              registerName="autoTargetNumberQuantity"
              width={100}
              placeholder=""
              type="number"
              disabled={
                customerTargetNumberRule?.length === 0 || upperLimit === 0
              }
            />
            <Typography variant="h5">
              (上限
              {(ruleDetails?.quantity ?? 0) - (ruleDetails?.usage_amount ?? 0)}
              組)
            </Typography>
          </div>
        </FormFieldsWrapper>
      </ModalBody>
      <ModalActions
        cancelText="取消"
        confirmText="產出"
        onCancel={enhancedOnClose}
        onConfirm={onSubmit}
        confirmButtonProps={{
          disabled:
            !watch('autoTargetNumberQuantity') ||
            !watch('codeRule') ||
            watch('codeRule').id === '' ||
            watch('codeRule').id === '0',
        }}
      />
    </Modal>
  );
}
