import { Key, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { ProductionFormResponse } from '@solar/data';
import { Space } from 'antd';
import { Loading, Tab, TabPane, Tabs } from '@mezzanine-ui/react';
import { groupBy, sortBy } from 'lodash';
import { FormField } from './FormField';

export interface ProductionFormProps {
  registerName: string;
  forms?: ProductionFormResponse[];
  loading?: boolean;
  renderTitle?: (form: ProductionFormResponse) => ReactNode;
  disableAllFields?: boolean;
  getFieldRegisterName?: (form: ProductionFormResponse) => string;
  onFormRender?: () => void;
}

export function ProductionForms({
  registerName,
  forms,
  loading,
  renderTitle,
  disableAllFields = false,
  getFieldRegisterName,
  onFormRender,
}: ProductionFormProps) {
  const [selectedCategory, setSelectedCategory] = useState<Key>();

  const categoryRef = useRef<Record<string, HTMLDivElement | null>>({});

  const categoryGroup = useMemo(() => {
    return Object.entries(groupBy(forms, 'category'));
  }, [forms]);

  useEffect(() => {
    onFormRender?.();
  }, [onFormRender]);

  useEffect(() => {
    if (categoryGroup.length > 0) {
      setSelectedCategory(categoryGroup[0][0]);
    }
  }, [categoryGroup]);

  return (
    <Loading stretch loading={loading}>
      <Space
        direction="vertical"
        size="large"
        style={{
          width: '100%',
          paddingBottom: '24px',
        }}
      >
        <Tabs activeKey={selectedCategory} onChange={setSelectedCategory}>
          {categoryGroup.map(([category]) => (
            <TabPane
              key={category}
              tab={
                <Tab>
                  <div
                    onClick={() => {
                      categoryRef.current[category]?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    }}
                  >
                    {category === 'null' ? '未分類' : category}
                  </div>
                </Tab>
              }
            />
          ))}
        </Tabs>
        <Space
          direction="vertical"
          style={{
            width: '100%',
            height: '500px',
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
        >
          {categoryGroup
            .filter(([category]) => category === selectedCategory)
            .map(([category, forms]) => (
              <div
                key={category}
                ref={(el) => {
                  categoryRef.current[category] = el;
                }}
              >
                <Space
                  direction="vertical"
                  size="large"
                  style={{ width: '100%' }}
                >
                  {sortBy(forms, 'sortIndex').map((form) => (
                    <FormField
                      form={form}
                      registerName={registerName}
                      disabled={disableAllFields}
                      renderTitle={renderTitle}
                      getFieldRegisterName={getFieldRegisterName}
                    />
                  ))}
                </Space>
              </div>
            ))}
        </Space>
      </Space>
    </Loading>
  );
}
