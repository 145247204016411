import { TableProps } from '@mezzanine-ui/react';
import { forwardRef } from 'react';

/**
 * @description:
 * TableForFormFieldArray 是為了解決 useFieldArray 搭配 Mzn Table 會出現的一個 bug
 *
 * ```
 * import { Table } from '@mezzanine-ui/react';
 * import { useForm, useFieldArray } from 'react-hooks-form';
 *
 * const methods = useForm<{ list: [] }>();
 * const { fields } = useFieldArray({
 *   control: methods.control,
 *   name: 'list',
 * });
 *
 * const columns = [
 *  {
 *    title: 'Name',
 *    dataIndex: 'name',
 *  },
 *  {
 *    title: 'Age',
 *    dataIndex: 'age',
 *    render: () => <InputField registerName="age" />
 *  },
 *  {
 *    title: 'Action',
 *    dataIndex: 'actions',
 *    render: () => (
 *      <Button type="button" variant="contained">Delete</Button>
 *    ),
 *  },
 * ]
 * <Table columns={columns} dataSource={fields} />
 * ```
 *
 * 這個 bug 是在 Mzn Table 中，在刪除時，會出現 fields 沒有正確刪除的情況。
 * 在 Mzn 中，這個問題在新版本已解決，但在此專案中，不適合更新到新版本。
 *
 * 這是一個簡易 table，沒有支援很多功能，非必要不要用這個 component
 *
 * 目前只支援
 *
 * - column.title
 * - column.render
 * - column.dataIndex
 * - column.width
 */

export const TableForFormFieldArray = forwardRef<
  HTMLTableElement,
  TableProps<any>
>(({ columns, dataSource }, ref) => {
  return (
    <table ref={ref} className="mzn-table">
      <thead className="mzn-table__header-fixed">
        <tr className="mzn-table__header">
          {columns?.map((column) => (
            <th
              className="mzn-table__header__cellWrapper"
              style={
                column?.width
                  ? {
                      width: column.width,
                      maxWidth: column.width,
                      flex: '1 1 auto',
                    }
                  : undefined
              }
            >
              <div className="mzn-table__cell">{column?.title ?? ''}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="mzn-table__body">
        {dataSource.map((data, index) => (
          <tr className="mzn-table__body__row">
            {columns.map((column) => (
              <td
                className="mzn-table__body__row__cellWrapper"
                style={
                  column?.width
                    ? {
                        width: column.width,
                        maxWidth: column.width,
                        flex: '1 1 auto',
                      }
                    : undefined
                }
              >
                <div className="mzn-table__cell">
                  <div className="mzn-table__cell__ellipsis">
                    {column?.render?.(data, index, column) ??
                      (typeof (data as Record<string, any>)?.[
                        column?.dataIndex ?? ''
                      ] === 'string' &&
                        (data as Record<string, string>)?.[
                          column?.dataIndex ?? ''
                        ]) ??
                      ''}
                  </div>
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
});
