import { MinusOutlined } from '@ant-design/icons';
import { PlusIcon } from '@mezzanine-ui/icons';
import { Icon, Typography, cx, Radio, RadioGroup } from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  DatePickerField,
  DateRangePickerField,
  HookFormFieldComponent,
  RadioGroupField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { TreeSelectDataType } from '@solar/data';
import { Input, TreeSelect, TreeSelectProps } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormReturn,
  UseFormSetValue,
} from 'react-hook-form';
import { AutoComplete as AntdAutoComplete } from 'antd';
import styles from './enhanceFilter.module.scss';
import Button from '@mezzanine-ui/react/Button/Button';

export function enhanceFilter(Component: HookFormFieldComponent<any>) {
  return function (label: string, props: any, registerName: string) {
    return (
      <div key={Math.random()} className={styles.wrapper}>
        {label !== '' && (
          <Typography variant="h6" color="secondary-light" noWrap={true}>
            {label}：
          </Typography>
        )}

        <Component registerName={registerName} {...props} />
      </div>
    );
  };
}

export type InputComponentType = {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  name: string;
  placeholder: string;
  disabled?: boolean;
  className?: Record<string, unknown>;
  width?: number;
};

const DEBOUNCE_TIME = 700;

const InputComponent = ({
  register,
  setValue,
  name,
  placeholder,
  disabled,
  className,
}: InputComponentType) => {
  const [value, setValueNoRender] = useState('');

  const debounceRef = useRef<any>();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueNoRender(e.target.value);

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      setValue(name, e.target.value);
    }, DEBOUNCE_TIME);
  };

  return (
    <input
      className={cx(styles.input, className)}
      {...register(name)}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

const AccountInputComponent = ({
  register,
  setValue,
  name,
  getValues,
}: InputComponentType & { getValues: UseFormGetValues<FieldValues> }) => {
  const [value, setValueNoRender] = useState('');

  const debounceRef = useRef<any>();

  const minusDisable = useMemo(() => {
    if (getValues(name) === '') return true;
    return false;
  }, [getValues(name)]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueNoRender(e.target.value);

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      setValue(name, e.target.value);
    }, DEBOUNCE_TIME);
  };

  const clickMinus = () => {
    const oldValue = getValues(name) === '' ? 0 : parseInt(getValues(name));
    const newValue = oldValue === 0 ? 0 : oldValue - 1;

    setValueNoRender(`${newValue}`);
    setValue(name, `${newValue}`);
  };

  const clickPlus = () => {
    const oldValue = getValues(name) === '' ? '0' : getValues(name);
    const newValue = parseInt(oldValue) + 1;

    setValueNoRender(`${newValue}`);
    setValue(name, `${newValue}`);
  };

  useEffect(() => {
    if (getValues(name) === '0') setValue(name, '');
  }, [getValues, name, setValue]);

  return (
    <div className={styles.accountInputWrapper}>
      <button
        className={styles.button}
        onClick={clickMinus}
        disabled={minusDisable}
      >
        <MinusOutlined style={{ color: minusDisable ? '' : '#0a72b4' }} />
      </button>
      <input
        {...register(name)}
        type="number"
        onChange={onChange}
        value={value}
        className={styles.accountInput}
        placeholder={'0'}
      />
      <button className={styles.button} onClick={clickPlus}>
        <Icon color="primary" icon={PlusIcon} />
      </button>
    </div>
  );
};

type AutoCompleteComponent = {
  getValues: UseFormGetValues<FieldValues>;
  options: any;
  width: number;
  placeholder: string;
  registerName: string;
  disabled?: boolean;
  handleOnInput?: (value: string, id: string) => void;
};

export const AutoCompleteComponent1 = ({
  options,
  registerName,
  placeholder,
  width,
  getValues,
  disabled,
  handleOnInput,
}: AutoCompleteComponent) => {
  return (
    <AutoCompleteField
      autoFocus
      id={registerName}
      options={options}
      width={width}
      registerName={registerName}
      placeholder={placeholder}
      value={getValues(registerName)}
      disabled={disabled}
      onSearch={(value) => {
        if (!handleOnInput) return;
        handleOnInput(value, registerName);
      }}
    />
  );
};

interface AutoCompleteWithUpdatingApiComponentProps {
  width: number;
  defaultValue?: string;
  placeholder: string;
  options: { id: string; name: string }[];
  name: string;
  form: UseFormReturn<FieldValues, any>;
  handleSearch: (value?: string) => void;
}

export const AutoCompleteWithUpdatingApiComponent = ({
  width,
  placeholder,
  options,
  name,
  handleSearch,
  form,
  defaultValue,
}: AutoCompleteWithUpdatingApiComponentProps) => {
  const [value, setValue] = useState(defaultValue);

  const displayOptions = options.map((option) => ({
    ...option,
    value: option.id,
    label: option.name,
  }));

  // useEffect(() => {
  //   handleSearch(defaultValue);
  //   form.setValue(name, defaultValue);
  // }, []);

  return (
    <AntdAutoComplete
      autoFocus={false}
      style={{ width }}
      placeholder={placeholder}
      options={displayOptions}
      value={value}
      onSelect={(e) => {
        form.setValue(name, e);
      }}
      onChange={(e) => setValue(e)}
    >
      <Input.Search
        onSearch={() => {
          if (!value) return;
          handleSearch(value);
        }}
      />
    </AntdAutoComplete>
  );
};

type FindParentsValueReturnObject = { value: string; title: string };

const findParentsValue = (
  nodes: TreeSelectDataType[],
  targetValue: string
): FindParentsValueReturnObject[] | undefined => {
  for (const node of nodes) {
    if (node.value === targetValue)
      return [{ value: node.value, title: node.title }];

    if (node.children) {
      const parentValues = findParentsValue(node.children, targetValue);
      if (parentValues)
        return [{ value: node.value, title: node.title }, ...parentValues];
    }
  }

  return undefined;
};

const joinTitle = (arr: FindParentsValueReturnObject[] | undefined): string => {
  if (!arr) return '';

  const titleArr = arr.map((item) => item.title);
  return titleArr.join('-');
};

const findNodeByValue = (
  nodes: TreeSelectDataType[],
  targetValue: string
): FindParentsValueReturnObject | undefined => {
  for (const node of nodes) {
    if (node.value === targetValue) return node;

    if (node.children) {
      const foundNode = findNodeByValue(node.children, targetValue);
      if (foundNode) return foundNode;
    }
  }
  return undefined;
};

type TreeSelectComponentType = {
  width: number;
  multiple?: boolean;
  treeData: TreeSelectDataType[];
  disabled?: boolean;
  registerName: string;
  methods: UseFormReturn<FieldValues, any>;
  placeholder: string;
  treeDataShouldNotDisabled?: boolean;
  onLoadData?: TreeSelectProps['loadData'];
  hasResourceActions?: boolean;
};

type TreeSelectDataDisableType = TreeSelectDataType & { disabled?: boolean };

function checkForDisableChildren(
  treeData: TreeSelectDataType[],
  index: number,
  treeValue: { value: string; disabled: boolean }[],
  arr: TreeSelectDataDisableType[]
): TreeSelectDataDisableType[] {
  const current = treeData?.[index];
  if (!current) return arr;

  if (current.children)
    checkForDisableChildren(current.children, 0, treeValue, arr);

  const currentValue = current.value;
  if (!currentValue.includes('role-') && !currentValue.includes('actions-')) {
    const filterChecked = treeValue.filter(
      (item) =>
        item.value.includes('role-') &&
        item.value.split('-')[1] === currentValue
    );
    const filterDisabled = arr.filter(
      (item) =>
        item.value.includes('role-') &&
        item.value.split('-')[1] === currentValue &&
        item.disabled
    );
    const disabled = filterChecked.length > 0 || filterDisabled.length > 0;
    arr.push({ ...current, disabled });
  }

  if (currentValue.includes('role-')) {
    const filter = treeValue.filter(
      (item) =>
        item.value.includes('actions') &&
        item.value.split('-')[1] === currentValue.split('-')[2]
    );
    const disabled = filter.length > 0;
    arr.push({ ...current, disabled });
  }

  return checkForDisableChildren(treeData, index + 1, treeValue, arr);
}

const unflattenTreeData = (data: TreeSelectDataDisableType[]) => {
  const rootArr = data.filter(
    (item) => !item.value.includes('role-') && !item.value.includes('actions-')
  );
  const roleArr = data.filter((item) => item.value.includes('role-'));
  const result = rootArr.map((root) => {
    const children = root.children;
    return children
      ? {
          ...root,
          children: children.map((child) => {
            const role = roleArr.filter((item) => item.value === child.value);
            return role.length > 0 ? role[0] : child;
          }),
        }
      : root;
  });
  return result;
};

export const TreeSelectComponent = ({
  width,
  multiple,
  treeData,
  disabled,
  registerName,
  methods,
  placeholder,
  treeDataShouldNotDisabled,
  onLoadData,
  hasResourceActions = false,
}: TreeSelectComponentType) => {
  const [treeValue, setTreeValue] = useState<
    { value: string; label: string; disabled: boolean }[]
  >(methods.getValues(registerName) ?? []);
  const expandKeys = useMemo(() => {
    const set = new Set(treeValue.map((value) => value.value.split('-')[1]));
    const arr: string[] = [];
    set.forEach((item) => arr.push(item));
    return arr;
  }, [treeValue]);

  const displayTreeData = useMemo(() => {
    if (treeDataShouldNotDisabled || treeValue.length <= 0) return treeData;
    return hasResourceActions
      ? unflattenTreeData(checkForDisableChildren(treeData, 0, treeValue, []))
      : treeData.map((data) =>
          treeValue.filter((item) => item.value?.split('-')[1] === data.value)
            .length === 0
            ? { ...data, disabled: data.disabled }
            : { ...data, disabled: true }
        );
  }, [
    treeDataShouldNotDisabled,
    hasResourceActions,
    treeValue,
    treeData,
    checkForDisableChildren,
    unflattenTreeData,
  ]);

  return (
    <div>
      <TreeSelect
        treeCheckStrictly={true}
        labelInValue={true}
        treeCheckable={true}
        tagRender={(e) => {
          const result = joinTitle(findParentsValue(treeData, e.value));
          return (
            <div
              className="ant-select-selection-overflow-item"
              style={{ opacity: 1 }}
            >
              <span className="ant-select-selection-item" title="SD">
                <span className="ant-select-selection-item-content">
                  {result}
                </span>
                <span
                  className="ant-select-selection-item-remove"
                  unselectable="on"
                  aria-hidden="true"
                  style={{ userSelect: 'none' }}
                >
                  <span
                    role="img"
                    aria-label="close"
                    className="anticon anticon-close"
                    onClick={e.onClose}
                  >
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="close"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                    </svg>
                  </span>
                </span>
              </span>
            </div>
          );
        }}
        showSearch
        allowClear
        multiple={multiple ?? true}
        showArrow
        placeholder={placeholder}
        bordered={false}
        className={styles.treeSelect}
        style={{ width }}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        treeDefaultExpandedKeys={expandKeys}
        treeData={displayTreeData ?? []}
        value={treeValue}
        showCheckedStrategy={
          treeDataShouldNotDisabled ? 'SHOW_ALL' : 'SHOW_CHILD'
        }
        disabled={disabled ?? false}
        loadData={onLoadData}
        onChange={(e) => {
          setTreeValue(e);
          methods.setValue(registerName, e);
        }}
      />
    </div>
  );
};

interface RadioGroupComponentProps {
  name: string;
  options: Array<{ value: string; label: string }>;
  methods: UseFormReturn<FieldValues, any>;
  customOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RadioGroupComponent = ({
  name,
  options,
  methods,
  customOnChange,
}: RadioGroupComponentProps) => {
  const { setValue, getValues } = methods;

  return (
    <div>
      <RadioGroup
        options={options}
        value={getValues(name)}
        onChange={(e) =>
          customOnChange ? customOnChange(e) : setValue(name, e.target.value)
        }
      ></RadioGroup>
    </div>
  );
};

type InfoComponentProps = {
  text: string;
};

export const InfoComponent = ({ text }: InfoComponentProps) => {
  return <Typography>{text}</Typography>;
};

export const MultipleSelect = enhanceFilter(SelectField);
export const DateRangeSelect = enhanceFilter(DatePickerField);
export const CalendarRanger = enhanceFilter(DateRangePickerField);
export const SearchInput = enhanceFilter(InputComponent);
export const AccountInput = enhanceFilter(AccountInputComponent);
export const AutoComplete = enhanceFilter(AutoCompleteComponent1);
export const AutoCompleteUpdateOptions = enhanceFilter(
  AutoCompleteWithUpdatingApiComponent
);
export const RadioSelect = enhanceFilter(RadioGroupComponent);
export const TreeSelectField = enhanceFilter(TreeSelectComponent);
export const InfoField = enhanceFilter(InfoComponent);
export const Br = () => <div style={{ width: '100%' }}></div>;
