import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';
import { API_NAMESPACE } from '@solar/data';
import useSWR from 'swr';

export function InventoryCountDocField() {
  const { data, error, isValidating } = useSWR<{
    takings: { id: string; name: string }[];
  }>([
    '/warehouses/takings',
    {
      namespace: API_NAMESPACE.MATERIALS,
    },
  ]);

  return (
    <AutoCompleteField
      label="盤點文件"
      required
      disabled={!data && !error && isValidating}
      registerName="inventoryTakingId"
      options={data?.takings ?? []}
    />
  );
}
