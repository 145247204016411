import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { useState } from 'react';
import {
  AnalysisRequirementsResponse,
  GetSampleAnalysisRequirementsParams,
  GetSampleSpecificationsParams,
  GetSampleSubmissionReasonsParams,
  GetSampleTypesParams,
  SampleSpecResponse,
  SampleStatus,
  SubmissionReason,
} from './sample.type';

const namespace = API_NAMESPACE.PP;

export function useGetSampleAnalysisRequirements(
  defaultParams?: GetSampleAnalysisRequirementsParams
) {
  const [params, setParams] = useState<
    GetSampleAnalysisRequirementsParams | undefined
  >(defaultParams);
  const { data, error, mutate, isValidating } =
    useSWR<AnalysisRequirementsResponse>(
      params?.factory
        ? [
            '/pp/lims/sample/analysis-requirements',
            {
              namespace,
              params,
            },
          ]
        : null
    );

  return {
    sampleAnalysisRequirements: data?.records ?? [],
    pageInfo: data?.pageInfo,
    isLoading: !error && !data && !isValidating,
    refetchGetSampleAnalysisRequirements: setParams,
    mutateGetSampleAnalysisRequirements: mutate,
  };
}

export function useGetSampleSubmissionReasons(
  defaultParams?: GetSampleSubmissionReasonsParams
) {
  const [params, setParams] = useState<
    GetSampleSubmissionReasonsParams | undefined
  >(defaultParams);

  const { data, error, mutate, isValidating } = useSWR<SubmissionReason>(
    params?.factory
      ? [
          '/pp/lims/sample/drop-down-lists/submission-reasons',
          {
            namespace,
            params,
          },
        ]
      : null
  );

  return {
    sampleSubmissionReasons: data?.items,
    isLoading: !error && !data && !isValidating,
    refetchGetSampleSubmissionReasons: setParams,
    mutateGetSampleSubmissionReasons: mutate,
  };
}

export function useGetSampleStatus(defaultParams?: GetSampleTypesParams) {
  const [params, setParams] = useState<GetSampleTypesParams | undefined>(
    defaultParams
  );
  const { data, error, mutate, isValidating } = useSWR<SampleStatus>(
    params?.factory
      ? [
          '/pp/lims/sample/drop-down-lists/sample-types',
          {
            namespace,
            params,
          },
        ]
      : null
  );

  return {
    sampleStatus: data?.items ?? [],
    isLoading: !error && !data && !isValidating,
    refetchGetSampleStatus: setParams,
    mutateGetSampleStatus: mutate,
  };
}

export function useGetSampleSpecifications(
  defaultParams?: GetSampleSpecificationsParams
) {
  const [params, setParams] = useState<
    GetSampleSpecificationsParams | undefined
  >(defaultParams);
  const { data, error, mutate, isValidating } = useSWR<SampleSpecResponse>(
    params?.factory
      ? [
          '/pp/lims/sample/drop-down-lists/specifications',
          {
            namespace,
            params,
          },
        ]
      : null
  );

  return {
    sampleSpecifications: data?.records ?? [],
    pageInfo: data?.pageInfo,
    isLoading: !error && !data && !isValidating,
    refetchGetSampleSpecifications: setParams,
    mutateGetSampleSpecifications: mutate,
  };
}
