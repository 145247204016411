import type { ProductionPlanningOrdersExportFormValues } from '@solar/data';
import { PPOrdersExportFormFieldName as FormFieldName } from '@solar/data';
import { useFormContext } from 'react-hook-form';
import { RadioGroupField } from '@mezzanine-ui/react-hook-form';

export function PlannedDeliveryRadioGroup() {
  const formMethods =
    useFormContext<ProductionPlanningOrdersExportFormValues>();

  const resetPlannedDeliveryDate = () => {
    formMethods.resetField(FormFieldName.PLANNED_DELIVERY_DATE_START);
    formMethods.resetField(FormFieldName.PLANNED_DELIVERY_DATE_END);
  };

  return (
    <>
      <div>生管回覆交期：</div>
      <div>
        <RadioGroupField
          control={formMethods.control}
          registerName={FormFieldName.PLANNED_DELIVERY_CONFIRM}
          options={[
            {
              label: '不限',
              value: '',
            },
            {
              label: '已回覆交期',
              value: 'Y',
            },
            {
              label: '未回覆交期',
              value: 'N',
            },
          ]}
          onChange={(e) => {
            if (e.target.value !== 'Y') {
              resetPlannedDeliveryDate();
            }
          }}
        />
      </div>
    </>
  );
}
