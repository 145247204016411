import { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { fetchUrl } from '.';
import { ResponseStatus } from './useHumanResourceBuManage';

const BU_TEAM = 'units/settings';

const fetcher = async (url: string, queryParams: string = '') => {
  const res = await fetch(`${url}${queryParams}`);
  const json = await res.json();

  return json ?? null;
};

export type mutationPayload = {
  unitId?: string | number;
  oldUnitId?: string | number;
  current_page?: string | number;
  per_page?: string | number;
  code?: string;
};

type BusinessUnit = {
  id: number;
  name: string;
  code: string;
  businessUnitName: string | null;
};

export function useHumanResourceBUTeam() {
  const [data, setData] = useState<any>(undefined);
  const [loading, setLoading] = useState(true);
  const [queryParams, setQueryParams] = useState('&page=1&perPage=10');

  const url = fetchUrl(BU_TEAM);

  const { data: swrData } = useSWR([url, queryParams], fetcher);

  const headers = {
    Accept: '*/*',
    'Content-Type': 'application/json',
  };

  const filterMutation = async (payload: mutationPayload) => {
    if (
      !payload.unitId &&
      !payload.oldUnitId &&
      !payload.current_page &&
      !payload.code &&
      !payload.per_page
    )
      return;
    const unitIdUrl = payload.unitId ? `businessUnitId=${payload.unitId}&` : '';
    const oldUnitIdUrl = payload.oldUnitId
      ? `unitName=${payload.oldUnitId}&`
      : '';
    const code = payload.code ? `code=${payload.code}&` : '';
    const page = payload.current_page ? `page=${payload.current_page}&` : '';
    const per_page = payload.per_page
      ? `perPage=${payload.per_page}`
      : 'perPage=10';

    setQueryParams(`&${unitIdUrl}${oldUnitIdUrl}${code}${page}${per_page}`);
  };

  const updateMutation = async (payload: mutationPayload) => {
    const options = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        businessUnitId: payload.unitId,
        unitId: payload.oldUnitId,
      }),
    };
    const res = await fetch(url, options);
    if (res.status === 200) {
      mutate([url, queryParams]);
      return ResponseStatus.SUCCESS;
    } else {
      return ResponseStatus.FAIL;
    }
  };

  const isBusinessUnit = (object: unknown): object is BusinessUnit => {
    if (object && typeof object === 'object') return 'id' in object;
    return false;
  };

  useEffect(() => {
    if (!swrData || typeof swrData === 'string') {
      setLoading(true);
      return;
    }
    setData(swrData);
    setLoading(false);
  }, [swrData]);

  return {
    loading,
    data,
    filterMutation,
    updateMutation,
    isBusinessUnit,
  };
}
