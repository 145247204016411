import { TableColumn } from '@mezzanine-ui/core/table';
import { Button } from '@mezzanine-ui/react';
import {
  AutoCompleteMultiField,
  DateRangePickerField,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  getNextParams,
  NextPagination,
  OutsourcePurchaseMaterialRequestOrder,
  OutsourcePurchaseShippedState,
  OUTSOURCE_PURCHASE_STATES_TRANSLATION,
  useMaterialRequestOrders,
  useOutsourcePurchaseMaterialRequestOrders,
  useSuppliers,
  WorkOrderStatus,
} from '@solar/data';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { CopyTextWrapper, MznPaginationTable } from '../../Templates';
import { shippedStates } from './shipped-states';
import styles from './work-order-table.module.scss';

type OutsourcingOrderTableFilter = {
  rangeOfDate?: [string, string];
  status?: { id: WorkOrderStatus; name: string }[];
  materialRequestOrderId?: string;
  workOrderId?: string;
};

export function OutsourcingOrderTable() {
  const navigate = useNavigate();
  const methods = useForm<OutsourcingOrderTableFilter>();
  // const { orders, mutateParams, pageInfo, isLoading } =
  //   useOutsourcePurchaseMaterialRequestOrders({
  //     initiateFetching: false,
  //   });
  const { orders, mutateParams, pageInfo, isLoading } =
    useOutsourcePurchaseMaterialRequestOrders({
      initiateFetching: false,
    });
  const { records: suppliers, mutateParams: mutateSuppliersParams } =
    useSuppliers();

  const refetchOutSourcingWorkOrders = useCallback(
    (nextPagination: NextPagination) => {
      const formState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);

      mutateParams({
        dateFrom: formState?.rangeOfDate?.[0],
        dateTo: formState?.rangeOfDate?.[1],
        states: formState?.status?.map((obj) => obj.id),
        materialRequestOrderIds: formState?.materialRequestOrderId
          ? [formState?.materialRequestOrderId]
          : undefined,
        workOrderIds: formState?.workOrderId
          ? [formState?.workOrderId]
          : undefined,
        offset,
        limit,
      });
    },
    [methods, pageInfo, mutateParams]
  );

  const columns: TableColumn<OutsourcePurchaseMaterialRequestOrder>[] = [
    {
      title: '操作',
      render: (source) => (
        <Button
          onClick={() =>
            navigate(
              `/warehouse/material-supply/outsourcing-work-order/${source?.id}/?outsourcePurchaseOrderId=${source?.outsourcePurchaseOrderId}`
            )
          }
        >
          檢視
        </Button>
      ),
    },
    {
      title: '狀態',
      dataIndex: 'states',
    },
    {
      title: '採購單號',
      render: (source) => (
        <CopyTextWrapper text={source?.outsourcePurchaseOrderId ?? ''} />
      ),
    },
    {
      title: '發料單號',
      render: (source) => <CopyTextWrapper text={source?.id ?? ''} />,
    },
    {
      title: '預期領料日',
      dataIndex: 'expectedCompletedAt',
    },
    {
      title: '供應商名稱',
      dataIndex: 'creatorId',
    },
    {
      title: '供應商代碼',
      dataIndex: 'users',
    },
    {
      title: '採購群組',
      dataIndex: 'groupId',
    },
    {
      title: '狀態',
      render: (source) => {
        return OUTSOURCE_PURCHASE_STATES_TRANSLATION[
          source['shippedState'] as OutsourcePurchaseShippedState
        ];
      },
    },
  ];

  useEffect(() => {
    // page data init
    refetchOutSourcingWorkOrders({ nextPage: 1 });
  }, []);

  return (
    <div className={styles.work_orders_wrapper}>
      <FormFieldsWrapper
        methods={methods}
        className={styles.filter_form_wrapper}
      >
        <InputField
          placeholder="請輸入發料單號"
          registerName="materialRequestOrderId"
        />
        <InputField placeholder="請輸入採購單號" registerName="workOrderId" />
        <AutoCompleteMultiField
          label="供應商名稱"
          registerName="suppliers"
          options={suppliers}
          onSearch={(input) => {
            mutateSuppliersParams({ searchTerms: input });
          }}
        />
        <SelectField
          mode="multiple"
          clearable
          label="狀態"
          registerName="status"
          options={shippedStates}
        />
        <DateRangePickerField label="日期區間" registerName="rangeOfDate" />
        <div>
          <Button
            type="button"
            variant="outlined"
            onClick={() => refetchOutSourcingWorkOrders({ nextPage: 1 })}
          >
            確認
          </Button>
        </div>
      </FormFieldsWrapper>
      <MznPaginationTable
        pageInfo={pageInfo}
        loading={isLoading}
        fetchData={refetchOutSourcingWorkOrders}
        dataSource={orders ?? []}
        columns={columns}
      />
    </div>
  );
}
