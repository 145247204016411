import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { environment } from '../environments/environment';
import { API_NAMESPACE } from '../request';
import { FilterDataType } from '../salesAndDistributions';

const namespace = API_NAMESPACE.HR;

type DataType = {
  id: string | number;
  name?: string;
  customer_id?: string;
};

type OrdersFiltersDataType = Record<
  | 'materials'
  | 'customers'
  | 'orders'
  | 'orderStatus'
  | 'orderTypes'
  | 'bu',
  DataType[]
>;

export const opConvert = (
  list: FilterDataType[]
): FilterDataType[] =>
  list.map((item) => ({
    id: item.id,
    name: `${item.name} (${item.id})`,
  }));

export function useOrdersFilters() {
  const filters = [
    'materials',
    'customers',
    'orders',
    'orderStatus',
    'orderTypes',
    'bu',
  ] as Array<keyof OrdersFiltersDataType>;
  const initData = filters.reduce((acc: any, cur) => {
    acc[cur] = [];
    return acc;
  }, {}) as OrdersFiltersDataType;

  const [data, setData] = useState<OrdersFiltersDataType>(initData);
  const [loading, setLoading] = useState(true);

  const { data: swrData } = useSWR([
    `/orders/filter?apiKey=${environment.API_KEY}`,
    { namespace },
  ]);

  useEffect(() => {
    if (!swrData || typeof swrData === 'string') {
      setLoading(true);
      return;
    }
    const customers = swrData?.customers as FilterDataType[];
    const convertCustomers = opConvert(customers);
    const orderTypes = swrData?.orderTypes as FilterDataType[];
    const convertOrderTypes = opConvert(orderTypes);

    swrData['customers'] = convertCustomers;
    swrData['orderTypes'] = convertOrderTypes;

    setData(swrData);
    setLoading(false);
  }, [swrData]);

  return {
    loading,
    data,
  };
}
