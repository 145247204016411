import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { useMemo, useState } from 'react';
import { request } from '../request';
import { TableDataSource } from '@mezzanine-ui/core/table';
import { SelectValue } from '@mezzanine-ui/react';

const namespace = API_NAMESPACE.MISC;

export interface IComponentItem {
  id: number;
  componentCode: string;
  componentItemNumber: string;
  componentItemSeq: string;
  wtRate: number;
  atRate: number;
}

export interface ISearchIngredientResponse {
  id: number | string;
  componentCode: string;
  componentItemNumber: string;
  items: IComponentItem[];
}

interface IIngredient {
  unit: string | undefined;
  componentItemNumber: string | undefined;
  value: string | undefined;
}

interface IIngredientPageInfo {
  current: number;
  pageSize: number;
  total: number;
}

export function useIngredientSearch() {
  const { data: componentNames, error: componentNameError } = useSWR([
    '/selectItem/listComponentName',
    { namespace },
  ]);

  const { data: componentCodes, error: componentCodeError } = useSWR([
    '/selectItem/listComponentCode',
    { namespace },
  ]);

  const [requestItems, setRequestItems] = useState<IIngredient[]>([]);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [serachResult, setSerachResult] = useState<TableDataSource[]>([]);
  const [searchErrorMessage, setSearchErrorMessage] = useState<string>('');
  const [ingredientCode, setIngredientCode] = useState<string>('');
  const [pageInfo, setPageInfo] = useState<IIngredientPageInfo>({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const doSearch = async (
    items: IIngredient[],
    pagination: IIngredientPageInfo,
    componentCode: string
  ) => {
    setSearchLoading(true);
    setSearchErrorMessage('');
    try {
      const requestBody = {
        items,
        pagination,
        componentCode,
      };
      const response = await request('/component/search ', {
        method: 'post',
        body: JSON.stringify(requestBody),
        responseParser: (res) => {
          //console.log(res);
          return res.json();
        },
        namespace,
      });
      if (Array.isArray(response.datas)) {
        console.log(response);
        // transform response id number to string prevent react-beautiful-dnd warning
        const result = response.datas.map((item: ISearchIngredientResponse) => {
          item.id = item.id.toString();
          return item;
        });
        // console.log(result);
        setSerachResult(result);
        setPageInfo(response.pagination);
      }
    } catch (e) {
      if (e instanceof Error) {
        if (e.message.indexOf('{') === 0) {
          const error = JSON.parse(e.message);
          setSearchErrorMessage(error.message);
        } else {
          setSearchErrorMessage('搜尋錯誤');
        }
      }
    }
    setSearchLoading(false);
  };

  async function searchIngredient(
    items: IIngredient[],
    pagination: IIngredientPageInfo,
    componentCode: string
  ) {
    setRequestItems(items);
    setIngredientCode(componentCode);
    doSearch(items, pagination, componentCode);
  }

  const onPageChange = (page: number) => {
    const pagination = {
      ...pageInfo,
      current: page,
    };
    doSearch(requestItems, pagination, ingredientCode);
  };

  const autoCompleteOptionsComponentNames = useMemo(() => {
    let options: SelectValue<string>[] = [];
    if (Array.isArray(componentNames)) {
      options = componentNames.map((componentName: string) => ({
        id: componentName,
        name: componentName,
      }));
    }
    return options;
  }, [componentNames]);

  const autoCompleteOptionsComponentCodes = useMemo(() => {
    let options: SelectValue<string>[] = [];
    if (Array.isArray(componentCodes)) {
      options = componentCodes.map((componentCode: string) => ({
        id: componentCode,
        name: componentCode,
      }));
    }
    return options;
  }, [componentCodes]);

  return {
    componentNames,
    searchLoading,
    searchIngredient,
    serachResult,
    searchErrorMessage,
    autoCompleteOptionsComponentNames,
    autoCompleteOptionsComponentCodes,
    setPageInfo,
    pageInfo,
    onPageChange,
  };
}
