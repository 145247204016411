import { Button, ButtonGroup } from '@mezzanine-ui/react';
import { checkJWTRoles } from '@solar/data';

interface SourceType {
  type?: TypeKey;
  status?: StatusKey;
}

type StatusKey = 'unused' | 'used' | 'disabled';
type TypeKey = 'internal' | 'customer';

export function useTargetRuleTableColumns(
  openDeleteModal: (id: string) => void,
  navigate: (path: string) => void
) {
  const statusTranslations: Record<StatusKey, string> = {
    unused: '未使用',
    used: '已使用',
    disabled: '停用',
  };

  const typeTranslations: Record<TypeKey, string> = {
    internal: '內部靶編',
    customer: '客戶靶編',
  };

  return [
    {
      title: '規則名稱',
      dataIndex: 'number_rule_name',
      width: 450,
    },
    {
      title: '類型',
      render: (source: SourceType) => {
        const typeKey = source.type ?? '';
        return typeKey ? typeTranslations[typeKey] : '';
      },
      width: 150,
    },
    {
      title: '狀況',
      render: (source: SourceType) => {
        const statusKey = source.status ?? '';
        return statusKey ? statusTranslations[statusKey] : '';
      },
      width: 100,
    },
    {
      title: '客戶名（出貨）',
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: 450,
    },
    {
      title: '客戶編號',
      dataIndex: 'customers[0].customer_id',
      width: 150,
    },
    {
      title: '操作',
      render: (record: { number_rule_id: string; status?: StatusKey }) => {
        switch (record.status) {
          case 'unused':
            return (
              checkJWTRoles([78]) && (
              <ButtonGroup variant="contained">
                {/* <Button
                  variant="outlined"
                  onClick={() =>
                    navigate(
                      `/target-number/number-rules/view-only/${record.number_rule_id}`
                    )
                  }
                >
                  詳細資料
                </Button> */}
                <Button
                  danger
                  onClick={() => openDeleteModal(record.number_rule_id)}
                >
                  停用
                </Button>
                <Button
                  onClick={() =>
                    navigate(
                      `/target-number/number-rules/edit/${record.number_rule_id}`
                    )
                  }
                >
                  編輯
                </Button>
              </ButtonGroup>
                )
            );
          case 'used':
            return (
              checkJWTRoles([78]) && (
              <ButtonGroup variant="contained">
                {/* <Button
                  variant="outlined"
                  onClick={() =>
                    navigate(
                      `/target-number/number-rules/view-only/${record.number_rule_id}`
                    )
                  }
                >
                  詳細資料
                </Button> */}

                <Button
                  danger
                  onClick={() => openDeleteModal(record.number_rule_id)}
                >
                  停用
                </Button>
                <Button
                  onClick={() =>
                    navigate(
                      `/target-number/number-rules/edit/${record.number_rule_id}`
                    )
                  }
                >
                  檢視&編輯
                </Button>
              </ButtonGroup>
              )
            );
          case 'disabled':
            return null;
          default:
            return null;
        }
      },
    },
  ];
}
