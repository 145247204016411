import {
  NextPagination,
  getNextParams,
  useGetSampleAnalysisRequirements,
} from '@solar/data';
import { MznPaginationTable } from '../../../Templates/PaginationTable/MznPaginationTable';
import { useCallback } from 'react';

export function AnalysisRequirementsTable({
  factory,
  ids,
}: {
  factory: string;
  ids: string[];
}) {
  const {
    sampleAnalysisRequirements,
    refetchGetSampleAnalysisRequirements,
    pageInfo,
  } = useGetSampleAnalysisRequirements({
    factory,
    ids,
  });

  const refetchGetSampleAnalysisRequirementsWithPagination = useCallback(
    (nextPagination: NextPagination) => {
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      refetchGetSampleAnalysisRequirements({
        offset,
        limit,
        factory,
        ids,
      });
    },
    [pageInfo, refetchGetSampleAnalysisRequirements, factory, ids]
  );

  return (
    <MznPaginationTable
      pageInfo={pageInfo}
      dataSource={sampleAnalysisRequirements ?? []}
      fetchData={refetchGetSampleAnalysisRequirementsWithPagination}
      columns={[
        {
          title: '需求類別',
          dataIndex: 'analysisRequirementType',
        },
        {
          title: '分析需求',
          dataIndex: 'analysisRequirement',
        },
        {
          title: '作業時間',
          dataIndex: 'processTime',
        },
        {
          title: '急件作業時間',
          dataIndex: 'rushProcessTime',
        },
        {
          title: '費用 (急件*1.33)',
          dataIndex: 'price',
        },
      ]}
    />
  );
}
