import type { ProductionPlanningOrdersExportFormValues } from '@solar/data';
import {
  PPOrdersExportFormFieldName as FormFieldName,
  usePPOrdersFilterConditionGet,
} from '@solar/data';
import { useFormContext } from 'react-hook-form';
import { AutoCompleteMultiField } from '@mezzanine-ui/react-hook-form';

export function BUIdAutoComplete() {
  const _NoUseFieldName = '_' + FormFieldName.BU;
  const formMethods =
    useFormContext<ProductionPlanningOrdersExportFormValues>();
  const { buList } = usePPOrdersFilterConditionGet();

  return (
    <>
      <div>BU：</div>
      <div>
        <AutoCompleteMultiField
          debounce={false}
          registerName={_NoUseFieldName}
          options={buList}
          onChange={(selected) => {
            // update react-hook-form by oneself because the format from @mezzanine is not quite suitable.
            formMethods.setValue(
              FormFieldName.BU,
              selected?.map((s) => s.name).join(',')
            );
          }}
        />
      </div>
    </>
  );
}
