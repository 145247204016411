import { Button, Message, SelectValue } from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  GetMaterialResolvesParams,
  MaterialResolvesFilter,
  ParseMaterialIdParams,
  resolveMaterialId,
  useGetComponentCodes,
  useMaterialClassCodes,
  useMaterialFeatureCodes,
  useMaterialGroups,
  useMaterialMachiningCodes,
  useMaterialSamplingCodes,
  useMaterialSourceCodes,
  useMaterialWasteCodes,
} from '@solar/data';
import { Col, Row } from 'antd';
import { uniqBy } from 'lodash';
import { useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

export const defaultValues = {
  resolveId: null,
  groupCode: null,
  bigCode: null,
  mediumCode: null,
  smallCode: null,
  sourceCode: null,
  featureCodeOne: null,
  featureCodeTwo: null,
  machiningCode: null,
  samplingCode: null,
  wasteCode: null,
  componentCode: null,
  drawingCode: null,
  sizeCodeOne: null,
  sizeCodeTwo: null,
  sizeCodeThree: null,
  packagingCode: null,
};

export function MaterialPropsFilter({
  methods,
  refetchGetMaterialResolves,
}: {
  methods: UseFormReturn<MaterialResolvesFilter>;
  refetchGetMaterialResolves: (params: GetMaterialResolvesParams) => void;
}) {
  const [isResolving, setIsResolving] = useState(false);
  const { materialGroups } = useMaterialGroups();
  const {
    materialClassCodes: materialClassCodesForBigClassCode,
    refetchMaterialClassCodes: refetchMaterialClassCodesForBigClassCode,
  } = useMaterialClassCodes();

  const {
    materialClassCodes: materialClassCodesForMediumClassCode,
    refetchMaterialClassCodes: refetchMaterialClassCodesForMediumClassCode,
  } = useMaterialClassCodes();

  const {
    materialClassCodes: materialClassCodesForSmallClassCode,
    refetchMaterialClassCodes: refetchMaterialClassCodesForSmallClassCode,
  } = useMaterialClassCodes();

  const { materialSourceCodes } = useMaterialSourceCodes();
  const { componentCodes, refetchGetComponentCodes } = useGetComponentCodes();
  const { materialFeatureCodes, refetchMaterialFeatureCodes } =
    useMaterialFeatureCodes();
  const { materialMachiningCodes } = useMaterialMachiningCodes();
  const { materialSamplingCodes } = useMaterialSamplingCodes();
  const { materialWasteCodes } = useMaterialWasteCodes();

  const groupOptions = materialGroups?.map((group) => ({
    id: group?.id,
    name: `${group?.code}(${group?.name})`,
  }));

  const onGroupCodeChange = (value: SelectValue) => {
    methods.setValue('bigCode', null);
    methods.setValue('mediumCode', null);
    methods.setValue('smallCode', null);
    refetchMaterialClassCodesForBigClassCode({ groupCode: value?.id });
    refetchMaterialFeatureCodes({ groupCode: value?.id });
  };

  const bigClassCodeOptions = uniqBy(
    materialClassCodesForBigClassCode,
    (code) => code?.bigCode
  )
    ?.filter((classCode) => classCode?.bigCode)
    ?.map((classCode) => ({
      id: classCode?.bigCode,
      name: `${classCode?.bigCode}(${classCode?.bigCodeName})`,
    }));

  const onBigClassCodeChange = (value: SelectValue) => {
    const formState = methods.getValues();
    methods.setValue('mediumCode', null);
    methods.setValue('smallCode', null);
    refetchMaterialClassCodesForMediumClassCode({
      groupCode: formState?.groupCode?.id,
      bigClassCode: value?.id,
    });
  };

  const midClassCodeOptions = uniqBy(
    materialClassCodesForMediumClassCode,
    (code) => code?.mediumCode
  )
    ?.filter((classCode) => classCode?.mediumCode)
    ?.map((classCode) => ({
      id: classCode?.mediumCode,
      name: `${classCode?.mediumCode}(${classCode?.mediumCodeName})`,
    }));

  const disabledMidClassCodeSelect = midClassCodeOptions?.length === 0;

  const onMidClassCodeChange = (value: SelectValue) => {
    const formState = methods.getValues();
    methods.setValue('smallCode', null);
    refetchMaterialClassCodesForSmallClassCode({
      groupCode: formState?.groupCode?.id,
      bigClassCode: formState?.bigCode?.id,
      mediumClassCode: value?.id,
    });
  };

  const smallClassCodeOptions = uniqBy(
    materialClassCodesForSmallClassCode,
    (code) => code?.smallCode
  )
    ?.filter((classCode) => classCode?.smallCode)
    ?.map((classCode) => ({
      id: classCode?.smallCode,
      name: `${classCode?.smallCode}(${classCode?.smallCodeName})`,
    }));

  const disabledSmallClassCodeSelect = smallClassCodeOptions?.length === 0;

  const sourceCodeOptions = materialSourceCodes?.map((sourceCode) => ({
    id: sourceCode?.id,
    name: `${sourceCode?.id}(${sourceCode?.name})`,
  }));

  const componentCodeOptions = componentCodes?.map((componentCode) => ({
    id: componentCode,
    name: componentCode,
  }));

  const featureCodeOptions = materialFeatureCodes?.map((featureCode) => ({
    id: featureCode?.code,
    name: `${featureCode?.code}(${featureCode?.name})`,
  }));

  const machiningCodeOptions = materialMachiningCodes?.map((machiningCode) => ({
    id: machiningCode?.code,
    name: `${machiningCode?.code}(${machiningCode?.name})`,
  }));

  const samplingCodeOptions = materialSamplingCodes?.map((samplingCode) => ({
    id: samplingCode?.id,
    name: `${samplingCode?.id}(${samplingCode?.name})`,
  }));

  const wasteCodeOptions = materialWasteCodes?.map((wasteCode) => ({
    id: wasteCode?.id,
    name: `${wasteCode?.id}(${wasteCode?.name})`,
  }));

  const onSearch = useCallback(() => {
    const formState = methods.getValues();
    refetchGetMaterialResolves({
      groupCode: formState?.groupCode?.id ?? '',
      bigClassCode: formState?.bigCode?.id ?? '',
      mediumClassCode: formState?.mediumCode?.id ?? '',
      smallClassCode: formState?.smallCode?.id ?? '',
      materialSourceCode: formState?.sourceCode?.id ?? '',
      componentCode: formState?.componentCode?.id ?? '',
      featureCodeOne: formState?.featureCodeOne?.id ?? '',
      featureCodeTwo: formState?.featureCodeTwo?.id ?? '',
      machiningCode: formState?.machiningCode?.id ?? '',
      samplingCode: formState?.samplingCode?.id ?? '',
      wasteCode: formState?.wasteCode?.id ?? '',
      drawingCode: formState?.drawingCode ?? '',
      sizeCodeOne: formState?.sizeCodeOne ?? '',
      sizeCodeTwo: formState?.sizeCodeTwo ?? '',
      sizeCodeThree: formState?.sizeCodeThree ?? '',
      packagingCode: formState?.packagingCode ?? '',
      idPrefix: '',
    });
  }, [methods, refetchGetMaterialResolves]);

  const onResolve = useCallback(async () => {
    try {
      setIsResolving(true);
      const formState = methods.getValues();

      const response = await resolveMaterialId({
        id: formState?.resolveId ?? '',
      });

      if (!response || response?.id === '') {
        Message.error('查無此料號資料');
        methods.reset({ ...defaultValues, resolveId: formState?.resolveId });
        return;
      }

      Object.entries(response)?.forEach(([key, value]) => {
        if (!value || value === '-') return;
        else {
          switch (key) {
            case 'group': {
              const v = value as ParseMaterialIdParams['group'];
              if (v) {
                methods.setValue('groupCode', {
                  id: v?.id,
                  name: `${v?.code}(${v?.name})`,
                });
              }
              break;
            }
            case 'class': {
              const v = value as ParseMaterialIdParams['class'];
              if (v && v?.bigCode) {
                methods.setValue('bigCode', {
                  id: v?.bigCode,
                  name: `${v?.bigCode}(${v?.bigCodeName})`,
                });
              }
              if (v && v?.mediumCode) {
                methods.setValue('mediumCode', {
                  id: v?.mediumCode,
                  name: `${v?.mediumCode}(${v?.mediumCodeName})`,
                });
              }
              if (v && v?.smallCode) {
                methods.setValue('smallCode', {
                  id: v?.smallCode,
                  name: `${v?.smallCode}(${v?.smallCodeName})`,
                });
              }
              break;
            }
            // materialSourceCode
            case 'source': {
              const v = value as ParseMaterialIdParams['source'];
              if (v) {
                methods.setValue('sourceCode', {
                  id: v?.id,
                  name: `${v?.id}(${v?.name})`,
                });
              }
              break;
            }
            case 'componentCode':
              if (value !== null || value !== '')
                methods.setValue('componentCode', {
                  id: value as string,
                  name: value as string,
                });
              break;
            case 'featureOne': {
              const v = value as ParseMaterialIdParams['featureOne'];
              if (v) {
                methods.setValue('featureCodeOne', {
                  id: v?.code,
                  name: `${v?.code}(${v?.name})`,
                });
              }
              break;
            }
            case 'featureTwo': {
              const v = value as ParseMaterialIdParams['featureTwo'];
              if (v) {
                methods.setValue('featureCodeTwo', {
                  id: v?.code,
                  name: `${v?.code}(${v?.name})`,
                });
              }
              break;
            }
            case 'machining': {
              const v = value as ParseMaterialIdParams['machining'];
              if (v) {
                methods.setValue('machiningCode', {
                  id: v?.code,
                  name: `${v?.code}(${v?.name})`,
                });
              }
              break;
            }
            case 'sampling': {
              const v = value as ParseMaterialIdParams['sampling'];
              if (v) {
                methods.setValue('samplingCode', {
                  id: v?.id,
                  name: `${v?.id}(${v?.name})`,
                });
              }
              break;
            }
            case 'waste': {
              const v = value as ParseMaterialIdParams['waste'];
              if (v) {
                methods.setValue('wasteCode', {
                  id: v?.id,
                  name: `${v?.id}(${v?.name})`,
                });
              }
              break;
            }
            case 'drawingCode':
              methods.setValue(
                'drawingCode',
                value as ParseMaterialIdParams['drawingCode']
              );
              break;
            case 'sizeCodeOne':
              methods.setValue(
                'sizeCodeOne',
                value as ParseMaterialIdParams['sizeCodeOne']
              );
              break;
            case 'sizeCodeTwo':
              methods.setValue(
                'sizeCodeTwo',
                value as ParseMaterialIdParams['sizeCodeTwo']
              );
              break;
            case 'sizeCodeThree':
              methods.setValue(
                'sizeCodeThree',
                value as ParseMaterialIdParams['sizeCodeThree']
              );
              break;
            case 'packagingCode':
              methods.setValue(
                'packagingCode',
                value as ParseMaterialIdParams['packagineCode']
              );
              break;
            default:
              break;
          }
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsResolving(false);
    }
  }, [methods]);

  return (
    <FormFieldsWrapper methods={methods} style={{ margin: '12px 0' }}>
      <Row gutter={[12, 12]} align="bottom">
        <Col span={24}>
          <InputField width={600} label="新料號" registerName="resolveId" />
          <Button
            type="button"
            loading={isResolving}
            variant="outlined"
            style={{ position: 'absolute', bottom: 0, marginLeft: 12 }}
            onClick={onResolve}
          >
            料號解析
          </Button>
        </Col>
        <Col>
          <SelectField
            key={methods.watch('groupCode')?.id}
            label="物料群組"
            registerName="groupCode"
            options={groupOptions ?? []}
            onChange={onGroupCodeChange}
          />
        </Col>
        <Col>
          <SelectField
            key={methods.watch('bigCode')?.id}
            label="產品階層(大)"
            registerName="bigCode"
            options={bigClassCodeOptions ?? []}
            onChange={onBigClassCodeChange}
          />
        </Col>
        <Col>
          <SelectField
            key={methods.watch('mediumCode')?.id}
            label="產品階層(中)"
            registerName="mediumCode"
            disabled={disabledMidClassCodeSelect}
            options={midClassCodeOptions ?? []}
            onChange={onMidClassCodeChange}
          />
        </Col>
        <Col>
          <SelectField
            key={methods.watch('smallCode')?.id}
            label="產品階層(小)"
            registerName="smallCode"
            disabled={disabledSmallClassCodeSelect}
            options={smallClassCodeOptions ?? []}
          />
        </Col>
        <Col>
          <SelectField
            key={methods.watch('sourceCode')?.id}
            label="身份碼"
            registerName="sourceCode"
            options={sourceCodeOptions ?? []}
          />
        </Col>
        <Col>
          <AutoCompleteField
            label="成份代碼"
            registerName="componentCode"
            options={componentCodeOptions ?? []}
            onSearch={(searchTerm) => refetchGetComponentCodes({ searchTerm })}
          />
        </Col>
        <Col>
          <SelectField
            key={methods.watch('featureCodeOne')?.id}
            label="特徵碼1"
            registerName="featureCodeOne"
            options={featureCodeOptions ?? []}
          />
        </Col>
        <Col>
          <SelectField
            key={methods.watch('featureCodeTwo')?.id}
            label="特徵碼2"
            registerName="featureCodeTwo"
            options={featureCodeOptions ?? []}
          />
        </Col>
        <Col>
          <InputField label="圖號" registerName="drawingCode" />
        </Col>
        <Col>
          <InputField label="尺寸一碼" registerName="sizeCodeOne" />
        </Col>
        <Col>
          <InputField label="尺寸二碼" registerName="sizeCodeTwo" />
        </Col>
        <Col>
          <InputField label="尺寸三碼" registerName="sizeCodeThree" />
        </Col>
        <Col>
          <SelectField
            key={methods.watch('machiningCode')?.id}
            label="加工碼"
            registerName="machiningCode"
            options={machiningCodeOptions ?? []}
          />
        </Col>
        <Col>
          <SelectField
            key={methods.watch('samplingCode')?.id}
            label="取樣碼"
            registerName="samplingCode"
            options={samplingCodeOptions ?? []}
          />
        </Col>
        <Col>
          <InputField label="包裝碼" registerName="packagingCode" />
        </Col>
        <Col>
          <SelectField
            key={methods.watch('wasteCode')?.id}
            label="環保碼"
            registerName="wasteCode"
            options={wasteCodeOptions ?? []}
          />
        </Col>
        <Col>
          <Button type="button" variant="contained" onClick={onSearch}>
            查詢
          </Button>
        </Col>
        <Col>
          <Button
            danger
            type="button"
            variant="outlined"
            onClick={() => methods.reset(defaultValues)}
          >
            清空條件
          </Button>
        </Col>
      </Row>
    </FormFieldsWrapper>
  );
}
