import { Drawing, useDrawings, useDrawingsQueryDDL, usePresignedFileLink } from "@solar/data";
import { Button, Form, Input, InputNumber, Modal, Table } from "antd";
import styles from './drawing.module.scss';
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PDFPreviewer } from "@solar/templates";
import moment from "moment";

type DrawingFilterType = {
  drawingType?: string[];
  ['drawingCompositeCode.code']?: string[];
  ['drawingShapeCode.code']?: string[];
  sizeCodeOne?: string[];
  sizeCodeTwo?: string[];
  sizeCodeThree?: string[];
  sandblastingCode?: string[];
  ['drawingDrillLayeringCode.code']?: string[];
  ['drawingSlottingCode.code']?: string[];
  completedStatusCode?: string[];
};

export function DrawingPage() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<Drawing>();
  const [params, setParams] = useState<object>({ page: 1, size: 10 });
  const { drawings, pageInfo, isLoading } = useDrawings({ params });
  const {
    typeCodes,
    compositeCodes,
    shapeCodes,
    sizeCodes,
    drillLayeringCodes,
    slottingCodes,
    completedStatusCodes,
    sandblastingCodes,
  } = useDrawingsQueryDDL();
  const { fileLink } = usePresignedFileLink({});
  const [filteredInfo, setFilteredInfo] = useState<DrawingFilterType>({});

  const refetchDrawings = (pagination: { page?: number, size?: number }, filters?: DrawingFilterType) => {
    const formState = form.getFieldsValue();
    setFilteredInfo(filters ?? {});
    setParams((prevParams) => ({
      ...prevParams,
      ...formState,
      ...pagination,
      typeCodes: filters?.drawingType,
      compositeCodes: filters?.['drawingCompositeCode.code'],
      shapeCodes: filters?.['drawingShapeCode.code'],
      sizeCodeOnes: filters?.sizeCodeOne,
      sizeCodeTwos: filters?.sizeCodeTwo,
      sizeCodeThrees: filters?.sizeCodeThree,
      sandblastingCodes: filters?.sandblastingCode,
      drillLayeringCodes: filters?.['drawingDrillLayeringCode.code'],
      slottingCodes: filters?.['drawingSlottingCode.code'],
      completedStatusCodes: filters?.completedStatusCode, 
    }));
  };

  const onClose = () => {
    setOpen(false);
    setData(undefined);
  };

  return (
    <div className={styles.page_content}>
      <Form
        form={form}
        style={{ rowGap: 8, columnGap: 16 }}
        layout="inline">
        <Form.Item name="oldDrawingFixed" label="修正後舊圖號">
          <Input />
        </Form.Item>
        <Form.Item name="newDrawingCode" label="新圖號">
          <Input />
        </Form.Item>
        <Form.Item name="oldMaterialID" label="舊料號">
          <Input />
        </Form.Item>
        <Form.Item name="newMaterialID" label="新料號">
          <Input />
        </Form.Item>
        <Form.Item name="sizeLength" label="尺寸一碼">
          <InputNumber
            min={0}
            onChange={(value) => {
              form.setFieldValue('sizeLength', value ? (value as number).toFixed(3) : null);
            }} />
        </Form.Item>
        <Button
          onClick={() => {
            refetchDrawings({
              page: 1,
              size: pageInfo.size,
            });
          }}>
          查詢
        </Button>
        <Button disabled type="primary" onClick={() => navigate('/drawings/create')}>新增圖號</Button>
      </Form>
      <Table
        bordered
        size="small"
        key="id"
        pagination={{
          current: pageInfo.page,
          pageSize: pageInfo.size,
          hideOnSinglePage: true,
          total: pageInfo.count,
        }}
        rowKey="newDrawingCode"
        loading={isLoading}
        dataSource={drawings}
        columns={[
          {
            title: '藍圖',
            align: 'center',
            width: 80,
            render: (text, record: Drawing) => (
              <Button
                type="link"
                onClick={() => {
                  setOpen(true);
                  setData(record);
                }}>
                預覽
              </Button>
            ),
          },
          {
            dataIndex: 'oldDrawingFixed',
            title: '修正後舊圖號',
          },
          {
            dataIndex: 'newDrawingCode',
            title: '新圖號',
            render: (code, record) => record?.newDrawingCode
              ? <Link to={`/drawings/${code}`}>{code}</Link>
              : (
                <Link
                  to={`/drawings/${code}?${new URLSearchParams({ oldDrawingFixed: record?.oldDrawingFixed }).toString()}`}
                >
                  新增圖號
                </Link>
              )
          },
          {
            dataIndex: 'completedStatusCode',
            title: '完成別',
            filters: completedStatusCodes?.options,
            filteredValue: filteredInfo?.completedStatusCode ?? null,
            render: (code) => completedStatusCodes?.options?.find((option) => option?.value === code)?.text ?? '',
          },
          {
            dataIndex: ['drawingCompositeCode', 'code'],
            title: '組合碼',
            align: 'center',
            width: 150,
            filters: compositeCodes?.options,
            filteredValue: filteredInfo?.['drawingCompositeCode.code'] ?? null,
            render: (code) => compositeCodes?.options?.find((option) => option?.value === code)?.text ?? '',
          },
          {
            dataIndex: ['drawingShapeCode', 'code'],
            title: '形狀碼',
            align: 'center',
            width: 150,
            filters: shapeCodes?.options,
            filteredValue: filteredInfo?.['drawingShapeCode.code'] ?? null,
            render: (code) => shapeCodes?.options?.find((option) => option?.value === code)?.text ?? '',
          },
          {
            dataIndex: 'sizeCodeOne',
            title: '尺寸一碼',
            align: 'center',
            width: 150,
            filters: sizeCodes?.optionsForSizeLength,
            filteredValue: filteredInfo?.sizeCodeOne ?? null,
            render: (code, record) => record?.sizeLength,
          },
          {
            dataIndex: 'sizeCodeTwo',
            title: '尺寸二碼',
            align: 'center',
            width: 150,
            filters: sizeCodes?.optionsForSizeLength,
            filteredValue: filteredInfo?.sizeCodeTwo ?? null,
            render: (code, record) => record?.sizeWidth,
          },
          {
            dataIndex: 'sizeCodeThree',
            title: '尺寸三碼',
            align: 'center',
            width: 150,
            filters: sizeCodes?.optionsForSizeLength,
            filteredValue: filteredInfo?.sizeCodeThree ?? null,
            render: (code, record) => record?.sizeHeight,
          },
          {
            dataIndex: 'sandblastingCode',
            title: '噴砂碼',
            align: 'center',
            width: 150,
            filters: sandblastingCodes?.options,
            filteredValue: filteredInfo?.sandblastingCode ?? null,
            render: (code) => sandblastingCodes?.options?.find((option) => option?.value === code)?.text ?? '',
          },
          {
            dataIndex: ['drawingDrillLayeringCode', 'code'],
            title: '鑽孔分階',
            align: 'center',
            width: 150,
            filters: drillLayeringCodes?.options,
            filteredValue: filteredInfo?.['drawingDrillLayeringCode.code'] ?? null,
            render: (code) => drillLayeringCodes?.options?.find((option) => option?.value === code)?.text ?? '',
          },
          {
            dataIndex: ['drawingSlottingCode', 'code'],
            title: '開槽碼',
            align: 'center',
            width: 150,
            filters: slottingCodes?.options,
            filteredValue: filteredInfo?.['drawingSlottingCode.code'] ?? null,
            render: (code) => slottingCodes?.options?.find((option) => option?.value === code)?.text ?? '',
          },
          {
            dataIndex: 'serialString',
            title: '流水三碼',
            align: 'center',
            width: 150,
          },
          {
            dataIndex: 'drawingType',
            title: '類型',
            align: 'center',
            width: 150,
            filters: typeCodes?.options,
            filteredValue: filteredInfo?.drawingType ?? null,
            render: (item) => item?.name,
          },
          {
            dataIndex: 'lastScheduleShipDate',
            title: '最後出貨日期',
            render: (date) => date ? moment(date).format('YYYY-MM-DD') : '',
          }
        ]}
        onChange={(pagination, filters) => {
          refetchDrawings({
            page: pagination.current,
            size: pagination.pageSize,
          }, filters);
        }}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              size="small"
              pagination={{ hideOnSinglePage: true }}
              dataSource={record?.materialDrawings}
              columns={[
                {
                  dataIndex: 'scheduleShipDate',
                  title: '出貨日期',
                  render: (date) => date ? moment(date).format('YYYY-MM-DD') : '',
                },
                {
                  dataIndex: 'newMaterialID',
                  title: '新料號',
                  width: 300,
                },
                {
                  dataIndex: 'oldMaterialID',
                  title: '舊料號',
                  width: 100,
                },
                {
                  dataIndex: 'desc',
                  title: '描述',
                },
              ]} />
          ),
          rowExpandable: (record) => record?.materialDrawings?.length > 0,
        }} />
        <Modal width={1000} open={open} footer={false} onCancel={onClose}>
          <PDFPreviewer fileLink={fileLink} />
        </Modal>
    </div>
  )
}