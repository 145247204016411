import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { UseDataSourceHook } from '../common-table-filter-param';
import { OffsetBased, PageInfo, PaginatedResponse } from '../pagination';
import { API_NAMESPACE, request } from '../request';

const STATES = {
  WAITING: '待發料',
  PARTIAL: '部分已發',
  COMPLETE: '已發料',
} as const;

const STATES_SELECTIONS = Object.entries(STATES).map(([key, value]) => ({
  id: key,
  name: value,
}));

const SORT_TYPE = {
  CREATED_AT_DESC: '工單建立日期近 -> 遠',
  CREATED_AT_ASC: '工單建立日期遠 -> 近',
  EXPECTED_COMPLETED_AT_DESC: '訂單交期近 -> 遠',
  EXPECTED_COMPLETED_AT_ASC: '訂單交期遠 -> 近',
};

export {
  STATES as MATERIAL_REQUEST_ORDERS_STATES,
  STATES_SELECTIONS as MATERIAL_REQUEST_ORDERS_STATES_SELECTIONS,
};

export type MaterialRequestOrderRequestParams = {
  materialRequestOrderIds: string[];
  states: Array<keyof typeof STATES>;
  searchTerm: string;
  sourceTypes: `${MaterialRequestOrderSourceType}`[];
  expectedCompletedAtDateFrom: string;
  expectedCompletedAtDateTo: string;
  sortType: keyof typeof SORT_TYPE;
  workOrderIds: string[];
  typeCodes: Array<string>;
} & OffsetBased;

export type MaterialRequestOrder = {
  id: string;
  workOrderId: string;
  creatorId: string;
  sourceType: `${MaterialRequestOrderSourceType}`;
  expectedCompletedAt: string;
  recipeId: string;
  remark: string;
  productionExpectedFinishAt: string;
  actualCompletedAt: string | null;
  barcode: string | null;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
};

export enum MaterialRequestOrderSourceType {
  REQUEST_ORDER = 'REQUEST_ORDER',
  INGREDIENTS_ORDER = 'INGREDIENTS_ORDER',
}

export const SOURCE_TYPE_TRANSLATION = {
  [MaterialRequestOrderSourceType.REQUEST_ORDER]: '發料',
  [MaterialRequestOrderSourceType.INGREDIENTS_ORDER]: '配料',
};

const namespace = API_NAMESPACE.MATERIALS;

export function useMaterialRequestOrders({
  initiateFetching = false,
  defaultParams,
}: Partial<UseDataSourceHook> & {
  defaultParams?: Partial<MaterialRequestOrderRequestParams>;
}) {
  const [params, setParams] = useState<
    Partial<MaterialRequestOrderRequestParams> | undefined
  >(defaultParams);
  const { data, error, mutate } = useSWR<
    PaginatedResponse<MaterialRequestOrder>
  >(
    initiateFetching || params
      ? ['/warehouses/material-request-orders', { params, namespace }]
      : null
  );

  const pageInfo = useMemo(() => {
    const page =
      (data?.pageInfo?.offset ?? 0) / (data?.pageInfo?.limit ?? 20) + 1;
    const pageSize = data?.pageInfo?.limit ?? 20;
    return { ...(data?.pageInfo ?? {}), page, pageSize };
  }, [data]);

  return {
    orders: data?.records || [],
    pageInfo,
    isLoading: !error && !data,
    mutateParams: setParams,
    mutate,
  };
}

export function revokeMaterialRequestOrder(orderId: string) {
  return request(`/warehouses/material-request-orders/${orderId}/revoke`, {
    method: 'put',
    namespace: API_NAMESPACE.MATERIALS,
    responseParser: (res) => res,
  });
}
