import { useForm } from 'react-hook-form';
import { useTargetModal } from '../../Templates/Modal/ModalGroup';
import {
  FormFieldsDebug,
  FormFieldsWrapper,
  InputField,
} from '@mezzanine-ui/react-hook-form';
import { RowSection } from '@solar/templates';
import { Modal, ModalActions, ModalBody } from '@mezzanine-ui/react';
import { useCallback } from 'react';

export function ReturnOrderModal() {
  const modalController = useTargetModal('RETURN_ORDER');
  const { open, closeModal } = modalController;
  const methods = useForm<{ quantity: number | undefined }>({
    defaultValues: { quantity: 0 },
  });

  const onCloseModal = useCallback(() => {
    closeModal();
    methods.reset();
  }, []);
  return (
    <Modal open={open} onClose={onCloseModal}>
      <br />
      <br />
      <ModalBody>
        <FormFieldsWrapper methods={methods}>
          <RowSection label="退貨數量">
            <InputField registerName="quantity" valueAsNumber />
          </RowSection>
        </FormFieldsWrapper>
      </ModalBody>
      <ModalActions
        cancelText="取消"
        confirmText="確認"
        onCancel={() => {
          onCloseModal();
        }}
        onConfirm={() => {
          onCloseModal();
        }}
      />
    </Modal>
  );
}
