import { Message } from '@mezzanine-ui/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

export function useLogout() {
  const navigate = useNavigate();

  return useCallback(() => {
    Message.success('已登出系統');

    localStorage.removeItem('accessToken');

    navigate('/auth/login', { replace: true });
  }, [navigate]);
}
