import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetchUrl } from './endpoint';

export enum ResponseStatus {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

const BUSINESS_UNITS = 'businessUnits';

const fetcher = async (url: string) => {
  const res = await fetch(url);
  const json = await res.json();

  return json ?? null;
};

export function useHumanResourceBUManage() {
  const [data, setData] = useState<any>(undefined);
  const [loading, setLoading] = useState(true);

  const url = fetchUrl(BUSINESS_UNITS);

  const { mutate, data: swrData } = useSWR(url, fetcher);

  const headers = {
    Accept: '*/*',
    'Content-Type': 'application/json',
  };

  const createMutation = async (payload: string) => {
    const options = {
      method: 'POST',
      headers,
      body: JSON.stringify({ name: payload }),
    };
    const res = await fetch(url, options);
    if (res.status === 200) {
      mutate(url);
      return ResponseStatus.SUCCESS;
    } else {
      return ResponseStatus.FAIL;
    }
  };

  const updateMutation = async (id: string, payload: string) => {
    const options = {
      method: 'PUT',
      headers,
      body: JSON.stringify({
        name: payload,
      }),
    };
    const res = await fetch(fetchUrl(`${BUSINESS_UNITS}/${id}`), options);
    if (res.status === 200) {
      mutate(url);
      return ResponseStatus.SUCCESS;
    } else {
      return ResponseStatus.FAIL;
    }
  };

  const deleteMutation = async (id: string) => {
    const options = {
      method: 'DELETE',
      headers,
    };
    const res = await fetch(fetchUrl(`${BUSINESS_UNITS}/${id}`), options);
    if (res.status === 200) {
      mutate(url);
      return ResponseStatus.SUCCESS;
    } else {
      return ResponseStatus.FAIL;
    }
  };

  useEffect(() => {
    if (!swrData || typeof swrData === 'string') {
      setLoading(true);
      return;
    }
    setData(swrData);
    setLoading(false);
  }, [swrData]);

  return {
    loading,
    data,
    createMutation,
    updateMutation,
    deleteMutation,
  };
}
