import { request, API_NAMESPACE } from '../../request';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const CustomerTradeConditionExportFormFieldName = {
  MATERIAL_ID: 'materialId',
  CUSTOMER_ID: 'customerId',
  MATERIAL_CATEGORY_ID: 'materialCategoryId',
} as const;

const _FormFieldName = CustomerTradeConditionExportFormFieldName;

export const customerTradeConditionExportFormSchema = yup.object().shape({
  [_FormFieldName.MATERIAL_ID]: yup.string().optional(),
  [_FormFieldName.CUSTOMER_ID]: yup.string().optional(),
  [_FormFieldName.MATERIAL_CATEGORY_ID]: yup.string().optional(),
});

export type CustomerTradeConditionExportFormValues = yup.InferType<
  typeof customerTradeConditionExportFormSchema
>;

export function useCustomerTradeConditionExport() {
  const formMethods = useForm<CustomerTradeConditionExportFormValues>({
    resolver: yupResolver(customerTradeConditionExportFormSchema),
  });

  return {
    formMethods,
    createFormSubmit,
    FormFieldName: _FormFieldName,
    downloadFromFilePath,
  };
}

async function postCustomerTradeConditionExport({
  materialId,
  customerId,
  materialCategoryId,
}: CustomerTradeConditionExportFormValues) {
  try {
    const payload = {
      [_FormFieldName.MATERIAL_ID]: materialId,
      [_FormFieldName.CUSTOMER_ID]: customerId,
      [_FormFieldName.MATERIAL_CATEGORY_ID]: materialCategoryId,
    };

    for (const key in payload) {
      const _key = key as keyof CustomerTradeConditionExportFormValues;
      if (typeof payload[_key] === 'undefined') delete payload[_key];
    }

    // TODO: update API URL
    return request('/customers/customer-trade-condition/export', {
      namespace: API_NAMESPACE.HR,
      method: 'POST',
      body: JSON.stringify(payload),
    });
  } catch (error) {
    return null;
  }
}

type FormSubmitCreatorProps = {
  successCallback?: (payload: { filePath: string }) => void;
  errorCallback?: (error: Error) => void;
};

function createFormSubmit({
  successCallback,
  errorCallback,
}: FormSubmitCreatorProps) {
  const onSubmit = async (data: CustomerTradeConditionExportFormValues) => {
    postCustomerTradeConditionExport(data)
      .then((payload) => {
        if (payload?.filePath) {
          successCallback?.(payload);
        } else {
          throw new Error();
        }
      })
      .catch((error) => {
        errorCallback?.(error);
      });
  };

  return onSubmit;
}

function downloadFromFilePath(filePath: string) {
  if (filePath) {
    const aTag = document.createElement('a');
    aTag.style.display = 'none';
    aTag.href = filePath;
    aTag.download = filePath.split('/').pop() ?? '';
    document.body.append(aTag);
    aTag.click();
    document.body.removeChild(aTag);
  }
}
