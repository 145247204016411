import { Button, Input, Message } from '@mezzanine-ui/react';
import { InputField } from '@mezzanine-ui/react-hook-form';
import {
  getInventoryByMaterial,
  InventoryByMaterialSearchType,
  InventoryItem,
  useGetDeliveryOrderDetail,
} from '@solar/data';
import { InputContainerWithUnit } from '@solar/templates';
import { Col, Row, Space, Table } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';

export interface PalletContentMethodsFormValues {
  deliveryOrderList: Partial<{
    totalGrossWeight: string;
    totalNumberOfBoxes: string;
    volumeHeight: string;
    volumeLength: string;
    volumeWidth: string;
    materialItems: (InventoryItem & {
      deliveryOrderDetailId: number;
      quantityUnit: string;
    })[];
  }>[];
}

type PalletContentTableProps = {
  methods: UseFormReturn<PalletContentMethodsFormValues>;
  palletIndex: number;
};

function PalletByOrderSpec({
  item,
  appendMaterialToPallet,
}: {
  item: any;
  appendMaterialToPallet: (payload: any) => void;
}) {
  const [materialBarcode, setMaterialBarcode] = useState<string>('');

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Table
        style={{ border: 'none' }}
        size="small"
        rowKey="materialId"
        pagination={false}
        dataSource={[item]}
        columns={[
          {
            width: 120,
            dataIndex: 'productionDeliveryOrderLine',
            title: '項次',
          },
          {
            dataIndex: 'materialId',
            title: '料號',
          },
          {
            dataIndex: 'materialDescription',
            title: '品名',
          },
          {
            dataIndex: 'quantity',
            title: '數量',
          },
          {
            dataIndex: 'quantityUnit',
            title: '單位',
          },
        ]}
      />
      <Space style={{ width: '100%', padding: '0px 15px 12px' }}>
        <Input
          fullWidth
          placeholder="請刷物料條碼"
          value={materialBarcode}
          onChange={(e) => setMaterialBarcode(e.target.value)}
          inputProps={{
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                appendMaterialToPallet({
                  materialBarcode,
                  deliveryOrderDetailId: item.productionDeliveryOrderSpecId,
                });
                setMaterialBarcode('');
              }
            },
          }}
        />
        <Button
          onClick={() => {
            appendMaterialToPallet({
              materialBarcode,
              deliveryOrderDetailId: item.productionDeliveryOrderSpecId,
            });
            setMaterialBarcode('');
          }}
        >
          加入棧板
        </Button>
      </Space>
    </Space>
  );
}

export function PalletContentTable({
  methods,
  palletIndex,
}: PalletContentTableProps) {
  const params = useParams<{ id: string }>();
  const { deliveryOrder } = useGetDeliveryOrderDetail(params?.id);
  const materialItemsUnderDeliveryOrder = useMemo(
    () => deliveryOrder?.specs.flatMap((row) => row.materialItems) ?? [],
    [deliveryOrder?.specs]
  );

  const palletContentMethods = useFieldArray({
    control: methods?.control,
    name: `deliveryOrderList.${palletIndex}.materialItems`,
  });

  const appendMaterialToPallet = useCallback(
    async ({
      materialBarcode,
      deliveryOrderDetailId,
    }: {
      materialBarcode: string;
      deliveryOrderDetailId: number;
    }) => {
      try {
        if (!materialBarcode) {
          Message.error('請輸入條碼');
          return;
        }

        const isExist = palletContentMethods.fields.find((row) => {
          return row?.materialBarcode === materialBarcode;
        });

        if (isExist) {
          Message.error('此條碼已經存在棧板內');
          return;
        }

        const response = await getInventoryByMaterial({
          type: InventoryByMaterialSearchType.MATERIAL_BARCODE,
          materialBarcodes: [materialBarcode],
        });

        if (!response) {
          Message.error('查無此條碼資料');
          return;
        }
        const targetMaterial = response?.records?.[0];

        const materialIdMatchTarget = materialItemsUnderDeliveryOrder?.find(
          (row) => row?.materialId === targetMaterial?.materialId
        );

        if (targetMaterial && materialIdMatchTarget) {
          palletContentMethods.append({
            ...targetMaterial,
            deliveryOrderDetailId,
            quantityUnit: materialIdMatchTarget?.quantityUnit,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [materialItemsUnderDeliveryOrder, palletContentMethods]
  );

  return (
    <Table
      size="small"
      pagination={false}
      rowKey="recordId"
      dataSource={palletContentMethods.fields}
      caption={
        <Space direction="vertical" style={{ width: '100%' }}>
          {deliveryOrder?.specs
            ?.flatMap((spec) => spec?.materialItems)
            ?.map((item) => (
              <PalletByOrderSpec
                item={item}
                appendMaterialToPallet={appendMaterialToPallet}
                key={item?.orderId}
              />
            ))}
        </Space>
      }
      columns={[
        {
          dataIndex: 'materialId',
          title: '料號',
        },
        {
          dataIndex: 'batchId',
          title: '批號',
        },
        {
          dataIndex: 'mainMaterialUnitQuantity',
          title: '數量',
          render: (value, record, index) => {
            const inputUnit = (record as any)?.materialInputUnit;
            const stockUnit = (record as any)?.materialStockUnit;
            return (
              <div
                style={{
                  display: 'grid',
                  gap: '4px',
                  gridTemplateColumns: '1fr 0.5fr',
                }}
              >
                <InputContainerWithUnit
                  suffixUnit={methods.watch(
                    `deliveryOrderList.${palletIndex}.materialItems.${index}.quantityUnit`
                  )}
                >
                  <InputField
                    registerName={`deliveryOrderList.${palletIndex}.materialItems.${index}.mainMaterialUnitQuantity`}
                  />
                </InputContainerWithUnit>

                {/* <SelectField
                  registerName={`deliveryOrderList.${palletIndex}.materialItems.${index}.unit`}
                  options={[
                    {
                      id: 'materialInputUnit',
                      name: `管理單位（${inputUnit})`,
                    },
                    {
                      id: 'materialStockUnit',
                      name: `基礎計量單位（${stockUnit})`,
                    },
                  ]}
                /> */}
              </div>
            );
          },
        },
        {
          dataIndex: 'actions',
          title: '操作',
          render: (value, record, index) => (
            <Button danger onClick={() => palletContentMethods.remove(index)}>
              刪除
            </Button>
          ),
        },
      ]}
    />
  );
}
