import { Typography } from '@mezzanine-ui/react';
import { MznPaginationTable, PageLayout } from '@solar/templates';
import { useTableColumn } from './hooks/entry-page/useTableColumn';

export function Inventory() {
  const columns = useTableColumn();

  return (
    <PageLayout>
      <Typography variant="h1">盤點</Typography>
      <MznPaginationTable
        columns={columns}
        dataSource={[{ id: 'test' }]}
        fetchData={() => ''} // 待補
        hideDefaultExpandColumn
        pageInfo={{ totalCount: 1 }}
      />
    </PageLayout>
  );
}
