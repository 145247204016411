import {
  DatePickerField,
  FormFieldsWrapper,
  InputField,
  SelectField,
  TextAreaField,
} from '@mezzanine-ui/react-hook-form';
import { useForm } from 'react-hook-form';
import { MznPaginationTable } from '../../Templates/PaginationTable/MznPaginationTable';
import { Row, Space } from 'antd';
import { Button } from '@mezzanine-ui/react';

export function OutsourcingOrderTabPaneCheckOut() {
  const methods = useForm();

  return (
    <FormFieldsWrapper methods={methods}>
      <Space direction="vertical" size="small" style={{ width: '100%' }}>
        <InputField registerName="workOrderId" label="工單單號" />
        <InputField registerName="requestOrderId" label="發料單號" />
        <DatePickerField registerName="checkOutTime" label="出站時間" />
        <SelectField registerName="materialId" label="料號" />
        <SelectField registerName="materialBatchId" label="批號" />
        <InputField registerName="materialQuantity" label="數量" />
        <InputField registerName="materialActualQuantity" label="實際數量" />
        <TextAreaField registerName="remark" label="備註" />
        <Row justify="end">
          <Button type="button" variant="contained">
            Check Out
          </Button>
        </Row>
      </Space>
    </FormFieldsWrapper>
  );
}
