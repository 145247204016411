import { API_NAMESPACE } from 'libs/data/src/request';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import {
  defaultValues,
  EStatus,
  SalariesFormField,
  schemas,
  SelectType,
} from './schema';

const namespace = API_NAMESPACE.HR;

export function useHumanResourceEmpDetail() {
  const endpoint = '/reports/hr/salaries/export';

  const methods = useForm<SalariesFormField>({ defaultValues });

  const [data, setData] = useState<
    GenericSalaries<SalariesExportData[]> | undefined
  >(undefined);
  const [queryParams, setQueryParams] = useState({ page: 1, perPage: 10 });
  const [modal, setModal] = useState<ColumnType | null>(null);

  const { data: swrData } = useSWR([
    endpoint,
    { namespace, params: queryParams },
  ]);

  const statusOptions: Array<SelectType<EStatus>> = [
    { id: EStatus.SUCCESS, name: EStatus.SUCCESS },
    { id: EStatus.PROCESSING, name: EStatus.PROCESSING },
    { id: EStatus.FAILED, name: EStatus.FAILED },
    { id: EStatus.WAITING, name: EStatus.WAITING },
  ];

  const isExportSource = (object: unknown): object is SalariesExportData => {
    if (object && typeof object === 'object') return 'id' in object;
    return false;
  };

  const handleCloseModal = () => {
    setModal(null);
  };

  const onSubmit = (data: SalariesFormField) => {
    const date = data[schemas.DATE];
    let dateStart: string | undefined = undefined;
    let dateEnd: string | undefined = undefined;
    if (Array.isArray(date)) {
      const formatData = date.map((item) => {
        const result = item.split('T')[0];
        return result.replace(/-/g, '/');
      });
      dateStart = formatData[0];
      dateEnd = formatData[1];
    }
    const newParams = {
      name: data[schemas.NAME],
      status: data[schemas.STATUS]?.id,
      dateStart,
      dateEnd,
    };
    const params = Object.entries(newParams).reduce((acc, cur) => {
      if (
        cur[1] !== undefined &&
        typeof cur[1] === 'string' &&
        cur[1].trim() !== ''
      )
        return { ...acc, [cur[0]]: cur[1] };
      return acc;
    }, {});
    setQueryParams((queries) => ({
      page: queries.page,
      perPage: queries.perPage,
      ...params,
    }));
  };

  useEffect(() => {
    if (isSalariesExportData(swrData)) setData(swrData);
  }, [swrData]);

  return {
    methods,
    data,
    queryParams,
    setQueryParams,
    modal,
    setModal,
    statusOptions,
    isExportSource,
    handleCloseModal,
    onSubmit,
  };
}

export type ColumnType = Record<'name' | 'id' | 'conditions', string>;

type GenericSalaries<D> = {
  currentPage: number;
  data: D;
  firstPageUrl: string;
  from: number;
  lastPage: number;
  lastPageUrl: string;
  nextPageUrl: string;
  path: string;
  perPage: string;
  prevPageUrl: string | null;
  to: number;
  total: number;
};

type SalariesExportData = {
  conditions: string;
  createdAt: string;
  filePath: string;
  id: number;
  name: string;
  status: 'PROCESSING';
};

function isSalariesExportData(
  obj: unknown
): obj is GenericSalaries<SalariesExportData[]> {
  if (obj && typeof obj === 'object') return 'data' in obj;
  return false;
}
