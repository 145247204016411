import styles from './index.module.scss';
import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions,
  Message,
  Table,
} from '@mezzanine-ui/react';
import { FormProvider, type UseFormReturn } from 'react-hook-form';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  useCustomerTradeConditionUpdate,
  CustomerTradeConditionUpdateFormFieldName as FormFieldName,
  type CustomerTradeConditionListData,
  type CustomerTradeConditionUpdateFormValues,
} from '@solar/data';
import { CustomerReadOnlyAutoComplete } from './CustomerReadOnlyAutoComplete';
import { MaterialReadOnlyAutoComplete } from './MaterialReadOnlyAutoComplete';
import { AnalysisValueMethodSelect } from './AnalysisValueMethodSelect';
import { WeightMethodSelect } from './WeightMethodSelect';

type Props = {
  dataSource: CustomerTradeConditionListData;
  refetchDataTable?: () => void;
};

const _WeightMethodName = [
  FormFieldName.WEIGHT_METHOD_1,
  FormFieldName.WEIGHT_METHOD_2,
  FormFieldName.WEIGHT_METHOD_3,
  FormFieldName.WEIGHT_METHOD_4,
];

const _WieghtMethodValue = {
  STANDARD: {
    id: 'STANDARD',
    name: '標準值',
  },
  ACTUAL: {
    id: 'ACTUAL',
    name: '實際值',
  },
};

const _AnalysisValueMethodName = [
  FormFieldName.ANALYSIS_VALUE_METHOD_1,
  FormFieldName.ANALYSIS_VALUE_METHOD_2,
  FormFieldName.ANALYSIS_VALUE_METHOD_3,
  FormFieldName.ANALYSIS_VALUE_METHOD_4,
];

const _AnalysisValueMethodValue = {
  STANDARD: {
    id: 'STANDARD',
    name: '標準值',
  },
  ACTUAL: {
    id: 'ACTUAL',
    name: '實際值',
  },
};

const _ComponentItemNumberName = [
  FormFieldName.COMPONENT_ITEM_NUMBER_1,
  FormFieldName.COMPONENT_ITEM_NUMBER_2,
  FormFieldName.COMPONENT_ITEM_NUMBER_3,
  FormFieldName.COMPONENT_ITEM_NUMBER_4,
];

export function ActionUpdateModalButton({
  dataSource,
  refetchDataTable,
}: Props) {
  const { formMethods, createFormSubmit } = useCustomerTradeConditionUpdate();

  const [activeModal, setActiveModal] = React.useState(false);

  React.useEffect(() => {
    resetUpdateForm(formMethods, dataSource);
  }, [formMethods, dataSource]);

  const openModal = () => {
    formMethods.reset();
    setActiveModal(true);
  };

  const closeModal = () => {
    formMethods.reset();
    setActiveModal(false);
  };

  const materialComposition = _ComponentItemNumberName.reduce<
    { id: string; component: string }[]
  >((sum, property, index) => {
    if (dataSource[property]) {
      sum.push({
        id: String(index),
        component: dataSource[property],
      });
    }
    return sum;
  }, []);

  const tableColumns = [
    {
      title: '成分',
      dataIndex: 'component',
    },
    {
      title: '重量交易方式',
      render(source: { id: string }, index: number) {
        const registerName = _WeightMethodName[index];
        const defaultValue = _WieghtMethodValue?.[
          dataSource[registerName] as keyof typeof _WieghtMethodValue
        ] as any;

        return (
          <WeightMethodSelect
            registerName={registerName}
            defaultValue={defaultValue}
          />
        );
      },
    },
    {
      title: '分析值交易方式',
      render(source: { id: string }, index: number) {
        const registerName = _AnalysisValueMethodName[index];
        const defaultValue = _AnalysisValueMethodValue?.[
          dataSource[registerName] as keyof typeof _AnalysisValueMethodValue
        ] as any;

        return (
          <AnalysisValueMethodSelect
            registerName={registerName}
            defaultValue={defaultValue}
          />
        );
      },
    },
  ];

  return (
    <>
      <Button variant="contained" onClick={openModal}>
        編輯
      </Button>
      <Modal
        hideCloseIcon={false}
        onClose={closeModal}
        open={activeModal}
        size="large"
      >
        <ModalHeader>編輯交易模式</ModalHeader>
        <FormProvider {...formMethods}>
          <FormFieldsWrapper
            methods={formMethods}
            onSubmit={createFormSubmit({
              successCallback: () => {
                Message.success('交易條件更新成功');
                refetchDataTable?.();
                closeModal();
              },
              errorCallback: (error) => {
                Message.error(`交易條件更新失敗：${error?.message}`);
              },
            })}
          >
            <ModalBody>
              <div className={styles.wrapper}>
                <CustomerReadOnlyAutoComplete
                  defaultValue={{
                    id: dataSource?.customerId,
                    name: `${dataSource?.customerName ?? ''}（${
                      dataSource?.customerId ?? ''
                    }）`,
                  }}
                />
                <MaterialReadOnlyAutoComplete
                  defaultValue={{
                    id: dataSource?.materialId,
                    name:
                      dataSource?.materialId &&
                      `${dataSource?.materialId}（${dataSource?.materialName}）`,
                  }}
                />
              </div>
              <div style={{ marginTop: '2rem' }}>
                <Table
                  columns={tableColumns}
                  dataSource={materialComposition}
                />
              </div>
            </ModalBody>
            <ModalActions
              cancelText="取消"
              confirmText="更新"
              confirmButtonProps={{ type: 'submit' }}
              cancelButtonProps={{ type: 'button' }}
              onCancel={closeModal}
            ></ModalActions>
          </FormFieldsWrapper>
        </FormProvider>
      </Modal>
    </>
  );
}

function resetUpdateForm(
  formMethods: UseFormReturn<CustomerTradeConditionUpdateFormValues>,
  dataSource: CustomerTradeConditionListData
) {
  formMethods.reset({
    [FormFieldName.CUSTOMER_ID]: dataSource.customerId,
    [FormFieldName.MATERIAL_ID]: dataSource.materialId,
    [FormFieldName.COMPONENT_ITEM_NUMBER_1]:
      dataSource.componentItemNumber1 || undefined,
    [FormFieldName.COMPONENT_ITEM_NUMBER_2]:
      dataSource.componentItemNumber2 || undefined,
    [FormFieldName.COMPONENT_ITEM_NUMBER_3]:
      dataSource.componentItemNumber3 || undefined,
    [FormFieldName.COMPONENT_ITEM_NUMBER_4]:
      dataSource.componentItemNumber4 || undefined,
    [FormFieldName.WEIGHT_METHOD_1]:
      (dataSource.weightMethod1 as any) || undefined,
    [FormFieldName.WEIGHT_METHOD_2]:
      (dataSource.weightMethod2 as any) || undefined,
    [FormFieldName.WEIGHT_METHOD_3]:
      (dataSource.weightMethod3 as any) || undefined,
    [FormFieldName.WEIGHT_METHOD_4]:
      (dataSource.weightMethod4 as any) || undefined,
    [FormFieldName.ANALYSIS_VALUE_METHOD_1]:
      (dataSource.analysisValueMethod1 as any) || undefined,
    [FormFieldName.ANALYSIS_VALUE_METHOD_2]:
      (dataSource.analysisValueMethod2 as any) || undefined,
    [FormFieldName.ANALYSIS_VALUE_METHOD_3]:
      (dataSource.analysisValueMethod3 as any) || undefined,
    [FormFieldName.ANALYSIS_VALUE_METHOD_4]:
      (dataSource.analysisValueMethod4 as any) || undefined,
  });
}
