import { useRef, useEffect } from 'react';
import { ExtendedRoutingRecord, LocalFilterOptions } from './typings';
import { UseFormReturn } from 'react-hook-form';

export function useSortingTable(
  tableValues: ExtendedRoutingRecord[],
  methods: UseFormReturn<LocalFilterOptions>
): React.RefObject<HTMLDivElement> {
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (tableRef.current) {
      const rows =
        tableRef.current.querySelectorAll<HTMLDivElement>('[role="row"]');

      let targetRowIndex = 0;

      const onDragStart = (e: DragEvent) => {
        const row = e.target as HTMLDivElement;

        targetRowIndex = Number(row.dataset.rowIndex);
      };

      const onDragEnd = (e: DragEvent) => {
        const { tableValues } = methods.getValues();

        if (!tableValues) return;

        const row = e.target as HTMLDivElement;
        const sourceRowIndex = Number(row.dataset.rowIndex);

        if (~[sourceRowIndex, sourceRowIndex + 1].indexOf(targetRowIndex))
          return;

        if (targetRowIndex < sourceRowIndex) {
          const targetRow = tableValues[sourceRowIndex];

          const strippedRows = [
            ...tableValues.slice(0, sourceRowIndex),
            ...tableValues.slice(sourceRowIndex + 1),
          ];

          methods.setValue('tableValues', [
            ...strippedRows.slice(0, targetRowIndex),
            targetRow,
            ...strippedRows.slice(targetRowIndex),
          ]);
        } else {
          const targetRow = tableValues[sourceRowIndex];

          const strippedRows = [
            ...tableValues.slice(0, sourceRowIndex),
            ...tableValues.slice(sourceRowIndex + 1),
          ];

          methods.setValue('tableValues', [
            ...strippedRows.slice(0, targetRowIndex - 1),
            targetRow,
            ...strippedRows.slice(targetRowIndex - 1),
          ]);
        }
      };

      const onDragOver = (e: DragEvent) => {
        const overElement = e.target as HTMLElement;
        const row = overElement.closest<HTMLDivElement>('[role="row"]');

        if (row) {
          const { top, bottom } = row.getBoundingClientRect();
          const centerLine = (bottom - top) / 2 + top;

          if (e.pageY >= centerLine) {
            row.style.borderTop = 'initial';
            row.style.borderBottom = '1px solid var(--mzn-color-primary)';

            targetRowIndex = Number(row.dataset.rowIndex) + 1;
          } else {
            row.style.borderTop = '1px solid var(--mzn-color-primary)';
            row.style.borderBottom = 'initial';

            targetRowIndex = Number(row.dataset.rowIndex);
          }
        }
      };

      const onDragLeave = (e: DragEvent) => {
        const overElement = e.target as HTMLElement;
        const row = overElement.closest<HTMLDivElement>('[role="row"]');

        if (row) {
          row.style.borderTop = 'initial';
          row.style.borderBottom = 'initial';
        }
      };

      const onTriggerButtonMouseEnter = (e: MouseEvent) => {
        const button = e.target as HTMLButtonElement;
        const row = button.closest<HTMLDivElement>('[role="row"]');

        if (row) {
          row.draggable = true;
        }
      };

      const onTriggerButtonMouseLeave = (e: MouseEvent) => {
        const button = e.target as HTMLButtonElement;
        const row = button.closest<HTMLDivElement>('[role="row"]');

        if (row) {
          row.draggable = false;
        }
      };

      Array.from(rows).forEach((row, index) => {
        row.addEventListener('dragstart', onDragStart, false);
        row.addEventListener('dragend', onDragEnd, false);
        row.addEventListener('dragover', onDragOver, false);
        row.addEventListener('dragleave', onDragLeave, false);

        row.dataset.rowIndex = index.toString();

        const triggerButton =
          row.querySelector<HTMLButtonElement>('.draggable-trigger');

        if (triggerButton) {
          triggerButton.addEventListener(
            'mouseenter',
            onTriggerButtonMouseEnter,
            false
          );
          triggerButton.addEventListener(
            'mouseleave',
            onTriggerButtonMouseLeave,
            false
          );
        }
      });

      return () => {
        Array.from(rows).forEach((row) => {
          row.removeEventListener('dragstart', onDragStart, false);
          row.removeEventListener('dragend', onDragEnd, false);
          row.removeEventListener('dragover', onDragOver, false);
          row.removeEventListener('dragleave', onDragLeave, false);

          delete row.dataset.rowIndex;

          const triggerButton =
            row.querySelector<HTMLButtonElement>('.draggable-trigger');

          if (triggerButton) {
            triggerButton.removeEventListener(
              'mouseenter',
              onTriggerButtonMouseEnter,
              false
            );
            triggerButton.removeEventListener(
              'mouseleave',
              onTriggerButtonMouseLeave,
              false
            );
          }
        });
      };
    }

    return () => {
      return;
    };
  }, [tableValues, methods]);

  return tableRef;
}
