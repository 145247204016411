import { Button } from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  FormFieldsWrapper,
} from '@mezzanine-ui/react-hook-form';
import { useGetWorkOrders, useMaterialRequestOrders } from '@solar/data';
import { Col, Divider, Row, Space } from 'antd';
import { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useReactToPrint } from 'react-to-print';
import { BillOfMaterialPage } from '../../Transportation/BillOfMaterial/BillOfMaterial';
import { A4Wrapper } from './A4Preview';
import classes from './dispatch-material-panel.module.scss';
import { useFilterContext } from './FilterContext';
import { DispatchMaterialFormProps } from './types';

export const DispatchMaterialPanel = () => {
  const filterContext = useFilterContext();
  const {
    workOrderId: contextWorkOrderId,
    setWorkOrderId,
    materialRequestOrderId,
  } = filterContext;

  const printRef = useRef<HTMLDivElement>(null);
  const methods = useForm<DispatchMaterialFormProps>();

  const { workOrders, refetchGetWorkOrders } = useGetWorkOrders();

  const workOrderOptions = workOrders?.map((wo) => ({
    id: wo?.id,
    name: wo?.id,
  }));

  const workOrderId = methods?.watch('workOrderId')?.id;

  const { orders, mutateParams } = useMaterialRequestOrders({
    initiateFetching: false,
  });

  const orderOptions = orders?.map((order) => ({
    id: order?.id.toString(),
    name: order?.id.toString(),
  }));

  const dispatchMaterialOrderId = methods?.watch('dispatchMaterialOrderId')?.id;

  const onSearchMaterialRequestOrdersByWorkOrderId = useCallback(
    (searchTerm: string) => {
      mutateParams({
        searchTerm,
        workOrderIds: workOrderId ? [workOrderId] : [],
      });
    },
    [mutateParams, workOrderId]
  );

  useEffect(() => {
    if (workOrderId) {
      onSearchMaterialRequestOrdersByWorkOrderId('');
    }
  }, [onSearchMaterialRequestOrdersByWorkOrderId, workOrderId]);

  useEffect(() => {
    if (materialRequestOrderId) {
      methods.setValue('dispatchMaterialOrderId', materialRequestOrderId);
    }
  }, [materialRequestOrderId, methods]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    methods.setValue('workOrderId', contextWorkOrderId);
  }, [contextWorkOrderId, methods]);
  return (
    <FormFieldsWrapper methods={methods} className={classes['host']}>
      <Space align="end">
        <AutoCompleteField
          required
          label="工單單號"
          registerName="workOrderId"
          options={workOrderOptions ?? []}
          onChange={(selectedValue) => {
            setWorkOrderId?.(selectedValue);
            methods.setValue('dispatchMaterialOrderId', null);
          }}
          onSearch={(searchTerm) => refetchGetWorkOrders({ searchTerm })}
        />
        <AutoCompleteField
          required
          label="發料單號"
          registerName="dispatchMaterialOrderId"
          options={orderOptions ?? []}
          onSearch={onSearchMaterialRequestOrdersByWorkOrderId}
        />
        <Button
          disabled={!dispatchMaterialOrderId}
          variant="outlined"
          onClick={handlePrint}
        >
          列印
        </Button>
      </Space>
      <Divider orientation="left">列印內容</Divider>
      <A4Wrapper landscape={true}>
        <BillOfMaterialPage
          ref={printRef}
          materialRequestId={dispatchMaterialOrderId}
        />
      </A4Wrapper>
    </FormFieldsWrapper>
  );
};
