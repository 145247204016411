import { Button, Tag } from '@mezzanine-ui/react';
import { FormFieldsWrapper, SelectField, DateRangePickerField } from '@mezzanine-ui/react-hook-form';
import { UseFormReturn } from 'react-hook-form';
import styles from './equipment-maintenance-filter.module.scss';
import { Equipment, RepairRequestPriorityOption, RepairRequestPriorityOptions, RepairRequestStatusOption, repairRequestStatusOptions } from '@solar/data';

export type EquipmentMaintenanceFilterForm = {
  rangeOfRequestDate?: [string, string];
  priority?: RepairRequestPriorityOption | { id: 'ALL', name: string };
  status?: RepairRequestStatusOption | { id: 'ALL', name: string };
  equipments?: Equipment[];
};

type EquipmentMaintenanceFilterProps = {
  methods: UseFormReturn<EquipmentMaintenanceFilterForm>,
  openLocationSelectorModal: () => void,
}

export function EquipmentMaintenanceFilter({ methods, openLocationSelectorModal }: EquipmentMaintenanceFilterProps) {
  const selectedEquipments = methods.getValues('equipments') ?? [];

  return (
    <FormFieldsWrapper className={styles.filter_form_wrapper} methods={methods}>
      <DateRangePickerField
        mode="day"
        label="申請區間："
        registerName="rangeOfRequestDate" />
      <SelectField
        label="優先序："
        registerName="priority"
        options={[
          { id: 'ALL', name: '全部' },
          ...RepairRequestPriorityOptions,
        ]} />
      <SelectField
        label="生產狀態："
        registerName="status"
        options={[
          { id: 'ALL', name: '全部' },
          ...repairRequestStatusOptions,
        ]} />
      <div>
      <div>
          {selectedEquipments.map((selectedEquipment) => (
            <Tag
              closable
              onClose={() => {
                const result = selectedEquipments.filter((equipment: Equipment) => equipment?.id !== selectedEquipment?.id);
                methods.setValue('equipments', result);
              }}
            >
              {selectedEquipment?.name}
            </Tag>
          ))}
        </div>
        <Button variant="outlined" onClick={openLocationSelectorModal}>從地圖選取</Button>
      </div>
    </FormFieldsWrapper>
  );
}
