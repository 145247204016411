import {
  AutoCompleteMultiField,
  DateRangePickerField,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { useDebounce, useSuppliers } from '@solar/data';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { INSPECT_STATUS_OPTIONS } from '../../InspectStatusTag';
import classes from './filter.module.scss';
import { pageOptions } from '../../page-options';

export function Filter() {
  const location = useLocation();

  const { records: suppliers, mutateParams: mutateSuppliersParams } =
    useSuppliers();

  const [searchTerm, setSearchTerm] = useState('');
  const watchSuppliers = useDebounce(searchTerm, 800);

  useEffect(() => {
    mutateSuppliersParams(
      watchSuppliers ? { searchTerms: watchSuppliers } : undefined
    );
  }, [mutateSuppliersParams, watchSuppliers]);

  return (
    <div className={classes.host}>
      <InputField
        registerName="searchTerm"
        placeholder="請輸入關鍵字進行搜尋（單號、供應商名稱、採購人員、採購單內項目"
      />
      <DateRangePickerField
        fullWidth
        label="日期區間："
        registerName="dateRange"
      />
      <AutoCompleteMultiField
        label={
          location.pathname.split('/').at(-1) === pageOptions[0].id
            ? '供應商名稱：'
            : '客戶名稱：'
        }
        registerName="suppliers"
        options={suppliers}
        onInput={(e) => {
          setSearchTerm((e.target as HTMLInputElement).value);
        }}
      />
      <SelectField
        label="狀態："
        mode="multiple"
        clearable
        registerName="status"
        options={[...INSPECT_STATUS_OPTIONS]}
      />
    </div>
  );
}
