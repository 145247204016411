import useSWR from 'swr';
import { request, API_NAMESPACE } from '../../request';

type CustomersModel = {
  customers: { id: string; name: string }[];
};

export function useHubClients() {
  const {
    data: swrData,
    mutate,
    isValidating,
  } = useSWR<CustomersModel>(validateSWRKey(), request, {
    revalidateOnFocus: false,
  });

  const { customers, ...meta } = swrData ?? {};

  return {
    customers,
  };
}

function validateSWRKey() {
  return [`/hubDeliveryOrders/get/filter`, { namespace: API_NAMESPACE.HR }];
}
