import {
  InventoryByMaterialSearchType,
  InventoryItem,
  NextPagination,
  PageInfo,
} from '@solar/data';

export type MaterialStock = InventoryItem;

export type StockBindOrderForm = {
  pageInfo: PageInfo;
  searchTerm: string;
} & {
  searchType: InventoryByMaterialSearchType;
  records: MaterialStock[];
};

export enum Modals {
  EDIT = 'EDIT',
  CANCEL_BIND = 'CANCEL_BIND',
}

export interface ColumnHooks {
  openEditModal: (data: EditModalDataProps) => void;
  openCancelBindModal: (data: MaterialStock) => void;
}

export type EditModalDataProps = {
  sapBatchId: string;
  subBatchId: string;
  loaderId: string;
};

export type EditModalProps = {
  paginationCallBack: (nextPagination: NextPagination) => Promise<void>;
};

export type CancelBoundModalProps = {
  paginationCallBack: (nextPagination: NextPagination) => Promise<void>;
};

export type UpdateSalesMaterialsPayload = EditModalDataProps & {
  materialId: string;
  orderId: string;
  orderSalesLine: string;
};
