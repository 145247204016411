import { Button, ButtonGroup, Icon, Typography } from '@mezzanine-ui/react';
import { TargetRuleFilter } from './components/TargetRuleManagement/TargetRuleFilter';
import styles from './TargetNumber.module.scss';
import { PlusIcon } from '@mezzanine-ui/icons';
import { TargetRuleTable } from './components/TargetRuleManagement/TargetRuleTable';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { checkJWTRoles, TargetNumberRulesParams } from '@solar/data';

export function TargetRuleManagementPage() {
  const [filterParams, setFilterParams] = useState<TargetNumberRulesParams>({});

  const handleFilterSubmit = (newFilterParams: TargetNumberRulesParams) => {
    setFilterParams(newFilterParams);
  };

  return (
    <div className={styles.container}>
      <Typography variant="h1">靶編規則管理</Typography>
      <TargetRuleFilter onSubmit={handleFilterSubmit} />
      <ButtonGroup className={styles.buttonGroup} variant="contained">
        {checkJWTRoles([78]) && (
          <Link to="/target-number/number-rules/create">
            <Button
              size="large"
              variant="contained"
              prefix={<Icon icon={PlusIcon} />}
            >
              新建規則
            </Button>
          </Link>
        )}
      </ButtonGroup>
      <TargetRuleTable filterParams={filterParams} />
    </div>
  );
}
