import { API_NAMESPACE, request } from '@solar/data';

const namespace = API_NAMESPACE.MATERIALS;

export const cancelBoundSalesOrder = ({
  payload,
}: {
  payload: {
    materialId: string;
    sapBatchId: string;
    subBatchId: string;
    loaderId: string;
  };
}): Promise<Response> => {
  return request(`/sales-materials/sales-order-bound`, {
    namespace,
    method: 'delete',
    body: JSON.stringify(payload),
    responseParser: (res) => res,
  });
};
