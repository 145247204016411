export enum CorrespondentTypes {
  Customer = 'CUSTOMER',
  Supplier = 'SUPPLIER',
  Other = 'OTHER',
  Employee = 'EMPLOYEE',
}

export const CorrespondentTypeOptions = [
  { id: CorrespondentTypes.Customer, name: '客戶' },
  { id: CorrespondentTypes.Supplier, name: '廠商' },
  { id: CorrespondentTypes.Other, name: '其他' },
  { id: CorrespondentTypes.Employee, name: '員工' },
];

export const LogisticTypeOptions = [
  { id: 'DELIVERY', name: '送貨' },
  { id: 'RECEIVING', name: '收貨' },
];

export const DeliveryAreaOptions = [
  { id: 'NORTH', name: '北部專車' },
  { id: 'CENTRAL', name: '中部專車' },
  { id: 'CHIA_NAN', name: '嘉南專車' },
  { id: 'KAO_PING', name: '高屏專車' },
  { id: 'SEA_SOUTH', name: '海運裝櫃-南部專車' },
  { id: 'SEA_NORTH_CONSOL', name: '海運併櫃-北部專車' },
  { id: 'SEA_SOUTH_CONSOL', name: '海運併櫃-南部專車' },
  { id: 'AIR_NORTH', name: '空運-北部專車' },
  { id: 'AIR_SOUTH', name: '空運-南部專車' },
];

export enum BooleanStrings {
  True = 'TRUE',
  False = 'FALSE',
}

export enum TableTypes {
  Brief = 'BRIEF',
  Detailed = 'DETAILED',
}

export const RequestStatusOptions = [
  { id: BooleanStrings.True, name: '已確認' },
  { id: BooleanStrings.False, name: '未確認' },
];

export const RequestStatusOptionsWithVoid = [
  ...RequestStatusOptions,
  { id: 'VOID', name: '作廢' },
];

export const YesNoOptions = [
  { id: BooleanStrings.True, name: '是' },
  { id: BooleanStrings.False, name: '否' },
];

export const UnitOptions = [
  { id: 'g', name: '公克' },
  { id: 'kg', name: '公斤' },
  { id: 'ton', name: '公噸' },
];

export const DeliveryItemUnitOptions = [
  { id: 'kg', name: '公斤_KG' },
  { id: 'l', name: '公升_L' },
  { id: 'gal', name: '加崙_GAL' },
];

export const DeliveryItemUnitMapping: Record<string, number> = {
  kg: 1,
  l: 1,
  gal: 3.785,
};

export const DeliveryItemDensityOptions = [
  { id: 'plastic', name: '塑膠桶' },
  { id: 'ironic', name: '鐵桶' },
  { id: 'bulk', name: '巴克桶' },
];

export const DeliveryItemDensityMapping: Record<string, number> = {
  plastic: 1.2,
  ironic: 1.25,
  bulk: 1.2,
};

export const CostUnitOptions = [
  { id: 'TEM BU', name: 'TEM BU' },
  { id: 'PGM BU', name: 'PGM BU' },
  { id: 'FCC BU', name: 'FCC BU' },
  { id: '德揚', name: '德揚' },
  { id: '精材', name: '精材' },
  { id: '董事長室', name: '董事長室' },
  { id: '總經理室', name: '總經理室' },
  { id: '財務處', name: '財務處' },
  { id: '資源整合中心', name: '資源整合中心' },
  { id: '光大', name: '光大' },
  { id: '寰宇', name: '寰宇' },
  { id: '汽化', name: '汽化' },
];

export const ScrapFeatureOptions = [
  {
    label: '酸性',
    value: '1',
  },
  {
    label: '含氰化物',
    value: '2',
  },
  {
    label: '會產生毒性氣體',
    value: '4',
  },
  {
    label: '放射性',
    value: '8',
  },
  {
    label: '易燃性',
    value: '16',
  },
  {
    label: '鹼性',
    value: '32',
  },
  {
    label: '含氟化物',
    value: '64',
  },
  {
    label: '禁水性',
    value: '128',
  },
  {
    label: '爆炸性',
    value: '256',
  },
  {
    label: '其他',
    value: '512',
  },
];

export const PackagingTypeOptions = [
  {
    label: '紙箱',
    value: '1',
  },
  {
    label: '金屬箱',
    value: '2',
  },
  {
    label: '大型塑膠桶',
    value: '4',
  },
  {
    label: '5加侖鐵桶',
    value: '8',
  },
  {
    label: '塑膠袋',
    value: '16',
  },
  {
    label: '塑膠箱',
    value: '32',
  },
  {
    label: '巴克桶',
    value: '64',
  },
  {
    label: '小型塑膠桶',
    value: '128',
  },
  {
    label: '太空包',
    value: '256',
  },
  {
    label: '其他',
    value: '512',
  },
];
