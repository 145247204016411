import { request, API_NAMESPACE } from '../../request';
import * as yup from 'yup';
import useSWR from 'swr';

const productionPlanningOrderQuerySchema = yup
  .object()
  .shape({
    page: yup.number().optional(),
    perPage: yup.number().optional(),
    materialId: yup.string().optional(),
    orderDateStart: yup.string().optional(),
    orderDateEnd: yup.string().optional(),
    expectedDeliveryDateStart: yup.string().optional(),
    expectedDeliveryDateEnd: yup.string().optional(),
    plannedDeliveryConfirm: yup.string().optional(),
    plannedDeliveryDateStart: yup.string().optional(),
    plannedDeliveryDateEnd: yup.string().optional(),
    orderId: yup.string().optional(),
    bu: yup.string().optional(),
    materialCategoryId: yup.string().optional(),
  })
  .optional();

export type ProductionPlanningOrderQuery = yup.InferType<
  typeof productionPlanningOrderQuerySchema
>;

export type ProductionPlanningOrdersMeta = {
  links: {
    url: string | null;
    lable: string;
    active: boolean;
  }[];
  path: string;
  current_page: number;
  per_page: number;
  from: number;
  to: number;
  total: number;
  first_page_url: string;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  prev_page_url: string | null;
};

export type ProductionPlanningOrdersData = {
  id: number;
  orderId: string;
  salesOrderLine: string;
  customerOrderDate: string;
  expectedDeliveryDate: string;
  materialId: string;
  materialName: string | null;
  quantity: number | null;
  quantityUnit: string;
  bu: string | null;
  plannedDeliveryDate: string;
  plannedDeliveryNote: string;
};

export type ProductionPlanningOrdersPayload = {
  data: ProductionPlanningOrdersData[];
} & ProductionPlanningOrdersMeta;

export function useProductionPlanningOrdersGet(
  query?: ProductionPlanningOrderQuery
) {
  const {
    data: swrData,
    mutate,
    isValidating,
  } = useSWR<ProductionPlanningOrdersPayload>(validateSWRKey(query), request, {
    revalidateOnFocus: false,
  });

  const { data, ...meta } = swrData ?? {};

  return {
    productionPlanningOrdersData: transformProductionPlanningOrdersData(
      data ?? []
    ),
    productionPlanningOrdersMeta: (meta as ProductionPlanningOrdersMeta) ?? {},
    refetchProductionPlanningOrders: mutate,
    isLoading: isValidating,
  };
}

function validateSWRKey(query?: ProductionPlanningOrderQuery) {
  try {
    const safeQuery = productionPlanningOrderQuerySchema.validateSync(query);

    return [
      `/production-planning/get/orders`,
      { namespace: API_NAMESPACE.HR, params: safeQuery },
    ];
  } catch (error) {
    return false;
  }
}

function transformDateFormat(date: string | null) {
  if (!date) return '';
  const d = new Date(date);
  return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
    2,
    '0'
  )}-${String(d.getDate()).padStart(2, '0')}`;
}

function transformIdType(id: number) {
  return String(id);
}

function transformProductionPlanningOrdersData(
  data: ProductionPlanningOrdersData[]
) {
  return data.map((item) => ({
    ...item,
    id: transformIdType(item.id),
    expectedDeliveryDate: transformDateFormat(item.expectedDeliveryDate),
    customerOrderDate: transformDateFormat(item.customerOrderDate),
  }));
}
