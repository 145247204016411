import { TableDataSourceWithID } from '@mezzanine-ui/core/table';
import { API_NAMESPACE, request } from '../request';
import { useState } from "react";

const namespace = API_NAMESPACE.MISC;

interface IRequestItem {
  startDate?: string | undefined;
  endDate?: string | undefined;
  customerName?: string | undefined;
  materialId?: string | undefined;
  bu?: string | undefined;
  salesName?: string | undefined;
}

interface IAPIItemComponent {
  component: string;
  wt: number;
  wtRate: number;
}

interface IAPIOrderItem {
  certainty: number | null;
  id: string;
  materialId: string;
  materialName: string;
  orderType: number | null;
  price: number;
  qty: number;
  standardWt: number;
  // status: number;
  stockArea: string;
  unit: string;
  components: IAPIItemComponent[];
}

interface IAPIOrderDetail {
  note: string;
  orderDate: string;
  sales: string;
  salesName: string;
  customer: string;
  customerName: string;
  bu: string;
  items: IAPIOrderItem[];
  deleteItemIds?: string[];
  id: string;
};

interface IOrderPredictPageInfo {
  current: number;
  pageSize: number;
  total: number;
}

export const useOrderPredictSearch = () => {

  const [requestItems, setRequestItems] = useState<IRequestItem>({});
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [serachResult, setSerachResult] = useState<TableDataSourceWithID[]>([]);
  const [searchErrorMessage, setSearchErrorMessage] = useState<string>('');
  const [pageInfo, setPageInfo] = useState<IOrderPredictPageInfo>({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const doSearch = async (
    items: IRequestItem,
    pagination: IOrderPredictPageInfo
  ) => {
    setSearchLoading(true);
    setSearchErrorMessage('');
    try {
      const requestBody = {
        ...items,
        pagination,
      };
      const response = await request('/forecastOrder/search', {
        method: 'post',
        body: JSON.stringify(requestBody),
        responseParser: (res) => {
          //console.log(res);
          return res.json();
        },
        namespace,
      });
      if (Array.isArray(response.datas)) {
        setSerachResult(response.datas);
        setPageInfo(response.pagination);
      }
    } catch (e) {
      if (e instanceof Error) {
        console.log(e.message[0])
        if (e.message[0] === '{') {
          const error = JSON.parse(e.message);
          console.log(error);
          setSearchErrorMessage(error.message);
        } else {
          setSearchErrorMessage('搜尋錯誤');
        }
      }
    }
    setSearchLoading(false);
  };

  const searchPredictOrder = async (
    items: IRequestItem,
    pagination: IOrderPredictPageInfo
  ) => {
    setRequestItems(items);
    doSearch(items, pagination);
  }

  const onPageChange = (page: number) => {
    const pagination = {
      ...pageInfo,
      current: page,
    };
    doSearch(requestItems, pagination);
  };

  const deletePredictOrder = async (id: string) => {
    try {
      const body = { id };
      const result = await request('/forecastOrder/delete', {
        body: JSON.stringify(body),
        method: 'POST',
        namespace: API_NAMESPACE.MISC,
      });
      return result;
    } catch (e) {
      console.error(e);
    }
  };

  return {
    searchPredictOrder,
    onPageChange,
    deletePredictOrder,
    searchLoading,
    serachResult,
    pageInfo,
    searchErrorMessage,
  }
};
