import { Button, Table, Typography } from '@mezzanine-ui/react';
import styles from './report-page.module.scss';
import { SubmissionFilter, useSubmissions } from './use-submissions';
import { useForm } from 'react-hook-form';
import { useTableColumns } from './use-table-columns';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';

type Props = {
  factoryId: string;
};

const FACTORY_LABELS: Record<string, string> = {
  '1100': '科工廠',
  '1200': '環科廠',
};

const defaultValues = {
  page: 1,
  pageSize: 10,
};

export function QualificationReportPage({ factoryId }: Props) {
  const methods = useForm<SubmissionFilter>({ defaultValues });

  const { submissions, loading, pageInfo, updateQueryParams } = useSubmissions(methods, factoryId);
  const { columns, pagination, expandable } = useTableColumns(methods, pageInfo);

  return (
    <div className={styles.container}>
      <iframe title="LIMS_new" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=ea8fa210-f52c-43e6-964e-cadbf3996bf6&autoAuth=true&ctid=e3553a8e-c723-4b54-ac77-0bb3db8cf161" allowFullScreen></iframe>
      <Typography variant="h2">
        檢驗結果查詢 [LIMS] {FACTORY_LABELS[factoryId]}
      </Typography>
      <FormFieldsWrapper methods={methods} className={styles.filters}>
        <InputField
          label="樣品名稱"
          placeholder="請輸入樣品名稱"
          registerName="sampleName" />
        <InputField
          label="批號"
          placeholder="請輸入批號"
          registerName="batchNumber" />
        <Button
          variant="contained"
          onClick={updateQueryParams}
          type="button">
          篩選
        </Button>
      </FormFieldsWrapper>
      <Table
        expandable={expandable}
        loading={loading}
        dataSource={submissions}
        pagination={pagination}
        columns={columns} />
    </div>
  );
}
