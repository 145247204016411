import { useEffect, useMemo, useState } from 'react';
import {
  LogisticsDataType,
  ScrapTypeResItem,
} from '../RequirementManagement.interface';
import styles from './PrintPage.module.scss';
import moment from 'moment';
import { request } from '@solar/data';
import { SelectValue } from '@mezzanine-ui/react';
import { useApplyUnit } from '../../RequirementPage/useApplyUnit';
import {
  LogisticTypeOptions,
  PackagingTypeOptions,
  ScrapFeatureOptions,
} from '../RequirementsManagement.config';

export function PrintPage() {
  const [scrapNames, setScrapNames] = useState<SelectValue[]>([]);
  const [scrapTypes, setScrapTypes] = useState<ScrapTypeResItem[]>([]);
  const { applyUnitOptions } = useApplyUnit();
  const [printing, setPrinting] = useState(false);

  useEffect(() => {
    Promise.all([
      request('/logistics/scraps', {
        namespace: '/sd/sd',
      }),
      request('/logistics/scrap-types', {
        namespace: '/sd/sd',
      }),
    ]).then(([scrapNames, scrapTypes]) => {
      setScrapNames(scrapNames);
      setScrapTypes(scrapTypes);
    });
  }, []);

  useEffect(() => {
    if (
      !window.printData?.length ||
      !applyUnitOptions?.length ||
      !scrapNames.length ||
      !scrapTypes.length ||
      printing
    ) {
      return;
    }

    window.print();
    setPrinting(true);
  }, [applyUnitOptions, scrapNames, scrapTypes, printing]);

  if (!window.printData?.length) return null;

  return (
    <div className={styles.sectionToPrint}>
      {window.printData.map((item: LogisticsDataType) => (
        <PrintItem
          data={item}
          key={item.id}
          scrapNames={scrapNames}
          scrapTypes={scrapTypes}
          applyUnitOptions={applyUnitOptions || []}
        />
      ))}
    </div>
  );
}

type PrintItemProps = {
  data: LogisticsDataType;
  scrapNames: SelectValue[];
  scrapTypes: ScrapTypeResItem[];
  applyUnitOptions: SelectValue[];
};

const PrintItem = ({
  data,
  scrapNames,
  scrapTypes,
  applyUnitOptions,
}: PrintItemProps) => {
  const applyUnit = useMemo(() => {
    return applyUnitOptions.find((item) => item.id === data.applyUnit)?.name;
  }, [applyUnitOptions, data.applyUnit]);

  const logisticType = useMemo(() => {
    return LogisticTypeOptions.find((item) => item.id === data.logisticType)
      ?.name;
  }, [data.logisticType]);

  const customerName = useMemo(() => {
    if (data.logisticType === 'RECEIVING') {
      return data.deliveryStartCompany;
    }
    if (data.logisticType === 'DELIVERY') {
      return data.deliveryEndCompany;
    }
    return '';
  }, [data.logisticType, data.deliveryStartCompany, data.deliveryEndCompany]);

  const scrapType = useMemo(() => {
    return scrapTypes.find((item) => item.id === data.scrapTypeId)?.scrapType;
  }, [scrapTypes, data.scrapTypeId]);

  const scrapName = useMemo(() => {
    return scrapNames.find((item) => item.id === data.scrapId)?.name;
  }, [scrapNames, data.scrapId]);

  const scrapFeature = useMemo(() => {
    return data.scrapFeature
      ?.toString(2)
      .split('')
      .reverse()
      .reduce((result, item, index) => {
        if (item === '1') {
          return [...result, ScrapFeatureOptions[index].label];
        }
        return result;
      }, [] as string[])
      .join(', ');
  }, [data.scrapFeature]);

  const packagingType = useMemo(() => {
    return data.packagingType
      ?.toString(2)
      .split('')
      .reverse()
      .reduce((result, item, index) => {
        if (item === '1') {
          return [...result, PackagingTypeOptions[index].label];
        }
        return result;
      }, [] as string[])
      .join(', ');
  }, [data.packagingType]);

  return (
    <div className={styles['custom-page']}>
      <div id="ctl04_page_header">
        <table className={styles['cust-table-header']}>
          <tbody>
            <tr>
              <td
                rowSpan={2}
                style={{ fontSize: '1.9em', width: '265px' }}
                align="center"
              >
                <span>Solar 派車申請單</span>
              </td>
              <td className={styles['w75']}>派車單號</td>
              <td
                colSpan={2}
                style={{ minWidth: '217px' }}
                className={styles['wBig']}
              >
                <span id="ctl04__lblDOC_NBR">{data?.requestFormId}</span>
              </td>
              <td rowSpan={2} className={styles['w75']}>
                廢料型態
              </td>
              <td rowSpan={2} colSpan={2} style={{ width: '160px' }}>
                <span id="ctl04__lblSCRAP_TYPE">{scrapType}</span>
              </td>
            </tr>
            <tr>
              <td>執行車號</td>
              <td colSpan={2}>
                <span id="ctl04__lblCAR_NO">{data.plateNumber}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <table className={styles['custom-table']}>
          <tbody>
            <tr>
              <td className={styles['w75']}>事由</td>
              <td style={{ width: '190px' }}>
                <span id="ctl04__lblREASON_TYPE">{logisticType}</span>
              </td>
              <td className={styles['w75']}>客戶名稱</td>
              <td>
                <span id="ctl04__lblTO_DESC">{customerName}</span>
              </td>
              <td rowSpan={2} className={styles['w75']}>
                廢料名稱
              </td>
              <td rowSpan={2} style={{ whiteSpace: 'normal', width: '160px' }}>
                <span id="ctl04__lblSCRAP_NAME">{scrapName}</span>
              </td>
            </tr>
            <tr>
              <td className={styles['w75']}>申請單位</td>
              <td>
                <span id="ctl04__lblAPPLY_DEPT">{applyUnit}</span>
              </td>
              <td className={styles['w75']}>聯絡人</td>
              <td>
                <span id="ctl04__lblCONTACTS_NAME">{data?.contact}</span>
                &nbsp;
                <span id="ctl04__lblCONTACTS_PHONE">{data?.contactPhone}</span>
              </td>
            </tr>
            <tr>
              <td>起點(自)</td>
              <td className={styles['wBig']} style={{ overflow: 'hidden' }}>
                <span id="ctl04__lblFROM_DESC">
                  {data?.deliveryStartCompany}
                </span>
              </td>
              <td>聯絡人</td>
              <td className={styles['wBig']}>
                <span id="ctl04__lblFROM_CONTACT">
                  {data?.deliveryStartContact}
                </span>
                <span id="ctl04__lblFROM_PHONE">
                  {data?.deliveryStartContactPhone}
                </span>
              </td>
              <td rowSpan={2}>廢料特性</td>
              <td rowSpan={2} style={{ whiteSpace: 'normal' }}>
                <span id="ctl04__lblSCRAP_PROPERTY">{scrapFeature}</span>
              </td>
            </tr>
            <tr>
              <td>起點地址</td>
              <td colSpan={3} className={styles['wBig']}>
                <span id="ctl04__lblFROM_ADDR">
                  {data?.deliveryStartAddress}
                </span>
              </td>
            </tr>
            <tr>
              <td>迄點(至)</td>
              <td className={styles['wBig']} style={{ overflow: 'hidden' }}>
                <span id="ctl04__lblTO_DESC_2">{data?.deliveryEndCompany}</span>
              </td>
              <td>聯絡人</td>
              <td className={styles['wBig']}>
                <span id="ctl04__lblTO_CONTACT">
                  {data?.deliveryEndContact}
                </span>
                <span id="ctl04__lblTO_PHONE">
                  {data?.deliveryEndContactPhone}
                </span>
              </td>
              <td rowSpan={2}>包裝容器</td>
              <td rowSpan={2} style={{ whiteSpace: 'normal' }}>
                <span id="ctl04__lblCONTAINET_TYPE">{packagingType}</span>
              </td>
            </tr>
            <tr>
              <td>迄點地址</td>
              <td
                colSpan={3}
                style={{ textAlign: 'left' }}
                className={styles['wBig']}
              >
                <span id="ctl04__lblTO_ADDR">{data?.deliveryEndAddress}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles['detail-wrap']}>
        <table
          id="ctl04_tableDetail"
          className={styles['custom-table-detail']}
          style={{ height: '100% !important' }}
        >
          <tbody>
            {(data?.items?.length ? data.items : [{} as any]).map((item) => (
              <tr key={item.id}>
                <td
                  className={styles['w75']}
                  align="center"
                  style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}
                >
                  貨品
                </td>
                <td className={styles['wBig']} align="left">
                  {item?.productName}
                </td>
                <td align="center" style={{ width: '40px' }}>
                  件數
                </td>
                <td
                  className={styles['wBig']}
                  align="center"
                  style={{ width: '60px' }}
                >
                  {item?.pieceNumber}
                </td>
                <td align="center" style={{ width: '40px' }}>
                  毛重
                </td>
                <td
                  className={styles['wBig']}
                  align="center"
                  style={{ width: '100px' }}
                >
                  {item?.totalGrossWeight} {item?.totalGrossWeightUnit}
                </td>
                <td align="center" style={{ width: '42px' }}>
                  尺寸
                </td>
                <td
                  className={styles['wBig']}
                  align="center"
                  style={{ width: '160px' }}
                >
                  {!(
                    item?.volumeLength &&
                    item?.volumeWidth &&
                    item?.volumeHeight
                  )
                    ? ''
                    : `${item?.volumeLength}x${item?.volumeWidth}x${item?.volumeHeight} cm`}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div id="page_footer" className={styles['page_footer']}>
        <table className={styles['custom-table-footer']}>
          <tbody>
            <tr>
              <td style={{ textAlign: 'center' }} className={styles['w75']}>
                備註
              </td>
              <td colSpan={3}>{data?.note}</td>
            </tr>
            <tr>
              <td style={{ textAlign: 'center' }} className={styles['w75']}>
                運輸人員
              </td>
              <td></td>
              <td style={{ textAlign: 'center' }} className={styles['w75']}>
                迄點簽收
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="Div1" className={styles['page_footer']}>
        <table style={{ width: '100%', tableLayout: 'fixed', border: '0px' }}>
          <tbody>
            <tr>
              <td className={styles['w75']}>預定日期</td>
              <td colSpan={5}>
                <span id="ctl04__lblEXPECTE_DATE">
                  {moment(data?.dispatchDate).format('yyyy/MM/DD')}
                </span>
              </td>
              <td style={{ textAlign: 'center' }} className={styles['w75']}>
                申請人:
              </td>
              <td style={{ width: '350px' }}>
                <span id="ctl04__lblAPPLY_USER">{data?.applicant}</span>
              </td>
            </tr>
            <tr>
              <td colSpan={4}>保存期限</td>
              <td colSpan={4} style={{ textAlign: 'right' }}>
                眝存容器是否標示：是□否□ PTFD-ST-GI0003-0001 Rev.02
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
