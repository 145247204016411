import { TrashIcon } from '@mezzanine-ui/icons';
import { Button, cx, Icon, Message } from '@mezzanine-ui/react';
import { API_NAMESPACE, request } from '@solar/data';
import bwipjs from 'bwip-js';
import { forwardRef, useEffect, useRef } from 'react';
import { UseFieldArrayReturn, useFormContext, useWatch } from 'react-hook-form';
import useSWR from 'swr';
import { stickerInitValue } from './const';
import classes from './material-batch-label-pdf.module.scss';
import { BatchStickerFormProps, MaterialLabelInfoProps } from './types';

export type MaterialBatchLabelPdfProps = {
  canvasId: string;
  stickerIndex: number;
  stickersArrayMethods: UseFieldArrayReturn<
    BatchStickerFormProps,
    'stickers',
    'id'
  >;
  // batchStoredLoaderRecordId: number | null;
};

export const MaterialBatchLabelPdf = forwardRef<
  HTMLDivElement,
  MaterialBatchLabelPdfProps
>(({ canvasId, stickerIndex, stickersArrayMethods }, ref) => {
  const swrFetchKey = useRef('');

  const formContext = useFormContext<BatchStickerFormProps>();
  const batchStoredLoaderRecordId = useWatch({
    control: formContext?.control,
    name: `stickers.${stickerIndex}.batchStoredLoaderRecordId`,
  });
  const stickersLength = useWatch({
    control: formContext?.control,
    name: `stickers`,
  }).length;
  const searchBarcode = useWatch({
    control: formContext?.control,
    name: `stickers.${stickerIndex}.searchBarcode`,
  });

  const { data } = useSWR<[MaterialLabelInfoProps]>(
    batchStoredLoaderRecordId || searchBarcode
      ? [
          '/warehouses/material-label-info',
          {
            namespace: API_NAMESPACE.MATERIALS,
            params: {
              batchStoredLoaderRecordIds: batchStoredLoaderRecordId
                ? [batchStoredLoaderRecordId]
                : undefined,
              barcodes:
                searchBarcode && !batchStoredLoaderRecordId
                  ? [searchBarcode]
                  : undefined,
            },
          },
        ]
      : null,
    request,
    {
      onErrorRetry: (error, key) => {
        if (
          key !== swrFetchKey.current &&
          JSON.parse(error.message).statusCode === 404
        ) {
          Message.error(JSON.stringify(JSON.parse(error.message).message));
          swrFetchKey.current = key;
          return;
        }
      },
    }
  );

  const focusedIndex = useWatch({
    control: formContext.control,
    name: 'focusedIndex',
  });

  const targetLabelInfo = data ? data[0] : undefined;

  useEffect(() => {
    if (
      (batchStoredLoaderRecordId || searchBarcode) &&
      data &&
      data[0].materialBarcode
    ) {
      bwipjs.toCanvas(canvasId, {
        bcid: 'datamatrix',
        text: String(data[0].materialBarcode),
        // width: 15,
        // height: 15,
      });
    }
  }, [searchBarcode, batchStoredLoaderRecordId, canvasId, data]);

  useEffect(() => {
    if (searchBarcode && targetLabelInfo) {
      formContext.setValue(`stickers.${focusedIndex}`, {
        material: {
          id: targetLabelInfo.materialId,
          name: targetLabelInfo.materialId,
        },
        materialDescription: {
          id: targetLabelInfo.materialId,
          name: targetLabelInfo?.materialDescription ?? '',
        },
        batch: {
          id: `${targetLabelInfo?.materialSubBatchId}-${targetLabelInfo.materialSapBatchId}`,
          name: `${targetLabelInfo?.materialSubBatchId}-${targetLabelInfo.materialSapBatchId}`,
          sapBatchId: targetLabelInfo?.materialSapBatchId ?? '',
          subBatchId: targetLabelInfo?.materialSubBatchId ?? '',
        },
        quantity: targetLabelInfo?.availableMaterialStockUnitQuantity ?? null,
        remark: targetLabelInfo?.materialSubBatchRemark ?? null,
        batchStoredLoaderRecordId:
          targetLabelInfo?.batchStoredLoaderRecordId ?? '',
        loader: {
          id: targetLabelInfo.loaderId,
          name: targetLabelInfo?.loaderId,
        },
        type: { id: targetLabelInfo.type, name: targetLabelInfo?.type },
        staff: '',
        searchBarcode: searchBarcode,
        triggerRerenderBoolean: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBarcode, targetLabelInfo]);

  return (
    <div
      ref={ref}
      className={cx(classes['wrapper'], 'pdf-wrapper', {
        [classes['focused-wrapper']]: focusedIndex === stickerIndex,
      })}
      onClick={() => {
        formContext.setValue('focusedIndex', stickerIndex);
      }}
    >
      {focusedIndex === stickerIndex && (
        <Button
          className={cx(classes['trash-icon'], 'trash-icon')}
          type="button"
          prefix={<Icon icon={TrashIcon} />}
          onClick={() => {
            if (stickersLength === 1) {
              formContext.setValue('stickers.0', stickerInitValue);
              return;
            }

            stickersArrayMethods.remove(stickerIndex);
          }}
        />
      )}
      <div className={classes['label_header']}>
        <img
          src="/assets/brand.png"
          alt="Solar"
          style={{
            filter:
              'invert(0%) sepia(5%) saturate(10%) hue-rotate(325deg) brightness(98%) contrast(99%)',
          }}
        />
      </div>
      <div className={cx(classes['top_content'])}>
        <div className={classes['label_content']}>
          <span>料號</span>
          <span>{`${targetLabelInfo?.materialId ?? ''}`}</span>
          <span>品名</span>
          <span className={classes['description']}>{`${
            targetLabelInfo?.materialDescription ?? ''
          }`}</span>
          <span>批號</span>
          <span>
            {targetLabelInfo
              ? `${targetLabelInfo?.materialSapBatchId}-${targetLabelInfo?.materialSubBatchId}`
              : ''}
          </span>
        </div>
      </div>
      <div className={cx(classes['left_content'])}>
        <div className={classes['label_content']}>
          <span>數量</span>
          <span>
            {`${targetLabelInfo?.quantity ?? ''} ${
              targetLabelInfo?.materialStockUnit ?? ''
            }`}
          </span>
          <span style={{ height: 'var(--mzn-spacing-5)' }} />
          <span>
            {targetLabelInfo
              ? `${
                  Number(targetLabelInfo?.quantity) /
                    Number(targetLabelInfo?.stockOverInputUnitRatio) ?? ''
                } ${targetLabelInfo?.materialInputUnit ?? ''}`
              : ''}
          </span>
          {/*  */}
          <span>實際重量</span>
          <span>{`${
            targetLabelInfo?.actualMaterialWeightUnitQuantity ?? ''
          }`}</span>
          {/*  */}
          <span>備註</span>
          <span className={classes['remark']}>{`${
            targetLabelInfo?.materialSubBatchRemark ?? ''
          }`}</span>
        </div>
      </div>
      <div className={classes['bottom_label_barcode']}>
        {(batchStoredLoaderRecordId || searchBarcode) && (
          <canvas id={canvasId} style={{ width: '85px', height: '85px' }} />
        )}
      </div>
    </div>
  );
});
