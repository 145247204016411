import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import {
  FieldsType,
  headers,
  opFilterMutation,
  ResponseStatus,
} from '../../humanResources';
import { usePackagingFilter } from './usePackagingFilter';
import { API_NAMESPACE, request } from '../../request';
import { Message } from '@mezzanine-ui/react';

const namespace = API_NAMESPACE.HR;

export function usePackaging() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [queryParams, setQueryParams] = useState('');

  const tableQueries = useMemo(
    () => `?page=${page}&perPage=${perPage}`,
    [page, perPage]
  );

  const { data: swrData, mutate } = useSWR([
    `/packaging/get${tableQueries}${queryParams}`,
    { namespace },
  ]);

  const { data: packagingFilterData } = usePackagingFilter();

  const total = useMemo(() => swrData?.total ?? 0, [swrData]) as number;

  const filterMutation = async (payload: FieldsType[]) => {
    const mapping = [
      { query: 'materialId', registerName: 'packagingNumber', isDate: false },
      { query: 'name', registerName: 'packagingName', isDate: false },
      { query: 'length', registerName: 'length', isDate: false },
      { query: 'width', registerName: 'width', isDate: false },
      { query: 'height', registerName: 'height', isDate: false },
    ];

    const joinResult = opFilterMutation(payload, mapping);

    joinResult === '' ? setQueryParams('') : setQueryParams(`&${joinResult}`);
  };

  const createMutation = async (payload: any) => {
    const options = {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    };
    request('/packaging', {
      ...options,
      namespace,
      responseParser: () => ({ status: 200 }),
    })
      .then((res) => {
        mutate();
        if (res?.status === 200) {
          mutate();
          Message['success']('成功');
        }
        return;
      })
      .catch((error) => {
        const message = JSON.parse(error?.message)?.message as string[];
        Message['error'](message[0] ?? '');
      });
  };

  const updateMutation = async (id: string, payload: any) => {
    const options = {
      method: 'PUT',
      headers,
      body: JSON.stringify(payload),
    };

    request(`/packaging/${id}`, {
      ...options,
      namespace,
      responseParser: () => ({ status: 200 }),
    })
      .then(() => {
        mutate();
        Message['success']('成功');
      })
      .catch((error) => {
        const message = JSON.parse(error?.message)?.message as string[];
        Message['error'](message[0] ?? '');
      });
  };

  const deleteMutation = async (id: string) => {
    const options = {
      method: 'DELETE',
      headers,
    };
    const res = await request(`/packaging/${id}`, {
      ...options,
      namespace,
      responseParser: () => ({ status: 200 }),
    });
    if (res.status === 200) {
      mutate();
      return ResponseStatus.SUCCESS;
    } else {
      return ResponseStatus.FAIL;
    }
  };

  const replacePackageTypeData = (data: any | undefined) => {
    return (
      data?.map((item: any) => ({
        ...item,
        packageTypeName: packagingFilterData?.packagingTypes?.find(
          (filter) => item.packagingType === filter.id
        )?.name,
      })) ?? []
    );
  };

  useEffect(() => {
    if (!swrData || typeof swrData === 'string') {
      setLoading(true);
      return;
    }

    setData(replacePackageTypeData(swrData.data));
    setLoading(false);
  }, [swrData, packagingFilterData]);

  return {
    data,
    total,
    loading,
    filterMutation,
    createMutation,
    updateMutation,
    deleteMutation,
    page,
    setPage,
    perPage,
    setPerPage,
  };
}
