import { Loading, Typography } from '@mezzanine-ui/react';
import styles from './ManagementPage.module.scss';
import { API_NAMESPACE, environment, request } from '@solar/data';
import { useEffect, useMemo, useState } from 'react';

export function ManagementPage() {
  const [token, setToken] = useState('');

  useEffect(() => {
    let cancelled = false;

    request('/logistics/login-token', {
      namespace: API_NAMESPACE.SD,
      responseParser: (res) => res.text(),
    }).then((responseToken) => {
      if (cancelled) return;

      setToken(responseToken);
    });

    return () => {
      cancelled = true;
    };
  }, []);

  const iframeSrc = useMemo(() => {
    return environment.production
      ? `https://logistic.solartech.cloud?token=${token}`
      : `https://dev-logistic.solartech.cloud?token=${token}`;
  }, [token]);

  if (!token) return <Loading loading />;

  return (
    <div className={styles.container}>
      <Typography variant="h2">派車 - 派車管理</Typography>
      <iframe
        src={iframeSrc}
        style={{ border: 'none', height: '100vh', width: '100%' }}
        title="Logistic Dispatch Management - Carota System"
      />
    </div>
  );
}

export default ManagementPage;
