import { CheckboxGroupField, InputField } from '@mezzanine-ui/react-hook-form';
import {
  BooleanStrings,
  PackagingTypeOptions,
} from '../../../Transportation/RequirementsManagementPage/RequirementsManagement.config';
import { useFormContext } from 'react-hook-form';

export function PackagingCheckbox({ className }: { className?: string }) {
  const { watch } = useFormContext();
  const isRecycle = watch('isRecycle')?.id === BooleanStrings.True;

  return (
    <>
      <CheckboxGroupField
        fieldClassName={className}
        label="包裝容器"
        required={isRecycle}
        disabled={!isRecycle}
        orientation="horizontal"
        registerName="packagingType"
        options={PackagingTypeOptions}
      />
      <InputField
        label="其他說明"
        placeholder="請說明"
        registerName="packagingTypeOtherNote"
      />
    </>
  );
}
