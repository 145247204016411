import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';
import { CorrespondentTypeOptions } from 'apps/frontend/src/app/Transportation/RequirementsManagementPage/RequirementsManagement.config';

export function CorrespondentTypeSelector() {
  return (
    <AutoCompleteField
      label="對象身份"
      required
      registerName="correspondentType"
      options={CorrespondentTypeOptions}
    />
  );
}
