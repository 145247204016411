import { Equipment } from '../equipments';

export type NewFactory = {
  id: string;
  name: string;
  seq: string;
  buildings: Building[];
};

export type Building = {
  id: string;
  name: string;
  seq: string;
  floors: Omit<Floor, 'areas'>[];
};

export type Floor = {
  id: string;
  name: string;
  seq: string;
  areas: Omit<Area, 'zones'>[];
};

export type Area = {
  id: string;
  name: string;
  seq: string;
  zones: Zone[];
};

export type Zone = {
  id: string;
  name: string;
  seq: string;
  areaId: Area['id'];
  stacks: Omit<Stack, 'shelves'>[];
  equipmentQuantity: number;
};

export type Stack = {
  id: string;
  name: string;
  chartId: string;
  shelfQty: number;
  shelves: Shelf[];
  equipmentQuantity: number;
};

export type StackJoinEquipmentsAndMaterials = Omit<Stack, 'shelves'> & {
  // shelves: (Shelf & {
  //   loaders: LoaderWithBatchRecords[];
  // })[];
  equipments: Equipment[];
  materials: {
    material_id: string;
    main_material_unit_quantity: string;
    main_material_unit_name: string;
  }[];
  shelfMaterials: {
    shelf_id: string;
    materials: {
      material_id: string;
      main_material_unit_quantity: string;
      main_material_unit_name: string;
    }[];
  }[];
};

export type Shelf = {
  id: string;
  name: string;
  stackId: Stack['id'];
  equipmentQuantity: number;
};

export type SelectedIds = {
  factoryId?: NewFactory['id'];
  buildingId?: Building['id'];
  floorId?: Floor['id'];
  areaId?: Area['id'];
  zoneId?: Zone['id'];
  stackId?: Stack['id'];
  shelfId?: Shelf['id'];
};
