import {
  DateRangePickerField,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';

import {
  OUTSOURCE_PURCHASE_STATES_TRANSLATION,
  OutsourcePurchaseShippedState,
} from '@solar/data';

import classes from './filter.module.scss';

const SORT_TYPE = {
  EXPECTED_COMPLETED_AT_DESC: '預期發料日期新 -> 舊',
  EXPECTED_COMPLETED_AT_ASC: '預期發料日期舊 -> 新',
};

const SORT_TYPE_SELECTIONS = Object.entries(SORT_TYPE).map(([key, value]) => ({
  id: key,
  name: value,
}));

const SHIPPEDSTATE_SELECTIONS = Object.values(
  OutsourcePurchaseShippedState
).map((key) => ({
    id: key,
    name: OUTSOURCE_PURCHASE_STATES_TRANSLATION[key],
  })
);

export function DispatchOrderPanelFilter() {
  return (
    <div className={classes.host}>
      <InputField
        registerName="searchTerm"
        placeholder="請輸入關鍵字進行搜尋（發料單號)"
      />
      <SelectField
        label="狀態："
        mode="multiple"
        clearable
        registerName="states"
        options={SHIPPEDSTATE_SELECTIONS}
      />
      <DateRangePickerField
        fullWidth
        label="預期發料日期："
        registerName="expectedCompletedDateRange"
      />
      <DateRangePickerField
        fullWidth
        label="實際發料日期："
        registerName="actualCompletedDateRange"
      />
      <SelectField
        label="預期發料日期："
        clearable
        registerName="sortType"
        options={SORT_TYPE_SELECTIONS}
      />
    </div>
  );
}
