import { Tab, TabPane, Tabs } from '@mezzanine-ui/react';
import {
  ModalGroup,
  PageLayout,
  useModalGroupController,
} from '@solar/templates';
import { useLocation, useNavigate } from 'react-router-dom';
import { PAGE_OPTIONS, SiteInfoContext } from './const';
import { GenParamsPanel } from './GenParamsPanel';
import { InStationPanel } from './InStationPanel/InStationPanel';
import { OutStationPanel } from './OutStationPanel/OutStationPanel';
import { Resolver, useForm } from 'react-hook-form';
import { useAutoNormalManufacturingPageRouting } from './hooks/use-auto-normal-manufacturing-page-routing';
import {
  EquipmentMaterialsList,
  MaterialListFormFields,
  equipmentMaterialsDefaultValues,
} from './EquipmentMaterialsList';
import { useEquipment } from '@solar/data';
import { EquipmentSelector, FilterFormFields } from './EquipmentSelector';
import {
  CheckInFormFields,
  checkInFormDefaultValues,
} from './hooks/use-in-station-list-columns';
import {
  CheckOutFormFields,
  checkOutFormDefaultValues,
} from './OutStationPanel/out-station-panel';
import { useEquipmentMaterials } from './hooks/use-equipment-materials';
import { useEffect, useMemo, useState } from 'react';
import { DispatchMaterialViewModal } from './DispatchMaterialViewModal';
import { Modals } from './types';

export function NormalManufacturingPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const isNormalManufacturing = location.pathname.includes(
    '/normal-manufacturing'
  );
  const tab = useAutoNormalManufacturingPageRouting();

  const filterMethods = useForm<FilterFormFields>();
  const materialListMethods = useForm<MaterialListFormFields>({
    defaultValues: equipmentMaterialsDefaultValues,
  });
  const checkInMethods = useForm<CheckInFormFields>({
    defaultValues: checkInFormDefaultValues,
  });
  const [checkOutMethodsResolver, setCheckOutMethodsResolver] =
    useState<Resolver<CheckOutFormFields>>();
  const checkOutMethods = useForm<CheckOutFormFields>({
    defaultValues: checkOutFormDefaultValues,
    resolver: checkOutMethodsResolver,
  });

  const modalGroupController = useModalGroupController([
    { name: Modals.DISPATCH_MATERIAL_VIEW },
  ]);

  const equipment = filterMethods.watch('equipment');

  const { equipment: selectedEquipment } = useEquipment(equipment?.id);

  const { equipmentMaterials } = useEquipmentMaterials(equipment?.id ?? '');

  // const selectAllEquipmentMaterials = useCallback(() => {
  //   materialListMethods?.setValue(
  //     'selectedEquipmentMaterials',
  //     equipmentMaterials?.inputMaterials?.map((record) => ({
  //       id: record?.equipmentMaterialRecordId?.toString(),
  //       outputMaterialWeightUnitQuantity:
  //         record?.inputMaterialWeightUnitQuantity ?? '0',
  //       outputMaterialStockUnitQuantity:
  //         record?.inputMaterialStockUnitQuantity ?? '0',
  //     })) ?? []
  //   );
  // }, [equipmentMaterials]);

  const siteInfo = useMemo(
    () => ({
      equipmentId: selectedEquipment?.id,
      workCenterId: selectedEquipment?.workCenterId,
      processingId: null,
      recipeId: null,
    }),
    [selectedEquipment]
  );

  const title = isNormalManufacturing ? '靶材製造' : '回收製造';

  useEffect(() => {
    materialListMethods.reset(equipmentMaterialsDefaultValues);
  }, [selectedEquipment]);

  return (
    <PageLayout title={title}>
      <ModalGroup {...modalGroupController}>
        <EquipmentSelector methods={filterMethods} />
        <EquipmentMaterialsList
          methods={materialListMethods}
          equipmentId={equipment?.id ?? null}
        />
        <SiteInfoContext.Provider value={siteInfo}>
          <Tabs
            activeKey={tab}
            onChange={(tabId) => {
              // if (tabId === PAGE_OPTIONS.CHECK_OUT.id) {
              //   selectAllEquipmentMaterials();
              // }
              checkInMethods.reset(checkInFormDefaultValues);
              checkOutMethods.reset(checkOutFormDefaultValues);
              navigate(location.pathname + `/../${tabId}`);
            }}
          >
            <TabPane
              key={PAGE_OPTIONS.CHECK_IN.id}
              tab={<Tab>{PAGE_OPTIONS.CHECK_IN.name}</Tab>}
            >
              <InStationPanel methods={checkInMethods} />
            </TabPane>
            <TabPane
              key={PAGE_OPTIONS.GEN_PARAMS.id}
              tab={<Tab>{PAGE_OPTIONS.GEN_PARAMS.name}</Tab>}
            >
              <GenParamsPanel filterMethods={filterMethods} />
            </TabPane>
            <TabPane
              key={PAGE_OPTIONS.CHECK_OUT.id}
              tab={<Tab>{PAGE_OPTIONS.CHECK_OUT.name}</Tab>}
            >
              <OutStationPanel
                materialListMethods={materialListMethods}
                methods={checkOutMethods}
                setResolver={setCheckOutMethodsResolver}
              />
            </TabPane>
          </Tabs>
        </SiteInfoContext.Provider>
        <DispatchMaterialViewModal />
      </ModalGroup>
    </PageLayout>
  );
}
