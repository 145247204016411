import { Message } from '@mezzanine-ui/react';
import { request, API_NAMESPACE } from '../../request';

export interface ReceivingDeliveryOrdersPayload {
  items: ReceiveingItem[];
  recycleNumber: any;
}

export interface ReceiveingItem {
  specId: number;
  toShelfId: string;
  toLoaderId: any;
  materialInputUnit: string;
  batches: ReceivingBatch[];
  remark: any;
}

export interface ReceivingBatch {
  materialInputUnitQuantity: string;
  materialWeightUnitQuantity: string;
  materialGrossWeightUnitQuantity: string;
  remark: any;
  deliveredOrderId: any;
  deliveredOrderLine: any;
  deliveredSapBatchId: any;
  deliveredSubBatchId: any;
}

async function putReceivingOrdersReceipt(
  payload: ReceivingDeliveryOrdersPayload
) {
  try {
    return request(`/warehouses/production-delivery-orders/receipt`, {
      namespace: API_NAMESPACE.MATERIALS,
      method: 'PUT',
      body: JSON.stringify(payload),
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export { putReceivingOrdersReceipt };
