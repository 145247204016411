import { TableColumn } from '@mezzanine-ui/core/table';
import { MaterialRequestOrderLogs } from '@solar/data';
import moment from 'moment';
import { useMemo } from 'react';
export function useLogsColumns() {
  return useMemo<TableColumn<MaterialRequestOrderLogs>[]>(
    () => [
      {
        title: '發料時間',
        render: (source) =>
          moment(source?.createdAt).format('YYYY/MM/DD HH:mm'),
      },
      {
        title: '領料人',
        dataIndex: 'staffId',
      },
      {
        title: '品名',
        dataIndex: 'materialDescription',
      },
      {
        title: '料號',
        dataIndex: 'materialId',
      },
      {
        title: '批號流水',
        dataIndex: 'materialBatchId',
      },
      {
        title: '發料位置',
        dataIndex: 'shelfId',
      },
      {
        title: '發料載具',
        dataIndex: 'loaderId',
      },
      {
        title: '發料數量',
        dataIndex: 'mainMaterialUnitQuantity',
      },
      {
        title: '單位',
        dataIndex: 'mainMaterialUnit',
      },
    ],
    []
  );
}
