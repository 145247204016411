import { useEffect, useMemo, useState } from 'react';
import {
  Typography,
  Button,
  Table,
  MenuDivider,
  Popper,
} from '@mezzanine-ui/react';
import { useNavigate } from 'react-router-dom';
import sharedStyles from '../shared-styles/index.module.scss';
import {
  LocationLevel,
  ZoneEntity,
  getZoneDisplayName,
  useEquipments,
  useSVGs,
} from '@solar/data';
import { useForm } from 'react-hook-form';

import { useModalController } from '../ModalLayout/hooks';
import {
  LocationSVGViewer,
  transformZoneToLocation,
} from '../../Equipment/LocationSelector/LocationSVGViewer';
import classes from '../MaterialCollectPage/material-collect-page.module.scss';
import { TableColumn } from '@mezzanine-ui/core/table';

import { SharedMaterialBasicFilter } from '../SharedMaterialBasicFilter/SharedMaterialBasicFilter';
import { MaterialScrappingModal } from './Modal/MaterialScrappingModal';

type MaterialScrappingPageTableProps = {
  materialId: string;
  batch: string;
  zone: ZoneEntity;
  vehicle: string;
  amount: number;
};

export function MaterialScrappingPage() {
  const navigate = useNavigate();

  const [anchorRef, setAnchorRef] = useState<HTMLDivElement | null>();
  const { svg, mutateGetSVG, resetSVG } = useSVGs();
  const [currentZone, setCurrentZone] = useState<ZoneEntity | null>();

  // 搜尋條件
  const filterMethods = useForm({});

  // modal controllers
  const modalController = useModalController();

  // table data
  const {
    equipments,
    pageInfo,
    setParams,
    mutateGetEquipments,
    isLoading: tableLoading,
  } = useEquipments(filterMethods);

  useEffect(() => {
    const subscription = filterMethods.watch(() =>
      mutateGetEquipments({ nextPage: 1 })
    );
    return () => subscription.unsubscribe();
  }, [filterMethods, filterMethods.watch, mutateGetEquipments]);

  // table column
  const columns: TableColumn<MaterialScrappingPageTableProps>[] = useMemo(
    () => [
      { title: '料號', dataIndex: 'materialId' },
      { title: '舊料號', dataIndex: 'oldMaterialId' },
      { title: '批號', dataIndex: 'batch' },
      {
        title: '儲位',
        ellipsis: false,
        render: (source) => (
          <div
            data-zone-id={source?.zone?.id}
            onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => {
              if (source?.zone?.id) {
                mutateGetSVG(source?.zone?.area?.id);
                setCurrentZone(source?.zone);
                setAnchorRef(event.currentTarget);
              }
            }}
            onMouseLeave={() => {
              if (source?.zone?.id) {
                resetSVG();
                setCurrentZone(null);
                setAnchorRef(null);
              }
            }}
          >
            {getZoneDisplayName(source?.zone)}
          </div>
        ),
      },
      { title: '載具', dataIndex: 'vehicle' },
      {
        title: '數量',
        render: (source) => {
          return <div>{source?.amount ?? '0'}</div>;
        },
      },
      {
        title: '操作',
        width: 120,
        align: 'center' as const,
        render() {
          return (
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                modalController.openModal({});
              }}
            >
              報廢
            </Button>
          );
        },
      },
    ],
    [modalController, mutateGetSVG, resetSVG]
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes['header-wrapper']}>
        <Typography variant="h1">物料報廢</Typography>
        <Button
          type="button"
          variant="outlined"
          onClick={() => navigate('/warehouse/material-collect/create')}
        >
          物料報廢
        </Button>
      </div>
      <MenuDivider />
      <SharedMaterialBasicFilter
        methods={filterMethods}
        setParams={setParams}
      />
      <Table
        columns={columns}
        loading={tableLoading}
        pagination={{
          disableAutoSlicing: true,
          total: pageInfo?.totalCount ?? 0,
          current: pageInfo?.page ?? 1,
          onChange: (nextPage) => mutateGetEquipments({ nextPage }),
          options: {
            pageSize: pageInfo?.pageSize ?? 20,
            pageSizeLabel: '每頁顯示：',
            pageSizeUnit: '筆',
            onChangePageSize: (nextPageSize) =>
              mutateGetEquipments({ nextPage: 1, nextPageSize }),
            renderPageSizeOptionName: (p) => `${p}`,
            renderPaginationSummary: (start, end) =>
              `目前顯示 ${start} - ${end} 筆，共 ${end - start + 1} 筆資料`,
            showPageSizeOptions: true, // 開啟每頁顯示 N 筆
            showJumper: true, // 開啟跳頁功能
            jumperButtonText: '前往',
            jumperHintText: '跳至',
          },
        }}
        dataSource={equipments ?? []}
        bodyClassName={sharedStyles.table}
      />
      <Popper
        anchor={anchorRef}
        open={Boolean(anchorRef)}
        style={{
          border: '1px solid #000',
          borderRadius: '5px',
          backgroundColor: '#fff',
        }}
        options={{
          placement: 'auto',
        }}
      >
        <LocationSVGViewer
          svg={svg}
          currentLevel={LocationLevel.ZONE}
          currentLocation={
            currentZone ? transformZoneToLocation(currentZone) : undefined
          }
        />
      </Popper>
      <MaterialScrappingModal {...modalController} />
    </div>
  );
}
