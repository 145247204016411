import { RepairRequest, downloadFile } from "@solar/data";
import { Button, Icon, Modal, ModalBody, ModalHeader, Typography } from "@mezzanine-ui/react";
import styles from './equipment-maintenance-modal.module.scss';
import { getZoneDisplayName, useEquipment, useGetRepairRequestLogs } from "@solar/data";
import moment from "moment";
import { RepairRequestPriorityTag } from "./RepairRequestPriorityTag";
import { RepairRequestStatusTag, RepairRequestStatusText, getRepairRequestStatusColor } from "./RepairRequestStatusTag";
import { Timeline } from "antd";
import { DownloadIcon } from "@mezzanine-ui/icons";
import { Modals } from "./EquipmentMaintenanceModal.enum";
import { useTargetModal } from "../../Templates";

export function ViewEquipmentMaintenanceModal() {
  const { open, data: repairRequest, closeModal } = useTargetModal<RepairRequest>(Modals.VIEW);
  const { equipment } = useEquipment(repairRequest?.equipment?.id ?? '');
  const { repairRequestLogs } = useGetRepairRequestLogs({ requestId: repairRequest?.id });
  const lastRepairRequestLog = repairRequestLogs?.at(-1);

  return (
    <Modal className={styles.modal_wrapper} open={open} onClose={closeModal}>
      <ModalHeader>
        <div style={{ display: 'flex', alignItems: 'center', gap: 'var(--mzn-spacing-4)' }}>
        <Typography variant="h1">維修單</Typography>
        <RepairRequestPriorityTag priority={repairRequest?.priority} />
        <RepairRequestStatusTag status={lastRepairRequestLog?.status} />
        </div>
      </ModalHeader>
      <ModalBody>
        <div className={styles.form_group}>
          <Typography variant="h4">基本資訊</Typography>
          <div className={styles.detail_wrapper}>
            <div style={{ width: '120px' }}>
              <Typography variant="h6" color="secondary">報修單號</Typography>
              <Typography>{repairRequest?.id || ''}</Typography>
            </div>
            <div style={{ width: '220px' }}>
              <Typography variant="h6" color="secondary">設備名稱</Typography>
              <Typography>{equipment?.name || ''}</Typography>
            </div>
            <div>
              <Typography variant="h6" color="secondary">位置</Typography>
              <Typography>{getZoneDisplayName(equipment?.zone)}</Typography>
            </div>
          </div>
          <div className={styles.detail_wrapper}>
            <div style={{ width: '120px' }}>
              <Typography variant="h6" color="secondary">申請人</Typography>
              <Typography>{repairRequest?.creator || ''}</Typography>
            </div>
            <div style={{ width: '220px' }}>
              <Typography variant="h6" color="secondary">報修時間</Typography>
              <Typography>{moment(repairRequest?.createdAt).format("YYYY/MM/DD HH:mm") || ''}</Typography>
            </div>
            <div>
              <Typography variant="h6" color="secondary">最後更新</Typography>
              <Typography>{moment(repairRequest?.updatedAt).format("YYYY/MM/DD HH:mm") || ''}</Typography>
            </div>
          </div>
          <div className={styles.detail_wrapper}>
            <div>
              <Typography variant="h6" color="secondary">報修備註</Typography>
              <Typography>{repairRequest?.remark || '無'}</Typography>
            </div>
          </div>
          <div>
            <Typography variant="h6" color="secondary">附件</Typography>
            {repairRequest?.files?.map((file: any) => (
              <Typography>
                {file?.title}
                <Button prefix={<Icon icon={DownloadIcon} />} onClick={() => downloadFile(file)} />
              </Typography>
            )) || []}
          </div>
        </div>
        {lastRepairRequestLog && (
          <div className={styles.form_group}>
            <Typography variant="h4">處理方式</Typography>
            {lastRepairRequestLog?.members?.length > 0 && (
              <>
                <div className={styles.detail_wrapper}>
                  <div style={{ width: '110px' }}>
                    <Typography variant="h6" color="secondary">處理方式</Typography>
                    <Typography>內部</Typography>
                  </div>
                  <div style={{ width: '150px' }}>
                    <Typography variant="h6" color="secondary">維修人數 (人)</Typography>
                    <Typography>{lastRepairRequestLog?.menCount || ''}</Typography>
                  </div>
                  <div style={{ width: '160px' }}>
                    <Typography variant="h6" color="secondary">維修時間 (小時)</Typography>
                    <Typography>{lastRepairRequestLog?.hoursCount || ''}</Typography>
                  </div>
                  <div>
                    <Typography variant="h6" color="secondary">維修人員</Typography>
                    <Typography>{lastRepairRequestLog?.members
                      ?.reduce((acc: any, cur: any) => `${acc}、${cur?.name}`, '')
                      ?.substring(1) || ''}</Typography>
                  </div>
                </div>
                <div>
                  <Typography variant="h6" color="secondary">處理明細</Typography>
                  <Typography>{lastRepairRequestLog?.internalRemark}</Typography>
                </div>
              </>
            )}
            {lastRepairRequestLog?.supplier && (
              <>
                <div className={styles.detail_wrapper}>
                  <div style={{ width: '110px' }}>
                    <Typography variant="h6" color="secondary">處理方式</Typography>
                    <Typography>外部</Typography>
                  </div>
                  <div style={{ width: '150px' }}>
                    <Typography variant="h6" color="secondary">廠商 (人)</Typography>
                    <Typography>{lastRepairRequestLog?.supplier?.name || ''}</Typography>
                  </div>
                  <div>
                    <Typography variant="h6" color="secondary">送修時間</Typography>
                    <Typography>{moment(lastRepairRequestLog?.sendOutAt).format('YYYY/MM/DD HH:mm:ss')  || ''}</Typography>
                  </div>
                  <div>
                    <Typography variant="h6" color="secondary">完修時間</Typography>
                    <Typography>{moment(lastRepairRequestLog?.fixedAt).format('YYYY/MM/DD HH:mm:ss') || ''}</Typography>
                  </div>
                </div>
                <div>
                  <Typography variant="h6" color="secondary">附件</Typography>
                  {lastRepairRequestLog?.files?.map((file: any) => (
                    <Typography>
                      {file?.title}
                      <Button prefix={<Icon icon={DownloadIcon} />} onClick={() => downloadFile(file)} />
                    </Typography>
                  )) || []}
                </div>
              </>
            )}
          </div>
        )}
        <div className={styles.form_group}>
          <Typography variant="h4">處理紀錄</Typography>
          <Timeline
            style={{ marginTop: '10px' }}
            mode="left"
            items={[repairRequest, ...(repairRequestLogs || [])]?.map((log: any) => ({
              color: getRepairRequestStatusColor(log?.status),
              children: (
                <Typography>
                  <RepairRequestStatusText status={log?.status} />
                  {moment(log?.createdAt).format('YYYY/MM/DD · HH:mm:ss')}
                </Typography>
              ),
            }))} />
        </div>
      </ModalBody>
    </Modal>
  );
}