import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useSWR from 'swr';
import { API_NAMESPACE } from '../../request';
import {
  authGetFetcher,
} from './fetch';

export type Detail<I> = {
  id: I;
  purchaseOrderId: string;
  materialId: string;
  materialName: string;
  customerMaterialId: string;
  customerMaterialName: string;
  quantity: string;
  productUnit: string;
};

type ShipmentPackingPrintPageData = {
  address: string;
  customerId: string;
  customerName: string;
  customerPhone: string;
  factoryId: string;
  id: string;
  invoice: string;
  method: string;
  note: string;
  shipDate: string;
  typeName: string;
  unit: string;
  processType: string;
  contact: string;
  contactPhone: string;
  sales: string;
  details: Detail<number>[];
};

type AccountData = {
  me: {
    name: string;
  }
};

const isShipmentPackingPrintPageData = (
  obj: unknown
): obj is ShipmentPackingPrintPageData => {
  if (obj && typeof obj === 'object') return 'id' in obj;
  return false;
};

const namespace = API_NAMESPACE.HR;
const namespaceIam = API_NAMESPACE.IAM;

export function useShipmentPackingPrintPage() {
  const { shipmentId } = useParams();

  const [data, setData] = useState<ShipmentPackingPrintPageData | undefined>(
    undefined
  );

  const tableData: Detail<string>[] | undefined = data?.details.map(
    (detail, index) => ({
      ...detail,
      id: `${index}`,
    })
  );

  const { data: swrData } = useSWR(() =>
    shipmentId
      ? [
          `/deliveryOrders/get/${shipmentId}/print`,
          {
            namespace,
          },
        ]
      : null
  );

  useEffect(() => {
    if (isShipmentPackingPrintPageData(swrData)) {
      setData(swrData);
    }
  }, [swrData]);

  return {
    data,
    tableData,
  };
}

export function useAccountData() {
  const [data, setData] = useState<AccountData | undefined>(
    undefined
  );

  const { data: swrData } = useSWR('/users/me', authGetFetcher);
  console.log("🚀 ~ useAccountData ~ myaccountData:", swrData)

  useEffect(() => {
    setData(swrData);
  }, [swrData]);

  return {
    data,
  };
}
