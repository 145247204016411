import {
  Button,
  Message,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  SelectValue,
  Table,
  Typography,
} from '@mezzanine-ui/react';
import { useEffect, useState } from 'react';
import {
  SPFormOrderItemInfo,
  SPMatchItem,
  useStrikePriceMatch,
  ISPPurchaseOrderItem,
  ISPEditPageMode,
  SPEditPageMode,
} from '@solar/data';
import Decimal from 'decimal.js';
import { useForm } from 'react-hook-form';
import {
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import styles from './SPItemMatchModal.module.scss';

export interface IMatchFinishParams {
  matchList: SPMatchItem[];
  unbindList: SPMatchItem[];
  unMatchQty: number;
}

interface ISPMatchModalProps {
  open: boolean;
  onFinish?: (d?: IMatchFinishParams) => void;
  itemInfo: SPFormOrderItemInfo | null;
  matchList?: SPMatchItem[];
  unbindList?: SPMatchItem[];
  pageMode: ISPEditPageMode;
}

interface SearchFormData {
  orderType: SelectValue<string>;
  orderId: string;
}



const SPItemMatchModal = ({
  itemInfo,
  open,
  onFinish = () => {
    /** */
  },
  matchList = [],
  unbindList = [],
  pageMode,
}: ISPMatchModalProps) => {
  const searchForm = useForm<SearchFormData>({
    defaultValues: {
      // orderType: { name: 'ZOR1', id: 'ZOR1' },
      // orderId: '2100000181',
    },
  });
  const [availableWeight, setAvailableWeight] = useState(0);
  const [tMatchList, setTMatchList] = useState<SPMatchItem[]>([]);
  const [tUnBindList, setTunBindList] = useState<SPMatchItem[]>([]);
  const {
    searchOrder,
    searchItems,
    isLoading,
    unbindSearchItem,
    bindSearchItem,
    clearSearchItems,
  } = useStrikePriceMatch();

  useEffect(() => {
    if (!open) {
      setAvailableWeight(0);
    }
    if (itemInfo) {
      // console.log(itemInfo);
      setAvailableWeight(Number(itemInfo.unMatchQty));

      // filter matchList
      const filterMatchList = matchList.filter(
        (item) => pageMode === SPEditPageMode.MATCH ? (item.reportPriceItemId === itemInfo.id) && ( item.state === 1) : (item.reportPriceItemId === itemInfo.id)
      );
      const filterUnbindList = unbindList.filter(
        (item) => item.reportPriceItemId === itemInfo.id
      );
      setTMatchList(filterMatchList);
      setTunBindList(filterUnbindList);
    }
  }, [itemInfo, open]);

  const bodyRowClassName = (record: Record<string, unknown>) :string=> record.state === 0 ? 'deleted-row' : '';

  // ZOR1,ZOR6,ZKE
  // 2024/09/06 新增 ZKE1
  const orderTypeOptions: SelectValue<string>[] = [
    { name: 'ZOR1', id: 'ZOR1' },
    { name: 'ZOR6', id: 'ZOR6' },
    { name: 'ZKE', id: 'ZKE' },
    { name: 'ZKE1', id: 'ZKE1' },
  ];

  const onSearch = async (data: SearchFormData) => {
    try {
      await searchOrder(
        {
          orderId: data.orderId || '',
          orderType: data.orderType?.id || '',
          component: itemInfo?.component?.id || '',
        },
        tMatchList,
        tUnBindList
      );
    } catch (e: any) {
      Message.error(e.message);
    }
  };

  const onClickBind = (record: ISPPurchaseOrderItem) => {
    const matchItem: SPMatchItem = {
      key: `${record.orderId}${record.orderItem}`,
      reportPriceId: itemInfo?.reportPriceId,
      reportPriceItemId: itemInfo?.id,
      component: itemInfo?.component?.id || '',
      matchQty: record.unBindQty,
      materialId: record.materialId,
      orderId: record.orderId,
      orderItem: record.orderItem,
    };

    // 如果 tUnBindList 有該比訂單項次則移除
    // setTunBindList(tUnBindList.filter(item => (item.orderId !== record.orderId && item.orderItem !== record.orderItem)));

    // 更新可對應重量
    const newWeight = new Decimal(availableWeight)
      .minus(record.unBindQty)
      .toNumber();
    if (newWeight > 0) {
      setAvailableWeight(newWeight);
      bindSearchItem(record, 0);
    } else {
      matchItem.matchQty = availableWeight;
      bindSearchItem(record, Math.abs(newWeight));
      setAvailableWeight(0);
    }

    setTMatchList([...tMatchList, matchItem]);
  };

  const onClickUnBind = (record: SPMatchItem) => {
    const hasUnbind = tUnBindList.find(
      (item) =>
        item.orderId === record.orderId && item.orderItem === record.orderItem
    );
    if (!hasUnbind && record.id) {
      setTunBindList([...tUnBindList, record]);
    }
    setTMatchList(
      tMatchList.filter(
        (item) =>
          !(
            record.orderId === item.orderId &&
            record.orderItem === item.orderItem
          )
      )
    );
    unbindSearchItem(record);
    const newWeight = new Decimal(availableWeight)
      .plus(record.matchQty)
      .toNumber();
    setAvailableWeight(newWeight);
  };

  const matchColumns = [
    {
      title: '操作',
      render: (record: ISPPurchaseOrderItem) => {
        const hasSelected = Boolean(
          tMatchList.find(
            (item) =>
              item.orderId === record.orderId &&
              item.orderItem === record.orderItem
          )
        );
        const hasVal = Boolean(record.unBindQty);
        const noAvailable = availableWeight <= 0;
        return (
          <Button
            onClick={() => onClickBind(record)}
            variant="contained"
            size="small"
            disabled={!hasVal || hasSelected || noAvailable}
          >
            對應
          </Button>
        );
      },
    },
    {
      title: '項次',
      dataIndex: 'orderItem',
    },
    {
      title: '料號',
      dataIndex: 'materialId',
    },
    {
      title: '訂單重量(G)',
      dataIndex: 'wt',
    },
    {
      title: '未綁定重量(G)',
      dataIndex: 'unBindQty',
    },
  ];

  const unbindColumn = [
    {
      title: '操作',
      render:
        pageMode === SPEditPageMode.MATCH
          ? (record: SPMatchItem) => (
              <Button
                variant="contained"
                size="small"
                onClick={() => onClickUnBind(record)}
              >
                取消對應
              </Button>
            )
          : () => null,
    },
    {
      title: '訂單',
      dataIndex: 'orderId',
      width: 140,
    },
    {
      title: '項次',
      dataIndex: 'orderItem',
      width: 100,
    },
    {
      title: '料號',
      dataIndex: 'materialId',
    },
    {
      title: '對應重量(G)',
      dataIndex: 'matchQty',
    },
  ];

  if (pageMode !== SPEditPageMode.MATCH) {
    unbindColumn.splice(0, 1);
  }

  const onCloseModal = () => {
    onFinish();
    setTMatchList([]);
    setTunBindList([]);
    setAvailableWeight(0);
    clearSearchItems();
  };

  const onConfirm = () => {
    onFinish({
      matchList: tMatchList,
      unbindList: tUnBindList,
      unMatchQty: availableWeight,
    });
    setTMatchList([]);
    setTunBindList([]);
    setAvailableWeight(0);
    clearSearchItems();
  };

  return (
    <Modal
      open={open}
      disableCloseOnBackdropClick
      disableCloseOnEscapeKeyDown
      style={{ width: 1300 }}
      onClose={onFinish}
      hideCloseIcon
    >
      <ModalHeader>對應敲價單</ModalHeader>
      <ModalBody>
        <div style={{ display: 'flex', gap: 12 }}>
          {pageMode === SPEditPageMode.MATCH && (
            <div style={{ flex: 1 }}>
              <Typography variant="h3">搜尋訂單對應</Typography>
              <Typography variant="h5" color="secondary">
                成分: {itemInfo?.component?.name}
              </Typography>
              <Typography variant="h5" color="secondary">
                可對應數量: {availableWeight} G
              </Typography>
              <div
                style={{ borderBottom: '1px solid #ccc', margin: '10px 0' }}
              />
              <FormFieldsWrapper methods={searchForm} onSubmit={onSearch}>
                <SelectField
                  style={{ width: '100%' }}
                  registerName="orderType"
                  options={orderTypeOptions}
                  label="訂單類型"
                />
                <InputField registerName="orderId" label="訂單編號" />
                <Button
                  style={{ marginTop: 12 }}
                  disabled={isLoading}
                  variant="contained"
                  type="submit"
                >
                  搜尋
                </Button>
              </FormFieldsWrapper>
              <Table
                style={{ marginTop: 12 }}
                columns={matchColumns}
                dataSource={searchItems}
                loading={isLoading}
              />
            </div>
          )}

          <div style={{ flex: 1 }} className={styles.spItemMatchTable}>
            <Typography variant="h3">已對應訂單</Typography>
            <Table
              style={{ marginTop: 12 }}
              columns={unbindColumn}
              dataSource={tMatchList}
              bodyRowClassName={bodyRowClassName}
            />
          </div>
        </div>
      </ModalBody>
      {pageMode === SPEditPageMode.MATCH ? (
        <ModalActions
          cancelText="取消"
          onCancel={onCloseModal}
          confirmText="確定"
          onConfirm={onConfirm}
        />
      ) : (
        <ModalActions
          cancelText="關閉"
          onCancel={onCloseModal}
          hideConfirmButton
        />
      )}
    </Modal>
  );
};

export default SPItemMatchModal;
