import { API_NAMESPACE, request } from '@solar/data';

const namespace = API_NAMESPACE.MATERIALS;

export type UpdateWorkOrderPayload = {
  expectedCompletedAt: string;
  designatedInventory: boolean;
  recipeId: string | null;
  items: Array<{
    materialId: string;
    materialSapBatchId: string;
    materialSubBatchId: string;
    materialStockUnitQuantity: string;
    loaderId: string | null;
  }>;
};

export const updateWorkOrder = ({
  payload,
  orderId,
}: {
  payload: UpdateWorkOrderPayload;
  orderId: string;
}): Promise<Response> => {
  return request(`/warehouses/material-request-orders/${orderId}`, {
    method: 'put',
    body: JSON.stringify(payload),
    responseParser: (res) => res,
    namespace,
  });
};
