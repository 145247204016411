// StrikePriceSearch 頁面有兩種模式，一種是搜尋模式，一種是審核模式
export const SPSearchPageMode = {
  SEARCH: 'search',
  APPROVE: 'approve',
};

// StrikePriceUpsert 頁面有三種模式，一種是新增模式，一種是編輯模式，一種是審核模式
export const SPEditPageMode = {
  VIEW: 'view',
  ADD: 'add',
  EDIT: 'edit',
  APPROVE: 'approve',
  MATCH: 'match',
};

export type ISPSearchPageMode =
  typeof SPSearchPageMode[keyof typeof SPSearchPageMode]; // 定義 SPSearchPageMode 型別

export type ISPEditPageMode =
  typeof SPEditPageMode[keyof typeof SPEditPageMode]; // 定義 SPEditPageMode 型別
