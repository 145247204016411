import { Select, SelectValue, Option } from '@mezzanine-ui/react';
import { Space } from 'antd';
import Decimal from 'decimal.js';
import { useState } from 'react';

interface QuantityWithUnitsColumnProps {
  quantity: string;
  materialInputUnit: string;
  materialStockUnit: string;
  stockOverInputUnitRatio: string;
}

export function QuantityWithUnitsColumn({
  quantity,
  materialInputUnit,
  materialStockUnit,
  stockOverInputUnitRatio,
}: QuantityWithUnitsColumnProps) {
  const [selectUnit, setSelectUnit] = useState<SelectValue<
    'stockUnit' | 'inputUnit'
  > | null>({ id: 'stockUnit', name: materialStockUnit });

  return (
    <Space>
      <span>
        {(() => {
          switch (selectUnit?.id) {
            case 'stockUnit':
              return quantity;
            case 'inputUnit':
              return new Decimal(quantity)
                .div(stockOverInputUnitRatio)
                .toString();
            default:
              return '';
          }
        })()}
      </span>
      <Select
        // className={styles.select_unit}
        mode="single"
        value={selectUnit}
        onChange={(value) =>
          setSelectUnit(value as SelectValue<'inputUnit' | 'stockUnit'>)
        }
      >
        <Option value="inputUnit">{materialInputUnit}</Option>
        <Option value="stockUnit">{materialStockUnit}</Option>
      </Select>
    </Space>
  );
}
