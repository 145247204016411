export const data = [
  {
    title: 'ADE010 堯富1200型脫腊爐 B03',
    SAP_WC: 'ADE010',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/KA8kHOlnk/tuo-la-lu-03',
    ],
  },
  {
    title: 'ADE011 堯富1200型脫腊爐 B05',
    SAP_WC: 'ADE011',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/X3OZHdlnk/tuo-la-lu-05',
    ],
  },
  {
    title: 'ADE012 堯富1200型脫腊爐 B06',
    SAP_WC: 'ADE012',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/GSR_B01_M_02_06_R3/tuo-la-lu-06',
    ],
  },
  {
    title: 'ADE013 堯富1200型脫腊爐 B07',
    SAP_WC: 'ADE013',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/Ak_nNdl7z/tuo-la-lu-07',
    ],
  },
  {
    title: 'ADE014 堯富1200型脫腊爐 B08',
    SAP_WC: 'ADE014',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/oXh4NO_nz/tuo-la-lu-08',
    ],
  },
  {
    title: 'ADE015 堯富1200型脫腊爐 B09',
    SAP_WC: 'ADE015',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/3wwINOl7k/tuo-la-lu-09',
    ],
  },
  {
    title: 'ADE016 堯富1200型脫腊爐 B10',
    SAP_WC: 'ADE016',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/xGgSNO_7z/tuo-la-lu-10',
    ],
  },
  {
    title: 'ADE017 堯富1200型脫腊爐 B11',
    SAP_WC: 'ADE017',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/tT5hCDlnz/tuo-la-lu-11',
    ],
  },
  {
    title: 'ADE018 堯富1200型脫腊爐 B12',
    SAP_WC: 'ADE018',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/pYZ8SOl7z/tuo-la-lu-12',
    ],
  },
  {
    title: 'ADE019 堯富1200型脫腊爐 B13',
    SAP_WC: 'ADE019',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/3JawIO_7z/tuo-la-lu-13',
    ],
  },
  {
    title: 'ADE020 堯富1200型脫腊爐 B14',
    SAP_WC: 'ADE020',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/T6NlSd_nk/tuo-la-lu-14',
    ],
  },
  {
    title: 'ADE021 堯富1800型脫腊爐 B15',
    SAP_WC: 'ADE021',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/GSR_B01_M_04_15_R2/tuo-la-lu-15',
    ],
  },
  {
    title: 'ADE022 堯富1800型脫腊爐 B16',
    SAP_WC: 'ADE022',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/VZjDNd_nz/tuo-la-lu-16',
    ],
  },
  {
    title: 'ADE023 堯富1800型脫腊爐 B17',
    SAP_WC: 'ADE023',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/_V-dNdl7k/tuo-la-lu-17',
    ],
  },
  {
    title: 'ADE024 堯富1800型脫腊爐 B18',
    SAP_WC: 'ADE024',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/YOxKHd_nz/tuo-la-lu-18',
    ],
  },
  {
    title: 'ASI006 堯富1200型燒結爐 S05',
    SAP_WC: 'ASI006',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/wkLlT7l7k/shao-jie-lu-05',
    ],
  },
  {
    title: 'ASI007 堯富1200型燒結爐 S06',
    SAP_WC: 'ASI007',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/jIDXTnlnk/shao-jie-lu-06',
    ],
  },
  {
    title: 'ASI008 堯富1200型燒結爐 S07',
    SAP_WC: 'ASI008',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/1j8uon_7z/shao-jie-lu-07',
    ],
  },
  {
    title: 'ASI009 堯富1200型燒結爐 S08',
    SAP_WC: 'ASI009',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/N8Dro7_nz/shao-jie-lu-08',
    ],
  },
  {
    title: 'ASI010 堯富1200型燒結爐 S09',
    SAP_WC: 'ASI010',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/gMr9Tn_nz/shao-jie-lu-09',
    ],
  },
  {
    title: 'ASI012 堯富1200型燒結爐 S11',
    SAP_WC: 'ASI012',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/9mryT7l7k/shao-jie-lu-11',
    ],
  },
  {
    title: 'ASI013 堯富1200型燒結爐 S12',
    SAP_WC: 'ASI013',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/6DRwo7_nz/shao-jie-lu-12',
    ],
  },
  {
    title: 'ASI014 堯富1200型燒結爐 S13',
    SAP_WC: 'ASI014',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/QM2Co7_nz/shao-jie-lu-13',
    ],
  },
  {
    title: 'ASI015 堯富1800型燒結爐 S14',
    SAP_WC: 'ASI015',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/PRGqo7lnz/shao-jie-lu-14',
    ],
  },
  {
    title: 'ASI016 堯富1800型燒結爐 S15',
    SAP_WC: 'ASI016',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/-kJ3o7l7z/shao-jie-lu-15',
    ],
  },
  {
    title: 'ASI017 堯富1800型燒結爐 S16',
    SAP_WC: 'ASI017',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/cFG6o7_nz/shao-jie-lu-16',
    ],
  },
  {
    title: 'ASI018 堯富1800型燒結爐 S17',
    SAP_WC: 'ASI018',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/qk86o7_7z/shao-jie-lu-17',
    ],
  },
  {
    title: 'ASI019 堯富1800型燒結爐 S18',
    SAP_WC: 'ASI019',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/17GRA7_7k/shao-jie-lu-18',
    ],
  },
  {
    title: 'ASI020 堯富1800型燒結爐 S19',
    SAP_WC: 'ASI020',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/cqagAnlnz/shao-jie-lu-19',
    ],
  },
  {
    title: 'ASI021 堯富1800型燒結爐 S20',
    SAP_WC: 'ASI021',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/XNWzAnlnk/shao-jie-lu-20',
    ],
  },
  {
    title: 'ACA004 台車式煆燒爐 LH-3500TA-EB2 1',
    SAP_WC: 'ACA004',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/68F2Tnl7k/xia-shao-lu-01',
    ],
  },
  {
    title: 'ACA005 台車式煆燒爐 LH-3500TA-EB2 2',
    SAP_WC: 'ACA005',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/aWSMo7_nz/xia-shao-lu-02',
    ],
  },
  {
    title: 'ACA006 台車式煆燒爐 LH-3500TA-EB2 3',
    SAP_WC: 'ACA006',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/xtPsh7lnz/xia-shao-lu-03',
    ],
  },
  {
    title: 'ACA007 台車式煆燒爐 LH-3500TA-EB2 5',
    SAP_WC: 'ACA007',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/hTuGT7lnk/xia-shao-lu-05',
    ],
  },
  {
    title: 'ACA008 台車式煆燒爐 LH-3500TA-EB2 6',
    SAP_WC: 'ACA008',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/tdyoo7lnz/xia-shao-lu-06',
    ],
  },
  {
    title: 'ACA009 台車式煆燒爐 LH-3500TA-EB2 7',
    SAP_WC: 'ACA009',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/Fr27o7_nk/xia-shao-lu-07',
    ],
  },
  {
    title: 'ACA010 台車式煆燒爐 LH-3500TA-EB2 8',
    SAP_WC: 'ACA010',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/tgz4onl7z/xia-shao-lu-08',
    ],
  },
  {
    title: 'ACA011 台車式煆燒爐 LH-3500TA-EB2 9',
    SAP_WC: 'ACA011',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/RpXVonl7z/xia-shao-lu-09',
    ],
  },
  {
    title: 'ACA003 台車式煆燒爐 LH-3500TA-EB3 10',
    SAP_WC: 'ACA003',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/hI40T7lnz/xia-shao-lu-10',
    ],
  },
  {
    title: 'ACA002 台車式煆燒爐 LH-3500TA-EB2 11',
    SAP_WC: 'ACA002',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:3794/d/fovST7lnz/xia-shao-lu-11',
    ],
  },
  {
    title: '測試1',
    SAP_WC: 'TEST1',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:5862/d/I1HV4rg7k/solartech-plc-data?orgId=1&from=1685611941312&to=1686216741312&viewPanel=33',
      'https://solar-ek3z83s84.solartech.com.tw:5862/d/I1HV4rg7k/solartech-plc-data?orgId=1&from=1685612011726&to=1686216811726&viewPanel=84',
      'https://solar-ek3z83s84.solartech.com.tw:5862/d/I1HV4rg7k/solartech-plc-data?orgId=1&from=1685612011726&to=1686216811726&viewPanel=24',
    ],
  },
  {
    title: '測試2',
    SAP_WC: 'TEST2',
    grafana_url: [
      'https://solar-ek3z83s84.solartech.com.tw:5862/d/I1HV4rg7k/solartech-plc-data?orgId=1&from=1685611941312&to=1686216741312&viewPanel=33',
    ],
  },
];
