// import useSWR from "swr";

export function useMaterialViewKeyword() {
  // const { data, error } = useSWR('/equipments/categories');
  const data = [
    {id: "ID", name: "料號"},
    {id: "BATCH_ID", name: "批號"},
    {id: "TAG", name: "標籤"},
  ]
  return {
    materialViewKeyword: data,
    // isLoading: !error && !data,
  };
}
