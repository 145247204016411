import { SelectValue } from '@mezzanine-ui/react';
import {
  DeliveryOrderDetail,
  InventoryPurchaseOrderResponse,
} from '@solar/data';
import { tableInputSearchParams } from './constant';
import { InspectStatus } from './InspectStatusTag';

export type OrdersTableProps = {
  id: string;
  expectedCompletedAt: string;
  receivedState: InspectStatus;
};

export type PurchaseOrderTableProps = OrdersTableProps & {
  supplierId: string;
  supplierName: string;
  staffName: string;
  items: ItemProps[];
};

export type ItemProps = {
  id: string;
  specId: string;
  expectedMainMaterialUnitQuantity: string;
  expectedSecondaryMaterialUnitQuantity: string;
  receivedMainMaterialUnitQuantity: string;
  receivedSecondaryMaterialUnitQuantity: string;
  returnedMainMaterialUnitQuantity: string;
  returnedSecondaryMaterialUnitQuantity: string;
  materialDescription: string;
  oldMaterialId: string;
  mainUnitCode: string;
  secondaryUnitCode: string | null;
  materialId: string;
};

export type CustomerOrderTableProps = OrdersTableProps & {
  customerId: string;
  customerName: string;
  processName: string;
  orderTypeName: string;
  employeeName: string | null;
  packedState: string;
};

export type MaterialLabelParam = {
  endpoint: LabelStickerEndpoint.MaterialLabel;
  batchIds: Array<string>;
};

export type LoaderLabelParam = {
  endpoint: LabelStickerEndpoint.LoaderLabel;
  loaderIds: Array<string>;
};
export type DownloadBarcodeStickerProps = MaterialLabelParam | LoaderLabelParam;

export enum LabelStickerEndpoint {
  MaterialLabel = '/warehouses/material-label',
  LoaderLabel = '/loaders/loader-label',
}

export enum Modals {
  ReceiveAction = 'ReceiveAction',
  RejectAction = 'RejectAction',
  Cancel = 'Cancel',
  QUALITY_CONTROL = 'quality-control',
}

export type ReceiveActionModalPassingData =
  | (Pick<
      InventoryPurchaseOrderResponse['items'][number],
      | 'specId'
      | 'materialId'
      | 'materialDescription'
      | 'mainUnitName'
      | 'secondaryUnitName'
      | 'materialStockUnit'
    > & {
      /** @description for 出貨單 */
      quantityUnit?: string;
    })
  | null;

export interface ReceiveActionModalOpenFunc {
  (item: ReceiveActionModalPassingData): void;
}

export interface ProductionDeliveryOrderReceiptModalOpenFunc {
  (item: DeliveryOrderDetail['specs'][number]['materialItems']): void;
}

export interface CancelModalOpenFunc {
  (cancelFunc: () => void): void;
}

export type TableInputSearch = typeof tableInputSearchParams[number];

export interface HandleTableDataILikeSearchFunc {
  dataSource: any; // 待補：等 dataSource 形狀收斂後
  fieldParams:
    | {
        materialIdKey: typeof tableInputSearchParams[0];
        materialDescriptionKey: typeof tableInputSearchParams[1];
      }
    | {
        materialIdKey: typeof tableInputSearchParams[2];
        materialDescriptionKey: typeof tableInputSearchParams[3];
      };
  tableDataSearchTerms: Record<
    | 'receivableMaterialIdSearch'
    | 'receivableMaterialDescriptionSearch'
    | 'receivedMaterialIdSearch'
    | 'receivedMaterialDescriptionSearch',
    string
  >;
}

export type CustomerSupplyForm = {
  orderId: string | null;
  customerName: string | null;
  employeeName: string | null;
  createdDateRange: [string, string] | null;
  packedStates: SelectValue[] | null;
  sortType: SelectValue | null;
  types: SelectValue[] | null;
};
