import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import styles from './routing-detail-custom-form-creator.module.scss';
import { Button, Icon, Message, SelectValue, Typography } from '@mezzanine-ui/react';
import { CustomFormItemType, CustomFormTypes, DetailFormCreatingOptions } from './typings';
import { CheckboxField, CheckboxGroupField, FormFieldsWrapper, InputField, RadioGroupField, SelectField } from '@mezzanine-ui/react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { SettingsIcon, TrashIcon } from '@mezzanine-ui/icons';
import { isNil } from 'lodash';

const OPTIONS = [{
  id: CustomFormItemType.INPUT,
  name: '輸入格',
}, {
  id: CustomFormItemType.SELECT,
  name: '下拉選單',
}, {
  id: CustomFormItemType.RADIO,
  name: '單選題',
}, {
  id: CustomFormItemType.CHECKBOX,
  name: '多選題',
}] as SelectValue[];

export function RoutingDetailCustomFormCreator({
  fieldName,
  title,
} = {
  fieldName: 'customForms',
  title: '機台參數',
}) {
  const methods = useFormContext();
  const { append: appendCustomForm, update: updateCustomForm, remove: removeCustomForm } = useFieldArray({
    control: methods.control,
    name: fieldName,
  });

  const customForms = methods.watch(fieldName) as CustomFormTypes[];

  const creatingMethods = useForm<DetailFormCreatingOptions>({
    defaultValues: {
      fieldType: OPTIONS[0],
      required: true,
      label: '',
      options: [{
        id: Math.random().toString(),
        name: '',
      }],
    },
  });

  const creatingFieldType = creatingMethods.watch('fieldType');

  const { fields, append, remove } = useFieldArray({
    control: creatingMethods.control,
    name: 'options',
  });

  const resetForm = useCallback(() => {
    creatingMethods.reset({
      fieldType: OPTIONS[0],
      required: true,
      label: '',
      options: [{
        id: Math.random().toString(),
        name: '',
      }],
    });

    setEditingIndex(null);
  }, [creatingMethods]);

  const addFormItem = useCallback(() => {
    const { fieldType, required, label, placeholder, number, options } = creatingMethods.getValues();

    if (!fieldType) return;

    switch (fieldType.id as CustomFormItemType) {
      case CustomFormItemType.INPUT:
        appendCustomForm({
          type: CustomFormItemType.INPUT,
          label,
          required,
          placeholder,
          number,
        });
        break;

      case CustomFormItemType.CHECKBOX:
      case CustomFormItemType.RADIO:
      case CustomFormItemType.SELECT:
        if (!options?.filter(option => option.name).length) {
          Message.error('請輸入至少一個選項');

          return;
        }

        appendCustomForm({
          type: fieldType.id,
          label,
          required,
          options,
        });
        break;
    }

    resetForm();
  }, [resetForm, creatingMethods, appendCustomForm]);

  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const updateFormItem = useCallback(() => {
    const { fieldType, required, label, placeholder, number, options } = creatingMethods.getValues();

    if (!fieldType || isNil(editingIndex)) return;

    switch (fieldType.id as CustomFormItemType) {
      case CustomFormItemType.INPUT:
        updateCustomForm(editingIndex, {
          type: CustomFormItemType.INPUT,
          label,
          required,
          placeholder,
          number,
        });
        break;

      case CustomFormItemType.CHECKBOX:
      case CustomFormItemType.RADIO:
      case CustomFormItemType.SELECT:
        if (!options?.filter(option => option.name).length) {
          Message.error('請輸入至少一個選項');

          return;
        }

        updateCustomForm(editingIndex, {
          type: fieldType.id,
          label,
          required,
          options,
        });
        break;
    }

    resetForm();
  }, [resetForm, creatingMethods, editingIndex, updateCustomForm]);

  useEffect(() => {
    if (editingIndex === null) return;

    const customForm = customForms[editingIndex];

    if (!customForm) return;

    switch (customForm.type) {
      case CustomFormItemType.INPUT:
        creatingMethods.reset({
          fieldType: OPTIONS[0],
          required: customForm.required,
          label: customForm.label,
          placeholder: customForm.placeholder,
          number: customForm.number,
        });
        break;

      case CustomFormItemType.CHECKBOX:
      case CustomFormItemType.RADIO:
      case CustomFormItemType.SELECT:
        creatingMethods.reset({
          fieldType: OPTIONS.find(option => option.id === customForm.type),
          required: customForm.required,
          label: customForm.label,
          options: customForm.options,
        });
        break;
    }
  }, [editingIndex, customForms, creatingMethods]);

  return (
    <div className={styles.wrapper}>
      <FormFieldsWrapper methods={creatingMethods} className={styles.controlPlane}>
        <Typography variant="h4">
          新增
          {title}
        </Typography>
        <header className={styles.typeSelector}>
          <SelectField
            options={OPTIONS}
            registerName='fieldType' />
          <CheckboxField
            label="必填"
            registerName="required" />
        </header>
        <div className={styles.fieldOptionsWrapper}>
          <InputField
            label="標題"
            required
            registerName="label" />
          {creatingFieldType?.id === CustomFormItemType.INPUT ? (
            <>
              <InputField
                registerName="placeholder"
                label="提示文字" />
              <CheckboxField
                registerName="number"
                label="僅允許填入數字" />
            </>
          ) : null}
          {~[
            CustomFormItemType.CHECKBOX,
            CustomFormItemType.RADIO,
            CustomFormItemType.SELECT,
          ].indexOf(creatingFieldType?.id as CustomFormItemType) ? (
            <>
              {fields.map((field, index) => (
                <div className={styles.option} key={field.id}>
                  <InputField
                    label={`選項 ${index + 1}`}
                    registerName={`options.${index}.name`} />
                  {fields.length === 1 ? null : (
                    <Button
                      onClick={() => remove(index)}
                      type="button"
                      color="secondary"
                      prefix={<Icon icon={TrashIcon} />} />
                  )}
                </div>
              ))}
              <Button type="button" onClick={() => append({ id: Math.random().toString(), name: '' })}>
                新增選項
              </Button>
            </>
          ) : null}
          <footer className={styles.footer}>
            {isNil(editingIndex) ? null : (
              <Button
                onClick={resetForm}
                variant="outlined"
                type="button">
                取消
              </Button>
            )}
            <Button
              disabled={!creatingFieldType}
              variant="contained"
              onClick={isNil(editingIndex) ? addFormItem : updateFormItem}
              type="button">
              {isNil(editingIndex) ? '新增項目' : '更新項目'}
            </Button>
          </footer>
        </div>
      </FormFieldsWrapper>
      {customForms?.length ? (
        <div className={styles.formItems}>
          {customForms.map((formItem, index) => {
            switch (formItem.type) {
              case CustomFormItemType.INPUT:
                return (
                  <div className={styles.formField}>
                    <InputField
                      label={formItem.label}
                      required={formItem.required}
                      placeholder={formItem.placeholder || '請輸入'}
                      valueAsNumber={formItem.number}
                      registerName={`mock${index}`} />
                    <Button
                      type="button"
                      onClick={() => setEditingIndex(index)}
                      prefix={<Icon icon={SettingsIcon} />}
                      color="secondary" />
                    <Button
                      onClick={() => removeCustomForm(index)}
                      type="button"
                      color="secondary"
                      prefix={<Icon icon={TrashIcon} />} />
                  </div>
                );

              case CustomFormItemType.CHECKBOX:
                return (
                  <div className={styles.formField}>
                    <CheckboxGroupField
                      label={formItem.label}
                      orientation="horizontal"
                      required={formItem.required}
                      options={(formItem.options ?? [])
                        .map(option => ({
                          label: option.name,
                          value: option.id,
                        }))}
                      registerName={`mock${index}`} />
                    <Button
                      type="button"
                      onClick={() => setEditingIndex(index)}
                      prefix={<Icon icon={SettingsIcon} />}
                      color="secondary" />
                    <Button
                      onClick={() => removeCustomForm(index)}
                      type="button"
                      color="secondary"
                      prefix={<Icon icon={TrashIcon} />} />
                  </div>
                );

              case CustomFormItemType.RADIO:
                return (
                  <div className={styles.formField}>
                    <RadioGroupField
                      label={formItem.label}
                      required={formItem.required}
                      options={(formItem.options ?? [])
                        .map(option => ({
                          label: option.name,
                          value: option.id,
                        }))}
                      registerName={`mock${index}`} />
                    <Button
                      type="button"
                      onClick={() => setEditingIndex(index)}
                      prefix={<Icon icon={SettingsIcon} />}
                      color="secondary" />
                    <Button
                      onClick={() => removeCustomForm(index)}
                      type="button"
                      color="secondary"
                      prefix={<Icon icon={TrashIcon} />} />
                  </div>
                );

              case CustomFormItemType.SELECT:
                return (
                  <div className={styles.formField}>
                    <SelectField
                      label={formItem.label}
                      required={formItem.required}
                      options={(formItem.options ?? [])}
                      registerName={`mock${index}`} />
                    <Button
                      type="button"
                      onClick={() => setEditingIndex(index)}
                      prefix={<Icon icon={SettingsIcon} />}
                      color="secondary" />
                    <Button
                      onClick={() => removeCustomForm(index)}
                      type="button"
                      color="secondary"
                      prefix={<Icon icon={TrashIcon} />} />
                  </div>
                );

              default:
                return null;
            }
          })}
        </div>
      ) : (
        <Typography className={styles.placeholder} variant="h3">
          尚未建立工作中心
          {title}
        </Typography>
      )}
    </div>
  );
}
