import { Button, Input } from '@mezzanine-ui/react';
import {
  DateRangePickerField,
  FormFieldsWrapper,
  SelectField,
} from '@mezzanine-ui/react-hook-form';

import { UseFormReturn } from 'react-hook-form';
import sharedStyles from '../shared-styles/index.module.scss';
import { useState } from 'react';
import { SOURCE_TYPE_OPTIONS } from './material-collect-enum';
import { INSPECT_STATUS_OPTIONS } from '../IncomingInspectOperationPage/InspectStatusTag';
import classes from './material-collect-page-filter.module.scss';

type EquipmentListFilterProps = {
  methods: UseFormReturn;
};

export function MaterialCollectPageFilter({
  methods,
}: EquipmentListFilterProps) {
  const [keyword, setKeyword] = useState<string>('');

  return (
    <FormFieldsWrapper
      methods={methods}
      className={sharedStyles.filter_form_wrapper}
    >
      <div className={classes.host}>
        <div className={sharedStyles['input-search']}>
          <Input
            value={keyword}
            fullWidth
            onChange={(event) => setKeyword(event.target.value)}
            placeholder="請輸入關鍵字搜尋"
          />
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              methods.setValue('keyword', keyword);
            }}
          >
            確認
          </Button>
        </div>
        <SelectField
          label="來源："
          mode="multiple"
          clearable
          registerName="categories"
          options={SOURCE_TYPE_OPTIONS ?? []}
        />
        <SelectField
          label="狀態："
          mode="multiple"
          clearable
          registerName="status"
          options={INSPECT_STATUS_OPTIONS ?? []}
        />
        <DateRangePickerField
          fullWidth
          label="日期區間："
          registerName="installedAt"
        />
      </div>
    </FormFieldsWrapper>
  );
}
