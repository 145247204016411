import {
  DatePickerField,
  FormFieldsWrapper,
  InputField,
} from '@mezzanine-ui/react-hook-form';
import { useForm } from 'react-hook-form';
import { MznPaginationTable } from '../../Templates/PaginationTable/MznPaginationTable';
import { Row, Space } from 'antd';
import { Button } from '@mezzanine-ui/react';

export function OutsourcingOrderTabPaneCheckIn() {
  const methods = useForm();

  return (
    <FormFieldsWrapper methods={methods}>
      <Space direction="vertical" size="small">
        <InputField registerName="workOrderId" label="工單單號" />
        <InputField registerName="requestOrderId" label="發料單號" />
        <DatePickerField registerName="checkInTime" label="入站時間" />
        <MznPaginationTable
          columns={[
            {
              title: '虛擬線邊倉ID',
              dataIndex: 'virtualLineId',
            },
            {
              title: '品名',
              dataIndex: 'materialDescription',
            },
            {
              title: '料號',
              dataIndex: 'materialId',
            },
            {
              title: '批號',
              dataIndex: 'materialBatchId',
            },
            {
              title: '儲存地點',
              dataIndex: 'location',
            },
            {
              title: '數量',
              dataIndex: 'materialQuantity',
            },
            {
              title: '單位',
              dataIndex: 'materialUnit',
            },
          ]}
          dataSource={[]}
          fetchData={console.log}
          pageInfo={{}}
        />
        <Row justify="end">
          <Button type="button" variant="contained">
            Check In
          </Button>
        </Row>
      </Space>
    </FormFieldsWrapper>
  );
}
