import { DateType } from '@mezzanine-ui/core/calendar';
import { PlusIcon } from '@mezzanine-ui/icons';
import { Button, DatePicker, Icon } from '@mezzanine-ui/react';
import { InputTagsModeField } from '@mezzanine-ui/react-hook-form';
import moment from 'moment';
import { FC, useState } from 'react';
import { FieldValues, UseFormReturn, useWatch } from 'react-hook-form';
import styles from './CalendarInputTagsTemplate.module.scss';

interface CalendarInputTagsTemplateProps {
  methods: UseFormReturn<FieldValues, object>;
}

export const CalendarInputTagsTemplate: FC<CalendarInputTagsTemplateProps> = ({
  methods,
}) => {
  const nowDate = new Date();
  const [displayCalendar, setDisplayCalendar] = useState(false);

  const toggleCalendar = () => {
    setDisplayCalendar(!displayCalendar);
  };

  const selectMonth = (date?: DateType) => {
    if (!date) return;
    const tags: string[] | undefined = methods.getValues('input-tags-field');

    const formatDate = moment(date).format('YYYY-MM');

    if (!tags) {
      methods.setValue('input-tags-field', [formatDate]);
    } else {
      if (tags.indexOf(formatDate) === -1) tags.push(formatDate);
      methods.setValue('input-tags-field', tags);
    }
  };

  const inputTags = useWatch({
    control: methods.control,
    name: 'input-tags-field',
  });

  return (
    <div className={styles.wrapper}>
      <InputTagsModeField
        inputClassName={styles.input}
        maxTagsLength={10}
        registerName="input-tags-field"
        size="medium"
        placeholder={inputTags ? '' : 'yyyy/mm'}
        readOnly
        key={(inputTags ?? []).join('/')}
        value={inputTags ?? []}
      />
      <div className={styles.calendarWrapper}>
        <DatePicker
          referenceDate={moment(nowDate).format('YYYY')}
          mode="month"
          onChange={selectMonth}
        />
        <Button
          size="medium"
          variant="contained"
          className={styles.calendarButton}
          type="button"
          onClick={toggleCalendar}
        >
          <Icon icon={PlusIcon} />
        </Button>
      </div>
    </div>
  );
};
