import { useFormContext } from 'react-hook-form';
import { ReactNode } from 'react';
import { useEffect } from 'react';
import classes from './filter-scaffold.module.scss';
import { cx } from '@mezzanine-ui/react';

type FilterScaffoldProps = {
  children: ReactNode;
  className?: string;
  mutateParams?: (payload: any) => void;
  fetchAsParamsMutated?: boolean; // 新增參數(需求改為按下搜尋後才 fetchData)
};
/**
 *
 * @description
 * 主要做兩件事
 * 1. label position styling
 * 2. filter 欄位值變更，重新 fetch data
 *
 * @sample
 * <FormFieldsWrapper methods={filterMethods}>
 *    <FilterScaffold mutateParams={refetchInventoryByPurchaseOrders}>
 *       <Filter />
 *     </FilterScaffold>
 *  </FormFieldsWrapper>
 *
 */

export function FilterScaffold({
  children,
  className,
  mutateParams,
  fetchAsParamsMutated = true,
}: FilterScaffoldProps) {
  const methods = useFormContext();

  useEffect(() => {
    const subscription = methods.watch(() => {
      if (fetchAsParamsMutated) mutateParams?.({ nextPage: 1 });
    });
    return () => subscription.unsubscribe();
  }, [fetchAsParamsMutated, methods, methods.watch, mutateParams]);

  return <div className={cx(classes.host, className)}>{children}</div>;
}
