import { Tag } from "@mezzanine-ui/react";
import styles from './tag.module.scss';
import { RepairRequestStatus } from "@solar/data";

type RepairRequestStatusTagProps = {
  status?: RepairRequestStatus,
  suffix?: string,
};

export function getRepairRequestStatusColor(status: RepairRequestStatus) {
  switch (status) {
    case RepairRequestStatus.INITED:
      return '#AF1C12';
    case RepairRequestStatus.WORKING_IN_PROGRESS:
      return '#F1842B';
    case RepairRequestStatus.RESOLVED:
      return '#0C5D19';
    default:
      return '#454545';
  }
}

export function RepairRequestStatusTag({ status, suffix }: RepairRequestStatusTagProps) {
  switch (status) {
    case RepairRequestStatus.INITED:
      return <Tag className={styles.tag} style={{ color: '#AF1C12', backgroundColor: 'rgba(219, 98, 88, 0.15)', width: '80px', display: 'inline-block' }}>待處理{suffix ?? ''}</Tag>;
    case RepairRequestStatus.WORKING_IN_PROGRESS:
      return <Tag className={styles.tag} style={{ color: '#F1842B', backgroundColor: 'rgba(253, 217, 72, 0.15)', width: '80px', display: 'inline-block' }}>處理中{suffix ?? ''}</Tag>;
    case RepairRequestStatus.WAITING_SUPPLIER:
      return <Tag className={styles.tag} style={{ color: '#454545', backgroundColor: 'rgba(143, 143, 143, 0.15)', width: '80px', display: 'inline-block' }}>等待廠商{suffix ?? ''}</Tag>;
    case RepairRequestStatus.RESOLVED:
      return <Tag className={styles.tag} style={{ color: '#0C5D19', backgroundColor: 'rgba(66, 174, 74, 0.15)', width: '80px', display: 'inline-block' }}>完成{suffix ?? ''}</Tag>;
    case RepairRequestStatus.REJECTED:
      return <Tag className={styles.tag}>已拒絕{suffix ?? ''}</Tag>;
    case RepairRequestStatus.CANCELLED:
      return <Tag className={styles.tag}>已取消{suffix ?? ''}</Tag>;
    default:
      return <Tag className={styles.tag} style={{ color: '#454545', backgroundColor: 'rgba(143, 143, 143, 0.15)', width: '80px', display: 'inline-block' }}>送出申請{suffix ?? ''}</Tag>;
  }
}

export function RepairRequestStatusText({ status }: RepairRequestStatusTagProps) {
  switch (status) {
    case RepairRequestStatus.INITED:
      return <span style={{ color: '#AF1C12', width: '80px', display: 'inline-block' }}>待處理</span>;
    case RepairRequestStatus.WORKING_IN_PROGRESS:
      return <span style={{ color: '#F1842B', width: '80px', display: 'inline-block' }}>處理中</span>;
    case RepairRequestStatus.WAITING_SUPPLIER:
      return <span style={{ color: '#454545', width: '80px', display: 'inline-block' }}>等待廠商</span>;
    case RepairRequestStatus.RESOLVED:
      return <span style={{ color: '#0C5D19', width: '80px', display: 'inline-block' }}>完成</span>;
    case RepairRequestStatus.REJECTED:
      return <span style={{ color: '#454545', width: '80px', display: 'inline-block' }}>已拒絕</span>;
    case RepairRequestStatus.CANCELLED:
      return <span style={{ color: '#454545', width: '80px', display: 'inline-block' }}>已取消</span>;
    default:
      return <span style={{ color: '#454545', width: '80px', display: 'inline-block' }}>送出申請</span>;
  }
}