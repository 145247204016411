import {
  Button,
  cx,
  Icon,
  MenuDivider,
  Modal,
  ModalActions,
  ModalBody,
  SelectValue,
  Table,
  Typography,
} from '@mezzanine-ui/react';
import {
  API_NAMESPACE,
  MiscPurchaseOrderOuterRecord,
  useLoaders,
  useMiscPurchaseOrders,
} from '@solar/data';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useForm, UseFormReturn } from 'react-hook-form';

import { TableColumn, TableExpandable } from '@mezzanine-ui/core/table';
import { CheckBoldIcon, ChevronDownIcon, TrashIcon } from '@mezzanine-ui/icons';
import {
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  InputContainerWithUnit,
  LocationSelectorGroupModal,
  LocationSelectorProvider,
  LOCATION_SELECTOR,
  ModalGroup,
  mznTableRowExpandHelper,
  PageLayout,
  RowSection,
  useLocationSelectorController,
  useModalGroupController,
  useTargetModal,
} from '@solar/templates';
import moment from 'moment';
import { WarehouseAndVehicleFilter } from '../IncomingInspectOperationPage/Modals/WarehouseAndVehicleFilter';
import { ModalLayout } from '../ModalLayout/ModalLayout';
import { BarcodeScanInput } from './BarcodeScanInput';
import classes from './shift-page.module.scss';

const namespace = API_NAMESPACE.MATERIALS;
type TableProps = {
  id: string;
  loaderId: string | null;
  batchSerial: string;
  quantity: number | null;
  mainUnit: string;
  weight: number;
  secondaryUnit: string;
  bindMaterials: Array<{
    id: string;
    materialId: string;
    batchSerial: string;
  }> | null;
};

type NonPLanInputs = {
  material: SelectValue | null;
  mainMaterialUnitsObj?: MainMaterialUnitsObj[];
  warehouse: SelectValue | null;
  loader: SelectValue | null;
};

type MainMaterialUnitsObj = {
  mainMaterialUnitQuantity: number | null;
  notes: string | null;
  receiveWeight: number | null;
  stickerNum: number | null;
};

export function GeneratedStorageIn() {
  const [materialBarCode, setMaterialBarCode] = useState('');
  const [isEnterHit, setEnterHit] = useState(false);

  const modalGroupController = useModalGroupController([
    { name: LOCATION_SELECTOR },
    { name: 'BIND' },
    { name: 'NON_PLAN' },
  ]);

  const bindModalOpen = useCallback(
    (item: { rowIndex: number }) => {
      modalGroupController.openModal('BIND', {
        ...item,
      });
    },
    [modalGroupController]
  );

  const nonPlanModalOpen = useCallback(() => {
    modalGroupController.openModal('NON_PLAN', null);
  }, [modalGroupController]);

  const methods = useForm<{ table: TableProps[] }>();
  const nonPlanMethods = useForm<NonPLanInputs>({
    defaultValues: {
      mainMaterialUnitsObj: [
        { mainMaterialUnitQuantity: null, receiveWeight: null },
      ],
    },
  });

  const columns = useMemo<TableColumn<{ id: string }>[]>(() => {
    return [
      {
        title: '操作',
        align: 'center',
        width: 200,
        render: (_, index) => {
          return (
            <div style={{ display: 'flex', gap: 'var(--mzn-spacing-1)' }}>
              <Button
                type="button"
                onClick={() => console.log()}
                variant="outlined"
                disabled={
                  !(
                    methods.watch(`table.${index}.loaderId`) &&
                    methods.watch(`table.${index}.quantity`) &&
                    methods.watch(`table.${index}.weight`)
                  )
                }
              >
                入庫
              </Button>
              <Button
                type="button"
                onClick={() => bindModalOpen({ rowIndex: index })}
                variant="outlined"
                suffix={
                  <Icon
                    icon={CheckBoldIcon}
                    color={
                      methods.watch(`table.${index}.bindMaterials`)?.length
                        ? 'success'
                        : 'disabled'
                    }
                    size={30}
                  />
                }
              >
                綁定原料
              </Button>
            </div>
          );
        },
      },
      {
        title: '載具',
        render: (_, index) => {
          return <InputField registerName={`table.${index}.loaderId`} />;
        },
      },
      {
        title: '流水批號',
        dataIndex: 'batchSerial',
      },
      {
        title: '數量',
        render: (_, index) => {
          return (
            <InputField
              registerName={`table.${index}.quantity`}
              type="number"
            />
          );
        },
      },
      {
        title: '單位',
        dataIndex: 'mainUnit',
      },
      {
        title: '實際重量',
        render: (_, index) => {
          return (
            <InputField registerName={`table.${index}.weight`} type="number" />
          );
        },
      },
      {
        title: '單位',
        dataIndex: 'secondaryUnit',
      },
      {
        title: '放入儲位',
        render: (_, index) => {
          return (
            <div style={{ display: 'flex', gap: 'var(--mzn-spacing-1)' }}>
              <SelectField
                registerName={`table.${index}.stack`}
                options={[{ id: 'test', name: '工作中心的線邊倉' }]}
                defaultValue={{ id: 'test', name: '工作中心的線邊倉' }}
              />
              <Button
                type="button"
                onClick={() =>
                  modalGroupController.openModal(LOCATION_SELECTOR, null)
                }
              >
                地圖選取
              </Button>
            </div>
          );
        },
      },
    ];
  }, [modalGroupController, methods]);

  const mockSource = [{ id: '1' }, { id: '2' }];

  return (
    <PageLayout title={<Typography variant="h1">生產入庫</Typography>}>
      <div className={classes['input-fields-wrapper']}>
        <BarcodeScanInput label="工單號碼" disableSearchBtn />
        <BarcodeScanInput
          disableSearchBtn
          inputValue={materialBarCode}
          setInputValue={setMaterialBarCode}
          isEnterHit={isEnterHit}
          setEnterHit={setEnterHit}
          label={'物料條碼'}
          otherOnKeyDownAction={async () => {}}
        />
      </div>
      <MenuDivider />
      <Typography variant="h2">料號：BR111FF01TI1001AT171SBA00400</Typography>
      <Typography variant="h2">
        品名：Pipe,1014,Ti,100at%,2615B-02,OD133xID125
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h2">預期產出數量：3</Typography>
        <Button
          variant="outlined"
          type="button"
          onClick={() => nonPlanModalOpen()}
        >
          ＋非計畫產出
        </Button>
      </div>
      <FormFieldsWrapper methods={methods}>
        <div style={{ width: '100%' }}>
          <Table
            scroll={{ x: 2500 }}
            columns={columns}
            dataSource={mockSource}
          />
        </div>
      </FormFieldsWrapper>
      <MenuDivider />
      <StorageInRecords />

      <ModalGroup {...modalGroupController}>
        <LocationSelectorGroupModal
          closeModal={() => {
            nonPlanModalOpen();
          }}
          onSubmit={() => {
            nonPlanModalOpen();
          }}
        />
        <BindMaterialModal methods={methods} />
        <NonPlanModal methods={nonPlanMethods} />
      </ModalGroup>
    </PageLayout>
  );
}

const BindMaterialModal = ({
  methods,
}: {
  methods: UseFormReturn<{ table: TableProps[] }>;
}) => {
  const modalController = useTargetModal<{
    rowIndex: number;
  }>('BIND');
  const { open, closeModal, data } = modalController;

  const handleModalClose = useCallback(() => {
    closeModal();
  }, []);

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const mockTableData = useMemo(
    () => [
      { id: '1', materialId: 'test1', batchSerial: 'abc-1' },
      { id: '2', materialId: 'test2', batchSerial: 'abc-2' },
    ],
    []
  );

  useEffect(() => {
    if (data?.rowIndex !== undefined) {
      const selected =
        methods
          .getValues(`table.${data.rowIndex}.bindMaterials`)
          ?.map((row) => row?.id) ?? [];

      setSelectedRowKeys(selected);
    }
  }, [data, data?.rowIndex]);

  return (
    <Modal open={open} onClose={handleModalClose}>
      <br />
      <br />
      <ModalBody>
        <Typography variant="h1">綁定原料</Typography>
        <Table
          dataSource={mockTableData}
          columns={[
            {
              title: '料號',
              dataIndex: 'materialId',
            },
            {
              title: '批號流水',
              dataIndex: 'batchSerial',
            },
          ]}
          rowSelection={{
            selectedRowKey: selectedRowKeys,
            onChange: (keys) => {
              setSelectedRowKeys(keys);
            },
          }}
        />
      </ModalBody>
      <ModalActions
        cancelText="取消"
        confirmText="確認"
        confirmButtonProps={{ type: 'button' }}
        onCancel={() => {
          handleModalClose();
        }}
        onConfirm={() => {
          const selectedRows =
            mockTableData.filter((row) => selectedRowKeys.includes(row.id)) ??
            [];

          if (data) {
            methods.setValue(
              `table.${data.rowIndex}.bindMaterials`,
              selectedRows
            );
          }
          handleModalClose();
        }}
      />
    </Modal>
  );
};

const NonPlanModal = ({
  methods,
}: {
  methods: UseFormReturn<NonPLanInputs>;
}) => {
  const modalController = useTargetModal<{
    rowIndex: number;
  }>('NON_PLAN');
  const locationController = useTargetModal(LOCATION_SELECTOR);
  const { open, closeModal, data } = modalController;

  const handleModalClose = useCallback(() => {
    closeModal();
  }, []);

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const mockTableData = useMemo(
    () => [
      { id: '1', materialId: 'test1', batchSerial: 'abc-1' },
      { id: '2', materialId: 'test2', batchSerial: 'abc-2' },
    ],
    []
  );

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'mainMaterialUnitsObj',
  });

  const { loaders, mutateLoaderParams } = useLoaders();
  const controller = useLocationSelectorController({
    onSelectedIdsChange(selectedIds) {
      const shelfId =
        selectedIds?.shelfId ?? selectedIds?.stackId ?? selectedIds?.zoneId;
      if (shelfId) {
        mutateLoaderParams({
          shelfIds: [shelfId],
          whenNotFoundAutoCreate: true,
        });
      }
    },
  });

  return (
    <ModalLayout
      style={{ width: '1500px' }}
      modalHeader="新增非計畫產出"
      open={open}
      closeModal={closeModal}
      modalFooter={
        <ModalActions
          cancelText="取消"
          confirmText="確認"
          confirmButtonProps={{ type: 'button' }}
          onCancel={() => {
            handleModalClose();
          }}
          onConfirm={() => {
            const selectedRows =
              mockTableData.filter((row) => selectedRowKeys.includes(row.id)) ??
              [];

            handleModalClose();
          }}
        />
      }
    >
      <FormFieldsWrapper methods={methods}>
        <RowSection label="料號：">
          <SelectField registerName="material" />
        </RowSection>
        <RowSection label="品名：">
          <p>Pipe,1014,Ti,100at%,</p>
        </RowSection>
        <LocationSelectorProvider controller={controller}>
          <WarehouseAndVehicleFilter
            enableLoaderSelector
            enableDirectlyLoaderSearch={false}
            locationLabel="存放儲位"
            loaders={loaders ?? []}
            openLocationSelectorModal={() => locationController.openModal(null)}
          />
        </LocationSelectorProvider>
        <div>
          {fields.map((row, index) => {
            const registerNameOfNotes =
              `mainMaterialUnitsObj.${index}.stickerNotes` as const;
            const registerNameOfQuantity =
              `mainMaterialUnitsObj.${index}.mainMaterialUnitQuantity` as const;
            const registerNameOfWeight =
              `mainMaterialUnitsObj.${index}.receiveWeight` as const;
            const registerNameOfStickerNum =
              `mainMaterialUnitsObj.${index}.stickerNum` as const;

            return (
              <div
                style={{ display: 'flex', gap: '20px', marginTop: '12px' }}
                key={row.id}
              >
                <InputContainerWithUnit
                  prefixAdj="收料數量"
                  suffixUnit="片"
                  htmlFor={registerNameOfQuantity}
                >
                  <InputField
                    id={registerNameOfQuantity}
                    registerName={registerNameOfQuantity}
                    type="number"
                  />
                </InputContainerWithUnit>
                <InputContainerWithUnit
                  prefixAdj="貼紙數量"
                  htmlFor={registerNameOfStickerNum}
                >
                  <InputField
                    id={registerNameOfStickerNum}
                    registerName={registerNameOfStickerNum}
                    type="number"
                  />
                </InputContainerWithUnit>
                <InputContainerWithUnit
                  prefixAdj="實際重量"
                  suffixUnit="g"
                  htmlFor={registerNameOfWeight}
                >
                  <InputField
                    id={registerNameOfWeight}
                    registerName={registerNameOfWeight}
                    type="number"
                  />
                </InputContainerWithUnit>
                <InputContainerWithUnit
                  prefixAdj="備註"
                  htmlFor={registerNameOfNotes}
                >
                  <InputField
                    placeholder="針對此流水填備註"
                    id={registerNameOfNotes}
                    registerName={registerNameOfNotes}
                  />
                </InputContainerWithUnit>
                {index !== 0 && (
                  <Button
                    type="button"
                    prefix={<Icon icon={TrashIcon} />}
                    onClick={() => remove(index)}
                  />
                )}
              </div>
            );
          })}
          <div>
            <Button
              variant="outlined"
              style={{ display: 'block', margin: '12px auto' }}
              type="button"
              onClick={() =>
                append({
                  notes: null,
                  mainMaterialUnitQuantity: null,
                  receiveWeight: null,
                  stickerNum: null,
                })
              }
            >
              ＋流水號
            </Button>
          </div>
        </div>
        <Typography variant="h4">綁定原料</Typography>
        <Table
          dataSource={mockTableData}
          columns={[
            {
              title: '料號',
              dataIndex: 'materialId',
            },
            {
              title: '批號流水',
              dataIndex: 'batchSerial',
            },
          ]}
          rowSelection={{
            selectedRowKey: selectedRowKeys,
            onChange: (keys) => {
              setSelectedRowKeys(keys);
            },
          }}
        />
      </FormFieldsWrapper>
    </ModalLayout>
  );
};

const StorageInRecords = () => {
  const tableRef = useRef<HTMLTableElement>(null);
  const { orders } = useMiscPurchaseOrders({
    initiateFetching: true,
  });

  const columns: TableColumn<MiscPurchaseOrderOuterRecord>[] = useMemo(
    () => [
      { title: '操作人員', dataIndex: 'staffName' },
      {
        title: '入庫時間',
        render: (source) =>
          source['expectedCompletedAt']
            ? moment(source['expectedCompletedAt']).format(
                'YYYY-MM-DD, hh:mm:ss'
              )
            : '',
      },
      {
        title: '類型',
        dataIndex: 'category',
      },
      {
        title: '品名',
        render: (_source, index) => {
          return (
            <>
              <Button
                type="button"
                onClick={() => {
                  mznTableRowExpandHelper({ ref: tableRef, index });
                }}
                prefix={<Icon icon={ChevronDownIcon} />}
              />
              <span>{'硝酸鹽'}</span>
            </>
          );
        },
      },
      {
        title: '料號',
        dataIndex: 'materialId',
      },
      {
        title: '批號流水序',
        dataIndex: 'batchSerial',
      },
      {
        title: '收料量',
        dataIndex: 'receiveQuantity',
      },
      {
        title: '單位',
        dataIndex: 'mainUnit',
      },
      {
        title: '實際重量',
        dataIndex: 'weight',
      },
      {
        title: '單位',
        dataIndex: 'secondaryUnit',
      },
      {
        title: '儲位',
        dataIndex: 'stackId',
      },
      {
        title: '載具',
        dataIndex: 'loader',
      },
      {
        title: '備註',
        dataIndex: 'notes',
      },
      {
        title: '操作',
        render: () => {
          return (
            <Button type="button" onClick={() => ''} variant="outlined">
              取消
            </Button>
          );
        },
      },
    ],
    []
  );

  const expandable = useMemo<TableExpandable<MiscPurchaseOrderOuterRecord>>(
    () => ({
      rowExpandable: (record) => !!(record.items ?? []).length,
      expandedRowRender: (record) => {
        return {
          dataSource: record.items,
          columns: [
            { dataIndex: 'null-1' },
            { dataIndex: 'materialDescription' },
            { dataIndex: 'materialId' },
            { dataIndex: 'expectedMainMaterialUnitQuantity' },
            { dataIndex: 'null-2' },
            { dataIndex: 'null-3' },
            { dataIndex: 'null-4' },
          ],
        };
      },
    }),
    []
  );

  return (
    <>
      <Typography variant="h2">入庫紀錄</Typography>
      <div ref={tableRef}>
        <Table
          dataSource={orders}
          scroll={{
            x: 2500,
          }}
          columns={columns}
          bodyClassName={cx(classes['custom-row-expand'])}
          expandable={expandable}
        />
      </div>
    </>
  );
};
