import { ChevronLeftIcon } from "@mezzanine-ui/icons";
import { Button, Icon } from "@mezzanine-ui/react";
import { useLocationSelectorContext } from "../LocationSelectorContext/useLocationSelectorContext";

export function LocationPrevLevelButton() {
  const { selectedIds, handleNextLevel } = useLocationSelectorContext();
  
  return (
    <div>
      {selectedIds?.floorId && !selectedIds?.areaId && (
        <Button
          prefix={<Icon icon={ChevronLeftIcon} />}
          onClick={() => handleNextLevel(selectedIds?.factoryId)}>
          返回選擇棟別
        </Button>
      )}
      {selectedIds?.areaId && (
        <Button
          prefix={<Icon icon={ChevronLeftIcon} />}
          onClick={() => handleNextLevel(selectedIds?.floorId)}>
          返回選擇大區
        </Button>
      )}
    </div>
  )
}