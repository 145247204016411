import { Stack, ZoneEntity } from '../locations';
import { OffsetBased } from '../pagination';

export type Equipment = {
  id: string;
  assetId: string;
  name: string;
  installedAt: string;
  zone: ZoneEntity;
  stack: Stack;
  workCenterId: string;
  workCenter?: {
    id: string;
    name: string;
  };
  categoryId: string;
  category?: {
    id: string;
    name: string;
  };
};

export type GetEquipmentsParams = OffsetBased & {
  categoryIds?: string[];
  deptNames?: string[];
  installedFrom?: string;
  installedTo?: string;
  equipmentIds?: string[];
  locationIds?: string[];
  searchTerm?: string;
  bigClassCodes?: string[];
  smallClassCodes?: string[];
  workCenterIds?: string[];
};
