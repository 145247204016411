import type { ComponentProps } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import type { HubOrdersFilterFormValues } from '@solar/data';
import { useRef } from 'react';
import { Typography, MenuDivider, Button } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { FormProvider } from 'react-hook-form';
import styles from './index.module.scss';

type Props = ComponentProps<'div'> & {
  formMethods: UseFormReturn<HubOrdersFilterFormValues>;
  applyFilterCondition: () => void;
};

export function FilterPanelForm({
  children,
  formMethods,
  applyFilterCondition,
}: Props) {
  const searchButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <div className={styles.wrapper}>
      <FormProvider {...formMethods}>
        <FormFieldsWrapper
          methods={formMethods}
          onKeyDown={(e) => {
            if (e.key === 'Enter') e.preventDefault();
            if (e.key === 'Enter' && searchButtonRef.current) {
              searchButtonRef.current.click();
            }
          }}
        >
          <Typography variant="h4">查詢</Typography>
          <MenuDivider className={styles.divider} />
          <div className={styles.filterGrid}>
            {children}
            <div
              style={{ gridColumn: 'span 4 / span 4', justifySelf: 'right' }}
            >
              <Button
                ref={searchButtonRef}
                type="button"
                variant="contained"
                style={{ marginRight: '1rem' }}
                onClick={applyFilterCondition}
              >
                查詢
              </Button>
            </div>
          </div>
        </FormFieldsWrapper>
      </FormProvider>
    </div>
  );
}
