import { TableDataSourceWithID } from '@mezzanine-ui/core/table';
import { SelectValue } from '@mezzanine-ui/react';

export enum DetailTabType {
  REMARK = 'REMARK',
  FORM = 'FORM',
  PRODUCT_FORM = 'PRODUCT_FORM',
}

export interface FilterOptions {
  recipe?: string;
  oldMaterialId?: string;
  newMaterialId?: string;
}

export interface LocalFilterOptions {
  mesRecipeVersion?: SelectValue;
  tableValues?: ExtendedRoutingRecord[];
  deletingRowId?: string;
  editingRowId?: string;
  editingDetailId?: string;
  selectedChartId?: string;
}

export enum CustomFormItemType {
  INPUT = 'input',
  SELECT = 'select',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
}

export interface CustomFormItem {
  type: CustomFormItemType;
  label: string;
  required: boolean;
}

export interface CustomFormInputItem extends CustomFormItem {
  type: CustomFormItemType.INPUT;
  placeholder?: string;
  number?: boolean;
}

export interface CustomFormSelectItem extends CustomFormItem {
  type: CustomFormItemType.SELECT;
  options: SelectValue[];
}

export interface CustomFormCheckboxItem extends CustomFormItem {
  type: CustomFormItemType.CHECKBOX;
  options: SelectValue[];
}

export interface CustomFormRadioItem extends CustomFormItem {
  type: CustomFormItemType.RADIO;
  options: SelectValue[];
}

export type CustomFormTypes =
  | CustomFormInputItem
  | CustomFormSelectItem
  | CustomFormCheckboxItem
  | CustomFormRadioItem;

export interface DetailFormCreatingOptions {
  fieldType?: SelectValue;
  required?: boolean;
  placeholder?: string;
  label?: string;
  number?: boolean;
  options?: SelectValue[];
}

export interface DetailFormOptions {
  remark?: string;
  customForms?: CustomFormTypes[];
  productCustomForms?: CustomFormTypes[];
  [key: string]: string | undefined | CustomFormTypes[];
}

export interface RoutingRecord extends TableDataSourceWithID {
  id: string;
  oldMaterialId: string;
  componentCode: string;
  drawingCode: string | null;
  newDrawingCode: string | null;
  sandBlasting: string | null;
  materialDescription: string | null;
  mesRecipe: string | null;
  mesRecipeVersion: number;
  newRecipe: string | null;
  areaDesc: string | null;
  sequence: number;
  actionCode: string | null;
  action: string | null;
  sourceMaterialId: string | null;
  destMaterialId: string | null;
  workCenterId: string | null;
  workCenterName: string | null;
  locationId: string | null;
  manHours: number;
  machineHours: number;
  standardWeight: number;
  createdAt: string;
  updatedAt: string;
  allowWorkCenterIds: string[];
}

export interface ExtendedRoutingRecord extends RoutingRecord {
  selectedWorkCenterOptions: SelectValue[];
  workCenterNames: string[];
  orderNumber: number;
  remark?: string;
  customForms?: CustomFormTypes[];
  productCustomForms?: CustomFormTypes[];
}
