import { useLocationControlContext } from '../LocationControlContext';
import { LocationLevels } from '../LocationLevel';
import { LocationLevelSelect } from './LocationLevelSelect';
import { useLocationPickerOptionHooks } from './useLocationPickerOptionHooks';

export function LocationPicker() {
  const { locationIds, error, onChangeByLocationPicker } =
    useLocationControlContext();

  const optionHooks = useLocationPickerOptionHooks();

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: 'var(--mzn-spacing-2)',
      }}
    >
      {LocationLevels.map(({ level, label }) => (
        <LocationLevelSelect
          key={level}
          label={label}
          id={locationIds[level]}
          error={error}
          onChange={(value) => onChangeByLocationPicker?.(value, level)}
          options={optionHooks[level].options}
          loading={optionHooks[level].loading}
          onSearch={optionHooks[level].onSearch}
        />
      ))}
    </div>
  );
}
