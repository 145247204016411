import { MenuDivider, SelectValue } from '@mezzanine-ui/react';
import { DateRangePickerField, FormFieldsWrapper, InputField, SelectField } from '@mezzanine-ui/react-hook-form';
import { Button } from '@mezzanine-ui/react';
import styles from './SPSearchForm.module.scss';
import { ISPSearchPageMode, SPSearchPageMode } from '@solar/data';
import { useMemo } from 'react';


interface ISPSearchFormProps {
  autoCompleteOptionsState: SelectValue<string>[];
  pageMode: ISPSearchPageMode;
  form: any;
  onSubmit?: (formData: any) => void;
}


const SPSearchForm = (
  {
    autoCompleteOptionsState,
    pageMode,
    form,
    onSubmit,
  }: ISPSearchFormProps
) => {

  const newStateOptions = useMemo(() => {
    const newOptions = [...autoCompleteOptionsState];
    newOptions.unshift({ id: 'all', name: '全部' });
    return newOptions;
  }, [autoCompleteOptionsState]);

  return (
    <div>
      <FormFieldsWrapper methods={form} onSubmit={onSubmit}>
        <InputField registerName="showId" label="敲價單號" />
        <div className={styles.fieldSpacer}>
          <InputField
            registerName="customerName"
            label="客戶名稱"
          />
          <InputField
            registerName="salesName"
            label="業務"
          />
          <SelectField
            registerName="status"
            label="狀態"
            options={newStateOptions}
          />
        </div>
        <DateRangePickerField
          registerName="dateRange"
          label="敲價單日期"
        />
        {
          pageMode === SPSearchPageMode.APPROVE && (
            <div>
              <DateRangePickerField
                registerName="dealDateRange"
                label="成交日期"
              />
            </div>
          )
        }
        <MenuDivider style={{ marginBottom: 12, marginTop: 12 }} />
        <Button type="submit" variant="contained">查詢</Button>
      </FormFieldsWrapper>
    </div>
  );
};

export default SPSearchForm;