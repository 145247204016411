import { PlusIcon } from '@mezzanine-ui/icons';
import { Button, Icon, Table, Typography } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  checkJWTRoles,
  FieldsType,
  usePackaging,
  usePackagingFilter,
  usePackagingModal,
} from '@solar/data';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  AccountInput,
  Br,
  SearchInput,
} from '../../OrderManagement/FilterSearch/enhanceFilter/enhanceFilter';
import { SubmitButtonType } from '../Layout/Filter/Filter';
import { Layout } from '../Layout/Layout';
import { getComponent } from '../Layout/ModalLayout/enhanceModalActions/enhanceModalActions';
import { ModalLayout } from '../Layout/ModalLayout/ModalLayout';
import { PackagingDeleteModal } from './PackagingDeleteModal/PackagingDeleteModal';
import styles from './PackagingPage.module.scss';

export function PackagingPage() {
  const methods = useForm();

  const {
    data: packagingData,
    filterMutation: filterPackaging,
    page: currentPage,
    setPage: setCurrentPage,
    total,
    perPage,
    setPerPage,
  } = usePackaging();

  const { layoutMethods } = usePackagingFilter();

  const {
    deleteModal,
    setDeleteModal,
    modal,
    setModal,
    closeModal,
    disableConfirm,
    createModal,
    updateModal,
    handleOpenModal,
    confirmModal,
    deleteModalMutation,
  } = usePackagingModal(methods);

  const modalBodyComponents = useMemo(() => {
    const modalData = modal?.title === '編輯' ? updateModal : updateModal;
    return modalData.map((item) => ({
      ...item,
      fn: getComponent(item.fn),
    }));
  }, [modal, createModal, updateModal]);

  const packagingTypeBodyComponents = useMemo(
    () => modalBodyComponents.slice(0, 5),
    [modalBodyComponents]
  );

  const packageBodyComponents = useMemo(
    () => modalBodyComponents.slice(5),
    [modalBodyComponents]
  );

  const filterButton = useMemo(
    () => ({
      name: '清空條件',
      onClick() {
        setCurrentPage(1);
        filtersData.forEach((filter) => {
          layoutMethods.setValue(filter.registerName, undefined);
        });
      },
    }),
    []
  ) as SubmitButtonType;

  const filtersData = [
    {
      label: '包材料號',
      fn: SearchInput,
      registerName: 'packagingNumber',
      props: {
        name: 'packagingNumber',
        clearable: true,
        placeholder: '請輸入',
        className: styles.input,
        register: layoutMethods.register,
        setValue: layoutMethods.setValue,
      },
    },
    {
      label: '包材名稱',
      fn: SearchInput,
      registerName: 'packagingName',
      props: {
        width: 160,
        clearable: true,
        placeholder: '請輸入',
        className: styles.input,
        name: 'packagingName',
        register: layoutMethods.register,
        setValue: layoutMethods.setValue,
      },
    },
    {
      label: '',
      fn: Br,
      registerName: '',
      props: {},
    },
    {
      label: '長 (mm)',
      fn: AccountInput,
      registerName: 'length',
      props: {
        width: 160,
        clearable: true,
        placeholder: '請輸入',
        className: styles.input,
        name: 'length',
        register: layoutMethods.register,
        setValue: layoutMethods.setValue,
        getValues: layoutMethods.getValues,
      },
    },
    {
      label: '寬 (mm)',
      fn: AccountInput,
      registerName: 'width',
      props: {
        width: 160,
        clearable: true,
        placeholder: '請輸入',
        className: styles.input,
        name: 'width',
        register: layoutMethods.register,
        setValue: layoutMethods.setValue,
        getValues: layoutMethods.getValues,
      },
    },
    {
      label: '高 (mm)',
      fn: AccountInput,
      registerName: 'height',
      props: {
        width: 160,
        clearable: true,
        placeholder: '請輸入',
        className: styles.input,
        name: 'height',
        register: layoutMethods.register,
        setValue: layoutMethods.setValue,
        getValues: layoutMethods.getValues,
      },
    },
  ];

  const registers = useMemo(() => {
    layoutMethods.watch();
    return filtersData.map((data) => ({
      registerName: data.registerName,
      value: layoutMethods.getValues(data.registerName),
    }));
  }, [layoutMethods.watch()]);

  const registerDependencies = useMemo(
    () => registers.map((register) => register.value),
    [registers]
  );

  const columns = useMemo(
    () => [
      { title: '類型', dataIndex: 'packagingType' },
      { title: '包材料號', dataIndex: 'materialId' },
      { title: '包材名稱', dataIndex: 'name' },
      { title: '材質', dataIndex: 'texture' },
      { title: '長 (mm)', dataIndex: 'length' },
      { title: '寬 (mm)', dataIndex: 'width' },
      { title: '高 (mm)', dataIndex: 'height' },
      { title: '英吋 (in)', dataIndex: 'inch' },
      { title: '分數', dataIndex: 'fraction' },
      {
        title: '動作',
        width: 120,
        align: 'center' as const,
        render(source: any) {
          return (
            checkJWTRoles([71]) && (
              <Button
                type="button"
                variant="outlined"
                onClick={() => {
                  handleOpenModal(source);
                }}
              >
                編輯
              </Button>
            )
          );
        },
      },
    ],
    []
  );

  const filterLayout = useCallback(
    async (payload: FieldsType[]) => {
      setCurrentPage(1);
      filterPackaging(payload);
    },
    [setCurrentPage]
  );

  return (
    <div className={styles.wrapper}>
      <ModalLayout
        title={modal?.title}
        open={modal}
        width={864}
        onClose={closeModal}
        confirmButtonText={modal?.confirmButton}
        onConfirm={confirmModal}
        onConfirmDelete={() => deleteModalMutation(modal?.source.id)}
        confirmButtonDisable={disableConfirm}
        deleteButton={modal?.title === '編輯'}
        source={modal?.source}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        deleteModalChildren={<PackagingDeleteModal source={modal?.source} />}
      >
        <div className={styles.modalWrapper}>
          <FormFieldsWrapper methods={methods} className={styles.formWrapper}>
            <div className={styles.modalBodyWithBorder}>
              <Typography variant="h4" color="text-primary">
                包材類型
              </Typography>
              <div className={styles.body}>
                {packagingTypeBodyComponents.map((component) =>
                  component.fn(component)
                )}
              </div>
            </div>
            <div className={styles.modalBodyWithBorder}>
              <Typography variant="h4" color="text-primary">
                包材尺寸
              </Typography>
              <div className={styles.body}>
                {packageBodyComponents.map((component) =>
                  component.fn(component)
                )}
              </div>
            </div>
          </FormFieldsWrapper>
        </div>
      </ModalLayout>
      <Layout
        title="包材管理"
        button={
          <Button
            variant="contained"
            size="large"
            disabled={!checkJWTRoles([71])}
            prefix={<Icon icon={PlusIcon} />}
            onClick={() =>
              setModal({
                title: '新增',
                confirmButton: '新增',
                source: null,
              })
            }
          >
            新增
          </Button>
        }
        filterHeader="查詢"
        filtersData={filtersData}
        filterMethods={filterLayout}
        filterDependencies={registerDependencies}
        registers={registers}
        submitButton={filterButton}
        methods={layoutMethods}
      >
        <Table
          columns={columns}
          dataSource={packagingData ?? []}
          bodyClassName={styles.table}
          headerClassName={styles.tableHeader}
          pagination={{
            disableAutoSlicing: true,
            current: currentPage,
            total,
            onChange(page) {
              setCurrentPage(page);
            },
            options: {
              pageSize: perPage,
              onChangePageSize: (pageSize: number) => {
                setPerPage(pageSize);
                setCurrentPage(1);
              },
              showPageSizeOptions: true,
              pageSizeLabel: '每頁顯示：',
              pageSizeUnit: '筆',
            },
          }}
        />
      </Layout>
    </div>
  );
}
