import type { ProductionPlanningOrdersData } from './useProductionPlanningOrdersGet';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { request, API_NAMESPACE } from '../../request';
import * as yup from 'yup';

const FormFieldName = {
  ORDER_DETAIL_ID: 'id',
  ORDER_ID: 'orderId',
  PLANNED_DELIVERY_NOTE: 'plannedDeliveryNote',
} as const;

const plannedDeliveryNoteInlineFormSchema = yup.object().shape({
  [FormFieldName.ORDER_DETAIL_ID]: yup.number().required(),
  [FormFieldName.ORDER_ID]: yup.string().required(),
  [FormFieldName.PLANNED_DELIVERY_NOTE]: yup.string(),
});

export type PlannedDeliveryNoteInlineFormValues = yup.InferType<
  typeof plannedDeliveryNoteInlineFormSchema
>;

type HookProps = {
  dataSource: ProductionPlanningOrdersData;
};

export function usePlannedDeliveryNoteInlineForm({ dataSource }: HookProps) {
  const formMethods = useForm<PlannedDeliveryNoteInlineFormValues>({
    resolver: yupResolver(plannedDeliveryNoteInlineFormSchema),
  });
  const formState = useFormState({ control: formMethods.control });

  useEffect(() => {
    if (!dataSource) return;

    formMethods.reset({
      [FormFieldName.ORDER_DETAIL_ID]: dataSource.id,
      [FormFieldName.ORDER_ID]: dataSource.orderId,
      [FormFieldName.PLANNED_DELIVERY_NOTE]: dataSource.plannedDeliveryNote,
    });
  }, [dataSource, formMethods]);

  return {
    formMethods,
    formState,
    formSubmitCreator,
    FormFieldName,
  };
}

async function putPlannedDeliveryNote({
  id,
  plannedDeliveryNote,
}: PlannedDeliveryNoteInlineFormValues) {
  try {
    return request(`/production-planning/orders/${id}`, {
      namespace: API_NAMESPACE.HR,
      method: 'PUT',
      body: JSON.stringify({
        [FormFieldName.PLANNED_DELIVERY_NOTE]: plannedDeliveryNote,
      }),
    });
  } catch (error) {
    return null;
  }
}

type FormSubmitCreatorProps = {
  successCallback?: () => void;
  errorCallback?: (error: Error) => void;
};

function formSubmitCreator({
  successCallback,
  errorCallback,
}: FormSubmitCreatorProps) {
  const onSubmit = async (data: PlannedDeliveryNoteInlineFormValues) => {
    putPlannedDeliveryNote(data)
      .then((res) => {
        successCallback?.();
      })
      .catch((error) => {
        errorCallback?.(error);
      });
  };
  return onSubmit;
}
