import { SelectField } from '@mezzanine-ui/react-hook-form';

export const typesMap = new Map([
  ['AVAILABLE', '未限制使用'],
  ['SALES', '銷售訂單'],
  ['INSPECTION', '品檢中'],
  ['HUB', 'HUB 庫存'],
  ['IN_TRANSIT', '委外採購庫存'],
]);

export function InventoryTypeField() {
  return (
    <SelectField
      label="庫存類型"
      registerName="inventoryType"
      options={Array.from(typesMap).map(([id, name]) => ({
        id,
        name,
      }))}
      clearable
    />
  );
}
