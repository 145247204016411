import { UseFormReturn } from 'react-hook-form';

import {
  AutoCompleteField,
  AutoCompleteFieldProps,
} from '@mezzanine-ui/react-hook-form';
import { API_NAMESPACE, MaterialAllocationFormValues } from '@solar/data';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

import { DispatchSettingModalFormProps } from '../types';
import { SelectValue } from '@mezzanine-ui/react';
import debounce from 'lodash/debounce';

export function useAutoCompleteRecipeField({
  methods,
  enabled,
  materialId,
  ...restAutoCompleteFieldProps
}: {
  methods: UseFormReturn<DispatchSettingModalFormProps>;
  // | UseFormReturn<MaterialAllocationFormValues>;
  enabled?: boolean;
  materialId?: string;
} & Omit<
  AutoCompleteFieldProps,
  'registerName' | 'placeholder' | 'options'
>): JSX.Element {
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const [localMaterialId, setLocalMaterialId] = useState<string | undefined>(
    undefined
  );
  const onMaterialIdSearch = useCallback(
    debounce((value: string | undefined) => {
      setSearchTerm(value ? value : undefined);
      setLocalMaterialId(value ? undefined : materialId);
    }, 500),
    []
  );

  const { data } = useSWR<
    {
      id: string;
      featureCode: {
        code: string;
        name: string;
      };
      group: {
        code: string;
        name: string;
      };
      version: number;
    }[]
  >(
    enabled
      ? [
          '/pp/recipes',
          {
            namespace: API_NAMESPACE.PP,
            params: {
              searchTerm: searchTerm,
              materialId: localMaterialId,
            },
          },
        ]
      : undefined
  );

  const recipes = useMemo(() => {
    const seenIds = new Set();
    const uniqueObjects = [] as SelectValue[];

    data?.forEach((item) => {
      if (!seenIds.has(item.id)) {
        seenIds.add(item.id);
        uniqueObjects?.push({ id: item.id, name: item.id });
      }
    });

    return uniqueObjects;
  }, [data]);

  useEffect(() => {
    setLocalMaterialId(materialId);
  }, [materialId, setLocalMaterialId]);

  return (
    <AutoCompleteField
      options={recipes ?? []}
      onSearch={onMaterialIdSearch}
      // placeholder="FTPB029"
      registerName="recipeId"
      onChange={(selected) => {
        const versions = data?.reduce((accu, cur, index) => {
          if (cur.id === selected.id) {
            accu.push({ id: String(index), name: String(cur.version) });
          }

          return accu;
        }, [] as SelectValue[]);

        methods.setValue('recipeVersions', versions ?? []);
        if (versions?.length) {
          methods.setValue('recipeVersion', versions?.[0]);
        }
      }}
      {...restAutoCompleteFieldProps}
    />
  );
}
