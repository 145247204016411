import {
  Button,
  MenuDivider,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SelectValue,
  Typography,
} from '@mezzanine-ui/react';
import { useEffect, useState } from 'react';
import styles from './bind-equipment-location-modal.module.scss';
import {
  Equipment,
  getLocationDisplayName,
  getZoneDisplayName,
} from '@solar/data';
import { Modals } from './EquipmentListModals.enum';
import {
  LocationSelectorGroup,
  useLocationSelectorController,
  useTargetModal,
} from '@solar/templates';

type BindEquipmentLocationModalProps = {
  onSubmit: (payload: { equipmentId: string; stackId: string }) => void;
};

export function BindEquipmentLocationModal({
  onSubmit,
}: BindEquipmentLocationModalProps) {
  const {
    open,
    data: equipment,
    closeModal,
  } = useTargetModal<Equipment>(Modals.BIND_LOCATION);
  const locationSelectorController = useLocationSelectorController({});
  const { selectedIds, resetLocationSelector, handleNextLevel } =
    locationSelectorController;

  useEffect(() => {
    if (open) {
      handleNextLevel(equipment?.stack?.id);
    }
  }, [equipment?.stack, open, handleNextLevel]);

  const onClose = () => {
    resetLocationSelector();
    closeModal();
  };

  const handleSubmit = () => {
    onSubmit({
      equipmentId: encodeURIComponent(equipment?.id ?? ''),
      stackId: selectedIds?.stackId ?? '',
    });
    onClose();
  };

  return (
    <Modal className={styles.modal_wrapper} open={open} onClose={onClose}>
      <ModalHeader>設備綁定</ModalHeader>
      <ModalBody>
        <div>
          <Typography variant="h6" color="secondary">
            設備 ID
          </Typography>
          <Typography>{equipment?.id || ''}</Typography>
        </div>
        <div>
          <Typography variant="h6" color="secondary">
            設備名稱
          </Typography>
          <Typography>{equipment?.name || ''}</Typography>
        </div>
        <div>
          <Typography variant="h6" color="secondary">
            當前位置
          </Typography>
          <Typography>
            {getLocationDisplayName(equipment?.stack ?? {})}
          </Typography>
        </div>
        <MenuDivider
          style={{ borderTop: '1px solid var(--mzn-color-border)' }}
        />
        <LocationSelectorGroup
          controller={locationSelectorController}
          showShelfSvg={false}
        />
        {/* <LocationSelector {...locationSelectorController} selectedChartId={equipment?.chartId} /> */}
      </ModalBody>
      <ModalFooter>
        <Button variant="outlined" onClick={onClose}>
          取消
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          儲存更變
        </Button>
      </ModalFooter>
    </Modal>
  );
}
