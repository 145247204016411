import { Button, Table, Typography } from '@mezzanine-ui/react';
import { useState } from 'react';
import styles from './MatchTable.module.scss';

type MatchedDataType = {
  id: string;
  state: string;
  received: boolean;
  code: string;
  batch: string;
  materialId: string;
  name: string;
  shippedNo: string;
  amount: number;
  unit: string;
};

type MatchedProps = {
  data: MatchedDataType[];
  onMatchedEdit: (index: number) => void;
};

export function MatchedTable({ data, onMatchedEdit }: MatchedProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const matchedColumns = [
    {
      title: '操作',
      width: 88,
      render: (item: MatchedDataType, index: number) => {
        return (
          <Button variant="outlined" onClick={() => onMatchedEdit(index)}>
            編輯
          </Button>
        );
      },
    },
    { title: '狀態', dataIndex: 'state', ellipsis: false, width: 88 },
    { title: '客戶靶編匹配', dataIndex: 'code', ellipsis: false },
    { title: '批號匹配', dataIndex: 'batch', ellipsis: false },
    {
      title: '料號',
      dataIndex: 'materialId',
      ellipsis: false,
      width: 160,
      bodyClassName: styles.lineBreak,
    },
    {
      title: '品名',
      dataIndex: 'name',
      ellipsis: false,
      width: 240,
      bodyClassName: styles.lineBreak,
    },
    { title: '歷史出貨單號', dataIndex: 'shippedNo', ellipsis: false },
    { title: '數量', dataIndex: 'amount', ellipsis: false },
    { title: '單位', dataIndex: 'unit', ellipsis: false },
  ];
  return (
    <div>
      <Typography variant="h3">已匹配記錄</Typography>
      <Table
        columns={matchedColumns}
        dataSource={data}
        pagination={{
          current: currentPage,
          total: data.length,
          onChange: setCurrentPage,
          options: {
            pageSize,
            onChangePageSize: (pageSize: number) => {
              setPageSize(pageSize);
              setCurrentPage(1);
            },
            showPageSizeOptions: true,
            pageSizeLabel: '每頁顯示：',
            pageSizeUnit: '筆',
          },
        }}
      ></Table>
    </div>
  );
}
