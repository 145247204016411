export enum InspectMaterialType {
  PurchaseOrder = 'purchase-order',
  CustomerOrder = 'customer-order',
  OutsourcingOrder = 'outsourcing-order',
  ReturnOrder = 'return-order',
  RentTargetOrder = 'rent-target',
  HubReturn = 'hub-return',
  VirtualIn = 'virtual-in',
}

export enum SourceType {
  OutsourcingOrder = 'out-sourcing-order',
  StockOrder = 'stock-order',
  MixedFixedOrder = 'mixed-fixed-order',
  CustomerSupply = 'customer-supply',
  ProcessOrder = 'process-order',
}
export function renderSourceTypeString(inspectModalType?: SourceType): string {
  switch (inspectModalType) {
    case SourceType.OutsourcingOrder:
      return '委外採購單';
    case SourceType.StockOrder:
      return '庫存採購單';
    case SourceType.MixedFixedOrder:
      return '雜項固支採購單';
    case SourceType.CustomerSupply:
      return '客供料';
    case SourceType.ProcessOrder:
      return '製程工單';
    default:
      return '';
  }
}

export const SOURCE_TYPE_OPTIONS = [
  {
    id: SourceType.OutsourcingOrder,
    name: renderSourceTypeString(SourceType.OutsourcingOrder),
  },
  {
    id: SourceType.StockOrder,
    name: renderSourceTypeString(SourceType.StockOrder),
  },
  {
    id: SourceType.MixedFixedOrder,
    name: renderSourceTypeString(SourceType.MixedFixedOrder),
  },
  {
    id: SourceType.CustomerSupply,
    name: renderSourceTypeString(SourceType.CustomerSupply),
  },
  {
    id: SourceType.ProcessOrder,
    name: renderSourceTypeString(SourceType.ProcessOrder),
  },
];
