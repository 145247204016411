import { Button, Typography } from '@mezzanine-ui/react';
import {
  checkJWTRoles,
  ManualData,
  MaterialAllocationFormValues,
} from '@solar/data';
import { Col, Row, Table } from 'antd';
import { Key, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

export function SuggestAllocationsTable({
  dataSource,
  appending,
  suggesting,
  appendManualData,
  openSuggestedMaterialAllocationsModal,
  calculateAllocationResult,
}: {
  dataSource?: any[];
  appending?: boolean;
  suggesting?: boolean;
  appendManualData?: (
    selectedIds: string[],
    metadata?: ManualData[]
  ) => Promise<void>;
  openSuggestedMaterialAllocationsModal?: () => void;
  calculateAllocationResult: () => void;
}) {
  const methods = useFormContext<MaterialAllocationFormValues>();
  const manualData = methods?.watch('manualData');

  const tableRef = useRef<HTMLDivElement>(null);
  const [selectedIds, setSelectedIds] = useState<Key[]>([]);

  const rowSelection = useMemo(() => {
    const manualDataRecordIdSet = new Set(
      manualData
        ?.map((item) => item?.batchStoredLoaderRecordId?.toString() ?? '')
        ?.filter((id) => id !== '') ?? []
    );
    return {
      selectedRowKeys: selectedIds?.concat([...manualDataRecordIdSet]) ?? [],
      onChange: (selectedRowKeys: Key[]) => {
        const result = selectedRowKeys?.filter(
          (key) => !manualDataRecordIdSet?.has(key as string)
        );
        setSelectedIds(result);
      },
      getCheckboxProps: (record: any) => ({
        disabled:
          manualDataRecordIdSet?.has(
            record?.batchStoredLoaderRecordId?.toString()
          ) || !['G', 'KG'].includes(record?.materialStockUnit ?? ''),
      }),
    };
  }, [manualData, selectedIds]);

  return (
    <div>
      <Row style={{ margin: '12px 0' }} justify="space-between">
        <Col>
          <Typography variant="h4">建議配料</Typography>
        </Col>
        {checkJWTRoles([117]) && (
          <Col>
            <Row gutter={[12, 12]}>
              <Col>
                <Button
                  loading={suggesting}
                  variant="contained"
                  onClick={openSuggestedMaterialAllocationsModal}
                >
                  計算建議配料
                </Button>
              </Col>
              <Col>
                <Button
                  loading={appending}
                  disabled={selectedIds?.length === 0}
                  variant="contained"
                  onClick={async () => {
                    try {
                      await appendManualData?.(
                        selectedIds as string[],
                        dataSource
                      );
                      setSelectedIds([]);
                      await calculateAllocationResult();
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  加入手動配料
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Table
        ref={tableRef}
        scroll={{ x: 'max-content' }}
        rowKey="batchStoredLoaderRecordId"
        loading={suggesting}
        pagination={false}
        dataSource={dataSource}
        rowSelection={rowSelection}
        columns={[
          {
            width: 100,
            title: '#',
            dataIndex: 'batchStoredLoaderRecordId',
          },
          {
            width: 300,
            title: '料號',
            dataIndex: 'materialId',
          },
          {
            width: 300,
            title: '批號',
            dataIndex: 'batchId',
            render: (value, source) =>
              `${source?.materialSapBatchId}_${source?.materialSubBatchId}`,
          },
          {
            width: 500,
            title: '品名',
            dataIndex: 'materialDescription',
          },
          {
            width: 100,
            title: '單位',
            dataIndex: 'materialStockUnit',
          },
          {
            width: 120,
            title: '儲位',
            dataIndex: 'shelfId',
          },
          {
            width: 100,
            title: '新舊料',
            dataIndex: 'usedOldMaterial',
            render: (value) => {
              switch (value) {
                case 'true':
                  return '舊料';
                case 'false':
                  return '新料';
                default:
                  return '舊料';
              }
            },
          },
          // {
          //   width: 100,
          //   title: '實際Wt%',
          //   dataIndex: 'actual_wt_percent',
          // },
          // {
          //   width: 100,
          //   title: '標準At%',
          //   dataIndex: 'standard_wt_percent',
          // },
          // {
          //   width: 100,
          //   title: '實際At%',
          //   dataIndex: 'actual_at_percent',
          // },
          {
            width: 120,
            title: '庫存量',
            dataIndex: 'availableMaterialStockUnitQuantity',
          },
          {
            width: 200,
            title: '建議使用數量',
            dataIndex: 'suggestedMaterialUnitQuantity',
          },
        ]}
      />
    </div>
  );
}
