import { Input, RadioGroup, Typography } from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  HookFormFieldComponent,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { FilterDataType } from '@solar/data';
import { TreeSelect } from 'antd';
import {
  AutoCompleteWithUpdatingApiComponent,
  InputComponentType,
  SearchInput,
  TreeSelectComponent,
} from 'apps/frontend/src/app/OrderManagement/FilterSearch/enhanceFilter/enhanceFilter';
import React, { useEffect, useMemo, useState } from 'react';
import {
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import styles from './enhanceModalActions.module.scss';

type PropsType<T> = {
  label?: string;
  required: boolean;
  componentsProps: T;
};

export function enhanceModalActions(Components: HookFormFieldComponent<any>) {
  return function <T>(props: PropsType<T>) {
    return (
      <div className={styles.wrapper}>
        {props.label && (
          <div className={styles.label}>
            <Typography variant="h6" color="secondary-light">
              {props.label}
            </Typography>
            {props.required && <Typography color="error-light">*</Typography>}
          </div>
        )}
        <Components {...props.componentsProps} />
      </div>
    );
  };
}

const Border = (props: PropsType<{ width: number }>) => (
  <div
    className={styles.border}
    style={{ width: props.componentsProps.width }}
  ></div>
);

const BorderVertical = (props: PropsType<{ height: number }>) => (
  <div
    className={styles.borderVertical}
    style={{ height: props.componentsProps.height }}
  ></div>
);

const Br = () => <div style={{ width: '100%' }}></div>;

const Info = ({ content }: { content: string }) => (
  <div className={styles.infoWrapper}>
    <span className={styles.info}>{content}</span>
  </div>
);

type AccountInputComponentType = {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  name: string;
  getValues: UseFormGetValues<FieldValues>;
  defaultValue?: string;
  disabled?: boolean;
};

const AccountInputComponent = ({
  register,
  setValue,
  name,
  getValues,
  defaultValue,
  disabled,
}: AccountInputComponentType) => {
  const [value, setValueNoRender] = useState(defaultValue ?? '');

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueNoRender(e.target.value);
    setValue(name, e.target.value);
  };

  useEffect(() => {
    if (!defaultValue) return;
    setValue(name, defaultValue);
    setValueNoRender(defaultValue);
  }, [defaultValue]);

  return (
    <input
      {...register(name)}
      type="number"
      placeholder="0"
      className={styles.accountInput}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

type SizeInputsType = Omit<AccountInputComponentType, 'name'> & {
  sizes: any[];
};

const SizeInputs = ({
  register,
  setValue,
  getValues,
  sizes,
}: SizeInputsType) => {
  useEffect(() => {
    if (!sizes) return;
    sizes.forEach((size) => {
      setValue(size.name, size.defaultValue);
    });
  }, [sizes]);

  return (
    <div className={styles.sizeInputs}>
      {sizes.map((size, index) => (
        <div key={Math.random()}>
          {size.label && (
            <div className={styles.typography}>
              <Typography
                variant="h6"
                color="secondary-light"
                style={{ height: 24 }}
              >
                {size.label}
              </Typography>
              {size.required && <Typography color="error-light">*</Typography>}
            </div>
          )}
          <div className={styles.input}>
            <AccountInputComponent
              register={register}
              setValue={setValue}
              getValues={getValues}
              name={size.name}
            />
            {index !== sizes.length - 1 && (
              <div className={styles.x}>
                <Typography variant="h6" color="secondary-light">
                  x
                </Typography>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const ModalSearchInputComponent = ({
  register,
  setValue,
  name,
  placeholder,
  disabled,
  defaultValue,
  label,
  width,
}: InputComponentType & { defaultValue: string; label?: string }) => {
  const [value, setValueNoRender] = useState('');

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueNoRender(e.target.value);
    setValue(name, e.target.value);
  };

  useEffect(() => {
    setValue(name, defaultValue);
    setValueNoRender(defaultValue);
  }, [defaultValue]);

  return (
    <div>
      {label && (
        <div className={styles.modalSearchInputLabel}>
          <Typography variant="h6" color="secondary-light">
            {label}
          </Typography>
        </div>
      )}
      <input
        className={styles.modalInput}
        style={{ width }}
        {...register(name)}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
};

export const EnhanceSelect = enhanceModalActions(SelectField);
export const EnhanceSearchInput = enhanceModalActions(Input);
export const EnhanceModalSearchInput = enhanceModalActions(
  ModalSearchInputComponent
);
export const EnhanceAutoCompleteUpdateOptions = enhanceModalActions(
  AutoCompleteWithUpdatingApiComponent
);
export const EnhanceAutoComplete = enhanceModalActions(AutoCompleteField);
export const EnhanceTreeSelect = enhanceModalActions(TreeSelectComponent);
export const EnhanceInfo = enhanceModalActions(Info);
export const EnhanceAccountInput = enhanceModalActions(AccountInputComponent);
export const EnhanceSizeInputs = enhanceModalActions(SizeInputs);

type ComponentData = {
  name:
    | 'EnhanceSelect'
    | 'EnhanceSearchInput'
    | 'EnhanceAutoComplete'
    | 'EnhanceAccountInput'
    | 'EnhanceSizeInputs'
    | 'Border';
  component: <T>(props: PropsType<T>) => JSX.Element;
};

const components = [
  { name: 'EnhanceSelect', component: EnhanceSelect },
  { name: 'EnhanceSearchInput', component: EnhanceSearchInput },
  { name: 'SearchInput', component: SearchInput },
  { name: 'EnhanceModalSearchInput', component: EnhanceModalSearchInput },
  { name: 'EnhanceAutoComplete', component: EnhanceAutoComplete },
  { name: 'EnhanceAccountInput', component: EnhanceAccountInput },
  {
    name: 'EnhanceAutoCompleteUpdateOptions',
    component: EnhanceAutoCompleteUpdateOptions,
  },
  { name: 'EnhanceSizeInputs', component: EnhanceSizeInputs },
  { name: 'Border', component: Border },
  { name: 'BorderVertical', component: BorderVertical },
  { name: 'Br', component: Br },
  { name: 'Info', component: EnhanceInfo },
] as ComponentData[];

type RadiosType = {
  fn: string;
  componentsProps: any;
  label: string;
  registerName: string;
  options?: FilterDataType[];
  borderBottom?: boolean;
  info?: any;
  defaultValue?: any;
};

type RadioGroupComponentType = Omit<
  InputComponentType,
  'placeholder' | 'disabled'
> & {
  radios: RadiosType[];
  getValues: UseFormGetValues<FieldValues>;
  packagingNameValue: string;
  setPackagingNameValue: React.Dispatch<React.SetStateAction<string>>;
  defaultRadio: number;
};

export const RadioGroupComponent = ({
  radios,
  setValue,
  register,
  name,
  setPackagingNameValue,
  defaultRadio,
}: RadioGroupComponentType) => {
  const [packagingMethods, setPackagingMethods] = useState(
    radios[defaultRadio]?.registerName
  );

  const options = useMemo(
    () =>
      radios.map((radio) => ({
        value: radio.registerName,
        label: radio.label,
      })),
    []
  );

  useEffect(() => {
    setValue(
      radios[defaultRadio].registerName,
      radios[defaultRadio].defaultValue
    );
    if (defaultRadio === 1)
      setPackagingNameValue(radios[defaultRadio].defaultValue);
  }, []);

  return (
    <div className={styles.radioWrapper}>
      <RadioGroup
        {...register(name)}
        orientation="vertical"
        value={packagingMethods}
        onChange={(e) => {
          setValue(name, e.target.value);
          setPackagingMethods(e.target.value);
        }}
        options={options}
        className={styles.radioGroup}
      />
      <div className={styles.radioGroup}>
        <div className={styles.radio}>
          {EnhanceAutoComplete({
            label: '',
            required: false,
            componentsProps: {
              label: '包材料號',
              required: false,
              registerName: 'radiosSelect',
              mode: 'single',
              width: 172,
              options: radios[0].options,
              clearable: true,
              placeholder: '請選擇',
              defaultValue: radios[0].defaultValue,
              disabled: packagingMethods !== 'radiosSelect',
            },
          })}
          <div className={styles.verticalBorder}></div>
          {EnhanceInfo(radios[0].info.props)}
        </div>
        <div>
          <Typography variant="h6" color="secondary-light">
            包材名稱
          </Typography>
          <input
            id="packagingName"
            placeholder="請輸入"
            className={styles.modalInput}
            defaultValue={defaultRadio === 1 ? radios[1].defaultValue : null}
            style={{
              marginTop: 4,
              background:
                packagingMethods !== radios[1].registerName
                  ? 'rgba(229,229,229, 1)'
                  : '',
            }}
            disabled={packagingMethods !== radios[1].registerName}
            onChange={(e) => setPackagingNameValue(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export const EnhanceRadioGroup = enhanceModalActions(RadioGroupComponent);

const finalComponents = [
  ...components,
  { name: 'EnhanceRadioGroup', component: EnhanceRadioGroup },
];

export const getComponent = (name: string) =>
  finalComponents.find((item) => item.name === name)?.component ?? Border;
