// import { TableColumn } from '@mezzanine-ui/core/table';
import { AutoCompleteField, InputField } from '@mezzanine-ui/react-hook-form';
import { API_NAMESPACE, DeliveryOrderDetail, request } from '@solar/data';
import { InputContainerWithUnit, RowSection } from '@solar/templates';
import { Input, TableProps } from 'antd';
import { UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { BarcodeScanInput } from '../../../../MaterialShift/BarcodeScanInput';
import { InspectMaterialType } from '../../../inspect-modal-enum';
import { ProductionDeliveryOrderReceiptModalColumns } from './types';

export function useProductionDeliveryOrderReceiptModalColumns({
  formMethods,
}: {
  formMethods: UseFormReturn<ProductionDeliveryOrderReceiptModalColumns, any>;
}) {
  const { orderType } = useParams<{ orderType: `${InspectMaterialType}` }>();

  const column: TableProps<
    DeliveryOrderDetail['specs'][number]['materialItems'][number] & {
      parentBatchIndex?: number;
    }
  >['columns'] =
    // useMemo(
    // () =>
    [
      {
        title: '料號',
        dataIndex: 'materialId',
        width: 450,
        render: (_, row, index) => {
          if (index === 0 || orderType === InspectMaterialType.CustomerOrder) {
            return row?.materialId;
          }

          return (
            <RowSection label="出貨單" colGap="4px">
              <BarcodeScanInput
                placeholder={'請輸入出貨單號'}
                otherOnKeyDownAction={async (deliveryOrderId) => {
                  try {
                    if (deliveryOrderId) {
                      formMethods.setValue(
                        `table.${row.parentBatchIndex ?? 0}.subBatches.${
                          index - 1
                        }.deliveryOrderId`,
                        deliveryOrderId
                      );
                      const res = await request('/shipped-orders', {
                        namespace: API_NAMESPACE.MATERIALS,
                        params: { deliveryOrderId: deliveryOrderId, limit: 0 },
                        responseParser: (res) => res,
                      });

                      if (res.ok) {
                        const data: {
                          deliveryOrderDetails: {
                            subBatchReferences: {
                              materialSapBatchId: string;
                              materialSubBatchId: string;
                              deliveryOrderId: string;
                              deliveryOrderLine: string;
                            }[];
                          }[];
                        }[] = await res.json();
                        const transformedSubBatchReferences = data
                          .flatMap((data) =>
                            data.deliveryOrderDetails.flatMap((detail) =>
                              detail.subBatchReferences.map((subBatch) => ({
                                name: `${subBatch.materialSapBatchId}-${subBatch.materialSubBatchId}`,
                                materialSapBatchId: subBatch.materialSapBatchId,
                                materialSubBatchId: subBatch.materialSubBatchId,
                                deliveredOrderId: subBatch.deliveryOrderId,
                                deliveredOrderLine: subBatch.deliveryOrderLine,
                              }))
                            )
                          )
                          .map((row, index) => ({
                            id: String(index),
                            ...row,
                          }));

                        formMethods.setValue(
                          `table.${row.parentBatchIndex ?? 0}.subBatches.${
                            index - 1
                          }.batchOptions`,
                          transformedSubBatchReferences
                        );
                      }
                    }
                  } catch (err) {
                    console.log(err);
                  }
                }}
              />
            </RowSection>
          );
        },
      },
      {
        title: '品名',
        dataIndex: 'materialDescription',
        width: 320,
        render: (_, row, index) => {
          if (index === 0 || orderType === InspectMaterialType.CustomerOrder) {
            return row?.materialDescription;
          }

          return (
            <AutoCompleteField
              label="出廠批號"
              options={
                formMethods.watch(
                  `table.${row.parentBatchIndex ?? 0}.subBatches.${
                    index - 1
                  }.batchOptions`
                ) ?? []
              }
              registerName={`table.${row.parentBatchIndex ?? 0}.subBatches.${
                index - 1
              }.batch`}
            />
          );
        },
      },
      {
        title: '應收量',
        dataIndex: 'quantity',
        width: 300,
        render: (_, row, index) => {
          if (index === 0) {
            return row?.quantity;
          }

          return (
            <InputContainerWithUnit
              prefixAdj="數量"
              suffixUnit={row.quantityUnit ?? ''}
            >
              {/* <Input
                type="number"
                onWheel={(e) => e.currentTarget.blur()}
                onChange={(e) => {
                  if (index > 0) {
                    formMethods.setValue(
                      `table.${row.parentBatchIndex ?? 0}.subBatches.${
                        index - 1
                      }.materialInputUnitQuantity`,
                      e.target.value || '0'
                    );
                  }
                }}
              /> */}
              <InputField
                width={200}
                registerName={`table.${row.parentBatchIndex ?? 0}.subBatches.${
                  index - 1
                }.materialInputUnitQuantity`}
              />
            </InputContainerWithUnit>
          );
        },
      },
      {
        title: '單位',
        width: 220,
        dataIndex: 'quantityUnit',
        render: (_, row, index) => {
          if (index === 0) {
            return row?.quantityUnit;
          }

          return (
            <InputField
              label="實際重量"
              placeholder="請輸入實際重量"
              registerName={`table.${row.parentBatchIndex ?? 0}.subBatches.${
                index - 1
              }.weight`}
              // errorMsgRender={({ message }) => (
              //   <Typography variant="body2" style={{ color: 'red' }}>
              //     {message}
              //   </Typography>
              // )}
            />
          );
        },
      },
      {
        title: '批號數量',
        render: (_, row, index) => {
          return (
            <>
              {index === 0 && (
                <Input
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => {
                    if (row.parentBatchIndex || row.parentBatchIndex === 0) {
                      formMethods.setValue(
                        `table.${row.parentBatchIndex}.subBatches`,
                        Array(
                          Math.max(
                            0,
                            Number(Number(e.target.value || 0).toFixed(0))
                          )
                        ).fill({
                          materialInputUnitQuantity: '0',
                          quantityUnit: row.quantityUnit,
                          parentBatchIndex: row.parentBatchIndex,
                        })
                      );
                    }
                  }}
                />
              )}
              {index !== 0 && (
                <InputField
                  placeholder="慶輸入備註"
                  label="備註"
                  registerName={`table.${
                    row.parentBatchIndex ?? 0
                  }.subBatches.${index - 1}.remark`}
                />
              )}
            </>
          );
        },
      },
    ];

  return column;
}
