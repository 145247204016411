import { useMemo } from 'react';
import compact from 'lodash/compact';
import { COLORS } from '../../MaterialViewerPage/colors';
import { Message } from '@mezzanine-ui/react';

export enum MaterialField {
  // GROUP = 'GROUP',
  // LARGE_LEVEL = 'LARGE_LEVEL',
  // MEDIUM_LEVEL = 'MEDIUM_LEVEL',
  // SMALL_LEVEL = 'SMALL_LEVEL',
  COMPONENT_CODE = 'COMPONENT_CODE',
  FEATURE_CODE_1 = 'FEATURE_CODE_1',
  // FEATURE_CODE_2 = 'FEATURE_CODE_2',
  // SIZE = 'SIZE',
  // PROCESSING_CODE = 'PROCESSING_CODE',
  DRAWING_CODE = 'DRAWING_CODE',
}

interface Summary {
  text: string;
  color?: string;
}

export function MaterialHighlight({
  id,
  highlights,
  copyable,
}: {
  id: string;
  highlights: MaterialField[];
  copyable?: boolean;
}) {
  const componentCodePosition = useMemo(() => {
    if (id.match(/^(F|S[FBPSWLGT]|C[NLAC]|W[LS]|P)/)) return [5, 6];
    if (id.match(/[TEA]/)) return [4, 6];
    if (id.match(/^[RO]/)) return [3, 6];

    return null;
  }, [id]);

  const drawingCodePosition = useMemo(() => {
    if (id.match(/^F[BPX]..F.C/)) return [4, 14];
    if (id.match(/^F[BPX]..F/)) return [4, 18];
    if (id.match(/^F[TE].{11}F.C/)) return [13, 14];
    if (id.match(/^F[TE].{11}F/)) return [13, 18];
    if (id.match(/^T.{13}[AS]C/)) return [14, 17];
    if (id.match(/^T.{13}[AS]/)) return [14, 21];
    if (id.match(/^[RO].{10}[AS]C/)) return [11, 17];
    if (id.match(/^[RO].{10}[AS]/)) return [11, 21];
    if (id.match(/^A.{11}[AS]C/)) return [12, 17];
    if (id.match(/^A.{11}[AS]/)) return [12, 21];
    if (id.match(/^B.{5}[AS]C/)) return [4, 17];
    if (id.match(/^B.{5}[AS]/)) return [4, 21];

    return null;
  }, [id]);

  const featureCode1Position = useMemo(() => {
    if (id.match(/^F/)) return [11, 2];
    if (id.match(/^H/)) return [2, 2];
    if (id.match(/^B/)) return [4, 2];
    if (id.match(/^(SE|V)/)) return [5, 2];
    if (id.match(/^[RO]/)) return [9, 2];
    if (id.match(/^[TEA]/)) return [10, 2];
    if (id.match(/^[SCWP]/)) return [11, 2];

    return null;
  }, [id]);

  const highlightPositions = useMemo(
    () =>
      compact([
        highlights.includes(MaterialField.COMPONENT_CODE)
          ? componentCodePosition
          : null,
        highlights.includes(MaterialField.DRAWING_CODE)
          ? drawingCodePosition
          : null,
        highlights.includes(MaterialField.FEATURE_CODE_1)
          ? featureCode1Position
          : null,
      ]).sort(([a], [b]) => a - b),
    [
      componentCodePosition,
      drawingCodePosition,
      featureCode1Position,
      highlights,
    ]
  );

  return (
    <span
      style={{
        cursor: copyable ? 'pointer' : undefined,
      }}
      onClick={() => {
        if (copyable) {
          try {
            navigator.clipboard.writeText(id);

            Message.success(`已複製料號: ${id}`);
          } catch (ex) {
            console.error(ex);
          }
        }
      }}
    >
      {highlightPositions.length
        ? highlightPositions
            .reduce((combined, position, index) => {
              const solvedString = combined.reduce(
                (len, { text }) => text.length + len,
                0
              );

              const prefixString = combined.length
                ? id.substring(solvedString, position[0])
                : id.substring(0, position[0]);

              const endIndex = position[0] + position[1];
              const highlightString = id.substring(position[0], endIndex);

              return [
                ...combined,
                ...(prefixString.length
                  ? [
                      {
                        text: prefixString,
                      },
                    ]
                  : []),
                {
                  text: highlightString,
                  color: COLORS[index],
                },
                ...(index + 1 === highlightPositions.length &&
                endIndex !== id.length
                  ? [
                      {
                        text: id.substring(endIndex),
                      },
                    ]
                  : []),
              ];
            }, [] as Summary[])
            .map((summary, index) => (
              <span key={index} style={{ color: summary.color }}>
                {summary.text}
              </span>
            ))
        : id}
    </span>
  );
}
