import { EditOutlined } from '@ant-design/icons';
import { Button, Message, Table, Typography } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { request, useShipmentPackingDetail } from '@solar/data';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  Br,
  InfoField,
} from '../../OrderManagement/FilterSearch/enhanceFilter/enhanceFilter';
import { Layout } from '../Layout/Layout';
import { getComponent } from '../Layout/ModalLayout/enhanceModalActions/enhanceModalActions';
import { ModalLayout } from '../Layout/ModalLayout/ModalLayout';
import styles from './ShipmentPackingDetailPage.module.scss';

export function ShipmentPackingDetailPage() {
  const layoutMethods = useForm();

  const {
    deliveryOrderId,
    namespace,
    endpoint,
    data: shipmentPackingDetailData,
    mutateDeliveryOrderData,
    boxTotalNum,
    deliveryOrderCreatedat,
    productNum,
    modal,
    methods,
    setModal,
    closeModal,
    createModal,
    disabledModalSubmitButton,
    disabledTableSubmitButton,
    handleUpdateDeliveryTable,
  } = useShipmentPackingDetail();

  const modalBodyComponents = useMemo(
    () =>
      createModal.map((item) => ({
        ...item,
        fn: getComponent(item.fn),
      })),
    [modal, createModal]
  );

  const filtersData = useMemo(
    () => [
      {
        label: '出貨單號',
        fn: InfoField,
        props: {
          text: deliveryOrderId ?? '',
        },
      },
      {
        label: '',
        fn: Br,
        registerName: '',
        props: {},
      },
      {
        label: '建立日期',
        fn: InfoField,
        props: {
          text: deliveryOrderCreatedat,
        },
      },
      {
        label: '',
        fn: Br,
        registerName: '',
        props: {},
      },
      {
        label: '總箱數',
        fn: InfoField,
        props: {
          text: boxTotalNum,
        },
      },
    ],
    [deliveryOrderId, deliveryOrderCreatedat, boxTotalNum]
  );

  const columns = useMemo(
    () => [
      { title: '箱號', dataIndex: 'boxNum' },
      { title: '出貨單項次', dataIndex: 'deliveryOrderLine' },
      { title: '序號', dataIndex: 'snum' },
      { title: '料號', dataIndex: 'materialId', width: 320 },
      { title: '品名', dataIndex: 'materialName' },
      {
        title: '數量 單位',
        align: 'center' as const,
        render(source: any) {
          return (
            <Typography>
              {source?.quantity ?? 0} {source.productUnit ?? ''}
            </Typography>
          );
        },
      },
      { title: '包材名稱', dataIndex: 'packagingName' },
      {
        title: '動作',
        width: 120,
        align: 'center' as const,
        render(source: any) {
          return (
            <div>
              <Button
                type="button"
                prefix={<EditOutlined />}
                onClick={() =>
                  setModal({
                    title: '編輯',
                    confirmButton: '確定',
                    source,
                  })
                }
              ></Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const handleSubmit = useCallback(async () => {
    try {
      const body = shipmentPackingDetailData.map((detail) => ({
        ...detail,
        id: `${detail.id}`.split('-')[0],
      }));
      await request(endpoint.replace('/get', ''), {
        method: 'POST',
        body: JSON.stringify(body),
        namespace,
      });
      mutateDeliveryOrderData();

      Message.success('更新成功');
    } catch (err) {
      if (err instanceof Error) {
        Message.error(JSON.parse(err.message).message);
      } else {
        Message.error('更新失敗，請再試一次');
      }
    }
  }, [endpoint, shipmentPackingDetailData, namespace]);

  return (
    <div className={styles.wrapper}>
      <ModalLayout
        title={modal?.title}
        open={modal}
        width={864}
        onClose={closeModal}
        onConfirm={() =>
          handleUpdateDeliveryTable(
            modal?.source?.id?.split('-')[0],
            boxTotalNum
          )
        }
        onConfirmDelete={() => {}}
        confirmButtonText={modal?.confirmButton}
        confirmButtonDisable={disabledModalSubmitButton}
        deleteButton={false}
        deleteModal={null}
        setDeleteModal={() => {}}
        deleteModalChildren={<div></div>}
      >
        <div style={{ marginBottom: -32 }}>
          <div className={styles.expandTable} style={{ maxWidth: '80%' }}>
            <div className={styles.td}>
              <Typography>料號：</Typography>
              <Typography>{modal?.source?.materialId ?? '無'}</Typography>
            </div>
            <div className={styles.td}>
              <Typography>數量：</Typography>
              <Typography>
                {modal?.source?.deliveryOrderLineQuantity ?? '無'}
              </Typography>
            </div>
            <div className={styles.td}>
              <Typography>數量單位：</Typography>
              <Typography>{modal?.source?.productUnit ?? '無'}</Typography>
            </div>
            <div className={styles.td}>
              <Typography>品名：</Typography>
              <Typography>{modal?.source?.materialName ?? '無'}</Typography>
            </div>
            <div className={styles.td}>
              <Typography>預計展開：</Typography>
              <Typography>{productNum ?? 0} 箱</Typography>
            </div>
          </div>
          <FormFieldsWrapper methods={methods} className={styles.modalBody}>
            {modalBodyComponents.map((component, index) => (
              <div key={index}>{component.fn(component)}</div>
            ))}
          </FormFieldsWrapper>
        </div>
      </ModalLayout>

      <Layout
        title="包裝編輯"
        filterHeader=""
        noFilterHeaderBorder={true}
        button={<div></div>}
        filtersData={filtersData}
        filterMethods={() => {}}
        filterDependencies={[]}
        registers={[]}
        submitButton={null}
        methods={layoutMethods}
      >
        <Table
          columns={columns}
          dataSource={shipmentPackingDetailData ?? []}
          bodyClassName={styles.table}
          headerClassName={styles.tableHeader}
        />
      </Layout>
      <div className={styles.submitButtonWrapper}>
        <Button
          type="button"
          variant="contained"
          disabled={disabledTableSubmitButton}
          onClick={handleSubmit}
        >
          確認送出
        </Button>
      </div>
    </div>
  );
}
