import { Message, SelectValue } from '@mezzanine-ui/react';
import {
  FormAnswer,
  GetProductionFormAnswerParams,
  getProductionFormAnswers,
  ProductionFormFieldType,
  ProductionFormResponse,
} from '@solar/data';
import { useCallback, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

interface UseGetProductionFormsAnswersProps {
  methods: UseFormReturn<any>;
  registerName: string;
  formsMap?: Map<string, ProductionFormResponse[]>;
  queryConditions: Partial<GetProductionFormAnswerParams>[];
  isFormRendered: boolean;
}

export function useGetProductionFormsAnswers({
  methods,
  registerName,
  formsMap,
  queryConditions,
  isFormRendered,
}: UseGetProductionFormsAnswersProps) {
  const { getValues, setValue, trigger } = methods;

  const [formAnswersMap, setFormAnswersMap] = useState(
    new Map<string, FormAnswer[]>()
  );

  const [formAnswersLoading, setFormAnswersLoading] = useState(false);

  const overwriteAnswerIfHasSameFieldName = useCallback(
    (
      key: string,
      forms: ProductionFormResponse[],
      formAnswers: FormAnswer[]
    ) => {
      const formAnswerMap = new Map(
        formAnswers.map((formAnswer) => [formAnswer.form.id, formAnswer.answer])
      );
      forms?.forEach((form) => {
        if (form?.id && form?.fieldName) {
          const fieldRegisterName = `${registerName}.${key}.${form?.id}`;
          const targetFieldAnswer = formAnswerMap.get(form.id);

          if (targetFieldAnswer) {
            switch (form.fields.type) {
              case ProductionFormFieldType.ENUM: {
                setValue(fieldRegisterName, {
                  id: targetFieldAnswer,
                  name: targetFieldAnswer,
                });
                break;
              }
              case ProductionFormFieldType.NUMERIC: {
                // 數值型態的答案如果是以 '<' 開頭，則預設為 0
                setValue(
                  fieldRegisterName,
                  targetFieldAnswer.startsWith('<') ? '0' : targetFieldAnswer
                );
                break;
              }
              default: {
                setValue(fieldRegisterName, targetFieldAnswer);
                break;
              }
            }
          }

          const formAnswerWithSameFieldName = formAnswers?.find(
            (formAnswer) => formAnswer.form.fieldName === form.fieldName
          );

          if (!targetFieldAnswer && formAnswerWithSameFieldName) {
            const sourceFieldAnswer =
              formAnswerWithSameFieldName?.answer?.startsWith('<')
                ? '0'
                : formAnswerWithSameFieldName?.answer;
            const sourceFieldType =
              formAnswerWithSameFieldName.form.fields.type;
            const targetFieldType = form.fields.type;
            const sameType = sourceFieldType === targetFieldType;

            if (sameType && sourceFieldAnswer) {
              setValue(fieldRegisterName, sourceFieldAnswer);
            } else if (!sameType && sourceFieldAnswer) {
              switch (`${sourceFieldType}>${targetFieldType}`) {
                case `${ProductionFormFieldType.TEXT}>${ProductionFormFieldType.NUMERIC}`: {
                  console.warn(
                    'form answer type is not match: answer is text, but field is numeric'
                  );
                  const targetFieldAnswer = sourceFieldAnswer.trim();
                  if (
                    !isNaN(parseFloat(targetFieldAnswer)) &&
                    isFinite(parseFloat(targetFieldAnswer))
                  ) {
                    setValue(fieldRegisterName, targetFieldAnswer);
                  }
                  break;
                }
                case `${ProductionFormFieldType.NUMERIC}>${ProductionFormFieldType.TEXT}`: {
                  console.warn(
                    'form answer type is not match: answer is numeric, but field is text'
                  );
                  setValue(fieldRegisterName, sourceFieldAnswer);
                  break;
                }

                case `${ProductionFormFieldType.ENUM}>${ProductionFormFieldType.TEXT}`:
                case `${ProductionFormFieldType.ENUM}>${ProductionFormFieldType.NUMERIC}`: {
                  console.warn(
                    'form answer type is not match: answer is enum, but field is text or numeric'
                  );
                  const targetFieldAnswer = (
                    sourceFieldAnswer as unknown as SelectValue
                  )?.id;

                  if (targetFieldAnswer) {
                    setValue(fieldRegisterName, targetFieldAnswer);
                  }
                  break;
                }

                case `${ProductionFormFieldType.TEXT}>${ProductionFormFieldType.ENUM}`:
                case `${ProductionFormFieldType.NUMERIC}>${ProductionFormFieldType.ENUM}`: {
                  console.warn(
                    'form answer type is not match: answer is text or numeric, but field is enum'
                  );
                  setValue(fieldRegisterName, {
                    id: sourceFieldAnswer,
                    name: sourceFieldAnswer,
                  });
                  break;
                }
                default: {
                  Message.warning(
                    `題目預設答案的格式不符合，欄位名稱：${form?.fieldName}`
                  );
                  break;
                }
              }
            }
          }
        }
      });
    },
    [registerName, setValue]
  );

  const compareFormAnswersFromMap = useCallback(
    (key: string) => {
      const currentAnswers = getValues(`${registerName}.${key}`) as Record<
        string,
        string | SelectValue
      >;

      const formMap = new Map(
        [...(formsMap?.values() ?? [])].flat().map((form) => [form.id, form])
      );

      const originalAnswersMap = new Map(
        (formAnswersMap.get(key) ?? []).map((formAnswer) => [
          formAnswer.form.id,
          formAnswer.answer,
        ])
      );

      return Object.entries(currentAnswers ?? {}).reduce<
        Record<string, string>
      >((result, [formId, answer]) => {
        const form = formMap.get(formId);
        const originalAnswer = originalAnswersMap.get(formId);

        const ans = (() => {
          switch (form?.fields?.type) {
            case ProductionFormFieldType.ENUM:
              return (answer as SelectValue<string>)?.id ?? '';
            default:
              return (answer as string) ?? '';
          }
        })();

        if (
          (originalAnswer && ans !== originalAnswer) ||
          (!originalAnswer && ans)
        ) {
          return Object.assign({}, result, {
            [formId]: ans,
          });
        }

        return result;
      }, {});
    },
    [formAnswersMap, formsMap, getValues, registerName]
  );

  useEffect(() => {
    let isMounted = true;

    if (
      !trigger ||
      !isMounted ||
      !formsMap ||
      !isFormRendered ||
      !queryConditions.length
    )
      return;

    const queryConditionsMap = new Map(
      queryConditions.reduce<
        [string, Partial<GetProductionFormAnswerParams>][]
      >((result, condition) => {
        if (
          condition?.materialId &&
          condition?.materialSapBatchId &&
          condition?.materialSubBatchId
        ) {
          const key = [
            condition.materialId,
            condition.materialSapBatchId,
            condition.materialSubBatchId,
          ].join('_');
          return result.concat([[key, condition]]);
        }
        return result;
      }, [])
    );

    const requests = Array.from(queryConditionsMap).filter(
      ([key, condition]) =>
        !formAnswersMap.get(key) &&
        formsMap?.has(condition.materialId as string)
    );

    if (!requests.length) {
      return;
    }

    const fetchFormAnswers = async (
      key: string,
      condition: Partial<GetProductionFormAnswerParams>
    ) => {
      try {
        const { records } = await getProductionFormAnswers(
          Object.assign(
            {
              offset: 0,
              withLims: false,
              selectDefaultAndPicked: false,
              latestOnly: true,
              templateTypes: ['PRODUCT', 'EQUIPMENT'],
            },
            condition
          )
        );

        return records;
      } catch (error) {
        Message.error(`${key}：取得表單答案失敗`);
        throw error;
      }
    };

    (async () => {
      const tmpFormAnswersMap = new Map();

      try {
        setFormAnswersLoading(true);

        const batchSize = 5;
        for (let i = 0; i < requests.length; i += batchSize) {
          const batch = requests.slice(i, i + batchSize);

          const responses = await Promise.allSettled(
            batch.map(([key, condition]) => fetchFormAnswers(key, condition))
          );

          batch.forEach(([key, condition], index) => {
            const forms = formsMap?.get(condition?.materialId as string) ?? [];
            const response = responses[index];
            if (response.status === 'fulfilled') {
              const formAnswers = response.value;
              tmpFormAnswersMap.set(key, formAnswers);
              overwriteAnswerIfHasSameFieldName(key, forms, formAnswers);
            }
          });

          await trigger(batch?.map(([key]) => [registerName, key].join('.')));
        }
      } finally {
        setFormAnswersLoading(false);
        setFormAnswersMap((prev) => {
          const newMap = new Map(prev);
          tmpFormAnswersMap.forEach((formAnswers, key) => {
            newMap.set(key, formAnswers);
          });
          return newMap;
        });
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [
    registerName,
    formsMap,
    formAnswersMap,
    queryConditions,
    overwriteAnswerIfHasSameFieldName,
    trigger,
    isFormRendered,
  ]);

  const resetFormAnswersMap = useCallback((keys?: string[]) => {
    setFormAnswersMap((prev) => {
      if (Array.isArray(keys)) {
        keys.forEach((key) => {
          prev.delete(key);
        });
        return new Map(prev);
      }
      return new Map();
    });
  }, []);

  return {
    formAnswersMap,
    setFormAnswersMap,
    resetFormAnswersMap,
    formAnswersLoading,
    compareFormAnswersFromMap,
  };
}
