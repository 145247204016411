import useSWR from 'swr';
import { API_NAMESPACE, request } from '../request';
import { useState } from 'react';
import { PageInfo } from '../pagination';
import {
  GetIQCDeliveryOrdersParams,
  GetIQCOrdersParams,
  GetIQCPurchaseOrdersParams,
  IncomingPurchaseOrder,
  IQCOrderStatus,
} from './incoming-quality-control.type';

const namespace = API_NAMESPACE.QC;

export function useGetIQCPurchaseOrders(
  defaultParams?: GetIQCPurchaseOrdersParams
) {
  const [params, setParams] = useState<GetIQCPurchaseOrdersParams | undefined>(
    defaultParams
  );
  const { data, error, mutate, isValidating } = useSWR<{
    records: IncomingPurchaseOrder[];
    pageInfo: PageInfo;
  }>([
    '/iqc/orders/purchase-orders',
    {
      namespace,
      params,
    },
  ]);

  return {
    iqcPurchaseOrders: data?.records ?? [],
    pageInfo: data?.pageInfo,
    isLoading: !data && !error && !isValidating,
    refetchGetIQCPurchaseOrders: setParams,
    mutateGetIQCPurchaseOrders: mutate,
  };
}

export function useGetIQCDeliveryOrders(
  defaultParams?: GetIQCDeliveryOrdersParams
) {
  const [params, setParams] = useState<GetIQCDeliveryOrdersParams | undefined>(
    defaultParams
  );
  const { data, error, mutate, isValidating } = useSWR<{
    records: IncomingPurchaseOrder[];
    pageInfo: PageInfo;
  }>([
    '/iqc/orders/delivery-orders',
    {
      namespace,
      params,
    },
  ]);

  return {
    iqcDeliveryOrders: data?.records ?? [],
    pageInfo: data?.pageInfo,
    isLoading: !data && !error && isValidating,
    refetchGetIQCDeliveryOrders: setParams,
    mutateGetIQCDeliveryOrders: mutate,
  };
}

export function useGetIQCPurchaseOrderReturnable(
  defaultParams?: GetIQCOrdersParams
) {
  const [params, setParams] = useState<GetIQCOrdersParams | undefined>(
    defaultParams
  );
  const { data, mutate, error, isValidating } = useSWR<{
    records: IncomingPurchaseOrder[];
    pageInfo: PageInfo;
  }>(['/iqc/orders/purchase-orders/returnable', { params, namespace }]);

  return {
    iqcPurchaseOrdersReturnable: data?.records ?? [],
    pageInfo: data?.pageInfo,
    loading: !data && !error && isValidating,
    refetchGetIQCPurchaseOrderReturnable: setParams,
    mutateGetIQCPurchaseOrderReturnable: mutate,
  };
}

export function useGetIQCOrderStatuses() {
  const { data } = useSWR<{
    records: IQCOrderStatus[];
    pageInfo: PageInfo;
  }>(['/iqc/statuses', { namespace }]);

  return { statuses: data?.records ?? [] };
}

export function updateIQCOrderStatus(
  id: number,
  payload: {
    updatedStatusId: number;
    qcNote?: string;
    demandedUnitNote?: string;
    warehouseNote?: string;
    waiverNumber?: string;
  }
) {
  return request(`/iqc/orders/${id}`, {
    method: 'PUT',
    namespace,
    body: JSON.stringify(payload),
  });
}
