import {
  AutoCompleteMultiField,
  DatePickerField,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import styles from './../../TargetNumber.module.scss';
import {
  CalendarConfigProvider,
  SelectValue,
  Typography,
} from '@mezzanine-ui/react';
import CalendarMethodsMoment from '@mezzanine-ui/core/calendarMethodsMoment';
import {
  Customer,
  RuleOption,
  TargetRuleCreateFormFields,
} from '../../TargetNumber.interface';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetTargetCodeRules, useGetTargetRuleCustomers } from '@solar/data';

interface TargetRuleCreateBasicsProps {
  onData: (data: RuleOption[]) => void;
  setCustomerIds: (ids: string[]) => void;
  isViewOnly: boolean;
  isEditing?: boolean;
  isUsed?: boolean;
  onApplyCustomerChange: (isEmpty: boolean) => void;
}

export function TargetRuleCreateBasics({
  onApplyCustomerChange,
  onData,
  setCustomerIds,
  isViewOnly = false,
  isEditing = false,
  isUsed = false,
}: TargetRuleCreateBasicsProps) {
  const methods = useFormContext<TargetRuleCreateFormFields>();
  const { watch } = useFormContext();
  const applyCustomer = watch('applyCustomer');

  const { ruleTypeOptions: internalRuleTypeOptions } = useGetTargetCodeRules({
    type: 'internal',
    ...(isViewOnly ? { include_deleted: 1 } : {}),
  });

  const { ruleTypeOptions: customerRuleTypeOptions } = useGetTargetCodeRules({
    type: 'customer',
    ...(isViewOnly ? { include_deleted: 1 } : {}),
  });

  const [ruleTypeOptions, setRuleTypeOptions] = useState<RuleOption>();
  const ruleType = methods.watch('ruleType');
  const ruleTypeId = ruleType?.id;

  useEffect(() => {
    switch (ruleTypeId) {
      case 'internal':
        setRuleTypeOptions(internalRuleTypeOptions);
        onData(internalRuleTypeOptions);
        break;
      case 'customer':
        setRuleTypeOptions(customerRuleTypeOptions);
        onData(customerRuleTypeOptions);
        break;
      default:
        setRuleTypeOptions(undefined);
    }
  }, [ruleTypeId, internalRuleTypeOptions, customerRuleTypeOptions, onData]);

  useEffect(() => {
    onApplyCustomerChange(!applyCustomer || applyCustomer.length === 0);
  }, [applyCustomer, onApplyCustomerChange]);

  const [customerQuery, setCustomerQuery] = useState('');
  const { targetRuleCustomers } = useGetTargetRuleCustomers(customerQuery);

  const handleSelectionChange = (
    selectedOptions?: SelectValue<string>[] | undefined
  ) => {
    const ids = selectedOptions?.map((option) => option.id) || [];
    setCustomerIds(ids);
    setCustomerQuery('');
  };

  useEffect(() => {
    if (applyCustomer) {
      const ids = applyCustomer.map((customer: Customer) => customer.id);
      setCustomerIds(ids);
      setCustomerQuery('');
    }
  }, [applyCustomer, setCustomerIds]);

  return (
    <FormFieldsWrapper methods={methods} className={styles.filter}>
      <CalendarConfigProvider methods={CalendarMethodsMoment}>
        <div className={styles.formContent}>
          <Typography variant="h5">規則名稱:</Typography>
          <InputField
            size="medium"
            registerName="ruleName"
            width={250}
            required
            disabled={isViewOnly || isEditing}
          />
          <Typography variant="h5">靶編類型:</Typography>
          <SelectField
            registerName="ruleType"
            options={[
              { id: 'internal', name: '光洋' },
              { id: 'customer', name: '客戶' },
            ]}
            required
            disabled
          />
          <Typography variant="h5">有效日期:</Typography>
          <DatePickerField registerName="validDateStart" width={180} required disabled={isViewOnly || isEditing} />
          <Typography variant="h5">-</Typography>
          <DatePickerField registerName="validDateEnd" width={180} disabled={isViewOnly || isUsed} />
        </div>
        <div className={styles.formContent}>
          <Typography variant="h5">套用客戶:</Typography>
          {ruleTypeId === 'internal' ? (
            <Typography variant="h4">此內部靶編規則已套用至全客戶</Typography>
          ) :
          <AutoCompleteMultiField
            debounceMs={1000}
            autoClickAwayDebounceMs={500}
            registerName="applyCustomer"
            options={targetRuleCustomers || []}
            width={1115}
            required
            onSearch={(inputValue) => setCustomerQuery(inputValue)}
            onChange={handleSelectionChange}
            disabled={isViewOnly}
          />}
        </div>
      </CalendarConfigProvider>
    </FormFieldsWrapper>
  );
}
