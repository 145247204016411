import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';
import { CostUnitOptions } from 'apps/frontend/src/app/Transportation/RequirementsManagementPage/RequirementsManagement.config';

export function CostBUSelector() {
  return (
    <AutoCompleteField
      label="運輸成本歸屬單位"
      required
      registerName="costUnit"
      options={CostUnitOptions}
    />
  );
}
