import { Button, Message, Typography } from '@mezzanine-ui/react';
import { TableCell } from '@mezzanine-ui/react/Table';
import { checkJWTRoles, responseMap, useRoleList } from '@solar/data';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  SearchInput,
  TreeSelectComponent,
} from '../../OrderManagement/FilterSearch/enhanceFilter/enhanceFilter';
import {
  EnhanceModalSearchInput,
  EnhanceSelect,
  EnhanceTreeSelect,
} from '../../SalesAndDistribution/Layout/ModalLayout/enhanceModalActions/enhanceModalActions';
import {
  AuthListLayout,
  AuthListLayoutProps,
  ExpandableType,
  FilterType,
} from '../Layout/ListLayout';
import { defaultResponse } from '../LoginPage/LoginPage';
import { CreateEditModal } from '../Modal/CreateEditModal';
import { WarningModal } from '../Modal/WarningModal';
import { formatChineseDate } from '../TenantPage/TenantPage';
import styles from './RolePage.module.scss';

type Action = {
  id: number;
  name: string;
};

export type ResourceActionsType = {
  id: number;
  name: string;
  actions: Action[];
};

export default function RolePage() {
  const {
    tenantSelectData,
    filterPoliciesData,
    onLoadFilterPoliciesData,
    dataSource,
    resources,
    methods,
    filterMethods,
    currentPage,
    onChangeCurrentPage,
    pageSize,
    onChangePageSize,
    openModal,
    modalSource,
    modalTitle,
    onOpenWarningModal,
    confirmDelete,
    onCloseModal,
    onOpenCreateEditModal,
    queryRoles,
    createEditModal: onCreateEditRole,
  } = useRoleList();

  const confirmCreateEditModal = useCallback(async () => {
    const res = await onCreateEditRole();
    if (res?.status === 'success') {
      Message.success('成功');
    } else {
      const title: string = res?.title ?? '';
      Message.error(responseMap?.[title] ?? defaultResponse);
    }
  }, [onCreateEditRole]);

  const confirmDeleteMessage = useCallback(async () => {
    const res = await confirmDelete();
    if (res?.status === 'success') {
      Message.success('刪除成功');
    } else {
      const title: string = res?.title ?? '';
      Message.error(responseMap?.[title] ?? defaultResponse);
    }
  }, [confirmDelete]);

  const [disableConfirm, setDisableConfirm] = useState(true);

  const roleTreeSelectTemplate = () => {
    return (
      <TreeSelectComponent
        treeDataShouldNotDisabled={false}
        methods={filterMethods}
        multiple={true}
        disabled={false}
        onLoadData={onLoadFilterPoliciesData}
        width={550}
        treeData={filterPoliciesData ?? []}
        placeholder="請選擇"
        registerName="policies"
        hasResourceActions={true}
      />
    );
  };

  const filtersData: FilterType[][] = useMemo(
    () => [
      [
        {
          label: '角色名稱',
          fn: SearchInput,
          registerName: 'roleName',
          props: {
            width: 160,
            clearble: true,
            placeholder: '請輸入',
            name: 'roleName',
            register: filterMethods.register,
            setValue: filterMethods.setValue,
          },
        },
        {
          customComponent: true,
          label: '權限',
          // fn: TreeSelectField,
          fn: roleTreeSelectTemplate,
          props: {
            key: 'policies-filter',
            width: 550,
            multiple: true,
            treeData: filterPoliciesData ?? [],
            disabled: false,
            registerName: 'policies',
            methods: filterMethods,
            onLoadData: onLoadFilterPoliciesData,
            placeholder: '請選擇',
          },
        },
      ],
    ],
    [
      filterMethods,
      filterPoliciesData,
      onLoadFilterPoliciesData,
      roleTreeSelectTemplate,
    ]
  );

  const columns = useMemo(
    () => [
      { title: '模組', dataIndex: 'tenant_name', width: 120 },
      { title: '角色名稱', dataIndex: 'name' },
      {
        title: '最後編輯時間',
        render(source: any) {
          const chineseDate = formatChineseDate(source.updated_at);
          return (
            <TableCell
              forceShownTooltipWhenHovered={true}
              tooltipTitle={chineseDate}
              style={{ width: '100%', padding: 0 }}
            >
              <Typography
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {chineseDate}
              </Typography>
            </TableCell>
          );
        },
      },
      {
        title: '建立時間',
        render(source: any) {
          const chineseDate = formatChineseDate(source.created_at);
          return (
            <TableCell
              forceShownTooltipWhenHovered={true}
              tooltipTitle={chineseDate}
              style={{ width: '100%', padding: 0 }}
            >
              <Typography
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {chineseDate}
              </Typography>
            </TableCell>
          );
        },
      },
      {
        title: '動作',
        width: 300,
        align: 'center' as const,
        render(source: any) {
          return (
            checkJWTRoles([61]) && (
              <div className={styles.columnsActionWrapper}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => onOpenCreateEditModal(source, '編輯角色')}
                >
                  編輯
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  danger={true}
                  onClick={() => onOpenWarningModal(source)}
                >
                  刪除
                </Button>
              </div>
            )
          );
        },
      },
    ],
    [onOpenWarningModal, onOpenCreateEditModal]
  );

  const createEditModalFields = useMemo(
    () => [
      {
        label: '模組',
        required: true,
        fn: EnhanceSelect,
        componentsProps: {
          width: 224,
          registerName: 'module',
          model: 'single',
          options: tenantSelectData,
          disabled:
            (modalSource ||
              methods.control._formValues?.['policies']?.length > 0) ??
            false,
          defaultValue:
            tenantSelectData.filter(
              (tenant) => parseInt(tenant.id) === modalSource?.tenant_id
            )?.[0] ?? null,
          clearable: false,
          placeholder: '請選擇',
        },
      },
      {
        label: '名稱',
        required: true,
        fn: EnhanceModalSearchInput,
        componentsProps: {
          registerName: 'name',
          width: 224,
          clearable: true,
          placeholder: '請輸入',
          register: methods.register,
          setValue: methods.setValue,
          name: 'name',
          defaultValue: modalSource?.name ?? '',
        },
      },
      {
        label: '權限',
        required: true,
        fn: EnhanceTreeSelect,
        componentsProps: {
          width: 224,
          multiple: true,
          treeData: resources ?? [],
          disabled: !resources,
          registerName: 'policies',
          methods,
          placeholder: '請選擇',
        },
      },
    ],
    [
      methods,
      modalSource,
      tenantSelectData,
      resources,
      methods.control._formValues?.['policies'],
    ]
  );

  const expandable: ExpandableType = useMemo(
    () => ({
      expandedRowRender: (record) => {
        const resource_with_actions: ResourceActionsType[] =
          record?.resource_with_actions ?? [];
        return (
          <div className={styles.expandedRow}>
            {resource_with_actions.map((resource) => (
              <div key={resource.id} style={{ display: 'flex' }}>
                <Typography>{resource.name}：</Typography>
                {resource.actions.map((action, index) => (
                  <Typography key={action.id}>
                    {action.name}
                    {index !== resource.actions.length - 1 && '、'}
                  </Typography>
                ))}
              </div>
            ))}
          </div>
        );
      },
      rowExpandable: (record) => {
        const resource_with_actions: ResourceActionsType[] =
          record?.resource_with_actions ?? [];
        return resource_with_actions.length > 0;
      },
    }),
    []
  );

  const authListLayoutProps: AuthListLayoutProps = useMemo(
    () => ({
      title: '角色清單',
      titleButton: (
        <Button
          type="button"
          variant="contained"
          disabled={!checkJWTRoles([61])}
          onClick={() => onOpenCreateEditModal(null, '新增角色')}
        >
          新增角色
        </Button>
      ),
      filtersData,
      columns,
      dataSource,
      tableTotal: 0,
      tableCurrent: currentPage,
      onChangeCurrentPage,
      tablePageSize: pageSize,
      onChangePageSize,
      methods: filterMethods,
      expandable,
      onSearch: queryRoles,
      noPagination: true,
    }),
    [
      filtersData,
      columns,
      dataSource,
      currentPage,
      onChangeCurrentPage,
      pageSize,
      onChangePageSize,
      filterMethods,
      expandable,
      queryRoles,
    ]
  );

  useEffect(() => {
    queryRoles();
  }, []);

  useEffect(() => {
    methods.watch();
    const requiredFields = createEditModalFields.filter(
      (field) => field.required
    );

    const disabled = requiredFields.some((field) => {
      const registerName: string = field.componentsProps.registerName ?? '';
      const value = methods.getValues(registerName);

      if (typeof value === 'string') {
        return value.trim() === '' || value === 'undefined';
      }
      if (Array.isArray(value))
        return value.length === 0 || value === undefined;
      return value == undefined || value == null;
    });

    setDisableConfirm(disabled);
  }, [methods.watch(), createEditModalFields, setDisableConfirm]);

  return (
    <div>
      <AuthListLayout {...authListLayoutProps} />
      <WarningModal
        title="確定要刪除此角色嗎？"
        source={null}
        openModal={openModal}
        onCloseModal={onCloseModal}
        danger={true}
        onConfirmModal={confirmDeleteMessage}
      />
      <CreateEditModal
        title={modalTitle}
        openModal={openModal}
        onCloseModal={onCloseModal}
        methods={methods}
        fields={createEditModalFields}
        source={modalSource}
        onConfirm={confirmCreateEditModal}
        confirmButtonDisalbed={disableConfirm}
      />
    </div>
  );
}
