import { Message } from '@mezzanine-ui/react';
import {
  MaterialAllocationFormValues,
  calculateMaterialAllocations,
} from '@solar/data';
import { useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

export function useGetCalculatedMaterialAllocations({
  methods,
  materialId,
  getTargetWeightByUnit,
}: {
  methods: UseFormReturn<MaterialAllocationFormValues>;
  materialId: string;
  getTargetWeightByUnit: (
    targetWeight: MaterialAllocationFormValues['targetWeight']
  ) => MaterialAllocationFormValues['targetWeight'];
}) {
  const [calculating, setCalculating] = useState<boolean>(false);
  const [calculatedData, setCalculatedData] = useState<any[]>([]);

  const getCalculatedMaterialAllocations = useCallback(async () => {
    try {
      setCalculating(true);

      if (!materialId) {
        return;
      }

      const formState = methods?.getValues();

      if ((formState?.targetWeight ?? 0) <= 0) {
        Message.error('請輸入目標重量');
        return;
      }

      const response = await calculateMaterialAllocations(materialId, {
        manualData:
          formState?.manualData?.map((item) => ({
            ...item,
            usedOldMaterial: item?.usedOldMaterial === 'true',
          })) ?? [],
        targetWeight: getTargetWeightByUnit(formState?.targetWeight ?? 0),
      });
      setCalculatedData(response?.data);

      Message.success('計算成功');
    } catch (error) {
      Message.error('計算錯誤');
    } finally {
      setCalculating(false);
    }
  }, [materialId, methods, getTargetWeightByUnit]);

  return {
    calculating,
    calculatedData,
    getCalculatedMaterialAllocations,
  };
}
