import { useContext } from 'react';
import { LocationControlContext } from './LocationControlContext';

export function useLocationControlContext() {
  const context = useContext(LocationControlContext);

  if (!context) {
    throw new Error(
      'useLocationControlContext must be used within a LocationControlProvider'
    );
  }

  return context;
}
