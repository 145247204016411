import type { HubWarehouseFilterFormValues } from '@solar/data';
import { HubWarehouseFilterFormFieldName as FormFieldName } from '@solar/data';
import { InputField } from '@mezzanine-ui/react-hook-form';
import { useFormContext } from 'react-hook-form';

export function MaterialIdInput() {
  const formMethods = useFormContext<HubWarehouseFilterFormValues>();

  return (
    <>
      <div>料號：</div>
      <div>
        <InputField
          control={formMethods.control}
          registerName={FormFieldName.MATERIAL_ID}
        />
      </div>
    </>
  );
}
