import { DownloadIcon } from '@mezzanine-ui/icons';
import { Button, Icon, Table, Typography } from '@mezzanine-ui/react';
import {
  DateRangePickerField,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { ColumnType, schemas, useHumanResourceEmpDetail } from '@solar/data';
import { DetailModal } from '../HumanResourceReportPage/HumanResourceReportDetailsPage/DetailModal/DetailModal';
import styles from './HumanResourceEmpDetailPage.module.scss';

export function HumanResourceEmpDetailPage() {
  const {
    methods,
    data,
    queryParams,
    setQueryParams,
    modal,
    setModal,
    statusOptions,
    isExportSource,
    handleCloseModal,
    onSubmit,
  } = useHumanResourceEmpDetail();

  const columns = [
    { title: '#', dataIndex: 'id', width: 120 },
    { title: '報表名稱', dataIndex: 'name' },
    { title: '狀態', dataIndex: 'status', width: 200 },
    { title: '生成日期', dataIndex: 'createdAt' },
    {
      title: '篩選條件',
      width: 120,
      render(source: ColumnType) {
        return (
          <Button
            variant="outlined"
            type="button"
            onClick={() => {
              setModal({
                name: source.name,
                id: source.id,
                conditions: source.conditions,
              });
            }}
          >
            查看
          </Button>
        );
      },
    },
    {
      title: '下載',
      width: 70,
      render(source: unknown) {
        if (!isExportSource(source)) return;
        if (!source.filePath) return null;
        return (
          <a
            href={source.filePath}
            download={source.name}
            className={styles.filePath}
          >
            <Icon icon={DownloadIcon} size={32} />
          </a>
        );
      },
    },
  ];

  return (
    <FormFieldsWrapper methods={methods} onSubmit={(e) => onSubmit(e)}>
      {modal && (
        <DetailModal
          name={modal.name}
          bonusConditions={modal.conditions}
          onClose={handleCloseModal}
        />
      )}
      <div className={styles.filterWrapper}>
        <div className={styles.filter}>
          <Typography>處理狀態：</Typography>
          <SelectField
            registerName={schemas.STATUS}
            options={statusOptions}
            clearable
          />
        </div>
        <div className={styles.filter}>
          <Typography>生成日期：</Typography>
          <DateRangePickerField registerName={schemas.DATE} />
        </div>
        <div className={styles.filter}>
          <Typography>報表名稱：</Typography>
          <InputField width={224} registerName={schemas.NAME} />
        </div>
        <div className={styles.submitButton}>
          <Button type="submit" variant="contained">
            查詢
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={data?.data ?? []}
        pagination={{
          disableAutoSlicing: true,
          total: data?.total ?? 0,
          current: queryParams.page,
          onChange: (page) => {
            setQueryParams((params) => ({ ...params, page }));
          },
          options: {
            pageSize: queryParams.perPage,
            onChangePageSize: (pageSize) => {
              setQueryParams((params) => ({
                ...params,
                page: 1,
                perPage: pageSize,
              }));
            },
            showPageSizeOptions: true,
            pageSizeLabel: '每頁顯示：',
            pageSizeUnit: '筆',
          },
        }}
      />
    </FormFieldsWrapper>
  );
}
