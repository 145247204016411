import { useCallback, useMemo, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { Modal } from '../mainFile';

type Title = '新增' | '編輯' | '選擇試算項次' | '試算' | '試算結果';

export function usePackagingEstimateModal(
  methods: UseFormReturn<FieldValues, any>
) {
  const [modal, setModal] = useState<Modal<Title>>(null);

  const createModal = useMemo(
    () => [
      {
        label: '訂單編號',
        required: false,
        fn: 'Info',
        componentsProps: {
          content: modal?.source?.orderId,
        },
      },
      {
        label: '',
        required: false,
        fn: 'BorderVertical',
        componentsProps: {
          height: 56,
        },
      },
      {
        label: '訂單日期',
        required: false,
        fn: 'Info',
        componentsProps: {
          content: modal?.source?.customerOrderDate,
        },
      },
      {
        label: '',
        required: false,
        fn: 'BorderVertical',
        componentsProps: {
          height: 56,
        },
      },
      {
        label: '客戶',
        required: false,
        fn: 'Info',
        componentsProps: {
          content: modal?.source?.customerName,
        },
      },
      {
        label: '',
        required: false,
        fn: 'BorderVertical',
        componentsProps: {
          height: 56,
        },
      },
      {
        label: '訂單項次數量',
        required: false,
        fn: 'Info',
        componentsProps: {
          content: modal?.source?.detailCount,
        },
      },
    ],
    [modal]
  );

  const modalTableColumns = useMemo(
    () => [
      { title: '項次', dataIndex: 'salesOrderLine', width: 70 },
      { title: '項次料號', dataIndex: 'materialId' },
      { title: '品名', dataIndex: 'materialName' },
      { title: '數量', dataIndex: 'quantity' },
      { title: '單位', dataIndex: 'unit' },
    ],
    []
  );

  const closeModal = useCallback(() => setModal(null), []);

  const confirmModal = useCallback(() => {}, []);

  return {
    modal,
    setModal,
    closeModal,
    confirmModal,
    createModal,
    modalTableColumns,
  };
}
