import { useState, useEffect } from 'react';
import { EventDataNode, DataNode } from 'rc-tree/lib/interface';
import {
  RCTreeDataType,
  useFetchTreeAllWithMembers,
} from './useFetchTreeAllWithMembers';

type FilterTreeNode =
  | ((node: RCTreeDataType | EventDataNode<DataNode>) => boolean)
  | undefined;

export function useSearchKeyword(keyword: string, setExpandedKeys: any) {
  const { data, isValidating } = useFetchTreeAllWithMembers();
  const debounceKeyword = useDebounce(keyword, 500);

  useEffect(() => {
    const filter = (node: RCTreeDataType) =>
      keywordFilter(node, debounceKeyword);
    const keys = recursiveRCTree(data, filter);
    setExpandedKeys(keys);
  }, [debounceKeyword]);

  const handleFilterTreeNode: FilterTreeNode = (node) => {
    return keywordFilter(node as RCTreeDataType, debounceKeyword);
  };

  return {
    handleFilterTreeNode,
  };
}

function useDebounce<T>(value: T, delay?: number): T {
  const [debounceValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debounceValue;
}

function recursiveRCTree(
  node: RCTreeDataType[] | undefined,
  filter: (node: RCTreeDataType) => any
) {
  if (!(node instanceof Array) || node.length === 0) return;

  const result = node.reduce((accumulator: any, current: any) => {
    const children = recursiveRCTree(current.children, filter);

    if (children instanceof Array && children.length !== 0) {
      accumulator.push(...children);
      accumulator.push(current.key);
    } else if (filter(current)) {
      accumulator.push(current.key);
    }
    return accumulator;
  }, []);

  return result;
}

function keywordFilter(node: RCTreeDataType, keyword: string) {
  const word = keyword.replace(/[!@#$%^&*()\-+=|\\?><]/g, '');
  const regex = new RegExp(`(?=[${word}])${word}`);
  return regex.test(String((node as any).id)) || regex.test(String(node.title));
}
