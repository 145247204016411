import { Message } from '@mezzanine-ui/react';
import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { UseDataSourceHook } from '../common-table-filter-param';
import { API_NAMESPACE, request } from '../request';
import { ERFCState } from '../salesAndDistributions';
import { ResponseStatus } from './useHumanResourceBuManage';
import { headers } from './useHumanResourceBUReport';
import { OffsetBased, PageInfo } from '../pagination';

const namespace = API_NAMESPACE.HR;

export const INIT_QUERY_PARAMS = '&page=1&perPage=10';
export const getInitQueryParams = (page: number, perPage: number) =>
  `&page=${page}&perPage=${perPage}`;

export type FieldsType = {
  registerName: string;
  value: any;
};

export const reduceField = (fields: FieldsType[]) =>
  fields.reduce((acc, cur) => {
    if (!cur.value) return acc;

    return {
      ...acc,
      [cur.registerName]: cur.value,
    };
  }, {});

type MappingType = {
  registerName: string;
  query: string;
  isDate: boolean;
};

type OpFilterMutation = (
  payload: FieldsType[],
  mapping: MappingType[]
) => string;

export const opFilterMutation: OpFilterMutation = (payload, mapping) => {
  const filterUndefined = payload.filter((item) => item.value !== undefined);
  const filterPayload = filterUndefined.filter((item) => {
    if (typeof item.value === 'string' && item.value.trim() === '') return;
    return item;
  });

  const result = filterPayload.map((item) => {
    const queryItem = mapping.find(
      (field) => field.registerName === item.registerName
    );
    const initValue = `${queryItem?.query}=`;

    return item.value?.constructor === Array
      ? arr_to_string(initValue, item.value)
      : queryItem?.isDate
      ? dateFormat(initValue, item.value)
      : `${initValue}${item.value}`;
  });

  const joinResult = result.join('&');

  return joinResult;
};

type OptionType = {
  id: string;
  name: string;
};

export const arr_to_string = (
  initState: string,
  payload?: OptionType[]
): string => {
  if (!payload) return initState;
  let returnState = initState;
  for (const item of payload) {
    if (returnState === initState) {
      returnState += item.id;
    } else {
      returnState += `,${item.id}`;
    }
  }

  return returnState;
};

export const dateFormat = (initState: string, payload?: string) => {
  if (!payload) return initState;
  const date = payload.split('T')[0].replace(/-/g, '/');
  return `${initState}${date}`;
};

export const convertToObject = (queryParams: string) => {
  const queriesArr = queryParams.split('&');

  queriesArr.shift();
  const result = queriesArr.reduce((acc, cur): object => {
    const split = cur.split('=');
    return { ...acc, [split[0]]: split[1] };
  }, {});
  return result;
};

export const downloadCsv = (filePath: string): void => {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.setAttribute('download', filePath);
  link.setAttribute('href', filePath);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function useOrders() {
  const [rfcState, setRfcState] = useState<ERFCState>(ERFCState.IDLE);
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [queryParams, setQueryParams] = useState('');

  const tableQueries = useMemo(
    () => `?page=${currentPage}&perPage=${perPage}`,
    [currentPage, perPage]
  );

  const { data: swrData } = useSWR([
    `/orders${tableQueries}${queryParams}`,
    { namespace },
  ]);

  const total = useMemo(() => swrData && swrData?.total, [swrData]);

  const filterMutation = async (payload: FieldsType[]) => {
    const reserveDateRange = payload.filter(
      (item) => item.registerName === 'reserveDate'
    )?.[0]?.value;

    const orderDateRange = payload.filter(
      (item) => item.registerName === 'orderDate'
    )?.[0]?.value;

    const withOutDate = payload.filter(
      (item) =>
        item.registerName !== 'reserveDate' && item.registerName !== 'orderDate'
    );
    const result = [
      ...withOutDate,
      {
        registerName: 'expectedDeliveryDateStart',
        value: reserveDateRange?.[0],
      },
      { registerName: 'expectedDeliveryDateEnd', value: reserveDateRange?.[1] },
      { registerName: 'orderDateStart', value: orderDateRange?.[0] },
      { registerName: 'orderDateEnd', value: orderDateRange?.[1] },
    ];

    // registerName: 前端定義, query: swagger, isDate: 是不是日期
    const mapping = [
      {
        registerName: 'expectedDeliveryDateStart',
        query: 'expectedDeliveryDateStart',
        isDate: true,
      },
      {
        registerName: 'expectedDeliveryDateEnd',
        query: 'expectedDeliveryDateEnd',
        isDate: true,
      },
      {
        registerName: 'orderDateStart',
        query: 'orderDateStart',
        isDate: true,
      },
      {
        registerName: 'orderDateEnd',
        query: 'orderDateEnd',
        isDate: true,
      },
      { registerName: 'clientName', query: 'customerId', isDate: false },
      { registerName: 'orderNumber', query: 'orderId', isDate: false },
      { registerName: 'productNumber', query: 'materialId', isDate: false },
      { registerName: 'orderStatus', query: 'orderStatusId', isDate: false },
      { registerName: 'orderTypes', query: 'orderTypeId', isDate: false },
      {
        registerName: 'customerPoNumber',
        query: 'customerPoNumber',
        isDate: false,
      },
      {
        registerName: 'deliveryOrderId',
        query: 'deliveryOrderId',
        isDate: false,
      },
      { registerName: 'invoiceNumber', query: 'invoiceNumber', isDate: false },
      { registerName: 'bu', query: 'bu', isDate: false },
    ];

    const newResult = result.map((item) =>
      item.registerName === 'clientName'
        ? {
            registerName: item.registerName,
            value: item.value ? [{ ...item.value }] : undefined,
          }
        : item
    );
    const joinResult = opFilterMutation(newResult, mapping);

    joinResult === '' ? setQueryParams('') : setQueryParams(`&${joinResult}`);
  };

  const exportData = async () => {
    const result = convertToObject(queryParams);

    const options = {
      method: 'POST',
      headers,
      body: JSON.stringify(result),
    };

    const json = await request('/orders/export', {
      ...options,
      namespace,
    });
    const filePath = json?.filePath;

    downloadCsv(filePath);

    if (filePath) return ResponseStatus.SUCCESS;
    return ResponseStatus.FAIL;
  };

  const handleUpdateRfc = async () => {
    const options = {
      method: 'POST',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
      namespace,
    };
    const json = (await request(`/orders/rfcSync`, options)) as unknown as {
      message: string;
    };
    if (json?.message.includes('完成')) {
      Message['success'](json.message);
    } else {
      Message['warning'](json.message);
    }
    setRfcState(ERFCState.IDLE);
  };

  useEffect(() => {
    if (!swrData || typeof swrData === 'string') {
      setLoading(true);
      return;
    }
    const dataWithKey = swrData.data?.map((data: any) => ({
      ...data,
      key: `${data.id}-${Math.random()}`,
    }));
    setData(dataWithKey);
    setLoading(false);
  }, [swrData]);

  return {
    rfcState,
    setRfcState,
    loading,
    data,
    currentPage,
    setCurrentPage,
    perPage,
    setPerPage,
    handleUpdateRfc,
    filterMutation,
    exportData,
    total,
    queryParams,
  };
}

export function useRefactoredOrders({
  initiateFetching = false,
}: UseDataSourceHook) {
  const [params, setParams] =
    useState<Partial<{ customerId: string; orderId: string }>>();
  const { data, error, mutate } = useSWR<{
    current_page: number;
    last_page: number;
    data: Array<Orders>;
    per_page: number;
  }>(
    initiateFetching || params
      ? [
          '/orders',
          { params: { ...params, page: '1', per_page: '10' }, namespace },
        ]
      : null
  );

  const pageInfo = useMemo(() => {
    const page = data?.current_page ?? 1;
    const pageSize = data?.per_page ?? 10;
    const limit = data?.per_page ?? 10;
    const offset = (data?.current_page ?? 0) * (data?.per_page ?? 0) + 1 || 1;
    const hasNext = (data?.current_page ?? 0) < (data?.last_page ?? 0);
    return { page, pageSize, limit, offset, hasNext };
  }, [data]);

  return {
    orders: data?.data || [],
    pageInfo,
    isLoading: !error && !data,
    mutateParams: setParams,
    refetchOrders: mutate,
  };
}

interface UseGetSalesOrdersParams extends OffsetBased {
  customerIds?: string[];
  orderIds?: string;
  searchTerm?: string;
}

interface Customer {
  id: string;
  name: string;
  phone: string;
  createdAt: string;
  updatedAt: string;
  parentCompanyId: string;
  country: string;
  city: string;
  address: string;
  zipcode: string;
  factory: any;
  shelfId: any;
}

interface OrderDetail {
  id: number;
  orderId: string;
  materialId: string;
  material: Material;
  salesOrderLine: string;
  materialSellingContent: string;
  workOrderDescription: string;
  scmNote: string;
  deliveryNote: string;
  standardWeight: string;
  elementCode: string;
  elementRatio: number;
  elementWeight: string;
  elementCode1: any;
  elementRatio1: any;
  elementWeight1: any;
  elementCode2: any;
  elementRatio2: any;
  elementWeight2: any;
  elementCode3: any;
  elementRatio3: any;
  elementWeight3: any;
  limsBarcode: any;
}

interface Material {
  id: string;
  description: string;
  materialStockUnit: string;
  mainMaterialUnitCode: string;
  mainMaterialUnitId: number;
  secondaryMaterialUnitId: any;
  categoryId: number;
  categoryCode: string;
  groupId: any;
  sourceId: any;
  oldMaterialId: string;
  purchaseType: string;
  syncAt: string;
}

export function useGetSalesOrders(defaultParams?: UseGetSalesOrdersParams) {
  const [params, setParams] = useState(defaultParams);

  const { data, error, mutate, isValidating } = useSWR<{
    records: {
      id: string;
      customerId: string;
      customer: Customer;
      customerOrderDate: string;
      type: string;
      contact: string;
      totalPrice: string;
      paymentTerm: string;
      bu: string;
      memberId: string;
      memberName: string;
      salesAssistantId: any;
      salesAssistantName: any;
      orderDetails: OrderDetail[];
    }[];
    pageInfo: PageInfo;
  }>(
    params
      ? ['/sd/order', { params, namespace: API_NAMESPACE.MATERIALS }]
      : null
  );

  return {
    salesOrders: data?.records || [],
    pageInfo: data?.pageInfo,
    isLoading: !error && !data && isValidating,
    refetchSalesOrders: setParams,
    mutateSalesOrders: mutate,
  };
}

export type Orders = {
  id: number;
  orderId: string;
  salesOrderLine: string;
  customerOrderDate: string;
  salesOrderType: string;
  status: string;
  customerId: string;
  customerName: string;
  customerShortName: string;
  customerAddress: string;
  paymentTerm: string;
  contact: string;
  customerPoNumber: string;
  expectedDeliveryDate: string;
  materialId: string;
  // customerMaterialId: any
  materialName: string;
  // customerMaterialName: any
  // quantity: string
  // quantityUnit: string
  // grossWeight: any
  // workOrderDescription: string
  // materialSellingContent: string
  // deliveryOrderId: any
  // debitDate: any
  // batchId: any
  // locationId: any
  // shipLocationName: any
  // shipFactoryName: any
  // settledDate: any
  // overdueDate: any
  // unitPrice: string
  // currency: string
  // unitPriceTwd: string
  // invoiceNumber: any
  // taxRate: string
  // exchangeRate: string
  // totalPrice: string
  // lastUpdatedDate: string
  // bu: string
};
