import { getRoleGroup } from './shared';

const SPEC_GROUP = {
  PG01: {
    name: 'PGE-酸鈀廢液',
  },
  PG02: {
    name: 'PGE-鹼鈀廢液',
  },
  PG03: {
    name: 'PGE-Pt.Pd王水',
  },
  PG04: {
    name: 'PGE-Pt錠_3N以上',
  },
  PG05: {
    name: 'PGE-Pt錠_3N以下',
  },
  PG06: {
    name: 'PGE-Pt王水',
  },
  PG07: {
    name: 'PGE-Pt粉體,氧化鋁砂,白金砂',
  },
  PG08: {
    name: 'PGE-PtRu氯酸液',
  },
  PG09: {
    name: 'PGE-Pt氯酸液',
  },
  PG10: {
    name: 'PGE-AuPt王水，Au<100ppm且Pt大於Au，走TEM-PGE回收流程 (特例:佳龍樹脂酸溶產生Au.Pt王水)',
  },
  PG11: {
    name: 'PGE-Pd>10%,固體',
  },
  PG12: {
    name: 'PGE-Pd氯酸液',
  },
  RE01: {
    name: '1N轉銀化-銀錠Ag>90%(UL)',
  },
  RE02: {
    name: '1N轉銀化-銀錠Ag>90%(非UL)',
  },
  RE03: {
    name: '回收固體-銀錠Ag_3N(UL)',
  },
  RE04: {
    name: '回收固體-銀錠Ag_4N(UL)',
  },
  RE05: {
    name: '回收固體-銀錠Ag_3N(非UL)',
  },
  RE06: {
    name: '回收固體-銀錠Ag_4N(非UL)',
  },
  RE07: {
    name: '回收固體-Ag粉體,氰化銀鉀',
  },
  RE08: {
    name: '回收固體-Au粉體,氰化金鉀',
  },
  GC01: {
    name: 'GCR-金>96%',
  },
  GC02: {
    name: 'GCR-金75%~96%',
  },
  GC03: {
    name: 'Ag500-多元合金Au<75%、Ag>5%',
  },
  RN01: {
    name: '取樣-Au樹脂粗顆粒',
  },
  RN02: {
    name: '取樣-AuKI樹脂(待前處理)',
  },
  RN03: {
    name: '取樣-Au樹脂(待前處理)',
  },
  RN04: {
    name: '取樣-Pd樹脂(待前處理)',
  },
  RN05: {
    name: '取樣-Au.Pd樹脂(待前處理)',
  },
  OU01: {
    name: '委外-IC廢料(過篩20mesh)',
  },
  OU02: {
    name: '委外-封裝IC廢料(過篩5mm)',
  },
  OU03: {
    name: '委外-碳基觸媒',
  },
  OU04: {
    name: '委外-氧化鋁基觸媒',
  },
  OU05: {
    name: '委外-空污觸媒',
  },
  OU06: {
    name: '委外-多元合金粉末',
  },
  OU07: {
    name: '委外-多元合金錠',
  },
  OU08: {
    name: '委外-汙泥',
  },
  OU09: {
    name: '委外-報廢板(客戶所有權)',
  },
  OU10: {
    name: '委外-報廢板(光洋所有權)',
  },
  OU11: {
    name: '委外-濾餅',
  },
  OU12: {
    name: '委外-PCB板(硬碟拆解)',
  },
  OU13: {
    name: '委外-世洋探針(硬碟拆解)',
  },
  OU14: {
    name: '委外-集塵灰',
  },
  OU15: {
    name: '委外-世洋廢鐵',
  },
  OU16: {
    name: '委外-世洋廢鋁',
  },
  OU17: {
    name: '委外-無價廢液委外-無價廢液(酸)',
  },
  OU18: {
    name: '委外-無價廢液委外-無價廢液(鹼)',
  },
  HD01: {
    name: '電子資源課-VCM磁石(含鐵)',
  },
  HD02: {
    name: '電子資源課-VCM磁石',
  },
  HD03: {
    name: '電子資源課-VCM裸磁石',
  },
  HD04: {
    name: '電子資源課-世洋(無板硬碟)',
  },
  EL01: {
    name: '金電解-Au電鍍液Au>600ppm,ph<9',
  },
  EL02: {
    name: '金電解-Au電鍍液Au<600ppm,ph<9',
  },
  EL03: {
    name: '金電解-Au電鍍液,ph>9',
  },
  EL04: {
    name: '金電解-Au.Pt.Pd剝金液(12號槽)',
  },
  EL05: {
    name: '金電解-AuPt德揚剝金液(Au>600PPM)',
  },
  EL06: {
    name: '金電解-AuPt德揚剝金液(Au<600PPM)',
  },
  EL07: {
    name: '金電解-Ag(CN)電鍍液(Ag>1000PPM,置換)',
  },
  EL08: {
    name: '金電解-Ag(CN)電鍍液(Ag<1000PPM,電解)',
  },
  EL09: {
    name: '金電解-無價廢液(鹼)排放',
  },
  EL10: {
    name: '金電解-鈀廢液排放',
  },
  EL11: {
    name: '金電解-Au<5ppm電鍍液(樹脂吸附)',
  },
  PL01: {
    name: '金樹脂-Au樹脂底料(已焚化)',
  },
  PL02: {
    name: '金樹脂-粉末>3%、Ag<10%、不含Pd',
  },
  PL03: {
    name: '金樹脂-粉末Au>3%、Ag>10%、不含Pd',
  },
  GR01: {
    name: '金還原-AuPt王水(Au≧100ppm)',
  },
  GR02: {
    name: '金還原-PGM客來料AuPtPd王水',
  },
  GR03: {
    name: '金還原-TEM客來料AuPtPd王水',
  },
  GR04: {
    name: '金還原-台通王水(低濃度) 還原區陰離子樹脂塔吸附',
  },
  GR05: {
    name: '金還原-AuSn王水還原(Sn>1000ppm)',
  },
  GR06: {
    name: '金還原-無價硫脲(還原區陽離子樹脂塔吸附)',
  },
  GR07: {
    name: '金還原-無價廢液(酸)排放',
  },
  GR08: {
    name: '金還原-Au王水',
  },
  AO01: {
    name: '金屬粉末專案-多元合金回收品(SnAgCu)',
  },
  SM01: {
    name: '銀回收-Ag粉末>30%',
  },
  PD01: {
    name: '鈀回收-廢液,酸鈀PH<9',
  },
  PD02: {
    name: '鈀回收-廢液,鹼鈀PH>9',
  },
  PD03: {
    name: '鈀回收-Pt.Pd王水',
  },
  SS01: {
    name: '銀還原-銀錠Ag<90%(UL)',
  },
  SS02: {
    name: '銀還原-銀錠Ag<90%(非UL)',
  },
  SS03: {
    name: '銀還原-Ag硝酸液',
  },
  SS04: {
    name: '銀還原-Ag硝酸液(無價中和)',
  },
  GA01: {
    name: '鎵回收-含砷鎵廢液',
  },
  GA02: {
    name: '鎵回收-不含砷鎵廢液',
  },
  GA03: {
    name: '鎵回收-無價廢液(酸)AsGa回收',
  },
  GA04: {
    name: '鎵回收-Ga固體',
  },
  FA01: {
    name: 'FCC 生物管課-客供料',
  },
  FA02: {
    name: 'FCC 研發-Ag>40%',
  },
  FA03: {
    name: 'FCC 研發-In 回收品',
  },
  FA04: {
    name: 'FCC 研發-多元合金回收品(PdSnCu)',
  },
  FA05: {
    name: 'FCC 實驗室-In 回收品',
  },
  FB01: {
    name: 'In回收-In酸液',
  },
  FB02: {
    name: 'In回收-In酸液,草酸銦',
  },
  FB03: {
    name: 'In回收-銦固體廢料',
  },
  FC01: {
    name: 'ITO回收精煉課-Ga',
  },
  FC02: {
    name: 'ITO回收精煉課-Ga >4N5',
  },
  FC03: {
    name: 'ITO回收精煉課-In 回收品',
  },
  FC04: {
    name: 'ITO回收精煉課-Ingot,In購料,>50%',
  },
  FC05: {
    name: 'ITO回收精煉課-In酸液,<10%',
  },
  FC06: {
    name: 'ITO回收精煉課-Pt >3N5',
  },
  FC07: {
    name: 'ITO回收精煉課-多元合金回收品(InGa)',
  },
  FC08: {
    name: 'ITO回收精煉課-多元合金回收品(InNi)',
  },
  FC09: {
    name: 'ITO回收精煉課-製程邊腳料',
  },
  TS01: {
    name: 'SUL回收-焚化後TaCo車屑(SUL回收)',
  },
  TS02: {
    name: 'SUL回收-Ta渣',
  },
  TS03: {
    name: 'SUL回收-Co渣',
  },
  TR01: {
    name: 'Ru回收-Shields_Ru',
  },
  TR02: {
    name: 'Ru回收-Shields_RuCo',
  },
  TR03: {
    name: 'Ru回收-Ru>10%,固體',
  },
  TR04: {
    name: 'Ru回收-Pt<15%且Ru>10%, 固體',
  },
  TR05: {
    name: 'Ru回收-電解泥、已Fusion待鹼溶塊狀料',
  },
  TR06: {
    name: 'Ru回收-Ru<10%且Co>20%, 固體',
  },
  TT01: {
    name: 'Pt精煉-Shields_Pt',
  },
  TT02: {
    name: 'Pt精煉-Shields_PtRu',
  },
  TT03: {
    name: 'Pt精煉-Pt>10%,固體',
  },
};

const ADD_GROUP = {
  G1: {
    name: '觸媒取樣機',
    nextLevel: SPEC_GROUP,
  },
  G2: {
    name: 'IC取樣機',
    nextLevel: SPEC_GROUP,
  },
  G3: {
    name: '取樣棒',
    nextLevel: SPEC_GROUP,
  },
  G4: {
    name: '勺取',
    nextLevel: SPEC_GROUP,
  },
  G5: {
    name: '水解取樣',
    nextLevel: SPEC_GROUP,
  },
  GA: {
    name: '藥劑浸泡',
    nextLevel: SPEC_GROUP,
  },
  GB: {
    name: '分類',
    nextLevel: SPEC_GROUP,
  },
  GC: {
    name: '水洗剝膜',
    nextLevel: SPEC_GROUP,
  },
  GD: {
    name: '拆解',
    nextLevel: SPEC_GROUP,
  },
  GE: {
    name: '水洗',
    nextLevel: SPEC_GROUP,
  },
  GF: {
    name: '脫水',
    nextLevel: SPEC_GROUP,
  },
  GG: {
    name: '噴砂',
    nextLevel: SPEC_GROUP,
  },
  GH: {
    name: '烘乾',
    nextLevel: SPEC_GROUP,
  },
  GI: {
    name: '破碎',
    nextLevel: SPEC_GROUP,
  },
  GJ: {
    name: '過篩',
    nextLevel: SPEC_GROUP,
  },
  GK: {
    name: '剝金作業',
    nextLevel: SPEC_GROUP,
  },
  GL: {
    name: '擠壓',
    nextLevel: SPEC_GROUP,
  },
  GM: {
    name: '焚化熱處理',
    nextLevel: SPEC_GROUP,
  },
  GN: {
    name: '煆燒爐',
    nextLevel: SPEC_GROUP,
  },
  GO: {
    name: '研磨',
    nextLevel: SPEC_GROUP,
  },
  GP: {
    name: '混合',
    nextLevel: SPEC_GROUP,
  },
  GQ: {
    name: '六分',
    nextLevel: SPEC_GROUP,
  },
  GR: {
    name: '二分',
    nextLevel: SPEC_GROUP,
  },
  GS: {
    name: '熔煉',
    nextLevel: SPEC_GROUP,
  },
  GT: {
    name: '酸溶槽',
    nextLevel: SPEC_GROUP,
  },
  GU: {
    name: '機械攪拌',
    nextLevel: SPEC_GROUP,
  },
  GV: {
    name: '曝氣',
    nextLevel: SPEC_GROUP,
  },
  GZ: {
    name: '製樣',
    nextLevel: SPEC_GROUP,
  },
};

const CODE_GROUP = {
  'A-0001': {
    name: '使用熱處理法處理有害事業廢棄物所產生之集塵灰',
    getNextLevel: () => ADD_GROUP,
  },
  'A-8801': {
    name: '電鍍製程之廢水處理污泥，但下述製程所產生者除外：(1)鋁之硫酸電鍍(2)碳鋼鍍錫(3)碳鋼鍍鋁(4)伴隨清洗或汽提之碳鋼鍍錫、鋁(5)鋁之蝕刻及研磨。',
    getNextLevel: () => ADD_GROUP,
  },
  'A-9001': {
    name: '電鍍廢棄之氰化物電鍍液',
    getNextLevel: () => ADD_GROUP,
  },
  AC9001: {
    name: '化學處理-電鍍廢棄之氰化物電鍍液',
    getNextLevel: () => ADD_GROUP,
  },
  AJ8801: {
    name: '二期焚化處理-電鍍製程之廢水處理污泥，但下述製程所產生者除外：(1)鋁之硫酸電鍍(2)碳鋼鍍錫(3)碳鋼鍍鋁(4)伴隨清洗或汽提之碳鋼鍍錫、鋁(5)鋁之蝕刻及研磨。',
    getNextLevel: () => ADD_GROUP,
  },
  'B-0299': {
    name: '其他前述化學物質混合物或廢棄盛裝容器',
    getNextLevel: () => ADD_GROUP,
  },
  'B-0347': {
    name: '二甲基甲醯胺＜毒性化學物質第二類＞',
    getNextLevel: () => ADD_GROUP,
  },
  BJ0299: {
    name: '二期焚化處理-其他前述化學物質混合物或廢棄盛裝容器',
    getNextLevel: () => ADD_GROUP,
  },
  'C-0110': {
    name: '銅及其化合物(總銅)(僅限廢觸媒、集塵灰、廢液、污泥、濾材、焚化飛灰或底渣)',
    getNextLevel: () => ADD_GROUP,
  },
  'C-0201': {
    name: '廢液pH值大(等)於12.5',
    getNextLevel: () => ADD_GROUP,
  },
  'C-0202': {
    name: '廢液pH值小(等)於2.0',
    getNextLevel: () => ADD_GROUP,
  },
  CC0201: {
    name: '化學處理-廢液pH值大(等)於12.5',
    getNextLevel: () => ADD_GROUP,
  },
  CC0202: {
    name: '化學處理-廢液pH值小(等)於2.0',
    getNextLevel: () => ADD_GROUP,
  },
  CJ0110: {
    name: '二期焚化處理-銅及其化合物(總銅)(僅限廢觸媒、集塵灰、廢液、污泥、濾材、焚化飛灰或底渣)',
    getNextLevel: () => ADD_GROUP,
  },
  'D-0201': {
    name: '廢離子交換樹脂',
    getNextLevel: () => ADD_GROUP,
  },
  'D-0202': {
    name: '廢樹脂（D-0201除外）',
    getNextLevel: () => ADD_GROUP,
  },
  'D-0299': {
    name: '廢塑膠混合物',
    getNextLevel: () => ADD_GROUP,
  },
  'D-0499': {
    name: '其他廢玻璃、陶瓷、磚、瓦及黏土等混合物',
    getNextLevel: () => ADD_GROUP,
  },
  'D-0699': {
    name: '廢紙混合物',
    getNextLevel: () => ADD_GROUP,
  },
  'D-0801': {
    name: '廢纖維',
    getNextLevel: () => ADD_GROUP,
  },
  'D-0802': {
    name: '廢棉屑',
    getNextLevel: () => ADD_GROUP,
  },
  'D-0803': {
    name: '廢布',
    getNextLevel: () => ADD_GROUP,
  },
  'D-0899': {
    name: '廢纖維或其他棉、布等混合物',
    getNextLevel: () => ADD_GROUP,
  },
  'D-0902': {
    name: '無機性污泥',
    getNextLevel: () => ADD_GROUP,
  },
  'D-1099': {
    name: '非有害廢集塵灰或其混合物',
    getNextLevel: () => ADD_GROUP,
  },
  'D-1103': {
    name: '焚化爐底渣',
    getNextLevel: () => ADD_GROUP,
  },
  'D-1201': {
    name: '金屬冶煉爐渣（含原煉鋼出渣）',
    getNextLevel: () => ADD_GROUP,
  },
  'D-1299': {
    name: '爐石(碴)或礦渣混合物',
    getNextLevel: () => ADD_GROUP,
  },
  'D-1399': {
    name: '其他單一非有害廢金屬或金屬廢料混合物',
    getNextLevel: () => ADD_GROUP,
  },
  'D-1499': {
    name: '其他單一非有害廢觸媒或其混合物',
    getNextLevel: () => ADD_GROUP,
  },
  'D-1502': {
    name: '非有害廢鹼',
    getNextLevel: () => ADD_GROUP,
  },
  'D-1503': {
    name: '非有害廢酸',
    getNextLevel: () => ADD_GROUP,
  },
  'D-1999': {
    name: '未納入公告之廢物品混合物',
    getNextLevel: () => ADD_GROUP,
  },
  'D-2399': {
    name: '一般廢化學物質混合物',
    getNextLevel: () => ADD_GROUP,
  },
  'D-2407': {
    name: '噴砂廢棄物',
    getNextLevel: () => ADD_GROUP,
  },
  'D-2612': {
    name: '廢電鍍金屬',
    getNextLevel: () => ADD_GROUP,
  },
  'D-2623': {
    name: '含金(銀、鈀)之導線架廢料',
    getNextLevel: () => ADD_GROUP,
  },
  'D-2624': {
    name: '含貴金屬(金、銀、鈀、鉑、銥、銠、鋨、釕)之廢觸媒',
    getNextLevel: () => ADD_GROUP,
  },
  'D-2625': {
    name: '含貴金屬(金、銀、鈀、鉑、銥、銠、鋨、釕)之離子交換樹脂',
    getNextLevel: () => ADD_GROUP,
  },
  'D-2626': {
    name: '汽機車觸媒轉化器',
    getNextLevel: () => ADD_GROUP,
  },
  'D-2627': {
    name: '其他以化學處理法（不含熱處理法）處理之混合五金廢料',
    getNextLevel: () => ADD_GROUP,
  },
  'D-2704': {
    name: '其他以熱處理法處理之混合五金廢料',
    getNextLevel: () => ADD_GROUP,
  },
  DC0201: {
    name: '化學處理-廢離子交換樹脂',
    getNextLevel: () => ADD_GROUP,
  },
  DC1201: {
    name: '化學處理-金屬冶煉爐渣（含原煉鋼出渣）',
    getNextLevel: () => ADD_GROUP,
  },
  DC1299: {
    name: '化學處理-爐石(碴)或礦渣混合物',
    getNextLevel: () => ADD_GROUP,
  },
  DC1399: {
    name: '化學處理-其他單一非有害廢金屬或金屬廢料混合物',
    getNextLevel: () => ADD_GROUP,
  },
  DC1502: {
    name: '化學處理-非有害廢鹼',
    getNextLevel: () => ADD_GROUP,
  },
  DC1503: {
    name: '化學處理-非有害廢酸',
    getNextLevel: () => ADD_GROUP,
  },
  DC1999: {
    name: '化學處理-未納入公告之廢物品混合物',
    getNextLevel: () => ADD_GROUP,
  },
  DC2407: {
    name: '化學處理-噴砂廢棄物',
    getNextLevel: () => ADD_GROUP,
  },
  DC2612: {
    name: '化學處理-廢電鍍金屬',
    getNextLevel: () => ADD_GROUP,
  },
  DC2623: {
    name: '化學處理-含金(銀、鈀)之導線架廢料',
    getNextLevel: () => ADD_GROUP,
  },
  DC2624: {
    name: '化學處理-含貴金屬(金、銀、鈀、鉑、銥、銠、鋨、釕)之廢觸媒',
    getNextLevel: () => ADD_GROUP,
  },
  DC2625: {
    name: '化學處理-含貴金屬(金、銀、鈀、鉑、銥、銠、鋨、釕)之離子交換樹脂',
    getNextLevel: () => ADD_GROUP,
  },
  DC2626: {
    name: '化學處理-汽機車觸媒轉化器',
    getNextLevel: () => ADD_GROUP,
  },
  DC2627: {
    name: '化學處理-其他以化學處理法（不含熱處理法）處理之混合五金廢料',
    getNextLevel: () => ADD_GROUP,
  },
  DH0201: {
    name: '一期熱處理(焚化除外)-廢離子交換樹脂',
    getNextLevel: () => ADD_GROUP,
  },
  DH0202: {
    name: '一期熱處理(焚化除外)-廢樹脂（D-0201除外）',
    getNextLevel: () => ADD_GROUP,
  },
  DH0299: {
    name: '一期熱處理(焚化除外)-廢塑膠混合物',
    getNextLevel: () => ADD_GROUP,
  },
  DH0699: {
    name: '一期熱處理(焚化除外)-廢紙混合物',
    getNextLevel: () => ADD_GROUP,
  },
  DH0801: {
    name: '一期熱處理(焚化除外)-廢纖維',
    getNextLevel: () => ADD_GROUP,
  },
  DH0802: {
    name: '一期熱處理(焚化除外)-廢棉屑',
    getNextLevel: () => ADD_GROUP,
  },
  DH0803: {
    name: '一期熱處理(焚化除外)-廢布',
    getNextLevel: () => ADD_GROUP,
  },
  DH0899: {
    name: '一期熱處理(焚化除外)-廢纖維或其他棉、布等混合物',
    getNextLevel: () => ADD_GROUP,
  },
  DH1399: {
    name: '一期熱處理(焚化除外)-其他單一非有害廢金屬或金屬廢料混合物',
    getNextLevel: () => ADD_GROUP,
  },
  DH1999: {
    name: '一期熱處理(焚化除外)-未納入公告之廢物品混合物',
    getNextLevel: () => ADD_GROUP,
  },
  DH2624: {
    name: '一期熱處理(焚化除外)-含貴金屬(金、銀、鈀、鉑、銥、銠、鋨、釕)之廢觸媒',
    getNextLevel: () => ADD_GROUP,
  },
  DH2625: {
    name: '一期熱處理(焚化除外)-含貴金屬(金、銀、鈀、鉑、銥、銠、鋨、釕)之離子交換樹脂',
    getNextLevel: () => ADD_GROUP,
  },
  DH2704: {
    name: '一期熱處理(焚化除外)-其他以熱處理法處理之混合五金廢料',
    getNextLevel: () => ADD_GROUP,
  },
  DJ0201: {
    name: '二期焚化處理-廢離子交換樹脂',
    getNextLevel: () => ADD_GROUP,
  },
  DJ0202: {
    name: '二期焚化處理-廢樹脂（D-0201除外）',
    getNextLevel: () => ADD_GROUP,
  },
  DJ0299: {
    name: '二期焚化處理-廢塑膠混合物',
    getNextLevel: () => ADD_GROUP,
  },
  DJ0699: {
    name: '二期焚化處理-廢紙混合物',
    getNextLevel: () => ADD_GROUP,
  },
  DJ0801: {
    name: '二期焚化處理-廢纖維',
    getNextLevel: () => ADD_GROUP,
  },
  DJ0802: {
    name: '二期焚化處理-廢棉屑',
    getNextLevel: () => ADD_GROUP,
  },
  DJ0803: {
    name: '二期焚化處理-廢布',
    getNextLevel: () => ADD_GROUP,
  },
  DJ0899: {
    name: '二期焚化處理-廢纖維或其他棉、布等混合物',
    getNextLevel: () => ADD_GROUP,
  },
  DJ0902: {
    name: '二期焚化處理-無機性污泥',
    getNextLevel: () => ADD_GROUP,
  },
  DJ1399: {
    name: '二期焚化處理-其他單一非有害廢金屬或金屬廢料混合物',
    getNextLevel: () => ADD_GROUP,
  },
  DJ1499: {
    name: '二期焚化處理-其他單一非有害廢觸媒或其混合物',
    getNextLevel: () => ADD_GROUP,
  },
  DJ1999: {
    name: '二期焚化處理-未納入公告之廢物品混合物',
    getNextLevel: () => ADD_GROUP,
  },
  DJ2624: {
    name: '二期焚化處理-含貴金屬(金、銀、鈀、鉑、銥、銠、鋨、釕)之廢觸媒',
    getNextLevel: () => ADD_GROUP,
  },
  DJ2625: {
    name: '二期焚化處理-含貴金屬(金、銀、鈀、鉑、銥、銠、鋨、釕)之離子交換樹脂',
    getNextLevel: () => ADD_GROUP,
  },
  DJ2704: {
    name: '二期焚化處理-其他以熱處理法處理之混合五金廢料',
    getNextLevel: () => ADD_GROUP,
  },
  DK1499: {
    name: '二期熱處理(焚化除外)-其他單一非有害廢觸媒或其混合物',
    getNextLevel: () => ADD_GROUP,
  },
  DK2624: {
    name: '二期熱處理(焚化除外)-含貴金屬(金、銀、鈀、鉑、銥、銠、鋨、釕)之廢觸媒',
    getNextLevel: () => ADD_GROUP,
  },
  DP1201: {
    name: '物理處理-金屬冶煉爐渣（含原煉鋼出渣）',
    getNextLevel: () => ADD_GROUP,
  },
  DP1299: {
    name: '物理處理-爐石(碴)或礦渣混合物',
    getNextLevel: () => ADD_GROUP,
  },
  DP1399: {
    name: '物理處理-其他單一非有害廢金屬或金屬廢料混合物',
    getNextLevel: () => ADD_GROUP,
  },
  DP2407: {
    name: '物理處理-噴砂廢棄物',
    getNextLevel: () => ADD_GROUP,
  },
  DP2623: {
    name: '物理處理-含金(銀、鈀)之導線架廢料',
    getNextLevel: () => ADD_GROUP,
  },
  'E-0217': {
    name: '廢電子零組件、下腳品及不良品',
    getNextLevel: () => ADD_GROUP,
  },
  'E-0218': {
    name: '廢光電零組件、下腳品及不良品',
    getNextLevel: () => ADD_GROUP,
  },
  'E-0221': {
    name: '含金屬之印刷電路板廢料及其粉屑',
    getNextLevel: () => ADD_GROUP,
  },
  'E-0222': {
    name: '附零組件之廢印刷電路板',
    getNextLevel: () => ADD_GROUP,
  },
  'E-0228': {
    name: '含鈹、銻、碲、鉈金屬廢料',
    getNextLevel: () => ADD_GROUP,
  },
  'E-0301': {
    name: '發光二極體晶圓廢料及粉屑',
    getNextLevel: () => ADD_GROUP,
  },
  EC0218: {
    name: '化學處理-廢光電零組件、下腳品及不良品',
    getNextLevel: () => ADD_GROUP,
  },
  EC0221: {
    name: '化學處理-含金屬之印刷電路板廢料及其粉屑',
    getNextLevel: () => ADD_GROUP,
  },
  EC0222: {
    name: '化學處理-附零組件之廢印刷電路板',
    getNextLevel: () => ADD_GROUP,
  },
  EC0301: {
    name: '化學處理-發光二極體晶圓廢料及粉屑',
    getNextLevel: () => ADD_GROUP,
  },
  EH0217: {
    name: '一期熱處理(焚化除外)-廢電子零組件、下腳品及不良品',
    getNextLevel: () => ADD_GROUP,
  },
  EH0218: {
    name: '一期熱處理(焚化除外)-廢光電零組件、下腳品及不良品',
    getNextLevel: () => ADD_GROUP,
  },
  EJ0217: {
    name: '二期焚化處理-廢電子零組件、下腳品及不良品',
    getNextLevel: () => ADD_GROUP,
  },
  EJ0218: {
    name: '二期焚化處理-廢光電零組件、下腳品及不良品',
    getNextLevel: () => ADD_GROUP,
  },
  EP0217: {
    name: '物理處理-廢電子零組件、下腳品及不良品',
    getNextLevel: () => ADD_GROUP,
  },
  EP0218: {
    name: '物理處理-廢光電零組件、下腳品及不良品',
    getNextLevel: () => ADD_GROUP,
  },
  EP0221: {
    name: '物理處理-含金屬之印刷電路板廢料及其粉屑',
    getNextLevel: () => ADD_GROUP,
  },
  EP0228: {
    name: '物理處理-含鈹、銻、碲、鉈金屬廢料',
    getNextLevel: () => ADD_GROUP,
  },
  EP0301: {
    name: '物理處理-發光二極體晶圓廢料及粉屑',
    getNextLevel: () => ADD_GROUP,
  },
  'R-0201': {
    name: '廢塑膠',
    getNextLevel: () => ADD_GROUP,
  },
  'R-0401': {
    name: '廢玻璃',
    getNextLevel: () => ADD_GROUP,
  },
  'R-0403': {
    name: '廢陶瓷',
    getNextLevel: () => ADD_GROUP,
  },
  'R-1301': {
    name: '廢鐵',
    getNextLevel: () => ADD_GROUP,
  },
  'R-1304': {
    name: '廢鋁',
    getNextLevel: () => ADD_GROUP,
  },
  'R-1305': {
    name: '廢錫',
    getNextLevel: () => ADD_GROUP,
  },
  'R-2408': {
    name: '廢活性碳',
    getNextLevel: () => ADD_GROUP,
  },
  'X-8888': {
    name: '資源化產品',
    getNextLevel: () => ADD_GROUP,
  },
  'Z-9999': {
    name: '不用申報',
    getNextLevel: () => ADD_GROUP,
  },
};

const SAMPLING_GROUP = {
  A1: {
    name: '白金坩堝(含W)*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  A2: {
    name: '頎晶非貴液體(酸鹼中和)*酸鹼中和後,積量委外',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  A3: {
    name: '1.PtShields 2.PtRuShields 3.Ru.Shields(榮鑫)*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  A4: {
    name: '1.PtShields2.Pt.RuShields3.Ru.Shields 4. Littel fuse5.WD Holder(自榮鑫送德揚)*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  B1: {
    name: '聯詠IC((不含膜)*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  B2: {
    name: '聯詠IC(含膜)*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  B3: {
    name: '1.PCB報廢板2.金回收廢料3.二元回收廢料*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  B4: {
    name: '硬碟*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  B5: {
    name: '1.顆粒觸媒2.粉末觸媒*分類/過篩/烘乾製程看料況決定',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  B6: {
    name: '1.顆粒觸媒2.粉末觸媒*分類/過篩製程看料況決定',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  C1: {
    name: '磁鐡*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  D1: {
    name: '1.封裝IC 2.PCB報廢板(不剝金)*秤重->破碎->過篩->封裝IC取樣機->六分->槌碎機研磨>過篩->混合->取樣棒取約樣->二分->製樣',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  D2: {
    name: '報廢板(破碎委外,不取樣)*嘉聯益/欣興/友勁',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  D3: {
    name: '剝金後報廢板(破碎取樣)*群泓',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  D4: {
    name: '1.Ru殘靶/邊料2.PtRu殘靶/邊料3.Pt殘靶/邊料*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  D5: {
    name: '汙泥*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  D6: {
    name: '1.爐渣2.廢坩堝3.鉭鋯渣廢料*德楊Pt噴砂/新唐-含金Wafer.含金石英玻璃',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  E1: {
    name: '藍白膜金*威力富-白膜',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  F1: {
    name: '銀濕土(榮鑫)*一詮銀土',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  G1: {
    name: '樹脂(非In樹脂)-客來料*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  H1: {
    name: '銀極桶*欣興極桶',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  H2: {
    name: '金回收廢料(烘乾後投酸溶)*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  H3: {
    name: '樹脂(非In樹脂)-廠內樹脂*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  H4: {
    name: '白金樹脂*先進行烘乾->混合->縮分取樣->焚化->研磨->過篩->混合->縮分取樣->酸溶取樣佳龍樹脂(客戶需求)',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  H5: {
    name: '1.白金廢料2.濾渣、濾餅*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  J1: {
    name: '磁鐡(榮鑫)*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  K1: {
    name: '1.金濾心回收廢料2.金擦拭布3.金鈀濾心*南茂金擦拭布/威力富 Au濾心/威力富 Au擦拭布',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  K2: {
    name: '1.銀膏空罐2.銀膏(膠)3.電子廢料*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  K3: {
    name: '1.銀濾心2.濾渣、濾袋、濾餅3.電子廢料4.銀擦拭布4.釕廢料5.業務樣品測試6.聯詠IC(少金含膜)7.銀鈀擦拭布*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  K4: {
    name: '1.銀擦拭布2.銀鈀擦拭布*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  K5: {
    name: '樹脂(須積料焚化)*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  K6: {
    name: '1.白金廢料2.噴砂、集塵灰*台灣神隆Pt廢料',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  K7: {
    name: '1.無價物料2.PGC空罐*欣偉-毒化物塑膠袋/欣偉-汙泥/欣偉AuPt濾布/聯鈞-無價料/杜邦-濾網 Ag',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  K8: {
    name: '松瑞觸媒*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  L1: {
    name: '銀濕土*一詮銀土',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  L2: {
    name: '白金坩堝(含M)*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  L3: {
    name: '1.銀回收料2.鉑回收料3.金回收料4.二元回收料5.三元回收料*Ag廢料/Pt無坩堝',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  L4: {
    name: '1.銀回收廢料2.爐渣*亞光低含量銀土/廠內爐渣',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  L5: {
    name: '1.金回收廢料(榮鑫)  2.LBMA 重熔Ag料*主要榮鑫',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  M1: {
    name: '1.電子廢料(須球磨)2.爐渣3.廢坩堝4.噴砂、集塵灰5.濾渣、濾餅(不焚化)6.鉭鋯渣廢料7.Pd廢料8.噴砂9..釕廢料(研磨泥、線割泥、車屑料)*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  M2: {
    name: '1.電子廢料(須球磨)2.爐渣3.廢坩堝4.噴砂、集塵灰5.濾渣、濾餅(不焚化)6.鉭鋯渣廢料7.Pd廢料8.噴砂9..釕廢料(研磨泥、線割泥、車屑料)*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  M3: {
    name: '1.PtShields2.Pt.RuShields3.Ru.Shields 4. Littel fuse5.WD Holder(榮鑫送環科)*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  N1: {
    name: '委外併批廢料*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  O1: {
    name: '1.業務樣品測試2.亞光樣品*小樣測試',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  P1: {
    name: '1.回收廢料(金屑料.集塵金.金坩堝)2.Pd廢料3.銀廢料*德揚Au.Ag;只溶銀',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  P2: {
    name: '銦廢料(草酸銦/聯電殘靶/(In鎢舟)(FCC專用)*德揚Au.Ag;只溶銀',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  P3: {
    name: 'Pd廢料(須酸溶瀑氣廢液)*健策',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  Q1: {
    name: '1.金回收液2.鈀回收液3.銀回收液4.鉑回收液5.銦回收液6.二元回收液7.三元回收液*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  R1: {
    name: '1.氰化銀鉀2.硝酸銀(須水解)*弘全氰化銀鉀/硝酸銀',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  S1: {
    name: '1..鎵廢液2.非貴廢液3.業務樣品測試4.亞光樣品*氰化銀鉀',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  T1: {
    name: '熱處理專用(水洗壓濾)-集塵灰*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  Z1: {
    name: '德揚免取樣回收液*輸入Barcode串Lins直接入cus30',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  Z2: {
    name: '1.PtShields2.Pt.RuShields3.Ru.Shields 4. Littel fuse5.WD Holder(自榮鑫送德揚)*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  Z3: {
    name: '1.無價鋁布2.無價空罐3.客供料銀粒*豐輝-無價濾袋/無價鋁擦拭布.鋁罐.銀膠罐',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
  ZZ: {
    name: '不經PGM取樣/取樣完成*',
    nextLevel: getRoleGroup(CODE_GROUP),
  },
};

export const RECYCLE_LARGE_GROUP = {
  S: {
    name: '固體',
    nextLevel: SAMPLING_GROUP,
  },
  L: {
    name: '液體',
    nextLevel: SAMPLING_GROUP,
  },
};
