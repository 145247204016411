import { Button, ButtonGroup } from '@mezzanine-ui/react';
import { checkJWTRoles } from '@solar/data';
import moment from 'moment';

const issuedTypeMapping: Record<string, string> = {
  auto: '自動取號',
  manual: '手動取號',
  'manual-edited': '手動取號(已修改)',
  'auto-edited': '自動取號(已修改)',
};

export function useDispatchedTargetTableColumns({
  openEditModal,
  openDisableModal,
}: {
  openEditModal: (numberHistoryId: number, item: any) => void;
  openDisableModal: (numberHistoryId: number, item: any) => void;
}) {
  return [
    {
      title: '訂單號碼',
      dataIndex: 'order_id',
      width: 150,
    },
    {
      title: '訂單項次',
      dataIndex: 'sales_order_line',
      width: 100,
    },
    {
      title: '光洋靶編',
      dataIndex: 'internal_issued_number',
      width: 250,
    },
    {
      title: '客戶靶編',
      dataIndex: 'customer_issued_number',
      width: 600,
    },
    {
      title: '料號',
      dataIndex: 'material_id',
      width: 400,
    },
    {
      title: '光洋靶編規則',
      render: (source: Record<string, unknown>) => {
        const text = source['internal_number_rule_name'] as string;
        return text || '--';
      },
      width: 220,
    },
    {
      title: '客戶靶編規則',
      render: (source: Record<string, unknown>) => {
        const text = source['customer_number_rule_name'] as string;
        return text || '--';
      },
      width: 450,
    },
    {
      title: '建立日期',
      render: (record: Record<string, unknown>) => {
        const date = record.issued_date as string;
        return moment(date).format('YYYY-MM-DD');
      },
      width: 220,
    },
    {
      title: '停用日期',
      render: (record: Record<string, unknown>) => {
        const date = record.disable_date as string | undefined;
        return date ? moment(date).format('YYYY-MM-DD') : '--';
      },
      width: 220,
    },
    {
      title: '取號型態',
      render: (source: Record<string, unknown>) => {
        const issuedType = source['issued_type'] as string;
        return issuedTypeMapping[issuedType] || issuedType;
      },
      width: 200,
    },
    {
      title: '',
      render: (record: any) =>
        checkJWTRoles([80]) &&
        (record.disable_date ? null : (
          <ButtonGroup variant="contained">
            <Button
              onClick={() => openEditModal(record.number_history_id, record)}
            >
              編輯
            </Button>
            <Button
              danger
              onClick={() => openDisableModal(record.number_history_id, record)}
            >
              停用
            </Button>
          </ButtonGroup>
        )),
    },
  ];
}
