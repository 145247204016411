import { ReactNode, useEffect, useRef } from "react";

export function ExpandDescriptionsItem({ colspan, children }: { colspan: string; children: ReactNode; }) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref?.current) {
      ref?.current?.parentElement?.parentElement?.setAttribute('colspan', colspan);
    }
  }, [colspan, ref]);

  return (
    <div ref={ref}>
      {children}
    </div>
  );
}