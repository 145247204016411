// import useSWR from "swr";

export function useReturnMaterial({ id }: { id: string }) {
  // const { data, error } = useSWR(['/materials/mapping-old-materials', { params }]);
  const data = [
    {
      id: 'DLY0001',
      date: '2023/02/15',
      creator: '張博閔',
      resourceType: '1',
    },
    {
      id: 'DLY0002',
      date: '2023/02/15',
      creator: '張博閔',
      resourceType: '2',
    },
    {
      id: 'DLY0003',
      date: '2023/02/15',
      creator: '張博閔',
      resourceType: '3',
    },
  ].find((item) => item?.id === id);

  return {
    returnMaterial: data,
    // isLoading: !data && !error,
  }
}