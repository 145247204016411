import useSWR from "swr";


export function useMaterialPropertyMaintain(endpoint:string) {
  const { data, error, mutate } = useSWR(endpoint);
  
  return {
    data,
    isLoading: !error && !data,
    mutate
  };
}
