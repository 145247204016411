import { Typography } from '@mezzanine-ui/react';
import styles from './TargetNumber.module.scss';
import { TargetNumberDispatcherFilter } from './components/TargetNumberDispatcher/DispatcherFilter';
import { DispatcherTable } from './components/TargetNumberDispatcher/DispatcherTable';
import { DispatcherTargetNumberRule } from '@solar/data';
import { useState } from 'react';

export function TargetNumberDispatcherPage() {

  const [filterParams, setFilterParams] = useState({});

  const handleFilterSubmit = (newFilterParams: DispatcherTargetNumberRule) => {
    setFilterParams(newFilterParams);
  };

  return (
    <div className={styles.container}>
      <Typography variant="h1">靶編取號</Typography>
      <TargetNumberDispatcherFilter onSubmit={handleFilterSubmit} />
      <DispatcherTable filterParams={filterParams} />
    </div>
  );
}
