import { useState } from 'react';
import { Key, EventDataNode, DataNode } from 'rc-tree/lib/interface';
import {
  RCTreeDataType,
  useFetchTreeAllWithMembers,
} from './useFetchTreeAllWithMembers';

type ExpandCallback = (
  keys: Key[],
  info: {
    node: EventDataNode<DataNode>;
    expanded: boolean;
    nativeEvent: MouseEvent;
  }
) => void;

export function useExpandedKeys() {
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const { data, isValidating } = useFetchTreeAllWithMembers();

  const handleManualExpandOperation: ExpandCallback = (keys, info) => {
    setExpandedKeys(keys);
  };

  const handleExpandAll = () => {
    if (!(data instanceof Array) || data.length === 0) return;
    setExpandedKeys(saveAllKeys(data));
  };

  const handleCollapseAll = () => {
    if (!(data instanceof Array) || data.length === 0) return;
    setExpandedKeys([]);
  };

  return {
    expandedKeys,
    setExpandedKeys,
    handleManualExpandOperation,
    handleExpandAll,
    handleCollapseAll,
  };
}

function saveAllKeys(data: RCTreeDataType[] | undefined) {
  return !(data instanceof Array) || data.length === 0
    ? []
    : data.reduce((accumulator: any, current: RCTreeDataType) => {
        accumulator.push(current.key);
        accumulator.push(...saveAllKeys(current.children));
        return accumulator;
      }, []);
}
