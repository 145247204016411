import { CopyOutlined, SettingOutlined, FormOutlined } from '@ant-design/icons';
import { TableColumn } from '@mezzanine-ui/core/table';
import { LogisticsDataType } from './RequirementManagement.interface';
import {
  LogisticTypeOptions,
  DeliveryAreaOptions,
  UnitOptions,
} from './RequirementsManagement.config';
import { Button } from '@mezzanine-ui/react';
import { useNavigate } from 'react-router';
import styles from './RequirementsManagementPage.module.scss';
import moment from 'moment';

const formatDateTime = (dateString: string | null, format: string) => {
  return dateString ? moment(dateString).format(format) : '';
};

export function useSearchResultColumns(
  onOperateBtnClick: (index: number) => void
) {
  const navigate = useNavigate();
  const getClassName = (status: boolean, deleted: boolean) => {
    return deleted
      ? styles.deleted
      : status
      ? styles.confirmed
      : styles.unConfirmed;
  };

  const actionColumns: TableColumn<LogisticsDataType>[] = [
    {
      title: '複製',
      width: 80,
      align: 'center',
      render: (item) => (
        <div className={getClassName(item.requestStatus, !!item.deletedAt)}>
          <Button
            className={styles.tableCellBtn}
            color="secondary"
            disabled={!!item.deletedAt}
            onClick={() => navigate(`../requirement/new?source=${item.id}`)}
          >
            <CopyOutlined />
          </Button>
        </div>
      ),
    },
    {
      title: '操作',
      width: 80,
      align: 'center',
      render: (item, index) => {
        return (
          <Button
            className={styles.tableCellBtn}
            color="secondary"
            disabled={!!item.deletedAt}
            onClick={() => onOperateBtnClick(index)}
          >
            <SettingOutlined />
          </Button>
        );
      },
    },
    {
      title: '編輯',
      width: 80,
      align: 'center',
      render: (item) => (
        <Button
          className={styles.tableCellBtn}
          color="secondary"
          disabled={!!item.deletedAt}
          onClick={() => navigate(`../requirement/${item.id}`)}
        >
          <FormOutlined />
        </Button>
      ),
    },
  ];

  const briefColumns: TableColumn<LogisticsDataType>[] = [
    {
      title: '申請人',
      width: 90,
      dataIndex: 'applicant',
      ellipsis: false,
    },
    {
      title: '申請日期',
      width: 120,
      ellipsis: false,
      render: (item) => formatDateTime(item.applyDate, 'YYYY/MM/DD'),
    },
    {
      title: '申請狀態',
      width: 90,
      ellipsis: false,
      render: (item) => {
        if (item?.deletedAt) return '作廢';
        if (item?.requestStatus === undefined) return '';
        return item?.requestStatus ? '已確認' : '未確認';
      },
    },
    {
      title: '需求單號',
      dataIndex: 'requestFormId',
      ellipsis: false,
      width: 180,
    },
    {
      title: '出貨單號',
      dataIndex: 'sapImportDeliveryOrderId',
      ellipsis: false,
      width: 180,
    },
    {
      title: '車號',
      dataIndex: 'plateNumber',
      ellipsis: false,
      width: 120,
    },
    {
      title: '事由',
      width: 72,
      ellipsis: false,
      render: (item) =>
        LogisticTypeOptions.find((option) => option.id === item?.logisticType)
          ?.name || '',
    },
    // { title: '確認人員', width: 90, dataIndex: 'confirmor', ellipsis: false },
    // {
    //   title: '確認日期',
    //   width: 140,
    //   dataIndex: 'confirmDate',
    //   ellipsis: false,
    //   render: (item) => formatDateTime(item.confirmDate, 'YYYY/MM/DD'),
    // },
    {
      title: '預定派車日',
      width: 140,
      ellipsis: false,
      render: (item) => formatDateTime(item.dispatchDate, 'YYYY/MM/DD'),
    },
    {
      title: '送達區域',
      width: 120,
      ellipsis: false,
      render: (item) =>
        DeliveryAreaOptions.find((option) => option.id === item.deliveryArea)
          ?.name || '',
    },
    {
      title: '起點',
      dataIndex: 'deliveryStartCompany',
      ellipsis: false,
      width: 280,
    },
    {
      title: '起點地址',
      dataIndex: 'deliveryStartAddress',
      ellipsis: false,
    },
    {
      title: '迄點',
      dataIndex: 'deliveryEndCompany',
      ellipsis: false,
      width: 280,
    },
    { title: '迄點地址', dataIndex: 'deliveryEndAddress', ellipsis: false },
    {
      title: '異動時間',
      width: 150,
      ellipsis: false,
      render: (item) => moment(item.updatedAt).add(8, 'hours').format('YYYY/MM/DD HH:mm:ss'),
    },
  ];

  const detailedColumns: TableColumn<LogisticsDataType>[] = [
    {
      title: '預定日期',
      width: 140,
      ellipsis: false,
      render: (item) => formatDateTime(item.dispatchDate, 'YYYY/MM/DD'),
    },
    {
      title: '事由',
      width: 72,
      ellipsis: false,
      render: (item) =>
        LogisticTypeOptions.find((option) => option.id === item?.logisticType)
          ?.name || '',
    },
    {
      title: '需求單號',
      dataIndex: 'requestFormId',
      ellipsis: false,
      width: 180,
    },
    {
      title: '出貨單號',
      dataIndex: 'sapImportDeliveryOrderId',
      ellipsis: false,
      width: 180,
    },
    {
      title: '車號',
      dataIndex: 'plateNumber',
      ellipsis: false,
      width: 120,
    },
    {
      title: '送達區域',
      width: 120,
      ellipsis: false,
      render: (item) =>
        DeliveryAreaOptions.find((option) => option.id === item.deliveryArea)
          ?.name || '',
    },
    // #region Items
    {
      title: '品號',
      width: 300,
      ellipsis: false,
      render: (item) => item.items[0]?.productNumber,
    },
    {
      title: '品名',
      width: 300,
      ellipsis: false,
      bodyClassName: styles.wordBreak,
      render: (item) => item.items[0]?.productName,
    },
    {
      title: '件數',
      width: 60,
      render: (item) => item.items[0]?.pieceNumber,
    },
    {
      title: '毛重',
      width: 60,
      render: (item) => item.items[0]?.totalGrossWeight,
    },
    {
      title: '毛重單位',
      width: 120,
      render: (item: LogisticsDataType) => {
        const option = UnitOptions.find(
          (o) => o.id === item.items[0]?.totalGrossWeightUnit
        );
        return option?.name;
      },
    },
    {
      title: '尺寸(cm)',
      width: 160,
      render: (item: LogisticsDataType) => {
        if (!item.items[0]) return '';
        const {
          volumeLength: l,
          volumeWidth: w,
          volumeHeight: h,
        } = item.items[0];
        if (!l || !w || !h) return '';
        return `${l} x ${w} x ${h}`;
      },
    },
    {
      title: '是否堆疊',
      width: 60,
      align: 'center',
      render: (item: LogisticsDataType) => {
        if (item.items[0]?.isStack === undefined) return '';
        return item.items[0]?.isStack ? 'Y' : 'N';
      },
    },
    {
      title: '有無棧板',
      width: 60,
      align: 'center',
      render: (item: LogisticsDataType) => {
        if (item.items[0]?.isUsingPallet === undefined) return '';
        return item.items[0]?.isUsingPallet ? 'Y' : 'N';
      },
    },
    {
      title: '棧板尺寸(cm)',
      width: 160,
      render: (item: LogisticsDataType) => {
        if (!item.items[0]) return '';
        const { palletSizeLength: l, palletSizeWidth: w } = item.items[0];
        if (!l || !w) return '';
        return `${l} x ${w}`;
      },
    },
    {
      title: '棧板號',
      width: 100,
      align: 'center',
      render: (item) => item.items[0]?.palletNumber,
    },
    {
      title: '棧板重量(KG)',
      width: 100,
      align: 'center',
      render: (item) => item.items[0]?.palletWeight,
    },
    {
      title: '尺寸(cm)',
      width: 160,
      render: (item: LogisticsDataType) => {
        if (!item.items[0]) return '';
        const { boxLength: l, boxWidth: w, boxHeight: h } = item.items[0];
        if (!l || !w || !h) return '';
        return `${l} x ${w} x ${h}`;
      },
    },
    // #endregion
    {
      title: '確認日期',
      width: 140,
      ellipsis: false,
      render: (item) => formatDateTime(item.confirmDate, 'YYYY/MM/DD'),
    },
    {
      title: '確認時間',
      width: 140,
      ellipsis: false,
      render: (item) => formatDateTime(item.confirmDate, 'hh:mm'),
    },
    {
      title: '起點',
      dataIndex: 'deliveryStartCompany',
      ellipsis: false,
      width: 280,
    },
    {
      title: '起點地址',
      dataIndex: 'deliveryStartAddress',
      ellipsis: false,
      width: 280,
    },
    {
      title: '起點聯絡人',
      dataIndex: 'deliveryStartContact',
      ellipsis: false,
      width: 140,
    },
    {
      title: '迄點',
      dataIndex: 'deliveryEndCompany',
      ellipsis: false,
      width: 280,
    },
    {
      title: '迄點地址',
      dataIndex: 'deliveryEndAddress',
      ellipsis: false,
      width: 280,
    },
    {
      title: '迄點聯絡人',
      dataIndex: 'deliveryEndContact',
      ellipsis: false,
      width: 140,
    },
    {
      title: '成本歸屬單位',
      dataIndex: 'costUnit',
      width: 120,
      ellipsis: false,
    },
    {
      title: '回收申報',
      width: 60,
      ellipsis: false,
      align: 'center',
      render: (item) => {
        if (item?.isRecycle === undefined) return '';
        return item?.isRecycle ? 'Y' : 'N';
      },
    },
    {
      title: '申請人',
      width: 90,
      dataIndex: 'applicant',
      ellipsis: false,
    },
    {
      title: '申請狀態',
      width: 90,
      ellipsis: false,
      render: (item) => {
        if (item?.deletedAt) return '作廢';
        if (item?.requestStatus === undefined) return '';
        return item?.requestStatus ? '已確認' : '未確認';
      },
    },
  ];

  return {
    actionColumns,
    briefColumns,
    detailedColumns,
  };
}
