import { Button, Popper, Table } from '@mezzanine-ui/react';
import styles from './material-table.module.scss';
import { LocationLevel, getZoneDisplayName, useSVGs } from '@solar/data';
import { useState } from 'react';
import {
  LocationSVGViewer,
  transformZoneToLocation,
} from '../../Equipment/LocationSelector/LocationSVGViewer';
import { CostWareHouseRadio } from '../const/cost-warehouse';

type MaterialTableProps = {
  equipments: any[];
  pageInfo: any;
  isLoading: boolean;
  defaultView: CostWareHouseRadio;
  mutateGetMaterials: (arg0: any) => void;
  openMaterialDetailModal: (arg0: any) => void;
};

export function MaterialTable({
  defaultView,
  equipments,
  pageInfo,
  mutateGetMaterials,
  isLoading,
  openMaterialDetailModal,
}: MaterialTableProps) {
  const [anchorRef, setAnchorRef] = useState<HTMLButtonElement | null>();
  const { svg, mutateGetSVG, resetSVG } = useSVGs();
  const [currentZone, setCurrentZone] = useState<any>();
  const isMaterialView = defaultView === CostWareHouseRadio.MATERIAL_VIEW; // 判斷當前 radio btn 選到的視角（物料｜倉庫）
  const columns = [
    {
      title: '料號',
      width: 400,
      dataIndex: 'id',
    },
    {
      width: 400,
      title: '規格',
      dataIndex: 'category.name',
    },
    {
      title: isMaterialView ? '數量' : '儲位',
      [isMaterialView ? 'width' : '']: 80,
      ellipsis: false,
      render: (source: any) =>
        isMaterialView ? (
          <>1</>
        ) : (
          <div
            data-zone-id={source?.zone?.id}
            onMouseEnter={(event: any) => {
              if (source?.zone?.id) {
                mutateGetSVG(source?.zone?.area?.id);
                setCurrentZone(source?.zone);
                setAnchorRef(event.currentTarget);
              }
            }}
            onMouseLeave={() => {
              if (source?.zone?.id) {
                resetSVG();
                setCurrentZone(null);
                setAnchorRef(null);
              }
            }}
          >
            {getZoneDisplayName(source?.zone)}
          </div>
        ),
    },
    {
      title: isMaterialView ? '操作' : '數量',
      width: 80,
      align: 'center' as const,
      render: (source: any) =>
        isMaterialView ? (
          <Button onClick={() => openMaterialDetailModal(source)}>View</Button>
        ) : (
          <>1</>
        ),
    },
  ];

  return (
    <>
      <Table
        loading={isLoading}
        pagination={{
          disableAutoSlicing: true,
          total: pageInfo?.totalCount ?? 0,
          current: pageInfo?.page ?? 1,
          onChange: (nextPage) => mutateGetMaterials({ nextPage }),
          options: {
            pageSize: pageInfo?.pageSize ?? 20,
            pageSizeLabel: '每頁顯示：',
            pageSizeUnit: '筆',
            onChangePageSize: (nextPageSize) =>
              mutateGetMaterials({ nextPage: 1, nextPageSize }),
            renderPageSizeOptionName: (p) => `${p}`,
            renderPaginationSummary: (start, end) =>
              `目前顯示 ${start} - ${end} 筆，共 ${end - start + 1} 筆資料`,
            showPageSizeOptions: true, // 開啟每頁顯示 N 筆
            showJumper: true, // 開啟跳頁功能
            jumperButtonText: '前往',
            jumperHintText: '跳至',
          },
        }}
        bodyClassName={styles.table}
        dataSource={equipments}
        columns={columns}
      />
      <Popper
        anchor={anchorRef}
        open={Boolean(anchorRef)}
        style={{
          border: '1px solid #000',
          borderRadius: '5px',
          backgroundColor: '#fff',
        }}
      >
        <LocationSVGViewer
          svg={svg}
          currentLevel={LocationLevel.ZONE}
          currentLocation={transformZoneToLocation(currentZone)}
        />
      </Popper>
    </>
  );
}
