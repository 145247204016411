import { RefObject } from 'react';

type MznTableRowExpandHelperProps = {
  ref: RefObject<HTMLTableElement> | null;
  index: number;
};
export const mznTableRowExpandHelper = ({
  ref,
  index,
}: MznTableRowExpandHelperProps) => {
  if (ref && ref?.current) {
    (
      (ref.current.querySelectorAll('tbody > tr.mzn-table__body__row') ?? [])[
        index
      ]?.querySelector(
        'td:first-child > div:first-child > div:first-child > i'
      ) as HTMLButtonElement
    )?.click();
  }
};
