import { IGraph, NodeConfig } from '@ant-design/charts';
import { useCallback, useState } from 'react';

export interface TrackingTreeContextMenuProps {
  x: number | null;
  y: number | null;
  open: boolean;
  nodeId: string | null;
  data: {
    materialId: string;
    materialSapBatchId: string;
    materialSubBatchId: string;
  } | null;
}

export interface TrackingTreeModalProps {
  open: boolean;
  data: any | null;
}

const defaultContextMenuProps = {
  x: null,
  y: null,
  open: false,
  nodeId: null,
  data: null,
};

// canvas, node 等事件設定參考下面連結
// https://ant-design-charts-v1.antgroup.com/en/api/graphs/common/common-graph#common-interaction-event

export function useTrackingTreeEvents() {
  const [contextMenuProps, setContextMenuProps] =
    useState<TrackingTreeContextMenuProps>(defaultContextMenuProps);

  const closeContextMenu = useCallback(() => {
    setContextMenuProps(defaultContextMenuProps);
  }, []);

  const onCanvasLeftClick = useCallback(
    (graph: IGraph) => {
      graph.on('canvas:click', (evt) => {
        evt.preventDefault();

        closeContextMenu();
      });
    },
    [closeContextMenu]
  );

  const onCanvasLeftDbClick = useCallback((graph: IGraph) => {
    graph.on('canvas:dblclick', (evt) => {
      evt.preventDefault();

      graph.zoom(2, { x: evt.canvasX, y: evt.canvasY });
    });
  }, []);

  const onCanvasRightClick = useCallback(
    (graph: IGraph) => {
      graph.on('canvas:contextmenu', (evt) => {
        evt.preventDefault();

        closeContextMenu();
      });
    },
    [closeContextMenu]
  );

  const onNodeLeftClick = useCallback((graph: IGraph) => {
    graph.on('node:click', (evt) => {
      evt.preventDefault();

      if (evt.item) graph.zoom(2, { x: evt.canvasX, y: evt.canvasY });
    });
  }, []);

  const onNodeRightClick = useCallback((graph: IGraph) => {
    graph.on('node:contextmenu', (evt) => {
      evt.preventDefault();

      const model = evt?.item?.getModel() as NodeConfig;
      console.log(model);
      if (!model?.x || !model?.y) {
        return;
      }

      setContextMenuProps((prevProps) => {
        const isSameNode = prevProps.nodeId === model.id;
        return {
          x: evt.canvasX,
          y: evt.canvasY,
          open: !isSameNode || !prevProps.open,
          nodeId: model.id ?? null,
          data: {
            materialId: model.value.title,
            materialSapBatchId: model.value.items[0].text.split('-')[0],
            materialSubBatchId: model.value.items[0].text.split('-')[1],
          },
        };
      });
    });
  }, []);

  return {
    contextMenuProps,
    closeContextMenu,
    onNodeLeftClick,
    onNodeRightClick,
    onCanvasLeftClick,
    onCanvasLeftDbClick,
    onCanvasRightClick,
  };
}
