import { AutoComplete, SelectValue, Typography } from '@mezzanine-ui/react';
import { useMemo } from 'react';

interface LocationLevelSelectProps {
  label: string;
  id: string | null;
  error: boolean;
  onChange: (value: SelectValue) => void;
  options: SelectValue[];
  loading: boolean;
  onSearch?: (searchTerm: string) => void;
}

export function LocationLevelSelect({
  label,
  id,
  error,
  onChange,
  options,
  loading,
  onSearch,
}: LocationLevelSelectProps) {
  const value = useMemo(
    () => (id ? options.find((option) => option.id === id) : null),
    [options, id]
  );

  return (
    <div>
      <Typography variant="h6" color="secondary">
        {label}
      </Typography>
      <AutoComplete
        fullWidth
        error={error}
        value={value}
        onChange={onChange}
        options={options}
        disabled={!options.length}
        onSearch={onSearch}
        placeholder="尚未選取"
      />
    </div>
  );
}
