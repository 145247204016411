import type { CustomerTradeConditionCreateFormValues } from '@solar/data';
import type { FieldPath } from 'react-hook-form';
import { SelectField } from '@mezzanine-ui/react-hook-form';
import { useFormContext } from 'react-hook-form';

type Props = {
  registerName: FieldPath<CustomerTradeConditionCreateFormValues>;
  defaultValue: { id: 'STANDARD' | 'ACTUAL'; name: '標準值' | '實際值' };
};

export function WeightMethodSelect({ registerName, defaultValue }: Props) {
  const formMethods = useFormContext<CustomerTradeConditionCreateFormValues>();

  const _PrivateFieldName = '_' + registerName;

  return (
    <SelectField
      control={formMethods.control}
      registerName={_PrivateFieldName}
      defaultValue={defaultValue}
      options={[
        { id: 'STANDARD', name: '標準值' },
        { id: 'ACTUAL', name: '實際值' },
      ]}
      onChange={(selected) => {
        formMethods.setValue(registerName, selected.id);
      }}
    />
  );
}
