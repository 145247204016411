import {
  MenuDivider,
  Radio,
  RadioGroup,
  Typography,
} from '@mezzanine-ui/react';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  ModalGroup,
  useModalGroupController,
} from '../Templates/Modal/ModalGroup';
import { BatchSerialModal } from './BatchSerialModal';
import {
  BATCH_SERIAL,
  MATERIAL_VIEW_ROUTE,
  WAREHOUSE_VIEW_ROUTE,
} from './constants';
import { WarehouseEnquiryMaterialView } from './MaterialView';
import styles from './warehouse-enquiry.module.scss';
import { WarehouseEnquiryWarehouseView } from './WarehouseView';

export function WarehouseEnquiry() {
  const location = useLocation();
  const navigate = useNavigate();

  const modalGroupController = useModalGroupController([
    {
      name: BATCH_SERIAL,
    },
  ]);

  const batchSerialModalOpen = useCallback(() => {
    modalGroupController.openModal(BATCH_SERIAL, {});
  }, [modalGroupController]);

  return (
    <div className={styles.page}>
      <Typography variant="h1">倉庫查詢</Typography>
      <RadioGroup value={location?.pathname} style={{ display: 'inline' }}>
        <Radio
          value={MATERIAL_VIEW_ROUTE}
          onClick={() => navigate(MATERIAL_VIEW_ROUTE)}
        >
          物料視角
        </Radio>
        <Radio
          value={WAREHOUSE_VIEW_ROUTE}
          onClick={() => navigate(WAREHOUSE_VIEW_ROUTE)}
        >
          倉庫視角
        </Radio>
      </RadioGroup>
      <MenuDivider />

      {location?.pathname === MATERIAL_VIEW_ROUTE && (
        <WarehouseEnquiryMaterialView
          batchSerialModalOpen={batchSerialModalOpen}
        />
      )}
      {location?.pathname === WAREHOUSE_VIEW_ROUTE && (
        <WarehouseEnquiryWarehouseView
          batchSerialModalOpen={batchSerialModalOpen}
        />
      )}
      <ModalGroup {...modalGroupController}>
        <BatchSerialModal />
      </ModalGroup>
    </div>
  );
}
