import { request } from '@solar/data';

export function uploadCsv({
  file,
  endPoint,
  namespace,
}: {
  file: File;
  endPoint: string;
  namespace: string;
}): Promise<Response> {
  const formData = new FormData();
  formData.append('file', file);

  return request(endPoint, {
    method: 'PUT',
    body: formData,
    json: false,
    namespace,
    responseParser: (res) => res,
  });
}
