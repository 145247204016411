import { TableColumn } from '@mezzanine-ui/core/table';
import { Button } from '@mezzanine-ui/react';
import {
  DateRangePickerField,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  NextPagination,
  WorkOrderStatus,
  getNextParams,
  useGetProductionVirtualDeliveryOrders,
  useMaterialRequestOrders,
} from '@solar/data';
import { CopyTextWrapper, MznPaginationTable } from '@solar/templates';
import { Space } from 'antd';
import moment from 'moment';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { shippedStates } from './shipped-states';

export function VirtualOutPage() {
  const navigate = useNavigate();
  const methods = useForm<{
    rangeOfDate?: [string, string];
    customerName?: string;
    status?: { id: WorkOrderStatus; name: string }[];
    searchTerm?: string;
  }>();

  const {
    productionVirtualDeliveryOrders,
    pageInfo,
    refetchGetProductionVirtualDeliveryOrders,
  } = useGetProductionVirtualDeliveryOrders({
    processTypes: ['01'],
    types: ['ZLC', 'ZLF1'],
  });

  const refetchGetProductionVirtualDeliveryOrdersWithPagination = useCallback(
    (nextPagination: NextPagination) => {
      const formState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      refetchGetProductionVirtualDeliveryOrders({
        processTypes: ['01'],
        types: ['ZLC', 'ZLF1'],
        createdDateFrom: formState?.rangeOfDate?.[0],
        createdDateTo: formState?.rangeOfDate?.[1],
        customerNames: formState?.customerName ? [formState?.customerName] : [],
        packedStates: formState?.status?.map((obj) => obj.id),
        searchTerm: formState?.searchTerm,
        offset,
        limit,
      });
    },
    [methods, pageInfo, refetchGetProductionVirtualDeliveryOrders]
  );

  const columns: TableColumn<any>[] = [
    {
      title: '操作',
      render: (source) => (
        <Button
          onClick={() =>
            navigate(
              `/warehouse/material-supply/virtual-delivery-order/${source?.deliveryOrderId}`
            )
          }
        >
          檢視
        </Button>
      ),
    },
    {
      title: '出貨單號',
      render: (source) => (
        <CopyTextWrapper text={source?.deliveryOrderId ?? ''} />
      ),
    },
    {
      title: '客戶名稱',
      render: (source) => <CopyTextWrapper text={source?.customerName ?? ''} />,
    },
    {
      title: '客戶代碼',
      render: (source) => <CopyTextWrapper text={source?.customerId ?? ''} />,
    },
    {
      title: '開立日期',
      render: (source) =>
        source?.createdAt ? moment(source?.createdAt).format('YYYY/MM/DD') : '',
    },
    {
      title: '狀態',
      dataIndex: 'packedState',
    },
  ];

  return (
    <Space direction="vertical">
      <FormFieldsWrapper methods={methods}>
        <Space align="end">
          <InputField
            label="出貨單號"
            placeholder="請輸入出貨單號"
            registerName="searchTerm"
          />
          <InputField
            label="客戶名稱"
            placeholder="請輸入客戶名稱"
            registerName="customerName"
          />
          <SelectField
            clearable
            mode="multiple"
            label="狀態"
            registerName="status"
            options={shippedStates ?? []}
          />
          <DateRangePickerField label="日期區間" registerName="rangeOfDate" />
          <Button
            variant="outlined"
            type="button"
            onClick={() =>
              refetchGetProductionVirtualDeliveryOrdersWithPagination({
                nextPage: 1,
              })
            }
          >
            查詢
          </Button>
        </Space>
      </FormFieldsWrapper>
      <MznPaginationTable
        pageInfo={pageInfo}
        fetchData={refetchGetProductionVirtualDeliveryOrdersWithPagination}
        dataSource={productionVirtualDeliveryOrders ?? []}
        columns={columns}
      />
    </Space>
  );
}
