import {
  Button,
  Message,
  Modal,
  ModalActions,
  ModalBody,
  SelectValue,
  Typography,
} from '@mezzanine-ui/react';
import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { TableColumn } from '@mezzanine-ui/core/table';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import { EditableBodyCellProps } from '@mezzanine-ui/react/Table';
import {
  getNextParams,
  NextPagination,
  PageInfo,
  useLoadersByCode,
  WaitReceiptObj,
} from '@solar/data';
import { useTargetModal } from '@solar/templates';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { Modals, SoftDeleteWaitReceiptPayload } from '../types';
import { softDeleteWaitReceipt } from '../utils';
import { ModalLayout } from '../../../Material/ModalLayout/ModalLayout';
import { KeyedMutator } from 'swr';

export type ReceiveActionInputs = {
  mainMaterialUnitQuantity?: number;
  warehouse?: SelectValue;
  loader: SelectValue | null;
};

export type WaitReceiptModalProps = {
  mutate: KeyedMutator<{
    records: WaitReceiptObj[];
    pageInfo: PageInfo;
  }>;
};

export function DeleteWaitReceiptModal({ mutate }: WaitReceiptModalProps) {
  const modalController = useTargetModal<{ id: string }>(
    Modals.DeleteWaitReceipt
  );

  const handleModalClose = useCallback(() => {
    modalController.closeModal();
  }, [modalController]);

  const [deleting, setDeleting] = useState(false);

  const onDelete = useCallback(async () => {
    if (!modalController?.data?.id) {
      Message.error('無資料來源');
      return;
    }

    try {
      setDeleting(true);

      const res = await softDeleteWaitReceipt({
        payload: { ids: [Number(modalController.data.id)] },
      });

      if (res.ok) {
        handleModalClose();
        Message.success('刪除成功');
        await mutate(undefined, true);
      }
    } catch (err) {
      if (err instanceof Error) {
        Message.error(JSON.parse(err.message).message);
      } else {
        Message.error('刪除失敗');
      }
      throw err;
    } finally {
      setDeleting(false);
    }
  }, [modalController?.data, mutate]);

  return (
    <ModalLayout
      modalHeader="刪除"
      {...modalController}
      closeModal={handleModalClose}
      modalFooter={
        <ModalActions
          confirmText="確認刪除"
          cancelText="取消"
          confirmButtonProps={{
            type: 'button',
            loading: deleting,
          }}
          onCancel={handleModalClose}
          onConfirm={onDelete}
        />
      }
    >
      <Typography variant="body1" color="error">
        確認是否刪除
      </Typography>
      <strong>編號：{modalController?.data?.id ?? ''}</strong>
    </ModalLayout>
  );
}
