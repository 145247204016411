import { TableColumn } from '@mezzanine-ui/core/table';
import { Button, Message, SelectValue, Table } from '@mezzanine-ui/react';
import { InputField } from '@mezzanine-ui/react-hook-form';
import {
  API_NAMESPACE,
  MaterialShiftInputFields,
  useInventoryByMaterial,
} from '@solar/data';
import { Descriptions } from 'antd';
import { memo, useCallback, useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { BarcodeScanInput } from '../../Material/MaterialShift/BarcodeScanInput';
import classes from './packaging-station-detail-page.module.scss';
import {
  PackageDetailBarcodeTable,
  PackagedTableColumns,
  PackagingExpandRowRecords,
  PackingsResponse,
  PalletsList,
  SetupPackagingMethods,
} from './types';

export type PackagingTableProps = {
  pallets: PalletsList;
  palletIndex: number;
};

const namespace = API_NAMESPACE.HR;

export function PackagingTable({
  pallets,
  palletIndex: index,
}: PackagingTableProps) {
  const pallet = pallets[index];

  const setupPackagingMethods = useFormContext<SetupPackagingMethods>();
  const tableDataSource = useWatch({
    control: setupPackagingMethods.control,
    name: `${pallet.id}.tableRows`,
  });

  const { remove: removeBoxInPallet, append: appendBoxInPallet } =
    useFieldArray({
      control: setupPackagingMethods.control,
      name: `${pallet.id}.tableRows`,
    });

  const params = useParams<{ orderId: string }>();
  const { data, error } = useSWR<PackingsResponse>([
    `/deliveryOrders/get/${params.orderId}/packings`,
    { namespace },
  ]);
  const orders = data?.details ?? [];

  const { fetchInventoryByMaterial } = useInventoryByMaterial({
    defaultType: MaterialShiftInputFields.MATERIAL_BARCODE,
  });

  const expandedDataSource = useCallback(
    (
      key: number
    ): PackagingExpandRowRecords['tableRows'][number]['subItems'] => {
      const targePalletTable = setupPackagingMethods.getValues(
        `${pallet.id}.tableRows`
      );

      const targetOuterRow = targePalletTable?.find((d) => d.index === key);

      return targetOuterRow?.subItems ?? [];
    },
    [pallet.id, setupPackagingMethods]
  );

  const expandableData = useMemo(
    () => ({
      expandedRowRender: (
        record: PackagingExpandRowRecords['tableRows'][number]
      ) => {
        return {
          dataSource: expandedDataSource(record.index),
          columns: [
            {
              title: '箱號',
              dataIndex: 'index-null',
            },
            {
              title: '包材料號',
              dataIndex: 'packagingMaterialId-null',
            },
            {
              title: '包材名稱',
              dataIndex: 'packagingName-null',
            },
            {
              title: '料號',
              dataIndex: 'materialId-null',
            },
            {
              title: '品名',
              dataIndex: 'packagingName-null',
            },
            {
              title: '批號',
              dataIndex: 'batchId',
              render: (source: PackageDetailBarcodeTable, index: number) => {
                return (
                  <LocalBatchIdRenderer
                    inputIndex={index}
                    pallet={pallet}
                    batchId={source['batchId']}
                    boxIndex={source['index']}
                  />
                );
              },
            },
            {
              title: '數量',
              dataIndex: 'boxQuantity',
              render: (source: PackageDetailBarcodeTable, index: number) => {
                const localBoxOrder = tableDataSource.findIndex(
                  (row) => row.index === source.index
                );

                const basePath =
                  `${pallet.id}.tableRows.${localBoxOrder}` as const;

                return (
                  <InputField
                    size="small"
                    // key={setupPackagingMethods.watch(
                    //   `${basePath}.subItems.${index}.mainMaterialUnitQuantity`
                    // )}
                    // error={
                    //   !!setupPackagingMethods.getFieldState(
                    //     `${basePath}.subItems.${index}.mainMaterialUnitQuantity`
                    //   ).error?.message
                    // }
                    registerName={`${basePath}.subItems.${index}.mainMaterialUnitQuantity`}
                    type="number"
                  />
                );
              },
            },
            {
              title: '單位',
              dataIndex: 'productUnit',
            },
            {
              title: '淨重',
              dataIndex: 'netWeight',
            },
            {
              title: '毛重',
              dataIndex: 'grossWeight',
            },
            {
              title: '移除',
              dataIndex: 'remove',
            },
          ],
        };
      },
      rowExpandable: () => true,
    }),
    [expandedDataSource, pallet, setupPackagingMethods, tableDataSource]
  );

  const columns: TableColumn<PackagedTableColumns>[] = useMemo(() => {
    return [
      {
        title: '箱號',
        dataIndex: 'index',
      },
      {
        title: '包材料號',
        dataIndex: 'packagingMaterialId',
      },
      {
        title: '包材名稱',
        dataIndex: 'packagingName',
      },
      {
        title: '料號',
        dataIndex: 'materialId',
      },
      {
        title: '品名',
        dataIndex: 'packagingName',
      },
      {
        title: '批號',
        width: 250,
        render: (source, index) => {
          return (
            <BarcodeScanInput
              disableSearchBtn
              otherOnKeyDownAction={async (inputValue) => {
                try {
                  if (inputValue) {
                    const res = await fetchInventoryByMaterial({
                      type: MaterialShiftInputFields.MATERIAL_BARCODE,
                      searchTerms: [inputValue],
                      limit: 0,
                    });

                    const { records } = res;

                    if (records?.length === 0) {
                      Message.info('查無資料');
                      return;
                    }

                    const prevSubItems = setupPackagingMethods.getValues(
                      `${pallet.id}.tableRows.${index}.subItems`
                    );

                    setupPackagingMethods.setValue(
                      `${pallet.id}.tableRows.${index}.subItems`,
                      (prevSubItems ?? [])?.concat(records).map((row) => ({
                        ...row,
                        index: source['index'],
                      })) ?? []
                    );
                  }
                } catch (e) {
                  console.log(e);
                }
              }}
            />
          );
        },
      },
      {
        title: '數量',
        dataIndex: 'boxQuantity',
        width: 150,
      },
      {
        title: '單位',
        dataIndex: 'productUnit',
      },
      {
        title: '淨重',
        dataIndex: 'netWeight',
      },
      {
        title: '毛重',
        dataIndex: 'grossWeight',
      },
      {
        title: '',
        render: (_, index) => {
          return (
            <Button
              type="button"
              onClick={() => {
                removeBoxInPallet(index);
              }}
            >
              移除
            </Button>
          );
        },
      },
    ];
  }, [
    fetchInventoryByMaterial,
    pallet.id,
    removeBoxInPallet,
    setupPackagingMethods,
  ]);
  return (
    <div>
      <Descriptions>
        <Descriptions.Item label="棧版編號">
          {`${String(index + 1).padStart(3, '0')}`}
        </Descriptions.Item>
        <Descriptions.Item label="棧板規格：">{pallet.name}</Descriptions.Item>
        <Descriptions.Item contentStyle={{ display: 'block' }}>
          <Button
            type="button"
            variant="contained"
            style={{ float: 'right' }}
            onClick={() => {
              // index 意義上是『箱號』
              const allTakenBoxIndices = pallets.reduce((acc, localPallet) => {
                const tableRows =
                  setupPackagingMethods.getValues(
                    `${localPallet.id}.tableRows`
                  ) ?? [];

                if (tableRows?.length) {
                  tableRows
                    .map((detail) => detail.index)
                    .forEach((element) => {
                      acc.push(element);
                    });
                }

                return acc;
              }, [] as number[]);

              const pickedBox = orders.find((order) => {
                return !allTakenBoxIndices.includes(order.index);
              });

              if (pickedBox?.id) {
                appendBoxInPallet({ ...pickedBox, subItems: [] });
              }
            }}
          >
            ＋新增裝箱
          </Button>
        </Descriptions.Item>
      </Descriptions>
      <Table
        loading={!data || error}
        bodyClassName={classes['table-body']}
        columns={columns}
        dataSource={tableDataSource ?? []}
        expandable={expandableData}
      />
    </div>
  );
}

const LocalBatchIdRenderer = memo(
  ({
    inputIndex,
    pallet,
    batchId,
    boxIndex,
  }: {
    inputIndex: number;
    pallet: PalletsList[number];
    batchId: string;
    boxIndex: number;
  }) => {
    const setupPackagingMethods = useFormContext<SetupPackagingMethods>();

    const tableDataSource = useWatch({
      control: setupPackagingMethods.control,
      name: `${pallet.id}.tableRows`,
    });

    const localBoxOrder = tableDataSource.findIndex((ele) => {
      return ele.index === boxIndex;
    });

    const { remove } = useFieldArray({
      control: setupPackagingMethods.control,
      name: `${pallet.id}.tableRows.${localBoxOrder}.subItems`,
    });

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p>{batchId}</p>
        <Button type="button" onClick={() => remove(inputIndex)}>
          移除
        </Button>
      </div>
    );
  }
);
