export enum LocationLevel {
  FACTORY = 'FACTORY',
  BUILDING = 'BUILDING',
  FLOOR = 'FLOOR',
  AREA = 'AREA',
  ZONE = 'ZONE',
  STACK = 'STACK',
  SHELF = 'SHELF',
}

export interface LocationLevelConfig {
  level: LocationLevel;
  label: string;
  svg?: {
    show: boolean;
    includeLevels: LocationLevel[];
  };
}

interface LocationBasicInfo {
  id: string;
  name: string;
}

export interface Factory extends LocationBasicInfo {
  seq: string;
}

export interface Building extends LocationBasicInfo {
  seq: string;
  factoryId: string;
}

export interface BuildingWithResource extends Building {
  factory: Factory;
}

export interface Floor extends LocationBasicInfo {
  seq: string;
  buildingId: string;
}

export interface FloorWithResource extends Floor {
  building: BuildingWithResource;
}

export interface Area extends LocationBasicInfo {
  seq: string;
  floorId: string;
  equipmentQuantity: number | null;
}

export interface AreaWithResource extends Area {
  floor: FloorWithResource;
}

export interface Zone extends LocationBasicInfo {
  seq: string;
  areaId: string;
  zoneCategory: string;
  equipmentQuantity: number | null;
}

export interface ZoneWithResource extends Zone {
  area: AreaWithResource;
}

export interface Stack extends LocationBasicInfo {
  category: string;
  chartId: string;
  shelfQty: number;
  zoneId: string;
  equipmentQuantity: number | null;
}

export interface StackWithResource extends Stack {
  zone: ZoneWithResource;
}

export interface Shelf extends LocationBasicInfo {
  stackId: string;
}

export interface ShelfWithResource extends Shelf {
  stack: StackWithResource;
}
