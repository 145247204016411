import * as Yup from 'yup';
import { exportSchemas } from './export-schemas';

export const exportValidation = () => {
  const validateObj = Yup.object({
    [exportSchemas.COMPANY_ID]: Yup.object().required(),
    [exportSchemas.YEAR]: Yup.object().required(),
    [exportSchemas.MONTH]: Yup.object().required(),
    [exportSchemas.CREATE_DATE]: Yup.string().required(),
  });

  return validateObj;
};
