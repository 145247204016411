import { useCallback, useMemo } from 'react';
import useSWR from 'swr';
import { Factory, CurrentLocation } from './types';
import { SelectValue } from '@mezzanine-ui/react';

export enum LocationLevel {
  FACTORY = 'FACTORY',
  BUILDING = 'BUILDING',
  FLOOR = 'FLOOR',
  BUILDING_FLOOR = 'BUILDING_FLOOR',
  AREA = 'AREA',
  ZONE = 'ZONE',
  STACK = 'STACK',
  SHELF = 'SHELF',
}

export function useFactories(params?: {
  returnLevel: Omit<LocationLevel, 'SHELF'>;
}) {
  const { data: factories, error } = useSWR<Factory[]>([
    '/factorys',
    { params: params },
  ]);

  const factoriesTreeSelectOptions = useMemo(
    () =>
      factories?.map((factory) => ({
        id: factory.id,
        name: factory.name,
        siblings: factory?.buildings
          ?.map((building) =>
            building?.floors?.map((floor) => ({
              id: floor.id,
              name: `${building.name}-${floor.name}`,
              siblings: floor?.areas?.map((area) => ({
                id: area.id,
                name: area.name,
                siblings: area?.zones?.map((zone) => ({
                  id: zone.id,
                  name: zone.name,
                  siblings: zone?.stacks,
                })),
              })),
            }))
          )
          .flat(),
      })),
    [factories]
  );

  return {
    factories,
    isLoading: !error && !factories,
    factoriesTreeSelectOptions,
  };
}

export function useCurrentLocationOptions(
  currentLocation: CurrentLocation | null
) {
  const { factories: factoryOptions } = useFactories();

  const buildingOptions = useMemo(() => {
    const targetFactory = factoryOptions?.find(
      (option) => option?.id === currentLocation?.factory?.id
    );
    return targetFactory?.buildings ?? [];
  }, [factoryOptions, currentLocation?.factory?.id]);

  const floorOptions = useMemo(() => {
    const targetBuilding = buildingOptions?.find(
      (option) => option?.id === currentLocation?.building?.id
    );
    return targetBuilding?.floors ?? [];
  }, [buildingOptions, currentLocation?.building?.id]);

  const areaOptions = useMemo(() => {
    const targetBuildingFloor = floorOptions?.find(
      (option) => option?.id === currentLocation?.floor?.id
    );
    return targetBuildingFloor?.areas ?? [];
  }, [floorOptions, currentLocation?.floor?.id]);

  const zoneOptions = useMemo(() => {
    const targetArea = areaOptions?.find(
      (option) => option?.id === currentLocation?.area?.id
    );
    return targetArea?.zones ?? [];
  }, [areaOptions, currentLocation?.area?.id]);

  /** @description: targetArea 底下所有的 stacks */
  const stackOptions = useMemo(() => {
    const targetArea = areaOptions?.find(
      (option) => option?.id === currentLocation?.area?.id
    );
    return targetArea?.zones?.map((zone) => zone?.stacks)?.flat() ?? [];
  }, [areaOptions, currentLocation?.area?.id]);

  const getTargetOption = useCallback(
    (currentLevel: LocationLevel | '', targetId: string | null) => {
      switch (currentLevel) {
        case LocationLevel.FACTORY:
          return factoryOptions?.find((option) => option.id === targetId);
        case LocationLevel.BUILDING:
          return buildingOptions?.find((option) => option.id === targetId);
        case LocationLevel.FLOOR: {
          const targetFactory = factoryOptions?.find(
            (option) => option?.id === currentLocation?.factory?.id
          );
          const targetBuilding = targetFactory?.buildings?.find(
            (option) => option.id === targetId
          );
          if (targetBuilding) {
            return buildingOptions?.find(
              (option) => option.id === targetBuilding?.floors?.[0]?.id
            );
          }
          return floorOptions?.find((option) => option.id === targetId);
        }
        case LocationLevel.AREA:
          return areaOptions?.find((option) => option.id === targetId);
        case LocationLevel.ZONE:
          return zoneOptions?.find((option) => option.id === targetId);
        default:
          return null;
      }
    },
    [
      currentLocation,
      areaOptions,
      buildingOptions,
      factoryOptions,
      floorOptions,
      zoneOptions,
    ]
  );

  const getCurrentLocation = useCallback(
    (item?: SelectValue): CurrentLocation => {
      const id = item?.id ?? '';
      const factory = factoryOptions?.find((option) => id.includes(option.id));
      const building = factory?.buildings?.find((option) =>
        id.includes(option.id)
      );
      const floor = building?.floors?.find((option) => id.includes(option.id));
      const area = floor?.areas?.find((option) => id.includes(option.id));
      const zone = area?.zones?.find((option) => id.includes(option.id));
      return {
        factory,
        building,
        floor,
        area,
        zone,
      };
    },
    [factoryOptions]
  );

  return {
    factoryOptions: factoryOptions ?? [],
    buildingOptions,
    floorOptions,
    areaOptions,
    zoneOptions,
    stackOptions,
    getTargetOption,
    getCurrentLocation,
  };
}
