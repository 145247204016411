import {
  DateRangePickerField,
  DateTimePickerField,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { useForm } from 'react-hook-form';
import { Button, ButtonGroup } from '@mezzanine-ui/react';
import styles from './RequirementsManagementPage.module.scss';
import {
  LogisticsListFilter,
  LogisticsListQuery,
} from './RequirementManagement.interface';
import {
  DeliveryAreaOptions,
  LogisticTypeOptions,
  RequestStatusOptionsWithVoid,
  TableTypes,
} from './RequirementsManagement.config';
import { useEffect } from 'react';

import moment from 'moment';
import { useApplyUnit } from '../RequirementPage/useApplyUnit';

interface FilterProps {
  canPrint: boolean;
  onSearch: (filters: Record<string, string>) => void;
  onPrint: () => void;
  onTypeChange: (type: TableTypes) => void;
}

const today = moment().startOf('day').toISOString();
const tomorrow = moment().add(1, 'days').endOf('day').toISOString();

const defaultValues: LogisticsListFilter = {
  dispatchDate: [today, tomorrow],
  applicant: '',
  company: '',
  logistic_type: undefined,
  request_status: undefined,
  delivery_area: undefined,
  updated_at: '',
  apply_unit: undefined,
  request_form_id: undefined,
  sapImported: undefined,
};

export function Filter({
  canPrint,
  onSearch,
  onPrint,
  onTypeChange,
}: FilterProps) {
  const { applyUnitOptions } = useApplyUnit();
  const methods = useForm<LogisticsListFilter>({ defaultValues });
  const submit = methods.handleSubmit((filters) => {
    const {
      dispatchDate,
      delivery_area,
      logistic_type,
      request_status,
      apply_unit,
      sapImported,
    } = filters;

    let dispatch_start_date = '';
    let dispatch_end_date = '';
    if (dispatchDate?.length) {
      dispatch_start_date = moment(dispatchDate?.[0])
        .startOf('day')
        .toISOString();
      dispatch_end_date = moment(dispatchDate?.[1]).endOf('day').toISOString();
    }

    const formatData: LogisticsListQuery = Object.entries({
      ...filters,
      dispatch_start_date,
      dispatch_end_date,
      apply_unit: apply_unit?.id,
      delivery_area: delivery_area?.id,
      logistic_type: logistic_type?.id,
      request_status: request_status?.id,
      sapImported: sapImported && sapImported.id,
    }).reduce((result, [key, value]) => {
      if (key === 'dispatchDate' || value === undefined || value === '') {
        return result;
      }
      return {
        ...result,
        [key]: value,
      };
    }, {});
    onSearch(formatData);
  });

  useEffect(() => {
    submit();
  }, []);

  return (
    <FormFieldsWrapper methods={methods} className={styles.container}>
      <DateRangePickerField
        registerName="dispatchDate"
        label="預計派車日"
      ></DateRangePickerField>

      <InputField
        registerName="request_form_id"
        label="派車單單號"
      ></InputField>
      <InputField registerName="applicant" label="申請人"></InputField>
      <InputField registerName="company" label="客戶名稱"></InputField>
      <SelectField
        registerName="logistic_type"
        label="事由"
        clearable
        options={LogisticTypeOptions}
      ></SelectField>
      <SelectField
        registerName="request_status"
        label="狀態"
        clearable
        options={RequestStatusOptionsWithVoid}
      ></SelectField>
      <SelectField
        registerName="delivery_area"
        label="送達區域"
        clearable
        options={DeliveryAreaOptions}
      ></SelectField>
      <DateTimePickerField
        registerName="updated_at"
        label="最後異動時間"
        clearable
      ></DateTimePickerField>
      <InputField
        registerName="delivery_start_address"
        label="起點地址"
      ></InputField>
      <InputField
        registerName="delivery_end_address"
        label="迄點地址"
      ></InputField>
      <SelectField
        registerName="apply_unit"
        label="申請單位"
        clearable
        options={applyUnitOptions}
      ></SelectField>

      <SelectField
        registerName="sapImported"
        label="單號種類"
        clearable
        options={[
          { id: 'false', name: 'CRH' },
          { id: 'true', name: 'CRS' },
        ]}
      ></SelectField>
      <InputField registerName="sapImportDeliveryOrderId" label="出貨單號" />

      <ButtonGroup
        className={styles.buttonGroup}
        variant="contained"
        size="large"
      >
        <Button
          onClick={() => {
            submit();
            onTypeChange(TableTypes.Brief);
          }}
        >
          查詢
        </Button>
        <Button
          onClick={() => {
            submit();
            onTypeChange(TableTypes.Detailed);
          }}
        >
          彙總查詢
        </Button>
        <Button onClick={onPrint} disabled={!canPrint}>
          列印
        </Button>
      </ButtonGroup>
    </FormFieldsWrapper>
  );
}
