import { Typography, Button, Tooltip } from '@mezzanine-ui/react';
import { InputField, RadioGroupField } from '@mezzanine-ui/react-hook-form';
import { Col, Row, Space, Table } from 'antd';
import { useFormContext } from 'react-hook-form';
import { Key, useCallback, useState } from 'react';
import {
  checkJWTRoles,
  InventoryItem,
  MaterialAllocationFormValues,
} from '@solar/data';

type ManualAllocationsTableProps = {
  removeManualData?: (selectedIds: string[]) => void;
  openAddMaterialsModal: () => void;
};

export function ManualAllocationsTable({
  removeManualData,
  openAddMaterialsModal,
}: ManualAllocationsTableProps) {
  const methods = useFormContext<MaterialAllocationFormValues>();
  const manualData = methods?.watch('manualData');

  const [selectedIds, setSelectedIds] = useState<Key[]>([]);

  const handleRemoveSelectedIds = useCallback(() => {
    removeManualData?.(selectedIds as string[]);
    setSelectedIds([]);
  }, [removeManualData, selectedIds]);

  return (
    <div>
      <Row style={{ margin: '12px 0' }} justify="space-between">
        <Col>
          <Typography variant="h4">手動配料</Typography>
        </Col>
        <Col>
          {checkJWTRoles([117]) && (
            <Row gutter={[12, 12]}>
              <Col>
                <Button variant="contained" onClick={openAddMaterialsModal}>
                  查詢配料
                </Button>
              </Col>
              <Col>
                <Button
                  danger
                  disabled={selectedIds?.length === 0}
                  variant="contained"
                  onClick={handleRemoveSelectedIds}
                >
                  移除手動配料
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Table
        scroll={{ x: 'max-content' }}
        pagination={false}
        dataSource={manualData ?? []}
        rowKey="batchStoredLoaderRecordId"
        rowSelection={{
          selectedRowKeys: selectedIds,
          onChange: (keys) => setSelectedIds(keys),
        }}
        columns={[
          {
            width: 100,
            title: '#',
            dataIndex: 'batchStoredLoaderRecordId',
          },
          {
            width: 300,
            title: '料號',
            dataIndex: 'materialId',
          },
          {
            width: 300,
            title: '批號',
            dataIndex: 'batchId',
            render: (value, source) =>
              `${source?.materialSapBatchId}_${source?.materialSubBatchId}`,
          },
          {
            width: 500,
            title: '品名',
            dataIndex: 'materialDescription',
          },
          {
            width: 100,
            title: '單位',
            dataIndex: 'materialStockUnit',
          },
          {
            width: 120,
            title: '儲位',
            dataIndex: 'shelfId',
          },
          {
            width: 200,
            title: '新舊料',
            dataIndex: 'usedOldMaterial',
            render: (_v, _r, index) => (
              <RadioGroupField
                type="button"
                registerName={`manualData.${index}.usedOldMaterial`}
                options={[
                  { label: '新料', value: 'false' },
                  { label: '舊料', value: 'true' },
                ]}
              />
            ),
          },
          {
            title: '成分代碼',
            render: (source: InventoryItem) => {
              console.log('source', source);
              return (
                <Tooltip
                  title={
                    <ul>
                      <Typography variant="h4">成份比例</Typography>
                      {source?.elementRatioAnswers?.map((formAnswer) => (
                        <li key={formAnswer?.id}>
                          <Space>
                            <Typography variant="h5">
                              {formAnswer?.form?.title ?? ''}
                            </Typography>
                            <Typography variant="h5">
                              {formAnswer?.modifiedAnswer &&
                              formAnswer?.modifiedAnswer !== ''
                                ? formAnswer?.modifiedAnswer
                                : formAnswer?.answer ?? ''}
                            </Typography>
                            <Typography variant="h5">
                              {formAnswer?.form?.suffix ?? ''}
                            </Typography>
                          </Space>
                        </li>
                      ))}
                    </ul>
                  }
                >
                  {({ onMouseEnter, onMouseLeave }) => (
                    <div
                      style={{
                        color: 'var(--mzn-color-primary)',
                        cursor: 'pointer',
                      }}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                    >
                      {source?.componentCode ?? '-'}
                    </div>
                  )}
                </Tooltip>
              );
            },
          },
          // {
          //   width: 100,
          //   title: '實際Wt%',
          //   dataIndex: 'actual_wt_percent',
          // },
          // {
          //   width: 100,
          //   title: '標準At%',
          //   dataIndex: 'standard_wt_percent',
          // },
          // {
          //   width: 100,
          //   title: '實際At%',
          //   dataIndex: 'actual_at_percent',
          // },
          {
            width: 120,
            title: '庫存量',
            dataIndex: 'availableMaterialStockUnitQuantity',
          },
          {
            width: 200,
            title: '應使用數量',
            dataIndex: 'usedMaterialUnitQuantity',
            render: (_v, _r, index) => (
              <InputField
                registerName={`manualData.${index}.usedMaterialUnitQuantity`}
              />
            ),
          },
          {
            width: 200,
            title: '應發料數量',
            dataIndex: 'requestOrderStockUnitQuantity',
            render: (_v, _r, index) => (
              <InputField
                registerName={`manualData.${index}.requestOrderStockUnitQuantity`}
              />
            ),
          },
        ]}
      />
    </div>
  );
}
