import {
  Button,
  Loading,
  MenuDivider,
  Message,
  SelectValue,
  Typography,
} from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  FormFieldsWrapper,
} from '@mezzanine-ui/react-hook-form';
import {
  API_NAMESPACE,
  checkJWTRoles,
  FetchingInventoryStockTypes,
  request,
  useGetDepts,
  useGetMembers,
  useInventoryByMaterial,
} from '@solar/data';
import {
  LocationAndLoaderPickerField,
  HorizontalField,
} from '@solar/templates';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { BarcodeScanInput } from './BarcodeScanInput';
import useInputGuard from './hooks/useInputGuard';
import { MaterialShiftTable } from './MaterialShiftTable';
import { MaterialShiftInputFields } from './shift-action.enum';
import classes from './shift-page.module.scss';
import {
  KeySourcePairType,
  ShiftActionRequestObject,
  ShiftTableColumnProps,
} from './typings';
import { enterKeyDownAction } from './utils';
import { Col, Row, notification } from 'antd';
import Decimal from 'decimal.js';
import { uniqBy } from 'lodash';

const namespace = API_NAMESPACE.MATERIALS;

const stockTypes = [
  FetchingInventoryStockTypes.AVAILABLE,
  FetchingInventoryStockTypes.PENDING,
  FetchingInventoryStockTypes.SALES,
];

export function MaterialShiftPage() {
  const [tableLoading, setTableLoading] = useState(false);
  const { members, refetchGetMembers } = useGetMembers();
  const { depts, refetchGetDepts } = useGetDepts();

  const [materialNumber, setMaterialNumber] = useState('');
  const [materialBarCode, setMaterialBarCode] = useState('');
  const [vehicleBarCode, setVehicleBarCode] = useState('');
  const [isEnterHit, setEnterHit] = useState(false);
  const [mockDataSourceByMaterialNumber, setMockDataSourceByMaterialNumber] =
    useState<Array<ShiftTableColumnProps>>([]);
  const [mockDataSourceByMaterialBarCode, setMockDataSourceByMaterialBarCode] =
    useState<Array<ShiftTableColumnProps>>([]);
  const [mockDataSourceByVehicleBarCode, setMockDataSourceByVehicleBarCode] =
    useState<Array<ShiftTableColumnProps>>([]);

  const [submitting, setSubmitting] = useState(false);

  const keySourcePair: KeySourcePairType<ShiftTableColumnProps> = useMemo(
    () => ({
      [MaterialShiftInputFields.MATERIAL_ID_OR_MATERIAL_NAME]: {
        fieldTranslation: '物料料號／品名',
        inputFieldValue: materialNumber,
        dataSource: mockDataSourceByMaterialNumber,
        dataSourceMaintainer: setMockDataSourceByMaterialNumber,
      },
      [MaterialShiftInputFields.MATERIAL_BARCODE]: {
        fieldTranslation: '物料條碼',
        inputFieldValue: materialBarCode,
        dataSource: mockDataSourceByMaterialBarCode,
        dataSourceMaintainer: setMockDataSourceByMaterialBarCode,
      },
      [MaterialShiftInputFields.LOADER_BARCODE]: {
        fieldTranslation: '載具條碼',
        inputFieldValue: vehicleBarCode,
        dataSource: mockDataSourceByVehicleBarCode,
        dataSourceMaintainer: setMockDataSourceByVehicleBarCode,
      },
    }),
    [
      materialBarCode,
      materialNumber,
      vehicleBarCode,
      mockDataSourceByMaterialBarCode,
      mockDataSourceByMaterialNumber,
      mockDataSourceByVehicleBarCode,
    ]
  );

  const filterMethods = useForm<{
    loader: SelectValue;
    loaderShelfId: string;
    selectedRowKeys: Array<string>;
    toShelfId: string;
    receiver: SelectValue;
    receiverDept: SelectValue;
  }>({ defaultValues: {} });

  const resetRowKeys = useCallback(() => {
    filterMethods.setValue('selectedRowKeys', []);
  }, []);

  const inputGuard = useInputGuard<ShiftTableColumnProps>(keySourcePair);

  const {
    fetchingInventoryParams,
    fetchInventoryByMaterial,
    mutateFetchInventoryParams,
  } = useInventoryByMaterial({
    defaultType: MaterialShiftInputFields.MATERIAL_ID_OR_MATERIAL_NAME,
  });

  const loaderShelfId = useWatch({
    control: filterMethods.control,
    name: 'loaderShelfId',
  });

  const toShelfId = useWatch({
    control: filterMethods.control,
    name: 'toShelfId',
  });

  const transferOrders = useCallback(
    async (payload: {
      items: any[];
      receiverId?: string;
      receiverDeptId?: string;
    }) => {
      try {
        if (payload?.items?.length) {
          const res = await request('/warehouses/inventory-transfer-orders', {
            method: 'post',
            body: JSON.stringify(payload),
            namespace,
            responseParser: (res) => res,
          });

          const data = await res?.json();

          if (res.ok) {
            notification.success({
              duration: 0,
              message: `物料移轉成功：${data?.id}`,
            });
          }
        }
      } catch (error: any) {
        notification.error({
          duration: 0,
          message: `物料移轉失敗：${JSON.parse(error?.message)?.message}`,
        });

        throw error;
      }
    },
    []
  );

  const changeLoaderLocation = useCallback(
    async (payload: {
      items: {
        toShelfId: string;
        fromLoaderId: string | null;
      }[];
      receiverId?: string;
      receiverDeptId?: string;
    }) => {
      try {
        const res = (await request(
          '/warehouses/inventory-transfer-orders/change-location',
          {
            method: 'post',
            body: JSON.stringify({
              items: payload.items,
              receiverId: payload?.receiverId,
              receiverDeptId: payload?.receiverDeptId,
            }),
            namespace,
            responseParser: (res) => res,
          }
        )) as Response;

        if (!res.ok) throw new Error(await res.text());

        const data = await res.json();

        notification.success({
          duration: 0,
          message: `載具移轉成功：${data?.id}`,
        });
      } catch (err: any) {
        notification.error({
          duration: 0,
          message: `載具移轉失敗：${payload.items
            .map((item) => item?.fromLoaderId)
            .join(', ')}（${JSON.parse(err.message)?.message}）`,
        });
      }
    },
    []
  );

  const onTransfer = useCallback(async () => {
    const values = filterMethods.getValues();
    const tableData = keySourcePair[fetchingInventoryParams?.type].dataSource;

    const isValid = tableData?.reduce((result, row) => {
      if (
        values?.selectedRowKeys?.includes(row.id) &&
        new Decimal(row?.materialWeightUnitQuantity ?? '0').lessThanOrEqualTo(0)
      ) {
        return false;
      }

      return result;
    }, true);

    if (!isValid) {
      Message.error('實際重量必須大於 0');
      return;
    }

    if (!values?.toShelfId) {
      Message.error('請選擇移轉儲位');
      return;
    }

    try {
      setSubmitting(true);

      if (
        fetchingInventoryParams?.type !==
        MaterialShiftInputFields.LOADER_BARCODE
      ) {
        let hasSameLoader = false;

        const items = tableData?.reduce((accumulator, row) => {
          if (row?.loaderId === values?.loader?.id) {
            hasSameLoader = true;
            return accumulator;
          }

          if (values?.selectedRowKeys?.includes(row.id)) {
            accumulator.push({
              batchStoredLoaderRecordId: row?.batchStoredLoaderRecordId,
              toShelfId: values?.toShelfId,
              toLoaderId: values?.loader?.id ?? null,
              materialStockUnitQuantity: new Decimal(row?.transferAmount ?? '0')
                .mul(row?.stockOverInputUnitRatio ?? '0')
                .toString(),
              materialWeightUnitQuantity: row?.materialWeightUnitQuantity
                ? new Decimal(row?.materialWeightUnitQuantity).toString()
                : undefined,
            });
          }

          return accumulator;
        }, [] as Omit<ShiftActionRequestObject, 'id'>[]);

        try {
          await transferOrders({
            items: uniqBy(items, 'batchStoredLoaderRecordId'),
            receiverId: values?.receiver?.id,
            receiverDeptId: values?.receiverDept?.id,
          });
        } catch (err) {
          hasSameLoader = false;
        }

        if (hasSameLoader) {
          await changeLoaderLocation({
            items: [
              {
                toShelfId: values?.toShelfId,
                fromLoaderId: values?.loader?.id ?? null,
              },
            ],
            receiverId: values?.receiver?.id,
            receiverDeptId: values?.receiverDept?.id,
          });
        }
      }

      if (
        fetchingInventoryParams?.type ===
        MaterialShiftInputFields.LOADER_BARCODE
      ) {
        await changeLoaderLocation({
          items: keySourcePair[fetchingInventoryParams?.type].dataSource?.map(
            ({ id: loaderId }) => ({
              toShelfId: values?.toShelfId,
              fromLoaderId: loaderId ?? null,
            })
          ),
          receiverId: values?.receiver?.id,
          receiverDeptId: values?.receiverDept?.id,
        });
      }
    } finally {
      setSubmitting(false);
    }

    keySourcePair[fetchingInventoryParams?.type].dataSourceMaintainer([]);
  }, [keySourcePair[fetchingInventoryParams?.type].dataSource]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 'var(--mzn-spacing-4)',
      }}
    >
      <Typography variant="h1">物料移轉</Typography>
      <MenuDivider />
      <div className={classes['input-fields-wrapper']}>
        <BarcodeScanInput
          inputStyle={classes['barcode-scan-input']}
          inputValue={materialBarCode}
          setInputValue={setMaterialBarCode}
          isEnterHit={isEnterHit}
          setEnterHit={setEnterHit}
          label={keySourcePair.MATERIAL_BARCODE.fieldTranslation}
          otherOnKeyDownAction={async () => {
            try {
              if (
                fetchingInventoryParams?.type !==
                MaterialShiftInputFields.MATERIAL_BARCODE
              ) {
                resetRowKeys();
              }

              setTableLoading(true);

              await enterKeyDownAction({
                keySourcePair,
                type: MaterialShiftInputFields.MATERIAL_BARCODE,
                inputGuard,
                fetchInventoryByMaterial,
                mutateFetchInventoryParams,
                fetchingInventoryParams,
              });
            } catch (err) {
              Message.error('系統問題！');
            } finally {
              setTableLoading(false);
            }
          }}
        />
        <BarcodeScanInput
          inputStyle={classes['barcode-scan-input']}
          inputValue={vehicleBarCode}
          setInputValue={setVehicleBarCode}
          isEnterHit={isEnterHit}
          setEnterHit={setEnterHit}
          label={keySourcePair.LOADER_BARCODE.fieldTranslation}
          otherOnKeyDownAction={async () => {
            try {
              if (
                fetchingInventoryParams?.type !==
                MaterialShiftInputFields.LOADER_BARCODE
              ) {
                resetRowKeys();
              }
              setTableLoading(true);

              setMockDataSourceByMaterialNumber([]);
              setMockDataSourceByMaterialBarCode([]);
              const res = await fetchInventoryByMaterial({
                type: MaterialShiftInputFields.LOADER_BARCODE,
                searchTerms: [vehicleBarCode],
                limit: 0,
              });
              const { records, pageInfo } = res;

              if (records?.length === 0) {
                Message.info('查無資料');
                return;
              }

              mutateFetchInventoryParams((prev) => ({
                ...pageInfo,
                searchTerms: [...prev.searchTerms, vehicleBarCode],
                type: MaterialShiftInputFields.LOADER_BARCODE,
              }));

              // prevent from push repeated data
              setMockDataSourceByVehicleBarCode((prev) => {
                if (prev?.some((item) => item?.id === records[0]?.loaderId)) {
                  return prev;
                }
                return [
                  ...prev,
                  {
                    id: records[0]?.loaderId,
                    batchStoredLoaderRecordId:
                      records[0]?.batchStoredLoaderRecordId,
                    loaderId: records[0]?.loaderId,
                    loaderName: records[0]?.loaderName,
                    shelfName: records[0]?.shelfName,
                    subItem: records,
                    remove: (
                      <Button
                        type="button"
                        onClick={() => {
                          setMockDataSourceByVehicleBarCode((prev) =>
                            prev.filter(
                              (row) => row?.loaderId !== records[0]?.loaderId
                            )
                          );
                        }}
                      >
                        移除
                      </Button>
                    ),
                  },
                ];
              });
            } catch (err) {
              Message.error('系統');
            } finally {
              setTableLoading(false);
            }
          }}
        />
        <BarcodeScanInput
          inputStyle={classes['barcode-scan-input']}
          inputValue={materialNumber}
          setInputValue={setMaterialNumber}
          isEnterHit={isEnterHit}
          setEnterHit={setEnterHit}
          label={keySourcePair.MATERIAL_ID_OR_MATERIAL_NAME.fieldTranslation}
          placeholder={`請輸入${keySourcePair.MATERIAL_ID_OR_MATERIAL_NAME.fieldTranslation}`}
          otherOnKeyDownAction={async () => {
            try {
              if (
                fetchingInventoryParams?.type !==
                MaterialShiftInputFields.MATERIAL_ID_OR_MATERIAL_NAME
              ) {
                resetRowKeys();
              }
              setTableLoading(true);

              await enterKeyDownAction({
                keySourcePair,
                type: MaterialShiftInputFields.MATERIAL_ID_OR_MATERIAL_NAME,
                inputGuard,
                fetchInventoryByMaterial,
                mutateFetchInventoryParams,
                fetchingInventoryParams,
              });
            } catch (err) {
              Message.error('系統問題');
            } finally {
              setTableLoading(false);
            }
          }}
        />
      </div>
      <FormFieldsWrapper methods={filterMethods}>
        <Loading
          loading={tableLoading || filterMethods?.formState?.isSubmitting}
        >
          <MaterialShiftTable
            keySourcePair={keySourcePair[fetchingInventoryParams?.type]}
            fetchingInventoryParams={fetchingInventoryParams}
            mutateFetchInventoryParams={mutateFetchInventoryParams}
            fetchInventoryByMaterial={fetchInventoryByMaterial}
          />
        </Loading>
        <MenuDivider />
        <br />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <LocationAndLoaderPickerField
              checkLoaderPosition
              locationIdLabel="移轉儲位："
              locationIdRegisterName="toShelfId"
              loaderIdLabel="放入載具："
              loaderIdRegisterName="loader"
              disabledLoaderField={
                fetchingInventoryParams?.type ===
                MaterialShiftInputFields.LOADER_BARCODE
              }
            />
          </Col>
          <Col span={12}>
            <HorizontalField label="接收人員：">
              <AutoCompleteField
                fullWidth
                registerName="receiver"
                options={members}
                onSearch={(searchTerm) => refetchGetMembers({ searchTerm })}
              />
            </HorizontalField>
          </Col>
          <Col span={12}>
            <HorizontalField label="接收單位：">
              <AutoCompleteField
                fullWidth
                registerName="receiverDept"
                options={depts}
                onSearch={(searchTerm) => refetchGetDepts({ searchTerm })}
              />
            </HorizontalField>
          </Col>
          <Col span={24}>
            {checkJWTRoles([83]) && (
              <Button
                variant="contained"
                disabled={
                  !toShelfId ||
                  (fetchingInventoryParams.type !==
                    MaterialShiftInputFields.LOADER_BARCODE &&
                    loaderShelfId !== toShelfId)
                }
                className={classes['shift-action-btn']}
                loading={submitting}
                onClick={onTransfer}
              >
                移轉
              </Button>
            )}
          </Col>
        </Row>
      </FormFieldsWrapper>
    </div>
  );
}
