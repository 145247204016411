import { useMemo } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { Button } from 'antd';
import { checkJWTRoles } from '@solar/data';

interface DataType {
  materialRequestOrderId: string;
  materialId: string;
  materialSapBatchId: string;
  materialBatchId: string;
  availableMaterialStockUnitQuantity: string;
  materialStockUnit: string;
}

export const useMaterialLabelColumn = () => {
  return useMemo<ColumnsType<DataType>>(
    () => [
      {
        title: '操作',
        render: (source) => {
          return (
            checkJWTRoles([115]) && (
              <Button
                type="link"
                onClick={() => {
                  window.open(
                    `/print-management/label?tab=batch-sticker&from=MaterialSupplyToWorkOrder&loaderMaterialBatchId=${source.recordId}`,
                    '_blank'
                  );
                }}
              >
                列印標籤
              </Button>
            )
          );
        },
      },
      {
        title: '發料單號',
        dataIndex: 'materialRequestOrderId',
      },
      {
        title: '目標料號',
        dataIndex: 'materialId',
      },
      {
        title: '目標批號',
        render: (_, { materialSapBatchId, materialBatchId }) =>
          `${materialSapBatchId ?? ''}-${materialBatchId}`,
      },
      {
        title: '管理數量單位',
        dataIndex: 'availableMaterialStockUnitQuantity',
      },
      {
        title: '單位',
        dataIndex: 'materialStockUnit',
      },
    ],
    []
  );
};
