import { SelectValue } from '@mezzanine-ui/react';
import {
  NextPagination,
  PageInfo,
  WaitReceiptObj,
  statusTag,
} from '@solar/data';
import { KeyedMutator } from 'swr';

export type WaitingStorageInColumnProps = {
  id: string;
  operation: string;
  stackId: string;
  outboundTime: string;
  workOrderId: string;
  materialId: string;
  materialDescription: string;
  batchId: string;
  loader: string;
  availableMaterialStockUnitQuantity: string;
  materialStockUnit: string;
  actualWeight: string;
  actualWeightUnit: string;
};

export type FilterPrams = {
  barcode: string;
  materialId: string;
  batchId: string;
  workOrderId: string;
  locationIds: SelectValue[] | null;
};

export enum TabsEnum {
  StorageIn = 'storage-in',
  Exception = 'exception',
  Record = 'record',
}

export enum Modals {
  Inspect = 'Inspect',
  DeleteWaitReceipt = 'DeleteWaitReceipt',
}

export interface ColumnHooks {
  openInspectModal: () => void;
}

export type PanelProps = {
  refetchWaitReceipt: (nextPagination: NextPagination) => void;
  mutateWaitReceipt?: KeyedMutator<any>;
  pageInfo?: PageInfo;
  tableData: WaitReceiptObj[];
  isLoading: boolean;
};

export type UpdateWaitReceiptPayload = {
  id: number;
  materialStockUnitQuantity: string;
  materialWeightUnitQuantity: string;
  newMaterialId: string;
  remark: string;
};

export type UpdateWaitReceiptStatusTagPayload = {
  materialStockUnitQuantity: string;
  materialWeightUnitQuantity: string;
  remark: string;
  statusTag: keyof typeof statusTag;
  waiverNumber: string | null;
};

export type SoftDeleteWaitReceiptPayload = {
  ids: number[];
};
