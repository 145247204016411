import { Button, ButtonGroup, Typography } from '@mezzanine-ui/react';
import styles from './../../TargetNumber.module.scss';
import { useForm } from 'react-hook-form';
import {
  AutoCompleteField,
  FormFieldsWrapper,
  InputField,
} from '@mezzanine-ui/react-hook-form';
import { useGetCustomerNumberRuleForDispatchedFilter } from '@solar/data';

interface TargetNumberRuleDispatchedFilterProps {
  onSubmit: (filterParams: DispatchedTargetNumberRuleFilter) => void;
}

export interface DispatchedTargetNumberRuleFilter {
  internal_issued_number?: string;
  customer_issued_number?: string;
  customer_number_rule?: {id: number, name: string};
  order_id?: string;
  sales_order_line?: string;
  material_id?: string;
  page_at?: number;
  page_size?: number;
}

export function TargetNumberDispatchedFilter({
  onSubmit,
}: TargetNumberRuleDispatchedFilterProps) {
  const methods = useForm<DispatchedTargetNumberRuleFilter>({
    defaultValues: {
      internal_issued_number: '',
      customer_issued_number: '',
      order_id: '',
      sales_order_line: '',
      material_id: '',
    },
  });

  const { customerNumberRule } = useGetCustomerNumberRuleForDispatchedFilter();

const options = customerNumberRule?.map((rule: any) => ({
  name: rule.number_rule_name,
  id: rule.number_rule_id,
}));

  const onSubmitHandler = (data: DispatchedTargetNumberRuleFilter) => {
    const mappedFilter = {
      internal_issued_number: data.internal_issued_number || undefined,
      customer_issued_number: data.customer_issued_number || undefined,
      customer_number_rule_id: data.customer_number_rule?.id,
      order_id: data.order_id || undefined,
      sales_order_line: data.sales_order_line || undefined,
      material_id: data.material_id || undefined,
    };
    onSubmit(mappedFilter);
    };

  return (
    <FormFieldsWrapper methods={methods} className={styles.filter} onSubmit={onSubmitHandler}>
      <div className={styles.formContent}>
        <Typography variant="h5">光洋靶編:</Typography>
        <InputField
          size="medium"
          registerName="internal_issued_number"
          width={300}
        />
        <Typography variant="h5">客戶靶編:</Typography>
        <InputField
          size="medium"
          registerName="customer_issued_number"
          width={300}
        />
        <Typography variant="h5">靶編規則:</Typography>
        <AutoCompleteField
          registerName="customer_number_rule"
          options={options || []}
          width={300}
        />
      </div>
      <div className={styles.formContent}>
        <Typography variant="h5">訂單號碼:</Typography>
        <InputField registerName="order_id" size="medium" width={300} />
        <Typography variant="h5">訂單項次:</Typography>
        <InputField registerName="sales_order_line" size="medium" width={300} />
        <Typography variant="h5">料號: </Typography>
        <InputField registerName="material_id" size="medium" width={330} />
      </div>
      <ButtonGroup
        className={styles.buttonGroup}
        variant="contained"
        size="large"
      >
        <Button type="submit">查詢</Button>
        <Button onClick={() => methods.reset()}>清除</Button>
      </ButtonGroup>
    </FormFieldsWrapper>
  );
  }
