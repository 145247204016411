import { Button, SelectValue, Typography } from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  getNextParams,
  MaterialRequestOrderSourceType,
  NextPagination,
  SORT_TYPE_SELECTIONS,
  useMaterialRequestOrders,
  WorkOrdersRequestParams,
  WORK_ORDERS_STATES,
} from '@solar/data';
import {
  FilterScaffold,
  ModalGroup,
  MznPaginationTable,
  useModalGroupController,
} from '@solar/templates';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DispatchMaterialViewModal } from '../WorkOrderManagementPage/modal/DispatchMaterialViewModal';
import { DispatchSettingModal } from '../WorkOrderManagementPage/modal/DispatchSettingModal';
import { Modals } from '../WorkOrderManagementPage/types';
import { LocalTabs } from './const';
import { DispatchOrdersFilter } from './DispatchOrdersFilter';
import { useDispatchOrdersColumn } from './hooks';

type FormProps = Omit<
  WorkOrdersRequestParams,
  | 'createdAtDateFrom'
  | 'createdAtDateTo'
  | 'expectedCompletedAtDateFrom'
  | 'expectedCompletedAtDateTo'
  | 'factoryId'
  | 'orderTypeCode'
  | 'sortType'
  | 'states'
> & {
  createdDateRange: [string, string];
  expectedCompletedDateRange: [string, string];
  factory: SelectValue;
  orderType: SelectValue;
  sortType: SelectValue;
  sourceType: SelectValue[];
  states: SelectValue[];
  materialRequestOrderId: string;
  workOrderId: string;
};

export function DispatchOrderPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { tab } = useParams<{ tab: LocalTabs }>();

  const methods = useForm<FormProps>({
    defaultValues: {
      sortType: SORT_TYPE_SELECTIONS[0],
    },
  });

  const { orders, mutateParams, pageInfo, isLoading, mutate } =
    useMaterialRequestOrders({
      defaultParams: {
        sortType: SORT_TYPE_SELECTIONS[0].id,
      },
    });

  const refetchProductionDeliveryOrders = useCallback(
    (nextPagination: NextPagination) => {
      const filterState = methods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);

      mutateParams({
        offset,
        limit,
        materialRequestOrderIds: filterState?.materialRequestOrderId
          ? [filterState?.materialRequestOrderId]
          : undefined,
        workOrderIds: filterState?.workOrderId
          ? [filterState?.workOrderId]
          : undefined,
        searchTerm: filterState?.searchTerm,
        expectedCompletedAtDateFrom: (filterState.createdDateRange ?? [])[0],
        expectedCompletedAtDateTo: (filterState.createdDateRange ?? [])[1],
        sourceTypes: filterState?.sourceType?.map(
          (type) => type.id as `${MaterialRequestOrderSourceType}`
        ),
        sortType: filterState?.sortType
          ?.id as WorkOrdersRequestParams['sortType'],
        states: filterState?.states?.map(
          (type) => type.id as keyof typeof WORK_ORDERS_STATES
        ),
      });
    },
    [methods]
  );

  const modalGroupController = useModalGroupController([
    { name: Modals.DISPATCH_MATERIAL_VIEW },
    { name: Modals.DISPATCH_SETTING },
  ]);

  const column = useDispatchOrdersColumn({ modalGroupController, mutate });

  // useEffect(() => {
  //   if (!tab) {
  //     navigate(location.pathname + `/${PAGE_OPTIONS.PRODUCTION.id}`);
  //   }

  //   if (
  //     tab &&
  //     ![PAGE_OPTIONS.PRODUCTION.id, PAGE_OPTIONS.OUTSOURCING.id].includes(tab)
  //   ) {
  //     navigate(location.pathname + `/../${PAGE_OPTIONS.PRODUCTION.id}`);
  //   }
  // }, [location.pathname, navigate, tab]);

  return (
    <div>
      <Typography variant="h1">發料單管理</Typography>
      <FormFieldsWrapper methods={methods}>
        <FilterScaffold
          mutateParams={refetchProductionDeliveryOrders}
          fetchAsParamsMutated={false}
        >
          <DispatchOrdersFilter />
          <Button
            loading={isLoading}
            type="button"
            variant="outlined"
            onClick={() => refetchProductionDeliveryOrders({ nextPage: 1 })}
          >
            確認
          </Button>
        </FilterScaffold>
        <br />
      </FormFieldsWrapper>
      <MznPaginationTable
        loading={isLoading}
        scroll={{ x: 2300 }}
        columns={column}
        dataSource={orders ?? []}
        fetchData={refetchProductionDeliveryOrders}
        pageInfo={pageInfo}
      />
      <ModalGroup {...modalGroupController}>
        <DispatchMaterialViewModal />
        <DispatchSettingModal />
      </ModalGroup>

      {/* <ProductionOrderPanel /> */}
      {/* <Tabs
        activeKey={tab}
        onChange={(tabId) => navigate(location.pathname + `/../${tabId}`)}
      >
        <TabPane
          key={PAGE_OPTIONS.PRODUCTION.id}
          tab={<Tab>{PAGE_OPTIONS.PRODUCTION.name}</Tab>}
        >
          <ProductionOrderPanel />
        </TabPane>
        <TabPane
          key={PAGE_OPTIONS.OUTSOURCING.id}
          tab={<Tab>{PAGE_OPTIONS.OUTSOURCING.name}</Tab>}
        >
          <ProductionOrderPanel />
        </TabPane>
      </Tabs> */}
    </div>
  );
}
