import { createContext } from 'react';
import { LocationControlContextInterface, LocationIds } from '../types';
import { LocationLevels } from '../LocationLevel';

export const defaultLocationIds = Object.fromEntries(
  LocationLevels.map(({ level }) => [level, null])
) as LocationIds;

export const LocationControlContext =
  createContext<LocationControlContextInterface>({
    activeId: null,
    error: false,
    level: null,
    loading: false,
    locationIds: defaultLocationIds,
  });
