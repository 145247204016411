import { Message } from '@mezzanine-ui/react';
import { environment } from '../../environments/environment';
import { API_NAMESPACE } from '../../request';

const urlHost = `${environment.API_HOST.replace('/equipments', '')}${
  API_NAMESPACE.IAM
}`;

const headers = {
  Accept: '*/*',
  'Content-Type': 'application/json',
  apikey: environment.API_KEY,
};

export const authGetFetcher = async (url: string) => {
  const options = {
    method: 'GET',
    headers: {
      ...headers,
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  };
  const res = await fetch(`${urlHost}${url}`, options);
  if (res.status === 401) {
    // Message['error']('權限不足');
  }
  const json = await res.json();
  return json ?? null;
};
