import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { UseDataSourceHook } from '../common-table-filter-param';
import { OffsetBased, PageInfo } from '../pagination';
import { API_NAMESPACE } from '../request';
// import { statusTag } from '../../../../apps/frontend/src/app/ProductionPlanning/WaitStorageInPage/const';

// const STATES = {
//   WAITING: '待發料',
//   PARTIAL: '部分已發',
//   COMPLETE: '已發料',
// } as const;

// const STATES_SELECTIONS = Object.entries(STATES).map(([key, value]) => ({
//   id: key,
//   name: value,
// }));

// const SORT_TYPE = {
//   CREATED_AT_DESC: '工單建立日期近 -> 遠',
//   CREATED_AT_ASC: '工單建立日期遠 -> 近',
//   EXPECTED_COMPLETED_AT_DESC: '訂單交期近 -> 遠',
//   EXPECTED_COMPLETED_AT_ASC: '訂單交期遠 -> 近',
// };

// export {
//   STATES as MATERIAL_REQUEST_ORDERS_STATES,
//   STATES_SELECTIONS as MATERIAL_REQUEST_ORDERS_STATES_SELECTIONS,
// };

export const statusTag = {
  WAITING: '待判定',
  WAIVER: '特採',
  CONTINUE: '續流程',
  REWORK: '重工',
  SCRAPPED: '報廢',
};

export enum WaitReceiptStatus {
  'NORMAL' = 'NORMAL',
  'ABNORMAL' = 'ABNORMAL',
  'NORMAL_EXECUTED' = 'NORMAL_EXECUTED',
  'ABNORMAL_EXECUTED' = 'ABNORMAL_EXECUTED',
}

export type WaitReceiptParams = {
  materialBarcodes: string[];
  materialIds: string[];
  materialBatchIds: string[];
  materialSapBatchIds: string[];
  materialSubBatchIds: string[];
  workOrderIds: string[];
  shelfIds: string[];
  sortType: string[];
  status: ReadonlyArray<WaitReceiptStatus>; //readonly [`${WaitReceiptStatus}`];
} & OffsetBased;

export type WaitReceiptObj = {
  id: string;
  materialId: string;
  materialSapBatchId: string;
  materialSubBatchId: string;
  materialStockUnitQuantity: string;
  materialWeightUnitQuantity: string;
  loaderId: string;
  materialRequestOrderId: string;
  status: WaitReceiptStatus;
  statusTag: keyof typeof statusTag;
  type: string;
  syncSapAt: string | null;
  createdAt: string;
  shelfId: string;
  workOrderId: string;
  materialDescription: string;
  materialStockUnit: string;
  materialInputUnit: string;
  materialBarcode: string;
  materialInputUnitQuantity: string;
  stockOverInputUnitRatio: string;
  waiverNumber: string | null;
  waitReceiptItemRemark: string | null;
};

// export type MaterialRequestOrder = {
//   id: string;
//   workOrderId: string;
//   creatorId: string;
//   // sourceType: `${MaterialRequestOrderSourceType}`;
//   expectedCompletedAt: string;
//   actualCompletedAt: string | null;
//   barcode: string | null;
//   createdAt: string;
//   updatedAt: string | null;
//   deletedAt: string | null;
// };

// export enum MaterialRequestOrderSourceType {
//   REQUEST_ORDER = 'REQUEST_ORDER',
//   INGREDIENTS_ORDER = 'INGREDIENTS_ORDER',
// }

// export const SOURCE_TYPE_TRANSLATION = {
//   [MaterialRequestOrderSourceType.REQUEST_ORDER]: '發料',
//   [MaterialRequestOrderSourceType.INGREDIENTS_ORDER]: '配料',
// };

const namespace = API_NAMESPACE.PP;

export function useWaitReceipt(defaultParams?: Partial<WaitReceiptParams>) {
  const [params, setParams] = useState<Partial<WaitReceiptParams> | undefined>(
    defaultParams
  );

  const { data, error, mutate, isValidating } = useSWR<{
    records: WaitReceiptObj[];
    pageInfo: PageInfo;
  }>(params ? ['/pp/wait-receipt', { params, namespace }] : null);

  return {
    data: data?.records || [],
    pageInfo: data?.pageInfo,
    isLoading: !error && !data && isValidating,
    refetchWaitReceipt: setParams,
    mutateWaitReceipt: mutate,
  };
}
