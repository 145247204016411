import { API_NAMESPACE, request } from '../request';

const namespace = API_NAMESPACE.PP;

export interface ExchangePair {
  fromMaterialId: string;
  fromMaterialSapBatchId: string;
  fromMaterialSubBatchId: string;
  fromLoaderId: string;
  toMaterialId: string;
  quantity: string;
}

export function exchangeMaterials(payload: {
  productionWorkOrderId: string;
  exchangePairs: ExchangePair[];
}) {
  return request('/exchange-materials', {
    method: 'POST',
    namespace,
    body: JSON.stringify(payload),
  });
}
