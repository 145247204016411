import useSWR from 'swr';
import { API_NAMESPACE } from '../request';

export interface SPExchangeRate {
  currency: string;
  currencyName: string;
  inRate: number;
  outRate: number;
}

export interface SPSolarQuotation {
  currency: string;
  elementName: string;
  issueDate: string;
  sysDate: string;
  itemNo: string;
  price: number;
  unit: string;
  tradeMode: '0' | '1';
}

export interface SPExchangeUnit {
  exchangeValue: number;
  unit: string;
}

export interface SPPremiun {
  elementName: string;
  price: number;
  reportType: '0' | '1' | '2';
  tradeMode: '0' | '1';
  unit: string;
}

export const useStrikePriceUnit = () => {
  // /reportPrice/listSolarQuotation 光洋盤api
  const { data: solarQuotation, mutate: getSolarQuotation } = useSWR<
    SPSolarQuotation[]
  >(['/reportPrice/listSolarQuotation', { namespace: API_NAMESPACE.MISC }]);

  // /reportPrice/listPremium 加減碼
  const { data: premiumList } = useSWR<SPPremiun[]>([
    '/reportPrice/listPremium',
    { namespace: API_NAMESPACE.MISC },
  ]);

  // /reportPrice/listExchangeUnit 單位值轉換
  const { data: exchangeUnit } = useSWR<SPExchangeUnit[]>([
    '/reportPrice/listExchangeUnit',
    { namespace: API_NAMESPACE.MISC },
  ]);

  // /selectItem/listExchangeRate 台銀匯率
  const { data: exchangeRate } = useSWR<SPExchangeRate[]>([
    '/selectItem/listExchangeRate',
    { namespace: API_NAMESPACE.MISC },
  ]);

  return {
    solarQuotation,
    getSolarQuotation,
    premiumList,
    exchangeUnit,
    exchangeRate,
  };
};
