export const ShipmentBoxMergeFieldName = {
  // post api
  DELIVERY_ORDER_ID: 'deliveryOrderId',
  PACKAGE_LIST_ID: 'packingListId',
  BOX_NUMBER: 'boxNum',
  // array
  BOX_MERGE_ITEMS: 'boxMergeItems',
} as const;

type DefaultRootFormValues = {
  rootServerError: {
    message: string;
  };
};

type ShipmentBoxMergeItem = {
  [ShipmentBoxMergeFieldName.PACKAGE_LIST_ID]: number;
  [ShipmentBoxMergeFieldName.BOX_NUMBER]: number;
};

export type ShipmentBoxMergeFormValues = {
  [ShipmentBoxMergeFieldName.DELIVERY_ORDER_ID]: string;
  [ShipmentBoxMergeFieldName.BOX_MERGE_ITEMS]: ShipmentBoxMergeItem[];
} & DefaultRootFormValues;
