import { ModalLayout } from '../../ModalLayout/ModalLayout';
import { Typography, cx } from '@mezzanine-ui/react';
import { useModalController } from '../../ModalLayout/hooks';

import classes from './material-scrapping-modal.module.scss';

export function RecoverScrappingDoneModal(
  props: ReturnType<typeof useModalController>
) {
  const { open, closeModal } = props;

  return (
    <ModalLayout
      className={cx(classes.modal)}
      open={open}
      closeModal={closeModal}
    >
      <Typography>報廢操作已取消</Typography>
    </ModalLayout>
  );
}
