import { TableColumn } from '@mezzanine-ui/core/table';
import {
  Button,
  Message,
  Modal,
  ModalHeader,
  Typography,
} from '@mezzanine-ui/react';
import { checkJWTRoles, responseMap, useTenantList } from '@solar/data';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  SearchInput,
  TreeSelectField,
} from '../../OrderManagement/FilterSearch/enhanceFilter/enhanceFilter';
import {
  EnhanceAutoComplete,
  EnhanceModalSearchInput,
  EnhanceTreeSelect,
} from '../../SalesAndDistribution/Layout/ModalLayout/enhanceModalActions/enhanceModalActions';
import {
  AuthListLayout,
  AuthListLayoutProps,
  FilterType,
} from '../Layout/ListLayout';
import { CreateEditModal } from '../Modal/CreateEditModal';
import { WarningModal } from '../Modal/WarningModal';
import styles from './TenantPage.module.scss';
import { TableCell } from '@mezzanine-ui/react/Table';
import { defaultResponse } from '../LoginPage/LoginPage';
import { ResetPasswordModal } from './ResetPasswordModal';

export const formatChineseDate = (timeStamp: number): string => {
  const date = new Date(timeStamp * 1000);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}年${month}月${day}日`;
};

export default function TenantPage() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any | undefined>();
  const {
    myAccountId,
    dataSource,
    tenantsObj,
    formatMembers,
    data: tenantsFilterData,
    methods,
    filterMethods,
    currentPage,
    onChangeCurrentPage,
    pageSize,
    onChangePageSize,
    onSearchTenantUsers,
    openModal,
    modalSource,
    modalTitle,
    onOpenWarningModal,
    onCloseModal,
    onOpenCreateEditModal,
    confirmStatusModal: onConfirmStatusModal,
    confirmDeleteModal: onConfirmDeleteModal,
    createEditModal: onCreateEditTenant,
  } = useTenantList();

  const [disableConfirm, setDisableConfirm] = useState(true);

  const confirmCreateEidtModal = useCallback(async () => {
    const res = await onCreateEditTenant();
    if (res?.status === 'success') {
      Message.success('成功');
    } else {
      const title: string = res?.title ?? '';
      Message.error((responseMap?.[title] as string) ?? defaultResponse);
    }
  }, [onCreateEditTenant]);

  const confirmStatusModal = useCallback(async () => {
    const res = await onConfirmStatusModal();
    if (res?.status === 'success') {
      Message.success('成功');
    } else {
      const title: string = res?.title ?? '';
      Message.error((responseMap?.[title] as string) ?? defaultResponse);
    }
  }, [onConfirmStatusModal]);

  const confirmDeleteModal = useCallback(async () => {
    const res = await onConfirmDeleteModal();
    if (res?.status === 'success') {
      Message.success('成功');
    } else {
      const title: string = res?.title ?? '';
      Message.error((responseMap?.[title] as string) ?? defaultResponse);
    }
  }, [onConfirmDeleteModal]);

  const filtersData: FilterType[][] = useMemo(
    () => [
      [
        {
          label: '帳號',
          fn: SearchInput,
          registerName: 'account',
          props: {
            width: 160,
            clearable: true,
            placeholder: '請輸入',
            name: 'account',
            register: filterMethods.register,
            setValue: filterMethods.setValue,
          },
        },
        {
          label: '工號',
          fn: SearchInput,
          registerName: 'worker_number',
          props: {
            width: 160,
            clearable: true,
            placeholder: '請輸入',
            name: 'worker_number',
            register: filterMethods.register,
            setValue: filterMethods.setValue,
          },
        },
        {
          label: '姓名',
          fn: SearchInput,
          registerName: 'name',
          props: {
            width: 160,
            clearable: true,
            placeholder: '請輸入',
            name: 'name',
            register: filterMethods.register,
            setValue: filterMethods.setValue,
          },
        },
      ],
      [
        {
          label: '模組 / 角色',
          fn: TreeSelectField,
          registerName: 'policies',
          props: {
            width: 750,
            multiple: true,
            treeData: tenantsFilterData ?? [],
            disabled: false,
            registerName: 'policies',
            methods: filterMethods,
            placeholder: '請選擇',
            // 下面如果是true勾選children會把parent disabled，並且是 SHOW_CHILD
            treeDataShouldNotDisabled: true,
          },
        },
      ],
    ],
    [filterMethods, tenantsFilterData]
  );

  const columns: TableColumn<any>[] = useMemo(
    () => [
      { title: '帳號', dataIndex: 'account', width: 120 },
      { title: '工號', dataIndex: 'worker_number', width: 120 },
      { title: '姓名', dataIndex: 'name', width: 100 },
      { title: '部門', dataIndex: 'unit_name', width: 100 },
      { title: '職稱', dataIndex: 'position_name', width: 80 },
      {
        title: '最後編輯時間',
        width: 160,
        render(source: any) {
          const chineseDate = formatChineseDate(source.updated_at);
          return (
            <TableCell
              forceShownTooltipWhenHovered={true}
              tooltipTitle={chineseDate}
              style={{ width: '100%', padding: 0 }}
            >
              <Typography
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {chineseDate}
              </Typography>
            </TableCell>
          );
        },
      },
      {
        title: '模組',
        width: 100,
        render(source: any) {
          const roles: Array<{ tenant_id: number }> = source.roles;
          const tenantSet = new Set(roles.map((role) => role.tenant_id));
          const arr: string[] = [];
          tenantSet.forEach((tenant) => {
            arr.push(tenantsObj[tenant]);
          });
          const displayTenant = arr.join(', ');
          return (
            <TableCell
              forceShownTooltipWhenHovered={true}
              tooltipTitle={displayTenant}
              style={{ width: '100%', padding: 0 }}
            >
              <Typography
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {displayTenant}
              </Typography>
            </TableCell>
          );
        },
      },
      {
        title: '角色',
        width: 120,
        align: 'center' as const,
        render(source: any) {
          const displayTenantArr: string[] = source.roles.map(
            (role: { id: string; tenant_id: number; name: string }) =>
              `${tenantsObj[role.tenant_id]}-${role.name}`
          );

          const displayTenant = displayTenantArr.join(', ');

          return (
            <TableCell
              forceShownTooltipWhenHovered={true}
              tooltipTitle={
                source.roles
                  .map(
                    (role: { id: number; tenant_id: number; name: string }) =>
                      `${tenantsObj[role.tenant_id]}-${role.name}`
                  )
                  .join(', ') ?? ''
              }
            >
              <div>
                {source.roles.length > 0 ? (
                  <Typography
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {displayTenant}
                  </Typography>
                ) : (
                  <div></div>
                )}
              </div>
            </TableCell>
          );
        },
      },
      {
        title: '動作',
        width: 400,
        align: 'center' as const,
        render(source: any) {
          return (
            checkJWTRoles([60]) && (
              <div className={styles.columnsActionWrapper}>
                <Button
                  danger
                  type="button"
                  variant="outlined"
                  onClick={() => {
                    setOpen(true);
                    setData({ account: source?.account, name: source?.name });
                  }}
                >
                  重設密碼
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => onOpenCreateEditModal(source, '編輯使用者')}
                >
                  編輯
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  danger={source.status === 2}
                  onClick={() => onOpenWarningModal(source)}
                  disabled={source?.id === myAccountId}
                >
                  {source?.status === 2 ? '停用' : '啟用'}
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  danger
                  onClick={() => onOpenWarningModal(source, true)}
                  disabled={source?.id === myAccountId}
                >
                  刪除
                </Button>
              </div>
            )
          );
        },
      },
    ],
    [onOpenWarningModal, tenantsObj]
  );

  const worker_number = methods.getValues('worker_number');
  const worker_name = methods.getValues('name');
  const worker_account = methods.getValues('account');

  const createEditModalFields = useMemo(
    () => [
      {
        label: '模組 / 角色',
        required: true,
        fn: EnhanceTreeSelect,
        componentsProps: {
          width: 224,
          multiple: true,
          treeData:
            tenantsFilterData?.map((tenant) => ({
              ...tenant,
              disabled: true,
            })) ?? [],
          disabled: false,
          registerName: 'module',
          methods,
          placeholder: '請選擇',
        },
      },
      // {
      //   label: '工號',
      //   fn: EnhanceAutoComplete,
      //   componentsProps: {
      //     registerName: 'worker_number',
      //     mode: 'single',
      //     options: formatMembers ?? [],
      //     disabled: modalSource !== null,
      //     clearable: true,
      //     placeholder: '請輸入或選擇',
      //     defaultValue: modalSource?.worker_number
      //       ? {
      //           id: modalSource?.worker_number,
      //           name: modalSource?.worker_number,
      //         }
      //       : null,
      //   },
      // },
      {
        label: '帳號',
        required: true,
        fn: EnhanceModalSearchInput,
        componentsProps: {
          registerName: 'account',
          width: 224,
          clearable: true,
          placeholder: '請輸入',
          methods,
          register: methods.register,
          setValue: methods.setValue,
          name: 'account',
          defaultValue: modalSource?.account ?? worker_account ?? '',
        },
      },
      {
        label: '姓名',
        required: false,
        fn: EnhanceModalSearchInput,
        componentsProps: {
          registerName: 'name',
          width: 224,
          clearable: true,
          placeholder: '請輸入',
          register: methods.register,
          setValue: methods.setValue,
          name: 'name',
          defaultValue: modalSource?.name ?? worker_name ?? '',
        },
      },
    ],
    [
      methods,
      modalSource,
      tenantsFilterData,
      formatMembers,
      worker_number,
      worker_account,
      worker_name,
    ]
  );

  useEffect(() => {
    if (modalSource) return;
    const new_worker_name =
      worker_number?.name?.split(' ')[1]?.split('（')?.[0] ?? '';
    methods.setValue('name', new_worker_name);
    methods.setValue('account', worker_number?.id ?? '');
  }, [worker_number, methods.setValue, modalSource]);

  const authListLayoutProps: AuthListLayoutProps = useMemo(
    () => ({
      title: '使用者清單',
      titleButton: (
        <Button
          type="button"
          variant="contained"
          disabled={!checkJWTRoles([60])}
          onClick={() => onOpenCreateEditModal(null, '新增使用者')}
        >
          新增使用者
        </Button>
      ),
      filtersData,
      columns,
      dataSource,
      tableTotal: 0,
      tableCurrent: currentPage,
      onChangeCurrentPage,
      tablePageSize: pageSize,
      onChangePageSize,
      methods: filterMethods,
      onSearch: onSearchTenantUsers,
      noPagination: true,
    }),
    [
      filtersData,
      columns,
      dataSource,
      currentPage,
      onChangeCurrentPage,
      pageSize,
      onChangePageSize,
      onSearchTenantUsers,
      filterMethods,
    ]
  );

  useEffect(() => {
    onSearchTenantUsers();
  }, []);

  useEffect(() => {
    methods.watch();
    const requiredFields = createEditModalFields.filter(
      (field) => field.required
    );

    const disabled = requiredFields.some((field) => {
      const registerName: string = field.componentsProps.registerName ?? '';
      const value = methods.getValues(registerName);

      if (typeof value === 'string') {
        return value.trim() === '' || value === 'undefined';
      }
      if (Array.isArray(value))
        return value.length === 0 || value === undefined;
      return value == undefined || value == null;
    });

    setDisableConfirm(disabled);
  }, [methods.watch(), createEditModalFields, setDisableConfirm]);

  return (
    <div>
      <AuthListLayout {...authListLayoutProps} />
      <WarningModal
        title={
          modalSource?.isDelete
            ? '確定要刪除此使用者嗎？'
            : `確定要${modalSource?.status === 2 ? '停用' : '啟用'}此使用者嗎？`
        }
        source={modalSource}
        openModal={openModal}
        danger={modalSource?.status === 2 || modalSource?.isDelete}
        onCloseModal={onCloseModal}
        onConfirmModal={
          modalSource?.isDelete ? confirmDeleteModal : confirmStatusModal
        }
      />
      <CreateEditModal
        title={modalTitle}
        openModal={openModal}
        onCloseModal={onCloseModal}
        methods={methods}
        fields={createEditModalFields}
        source={modalSource}
        onConfirm={confirmCreateEidtModal}
        confirmButtonDisalbed={disableConfirm}
      />
      <ResetPasswordModal
        open={open}
        data={data}
        onClose={() => {
          setOpen(false);
          setData(undefined);
        }}
      />
    </div>
  );
}
