import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Typography,
} from '@mezzanine-ui/react';
import {
  FormFieldsWrapper,
  TextAreaField,
} from '@mezzanine-ui/react-hook-form';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styles from './DetailModal.module.scss';

type DetailModalProps = {
  name: string;
  bonusConditions: string;
  onClose: () => void;
};

export function DetailModal({
  name,
  bonusConditions,
  onClose,
}: DetailModalProps) {
  const methods = useForm();

  useEffect(() => {
    if (!bonusConditions) return;
    methods.setValue('textarea', bonusConditions);
  }, [bonusConditions, methods]);

  return (
    <Modal onClose={onClose} open={true} style={{ width: 864 }}>
      <FormFieldsWrapper methods={methods}>
        <ModalHeader>查看篩選條件</ModalHeader>
        <ModalBody>
          <div className={styles.body}>
            <Typography variant="h6" color="secondary">
              報表名稱
            </Typography>
            <Typography variant="h3">{name}</Typography>
            <div className={styles.border}></div>
            <TextAreaField
              registerName="textarea"
              size="large"
              width={744}
              label="篩選條件"
              readOnly
            />
          </div>
        </ModalBody>
        <ModalFooter className={styles.footer}>
          <Button
            type="button"
            onClick={onClose}
            size="medium"
            style={{ width: 132 }}
            variant="contained"
          >
            確定
          </Button>
        </ModalFooter>
      </FormFieldsWrapper>
    </Modal>
  );
}
