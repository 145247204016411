import { ReactNode } from 'react';
export function A4Wrapper({
  children,
  landscape = false,
}: {
  children: ReactNode;
  landscape?: boolean;
}) {
  const landscapeStyle = {
    width: '29.7cm',
    maxHeight: '21cm',
  };

  const portraitStyle = {
    width: '21cm',
    minHeight: '29.7cm',
  };

  /** 
   * 盡量不要移除 className="preview"
   * 在 call useReactToPrint 為了調整樣式會用到
   * pageStyle: `
      @media print {
        .page-break {
          display: none !important;
        }
        .preview {
          border: none !important;
        }
      }
    `,
   */

  return (
    <div
      style={{
        ...(landscape ? landscapeStyle : portraitStyle),
        padding: '5mm',
        border: '1px #D3D3D3 solid',
      }}
      className="preview"
    >
      {children}
    </div>
  );
}
