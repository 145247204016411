import { useCallback, useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const key = 'app-layout-side-bar';

const setStorage = (value: boolean) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};

const getFromStorage = () => {
  const value =
    typeof window !== 'undefined'
      ? window.localStorage.getItem(key)
      : undefined;

  return value ? (JSON.parse(value) as boolean) : false;
};

export const disabledSideBarRoutes = [
  '/auth/login',
  '/auth/reset-password',
  '/pdfPrint',
] as const;

export function useSideBarHidden() {
  const { pathname } = useLocation();

  const disableSideBar = disabledSideBarRoutes.some((route) => {
    return pathname.includes(route);
  });

  const [hide, setHide] = useState(
    disableSideBar ? true : getFromStorage() || false
  );

  const on = useCallback(() => {
    setStorage(true);
    setHide((prev) => !prev);
  }, []);

  const off = useCallback(() => {
    setStorage(false);
    setHide((prev) => !prev);
  }, []);

  const toggle = useCallback(() => {
    setHide((prev) => {
      if (disableSideBar) return true;

      setStorage(!prev);

      return getFromStorage();
    });
  }, [disableSideBar]);

  useEffect(() => {
    toggle();
  }, [toggle]);

  const controller = useMemo(
    () => ({
      on,
      off,
      toggle,
    }),
    [off, on, toggle]
  );

  return [hide, controller, disableSideBar] as const;
}
