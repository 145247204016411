import { useEditDrawingForm } from "../EditDrawingForm/EditDrawingForm";
import { createFCCDrawing, createTEMDrawing, useGetPendingDrawingById } from "@solar/data";
import { Button, Descriptions, Radio, RadioChangeEvent, Typography, message } from "antd";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TEMDrawingEditForm } from "../DrawingForm/TEMDrawingEditForm";
import { FCCDrawingEditForm } from "../DrawingForm/FCCDrawingEditForm";
import styles from './create-drawing-page.module.scss';

export function CreateDrawingPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { form, uploading } = useEditDrawingForm();
  const id = searchParams.get('id');
  const bu = searchParams.get('bu');

  const { pendingDrawing } = useGetPendingDrawingById({ id });

  useEffect(() => {
    if (!bu && id) {
      setSearchParams({ id, bu: 'TEM' });
    }
  }, [bu, id, setSearchParams]);

  const handleBUChange = (e: RadioChangeEvent) => {
    if (id) {
      setSearchParams({ id, bu: e.target.value });
      form.resetFields();
    }
  }

  return (
    <div className={styles.page_content}>
      <Descriptions
        bordered
        title={<Typography.Title level={2}>新增圖號</Typography.Title>}
        column={3}
        labelStyle={{ width: 140 }}
        extra={(
          <div style={{ display: 'flex', gap: 'var(--mzn-spacing-4)'}}>
            <Button onClick={() => navigate('/drawings/pending')}>
              返回列表
            </Button>
            <Button loading={uploading} type="primary" htmlType="submit" form={id ?? undefined}>
              建立
            </Button>
          </div>
        )}>
        <Descriptions.Item label="BU" span={3}>
          <Radio.Group value={bu} onChange={handleBUChange} options={['TEM', 'FCC']} />
        </Descriptions.Item>
      </Descriptions>
      {bu === 'TEM' && (
        <TEMDrawingEditForm
          id={id ?? undefined}
          form={form}
          pendingDrawing={pendingDrawing}
          onFinish={async (payload) => {
            try {
              const response = await createTEMDrawing({ drawings: [payload] });
              const newDrawingCode = response?.data?.[0]?.newDrawingCode;
              if (newDrawingCode) {
                await navigate(`/drawings/tem/${newDrawingCode}`);
                message.success('建立成功');
              } else throw new Error();
            } catch (error) {
              message.error('建立失敗');
            }
          }}
        />
      )}
      {bu === 'FCC' && (
        <FCCDrawingEditForm
          id={id ?? undefined}
          form={form}
          pendingDrawing={pendingDrawing}
          onFinish={async (payload) => {
            try {
              const response = await createFCCDrawing({ drawings: [payload] });
              const newDrawingCode = response?.data?.[0]?.newDrawingCode;
              if (newDrawingCode) {
                await navigate(`/drawings/fcc/${newDrawingCode}`);
                message.success('建立成功');
              } else throw new Error();
            } catch (error) {
              message.error('建立失敗');
            }
          }}
        />
      )}
    </div>
  )
}