import { BasicInfoPanel } from './BasicInfoPanel';
import { DispatchOrderPanel } from './DispatchOrderPanel';

export const tabsOptions = [
  {
    id: 'basic-info',
    name: '基本資訊',
    panel: BasicInfoPanel,
  },
  {
    id: 'dispatch-material',
    name: '發料單',
    panel: DispatchOrderPanel,
  },
];

export const tabIds = tabsOptions.map((tab) => tab.id);
export const filteredOrderTypeCodes = [
  '1B',
  '1N',
  '2B',
  '2N',
  '3B',
  '3N',
  'ZZ',
];
