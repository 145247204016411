import { ChevronLeftIcon } from '@mezzanine-ui/icons';
import { Button, Icon, MenuDivider, Typography } from '@mezzanine-ui/react';
import { Col, Row, Space } from 'antd';
import { CSSProperties, ReactNode, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { usePageModalsController } from './use-page-modals';
import { PageContext } from './use-page-context';

export interface PageWrapperProps {
  title?: ReactNode;
  subtitle?: ReactNode;
  extraContent?: ReactNode;
  children: ReactNode;
  backTo?: string;
  contextValue?: any;
  disabledDivider?: boolean;
}

export function PageWrapper(props: PageWrapperProps) {
  const {
    title,
    subtitle,
    extraContent,
    children,
    backTo,
    contextValue,
    disabledDivider,
  } = props;

  const modalsController = usePageModalsController();

  return (
    <PageContext.Provider value={{ value: contextValue, modalsController }}>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Row justify="space-between" align="middle">
          <Col>
            <Space>
              <Space direction="vertical">
                {title && <Typography variant="h1">{title}</Typography>}
                {subtitle && (
                  <Typography variant="h5" color="secondary">
                    {subtitle}
                  </Typography>
                )}
              </Space>
            </Space>
          </Col>
          <Col>
            <Space>
              {extraContent}
              {backTo && (
                <Link to={backTo}>
                  <Button
                    type="button"
                    prefix={
                      <Icon
                        style={{ color: '#8F8F8F' } as CSSProperties}
                        icon={ChevronLeftIcon}
                      />
                    }
                    variant="text"
                  >
                    <Typography color="text-secondary" variant="button2">
                      返回上一頁
                    </Typography>
                  </Button>
                </Link>
              )}
            </Space>
          </Col>
        </Row>
        {!disabledDivider && <MenuDivider />}
        {children}
      </Space>
    </PageContext.Provider>
  );
}
