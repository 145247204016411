import useSWR from 'swr';

export function useWorkCenters() {
  const { data, error } = useSWR<
    {
      id: string;
      name: string;
      bigClassCode: string;
      bigClassName: string;
      smallClassCode: string;
      smallClassName: string;
    }[]
  >('/utils/work-centers');

  return {
    workCenters: data ?? [],
    isLoading: !error && !data,
  };
}
