import { useCallback, useMemo } from 'react';
import { Button } from '@mezzanine-ui/react';
import { useForm } from 'react-hook-form';
import { NextPagination, getNextParams } from '@solar/data';
import { useLocation, useNavigate } from 'react-router-dom';
import { TableColumn } from '@mezzanine-ui/core/table';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import { MznPaginationTable, FilterScaffold } from '@solar/templates';
import sharedStyles from '../shared-styles/index.module.scss';
import { PageLayout } from '../PageLayout/PageLayout';
import {
  CustomerOrderTableProps,
  PurchaseOrderTableProps,
} from '../IncomingInspectOperationPage/typings';
import { Filter } from './Filter';
import { pageOptions } from './page-options';

export function OrderPanel() {
  const filterMethods = useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const currentTab = pageOptions.find(
    (tab) => tab.id === location.pathname.split('/').at(-1)
  );

  // table data
  const { orders, pageInfo, mutateParams } = currentTab?.fetchOrders?.() ?? {};

  const refetchInventoryByPurchaseOrders = useCallback(
    (nextPagination: NextPagination) => {
      const filterState = filterMethods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);

      // TODO: 「類型」尚未被完整定義; api 實作完記得補上
      mutateParams?.({
        offset,
        limit,
        ...(filterState?.searchTerm && { searchTerm: filterState?.searchTerm }),
        ...(filterState?.dateRange && { dateFrom: filterState?.dateRange[0] }),
        ...(filterState?.dateRange && { dateTo: filterState?.dateRange[1] }),
      });
    },
    [filterMethods, mutateParams, pageInfo]
  );

  const purchaseOrderColumns: TableColumn<PurchaseOrderTableProps>[] = useMemo(
    () => [
      { title: '採購單號', dataIndex: 'id' },
      { title: '供應商名稱', dataIndex: 'supplierName' },
      { title: '供應商代碼', dataIndex: 'supplierId' },
      {
        title: '類型',
        render: (source) => {
          return <div>庫存</div>;
        },
      },
      { title: '採購人員', dataIndex: 'staffName' },
      {
        title: '操作',
        width: 120,
        align: 'center' as const,
        render(source) {
          return (
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                navigate(`${source.id}`);
              }}
            >
              VIEW
            </Button>
          );
        },
      },
    ],
    [navigate]
  );
  const customerOrderColumns: TableColumn<CustomerOrderTableProps>[] = useMemo(
    () => [
      { title: '採購單號', dataIndex: 'id' },
      { title: '供應商名稱', dataIndex: 'customerName' },
      { title: '供應商代碼', dataIndex: 'customerId' },
      {
        title: '類型',
        render: (source) => {
          return <div>庫存</div>;
        },
      },
      { title: '業務人員', dataIndex: 'staffName' },
      {
        title: '操作',
        width: 120,
        align: 'center' as const,
        render(source) {
          return (
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                navigate(`${source.id}`);
              }}
            >
              VIEW
            </Button>
          );
        },
      },
    ],
    [navigate]
  );

  const columnOptions = useMemo(
    () => ({
      [pageOptions[0].id]: purchaseOrderColumns,
      [pageOptions[1].id]: purchaseOrderColumns,
      [pageOptions[2].id]: purchaseOrderColumns,
      [pageOptions[3].id]: customerOrderColumns,
    }),
    []
  );

  return (
    <PageLayout>
      <FormFieldsWrapper methods={filterMethods}>
        <FilterScaffold mutateParams={refetchInventoryByPurchaseOrders}>
          <Filter />
        </FilterScaffold>
      </FormFieldsWrapper>
      {currentTab && (
        <MznPaginationTable
          columns={columnOptions[currentTab?.id]}
          dataSource={orders ?? []}
          fetchData={refetchInventoryByPurchaseOrders}
          pageInfo={pageInfo}
          bodyClassName={sharedStyles.table}
        />
      )}
    </PageLayout>
  );
}
