import { request, API_NAMESPACE } from '../../request';
import * as yup from 'yup';
import useSWR from 'swr';

const materialCompositionQuerySchema = yup.object().shape({
  materialId: yup.string().required(' '),
});

export type MaterialCompositionQuery = yup.InferType<
  typeof materialCompositionQuerySchema
>;

export type MaterialCompositionData = {
  components: {
    id: string;
  }[];
};

export function useMaterialCompositionGet(query?: MaterialCompositionQuery) {
  const {
    data: swrData,
    mutate,
    isValidating,
  } = useSWR<MaterialCompositionData>(validateSWRKey(query), request, {
    revalidateOnFocus: false,
  });

  const { components } = swrData ?? {};

  return {
    materialComposition: components ?? [],
    isLoading: isValidating,
    refetchMaterialComposition: mutate,
  };
}

function validateSWRKey(query?: MaterialCompositionQuery) {
  try {
    const safeQuery = materialCompositionQuerySchema.validateSync(query);

    return [
      '/customers/customer-trade-condition/components',
      { namespace: API_NAMESPACE.HR, params: safeQuery },
    ];
  } catch (error) {
    return false;
  }
}
