import { useFormContext, useWatch } from 'react-hook-form';
import { BindingFormValues, VirtualInType } from '../typing';
import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';
import {
  useGetDeliveryOrders,
  useInventoryByPurchaseOrders,
} from '@solar/data';
import { useCallback } from 'react';
import { SelectValue } from '@mezzanine-ui/react';

function PurchaseOrderSelector({
  registerName,
  onOrderIdChange,
}: {
  registerName: string;
  onOrderIdChange: (selectedItem: SelectValue) => void;
}) {
  const { orders, refetchInventoryByPurchaseOrders } =
    useInventoryByPurchaseOrders();

  return (
    <AutoCompleteField
      required
      registerName={registerName}
      options={orders?.map((order: any) => ({
        id: order.id,
        name: order.id,
      }))}
      onSearch={(searchTerm) => {
        refetchInventoryByPurchaseOrders({ searchTerm });
      }}
      onChange={onOrderIdChange}
    />
  );
}

function DeliveryOrderSelector({
  registerName,
  onOrderIdChange,
}: {
  registerName: string;
  onOrderIdChange: (selectedItem: SelectValue) => void;
}) {
  const { deliveryOrders, refetchGetDeliveryOrders } = useGetDeliveryOrders({
    processTypes: ['01'],
    types: ['ZLR', 'ZLR2', 'ZLR3'],
  });

  return (
    <AutoCompleteField
      required
      registerName={registerName}
      options={
        deliveryOrders?.map((order) => ({
          id: order.id,
          name: order.id,
        })) ?? []
      }
      onSearch={(searchTerm) => {
        refetchGetDeliveryOrders({
          processTypes: ['01'],
          types: ['ZLR', 'ZLR2', 'ZLR3'],
          searchTerm,
        });
      }}
      onChange={onOrderIdChange}
    />
  );
}

export function VirtualInOrderIdSelector({
  virtualOutDeliveryOrderItemId,
}: {
  virtualOutDeliveryOrderItemId: string;
}) {
  const { control, getValues, setValue } = useFormContext<BindingFormValues>();

  const watchedRelatedCategory = useWatch({
    control,
    name: `${virtualOutDeliveryOrderItemId}.relatedCategory`,
  });

  const registerName =
    `${virtualOutDeliveryOrderItemId}.relatedOrderId` as const;

  const onOrderIdChange = useCallback(
    (selectedItem: SelectValue) => {
      const values = getValues();

      Object.entries(values).forEach(([key]) => {
        setValue(`${key}.relatedOrderId`, selectedItem);
        setValue(`${key}.relatedOrderItemId`, null);
        setValue(`${key}.relatedOrderMaterialId`, null);
      });
    },
    [getValues, setValue]
  );

  if (watchedRelatedCategory?.id === VirtualInType.DELIVERY_ORDER)
    return (
      <DeliveryOrderSelector
        registerName={registerName}
        onOrderIdChange={onOrderIdChange}
      />
    );

  if (watchedRelatedCategory?.id === VirtualInType.INVENTORY_PURCHASE_ORDER)
    return (
      <PurchaseOrderSelector
        registerName={registerName}
        onOrderIdChange={onOrderIdChange}
      />
    );

  return null;
}
