import { getRoleGroup } from './shared';

export const PPM_SIZE_L2 = {
  K003: {
    name: '3000M',
  },
  K001: {
    name: '1000M',
  },
  M500: {
    name: '500M',
  },
  M300: {
    name: '300M',
  },
  M100: {
    name: '100M',
  },
  M050: {
    name: '50M',
  },
  M020: {
    name: '20M',
  },
  M012: {
    name: '12M',
  },
  M010: {
    name: '10M',
  },
  '5000': {
    name: '5M',
  },
  '2000': {
    name: '2M',
  },
  '1600': {
    name: '1.6M',
  },
  '1500': {
    name: '1.5M',
  },
  '1100': {
    name: '1.1M',
  },
  '1000': {
    name: '1M',
  },
  '0250': {
    name: '0.25M',
  },
  '0300': {
    name: '0.3M',
  },
  '0100': {
    name: '100mm',
  },
  '076B': {
    name: '76.2mm',
  },
  '004E': {
    name: '4.5mm',
  },
};

export const PPM_SIZE = {
  '0100': {
    name: '10 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0120': {
    name: '12 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0150': {
    name: '15 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0180': {
    name: '18 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0200': {
    name: '20 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0230': {
    name: '23 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0250': {
    name: '25 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0251': {
    name: '25.1 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0280': {
    name: '28 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0300': {
    name: '30 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0350': {
    name: '35 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0330': {
    name: '33 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0337': {
    name: '33.7 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0381': {
    name: '38.1 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0400': {
    name: '40 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0450': {
    name: '45 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0500': {
    name: '50 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0580': {
    name: '58 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0593': {
    name: '59.3 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0625': {
    name: '62.5 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0700': {
    name: '70 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0762': {
    name: '76.2 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0889': {
    name: '88.9 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0950': {
    name: '95 um',
    nextLevel: PPM_SIZE_L2,
  },
  '0980': {
    name: '98 um',
    nextLevel: PPM_SIZE_L2,
  },
  '1000': {
    name: '100 um',
    nextLevel: PPM_SIZE_L2,
  },
  '1500': {
    name: '150 um',
    nextLevel: PPM_SIZE_L2,
  },
  '2034': {
    name: '203.4 um',
    nextLevel: PPM_SIZE_L2,
  },
  '2300': {
    name: '230 um',
    nextLevel: PPM_SIZE_L2,
  },
  '4000': {
    name: '400 um',
    nextLevel: PPM_SIZE_L2,
  },
  '5000': {
    name: '500 um',
    nextLevel: PPM_SIZE_L2,
  },
  '6000': {
    name: '600 um',
    nextLevel: PPM_SIZE_L2,
  },
  '7000': {
    name: '700 um',
    nextLevel: PPM_SIZE_L2,
  },
  '8000': {
    name: '800 um',
    nextLevel: PPM_SIZE_L2,
  },
  '8500': {
    name: '850 um',
    nextLevel: PPM_SIZE_L2,
  },
  '006Z': {
    name: '6 mm',
    nextLevel: PPM_SIZE_L2,
  },
  '008Z': {
    name: '8 mm',
    nextLevel: PPM_SIZE_L2,
  },
  '010Z': {
    name: '10 mm',
    nextLevel: PPM_SIZE_L2,
  },
};

const PPM_SECOND_SPEC = {
  MB: {
    name: 'EL>10%',
  },
  MC: {
    name: 'EL>20%',
  },
  MD: {
    name: 'EL16-20',
  },
  ME: {
    name: 'EL8-12',
  },
  ML: {
    name: 'EL<10%',
  },
  A1: {
    name: '探針規格',
  },
  F0: {
    name: '製成熱處理',
  },
  F1: {
    name: '成品退火規格1',
  },
  F2: {
    name: '成品退火規格2',
  },
  F3: {
    name: '成品退火規格3',
  },
  F4: {
    name: '成品退火規格4',
  },
  F5: {
    name: '成品退火規格5',
  },
  M0: {
    name: '製成矯直',
  },
  M1: {
    name: '成品矯直規格1',
  },
  M2: {
    name: '成品矯直規格2',
  },
  M3: {
    name: '成品矯直規格3',
  },
  M4: {
    name: '成品矯直規格4',
  },
  M5: {
    name: '成品矯直規格5',
  },
  AN: {
    name: '中間熱處理',
  },
  '01': {
    name: '未分類',
  },
};

const PPM_FIRST_SPEC = {
  HB: {
    name: '高藍',
    nextLevel: PPM_SECOND_SPEC,
  },
  HR: {
    name: '高紅',
    nextLevel: PPM_SECOND_SPEC,
  },
  HS: {
    name: '高銀',
    nextLevel: PPM_SECOND_SPEC,
  },
  HK: {
    name: '高黑',
    nextLevel: PPM_SECOND_SPEC,
  },
  HU: {
    name: '高棕',
    nextLevel: PPM_SECOND_SPEC,
  },
  TY: {
    name: '高黃',
    nextLevel: PPM_SECOND_SPEC,
  },
  LB: {
    name: '低藍',
    nextLevel: PPM_SECOND_SPEC,
  },
  LU: {
    name: '低棕',
    nextLevel: PPM_SECOND_SPEC,
  },
  LG: {
    name: '低綠',
    nextLevel: PPM_SECOND_SPEC,
  },
  LO: {
    name: '低橘',
    nextLevel: PPM_SECOND_SPEC,
  },
  LK: {
    name: '黑鐵軸',
    nextLevel: PPM_SECOND_SPEC,
  },
  HG: {
    name: '灰色軸',
    nextLevel: PPM_SECOND_SPEC,
  },
  FF: {
    name: '未分類',
    nextLevel: PPM_SECOND_SPEC,
  },
};

const BASIC_NO_SPEC = {
  FF: {
    name: '未分類',
    nextLevel: {
      '01': {
        name: '未分類',
      },
    },
  },
};

const HD_MEDIUM_GROUP = {
  A: {
    name: '釹鐵硼(N32EH)',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(BASIC_NO_SPEC),
      },
    },
  },
  B: {
    name: '釹鐵硼(N40H)',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(BASIC_NO_SPEC),
      },
    },
  },
  C: {
    name: '釹鐵硼(N40M)',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(BASIC_NO_SPEC),
      },
    },
  },
  D: {
    name: '釹鐵硼(N48M)',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(BASIC_NO_SPEC),
      },
    },
  },
  1: {
    name: '未分類',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(BASIC_NO_SPEC),
      },
    },
  },
};

const STICK_SECOND_SPEC = {
  C1: {
    name: '全回收',
  },
  '01': {
    name: '未分類',
  },
};

const STICK_FIRST_SPEC = {
  LC: {
    name: '低碳版',
    nextLevel: STICK_SECOND_SPEC,
  },
  MC: {
    name: '微碳版',
    nextLevel: STICK_SECOND_SPEC,
  },
  NC: {
    name: '無碳版',
    nextLevel: STICK_SECOND_SPEC,
  },
  AG: {
    name: '5N金不純物飛利浦規範',
    nextLevel: STICK_SECOND_SPEC,
  },
  VP: {
    name: '真空包裝',
    nextLevel: STICK_SECOND_SPEC,
  },
  FF: {
    name: '未分類',
    nextLevel: STICK_SECOND_SPEC,
  },
};

const NO_MEDIUM_GROUP = {
  1: {
    name: '未分類',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(BASIC_NO_SPEC),
      },
    },
  },
};

const SPONGE_GOLD_FIRST_SPEC = {
  IN: {
    name: '回收產出高純銦',
    nextLevel: STICK_SECOND_SPEC,
  },
  LC: {
    name: '低碳版',
    nextLevel: STICK_SECOND_SPEC,
  },
  MC: {
    name: '微碳版',
    nextLevel: STICK_SECOND_SPEC,
  },
  NC: {
    name: '無碳版',
    nextLevel: STICK_SECOND_SPEC,
  },
  AG: {
    name: '5N金不純物飛利浦規範',
    nextLevel: STICK_SECOND_SPEC,
  },
  FF: {
    name: '未分類',
    nextLevel: STICK_SECOND_SPEC,
  },
};

const SPONGE_GOLD_MEDIUM_GROUP = {
  R: {
    name: '待取樣投GCR精煉',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(SPONGE_GOLD_FIRST_SPEC),
      },
    },
  },
  G: {
    name: 'GCR產出待重熔取樣',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(SPONGE_GOLD_FIRST_SPEC),
      },
    },
  },
  5: {
    name: 'GCR 精煉課-5N海綿金',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(SPONGE_GOLD_FIRST_SPEC),
      },
    },
  },
  4: {
    name: 'GCR 精煉課-4N8海綿金',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(SPONGE_GOLD_FIRST_SPEC),
      },
    },
  },
  1: {
    name: '未分類',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(SPONGE_GOLD_FIRST_SPEC),
      },
    },
  },
};

const POWDER_SECOND_SPEC = {
  U1: {
    name: 'Ru粉不純物大於V2規範',
  },
  U2: {
    name: 'RU粉-O：2000ppm<O含量 ≦2100ppm',
  },
  U3: {
    name: 'RU粉-NACl：夾渣Na or Cl元素，Cl含量 > 10ppm，Na>3ppm',
  },
  U4: {
    name: 'RU粉無還原前處理>Ru有還原/無還原',
  },
  R1: {
    name: 'Cr粉O<700ppm',
  },
  R2: {
    name: 'Cr粉Zr<300ppm Fe<400ppm O<1800ppm N<500ppm',
  },
  O1: {
    name: 'Co粉O<4500ppm',
  },
  O2: {
    name: 'Co粉Cl<10ppm',
  },
  O3: {
    name: 'Co粉Ni<50ppm',
  },
  T1: {
    name: 'Pt粉穩懋規範',
  },
  T2: {
    name: 'Pt粉Osram規範',
  },
  T3: {
    name: 'Pt粉細粉特採',
  },
  T4: {
    name: 'Pt粉4N7特採',
  },
  PY: {
    name: 'CM使用ZrO研磨球',
  },
  PZ: {
    name: 'CM使用ST6研磨球',
  },
  C1: {
    name: '全回收',
  },
  FR: {
    name: 'FCC研發粉',
  },
  BA: {
    name: 'binder_1%',
  },
  BB: {
    name: 'binder_1.5%',
  },
  BC: {
    name: 'binder_2%',
  },
  BD: {
    name: 'binder_2.5%',
  },
  BE: {
    name: 'binder_3%',
  },
  '01': {
    name: '未分類',
  },
};

const POWDER_FIRST_SPEC = {
  GN: {
    name: '造粒粉',
    nextLevel: POWDER_SECOND_SPEC,
  },
  GL: {
    name: '煆燒粉',
    nextLevel: POWDER_SECOND_SPEC,
  },
  GA: {
    name: 'GA粉',
    nextLevel: POWDER_SECOND_SPEC,
  },
  BG: {
    name: '球磨',
    nextLevel: POWDER_SECOND_SPEC,
  },
  RG: {
    name: '環磨',
    nextLevel: POWDER_SECOND_SPEC,
  },
  PG: {
    name: '行星式研磨',
    nextLevel: POWDER_SECOND_SPEC,
  },
  CM: {
    name: 'CM粉',
    nextLevel: POWDER_SECOND_SPEC,
  },
  OS: {
    name: '外購粉',
    nextLevel: POWDER_SECOND_SPEC,
  },
  FF: {
    name: '未分類',
    nextLevel: POWDER_SECOND_SPEC,
  },
};

const POWDER_MEDIUM_GROUP = {
  N: {
    name: '未測量',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(POWDER_FIRST_SPEC),
      },
    },
  },
  S: {
    name: '過篩粉',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(POWDER_FIRST_SPEC),
      },
    },
  },
  P: {
    name: 'PSD粉',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(POWDER_FIRST_SPEC),
      },
    },
  },
  M: {
    name: '過篩+PSD',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(POWDER_FIRST_SPEC),
      },
    },
  },
};

const GRAIN_MEDIUM_GROUP = {
  G: {
    name: 'Grain',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(STICK_FIRST_SPEC),
      },
    },
  },
};

const BLOCK_CIRCLE_FIRST_SPEC = {
  FF: {
    name: '未分類',
    nextLevel: STICK_SECOND_SPEC,
  },
};

const BLOCK_SMALL_GROUP = {
  S: {
    name: '車屑重熔錠',
    nextLevel: getRoleGroup(BLOCK_CIRCLE_FIRST_SPEC),
  },
  R: {
    name: '回靶重熔錠',
    nextLevel: getRoleGroup(BLOCK_CIRCLE_FIRST_SPEC),
  },
  1: {
    name: '未分類',
    nextLevel: getRoleGroup(BLOCK_CIRCLE_FIRST_SPEC),
  },
};

const BLOCK_NON_CIRCLE_FIRST_SPEC = {
  LC: {
    name: '低碳版',
  },
  MC: {
    name: '微碳版',
  },
  NC: {
    name: '無碳版',
  },
  AG: {
    name: '5N金不純物飛利浦規範',
  },
  A1: {
    name: '商品合金料/401003617',
  },
  FF: {
    name: '未分類',
  },
};

const BLOCK_GCR_SMALL_GROUP = {
  2: {
    name: 'GCR 精煉課-Au,4N;Ag 50-80ppm;其他<20ppm,Au,100at%,金屬,錠',
    nextLevel: getRoleGroup(STICK_SECOND_SPEC),
  },
  3: {
    name: 'GCR 精煉課-Au,≧96%,AuOthers,96/4at%,固體',
    nextLevel: getRoleGroup(STICK_SECOND_SPEC),
  },
  4: {
    name: 'GCR 精煉課-75%<Au<96%,固體 (75~96%可以分批投Au250)',
    nextLevel: getRoleGroup(STICK_SECOND_SPEC),
  },
  1: {
    name: '未分類',
    nextLevel: getRoleGroup(STICK_SECOND_SPEC),
  },
};

const BLOCK_MEDIUM_GROUP = {
  C: {
    name: '圓形',
    nextLevel: BLOCK_SMALL_GROUP,
  },
  G: {
    name: '待GCR精煉',
    nextLevel: BLOCK_GCR_SMALL_GROUP,
  },
  S: {
    name: '水花',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(STICK_SECOND_SPEC),
      },
    },
  },
  L: {
    name: 'LBMA',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(STICK_SECOND_SPEC),
      },
    },
  },
  I: {
    name: '不規則形',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(STICK_SECOND_SPEC),
      },
    },
  },
  1: {
    name: '未分類',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: getRoleGroup(STICK_SECOND_SPEC),
      },
    },
  },
};

export const SOLID_LARGE_GROUP = {
  F: {
    name: '板材',
    nextLevel: {
      M: {
        name: '待加工料',
        nextLevel: {
          1: {
            name: '未分類',
            nextLevel: getRoleGroup(BASIC_NO_SPEC),
          },
        },
      },
      P: {
        name: '製成品',
        nextLevel: {
          C: {
            name: '圓形',
            nextLevel: getRoleGroup(BASIC_NO_SPEC),
          },
          R: {
            name: '矩形',
            nextLevel: getRoleGroup(BASIC_NO_SPEC),
          },
          1: {
            name: '未分類',
            nextLevel: getRoleGroup(BASIC_NO_SPEC),
          },
        },
      },
      F: {
        name: 'Flake',
        nextLevel: {
          1: {
            name: '未分類',
            nextLevel: getRoleGroup(BASIC_NO_SPEC),
          },
        },
      },
    },
  },
  E: {
    name: '磁鐵毛胚',
    nextLevel: HD_MEDIUM_GROUP,
  },
  S: {
    name: '棒材',
    nextLevel: {
      1: {
        name: '未分類',
        nextLevel: {
          1: {
            name: '未分類',
            nextLevel: getRoleGroup(STICK_FIRST_SPEC),
          },
        },
      },
    },
  },
  W: {
    name: '線材',
    nextLevel: {
      P: {
        name: '一般',
        nextLevel: {
          1: {
            name: '未分類',
            nextLevel: getRoleGroup(STICK_FIRST_SPEC),
          },
        },
      },
      G: {
        name: '光大',
        nextLevel: {
          M: {
            name: '待加工料',
            nextLevel: getRoleGroup(PPM_FIRST_SPEC),
          },
          P: {
            name: '製成品',
            nextLevel: getRoleGroup(PPM_FIRST_SPEC),
          },
        },
      },
    },
  },
  T: {
    name: '管材',
    nextLevel: NO_MEDIUM_GROUP,
  },
  N: {
    name: '海綿金',
    nextLevel: SPONGE_GOLD_MEDIUM_GROUP,
  },
  P: {
    name: '粉末',
    nextLevel: POWDER_MEDIUM_GROUP,
  },
  G: {
    name: '粒材',
    nextLevel: GRAIN_MEDIUM_GROUP,
  },
  L: {
    name: '焊料',
    nextLevel: NO_MEDIUM_GROUP,
  },
  B: {
    name: '塊材',
    nextLevel: BLOCK_MEDIUM_GROUP,
  },
};
