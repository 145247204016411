import { TableColumn } from '@mezzanine-ui/core/table';
import { LogisticsDataType } from './RequirementManagement.interface';

export const exportCSVFile = (
  list: LogisticsDataType[] | undefined,
  detailedColumns: TableColumn<LogisticsDataType>[]
) => {
  const title = detailedColumns.map((column) => column.title).join(',') + '\n';
  const content = list
    ?.flatMap((data, index) => {
      if (data.items && data.items.length > 0) {
        return data.items.map((item) => {
          return detailedColumns
            .map((column) => {
              const value = column.dataIndex === 'items'
                ? item[column.dataIndex as keyof typeof item]
                : data[column.dataIndex as keyof LogisticsDataType];
              return column.render ? column.render({ ...data, items: [item] }, index, column) : value;
            })
            .map((value) => (value === null || value === undefined ? '' : `"${value}"`))
            .join(',');
        });
      }
      return detailedColumns
        .map((column) => {
          const value = data[column.dataIndex as keyof LogisticsDataType];
          return column.render ? column.render(data, index, column) : value;
        })
        .map((value) => (value === null || value === undefined ? '' : `"${value}"`))
        .join(',');
    })
    .join('\n') + '\n';
  const csvContent = title + content;

  const blob = new Blob([`\uFEFF${csvContent}`], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute('download', 'Export.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};
