import { PageLayout } from '../Templates/PageLayout/PageLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import SPSearchForm from './components/SPSearchForm';
import SPSearchTable from './components/SPSearchTable';
import {
  SPFormSearch,
  SPSearchPageMode,
  checkJWTRoles,
  convertSPSearchFormToAPI,
  useStrikePriceSearch,
} from '@solar/data';
import { Button } from '@mezzanine-ui/react';
import { useForm } from 'react-hook-form';
import { useRef, useEffect } from 'react';

const StrikePriceSearch = () => {
  const {
    autoCompleteOptionsState,
    searchStrikePrice,
    serachResult,
    pageInfo,
    onPageChange,
    searchLoading,
    reportPriceState,
    deleteStrikePrice,
    backStrikePrice,
  } = useStrikePriceSearch();
  const formRef = useRef<boolean>(true);
  const searchForm = useForm<SPFormSearch>();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  let pageMode = SPSearchPageMode.SEARCH;
  if (pathname.includes('approve')) {
    pageMode = SPSearchPageMode.APPROVE;
  }

  const pageTitle =
    pageMode === SPSearchPageMode.SEARCH
      ? '敲價管理-敲價人員'
      : '敲價管理-敲價審核';

  const goToAddPage = () => {
    navigate('/strike-price/add');
  };

  const onSearchFormSubmit = (formData: SPFormSearch) => {
    const data = convertSPSearchFormToAPI(formData);
    searchStrikePrice(data, { current: 1, pageSize: 10, total: 0 });
  };

  useEffect(() => {
    // 因為 React 18 的 strict mode 會導致 componentDidMount 被呼叫兩次，我做了一個 ref 來控制只能呼叫一次
    if (formRef.current) {
      formRef.current = false;
      searchForm.handleSubmit(onSearchFormSubmit)();
    }
  }, [formRef]);

  return (
    <PageLayout title={pageTitle}>
      <SPSearchForm
        pageMode={pageMode}
        form={searchForm}
        onSubmit={onSearchFormSubmit}
        autoCompleteOptionsState={autoCompleteOptionsState}
      />
      {pageMode === SPSearchPageMode.SEARCH && checkJWTRoles([111]) && (
        <Button onClick={goToAddPage}>新增敲價單</Button>
      )}
      <SPSearchTable
        pageMode={pageMode}
        dataSource={serachResult}
        pageInfo={pageInfo}
        onPageChange={onPageChange}
        searchLoading={searchLoading}
        reportPriceState={reportPriceState || []}
        onDelete={deleteStrikePrice}
        onBack={backStrikePrice}
      />
    </PageLayout>
  );
};

export default StrikePriceSearch;
