import { Button } from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  FormFieldsWrapper,
} from '@mezzanine-ui/react-hook-form';
import { useWorkOrder, useWorkOrders } from '@solar/data';
import { useForm } from 'react-hook-form';

import { Col, Divider, Row } from 'antd';
import { useContext, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { WorkOrderPrintPage } from '../../Transportation/WorkOrderPrint/WorkOrderPrint';
import { A4Wrapper } from './A4Preview';
import { FilterContext } from './FilterContext';
import { WorkOderFormProps } from './types';
import classes from './work-order-panel.module.scss';

export const WorkOrderPanel = () => {
  const filterContext = useContext(FilterContext);
  const { workOrderId: contextWordOrderId, setWorkOrderId } = filterContext;
  const printRef = useRef<HTMLDivElement>(null);
  const { orders, mutateParams } = useWorkOrders({ initiateFetching: true });
  const methods = useForm<WorkOderFormProps>();
  const workOrder = methods.watch('workOrder');
  const orderOptions = orders.map((order) => ({
    id: order.id,
    name: order.id,
  }));

  const { order } = useWorkOrder({ orderId: workOrder?.id });

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    methods.setValue('workOrder', contextWordOrderId);
  }, [contextWordOrderId, methods]);

  return (
    <FormFieldsWrapper methods={methods} className={classes['host']}>
      <Row gutter={[12, 12]} align="bottom">
        <Col>
          <AutoCompleteField
            required
            registerName="workOrder"
            label="工單單號"
            options={orderOptions ?? []}
            onChange={(selectedValue) => {
              setWorkOrderId?.(selectedValue);
            }}
            onSearch={(searchTerm) => mutateParams({ searchTerm })}
          />
        </Col>
        <Col>
          <Button variant="outlined" onClick={handlePrint}>
            列印
          </Button>
        </Col>
      </Row>
      <Divider orientation="left">列印內容</Divider>
      <A4Wrapper>
        {order && <WorkOrderPrintPage ref={printRef} workOrder={order} />}
      </A4Wrapper>
    </FormFieldsWrapper>
  );
};
