import type { ProductionPlanningOrdersData } from '@solar/data';
import { useState } from 'react';
import { Table } from '@mezzanine-ui/react';
import { PageLayout } from '@solar/templates';
import { FilterPanelForm } from './FilterPanelForm';
import {
  useProductionPlanningOrdersGet,
  useProductionPlanningOrdersFilter,
} from '@solar/data';
import { PlannedDeliveryDateInlineForm } from './PlannedDeliveryDateInlineForm';
import { PlannedDeliveryNoteInlineForm } from './PlannedDeliveryNoteInlineForm';
import { CustomerOrderDatePicker } from './FilterPanelForm/CustomerOrderDatePicker';
import { ExpectedDeliveryDatePicker } from './FilterPanelForm/ExpectedDeliveryDatePicker';
import { PlannedDeliveryDatePicker } from './FilterPanelForm/PlannedDeliveryDatePicker';
import { PlannedDeliveryRadioGroup } from './FilterPanelForm/PlannedDeliveryRadioGroup';
import { BUIdAutoComplete } from './FilterPanelForm/BUIdAutoComplete';
import { OrderIdInput } from './FilterPanelForm/OrderIdInput';
import { MaterialIdInput } from './FilterPanelForm/MaterialIdInput';
import { MaterialCategoryAutoComplete } from './FilterPanelForm/MaterialCategoryAutoComplete';

export function DeliveryManagementPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const {
    filterCondition,
    updateFilterCondition,
    bindFormMethodsForInitialization,
  } = useProductionPlanningOrdersFilter();
  const {
    productionPlanningOrdersData,
    productionPlanningOrdersMeta,
    refetchProductionPlanningOrders,
    isLoading,
  } = useProductionPlanningOrdersGet({
    page: currentPage,
    perPage: perPage,
    ...filterCondition,
  });

  const tableColumns = [
    { title: '訂單日期', dataIndex: 'customerOrderDate', width: 200 },
    { title: '請求交貨日', dataIndex: 'expectedDeliveryDate', width: 200 },
    { title: '指定生管', dataIndex: 'assignedOperationName', width: 250 },
    {
      title: '生管回覆交期',
      width: 380,
      render(source: ProductionPlanningOrdersData, index: number) {
        return (
          <PlannedDeliveryDateInlineForm
            source={source}
            refetchProductionPlanningOrders={refetchProductionPlanningOrders}
          />
        );
      },
    },
    {
      title: '備註',
      width: 380,
      render(source: ProductionPlanningOrdersData, index: number) {
        return (
          <PlannedDeliveryNoteInlineForm
            source={source}
            refetchProductionPlanningOrders={refetchProductionPlanningOrders}
          />
        );
      },
    },
    { title: '訂單編號', dataIndex: 'orderId', width: 200 },
    { title: '訂單項次', dataIndex: 'salesOrderLine', width: 150 },
    { title: '料號', dataIndex: 'materialId', width: 350 },
    { title: '品名', dataIndex: 'materialName', width: 350 },
    { title: '單位', dataIndex: 'quantityUnit', width: 90 },
    { title: '工廠／收出貨倉庫', dataIndex: 'bu', width: 150 },
    { title: '數量', dataIndex: 'quantity', width: 150 },
  ];

  return (
    <PageLayout title="交期管理">
      <FilterPanelForm
        exposeFormMethodsForInitializeFilterPanel={
          bindFormMethodsForInitialization
        }
        applyFilterCondition={() => {
          updateFilterCondition({
            onFilterConditionNoChange: refetchProductionPlanningOrders,
            onUpdateSuccess: () => setCurrentPage(1),
          });
        }}
      >
        <CustomerOrderDatePicker />
        <ExpectedDeliveryDatePicker />
        <PlannedDeliveryRadioGroup />
        <BUIdAutoComplete />
        <PlannedDeliveryDatePicker />
        <OrderIdInput />
        <MaterialCategoryAutoComplete
          styles={{ label: { alignSelf: 'self-start' } }}
        />
        <MaterialIdInput
          styles={{
            label: { alignSelf: 'self-start' },
            field: { alignSelf: 'self-start' },
          }}
        />
      </FilterPanelForm>
      <Table
        scroll={{
          x: 2000,
          y: 570,
          fixedFirstColumn: true,
        }}
        draggable={{ enabled: false }}
        loading={isLoading}
        columns={tableColumns}
        dataSource={productionPlanningOrdersData}
        pagination={{
          disableAutoSlicing: true,
          total: productionPlanningOrdersMeta.total,
          current: currentPage,
          onChange: (page) => {
            setCurrentPage(page);
          },
          options: {
            pageSize: perPage,
            pageSizeLabel: '每頁顯示：',
            pageSizeUnit: '筆',
            onChangePageSize: (pageSize) => {
              setPerPage(pageSize);
              setCurrentPage(1);
            },
            renderPageSizeOptionName: (p) => `${p}`,
            showPageSizeOptions: true,
            showJumper: true,
            jumperButtonText: '前往',
            jumperHintText: '跳至',
          },
        }}
      />
    </PageLayout>
  );
}
