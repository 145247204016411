import useSWR from 'swr';
import { API_NAMESPACE, request } from '../request';
import { useCallback, useEffect, useState } from 'react';
import {
  GetMaterialStacksParams,
  GetMaterialStacksResponse,
  MaterialStack,
} from './materialStacks.type';

const namespace = API_NAMESPACE.MATERIALS;

export function useGetMaterialStacks() {
  const [params, setParams] = useState<GetMaterialStacksParams>();
  const { data, error, mutate } = useSWR<GetMaterialStacksResponse>(
    params ? ['/material-stack', { params, namespace }] : null
  );

  const fetchMaterialStacks = useCallback(
    (nextParams: GetMaterialStacksParams) => {
      setParams(nextParams);
    },
    []
  );

  const mutateMaterialStacks = () =>
    mutate(request('/material-stack', { params, namespace }));

  return {
    materialStacks: data?.records,
    pageInfo: data?.pageInfo,
    isLoading: !data && !error,
    fetchMaterialStacks,
    mutateMaterialStacks,
  };
}

export function useGetMaterialStackById({ id }: { id?: MaterialStack['id'] }) {
  const { data, error } = useSWR<MaterialStack>(
    id !== undefined ? [`/material-stack/${id}`, { namespace }] : null
  );

  return {
    materialStack: data,
    isLoading: !data && !error,
  };
}

export function createMaterialStack({
  data,
}: {
  data: Omit<MaterialStack, 'id' | 'createdAt'>;
}) {
  return request('/material-stack', {
    method: 'post',
    body: JSON.stringify(data),
    namespace,
  });
}

export function updateMaterialStack({
  id,
  data,
}: {
  id: MaterialStack['id'];
  data: MaterialStack;
}) {
  return request(`/material-stack/${id}`, {
    method: 'put',
    body: JSON.stringify(data),
    namespace,
  });
}

export function deleteMaterialStack({ id }: { id: MaterialStack['id'] }) {
  return request(`/material-stack/${id}`, {
    method: 'delete',
    namespace,
    responseParser: (res) => res,
  });
}
