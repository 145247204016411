import { createContext, useContext } from 'react';

interface ModalsController {
  currentModal: string | null;
  currentModalData: unknown;
  register: (name: string) => void;
  unregister: (name?: string) => void;
  openModal: (name: string, data: unknown) => void;
  closeModal: () => void;
}

export interface PageContextValue<ValueType = any> {
  value: ValueType | null;
  modalsController: ModalsController | null;
}

export const PageContext = createContext<PageContextValue>({
  value: null,
  modalsController: null,
});

export function usePageContext<ValueType = any>() {
  return useContext<PageContextValue<ValueType>>(PageContext);
}
