import styles from './Other-Info.module.scss';
import { Typography } from '@mezzanine-ui/react';
import { TextAreaField } from '@mezzanine-ui/react-hook-form';
import { RecycleTypeSelector } from '../../Selectors/RecycleType-Selector/RecycleType-Selector';
import { SealingTypeSelector } from '../../Selectors/SealingType-Selector/SealingType-Selector';
import { WasteTypeSelector } from '../../Selectors/Waste/WasteType-Selector';
import { WasteNameSelector } from '../../Selectors/Waste/WasteName-Selector';
import { WastePropertyCheckbox } from '../../Checkboxes/WasteProperty-Checkbox';
import { PackagingCheckbox } from '../../Checkboxes/Packaging-Checkbox';

export function OtherInfoForm() {
  return (
    <div className={styles.container}>
      <Typography variant="h3" className={styles.title}>
        其他注意項目
      </Typography>
      <div className={styles.otherInfoNote}>
        <TextAreaField
          label="備註"
          registerName="note"
          size="large"
        ></TextAreaField>
      </div>
      <RecycleTypeSelector className={styles.otherInfoSelectors} />
      <SealingTypeSelector className={styles.otherInfoSelectors} />
      <WasteTypeSelector className={styles.otherInfoSelectors} />
      <WasteNameSelector className={styles.otherInfoSelectors} />
      <WastePropertyCheckbox className={styles.otherInfoCheckboxes} />
      <PackagingCheckbox className={styles.otherInfoCheckboxes} />
    </div>
  );
}
