import { InputField } from '@mezzanine-ui/react-hook-form';
import { useMiscMaterialGroups } from '@solar/data';
import { CustomDependentSelectors } from '../../MaterialEnquiry/Filters/CustomDependentSelectors';
import classes from './filter.module.scss';
import { labelAndRegisterKeyArray } from './const';
import { Writeable } from './types';

export function Filter() {
  const { miscMaterialGroups } = useMiscMaterialGroups();

  return (
    <div className={classes.host}>
      <InputField
        label="關鍵字："
        registerName="searchTerm"
        placeholder="請輸入關鍵字"
      />
      <CustomDependentSelectors
        data={miscMaterialGroups}
        labelAndRegisterKeyArray={
          labelAndRegisterKeyArray as Writeable<typeof labelAndRegisterKeyArray>
        }
        thisSelectorLevel={0}
      />
    </div>
  );
}
