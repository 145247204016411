import { Button, Icon, MenuDivider, Typography } from "@mezzanine-ui/react";
import styles from './page-layout.module.scss';
import { CSSProperties, ReactNode } from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { ChevronLeftIcon } from "@mezzanine-ui/icons";

type PageLayoutProps = {
  title?: ReactNode;
  subtitle?: ReactNode;
  extraContent?: ReactNode;
  children: ReactNode;
  headerSpan?: [number, number];
  backTo?: string;
  disabledDivider?: boolean;
};

export function PageLayout({
  title,
  subtitle,
  extraContent,
  children,
  headerSpan = [12, 12],
  backTo,
  disabledDivider = false,
}: PageLayoutProps) {
  return (
    <div className={styles.page}>
      {backTo && (
        <Link to={backTo}>
          <Button
            type="button"
            prefix={
              <Icon
                style={{ color: '#8F8F8F' } as CSSProperties}
                icon={ChevronLeftIcon}
              />
            }
            variant="text"
          >
            <Typography color="text-secondary" variant="button2">
              返回上一頁
            </Typography>
          </Button>
        </Link>
      )}
      <Row>
        <Col span={headerSpan?.[0] ?? 24}>
          {title && <Typography variant="h1">{title}</Typography>}
          {subtitle && <Typography variant="h5" color="secondary">{subtitle}</Typography>}
        </Col>
        {extraContent && (
          <Col span={headerSpan?.[1]}>
            {extraContent}
          </Col>
        )}
      </Row>
      {!disabledDivider && <MenuDivider />}
      {children}
    </div>
  )
}