import { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Checkbox, SelectValue } from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  FormFieldsWrapper,
} from '@mezzanine-ui/react-hook-form';
import {
  useLoaderLabelInfo,
  useLoaders,
  useLoadersCategories,
} from '@solar/data';
import { RowSection } from '@solar/templates';
import { useForm, useWatch } from 'react-hook-form';
import { useReactToPrint } from 'react-to-print';

import classes from './loader-sticker-panel.module.scss';
import { LoaderStickerFormProps } from './types';

import bwipjs from 'bwip-js';
import debounce from 'lodash/debounce';
import { LabelPreview } from './LabelPreview';
import { LoaderLabelPdf, LoaderLabelPdfProps } from './LoaderLabelPdf';

const CANVAS_ID = 'loader_id';

export const LoaderStickerPanel = () => {
  const [specifyMode, setSpecifyMode] = useState(false);
  const printRef = useRef<HTMLDivElement>(null);

  const methods = useForm<LoaderStickerFormProps>();

  const { data: loadersCategories } = useLoadersCategories();
  const { loaders, mutateLoaderParams } = useLoaders();
  const { data: specifiedLoader, mutateParams: mutateLoaderLabelInfoParams } =
    useLoaderLabelInfo();

  const debounceFn = useCallback(
    debounce((props: { loaderId: string }) => {
      mutateLoaderLabelInfoParams({ loaderIds: [props.loaderId] });
    }, 600),
    []
  );

  const handleSearch = useCallback(
    (value: string) => {
      if (specifyMode) {
        debounceFn({ loaderId: value });
      }
    },
    [specifyMode]
  );

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    pageStyle: `
      @media print {
        html, body {
          height: auto !important;    
          
        }

        .preview {
          border: 0px !important;
          margin-top: 2pt !important;
          margin-left: 2pt !important;
          
          
        }

        @page {
          size: 100mm 60mm !important;
          margin: 0 !important;
        }
      }
      `,
  });

  const watchLoader = useWatch({
    control: methods.control,
    name: 'loader',
  });

  const watchLocation = useWatch({
    control: methods.control,
    name: 'location',
  });

  const selectedLoader =
    specifyMode && specifiedLoader.length
      ? (specifiedLoader.map((loaderObj) => ({
          id: loaderObj.loaderId,
          category: {
            code: loaderObj.loaderId.charAt(0),
            name: loaderObj.loaderCategoryName,
          },
          lengthCm: loaderObj.loaderLengthCm,
          heightCm: loaderObj.loaderHeightCm,
          widthCm: loaderObj.loaderWidthCm,
          capacityL: loaderObj.loaderCapacityL,
        })) as LoaderLabelPdfProps['selectedLoader'][])
      : watchLoader && loaders.filter((row) => row.id === watchLoader.id);

  useEffect(() => {
    if (watchLoader) {
      bwipjs.toCanvas(CANVAS_ID, {
        bcid: 'datamatrix',
        text: watchLoader.id,
        width: 25,
        height: 25,
      });
    }
  }, [watchLoader]);

  return (
    <div className={classes['host']}>
      <FormFieldsWrapper methods={methods} className={classes['form-wrapper']}>
        <div style={{ display: 'flex', gap: 'var(--mzn-spacing-4)' }}>
          <label>指定編號</label>
          <Checkbox
            checked={specifyMode}
            onChange={() => {
              setSpecifyMode((prev) => !prev);
              methods.setValue('category', null);
              methods.setValue('location', null);
            }}
          />
        </div>
        <RowSection label="類型：">
          <AutoCompleteField
            disabled={specifyMode}
            registerName="category"
            options={
              loadersCategories?.map((row) => ({
                id: row.code,
                name: row.name,
              })) ?? []
            }
            onChange={(category) => {
              if (category) {
                mutateLoaderParams({ categoryCodes: [category.id] });
              }
            }}
          />
        </RowSection>
        <RowSection label="位置：">
          <AutoCompleteField
            disabled={specifyMode}
            registerName="location"
            options={
              loaders?.reduce(
                (accu, cur) => {
                  if (!accu.shelfIdSet.has(cur.shelfId)) {
                    accu.shelfIdSet.add(cur.shelfId);
                    accu.result.push({ id: cur.shelfId, name: cur.shelfId });
                  }
                  return accu;
                },
                { shelfIdSet: new Set(), result: [] as SelectValue[] }
              ).result ?? []
            }
          />
        </RowSection>
        <RowSection label="編號：">
          <AutoCompleteField
            registerName="loader"
            options={
              specifyMode
                ? specifiedLoader.map((row) => ({
                    id: row.loaderId,
                    name: row.loaderId,
                  }))
                : (watchLocation
                    ? loaders?.filter((row) => row.shelfId === watchLocation.id)
                    : loaders
                  ).map((row) => ({ id: row.id, name: row.id })) ?? []
            }
            onChange={(loader) => {
              const location = loaders.find((row) => row?.id === loader?.id);

              if (location) {
                methods.setValue('location', {
                  id: location.shelfId,
                  name: location.shelfId,
                });
              }

              if (specifyMode && specifiedLoader.length) {
                const categoryId = specifiedLoader[0].loaderId.charAt(0);
                const categoryName = specifiedLoader[0].loaderCategoryName;
                methods.setValue('category', {
                  id: categoryId,
                  name: categoryName,
                });

                methods.setValue('location', null);
              }
            }}
            onSearch={handleSearch}
          />
        </RowSection>

        <div>
          <Button variant="outlined" onClick={handlePrint}>
            列印
          </Button>
        </div>
      </FormFieldsWrapper>
      <div ref={printRef}>
        <LabelPreview>
          <LoaderLabelPdf
            selectedLoader={selectedLoader?.length ? selectedLoader[0] : null}
            canvasId={CANVAS_ID}
          />
        </LabelPreview>
      </div>
    </div>
  );
};
