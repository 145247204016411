import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { SelectValue } from '@mezzanine-ui/react';
import { useState } from 'react';

const namespace = API_NAMESPACE.MATERIALS;

export function useMaterialFeatureCodes() {
  const [params, setParams] = useState<{ groupCode?: string }>();
  const { data, error } = useSWR<(SelectValue & { code: string })[]>([
    '/material-properties/feature-codes',
    { namespace, params },
  ]);

  return {
    materialFeatureCodes: data ?? [],
    refetchMaterialFeatureCodes: setParams,
    isLoading: !error && !data,
  };
}
