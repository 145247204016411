import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Select, Table, Typography } from "@mezzanine-ui/react";
import { UseModalControllerReturn } from "@solar/data";
import styles from './material-transfer-modal.module.scss';
import { useForm } from "react-hook-form";
import moment from "moment";
import { SelectedMaterialBatchList } from "./SelectedMaterialBatchList";

type MergeMaterialBatchModalProps = UseModalControllerReturn<any> & {
  onSubmit: (payload: any) => void,
}

export function MergeMaterialBatchModal({ open, data, closeModal, onSubmit }: MergeMaterialBatchModalProps) {
  const methods = useForm();
  const handleSubmit = methods.handleSubmit((formState) => {
    onSubmit(formState);
    closeModal();
  });
  const columns = [{
    title: '料號',
    dataIndex: 'materialId',
  }, {
    title: '批號',
    dataIndex: 'materialBatchId',
  }, {
    title: '儲位',
    dataIndex: 'stack',
  }, {
    width: 100,
    title: '數量',
    dataIndex: 'amount',
  }];
  return (
    <Modal className={styles.modal_wrapper} open={open} onClose={closeModal}>
      <ModalHeader>批號合併</ModalHeader>
      <ModalBody>
        <SelectedMaterialBatchList dataSource={data?.selectedRowKeys || []} />
        <div style={{ height: 100 }}>
          <Typography variant="h6" color="secondary">新批號設定</Typography>
          <Table
            columns={[
              {
                title: '西元年碼',
                render: () => moment().format('YYYY').slice(2),
              },
              {
                title: '月碼',
                render: () => moment().month().toString(16).toUpperCase(),
              },
              {
                title: '廠區碼',
                render: () => (
                  <Select>

                  </Select>
                ),
              },
              {
                title: '課級碼',
                render: () => (
                  <Select>

                  </Select>
                ),
              },
              {
                title: '組級碼',
                render: () => (
                  <Select>

                  </Select>
                ),
              },
            ]}
            dataSource={[{ id: '' }]}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => closeModal()}>取消</Button>
        <Button onClick={handleSubmit}>確認</Button>
      </ModalFooter>
    </Modal>
  )
}