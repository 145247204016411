export const COLORS = [
  '#f3722c',
  '#277da1',
  '#577590',
  '#f8961e',
  '#4d908e',
  '#f9844a',
  '#43aa8b',
  '#f9c74f',
  '#90be6d',
  '#0f4c5c',
  '#5c8001',
  '#918450',
  '#3d348b',
];
