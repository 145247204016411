import { Col, Form, Row, Select } from "antd";
import { data } from './data';
import { useMemo, useRef } from "react";

export function GrafanaPage() {
  const ref = useRef<HTMLDivElement>(null);
  const [form] = Form.useForm();

  const wc = Form.useWatch('wc', form);

  const iframesProps = useMemo(() => {
    const targetUrls = data?.find((item) => item?.SAP_WC === wc)?.grafana_url ?? [];
    return targetUrls?.map((targetUrl) => {
      const url = new URL(targetUrl);
      const viewPanel = url.searchParams.get('viewPanel');
      url.searchParams.set('theme', 'light');
      return {
        src: url.toString(),
        style: {
          border: 0,
          width: '100%',
          minWidth: 1024,
          height: 800,
          marginLeft: '-60px',
          marginTop: (viewPanel === null) ? '-100px' : '-60px',
        },
      };
    });
  }, [wc]);

  return (
    <div style={{ height: '100%', display: 'grid', gridTemplateRows: 'min-content auto' }}>
      <Form form={form}>
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item name="wc" label="選擇機台" initialValue={data?.[0]?.SAP_WC}>
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={data?.map((item) => ({ value: item?.SAP_WC, label: item?.title }))} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div ref={ref}>
        {iframesProps?.map((prop) => (
          <div style={{ overflow: 'hidden' }}>
            <iframe
              title="grafana"
              style={prop.style}
              src={prop.src}
            />
          </div>
        ))}
      </div>
    </div>
  )
} 