import { Tab, TabPane, Tabs, Typography, Table, Input } from "@mezzanine-ui/react";
import { DrillLayeringCode, SizeCodeOne, SlottingCode, calculateSizeCode, useDrawingsQueryDDL, useGetDrawingsAttributeCodes } from "@solar/data";
import { Col, Row } from "antd";
import { Key, useMemo, useState } from "react";
import { RowSection } from "../../Templates/RowSection/RowSection";

export function AttributeCodeMappingPage() {
  const [activeTabKey, setActiveTabKey] = useState<Key>('compositeCode');
  const {
    compositeCodes,
    shapeCodes,
    drillingCodes,
    slottingCodes,
    sandblastingCodes,
    notchCodes,
  } = useGetDrawingsAttributeCodes();
  const [sizeValue, setSizeValue] = useState<string>();
  const sizeCode = useMemo(() => calculateSizeCode(Number(sizeValue)), [sizeValue]);

  const mapData = (data: any) => data?.map((code: any) => ({ id: code?.code, key: code?.code, ...code }));
  
  const getWordingByBoolean = (bool: boolean) => bool ? '有' : '無'; 

  const codeColumn = {
    width: 100,
    dataIndex: 'code',
    title: '代碼',
  };

  const nameColumn = {
    dataIndex: 'name',
    title: '對應值',
  };

  return (
    <div>
      <Typography variant="h1">圖號屬性對應表</Typography>
      <Tabs
        activeKey={activeTabKey}
        onChange={(key) => setActiveTabKey(key)}
      >
        <TabPane
          key="compositeCode"
          tab={<Tab>組合碼</Tab>}
        >
          <Table
            style={{ height: 550 }}
            columns={[
              codeColumn,
              nameColumn,
            ]}
            dataSource={mapData(compositeCodes?.data)} />
        </TabPane>
        <TabPane
          key="shapeCode"
          tab={<Tab>形狀碼</Tab>}
        >
          <Table
            style={{ height: 550 }}
            columns={[
              {
                width: 80,
                title: 'BU',
                dataIndex: 'bu',
              },
              codeColumn,
              nameColumn,
            ]}
            dataSource={mapData(shapeCodes?.data)} />
        </TabPane>
        <TabPane
          key="sizeCode"
          tab={<Tab>尺寸碼</Tab>}
        >
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <RowSection label="代碼預覽">
                {sizeCode}
              </RowSection>
            </Col>
            <Col span={24}>
              <RowSection label="對應值">
                <Input value={sizeValue} onChange={(e) => setSizeValue(e?.target?.value ?? 0)} />
              </RowSection>
            </Col>
          </Row>
        </TabPane>
        <TabPane
          key="layeringCode"
          tab={<Tab>TEM 分階碼</Tab>}
        >
          <Table
            style={{ height: 550 }}
            columns={[
              codeColumn,
              nameColumn,
            ]}
            dataSource={[
              { id: 1, code: 'N', name: '無' },
              { id: 2, code: 'S', name: '有' },
            ]}
          />
        </TabPane>
        <TabPane
          key="sandblastingCode"
          tab={<Tab>TEM 噴砂碼</Tab>}
        >
          <Table
            style={{ height: 550 }}
            columns={[
              {
                width: 80,
                dataIndex: 'value',
                title: '代碼',
              },
              {
                dataIndex: 'label',
                title: '對應值',
              },
            ]}
            dataSource={
              mapData(sandblastingCodes?.options?.map((option) => ({
                ...option,
                label: option?.label?.split('（')?.[1]?.slice(0, -1)
              })))} />
        </TabPane>
        <TabPane
          key="slottingCode"
          tab={<Tab>TEM 開槽碼</Tab>}
        >
          <Table
            style={{ height: 550 }}
            columns={[
              codeColumn,
              {
                title: '對應值',
                render: (source) => {
                  console.log(source);
                  const oName = `${getWordingByBoolean(source?.isOring as boolean)}Oring`;
                  const pName = `${getWordingByBoolean(source?.isProfile as boolean)}Profile`;
                  const name = `${oName}、${pName}`;
                  return name;
                }
              },
            ]}
            dataSource={mapData(slottingCodes?.data)} />
        </TabPane>
        <TabPane
          key="drillingCode"
          tab={<Tab>TEM 鑽孔碼</Tab>}
        >
          <Table
            style={{ height: 550 }}
            columns={[
              codeColumn,
              {
                title: '對應值',
                render: (source) => {
                  console.log(source);
                  const tName = `${getWordingByBoolean(source?.isTapping as boolean)}攻牙`;
                  const pName = `${getWordingByBoolean(source?.isPerforation as boolean)}穿孔`;
                  const bName = `${getWordingByBoolean(source?.isBlindHole as boolean)}盲孔`;
                  const name = `${tName}、${pName}、${bName}`;
                  return name;
                }
              },
            ]}
            dataSource={mapData(drillingCodes?.data)}
          />
        </TabPane>
        <TabPane
          key="notchCode"
          tab={<Tab>FCC 角加工碼</Tab>}
        >
          <Table
            style={{ height: 550 }}
            columns={[
              codeColumn,
              {
                width: 80,
                dataIndex: 'chamferAngleCount',
                title: 'Ｃ角數',
              },
              {
                width: 80,
                dataIndex: 'radiusAngleCount',
                title: 'Ｒ角數',
              },
              {
                width: 80,
                dataIndex: 'totalAngleCount',
                title: '總角數',
              },
            ]}
            dataSource={mapData(notchCodes?.data)}
          />
        </TabPane>
      </Tabs>
    </div>
  )
}