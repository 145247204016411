import { PageLayout } from '@solar/templates';
import { Space } from 'antd';
import { IncomingQualityControlTable } from '../IncomingQualityControlTable/IncomingQualityControlTable';

export function IncomingQualityControlPurchaseViewPage() {
  return (
    <PageLayout title="可付款清單">
      <Space size="middle" direction="vertical">
        <IncomingQualityControlTable
          statusIds={[2, 12]}
          getColumns={(defaultColumns) => defaultColumns}
        />
      </Space>
    </PageLayout>
  );
}
