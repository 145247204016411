import useSWR from "swr";

export function useEquipmentCategories() {
  const { data, error } = useSWR('/equipments/categories');

  return {
    categories: data,
    isLoading: !error && !data,
  };
}
