import { SelectValue } from '@mezzanine-ui/react';

export interface TargetNumberRulesParams {
  name?: string;
  ruleType?: SelectValue<string>;
  ruleStatus?: SelectValue<string>;
  customerName?: CustomerNameSearch;
  customerId?: CustomerNameSearch;
  materialId?: string;
  page_at?: number;
  page_size?: number;
}

export interface CustomerNameSearch {
  id: string;
  name: string;
}

export interface CustomerFromAPI {
  customer_id: string;
  customer_name: string;
  customer_short_name: string;
}

export interface UseGetCustomerMaterialIdParams {
  customerIds: string;
  query?: string;
  pageAt?: number;
  pageSize?: number;
}

export interface UseGetCustomerMaterialIdForInternalRuleParams {
  query?: string;
  pageAt?: number;
  pageSize?: number;
}

export interface NumberRulePayload {
  number_rule_name: string;
  rule_type: string;
  start_date: string;
  end_date?: string;
  customer_ids: string[];
  code_rules: CodeRule[];
  material_ids: string[];
}

export interface NumberRuleDisablePayload extends NumberRulePayload {
  is_disabled: boolean;
}

export interface CodeRule {
  code_rule_id: number;
  seq: number | undefined;
  is_disabled: boolean | string;
  is_reset_criterion: boolean;
  parameters: Parameter[];
}

export interface Parameter {
  code_parameter_id: number;
  value: string | number | boolean | undefined;
}

export type CreateTargetCodeRulePayload = {
  body: NumberRulePayload;
};

export type UpdateTargetCodeRulePayload = {
  id: string | undefined;
  body: NumberRulePUTPayload;
};

export interface NumberRulePUTPayload {
  end_date?: string;
  customer_ids?: string[];
  code_rules?: CodeRule[];
  material_ids?: string[];
}

export interface DisableTargetCodeRulePayload {
  is_disabled: boolean;
}

export interface DispatcherTargetNumberRule {
  customerId?: string;
  order_id?: string;
  start_date?: string;
  end_date?: string;
  page_at?: number;
  page_size?: number;
  salesOrderNumber?: string;
  date?: [string, string];
  customerName?: CustomerNameSearch;
}

export interface CustomerNameSearch {
  id: string;
  name: string;
}

export interface DispatchedTargetNumberRuleFilter {
  internal_issued_number?: string;
  customer_issued_number?: string;
  customer_number_rule_id?: number;
  order_id?: string;
  sales_order_line?: string;
  material_id?: string;
  page_at?: number;
  page_size?: number;
}

export interface ManualDispatcherPayload {
  order_detail_id: number;
  numbers: { internal: string; customer: string; }[]
}

export interface AutoDispatcherPayload {
  order_detail_id: number;
  customer_number_rule_id: number;
  quantity: number;
}

export interface UpdateNumberHistoryPayload {
  disable_date?: string;
  customer_issued_number?: string;
}

export interface MaterialInternalTargetRuleCheckerPayload {
  order_detail_id: number;
}

export interface UpdateTargetRuleCustomerPayload {
  id: string | undefined;
  body: {
    customers: customers[];
  }
}

export interface UpdateTargetRuleMaterialPayload {
  id: string | undefined;
  body: {
    materials: isUsedMaterial[];
  };
}

export interface customers {
  customer_id: string;
  is_disabled: boolean;
}

export interface isUsedMaterial {
  material_id: string;
  is_disabled: boolean;
}
