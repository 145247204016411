import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Typography } from "@mezzanine-ui/react";
import { UseModalControllerReturn } from "@solar/data";
import { LocationSelector, useLocationSelector } from "../../Equipment/LocationSelector/LocationSelector";
import styles from './material-transfer-modal.module.scss';
import { SelectedMaterialBatchList } from "./SelectedMaterialBatchList";

type UpdateStackModalProps = UseModalControllerReturn<any> & {
  onSubmit: (payload: any) => void,
}

export function UpdateStackModal({ open, data, closeModal, onSubmit }: UpdateStackModalProps) {
  const locationSelectorController = useLocationSelector();
  const { currentLocation } = locationSelectorController;
  return (
    <Modal className={styles.modal_wrapper} open={open} onClose={closeModal}>
      <ModalHeader>儲位更換</ModalHeader>
      <ModalBody>
        <SelectedMaterialBatchList dataSource={data?.selectedRowKeys || []} />
        <LocationSelector {...locationSelectorController} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => closeModal()}>取消</Button>
        <Button onClick={() => onSubmit({ currentLocation })}>確認</Button>
      </ModalFooter>
    </Modal>
  )
}