import { API_NAMESPACE, request } from '@solar/data';
import { UpdateSalesMaterialsPayload } from '../types';

const namespace = API_NAMESPACE.MATERIALS;

export const updateSalesMaterials = ({
  payload,
}: {
  payload: UpdateSalesMaterialsPayload;
}): Promise<Response> => {
  return request('/sales-materials', {
    namespace,
    method: 'put',
    body: JSON.stringify(payload),
    responseParser: (res) => res,
  });
};
