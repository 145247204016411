import { API_NAMESPACE, CurrentLocation, Equipment, GetMaterialStacksResponse, LocationLevel, MaterialStack, deleteMaterialStack, request, updateMaterialStack, useLocationsBySearchTerm, useSVGs } from '@solar/data';
import useSWR from 'swr';
import { Modals } from '../WarehouseMapPage/Modals.enum';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Table, Button, Popper, Icon, Message } from '@mezzanine-ui/react';
import { LocationSVGPopper, LocationSelectorProvider, ModalGroup, useLocationSVGPopper, useLocationSelectorController, useModalGroupController } from '@solar/templates';
import { UpdateMaterialModal } from '../WarehouseMapPage/UpdateMaterialModal';
import { FEATURE_CODE_MAP } from './featureCode';
import { MACHINE_CODE_MAP } from './machineCode';
import moment from 'moment';
import { DateRangePickerField, FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import { useForm, useWatch } from 'react-hook-form';
import { CheckIcon, TrashIcon } from '@mezzanine-ui/icons';
import { saveAs } from 'file-saver';
import styles from './warehouse-temp-list-page.module.scss';

const namespace = API_NAMESPACE.MATERIALS;

export function WarehouseTempListPage() {
  const modalGroupController = useModalGroupController([{ name: Modals.CREATE }, { name: Modals.EDIT }]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const methods = useForm();

  const searchTerm = useWatch({ control: methods.control, name: 'searchTerm' });
  const locationIds = useWatch({ control: methods.control, name: 'locationIds' });
  const dateRange = useWatch({ control: methods.control, name: 'dateRange' });
  const oldMaterialId = useWatch({ control: methods.control, name: 'oldMaterialId' });
  const newMaterialId = useWatch({ control: methods.control, name: 'newMaterialId' });
  const materialBatchId = useWatch({ control: methods.control, name: 'materialBatchId' });
  const runCardId = useWatch({ control: methods.control, name: 'runCardId' });
  const featureCode = useWatch({ control: methods.control, name: 'featureCode' });
  const description = useWatch({ control: methods.control, name: 'description' });
  const note = useWatch({ control: methods.control, name: 'note' });

  const queryParams = useMemo(() => {
    const params = new URLSearchParams();

    if (searchTerm) {
      params.append('searchTerm', searchTerm);
    }

    if (locationIds) {
      params.append('locationIds', locationIds);
    }

    if (dateRange?.length) {
      params.append('startFrom', moment(dateRange[0]).format('YYYY-MM-DD'));

      if (dateRange[1]) {
        params.append('endOn', moment(dateRange[1]).format('YYYY-MM-DD'));
      }
    }

    if (oldMaterialId) {
      params.append('oldMaterialId', oldMaterialId.trim());
    }

    if (newMaterialId) {
      params.append('newMaterialId', newMaterialId.trim());
    }

    if (materialBatchId) {
      params.append('materialBatchId', materialBatchId.trim());
    }

    if (runCardId) {
      params.append('runCardId', runCardId.trim());
    }

    if (featureCode) {
      params.append('featureCode', featureCode.trim());
    }

    if (description) {
      params.append('description', description.trim());
    }

    if (note) {
      params.append('note', note.trim());
    }

    return params.toString();
  }, [searchTerm, locationIds, dateRange, page, pageSize, oldMaterialId, newMaterialId, materialBatchId, runCardId, featureCode, description, note]);

  const latestQueryParams = useRef<string>('');

  useEffect(() => {
    latestQueryParams.current = queryParams;
  }, [queryParams]);

  const [executedQueryParams, setExecutedQueryParams] = useState(queryParams);

  useEffect(() => {
    setExecutedQueryParams(latestQueryParams.current);
  }, [page, pageSize]);

  const { data, mutate } = useSWR<GetMaterialStacksResponse>(
    [`/material-stack?${executedQueryParams}`, { namespace }],
  );

  // const [anchorRef, setAnchorRef] = useState<HTMLButtonElement | null>();
  // const [selectedChartId, setSelectedChartId] = useState<string>();
  // const { svg, mutateGetSVG, resetSVG } = useSVGs();
  const controller = useLocationSelectorController({});
  const { anchorRef, onMouseEnter, onMouseLeave } = useLocationSVGPopper({
    handleNextLevel: controller?.handleNextLevel,
  });

  const mutateMaterialStacks = useCallback(() =>
    mutate(request(`/material-stack?${executedQueryParams}`, { namespace })), [executedQueryParams]);
  const [updatingLocationId, setUpdatingLocationId] = useState<number | null>(null);

  useEffect(() => {
    if (updatingLocationId) {
      const targetRecord = data?.records.find(record => record.id === updatingLocationId) ?? null;

      if (targetRecord) {
        methods.setValue('stackId', targetRecord.stackId);
      }
    }
  }, [updatingLocationId, methods, data]);

  const stackId = useWatch({ control: methods.control, name: 'stackId' });

  const updateStackId = useCallback(async () => {
    if (updatingLocationId) {
      const targetRecord = data?.records.find(record => record.id === updatingLocationId) ?? null;

      setUpdatingLocationId(null);

      if (targetRecord) {
        await updateMaterialStack({
          id: targetRecord.id,
          data: {
            ...targetRecord,
            mainMaterialUnitQuantity: !targetRecord.mainMaterialUnitQuantity || Number.isNaN((targetRecord.mainMaterialUnitQuantity))
              ? undefined
              : Number(targetRecord.mainMaterialUnitQuantity),
            managementUnit: targetRecord.managementUnit || '',
            height: targetRecord.height || '',
            featureCode: targetRecord.featureCode || '',
            machineCode: targetRecord.machineCode || '',
            runCardNumber: targetRecord.runCardNumber || '',
            stackId,
          },
        });

        Message.success('已更新位置');

        mutateMaterialStacks();
      }
    }
  }, [stackId, updatingLocationId]);

  const exportTable = useCallback(() => {
    const csvString = [
      ['舊料號','料號描述','批號','數量','單位','位置','位置名稱','RunCard單號','新料號','特徵碼','特徵碼名稱','加工碼','加工碼名稱','入庫時間','備註'].join(','),
      ...(data?.records ?? []).map((record) => ([
        `"${(record.oldMaterialId || '').toString().replace(/"/g, '""')}"`,
        `"${(record.description || '').toString().replace(/"/g, '""')}"`,
        `"${(record.materialBatchId || '').toString().replace(/"/g, '""')}"`,
        `"${(record.mainMaterialUnitQuantity || '').toString().replace(/"/g, '""')}"`,
        `"${(record.mainMaterialUnit || '').toString().replace(/"/g, '""')}"`,
        `"${(record.stackId || '').toString().replace(/"/g, '""')}"`,
        `"${(record.placement || record.stackId || '').toString().replace(/"/g, '""')}"`,
        `"${(record.runCardNumber || '').toString().replace(/"/g, '""')}"`,
        `"${(record.materialId || '').toString().replace(/"/g, '""')}"`,
        `"${(record.featureCode || '').toString().replace(/"/g, '""')}"`,
        `"${(record.featureCode || '').toString().replace(/"/g, '""')}"`,
        `"${(record.machineCode || '').toString().replace(/"/g, '""')}"`,
        `"${(record.machineCode || '').toString().replace(/"/g, '""')}"`,
        `"${(record.recordedAt || '').toString().replace(/"/g, '""')}"`,
        `"${(record.note || '').toString().replace(/"/g, '""')}"`,
      ]).join(',')),
    ].join('\n');

    const blob = new Blob([
      new Uint8Array([0xEF, 0xBB, 0xBF]),
      csvString,
    ], { type: 'text/csv' });

    saveAs(blob, 'export.csv');
  }, [data]);

  const [updateKey, setUpdateKey] = useState<string>();

  return (
    <LocationSelectorProvider controller={controller}>
      <FormFieldsWrapper
        methods={methods}>
        <div style={{ padding: '12px 0', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start', columnGap: 12 }}>
          <InputField
            width={200}
            label="地點"
            registerName="locationIds" />
          <InputField
            width={220}
            label="舊料號"
            registerName="oldMaterialId" />
          <InputField
            width={220}
            label="新料號"
            registerName="newMaterialId" />
          <InputField
            width={220}
            label="批號"
            registerName="materialBatchId" />
          <DateRangePickerField
            label="入庫時間"
            registerName="dateRange" />
          <Button variant="contained" onClick={() => setExecutedQueryParams(queryParams)}>
            篩選
          </Button>
          <Button variant="contained" onClick={exportTable}>
            匯出表格資料
          </Button>
        </div>
        <div style={{ padding: '12px 0', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start', columnGap: 12 }}>
          <InputField
            width={200}
            label="RunCard"
            registerName="runCardId" />
          <InputField
            width={220}
            label="特徵碼"
            registerName="featureCode" />
          <InputField
            width={220}
            label="料號描述"
            registerName="description" />
          <InputField
            width={220}
            label="備註"
            registerName="note" />
        </div>
        <div className={styles.tableContainer}>
          <div className={styles.tableWrapper}>
            <Table
              loading={!data}
              pagination={{
                total: data?.pageInfo.totalCount,
                current: page,
                onChange: (nextPage) => {
                  setPage(nextPage);
                },
                options: {
                  pageSize,
                  pageSizeLabel: '每頁顯示：',
                  pageSizeUnit: '筆',
                  onChangePageSize: (nextPageSize) => {
                    setPageSize(nextPageSize);
                    setPage(1);
                  },
                  renderPageSizeOptionName: (p) => `${p}`,
                  renderPaginationSummary: (start, end) => `目前顯示 ${start} - ${end} 筆，共 ${end - start + 1} 筆資料`,
                  showPageSizeOptions: true, // 開啟每頁顯示 N 筆
                  showJumper: true, // 開啟跳頁功能
                  jumperButtonText: '前往',
                  jumperHintText: '跳至',
                }
              }}
              style={{ height: (pageSize + 2) * 52 }}
              dataSource={data?.records ?? []}
              columns={[
                {
                  title: '#',
                  width: 80,
                  render: (value: MaterialStack, index: number) => (
                    index + 1
                  ),
                },
                {
                  title: '操作',
                  width: 160,
                  render: (source: MaterialStack) => (
                    <div style={{ display: 'flex' }}>
                      <Button onClick={() => {
                        modalGroupController.openModal(Modals.EDIT, source);

                        setUpdateKey(source.id.toString());
                      }}>更新</Button>
                    </div>
                  )
                },
                {
                  title: '舊料號',
                  width: 140,
                  dataIndex: 'oldMaterialId',
                },
                {
                  title: '料號描述',
                  width: 300,
                  dataIndex: 'description',
                },
                {
                  title: '批號',
                  width: 300,
                  dataIndex: 'materialBatchId',
                },
                {
                  title: '數量',
                  width: 150,
                  dataIndex: 'mainMaterialUnitQuantity',
                },
                {
                  title: '單位',
                  width: 96,
                  dataIndex: 'mainMaterialUnit',
                },
                {
                  title: '位置',
                  width: updatingLocationId ? 300 : 200,
                  render: (value: MaterialStack) => (
                    updatingLocationId === value.id ? (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', columnGap: 4 }}>
                        <InputField
                          registerName='stackId' />
                        <Button
                          onClick={updateStackId}
                          style={{ padding: 0 }}
                          size="small">
                          <Icon icon={CheckIcon} />
                        </Button>
                        <Button
                          danger
                          onClick={() => setUpdatingLocationId(null)}
                          style={{ padding: 0 }}
                          size="small">
                          <Icon icon={TrashIcon} />
                        </Button>
                      </div>
                    ) : (
                      <span onClick={() => setUpdatingLocationId(value.id)}>
                        {value.stackId}
                      </span>
                    )
                  ),
                },
                {
                  title: '位置名稱',
                  width: 140,
                  render: (value: MaterialStack) => (
                    <div
                      data-zone-id={value.stackId}
                      onMouseEnter={(event: any) => {
                        if (value.stackId) {
                          onMouseEnter(value.stackId)(event);
                        }
                      }}
                      onMouseLeave={onMouseLeave}
                    >
                      {value.placement || value.stackId}
                    </div>
                  ),
                },
                {
                  title: 'RunCard單號',
                  width: 220,
                  dataIndex: 'runCardNumber',
                },
                {
                  title: '新料號',
                  width: 300,
                  dataIndex: 'materialId',
                },
                {
                  title: '特徵碼',
                  width: 90,
                  dataIndex: 'featureCode',
                },
                {
                  title: '特徵碼名稱',
                  width: 120,
                  render(source, index, column) {
                    return FEATURE_CODE_MAP[source.featureCode as string] || source.featureCode || '-';
                  },
                },
                {
                  title: '加工碼',
                  width: 90,
                  dataIndex: 'machineCode',
                },
                {
                  title: '加工碼名稱',
                  width: 120,
                  render(source, index, column) {
                    return MACHINE_CODE_MAP[source.machineCode as string] || source.machineCode || '-';
                  },
                },
                {
                  title: '入庫時間',
                  width: 160,
                  sorter: (recordedAtA, recordedAtB) => (
                    new Date(recordedAtB).getTime()
                    - new Date(recordedAtA).getTime()
                  ),
                  render(source: MaterialStack) {
                    return moment(source.recordedAt).format('YYYY-MM-DD');
                  },
                },
                {
                  title: '備註',
                  width: 300,
                  dataIndex: 'note',
                }
              ]} />
          </div>
        </div>
      </FormFieldsWrapper>
      <ModalGroup {...modalGroupController} key={updateKey}>
        <UpdateMaterialModal refetchGetMaterialStacks={mutateMaterialStacks} />
      </ModalGroup>
      <LocationSVGPopper anchorRef={anchorRef} />
    </LocationSelectorProvider>
  );
}
