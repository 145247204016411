import { AutoCompleteField, BaseField, DatePickerField, DateRangePickerField, FormFieldsWrapper, InputField, RadioGroupField, SelectField } from '@mezzanine-ui/react-hook-form';
import { Button, Icon, SelectValue } from '@mezzanine-ui/react';
import styles from './SPOrderForm.module.scss';
import DebounceAutoCompleteField from '../../OrderManagement/components/DebounceAutoCompleteField';
import { UseFormReturn } from 'react-hook-form';
import { IReportPriceType, ISPEditPageMode, SPEditPageMode, SPFormOrderInfo, SPFormOrderItemInfo, convertSPMaterialToOrderItem, environment, getSPMaterialDetail } from '@solar/data';
import { Upload, UploadFile } from 'antd';
import { UploadIcon } from '@mezzanine-ui/icons';
import { ChangeEvent } from 'react';

const isDateDisabled = (date: string): boolean => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return new Date(date).getTime() < today.getTime();
};

// all registerName: showId, customer, tradeMode, reportMode, reportType, entrustDateRange, reportNote, material, reportDate, dealDate, reportState, reportUser, sales, checkUser, backNote, reportQty
const disabledMapping = (pageMode: ISPEditPageMode, registerName: string) => {
  const addEnabledList = ['customer', 'tradeMode', 'reportMode', 'reportType', 'entrustDateRange', 'reportNote',
    'material', 'reportDate', 'reportUser', 'sales', 'reportQty', 'entrustDate', 'files'];
  const editEnabledList = ['customer', 'reportNote', 'reportUser', 'sales', 'files'];
  const approveEnableList = ['dealDate', 'reportState', 'checkUser', 'backNote', 'customer', 'files'];
  let disabled = true;
  if (pageMode === SPEditPageMode.ADD) {
    disabled = !addEnabledList.includes(registerName);
  } else if (pageMode === SPEditPageMode.EDIT) {
    disabled = !editEnabledList.includes(registerName);
  } else if (pageMode === SPEditPageMode.APPROVE) {
    disabled = !approveEnableList.includes(registerName);
  }
  return disabled;
};

interface ISPOrderFormProps {
  reportPriceType: IReportPriceType[]
  autoCompleteOptionsMaterial: SelectValue<string>[];
  autoCompleteOptionsSales: SelectValue<string>[];
  autoCompleteReportPriceState: SelectValue<string>[];
  autoCompleteOptionsCustomer: SelectValue<string>[];
  autoCompleteOptionsComponentNames: SelectValue<string>[];
  formMethods: UseFormReturn<SPFormOrderInfo>;
  pageMode: ISPEditPageMode;
  modifyItems: (items?: SPFormOrderItemInfo[]) => void;
}

const SPOrderForm = ({
  reportPriceType,
  autoCompleteOptionsMaterial,
  autoCompleteOptionsSales,
  autoCompleteReportPriceState,
  autoCompleteOptionsCustomer,
  autoCompleteOptionsComponentNames,
  formMethods,
  pageMode,
  modifyItems,
}: ISPOrderFormProps) => {

  const optionsTradeMode = [
    { label: '買進', value: '0' },
    { label: '賣出', value: '1' }
  ];

  //0:即時報價, 1:委託下單,2:限價掛單
  const optionsReportMode = [
    { label: '即時報價', value: '0' },
    { label: '委託下單', value: '1' },
    { label: '限價掛單', value: '2' },
  ];

  const onInsertCustomer = (text: string) => {
    return {
      id: text,
      name: text,
    };
  }

  const [rm, files, rs] = formMethods.watch(['reportMode', 'files', 'reportState.id']);

  const reportTypeOptions = reportPriceType.filter((record)=>(record.reportModes.includes(rm))).map((record)=>({id: record.value, name: record.name}));

  const onChangeReportMode = (e: ChangeEvent<HTMLInputElement>) => {
    modifyItems();
    const reportType = formMethods.getValues('reportType');
    // console.log(reportType);
    if (reportType) {
      formMethods.setValue('reportType', { id: '', name: '請重新選擇' });
    }
    // 修改 reportMode 的時候要同時修改 委託區間 
    // 即時報價0: 系統自動顯示今日日期，無法修改
    // 委託下單1: 選擇一個日期，必須為系統日期今日(含)之後
    // 限價掛單2:選擇兩個日期，起始與到期日，起始日必須為系統日期今日(含)之後
    const reportMode = e.target.value;
    if (reportMode !== '2') {
      formMethods.setValue('entrustDate', new Date().toISOString());
    }
  };

  const onChangeClearItems = () => {
    modifyItems();
  };

  const onChangeMaterial = async (value: SelectValue<string>) => {
    if (value) {
      const material = await getSPMaterialDetail(value.id);
      const reportQty = formMethods.getValues('reportQty');
      if (material && material.standardWt) {
        formMethods.setValue('reportQty', String(material.standardWt));
      }
      if (material.component.items) {
        const newItems = material.component.items
          .filter((item) => autoCompleteOptionsComponentNames.find((option) => option.id === item.componentItemNumber))
          .map((item) => convertSPMaterialToOrderItem(item, material.unitCode, (material.standardWt || Number(reportQty))));
        modifyItems(newItems);
      }
    }
  };

  const onRemove = (file: UploadFile) => {
    const dKeys = formMethods.getValues('deleteFileKeys');
    if (dKeys) {
      formMethods.setValue('deleteFileKeys', [...dKeys, file.response.msg]);
    } else {
      formMethods.setValue('deleteFileKeys', [file.response.msg]);
    }
  };

  return (
    <div>
      <FormFieldsWrapper methods={formMethods}>
        <div className={styles.formRow}>
          <div className={styles.formColLeft}>
            <InputField
              disabled={disabledMapping(pageMode, 'showId')}
              placeholder='系統生成'
              registerName="showId"
              label="敲價單號"
            />
            <div>
              <AutoCompleteField
                disabled={disabledMapping(pageMode, 'customer')}
                value={null}
                registerName='customer'
                label='客戶名稱'
                options={autoCompleteOptionsCustomer}
                required
                addable
                onInsert={onInsertCustomer}
              />
            </div>
            <div style={{ minHeight: 61 }}>
              <RadioGroupField
                disabled={disabledMapping(pageMode, 'tradeMode')}
                registerName="tradeMode"
                label="交易模式"
                options={optionsTradeMode}
                onChange={onChangeClearItems}
                required
              />
            </div>
            <div style={{ minHeight: 61 }}>
              <RadioGroupField
                disabled={disabledMapping(pageMode, 'reportMode')}
                registerName="reportMode"
                label="敲價模式"
                options={optionsReportMode}
                onChange={onChangeReportMode}
                required
              />
            </div>
            <div>
              <SelectField
                disabled={disabledMapping(pageMode, 'reportType')}
                clearable
                registerName='reportType'
                label='敲價形態'
                options={reportTypeOptions}
                onChange={onChangeClearItems}
                required
              />
            </div>
            <div>
              {
                rm === '2' ? (
                  <DateRangePickerField
                    disabled={disabledMapping(pageMode, 'entrustDateRange')}
                    registerName="entrustDateRange"
                    label="委託區間"
                    isDateDisabled={isDateDisabled}
                    required
                  />
                ) : (
                  <DatePickerField
                    disabled={disabledMapping(pageMode, 'entrustDate') || rm === '0'}
                    registerName="entrustDate"
                    label="委託日期"
                    isDateDisabled={isDateDisabled}
                    required
                  />
                )
              }
            </div>
            <div>
              <InputField
                disabled={disabledMapping(pageMode, 'reportNote')}
                registerName="reportNote"
                label="敲價備註"
              />
            </div>
            <div>
              <DebounceAutoCompleteField
                disabled={disabledMapping(pageMode, 'material')}
                registerName='material'
                label='產品料號'
                options={autoCompleteOptionsMaterial}
                onChange={onChangeMaterial}
              />
            </div>
          </div>
          <div className={styles.formCol}>
            <div>
              <DatePickerField
                disabled={disabledMapping(pageMode, 'reportDate')}
                registerName='reportDate'
                label='敲單日期'
                required
              />
            </div>
            <div>
              <DatePickerField
                disabled={disabledMapping(pageMode, 'dealDate')}
                registerName='dealDate'
                label='成交日期'
              />
            </div>
            <div>
              <SelectField
                disabled={disabledMapping(pageMode, 'reportState')}
                registerName="reportState"
                label="敲價單狀態"
                options={autoCompleteReportPriceState}
              />
            </div>
            <div>
              <AutoCompleteField
                disabled={disabledMapping(pageMode, 'reportUser')}
                registerName='reportUser'
                label='敲價人員'
                required
                options={autoCompleteOptionsSales}
              />
            </div>
            <div>
              <AutoCompleteField
                disabled={disabledMapping(pageMode, 'sales')}
                registerName='sales'
                label='業務人員'
                required
                options={autoCompleteOptionsSales}
              />
            </div>
            <div>
              <AutoCompleteField
                disabled={disabledMapping(pageMode, 'checkUser')}
                registerName='checkUser'
                label='核價人員'
                options={autoCompleteOptionsSales}
                required={pageMode === SPEditPageMode.APPROVE}
              />
            </div>
            <div>
              <InputField
                disabled={disabledMapping(pageMode, 'backNote')}
                registerName="backNote"
                label="退回備註"
                required={pageMode === SPEditPageMode.APPROVE && rs === '2'}
              />
            </div>
            <div>
              <InputField
                disabled={disabledMapping(pageMode, 'reportQty')}
                registerName="reportQty"
                label="敲價數量(G)"
                type="number"
              />
            </div>
          </div>
        </div>

        <BaseField name="files" label="附件">
          <Upload
            disabled={disabledMapping(pageMode, 'files')}
            accept="image/*"
            action={`${environment.API_HOST}/misc/file/upload`}
            headers={{ apikey: environment.API_KEY }}
            fileList={files}
            onRemove={onRemove}
            onChange={({ file, fileList }) => {
              formMethods.setValue('files', fileList);
            }}>
            <Button
              variant="outlined"
              prefix={<Icon icon={UploadIcon} />}
              disabled={disabledMapping(pageMode, 'files')}
            >
              上傳附件
            </Button>
          </Upload>
        </BaseField>
      </FormFieldsWrapper>
    </div>
  );
};

export default SPOrderForm;