import {
  checkJWTRoles,
  IncomingPurchaseOrder,
  useGetIQCPurchaseOrderReturnable,
} from '@solar/data';
import {
  QuantityWithUnitsColumn,
  useDraggableColumns,
  useTargetModal,
} from '@solar/templates';
import { useCallback, useMemo, useRef } from 'react';
import { Button, Table } from '@mezzanine-ui/react';
import { Space } from 'antd';
import { TableColumn } from '@mezzanine-ui/core/table';
import { RETURN_STORED_LOADER_RECORD_MODAL } from './ReturnStoredLoaderRecordModal';

interface StoredLoaderRecordsTableProps {
  iqcOrderId: string;
}

export function StoredLoaderRecordsTable({
  iqcOrderId,
}: StoredLoaderRecordsTableProps) {
  const tableRef = useRef<HTMLTableElement>(null);

  const { iqcPurchaseOrdersReturnable, loading } =
    useGetIQCPurchaseOrderReturnable({
      iqcOrderId,
    });

  const { openModal } = useTargetModal(RETURN_STORED_LOADER_RECORD_MODAL);

  const defaultColumns = useMemo(
    () =>
      [
        {
          width: 120,
          title: '操作',
          render: (source) => (
            <Space>
              {checkJWTRoles([153]) && (
                <Button
                  danger
                  variant="contained"
                  onClick={() => openModal(source)}
                >
                  退貨
                </Button>
              )}
            </Space>
          ),
        },
        {
          dataIndex: 'materialId',
          title: '料號',
        },
        {
          width: 200,
          title: '批號',
          render: (source) =>
            `${source?.materialSapBatchId}-${source?.materialBatchId}`,
        },
        {
          width: 120,
          dataIndex: 'loaderId',
          title: '載具',
        },
        {
          width: 150,
          dataIndex: 'shelfId',
          title: '儲位',
        },
        {
          width: 250,
          title: '數量',
          render: (source) => (
            <QuantityWithUnitsColumn
              quantity={source?.quantity}
              materialInputUnit={source?.materialInputUnit}
              materialStockUnit={source?.materialStockUnit}
              stockOverInputUnitRatio={source?.stockOverInputUnitRatio}
            />
          ),
        },
        {
          width: 200,
          dataIndex: 'actualMaterialWeightUnitQuantity',
          title: '實際重量',
        },
        {
          dataIndex: 'remark',
          title: '備註',
        },
      ] as TableColumn<
        IncomingPurchaseOrder['sapBatch']['subBatches'][number]['storedLoaderRecords'][number] & {
          iqcOrderId: string;
          materialInputUnit: string;
          materialStockUnit: string;
          stockOverInputUnitRatio: string;
          remark: string;
        }
      >[],
    [openModal]
  );

  const columns = useDraggableColumns(tableRef, defaultColumns);

  const dataSource = useMemo(() => {
    const targetOrder = iqcPurchaseOrdersReturnable?.[0];

    if (!targetOrder) return [];

    return targetOrder?.sapBatch?.subBatches?.flatMap((subBatch) =>
      subBatch?.storedLoaderRecords?.map((record) =>
        Object.assign(record, {
          iqcOrderId: targetOrder?.id,
          materialInputUnit:
            targetOrder?.inventoryPurchaseOrderSpec?.materialInputUnit,
          materialStockUnit:
            targetOrder?.inventoryPurchaseOrderSpec?.materialStockUnit,
          stockOverInputUnitRatio:
            targetOrder?.inventoryPurchaseOrderSpec?.stockOverInputUnitRatio,
          remark: subBatch?.remark,
        })
      )
    );
  }, [iqcPurchaseOrdersReturnable]);

  return (
    <Table
      ref={tableRef}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
    />
  );
}
