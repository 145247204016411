import { Message } from '@mezzanine-ui/react';
import { useCallback, useMemo, useState } from 'react';
import useSWR from 'swr';
import { dateFormat, FieldsType } from '../../humanResources';
import { API_NAMESPACE, request } from '../../request';

const namespace = API_NAMESPACE.HR;

export enum ERFCState {
  IDLE = 'idle',
  BUSY = 'busy',
}

export function useShipmentPackingList() {
  const [rfcState, setRfcState] = useState<ERFCState>(ERFCState.IDLE);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    perPage: 10,
  });

  const { data: apiData, mutate } = useSWR([
    '/deliveryOrders/get',
    { namespace, params: queryParams },
  ]);

  const { data: filterData } = useSWR([
    '/deliveryOrders/get/filter',
    { namespace },
  ]);

  const deliveryOrdersData = useMemo(() => apiData?.data ?? [], [apiData]);

  const handleUpdateRfc = async () => {
    const options = {
      method: 'POST',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
    };
    const json = (await request(`/deliveryOrders/rfcSync`, {
      ...options,
      namespace,
    })) as unknown as { message: string };

    if (json?.message.includes('完成')) {
      Message['success'](json.message);
    } else {
      Message['warning'](json.message);
    }
    setRfcState(ERFCState.IDLE);
  };

  const handleSearchFilter = useCallback(
    async (payload: FieldsType[]) => {
      const dateRange = payload.filter(
        (item) => item.registerName === 'orderDate'
      );
      const withOutDate = payload.filter(
        (item) => item.registerName !== 'orderDate'
      );
      const result = [
        ...withOutDate,
        {
          registerName: 'shipDateStart',
          value: dateFormat('', dateRange?.[0]?.value?.[0] ?? ''),
        },
        {
          registerName: 'shipDateEnd',
          value: dateFormat('', dateRange?.[0]?.value?.[1] ?? ''),
        },
      ];
      const mapping = [
        { registerName: 'shipDateStart', query: 'shipDateStart', isDate: true },
        { registerName: 'shipDateEnd', query: 'shipDateEnd', isDate: true },
        { registerName: 'customerName', query: 'customerId', isDate: false },
        {
          registerName: 'deliveryOrderName',
          query: 'deliveryOrderId',
          isDate: false,
        },
      ];
      const newResult = result.map((item) =>
        item.registerName === 'customerName'
          ? {
              registerName: item.registerName,
              value: item.value ? item.value.id : undefined,
            }
          : item
      );
      const newParams = newResult.reduce((acc, cur) => {
        if (cur.value !== '' && cur.value !== undefined) {
          const key =
            mapping.filter((map) => map.registerName === cur.registerName)?.[0]
              .query ?? '';
          return {
            ...acc,
            [key]: cur.value,
          };
        }
        return acc;
      }, {});
      setQueryParams((params) => ({ ...params, ...newParams, page: 1 }));
    },
    [dateFormat, setQueryParams]
  );

  const handleChangePage = useCallback(
    (currentPage: number) => {
      setQueryParams((params) => ({ ...params, page: currentPage }));
    },
    [setQueryParams]
  );

  const handleChangePerPage = useCallback(
    (pageSize: number) => {
      setQueryParams((params) => ({ ...params, perPage: pageSize, page: 1 }));
    },
    [setQueryParams]
  );

  const resetQueryParams = useCallback(() => {
    setQueryParams((query) => ({ perPage: query.perPage, page: 1 }));
  }, []);

  return {
    rfcState,
    setRfcState,
    data: deliveryOrdersData,
    total: apiData?.total ?? 0,
    filterData,
    queryParams,
    handleUpdateRfc,
    handleSearchFilter,
    mutate,
    handleChangePage,
    handleChangePerPage,
    resetQueryParams,
  };
}
