import { API_NAMESPACE, request } from '@solar/data';
import { SoftDeleteWaitReceiptPayload } from '../types';

const namespace = API_NAMESPACE.PP;

export const softDeleteWaitReceipt = ({
  payload,
}: {
  payload: SoftDeleteWaitReceiptPayload;
}): Promise<Response> => {
  return request(`/pp/wait-receipt`, {
    namespace,
    method: 'delete',
    body: JSON.stringify(payload),
    responseParser: (res) => res,
  });
};
