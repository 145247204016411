import { Button, Typography } from '@mezzanine-ui/react';
import { TreeSelect } from 'antd';
import {
  DateRangePickerField,
  FormFieldsWrapper,
  SearchInputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  FilterPayload,
  TreeSelectDataType,
  useHumanResourceMembers,
} from '@solar/data';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import styles from './HumanResourceMemberFilter.module.scss';

interface HumanResourceMemberFilterProps {
  methods: UseFormReturn<FieldValues, any>;
  filterHumanResourceMembers: (payload: FilterPayload) => Promise<void>;
  updateFilterObject: boolean;
  setUpdateFilterObject: React.Dispatch<React.SetStateAction<boolean>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  treeSelectData: TreeSelectDataType[];
  treeValue: string[] | number[];
  setTreeValue: React.Dispatch<React.SetStateAction<string[] | number[]>>;
}

export function HumanResourceMemberFilter({
  methods,
  filterHumanResourceMembers,
  updateFilterObject,
  setUpdateFilterObject,
  setPage,
  treeSelectData,
  treeValue,
  setTreeValue,
}: HumanResourceMemberFilterProps) {
  const { filterData: membersFilterOptions } = useHumanResourceMembers();

  const categoryField = methods.getValues('categoryField');
  const employeeTypeField = methods.getValues('employeeTypeField');
  const businessUnitsField = methods.getValues('businessUnitsField');
  const codeSearchInput = methods.getValues('codeSearchInput');
  const nameSearchInput = methods.getValues('nameSearchInput');
  const onboardCalendar = methods.getValues('onboardCalendar');
  const dismissCalendar = methods.getValues('dismissCalendar');

  return (
    <FormFieldsWrapper methods={methods}>
      <div className={styles.row1}>
        <div className={styles.flex}>
          <Typography variant="h6" color="secondary-light">
            職別：
          </Typography>
          <SelectField
            registerName="categoryField"
            mode="multiple"
            width={150}
            options={membersFilterOptions?.employeeCategories ?? []}
            clearable={true}
          />
        </div>
        <div className={styles.flex}>
          <Typography variant="h6" color="secondary-light">
            狀態：
          </Typography>
          <SelectField
            registerName="employeeTypeField"
            mode="multiple"
            width={150}
            options={membersFilterOptions?.employeeChangeTypes ?? []}
            clearable={true}
          />
        </div>
        <div className={styles.flex}>
          <Typography variant="h6" color="secondary-light" noWrap={true}>
            工號：
          </Typography>
          <SearchInputField registerName="codeSearchInput" width={150} />
        </div>
        <div className={styles.flex}>
          <Typography variant="h6" color="secondary-light" noWrap={true}>
            姓名：
          </Typography>
          <SearchInputField registerName="nameSearchInput" />
        </div>
        <div className={styles.flex}>
          <Typography variant="h6" color="secondary-light">
            入職：
          </Typography>
          <DateRangePickerField registerName="onboardCalendar" />
        </div>
        <div className={styles.flex}>
          <Typography variant="h6" color="secondary-light">
            離職：
          </Typography>
          <DateRangePickerField registerName="dismissCalendar" />
        </div>
      </div>
      <div className={styles.filterFlex}>
        <div className={styles.flex} style={{ width: '70%' }}>
          <Typography variant="h6" color="secondary-light">
            BU：
          </Typography>
          <SelectField
            registerName="businessUnitsField"
            mode="multiple"
            width={120}
            options={membersFilterOptions?.businessUnits ?? []}
            clearable={true}
          />
          <Typography
            variant="h6"
            color="secondary-light"
            style={{ marginLeft: 16 }}
          >
            所屬部門：
          </Typography>
          <TreeSelect
            showSearch
            allowClear
            multiple
            showArrow
            placeholder={'請選擇'}
            bordered={false}
            style={{ flex: 1 }}
            className={styles.treeSelect}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={treeSelectData}
            value={treeValue}
            showCheckedStrategy={'SHOW_ALL'}
            onChange={(e) => {
              setTreeValue(e);
              methods.setValue('treeSelect', e);
            }}
          />
        </div>
        <Button
          type="button"
          className={styles.filterButton}
          variant="contained"
          onClick={() => {
            setUpdateFilterObject(!updateFilterObject);
            filterHumanResourceMembers({
              category: categoryField,
              employeeType: employeeTypeField,
              BU: businessUnitsField,
              code: codeSearchInput,
              name: nameSearchInput,
              onBoard: onboardCalendar,
              dismiss: dismissCalendar,
              unitPath: treeValue,
              page: 1,
            });
            setPage(1);
          }}
        >
          送出篩選條件
        </Button>
      </div>
    </FormFieldsWrapper>
  );
}
