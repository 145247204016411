import { useCallback, useState } from 'react';
import useSWR from 'swr';
import { GetRepairRequestsParams, RepairRequest } from './repairRequest.type';
import { PageInfo } from '../pagination';

export function useGetRepairRequests() {
  const [params, setParams] = useState<GetRepairRequestsParams>({});
  const { data, error, mutate } = useSWR<{
    repairRequests: RepairRequest[];
    pageInfo: PageInfo;
  }>(params ? ['/repair-requests', { params }] : null);

  const fetchRepairRequests = useCallback(
    (nextParams: GetRepairRequestsParams) => setParams(nextParams),
    []
  );

  return {
    repairRequests: data?.repairRequests ?? [],
    pageInfo: data?.pageInfo,
    isLoading: !error && !data,
    fetchRepairRequests,
    mutateGetRepairRequests: mutate,
  };
}
