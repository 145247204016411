import type { ProductionPlanningOrdersExportFormValues } from '@solar/data';
import React from 'react';
import { PPOrdersExportFormFieldName as FormFieldName } from '@solar/data';
import { useFormContext } from 'react-hook-form';
import { InputField } from '@mezzanine-ui/react-hook-form';

type Props = {
  styles?: {
    label?: React.ComponentProps<'div'>['style'];
    field?: React.ComponentProps<'div'>['style'];
  };
};

export function MaterialIdInput({ styles }: Props) {
  const formMethods =
    useFormContext<ProductionPlanningOrdersExportFormValues>();

  return (
    <>
      <div style={{ ...styles?.label, height: '32px', lineHeight: '32px' }}>
        料號：
      </div>
      <div style={styles?.field}>
        <InputField
          control={formMethods.control}
          registerName={FormFieldName.MATERIAL_ID}
        />
      </div>
    </>
  );
}
