import { useForm } from 'react-hook-form';
import { PaginationTable } from '../PaginationTable/PaginationTable';

import {
  Button,
  MenuDivider,
  SelectValue,
  Typography,
} from '@mezzanine-ui/react';
import { FormFieldsWrapper } from '@mezzanine-ui/react-hook-form';
import {
  getNextParams,
  NextPagination,
  useMiscMaterialBatches,
} from '@solar/data';
import {
  FilterScaffold,
  LocationSelectorGroupModal,
  LocationSelectorProvider,
  LOCATION_SELECTOR,
  ModalGroup,
  useLocationSelectorController,
  useModalGroupController,
} from '@solar/templates';
import { PageLayout } from '../PageLayout/PageLayout';
import { Filter } from './Filter';

import { useCallback, useRef } from 'react';
import { useColumns } from './hooks';
import { Dispatch } from './modals/Dispatch';
import { Scrap } from './modals/Scrap';
import { Modals } from './types';

type FilterForm = {
  groups: SelectValue | undefined;
  categories: SelectValue | undefined;
  subCategories: SelectValue | undefined;
  materialId: string;
  description: string;
};

export function MiscellaneousManagePage() {
  const filterMethods = useForm<FilterForm>();
  const prevModalNameRef = useRef<Modals | null>(null);

  // table data
  const {
    miscMaterialBatches,
    isLoading,
    pageInfo,
    setParams: mutateParams,
    mutate,
  } = useMiscMaterialBatches();

  const modalGroupController = useModalGroupController([
    { name: Modals.DISPATCH, keepData: true },
    { name: Modals.SCRAP, keepData: true },
    { name: LOCATION_SELECTOR },
  ]);

  // 地圖選擇器
  const locationSelectorController = useLocationSelectorController({
    resetAfterClosing: false,
  });
  const svgMapController = useLocationSelectorController({
    resetAfterClosing: false,
  });

  const columns = useColumns({ modalGroupController, prevModalNameRef });

  const refetchMiscMaterials = useCallback(
    (nextPagination: NextPagination) => {
      const filterState = filterMethods.getValues();
      const { offset, limit } = getNextParams(nextPagination, pageInfo);

      mutateParams({
        offset,
        limit,
        groupId: filterState?.groups?.id ?? undefined,
        categoryId: filterState?.categories?.id ?? undefined,
        subCategoryId: filterState?.categories?.id ?? undefined,
        materialId: filterState?.materialId ?? undefined,
        description: filterState?.description ?? undefined,
      });
    },
    [filterMethods]
  );

  return (
    <>
      <PageLayout>
        <Typography variant="h1">雜項管理</Typography>
        <MenuDivider />
        <FormFieldsWrapper methods={filterMethods}>
          <FilterScaffold
            mutateParams={() => refetchMiscMaterials({ nextPage: 1 })}
            fetchAsParamsMutated={false}
          >
            <Filter />
          </FilterScaffold>
        </FormFieldsWrapper>
        <div>
          <Button
            variant="outlined"
            type="button"
            onClick={() => refetchMiscMaterials({ nextPage: 1 })}
          >
            搜尋
          </Button>
        </div>

        <PaginationTable
          loading={isLoading}
          columns={columns}
          dataSource={miscMaterialBatches ?? []}
          pageInfo={pageInfo}
          mutateParams={refetchMiscMaterials}
          scroll={{ x: 1500 }}
        />
      </PageLayout>
      <LocationSelectorProvider controller={locationSelectorController}>
        <ModalGroup {...modalGroupController}>
          <Dispatch
            locationSelectorController={locationSelectorController}
            refetch={mutate}
          />
          <Scrap
            locationSelectorController={locationSelectorController}
            refetch={mutate}
          />
          <LocationSelectorGroupModal
            controller={svgMapController}
            closeModal={() => {
              const prevModal = modalGroupController.getModalProps(
                prevModalNameRef.current ?? ''
              );

              modalGroupController.openModal(
                prevModalNameRef.current ?? '',
                prevModal?.data
              );
            }}
            showShelfSvg={true}
            onSubmit={(activeId, selectedIds) => {
              locationSelectorController.setSelectedIds(selectedIds ?? {});

              const prevModal = modalGroupController.getModalProps(
                prevModalNameRef.current ?? ''
              );

              modalGroupController.openModal(
                prevModalNameRef.current ?? '',
                prevModal?.data
              );
            }}
          />
        </ModalGroup>
      </LocationSelectorProvider>
    </>
  );
}
