import {
  Button,
  Message,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@mezzanine-ui/react';
import { FormFieldsWrapper, InputField } from '@mezzanine-ui/react-hook-form';
import {
  ResponseStatus,
  useHumanResourceBUReportExportList,
} from '@solar/data';
import { useForm } from 'react-hook-form';
import styles from './Modals.module.scss';

type ModalProps = {
  baseYear: string;
  bonusConditions: string[];
  onClose: () => void;
};

export function ExportModal({
  onClose,
  baseYear,
  bonusConditions,
}: ModalProps) {
  const methods = useForm();
  const { exportMutation: exportHumanResourceBUReportExportList } =
    useHumanResourceBUReportExportList();

  const onExport = async () => {
    const nameInput = methods.getValues('inputField');
    const res = await exportHumanResourceBUReportExportList({
      baseYear,
      name: nameInput,
      bonusConditions,
    });

    if (res === ResponseStatus.SUCCESS) {
      Message.success('成功送出');
    } else {
      Message.error('失敗，請再試一次');
    }

    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      open={true}
      style={{ width: 576 }}
      className={styles.modal}
    >
      <FormFieldsWrapper methods={methods}>
        <ModalHeader>匯出報表</ModalHeader>
        <ModalBody>
          <InputField
            label="報表名稱"
            registerName="inputField"
            fullWidth
            size="large"
          />
        </ModalBody>
        <ModalFooter className={styles.footer}>
          <Button
            type="button"
            variant="outlined"
            className={styles.leftButton}
            onClick={onClose}
          >
            取消
          </Button>
          <Button type="button" variant="contained" onClick={onExport}>
            匯出
          </Button>
        </ModalFooter>
      </FormFieldsWrapper>
    </Modal>
  );
}
