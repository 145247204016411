import useSWR from 'swr';
import { FormTemplateResponse } from '../../FormTemplate/typing';
import { API_NAMESPACE } from '@solar/data';
import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';
import { useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ProductionFormCreatorFormFields } from '../typings';

export function useAutoCompleteFormTemplateField({
  methods,
  disabled,
  enabled,
}: {
  methods: UseFormReturn<ProductionFormCreatorFormFields>;
  disabled?: boolean;
  enabled?: boolean;
}): JSX.Element {
  const { data } = useSWR<FormTemplateResponse[]>(
    enabled ? ['/form-templates', { namespace: API_NAMESPACE.PP }] : undefined
  );

  const templates = useMemo(
    () =>
      (data ?? []).map((template) => ({
        id: template.id,
        name: `${template.name} v${template.versions[0].version} [${template.versions[0].fields.type}]`,
      })),
    [data]
  );

  const formTemplateId = methods.watch('formTemplateId');

  const { setValue } = methods;

  useEffect(() => {
    if (formTemplateId) {
      const selectedTemplate = (data ?? []).find(
        (template) => template.id === formTemplateId.id
      );

      if (selectedTemplate) {
        setValue('title', selectedTemplate.name);
        setValue('suffix', selectedTemplate.suffix);
        setValue('formTemplateVersion', selectedTemplate.versions[0].version);
      }
    }
  }, [formTemplateId, data, setValue]);

  return (
    <AutoCompleteField
      fullWidth
      disabled={disabled}
      required
      options={templates}
      label="題型"
      registerName="formTemplateId"
    />
  );
}
