import styles from './TargetNumber.module.scss';
import { Button, ButtonGroup, Typography, Message } from '@mezzanine-ui/react';
import { TargetRuleCreateBasics } from './components/TargetRuleCreate/TargetRuleCreateBasics';
import { TargetRuleCreateTable } from './components/TargetRuleCreate/TargetRuleCreateTable';
import { TargetRuleCreateApplyMaterial } from './components/TargetRuleCreate/TargetRuleCreateApplyMaterial';
import { FormProvider, useForm } from 'react-hook-form';
import {
  RuleOption,
  TargetRuleCreateFormFields,
  TargetRuleEditFormFields,
} from './TargetNumber.interface';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import {
  CodeRule,
  Parameter,
  createTargetCodeRule,
  updateTargetCodeRule,
  updateTargetRuleCustomer,
  updateTargetRuleMaterial,
  useGetTargetNumberRuleById,
  useGetTargetRuleCustomers,
} from '@solar/data';
import { Breadcrumb } from 'antd';

interface CustomerType {
  id: string;
  customer_id: string;
  customer_name: string;
  customer_short_name: string;
  is_disabled: boolean;
}

interface Material {
  number_rule_id: number;
  material_id: string;
  is_disabled: boolean;
  created_at: string;
  updated_at: string;
  number_rule_material_id: number;
  description: string;
}

interface ParameterOptions {
  [key: string]: { option_value: string; option_name: string }[];
}

const ruleType = [
  { id: 'internal', name: '光洋' },
  { id: 'customer', name: '客戶' },
];

export function TargetRuleCreatePage() {
  const { NumberRuleId } = useParams<{ NumberRuleId?: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const isViewOnly = location.pathname.includes('view-only');
  const isEditing = !!NumberRuleId && !isViewOnly;
  const [ruleNameForTitle, setRuleNameForTitle] = useState('');
  const [ruleTypeForDisplay, setRuleTypeForDisplay] = useState('');
  const showBreadcrumb =
    location.pathname !== '/target-number/number-rules/create';

  const [processingComplete, setProcessingComplete] = useState(false);
  const processingCompleteRef = useRef(false);

  const { targetNumberRule } = useGetTargetNumberRuleById(NumberRuleId || '');
  const materialIdForViewOnly =
    targetNumberRule?.materials.filter(
      (material: Material) => !material.is_disabled
    ) || [];

  const isUsed = targetNumberRule?.status === 'used';

  const [ruleTypeOptions, setRuleTypeOptions] = useState<RuleOption[] | null>(
    null
  );
  const [customerIds, setCustomerIds] = useState<string[]>([]);

  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [resetCriterionCodeRule, setResetCriterionCodeRule] = useState('');

  const [applyCustomerIsEmpty, setApplyCustomerIsEmpty] = useState(true);

  const handleApplyCustomerChange = (isEmpty: boolean) => {
    setApplyCustomerIsEmpty(isEmpty);
  };

  const methods = useForm<TargetRuleEditFormFields>({
    defaultValues: {
      ruleName: '',
      ruleType: { id: 'customer', name: '客戶' },
      validDateStart: '',
      validDateEnd: '',
      applyCustomer: [],
      codeRules: [],
      materialIds: [],
      resetCriterion: 'nope',
    },
  });
  const { targetRuleCustomers } = useGetTargetRuleCustomers('') || [];

  useEffect(() => {
    if (
      targetNumberRule &&
      (isEditing || isViewOnly) &&
      !initialDataLoaded &&
      targetRuleCustomers
    ) {
      if (targetNumberRule.status === 'disabled') {
        navigate('/target-number/number-rules/management');
        return;
      }

      const selectedCustomers = targetNumberRule.customers
      .filter((customer: CustomerType) => !customer.is_disabled)
      .map(
        (customer: CustomerType) => {
          const customerDetail = targetRuleCustomers.find(
            (c: CustomerType) => c.id === customer.customer_id
          );
          return {
            id: customer.customer_id,
            name: customerDetail ? customerDetail.name : 'Unknown',
          };
        }
      );

      const resetCriterionCodeRuleFun =
        targetNumberRule.code_rules.find(
          (codeRule: CodeRule) => codeRule.is_reset_criterion
        )?.code_rule_id || 'nope';
      setResetCriterionCodeRule(resetCriterionCodeRuleFun.toString());

      const sortedCodeRules = targetNumberRule.code_rules.sort(
        (a: { code_rule_id: number }, b: { code_rule_id: number }) =>
          a.code_rule_id - b.code_rule_id
      );

      const parameterOptionsTemplate: ParameterOptions = {
        '5': [
          { option_value: '1', option_name: '1碼' },
          { option_value: '2', option_name: '2碼' },
          { option_value: '4', option_name: '4碼' },
        ],
        '6': [
          { option_value: '1', option_name: '1碼' },
          { option_value: '2', option_name: '2碼' },
          { option_value: '3', option_name: '3碼' },
        ],
        '18': [
          { option_value: '1', option_name: '1碼' },
          { option_value: '2', option_name: '2碼' },
          { option_value: '4', option_name: '4碼' },
        ],
        '19': [
          { option_value: '1', option_name: '1碼' },
          { option_value: '2', option_name: '2碼' },
          { option_value: '3', option_name: '3碼' },
        ],
        '20': [
          { option_value: '1-7', option_name: '1-7' },
          { option_value: '1-5', option_name: '1-5' },
          { option_value: 'A/B', option_name: 'A/B Side' },
          { option_value: 'ABC', option_name: 'ABC' },
        ],
      };

      const codeRulesForForm = sortedCodeRules.map((codeRule: CodeRule) => ({
        code_rule_id: codeRule.code_rule_id,
        is_enable: !codeRule.is_disabled,
        seq: codeRule.seq,
        parameters: codeRule.parameters
          ? codeRule.parameters
              .filter(
                (param): param is Parameter =>
                  param !== null && param !== undefined
              )
              .map((param: Parameter) => {
                const parameterOption = ruleTypeOptions
                  ?.find((ro) => ro.code_rule_id === codeRule.code_rule_id)
                  ?.parameters.find(
                    (p) => p.code_parameter_id === param.code_parameter_id
                  );

                const parameterIdAsString = param.code_parameter_id.toString();
                const optionsForParameter =
                  parameterOptionsTemplate[parameterIdAsString] || [];
                const selectedOption = optionsForParameter.find(
                  (option) => option.option_value === param.value
                );

                if (parameterOption?.data_type === 'options') {
                  return {
                    id: param.code_parameter_id,
                    value: selectedOption
                      ? {
                          id: selectedOption.option_value,
                          name: selectedOption.option_name,
                        }
                      : null,
                    options: optionsForParameter,
                    code_parameter_id: parameterIdAsString,
                  };
                } else {
                  return {
                    id: param.code_parameter_id,
                    value: param.value,
                    code_parameter_id: parameterIdAsString,
                  };
                }
              })
          : [],
      }));

      const { materials } = targetNumberRule;

      const materialIds = materials
        .filter((material: Material) => !material.is_disabled)
        .map((material: Material) => ({ materialId: material.material_id }));

      const formValues = {
        ruleName: targetNumberRule.number_rule_name,
        ruleType: ruleType.find(
          (option) => option.id === targetNumberRule.type
        ),
        validDateStart: targetNumberRule.start_date,
        validDateEnd: targetNumberRule.end_date || '',
        applyCustomer: selectedCustomers,
        codeRules: codeRulesForForm,
        materialIds: materialIds,
        resetCriterion: resetCriterionCodeRule,
      };

      methods.reset({
        ...formValues,
      });

      setInitialDataLoaded(true);
      setProcessingComplete(true);
      setRuleNameForTitle(targetNumberRule.number_rule_name);
      setRuleTypeForDisplay(targetNumberRule.type);
    }
  }, [
    isEditing,
    isViewOnly,
    targetNumberRule,
    initialDataLoaded,
    methods,
    targetRuleCustomers,
    ruleTypeOptions,
    resetCriterionCodeRule,
    navigate,
  ]);

  useEffect(() => {
    if (processingComplete && !processingCompleteRef.current) {
      setInitialDataLoaded(false);
      processingCompleteRef.current = true;
    }
  }, [processingComplete]);

  const handleRuleTypeData = (data: RuleOption[] | undefined) => {
    if (data) {
      const sortedData = data.sort((a, b) => a.code_rule_id - b.code_rule_id);
      setRuleTypeOptions(sortedData);
    }
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  function generateMaterialsPayload(
    currentSelectedMaterialIds: string[],
    originalMaterials: { material_id: string; is_disabled: boolean }[]
  ): { material_id: string; is_disabled: boolean }[] {
    const originalMaterialsMap = originalMaterials.reduce((acc, material) => {
      acc[material.material_id] = material;
      return acc;
    }, {} as Record<string, { material_id: string; is_disabled: boolean }>);

    const deselectedMaterials = originalMaterials
      .filter(
        (material) =>
          !currentSelectedMaterialIds.includes(material.material_id) &&
          !material.is_disabled
      )
      .map((material) => ({
        material_id: material.material_id,
        is_disabled: true,
      }));

    const selectedMaterials = currentSelectedMaterialIds
      .map((materialId) => {
        const originalMaterial = originalMaterialsMap[materialId];
        if (!originalMaterial || originalMaterial.is_disabled) {
          return {
            material_id: materialId,
            is_disabled: false,
          };
        }
        return null;
      })
      .filter(
        (material): material is { material_id: string; is_disabled: boolean } =>
          material !== null
      );

    return [...deselectedMaterials, ...selectedMaterials];
  }

  function generateCustomersPayload(
    currentSelectedCustomerIds: string[],
    originalCustomers: { customer_id: string; is_disabled: boolean }[]
  ): { customer_id: string; is_disabled: boolean }[] {
    const originalCustomersMap = originalCustomers.reduce((acc, customer) => {
      acc[customer.customer_id] = customer;
      return acc;
    }, {} as Record<string, { customer_id: string; is_disabled: boolean }>);

    const deselectedCustomers = originalCustomers
      .filter(
        (customer) =>
          !currentSelectedCustomerIds.includes(customer.customer_id) &&
          !customer.is_disabled
      )
      .map((customer) => ({
        customer_id: customer.customer_id,
        is_disabled: true,
      }));

    const selectedCustomers = currentSelectedCustomerIds
      .map((customerId) => {
        const originalCustomer = originalCustomersMap[customerId];
        if (!originalCustomer || originalCustomer.is_disabled) {
          return {
            customer_id: customerId,
            is_disabled: false,
          };
        }
        return null;
      })
      .filter(
        (customer): customer is { customer_id: string; is_disabled: boolean } =>
          customer !== null
      );

    return [...deselectedCustomers, ...selectedCustomers];
  }

  const onSubmit = async (data: TargetRuleCreateFormFields) => {
    const sequenceNumbers = data.codeRules
      ?.filter((codeRule) => codeRule !== null)
      .map((codeRule) => codeRule.seq);

    const sequenceNumbersWithoutInvalids = sequenceNumbers.filter(
      (seq) => seq !== null && seq !== undefined && seq.toString() !== ''
    );
    const hasDuplicates =
      new Set(sequenceNumbersWithoutInvalids).size !==
      sequenceNumbersWithoutInvalids.length;

    if (sequenceNumbersWithoutInvalids.length === 0) {
      Message.error('沒有提供任何資料或碼序，請檢查後再送出');
      return;
    }

    if (hasDuplicates) {
      Message.error('有碼序重複了，請檢查後再送出');
      return;
    }

    const customerIds = data.applyCustomer.map((customer) => customer.id);
    if (customerIds.length === 0 && data.ruleType.id !== 'internal') {
      Message.error('請至少選擇一位客戶');
      return;
    }

    const materialIds = data.materialIds.map((material) => material.materialId);
    if (materialIds.length === 0) {
      Message.error('請至少選擇一種料號');
      return;
    }

    try {
      const backendPayload = {
        number_rule_name: data.ruleName,
        rule_type: data.ruleType.id,
        start_date: formatDate(data.validDateStart),
        end_date: data.validDateEnd ? formatDate(data.validDateEnd) : undefined,
        customer_ids: data.applyCustomer.map((customer) => customer.id),
        code_rules:
          data.codeRules
            ?.filter((codeRule) => codeRule !== null)
            .map((codeRule) => ({
              code_rule_id: Number(codeRule.code_rule_id),
              seq: codeRule.seq ? Number(codeRule.seq) : undefined,
              is_disabled: !codeRule.is_enable,
              is_reset_criterion:
                codeRule.code_rule_id === Number(data.resetCriterion) ||
                codeRule.code_rule_id === data.resetCriterion
                  ? true
                  : false,
              parameters:
                codeRule.parameters &&
                codeRule.parameters.some(
                  (param) =>
                    param.value !== undefined &&
                    param.value !== '' &&
                    param.value !== null
                )
                  ? codeRule.parameters.map(({ code_parameter_id, value }) => ({
                      code_parameter_id: parseInt(code_parameter_id, 10),
                      value:
                        value === '' || value === null || value === undefined
                          ? undefined
                          : value && typeof value === 'object' && 'id' in value
                          ? value.id
                          : typeof value === 'string' ||
                            typeof value === 'number' ||
                            typeof value === 'boolean'
                          ? value
                          : String(value),
                    }))
                  : [],
            })) ?? [],
        material_ids: data.materialIds.map((material) => material.materialId),
      };

      const currentSelectedMaterialIds = data.materialIds.map(
        (material) => material.materialId
      );
      const materialsPayload = generateMaterialsPayload(
        currentSelectedMaterialIds,
        targetNumberRule?.materials || []
      );

      const currentSelectedCustomerIds = data.applyCustomer.map(
        (customer) => customer.id
      );
      const customerIdsPayload = generateCustomersPayload(
        currentSelectedCustomerIds,
        targetNumberRule?.customers || []
      );

      const backendPayloadForCustomerPost = {
        customers: customerIdsPayload,
      };

      const backendPayloadForMaterialPost = {
        materials: materialsPayload,
      };

      const backendPayloadForPUT = {
        end_date: data.validDateEnd ? formatDate(data.validDateEnd) : undefined,
        customer_ids: data.applyCustomer.map((customer) => customer.id),
        code_rules:
          data.codeRules
            ?.filter((codeRule) => codeRule !== null)
            .map((codeRule) => ({
              code_rule_id: Number(codeRule.code_rule_id),
              seq: codeRule.seq ? Number(codeRule.seq) : undefined,
              is_disabled: !codeRule.is_enable,
              is_reset_criterion:
                codeRule.code_rule_id === Number(data.resetCriterion) ||
                codeRule.code_rule_id === data.resetCriterion
                  ? true
                  : false,
              parameters:
                codeRule.parameters &&
                codeRule.parameters.some(
                  (param) =>
                    param.value !== undefined &&
                    param.value !== '' &&
                    param.value !== null
                )
                  ? codeRule.parameters.map(({ code_parameter_id, value }) => ({
                      code_parameter_id: parseInt(code_parameter_id, 10),
                      value:
                        value === '' || value === null || value === undefined
                          ? undefined
                          : value && typeof value === 'object' && 'id' in value
                          ? value.id
                          : typeof value === 'string' ||
                            typeof value === 'number' ||
                            typeof value === 'boolean'
                          ? value
                          : String(value),
                    }))
                  : [],
            })) ?? [],
        materials: materialsPayload,
      };

      console.log('Submitted Form Data:', data);

      if (isUsed && isEditing) {
        try {
          if (customerIdsPayload.length > 0 && materialsPayload.length > 0) {
            await updateTargetRuleCustomer({
              id: NumberRuleId,
              body: backendPayloadForCustomerPost,
            });
            await updateTargetRuleMaterial({
              id: NumberRuleId,
              body: backendPayloadForMaterialPost,
            });
            Message.success('已使用規則之客戶與料號更新成功');
          } else if (materialsPayload.length > 0) {
            await updateTargetRuleMaterial({
              id: NumberRuleId,
              body: backendPayloadForMaterialPost,
            });
            Message.success('已使用規則之料號更新成功');
          } else if (customerIdsPayload.length > 0) {
            await updateTargetRuleCustomer({
              id: NumberRuleId,
              body: backendPayloadForCustomerPost,
            });
            Message.success('已使用規則之客戶更新成功');
          }
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } catch (error) {
          console.error('Update Error:', error);
          Message.error('已使用規則之更新失敗');
        }
      } else if (isEditing) {
        try {
          await updateTargetCodeRule({
            id: NumberRuleId,
            body: backendPayloadForPUT,
          });
          Message.success('更新成功');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          // console.log('backendPayloadForPUT', backendPayloadForPUT);
        } catch (error) {
          console.error('Update Error:', error);
          Message.error('更新失敗');
        }
      } else {
        try {
          await createTargetCodeRule({ body: backendPayload });
          navigate('/target-number/number-rules/management');
          // console.log('backendPayload', backendPayload);
          Message.success('新增成功');
        } catch (error) {
          console.error('Creation Error:', error);
          Message.error('新增失敗');
        }
      }
      // console.log('Backend Payload:', backendPayload);
    } catch (error) {
      console.error('Submit Error:', error);
      Message.error('發生錯誤');
    }
  };

  useEffect(() => {
    if (location.pathname === '/target-number/number-rules/create') {
      const hasReloaded = localStorage.getItem('hasReloaded');

      if (hasReloaded !== 'true') {
        localStorage.setItem('hasReloaded', 'true');
        window.location.reload();
      }
    } else {
      localStorage.setItem('hasReloaded', 'false');
    }
  }, [location.pathname]);

  return (
    <FormProvider {...methods}>
      <div className={styles.container}>
        {showBreadcrumb && (
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/target-number/number-rules/management">
                靶編規則管理
              </Link>
            </Breadcrumb.Item>
            {isEditing && <Breadcrumb.Item>靶編規則編輯</Breadcrumb.Item>}
            {isViewOnly && (
              <Breadcrumb.Item> 靶編規則: {ruleNameForTitle} </Breadcrumb.Item>
            )}
          </Breadcrumb>
        )}
        <br />
        <Typography variant="h1">
          {isEditing
            ? '靶編規則編輯'
            : isViewOnly
            ? `靶編規則: ${ruleNameForTitle}`
            : '靶編規則建立'}
        </Typography>
        <TargetRuleCreateBasics
          onData={handleRuleTypeData}
          setCustomerIds={setCustomerIds}
          isViewOnly={isViewOnly}
          isEditing={isEditing}
          onApplyCustomerChange={handleApplyCustomerChange}
          isUsed={isUsed}
        />
        <TargetRuleCreateTable
          customerIds={customerIds}
          ruleTypeOptions={ruleTypeOptions}
          resetCriterion={resetCriterionCodeRule}
          isViewOnly={isViewOnly}
          targetNumberRule={targetNumberRule}
          isUsed={isUsed}
        />
        {isEditing && !initialDataLoaded ? null : (
          <TargetRuleCreateApplyMaterial
            customerIds={customerIds}
            isViewOnly={isViewOnly}
            isUsed={isUsed}
            ruleTypeForDisplay={ruleTypeForDisplay}
            materialIdForViewOnly={materialIdForViewOnly}
            applyCustomerIsEmpty={applyCustomerIsEmpty}
          />
        )}
        <ButtonGroup className={styles.buttonGroup}>
          <Link to="/target-number/number-rules/management">
            <Button variant="text">{isViewOnly ? '返回' : '取消'}</Button>
          </Link>
          {!isViewOnly && (
            <Button
              variant="contained"
              onClick={methods.handleSubmit(onSubmit)}
            >
              {isEditing ? '更新' : '新增'}
            </Button>
          )}
        </ButtonGroup>
      </div>
    </FormProvider>
  );
}
