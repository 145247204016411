import { Tab, TabPane, Tabs } from '@mezzanine-ui/react';
import { useEffect } from 'react';

import { createSearchParams, useSearchParams } from 'react-router-dom';
import { PageLayout } from '../../Material/PageLayout/PageLayout';
import { tabIds, tabsOptions } from './const';

export const OutsourcePurchaseOrderDetailPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('tab') || undefined;

  useEffect(() => {
    if (!tabIds.includes(activeTab ?? '')) {
      setSearchParams(createSearchParams({ tab: tabIds[0] }));
    }
  }, [activeTab, setSearchParams]);

  return (
    <PageLayout
      setGoBack
      goBackPath="/production-planning/outsource-purchase-order-management"
    >
      <Tabs
        activeKey={activeTab}
        onChange={(tabId) => {
          setSearchParams(createSearchParams({ tab: String(tabId) }));
        }}
      >
        {tabsOptions.map((option) => (
          <TabPane key={option.id} tab={<Tab>{option.name}</Tab>}>
            <option.panel />
          </TabPane>
        ))}
      </Tabs>
    </PageLayout>
  );
};
