import { Typography } from '@mezzanine-ui/react';
import styles from './MainFileDeleteModal.module.scss';

export interface MainFileDeleteModalProps {
  source: any;
}

export function MainFileDeleteModal({ source }: MainFileDeleteModalProps) {
  return (
    <div>
      <div className={styles.info}>
        <div className={styles.content} style={{ wordWrap: 'break-word' }}>
          <Typography variant="h6" color="text-secondary">
            產品料號 / 料號單位
          </Typography>
          <Typography>{source?.materialId}</Typography>
        </div>
        <div className={styles.fakeBorder}></div>
        <div className={styles.content}>
          <Typography variant="h6" color="text-secondary">
            產品名稱
          </Typography>
          <Typography>{source?.materialName}</Typography>
        </div>
        <div className={styles.fakeBorder}></div>
        <div className={styles.content}>
          <Typography variant="h6" color="text-secondary">
            不需等候包裝指示
          </Typography>
          <Typography>{source?.passPackingRequest === 'Y' ? '不需等候' : '需等候'}</Typography>
        </div>
      </div>
      <div className={styles.border}></div>
      <div className={styles.info}>
        <div className={styles.content}>
          <Typography variant="h6" color="text-secondary">
            包材類型
          </Typography>
          <Typography>{source?.packagingType}</Typography>
        </div>
        <div className={styles.fakeBorder}></div>
        <div className={styles.content}>
          <Typography variant="h6" color="text-secondary">
            包材名稱
          </Typography>
          <Typography>{source?.packagingName}</Typography>
        </div>
        <div className={styles.fakeBorder}></div>
        <div className={styles.content}>
          <Typography variant="h6" color="text-secondary">
            包裝數量
          </Typography>
          <Typography>{source?.limitCount}</Typography>
        </div>
      </div>
    </div>
  );
}
