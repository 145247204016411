import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { fetchUrl } from './endpoint';
import { ResponseStatus } from './useHumanResourceBuManage';

const MEMBERS = 'members';
const MEMBERS_EXPORT = 'members/export';

const fetcher = async (url: string, queryParams: string) => {
  const res = await fetch(`${url}${queryParams}`);
  const json = await res.json();

  return json ?? null;
};

const MEMBERS_FILTER = 'membersFilter';

const filterFetcher = async (url: string) => {
  const res = await fetch(url);
  const json = await res.json();

  return json ?? null;
};

export type FilterPayload = {
  category?: { id: string; name: string }[];
  employeeType?: { id: string; name: string }[];
  BU?: { id: string; name: string }[];
  code?: string;
  name?: string;
  onBoard?: string[];
  dismiss?: string[];
  unitPath?: Array<string | number>;
  page?: number;
  per_page?: number;
};

type ExportPayload = Omit<FilterPayload, 'page' | 'per_page'>;

export function useHumanResourceMembers(hasMembers: boolean = true) {
  const [data, setData] = useState<any>(undefined);
  const [loading, setLoading] = useState(true);
  const [queryParams, setQueryParams] = useState('&page=1&perPage=10');

  const url = fetchUrl(MEMBERS);
  const filter_url = fetchUrl(MEMBERS_FILTER);
  const export_url = fetchUrl(MEMBERS_EXPORT);

  const { data: swrData } = useSWR(
    hasMembers ? [url, queryParams] : null,
    fetcher,
    {
      revalidateOnMount: true,
    }
  );
  const { data: filterData } = useSWR(filter_url, filterFetcher);

  const dateFormat = (
    initState: string | undefined,
    payload?: string[],
    firstPrefix?: string,
    secondPrefix?: string
  ): (string | undefined)[] => {
    if (!payload) return [initState, initState];
    const beginDate = payload[0].split('T')[0].replace(/-/g, '/');
    const endDate = payload[1].split('T')[0].replace(/-/g, '/');

    if (firstPrefix && secondPrefix)
      return [`${firstPrefix}=${beginDate}&`, `${secondPrefix}=${endDate}&`];
    return [beginDate, endDate];
  };

  const arr_to_string = (
    initState: string,
    payload: { id: string; name: string }[] | undefined
  ) => {
    if (!payload) return initState;
    let returnState = initState;
    for (const item of payload) {
      if (returnState === initState) {
        returnState += item.id;
      } else {
        returnState += `,${item.id}`;
      }
    }

    return returnState;
  };

  const unitPath_to_query = (
    initState: string,
    payload: Array<string | number> | undefined
  ) => {
    if (!payload) return initState;
    const result = payload.join(',');
    const returnState = initState + result;
    return returnState;
  };

  const filterMutation = async (payload: FilterPayload) => {
    const initUnitId = 'unitId=';
    const unitId = unitPath_to_query(initUnitId, payload.unitPath);

    const initCategory = 'employeeCategories=';
    const category = arr_to_string(initCategory, payload.category);

    const initEmployeeType = 'employeeChangeTypes=';
    const employeeType = arr_to_string(initEmployeeType, payload.employeeType);

    const initBusinessUnits = 'businessUnits=';
    const businessUnits = arr_to_string(initBusinessUnits, payload.BU);

    const [onBoardBegin, onBoardEnd] = dateFormat(
      '',
      payload.onBoard,
      'onBoardBegin',
      'onBoardEnd'
    );
    const [dismissBegin, dismissEnd] = dateFormat(
      '',
      payload.dismiss,
      'dismissBegin',
      'dismissEnd'
    );

    const finalUnitId = unitId !== initUnitId ? `${unitId}&` : '';
    const finalCategory = category !== initCategory ? `${category}&` : '';
    const finalEmployeeType =
      employeeType !== initEmployeeType ? `${employeeType}&` : '';
    const finalBusinessUnits =
      businessUnits !== initBusinessUnits ? `${businessUnits}&` : '';
    const memberId = payload.code ? `memberId=${payload.code}&` : '';
    const name = payload.name ? `name=${payload.name}&` : '';

    const page = payload.page ? `page=${payload.page}&` : 'page=1&';
    const per_page = payload.per_page
      ? `perPage=${payload.per_page}`
      : 'perPage=10';

    setQueryParams(
      `&${finalUnitId}${finalCategory}${finalEmployeeType}${finalBusinessUnits}${memberId}${name}${onBoardBegin}${onBoardEnd}${dismissBegin}${dismissEnd}${page}${per_page}`
    );
  };

  const export_arr_to_string = (
    payload: { id: string; name: string }[] | undefined
  ) => {
    if (!payload) return;
    let returnState: undefined | string = undefined;
    for (const item of payload) {
      if (!returnState) {
        returnState = item.id;
      } else {
        returnState += `,${item.id}`;
      }
    }

    return returnState;
  };

  const exportMembers = async (payload: ExportPayload) => {
    const category = export_arr_to_string(payload.category);
    const employeeType = export_arr_to_string(payload.employeeType);
    const businessUnits = export_arr_to_string(payload.BU);

    const [onBoardBegin, onBoardEnd] = dateFormat(undefined, payload.onBoard);
    const [dismissBegin, dismissEnd] = dateFormat(undefined, payload.dismiss);

    const unitId = unitPath_to_query('', payload.unitPath);

    const headers = {
      Accept: '*/*',
      'Content-Type': 'application/json',
    };

    const body = {
      name: payload.name,
      memberId: payload.code,
      employeeCategories: category,
      employeeChangeTypes: employeeType,
      businessUnits,
      onBoardBegin,
      onBoardEnd,
      dismissBegin,
      dismissEnd,
      unitId,
    };

    const options = {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    };

    const res = await fetch(export_url, options);
    const json = await res.json();
    const filePath = json?.filePath;

    const link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('download', filePath);
    link.setAttribute('href', filePath);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    if (res.status === 200) return ResponseStatus.SUCCESS;
    return ResponseStatus.FAIL;
  };

  useEffect(() => {
    if (!swrData || typeof swrData === 'string') {
      setLoading(true);
      return;
    }
    setData(swrData);
    setLoading(false);
  }, [swrData]);

  return {
    data,
    filterData,
    loading,
    filterMutation,
    exportMembers,
  };
}
