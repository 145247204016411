import { TableDataSourceWithID } from '@mezzanine-ui/core/table';
import { API_NAMESPACE, request } from '@solar/data';
import { useMemo } from 'react';
import useSWR from 'swr';
import { ExpectedItem, UnexpectedItem } from '../typings';

export interface EquipmentMaterial extends TableDataSourceWithID {
  // id: string;
  // equipmentId: string;
  // materialRequestOrder: {
  //   id: number;
  //   workOrderId: string;
  //   creatorId: string;
  //   sourceType: 'REQUEST_ORDER';
  //   expectedCompleteAt: string;
  //   actualCompletedAt: string | null;
  //   barcode: string;
  //   createdAt: string;
  //   updatedAt: string;
  //   deletedAt: string | null;
  // };
  // inputMaterial: {
  //   id: string;
  //   description: string;
  //   mainMaterialUnitId: number;
  //   secondaryMaterialUnitId: number | null;
  //   categoryId: number;
  //   groupId: number | null;
  //   sourceId: number | null;
  //   oldMaterialId: string;
  // };
  // inputMaterialBatchId: string;
  // outputMaterialId: string;
  // outputMaterialBatchId: string;
  // mainMaterialUnitQuantity: string;
  // mainMaterialUnit: string;
  // createdAt: string;
  // deletedAt: string | null;

  materialRequestOrderId: string;
  inputMaterialId: string;
  inputMaterialSapBatchId: string;
  inputMaterialSubBatchId: string;
  inputMaterialStockUnitQuantity: string;
  inputMaterialWeightUnitQuantity: string;
  inputMaterialInputUnitQuantity: string;
  inputMaterialStockUnit: string;
  inputMaterialInputUnit: string;
  inputMaterialDescription: string;
  equipmentMaterialRecordId: number;
  outputMaterialBarcodes: Array<string>;
  stockOverInputUnitRatio: string;
}

const namespace = API_NAMESPACE.PP;

export function useEquipmentMaterials(equipmentId: string | null) {
  const { data, error, mutate, isValidating } = useSWR(
    equipmentId
      ? [`/pp/general-manufacturing/${equipmentId}`, { namespace }]
      : null
  );

  return useMemo(
    () => ({
      equipmentMaterials: (error ? {} : data) as {
        inputMaterials: EquipmentMaterial[];
        outputMaterials: EquipmentMaterial[];
        inputTotalMaterialWeightUnitQuantity: string;
      },
      error,
      isLoading: !data && !error && isValidating,
      mutateGetEquipmentMaterials: mutate,
    }),
    [data, error, isValidating, mutate]
  );
}

export function checkInMaterialsInEquipment(payload: {
  equipmentId: string;
  materialRequestOrderId: string;
  createdAt: any;
  salesOrderId: string | null;
  salesOrderLine: string | null;
  items: Array<{
    inputMaterialBarcode: string;
    inputMaterialStockUnitQuantity: string;
    inputMaterialStockUnit: string;
  }>;
}) {
  return request(`/pp/general-manufacturing/check-in`, {
    namespace,
    method: 'post',
    body: JSON.stringify(payload),
    responseParser: (res) => res,
  });
}

export function checkOutMaterialsInEquipment(payload: {
  equipmentId: string;
  materialRequestOrderId: string;
  status: 'NORMAL' | 'ABNORMAL';
  createdAt: string;
  items: Array<
    Omit<ExpectedItem, 'isExpected' | 'outputMaterialInputUnit'> & {
      outputMaterialInputUnit?: string;
    }
  >;
  unexpectedItems: Array<
    Omit<UnexpectedItem, 'isExpected' | 'outputMaterialInputUnit'> & {
      outputMaterialInputUnit?: string;
    }
  >;
  removedItems: Array<{
    recordId: number;
    outputMaterialStockUnitQuantity?: string;
    outputMaterialWeightUnitQuantity?: string;
  }>;
  syncSap: boolean;
}) {
  return request(`/pp/general-manufacturing/check-out`, {
    namespace,
    method: 'put',
    body: JSON.stringify(payload),
    responseParser: (res) => res,
  });
}

export function checkOutUnplannedMaterialsInEquipment(payload: {
  equipmentId: string;
  materialRequestOrderId: string;
  createdAt: string;
  items: Array<Omit<UnexpectedItem, 'isExpected'>>;
}) {
  return request(`/pp/general-manufacturing/check-out-secondary`, {
    namespace,
    method: 'put',
    body: JSON.stringify(payload),
    responseParser: (res) => res,
  });
}

export function clearMaterialsInEquipment({
  equipmentId,
}: {
  equipmentId: string;
}) {
  return request(`/pp/general-manufacturing/${equipmentId}`, {
    method: 'delete',
    namespace,
    responseParser: (res) => res,
  });
}

export function takeOutMaterials({
  items,
}: {
  items: {
    recordId: number;
    outputMaterialStockUnitQuantity: string;
    outputMaterialWeightUnitQuantity: string;
  }[];
}) {
  return request(`/pp/general-manufacturing/take-out`, {
    namespace,
    method: 'put',
    body: JSON.stringify({ items }),
    responseParser: (res) => res,
  });
}

export function saveAnswers(payload: {
  materialRequestOrderId: string;
  equipmentId?: string;
  answers: {
    productionFormId: string;
    answer: any;
    materialId: string;
    materialSapBatchId: string;
    materialSubBatchId: string;
  }[];
}) {
  return request(`/pp/general-manufacturing/save-answer`, {
    namespace,
    method: 'post',
    body: JSON.stringify(payload),
    responseParser: (res) => res,
  });
}
