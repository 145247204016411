import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';
import { ScrapTypeOptionsContext } from 'apps/frontend/src/app/Transportation/RequirementPage/RequirementPage';
import { BooleanStrings } from 'apps/frontend/src/app/Transportation/RequirementsManagementPage/RequirementsManagement.config';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

export function WasteTypeSelector({ className }: { className?: string }) {
  const options = useContext(ScrapTypeOptionsContext);
  const { watch } = useFormContext();
  const isRecycle = watch('isRecycle')?.id === BooleanStrings.True;

  return (
    <AutoCompleteField
      label="廢料型態"
      required={isRecycle}
      disabled={!isRecycle}
      className={className}
      registerName="scrapType"
      options={options}
    />
  );
}
