import { useTargetModal } from "@solar/templates";
import { Modals } from "./Modals";
import { Form, Input, Modal, message } from "antd";
import { updatePendingDrawing, useGetPendingDrawingById } from "@solar/data";
import { UploadDrawingField, UploadDrawingValue } from "../UploadDrawingField/UploadDrawingField";
import { useEffect } from "react";

export function EditPendingDrawingModal({ mutateGetPendingDrawings }: { mutateGetPendingDrawings: () => void }) {
  const [form] = Form.useForm<{
    parentDrawingCode: string;
    file: UploadDrawingValue;
  }>();
  const { open, data, closeModal } = useTargetModal<{ id: string; }>(Modals.EDIT);
  const { pendingDrawing } = useGetPendingDrawingById({ id: data?.id ?? null });

  useEffect(() => {
    if (open && data?.id && pendingDrawing) {
      form.setFieldsValue({
        parentDrawingCode: pendingDrawing.parentDrawingCode,
      });
    }
  }, [open, data, pendingDrawing]);

  return (
    <Modal
      width={1000}
      open={open}
      title="新增"
      onOk={async () => {
        try {
          const result = await form?.validateFields();
          const targetFile = result?.file?.fileList?.[0];

          if (data?.id && targetFile?.response) {
            await updatePendingDrawing({
              id: data?.id,
              data: {
                ...targetFile.response,
                parentDrawingCode: result?.parentDrawingCode,
                location: targetFile?.response?.location ?? '台灣',
                s3FilePath: targetFile?.response?.s3FilePath ?? '',
                s3ObjectKey: targetFile?.response?.s3ObjectKey ?? '',
              },
            });

            message.success('新增成功');
            await mutateGetPendingDrawings();
            closeModal();
          }
          
          console.log(result);
        } catch (err) {
          console.log(err);
        }
      }}
      onCancel={() => closeModal()}
      okText="確認"
      cancelText="取消"
    >
      <Form form={form}>
        <Form.Item label="舊成品圖號" name="parentDrawingCode">
          <Input />
        </Form.Item>
        <Form.Item
          label="檔案"
          name="file"
          required
          rules={[{ required: true, message: '必填' }]}>
          <UploadDrawingField currentDrawing={pendingDrawing ?? null} />
        </Form.Item>
      </Form>
    </Modal>
  )
}