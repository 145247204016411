import { Typography } from '@mezzanine-ui/react';
import { ProductionFormFieldType, ProductionFormResponse } from '@solar/data';
import { Col, Row } from 'antd';
import {
  DateTimePickerField,
  EnumSelectField,
  NumericInputField,
  TextInputField,
} from './components';
import { ReactNode, useCallback } from 'react';

interface FormFieldProps {
  registerName: string;
  form: ProductionFormResponse;
  disabled?: boolean;
  renderTitle?: (form: ProductionFormResponse) => ReactNode;
  getFieldRegisterName?: (form: ProductionFormResponse) => string;
}

export function FormField({
  form,
  disabled,
  renderTitle,
  registerName,
  getFieldRegisterName,
}: FormFieldProps) {
  const _getFieldRegisterName = useCallback(
    (form: ProductionFormResponse) =>
      getFieldRegisterName?.(form) ?? `${registerName}.${form.id}`,
    [getFieldRegisterName, registerName]
  );

  return (
    <Row
      gutter={[12, 12]}
      align="middle"
      key={`${_getFieldRegisterName(form)}_wrapper`}
    >
      <Col flex="200px">
        {renderTitle?.(form) ?? (
          <Typography variant="h5">{form.title ?? '-'}</Typography>
        )}
      </Col>
      <Col flex="auto">
        {form.fields.type === ProductionFormFieldType.TEXT ? (
          <TextInputField
            key={_getFieldRegisterName(form)}
            registerName={_getFieldRegisterName(form)}
            fields={form.fields}
            disabled={disabled}
          />
        ) : null}
        {form.fields.type === ProductionFormFieldType.NUMERIC ? (
          <NumericInputField
            key={_getFieldRegisterName(form)}
            registerName={_getFieldRegisterName(form)}
            fields={form.fields}
            disabled={disabled}
          />
        ) : null}
        {form.fields.type === ProductionFormFieldType.ENUM ? (
          <EnumSelectField
            key={_getFieldRegisterName(form)}
            registerName={_getFieldRegisterName(form)}
            fields={form.fields}
            disabled={disabled}
          />
        ) : null}
        {form.fields.type === ProductionFormFieldType.TIMESTAMP ? (
          <DateTimePickerField
            key={_getFieldRegisterName(form)}
            registerName={_getFieldRegisterName(form)}
            fields={form.fields}
            disabled={disabled}
            fullWidth
            hideSecond
            format="YYYY/MM/DD HH:mm"
          />
        ) : null}
      </Col>
      <Col flex="100px">
        <Typography variant="h5">{form.suffix ?? ''}</Typography>
      </Col>
    </Row>
  );
}
