import 'rc-tree/assets/index.css';
import styles from './RCTree.module.scss';
import Tree from 'rc-tree';
import { useMemo, useState } from 'react';
import { useHumanResourceMembers, useMembersTreeAll } from '@solar/data';
import { Typography, Button, Input, Table, Loading } from '@mezzanine-ui/react';

export function RCTree() {
  const [keyword, setKeyword] = useState('');
  const {
    data,
    isValidating,
    expandedKeys,
    handleManualExpandOperation,
    handleExpandAll,
    handleCollapseAll,
    handleFilterTreeNode,
    selectedKeys,
    handleSelectedKeys,
    tableData,
    currentPage,
    perPage,
    handleChangePage,
    handleChangePageSize,
  } = useMembersTreeAll(keyword);

  const CustomIcon = (props: any) => {
    const { data } = props;
    return data.type === 'Unit' ? <span>🏢</span> : <span>🧑‍💼</span>;
  };

  const tableColumns = useMemo(
    () => [
      { title: '工號', dataIndex: 'id', width: 100 },
      { title: '姓名', dataIndex: 'name', width: 100 },
      { title: '狀態', dataIndex: 'employeeChangeType', width: 150 },
      { title: 'BU', dataIndex: 'bu', width: 100 },
      { title: '單位', dataIndex: 'unitPath', width: 250 },
    ],
    []
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.controlPanel}>
        <Input
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="請輸入關鍵字"
        ></Input>
        <Button variant="contained" type="button" onClick={handleExpandAll}>
          展開
        </Button>
        <Button variant="contained" type="button" onClick={handleCollapseAll}>
          收合
        </Button>
      </div>
      <div className={styles.leftWrapper}>
        {isValidating ? (
          <div className={styles.loading}>
            <Loading loading tip="Loading..." />
          </div>
        ) : (
          <Tree
            className={styles.rctree}
            expandAction="doubleClick"
            selectable={true}
            icon={CustomIcon}
            treeData={data}
            expandedKeys={expandedKeys}
            filterTreeNode={handleFilterTreeNode}
            onExpand={handleManualExpandOperation}
            onSelect={handleSelectedKeys}
          ></Tree>
        )}
      </div>
      <div className={styles.rightWrapper}>
        <div className={styles.rightTitle}>
          <Typography variant="h5">
            單位：<span>{selectedKeys.where}</span>
          </Typography>
        </div>
        <div className={styles.tableWrapper}>
          <Table
            columns={tableColumns}
            dataSource={tableData?.data ?? []}
            bodyClassName={styles.table}
            pagination={{
              disableAutoSlicing: true,
              total: tableData?.total,
              current: currentPage,
              onChange: handleChangePage,
              options: {
                pageSize: perPage,
                onChangePageSize: handleChangePageSize,
                showPageSizeOptions: true,
                pageSizeLabel: '每頁顯示：',
                pageSizeUnit: '筆',
                renderPaginationSummary: () => '',
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}
