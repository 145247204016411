import { useMemo, useState } from 'react';
import {
  ModalLayout,
  ModalLayoutProps,
} from '../../../ModalLayout/ModalLayout';
import {
  Button,
  ModalActions,
  Popper,
  Table,
  Typography,
} from '@mezzanine-ui/react';
import { useModalController } from '../../../ModalLayout/hooks';
import {
  LocationLevel,
  ZoneEntity,
  getZoneDisplayName,
  useEquipments,
  useSVGs,
} from '@solar/data';
import {
  LocationSVGViewer,
  transformZoneToLocation,
} from '../../../../Equipment/LocationSelector/LocationSVGViewer';
import {
  MaterialCollectSourceType,
  renderMaterialCollectSourceTypeString,
} from '../../material-collect-enum';
import { useForm } from 'react-hook-form';
import { MaterialRegisterEnum } from '../../Modal/register-material-modal-enum';

import sharedStyles from '../../../shared-styles/index.module.scss';
import { ChooseMaterialFirstLayerModalFilter } from './ChooseMaterialFirstLayerModalFilter';
import { TableColumn } from '@mezzanine-ui/core/table';

export type ExpectedTakeTableColumn = {
  materialId: string;
  spec: string;
  batch: string;
  zone: ZoneEntity;
  vehicle: string;
  received: number;
  willReceive: number;
};

type Props = ModalLayoutProps & {
  secondLayerModalController: ReturnType<typeof useModalController>;
};
export function ChooseMaterialFirstLayerModal({
  open,
  data,
  closeModal,
  secondLayerModalController,
}: Props) {
  const filterMethods = useForm();
  const {
    equipments,
    pageInfo,
    mutateGetEquipments,
    isLoading: tableDataLoading,
  } = useEquipments(filterMethods);
  const modalHeader = data?.modalHeader as MaterialCollectSourceType;

  const [anchorRef, setAnchorRef] = useState<HTMLDivElement | null>();
  const { svg, mutateGetSVG, resetSVG } = useSVGs();
  const [currentZone, setCurrentZone] = useState<ZoneEntity | null>();

  const expectedTakeTableColumn: TableColumn<ExpectedTakeTableColumn>[] =
    useMemo(
      () => [
        { title: '料號', dataIndex: 'materialId' },
        { title: '規格', dataIndex: 'spec' },
        { title: '批號', dataIndex: 'batch' },
        {
          title: '儲位',
          ellipsis: false,
          render: (source) => (
            <div
              data-zone-id={source?.zone?.id}
              onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => {
                if (source?.zone?.id) {
                  mutateGetSVG(source?.zone?.area?.id);
                  setCurrentZone(source?.zone);
                  setAnchorRef(event.currentTarget);
                }
              }}
              onMouseLeave={() => {
                if (source?.zone?.id) {
                  resetSVG();
                  setCurrentZone(null);
                  setAnchorRef(null);
                }
              }}
            >
              {getZoneDisplayName(source?.zone)}
            </div>
          ),
        },
        { title: '載具', dataIndex: 'vehicle' },
        { title: '已領收量', dataIndex: 'received' },
        {
          title: '應領數量',
          render: () => <>1</>,
        },
        {
          title: '操作',
          width: 120,
          align: 'center' as const,
          render(source) {
            return (
              <Button
                type="button"
                variant="outlined"
                onClick={() => {
                  secondLayerModalController.openModal({
                    modalHeader: MaterialRegisterEnum.Expected,
                    zone: source.zone,
                  });
                }}
              >
                選擇
              </Button>
            );
          },
        },
      ],
      [mutateGetSVG, resetSVG, secondLayerModalController]
    );

  return (
    <>
      <ModalLayout
        modalHeader={
          renderMaterialCollectSourceTypeString(modalHeader) + '領料'
        }
        open={open}
        closeModal={closeModal}
        modalFooter={
          <ModalActions
            confirmText="完成"
            onConfirm={closeModal}
            cancelButtonProps={{ style: { display: 'none' } }}
          />
        }
      >
        <ChooseMaterialFirstLayerModalFilter methods={filterMethods} />
        <div className={sharedStyles['row-block']}>
          <Typography variant="h4" color="text-primary">
            預期領取物料
          </Typography>
          <Table
            loading={tableDataLoading}
            columns={expectedTakeTableColumn}
            dataSource={equipments ?? []}
            pagination={{
              disableAutoSlicing: true,
              total: pageInfo?.totalCount ?? 0,
              current: pageInfo?.page ?? 1,
              onChange: (nextPage) => mutateGetEquipments({ nextPage }),
              options: {
                pageSize: pageInfo?.pageSize ?? 20,
                pageSizeLabel: '每頁顯示：',
                pageSizeUnit: '筆',
                onChangePageSize: (nextPageSize) =>
                  mutateGetEquipments({ nextPage: 1, nextPageSize }),
                renderPageSizeOptionName: (p) => `${p}`,
                renderPaginationSummary: (start, end) =>
                  `目前顯示 ${start} - ${end} 筆，共 ${end - start + 1} 筆資料`,
                showPageSizeOptions: true, // 開啟每頁顯示 N 筆
                showJumper: true, // 開啟跳頁功能
                jumperButtonText: '前往',
                jumperHintText: '跳至',
              },
            }}
            bodyClassName={sharedStyles.table}
          />
        </div>
      </ModalLayout>
      <Popper
        anchor={anchorRef}
        open={Boolean(anchorRef)}
        style={{
          border: '1px solid #000',
          borderRadius: '5px',
          backgroundColor: '#fff',
          zIndex: 1002,
        }}
        options={{
          placement: 'auto',
        }}
      >
        <LocationSVGViewer
          svg={svg}
          currentLevel={LocationLevel.ZONE}
          currentLocation={
            currentZone ? transformZoneToLocation(currentZone) : undefined
          }
        />
      </Popper>
    </>
  );
}
