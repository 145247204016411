import { WarehouseModule, WarehouseModuleZh } from '../../constant';

export const tabsOptions = [
  {
    id: WarehouseModule.MaterialStorageIn,
    name: WarehouseModuleZh[WarehouseModule.MaterialStorageIn],
  },
  {
    id: WarehouseModule.WarehouseDispatch,
    name: WarehouseModuleZh[WarehouseModule.WarehouseDispatch],
  },
  {
    id: WarehouseModule.GeneratedStorageIn,
    name: WarehouseModuleZh[WarehouseModule.GeneratedStorageIn],
  },
  {
    id: WarehouseModule.MaterialShift,
    name: WarehouseModuleZh[WarehouseModule.MaterialShift],
  },
  {
    id: WarehouseModule.MiscellaneousStorageIn,
    name: WarehouseModuleZh[WarehouseModule.MiscellaneousStorageIn],
  },
  {
    id: WarehouseModule.MiscellaneousDispatchAndReturn,
    name: WarehouseModuleZh[WarehouseModule.MiscellaneousDispatchAndReturn],
  },
];
