import { TableColumn } from '@mezzanine-ui/core/table';
import { Button, ButtonGroup } from '@mezzanine-ui/react';

interface RuleDetails extends Record<string, any> {
  customer_id: string;
  customer_name: string;
  material_id: string;
  order_id: string;
  order_detail_id: number;
  sales_order_line: string;
  quantity: number;
  usage_amount: number;
}

export function useDispatcherTableColumns({
  openAutoDispatchModal,
  openManualDispatchModal,
}: {
  openAutoDispatchModal: (ruleDetails: RuleDetails) => void;
  openManualDispatchModal: (ruleDetails: RuleDetails) => void;
}) {
  return [
    {
      title: '訂單號碼',
      dataIndex: 'order_id',
      width: 150,
    },
    {
      title: '訂單項次',
      dataIndex: 'sales_order_line',
      width: 100,
    },
    {
      title: '數量',
      dataIndex: 'quantity',
      width: 100,
    },
    {
      title: '已取數量',
      dataIndex: 'usage_amount',
      width: 100,
    },
    {
      title: '料號',
      dataIndex: 'material_id',
      width: 400,
    },
    {
      title: '料號敘述',
      dataIndex: 'material_description',
      width: 480,
    },
    {
      title: '客戶名（出貨）',
      dataIndex: 'customer_name',
      key: 'customer_name',
      width: 500,
    },
    {
      width: 200,
      render: (source, index) => {
        const record = source;
        return (
          <ButtonGroup variant="contained">
            <Button onClick={() => openManualDispatchModal(record)}>
              手動
            </Button>
            <Button onClick={() => openAutoDispatchModal(record)}>自動</Button>
          </ButtonGroup>
        );
      },
    },
  ] as TableColumn<RuleDetails>[];
}
