import { Table } from '@mezzanine-ui/react';
import { API_NAMESPACE } from '@solar/data';
import { useMemo } from 'react';
import useSWR from 'swr';

interface ElementRatioTableProps {
  orderId?: string;
  barcode?: string;
}

export function ElementRatioTable({
  orderId,
  barcode,
}: ElementRatioTableProps) {
  const { data, error, isValidating } = useSWR<{
    standardWeightTheoreticalRatio: { element: string; value: string | null }[];
    actualWeightTheoreticalRatio: { element: string; value: string | null }[];
    standardWeightLIMSRatio: { element: string; value: string | null }[];
    actualWeightLIMSRatio: { element: string; value: string | null }[];
    orderCondition: { element: string; value: string | null }[];
  }>([
    '/inventory-element-ratio',
    {
      namespace: API_NAMESPACE.PP,
      params: { orderId, barcode },
    },
  ]);

  const loading = !data && !error && !isValidating;

  const dataSource = useMemo(() => {
    const elementSet = new Set<string>(
      Object.values(data ?? {}).flatMap((elements) =>
        elements.map((element) => element.element)
      )
    );

    return Array.from(elementSet).map((element) => {
      const standardWeightTheoreticalRatio =
        data?.standardWeightTheoreticalRatio.find(
          (item) => item.element === element
        )?.value;
      const actualWeightTheoreticalRatio =
        data?.actualWeightTheoreticalRatio.find(
          (item) => item.element === element
        )?.value;
      const standardWeightLIMSRatio = data?.standardWeightLIMSRatio.find(
        (item) => item.element === element
      )?.value;
      const actualWeightLIMSRatio = data?.actualWeightLIMSRatio.find(
        (item) => item.element === element
      )?.value;
      const orderCondition = data?.orderCondition.find(
        (item) => item.element === element
      )?.value;

      return {
        id: element,
        key: element,
        element,
        standardWeightTheoreticalRatio,
        actualWeightTheoreticalRatio,
        standardWeightLIMSRatio,
        actualWeightLIMSRatio,
        orderCondition,
      };
    });
  }, [data]);

  return (
    <Table
      loading={loading}
      dataSource={dataSource ?? []}
      columns={[
        { title: '元素', dataIndex: 'element' },
        { title: '交易條件建議量', dataIndex: 'orderCondition' },
        {
          title: '靶材標準重*理論元素比例',
          dataIndex: 'standardWeightTheoreticalRatio',
        },
        {
          title: '靶材實際重*理論元素比例',
          dataIndex: 'actualWeightTheoreticalRatio',
        },
        {
          title: '靶材標準重*LIMS元素比例',
          dataIndex: 'standardWeightLIMSRatio',
        },
        {
          title: '靶材實際重*LIMS元素比例',
          dataIndex: 'actualWeightLIMSRatio',
        },
      ]}
    />
  );
}
