import { request, API_NAMESPACE } from '../../request';

export interface DeliveryOrderShipPost {
  orderId: string;
  items: Item[];
}

export interface Item {
  palletCategoryId: number;
  totalGrossWeight: string;
  totalNetWeight: string;
  totalNumberOfBoxes: number;
  volumeLength: string;
  volumeWidth: string;
  volumeHeight: string;
  materialItems: MaterialItem[];
}

export interface MaterialItem {
  materialBarcode: string;
  materialInputUnitQuantity: string;
  materialInputUnit: string;
  deliveryOrderDetailId: number;
}

async function postDeliveryOrderShip(payload: DeliveryOrderShipPost) {
  try {
    return request(`/warehouses/production-delivery-orders/ship`, {
      namespace: API_NAMESPACE.MATERIALS,
      method: 'POST',
      body: JSON.stringify(payload),
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export { postDeliveryOrderShip };
