import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { API_NAMESPACE, request } from '../../request';
import { isOption, Modal, ModalBody } from '../mainFile';
import { isOptionObject } from '../orderMarks';
import { usePackaging } from './usePackaging';
import { usePackagingFilter } from './usePackagingFilter';

const namespace = API_NAMESPACE.HR;

const initialInfo = '一';

type Title = '新增' | '編輯';

export function usePackagingModal(methods: UseFormReturn<FieldValues, any>) {
  const { createMutation, updateMutation, deleteMutation } = usePackaging();
  const {
    queryParams,
    data: packagingFiltersData,
    setData: setPackaginFiltersData,
    isPackagingFilters,
    clearSelectByMaterials,
    handleFilterByKeyword,
  } = usePackagingFilter();

  const [deleteModal, setDeleteModal] = useState<any>(null);
  const [modal, setModal] = useState<Modal<Title>>(null);
  const [disableConfirm, setDisableConfirm] = useState(true);

  const [packagingNameValue, setPackagingNameValue] = useState('');
  const [defaultInfo, setDefaultInfo] = useState(initialInfo);

  const requiredFields = useMemo(
    () => [
      'lengthMeter',
      'heightMeter',
      'widthMeter',
      'packagingType',
      'radiosSelect',
    ],
    []
  );

  const closeModal = useCallback(() => {
    setModal(null);
    setDefaultInfo(initialInfo);
    clearSelectByMaterials();
    setPackagingNameValue('');
    methods.setValue('radioValue', 'packagingNameValue');
    requiredFields.forEach((field) => {
      methods.setValue(field, undefined);
    });
  }, []);

  const createModal = useMemo(
    () => [
      {
        label: '類型',
        required: true,
        fn: 'EnhanceSelect',
        componentsProps: {
          registerName: 'packagingType',
          mode: 'single',
          width: 172,
          options: packagingFiltersData?.packagingTypes ?? [],
          defaultValue:
            packagingFiltersData?.packagingTypes.find(
              (type) => type.id === modal?.source?.packagingTypeId?.id
            ) ?? null,
          clearable: false,
          placeholder: '請選擇',
        },
      },
      {
        label: '包材料號(料號)',
        required: true,
        fn: 'EnhanceAutoCompleteUpdateOptions',
        componentsProps: {
          registerName: 'radiosSelect',
          mode: 'single',
          name: 'radiosSelect',
          width: 320,
          form: methods,
          options:
            packagingFiltersData?.selectByMaterials.map((item) => {
              // AutoComplete component use 'name' to display option label, so replace it and return new array
              return { ...item, name: item.id };
            }) ?? [],
          handleSearch: handleFilterByKeyword,
          placeholder: '請選擇',
          defaultValue: modal?.source?.materialId,
        },
      },
      {
        label: '',
        required: false,
        fn: 'BorderVertical',
        componentsProps: {
          height: 56,
        },
      },
      {
        label: '包材名稱(由料號帶入品名)',
        required: false,
        fn: 'Info',
        componentsProps: {
          content: defaultInfo,
        },
      },
      {
        label: '材質',
        required: false,
        fn: 'EnhanceModalSearchInput',
        registerName: 'texture',
        componentsProps: {
          registerName: 'texture',
          width: 172,
          placeholder: '紙箱',
          register: methods.register,
          setValue: methods.setValue,
          name: 'texture',
          defaultValue: modal?.source?.texture,
        },
      },
      {
        label: '長 (mm)',
        required: true,
        fn: 'EnhanceAccountInput',
        componentsProps: {
          registerName: 'lengthMeter',
          width: 128,
          clearable: true,
          placeholder: '0',
          register: methods.register,
          setValue: methods.setValue,
          name: 'lengthMeter',
          defaultValue: modal?.source?.length,
        },
      },
      {
        label: '寬 (mm)',
        required: true,
        fn: 'EnhanceAccountInput',
        componentsProps: {
          registerName: 'widthMeter',
          width: 128,
          clearable: true,
          placeholder: '0',
          register: methods.register,
          setValue: methods.setValue,
          name: 'widthMeter',
          defaultValue: modal?.source?.width,
        },
      },
      {
        label: '高 (mm)',
        required: true,
        fn: 'EnhanceAccountInput',
        componentsProps: {
          registerName: 'heightMeter',
          width: 128,
          clearable: true,
          placeholder: '0',
          register: methods.register,
          setValue: methods.setValue,
          name: 'heightMeter',
          defaultValue: modal?.source?.height,
        },
      },
      {
        label: '',
        required: false,
        fn: 'BorderVertical',
        componentsProps: {
          height: 56,
        },
      },
      {
        label: '英吋長寬高 (in)',
        required: false,
        fn: 'EnhanceModalSearchInput',
        registerName: 'inchSize',
        componentsProps: {
          registerName: 'inchSize',
          width: 172,
          placeholder: '請輸入',
          register: methods.register,
          setValue: methods.setValue,
          name: 'inchSize',
          defaultValue: modal?.source?.inch,
        },
      },
      {
        label: '',
        required: false,
        fn: 'BorderVertical',
        componentsProps: {
          height: 56,
        },
      },
      {
        label: '分數 (in)',
        required: false,
        fn: 'EnhanceModalSearchInput',
        registerName: 'fraction',
        componentsProps: {
          registerName: 'fraction',
          width: 172,
          placeholder: '請輸入',
          register: methods.register,
          setValue: methods.setValue,
          name: 'fraction',
          defaultValue: modal?.source?.fraction,
        },
      },
    ],
    [
      packagingFiltersData,
      modal,
      methods.getValues('radiosSelect'),
      defaultInfo,
    ]
  ) as ModalBody[];

  const updateModal = useMemo(() => {
    const filterData = createModal.map((item) =>
      item.fn === 'Border' ? { ...item, fn: 'Br' } : item
    );
    const map = filterData.map((item) => ({
      ...item,
      componentsProps: { ...item.componentsProps, clearable: false },
    }));
    return map;
  }, [createModal]) as ModalBody[];

  const handleOpenModal = async (source: any) => {
    const materialId = source?.materialId ?? '';
    setModal({ title: '編輯', confirmButton: '更新', source });
    const result = await request('/packaging/get/filter', {
      params: { ...queryParams, materialKeyword: materialId },
      namespace,
    });
    if (isPackagingFilters(result)) {
      methods.setValue('radiosSelect', materialId);
      setPackaginFiltersData(result);
      const { selectByMaterials } = result;
      setDefaultInfo(
        selectByMaterials.find((material) => material.id === materialId)
          ?.name ?? '一'
      );
    }
  };

  const confirmModal = useCallback(async () => {
    const data = methods.watch();

    const objectKeys = Object.keys(data);

    const mapping = [
      { body: 'packagingType', name: 'packagingType' },
      { body: 'length', name: 'lengthMeter' },
      { body: 'width', name: 'widthMeter' },
      { body: 'height', name: 'heightMeter' },
      { body: 'inch', name: 'inchSize' },
      { body: 'fraction', name: 'fraction' },
      { body: 'materialId', name: 'radiosSelect' },
      { body: 'texture', name: 'texture' },
    ];

    const reduceData: { shouldDrop: any } & any = objectKeys.reduce(
      (acc, cur) => {
        if (isOption(data[cur]))
          return {
            ...acc,
            [mapping.find((item) => item.name === cur)?.body ?? 'shouldDrop']:
              data[cur][0].id,
          };

        if (isOptionObject(data[cur]))
          return {
            ...acc,
            [mapping.find((item) => item.name === cur)?.body ?? 'shouldDrop']:
              data[cur]?.id,
          };
        return {
          ...acc,
          [mapping.find((item) => item.name === cur)?.body ?? 'shouldDrop']:
            data[cur],
        };
      },
      {}
    );

    const { shouldDrop, ...others } = reduceData;

    const finalData = others;

    let response;

    if (modal?.source) {
      response = await updateMutation(modal.source?.id, finalData);
    } else {
      response = await createMutation(finalData);
    }

    closeModal();

    return response;
  }, [closeModal, modal, methods]);

  const deleteModalMutation = useCallback(
    (id: string) => {
      deleteMutation(id);

      setDeleteModal(null);
      closeModal();
    },
    [closeModal]
  );

  useEffect(() => {
    const radiosSelect = methods.watch()['radiosSelect'];
    setDefaultInfo(
      packagingFiltersData?.selectByMaterials.find(
        (material) => material.id === radiosSelect
      )?.name ?? initialInfo
    );
  }, [methods.watch(), packagingFiltersData]);

  useEffect(() => {
    if (defaultInfo === initialInfo) {
      setDisableConfirm(true);
      return;
    }
    methods.watch();
    let disabled = false;

    requiredFields.forEach((field) => {
      const values = methods.getValues(field);
      if (!values || (typeof values === 'string' && values.trim() === '')) {
        disabled = true;
      }
    });

    setDisableConfirm(disabled);
  }, [
    defaultInfo,
    methods.watch(),
    packagingNameValue,
    methods.getValues('radiosSelect'),
  ]);

  return {
    deleteModal,
    setDeleteModal,
    modal,
    setModal,
    closeModal,
    disableConfirm,
    createModal,
    updateModal,
    handleOpenModal,
    confirmModal,
    deleteModalMutation,
  };
}
