import { useSearchKeyword } from './useSearchKeyword';
import { useFetchTreeAllWithMembers } from './useFetchTreeAllWithMembers';
import { useExpandedKeys } from './useExpandedKeys';
import { useSelectedKeys } from './useSelectedKeys';

export function useMembersTreeAll(keyword: string) {
  const { data, isValidating } = useFetchTreeAllWithMembers();
  const {
    expandedKeys,
    setExpandedKeys,
    handleExpandAll,
    handleCollapseAll,
    handleManualExpandOperation,
  } = useExpandedKeys();
  const {
    selectedKeys,
    handleSelectedKeys,
    currentPage,
    perPage,
    handleChangePage,
    handleChangePageSize,
    tableData,
  } = useSelectedKeys();
  const { handleFilterTreeNode } = useSearchKeyword(keyword, setExpandedKeys);

  return {
    data,
    isValidating,
    expandedKeys,
    handleManualExpandOperation,
    handleExpandAll,
    handleCollapseAll,
    handleFilterTreeNode,
    selectedKeys,
    handleSelectedKeys,
    tableData,
    currentPage,
    perPage,
    handleChangePage,
    handleChangePageSize,
  };
}
