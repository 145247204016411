import useSWR from 'swr';
import { API_NAMESPACE } from '../request';

export type LoaderCategory = {
  code: string;
  name: string;
};

const namespace = API_NAMESPACE.MATERIALS;
export const useLoadersCategories = () => {
  return useSWR<Array<LoaderCategory>>(['/loaders/categories', { namespace }]);
};
