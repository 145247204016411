import { BACK_PLATE_LARGE_GROUP } from './groups/back-plate';
import { CAR_CHEMICAL_LARGE_GROUP } from './groups/car-chemical';
import { CHEMICAL_LARGE_GROUP } from './groups/chemical';
import { EMBRYO_LARGE_GROUP } from './groups/embryo';
import { PART_LARGE_GROUP } from './groups/part';
import { RECYCLE_LARGE_GROUP } from './groups/recycle';
import { PPM_SIZE, SOLID_LARGE_GROUP } from './groups/solid';
import { TARGET_LARGE_GROUP } from './groups/target';
import { WASTE_LARGE_GROUP } from './groups/waste';
import { Group, Hierarchy, Result, ResultDetail } from './typings';

export const GROUP_CODE: Record<
  string,
  {
    name: string;
    getNextLevel?: () => Record<string, Group>;
    nextLevel?: Record<string, Group>;
    prefixLabels: string[];
    suffixKeys: {
      label: string;
      size: number;
      getSize?: (prefix: string) => number;
      getOptions?: (prefix: string) => undefined | Record<string, Group>;
    }[];
  }
> = {
  T: {
    name: '靶材',
    nextLevel: TARGET_LARGE_GROUP,
    prefixLabels: [
      '物料群組',
      '產品階層(大)',
      '產品階層(中)',
      '產品階層(小)',
      '身份碼',
      '特徵碼1',
      '特徵碼2',
    ],
    suffixKeys: [
      { label: '成分代碼', size: 6 },
      { label: '圖號', size: 11 },
      { label: '加工碼', size: 2 },
    ],
  },
  E: {
    name: '靶胚',
    nextLevel: EMBRYO_LARGE_GROUP,
    prefixLabels: [
      '物料群組',
      '產品階層(大)',
      '產品階層(中)',
      '產品階層(小)',
      '身份碼',
      '特徵碼1',
      '特徵碼2',
    ],
    suffixKeys: [
      { label: '成分代碼', size: 6 },
      {
        label: '模具號',
        size: 12,
        getSize: (prefix) => {
          const shape = prefix.substring(2, 3);

          switch (shape) {
            case 'C':
              return 9;

            case 'R':
            default:
              return 12;
          }
        },
      },
      { label: '尺寸1', size: 4 },
      { label: '加工碼', size: 2 },
    ],
  },
  B: {
    name: '背板',
    nextLevel: BACK_PLATE_LARGE_GROUP,
    prefixLabels: [
      '物料群組',
      '產品階層(大)',
      '產品階層(中)',
      '產品階層(小)',
      '身份碼',
      '特徵碼1',
    ],
    suffixKeys: [
      { label: '特徵碼2', size: 2 },
      { label: '成分代碼', size: 6 },
      { label: '圖號', size: 11 },
      { label: '加工碼', size: 2 },
    ],
  },
  S: {
    name: '固材',
    nextLevel: SOLID_LARGE_GROUP,
    prefixLabels: [
      '物料群組',
      '產品階層(大)',
      '產品階層(中)',
      '產品階層(小)',
      '身份碼',
      '特徵碼1',
      '特徵碼2',
    ],
    suffixKeys: [
      { label: '成分代碼', size: 6 },
      {
        label: '尺寸1',
        size: 4,
        getSize: (prefix) => {
          switch (prefix[1]) {
            case 'F':
              switch (prefix[2]) {
                case 'P':
                  return 4;

                default:
                  return 0;
              }

            case 'P': {
              switch (prefix[2]) {
                case 'S':
                case 'M':
                  return 4;

                default:
                  return 0;
              }
            }

            case 'W':
            case 'S':
              return 4;

            default:
              return 0;
          }
        },
        getOptions: (prefix) => {
          switch (prefix[1]) {
            case 'W':
              switch (prefix[2]) {
                case 'G':
                  return PPM_SIZE;

                default:
                  return undefined;
              }

            default:
              return undefined;
          }
        },
      },
      {
        label: '尺寸2',
        size: 4,
        getSize: (prefix) => {
          switch (prefix[1]) {
            case 'F':
              switch (prefix[2]) {
                case 'P':
                  return 4;

                default:
                  return 0;
              }

            case 'P': {
              switch (prefix[2]) {
                case 'P':
                case 'M':
                  return 4;

                default:
                  return 0;
              }
            }

            default:
              return 0;
          }
        },
      },
      {
        label: '尺寸3',
        size: 4,
        getSize: (prefix) => {
          switch (prefix[1]) {
            case 'F':
              switch (prefix[2]) {
                case 'P':
                  return 4;

                default:
                  return 0;
              }

            default:
              return 0;
          }
        },
      },
    ],
  },
  C: {
    name: '化學品',
    nextLevel: CHEMICAL_LARGE_GROUP,
    prefixLabels: [
      '物料群組',
      '產品階層(大)',
      '產品階層(中)',
      '產品階層(小)',
      '身份碼',
      '特徵碼1',
      '特徵碼2',
    ],
    suffixKeys: [{ label: '成分代碼', size: 6 }],
  },
  V: {
    name: '車用化學品',
    nextLevel: CAR_CHEMICAL_LARGE_GROUP,
    prefixLabels: [
      '物料群組',
      '產品階層(大)',
      '產品階層(中)',
      '產品階層(小)',
      '身份碼',
      '特徵碼1',
      '特徵碼2',
    ],
    suffixKeys: [],
  },
  W: {
    name: '殘料',
    nextLevel: WASTE_LARGE_GROUP,
    prefixLabels: [
      '物料群組',
      '產品階層(大)',
      '產品階層(中)',
      '產品階層(小)',
      '身份碼',
      '特徵碼1',
      '特徵碼2',
    ],
    suffixKeys: [{ label: '成分代碼', size: 6 }],
  },
  P: {
    name: '零部件',
    nextLevel: PART_LARGE_GROUP,
    prefixLabels: [
      '物料群組',
      '產品階層(大)',
      '產品階層(中)',
      '產品階層(小)',
      '身份碼',
      '特徵碼1',
      '特徵碼2',
    ],
    suffixKeys: [
      {
        label: '尺寸1',
        size: 4,
      },
      {
        label: '尺寸2',
        size: 4,
      },
      {
        label: '尺寸3',
        size: 4,
      },
    ],
  },
  H: {
    name: '環保料號',
    nextLevel: RECYCLE_LARGE_GROUP,
    prefixLabels: [
      '物料群組',
      '產品階層(大)',
      '取樣碼',
      '身份碼',
      '環保代碼',
      '加工碼',
      '特徵碼',
    ],
    suffixKeys: [],
  },
};

function getChildren(
  allMap: Map<string, ResultDetail>,
  item: Group,
  prefix: string,
  cursor = 0,
  hierarchy: Hierarchy[] = []
): Result {
  const newHierarchy = Array.from(hierarchy);

  newHierarchy.push({
    name: item.name,
    cursor,
    length: prefix.length - cursor,
  });

  if (item.nextLevel) {
    return Object.entries(item.nextLevel).reduce((map, [code, subItem]) => {
      const subResults = getChildren(
        allMap,
        subItem,
        `${prefix}${code}`,
        prefix.length,
        newHierarchy
      );

      subResults.set(prefix, {
        hierarchy: newHierarchy,
        nextLevel: item.nextLevel,
      });

      return map;
    }, allMap);
  }

  allMap.set(prefix, {
    hierarchy: newHierarchy,
    ...(item.nextLevel
      ? {
          nextLevel: item.nextLevel,
        }
      : {}),
  });

  return allMap;
}

function getHierarchy(): Result {
  return Object.entries(GROUP_CODE).reduce(
    (map, [code, item]) => getChildren(map, item, code),
    new Map()
  );
}

const hierarchy = getHierarchy();

export function useMaterialHierarchy() {
  return hierarchy;
}
