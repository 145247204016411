import {
  Button,
  MenuDivider,
  Message,
  Typography,
  UploadButton,
} from '@mezzanine-ui/react';
import { PageWrapper } from '@solar/templates';
import { useCallback, useState } from 'react';
import { API_NAMESPACE, request } from '@solar/data';
import { Space } from 'antd';

export function RecipeImportPage() {
  const [uploading, setUploading] = useState(false);

  const [bomFiles, setBomFiles] = useState<File[]>([]);
  const [bomGroupFiles, setBomGroupFiles] = useState<File[]>([]);

  const onUploadCsv = useCallback(
    async (endpoint: string, formData: FormData) => {
      try {
        setUploading(true);
        await request(endpoint, {
          method: 'POST',
          body: formData,
          json: false,
          namespace: API_NAMESPACE.PP,
          responseParser: (res) => res,
        });

        Message.success('上傳成功');
      } catch (error) {
        console.error(error);
        Message.error('上傳失敗');
      } finally {
        setUploading(false);
      }
    },
    []
  );

  const onUploadQuestions = useCallback(async (file: File[]) => {
    const targetFile = file?.[0];

    if (!targetFile) {
      Message.error('請選擇新圖庫檔案');
      return;
    }

    const formData = new FormData();
    formData.append('files', targetFile);

    onUploadCsv('/recipes/questions/imports', formData);
  }, []);

  const onUploadSapRoutingData = useCallback(async (file: File[]) => {
    const targetFile = file?.[0];

    if (!targetFile) {
      Message.error('請選擇 Sap Routing 檔案');
      return;
    }

    const formData = new FormData();
    formData.append('files', targetFile);

    onUploadCsv('/recipes/routings/imports', formData);
  }, []);

  const onUploadRecipeRoutings = useCallback(async (file: File[]) => {
    const targetFile = file?.[0];

    if (!targetFile) {
      Message.error('請選擇 Recipe Routings 檔案');
      return;
    }

    const formData = new FormData();
    formData.append('files', targetFile);

    onUploadCsv('/recipes/imports', formData);
  }, []);

  const onUploadBom = useCallback(async () => {
    if (bomFiles.length === 0) {
      Message.error('請選擇 BOM 檔案');
      return;
    }

    if (bomGroupFiles.length === 0) {
      Message.error('請選擇 BOM 群組檔案');
      return;
    }

    const formData = new FormData();
    formData.append('bomFiles', bomFiles[0]);
    formData.append('groupFiles', bomGroupFiles[0]);

    onUploadCsv('/recipes/bom-groups/imports', formData);
  }, [bomFiles, bomGroupFiles]);

  return (
    <PageWrapper title="上傳區">
      <UploadButton
        loading={uploading}
        multiple={false}
        accept="text/csv"
        onUpload={onUploadQuestions}
      >
        上傳新題庫檔案
      </UploadButton>
      <MenuDivider />
      <UploadButton
        loading={uploading}
        multiple={false}
        accept="text/csv"
        onUpload={onUploadSapRoutingData}
      >
        上傳 SAP Routing Data
      </UploadButton>
      <MenuDivider />
      <UploadButton
        loading={uploading}
        multiple={false}
        accept="text/csv"
        onUpload={onUploadRecipeRoutings}
      >
        上傳 Recipe Routings
      </UploadButton>
      <MenuDivider />
      <Space align="start">
        <Space direction="vertical">
          <UploadButton
            multiple={false}
            accept="text/csv"
            onUpload={setBomFiles}
          >
            上傳 BOM
          </UploadButton>
          <Typography variant="h5">{bomFiles?.[0]?.name ?? ''}</Typography>
        </Space>
        <Space direction="vertical">
          <UploadButton
            multiple={false}
            accept="text/csv"
            onUpload={setBomGroupFiles}
          >
            上傳 BOM 群組
          </UploadButton>
          <Typography variant="h5">{bomGroupFiles?.[0]?.name ?? ''}</Typography>
        </Space>
        <Button loading={uploading} variant="contained" onClick={onUploadBom}>
          送出
        </Button>
      </Space>
    </PageWrapper>
  );
}
