export type APIResponse<T> = {
  code: number;
  count: number;
  data: T;
  message: string;
  page: number;
  size: number;
  title: string;
  ok: boolean;
};

export type TypeCode = {
  code: string;
  name: string;
};

export type CompositeCode = {
  code: string;
  name: string;
};

export type BU = 'TEM' | 'FCC';

export type ShapeCode = {
  id: number;
  bu: BU;
  code: string;
  name: string;
  requiredSizeCodeOne: string;
  requiredSizeCodeTwo: string;
  requiredSizeCodeThree: string;
  createdAt: string;
};

export type SizeCodeOne = {
  code: string;
  lowerBound: number;
  upperBound: number;
};

// old version
export type DrillLayeringCode = {
  code: string;
  isDrilling: boolean;
  isLayering: boolean;
};

export type DrillingCode = {
  id: number;
  code: string;
  isTapping: boolean;
  isPerforation: boolean;
  isBlindHole: boolean;
  createdAt: string;
};

export type SlottingCode = {
  id: number;
  code: string;
  isOring: boolean;
  isProfile: boolean;
  createdAt: string;

  // old version
  isSideSlotting: boolean;
  isSurfaceSlotting: boolean;
};

export type NotchCode = {
  id: number;
  code: string;
  chamferAngleCount: number;
  radiusAngleCount: number;
  totalAngleCount: number;
  createdAt: string;
};

export type CompletedStatusCode = {
  code: string;
  name: string;
};

export type BackplateSourceCode = {
  code: string;
  name: string;
};

export type completedStatusCode = {
  code: string;
  name: string;
};

export type SandblastingCode = {
  id: number;
  code: string;
  isTraditionalSandblasting: boolean;
  isLaserSandblasting: boolean;
  isGrooveSandblasting: boolean;
  isPlasmaSandblasting: boolean;
  createdAt: string;
};

export type MaterialDrawing =
  | {
      // old version
      id: number;
      drawingCode: string; // newDrawingCode
      drawing: string; // oldDrawingCode
      oldMaterialID: string;
      newMaterialID: string;
      desc: string;
    }
  | {
      id: string;
      createdAt: string;
      updatedAt: string;
      deletedAt: string | null;
      oldDrawingCode: string;
      oldMaterialID: string;
      newMaterialID: string;
      desc: string;
      transactionDate: string;
      scheduleShipDate: string;
    };

export type Drawing = {
  id: number;
  fileType: string;
  fileLink: string;
  fileName: string;
  s3ObjectKey: string;
  oldDrawingFixed: string;
  checker: string;
  parentDrawingCode: string;
  newDrawingCodePrefix: string;
  serialString: string;
  newDrawingCode: string;
  typeCode: string;
  drawingType: TypeCode;
  compositeCode: string;
  drawingCompositeCode: CompositeCode;
  shapeCode: string;
  drawingShapeCode: ShapeCode;
  compositeNumber: number;
  sizeLength: string;
  sizeCodeOne: string;
  sizeWidth: string;
  sizeCodeTwo: string;
  sizeHeight: string;
  sizeCodeThree: string;
  isChamfer: boolean;
  isSandblasting: boolean;
  isDrilling: boolean;
  isLayering: boolean;
  drillLayeringCode: string;
  drawingDrillLayeringCode: DrillLayeringCode;
  isSurfaceSlotting: boolean;
  isSideSlotting: boolean;
  slottingCode: string;
  drawingSlottingCode: SlottingCode;
  isLettering: boolean;
  isTapping: boolean;
  remark: string;
  backplateSource: string;
  drawingBackplateSource: string;
  completedStatusCode: string;
  drawingCompletedStatus: CompletedStatusCode;
  materialDrawings: MaterialDrawing[];
};

export type TEMDrawing = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  year: number;
  fileLink: string;
  fileName: string;
  s3ObjectKey: string;
  parentOldDrawingCode: string;
  oldDrawingCode: string;
  oldDrawingFixed: string;
  newDrawingCodePrefix: string;
  serialString: string;
  serialNumber: number;
  newDrawingCode: string;
  typeCode: TypeCode['code'];
  typeName: TypeCode['name'];
  compositeCode: CompositeCode['code'];
  compositeName: CompositeCode['name'];
  compositeNumber: number;
  shapeCodeID: ShapeCode['id'];
  shapeCode: ShapeCode['code'];
  shapeName: ShapeCode['name'];
  sizeOne: string;
  sizeCodeOne: string;
  sizeTwo: string;
  sizeCodeTwo: string;
  sizeThree: string;
  sizeCodeThree: string;
  layeringCode: string;
  sandblastingCode: SandblastingCode['code'];
  isTraditionalSandblasting: SandblastingCode['isTraditionalSandblasting'];
  isLaserSandblasting: SandblastingCode['isLaserSandblasting'];
  isGrooveSandblasting: SandblastingCode['isGrooveSandblasting'];
  isPlasmaSandblasting: SandblastingCode['isPlasmaSandblasting'];
  slottingCode: SlottingCode['code'];
  isOring: SlottingCode['isOring'];
  isProfile: SlottingCode['isProfile'];
  drillingCode: DrillingCode['code'];
  isTapping: DrillingCode['isTapping'];
  isPerforation: DrillingCode['isPerforation'];
  isBlindHole: DrillingCode['isBlindHole'];
  checker: string;
  remark: string;
  backplateSourceCode: BackplateSourceCode['code'];
  backplateSourceName: BackplateSourceCode['name'];
  completedStatusCode: CompletedStatusCode['code'];
  completedStatusName: CompletedStatusCode['name'];
  lastScheduleShipDate: string;
  materialDrawings: MaterialDrawing[];
  parentDrawings: TEMDrawing[];
  childDrawings: TEMDrawing[];
  hasParent: boolean;
};

export type FCCDrawing = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  fileLink: string;
  fileName: string;
  s3ObjectKey: string;
  parentOldDrawingCode: string;
  oldDrawingCode: string;
  oldDrawingFixed: string;
  newDrawingCodePrefix: string;
  serialString: string;
  serialNumber: number;
  newDrawingCode: string;
  typeCode: TypeCode['code'];
  typeName: TypeCode['name'];
  compositeCode: CompositeCode['code'];
  compositeName: CompositeCode['name'];
  compositeNumber: number;
  shapeCodeID: ShapeCode['id'];
  shapeCode: ShapeCode['code'];
  shapeName: ShapeCode['name'];
  sizeOne: string;
  sizeCodeOne: string;
  sizeTwo: string;
  sizeCodeTwo: string;
  sizeThree: string;
  sizeCodeThree: string;
  notchCodeID: number;
  notchCode: string;
  chamferAngleCount: number;
  radiusAngleCount: number;
  totalAngleCount: number;
  edgeChamferCount: number;
  edgeRadiusCount: number;
  isSandblasting: boolean;
  year: number;
  checker: string;
  remark: string;
  backplateSourceCode: BackplateSourceCode['code'];
  backplateSourceName: BackplateSourceCode['name'];
  completedStatusCode: CompletedStatusCode['code'];
  completedStatusName: CompletedStatusCode['name'];
  lastScheduleShipDate: string;
  materialDrawings: MaterialDrawing[];
  parentDrawings: FCCDrawing[];
  childDrawings: FCCDrawing[];
  hasParent: boolean;
};

export type DrawingRefs = {
  me: Drawing;
  parentDrawings: Drawing[];
  childDrawings: Drawing[];
};

export type PendingDrawing = {
  id?: number;
  fileLink: string;
  fileName: string;
  fileType: 'pdf' | 'tiff' | 'jpg';
  s3ObjectKey: string;
  oldDrawingFixed: string;
  checker: string;
  parentDrawingCode: string;
  newDrawingCodePrefix: string;
  serialString: string;
  newDrawingCode: string;
  typeCode: string;
  drawingType: TypeCode;
  compositeCode: string;
  drawingCompositeCode: CompositeCode;
  shapeCode: string;
  drawingShapeCode: ShapeCode;
  compositeNumber: number;
  sizeLength: string;
  sizeCodeOne: string;
  sizeWidth: string;
  sizeCodeTwo: string;
  sizeHeight: string;
  sizeCodeThree: string;
  isChamfer: boolean;
  isSandblasting: boolean;
  isDrilling: boolean;
  isLayering: boolean;
  drillLayeringCode: string;
  drawingDrillLayeringCode: DrillLayeringCode;
  isSurfaceSlotting: boolean;
  isSideSlotting: boolean;
  slottingCode: string;
  drawingSlottingCode: SlottingCode;
  isLettering: boolean;
  isTapping: boolean;
  remark: string;
  backplateSource: string;
  drawingBackplateSource: string;
  completedStatusCode: string;
  drawingCompletedStatus: CompletedStatusCode;
  materialDrawings: MaterialDrawing[];
  location: string;
  s3FilePath: string;
};

// export type PendingDrawing = {
//   createdAt: string;
//   deletedAt: string;
//   docID: string;
//   docType: string;
//   drawingType: string;
//   fileLink: string;
//   fileName: string;
//   fileType: 'pdf' | 'tiff' | 'jpg';
//   id: string;
//   location: string;
//   modifyTime: string;
//   oldDrawingFixed: string;
//   s3ObjectKey: string;
//   skDepart: string;
//   updatedAt: string;
//   parentDrawingCode: string;
// };
