import { Tab, TabPane, TabPaneProps, Tabs } from '@mezzanine-ui/react';
import { PageLayout } from '@solar/templates';
import { Key, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MaterialGroupCodePanel } from './MaterialGroupCodePanel';
import { MaterialClassCodePanel } from './MaterialClassCodePanel';
import { MaterialSourceCodePanel } from './MaterialSourceCodePanel';
import { MaterialMachiningCodePanel } from './MaterialMachiningCodePanel';
import { MaterialWasteCodePanel } from './MaterialWasteCodePanel';
import { MaterialSamplingCodePanel } from './MaterialSamplingCodePanel';
import { MaterialFeatureCodePanel } from './MaterialFeatureCodePanel';

function useTabsRouting(tabOptions: { key: Key }[]) {
  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get('tab');

  const [activeTab, setActiveTab] = useState(tab ?? tabOptions[0].key);

  useEffect(() => {
    if (!tab) {
      setSearchParams((prevParams) =>
        Object.assign({}, prevParams, { tab: tabOptions[0].key })
      );
    }
  }, [tabOptions]);

  const onTabChange = useCallback((key: Key) => {
    setActiveTab(key);
    setSearchParams((prevParams) =>
      Object.assign({}, prevParams, { tab: key })
    );
  }, []);

  return {
    activeTab,
    onTabChange,
  };
}

export function MaterialPropertiesMaintainPage() {
  const tabs = [
    { key: 'group-code', label: '物料群組', panel: <MaterialGroupCodePanel /> },
    {
      key: 'class-code',
      label: '產品階層',
      panel: <MaterialClassCodePanel />,
    },
    { key: 'source-code', label: '身份碼', panel: <MaterialSourceCodePanel /> },
    {
      key: 'feature-code',
      label: '特徵碼',
      panel: <MaterialFeatureCodePanel />,
    },
    {
      key: 'machining-code',
      label: '加工碼',
      panel: <MaterialMachiningCodePanel />,
    },
    {
      key: 'sampling-code',
      label: '取樣碼',
      panel: <MaterialSamplingCodePanel />,
    },
    { key: 'waste-code', label: '環境碼', panel: <MaterialWasteCodePanel /> },
  ];

  const { activeTab, onTabChange } = useTabsRouting(tabs);

  return (
    <PageLayout disabledDivider title="料號屬性維護">
      <Tabs activeKey={activeTab} onChange={onTabChange}>
        {tabs.map((tab) => (
          <TabPane key={tab.key} tab={<Tab>{tab.label}</Tab>}>
            {tab.panel}
          </TabPane>
        ))}
      </Tabs>
    </PageLayout>
  );
}
