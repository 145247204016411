import {
  AutoCompleteField,
  DateTimePickerField,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import { useForm } from 'react-hook-form';
import {
  Button,
  MenuDivider,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SelectValue,
  Typography,
} from '@mezzanine-ui/react';
import styles from './SPOrderForm.module.scss';
import {
  SPFormOrderItemInfo,
  optionsStandardCurrancy,
  optionsSPItemUnit,
  useStrikePriceUnit,
  optionsReportCurrancy,
  SPExchangeRate,
  SPPremiun,
  SPExchangeUnit,
  SPSolarQuotation,
  ISPEditPageMode,
  SPEditPageMode,
  SPItemValidateSchema,
  SPItemValidateAddSchema,
} from '@solar/data';
import { useEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { yupResolver } from '@hookform/resolvers/yup';

interface ISPOrderFormProps {
  open: boolean;
  onFinish?: (item?: SPFormOrderItemInfo) => void;
  autoCompleteOptionsComponentNames: SelectValue<string>[];
  itemInfo: SPFormOrderItemInfo | null;
  reportType: string;
  tradeMode: string;
  reportMode: string;
  pageMode: ISPEditPageMode;
}

interface ICalCResult {
  value: string;
  msg: string;
}

const disabledMapping = (
  columnName: string,
  pageMode: ISPEditPageMode,
  reportMode: string,
  reportType: string
): boolean => {
  const addEnableList = [
    'component',
    // 'premium', // 2024/02/20 需求變更, 加減碼不可編輯
    // 'exchangePremium', // 2024/02/20 需求變更, 加減碼不可編輯
    'exchange',
    'standardCurrency',
    'standardUnit',
    'platformPrice',
    'standardTime',
    'reportCurrency',
    'reportUnit',
    'reportUnitPrice',
    'quoteUnitPrice',
    'tempQty',
    'checkQty',
  ];
  const solarEditEnableList = [
    'exchange',
    'unit',
    'reportCurrency',
    'reportUnit',
    'quoteUnitPrice',
    'tempQty',
    'checkQty',
  ];
  const otherEditEnableList = [
    'exchange',
    'standardCurrency',
    'standardUnit',
    'platformPrice',
    'standardTime',
    'reportCurrency',
    'reportUnit',
    'quoteUnitPrice',
    'tempQty',
    'checkQty',
    // 'premium', // 2024/02/20 需求變更, 加減碼不可編輯
    // 'exchangePremium', // 2024/02/20 需求變更, 加減碼不可編輯
    'reportUnitPrice',
    'component',
  ];
  const approveEnableList = [
    'component',
    'premium',
    'exchangePremium',
    'exchange',
    'standardCurrency',
    'standardUnit',
    'platformPrice',
    'standardTime',
    'reportCurrency',
    'reportUnit',
    'reportUnitPrice',
    'quoteUnitPrice',
    'tempQty',
    'checkQty',
    'interUnitPrice',
  ];

  let disabled = true;
  if (pageMode === SPEditPageMode.ADD) {
    disabled = !addEnableList.includes(columnName);
  } else if (
    pageMode === SPEditPageMode.EDIT &&
    reportMode === '0' &&
    reportType === '0'
  ) {
    disabled = !solarEditEnableList.includes(columnName);
  } else if (pageMode === SPEditPageMode.APPROVE) {
    disabled = !approveEnableList.includes(columnName);
  } else {
    disabled = !otherEditEnableList.includes(columnName);
  }
  return disabled;
};

const SPItemFormModal = ({
  open,
  onFinish = () => {
    /** */
  },
  autoCompleteOptionsComponentNames,
  itemInfo,
  reportType,
  tradeMode,
  reportMode,
  pageMode,
}: ISPOrderFormProps) => {
  const [rateMsg, setRateMsg] = useState('');
  const [priceMsg, setPriceMsg] = useState('');
  const [quPriceMsg, setQuPriceMsg] = useState('');
  const [unitRateMsg, setUnitRateMsg] = useState('');
  const [interPriceMsg, setInterPriceMsg] = useState('');
  const itemForm = useForm<SPFormOrderItemInfo>({
    resolver: yupResolver(
      reportMode === '0' ? SPItemValidateSchema : SPItemValidateAddSchema
    ), // Use yupResolver here
  });
  const {
    solarQuotation,
    getSolarQuotation,
    premiumList,
    exchangeUnit,
    exchangeRate,
  } = useStrikePriceUnit();

  const isSolarPrice = reportType === '0';

  const onSubmit = (formData: SPFormOrderItemInfo) => {
    setUnitRateMsg('');
    setPriceMsg('');
    setQuPriceMsg('');
    setRateMsg('');
    setInterPriceMsg('');
    onFinish(formData);
  };

  const onCancel = () => {
    setUnitRateMsg('');
    setPriceMsg('');
    setQuPriceMsg('');
    setRateMsg('');
    setInterPriceMsg('');
    onFinish();
  };

  // 一開始 render 的時候重置表單
  useEffect(() => {
    if (itemInfo) {
      itemForm.reset(itemInfo);
    } else {
      itemForm.reset({ key: Math.random().toString() });
    }
  }, [itemInfo, itemForm]);

  const title = itemInfo ? '編輯敲價項目' : '新增敲價項目';

  const calcInterUnitPrice = () => {
    const formValues = itemForm.getValues();
    if (
      formValues.component?.id &&
      formValues.reportUnitPrice &&
      formValues.reportCurrency?.id &&
      formValues.reportUnit?.id
    ) {
      const rpU = formValues.component.id === 'Au' ? 'T.O' : 'TOZ';
      const exchangR2 = calcExchange(
        'USD',
        formValues.reportCurrency?.id,
        exchangeRate,
        tradeMode
      );
      const unitR2 = calcUnitRate(formValues.reportUnit?.id, rpU, exchangeUnit);
      const unitPrice = (
        Number(formValues.reportUnitPrice) *
        Number(exchangR2?.value) *
        Number(unitR2?.value)
      ).toFixed(3);
      const msg = `敲價單價${formValues.reportUnitPrice} * 目前匯率${exchangR2?.value} * 單位率${unitR2?.value} = ${unitPrice}`;
      setInterPriceMsg(msg);
      itemForm.setValue('interUnitPrice', unitPrice);
    } else {
      itemForm.setValue('interUnitPrice', '');
    }
  };

  const calcExchange = (
    rpC?: string,
    stanC?: string,
    exRateList: SPExchangeRate[] = [],
    tm?: string
  ): ICalCResult | null => {
    if (rpC && stanC) {
      const rateInItem = exRateList.find((item) => item.currency === stanC);
      const rateOutItem = exRateList.find((item) => item.currency === rpC);
      console.log('tradeMode', tm);
      console.log('rateInItem', rateInItem);
      console.log('rateOutItem', rateOutItem);
      const rateIn =
        tm === '0' ? rateInItem?.inRate || 1 : rateInItem?.outRate || 1;
      const rateOut =
        tm === '0' ? rateOutItem?.inRate || 1 : rateOutItem?.outRate || 1;
      const rate = (rateIn / rateOut).toString();
      return {
        value: rate,
        msg: ` ${rateIn}/${rateOut} = ${rate}`,
      };
    }
    return null;
  };

  const calcPremium = (
    com?: string,
    stanU?: string,
    pList: SPPremiun[] = [],
    rt?: string,
    tm?: string
  ): ICalCResult | null => {
    if (com && rt && tm && stanU) {
      const premiunItem = pList.find(
        (data) =>
          data.elementName === com &&
          data.reportType === rt &&
          data.tradeMode === tm &&
          data.unit === stanU
      );
      const premium = premiunItem?.price || 0;
      return {
        value: premium.toString(),
        msg: ` ${premium}`,
      };
    }
    return null;
  };

  const calcExchangePremium = (pm?: string, stndC?: string) => {
    console.log('calcExchangePremium', pm, stndC);
    if (pm && stndC) {
      const exchangeResult = calcExchange(
        stndC,
        'USD',
        exchangeRate,
        tradeMode
      );
      if (exchangeResult) {
        const ep = (Number(pm) * Number(exchangeResult.value)).toFixed(3);
        const msg = `exchangePremium: ${pm} * ${exchangeResult.value} = ${ep}`;
        console.log(msg);
        itemForm.setValue('exchangePremium', ep);
        return {
          value: ep,
          msg: msg,
        };
      }
    }
    itemForm.setValue('exchangePremium', '');
    return null;
  };

  const calcUnitRate = (
    stanU?: string,
    rpU?: string,
    exchangeUnitList: SPExchangeUnit[] = []
  ): ICalCResult | null => {
    if (stanU && rpU) {
      const inRateItem = exchangeUnitList.find((item) => item.unit === stanU);
      const outRateItem = exchangeUnitList.find((item) => item.unit === rpU);
      const outRate = outRateItem?.exchangeValue || 1;
      const inRate = inRateItem?.exchangeValue || 1;
      const rate = (outRate / inRate).toString();
      return {
        value: rate,
        msg: ` ${outRate}/${inRate} = ${rate}`,
      };
    }
    return null;
  };

  // 計算盤價
  const calcPlatformPrice = (
    com?: string,
    stanC?: string,
    stanU?: string,
    tm?:string,
    quotationList: SPSolarQuotation[] = []
  ): ICalCResult | null => {
    if (com && stanC && stanU) {
      const findQuotation = quotationList.find(
        (d) => d.currency === stanC && d.unit === stanU && d.elementName === com && d.tradeMode === tm
      );
      return {
        value: findQuotation?.price.toString() || '0',
        msg: findQuotation?.sysDate || '',
      };
    }
    return null;
  };

  const calcReportUnitPrice = (
    exc?: string,
    pp?: string,
    unitRate?: string,
    prem?: string
  ): ICalCResult | null => {
    if (exc && pp && unitRate && prem) {
      const rate =
        Number(pp) * Number(unitRate) * Number(exc) +
        Number(unitRate) * Number(exc) * Number(prem);
      const value = Number.isNaN(rate) ? '0' : rate.toFixed(3);
      return {
        value,
        msg: `盤價換算: (平台盤價 + 盤價加減碼) * 匯率 * 單位轉換率 = (${pp} + ${prem}) * ${exc} * ${unitRate} = ${value}`,
      };
    }
    return null;
  };

  // const calcQuoteUnitPrice = (
  //   rup?: string, unitRate?: string, exc?: string, prem?: string
  // ): ICalCResult | null => {
  //   if (rup && unitRate && exc && prem) {
  //     const rate = (Number(rup) + (Number(unitRate) * Number(exc) * Number(prem)))
  //     const value = Number.isNaN(rate) ? '0' : rate.toFixed(3);
  //     return {
  //       value,
  //       msg: `${rup} + (${unitRate} * ${exc} * ${prem}) = ${value}`,
  //     };
  //   }
  //   return null;
  // }

  // 當成份有改變，重新計算 premium, platformPrice, unitRate, reportUnitPrice, quoteUnitPrice
  const onComponentChange = useCallback(
    debounce(() => {
      const formValues = itemForm.getValues();
      const premiumResult = calcPremium(
        formValues.component?.id,
        formValues.standardUnit?.id,
        premiumList,
        reportType,
        tradeMode
      );
      if (premiumResult) {
        const expResult = calcExchangePremium(
          premiumResult.value,
          formValues.standardCurrency?.id
        );
        itemForm.setValue('premium', premiumResult.value);
        // 如果是光洋盤才需要幫忙計算盤價
        if (isSolarPrice) {
          const platformPriceResult = calcPlatformPrice(
            formValues.component?.id,
            formValues.standardCurrency?.id,
            formValues.standardUnit?.id,
            tradeMode,
            solarQuotation
          );

          if (platformPriceResult) {
            itemForm.setValue('platformPrice', platformPriceResult.value);
            itemForm.setValue('standardTime', platformPriceResult.msg);
            const unitRateResult = calcUnitRate(
              formValues.standardUnit?.id,
              formValues.reportUnit?.id,
              exchangeUnit
            );
            if (unitRateResult) {
              const reportUnitPriceResult = calcReportUnitPrice(
                formValues.exchange,
                platformPriceResult.value,
                unitRateResult.value,
                expResult?.value
              );
              setUnitRateMsg(unitRateResult.msg);
              if (reportUnitPriceResult) {
                itemForm.setValue(
                  'reportUnitPrice',
                  reportUnitPriceResult.value
                );
                setPriceMsg(reportUnitPriceResult.msg);
                itemForm.setValue(
                  'quoteUnitPrice',
                  reportUnitPriceResult.value
                );
              }
            }
          }
        }
      }

      calcInterUnitPrice();
    }, 200),
    [
      itemForm,
      premiumList,
      solarQuotation,
      reportType,
      tradeMode,
      exchangeRate,
      exchangeUnit,
      isSolarPrice,
    ]
  );

  // 加減碼有改變, 重新計算 quoteUnitPrice
  const onPremiumChange = useCallback(
    debounce(() => {
      const formValues = itemForm.getValues();
      const expResult = calcExchangePremium(
        formValues.premium,
        formValues.standardCurrency?.id
      );
      const unitRateResult = calcUnitRate(
        formValues.standardUnit?.id,
        formValues.reportUnit?.id,
        exchangeUnit
      );
      const result = calcReportUnitPrice(
        formValues.exchange,
        formValues.platformPrice,
        unitRateResult?.value || '',
        expResult?.value
      );
      // const result = calcQuoteUnitPrice(formValues.reportUnitPrice, unitRateResult?.value || '', formValues.exchange, formValues.premium);
      if (result) {
        setUnitRateMsg(unitRateResult?.msg || '');
        setPriceMsg(result.msg);
        itemForm.setValue('reportUnitPrice', result.value);
        itemForm.setValue('quoteUnitPrice', result.value);
      }

      calcInterUnitPrice();
    }, 1000),
    [itemForm, exchangeUnit, exchangeRate, exchangeUnit, tradeMode]
  );

  // 盤價幣別有改變, 重新計算 exchange, platformPrice, reportUnitPrice, quoteUnitPrice
  const onStandardCurrancyChange = useCallback(
    debounce(() => {
      const formValues = itemForm.getValues();
      const exchangeResult = calcExchange(
        formValues.reportCurrency?.id,
        formValues.standardCurrency?.id,
        exchangeRate,
        tradeMode
      );
      const expResult = calcExchangePremium(
        formValues.premium,
        formValues.standardCurrency?.id
      );
      if (exchangeResult) {
        itemForm.setValue('exchange', exchangeResult.value);
        const platformPriceResult = calcPlatformPrice(
          formValues.component?.id,
          formValues.standardCurrency?.id,
          formValues.standardUnit?.id,
          tradeMode,
          solarQuotation
        );
        if (platformPriceResult && isSolarPrice) {
          itemForm.setValue('platformPrice', platformPriceResult.value);
          itemForm.setValue('standardTime', platformPriceResult.msg);
          const unitRateResult = calcUnitRate(
            formValues.standardUnit?.id,
            formValues.reportUnit?.id,
            exchangeUnit
          );
          if (unitRateResult) {
            setUnitRateMsg(unitRateResult.msg);
            const reportUnitPriceResult = calcReportUnitPrice(
              exchangeResult.value,
              platformPriceResult.value,
              unitRateResult.value,
              expResult?.value
            );
            if (reportUnitPriceResult) {
              itemForm.setValue('reportUnitPrice', reportUnitPriceResult.value);
              itemForm.setValue('quoteUnitPrice', reportUnitPriceResult.value);
              setPriceMsg(reportUnitPriceResult.msg);
            }
          }
        }
      }

      calcInterUnitPrice();
    }, 200),
    [
      itemForm,
      exchangeRate,
      tradeMode,
      exchangeUnit,
      solarQuotation,
      isSolarPrice,
    ]
  );

  // 幣別有改變, 重新計算 exchange, reportUnitPrice, quoteUnitPrice
  const onCurrancyChange = useCallback(
    debounce(() => {
      const formValues = itemForm.getValues();
      const exchangeResult = calcExchange(
        formValues.reportCurrency?.id,
        formValues.standardCurrency?.id,
        exchangeRate,
        tradeMode
      );
      if (exchangeResult) {
        itemForm.setValue('exchange', exchangeResult.value);
        const unitRateResult = calcUnitRate(
          formValues.standardUnit?.id,
          formValues.reportUnit?.id,
          exchangeUnit
        );
        if (unitRateResult) {
          setUnitRateMsg(unitRateResult.msg);
          const reportUnitPriceResult = calcReportUnitPrice(
            exchangeResult.value,
            formValues.platformPrice,
            unitRateResult.value,
            formValues.exchangePremium
          );
          if (reportUnitPriceResult) {
            itemForm.setValue('reportUnitPrice', reportUnitPriceResult.value);
            itemForm.setValue('quoteUnitPrice', reportUnitPriceResult.value);
            setPriceMsg(reportUnitPriceResult.msg);
          }
        }
      }

      calcInterUnitPrice();
    }, 200),
    [itemForm, exchangeRate, tradeMode, exchangeUnit]
  );

  // 盤價單位有改變, 重新計算 premiumm, platformPrice, unitRate, reportUnitPrice, quoteUnitPrice
  const onStandardUnitChange = useCallback(
    debounce(() => {
      const formValues = itemForm.getValues();
      const premiumResult = calcPremium(
        formValues.component?.id,
        formValues.standardUnit?.id,
        premiumList,
        reportType,
        tradeMode
      );
      if (premiumResult) {
        const expResult = calcExchangePremium(
          premiumResult.value,
          formValues.standardCurrency?.id
        );
        itemForm.setValue('premium', premiumResult.value);
        const platformPriceResult = calcPlatformPrice(
          formValues.component?.id,
          formValues.standardCurrency?.id,
          formValues.standardUnit?.id,
          tradeMode,
          solarQuotation
        );
        if (platformPriceResult && isSolarPrice) {
          itemForm.setValue('platformPrice', platformPriceResult.value);
          itemForm.setValue('standardTime', platformPriceResult.msg);
          const unitRateResult = calcUnitRate(
            formValues.standardUnit?.id,
            formValues.reportUnit?.id,
            exchangeUnit
          );
          if (unitRateResult) {
            const reportUnitPriceResult = calcReportUnitPrice(
              formValues.exchange,
              platformPriceResult.value,
              unitRateResult.value,
              expResult?.value
            );
            setUnitRateMsg(unitRateResult.msg);
            if (reportUnitPriceResult) {
              itemForm.setValue('reportUnitPrice', reportUnitPriceResult.value);
              itemForm.setValue('quoteUnitPrice', reportUnitPriceResult.value);
              setPriceMsg(reportUnitPriceResult.msg);
            }
          }
        }
      }

      calcInterUnitPrice();
    }, 200),
    [
      premiumList,
      reportType,
      tradeMode,
      itemForm,
      solarQuotation,
      exchangeRate,
      exchangeUnit,
    ]
  );

  // 敲價單位有改變, 重新計算 unitRate, reportUnitPrice, quoteUnitPrice
  const onReportUnitChange = useCallback(
    debounce(() => {
      const formValues = itemForm.getValues();
      const unitRateResult = calcUnitRate(
        formValues.standardUnit?.id,
        formValues.reportUnit?.id,
        exchangeUnit
      );
      if (unitRateResult) {
        setUnitRateMsg(unitRateResult.msg);
        const reportUnitPriceResult = calcReportUnitPrice(
          formValues.exchange,
          formValues.platformPrice,
          unitRateResult.value,
          formValues.exchangePremium
        );
        if (reportUnitPriceResult) {
          itemForm.setValue('reportUnitPrice', reportUnitPriceResult.value);
          itemForm.setValue('quoteUnitPrice', reportUnitPriceResult.value);
          setPriceMsg(reportUnitPriceResult.msg);
        }
      }

      calcInterUnitPrice();
    }, 200),
    [itemForm, exchangeUnit, exchangeRate, exchangeUnit, tradeMode]
  );

  // 平台盤價有改變, 重新計算 reportUnitPrice, quoteUnitPrice
  const onPlatformPriceChange = useCallback(
    debounce(() => {
      const formValues = itemForm.getValues();
      const unitRateResult = calcUnitRate(
        formValues.standardUnit?.id,
        formValues.reportUnit?.id,
        exchangeUnit
      );
      if (unitRateResult) {
        setUnitRateMsg(unitRateResult.msg);
        const reportUnitPriceResult = calcReportUnitPrice(
          formValues.exchange,
          formValues.platformPrice,
          unitRateResult.value,
          formValues.exchangePremium
        );
        if (reportUnitPriceResult) {
          itemForm.setValue('reportUnitPrice', reportUnitPriceResult.value);
          itemForm.setValue('quoteUnitPrice', reportUnitPriceResult.value);
          setPriceMsg(reportUnitPriceResult.msg);
        }
      }
      calcInterUnitPrice();
    }, 1000),
    [itemForm, exchangeUnit, exchangeRate, tradeMode]
  );

  // 敲價單價有改變, 重新計算 quoteUnitPrice
  // 更新: reportUnitPrice 已經不影響 quoteUnitPrice
  // 更新: 2024/02/20 重新計算國際加減碼, 國際單價
  const onReportUnitPriceChange = useCallback(
    debounce(() => {
      calcInterUnitPrice();
    }, 1000),
    [itemForm, exchangeUnit, exchangeRate, tradeMode]
  );

  // 匯率有改變, 重新計算 unitRate, reportUnitPrice, quoteUnitPrice
  const onExchangeChange = useCallback(
    debounce(() => {
      const formValues = itemForm.getValues();
      const unitRateResult = calcUnitRate(
        formValues.standardUnit?.id,
        formValues.reportUnit?.id,
        exchangeUnit
      );
      if (unitRateResult) {
        setUnitRateMsg(unitRateResult.msg);
        const reportUnitPriceResult = calcReportUnitPrice(
          formValues.exchange,
          formValues.platformPrice,
          unitRateResult.value,
          formValues.exchangePremium
        );
        if (reportUnitPriceResult) {
          itemForm.setValue('reportUnitPrice', reportUnitPriceResult.value);
          itemForm.setValue('quoteUnitPrice', reportUnitPriceResult.value);
          setPriceMsg(reportUnitPriceResult.msg);
        }
      }
    }, 1000),
    [itemForm, exchangeUnit, tradeMode]
  );

  // 重新取得盤價
  const onRefreshPlatformPrice = async () => {
    await getSolarQuotation();
    const formValues = itemForm.getValues();
    const platformPriceResult = calcPlatformPrice(
      formValues.component?.id,
      formValues.standardCurrency?.id,
      formValues.standardUnit?.id,
      tradeMode,
      solarQuotation
    );
    if (platformPriceResult) {
      itemForm.setValue('platformPrice', platformPriceResult.value);
      itemForm.setValue('standardTime', platformPriceResult.msg);
    }
  };

  const [rup, qup] = itemForm.watch(['reportUnitPrice', 'quoteUnitPrice']);
  useEffect(() => {
    // console.log('reportUnitPrice', rup, 'quoteUnitPrice', qup);
    if (rup && qup && Number(rup) > Number(qup)) {
      setQuPriceMsg('提醒: 報價單價小於敲價單價');
    } else {
      setQuPriceMsg('');
    }
  }, [rup, qup]);

  const onChangeOthersColumn = debounce(calcInterUnitPrice, 400);

  return (
    <Modal
      open={open}
      disableCloseOnBackdropClick
      disableCloseOnEscapeKeyDown
      style={{ width: 1000 }}
      onClose={onCancel}
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <div>
          <FormFieldsWrapper methods={itemForm} onSubmit={onSubmit}>
            <div className={styles.formRow}>
              <div className={styles.formColLeft}>
                <div>
                  <AutoCompleteField
                    registerName="component"
                    label="成分"
                    options={autoCompleteOptionsComponentNames}
                    onChange={onComponentChange}
                    required
                    disabled={disabledMapping(
                      'component',
                      pageMode,
                      reportMode,
                      reportType
                    )}
                  />
                </div>
                <div>
                  <InputField
                    type="number"
                    registerName="premium"
                    label="加減碼(USD)"
                    onChange={
                      isSolarPrice ? onPremiumChange : onChangeOthersColumn
                    }
                    clearable={false}
                    disabled={disabledMapping(
                      'premium',
                      pageMode,
                      reportMode,
                      reportType
                    )}
                  />
                </div>
              </div>
              <div className={styles.formCol}>
                <div>
                  <InputField
                    registerName="componentRate"
                    label="成分比例(%)"
                    disabled
                  />
                </div>

                <div>
                  <InputField
                    registerName="exchange"
                    label={`匯率${rateMsg}`}
                    onChange={isSolarPrice ? onExchangeChange : undefined}
                    disabled={disabledMapping(
                      'exchange',
                      pageMode,
                      reportMode,
                      reportType
                    )}
                  />
                </div>
              </div>
            </div>
            <MenuDivider style={{ marginBottom: 12, marginTop: 12 }} />
            <div className={styles.formRow}>
              <div className={styles.formColLeft}>
                <div>
                  <SelectField
                    registerName="standardCurrency"
                    label="盤價幣別"
                    options={optionsStandardCurrancy}
                    onChange={onStandardCurrancyChange}
                    disabled={disabledMapping(
                      'standardCurrency',
                      pageMode,
                      reportMode,
                      reportType
                    )}
                  />
                </div>
                <div>
                  <SelectField
                    registerName="standardUnit"
                    label="盤價單位"
                    options={optionsSPItemUnit}
                    onChange={onStandardUnitChange}
                    disabled={disabledMapping(
                      'standardUnit',
                      pageMode,
                      reportMode,
                      reportType
                    )}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <InputField
                    registerName="platformPrice"
                    label="平台盤價"
                    onChange={
                      isSolarPrice
                        ? onPlatformPriceChange
                        : onChangeOthersColumn
                    }
                    clearable={false}
                    disabled={disabledMapping(
                      'platformPrice',
                      pageMode,
                      reportMode,
                      reportType
                    )}
                  />
                  {isSolarPrice && (
                    <Button
                      type="button"
                      onClick={onRefreshPlatformPrice}
                      disabled={disabledMapping(
                        'platformPrice',
                        pageMode,
                        reportMode,
                        reportType
                      )}
                    >
                      重新取得盤價
                    </Button>
                  )}
                </div>
                <div>
                  <InputField
                    registerName="exchangePremium"
                    label="加減碼(盤價單位)"
                    disabled={disabledMapping(
                      'exchangePremium',
                      pageMode,
                      reportMode,
                      reportType
                    )}
                  />
                </div>
                <div>
                  <DateTimePickerField
                    registerName="standardTime"
                    label="盤價時間"
                    disabled={disabledMapping(
                      'standardTime',
                      pageMode,
                      reportMode,
                      reportType
                    )}
                  />
                </div>
              </div>
              <div className={styles.formCol}>
                <div>
                  <SelectField
                    registerName="reportCurrency"
                    label="敲價幣別"
                    required
                    options={optionsReportCurrancy}
                    onChange={
                      isSolarPrice ? onCurrancyChange : onChangeOthersColumn
                    }
                    disabled={disabledMapping(
                      'reportCurrency',
                      pageMode,
                      reportMode,
                      reportType
                    )}
                  />
                </div>
                <div>
                  <SelectField
                    registerName="reportUnit"
                    label="敲價單位"
                    options={optionsSPItemUnit}
                    onChange={
                      isSolarPrice ? onReportUnitChange : onChangeOthersColumn
                    }
                    required
                    disabled={disabledMapping(
                      'reportUnit',
                      pageMode,
                      reportMode,
                      reportType
                    )}
                  />
                  <Typography variant="input3" color="text-secondary">
                    {unitRateMsg}
                  </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <InputField
                    registerName="reportUnitPrice"
                    label={`敲價單價`}
                    onChange={
                      isSolarPrice
                        ? onReportUnitPriceChange
                        : onChangeOthersColumn
                    }
                    clearable={false}
                    required={reportMode === '0' ? true : false}
                    disabled={disabledMapping(
                      'reportUnitPrice',
                      pageMode,
                      reportMode,
                      reportType
                    )}
                  />
                </div>
                <Typography variant="input3" color="text-secondary">
                  {priceMsg}
                </Typography>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <InputField
                    registerName="quoteUnitPrice"
                    label="報價單價"
                    clearable={false}
                    required={reportMode === '0' ? true : false}
                    disabled={disabledMapping(
                      'quoteUnitPrice',
                      pageMode,
                      reportMode,
                      reportType
                    )}
                  />
                </div>
                <Typography variant="input3" color="text-secondary">
                  {quPriceMsg}
                </Typography>
              </div>
            </div>
            <MenuDivider style={{ marginBottom: 12, marginTop: 12 }} />
            <div className={styles.formRow}>
              <div className={styles.formCol} style={{ marginBottom: 16 }}>
                <InputField
                  registerName="tempQty"
                  label="暫敲數量(G)"
                  disabled={disabledMapping(
                    'tempQty',
                    pageMode,
                    reportMode,
                    reportType
                  )}
                />
              </div>
              <div className={styles.formCol}>
                <InputField
                  registerName="checkQty"
                  label="確認數量(G)"
                  disabled={disabledMapping(
                    'checkQty',
                    pageMode,
                    reportMode,
                    reportType
                  )}
                />
              </div>
            </div>
            <div className={styles.formRow}>
              <div className={styles.formCol} style={{ marginBottom: 16 }}>
                <InputField
                  registerName="interUnitPrice"
                  label="國際單價"
                  disabled={disabledMapping(
                    'interUnitPrice',
                    pageMode,
                    reportMode,
                    reportType
                  )}
                />
                <Typography variant="input3" color="text-secondary">
                  {interPriceMsg}
                </Typography>
              </div>
            </div>
          </FormFieldsWrapper>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="contained"
          color="primary"
          onClick={itemForm.handleSubmit(onSubmit)}
        >
          確認
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SPItemFormModal;
