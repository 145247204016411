import styles from './Basic-Info.module.scss';
import { Typography } from '@mezzanine-ui/react';
import {
  InputField,
  DatePickerField,
  DateTimePickerField,
} from '@mezzanine-ui/react-hook-form';
import { LogisticsTypeSelector } from '../../Selectors/LogisticsType-Selector/LogisticsType-Selector';
import { ApplyUntSelector } from '../../Selectors/ApplyUnit-Selector/ApplyUnit-Selector';
import { CostBUSelector } from '../../Selectors/CostBU-Selector/CostBU-Selector';
import { CorrespondentTypeSelector } from '../../Selectors/CorrespondentType-Selector/CorrespondentType-Selector';
import { DeliveryAreaSelector } from '../../Selectors/DeliveryArea-Selector/DeliveryArea-Selector';

export function BasicInfoForm() {
  return (
    <div className={styles.container}>
      <Typography variant="h3" className={styles.title}>
        派車單申請
      </Typography>
      <InputField label="申請人員" registerName="applicant" required />
      <DatePickerField
        className={styles.datePicker}
        registerName="applyDate"
        label="申請日期"
        required
      />
      {/* <InputField label="確認人員" registerName="confirmor" required />
      <DatePickerField
        className={styles.datePicker}
        registerName="confirmDate"
        label="確認日期"
        required
      /> */}
      <LogisticsTypeSelector />
      <DatePickerField
        required
        className={styles.datePicker}
        registerName="dispatchDate"
        label="預定派車日"
      />
      <ApplyUntSelector />
      <CostBUSelector />
      <InputField label="聯絡人" required registerName="contact" />
      <InputField label="聯絡電話" required registerName="contactPhone" />
      <CorrespondentTypeSelector />
      <DeliveryAreaSelector />
      <DateTimePickerField
        registerName="specifiedTimeStart"
        label="指定時間(起)"
      />
      <DateTimePickerField
        registerName="specifiedTimeEnd"
        label="指定時間(迄)"
      />
    </div>
  );
}
