import { API_NAMESPACE } from '@solar/data';
import { useParams } from 'react-router-dom';
import { InspectMaterialType } from './inspect-modal-enum';
import { ReceiveActionInputs } from './Modals/ReceiveActionModal';
import { TempCustomerSupplyDetailPage } from './TempCustomerSupplyDetailPage';
import { TempDetailPage } from './TempDetailPage';
import { RentTargetDetailPage } from './RentTargetDetailPage';
import { OutsourcePurchaseOrderDetailPage } from './OutsourcePurchaseOrderDetailPage';
import { HubDeliveryOrderPage } from '../../SalesAndDistribution/HubDeliveryOrderPage/HubDeliveryOrderPage';
import { HubReturnDetailPage } from './HubReturnDetailPage';
import { VirtualInDetailPage } from './VirtualInDetailPage';

export type ReceivableItemTable = {
  id: string;
  materialId: string;
  oldMaterialId: string;
  materialDescription: string;
  categoryName: string;
  groupName: string;
  expectedMainMaterialUnitQuantity: number;
  receivedMainMaterialUnitQuantity?: number;
  specId?: string;
};

export type ReceivedMaterialItemTable = ReceivableItemTable &
  ReceiveActionInputs;

export function IncomingInspectDetailPage() {
  const { orderType } = useParams<{ orderType: `${InspectMaterialType}` }>();

  return (
    <>
      {orderType === InspectMaterialType.PurchaseOrder && <TempDetailPage />}
      {orderType === InspectMaterialType.CustomerOrder && (
        <TempCustomerSupplyDetailPage />
      )}
      {orderType === InspectMaterialType.OutsourcingOrder && (
        <OutsourcePurchaseOrderDetailPage />
      )}
      {orderType === InspectMaterialType.RentTargetOrder && (
        <RentTargetDetailPage />
      )}
      {orderType === InspectMaterialType.HubReturn && <HubReturnDetailPage />}
      {orderType === InspectMaterialType.VirtualIn && <VirtualInDetailPage />}
    </>
  );
}
