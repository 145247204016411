import { DownloadIcon } from '@mezzanine-ui/icons';
import { Button, Icon, Table, Typography } from '@mezzanine-ui/react';
import {
  DateRangePickerField,
  FormFieldsWrapper,
  SearchInputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  ExportListFilterPayload,
  statusOptions,
  useHumanResourceBUReportExportList,
} from '@solar/data';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Header } from '../HumanResourceReportsComponents/HumanResourceReportsComponents';
import { DetailModal } from './DetailModal/DetailModal';
import styles from './HumanResourceReportDetailsPage.module.scss';

type ColumnType = Record<'name' | 'id' | 'bonusConditions', string>;

export function HumanResourceReportDetailsPage() {
  const {
    loading,
    data: exportData,
    filterMutation: filterHumanResourceBUReportList,
    isExportSource,
  } = useHumanResourceBUReportExportList();
  const exportList = exportData?.data;

  const methods = useForm();
  const [page, setPage] = useState(1);
  const [modal, setModal] = useState<ColumnType | null>(null);
  const [per_page, setPer_page] = useState(exportData?.per_page);
  const [updateFilterObject, setUpdateFilterObject] = useState(true);

  const columns = [
    { title: '#', dataIndex: 'id', width: 120 },
    { title: '報表名稱', dataIndex: 'name' },
    { title: '狀態', dataIndex: 'status', width: 200 },
    { title: '生成日期', dataIndex: 'createdAt' },
    {
      title: '篩選條件',
      width: 120,
      render(source: ColumnType) {
        return (
          <Button
            variant="outlined"
            type="button"
            onClick={() => {
              setModal({
                name: source.name,
                id: source.id,
                bonusConditions: source.bonusConditions,
              });
            }}
          >
            查看
          </Button>
        );
      },
    },
    {
      title: '下載',
      width: 70,
      render(source: unknown) {
        if (!isExportSource(source)) return;
        if (!source.filePath) return null;
        return (
          <a
            href={source.filePath}
            download="proposed_file_name"
            className={styles.filePath}
          >
            <Icon icon={DownloadIcon} size={32} />
          </a>
        );
      },
    },
  ];

  const onClose = () => {
    setModal(null);
  };

  const status = methods.getValues('statusField');
  const nameInput = methods.getValues('searchInput');
  const date = methods.getValues('date');

  const filterObject: ExportListFilterPayload = {
    status: status?.name,
    dateStart: date ? date[0] : undefined,
    dateEnd: date ? date[1] : undefined,
    name: nameInput,
  };

  const searchedFilterObject = useMemo(() => {
    return filterObject;
  }, [updateFilterObject]);

  return (
    <div className={styles.wrapper}>
      {modal && (
        <DetailModal
          name={modal.name}
          bonusConditions={modal.bonusConditions}
          onClose={onClose}
        />
      )}
      <Header title="匯出報表明細" button={null} />
      <FormFieldsWrapper methods={methods}>
        <div className={styles.flex}>
          <div className={styles.filter}>
            <Typography
              className={styles.text}
              style={{ marginLeft: 0 }}
              variant="h6"
              color="secondary-light"
            >
              狀態：
            </Typography>
            <SelectField
              registerName="statusField"
              placeholder="處理狀態"
              width={120}
              options={statusOptions ?? []}
              clearable={true}
            />
            <Typography
              className={styles.text}
              variant="h6"
              color="secondary-light"
            >
              生成日期：
            </Typography>
            <DateRangePickerField registerName="date" width={296} />
            <Typography
              className={styles.text}
              variant="h6"
              color="secondary-light"
            >
              報表名稱：
            </Typography>
            <SearchInputField
              registerName="searchInput"
              className={styles.searchInput}
              placeholder="輸入關鍵字"
              clearable={true}
            />
          </div>
          <Button
            type="button"
            variant="contained"
            onClick={() => {
              setUpdateFilterObject(!updateFilterObject);
              filterHumanResourceBUReportList({
                ...filterObject,
                page: 1,
                per_page,
              });
              setPage(1);
            }}
          >
            送出篩選條件
          </Button>
        </div>
        <Table
          loading={loading}
          headerClassName={styles.header}
          bodyClassName={styles.table}
          dataSource={exportList ?? []}
          columns={columns}
          pagination={{
            disableAutoSlicing: true,
            total: exportData?.total,
            current: page,
            onChange: (page) => {
              filterHumanResourceBUReportList({
                ...searchedFilterObject,
                page,
                per_page,
              });
              setPage(page);
            },
            options: {
              pageSize: per_page,
              onChangePageSize: (pageSize: number) => {
                filterHumanResourceBUReportList({
                  ...searchedFilterObject,
                  page: 1,
                  per_page: pageSize,
                });
                setPer_page(pageSize);
                setPage(1);
              },
              showPageSizeOptions: true,
              pageSizeLabel: '每頁顯示：',
              pageSizeUnit: '筆',
            },
          }}
        />
      </FormFieldsWrapper>
    </div>
  );
}
