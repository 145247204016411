import { API_NAMESPACE, request, WaitReceiptStatus } from '@solar/data';

const namespace = API_NAMESPACE.PP;

export const exceptionStatusConvert = ({
  id,
  updateStatus,
}: {
  id: string | number;
  updateStatus: `${WaitReceiptStatus}`;
}): Promise<Response> => {
  return request(`/pp/wait-receipt/${id}/${updateStatus}`, {
    namespace,
    method: 'put',
    responseParser: (res) => res,
  });
};
