import { useAutoAnimate } from '@formkit/auto-animate/react';
import { TrashIcon } from '@mezzanine-ui/icons';
import {
  Button,
  Icon,
  MenuDivider,
  Message,
  ModalActions,
  SelectValue,
  Typography,
} from '@mezzanine-ui/react';
import {
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  updateProductionDeliveryOrderReceipt,
  updatePurchaseOrderReceipt,
  useDebounce,
  useGetIQCDeliveryOrders,
  useGetIQCPurchaseOrders,
  useInventoryByPurchaseOrder,
  useUnitConversionRatiosByMaterialId,
} from '@solar/data';
import {
  InputContainerWithUnit,
  LocationSelectorProvider,
  NumericInputField,
  RowSection,
  useLocationSelectorController,
  useTargetModal,
} from '@solar/templates';
import { Col, Row } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { LOCATION_SELECTOR } from '../../../Equipment/LocationSelector/LocationSelectorModal';
import { ModalLayout } from '../../ModalLayout/ModalLayout';
import { ReceivedMaterialSubItem } from '../TempDetailPage';
import { InspectMaterialType } from '../inspect-modal-enum';
import { ReceiveActionModalPassingData } from '../typings';
import { WarehouseAndVehicleFilter } from './WarehouseAndVehicleFilter';

export type ReceiveActionInputs = {
  batchTotal?: string;
  mainMaterialUnitsObj?: MainMaterialUnitsObj[];
  warehouse?: SelectValue;
  loader: SelectValue | null;
  recycleNumber?: string;
};

export type MainMaterialUnitsObj = {
  mainMaterialUnit: SelectValue | null; // mainMaterialUnit api 待補
  // mainMaterialUnitQuantity: string | null;
  materialInputUnitQuantity: string | null;
  notes: string | null;
  // receiveWeight: string | null;
  materialWeightUnitQuantity: string | null;
  materialInputUnit: SelectValue | null;
  stickerNum: number | null;
};

type TempReceiveActionModal = {
  locationSelectorController: ReturnType<typeof useLocationSelectorController>;
};

const updater = {
  [InspectMaterialType.PurchaseOrder]: updatePurchaseOrderReceipt,
  [InspectMaterialType.CustomerOrder]: updateProductionDeliveryOrderReceipt,
  // [InspectMaterialType.OutsourcingOrder]: ()=>{},
  // [InspectMaterialType.ReturnOrder]: ()=>{},
};

const statusIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const baseBatchSerial = {
  notes: null,
  mainMaterialUnit: null,
  materialInputUnitQuantity: null,
  materialWeightUnitQuantity: null,
  materialInputUnit: null,
  stickerNum: null,
};

export function TempReceiveActionModal({
  locationSelectorController: controller,
}: TempReceiveActionModal) {
  const { orderType } = useParams<{
    orderType:
      | InspectMaterialType.PurchaseOrder
      | InspectMaterialType.CustomerOrder;
  }>();
  const receiveOrReturnMaterialMethods = useForm<ReceiveActionInputs>({
    defaultValues: {
      mainMaterialUnitsObj: [
        { materialInputUnitQuantity: null, materialWeightUnitQuantity: null },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: receiveOrReturnMaterialMethods.control,
    name: 'mainMaterialUnitsObj',
  });

  const locationSelectorModalController =
    useTargetModal<null>(LOCATION_SELECTOR);
  const receiveActionModalController =
    useTargetModal<ReceiveActionModalPassingData>('ReceiveAction');
  const handleReceiveActionModalClose = useCallback(() => {
    receiveOrReturnMaterialMethods.reset();
    receiveActionModalController.closeModal();
  }, []);

  const [receiving, setReceiving] = useState(false);

  const warehouseAndVehicleFilterRef =
    useRef<React.ElementRef<typeof WarehouseAndVehicleFilter>>(null);

  const {
    specId,
    materialId,
    materialDescription,
    mainUnitName,
    /** 需求變更: SOL-1106 secondaryUnitName 改為 dropdown*/
    secondaryUnitName,
    materialStockUnit,
    quantityUnit,
  } = receiveActionModalController?.data ?? {};

  const { orderId } = useParams();
  const location = useLocation();
  const { mutate } = useInventoryByPurchaseOrder({
    orderId:
      location.pathname.split('/')[2] === InspectMaterialType.PurchaseOrder
        ? orderId
        : undefined,
  });
  const { data: conversionUnits } = useUnitConversionRatiosByMaterialId({
    materialId,
  });
  const { mutateGetIQCPurchaseOrders } = useGetIQCPurchaseOrders({
    statusIds,
    purchaseOrderId: orderId,
  });
  const { mutateGetIQCDeliveryOrders } = useGetIQCDeliveryOrders({
    statusIds,
    deliveryOrderId: orderId,
  });

  const [parent] = useAutoAnimate();
  // const [batchTotal, setBatchTotal] = useState('');
  const batchTotal = useWatch({
    control: receiveOrReturnMaterialMethods.control,
    name: 'batchTotal',
  });
  const debouncedBatchTotal = useDebounce<string>(batchTotal ?? '0', 700);

  useEffect(() => {
    receiveOrReturnMaterialMethods.setValue(
      'mainMaterialUnitsObj',
      Array(Math.max(0, Number(debouncedBatchTotal || 0))).fill(baseBatchSerial)
    );
  }, [debouncedBatchTotal]);

  return (
    <ModalLayout
      style={{ width: '1500px' }}
      loading={receiveOrReturnMaterialMethods.formState.isSubmitting}
      modalHeader="收料"
      modalFooter={
        <ModalActions
          confirmText="確認"
          cancelText="取消"
          confirmButtonProps={{ form: 'receiveActionForm', loading: receiving }}
          onCancel={() => handleReceiveActionModalClose()}
        />
      }
      {...receiveActionModalController}
      closeModal={handleReceiveActionModalClose}
    >
      <LocationSelectorProvider controller={controller}>
        <FormFieldsWrapper
          id="receiveActionForm"
          methods={receiveOrReturnMaterialMethods}
          onSubmit={async (values) => {
            try {
              setReceiving(true);

              const { selectedIds } = controller;
              const shelfId =
                selectedIds?.shelfId ??
                selectedIds?.stackId ??
                selectedIds?.zoneId;

              if (!shelfId) {
                warehouseAndVehicleFilterRef.current?.setLocationRequired(true);
                return;
              } else {
                warehouseAndVehicleFilterRef.current?.setLocationRequired(
                  false
                );
              }

              const subItems = (values?.mainMaterialUnitsObj ?? [])?.map(
                (row, index) => {
                  return {
                    id: fields[index].id,
                    materialInputUnitQuantity: row.materialInputUnitQuantity,
                    notes: row.notes,
                    materialWeightUnitQuantity: row.materialWeightUnitQuantity,
                    stickerNum: row.stickerNum,
                    receiveUnit: '桶',
                  };
                }
              ) satisfies Omit<
                ReceivedMaterialSubItem,
                'mainMaterialUnit' | 'materialInputUnit'
              >[]; // mainMaterialUnit api 待補

              const materialInputUnit = (
                (values?.mainMaterialUnitsObj ?? [])[0] ?? {}
              )?.mainMaterialUnit?.id;

              if (
                orderType === InspectMaterialType.PurchaseOrder &&
                (!materialInputUnit || !conversionUnits?.length)
              ) {
                Message.warning('請選擇單位');
                return;
              }

              if (!specId) {
                Message.error('系統錯誤，請重新整理試試');
                return;
              }

              const body = {
                items: [
                  {
                    specId: specId,
                    toShelfId: shelfId,
                    materialInputUnit:
                      orderType === InspectMaterialType.CustomerOrder
                        ? quantityUnit ?? ''
                        : conversionUnits?.[Number(materialInputUnit)]
                            ?.materialInputUnit ||
                          (conversionUnits?.[0]
                            ? conversionUnits?.[0]?.materialStockUnit
                            : ''),
                    batches: subItems.map((row) => ({
                      materialInputUnitQuantity: String(
                        row.materialInputUnitQuantity
                      ),
                      materialWeightUnitQuantity: String(
                        row.materialWeightUnitQuantity
                      ),
                      materialGrossWeightUnitQuantity: String(
                        row.materialWeightUnitQuantity
                      ),
                      remark: row.notes,
                      oldBatchId: null,
                    })),
                  },
                ],
                ...(values?.recycleNumber && {
                  recycleNumber: values?.recycleNumber,
                }),
              };

              const res = await updater[orderType ?? 'purchase-order'](body);

              if (res.ok) {
                await mutate?.(undefined, true);
                await mutateGetIQCPurchaseOrders?.(undefined, true);
                await mutateGetIQCDeliveryOrders?.(undefined, true);
                Message.success('收料成功');
                handleReceiveActionModalClose();
              }
            } catch (err) {
              if (err instanceof Error) {
                Message.error(JSON.parse(err.message).message);
              } else {
                Message.error('收料失敗');
              }
            } finally {
              setReceiving(false);
            }
          }}
        >
          <Typography variant="h4" color="text-primary">
            {`料號：${materialId}`}
          </Typography>
          <Typography variant="h4" color="text-primary">
            {`品名：${materialDescription}`}
          </Typography>
          <WarehouseAndVehicleFilter
            openLocationSelectorModal={
              locationSelectorModalController.openModal
            }
            ref={warehouseAndVehicleFilterRef}
            locationLabel="存放儲位"
          />
          <MenuDivider />
          <RowSection label="環保聯單號">
            <InputField
              registerName="recycleNumber"
              width={400}
              onKeyPress={(e) => {
                if (e.key == 'Enter') {
                  e.preventDefault();
                }

                const event =
                  e as unknown as React.ChangeEvent<HTMLInputElement>;

                if (event.target.value.length >= 16) {
                  event.preventDefault();
                  event.stopPropagation();
                }
              }}
            />
          </RowSection>
          <div>
            <Row align="middle">
              <Col span={2}>
                <Typography variant="h4" color="text-primary">
                  批號流水
                </Typography>
              </Col>

              <RowSection colGap="4px" label="批號數量：">
                <NumericInputField
                  registerName={'batchTotal'}
                  validateMode={'int'}
                />
              </RowSection>
            </Row>
            <div ref={parent}>
              {fields.map((row, index) => {
                const registerNameOfNotes =
                  `mainMaterialUnitsObj.${index}.notes` as const;
                const registerNameOfQuantity =
                  `mainMaterialUnitsObj.${index}.materialInputUnitQuantity` as const;
                // index -> 0: reason: api 邏輯再一次收料只接收一個單位
                const registerNameOfUnit =
                  `mainMaterialUnitsObj.${0}.mainMaterialUnit` as const;
                const registerNameOfWeight =
                  `mainMaterialUnitsObj.${index}.materialWeightUnitQuantity` as const;
                const registerNameOfStickerNum =
                  `mainMaterialUnitsObj.${index}.stickerNum` as const;

                return (
                  <div
                    style={{ display: 'flex', gap: '20px', marginTop: '12px' }}
                    key={row.id}
                  >
                    <InputContainerWithUnit
                      withBorder
                      prefixAdj="收料數量"
                      suffixUnit={
                        <>
                          {
                            orderType === InspectMaterialType.PurchaseOrder ? (
                              <SelectField
                                id={registerNameOfUnit}
                                placeholder="單位"
                                width={120}
                                registerName={registerNameOfUnit}
                                options={conversionUnits?.map((row, index) => ({
                                  id: String(index),
                                  name: row.materialInputUnitName,
                                }))}
                              />
                            ) : (
                              quantityUnit ?? ''
                            ) //(conversionUnits?.[0] ? conversionUnits?.[0]?.materialInputUnit : '')
                          }
                        </>
                      }
                      htmlFor={registerNameOfQuantity}
                    >
                      <InputField
                        id={registerNameOfQuantity}
                        registerName={registerNameOfQuantity}
                        type="number"
                      />
                    </InputContainerWithUnit>
                    <InputContainerWithUnit
                      withBorder
                      prefixAdj="貼紙數量"
                      htmlFor={registerNameOfStickerNum}
                    >
                      <InputField
                        id={registerNameOfStickerNum}
                        registerName={registerNameOfStickerNum}
                        type="number"
                      />
                    </InputContainerWithUnit>
                    <InputContainerWithUnit
                      withBorder
                      prefixAdj="收料重量"
                      suffixUnit={
                        orderType === InspectMaterialType.PurchaseOrder
                          ? mainUnitName
                          : 'G'
                      }
                      htmlFor={registerNameOfWeight}
                    >
                      <InputField
                        id={registerNameOfWeight}
                        registerName={registerNameOfWeight}
                        type="number"
                        onKeyPress={(e) => {
                          const value = (
                            e?.target as HTMLInputElement
                          )?.value?.replace('.', '');

                          if (orderType === InspectMaterialType.CustomerOrder) {
                            if (value.length >= 5) {
                              e.preventDefault();
                            }
                          }
                        }}
                      />
                    </InputContainerWithUnit>
                    <InputContainerWithUnit
                      withBorder
                      prefixAdj="備註"
                      htmlFor={registerNameOfNotes}
                    >
                      <InputField
                        id={registerNameOfNotes}
                        registerName={registerNameOfNotes}
                      />
                    </InputContainerWithUnit>
                    {index !== 0 && (
                      <Button
                        type="button"
                        prefix={<Icon icon={TrashIcon} />}
                        onClick={() => remove(index)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <div>
              {/* <Button
                variant="outlined"
                style={{ display: 'block', margin: '12px auto' }}
                type="button"
                onClick={() =>
                  append({
                    notes:null,
                    mainMaterialUnit: null,
                    materialInputUnitQuantity: null,
                    materialWeightUnitQuantity: null,
                    materialInputUnit: null,
                    stickerNum: null
                  })
                }
              >
                ＋流水號
              </Button> */}
            </div>
          </div>
        </FormFieldsWrapper>
      </LocationSelectorProvider>
    </ModalLayout>
  );
}
