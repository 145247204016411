import { updateNumberHistoryById } from '@solar/data';
import styles from './../../../TargetNumber.module.scss';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalActions,
  CalendarConfigProvider,
  Message,
  Typography,
} from '@mezzanine-ui/react';
import { useForm } from 'react-hook-form';
import { DispatchedTargetDisableModalFields } from '../../../TargetNumber.interface';
import CalendarMethodsMoment from '@mezzanine-ui/core/calendarMethodsMoment';
import {
  DatePickerField,
  FormFieldsWrapper,
} from '@mezzanine-ui/react-hook-form';
import { useEffect, useState } from 'react';
import moment from 'moment';

export function DispatchedTargetDisableModal({
  onClose,
  open,
  selectedNumberHistoryId,
  onSuccessfulDispatch,
  selectedItem,
}: {
  onClose: () => void;
  open: boolean;
  selectedNumberHistoryId: number | null;
  onSuccessfulDispatch?: () => void;
  selectedItem: any | null;
}) {
  const methods = useForm<DispatchedTargetDisableModalFields>({
    defaultValues: {
      disable_date: '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      methods.reset();
    }
  }, [open, methods]);

  const handleSubmit = methods.handleSubmit(async (data) => {
    if (selectedNumberHistoryId) {
      setIsLoading(true);
      try {
        const formattedDate = moment(data.disable_date).format('YYYY-MM-DD');
        await updateNumberHistoryById(selectedNumberHistoryId, {
          disable_date: formattedDate,
        });
        Message.success('停用成功');
        onClose();
        if (onSuccessfulDispatch) {
          onSuccessfulDispatch();
        }
      } catch (error) {
        Message.error('停用失敗');
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  });

  return (
    <Modal
      size="medium"
      hideCloseIcon
      className={styles.modal}
      onClose={onClose}
      open={open}
      severity="warning"
      loading={isLoading}
    >
      <ModalHeader className={styles.modalTitle} showSeverityIcon>
        停用靶編規則
      </ModalHeader>
      <ModalBody>
        <Typography variant="h5">
          客戶名稱: {selectedItem?.customer_name} -{' '}
          {selectedItem?.customer_short_name} - {selectedItem?.customer_id}
        </Typography>
        <Typography variant="h5">
          訂單: {selectedItem?.order_id} - {selectedItem?.sales_order_line}
        </Typography>
        <Typography variant="h5">料號: {selectedItem?.material_id}</Typography>
        <br />
        <Typography variant="h5">
          光洋靶編: {selectedItem?.internal_issued_number}
        </Typography>
        <br />
        <Typography variant="h5">
          客戶靶編: {selectedItem?.customer_issued_number}
        </Typography>
        <br />
        <CalendarConfigProvider methods={CalendarMethodsMoment}>
          <FormFieldsWrapper methods={methods} className={styles.creatingForm}>
            <DatePickerField
              name="disable_date"
              label="停用日期"
              registerName="disable_date"
              required
            ></DatePickerField>
          </FormFieldsWrapper>
        </CalendarConfigProvider>
      </ModalBody>
      <ModalActions
        cancelText="取消"
        confirmText="確定"
        onCancel={onClose}
        onConfirm={handleSubmit}
      ></ModalActions>
    </Modal>
  );
}
