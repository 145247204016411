import { TableColumn } from '@mezzanine-ui/core/table';
import { Button } from '@mezzanine-ui/react';
import { checkJWTRoles, MiscMaterialBatch } from '@solar/data';
import { useMemo } from 'react';
import { ColumnHooks, Modals } from '../types';

export const useColumns = ({
  modalGroupController,
  prevModalNameRef,
}: ColumnHooks) => {
  const columns: TableColumn<MiscMaterialBatch>[] = useMemo(
    () => [
      {
        title: '操作',
        // align: 'start' as const,
        render: (source) => {
          return (
            <div style={{ display: 'flex', gap: '4px' }}>
              {checkJWTRoles([92]) && source.eraseOnPick === false && (
                <>
                  {/* 發退料 */}
                  <Button
                    type="button"
                    onClick={() => {
                      prevModalNameRef.current = Modals.DISPATCH;
                      modalGroupController.openModal<MiscMaterialBatch>(
                        Modals.DISPATCH,
                        source
                      );
                    }}
                  >
                    移轉
                  </Button>
                  {/* 報廢 */}
                  <Button
                    type="button"
                    onClick={() => {
                      prevModalNameRef.current = Modals.SCRAP;
                      modalGroupController.openModal<MiscMaterialBatch>(
                        Modals.SCRAP,
                        source
                      );
                    }}
                  >
                    領用
                  </Button>
                </>
              )}
              {/* 發料 */}
              {checkJWTRoles([92]) && source.eraseOnPick === true && (
                <Button
                  type="button"
                  onClick={() => {
                    prevModalNameRef.current = Modals.SCRAP;
                    modalGroupController.openModal<MiscMaterialBatch>(
                      Modals.SCRAP,
                      source
                    );
                  }}
                >
                  領用
                </Button>
              )}
            </div>
          );
        },
      },
      {
        title: '類型',
        dataIndex: 'categoryName',
      },
      { title: '料號', dataIndex: 'id' },
      { title: '品名', dataIndex: 'description' },
      { title: '批號', dataIndex: 'batchId' },
      { title: '儲位', dataIndex: 'batchShelfId' },
      { title: '數量', dataIndex: 'quantity' },
      { title: '單位', dataIndex: 'unit' },
    ],
    [modalGroupController, prevModalNameRef]
  );

  return columns;
};
