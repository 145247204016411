import type { ProductionPlanningOrdersExportFormValues } from '@solar/data';
import { PPOrdersExportFormFieldName as FormFieldName } from '@solar/data';
import { useFormContext } from 'react-hook-form';
import { InputField } from '@mezzanine-ui/react-hook-form';

export function OrderIdInput() {
  const formMethods =
    useFormContext<ProductionPlanningOrdersExportFormValues>();

  return (
    <>
      <div>訂單編號：</div>
      <div>
        <InputField
          control={formMethods.control}
          registerName={FormFieldName.ORDER_ID}
        />
      </div>
    </>
  );
}
