import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@mezzanine-ui/react';
import { useCallback, useState, type ReactNode } from 'react';
import styles from './location-picker-modal.module.scss';
import { LocationControlProvider } from '../LocationControlContext';
import { LocationAllLevelSearch } from '../LocationAllLevelSearch';
import { LocationPicker } from '../LocationPicker';
import { LocationSVGPicker } from '../LocationSVGPicker';
import { LocationControlContextValues } from '../types';

export interface LocationPickerModalProps {
  open: boolean;
  title?: ReactNode;
  defaultContextValues?: LocationControlContextValues;
  onClose?: () => void;
  onSubmit?: (payload: LocationControlContextValues) => Promise<void> | void;
}

export function LocationPickerModal({
  open,
  title,
  defaultContextValues,
  onClose,
  onSubmit,
}: LocationPickerModalProps) {
  const [contextValues, setContextValues] = useState<
    LocationControlContextValues | undefined
  >(defaultContextValues);

  const [submitting, setSubmitting] = useState(false);

  const _onSubmit = useCallback(async () => {
    try {
      setSubmitting(true);

      if (onSubmit && contextValues) {
        await onSubmit(contextValues);
      }
    } finally {
      setSubmitting(false);
    }
  }, [contextValues, onSubmit]);

  return (
    <Modal className={styles.modal} open={open} onClose={onClose}>
      <ModalHeader>{title ?? '選取位置'}</ModalHeader>
      <ModalBody>
        <LocationControlProvider onChange={setContextValues}>
          <LocationAllLevelSearch />
          <LocationPicker />
          <LocationSVGPicker />
        </LocationControlProvider>
      </ModalBody>
      <ModalFooter>
        <Button type="button" variant="outlined" onClick={onClose}>
          取消
        </Button>
        <Button
          type="button"
          variant="contained"
          disabled={!contextValues}
          loading={submitting}
          onClick={_onSubmit}
        >
          確認
        </Button>
      </ModalFooter>
    </Modal>
  );
}
