import { request, API_NAMESPACE } from '../../request';
import * as yup from 'yup';
import { useForm, useFormState } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export const PPOrdersExportFormFieldName = {
  MATERIAL_ID: 'materialId',
  ORDER_DATE_START: 'orderDateStart',
  ORDER_DATE_END: 'orderDateEnd',
  EXPECTED_DELIVERY_DATE_START: 'expectedDeliveryDateStart',
  EXPECTED_DELIVERY_DATE_END: 'expectedDeliveryDateEnd',
  PLANNED_DELIVERY_CONFIRM: 'plannedDeliveryConfirm',
  PLANNED_DELIVERY_DATE_START: 'plannedDeliveryDateStart',
  PLANNED_DELIVERY_DATE_END: 'plannedDeliveryDateEnd',
  ORDER_ID: 'orderId',
  BU: 'bu',
  MATERIAL_CATEGORY_ID: 'materialCategoryId',
} as const;

const _FormFieldName = PPOrdersExportFormFieldName;

export const productionPlanningOrdersExportPayloadSchema = yup.object().shape({
  [_FormFieldName.MATERIAL_ID]: yup.string().optional(),
  [_FormFieldName.ORDER_DATE_START]: yup.string().optional(),
  [_FormFieldName.ORDER_DATE_END]: yup.string().optional(),
  [_FormFieldName.EXPECTED_DELIVERY_DATE_START]: yup.string().optional(),
  [_FormFieldName.EXPECTED_DELIVERY_DATE_END]: yup.string().optional(),
  [_FormFieldName.PLANNED_DELIVERY_CONFIRM]: yup
    .string()
    .oneOf(['', 'Y', 'N'])
    .optional(),
  [_FormFieldName.PLANNED_DELIVERY_DATE_START]: yup.string().optional(),
  [_FormFieldName.PLANNED_DELIVERY_DATE_END]: yup.string().optional(),
  [_FormFieldName.ORDER_ID]: yup.string().optional(),
  [_FormFieldName.BU]: yup.string().optional(),
  [_FormFieldName.MATERIAL_CATEGORY_ID]: yup.string().optional(),
});

export type ProductionPlanningOrdersExportFormValues = yup.InferType<
  typeof productionPlanningOrdersExportPayloadSchema
>;

export function useProductionPlanningOrdersExport() {
  const formMethods = useForm<ProductionPlanningOrdersExportFormValues>({
    resolver: yupResolver(productionPlanningOrdersExportPayloadSchema),
  });
  const formState = useFormState({ control: formMethods.control });

  return {
    formMethods,
    formState,
    formSubmitCreator,
    FormFieldName: _FormFieldName,
    downloadFromFilePath,
  };
}

async function postProductionPlanningOrdersExport({
  materialId,
  orderDateStart,
  orderDateEnd,
  expectedDeliveryDateStart,
  expectedDeliveryDateEnd,
  plannedDeliveryConfirm,
  plannedDeliveryDateStart,
  plannedDeliveryDateEnd,
  orderId,
  bu,
  materialCategoryId,
}: ProductionPlanningOrdersExportFormValues) {
  try {
    const payload = {
      [_FormFieldName.MATERIAL_ID]: materialId,
      [_FormFieldName.ORDER_DATE_START]: orderDateStart,
      [_FormFieldName.ORDER_DATE_END]: orderDateEnd,
      [_FormFieldName.EXPECTED_DELIVERY_DATE_START]: expectedDeliveryDateStart,
      [_FormFieldName.EXPECTED_DELIVERY_DATE_END]: expectedDeliveryDateEnd,
      [_FormFieldName.PLANNED_DELIVERY_CONFIRM]: plannedDeliveryConfirm,
      [_FormFieldName.PLANNED_DELIVERY_DATE_START]: plannedDeliveryDateStart,
      [_FormFieldName.PLANNED_DELIVERY_DATE_END]: plannedDeliveryDateEnd,
      [_FormFieldName.ORDER_ID]: orderId,
      [_FormFieldName.BU]: bu,
      [_FormFieldName.MATERIAL_CATEGORY_ID]: materialCategoryId,
    };

    for (const key in payload) {
      const _key = key as keyof ProductionPlanningOrdersExportFormValues;
      if (typeof payload[_key] === 'undefined') delete payload[_key];
    }

    return request('/production-planning/get/orders/export', {
      namespace: API_NAMESPACE.HR,
      method: 'POST',
      body: JSON.stringify(payload),
    });
  } catch (error) {
    return null;
  }
}

type FormSubmitCreatorProps = {
  successCallback?: (payload: { filePath: string }) => void;
  errorCallback?: (error: Error) => void;
};

function formSubmitCreator({
  successCallback,
  errorCallback,
}: FormSubmitCreatorProps) {
  const onSubmit = async (data: ProductionPlanningOrdersExportFormValues) => {
    postProductionPlanningOrdersExport(data)
      .then((payload) => {
        if (payload?.filePath) successCallback?.(payload);
      })
      .catch((error) => {
        errorCallback?.(error);
      });
  };
  return onSubmit;
}

function downloadFromFilePath(filePath: string) {
  if (filePath) {
    // FIXME: not work in safari
    const aTag = document.createElement('a');
    aTag.style.display = 'none';
    aTag.href = filePath;
    aTag.download = filePath.split('/').pop() ?? '';
    document.body.appendChild(aTag);
    aTag.click();
    document.body.removeChild(aTag);
  }
}
