import { API_NAMESPACE, request } from '@solar/data';

export type GenerateSapBatchIdResponse = [
  {
    workOrderId: string;
    materialId: string;
    sapBatchId: string;
  }
];

export type GenerateSapBatchIdPayload = {
  workOrderId: string;
  materialId: string;
};

const namespace = API_NAMESPACE.MATERIALS;
export const generateSapBatchId = (
  payload: GenerateSapBatchIdPayload
): Promise<GenerateSapBatchIdResponse> => {
  return request('/warehouses/material-request-orders/expected-sap-batch-id', {
    method: 'post',
    body: JSON.stringify(payload),
    namespace,
    responseParser: (res) => res,
  }).then((res) => res.json());
};
