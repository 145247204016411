import { Button, Icon } from "@mezzanine-ui/react";
import { useState } from "react";
import { LocationSVGViewer } from "../LocationSVGViewer/LocationSVGViewer";
import { ChevronDownIcon, ChevronUpIcon } from "@mezzanine-ui/icons";
import { Space } from "antd";
import { LocationSelector } from "../ContextComponent/LocationSelector";
import { LocationSearchInput } from "../ContextComponent/LocationSearchInput";
import { LocationSelectorProvider, UseLocationSelectorControllerProps, UseLocationSelectorControllerReturn, useLocationSelectorContext, useLocationSelectorController } from "../LocationSelectorContext/useLocationSelectorContext";
import { LocationPrevLevelButton } from "../ContextComponent/LocationPrevLevelButton";

type LocationSelectorProps = {
  showSVG?: boolean;
  showShelfSvg?: boolean;
  showSearchInput?: boolean;
  expandable?: boolean;
  controller?: UseLocationSelectorControllerReturn;
  onSelectedIdsChange?: UseLocationSelectorControllerProps['onSelectedIdsChange'];
};

export function LocationSelectorGroup({
  showShelfSvg = true,
  showSVG = true,
  showSearchInput = true,
  expandable = false,
  controller,
  onSelectedIdsChange,
}: LocationSelectorProps) {
  const [svgExpand, setSvgExpand] = useState(true);
  const newController = useLocationSelectorController({ onSelectedIdsChange });

  return (
    <LocationSelectorProvider controller={controller ?? newController}>
      <Space direction="vertical" size={24} style={{ maxWidth: '1200px' }}>
        {showSearchInput && <LocationSearchInput />}
        <LocationSelector />
        <LocationPrevLevelButton />
        {showSVG && svgExpand && (
          <LocationSVGViewer showShelfSvg={showShelfSvg} />
        )}
        {expandable && (
          <Button
            style={{ width: '100%' }}
            variant="contained"
            prefix={<Icon icon={svgExpand ? ChevronUpIcon : ChevronDownIcon} />}
            onClick={() => setSvgExpand((prev) => !prev)}
          >
            {svgExpand ? '收起地圖' : '顯示地圖'}
          </Button>
        )}
      </Space>
    </LocationSelectorProvider>
  )
}
