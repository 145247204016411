import type { HubWarehouseFilterFormValues } from '@solar/data';
import { HubWarehouseFilterFormFieldName as FormFieldName } from '@solar/data';
import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';
import { useFormContext } from 'react-hook-form';
import { useHubClients } from '@solar/data';
const _PrivateFieldName = '_customer';

export function CustomerIdInput() {
  const formMethods = useFormContext<HubWarehouseFilterFormValues>();
  const { customers } = useHubClients();
  return (
    <>
      <div>客戶姓名／編號：</div>
      <div>
        <AutoCompleteField
          placeholder="輸入客戶名稱或代碼"
          registerName={_PrivateFieldName}
          options={
            customers?.map((customer) => ({
              id: customer.id,
              name: `${customer.name} (${customer.id})`,
            })) || []
          }
          onChange={(selected) => {
            formMethods.setValue(FormFieldName.CUSTOMER_ID, selected?.id);
          }}
        />
      </div>
    </>
  );
}
