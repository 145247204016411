import { Button, SelectValue } from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  FormFieldsWrapper,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  NextPagination,
  getNextParams,
  useGetMaterialAllocationsResult,
  useGetWorkOrders,
} from '@solar/data';
import { PageLayout, MznPaginationTable } from '@solar/templates';
import { Col, Row, Space } from 'antd';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

export function MaterialAllocationsResultPage() {
  const navigate = useNavigate();
  const methods = useForm<{
    workOrder: SelectValue;
    statuses: SelectValue<'EDITING' | 'FINISHED'>[];
  }>();

  const { workOrders, refetchGetWorkOrders } = useGetWorkOrders();

  const { results, pageInfo, refetchGetMaterialAllocationsResult, isLoading } =
    useGetMaterialAllocationsResult();

  const refetchGetMaterialAllocationsResultWithPagination = useCallback(
    (nextPagination: NextPagination) => {
      const formState = methods?.getValues();
      refetchGetMaterialAllocationsResult({
        workOrderId: formState?.workOrder?.id ?? '',
        statuses: formState?.statuses?.map((status) => status?.id) ?? [],
        page: (nextPagination?.nextPage ?? 1) - 1,
        size: nextPagination?.nextPageSize ?? 10,
      });
    },
    [methods, pageInfo, refetchGetMaterialAllocationsResult]
  );

  return (
    <PageLayout title="配料結果清單">
      <FormFieldsWrapper methods={methods}>
        <Space align="end" style={{ marginBottom: 15 }}>
          <AutoCompleteField
            width={250}
            label="工單"
            registerName="workOrder"
            onSearch={(searchTerm) => refetchGetWorkOrders({ searchTerm })}
            options={workOrders?.map((wo) => ({ id: wo?.id, name: wo?.id }))}
          />
          <SelectField
            width={250}
            mode="multiple"
            clearable
            label="配料狀態"
            registerName="statuses"
            options={[
              { id: 'EDITING', name: '編輯中' },
              { id: 'FINISHED', name: '完成' },
            ]}
          />
          <Button
            type="button"
            variant="outlined"
            loading={isLoading}
            onClick={() => {
              refetchGetMaterialAllocationsResultWithPagination({
                nextPage: 1,
                nextPageSize: pageInfo?.limit,
              });
            }}
          >
            查詢
          </Button>
        </Space>
        <MznPaginationTable
          loading={isLoading}
          dataSource={results ?? []}
          fetchData={refetchGetMaterialAllocationsResultWithPagination}
          pageInfo={pageInfo}
          columns={[
            {
              dataIndex: 'workOrderId',
              title: '工單',
            },
            {
              dataIndex: 'targetMaterialId',
              title: '料號',
            },
            {
              dataIndex: 'componentCode',
              title: 'Component Code',
            },
            {
              title: '配料狀態',
              render: (source) => {
                switch (source?.status) {
                  case 'EDITING':
                    return '編輯中';
                  case 'FINISHED':
                    return '完成';
                  default:
                    return '';
                }
              },
            },
            {
              title: '操作',
              render: (source) => (
                <Row>
                  <Col>
                    <Button
                      type="button"
                      disabled={!source?.id}
                      onClick={() =>
                        navigate(
                          `/production-planning/material-allocations?resultId=${source?.id}`
                        )
                      }
                    >
                      前往配料
                    </Button>
                  </Col>
                </Row>
              ),
            },
          ]}
        />
      </FormFieldsWrapper>
    </PageLayout>
  );
}
