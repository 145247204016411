import { InspectMaterialType } from './inspect-modal-enum';

export const detailPageHeader = {
  [InspectMaterialType.PurchaseOrder]: '採購單入庫',
  [InspectMaterialType.CustomerOrder]: '客供料入庫',
  [InspectMaterialType.OutsourcingOrder]: '委外加工入庫',
  [InspectMaterialType.ReturnOrder]: '銷貨退回入庫',
  [InspectMaterialType.RentTargetOrder]: '租賃回靶',
  [InspectMaterialType.HubReturn]: 'Hub 倉回廠',
  [InspectMaterialType.VirtualIn]: '虛入出貨單',
};
