import { Card, Space } from 'antd';
import { useCallback, useRef, useState } from 'react';
import { BarcodeScanInput } from '../../Material/MaterialShift/BarcodeScanInput';
import {
  InventoryByMaterialSearchType,
  getInventoryByMaterial,
} from '@solar/data';
import {
  Button,
  Message,
  SelectValue,
  Table,
  Typography,
} from '@mezzanine-ui/react';
import { UseFieldArrayReturn } from 'react-hook-form';
import { InputField } from '@mezzanine-ui/react-hook-form';
import { useDraggableColumns } from '../../Templates/PaginationTable';
import { CopyTextWrapper } from '../../Templates/CopyTextWrapper/CopyTextWrapper';
import { QuantityWithUnitsColumn } from '../../Templates/QuantityWithUnitsColumn';
import { useParams } from 'react-router';
import { ElementRatioTable } from './ElementRatioTable';

export interface FormValues {
  unexpectedMaterial: SelectValue;
  exchangePairs: {
    isExpected: boolean;
    fromMaterialId: string | null;
    fromMaterialSapBatchId: string | null;
    fromMaterialSubBatchId: string | null;
    fromMaterialDescription: string | null;
    fromMaterialInputUnit: string | null;
    fromLoaderId: string | null;
    toMaterialId: string | null;
    materialBarcode: string | null;
    quantity: string;
    materialStockUnit: string;
    materialInputUnit: string;
    stockOverInputUnitRatio: string;
    shelfId: string;
    loaderId: string;
  }[];
}

type UseExchangePairsArray = UseFieldArrayReturn<FormValues, 'exchangePairs'>;

export function ExchangeMaterialCard({
  item,
  arrayMethods,
}: {
  item: any;
  arrayMethods: UseExchangePairsArray;
}) {
  // const { open } = useTargetModal<any>(Modals.EXCHANGE_MATERIAL);
  const { orderId } = useParams<{ orderId: string }>();
  const { fields, append, remove } = arrayMethods;
  const [loading, setLoading] = useState(false);

  const expectedMaterialTable = useRef<HTMLTableElement>(null);
  const inputMaterialTable = useRef<HTMLTableElement>(null);

  const onBarcodeScan = useCallback(
    async ({
      searchTerm,
      searchType,
    }: {
      searchTerm: string | undefined;
      searchType: InventoryByMaterialSearchType;
    }) => {
      try {
        setLoading(true);

        if (!searchTerm) {
          Message.error('請輸入條碼');
          return;
        }

        const res = await getInventoryByMaterial({
          type: searchType,
          offset: 0,
          limit: 20,
          searchTerms: [searchTerm ?? ''],
        });

        if (res?.records?.length === 0) {
          Message.error('沒有匹配的資料');
          return;
        }

        const inventory = res?.records?.[0];

        const existedInventory = fields.find(
          (field) =>
            field.isExpected &&
            field.fromMaterialId === inventory?.materialId &&
            field.fromMaterialSapBatchId === inventory?.materialSapBatchId &&
            field.fromMaterialSubBatchId === inventory?.materialSubBatchId
        );

        if (existedInventory) {
          Message.error('已經存在投入物料條碼');
          return;
        }

        if (inventory?.materialInputUnit !== item?.materialInputUnit) {
          Message.error('物料單位不一致');
          return;
        }

        append({
          isExpected: true,
          fromLoaderId: inventory?.loaderId ?? '',
          fromMaterialId: inventory?.materialId ?? '',
          fromMaterialSapBatchId: inventory?.materialSapBatchId ?? '',
          fromMaterialSubBatchId: inventory?.materialSubBatchId ?? '',
          fromMaterialDescription: inventory?.materialDescription ?? '',
          fromMaterialInputUnit: inventory?.materialInputUnit ?? '',
          toMaterialId: item?.materialId ?? '',
          quantity: inventory?.quantity ?? '0',
          materialBarcode: inventory?.materialBarcode ?? '',
          materialInputUnit: inventory?.materialInputUnit ?? '',
          materialStockUnit: inventory?.materialStockUnit ?? '',
          stockOverInputUnitRatio: inventory?.stockOverInputUnitRatio ?? '0',
          shelfId: inventory?.shelfId ?? '',
          loaderId: inventory?.loaderId ?? '',
        });
      } catch (err) {
        if (err instanceof Error) {
          Message.error(JSON.parse(err.message).message);
        } else {
          Message.error(JSON.stringify(err));
        }
      } finally {
        setLoading(false);
      }
    },
    [append, fields, item?.materialId, item?.materialInputUnit]
  );

  const expectedMaterialTableColumns = useDraggableColumns<any>(
    expectedMaterialTable,
    [
      {
        draggable: true,
        width: 300,
        title: '物料',
        render: (source) => <CopyTextWrapper text={source?.materialId ?? ''} />,
      },
      {
        dataIndex: 'materialDescription',
        title: '品名',
      },
      {
        title: '預期產出數量',
        align: 'end',
        render: (source) => (
          <QuantityWithUnitsColumn
            quantity={source?.expectedMaterialStockUnitQuantity ?? ''}
            materialInputUnit={source?.materialInputUnit ?? ''}
            materialStockUnit={source?.materialStockUnit ?? ''}
            stockOverInputUnitRatio={source?.stockOverInputUnitRatio ?? ''}
          />
        ),
      },
    ]
  );

  const inputMaterialTableColumns = useDraggableColumns<
    FormValues['exchangePairs'][number]
  >(inputMaterialTable, [
    {
      width: 120,
      title: '操作',
      render: (source) => (
        <Space>
          <Button
            danger
            variant="text"
            onClick={() => {
              const index = fields.findIndex(
                (field) =>
                  field.isExpected &&
                  field.toMaterialId === source?.toMaterialId &&
                  field.materialBarcode === source?.materialBarcode
              );
              remove(index);
            }}
          >
            移除
          </Button>
        </Space>
      ),
    },
    {
      draggable: true,
      width: 500,
      title: '投入物料條碼',
      render: (source) => (
        <CopyTextWrapper text={source?.materialBarcode ?? ''} />
      ),
    },
    {
      draggable: true,
      title: '品名',
      render: (source) => (
        <CopyTextWrapper text={source?.fromMaterialDescription ?? ''} />
      ),
    },
    {
      width: 180,
      dataIndex: 'shelfId',
      title: '儲位',
    },
    {
      width: 120,
      dataIndex: 'loaderId',
      title: '載具',
    },
    {
      width: 300,
      title: '投入數量',
      align: 'end',
      render: (source) => {
        const index = fields.findIndex(
          (field) =>
            field.isExpected &&
            field.toMaterialId === source?.toMaterialId &&
            field.materialBarcode === source?.materialBarcode
        );
        return (
          <Space>
            <InputField registerName={`exchangePairs.${index}.quantity`} />
            {source?.fromMaterialInputUnit}
          </Space>
        );
      },
    },
  ]);

  return (
    <Card
      style={{ width: '100%' }}
      title={
        <Typography variant="h4">
          {item?.isMainProduct ? '主產品' : '聯產品'}
        </Typography>
      }
    >
      <Space
        direction="vertical"
        size="large"
        style={{ width: '100%', padding: '16px 0' }}
      >
        <Table
          ref={expectedMaterialTable}
          scroll={{ x: 1500 }}
          columns={expectedMaterialTableColumns}
          dataSource={[item]}
        />
        <BarcodeScanInput
          placeholder="請輸入投入物料條碼"
          otherOnKeyDownAction={async (searchTerm) => {
            await onBarcodeScan({
              searchTerm,
              searchType: InventoryByMaterialSearchType.MATERIAL_BARCODE,
            });
          }}
        />
        <Table
          ref={inputMaterialTable}
          scroll={{ x: 1500 }}
          dataSource={fields?.filter((field) => field.isExpected) ?? []}
          columns={inputMaterialTableColumns}
          expandable={{
            rowExpandable: () => true,
            expandedRowRender: (record) => (
              <ElementRatioTable
                orderId={orderId}
                barcode={record?.materialBarcode as string}
              />
            ),
          }}
        />
      </Space>
    </Card>
  );
}
