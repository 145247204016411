import { HandleTableDataILikeSearchFunc } from '../typings';

export function handleTableDataILikeSearch({
  dataSource,
  fieldParams,
  tableDataSearchTerms,
}: HandleTableDataILikeSearchFunc) {
  const { materialIdKey, materialDescriptionKey } = fieldParams;
  const materialIdValue = tableDataSearchTerms[materialIdKey];
  const materialDescriptionValue = tableDataSearchTerms[materialDescriptionKey];

  const result =
    !materialIdValue.trim() &&
    !materialDescriptionValue.trim() &&
    dataSource?.length
      ? dataSource
      : dataSource?.filter((row: any) => {
          const subStringOfMaterialId = new RegExp(materialIdValue, 'i');

          const subStringOfMaterialDescription = new RegExp(
            materialDescriptionValue,
            'i'
          );

          return (
            (!!materialIdValue &&
              subStringOfMaterialId.test(row?.materialId)) ||
            (!!materialDescriptionValue &&
              subStringOfMaterialDescription.test(
                row?.materialDescription || row?.material?.description
              ))
          );
        });

  return result;
}
