import { TableDataSourceWithID, TableColumn } from '@mezzanine-ui/core/table';
import { ProductionInfoBaseResponse } from '@solar/data';
import { useMemo } from 'react';

export type TemplateProductionForms = ProductionInfoBaseResponse & {
  title: string;
  suffix: string | null;
} & TableDataSourceWithID;

export function useFormTemplateProductionFormListColumns({
  templateName,
  templateSuffix,
}: {
  templateName: string;
  templateSuffix: string | null;
}) {
  return useMemo<TableColumn<TemplateProductionForms>[]>(
    () => [
      {
        title: '料號',
        dataIndex: 'material.id',
        width: 360,
      },
      {
        title: '路徑碼',
        dataIndex: 'recipe.id',
        width: 108,
      },
      {
        title: '行為',
        dataIndex: 'processing.id',
        width: 72,
      },
      {
        title: '工作中心',
        dataIndex: 'workCenter.id',
        width: 108,
      },
      {
        title: '機台',
        dataIndex: 'equipment.id',
        width: 120,
      },
      {
        title: '題目名稱',
        render: (row) => row.title || templateName,
      },
      {
        title: '單位',
        width: 72,
        render: (row) => row.suffix ?? templateSuffix,
      },
    ],
    [templateName, templateSuffix]
  );
}
