import {
  FetchingInventoryStockTypes,
  NextPagination,
  ProductionAnswerDto,
  getNextParams,
  useGetInventoryByWarehouse,
} from '@solar/data';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMaterialViewBaseColumns } from './hooks/useColumns';
import { Loading, Popper, Typography } from '@mezzanine-ui/react';
import {
  LocationSelectorGroup,
  MznPaginationTable,
  useLocationSelectorController,
} from '@solar/templates';
import { FilterFormType } from './typings';
import { useForm } from 'react-hook-form';
import { useLocationViewerPopup } from '../ProductionPlanning/RoutingPage/use-location-viewer-popup';
import styles from './element-ratio.module.scss';

export type WarehouseEnquiryWarehouseViewProps = {
  batchSerialModalOpen: () => void;
};

const stockTypes = [
  FetchingInventoryStockTypes.AVAILABLE,
  FetchingInventoryStockTypes.INSPECTION,
  FetchingInventoryStockTypes.IN_TRANSIT,
  FetchingInventoryStockTypes.SALES,
  FetchingInventoryStockTypes.REQUESTED,
  FetchingInventoryStockTypes.IN_TRANSIT_SALES,
  FetchingInventoryStockTypes.LOCKED,
  FetchingInventoryStockTypes.REQUESTED_SALES,
  FetchingInventoryStockTypes.HUB,
];

export function WarehouseEnquiryWarehouseView({
  batchSerialModalOpen,
}: WarehouseEnquiryWarehouseViewProps) {
  const methods = useForm<FilterFormType>({ defaultValues: {} });

  const locationSelectorController = useLocationSelectorController({});
  const {
    isLoading,
    inventoryByWarehouse,
    mutateGetInventoryByWarehouse,
    pageInfo,
  } = useGetInventoryByWarehouse({
    stockTypes,
  });
  const { currentLocation } = locationSelectorController;

  const refetchGetInventoryByWarehouse = useCallback(
    (nextPagination: NextPagination) => {
      const { offset, limit } = getNextParams(nextPagination, pageInfo);
      mutateGetInventoryByWarehouse({
        withElementRatio: true,
        locationIds: [
          currentLocation?.stack?.id ??
            currentLocation?.zone?.id ??
            currentLocation?.area?.id ??
            '',
        ],
        stockTypes,
        offset,
        limit,
        shouldFetch: true,
      });
    },
    [
      currentLocation?.area?.id,
      currentLocation?.zone?.id,
      currentLocation?.stack?.id,
      mutateGetInventoryByWarehouse,
      pageInfo,
    ]
  );

  useEffect(() => {
    if (
      currentLocation?.area ||
      currentLocation?.zone ||
      currentLocation?.stack
    ) {
      refetchGetInventoryByWarehouse({ nextPage: 1 });
    }
  }, [currentLocation]);

  const [locationPopup, setLocationViewerAnchor] =
    useLocationViewerPopup(methods);

  const [shownElementRatioInfo, setElementRatioInfo] = useState<
    ProductionAnswerDto[]
  >([]);

  const elementRatioAnchorRef = useRef<HTMLButtonElement | null>(null);

  const baseColumns = useMaterialViewBaseColumns({
    batchSerialModalOpen,
    methods,
    setLocationViewerAnchor,
    setElementRatioInfo,
    elementRatioAnchorRef,
  });

  // if (isLoading) return <Loading loading />;

  return (
    <>
      <LocationSelectorGroup
        controller={locationSelectorController}
        expandable
      />
      {isLoading ? (
        <Loading loading />
      ) : (
        <div style={{ width: '100%', overflow: 'auto' }}>
          <MznPaginationTable
            loading={isLoading}
            scroll={{ fixedFirstColumn: true, x: 3500 }}
            style={{ height: '50vh' }}
            pageInfo={pageInfo ?? {}}
            fetchData={refetchGetInventoryByWarehouse}
            dataSource={
              inventoryByWarehouse?.map((inventory) => ({
                id: inventory?.recordId,
                ...inventory,
              })) ?? []
            }
            columns={baseColumns}
          />
          <Popper
            anchor={elementRatioAnchorRef}
            open={!!shownElementRatioInfo.length}
          >
            <div className={styles.elementRatioContainer}>
              <Typography variant="h5">元素比例</Typography>
              {shownElementRatioInfo.map((elementRatio) => (
                <Typography variant="body1">
                  {elementRatio.form.title}：
                  {elementRatio.modifiedAnswer || elementRatio.answer}{' '}
                  {elementRatio.form.suffix}
                </Typography>
              ))}
            </div>
          </Popper>
        </div>
      )}
      {locationPopup}
    </>
  );
}
