import { Typography } from '@mezzanine-ui/react';
import { MainFileDeleteModalProps } from '../../MainFilePage/MainFileDeleteModal/MainFileDeleteModal';
import styles from '../../MainFilePage/MainFileDeleteModal/MainFileDeleteModal.module.scss';

export function PackagingDeleteModal({ source }: MainFileDeleteModalProps) {
  return (
    <div>
      <div className={styles.info}>
        <div className={styles.content} style={{ wordWrap: 'break-word' }}>
          <Typography variant="h6" color="text-secondary">
            包材料號
          </Typography>
          <Typography>{source?.materialId}</Typography>
        </div>
        <div className={styles.fakeBorder}></div>
        <div className={styles.content} style={{ wordWrap: 'break-word' }}>
          <Typography variant="h6" color="text-secondary">
            包材名稱
          </Typography>
          <Typography>{source?.name}</Typography>
        </div>
        <div className={styles.fakeBorder}></div>
        <div className={styles.content} style={{ wordWrap: 'break-word' }}>
          <Typography variant="h6" color="text-secondary">
            材質
          </Typography>
          <Typography>{source?.texture}</Typography>
        </div>
      </div>
      <div className={styles.border}></div>
      <div className={styles.info}>
        <div className={styles.content}>
          <Typography variant="h6" color="text-secondary">
            長x寬x高 (mm)
          </Typography>
          <Typography>
            {source?.length} x {source?.width} x {source?.height}
          </Typography>
        </div>
        <div className={styles.fakeBorder}></div>
        <div className={styles.content} style={{ width: 170 }}>
          <Typography variant="h6" color="text-secondary">
            英吋換算 (in)
          </Typography>
          <Typography>{source?.inch}</Typography>
        </div>
        <div className={styles.fakeBorder}></div>
        <div className={styles.content}>
          <Typography variant="h6" color="text-secondary">
            分數
          </Typography>
          <Typography>{source?.fraction}</Typography>
        </div>
      </div>
    </div>
  );
}
