import { useGetSVG } from "@solar/data";
import { useLocationSelectorContext } from "../LocationSelectorContext/useLocationSelectorContext";
import { useLocationSVGElements } from "./useLocationSVGElements";
import { useLocationSVGClick } from "./useLocationSVGClick";
import { CSSProperties, useRef } from "react";

type LocationSVGProps = {
  className?: string;
  style?: CSSProperties;
  onClick: (event: MouseEvent) => void;
}

export function LocationSVG({ className, style, onClick }: LocationSVGProps) {
  const { svgId } = useLocationSelectorContext();
  const { svg } = useGetSVG(svgId);
  const svgWrapperRef = useRef<HTMLDivElement>(null);

  useLocationSVGElements(svgWrapperRef);
  useLocationSVGClick(svgWrapperRef, { onClick });

  return (
    <div
      id="location-svg-wrapper"
      ref={svgWrapperRef}
      className={className}
      style={style}
      dangerouslySetInnerHTML={{ __html: svg?.statusCode === 404 ? '圖片不存在' : svg }} />
  )
}