import type { HubOrdersFilterFormValues } from '@solar/data';
import { HubOrdersFilterFormFieldName as FormFieldName } from '@solar/data';
import { InputField } from '@mezzanine-ui/react-hook-form';
import { useFormContext } from 'react-hook-form';

export function OrderIdInput() {
  const formMethods = useFormContext<HubOrdersFilterFormValues>();

  return (
    <>
      <div>出貨單編號：</div>
      <div>
        <InputField
          control={formMethods.control}
          registerName={FormFieldName.HUB_DELIVERY_ORDER_ID}
        />
      </div>
    </>
  );
}
