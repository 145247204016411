import React from 'react';
import { Filter, FilterProps } from './Filter/Filter';
import { Header, HeaderProps } from './Header/Header';
import styles from './Layout.module.scss';

interface LayoutProps extends HeaderProps, FilterProps {
  children: React.ReactElement;
}

export function Layout({
  title,
  button,
  filterHeader,
  noFilterHeaderBorder,
  filtersData,
  filterDependencies,
  filterMethods,
  registers,
  submitButton,
  children,
  methods,
}: LayoutProps) {
  return (
    <div className={styles.wrapper}>
      <Header title={title} button={button} />
      <div className={styles.filter}>
        <Filter
          filterHeader={filterHeader}
          noFilterHeaderBorder={noFilterHeaderBorder}
          filtersData={filtersData}
          filterDependencies={filterDependencies}
          filterMethods={filterMethods}
          registers={registers}
          submitButton={submitButton}
          methods={methods}
        />
      </div>
      {children}
    </div>
  );
}
