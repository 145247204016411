import type { CustomerTradeConditionExportFormValues } from '@solar/data';
import {
  CustomerTradeConditionExportFormFieldName as FormFieldName,
  useCustomerTradeConditionFilterGet,
} from '@solar/data';
import { useFormContext } from 'react-hook-form';
import { AutoCompleteField } from '@mezzanine-ui/react-hook-form';

const _PrivateFieldName = '_customer';

export function CustomerAutoComplete() {
  const formMethods = useFormContext<CustomerTradeConditionExportFormValues>();

  const { customers } = useCustomerTradeConditionFilterGet();

  return (
    <>
      <div>客戶：</div>
      <div>
        <AutoCompleteField
          placeholder="輸入客戶名稱或代碼"
          registerName={_PrivateFieldName}
          options={customers}
          onChange={(selected) => {
            // update react-hook-form by oneself because the format from @mezzanine is not quite suitable.
            formMethods.setValue(FormFieldName.CUSTOMER_ID, selected?.id);
          }}
        />
      </div>
    </>
  );
}
