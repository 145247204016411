import useSWR from 'swr';
import {
  Area,
  Building,
  NewFactory,
  Floor,
  Stack,
  Zone,
  SelectedIds,
  StackJoinEquipmentsAndMaterials,
  Shelf,
} from './location.type';
import { useCallback, useMemo, useState } from 'react';
import { request } from '../request';
import { Factory } from './types';

export function useGetFactoryById(id?: string) {
  const { data: factory, error } = useSWR<NewFactory>(
    id ? [`/factorys/${id}`] : null
  );

  return {
    factory,
    isLoading: !factory && !error,
  };
}

export function useGetBuildingById(id?: string) {
  const { data: building, error } = useSWR<Building>(
    id ? `/buildings/${id}` : null
  );

  return {
    building,
    isLoading: !building && !error,
  };
}

export function getBuildingById(id?: string) {
  return request(`/buildings/${id}`, { method: 'get' });
}

export function useGetFloorById(id?: string) {
  const { data: floor, error } = useSWR<Floor>(id ? `/floors/${id}` : null);

  return {
    floor,
    isLoading: !floor && !error,
  };
}

export function useGetAreaById(id?: string) {
  const { data: area, error } = useSWR<Area>(id ? `/areas/${id}` : null);

  return {
    area,
    isLoading: !area && !error,
  };
}

export function useGetZoneById(id?: string) {
  const { data: zone, error } = useSWR<Zone>(id ? `/zones/${id}` : null);

  return {
    zone,
    isLoading: !zone && !error,
  };
}

export function useGetStackById(id?: string) {
  const { data: stack, error } = useSWR<Stack>(id ? `/stacks/${id}` : null);

  return {
    stack,
    isLoading: !stack && !error,
  };
}

export function useGetShelfById(id?: string) {
  const { data: shelf, error } = useSWR<Shelf>(id ? `/shelves/${id}` : null);

  return {
    shelf,
    isLoading: !shelf && !error,
  };
}

export function useGetShelves() {
  const [params, setParams] = useState<{ searchTerm: string }>();
  const { data: shelves, error } = useSWR<Shelf[]>([
    '/shelves/',
    {
      params,
    },
  ]);

  return {
    shelves,
    isLoading: !shelves && !error,
    mutateGetShelvesParams: setParams,
  };
}

export function getStackById(id?: string) {
  return request(`/stacks/${id}`, { method: 'get' });
}

export function getStackJoinEquipmentsAndMaterials(id?: string) {
  return request(`/stacks-join-equipment-and-material/${id}`, {
    method: 'get',
  });
}

export function useGetStackJoinEquipmentsAndMaterials(id?: string) {
  const { data, error } = useSWR<StackJoinEquipmentsAndMaterials>(
    id ? `/stacks-join-equipment-and-material/${id}` : null
  );

  return {
    stackJoinEquipmentsAndMaterials: data,
    isLoading: !data && !error,
  };
}

export function useGetSVG(filename?: string) {
  const responseParser = useCallback(
    (res: Response) => (res.ok ? res.text() : res.json()),
    []
  );
  const { data, error, mutate } = useSWR(
    filename ? [`/svgs/${filename}.svg`, { responseParser }] : null
  );

  const resetSVG = () => mutate(null);

  return {
    svg: data,
    error,
    isLoading: !data && !error,
    resetSVG,
  };
}

export function getLocationRelationParent(id: string) {
  return request(`/locations/relation-parent/${id}`, { method: 'get' });
}

export function useGetLocation(selectedIds?: SelectedIds) {
  const { factory } = useGetFactoryById(selectedIds?.factoryId);
  const { building } = useGetBuildingById(selectedIds?.buildingId);
  const { floor } = useGetFloorById(selectedIds?.floorId);
  const { area } = useGetAreaById(selectedIds?.areaId);
  const { zone } = useGetZoneById(selectedIds?.zoneId);
  const { stack } = useGetStackById(selectedIds?.stackId);
  const { shelf } = useGetShelfById(selectedIds?.shelfId);

  return useMemo(
    () => ({
      factory,
      building,
      floor,
      area,
      zone,
      stack,
      shelf,
    }),
    [area, building, factory, floor, shelf, stack, zone]
  );
}

export function useGetLocationOptions(
  factories?: Factory[],
  currentLocation?: ReturnType<typeof useGetLocation>
) {
  return useMemo(
    () => ({
      factory: factories,
      building: currentLocation?.factory?.buildings,
      floor: currentLocation?.building?.id
        ? currentLocation?.building?.floors
        : currentLocation?.factory?.buildings?.flatMap(
            (building) => building.floors
          ),
      area: currentLocation?.floor?.areas,
      zone: currentLocation?.area?.zones,
      stack: currentLocation?.zone?.id
        ? currentLocation?.zone?.stacks
        : currentLocation?.area?.zones?.flatMap((zone) => zone.stacks),
      shelf: currentLocation?.stack?.shelves,
    }),
    [currentLocation, factories]
  );
}

export function getLocationDisplayName(
  location: { [key: string]: any },
  separator?: string
): string {
  if (!location?.['id']) return '無';

  const sep = separator ?? ' · ';
  const keys = [
    'shelf',
    'stack',
    'zone',
    'area',
    'floor',
    'building',
    'factory',
  ];
  const targetKey = keys.find((key) => !!location?.[key]);

  if (!targetKey) return location?.['name'];

  const childName = getLocationDisplayName(location?.[targetKey], separator);
  return `${childName}${sep}${location?.['name']}`;
}
