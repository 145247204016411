import { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { API_NAMESPACE } from '../../request';
import { FilterDataType } from '../mainFile';
import { sdOpConvertCustomers } from '../orderMarks';

const namespace = API_NAMESPACE.HR;

type PackingFiltersDataType = {
  customers: FilterDataType[];
};

const isPackingFiltersData = (
  object: unknown
): object is PackingFiltersDataType => {
  if (object && typeof object === 'object') return 'customers' in object;
  return false;
};

export function usePackagingEstimateFilter() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<PackingFiltersDataType | null>(null);

  const { data: swrData } = useSWR([
    '/packings/estimate/filter',
    { namespace },
  ]);

  const customers = useMemo(
    () => sdOpConvertCustomers(swrData?.customers),
    [swrData]
  );

  useEffect(() => {
    if (isPackingFiltersData(swrData)) {
      setLoading(false);
      setData(swrData);
      return;
    }

    setLoading(true);
  }, [swrData]);

  return {
    loading,
    data,
    customers,
  };
}
