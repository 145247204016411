import {
  MenuDivider,
  Message,
  Radio,
  RadioGroup,
  RadioGroupOption,
  Typography,
} from '@mezzanine-ui/react';

import styles from '../../Equipment/EquipmentListPage/equipment-list-page.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  useMaterials,
  useMaterialsByLocation,
  getEquipmentsByZone,
  LocationType,
} from '@solar/data';

import { useNavigate, useLocation } from 'react-router-dom';

import { MaterialViewFilter } from '../Filters/MaterialViewFilter';
import { LocationSelectorModal } from '../../Equipment/LocationSelector/LocationSelectorModal';
import { StorageInfoModal } from '../Modal/StorageInfoModal';
import { MaterialTable } from '../MaterialTable/MaterialTable';
import { CostWareHouseRadio } from '../const/cost-warehouse';
import { WarehouseMapPage } from '../../Warehouse/WarehouseMapPage/WarehouseMapPage';

export function useModalController() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const openModal = (data: any) => {
    setData(data);
    setOpen(true);
  };
  const closeModal = (options?: { keepData?: boolean }) => {
    if (!options?.keepData) setData(null);
    setOpen(false);
  };
  return { open, data, openModal, closeModal };
}

export function CostWarehousePage() {
  const filterMethods = useForm();
  const filterMethodsByLocation = useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const locationSelectorModalController = useModalController();
  const createEquipmentMaintenanceModalController = useModalController();

  const { materials, pageInfo, mutateGetMaterials, isLoading, setParams } =
    useMaterials(filterMethods);

  const {
    materials: materialsByLocation,
    pageInfo: pageInfoByLocation,
    isLoading: isLoadingByLocation,
    mutateGetMaterials: mutateGetMaterialsByLocation,
    setParams: setParamsByLocation,
  } = useMaterialsByLocation(filterMethodsByLocation);

  const [defaultView, setDefaultView] = useState(() =>
    location.pathname === '/material-enquiry/material-view'
      ? CostWareHouseRadio.MATERIAL_VIEW
      : CostWareHouseRadio.WAREHOUSE_VIEW
  );

  const isMaterialView = useMemo(
    () => defaultView === CostWareHouseRadio.MATERIAL_VIEW,
    [defaultView]
  );

  useEffect(() => {
    const subscription = isMaterialView
      ? filterMethods.watch(
          () => mutateGetMaterials({ nextPage: 1 })
          // : mutateGetMaterialsByLocation({ nextPage: 1 })
        )
      : filterMethodsByLocation.watch(() =>
          mutateGetMaterialsByLocation({ nextPage: 1 })
        );
    return () => subscription.unsubscribe();
  }, [
    filterMethods,
    filterMethodsByLocation,
    filterMethods.watch,
    filterMethodsByLocation.watch,
    mutateGetMaterials,
    mutateGetMaterialsByLocation,
    isMaterialView,
  ]);

  const options: RadioGroupOption[] = [
    {
      value: 'material-view',
      label: '物料視角',
    },
    {
      value: 'warehouse-view',
      label: '倉庫視角',
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div>
          <Typography variant="h1">物料查詢</Typography>
          <RadioGroup
            defaultValue={defaultView}
            onChange={(e) => {
              navigate(`/material-enquiry/${e.target.value}`);
              setDefaultView(e.target.value as CostWareHouseRadio);
            }}
          >
            {options?.map(({ label, value }: RadioGroupOption) => (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            ))}
          </RadioGroup>
        </div>
      </div>
      <MenuDivider />
      {isMaterialView ? (
        <MaterialViewFilter
          setParams={setParams}
          methods={filterMethods}
          openLocationSelectorModal={locationSelectorModalController.openModal}
        />
      ) : null}
      {/* The recent update is to remove original WarehouseView and relocate 倉庫地圖 into this page */}
      {defaultView === CostWareHouseRadio.MATERIAL_VIEW && (
        <MaterialTable
          defaultView={defaultView}
          equipments={(isMaterialView ? materials : materialsByLocation) ?? []}
          pageInfo={isMaterialView ? pageInfo : pageInfoByLocation}
          mutateGetMaterials={
            isMaterialView ? mutateGetMaterials : mutateGetMaterialsByLocation
          }
          isLoading={isMaterialView ? isLoading : isLoadingByLocation}
          openMaterialDetailModal={
            createEquipmentMaintenanceModalController.openModal
          }
        />
      )}
      {defaultView === CostWareHouseRadio.WAREHOUSE_VIEW && (
        <WarehouseMapPage />
      )}
      <StorageInfoModal {...createEquipmentMaintenanceModalController} />
      <LocationSelectorModal
        {...locationSelectorModalController}
        headerText="儲位位置"
        onSubmit={async (currentLocation: LocationType) => {
          const equipmentsData = await getEquipmentsByZone({
            zoneId: currentLocation?.zone?.id,
          });
          if (equipmentsData?.equipments?.length === 0) {
            Message.error('此區無設備');
          }
          if (equipmentsData?.equipments?.length > 0) {
            filterMethods.setValue('equipments', equipmentsData?.equipments);
          }
        }}
      />
    </div>
  );
}
