import {
  useInventoryByCustomerSupplyOrder,
  useInventoryByPurchaseOrder,
} from '@solar/data';

export const pageOptions = [
  {
    id: 'purchase-order',
    name: '庫存採購單',
    fetchOrder: useInventoryByPurchaseOrder,
  },
  {
    id: 'customer-order',
    name: ' 收料訂單', // 客供料 -> 收料訂單 -> 出貨單(SOL-1144),
    fetchOrder: useInventoryByCustomerSupplyOrder,
  },
  {
    id: 'outsourcing-order',
    name: '委外採購單',
    fetchOrder: useInventoryByPurchaseOrder,
  },
  // {
  //   id: 'return-order',
  //   name: '銷貨退回',
  //   fetchOrder: useInventoryByCustomerSupplyOrder,
  // },
];
