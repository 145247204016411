import { CSSProperties, ReactNode, useCallback, useMemo } from "react";
import styles from './location-svg-viewer.module.scss';
import { Button, Col, Popover, Row, Spin, Typography } from "antd";
import { useGetStackJoinEquipmentsAndMaterials } from "@solar/data";
import { useLocationSVGPopover } from "./useLocationSVGPopover";
import { CloseOutlined } from "@ant-design/icons";

type LocationSVGPopoverProps = {
  style?: CSSProperties;
  children: ReactNode;
};

export function LocationSVGPopover({
  style,
  children,
}: LocationSVGPopoverProps) {
  const {
    locked,
    popoverWrapperRef,
    selectedId,
    selectedElement,
    popoverBox,
    closePopover,
  } = useLocationSVGPopover();
  const { stackJoinEquipmentsAndMaterials, isLoading } = useGetStackJoinEquipmentsAndMaterials(
    selectedId ?? undefined
  );
  const equipments = stackJoinEquipmentsAndMaterials?.equipments;
  const materials = stackJoinEquipmentsAndMaterials?.id === selectedId
    ? stackJoinEquipmentsAndMaterials?.materials
    : stackJoinEquipmentsAndMaterials?.shelfMaterials?.find((item) => item?.shelf_id === selectedId)?.materials;

  const getPopupContainer = useCallback(() => popoverWrapperRef.current ?? document.body, [popoverWrapperRef]);

  const category = useMemo(() => selectedElement?.getAttribute('category')?.toLowerCase(), [selectedElement]);;

  return (
    <div ref={popoverWrapperRef} className={styles.svg_with_popover} style={style}>
      <Popover
        key={selectedId}
        arrow={false}
        title={(
          <Row justify="space-between" align="middle">
            <Col>
              <Typography.Title level={5} style={{ margin: 0 }}>位置：{selectedId}</Typography.Title>
            </Col>
            <Col>
              <Button type="text" icon={<CloseOutlined />} onClick={closePopover} />
            </Col>
          </Row>
        )}
        style={{ padding: 5 }}
        open={!!selectedId && !!popoverBox}
        placement="bottom"
        getPopupContainer={getPopupContainer}
        content={(
          <Spin spinning={isLoading}>
            <Row gutter={[12, 12]} justify="start" align="top" style={{ width: 500, maxHeight: 400, overflow: 'scroll' }}>
              {category !== 'warehouse' && (
                <Col span={24}>
                  <p>設備</p>
                  <ul>
                    {equipments?.map((equipment) => (
                      <li key={equipment?.id}>
                        {equipment?.workCenter?.id ? `${equipment?.workCenter?.id} / ` : ''}
                        {equipment?.name}
                      </li>
                    ))}
                  </ul>
                </Col>
              )}
              {locked && (
                <Col span={24}>
                  <p>物料</p>
                  <ul>
                    {materials?.map((material) => (
                      <li key={material?.material_id}>
                        {`${material?.material_id} / ${material?.main_material_unit_quantity} ${material?.main_material_unit_name}`}
                      </li>
                    ))}
                  </ul>
                </Col>
              )}
            </Row>
          </Spin>
        )}
      >
        <div
          style={{
            position: 'absolute',
            pointerEvents: 'none',
            ...popoverBox,
          }}
        />
      </Popover>
      {children}
    </div>
  )
};