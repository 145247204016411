import { BaseField } from '@mezzanine-ui/react-hook-form';
import {
  LocationAllLevelSearch,
  LocationControlContextValues,
  LocationControlProvider,
  LocationPickerModalButton,
} from '../LocationPicker';
import { useFormContext } from 'react-hook-form';
import { useCallback } from 'react';

interface LocationAutoCompleteFieldProps {
  registerName: string;
  label: string;
}

export function LocationAutoCompleteField({
  registerName,
  label,
}: LocationAutoCompleteFieldProps) {
  const methods = useFormContext();

  const _onLocationChange = useCallback(
    (values: LocationControlContextValues) => {
      methods.setValue(registerName, values.activeId);
    },
    [methods, registerName]
  );

  return (
    <LocationControlProvider onChange={_onLocationChange}>
      <BaseField
        name={registerName}
        label={label}
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 'var(--mzn-spacing-2)',
        }}
      >
        <LocationAllLevelSearch />
        <LocationPickerModalButton />
      </BaseField>
    </LocationControlProvider>
  );
}
