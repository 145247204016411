import { useMemo } from 'react';

export function useMocks() {
  const mockModuleRoleTreeData = useMemo(
    () => [
      {
        value: 'HR_id',
        title: 'HR',
        selectable: true,
        children: [
          { value: 'owner_id', title: '管理員', selectable: true },
          { value: 'user1', title: '專員', selectable: true },
          { value: 'manager', title: '經理', selectable: true },
        ],
      },
      {
        value: 'SD_id',
        title: 'SD',
        selectable: true,
        children: [
          { value: 'owner_id_1', title: '管理員', selectable: true },
          { value: 'user1_1', title: '專員', selectable: true },
          { value: 'manager_1', title: '經理', selectable: true },
        ],
      },
    ],
    []
  );

  const mockRolePolicyTreeData = useMemo(
    () => [
      {
        value: 'SD_id',
        title: 'SD',
        selectable: true,
        children: [
          {
            value: 'resource1',
            title: '資源1',
            selectable: true,
            children: [
              { value: 'read1', title: '檢視', selectable: true },
              { value: 'create1', title: '新增', selectable: true },
              { value: 'update1', title: '編輯', selectable: true },
              { value: 'delete1', title: '刪除', selectable: true },
            ],
          },
          {
            value: 'resource2',
            title: '資源2',
            selectable: true,
            children: [
              { value: 'read2', title: '檢視', selectable: true },
              { value: 'create2', title: '新增', selectable: true },
              { value: 'update2', title: '編輯', selectable: true },
              { value: 'delete2', title: '刪除', selectable: true },
            ],
          },
        ],
      },
      {
        value: 'HR_id',
        title: 'HR',
        selectable: true,
        children: [
          {
            value: 'hr_resource1',
            title: '資源1',
            selectable: true,
            children: [
              { value: 'hr_read1', title: '檢視', selectable: true },
              { value: 'hr_create1', title: '新增', selectable: true },
              { value: 'hr_update1', title: '編輯', selectable: true },
              { value: 'hr_delete1', title: '刪除', selectable: true },
            ],
          },
          {
            value: 'hr_resource2',
            title: '資源2',
            selectable: true,
            children: [
              { value: 'hr_read2', title: '檢視', selectable: true },
              { value: 'hr_create2', title: '新增', selectable: true },
              { value: 'hr_update2', title: '編輯', selectable: true },
              { value: 'hr_delete2', title: '刪除', selectable: true },
            ],
          },
        ],
      },
    ],
    []
  );

  return {
    mockModuleRoleTreeData,
    mockRolePolicyTreeData,
  };
}
