import { Button, SelectValue } from "@mezzanine-ui/react";
import { AutoCompleteField, AutoCompleteFieldProps, AutoCompleteMultiField, AutoCompleteMultiFieldProps } from "@mezzanine-ui/react-hook-form";
import { useLocationsBySearchTerm } from "@solar/data";
import { Col, Row, RowProps } from "antd";
import { uniqBy } from "lodash";
import { useState } from "react";

type LocationIdsAutocomepleteFieldProps = Omit<(AutoCompleteFieldProps | AutoCompleteMultiFieldProps) & {
  mode: 'single' | 'multiple';
  buttonText?: string;
  rowProps?: RowProps;
  openModal: () => void;
  onChange: (values: SelectValue[]) => void;
}, 'options'>;

export function LocationIdsAutocomepleteField({
  registerName,
  mode,
  buttonText,
  rowProps,
  openModal,
  onChange,
}: LocationIdsAutocomepleteFieldProps) {
  const LocationIdsField = mode === 'single'
    ? AutoCompleteField
    : AutoCompleteMultiField;

  const [searchText, setSearchText] = useState('');

  const { locations } = useLocationsBySearchTerm({ searchText });

  const locationOptions = uniqBy(locations, 'id')
    ?.map(({ id, level }) => ({ id, name: id, level })) ?? [];

  return (
    <Row gutter={[8, 8]} {...rowProps}>
      <Col span={18}>
        <LocationIdsField
          registerName={registerName}
          options={locationOptions}
          onChange={(selectvalue) => onChange(
            Array.isArray(selectvalue)
            ? selectvalue
            : selectvalue
              ? [selectvalue]
              : []
          )}
          onSearch={setSearchText} />
      </Col>
      <Col span={6}>
        <Button type="button" variant="outlined" onClick={openModal}>
          {buttonText ?? '地圖'}
        </Button>
      </Col>
    </Row>
  );
}