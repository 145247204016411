import { Message } from '@mezzanine-ui/react';
import {
  MaterialShiftInputFields,
  ScanBarCodeActionResult,
} from '../shift-action.enum';
import { KeySourcePairType, ShiftTableColumnProps } from '../typings';
import {
  FetchingInventoryStockTypes,
  InventoryItem,
  PageInfo,
  useInventoryByMaterial,
} from '@solar/data';
import Decimal from 'decimal.js';

const stockTypes = [
  FetchingInventoryStockTypes.AVAILABLE,
  FetchingInventoryStockTypes.PENDING,
  FetchingInventoryStockTypes.SALES,
];

export const enterKeyDownAction = async ({
  keySourcePair,
  type,
  inputGuard,
  fetchInventoryByMaterial,
  mutateFetchInventoryParams,
  fetchingInventoryParams,
}: {
  inputGuard: (
    targetField: MaterialShiftInputFields
  ) => ScanBarCodeActionResult;
  keySourcePair: KeySourcePairType<ShiftTableColumnProps>;
  type: MaterialShiftInputFields;
  fetchInventoryByMaterial: ReturnType<
    typeof useInventoryByMaterial
  >['fetchInventoryByMaterial'];
  mutateFetchInventoryParams: ReturnType<
    typeof useInventoryByMaterial
  >['mutateFetchInventoryParams'];
  fetchingInventoryParams: ReturnType<
    typeof useInventoryByMaterial
  >['fetchingInventoryParams'];
}) => {
  // if (
  //   inputGuard(MaterialShiftInputFields.MATERIAL_ID_OR_MATERIAL_NAME) ===
  //   ScanBarCodeActionResult.Prohibit
  // )
  //   return;

  // 清空其他 table 資料
  const restDataMaintainers = Object.keys(keySourcePair)
    .filter((key) => {
      return key !== type;
    })
    .map(
      (key) =>
        keySourcePair[key as MaterialShiftInputFields].dataSourceMaintainer
    );

  restDataMaintainers.forEach((maintainer) => {
    maintainer([]);
  });

  const { inputFieldValue, dataSourceMaintainer } = keySourcePair[type];

  // 第一次 fetch 需帶入 type，後續 mutateFetchInventoryParams 會 maintain type
  // Table fetchMore 的 callback 可以直接 call fetchInventoryByMaterial
  const res = await fetchInventoryByMaterial({
    type: type as MaterialShiftInputFields,
    stockTypes,
    searchTerms: [inputFieldValue], //...fetchingInventoryParams.searchTerms,
  });
  const { records, pageInfo } = res as {
    records: InventoryItem[];
    pageInfo: PageInfo;
  };

  if (records?.length === 0) {
    Message.info('查無資料');
  }

  mutateFetchInventoryParams((prev) => {
    return {
      ...pageInfo,
      searchTerms: [...prev.searchTerms, inputFieldValue],
      type: type,
      stockTypes,
    };
  });

  // 只有第一次 enter key down 才 setData
  // 後續依賴 fetchMore callBack

  // if (fetchingInventoryParams.searchTerms.length === 0)
  dataSourceMaintainer((prevSource: ShiftTableColumnProps[]) => {
    const sourceIdSet = new Set(
      prevSource.map((record) => record?.batchStoredLoaderRecordId)
    );

    return prevSource.concat(
      records
        ?.filter(
          (record) => !sourceIdSet.has(record?.batchStoredLoaderRecordId)
        )
        ?.map(
          (record) =>
            ({
              ...record,
              id: record?.batchStoredLoaderRecordId?.toString(),
              batchStoredLoaderRecordId: record?.batchStoredLoaderRecordId,
              stackId: record?.stackId,
              stackName: record?.stackName,
              batchId: record?.batchId,
              loaderId: record?.loaderId,
              loaderName: record?.loaderName,
              mainMaterialUnitQuantity: record?.mainMaterialUnitQuantity,
              materialDescription: record?.materialDescription,
              materialId: record?.materialId,
              availableMaterialInputUnitQuantity: new Decimal(
                record?.quantity ?? '0'
              )
                .div(record?.stockOverInputUnitRatio ?? '1')
                .toString(),
              transferAmount: new Decimal(record?.quantity ?? '0')
                .div(record?.stockOverInputUnitRatio ?? '1')
                .toString(),
              actualMaterialWeightUnitQuantity:
                record?.actualMaterialWeightUnitQuantity ?? '0',
              materialWeightUnitQuantity:
                record?.materialWeightUnitQuantity ?? '0',
            } as ShiftTableColumnProps)
        ) ?? []
    );
  });
};
