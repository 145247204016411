import useSWR from 'swr';
import { API_NAMESPACE } from '../request';
import { useState } from 'react';
import { OffsetBased, PageInfo } from '../pagination';
import { WorkOrder, WorkOrderDetail } from './work-orders.type';

const namespace = API_NAMESPACE.MATERIALS;

type GetWorkOrdersParams = OffsetBased & {
  searchTerm?: string;
};

// export type WorkOrder = {
//   id: string;
//   expectedCompletedAt: string;
//   factoryId: string;
//   createdAt: string;
//   state: string;
//   orderTypeName: string;
// };

export function useGetWorkOrders(defaultParams?: GetWorkOrdersParams) {
  const [params, setParams] = useState<GetWorkOrdersParams | undefined>(
    defaultParams
  );
  const { data, error, mutate } = useSWR<{
    records: WorkOrder[];
    pageInfo: PageInfo;
  }>(['/warehouses/work-orders', { params, namespace }]);

  return {
    workOrders: data?.records ?? [],
    isLoading: !data && !error,
    pageInfo: data?.pageInfo,
    mutateGetWorkOrders: mutate,
    refetchGetWorkOrders: setParams,
  };
}

export function useGetWorkOrderDetailById(id?: string) {
  const { data, error, mutate } = useSWR<WorkOrderDetail>(
    id ? [`/warehouses/work-orders/${id}`, { namespace }] : null
  );

  return {
    workOrder: data,
    isLoading: !data && !error,
    mutateGetWorkOrderDetailById: mutate,
  };
}
