import { API_NAMESPACE, request } from '@solar/data';
import { PostMiscMaterialPayload } from '../types';

const namespace = API_NAMESPACE.MATERIALS;

export const postMiscMove = ({
  miscMaterialId,
  payload,
}: {
  payload: PostMiscMaterialPayload;
  miscMaterialId: string;
}): Promise<Response> => {
  return request(`/misc-material/${miscMaterialId}/move`, {
    method: 'post',
    body: JSON.stringify(payload),
    responseParser: (res) => res,
    namespace,
  });
};
