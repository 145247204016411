import { Button, Modal, ModalBody, ModalFooter, Typography } from "@mezzanine-ui/react";
import { MznPaginationTable, useTargetModal } from "@solar/templates";
import { Modals } from "./Modals.enum";

export function DispatchOrderViewModal() {
  const { open, closeModal } = useTargetModal(Modals.VIEW_DISPATCH_ORDER);

  return (
    <Modal style={{ width: '1000px' }} open={open} onClose={closeModal}>
      <ModalBody style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <Typography variant="h4">投料需求</Typography>
        <MznPaginationTable
          fetchData={console.log}
          pageInfo={{}}
          dataSource={[{} as any]}
          columns={[
            {
              dataIndex: 'materialDescription',
              title: '品名',
            },
            {
              dataIndex: 'materialId',
              title: '料號',
            },
            {
              dataIndex: 'batchId',
              title: '批號流水',
            },
            {
              dataIndex: 'location',
              title: '儲存位置',
            },
            {
              dataIndex: 'loaderId',
              title: '載具',
            },
            {
              dataIndex: 'shouldDeliveredAmount',
              title: '應發數量',
            },
            {
              dataIndex: 'deliveredAmount',
              title: '已發數量',
            },
            {
              dataIndex: 'unit',
              title: '單位',
            },
          ]}
        />
        <Typography variant="h4">發料紀錄</Typography>
        <MznPaginationTable
          fetchData={console.log}
          pageInfo={{}}
          dataSource={[{} as any]}
          columns={[
            {
              dataIndex: 'deliveredDate',
              title: '發料時間',
            },
            {
              dataIndex: 'receiver',
              title: '領料人',
            },
            {
              dataIndex: 'materialDescription',
              title: '品名',
            },
            {
              dataIndex: 'materialId',
              title: '料號',
            },
            {
              dataIndex: 'batchId',
              title: '批號流水',
            },
            {
              dataIndex: 'location',
              title: '發料位置',
            },
            {
              dataIndex: 'loaderId',
              title: '發料載具',
            },
            {
              dataIndex: 'amount',
              title: '發料數量',
            },
            {
              dataIndex: 'unit',
              title: '單位',
            },
          ]}
        />
      </ModalBody>
    </Modal>
  );
}