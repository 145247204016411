import {
  DatePickerField,
  FormFieldsWrapper,
  InputField,
} from '@mezzanine-ui/react-hook-form';
import { useForm } from 'react-hook-form';
import { MznPaginationTable } from '../../Templates/PaginationTable/MznPaginationTable';
import { Row, Space } from 'antd';
import { Button } from '@mezzanine-ui/react';

export function OutsourcingOrderTabPaneInventory() {
  const methods = useForm();

  return (
    <FormFieldsWrapper methods={methods}>
      <Space direction="vertical" size="small">
        <Row justify="end">
          <Button type="button" variant="contained">
            出貨
          </Button>
        </Row>
        <MznPaginationTable
          columns={[
            {
              title: '料號',
              dataIndex: 'materialId',
            },
            {
              title: '品名',
              dataIndex: 'materialDescription',
            },
            {
              title: '批號',
              dataIndex: 'materialBatchId',
            },
            {
              title: '數量',
              dataIndex: 'materialQuantity',
            },
            {
              title: '單位',
              dataIndex: 'materialUnit',
            },
          ]}
          dataSource={[]}
          fetchData={console.log}
          pageInfo={{}}
        />
      </Space>
    </FormFieldsWrapper>
  );
}
