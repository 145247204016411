import useSWR from 'swr';
import { PaginatedResponse } from '../pagination';

import { API_NAMESPACE } from '../request';
import { useMemo, useState } from 'react';

export type LMBRecord = {
  id: string;
  recordId: string;
  recordType: string;
  beforeId: string | null;
  beforeMaterialId: string | null;
  beforeMaterialSapBatchId: string | null;
  beforeMaterialSubBatchId: string | null;
  beforeLoaderId: string | null;
  beforeLocationId: string | null;
  beforeBarcode: string | null;
  beforeQuantity: string | null;
  beforeActualWeightQuantity: string | null;
  beforeStockType: string | null;
  beforeDeletedAt: string | null;
  afterId: number;
  afterMaterialId: string;
  afterMaterialSapBatchId: string;
  afterMaterialSubBatchId: string;
  afterLoaderId: string;
  afterLocationId: string | null;
  afterBarcode: string | null;
  afterQuantity: string;
  afterActualWeightQuantity: string;
  afterStockType: string;
  afterDeletedAt: string | null;
  userId: string;
  xRequestId: string;
  recordCreatedAt: string;
  moveType: string | null;
  sourceOrderType: string | null;
  sourceOrderId: string | null;
};

export type LMBRecordParams = {
  limit: number;
  offset: number;
  materialIds: string[];
  loaderIds: string[];
  barcodes: string[];
  sourceOrderTypes: [keyof typeof STOCK_CHANGE_ORDER_TYPES];
  sourceOrderIds: string[];
};

export const STOCK_CHANGE_ORDER_TYPES = {
  REQUEST_ORDER: '工單發料單',
  DELIVERY_ORDER: '出貨單',
  PURCHASE_ORDER: '庫存採購單',
  IQC_ORDER: 'IQC單',
  TRANSFER_ORDER: '移轉單',
  SALES_ORDER: '銷售訂單',
  OUTSOURCE_PURCHASE_ORDER: '委外採購單',
} as const;

export const useLMBRecords = (defaultProps: Partial<LMBRecordParams>) => {
  const [params, setParams] = useState<Partial<LMBRecordParams>>(defaultProps);
  const { data, error } = useSWR<PaginatedResponse<LMBRecord>>(
    params
      ? [`/LMBRecords`, { namespace: API_NAMESPACE.MATERIALS, params }]
      : null
  );

  const pageInfo = useMemo(() => {
    const page =
      (data?.pageInfo?.offset ?? 0) / (data?.pageInfo?.limit ?? 20) + 1;
    const pageSize = data?.pageInfo?.limit ?? 20;
    return { ...(data?.pageInfo ?? {}), page, pageSize };
  }, [data]);

  return {
    data:
      data?.records.map((record) => ({ ...record, id: record.recordId })) ?? [],
    isLoading: !data && !error,
    setParams,
    pageInfo,
  };
};
