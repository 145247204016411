import { useAutoAnimate } from '@formkit/auto-animate/react';
import { ChevronLeftIcon, TrashIcon } from '@mezzanine-ui/icons';
import {
  Button,
  Icon,
  IconButton,
  MenuDivider,
  Message,
  SelectValue,
  Typography,
} from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  FormFieldsDebug,
  FormFieldsWrapper,
  InputField,
  SelectField,
} from '@mezzanine-ui/react-hook-form';
import {
  API_NAMESPACE,
  checkJWTRoles,
  InventoryItem,
  MaterialShiftInputFields,
  request,
  useGetDepts,
  useGetMembers,
  useInventoryByMaterial,
  useLoaders,
  useMaterialRequestOrderLogs,
  useModalController,
} from '@solar/data';
import {
  HorizontalField,
  LOCATION_SELECTOR,
  LocationAndLoaderPickerField,
  LocationSelectorGroupModal,
  LocationSelectorProvider,
  ModalGroup,
  NumericInputField,
  PageLayout,
  RowSection,
  useLocationSelectorController,
  useModalGroupController,
} from '@solar/templates';
import { Descriptions, Space, Table, notification } from 'antd';
import { Decimal } from 'decimal.js';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { WarehouseAndVehicleFilter } from '../../Material/IncomingInspectOperationPage/Modals/WarehouseAndVehicleFilter';
import { BarcodeScanInput } from '../../Material/MaterialShift/BarcodeScanInput';
import {
  WorkOrderInto,
  useWorkOrderInfo,
} from '../../ProductionPlanning/NormalManufacturingPage/hooks/use-work-order-info';
import {
  MaterialSupplyByLoaderModal,
  MaterialSupplyByLoaderModalData,
} from './MaterialSupplyByLoaderModal';
import {
  MaterialSupplyByMaterialModal,
  MaterialSupplyByMaterialModalData,
} from './MaterialSupplyByMaterialModal';
import { useLogsColumns } from './hooks/use-logs-columns';
import classes from './material-supply-to-work-order-page.module.scss';
import { TransferResponse } from './types';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

type TmpShip = {
  id: string;
  specId: number;
  materialId: string;
  materialDescription: string;
  batchId: string;
  loaderId: number;
  stackId: string;
  availableMaterialStockUnitQuantity: string;
};

const namespace = API_NAMESPACE.MATERIALS;

type RequestTable = Partial<
  WorkOrderInto['materialRequestOrderSpecs'][number]
> & {
  id: number;
  isChild?: true;
  isEnabled?: true;
  parentOwnsChildrenQty?: number;
  materialBarcode?: string;
  // materialInputUnit?: string;
  availableMaterialStockUnitQuantity?: string | undefined;
  stockOverInputUnitRatio?: string | undefined;
};

const schema = Yup.object().shape({
  tempRequestTable: Yup.array().of(
    Yup.object().shape({
      tempQuantity: Yup.number().moreThan(0, '需大於 0').required('必填'),
      tempWeightQuantity: Yup.number().moreThan(0, '需大於 0').required('必填'),
    })
  ),
});

export function MaterialSupplyToWorkOrderPage() {
  const [dispatching, setDispatching] = useState(false);

  const navigate = useNavigate();
  const params = useParams<{ workOrderId: string }>();
  const logsColumns = useLogsColumns();

  const { members, refetchGetMembers } = useGetMembers();
  const { depts, refetchGetDepts } = useGetDepts();

  const requestOrderSpecsMethods = useForm<{
    toShelfId: string;
    loader: SelectValue;
    recipient: SelectValue;
    receiverDept: SelectValue;
    requestTable: RequestTable[];
    tempRequestTable: // RequestTable &
    (InventoryItem & {
      tempQuantity: string;
      tempWeightQuantity: string;
      tempUnit: SelectValue;
      materialBatchId: string;
    })[];
    insertChildIndex: number;
  }>({
    defaultValues: {
      requestTable: [],
    },
    resolver: yupResolver(schema),
  });

  const {
    fields: tempRequestTable,
    insert: insertByTempRequestTable,
    remove: removeByTempRequestTable,
  } = useFieldArray({
    control: requestOrderSpecsMethods.control,
    name: 'tempRequestTable',
  });

  const requestTable = useWatch({
    control: requestOrderSpecsMethods.control,
    name: 'requestTable',
  });

  const { fetchInventoryByMaterial } = useInventoryByMaterial({
    defaultType: MaterialShiftInputFields.MATERIAL_BARCODE,
  });

  // const { loaders, mutateLoaderParams } = useLoaders();
  // const locationSelectorController = useLocationSelectorController({
  //   onSelectedIdsChange(selectedIds) {
  //     const shelfId =
  //       selectedIds?.shelfId ?? selectedIds?.stackId ?? selectedIds?.zoneId;
  //     if (shelfId) {
  //       mutateLoaderParams({
  //         shelfIds: [shelfId],
  //         whenNotFoundAutoCreate: true,
  //       });
  //     }
  //   },
  // });

  // const svgMapController = useLocationSelectorController({
  //   resetAfterClosing: false,
  // });

  // const warehouseAndVehicleFilterRef =
  //   useRef<React.ElementRef<typeof WarehouseAndVehicleFilter>>(null);

  const { workOrder: data } = useWorkOrderInfo(params?.workOrderId ?? '');
  const { logs, isLoading: logsLoading } = useMaterialRequestOrderLogs(
    params?.workOrderId ?? ''
  );

  const [tmpShipList, setTmpShipList] = useState<TmpShip[]>([]);

  const materialSupplyByMaterialModalController =
    useModalController<MaterialSupplyByMaterialModalData>();
  const materialSupplyByLoaderModalController =
    useModalController<MaterialSupplyByLoaderModalData>();
  // const modalGroupController = useModalGroupController([
  //   { name: LOCATION_SELECTOR },
  // ]);

  const handleAddItems = (items: TmpShip[]) => {
    setTmpShipList((prevItems) => [
      ...items,
      ...prevItems.filter(
        (prevItem) => !items.some((item) => item?.id === prevItem?.id)
      ),
    ]);
  };

  useEffect(() => {
    if (data && data?.materialRequestOrderSpecs) {
      requestOrderSpecsMethods.setValue(
        'requestTable',
        data?.materialRequestOrderSpecs
      );
    }
  }, [data]);

  // useEffect(() => {
  //   return () => {
  //     debounceInputHandler.cancel();
  //   };
  // }, [debounceInputHandler]);

  const transferOrders = useCallback(
    async (payload: {
      items: any[];
      receiverId?: string;
      receiverDeptId?: string;
      materialRequestOrderId?: string;
    }) => {
      try {
        const res = (await request('/warehouses/inventory-transfer-orders', {
          method: 'post',
          body: JSON.stringify(payload),
          namespace,
          responseParser: (res) => res,
        })) as Response;

        if (!res.ok) throw new Error(await res.text());

        const data: TransferResponse = await res.json();

        notification.success({
          duration: 0,
          message: `物料移轉成功：${data?.id}`,
        });

        return data;
      } catch (error: any) {
        notification.error({
          duration: 0,
          message: `物料移轉失敗：${JSON.parse(error?.message)?.message}`,
        });
        throw error;
      }
    },
    []
  );

  const changeLoaderLocation = useCallback(
    async (payload: {
      items: { toShelfId: string; fromLoaderId: string }[];
      receiverId?: string;
      receiverDeptId?: string;
      materialRequestOrderId?: string;
    }) => {
      try {
        const res = await request(
          '/warehouses/inventory-transfer-orders/change-location',
          {
            method: 'post',
            body: JSON.stringify(payload),
            namespace,
            responseParser: (res) => res,
          }
        );

        if (!res.ok) throw new Error(await res.text());

        const data = await res.json();

        notification.success({
          duration: 0,
          message: `載具移轉成功：${data?.id}`,
        });
      } catch (error: any) {
        notification.error({
          duration: 0,
          message: `載具移轉失敗：${payload?.items
            ?.map((item) => item?.fromLoaderId)
            ?.join(', ')}（${JSON.parse(error?.message)?.message}）`,
        });
        throw error;
      }
    },
    []
  );

  return (
    <>
      <div>
        <Button
          type="button"
          onClick={() => {
            if (window.history.state && window.history.state.key) {
              navigate(-1);
            } else {
              navigate('/', { replace: true });
            }
          }}
          prefix={<Icon style={{ color: '#8F8F8F' }} icon={ChevronLeftIcon} />}
          variant="text"
        >
          <Typography color="text-secondary" variant="button2">
            返回上一頁
          </Typography>
        </Button>
      </div>
      <PageLayout title="生產工單發料">
        <Descriptions>
          <Descriptions.Item label="發料單號">
            {data?.id ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label="工單單號">
            {data?.workOrderId ?? ''}
          </Descriptions.Item>
          <Descriptions.Item label="預期領料日">
            {data?.expectedCompletedAt
              ? moment(data?.expectedCompletedAt).format('YYYY/MM/DD')
              : ''}
          </Descriptions.Item>
          <Descriptions.Item label="生管人員">
            {data?.creatorId ?? ''}
          </Descriptions.Item>
        </Descriptions>
        <Typography variant="h3">發料作業</Typography>
        <MenuDivider />
        {/* <LocationSelectorProvider controller={locationSelectorController}> */}
        {checkJWTRoles([85]) && (
          <FormFieldsWrapper
            methods={requestOrderSpecsMethods}
            onSubmit={async (values) => {
              const {
                toShelfId,
                loader,
                recipient,
                receiverDept,
                tempRequestTable,
              } = values;

              setDispatching(true);

              try {
                // const { selectedIds } = locationSelectorController;
                // const shelfId =
                //   selectedIds?.shelfId ??
                //   selectedIds?.stackId ??
                //   selectedIds?.zoneId;

                // if (!shelfId) {
                //   warehouseAndVehicleFilterRef.current?.setLocationRequired(
                //     true
                //   );
                //   return;
                // } else {
                //   warehouseAndVehicleFilterRef.current?.setLocationRequired(
                //     false
                //   );
                // }

                let hasSameLoader = false;

                const items = tempRequestTable?.reduce<any[]>(
                  (accumulator, row) => {
                    if (row?.loaderId === loader?.id) {
                      hasSameLoader = true;
                      return accumulator;
                    }

                    const materialStockUnitQuantity = (() => {
                      switch (row.tempUnit?.id) {
                        case 'materialStockUnit':
                          return new Decimal(
                            row?.tempQuantity ?? '0'
                          ).toString();
                        case 'materialInputUnit':
                          return new Decimal(row?.tempQuantity ?? '0')
                            .mul(row?.stockOverInputUnitRatio ?? '0')
                            .toString();
                        default:
                          return null;
                      }
                    })();

                    accumulator.push({
                      batchStoredLoaderRecordId: row?.batchStoredLoaderRecordId,
                      toShelfId: toShelfId ?? '',
                      toLoaderId: values?.loader?.id ?? null,
                      materialStockUnitQuantity,
                      materialWeightUnitQuantity: row?.tempWeightQuantity
                        ? new Decimal(row?.tempWeightQuantity).toString()
                        : undefined,
                    });

                    return accumulator;
                  },
                  []
                );

                if (!items?.length) {
                  Message.error('缺少物料');
                  return;
                }

                const nextPageParams = new URLSearchParams();

                try {
                  const data = await transferOrders({
                    items,
                    receiverId: values?.recipient?.id,
                    receiverDeptId: values?.receiverDept?.id,
                    materialRequestOrderId: params?.workOrderId,
                    // isRequestMaterialOrder: true,
                  });

                  (data?.inventoryTransferOrderSpecs ?? [])?.forEach(
                    (spec: any) => {
                      nextPageParams.append(
                        'loaderMaterialBatchId',
                        spec?.loaderMaterialBatchId
                        // `${spec.materialId}+${spec.materialSapBatchId}-${spec.materialSubBatchId}+${spec.createdAt}`
                      );
                    }
                  );
                } catch (error: any) {
                  hasSameLoader = false;
                  throw error;
                }

                if (hasSameLoader) {
                  try {
                    changeLoaderLocation({
                      items: [
                        {
                          toShelfId: toShelfId ?? '',
                          fromLoaderId: values?.loader?.id ?? null,
                        },
                      ],
                      receiverId: values?.recipient?.id,
                      receiverDeptId: values?.receiverDept?.id,
                      materialRequestOrderId: params?.workOrderId,
                    });
                  } catch (error: any) {
                    throw error;
                  }
                }

                await window.open(
                  `/print-management/label?tab=batch-sticker&from=MaterialSupplyToWorkOrder&${nextPageParams.toString()}`,
                  '_blank'
                );
              } catch (err) {
                if (err instanceof Error) {
                  Message.error(JSON.parse(err.message).message);
                } else {
                  Message.error('發料失敗');
                }
              } finally {
                setDispatching(false);
              }
            }}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              {/* <WarehouseAndVehicleFilter
              enableLoaderSelector
              enableDirectlyLoaderSearch
              locationLabel="發料目的地："
              loaderLabel="發料載具："
              openLocationSelectorModal={() =>
                modalGroupController.openModal(LOCATION_SELECTOR, null)
              }
              loaders={loaders}
              ref={warehouseAndVehicleFilterRef}
            /> */}
              <LocationAndLoaderPickerField
                // checkLoaderPosition
                locationIdLabel="發料目的地："
                locationIdRegisterName="toShelfId"
                loaderIdLabel="發料載具："
                loaderIdRegisterName="loader"
              />
              <HorizontalField label="接收人員：">
                <AutoCompleteField
                  registerName="recipient"
                  options={members}
                  onSearch={(searchTerm) => refetchGetMembers({ searchTerm })}
                />
              </HorizontalField>
              <HorizontalField label="接收單位：">
                <AutoCompleteField
                  registerName="receiverDept"
                  options={depts}
                  onSearch={(searchTerm) => refetchGetDepts({ searchTerm })}
                />
              </HorizontalField>
              <div>
                <Table
                  components={{
                    body: { wrapper: Wrapper, row: Row },
                  }}
                  pagination={false}
                  rowClassName={classes['table-row']}
                  rowKey="id"
                  columns={[
                    {
                      title: '品名',
                      dataIndex: 'material',
                      render: (_, source) => {
                        // if (source?.isChild) {
                        //   return <Icon size={20} icon={ChevronUpIcon} />;
                        // }

                        return (
                          (source?.material as any)?.description ??
                          source?.materialDescription ??
                          ''
                        );
                      },
                    },
                    {
                      title: '料號',
                      dataIndex: 'material',
                      render: (_, record) => {
                        return record?.material?.id;
                      },
                    },
                    {
                      title: '批號',
                      // dataIndex: 'materialBatchId',
                      render: (_, record) =>
                        record['materialSapBatchId'] &&
                        record['materialBatchId']
                          ? `${record['materialSapBatchId']}-${record['materialBatchId']}`
                          : '',
                    },
                    {
                      title: '基礎劑量單位數量',
                      dataIndex:
                        'availableMaterialStockUnitQuantity-expectedMaterialStockUnitQuantity',
                      render: (_, record) =>
                        record?.availableMaterialStockUnitQuantity ??
                        record?.expectedMaterialStockUnitQuantity,
                    },
                    {
                      title: '基礎計量單位',
                      dataIndex: 'materialStockUnit-materialStockUnit',
                      render: (_, record) =>
                        (record?.material as any)?.materialStockUnit ??
                        record?.materialStockUnit,
                    },
                    {
                      title: '管理單位數量',
                      dataIndex: 'expectedMaterialInputUnitQuantity',
                    },
                    {
                      title: '管理單位',
                      dataIndex: 'materialInputUnit',
                    },
                    {
                      title: '單位',
                      dataIndex: 'mainMaterialUnit',
                    },
                  ]}
                  dataSource={requestTable ?? []}
                  loading={!data || dispatching}
                />
                <Typography variant="h4">投料需求</Typography>
                <BarcodeScanInput
                  disabled={dispatching}
                  inputStyle={classes['barcode-scan-input']}
                  otherOnKeyDownAction={async (value) => {
                    if (!data) {
                      Message.warning('投入需求資料尚未載入');
                      return;
                    }

                    if (dispatching) {
                      Message.warning('發料中！請勿執行查詢物料');
                      return;
                    }
                    if (value) {
                      const res = await fetchInventoryByMaterial({
                        materialBarcodes: [value],
                      });

                      let isMatchedBatchId = false;

                      if (res?.records?.length) {
                        let successMatchOneCondition = false;

                        for (let i = 0; i < requestTable.length; i++) {
                          let matchedDataIndex = -1;
                          const row = requestTable[i];

                          if (row.materialId === res?.records[0]?.materialId) {
                            matchedDataIndex = i;
                          } else {
                            continue;
                          }

                          isMatchedBatchId =
                            `${row.materialSapBatchId}-${row.materialBatchId}` ===
                            res?.records[0]?.batchId;

                          if (isMatchedBatchId) {
                            insertByTempRequestTable(0, {
                              ...res?.records[0],
                              materialBatchId:
                                res?.records[0]?.materialSubBatchId,
                              tempQuantity:
                                res?.records[0]
                                  ?.availableMaterialStockUnitQuantity,
                              tempWeightQuantity:
                                res?.records[0]
                                  ?.actualMaterialWeightUnitQuantity,
                              tempUnit: {
                                id: 'materialStockUnit', //res?.records[0]?.materialStockUnit,
                                name: res?.records[0]?.materialStockUnit,
                              },
                            });
                            successMatchOneCondition = true;
                          } else {
                            continue;
                          }
                        }
                        if (!successMatchOneCondition) {
                          Message.info('沒有匹配的資料');
                        }
                      } else {
                        Message.info('沒有匹配的資料');
                      }
                    }
                  }}
                />
                <Table
                  pagination={false}
                  columns={[
                    {
                      title: '移除',
                      dataIndex: 'recover',
                      render: (_, source, index) => {
                        return (
                          <IconButton
                            type="button"
                            onClick={() => {
                              removeByTempRequestTable(index);
                            }}
                          >
                            <Icon icon={TrashIcon} />
                          </IconButton>
                        );
                      },
                    },

                    {
                      title: '物料條碼',
                      dataIndex: 'materialBarcode',
                    },
                    {
                      title: '儲位',
                      dataIndex: 'shelfId',
                    },
                    {
                      title: '載具',
                      dataIndex: 'loaderId',
                    },
                    {
                      title: '數量',
                      dataIndex: 'tempQuantity',
                      render: (_, source, index) => {
                        source.actualMaterialWeightUnitQuantity;
                        return (
                          <NumericInputField
                            validateMode="float"
                            registerName={`tempRequestTable.${index}.tempQuantity`}
                            onChange={(e) => {
                              const countedWeight =
                                Number(
                                  source['availableMaterialInputUnitQuantity']
                                ).toString() === '0'
                                  ? '0'
                                  : new Decimal(
                                      source['actualMaterialWeightUnitQuantity']
                                    )
                                      .mul(e.target.value)
                                      .div(
                                        new Decimal(source['quantity']).div(
                                          source['stockOverInputUnitRatio']
                                        )
                                      );

                              requestOrderSpecsMethods.setValue(
                                `tempRequestTable.${index}.tempWeightQuantity`,
                                countedWeight.toString()
                              );
                            }}
                          />
                        );
                      },
                    },
                    {
                      title: '實際重量',
                      dataIndex: 'tempWeightQuantity',
                      render: (_, source, index) => {
                        return (
                          <NumericInputField
                            validateMode="float"
                            valueAsNumber
                            registerName={`tempRequestTable.${index}.tempWeightQuantity`}
                          />
                        );
                      },
                    },
                    {
                      title: '單位',
                      dataIndex: 'tempUnit',
                      render: (_, source, index: number) => {
                        return (
                          <SelectField
                            registerName={`tempRequestTable.${index}.tempUnit`}
                            options={[
                              // {
                              //   id: 'materialStockUnit',
                              //   name: source?.materialStockUnit as string,
                              // },
                              {
                                id: 'materialInputUnit',
                                name: source?.materialInputUnit as string,
                              },
                            ]}
                            onChange={(obj) => {
                              if (obj.id === 'materialStockUnit') {
                                requestOrderSpecsMethods.setValue(
                                  `tempRequestTable.${index}.tempQuantity`,
                                  source?.availableMaterialStockUnitQuantity ||
                                    '0'
                                );
                              } else if (obj.id === 'materialInputUnit') {
                                requestOrderSpecsMethods.setValue(
                                  `tempRequestTable.${index}.tempQuantity`,
                                  source?.availableMaterialStockUnitQuantity ||
                                    '0'
                                );
                              }
                            }}
                          />
                        );
                      },
                    },
                  ]}
                  dataSource={tempRequestTable ?? []}
                />
              </div>
              <div>
                <Button
                  variant="outlined"
                  style={{ float: 'right' }}
                  loading={dispatching}
                >
                  發料
                </Button>
              </div>
            </Space>
          </FormFieldsWrapper>
        )}

        {/* </LocationSelectorProvider> */}
        <MenuDivider />

        <Typography variant="h4">發料紀錄</Typography>

        <Table
          columns={logsColumns as any}
          dataSource={logs ?? []}
          loading={logsLoading}
        />
        <div style={{ height: '16px' }}></div>
        <MaterialSupplyByMaterialModal
          {...materialSupplyByMaterialModalController}
          tmpShipList={tmpShipList}
          onSubmit={handleAddItems}
        />
        <MaterialSupplyByLoaderModal
          {...materialSupplyByLoaderModalController}
          tmpShipList={tmpShipList}
          onSubmit={handleAddItems}
        />
        {/* <ModalGroup {...modalGroupController}>
          <LocationSelectorGroupModal
            controller={svgMapController}
            onSubmit={(activeId, selectedIds) => {
              locationSelectorController.setSelectedIds(selectedIds ?? {});

              if (activeId) {
                mutateLoaderParams({
                  shelfIds: [activeId],
                  whenNotFoundAutoCreate: true,
                });
              }
            }}
          />
        </ModalGroup> */}
      </PageLayout>
    </>
  );
}

const Wrapper = (props: React.HTMLAttributes<HTMLTableSectionElement>) => {
  const [parent] = useAutoAnimate();
  return (
    <tbody ref={parent} {...props}>
      {props.children}
    </tbody>
  );
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = (props: RowProps) => {
  return <tr key={props['data-row-key']} {...props} />;
};
