import { SelectValue } from '@mezzanine-ui/react';

export const checkOutFormDefaultValues = {
  materialRequestOrderId: '',
  materialBarcode: '',
  materialCheckOutList: [],
  createdAt: new Date().toISOString(),
};

export type CheckOutFormFields = {
  answerLoading: boolean;
  materialRequestOrderId: string;
  processingId: SelectValue;
  displayFormsByMaterialBarcode?: string | null;
  forms: Record<
    string /* materialId_materialSapBatchId_materialSubBatchId */,
    Record<string /* formId */, any>
  >;
  materialBarcode: string;
  materialCheckOutList: ((ExpectedItem | UnexpectedItem) & {
    material?: SelectValue;
  })[];
  createdAt: string;
};

export type ExpectedItem = {
  isExpected: true;
} & Partial<{
  toLoaderId: SelectValue | null;
  outputMaterialBarcode: string | null;
  outputMaterialWeightUnitQuantity: string | null;
  outputMaterialInputUnitQuantity: string | null;
  outputMaterialInputUnit: SelectValue | null;
  remark: string;
  answers?: FormAnswer[];
}>;

export type UnexpectedItem = {
  isExpected: false;
} & Partial<{
  toLoaderId: SelectValue;
  outputMaterialId: string | null;
  outputMaterialWeightUnitQuantity: string | null;
  outputMaterialInputUnitQuantity: string | null;
  outputMaterialInputUnit: SelectValue | null;
  remark: string;
  answers?: FormAnswer[];
}>;

export type FormAnswer = {
  productionFormId: string;
  answer: string;
};
