import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Typography,
} from '@mezzanine-ui/react';
import {
  AutoCompleteField,
  FormFieldsWrapper,
  InputField,
} from '@mezzanine-ui/react-hook-form';
import {
  HumanResourceDepartmentInformationData,
  HumanResourceDepartmentInformationLevelList,
  HumanResourceDepartmentInformationModalField,
  humanResourceDepartmentInformationSchemas,
} from '@solar/data';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import styles from './HumanResourceDepartmentInformationModal.module.scss';

interface HumanResourceDepartmentInformationModalProps {
  methods: UseFormReturn<HumanResourceDepartmentInformationModalField, any>;
  levelListOptions: HumanResourceDepartmentInformationLevelList[] | undefined;
  modal: HumanResourceDepartmentInformationData | null;
  onSubmit: SubmitHandler<HumanResourceDepartmentInformationModalField>;
  onClose: () => void;
}

export function HumanResourceDepartmentInformationModal({
  methods,
  levelListOptions,
  modal,
  onSubmit,
  onClose,
}: HumanResourceDepartmentInformationModalProps) {
  const { formState } = methods;
  const { isValid } = formState;

  return (
    <Modal open={modal !== null} onClose={onClose} className={styles.modal}>
      <FormFieldsWrapper methods={methods} onSubmit={onSubmit}>
        <ModalHeader>編輯部門資料</ModalHeader>
        <ModalBody className={styles.body}>
          <div className={styles.content}>
            <div className={styles.label}>
              <Typography>BU</Typography>
            </div>
            <InputField
              width={300}
              registerName={humanResourceDepartmentInformationSchemas.BU}
            />
          </div>
          <div className={styles.content}>
            <div className={styles.label}>
              <Typography>層級</Typography>
            </div>
            <AutoCompleteField
              width={300}
              registerName={humanResourceDepartmentInformationSchemas.LEVEL_ID}
              options={levelListOptions ?? []}
            />
          </div>
          <div className={styles.content}>
            <div className={styles.label}>
              <Typography>成本中心</Typography>
            </div>
            <InputField
              width={300}
              registerName={humanResourceDepartmentInformationSchemas.COST_CENTER_ID}
            />
          </div>
        </ModalBody>
        <ModalFooter className={styles.footer}>
          <Button type="button" variant="outlined" onClick={onClose}>
            取消
          </Button>
          <Button type="submit" variant="contained" disabled={!isValid}>
            確定
          </Button>
        </ModalFooter>
      </FormFieldsWrapper>
    </Modal>
  );
}

export function isHumanResourceDepartmentInformationModalData(
  obj: unknown
): obj is HumanResourceDepartmentInformationLevelList[] {
  if (obj && Array.isArray(obj)) return obj.every((item) => 'id' in item);
  return false;
}
