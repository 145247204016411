import { DispatchMaterialPanel } from './DispatchMaterialPanel';
import { WorkOrderPanel } from './WorkOrderPanel';

export const tabsOptions = [
  {
    id: 'work-order',
    name: '工單',
    panel: WorkOrderPanel,
  },
  {
    id: 'dispatch-material-order',
    name: '發料單',
    panel: DispatchMaterialPanel,
  },
];

export const tabIds = tabsOptions.map((tab) => tab.id);
