import { Button, Typography } from '@mezzanine-ui/react';
import {
  FormFieldsWrapper,
  SearchInputField,
} from '@mezzanine-ui/react-hook-form';
import { GetMaterialsParams } from '@solar/data';
import { Dispatch, SetStateAction } from 'react';
import { UseFormReturn } from 'react-hook-form';
import styles from './warehouse-view-filter.module.scss';

type EquipmentListFilterProps = {
  setParams: Dispatch<SetStateAction<GetMaterialsParams>>;
  methods: UseFormReturn;
  openLocationSelectorModal: (arg0: any) => void;
};

export function WarehouseViewFilter({
  setParams,
  methods,
  openLocationSelectorModal,
}: EquipmentListFilterProps) {
  // const { factories } = useFactories();

  return (
    <FormFieldsWrapper className={styles.filter_form_wrapper} methods={methods}>
      {/* <CustomDependentSelectors
        data={factories}
        locationLabelAndKeys={locationLabelAndKeys}
        thisSelectorLevel={0}
      /> */}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'max-content max-content',
        }}
      >
        <Typography variant="h4">儲位：</Typography>
        <SearchInputField
          registerName="storagePosition"
          placeholder="請輸入關鍵字搜尋"
          clearable
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Button
          variant="contained"
          type="button"
          onClick={() => {
            const filterState = methods?.getValues();
            const searchTerm = filterState?.storagePosition ?? '';
            setParams((prevParams) => ({
              ...prevParams,
              searchTerm,
            }));
          }}
        >
          搜尋
        </Button>
        <Button variant="outlined" onClick={openLocationSelectorModal}>
          從地圖選取
        </Button>
      </div>
    </FormFieldsWrapper>
  );
}
