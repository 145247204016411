import { Button, Message } from '@mezzanine-ui/react';
import { syncLimsSubmissionResults } from '@solar/data';
import { useCallback, useState } from 'react';

export function SyncLimsButton({
  factory,
  afterSync,
}: {
  factory: string;
  afterSync?: () => void;
}) {
  const [syncing, setSyncing] = useState(false);

  const onSync = useCallback(async () => {
    try {
      setSyncing(true);
      await syncLimsSubmissionResults({ factory });
      afterSync?.();
      Message.success('同步Lims資料成功');
    } catch (error) {
      console.error(error);
      Message.error('同步Lims資料失敗');
    } finally {
      setSyncing(false);
    }
  }, [afterSync, factory]);

  return (
    <Button
      type="button"
      variant="contained"
      loading={syncing}
      onClick={onSync}
    >
      同步lims資料
    </Button>
  );
}
